import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { AnsweredAt } from 'pages/Funds/components/ProcessManagement/AnsweredAt';
import { TextAnswers } from 'pages/Funds/components/ProcessManagement/TextAnswers';
import { foundSelectedOption } from 'pages/Funds/utils';
import { getNumberValue, gridLocalShortDate } from 'utillities';
import { DropdownAndDateAnswersProps } from './types';

const DropdownAndDateAnswers: FC<DropdownAndDateAnswersProps> = props => {
  const { task, isDropdown = false } = props;
  const { options, answers, children_questions: childrenQuestions } = task;

  const areChildrenQuestionsEmpty = childrenQuestions?.every(question => isEmpty(question.answers));

  return (
    <Box marginTop={2}>
      {answers?.map(answer => (
        <div key={answer?.id}>
          <Typography>
            <strong>Answer:</strong>{' '}
            {isDropdown
              ? foundSelectedOption({ id: getNumberValue(answer?.selected_choice), options })
              : gridLocalShortDate(answer?.date_answer)}
          </Typography>
          {childrenQuestions?.map(question => (
            <TextAnswers key={question.id} answers={question.answers} />
          ))}
          {areChildrenQuestionsEmpty && (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <AnsweredAt date={answer?.updated_at} />
            </Box>
          )}
        </div>
      ))}
    </Box>
  );
};

export default DropdownAndDateAnswers;
