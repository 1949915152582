export const addClassName = (className, classNameToAdd) => {
  const tempClassName = className ?? '';
  return !tempClassName.includes(classNameToAdd) ? `${tempClassName} ${classNameToAdd}` : className;
};

export const removeClassName = (className, classNameToRemove) => {
  const tempClassName = className ?? '';
  const pattern = new RegExp(classNameToRemove, 'g');
  return tempClassName.replace(pattern, '').replace(/\s+/g, ' ').trim();
};

export const replaceClassName = (className, classNameToRemove, classNameToAdd) => {
  const tempClassName = className ?? '';
  return `${removeClassName(tempClassName, classNameToRemove)} ${classNameToAdd}`;
};

export const removeValidationClasses = className => {
  const tempClassName = className ?? '';
  return tempClassName
    .replace(/error|warning|success/g, '')
    .replace(/\s+/g, ' ')
    .trim();
};
