import React from 'react';
import PropTypes from 'prop-types';
import { DATE, DROPDOWN, TEXT } from 'common/constants/process-management';
import QuestionInputDate from './QuestionInputDate';
import QuestionInputDropdown from './QuestionInputDropdown';
import QuestionInputText from './QuestionInputText';

const QuestionInput = props => {
  const { questionType, options } = props;

  const availableOptions = options.map(option => ({
    id: option.name,
    label: option.name,
    value: option.id,
  }));
  const inputLabel = options.length > 0 ? options.map(({ name }) => name).join('/') : '';

  switch (questionType) {
    case DATE:
      return <QuestionInputDate {...props} />;
    case TEXT:
      return <QuestionInputText {...props} />;
    case DROPDOWN:
      return <QuestionInputDropdown availableOptions={availableOptions} inputLabel={inputLabel} {...props} />;
    default:
      return <div />;
  }
};

QuestionInput.propTypes = {
  questionType: PropTypes.string.isRequired,
  options: PropTypes.array,
  isDropdown: PropTypes.bool,
};

export default QuestionInput;
