import React, { useContext } from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { ENTER_DATE } from 'common/constants/process-management';
import { TaskContext } from 'context';
import theme from 'theme';
import { dbShortDate, gridLocalShortDate } from 'utillities';
import ChildrenQuestionInput from './ChildrenTextQuestionInput';

const useStyles = makeStyles({
  typographyLabel: {
    fontWeight: '400',
    fontSize: '0.75rem',
    margin: '1rem 0',
  },
  datePicker: {
    '& .MuiFormLabel-root': {
      backgroundColor: theme.palette.cyan,
      color: theme.palette.primary[500],
      padding: '0 0.25rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary[500],
      borderWidth: '2px',
    },
  },
  dateBoxButtons: isMobile => ({
    display: 'flex',
    justifyContent: isMobile ? 'center' : 'flex-end',
    marginTop: '1rem',
  }),
  dateButton: {
    textTransform: 'none',
    fontSize: '0.875rem',
  },
});

const QuestionInputDate = ({
  handleAnswerQuestion,
  setActiveStep,
  setAnswer,
  answer,
  setIsDisabled,
  question,
  dbAnswers,
  childrenAnswers,
  setChildrenAnswers,
}) => {
  const { isMobile } = useContext(TaskContext);
  const { children_questions: childrenQuestions } = question;
  const classes = useStyles(isMobile);
  const initialDate = dbAnswers?.length > 0 ? dbAnswers[0].date_answer : '';

  function handleOnClick() {
    handleAnswerQuestion(answer === '' ? new Date() : answer);
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setIsDisabled(false);
  }

  function handleCancelClick() {
    setAnswer(initialDate);
  }

  const handleOnChange = newValue => {
    setAnswer(dbShortDate(newValue));
  };

  const childQuestionProps = {
    setIsDisabled,
    childrenAnswers,
    setChildrenAnswers,
  };

  return (
    <Box width={!isMobile ? '40%' : '100%'}>
      <div>
        <Typography className={classes.typographyLabel}>{ENTER_DATE}</Typography>
        <DatePicker
          label="Date"
          format="MM/dd/yyyy"
          value={gridLocalShortDate(answer) || null}
          onChange={handleOnChange}
          inputVariant="outlined"
          className={classes.datePicker}
          fullWidth
        />
        <div className={classes.dateBoxButtons}>
          <Button className={classes.dateButton} color="primary" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button color="primary" className={classes.dateButton} onClick={handleOnClick} disabled={answer === ''}>
            Ok
          </Button>
        </div>
      </div>
      {childrenQuestions?.length > 0
        && childrenQuestions.map(question => (
          <ChildrenQuestionInput key={question.id} question={question} {...childQuestionProps} />
        ))}
    </Box>
  );
};

QuestionInputDate.propTypes = {
  handleAnswerQuestion: PropTypes.func,
  dbAnswers: PropTypes.array,
  setActiveStep: PropTypes.func,
  setAnswer: PropTypes.func,
  answer: PropTypes.string,
  setIsDisabled: PropTypes.func,
  question: PropTypes.object,
  childrenAnswers: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setChildrenAnswers: PropTypes.func,
};

export default QuestionInputDate;
