import React, { useMemo, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatNumbers } from 'utillities';
import NumberFormatWhileTyping from './NumberFormatWhileTyping';

const numberFormat = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

const NumberFormattedField = ({
  currencyCode,
  dbErrors,
  format,
  formErrors,
  id,
  label,
  name,
  onBlur,
  onChange,
  type,
  value,
  ...restProps
}) => {
  const inputRef = useRef();
  const [wasFocused, setWasFocused] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const errors = useMemo(() => {
    const tmpErrors = [];

    if (formErrors[name]) {
      tmpErrors.push(...formErrors[name]);
    }

    if (dbErrors[name]) {
      tmpErrors.push(formErrors[name]);
    }

    return tmpErrors.length ? tmpErrors.join('. ') : '';
  }, [formErrors, dbErrors, name]);

  const onFocus = () => {
    setWasFocused(true);
    setIsEditing(true);
  };

  const handleOnBlur = () => {
    setIsEditing(false);
    onBlur?.();
  };

  const formatNumber = number =>
    formatNumbers({
      currencyCode,
      format: { ...numberFormat, style: format === 'currency' ? 'currency' : 'decimal' },
      value: number,
    });

  return (
    <TextField
      autoComplete="off"
      error={!isEditing && wasFocused && !!errors}
      helperText={!isEditing && wasFocused && errors}
      id={id}
      InputProps={{ inputComponent: NumberFormatWhileTyping }}
      label={label}
      name={name}
      onBlur={handleOnBlur}
      onChange={onChange}
      onFocus={onFocus}
      ref={inputRef}
      type="text"
      value={formatNumber(value)}
      {...restProps}
    />
  );
};

NumberFormattedField.defaultProps = {
  dbErrors: {},
  formErrors: {},
};

NumberFormattedField.propTypes = {
  currencyCode: PropTypes.string,
  dbErrors: PropTypes.shape({}),
  format: PropTypes.string,
  formErrors: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default NumberFormattedField;
