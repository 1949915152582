export const getScalarOpinion = (companyId: number) => `/api/dashboard/company/${companyId}`;
export const findScalarOpinion = (dashboardId: number) => `/api/dashboard/${dashboardId}`;
export const getGpcComps = (dashboardId: number) => `/api/dashboard/${dashboardId}/gpc-comps`;
export const getCompanyRequestedInformation = (companyId: number) =>
  `/api/dashboard/company/${companyId}/requested-information`;
export const createInformationRequest = (companyId: number) =>
  `/api/dashboard/company/${companyId}/create-information-request/`;
export const getCurrentInformationRequests = (measurementDateId: number) =>
  `/api/dashboard/current-information-requests/${measurementDateId}`;
export const modifyExistingRequest = () => '/api/dashboard/company/modify-existing-request/';
export const createRequestedDocuments = () => '/api/dashboard/company/create-requested-documents/';
export const createQuestions = () => '/api/dashboard/company/create-questions/';
export const retrieveUpdateDestroyCompanyRequestedDocument = (requestedDocumentId: number) =>
  `/api/dashboard/companies/company-requested-document/${requestedDocumentId}`;
export const retrieveUpdateDestroyCompanyQuestion = (questionId: number) =>
  `/api/dashboard/companies/company-question/${questionId}`;

export default {
  getScalarOpinion,
  findScalarOpinion,
  getGpcComps,
  getCompanyRequestedInformation,
  createInformationRequest,
  getCurrentInformationRequests,
  modifyExistingRequest,
  createRequestedDocuments,
  createQuestions,
  retrieveUpdateDestroyCompanyRequestedDocument,
  retrieveUpdateDestroyCompanyQuestion,
};
