import { CONVERTIBLE_NOTES, UNISSUED_OPTIONS } from 'common/constants/securityTypes';

const getValidSecurities = captableObject => {
  if (typeof captableObject !== 'object' || !Object.keys(captableObject).includes('securities')) {
    return [];
  }

  return captableObject.securities.filter(s => ![UNISSUED_OPTIONS, CONVERTIBLE_NOTES].includes(s.security_type));
};

export default getValidSecurities;
