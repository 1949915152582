import React from 'react';
import { Grid, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';

const BackupCodesContainer = props => {
  const { children, handleClose, isModal = false, openModal } = props;

  if (isModal) {
    return (
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        {children}
      </Modal>
    );
  }

  return <Grid container>{children}</Grid>;
};

BackupCodesContainer.propTypes = {
  children: PropTypes.element,
  handleClose: PropTypes.func,
  isModal: PropTypes.bool,
  openModal: PropTypes.bool,
};

export default BackupCodesContainer;
