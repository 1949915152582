import {
  EXTERNAL_VALUATION_SPREADSHEET_COLUMN_NAME,
  EXTERNAL_VALUATION_SPREADSHEET_COLUMN_NAME_PLURAL,
  EXTERNAL_VALUATION_SPREADSHEET_TABLE,
  EXTERNAL_VALUATION_SPREADSHEET_TITLE,
} from 'pages/ValuationsAllocation/common/constants/externalValuation/externalValuation';

export const EXTERNAL_VALUATION_SPREADSHEET_TABLE_TERMS = Object.freeze({
  columnName: EXTERNAL_VALUATION_SPREADSHEET_COLUMN_NAME,
  pluralColumnName: EXTERNAL_VALUATION_SPREADSHEET_COLUMN_NAME_PLURAL,
  tableName: EXTERNAL_VALUATION_SPREADSHEET_TITLE,
  tableSlug: EXTERNAL_VALUATION_SPREADSHEET_TABLE,
});
