import React, { useRef, useState } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add as AddIcon, ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { HISTORICAL, PROJECTED } from 'common/constants/financials';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    '& .MuiButtonGroup-groupedHorizontal': {
      borderColor: theme.palette.primary[200],
      '&:hover': {
        borderColor: theme.palette.primary[500],
      },
    },
    borderRadius: '2.688rem',
    marginTop: '1.5em',
    position: 'fixed',
    right: '2rem',
    bottom: '2rem',
    zIndex: 150,
  },
  baseButtonStyle: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary[500],
    width: 'fit-content',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: theme.palette.white,
    },
  },
}));

const FinancialsButtons = ({ addFinancialYear, isDisabled }) => {
  const classes = useStyles();
  const [isGroupExpanded, setIsGroupExpanded] = useState(false);
  const yearsRef = useRef(null);

  const Icon = () => <AddIcon fontSize="small" style={{ marginRight: '0.5rem' }} />;

  return (
    <ButtonGroup color="primary" variant="outlined" aria-label="split button" className={classes.buttonGroup}>
      <Button
        className={classes.baseButtonStyle}
        id="financials-add-historical-year-btn"
        disabled={isDisabled}
        onClick={() =>
          addFinancialYear({
            isPrevYear: false,
          })
        }
        style={{
          borderBottomLeftRadius: '2.688rem',
          borderTopLeftRadius: '2.688rem',
        }}>
        <Icon />
        {PROJECTED}
      </Button>
      <Button
        ref={yearsRef}
        size="small"
        aria-label="select year type"
        disabled={isDisabled}
        aria-haspopup="menu"
        aria-controls={() => {
          if (isGroupExpanded) return 'split-button-menu';
          return undefined;
        }}
        aria-expanded={() => {
          if (isGroupExpanded) return 'true';
          return undefined;
        }}
        className={classes.baseButtonStyle}
        style={{
          borderBottomRightRadius: '2.688rem',
          borderTopRightRadius: '2.688rem',
        }}
        onClick={() => setIsGroupExpanded(!isGroupExpanded)}>
        {isGroupExpanded ? <ArrowDropDownIcon id="icon-button-down" /> : <ArrowDropUpIcon id="icon-button-up" />}
      </Button>
      {isGroupExpanded && (
        <Button
          className={classes.baseButtonStyle}
          style={{
            position: 'fixed',
            right: '2rem',
            bottom: '5.5rem',
            borderRadius: 'inherit',
          }}
          id="financials-add-previous-year-btn"
          onClick={() =>
            addFinancialYear({
              isPrevYear: true,
            })
          }>
          <Icon />
          {HISTORICAL}
        </Button>
      )}
    </ButtonGroup>
  );
};

FinancialsButtons.propTypes = {
  addFinancialYear: PropTypes.func.isRequired,
};

export default FinancialsButtons;
