import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import reverseParser from 'pages/Valuations/approaches/discountCashFlow/utilities/reverseParser';
import { getWaccDataApproach } from 'pages/Valuations/util/util';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import parser from './config/parser';
import getRowConfig from './config/rowConfig';

export const parseDataToCols = ({ rowConfig, waccData }) => [
  rowConfig.reduce((col, row) => {
    // eslint-disable-next-line no-param-reassign
    col[row.alias] = waccData[row.alias];
    return col;
  }, {}),
];

const createDCFWACCData = ({ approach, dcfProps, dcfAttributes, isDisabled }) => {
  const { cashTaxRate } = dcfProps;
  const name = getApproachTableName({ approach, tableSuffix: 'wacc' });

  const waccData = getWaccDataApproach(approach, 'valuations_approach_dcf');

  const rowConfig = getRowConfig({
    cashTaxRate,
    isDisabled,
  });

  const columns = parseDataToCols({ rowConfig, waccData });

  return new SpreadsheetConfig({
    name,
    tableData: { approach },
    rowConfig,
    columns,
    parser,
    reverseParser,
    showToolbar: true,
    hasColTitle: true,
    showTotalColumn: false,
    tableTerms: {
      tableSlug: 'wacc-table',
    },
    fieldAttributes: dcfAttributes,
  });
};

export default createDCFWACCData;
