import { currencyFormat } from 'common/formats/formats';
import { Security } from 'dashboard409a/services/types';
import { RowConfigProps } from '../types';

export const TABLE_TITLE = 'Security';

export const TOTAL_ALIAS = 'total';
export const TOTAL_TITLE = 'Totals';

const baseCurrencyFormat = currencyFormat({ fractionDigits: 0 });

const getSecuritiesRows = (securities: Security[]) =>
  securities.length > 0
    ? securities.map((security: Security) => ({
      value: security.name,
      alias: `security-${security.id}`,
      readOnly: true,
      gridType: 'number',
      format: baseCurrencyFormat,
      allowNegativeValue: true,
    }))
    : [];

const getTotalExpr = (securities: Security[]) =>
  securities.length > 0 ? `=SUM(${securities.map((_, index) => `A${index + 2}`).join(',')})` : '';

const rowConfig = ({ securities }: RowConfigProps) => [
  {
    className: 'table-header trends-table-header division-bottom-only',
    value: TABLE_TITLE,
    alias: 'title',
    readOnly: true,
    gridType: 'string',
  },
  ...getSecuritiesRows(securities),
  {
    value: TOTAL_TITLE,
    alias: TOTAL_ALIAS,
    readOnly: true,
    gridType: 'number',
    format: baseCurrencyFormat,
    expr: getTotalExpr(securities),
    allowNegativeValue: true,
    className: 'row-label-title division-top-only',
  },
];

export default rowConfig;
