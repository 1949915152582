/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import DCFApproachContext from 'pages/Valuations/approaches/discountCashFlow/context/DCFApproachContext';
import { TERMINAL_VALUE, TERMINAL_VALUE_OPTIONS } from 'pages/Valuations/util/constants';

const tvtSpreadSheetKey = {
  [TERMINAL_VALUE_OPTIONS.PERPETUITY_GROWTH]: 'dcfTVTPG',
  [TERMINAL_VALUE_OPTIONS.H_MODEL]: 'dcfTVTHM',
  [TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE]: 'dcfTVTRM',
  [TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE]: 'dcfTVTEM',
  [TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE]: 'dcfTVTREM',
};

const DCFTerminalValueTable = ({
  format,
  formatDispatch,
  spreadsheets,
  onChange: onWorkbookChange,
  workbook,
  terminalValue,
}) => {
  const initialDcfTvtProps = tvtSpreadSheetKey[terminalValue || TERMINAL_VALUE_OPTIONS.PERPETUITY_GROWTH];
  const [dcfTVTProps, setDcfTVTProps] = useState(spreadsheets[initialDcfTvtProps]);
  const { setCurrentTerminalValue, currentTerminalValue } = useContext(DCFApproachContext);
  const changeTvtType = useCallback(
    ({ tvtType }) => {
      const newSpreadsheetConfigs = spreadsheets[tvtSpreadSheetKey[tvtType]];
      Object.values(tvtSpreadSheetKey).forEach(key => {
        spreadsheets[key].columns[0].terminal_value = tvtType;
      });
      setCurrentTerminalValue(newSpreadsheetConfigs);
    },
    [setCurrentTerminalValue, spreadsheets]
  );

  useEffect(() => {
    if (currentTerminalValue) {
      setDcfTVTProps(currentTerminalValue);
    }
  }, [currentTerminalValue]);

  const onChange = useCallback(
    (cell, expr) => {
      onWorkbookChange(cell, expr);

      if (cell.alias === TERMINAL_VALUE) {
        changeTvtType({ tvtType: expr });
      }
    },
    [changeTvtType, onWorkbookChange]
  );

  return (
    <div>
      <ScalarSpreadsheet
        {...dcfTVTProps}
        key={dcfTVTProps.name}
        onChange={onChange}
        sheet={dcfTVTProps}
        workbook={workbook}
        format={format}
        formatDispatch={formatDispatch}
      />
    </div>
  );
};

DCFTerminalValueTable.propTypes = {
  name: PropTypes.string,
  spreadsheets: PropTypes.object,
  onChange: PropTypes.func,
  workbook: PropTypes.object,
  setEnterpriseValueMap: PropTypes.func,
  enterpriseValueMap: PropTypes.object,
  format: PropTypes.object,
  formatDispatch: PropTypes.func,
  terminalValue: PropTypes.string,
};

export default DCFTerminalValueTable;
