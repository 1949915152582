/* eslint-disable import/no-unresolved */
import React, { useContext } from 'react';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { CustomSelect } from 'components';
import SpreadsheetContext from 'components/Spreadsheet/context/SpreadsheetContext';
import withTooltip from 'components/WithTooltip';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
});

const GridSelect = ({ cell, options }) => {
  const classes = useStyles();
  const { onCellsChanged } = useContext(SpreadsheetContext);

  const handleChange = ({ target: { value } }) => {
    const newValue = value.toString();
    onCellsChanged([{ cell, value: newValue }]);
  };

  return (
    <FormControl className={classes.root}>
      <CustomSelect
        id={`grid-select-${cell.key}`}
        value={cell.value}
        options={options}
        onChange={handleChange}
        onEmptyValue="SELECT OPTION"
        disabled={cell.readOnly}
      />
    </FormControl>
  );
};

GridSelect.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
};

GridSelect.propTypes = {
  cell: PropTypes.object,
  options: PropTypes.array.isRequired,
};

export default withTooltip(GridSelect);
