import { COMMON_STOCK, OPTION, PREFERRED_STOCK, UNISSUED_OPTIONS, WARRANT } from 'common/constants/securityTypes';
import { CURRENT_VALUE, FUTURE_EXIT } from './allocations';

const securityTypes = {
  Preferred_Stock: PREFERRED_STOCK,
  Common_Stock: COMMON_STOCK,
  Warrant: WARRANT,
  Option: OPTION,
  Unissued_Options: UNISSUED_OPTIONS,
};

const scenarioTypes = {
  Future_Exit: FUTURE_EXIT,
  Current_Value: CURRENT_VALUE,
};

const cashOrPik = {
  Cash: 0,
  Pik: 1,
};

const YesOrNo = {
  N: 0,
  Y: 1,
};

export default {
  ...securityTypes,
  ...scenarioTypes,
  ...cashOrPik,
  ...YesOrNo,
};
