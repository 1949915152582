import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { DROPDOWN_TYPE_LABEL, QUESTION_TYPE_CHOICES } from 'common/constants/firms';
import { ExtraActionsMenu, FormDialog } from 'components';
import CustomPrimaryButton from 'components/CustomPrimaryButton/CustomPrimaryButton';
import useRequestDocsAndQuestions from 'services/hooks/useRequestDocsAndQuestions/useRequestDocsAndQuestions';
import theme from 'theme';
import QuestionContent from './RequestItemModalContent/QuestionContent';
import RequestItemModalContent from './RequestItemModalContent/RequestItemModalContent';

const useStyles = makeStyles({
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
});

const RequestContentListOfTasks = ({
  isDocumentRequest,
  tasks,
  firmId,
  companyId,
  setUpdateTaskList,
  isInitialSetup,
  primaryInformationRequestId,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [isMaxCharactersError, setIsMaxCharactersError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  let formInitialValues = {
    name: '',
    description: '',
  };

  if (!isDocumentRequest) {
    formInitialValues = {
      ...formInitialValues,
      children_questions: [],
      options: [],
      question_type: '',
    };
  }
  const [formValues, setFormValues] = useState(formInitialValues);

  const isNameInvalid = !formValues.name || isMaxCharactersError;
  const isQuestionTypeInvalid = isUndefined(formValues.question_type) || formValues.question_type === '';
  const disableCreateButton = isDocumentRequest ? isNameInvalid : isNameInvalid || isQuestionTypeInvalid;
  const disabledButtonTooltip = isDocumentRequest
    ? 'Document name is required'
    : 'Question and answer type are required';

  const dropdownTypeValue = QUESTION_TYPE_CHOICES.find(choice => choice.label === DROPDOWN_TYPE_LABEL).value;
  const { handleRequestNewItem, handleUpdateTask, handleDeleteRequestedItem, handleCancel }
    = useRequestDocsAndQuestions({
      formInitialValues,
      formValues,
      isDocumentRequest,
      firmId,
      companyId,
      taskToDelete,
      setUpdateTaskList,
      setOpenDeleteDialog,
      setOpen,
      setIsEdit,
      setFormValues,
      isInitialSetup,
      primaryInformationRequestId,
    });

  const editTask = useCallback(
    task => {
      const updatedTask = { ...task };

      if (!isDocumentRequest) {
        const questionTypeChoice = QUESTION_TYPE_CHOICES.find(choice => choice.label === task.question_type);
        updatedTask.question_type = questionTypeChoice ? questionTypeChoice.value : task.question_type;
      }

      setFormValues(updatedTask);
      setIsEdit(true);
      setOpen(true);
    },
    [isDocumentRequest]
  );

  const deleteTask = useCallback(task => {
    setOpenDeleteDialog(true);
    setTaskToDelete(task);
  }, []);

  const options = useMemo(
    () => [
      {
        label: 'Edit',
        callback: editTask,
        isActive: true,
        icon: <EditIcon />,
      },
      {
        label: 'Delete',
        callback: deleteTask,
        isActive: true,
        icon: <DeleteIcon />,
      },
    ],
    [deleteTask, editTask]
  );

  return (
    <>
      <Box margin="2rem 0">
        <div>
          {tasks.map(task => (
            <Box
              key={task.id}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{
                border: `1px solid ${theme.palette.gray[300]}`,
                borderRadius: '4px',
                padding: '0.5rem',
                margin: '0.5rem 0',
              }}>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: '0.875rem',
                  color: theme.palette.black,
                }}>
                {task.name}
              </Typography>
              <ExtraActionsMenu options={options} dotsColor={theme.palette.gray[300]} singleItem={task} />
            </Box>
          ))}
        </div>
        <Button
          fullWidth
          variant="outlined"
          style={{
            borderRadius: '4px',
            padding: '0.5rem 1rem',
            margin: '0.5rem 0',
            backgroundColor: theme.palette.gray[200],
          }}
          onClick={() => setOpen(true)}>
          <AddIcon
            style={{
              color: theme.palette.black,
            }}
          />
        </Button>
      </Box>
      <FormDialog
        open={open}
        onClose={handleCancel}
        title={`${isEdit ? 'UPDATE' : 'CREATE NEW'} ${isDocumentRequest ? 'DOCUMENT REQUEST' : 'QUESTION'}`}
        disableTitleClose={false}
        showSaveButton={false}
        showCustomActionButton
        customCancelButton={
          <CustomPrimaryButton onClick={handleCancel} variant="outlined">
            Cancel
          </CustomPrimaryButton>
        }
        customButton={
          <CustomPrimaryButton
            onClick={isEdit ? handleUpdateTask : handleRequestNewItem}
            variant="contained"
            disabled={disableCreateButton}
            tooltip={disableCreateButton ? disabledButtonTooltip : ''}>
            {`${isEdit ? 'UPDATE' : 'CREATE'} ${isDocumentRequest ? 'DOCUMENT' : 'QUESTION'}`}
          </CustomPrimaryButton>
        }>
        <RequestItemModalContent
          isDocumentRequest={isDocumentRequest}
          formValues={formValues}
          setFormValues={setFormValues}
          hideDescription={isInitialSetup}
          setIsMaxCharactersError={setIsMaxCharactersError}>
          {isDocumentRequest ? null : (
            <QuestionContent
              formValues={formValues}
              setFormValues={setFormValues}
              setIsMaxCharactersError={setIsMaxCharactersError}
              dropdownTypeValue={dropdownTypeValue}
              isEdit={isEdit}
            />
          )}
        </RequestItemModalContent>
      </FormDialog>
      <FormDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        title={`DELETE ${isDocumentRequest ? 'DOCUMENT REQUEST' : 'QUESTION'}`}
        showSaveButton={false}
        showCustomActionButton
        customCancelButton={
          <CustomPrimaryButton onClick={() => setOpenDeleteDialog(false)} variant="outlined">
            Cancel
          </CustomPrimaryButton>
        }
        customButton={
          <CustomPrimaryButton onClick={handleDeleteRequestedItem} variant="contained" className={classes.deleteButton}>
            Delete
          </CustomPrimaryButton>
        }>
        <Typography>
          {`${isDocumentRequest ? 'Document request' : 'Question'} to delete:`} <strong>{taskToDelete?.name}</strong>
        </Typography>
      </FormDialog>
    </>
  );
};

RequestContentListOfTasks.propTypes = {
  isDocumentRequest: PropTypes.bool.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  firmId: PropTypes.number,
  companyId: PropTypes.number,
  setUpdateTaskList: PropTypes.func.isRequired,
  primaryInformationRequestId: PropTypes.number,
  isInitialSetup: PropTypes.bool,
};

export default RequestContentListOfTasks;
