import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CONNECTORS } from 'common/constants/process-management';

const NameLetters = ({ name, size }) => {
  const fontSize = {
    small: '1rem',
    medium: '1.25rem',
    large: '1.5rem',
  };

  const getInitials = nameParam => {
    const tmpNames = nameParam.split(' ').filter(n => !CONNECTORS.includes(n.toLowerCase()));

    // no more than 3 initials
    return tmpNames.length > 3
      ? `${tmpNames[0].charAt(0)}${tmpNames[1].charAt(0)}${tmpNames[2].charAt(0)}`
      : tmpNames.map(n => n.charAt(0)).join('');
  };

  return (
    <Typography
      style={{
        color: 'white',
        fontWeight: 600,
        fontSize: fontSize[size],
        lineHeight: '1.5rem',
        textAlign: 'center',
        marginTop: '1.5rem',
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg)',
        textTransform: 'uppercase',
        cursor: 'default',
      }}>
      {getInitials(name)}
    </Typography>
  );
};

NameLetters.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default NameLetters;
