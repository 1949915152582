import { getPublicCompsByDate } from 'common/endpoints/dashboard';
import BaseServiceRetryWrapper from 'services/baseRetry';

class DashboardService extends BaseServiceRetryWrapper {
  getPublicCompsByDate = (date, identifiers, currency, firmId = 'USD', identifiersAsCapIqIds) =>
    this.post(`${getPublicCompsByDate}`, {
      date,
      identifiers,
      currency,
      id: firmId,
      identifiers_as_cap_iq_id: identifiersAsCapIqIds,
    });
}

export default DashboardService;
