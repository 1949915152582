import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    paperButton: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: theme.spacing(4),
      marginBottom: theme.spacing(1.75),
      textTransform: 'uppercase',
      width: 'fit-content',

      '&:hover': {
        backgroundColor: theme.palette.primary.extraLight,
      },
    },
  })
);

export default useStyles;
