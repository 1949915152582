import { YEARFRAC } from 'common/formulas/math.js';

const dividendsPerShare = (hasCumulativeDividends, purchased, hasCompounding, security, selectedMeasurementDate) => {
  const dividendsAux = [];
  let result;

  const rows = purchased || [];
  rows.forEach(row => {
    if (hasCompounding) {
      result
        = security?.issue_price
          * (1 + security?.dividend_rate / security?.compounding_period)
            ** (YEARFRAC(row.date, selectedMeasurementDate, 3) * security?.compounding_period)
        - security?.issue_price;
    } else {
      result = security?.issue_price * security?.dividend_rate * YEARFRAC(row.date, selectedMeasurementDate, 3);
    }

    const dividendsPerShare = hasCumulativeDividends ? result : 0;
    dividendsAux.push(dividendsPerShare);
  });

  return dividendsAux;
};

export default dividendsPerShare;
