/**
 * NotesNotAvailable Component
 *
 * @component
 * @description This component displays a message when the page has not yet been saved, therefore the save notes feature is not available.
 *
 */

import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { NOTES_TITLE, SAVE_PAGE_TO_ADD_NOTES } from 'common/constants/notes';
import useStyles from './styles';

const NotesNotAvailable = () => {
  const classes = useStyles();
  return (
    <Accordion className={classes.boxContainer}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon className={classes.expandIcon} />}
        aria-controls="notes-widget-content"
        id="notes-widget-header">
        <Typography variant="overline" className={classes.notesContainerTitle}>
          {NOTES_TITLE}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.notesNotAvialableContainerDetails}>
        <Typography align="center" className={classes.notesEmpty}>
          {SAVE_PAGE_TO_ADD_NOTES}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default NotesNotAvailable;
