import { createStyles, makeStyles } from '@material-ui/styles';
import { ALLOCATIONS_DRAFT, ALLOCATIONS_FINAL, ALLOCATIONS_PUBLISHED } from 'common/constants/allocations';
import { ITheme } from 'theme/types';
import { ALLOCATION_STATUS } from './common/constants';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    badge: {
      backgroundColor: theme.palette.black,
      borderRadius: theme.spacing(0.8),
      display: 'inline-block',
      margin: `0 ${theme.spacing(1)}px`,
      padding: `${theme.spacing(0.3)}px ${theme.spacing(1)}px`,
      textAlign: 'center',

      [`&.${ALLOCATION_STATUS[ALLOCATIONS_DRAFT]}`]: {
        backgroundColor: theme.palette.disabled,
      },

      [`&.${ALLOCATION_STATUS[ALLOCATIONS_PUBLISHED]}`]: {
        backgroundColor: theme.palette.primary[600],
      },

      [`&.${ALLOCATION_STATUS[ALLOCATIONS_FINAL]}`]: {
        backgroundColor: theme.palette.error.main,
      },
      '&.ready-for-audit': {
        backgroundColor: theme.palette.primary[500],
      },
    },
    title: {
      color: theme.palette.white,
      fontSize: theme.spacing(1.5),
      lineHeight: 'normal',
      margin: 0,
      textTransform: 'uppercase',

      [`&.${ALLOCATION_STATUS[ALLOCATIONS_DRAFT]}`]: {
        color: theme.palette.gray[400],
      },
    },
  })
);

export default useStyles;
