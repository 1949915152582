/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */

// #region dependencies
import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { GridSkeleton } from 'components/Grid';
import useAllocationPage from 'pages/Allocation/hooks/useAllocationPage';
import useLoadAllocationData from 'pages/Allocation/hooks/useLoadAllocationData';
import Allocation from './allocations/Allocations';
import { AllocationDialog } from '../../layouts/Main/components';

const EditButton = ({ onClose }) => (
  <Tooltip title="Edit" arrow>
    <IconButton onClick={onClose} size="small">
      <EditIcon color="primary" aria-label="edit" style={{ fontSize: '1.2rem' }} />
    </IconButton>
  </Tooltip>
);

EditButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const AllocationView = () => {
  const {
    allocationInfo,
    selectedMeasurementDate,
    MDDialogue,
    isForbidden,
    getVersions,
    dialogProps,
    versionName,
    ltmData,
    ntmData,
    scenarios,
    storedWSV,
    primaryCaptable,
    capTableList,
    multiples,
    setAllocationInfo,
    valuationInfo,
  } = useLoadAllocationData();

  const { identifier } = useAllocationPage({
    isForbidden,
    versionName,
    dialogProps,
  });

  // this way we make sure that the Allocation component will always have scenarios defined in its first render
  // (end goal is to reduce derived state)
  if (isUndefined(scenarios) || isUndefined(storedWSV)) return <GridSkeleton />;

  return (
    <>
      <Allocation
        allocationInfo={allocationInfo}
        scenarios={scenarios}
        weightedSV={storedWSV}
        getVersions={getVersions}
        isForbidden={isForbidden}
        ltmData={ltmData}
        ntmData={ntmData}
        selectedMeasurementDate={selectedMeasurementDate}
        primaryCaptable={primaryCaptable}
        capTableList={capTableList}
        multiples={multiples}
        identifier={identifier}
        setAllocationInfo={setAllocationInfo}
        valuationInfo={valuationInfo}
      />

      {dialogProps?.open && (
        <AllocationDialog {...dialogProps} setAllocationInfo={setAllocationInfo} allocationInfo={allocationInfo} />
      )}
      <MDDialogue />
    </>
  );
};

export default AllocationView;
