import React from 'react';
import GridRowLabel from 'components/Grid/GridRowLabel';
import { BACKSOLVE_TABLE_NAMES, TABLE_HEADER_CONFIG } from 'pages/Valuations/approaches/backsolveApproach/constants';
import { MARKET_ADJUSTMENT_TITLE } from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentTable/util/constants';
import { getApproachTableName } from '../../../../../ValuationsAllocation/util';

export default async ({ columns, rowConfig, tableData }) => {
  let cells = {};
  const getExpr = (expr, columnLegend) => {
    if (expr) {
      return expr.replace(/@/g, `${columnLegend}`);
    }
    return '';
  };

  cells.A1 = {
    ...TABLE_HEADER_CONFIG,
    value: MARKET_ADJUSTMENT_TITLE,
    expr: MARKET_ADJUSTMENT_TITLE,
    largeHeader: true,
    rowNumber: 1,
    colSpan: 2,
    checkPrevCol: true,
    valueViewer: props => <GridRowLabel {...props} unitsFormatter currencyFormatter formatterCell />,
  };

  const [, ...actualRowConfig] = rowConfig;
  actualRowConfig.forEach((row, rowIdx) => {
    const rowNumber = rowIdx + 2;
    const columnLegend = 'A';
    const key = columnLegend + rowNumber;

    cells = {
      ...cells,
      [key]: {
        ...row,
        dialog: undefined,
        className: 'name-table',
        key,
        expr: undefined,
        readOnly: true,
        columnLegend,
        dataEditor: undefined,
        valueViewer: undefined,
      },
    };
  });

  actualRowConfig.forEach((row, rowIdx) => {
    const rowNumber = rowIdx + 2;
    const col = columns[1];
    const columnLegend = 'B';
    const key = columnLegend + rowNumber;
    const expr = getExpr(row.expr, columnLegend);
    const value = col[row.alias] || row.defaultValue;

    cells = {
      ...cells,
      [key]: {
        ...row,
        expr,
        key,
        columnLegend,
        value,
        className: '',
      },
    };
  });

  const summaryTableName = getApproachTableName({
    approach: tableData.approach,
    tableSuffix: BACKSOLVE_TABLE_NAMES.BACKSOLVE_SUMMARY,
  });
  const adjustedEquityValueCell = cells.B8;
  const adjustedEnterpriseValueCell = cells.B7;
  const { backsolveSummarySheetName } = tableData;
  if (cells.B6.value === 'Enterprise Value') {
    const adjustedEnterpriseExpr = `=${backsolveSummarySheetName}.summary_enterprise_value + ${backsolveSummarySheetName}.summary_enterprise_value * MarketAdjustment`;
    const adjustedEquityExpr = `=EQUITY_VALUE(${adjustedEnterpriseValueCell.key}, summary.less_cash, summary.plus_debt)`;
    cells.B7 = {
      ...cells.B7,
      expr: adjustedEnterpriseExpr,
      value: adjustedEnterpriseExpr,
    };
    cells.B8 = {
      ...cells.B8,
      expr: adjustedEquityExpr,
      value: adjustedEquityExpr,
    };
  } else if (cells.B6.value === 'Equity Value') {
    const adjustedEquityExpr = `=${summaryTableName}.implied_equity_value + ${summaryTableName}.implied_equity_value * MarketAdjustment`;
    const adjustedEnterpriseExpr = `=ENTERPRISE_VALUE(${adjustedEquityValueCell.key}, summary.less_cash, summary.plus_debt)`;
    cells.B7 = {
      ...cells.B7,
      expr: adjustedEnterpriseExpr,
      value: adjustedEnterpriseExpr,
    };
    cells.B8 = {
      ...cells.B8,
      expr: adjustedEquityExpr,
      value: adjustedEquityExpr,
    };
  }
  return cells;
};
