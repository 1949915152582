import React, { useContext } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  FOR_ANY_FOLLOW_UPS_LOOK_FOR_COMMUNICATIONS_FROM,
  GO_TO_DOCUMENT_UPLOAD_TOOL,
  THANK_YOU_FOR_COMPLETING_ALL_QUESTIONS,
} from 'common/constants/process-management';
import { TaskContext } from 'context';

const useStyles = makeStyles({
  typographyDescription: isMobile => ({
    fontWeight: 500,
    fontSize: isMobile ? '0.9rem' : '1.125rem',
    textDecorationLine: 'underline',
    margin: '1rem 0',
  }),
});

const QuestionnaireCompleted = ({ taskData, typographyClassName }) => {
  const { isMobile, documentUrl } = useContext(TaskContext);
  const classes = useStyles(isMobile);

  return (
    <>
      <Typography align="center" className={typographyClassName}>
        {THANK_YOU_FOR_COMPLETING_ALL_QUESTIONS}
      </Typography>
      <Typography align="center" className={classes.typographyDescription}>
        {FOR_ANY_FOLLOW_UPS_LOOK_FOR_COMMUNICATIONS_FROM(taskData.firm.name)}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size={isMobile ? 'small' : 'medium'}
        style={{
          padding: isMobile ? '1rem 0.5rem' : '1rem 3.875rem',
          marginTop: '2rem',
          fontWeight: 400,
        }}
        component={Link}
        to={documentUrl}>
        {GO_TO_DOCUMENT_UPLOAD_TOOL}
      </Button>
    </>
  );
};

QuestionnaireCompleted.propTypes = {
  taskData: PropTypes.object,
  typographyClassName: PropTypes.string,
};
export default QuestionnaireCompleted;
