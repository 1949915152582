import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white,
    height: '100%',
    width: '100%',
  },
  // Left column
  contentImage: {
    zIndex: 0,
    position: 'absolute',
    width: '50%',
    height: '100%',
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  backImage: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  headerLogo: {
    zIndex: 1,
    padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
    borderBottom: `2px solid ${theme.palette.background.default}`,
  },
  quoteContainer: {
    zIndex: 1,
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',

    '&::before': {
      content: '""',
      display: 'inline-block',
      backgroundColor: theme.palette.white,
      width: '75px',
      height: '4px',
      marginBottom: theme.spacing(3),
      marginTop: `-${theme.spacing(10)}px`,
    },
    '& h1': {
      color: 'white !important',
      fontSize: '3rem',
      fontWeight: 800,
      lineHeight: 1.2,
      marginBottom: theme.spacing(4),
    },
    '& h2': {
      color: 'white !important',
      fontSize: '2rem',
      lineHeight: 1.2,
      marginBottom: theme.spacing(2),
    },
    '& p': {
      color: theme.palette.white,
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
  },
  // Right column
  formContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(8),
    flexDirection: 'column',
  },
  form: {
    width: '100%',
    maxWidth: 600,
    boxSizing: 'border-box',
    padding: theme.spacing(3),

    '& h2': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    '& .scar-textField': {
      marginBottom: theme.spacing(2),
    },
  },
  btn: {
    marginTop: theme.spacing(1),
  },
  linkContainer: {
    textAlign: 'center',
    marginTop: 20,
  },
  link: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    letterSpacing: 1,
  },
  content: {
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

export default useStyles;
