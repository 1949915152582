import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { FormControlLabel, Grid, Switch } from '@material-ui/core';
import { RenewalContainer } from './CustomComponets';
import { IRenewalToggleProps } from '../types';

const RenewalToggle: FC<IRenewalToggleProps> = ({ value, onChange }) => {
  const [checked, setChecked] = useState<boolean>(value);

  useEffect(() => {
    if (checked !== value) {
      setChecked(value);
    }
  }, [value, checked]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    // eslint-disable-next-line no-console
    onChange(isChecked).catch(ex => console.error(ex));
  };

  return (
    <Grid container item xs={12}>
      <RenewalContainer>
        <FormControlLabel
          control={<Switch checked={checked} onChange={handleChange} color="primary" />}
          label="Auto-Renew"
          labelPlacement="start"
        />
      </RenewalContainer>
    </Grid>
  );
};

export default RenewalToggle;
