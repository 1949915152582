/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormControlLabel, FormGroup, Switch, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { captableAction } from 'common/actions';
import { ERROR_403 } from 'common/config/api';
import { captableUrl } from 'common/config/urls';
import { DEFAULT_CURRENCY } from 'common/constants/cap-table';
import {
  BREAKPOINT_ANALYSIS_LOCKED_MESSAGE,
  CAP_TABLE_NEEDS_MORE_SECURITIES_TAGLINE,
  CAP_TABLE_NEEDS_MORE_SECURITIES_TITLE,
  FORBIDDEN_BP_ANALYSIS,
  GET_BP_ACCESS,
} from 'common/constants/cap-table/capTable';
import { CONVERTIBLE_NOTES, UNISSUED_OPTIONS } from 'common/constants/securityTypes';
import { useFormat } from 'common/hooks';
import { useStore } from 'common/store';
import { Alert, AllocationFinalMessage, MessageBox } from 'components';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import { GridSkeleton } from 'components/Grid';
import { LayoutContext } from 'context';
import GoToCapTable from 'pages/CapTable/cap-table/components/GoToCapTable';
import { CaptableHk } from 'services/hooks';
import { worldCurrencies } from 'utillities';
import CustomBreakpoints from './components/CustomBreakpoints/CustomBreakpoints';
import WaterfallBreakpoints from './components/WaterfallBreakpoints/WaterfallBreakpoints';
import replaceProRata from './utilities/replaceProRata';

const useStyles = makeStyles(() => ({
  useCustomBreakpoints: {
    paddingBottom: '1.5rem',
  },
  switchToggleLegend: {
    fontWeight: '700',
  },
}));
const BreakpointAnalysis = props => {
  const classes = useStyles();
  const [globalStore] = useStore();
  const { viewOnlyUser } = useContext(LayoutContext);
  const { firmSlugParam, companySlugParam } = useParams();
  const { isShowLoadingProgress } = globalStore;
  const captableInfo = globalStore.captableInfo || {};
  const [, setWaterfallTableData] = useState();
  const [waterfallColumns, setWaterfallColumns] = useState(null);
  const [customBreakpointColumns, setCustomBreakpointColumns] = useState([]);
  const [showSecurityTypeMsg, setSecurityTypeMsg] = useState(false);
  const [customBreakpoints, setCustomBreakpoints] = useState(captableInfo?.use_custom_breakpoints || false);
  const [isDisabled, setIsDisabled] = useState();
  const [isAllocationFinal, setIsAllocationFinal] = useState();

  const { useGetBreakpointAnalysis } = CaptableHk;
  const [updatedCaptableInfo, getCapTableBreakpoints] = useGetBreakpointAnalysis();
  const { selectedMeasurementDate, saveAsNewProforma } = props;
  const [format] = useFormat({ page: 'captable', units: REGULAR_UNIT });
  const { currency } = format;
  const { symbol: currencySymbol } = currency;

  const capTableURL = useMemo(() => captableUrl(firmSlugParam, companySlugParam), [firmSlugParam, companySlugParam]);

  const getAllocationStatus = async () => {
    try {
      const response = await captableAction.verifyIfHasFinalAllocation(captableInfo.id);
      setIsDisabled(!isEmpty(response) || viewOnlyUser);
      setIsAllocationFinal(!isEmpty(response));
    } catch (error) {
      setIsDisabled(false);
      setIsAllocationFinal(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (captableInfo.id && !isEmpty(captableInfo.securities) && isEmpty(updatedCaptableInfo)) {
      const optionConvertibleSecurities = captableInfo.securities.filter(s =>
        [UNISSUED_OPTIONS, CONVERTIBLE_NOTES].includes(s.security_type)
      );

      if (optionConvertibleSecurities.length < captableInfo.securities.length) {
        getCapTableBreakpoints(captableInfo.id);
      } else {
        setSecurityTypeMsg(true);
      }
    }
    if (captableInfo.id) {
      getAllocationStatus();
    }
  }, [captableInfo.id, viewOnlyUser]);

  useEffect(() => {
    if (!isEmpty(captableInfo)) {
      setCustomBreakpoints(captableInfo.use_custom_breakpoints);
      setCustomBreakpointColumns(null);
    }
  }, [captableInfo.id, captableInfo.use_custom_breakpoints]);

  const getColumnsData = (breakpointsData, setInitialValue = false) => {
    const getSerieData = serieItem => {
      if (!Number.isNaN(serieItem.value)) {
        return {
          ...serieItem,
          value: setInitialValue ? 0 : serieItem.value,
        };
      }
      return serieItem;
    };

    return breakpointsData.map(bp => ({
      ...bp,
      price: setInitialValue ? 0 : bp.price,
      series: bp.series.map(serie => ({
        ...getSerieData(serie),
        security: serie.security,
      })),
      range: bp.range.replaceAll(worldCurrencies[DEFAULT_CURRENCY], currencySymbol),
    }));
  };

  useEffect(() => {
    if (!isEmpty(captableInfo)) {
      if (captableInfo?.waterfall_breakpoints?.breakpoints) {
        const waterfallBreakpoints = captableInfo.waterfall_breakpoints.breakpoints;
        const tmpBreakpoints = getColumnsData(waterfallBreakpoints);
        setWaterfallColumns(tmpBreakpoints);
      } else {
        setWaterfallColumns(null);
      }
    }
  }, [captableInfo?.id]);

  useEffect(() => {
    if (!isEmpty(captableInfo) && customBreakpoints && !isEmpty(waterfallColumns)) {
      let tmpBreakpoints = null;
      if (captableInfo?.custom_breakpoints?.breakpoints) {
        const customBreakpointsData = captableInfo.custom_breakpoints.breakpoints;
        tmpBreakpoints = getColumnsData(customBreakpointsData);
        const lastBreakpoint = tmpBreakpoints[tmpBreakpoints.length - 1];
        const lastBreakpointSeries = lastBreakpoint.series;
        // Use current own percent as value for existing custom breakpoints when the final bp is PRO RATA
        lastBreakpoint.series = replaceProRata(lastBreakpointSeries, captableInfo.securities);
      } else {
        tmpBreakpoints = getColumnsData(waterfallColumns, true);
      }
      setCustomBreakpointColumns(tmpBreakpoints);
    } else {
      setCustomBreakpointColumns(null);
    }
  }, [captableInfo, customBreakpoints, waterfallColumns]);

  const breakPointAnalysisDetailTableProps = useMemo(
    () => ({
      data: updatedCaptableInfo,
      waterfallColumns,
      setTableData: setWaterfallTableData,
      format,
      hideGrid: customBreakpoints,
    }),
    [updatedCaptableInfo, waterfallColumns, format, customBreakpoints]
  );

  const customBreakpointsDetailTableProps = {
    captableId: captableInfo?.id,
    hideGrid: !customBreakpoints,
    customBreakpointColumns,
    selectedMeasurementDate,
    saveAsNewProforma,
    format,
    isAllocationFinal,
    isDisabled,
  };

  const handleChangeSwitch = () => setCustomBreakpoints(customBPs => !customBPs);

  if (isEqual(updatedCaptableInfo, ERROR_403)) {
    return <MessageBox title={FORBIDDEN_BP_ANALYSIS} tagline={GET_BP_ACCESS} fullWidth={false} />;
  }

  if (captableInfo?.id && (isEmpty(captableInfo.securities) || showSecurityTypeMsg)) {
    let title = 'The Cap Table is empty';
    let message = 'Please add a security first.';

    if (showSecurityTypeMsg) {
      title = CAP_TABLE_NEEDS_MORE_SECURITIES_TITLE;
      message = CAP_TABLE_NEEDS_MORE_SECURITIES_TAGLINE;
    }

    return (
      <MessageBox
        title={title}
        tagline={message}
        fullWidth={false}
        action={<GoToCapTable capTableURL={capTableURL} />}
      />
    );
  }

  if (!captableInfo?.id || isEmpty(updatedCaptableInfo)) {
    return <GridSkeleton />;
  }

  return (
    <>
      {/* Allocation Final Message */}
      {isAllocationFinal && isDisabled && (
        <AllocationFinalMessage
          tableTerms={{ tableName: 'Breakpoint Analysis' }}
          setIsDisabled={setIsDisabled}
          singleAlert
        />
      )}
      {isAllocationFinal && !isDisabled && <Alert isAlertVisible>{BREAKPOINT_ANALYSIS_LOCKED_MESSAGE}</Alert>}
      <FormGroup row className={classes.useCustomBreakpoints}>
        <FormControlLabel
          control={
            <Switch
              id="custom-breakpoints-switch"
              checked={customBreakpoints || false}
              onChange={handleChangeSwitch}
              name="useCustomBreakpoints"
              color="primary"
              disabled={isShowLoadingProgress || isDisabled}
              disableRipple
            />
          }
          label={
            <Typography variant="body2" color="textPrimary" className={classes.switchToggleLegend}>
              Use custom breakpoints?
            </Typography>
          }
        />
      </FormGroup>
      <CustomBreakpoints {...customBreakpointsDetailTableProps} />
      <WaterfallBreakpoints {...breakPointAnalysisDetailTableProps} />
    </>
  );
};

BreakpointAnalysis.propTypes = {
  selectedVersions: PropTypes.number,
  saveAsNewProforma: PropTypes.func,
  selectedMeasurementDate: PropTypes.object,
};

export default BreakpointAnalysis;
