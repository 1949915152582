/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import { isEmpty, isUndefined } from 'lodash';
import { GRAY_CELL } from 'common/constants/general';
import { GET_ENTER_DATA } from 'common/constants/inputs';
import { onCellsChanged, parseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import * as CONSTANTS from './constants';

const editableRows = [
  CONSTANTS.NOTE_NAME_ROW,
  CONSTANTS.ISSUANCE_DATE_ROW,
  CONSTANTS.MATURITY_DATE_ROW,
  CONSTANTS.NOTE_PRINCIPLE_AMOUNT_ROW,
  CONSTANTS.INTEREST_RATE_ROW,
  CONSTANTS.COMPOUNDING_ROW,
  CONSTANTS.COMPOUNDING_PERIODS_PER_YEAR_ROW,
  CONSTANTS.CONVERSION_DISCOUNT_ROW,
  CONSTANTS.CONVERSION_CAP_ROW,
  CONSTANTS.TERMS_ANYTIME_PRIOR_ROW,
  CONSTANTS.TERMS_AT_QUALIFIED_FINANCING_ROW,
  CONSTANTS.TERMS_AT_QUALIFIED_FINANCING_ROW,
  CONSTANTS.TERMS_AT_CHANGE_OF_CONTROL_ROW,
  CONSTANTS.TERMS_AT_MATURITY_ROW,
  CONSTANTS.EQUITY_CONVERSION_MODEL_ROW,
  CONSTANTS.LOAN_VALUE_ROW,
  CONSTANTS.CONVERSION_DATE_ROW,
  CONSTANTS.INTEREST_AT_MEASUREMENT_DATE_ROW,
  CONSTANTS.VALUE_AT_MEASUREMENT_DATE_ROW,
  CONSTANTS.VALUE_AT_CONVERSION_ROW,
  CONSTANTS.NEXT_ROUND_PREMONEY_ROW,
  CONSTANTS.CONVERSION_PRE_MONEY_ROW,
  CONSTANTS.PRE_MONEY_SHARE_COUNT_ROW,
  CONSTANTS.CONVERSION_PRICE_PER_SHARE_ROW,
  CONSTANTS.EXPECTED_SHARES_ROW,
];
const visibleRows = [...editableRows];

const getValue = (column, alias) => (!isUndefined(column[alias]) ? column[alias] : null);

const getCellValue = (type, value, row, alias) => {
  const currentCellValue = parseValue(value, type, null, null, row.dbType, true);
  const isConversionPreMoney = alias === CONSTANTS.CONVERSION_PRE_MONEY_ALIAS;
  if (type === 'string') {
    return isEmpty(currentCellValue) ? GET_ENTER_DATA(row.isRequired, row.enterDataMessage) : currentCellValue;
  }
  if (CONSTANTS.GREATER_THAN_ZERO_IF_PRESENT.includes(alias)) {
    return currentCellValue > 0 ? currentCellValue : null;
  }
  if (CONSTANTS.ROW_WITH_PAIRS.includes(alias) && isConversionPreMoney) {
    return currentCellValue > 0 ? currentCellValue : null;
  }
  return currentCellValue;
};

const getExpr = (cellExprStr, columnLegend, prevColumnLegend) => {
  if (cellExprStr) {
    let tmpExpr = cellExprStr.replace(/@PREVCOL/g, prevColumnLegend);
    tmpExpr = tmpExpr.replace(/@/g, columnLegend);
    return tmpExpr;
  }

  return '';
};

const getTooltipMessage = (isConversionPreMoney, isCellCalculated) =>
  !isConversionPreMoney && isCellCalculated ? [CONSTANTS.NEXT_ROUND_PREMONEY_TOOLTIP] : null;

const parser = async ({ columns, rowConfig }) => {
  const alphabet = alphabetGenerator([], columns.length);

  let cells = {};
  const changes = [];

  // Parse body cells
  columns.forEach((column, columnIndex) => {
    const prevColumnLegend = alphabet[Math.max(0, columnIndex - 1)];
    const columnLegend = alphabet[columnIndex];

    rowConfig.forEach((row, rowIndex) => {
      const { alias, gridType = null, expr: cellExpr, exprPair } = row;
      const rowNumber = rowIndex + 1;
      const key = columnLegend + rowNumber;
      const value = getValue(column, alias);
      let expr = getExpr(cellExpr, columnLegend, prevColumnLegend);
      let aditionalProps = {};
      const cellValue = getCellValue(gridType, value, row, alias);
      if (CONSTANTS.ROW_WITH_PAIRS.includes(alias)) {
        const isConversionPreMoney = alias === CONSTANTS.CONVERSION_PRE_MONEY_ALIAS;
        const statusKey = `${alias}_enabled`;
        const isCellCalculated = !column[statusKey];

        expr = isCellCalculated ? getExpr(exprPair, columnLegend, prevColumnLegend) : '';
        aditionalProps = {
          className: isCellCalculated ? GRAY_CELL : '',
          tooltipMessages: getTooltipMessage(isConversionPreMoney, isCellCalculated),
          isCellCalculated,
        };
      }

      const cell = {
        // Copy default props from the row config
        ...row,
        // Replace default props
        key,
        expr,
        value: cellValue,
        alias: alias || '',
        columnId: column.id,
        columnLegend,
        columnOrder: column.order,
        insideLedger: true,
        positionFixed: true,
        allowInsideLedger: true,
        ...aditionalProps,
      };

      if (columnIndex === 0) {
        const isNotVisibleRow = !visibleRows.includes(rowNumber);
        const hasNotDefaultValue = !cell.defaultValue;

        if (editableRows.includes(rowNumber)) {
          cell.readOnly = false;
        }

        if (isNotVisibleRow && hasNotDefaultValue) {
          cell.value = null;
          cell.format = null;
          cell.expr = null;
        }
      }
      cells = {
        ...cells,
        [key]: cell,
      };
    });
  });

  // Compute expressions
  cells = await onCellsChanged(changes, cells);
  return cells;
};

export default parser;
