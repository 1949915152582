import { useQuery } from '@tanstack/react-query';
import { hoursToMilliseconds } from 'date-fns';
import { isEmpty, isNil } from 'lodash';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import { RISK_FREE_RATES_QUERY_KEY } from 'common/constants/services/allocations';
import { useGetRiskFreeRateByMD } from 'services/hooks/allocations/useGetRiskFreeRateByMD';
import {
  GetRiskFreeRatesHook,
  GetRiskFreeRatesParams,
  GetRiskFreeRatesResponse,
  UseGetRiskFreeRatesParams,
} from './types';

const getRiskFreeRates = (params: GetRiskFreeRatesParams) => {
  const { fetchRiskFreeRateByMD, measurementDateId, OPMInputs } = params;

  if (isNil(measurementDateId)) return null;

  const result = OPMInputs?.reduce(async (accumulatorPromise, current) => {
    const accumulator = await accumulatorPromise;

    const { backsolveDate, maturity, scenarioIdOrRef } = current;

    try {
      const riskFreeRate = await fetchRiskFreeRateByMD({ backsolveDate, maturity, measurementDateId });

      if (isNil(riskFreeRate)) return accumulator;

      return {
        ...accumulator,
        [scenarioIdOrRef]: riskFreeRate,
      } as GetRiskFreeRatesResponse;
    } catch (error) {
      return accumulator;
    }
  }, Promise.resolve({} as GetRiskFreeRatesResponse));

  return result;
};

const useGetRiskFreeRates = (params: UseGetRiskFreeRatesParams) => {
  const { measurementDate, OPMInputs, shouldQueryAutomatically = true } = params;

  const measurementDateId = measurementDate?.id;

  const fetchRiskFreeRateByMD = useGetRiskFreeRateByMD();

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<GetRiskFreeRatesHook['data'], Error>({
    enabled: !isNil(measurementDateId) && !isEmpty(OPMInputs) && shouldQueryAutomatically,
    queryFn: () => getRiskFreeRates({ fetchRiskFreeRateByMD, measurementDateId, OPMInputs }),
    queryKey: [RISK_FREE_RATES_QUERY_KEY, OPMInputs, measurementDateId, fetchRiskFreeRateByMD],
    refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
    staleTime: hoursToMilliseconds(24),
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetRiskFreeRates;
