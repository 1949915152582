import { sortBy } from 'lodash';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import parseDatabaseValue from 'utillities/parseDatabaseValue';

const reverseParser = ({ cells, columns, allowEmptyValues, rowConfig, checkPairCells, fieldAttributes }) => {
  let tableColumns = [];

  const sortedColumns = sortBy(columns, ['order']);
  const alphabet = alphabetGenerator([], sortedColumns.length);

  sortedColumns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];

    // copy original values
    let tmpColumn = { ...column };

    // replace updated values
    rowConfig.forEach((cell, cellIndex) => {
      const key = columnLegend + (cellIndex + 1);
      const defaultValue = cell.defaultValue || null;
      const type = cell.dbType || null;
      const format = cell.format || null;
      const value = cells[key] ? cells[key].value : defaultValue;
      const { gridType } = cell;
      const decimalPlaces = fieldAttributes[cell.alias]?.decimal_places;

      if (cell.alias && cells[key]) {
        tmpColumn = {
          ...tmpColumn,
          [cell.alias]: parseDatabaseValue({
            type,
            value,
            defaultValue,
            format,
            allowEmptyValues,
            gridType,
            decimalPlaces,
          }),
        };
      }
      if (checkPairCells) {
        tmpColumn = checkPairCells(cell, key, tmpColumn);
      }
    });

    tableColumns = [...tableColumns, tmpColumn];
  });

  return tableColumns;
};

export default reverseParser;
