import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, orderBy } from 'lodash';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { MeasurementDateName } from 'api';
import { ERROR_403 } from 'common/config/api';
import { notFoundPath } from 'common/config/urls';
import { ALLOCATION_VERSION_FILTER, ALLOCATION_VERSION_FILTER_TITLE } from 'common/constants/pageFilters';
import { useStore } from 'common/store';
import { LayoutContextValues, SetPageFilters, UseStoreValues } from 'common/types/store';
import { UnsavedChangesValues } from 'common/types/unsavedChanges';
import LayoutContext from 'context/LayoutContext';
import UnsavedChanges from 'context/UnsavedChanges';
import Scalar from 'icons/Scalar';
import { useGetAllocationsVersionsByCompanyIdByDateId } from 'services/hooks/company';
import { useAllocationValuesStore } from 'store';
import { formatDateView, getArrayValue, isFalseStrict } from 'utillities';
import { AllocationVersionName } from './types';

const useAllocationVersionQueryString = (selectedMeasurementDate: MeasurementDateName) => {
  const [isForbidden, setIsForbidden] = useState(false);
  const [{ fundList, companyInfo }] = useStore() as unknown as UseStoreValues;

  const { showPageForm, togglePageForm, pageFilters, setPageFilters } = useContext(
    LayoutContext
  ) as unknown as LayoutContextValues<AllocationVersionName>;

  const { action: hasChanges, showDialog } = useContext(UnsavedChanges) as unknown as UnsavedChangesValues;

  const [selectedVersion, setSelectedVersion] = useState<AllocationVersionName | null>(null);
  const [allocationVersions, setAllocationVersions] = useState<AllocationVersionName[]>();
  const [matchedVersion, setMatchedVersion] = useState<AllocationVersionName | null>(null);
  const history = useHistory();
  const location = useLocation();

  const getAllocationVersions = useGetAllocationsVersionsByCompanyIdByDateId();

  // Allocation Values Store
  const setCurrentAllocationStatus = useAllocationValuesStore(state => state.setCurrentAllocationStatus);
  const setCurrentEquityValue = useAllocationValuesStore(state => state.setCurrentEquityValue);
  const setCurrentFirmTotal = useAllocationValuesStore(state => state.setCurrentFirmTotal);

  const defaultVersion = useMemo(() => {
    if (!isEmpty(allocationVersions)) {
      const finalVersion = allocationVersions?.find(v => v.is_final);
      const publishedVersion = allocationVersions?.find(v => v.is_published);
      return finalVersion || publishedVersion || allocationVersions?.[0];
    }
    return null;
  }, [allocationVersions]);

  const versionQueryString = useMemo(() => {
    const { version } = queryString.parse(location.search);
    return version;
  }, [location]);

  useEffect(() => {
    const getAllocationVersionsData = async () => {
      const versionsResult = await getAllocationVersions(companyInfo?.id, selectedMeasurementDate.id, true);
      if (versionsResult === ERROR_403) {
        setIsForbidden(true);
      } else if (versionsResult && versionsResult.length > 0 && versionsResult !== ERROR_403) {
        setAllocationVersions(versionsResult);
      }
    };
    if (companyInfo?.id === selectedMeasurementDate?.company_id && companyInfo?.id && selectedMeasurementDate?.id) {
      getAllocationVersionsData();
    }
  }, [companyInfo, getAllocationVersions, selectedMeasurementDate]);

  const versionName = useMemo(() => {
    if (!selectedVersion) {
      return '';
    }

    return `Version ${selectedVersion.version} - ${selectedVersion.name}`;
  }, [selectedVersion]);

  const matchVersion = useCallback(
    version =>
      allocationVersions?.find(item => item.slug === version || item.id?.toString() === version.toString()) || null,
    [allocationVersions]
  );

  useEffect(() => {
    const version = versionQueryString || defaultVersion?.id;
    if (version) {
      const matchedVersionRes = matchVersion(version);
      if (matchedVersionRes?.slug !== versionQueryString || matchedVersion?.slug !== matchedVersionRes?.slug) {
        setMatchedVersion(matchedVersionRes);
      }
    }
  }, [versionQueryString, defaultVersion, matchVersion, matchedVersion]);

  const parsedQuery = useMemo(() => queryString.parse(location.search), [location.search]);

  const setQueryString = useCallback(
    (paramName, newQueryString) => {
      parsedQuery[paramName] = newQueryString;
      history.replace({ pathname: location.pathname, search: queryString.stringify(parsedQuery) });
    },
    [history, location, parsedQuery]
  );

  const pushQueryString = useCallback(
    (paramName, newQueryString) => {
      if (newQueryString && paramName) {
        parsedQuery[paramName] = newQueryString;
        history.push({ pathname: location.pathname, search: queryString.stringify(parsedQuery) });
      }
    },
    [history, location.pathname, parsedQuery]
  );

  const setVersionQueryString = useCallback(
    mv => {
      if (
        !selectedVersion
        || selectedVersion?.id?.toString() !== mv.id.toString()
        || selectedVersion.slug !== mv.slug
      ) {
        setSelectedVersion(mv);
        setQueryString('version', mv.slug);
      }
    },
    [selectedVersion, setQueryString]
  );

  const handleAllocationVersionChange = useCallback(
    versionId => {
      const version = allocationVersions?.find(allocationVersion => allocationVersion?.id?.toString() === versionId);
      setVersionQueryString(version);
    },
    [allocationVersions, setVersionQueryString]
  );

  const handleAllocationVersionQueryString = useCallback(() => {
    if (allocationVersions) {
      if (matchedVersion) {
        setVersionQueryString(matchedVersion);
      } else {
        history.push(notFoundPath);
      }
    }
  }, [allocationVersions, history, matchedVersion, setVersionQueryString]);

  const onVersionChange = useCallback(
    event => {
      // If there are more thant one Allocation version
      if (getArrayValue(allocationVersions)?.length > 1) {
        // Reset current Allocation Status
        setCurrentAllocationStatus?.(null);
        // Reset current Equity Value
        setCurrentEquityValue?.(null);
        // Reset current Firm Total
        setCurrentFirmTotal?.(null);
      }

      const callback = () => {
        const newVersionId = event.target.value;
        handleAllocationVersionChange(newVersionId);
      };

      if (hasChanges) {
        showDialog(callback);
      } else {
        callback();
      }
    },
    [
      allocationVersions,
      handleAllocationVersionChange,
      hasChanges,
      setCurrentAllocationStatus,
      setCurrentEquityValue,
      setCurrentFirmTotal,
      showDialog,
    ]
  );

  const setFilters = useCallback(() => {
    let orderedAllocationsVersions: AllocationVersionName[] = [];
    if (allocationVersions?.length) {
      orderedAllocationsVersions = orderBy(allocationVersions, 'version', 'asc').map(version => {
        const versionName = `Version ${version.version} - ${formatDateView(version.name)}`;
        return {
          ...version,
          label: versionName,
          name: isFalseStrict(version.is_firm_owned) ? (
            <p>
              {versionName} <Scalar className="scalar-version-icon" />
            </p>
          ) : (
            versionName
          ),
        };
      });
    }
    const pageFilterMap = pageFilters
      ? pageFilters.reduce<{ [key: string]: SetPageFilters<AllocationVersionName> }>((sofar, pageFilter) => {
        if (pageFilter.id) {
          // eslint-disable-next-line no-param-reassign
          sofar[pageFilter.id] = pageFilter;
        }
        return sofar;
      }, {})
      : {};
    // search pageFilterMap[ALLOCATION_VERSION_FILTER].options for the option matching the pageFilterMap[ALLOCATION_VERSION_FILTER].selectedValue;
    const allocationFilter = pageFilterMap[ALLOCATION_VERSION_FILTER];
    const selectedValue = allocationFilter?.selectedValue;
    const selectedPageFilter = allocationFilter?.options?.find(version => selectedValue === version.id);
    if (selectedValue !== selectedVersion?.id || selectedPageFilter?.slug !== selectedVersion?.slug) {
      pageFilterMap[ALLOCATION_VERSION_FILTER] = {
        id: ALLOCATION_VERSION_FILTER,
        options: orderedAllocationsVersions,
        title: ALLOCATION_VERSION_FILTER_TITLE,
        selectedValue: selectedVersion?.id,
        handler: onVersionChange,
      };
      if (setPageFilters) {
        setPageFilters(Object.values(pageFilterMap));
      }
    }
  }, [allocationVersions, onVersionChange, pageFilters, selectedVersion, setPageFilters]);

  const getVersions = useCallback(
    version => {
      const foundVersion = allocationVersions?.find(v => v.id === version.id);

      if (!foundVersion) {
        const versions = allocationVersions || [];
        const updatedAllocationVersions = [...versions, version];
        setAllocationVersions(updatedAllocationVersions);
        setSelectedVersion(version);
        pushQueryString('version', version.slug);
      } else if (foundVersion && foundVersion.slug !== version.slug) {
        // replace the matched version with the new version in the allocationVersions and set the allocation versions
        const versions = allocationVersions || [];
        const updatedAllocationVersions = versions.map(v => {
          if (v.id === version.id) {
            return version;
          }
          return v;
        });
        setAllocationVersions(updatedAllocationVersions);
        pushQueryString('version', version.slug);
      }
    },
    [allocationVersions, pushQueryString]
  );

  useEffect(() => {
    if (selectedMeasurementDate && matchedVersion) {
      handleAllocationVersionQueryString();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMeasurementDate, matchedVersion]);

  // Set filters
  useEffect(() => {
    if (!isEmpty(fundList) && !isEmpty(allocationVersions) && selectedVersion && pageFilters) {
      setFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageFilters, selectedMeasurementDate, selectedVersion, fundList, allocationVersions]);

  const dialogProps = useMemo(() => {
    if (matchedVersion && allocationVersions) {
      return {
        open: showPageForm,
        onClose: togglePageForm,
        versions: allocationVersions,
        getVersions,
        matchedVersion,
      };
    }

    return null;
  }, [showPageForm, togglePageForm, allocationVersions, getVersions, matchedVersion]);

  return {
    dialogProps,
    getVersions,
    isForbidden,
    selectedVersion,
    versionName,
  };
};

export default useAllocationVersionQueryString;
