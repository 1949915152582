import {
  SERVICES_AN_ERROR_OCCURRED_LOADING,
  SERVICES_AN_ERROR_OCCURRED_PUBLISHING,
  SERVICES_AN_ERROR_OCCURRED_UPDATING,
  SERVICES_SUCCESSFULLY_PUBLISHED,
  SERVICES_SUCCESSFULLY_UPDATED,
} from 'common/constants/services/services';

// Make Allocations Final
export const MAKE_ALLOCATIONS_FINAL_TITLE = 'Allocations Statuses';
export const MAKE_ALLOCATIONS_FINAL_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_UPDATING(MAKE_ALLOCATIONS_FINAL_TITLE);
export const MAKE_ALLOCATIONS_FINAL_DEFAULT_SUCCESS_MESSAGE
  = SERVICES_SUCCESSFULLY_UPDATED(MAKE_ALLOCATIONS_FINAL_TITLE);

// Make Allocation Final
export const MAKE_ALLOCATION_FINAL_TITLE = 'Allocation Status';
export const MAKE_ALLOCATION_FINAL_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_UPDATING(MAKE_ALLOCATION_FINAL_TITLE);
export const MAKE_ALLOCATION_FINAL_DEFAULT_SUCCESS_MESSAGE = SERVICES_SUCCESSFULLY_UPDATED(MAKE_ALLOCATION_FINAL_TITLE);

// Risk Free Rate by Measurement Date
export const RISK_FREE_RATE_BY_MD_QUERY_KEY = 'RISK_FREE_RATE_BY_MD';
export const RISK_FREE_RATE_BY_MD_TITLE = 'Risk Free Rate';
export const RISK_FREE_RATE_BY_MD_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_LOADING(RISK_FREE_RATE_BY_MD_TITLE);

// Risk Free Rates
export const RISK_FREE_RATES_QUERY_KEY = 'RISK_FREE_RATES';
export const RISK_FREE_RATES_TITLE = 'Risk Free Rates';
export const RISK_FREE_RATES_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_LOADING(RISK_FREE_RATES_TITLE);

// Scenario Values
export const SCENARIO_VALUES_QUERY_KEY = 'SCENARIO_VALUES';
export const SCENARIO_VALUES_TITLE = 'Scenario Values';
export const SCENARIO_VALUES_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_LOADING(SCENARIO_VALUES_TITLE);

// Scenarios Values
export const ALLOCATION_SCENARIOS_VALUES_QUERY_KEY = 'ALLOCATION_SCENARIOS_VALUES';
export const ALLOCATION_SCENARIOS_VALUES_TITLE = 'Scenarios Values';
export const ALLOCATION_SCENARIOS_VALUES_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_LOADING(
  ALLOCATION_SCENARIOS_VALUES_TITLE
);

// Backsolve Equity Value
export const BACKSOLVE_EQUITY_VALUE_QUERY_KEY = 'BACKSOLVE_EQUITY_VALUE';
export const BACKSOLVE_EQUITY_VALUE_TITLE = 'Backsolve Equity Value';
export const BACKSOLVE_EQUITY_VALUE_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_LOADING(BACKSOLVE_EQUITY_VALUE_TITLE);

// Backsolves Equity Values
export const BACKSOLVES_EQUITY_VALUES_QUERY_KEY = 'BACKSOLVES_EQUITY_VALUES';
export const BACKSOLVES_EQUITY_VALUES_TITLE = 'Backsolves Equity Values';
export const BACKSOLVES_EQUITY_VALUES_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_LOADING(BACKSOLVES_EQUITY_VALUES_TITLE);

// Publish Allocation
export const PUBLISH_ALLOCATION_TITLE = 'Allocation';
export const PUBLISH_ALLOCATION_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_PUBLISHING(PUBLISH_ALLOCATION_TITLE);
export const PUBLISH_ALLOCATION_DEFAULT_SUCCESS_MESSAGE = SERVICES_SUCCESSFULLY_PUBLISHED(PUBLISH_ALLOCATION_TITLE);
