import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';
import { textWithEllipsisStyle } from 'utillities';

export const EDIT_BUTTON_CLASSNAME = 'editButton';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    title: {
      ...textWithEllipsisStyle,
      fontWeight: 700,
      paddingRight: theme.spacing(1.5),

      [`& .${EDIT_BUTTON_CLASSNAME}`]: {
        marginLeft: theme.spacing(1.2),
        opacity: 0,
        transition: 'ease opacity 0.3s',
      },

      [`&:hover .${EDIT_BUTTON_CLASSNAME}`]: {
        opacity: 1,
      },
    },
  })
);

export default useStyles;
