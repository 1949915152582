/**
 * @name Global Reducer
 * @memberof module:common/reducers
 * @type {ReduxReducer}
 * @return {Object} Reducer Specification
 */

import {
  GET_FIRM_LIST,
  GET_FUND_LIST_BY_FIRM_ID,
  GET_SELECTED_USER_PERMISSIONS,
  GET_USER_MANAGEMENT_FIRM_LIST,
  SET_FIRM_ID,
  SET_FIRM_INFO,
  SET_FIRM_INFO_ID,
  SET_FIRM_LIST,
  SET_SELECTED_USER_PERMISSIONS,
  SET_USER_MANAGEMENT_FIRM_LIST,
} from 'common/actions/firms/types';
import {
  getFirmList,
  getFundListByFirmId,
  getSelectedUserPermissions,
  getUserManagementFirmList,
  setFirmId,
  setFirmInfo,
  setFirmInfoById,
  setFirmList,
  setSelectedUserPermissions,
  setUserManagementFirmList,
} from './reducer';

export default {
  [SET_FIRM_INFO]: setFirmInfo,
  [SET_FIRM_INFO_ID]: setFirmInfoById,
  [GET_FIRM_LIST]: getFirmList,
  [GET_USER_MANAGEMENT_FIRM_LIST]: getUserManagementFirmList,
  [GET_FUND_LIST_BY_FIRM_ID]: getFundListByFirmId,
  [SET_FIRM_ID]: setFirmId,
  [SET_FIRM_LIST]: setFirmList,
  [SET_USER_MANAGEMENT_FIRM_LIST]: setUserManagementFirmList,
  [SET_SELECTED_USER_PERMISSIONS]: setSelectedUserPermissions,
  [GET_SELECTED_USER_PERMISSIONS]: getSelectedUserPermissions,
};
