import React from 'react';
import { Box } from '@material-ui/core';

const ConfirmUnsavedChanges = () => (
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
    <p>Are you sure you want to continue?</p><br />
    <p>All changes will be lost if you leave this page without saving</p>
  </Box>
);

export default ConfirmUnsavedChanges;
