/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import { isUndefined, sortBy } from 'lodash';
import uuid from 'react-uuid';
import { securityTypes } from 'common/constants';
import {
  CURRENT_OWNERSHIP_ROW_NUMBER,
  FULLY_DILUTED_OWNERSHIP_ROW_NUMBER,
  HAS_MULTIPLE_INVESTMENT_DATES_ROW_NUMBER,
  INVESTMENT_DATE_ROW_NUMBER,
  MULTIPLE_INVESTMENT_LEDGER_ROW_NUMBER,
  OPTIONS_LEDGER_ROW_NUMBER,
  PIK_SHARES_ROW_NUMBER,
  SECURITY_NAME_ROW_NUMBER,
  SECURITY_TYPE_ROW_NUMBER,
  SHARES_FULLY_DILUTED_ROW_NUMBER,
  SHARES_OUTSTANDING_ROW_NUMBER,
  STRIKE_PRICE_ROW_NUMBER,
} from 'common/constants/cap-table';
import { YES_STRING } from 'common/constants/general';
import { CONVERTIBLE_NOTES, UNISSUED_OPTIONS } from 'common/constants/securityTypes';
import checkIfExistConvertibleNoteInCapTable from 'pages/CapTable/cap-table/utilities/checkIfExistConvertibleNoteInCapTable';
import { parseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';

export const calcSharesOutstandingFromMultipleInvestments = investments =>
  investments.map(investment => Number(investment.shares)).reduce((previous, current) => previous + current, 0);

const capTableParser = async ({ columns, rowConfig }) => {
  const alphabet = alphabetGenerator([], columns.length);
  let cells = {};

  // Sort all columns by creation date
  const sortedColumns = sortBy(columns, ['order']);

  // Filter updated columns to get only not "Pool Option" security types
  const noConvertibleNotesEitherUnissuedOption = rowNumber =>
    sortedColumns.reduce((list, column, columnIndex) => {
      const key = alphabet[columnIndex] + rowNumber;

      if (
        ![securityTypes.UNISSUED_OPTIONS.toString(), securityTypes.CONVERTIBLE_NOTES.toString()].includes(
          column.security_type?.toString()
        )
      ) {
        list.push(key);
      }

      return list;
    }, []);

  const notOptionPoolKeyList = rowNumber =>
    sortedColumns.reduce((list, column, columnIndex) => {
      const key = alphabet[columnIndex] + rowNumber;

      if (column.security_type !== securityTypes.UNISSUED_OPTIONS) {
        list.push(key);
      }

      return list;
    }, []);

  const currentOwnershipCellFormula = columnLegend => {
    const key = columnLegend + SHARES_FULLY_DILUTED_ROW_NUMBER;
    const captableHasConvertibleNotes = checkIfExistConvertibleNoteInCapTable(sortedColumns);
    const keyList = captableHasConvertibleNotes
      ? noConvertibleNotesEitherUnissuedOption(SHARES_FULLY_DILUTED_ROW_NUMBER)
      : notOptionPoolKeyList(SHARES_FULLY_DILUTED_ROW_NUMBER);

    if (keyList.length === 0) {
      return '0.0';
    }
    return `=${key}/sum(${keyList})`;
  };

  const fullyDilutedCellFormula = columnLegend => {
    const key = columnLegend + SHARES_FULLY_DILUTED_ROW_NUMBER;
    const keyList = columns.map((_c, columnIndex) => alphabet[columnIndex] + SHARES_FULLY_DILUTED_ROW_NUMBER);

    if (keyList.length === 0) {
      return '0';
    }

    return `=${key}/sum(${keyList})`;
  };

  const getExpr = (expr, columnLegend, defaultValue = '') => (expr ? expr.replace(/@/g, columnLegend) : defaultValue);

  const getLinkedCells = (linkedCells, columnLegend, columnLegends) =>
    new Set(
      linkedCells.flatMap(linkedCell => {
        if (linkedCell.includes('@@')) {
          return columnLegends.map(legend => linkedCell.replace('@@', legend));
        }
        return linkedCell.replace('@', columnLegend);
      })
    );

  // Parse body cells
  sortedColumns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];

    rowConfig.forEach((row, index) => {
      const rowNumber = index + 1;
      const key = columnLegend + rowNumber;
      const value = !isUndefined(column[row.alias]) ? column[row.alias] : null;
      const type = row.gridType || null;

      const ref = column.security_ref || uuid();
      const { defaultValue } = row;
      const displayOwnershipPercentage = [securityTypes.UNISSUED_OPTIONS, securityTypes.CONVERTIBLE_NOTES].includes(
        column.security_type
      );

      const parsedValue = parseValue(value, type, null, null, row.dbType, true);
      cells = {
        ...cells,
        [key]: {
          // Copy default props from the row config
          ...row,
          // Replace default props
          key,
          expr: getExpr(row.expr, columnLegend, parsedValue),
          value: parsedValue,
          alias: row.alias || '',
          columnId: column.id,
          // Use it's own security_ref if exists, otherwise create a new one
          securityRef: ref,
          columnRef: ref,
          columnLegend,
          columnOrder: column.order,
          linkedCellSymbols: getLinkedCells(row.linkedCellSymbols || [], columnLegend, alphabet),
        },
      };

      const currentOwnershipExpr = currentOwnershipCellFormula(columnLegend);
      const fullyDilutedExpr = fullyDilutedCellFormula(columnLegend);
      const securityType = cells[columnLegend + SECURITY_TYPE_ROW_NUMBER];

      switch (rowNumber) {
        case SECURITY_NAME_ROW_NUMBER:
          cells[key].id = column.id || '';
          break;
        case INVESTMENT_DATE_ROW_NUMBER:
          cells[key].dataSourceKey = `${columnLegend}${MULTIPLE_INVESTMENT_LEDGER_ROW_NUMBER}`;
          break;
        case SHARES_OUTSTANDING_ROW_NUMBER:
          cells[key].dataSourceKey = `${columnLegend}${OPTIONS_LEDGER_ROW_NUMBER}`;
          if (column.security_type === CONVERTIBLE_NOTES) {
            cells[key].expr = column.convertible_notes.reduce(
              (sofar, { expected_shares }) => sofar + Number(expected_shares),
              0
            );
          }
          break;
        case PIK_SHARES_ROW_NUMBER:
          if (securityType.value !== UNISSUED_OPTIONS) {
            cells[key].expr = getExpr('=IF(@21==Pik,@25/@4,0)', columnLegend);
          }
          break;
        case CURRENT_OWNERSHIP_ROW_NUMBER:
          cells[key].expr = displayOwnershipPercentage ? defaultValue : currentOwnershipExpr;
          break;
        case FULLY_DILUTED_OWNERSHIP_ROW_NUMBER:
          cells[key].expr = fullyDilutedExpr;
          break;
        case STRIKE_PRICE_ROW_NUMBER:
          cells[key].dataSourceKey = `${columnLegend}${OPTIONS_LEDGER_ROW_NUMBER}`;
          break;
        case HAS_MULTIPLE_INVESTMENT_DATES_ROW_NUMBER:
          cells[key].dataSourceKey = `${columnLegend}${MULTIPLE_INVESTMENT_LEDGER_ROW_NUMBER}`;
          break;
        case MULTIPLE_INVESTMENT_LEDGER_ROW_NUMBER:
          if (value === YES_STRING) {
            const sharesOutstanding = calcSharesOutstandingFromMultipleInvestments(value);
            cells[`${columnLegend}${SHARES_OUTSTANDING_ROW_NUMBER}`].expr = sharesOutstanding.toString();
          }
          break;
        default:
          break;
      }
    });
  });

  return cells;
};

export default capTableParser;
