import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { onUnloadPage } from 'common/hooks/unloadActions';
import DialogContext from 'context/DialogContext';
import UnsavedChanges from 'context/UnsavedChanges';

export const UnsavedChangesProvider = ({ children }) => {
  const [action, _setAction] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const dialogContext = useContext(DialogContext);

  const setAction = useCallback(action => {
    _setAction(action);
    onUnloadPage(!!action);
  }, []);

  const cleanAction = useCallback(() => {
    setAction(false);
    setIsVisible(false);
  }, [setAction]);

  const showDialog = useCallback(
    callback => {
      const {
        wrapper: Dialog,
        content: Content,
        onLeave, // onSave
      } = action;
      dialogContext.showDialog({
        wrapper: Dialog,
        content: Content,
        onClose: () => setIsVisible(false),
        actions: [
          {
            label: 'Leave Anyways',
            variant: 'contained',
            type: 'danger',
            callback: () => {
              if (onLeave) onLeave();
              cleanAction();
              callback();
            },
          },
        ],
      });
      setIsVisible(true);
    },
    [action, cleanAction, dialogContext]
  );

  const providerValue = useMemo(
    () => ({
      action,
      setAction,
      cleanAction,
      showDialog,
      isVisible,
    }),
    [action, setAction, cleanAction, showDialog, isVisible]
  );

  return <UnsavedChanges.Provider value={providerValue}>{children}</UnsavedChanges.Provider>;
};

UnsavedChangesProvider.propTypes = {
  children: PropTypes.element,
};

export const UnsavedChangesConsumer = UnsavedChanges.Consumer;
