import { useEffect } from 'react';
import { N_CHAR, N_CODE } from 'common/constants/general';

const useKeyEventForFabButton = isStillLoading => {
  useEffect(() => {
    // most likely to work is alt + n
    const triggerFabClick = event => {
      const key = event.key || event.code;
      const initiatingKey = event.ctrlKey || event.metaKey || event.altKey;
      const nKeyHitted = [N_CHAR, N_CODE].includes(key);

      if (initiatingKey && nKeyHitted) {
        const matchedButton = document.querySelector('button.MuiFab-root');
        matchedButton.click();
      }
    };
    if (!isStillLoading) {
      document.addEventListener('keyup', triggerFabClick);
    }
    return () => document.removeEventListener('keyup', triggerFabClick);
  });
};

export default useKeyEventForFabButton;
