import React, { FC } from 'react';
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { LoadingSection } from 'components';
import { LineBarChart } from 'dashboard409a/components';
import { VALUATION_TREND } from 'dashboard409a/utilities';
import { IValuationChartData, ValuationTrendProps } from './types';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '2%',
    padding: 'inherit',
  },
}));

const ValuationTrend: FC<ValuationTrendProps> = ({ data, isLoading }) => {
  const classes = useStyles();
  let stockValues = [] as number[];
  let weightedValue = [] as number[];

  if (data) {
    // eslint-disable-next-line radix
    stockValues = data.map((item: IValuationChartData) => +item.marketable_value_per_common_share);
    // eslint-disable-next-line radix
    weightedValue = data.map((item: IValuationChartData) => +item.weighted_equity_value);
  }

  const parsedData = {
    labels: data?.map((item: IValuationChartData) => item.report_date.measurement_date.date),
    datasets: [
      {
        type: 'line' as const,
        label: 'Common Stock Value', // TODO: Replace this to not be hardcoded
        yAxisID: 'common',
        data: stockValues,
        backgroundColor: '#65C687',
        borderColor: '#65C687',
      },
      {
        type: 'bar' as const,
        label: 'Equity Value', // TODO: Replace this to not be hardcoded
        yAxisID: 'equity',
        data: weightedValue,
        backgroundColor: '#80bef4',
        borderColor: '#80bef4',
      },
    ],
  };

  return isLoading || !data ? (
    <LoadingSection />
  ) : (
    <Card className={classes.root}>
      <LineBarChart data={parsedData} options={VALUATION_TREND} />
    </Card>
  );
};

export default ValuationTrend;
