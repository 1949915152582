import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, orderBy } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { largeCurrencyFormat, smallCurrencyFormat } from 'common/formats/formats';
import { useFormat } from 'common/hooks';
import { useStore } from 'common/store';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import ScalarDatasheet from 'components/ScalarDatasheet';
import LayoutContext from 'context/LayoutContext';
import { NeedsFundOwnershipFirst } from 'pages/Allocation/allocations/components';
import { getValidSecurities } from 'pages/CapTable/cap-table/utilities';
import { AllocationService, CaptableService } from 'services';
import { useCompanyMeasurementDateFilter, useResponse } from 'services/hooks';
import { useGetAllocationStatusBreadcrumb } from 'services/hooks/allocation';
import { useGetCapTableList } from 'services/hooks/company';
import { formatNumbers, getBreadcrumbs } from 'utillities';
import FundFirmDistribution from './components/FundFirmDistribution';
import Waterfall from './Waterfall';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';

const WaterfallView = () => {
  const [, getCapTableList] = useGetCapTableList();
  const [{ companyInfo, firmInfo, fundList, capTableList }] = useStore();
  const { id: companyId } = companyInfo;
  const { setPageBreadcrumbs } = useContext(LayoutContext);
  const { errorNotification } = useResponse();
  const [{ currency }] = useFormat({
    page: 'captable',
    units: REGULAR_UNIT,
  });

  const { selectedMeasurementDate, MDDialogue, measurementDates } = useCompanyMeasurementDateFilter();
  const [selectedCaptable, setSelectedCaptable] = useState();
  const [equityValue, setEquityValue] = useState();
  const [distributionData, setDistributionData] = useState([]);
  const [updatedWeightedShareValues, setUpdatedWeightedShareValues] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [captableDetail, setCaptableDetail] = useState({});

  const [allocationStatusBreadcrumb] = useGetAllocationStatusBreadcrumb();

  useEffect(() => {
    if (selectedMeasurementDate) {
      const date = selectedMeasurementDate?.id;
      getCapTableList(companyId, date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMeasurementDate]);

  const getCaptableDetail = async captableId => {
    const captSvc = new CaptableService();
    const data = await captSvc.getCaptableById(captableId);
    setCaptableDetail(data);
  };

  const getSharesByFundAndSecurity = ({ fundId, securityId, fundOwnershipDetail }) => {
    const tmpFundOwnershipDetail = fundOwnershipDetail.filter(
      fod => fod.fund === fundId && fod.security === securityId
    );
    if (tmpFundOwnershipDetail) {
      return tmpFundOwnershipDetail.reduce((acc, curr) => acc + Number(curr.shares), 0);
    }

    return 0;
  };

  const getUniqueSecurityAndFundCombination = ({ fundOwnershipDetail }) => {
    const uniqueSecurityAndFundCombination = fundOwnershipDetail.reduce((acc, current) => {
      const uniqueIdentifier = `${current.security}-${current.fund}`;
      if (!acc.find(fod => `${fod.security}-${fod.fund}` === uniqueIdentifier)) {
        acc.push(current);
      }
      return acc;
    }, []);

    return uniqueSecurityAndFundCombination;
  };

  const validSecurities = useMemo(() => {
    if (captableDetail) {
      return getValidSecurities(captableDetail);
    }

    return [];
  }, [captableDetail]);

  useEffect(() => {
    if (!isEmpty(companyInfo)) {
      setPageBreadcrumbs(getBreadcrumbs(firmInfo, companyInfo, 'Waterfall', [allocationStatusBreadcrumb]));
    }
    return () => setPageBreadcrumbs(null);
  }, [companyInfo, firmInfo, setPageBreadcrumbs, allocationStatusBreadcrumb]);

  useEffect(() => {
    if (selectedCaptable) {
      getCaptableDetail(selectedCaptable);
    }
  }, [selectedCaptable]);

  const handleCaptableDetail = useCallback(
    ({ sortedPresentValues }) => {
      const {
        securities,
        fund_ownership: { fund_ownership_detail: fundOwnershipDetail },
      } = captableDetail;
      const uniqueFundIds = new Set(fundOwnershipDetail.map(fod => fod.fund));
      const fundsWithOwnership = fundList?.filter(fund => uniqueFundIds.has(fund.id));
      const uniqueSecurityAndFundCombination = getUniqueSecurityAndFundCombination({ fundOwnershipDetail });
      const shares = fundsWithOwnership.map(fo => ({
        fund: { id: fo.id, name: fo.name },
        name: `${fo.name} Shares`,
        share_values: uniqueSecurityAndFundCombination
          .filter(fod => fod.fund === fo.id)
          .map(item => ({
            securityId: item.security,
            shares: getSharesByFundAndSecurity({
              fundId: fo.id,
              securityId: item.security,
              fundOwnershipDetail,
            }),
            security: securities.find(s => s.id === item.security)?.name,
            per_share: sortedPresentValues.find(presentValue => presentValue.security.id === item.security)?.value,
          })),
      }));
      setUpdatedWeightedShareValues(shares);
    },
    [captableDetail, fundList]
  );

  const handleScenarioValuesAndSetData = ({ scenarioValues }) => {
    const { aggregate_values: aggregateValues, present_values: presentValues } = scenarioValues;

    const sortedAggregateValues = orderBy(aggregateValues, ['security.id'], ['desc']);
    const sortedPresentValues = orderBy(presentValues, ['security.id'], ['desc']);

    const headerClassRight = 'table-header title-col align-right';
    const headerClass = 'table-header title-col';

    const totalClass = 'title-col division-bottom-only division-top-only subtitle cell ';
    const contentClass = 'align-right';
    const totalClassRight = 'title-col division-bottom-only division-top-only subtitle cell align-right';

    const data = [];

    const headers = [
      {
        value: 'Security',
        readOnly: true,
        className: headerClass,
        rowSpan: 2,
      },
      {
        value: 'Distribution',
        readOnly: true,
        className: headerClassRight,
        rowSpan: 2, // Aggregate values
      },
      {
        value: 'Per Share',
        readOnly: true,
        className: headerClassRight,
        rowSpan: 2, // Present share values
      },
    ];
    data.push(headers);
    data.push([]); // Empty array to allow for the rowspan: 2
    sortedAggregateValues.forEach((aggregateValue, index) => {
      const presentValue = sortedPresentValues[index];
      const row = [
        {
          value: aggregateValue.security.name,
          readOnly: true,
          className: 'row-label',
        },
        {
          value: formatNumbers({
            currency,
            format: largeCurrencyFormat,
            value: aggregateValue.value,
          }),
          readOnly: true,
          gridType: 'number',
          className: contentClass,
        },
        {
          value: formatNumbers({
            currency,
            format: smallCurrencyFormat,
            value: presentValue.value,
          }),
          readOnly: true,
          gridType: 'number',
          className: contentClass,
        },
      ];
      data.push(row);
    });

    const distributionTotal = sortedAggregateValues.reduce((acc, curr) => acc + curr.value, 0);

    const totals = [
      { value: 'Totals', readOnly: true, className: totalClass },
      {
        value: formatNumbers({
          currency,
          format: largeCurrencyFormat,
          value: distributionTotal,
        }),
        readOnly: true,
        className: totalClassRight,
        gridType: 'number',
      },
      { value: '', readOnly: true, className: totalClass },
    ];
    data.push(totals);
    setDistributionData(data);

    if (captableDetail) {
      handleCaptableDetail({ sortedPresentValues });
    }
  };

  // Populate waterfall distribution table
  const getDistribution = useCallback(async () => {
    // Do not proceed if the cap table is empty
    if (capTableList.find(captable => captable.id === selectedCaptable)?.is_empty) {
      setUpdatedWeightedShareValues([]);
      return;
    }
    const service = new AllocationService();
    const today = new Date();
    try {
      const scenarioValues = await service.postScenarioValues({
        scenarioMethod: 0, // Always Waterfall
        captableId: selectedCaptable,
        equityValue,
        exitDate: today.toISOString().split('T')[0],
        scenarioType: 1, // Waterfall should use CURRENT VALUE type
        maturity: 0, // OPM not used for standard waterfall
        volatility: 0, // OPM not used for standard waterfall
      });

      if (scenarioValues) {
        handleScenarioValuesAndSetData({ scenarioValues });
      }
    } catch (error) {
      // error.response.text is an object with the actual message inside of an array
      const errorMessage = Object.values(JSON.parse(error.response?.text))[0][0] || error.response.statusText;
      errorNotification(errorMessage);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCaptable, equityValue, captableDetail]);

  useEffect(() => {
    if (captableDetail && equityValue) {
      getDistribution();
    } else {
      setDistributionData([]);
    }

    return () => setUpdatedWeightedShareValues(undefined);
  }, [selectedCaptable, equityValue, captableDetail, getDistribution]);

  useEffect(() => {
    // Valid equity values are initially strings (we convert them to numbers later)
    if (typeof equityValue === 'string' && !updatedWeightedShareValues) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [equityValue, updatedWeightedShareValues]);

  return (
    <>
      <Waterfall
        setSelectedCaptable={setSelectedCaptable}
        setEquityValue={setEquityValue}
        currencyCode={currency.code}
      />
      {!isEmpty(distributionData) && updatedWeightedShareValues?.length > 0 && (
        <PerfectScrollbar id="distribution-table" className="always-visible">
          <div className="inline-tables">
            <ScalarDatasheet className="data-table" data={distributionData} />
            <FundFirmDistribution
              weightedShareValues={updatedWeightedShareValues}
              currency={currency}
              validSecurities={validSecurities}
            />
          </div>
        </PerfectScrollbar>
      )}
      <LoadingIndicator isLoading={isLoading} />
      {updatedWeightedShareValues && isEmpty(updatedWeightedShareValues) && (
        <NeedsFundOwnershipFirst
          measurementDates={measurementDates}
          selectedMeasurementDate={selectedMeasurementDate}
          title="No fund ownership defined for the cap table"
          tagline="Add fund ownership to see its share values"
        />
      )}
      <MDDialogue />
    </>
  );
};

export default WaterfallView;
