import moment from 'moment';
import { getArrayValue } from './getArrayValue';

export const isDateInPeriods = (date, periods, dateField, dateFormat) => {
  const formattedDate = moment(date).format(dateFormat);
  const dates = getArrayValue(periods?.map(period => period?.[dateField]));
  return dates.includes(formattedDate);
};

export const isLastQuarter = (asOfDate, fyEnd) => {
  const date = moment(asOfDate, moment.ISO_8601, true);
  const fyEndMoment = moment(fyEnd, moment.ISO_8601, true);
  const fyEndStartMoment = fyEndMoment.clone().subtract(3, 'months');
  const dateIsWithinLastQuarter = date.isBetween(fyEndStartMoment, fyEndMoment, null, '[]');

  return dateIsWithinLastQuarter;
};
