import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MailIcon from '@material-ui/icons/Mail';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useInformation409A } from 'common/hooks';
import { ExtraActionsMenu, Tab, TabsNavigation } from 'components';
import { InformationDashboard409AContext } from 'context/InformationDashboard409AContext';
import { getCompanyRequestedInformation } from 'dashboard409a/services/dashboard';
import { hideLoadingProgressBar, showLoadingProgressBar } from 'dashboard409a/states/features/global';
import { RootStateType } from 'dashboard409a/states/store';
import theme from 'theme';
import { CreateInformationRequestModal } from './CreateInformationRequestModal';
import CurrentRequestedDocument from './CurrentRequestedDocumentsAndQuestions/CurrentRequestedDocument';
import CurrentRequestedQuestions from './CurrentRequestedDocumentsAndQuestions/CurrentRequestedQuestions';
import { CurrentInformationRequest } from './CurrentRequestedDocumentsAndQuestions/types';
import { InitialRequestProcessModal } from './InitialRequestProcessModal';
import useStyles from './styles';
import { CompanyData } from './types';
import { ApiService, InformationRequestStatus, Portal } from '../../../api';
import { LinkVariant } from '../../../icons';
import useTabQueryParam from '../../../pages/ValuationsAllocation/hooks/useApproachQueryParam/useTabQueryParam';
import { useDocuments } from '../../../services/hooks';
import useGetCompanyUsers from '../../../services/hooks/useGetCompanyUsers';
import { VALUATION_TYPE_409A } from '../../common/constants';

const Information: FC = () => {
  const classes = useStyles();
  const [isInfoSetupModalOpen, setIsInfoSetupModalOpen] = useState(false);
  const [isTaskPreperationModelOpen, setIsTaskPreperationModelOpen] = useState(false);
  const [currentInformationRequests, setCurrentInformationRequests] = useState<CurrentInformationRequest[]>([]);
  const [updateTaskList, setUpdateTaskList] = useState(false);
  const [informationRequestStatuses, setInformationRequestStatuses] = useState<InformationRequestStatus[]>([]);
  const { currentCompany, currentMeasurementDateId, currentCompanyMeasurementDateId } = useSelector(
    (state: RootStateType) => state.global
  );
  const { currentDocuments, getDocuments, isLoading } = useDocuments();

  const companyId = useMemo(() => currentCompany?.id, [currentCompany]);

  const mdId = useMemo(() => currentMeasurementDateId, [currentMeasurementDateId]);

  useEffect(() => {
    if (companyId && mdId && !isLoading && !currentDocuments) {
      getDocuments(mdId, companyId);
    }
  }, [getDocuments, companyId, mdId, isLoading, currentDocuments]);

  const { enqueueSnackbar } = useSnackbar();
  const { data: userData } = useGetCompanyUsers(currentCompany?.id, '');
  const [portalLinks, setPortalLinks] = useState<Portal | undefined>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const copyToClipboard = useCallback(
    (link: string) => {
      navigator.clipboard.writeText(link);
      enqueueSnackbar('Link copied to clipboard', { variant: 'success' });
    },
    [enqueueSnackbar]
  );

  const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(
    (link: string | undefined) => {
      if (link) {
        copyToClipboard(link);
      }
      setAnchorEl(null);
    },
    [copyToClipboard]
  );

  const getPortalLinks = useCallback(async () => {
    if (currentCompanyMeasurementDateId && currentInformationRequests.length > 0) {
      return ApiService.apiTasksPortalLinksRead(currentCompanyMeasurementDateId.toString());
    }
  }, [currentCompanyMeasurementDateId, currentInformationRequests]);

  useEffect(() => {
    getPortalLinks().then(response => {
      setPortalLinks(response);
    });
  }, [getPortalLinks]);

  const { handleGetCurrentInformationRequests } = useInformation409A({
    currentCompanyMeasurementDateId,
    setCurrentInformationRequests,
  });
  const [companyData, setCompanyData] = useState<CompanyData>({ requested_documents: [], questions: [] });
  const { requested_documents: requestedDocuments, questions } = companyData;

  const documentsAndQuestionsProps = {
    companyId: currentCompany?.id,
    documents: requestedDocuments,
    questions,
    setUpdateTaskList,
  };

  const optionsForExtraActionsMenu = [
    {
      label: 'Edit Company Questions and Documents',
      callback: () => setIsInfoSetupModalOpen(true),
      isActive: true,
    },
  ];

  const createAndSetInformationRequestStatus = useCallback(async () => {
    if (currentCompanyMeasurementDateId === undefined) return;
    const informationRequestStatus = await ApiService.apiCreateInformationRequestStatusCreate({
      report_date: currentCompanyMeasurementDateId,
      valuation_type: VALUATION_TYPE_409A,
    });
    setInformationRequestStatuses([informationRequestStatus]);
  }, [currentCompanyMeasurementDateId]);

  const retrieveAndSetInformationRequestStatus = useCallback(async () => {
    if (currentCompanyMeasurementDateId === undefined) return;
    const informationRequestStatus = await ApiService.apiRetrieveInformationRequestStatusRead(
      currentCompanyMeasurementDateId.toString()
    );
    if (informationRequestStatus.results.length === 0) {
      await createAndSetInformationRequestStatus();
      return;
    }

    setInformationRequestStatuses(informationRequestStatus.results);
  }, [createAndSetInformationRequestStatus, currentCompanyMeasurementDateId]);

  useEffect(() => {
    if (currentCompanyMeasurementDateId) {
      retrieveAndSetInformationRequestStatus();
    }
  }, [currentCompanyMeasurementDateId, retrieveAndSetInformationRequestStatus]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      showLoadingProgressBar();

      if (currentCompany?.id && currentMeasurementDateId) {
        const data = await getCompanyRequestedInformation(currentCompany.id);
        setCompanyData(data);
        handleGetCurrentInformationRequests();
        setUpdateTaskList(false);
      }

      await retrieveAndSetInformationRequestStatus();

      hideLoadingProgressBar();
    };
    fetchCompanyData();
  }, [
    currentCompany,
    currentMeasurementDateId,
    handleGetCurrentInformationRequests,
    retrieveAndSetInformationRequestStatus,
    updateTaskList,
  ]);

  const providerValue = useMemo(
    () => ({
      handleGetCurrentInformationRequests,
      currentDocuments,
    }),
    [handleGetCurrentInformationRequests, currentDocuments]
  );
  const { tabId } = useTabQueryParam();

  const tabs: Tab[] = useMemo(
    () => [
      {
        id: 'documents',
        label: 'Documents',
        content: <CurrentRequestedDocument currentInformationRequests={currentInformationRequests} />,
      },
      {
        id: 'questions',
        label: 'Questions',
        content: <CurrentRequestedQuestions currentInformationRequests={currentInformationRequests} />,
      },
    ],
    [currentInformationRequests]
  );

  const selectedTab = useMemo(() => (tabs.find(tab => tab.id === tabId) || tabs[0]).label, [tabId, tabs]);

  return (
    <InformationDashboard409AContext.Provider value={providerValue}>
      <Grid container spacing={3}>
        <TabsNavigation tabs={tabs} sidePanelOpen>
          <Grid item xs={12} className={classes.header}>
            <Grid item>
              <br />
              <Typography className={classes.typographyTitle}>{selectedTab}</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonOutlined}
                onClick={() => setIsTaskPreperationModelOpen(true)}
                startIcon={<MailIcon />}>
                {currentInformationRequests?.length ? 'UPDATE INFO REQUEST' : 'NEW INFO REQUEST'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={currentInformationRequests?.length === 0}
                className={`${classes.buttonOutlined} ${classes.squareIcon}`}
                onClick={handleMenuOpen}>
                <LinkVariant />
                &nbsp;
              </Button>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleMenuClose(undefined)}>
                <MenuItem onClick={() => handleMenuClose(portalLinks?.document_portal_link)}>
                  <ListItemIcon>
                    <FileCopyIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>Secure Portal Documents</Typography>
                </MenuItem>

                <MenuItem onClick={() => handleMenuClose(portalLinks?.questionnaire_portal_link)}>
                  <ListItemIcon>
                    <FileCopyIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>Secure Portal Questions</Typography>
                </MenuItem>
              </Menu>
              <ExtraActionsMenu options={optionsForExtraActionsMenu} dotsColor={theme.palette.gray[500]} />
            </Grid>
          </Grid>
          <CreateInformationRequestModal
            isOpen={isTaskPreperationModelOpen}
            setIsOpen={() => setIsTaskPreperationModelOpen(false)}
            informationRequestStatuses={informationRequestStatuses}
            setUpdateTaskList={setUpdateTaskList}
            currentInformationRequests={currentInformationRequests}
            companyUsers={userData || []}
          />
          <InitialRequestProcessModal
            {...documentsAndQuestionsProps}
            isOpen={isInfoSetupModalOpen}
            setIsOpen={setIsInfoSetupModalOpen}
          />
        </TabsNavigation>
      </Grid>
    </InformationDashboard409AContext.Provider>
  );
};

export default Information;
