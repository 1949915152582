import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { InlineMessageBox } from 'components';
import useStyles from './styles';
import { InformationMessageProps } from './types';

const InformationMessage: FC<InformationMessageProps> = props => {
  const { displayMessage, message } = props;

  const classes = useStyles();

  return (
    <>
      {displayMessage && (
        <Grid className={classes.messageContainer}>
          <InlineMessageBox text={message} />
        </Grid>
      )}
    </>
  );
};

export default InformationMessage;
