/* eslint-disable no-param-reassign */
import { isUndefined } from 'lodash';
import { COMPANY } from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import addApproachDataInColumn from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/addApproachDataInColumn';
import {
  ACQUIRER_NAME_ID,
  ACQUIRER_NAME_TITLE,
  EBITDA_MULTIPLE_TITLE,
  ENTERPRISE_VALUE_ID,
  ENTERPRISE_VALUE_TITLE,
  FINANCIALS_COMPANY_INFO_KEYS,
  LTM_EBITDA_ID,
  LTM_EBITDA_MULTIPLE_ENABLED,
  LTM_EBITDA_MULTIPLE_ID,
  LTM_EBITDA_TITLE,
  LTM_REVENUE_ID,
  LTM_REVENUE_MULTIPLE_ENABLED,
  LTM_REVENUE_MULTIPLE_ID,
  LTM_REVENUE_TITLE,
  MULTIPLE_COLUMNS_ALIAS,
  NTM_EBITDA_ID,
  NTM_EBITDA_MULTIPLE_ENABLED,
  NTM_EBITDA_MULTIPLE_ID,
  NTM_REVENUE_ID,
  NTM_REVENUE_MULTIPLE_ENABLED,
  NTM_REVENUE_MULTIPLE_ID,
  REVENUE_MULTIPLE_TITLE,
  TRANSACTION_DATE_ID,
  TRANSACTION_DATE_TITLE,
} from 'pages/Valuations/approaches/GuidelineTransactions/config/constants';

const rowTransformer = (approach, financials) => {
  const transactions = approach?.gpt_transactions || [];
  const TABLE_COLUMNS = [
    {
      id: ACQUIRER_NAME_ID,
      title: ACQUIRER_NAME_TITLE,
      fields: [{ acquirer_name: ACQUIRER_NAME_ID }],
    },
    {
      id: TRANSACTION_DATE_ID,
      title: TRANSACTION_DATE_TITLE,
      fields: [{ transaction_date: TRANSACTION_DATE_ID }],
    },
    {
      id: ENTERPRISE_VALUE_ID,
      title: ENTERPRISE_VALUE_TITLE,
      fields: [{ enterprise_value: ENTERPRISE_VALUE_ID }],
    },
    {
      id: LTM_REVENUE_ID,
      title: LTM_REVENUE_TITLE,
      fields: [{ ltm_revenue: LTM_REVENUE_ID }],
    },
    {
      id: LTM_EBITDA_ID,
      title: LTM_EBITDA_TITLE,
      fields: [{ ltm_ebitda: LTM_EBITDA_ID }],
    },
    {
      id: LTM_REVENUE_MULTIPLE_ID,
      title: REVENUE_MULTIPLE_TITLE,
      fields: [{ number: LTM_REVENUE_ID }, { enabled: LTM_REVENUE_MULTIPLE_ENABLED }],
    },
    {
      id: LTM_EBITDA_MULTIPLE_ID,
      title: EBITDA_MULTIPLE_TITLE,
      fields: [{ number: LTM_EBITDA_ID }, { enabled: LTM_EBITDA_MULTIPLE_ENABLED }],
    },
    {
      id: NTM_REVENUE_MULTIPLE_ID,
      title: REVENUE_MULTIPLE_TITLE,
      fields: [{ number: NTM_REVENUE_ID }, { enabled: NTM_REVENUE_MULTIPLE_ENABLED }],
    },
    {
      id: NTM_EBITDA_MULTIPLE_ID,
      title: EBITDA_MULTIPLE_TITLE,
      fields: [{ number: NTM_EBITDA_ID }, { enabled: NTM_EBITDA_MULTIPLE_ENABLED }],
    },
  ];

  // copy the fields into the column
  const getComparisonFields = (gpt_transactions, transactionFields) =>
    transactionFields.reduce((obj, transactionField) => {
      obj[Object.keys(transactionField)[0]] = gpt_transactions[Object.values(transactionField)[0]];
      return obj;
    }, {});

  return TABLE_COLUMNS.map(tableColumn => {
    const tmpTableColumn = {};
    transactions.forEach(transaction => {
      const transactionId = transaction.row_ref || transaction.id;
      tmpTableColumn[transactionId] = getComparisonFields(transaction, tableColumn.fields);
      const isMultiple = MULTIPLE_COLUMNS_ALIAS.indexOf(tableColumn.id) >= 0;
      if (!isUndefined(financials) && isMultiple) {
        const ltmCompanyInfoKey = FINANCIALS_COMPANY_INFO_KEYS[tableColumn.id];
        tmpTableColumn[COMPANY] = financials[ltmCompanyInfoKey];
      }
    });
    tableColumn = addApproachDataInColumn(tableColumn, approach);
    return { ...tableColumn, ...tmpTableColumn };
  });
};

export default rowTransformer;
