import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, makeStyles, useMediaQuery } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { DOCUMENT_REQUEST_EXPIRED } from 'common/constants/documents';
import { DOCUMENTS_URL_PARAM, QUESTIONS_URL_PARAM } from 'common/constants/process-management';
import { useStore } from 'common/store';
import { MessageBox } from 'components';
import { TaskContext } from 'context';
import ProcessManagementFooter from 'pages/ProcessManagement/components/ProcessManagementFooter';
import ProcessManagementHeader from 'pages/ProcessManagement/components/ProcessManagementHeader';
import { useDocuments } from 'services/hooks';
import { useTasks } from 'services/hooks/useTasks';
import theme from 'theme';

const useStyles = makeStyles({
  messageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
});

const TaskLayout = ({ children }) => {
  const classes = useStyles();
  const { requestId, hmacToken, processType } = useParams();
  const { validateDocumentRequestToken } = useDocuments();
  const { validateQuestionnairesRequestToken } = useTasks();
  const [isValidToken, setIsValidToken] = useState(false);
  const [task, setTask] = useState({});
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [companyDocuments, setCompanyDocuments] = useState([]);
  const headerMenuItemsInitialState = {
    left: [],
    right: [],
  };
  const [headerMenuItems, setHeaderMenuItems] = useState(headerMenuItemsInitialState);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [questionnaireUrl, setQuestionnaireUrl] = useState('');
  const [documentUrl, setDocumentUrl] = useState('');
  const [{ user }] = useStore();

  const validateToken = useCallback(async () => {
    let response = {};
    if (processType === DOCUMENTS_URL_PARAM) {
      response = await validateDocumentRequestToken(requestId, hmacToken);
    } else if (processType === QUESTIONS_URL_PARAM) {
      response = await validateQuestionnairesRequestToken(requestId, hmacToken);
    }

    if (response.task) {
      setTask(response);
      setIsValidToken(true);
    } else {
      setIsValidToken(false);
    }
    setIsLoadingPage(false);
  }, [hmacToken, processType, requestId, validateDocumentRequestToken, validateQuestionnairesRequestToken]);

  useEffect(() => {
    (async () => {
      try {
        await validateToken();
      } catch (err) {
        setIsValidToken(false);
        throw new Error(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processType, user]);

  function setPairedUrls(taskParam, setDocumentUrlParam, setQuestionnaireUrlParam) {
    if (taskParam.paired_document_request) {
      setDocumentUrlParam(
        `/${DOCUMENTS_URL_PARAM}/requests/${taskParam.paired_document_request.document_request_id}/${taskParam.paired_document_request.document_token}`
      );
    }
    if (taskParam.paired_questionnaire) {
      setQuestionnaireUrlParam(
        `/${QUESTIONS_URL_PARAM}/requests/${taskParam.paired_questionnaire.questionnaire_id}/${taskParam.paired_questionnaire.questionnaire_token}`
      );
    }
  }

  useEffect(() => {
    setPairedUrls(task, setDocumentUrl, setQuestionnaireUrl);
  }, [task]);

  const taskProviderValue = useMemo(
    () => ({
      task,
      headerMenuItems,
      setHeaderMenuItems,
      isMobile,
      companyDocuments,
      setCompanyDocuments,
      questionnaireUrl,
      documentUrl,
      validateToken,
    }),
    [companyDocuments, documentUrl, headerMenuItems, isMobile, questionnaireUrl, task, validateToken]
  );

  if (isLoadingPage) {
    return null;
  }

  if (!isValidToken && isEmpty(task)) {
    return (
      <div className={classes.messageBox}>
        <MessageBox
          tagline={DOCUMENT_REQUEST_EXPIRED}
          type={'error-inside'}
          fullWidth={false}
          hasFrame={false}
          hasBgColor={false}
        />
      </div>
    );
  }

  return (
    <TaskContext.Provider value={taskProviderValue}>
      <div
        style={{
          background:
            'linear-gradient(254.7deg, #FFFFFF 50.36%, rgba(255, 255, 255, 0) 120.31%), url(/images/login-white-bg.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}>
        <Container
          style={{
            maxWidth: '100rem',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          <ProcessManagementHeader />
          {children}
          <ProcessManagementFooter />
        </Container>
      </div>
    </TaskContext.Provider>
  );
};

TaskLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TaskLayout;
