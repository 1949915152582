/* eslint-disable no-tabs */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import {
  ALLOCATION_METHODS,
  BACKSOLVE,
  BACKSOLVE_VALUATION_TABLE_ID,
  BASKET_TABLE_ID,
  CAP_TABLE_ROW_NUMBER,
  OPM,
  OPM_TABLE_ID,
  SCENARIO_METHOD_ROW_NUMBER,
  SCENARIO_METHODS_BACKSOLVE,
  TARGET_VALUE_TABLE_ID,
  WATERFALL,
  WATERFALL_METHOD,
} from 'common/constants/allocations';
import { useStore } from 'common/store';
import { MessageBox } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { GridSkeleton } from 'components/Grid';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import AllocationContext from 'context/AllocationContext';
import BacksolveContext from 'context/BacksolveContext';
import { getValidSecurities } from 'pages/CapTable/cap-table/utilities';
import { getEditableSecurityValues, getOpmData } from 'pages/Valuations/approaches/backsolveApproach/BacksolveApproach';
import BacksolveTable from 'pages/Valuations/approaches/backsolveApproach/BacksolveTable/BacksolveTable';
import createBacksolveTableData from 'pages/Valuations/approaches/backsolveApproach/BacksolveTable/createBacksolveTableData';
import calculateWeightedPerShareValues from 'pages/Valuations/approaches/backsolveApproach/calculateWeightedPerShareValue';
import {
  ALLOCATION_METHOD_ALIAS,
  CAP_TABLE_ALIAS,
  METRICS,
  TARGET_VALUE_ALIAS,
} from 'pages/Valuations/approaches/backsolveApproach/constants';
import createOpmInputData from 'pages/Valuations/approaches/backsolveApproach/OPMInputTable/createOpmInputData';
import OPMInputTable from 'pages/Valuations/approaches/backsolveApproach/OPMInputTable/OPMInputTable';
import createSecurityTableData from 'pages/Valuations/approaches/backsolveApproach/SecurityTable/createSecurityTableData';
import SecurityTable from 'pages/Valuations/approaches/backsolveApproach/SecurityTable/SecurityTable';
import getRowConfig from 'pages/Valuations/approaches/backsolveApproach/SecurityTable/util/getRowConfig';
import useCalculateEquityValue from 'pages/Valuations/approaches/backsolveApproach/useCalculateEquityValue';
import { EMPTY_BASKET } from 'pages/ValuationsAllocation/util';
import { useReadFieldAttributes } from 'services/hooks';
import {
  BACKSOLVE_APPROACH_MODEL_NAME,
  GPC_BACKSOLVE_RELATION_MODEL_NAME,
  METHODOLOGY_PRESENT_SHARE_VALUE_MODEL_NAME,
  PUBLIC_COMPANY_ADJUSTMENT_STATUS_MODEL_NAME,
  VALUATIONS_PAGE,
} from 'services/hooks/useReadFieldAttributes/constants';
import { getLedgerKey } from 'utillities';
import AllocationMethodCard from './AllocationMethodCard';
import EquityValueCard from './EquityValueCard';
import { SelectButton } from '../future-exit-ledger/components';

const useStyles = makeStyles(theme => ({
  // Dialog
  dialogTitle: {
    position: 'relative',
    boxShadow: '0 1px 2px rgba(68, 68, 68, 0.3)',
    padding: '8px 40px 16px 40px',
  },
  dialogActions: {
    padding: '24px 40px',
  },
  // Equity Value
  equityValueContainer: {
    backgroundColor: '#f1f4f6',
  },
  infoCardContainer: {
    padding: '16px 40px',
  },
  card: {
    padding: '16px 0',
    textAlign: 'center',
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  cardBody: {
    fontFamily: theme.typography.mainFont,
    fontSize: '1.125em',
  },
  cardSkeleton: {
    width: '25%',
    height: 36,
    margin: 'auto',
  },
  cardDivider: {
    backgroundColor: theme.palette.secondary.main,
    height: 2,
    width: '20%',
    margin: `${theme.spacing()}px auto`,
  },
  // Utils
  fullWidth: {
    width: '100%',
  },
  flexGrow: {
    flexGrow: 1,
  },
  noPadding: {
    padding: 0,
  },
  padding: {
    padding: '8px 40px',
  },
  margin: {
    marginTop: theme.spacing(2),
  },
}));

const emptyRow = {
  security: '',
  shares: '',
  per_share: '',
  value: '',
};

const getEmptyAproach = capTable => ({
  allocation_method: WATERFALL_METHOD,
  applied_methodologies: [
    { allocation_method: WATERFALL.toString(), cap_table: capTable, weight: '1', present_share_values: [] },
  ],
  adjust_for_market: false,
  cap_table: capTable,
  metric: METRICS[0],
  as_of_date: '',
  securities_basket: {
    ...EMPTY_BASKET,
  },
});

const BacksolveLedger = ({ cell, closeDialog }) => {
  const classes = useStyles();
  // create spreadsheets from configurations
  const { measurementDate, ltmData, valuationInfo } = useContext(AllocationContext);
  const { cells: allocationCells, onCellsChanged, format } = useContext(FeaturedSpreadsheetContext);
  const { currency } = format;
  const [prevEditableSecurityValues, setPrevEditableSecurityValues] = useState();
  const [prevOpmData, setPrevOpmData] = useState();
  const [scenarioEquityValue, setScenarioEquityValue] = useState();
  const [prevTargetValue, setPrevTargetValue] = useState();
  const [valuationApproaches, setValuationApproaches] = useState();
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [backsolveValuation, setBacksolveValuation] = useState({});
  const [{ capTableList }] = useStore();
  const fieldAttributesMap = useReadFieldAttributes(VALUATIONS_PAGE);
  const [distributionValues, setDistributionValues] = useState();

  const [calculateEquityValue, isLoading] = useCalculateEquityValue();

  const backsolveAttributes = useMemo(
    () => ({
      backsolveApproachAttrs: fieldAttributesMap.get(BACKSOLVE_APPROACH_MODEL_NAME),
      shareValueAttrs: fieldAttributesMap.get(METHODOLOGY_PRESENT_SHARE_VALUE_MODEL_NAME),
      publicCompAdjustmentAttrs: fieldAttributesMap.get(PUBLIC_COMPANY_ADJUSTMENT_STATUS_MODEL_NAME),
      gpcBacksolveRelationAttrs: fieldAttributesMap.get(GPC_BACKSOLVE_RELATION_MODEL_NAME),
    }),
    [fieldAttributesMap]
  );

  const ledgerKey = getLedgerKey(cell.columnLegend);
  const parentColumnLegend = cell?.columnLegend;

  const usedBacksolveApproachIds = useMemo(() => {
    // filter the allocationCells to find only those that have the word 'LEDGER' in the key
    const ledgerCells = Object.keys(allocationCells).filter(key => key.includes('LEDGER'));
    // for each key, look up the related cell and return the 'BACKSOLVE_VALUATION' property
    return ledgerCells.map(key => allocationCells[key].BACKSOLVE_VALUATION?.valuation_approach);
  }, [allocationCells]);

  const getScenarioValue = useCallback(
    row => {
      if (allocationCells) {
        const scenarioCell = allocationCells[parentColumnLegend + row];
        if (scenarioCell) {
          return scenarioCell.value;
        }
      }
      return undefined;
    },
    [allocationCells, parentColumnLegend]
  );

  const scenarioMethod = useMemo(() => getScenarioValue(SCENARIO_METHOD_ROW_NUMBER), [getScenarioValue]);

  const securitiesBasket = useMemo(() => {
    if (!isEmpty(backsolveValuation)) {
      const { basket, target_value: targetValue } = backsolveValuation.valuations_approach_backsolve.securities_basket;
      return {
        basket,
        target_value: targetValue,
      };
    }
    const ledgerData = allocationCells[ledgerKey];
    const basket = isEmpty(ledgerData?.[BASKET_TABLE_ID]) ? [{ ...emptyRow }] : ledgerData?.[BASKET_TABLE_ID];
    const targetValue = ledgerData?.[TARGET_VALUE_TABLE_ID] || [{ alias: 'target_value', value: undefined }];
    return {
      basket,
      target_value: targetValue?.[0]?.value,
    };
  }, [allocationCells, ledgerKey, backsolveValuation]);

  const securityList = useMemo(() => getScenarioValue(CAP_TABLE_ROW_NUMBER)?.securities, [getScenarioValue]);

  const getInitialData = useCallback(() => {
    const securityIds = securityList.map(s => s.id);
    const ledgerData = allocationCells[ledgerKey];
    const basket = ledgerData?.[BASKET_TABLE_ID];

    if (basket && Array.isArray(basket)) {
      setScenarioEquityValue(cell.value);

      basket.forEach(item => {
        if (!securityIds.includes(item.security)) {
          item.security = 0;
        }
      });
    }
  }, [allocationCells, cell, ledgerKey, securityList]);

  useEffect(() => {
    if (allocationCells) {
      getInitialData();
    }
  }, [allocationCells, getInitialData]);

  const [spreadsheets, setSpreadsheets] = useState();

  const captable = useMemo(() => getScenarioValue(CAP_TABLE_ROW_NUMBER), [getScenarioValue]);

  useEffect(() => {
    if (securitiesBasket && captable && (!isEmpty(backsolveValuation) || isAddingNew)) {
      const attrs = backsolveAttributes.backsolveApproachAttrs;
      const backsolveAndPVAttrs = {
        ...backsolveAttributes.backsolveApproachAttrs,
        ...backsolveAttributes.shareValueAttrs,
      };

      const backsolveSheet = createBacksolveTableData({
        approach: backsolveValuation,
        captableList: capTableList,
        attrs: backsolveAndPVAttrs,
        tableName: 'backsolveSheet',
        isDisabled: cell.isDisabled,
      });

      const securitySheetName = 'securitySheet';

      const securitySheet = createSecurityTableData({
        captable,
        tableName: securitySheetName,
        securitiesBasket,
        isDisabled: cell.isDisabled,
      });

      const opmTableName = 'opmInputSheet';
      const opmInputSheet = createOpmInputData({
        tableName: opmTableName,
        data: backsolveValuation.valuations_approach_backsolve,
        attrs,
        measurementDate,
        approaches: valuationInfo?.valuations_approaches,
        isDisabled: cell.isDisabled,
      });

      setSpreadsheets([backsolveSheet, securitySheet, opmInputSheet]);
    }
  }, [
    securitiesBasket,
    captable,
    allocationCells,
    ledgerKey,
    backsolveAttributes,
    backsolveValuation,
    capTableList,
    isAddingNew,
    measurementDate,
    valuationInfo,
    cell.isDisabled,
  ]);

  useEffect(() => {
    if (valuationInfo?.valuations_approaches) {
      const getCapTableId = appliedMethodologies => {
        if (appliedMethodologies?.length) {
          return appliedMethodologies.map(method => method.cap_table)[0];
        }
        throw new Error('No cap table found for this approach');
      };

      const backsolveApproaches = valuationInfo?.valuations_approaches.filter(
        approach =>
          approach?.approach_type.includes('Backsolve')
          && getCapTableId(approach.valuations_approach_backsolve.applied_methodologies) === captable.id
          && !usedBacksolveApproachIds.includes(approach.id)
      );

      setValuationApproaches(backsolveApproaches);
    }
  }, [valuationInfo, scenarioMethod, captable, usedBacksolveApproachIds]);

  useEffect(() => {
    if (!isEmpty(valuationInfo?.valuations_approaches)) {
      const ledgersData = allocationCells[ledgerKey] || {};
      const valuationApp = ledgersData[BACKSOLVE_VALUATION_TABLE_ID];

      if (isEmpty(valuationApp)) return;

      const valuation = {
        valuations_approach_backsolve: valuationApp,
      };

      setBacksolveValuation(valuation);
    }
  }, [allocationCells, captable.id, cell, ledgerKey, valuationInfo]);

  const { onChange: onSpreadsheetChange, workbook, cells, data, areCellsValid } = useWorkbook(spreadsheets);

  const targetValueColumnLegend = 'D';

  const getTargetValue = useCallback(() => {
    const rowIndex = data?.securitySheet.tableData.securitiesBasket.basket.length + 2;
    const cellKey = `${targetValueColumnLegend}${rowIndex}`;
    return data?.securitySheet.cells[cellKey]?.value;
  }, [data]);

  const getAppliedMethodologies = useCallback(
    () => (data ? data.backsolveSheet.tableData.approach.valuations_approach_backsolve.applied_methodologies : []),
    [data]
  );

  const save = useCallback(() => {
    if (areCellsValid()) {
      const { basket } = securitiesBasket;

      const tmpCells = { ...allocationCells };
      const changes = [];

      const tmpDialogData = {
        ...securitiesBasket,
        basket: [...basket],
      };

      const opmInputs = Object.entries(data?.opmInputSheet.tableData.data).map(([alias, value]) => ({ value, alias }));
      const methodologies = getAppliedMethodologies().map(({ allocation_method }) => allocation_method);
      const allocationMethodCell = tmpCells[`${cell.columnLegend}1`];
      changes.push({ cell: allocationMethodCell, value: methodologies });

      let tmpDistributionValues = distributionValues ?? [];
      const pvs = tmpDistributionValues.map(v => v.presentValues);
      const aggvs = tmpDistributionValues.map(v => v.aggregateValues);
      const fvs = tmpDistributionValues.map(v => v.futureValues);

      const currentMethodologies = getAppliedMethodologies();

      tmpDistributionValues = {
        present_values: calculateWeightedPerShareValues(pvs, currentMethodologies, true),
        aggregate_values: calculateWeightedPerShareValues(aggvs, currentMethodologies, true),
        future_values: calculateWeightedPerShareValues(fvs, currentMethodologies, true),
      };

      const ledgerCell = tmpCells[ledgerKey];
      ledgerCell[BASKET_TABLE_ID] = tmpDialogData.basket;
      ledgerCell[TARGET_VALUE_TABLE_ID] = [{ alias: 'target_value', value: getTargetValue() }];
      ledgerCell[OPM_TABLE_ID] = opmInputs;
      ledgerCell[BACKSOLVE_VALUATION_TABLE_ID] = {
        ...backsolveValuation.valuations_approach_backsolve,
      };
      ledgerCell.distributionValues = tmpDistributionValues;
      // here we need to include the distribution values and the resulting equity value
      changes.push({
        cell: ledgerCell,
        value: ledgerCell.value,
      });
      const equityValueCell = tmpCells[cell.key];

      changes.push({ cell: equityValueCell, value: Number(scenarioEquityValue).toFixed(2) });

      onCellsChanged(changes);
      closeDialog();
    }
  }, [
    distributionValues,
    allocationCells,
    getTargetValue,
    areCellsValid,
    cell,
    closeDialog,
    data,
    ledgerKey,
    scenarioEquityValue,
    securitiesBasket,
    onCellsChanged,
    backsolveValuation,
    getAppliedMethodologies,
  ]);

  useEffect(() => {
    if (!isEmpty(backsolveValuation)) {
      setScenarioEquityValue(cell.value ?? backsolveValuation?.valuations_approach_backsolve.implied_equity_value ?? 0);
    }
  }, [backsolveValuation, cell.value]);

  const getSecurityRows = useCallback(
    () => backsolveValuation?.valuations_approach_backsolve?.securities_basket.basket,
    [backsolveValuation]
  );

  const getAllocationMethods = useCallback(
    () => backsolveValuation?.valuations_approach_backsolve?.applied_methodologies ?? {},
    [backsolveValuation]
  );

  const securityRows = useMemo(() => {
    if (backsolveValuation) {
      return getSecurityRows();
    }
    return [];
  }, [getSecurityRows, backsolveValuation]);

  const allocationMethods = useMemo(() => {
    if (backsolveValuation) {
      return getAllocationMethods();
    }
    return [];
  }, [getAllocationMethods, backsolveValuation]);

  const allocation = useMemo(() => valuationInfo.allocation_id, [valuationInfo.allocation_id]);

  const checkAllocationMethods = useCallback(() => {
    const appliedMethodologies = getAppliedMethodologies();

    if (isEmpty(appliedMethodologies)) {
      return '';
    }

    const allocationMethodsNames = appliedMethodologies.map(am => ALLOCATION_METHODS[am.allocation_method]);

    return allocationMethodsNames.join(', ');
  }, [getAppliedMethodologies]);

  const getScenarioMethod = useCallback(() => data?.backsolveSheet.cells.allocationMethod.value, [data]);

  const checkIfIsOpm = useCallback(() => {
    const methods = getAppliedMethodologies();

    if (isEmpty(methods)) return false;

    return methods.some(x => Number(x.allocation_method) === Number(OPM));
  }, [getAppliedMethodologies]);

  const [isOpm, setOpm] = useState(checkIfIsOpm());
  const [allocationMethodCardValue, setAllocationMethodCardValue] = useState(checkAllocationMethods());

  useEffect(() => {
    if (checkIfIsOpm() !== isOpm) {
      setOpm(checkIfIsOpm());
    }
  }, [checkIfIsOpm, isOpm]);

  useEffect(() => {
    if (checkAllocationMethods() !== allocationMethodCardValue) {
      setAllocationMethodCardValue(checkAllocationMethods());
    }
  }, [checkAllocationMethods, allocationMethodCardValue]);

  const calculateEquityValues = useCallback(() => {
    const equityArgs = {
      captable,
      scenarioType: BACKSOLVE,
      allocation,
      appliedMethodologies: getAppliedMethodologies(),
      opmData: getOpmData(data),
      securityRows,
      scenarioMethod: getScenarioMethod(),
      targetValue: getTargetValue(),
      prevOpmData,
      editableLedgerCells: getEditableSecurityValues(securityRows),
      prevEditableLedgerCells: prevEditableSecurityValues,
      prevTargetValue,
      securityList,
      spreadsheets: data,
      onCellsChanged: onSpreadsheetChange,
    };

    calculateEquityValue(equityArgs).then(({ equityValue, newDistribution }) => {
      const ledgerData = allocationCells[ledgerKey];
      const hasAdjustment = ledgerData.BACKSOLVE_VALUATION?.adjust_for_market ?? false;
      if (hasAdjustment && equityValue) {
        const debt = Number(ltmData?.balance_sheet?.total_debt ?? 0);
        const cash = Number(ltmData?.balance_sheet?.total_cash_equivalents ?? 0);
        const enterpriseValue = equityValue + debt - cash;
        const marketAdjustment = Number(ledgerData.BACKSOLVE_VALUATION?.market_adjustment ?? 0);
        const adjustedEnterpriseValue = enterpriseValue + enterpriseValue * marketAdjustment;
        const adjustedEquityValue = adjustedEnterpriseValue - debt + cash;
        setScenarioEquityValue(adjustedEquityValue);
      } else {
        setScenarioEquityValue(equityValue);
      }
      setDistributionValues(newDistribution);
    });
  }, [
    allocation,
    allocationCells,
    calculateEquityValue,
    captable,
    data,
    getAppliedMethodologies,
    getScenarioMethod,
    getTargetValue,
    ledgerKey,
    ltmData,
    onSpreadsheetChange,
    prevEditableSecurityValues,
    prevOpmData,
    prevTargetValue,
    securityList,
    securityRows,
  ]);

  const [initialEquityValuesLoaded, setInitialEquityValuesLoaded] = useState(false);

  useEffect(() => {
    if (
      data?.backsolveSheet
      && data?.securitySheet
      && isUndefined(distributionValues)
      && cells
      && initialEquityValuesLoaded === false
    ) {
      setInitialEquityValuesLoaded(true);
      calculateEquityValues();
    }
  }, [calculateEquityValues, data, distributionValues, initialEquityValuesLoaded, cells]);

  const onChange = useCallback(
    (cell, value) => {
      if (cell.alias === ALLOCATION_METHOD_ALIAS) {
        setOpm(checkIfIsOpm());
        setAllocationMethodCardValue(checkAllocationMethods());
      }
      if (cell.alias === TARGET_VALUE_ALIAS) {
        setPrevTargetValue(cell.value);
      }
      if (cell.alias === CAP_TABLE_ALIAS && !capTableList.map(({ id }) => id).includes(Number(value))) {
        return;
      }
      onSpreadsheetChange(cell, value);
      calculateEquityValues();
    },
    [onSpreadsheetChange, calculateEquityValues, checkIfIsOpm, checkAllocationMethods, capTableList]
  );

  const addSecurity = useCallback(() => {
    const { securitySheet } = data;
    securitiesBasket.basket.push({
      ...emptyRow,
    });

    securitySheet.reset({
      tableData: securitySheet.tableData,
      rowConfig: getRowConfig(securitiesBasket.basket),
    });
    setSpreadsheets([...spreadsheets]);
  }, [data, securitiesBasket, spreadsheets]);

  const deleteSecurity = useCallback(
    securityIndex => {
      const { securitySheet } = data;
      securitiesBasket.basket.splice(securityIndex - 1, 1);
      securitySheet.reset({
        tableData: securitySheet.tableData,
        rowConfig: getRowConfig(securitiesBasket.basket),
      });
      if (securitiesBasket.basket.length === 0) {
        addSecurity();
      }
      setSpreadsheets([...spreadsheets]);
    },
    [addSecurity, data, securitiesBasket.basket, spreadsheets]
  );

  const addAllocationMethod = useCallback(() => {
    const defaultCapTable = capTableList?.length ? capTableList.find(ct => ct.is_primary)?.id : undefined;

    const { backsolveSheet } = data;
    const { applied_methodologies: currentAllocationMethods }
      = backsolveSheet.tableData.approach.valuations_approach_backsolve;
    currentAllocationMethods.push({
      allocation_method: WATERFALL.toString(),
      cap_table: defaultCapTable,
      weight: '',
      maturity: null,
      volatility: null,
      present_share_values: [],
    });

    backsolveSheet.reset({
      tableData: backsolveSheet.tableData,
      columns: [[], ...currentAllocationMethods],
    });
    setSpreadsheets([...spreadsheets]);
  }, [spreadsheets, capTableList, data]);

  const deleteAllocationMethod = useCallback(
    allocationMethodIndex => {
      const { backsolveSheet } = data;

      const { applied_methodologies: currentAllocationMethods }
        = backsolveSheet.tableData.approach.valuations_approach_backsolve;
      // if it was stored I want to store the deleted id in "deleted_methodologies"
      if (currentAllocationMethods.length > 1) {
        const deletedMethod = currentAllocationMethods.splice(allocationMethodIndex - 1, 1);
        const deletedMethodId = deletedMethod[0].id;

        if (deletedMethodId) {
          backsolveSheet.tableData.approach.valuations_approach_backsolve.deleted_methodologies.push(deletedMethodId);
        }

        backsolveSheet.reset({
          tableData: backsolveSheet.tableData,
          columns: [[], ...currentAllocationMethods],
        });

        setSpreadsheets([...spreadsheets]);
        setOpm(checkIfIsOpm());
      }
    },
    [checkIfIsOpm, data, spreadsheets]
  );

  const BacksolveContextData = useMemo(
    () => ({
      spreadsheets: data,
      securityList,
      measurementDate,
    }),
    [data, securityList, measurementDate]
  );

  const selectApproach = useCallback(
    approach => {
      setIsAddingNew(true);
      const initialApproach = {
        approach_type: 'Backsolve Valuation',
        valuations_approach_backsolve: getEmptyAproach(captable.id),
      };

      setBacksolveValuation(approach || initialApproach);
    },
    [captable]
  );

  if (!isAddingNew && !spreadsheets && valuationApproaches && isEmpty(backsolveValuation)) {
    return (
      <>
        <MessageBox
          title="Backsolve Valuation"
          tagline="Select an existing Backsolve approach or add a new one."
          action={
            <SelectButton
              valuationApproaches={valuationApproaches}
              addNew={() => setIsAddingNew(true)}
              selectApproach={selectApproach}
              label="Select Backsolve"
            />
          }
          showIcon={false}
          hasFrame={false}
        />
        <DialogActions className={classes.dialogActions}>
          <Button id="backsolve-ledger-cancel-btn" color="primary" onClick={closeDialog}>
            Cancel
          </Button>
        </DialogActions>
      </>
    );
  }

  if (spreadsheets && cells && data) {
    return (
      <div id="backsolve-ledger" data-testid="backsolve-ledger">
        <DialogTitle id="backsolve-ledger-title" className={classes.dialogTitle}>
          Backsolve Ledger
        </DialogTitle>
        <DialogContent className={classes.noPadding}>
          <div className={classes.flexGrow}>
            {!isEmpty(cells) && (
              <Grid container justifyContent="center" classes={{ root: classes.equityValueContainer }}>
                <Grid item xs={5} classes={{ root: classes.infoCardContainer }}>
                  <EquityValueCard
                    title="Implied Equity Value"
                    isLoading={isLoading}
                    scenarioEquityValue={scenarioEquityValue}
                    currency={currency}
                  />
                </Grid>
                <Grid item xs={5} classes={{ root: classes.infoCardContainer }}>
                  <AllocationMethodCard
                    title="Allocation Methods"
                    isLoading={isLoading}
                    allocationMethods={allocationMethodCardValue}
                  />
                </Grid>
              </Grid>
            )}
            <div className={`${classes.margin} ${classes.padding}`}>
              <BacksolveContext.Provider value={BacksolveContextData}>
                <BacksolveTable
                  spreadsheets={data}
                  onChange={onChange}
                  workbook={workbook}
                  deleteColFn={deleteAllocationMethod}
                  isLedgerTable
                />
                <br />
                <div>
                  <Button
                    className="btn-table-aligned"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={addAllocationMethod}
                    disabled={allocationMethods.length >= SCENARIO_METHODS_BACKSOLVE.length || cell.isDisabled}>
                    Add Allocation Method
                  </Button>
                </div>
                <br />

                <SecurityTable
                  spreadsheets={data}
                  onChange={onChange}
                  workbook={workbook}
                  setPrevEditableSecurityValues={setPrevEditableSecurityValues}
                  securities={securitiesBasket.basket}
                  securityList={securityList}
                  deleteRowFn={deleteSecurity}
                  isLedgerTable
                />

                <br />
                <div>
                  <Button
                    variant="outlined"
                    className="btn-table-aligned"
                    startIcon={<AddIcon />}
                    disabled={securityRows.length >= getValidSecurities(captable).length || cell.isDisabled}
                    onClick={addSecurity}>
                    Add Row
                  </Button>
                </div>
                <br />

                <Box width="100%" display="flex" flexDirection="row">
                  {isOpm && (
                    <OPMInputTable
                      spreadsheets={data}
                      onChange={onChange}
                      setPrevOpmData={setPrevOpmData}
                      fullWidth={false}
                      isLedgerTable
                      workbook={workbook}
                      measurementDate={measurementDate}
                    />
                  )}
                </Box>
              </BacksolveContext.Provider>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button id="backsolve-ledger-cancel-btn" color="primary" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            id="backsolve-ledger-save-btn"
            disabled={isLoading || isEmpty(cells) || !areCellsValid() || cell.isDisabled}
            onClick={save}
            variant="contained"
            color="secondary">
            Save
          </Button>
        </DialogActions>
      </div>
    );
  }
  return <GridSkeleton />;
};

BacksolveLedger.propTypes = {
  columnLegend: PropTypes.string,
  cell: PropTypes.object,
  closeDialog: PropTypes.func,
};

export default BacksolveLedger;
