/**
 * @function
 * @name setCaptableCompany
 * @description reducer for action.
 * @param {Object} state  - reducer state
 * @param {Object} action - action context
 * @return {OBJECT} Action context
 */
export const setCaptableCompany = (state, action) => ({
  ...state,
  ...action
});

/**
 * @function
 * @name setCaptableInfo
 * @description reducer for action.
 * @param {Object} state  - reducer state
 * @param {Object} action - action context
 * @return {OBJECT} Action context
 */
export const setCaptableInfo = (state, action) => ({
  ...state,
  ...action
});

export const getCapTableInfo = (state, action) => ({
  ...state,
  ...action
});

export const createCapTableProforma = (state, action) => ({
  ...state,
  ...action
});
