import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SelectValueViewer } from 'components';
import CompGroupContext from 'context/CompGroupContext';
import { getSelectionCellOptions } from 'pages/ValuationsAllocation/util/getSelectionCellOptions';

const MultipleTypeValueViewer = props => {
  const { cellOptions } = useContext(CompGroupContext);
  const cellOptionsData = cellOptions || props.performanceCellOptions;
  const options = getSelectionCellOptions({
    specificApproach: cellOptionsData,
  });
  return <SelectValueViewer options={options} useLabel {...props} />;
};

MultipleTypeValueViewer.propTypes = {
  performanceCellOptions: PropTypes.object,
};

export default MultipleTypeValueViewer;
