import { has, isNull } from 'lodash';
import {
  DRAG_OVER,
  DRAGGABLE,
  DRAGGING,
  DRAGGING_ELEMENT,
  DRAGGING_ELEMENT_TYPE,
  DROPPABLE,
  FILE,
  FOLDER,
} from 'common/constants/documents';
import useDocumentsContext from 'context/DocumentsContext';
import useDocuments from 'services/hooks/useDocuments';

const useDocumentsDragAndDrop = ({ validations, selectedMeasurementDateId }) => {
  const { company_id, setCompanyDocuments, setIsLoading, currentDocuments } = useDocumentsContext();
  const { getDocuments, updateSubFolder, addFilesToFolder, addDocumentReference } = useDocuments();

  const setLocalStorageInfo = (data, key = DRAGGING_ELEMENT) => {
    if (data) {
      const dataToStore = key === DRAGGING_ELEMENT ? JSON.stringify(data) : data;
      localStorage.setItem(key, dataToStore);
    } else {
      localStorage.removeItem(key);
    }
  };

  const getElementType = () => localStorage.getItem(DRAGGING_ELEMENT_TYPE);

  const setElementType = data => {
    const isFile = has(data, FILE);
    const elementType = isFile ? FILE : FOLDER;
    setLocalStorageInfo(elementType, DRAGGING_ELEMENT_TYPE);
  };

  const getDraggableContent = (event, droppableNeeded = false) => {
    const { target } = event;
    let foundElement = target;
    const classToFind = droppableNeeded ? DROPPABLE : DRAGGABLE;
    const containFindingClass = target.classList?.contains(classToFind);
    if (!containFindingClass) {
      foundElement = target.closest(`.${classToFind}`);
    }
    return foundElement;
  };

  const removeStylesOf = className => {
    const foundElements = document.querySelectorAll(`.${className}`);

    foundElements.forEach(item => {
      item.classList.remove(className);
    });
  };

  const handleDragStart = (event, data) => {
    setElementType(data);
    const draggableContent = getDraggableContent(event, true);
    if (draggableContent) draggableContent.classList.add(DRAGGING);
    event.dataTransfer.setData('draggableContent', JSON.stringify(data));
    setLocalStorageInfo(data);
    event.stopPropagation();
  };

  const handleDragEnd = event => {
    removeStylesOf(DRAGGING);
    removeStylesOf(DRAG_OVER);
    setLocalStorageInfo(null);
    setLocalStorageInfo(null, DRAGGING_ELEMENT_TYPE);
    event.stopPropagation();
    event.preventDefault();
    return false;
  };

  const handleDragOver = event => {
    removeStylesOf(DRAG_OVER);
    if (event.preventDefault) {
      event.preventDefault();
    }
    const dragOverFolder = getDraggableContent(event, true);
    const folderId = dragOverFolder.getAttribute('data-id');
    const newParentFolderId = folderId ? Number(folderId) : null;
    const elementType = getElementType();
    if (
      validations.isValidData(newParentFolderId, elementType)
      && validations.shouldApplyDragOverStyle(dragOverFolder, elementType)
    ) {
      dragOverFolder.classList.add(DRAG_OVER);
    }
  };

  const isOnCurrentDragOver = target => !isNull(target.closest(`.${DRAG_OVER}`));
  const isOverMainFoldersContent = () => {
    const mainFolder = document.querySelector('#folders-content>.main-folder');
    if (!mainFolder?.classList) return false;
    return mainFolder.classList?.contains(DRAG_OVER);
  };

  const handleDragLeave = event => {
    if (!isOnCurrentDragOver(event?.target) || !isOverMainFoldersContent()) {
      const dragOverFolder = getDraggableContent(event, true);
      if (dragOverFolder) dragOverFolder.classList.remove(DRAG_OVER);
    }
  };

  const refreshDocuments = async () => {
    setCompanyDocuments(await getDocuments(company_id));
  };

  const getDataOfDraggingElement = event => {
    const folderData = event.dataTransfer.getData('draggableContent');
    return folderData ? JSON.parse(folderData) : null;
  };

  // Start of methods to use when is dragging a folder
  const updateFolderData = async (folderData, parentFolder) => {
    const {
      id: folderId,
      name: folderName,
      parent_folder: currentParentFolder,
      md_documents: documentsId,
    } = folderData;
    const currentParentFolderId = currentParentFolder?.id || null;
    if (folderId !== parentFolder && parentFolder !== currentParentFolderId) {
      const data = {
        name: folderName,
        parent_folder: parentFolder,
      };
      await updateSubFolder(documentsId, folderId, data);
    }
  };

  const saveFolderData = async (event, folder, elementType) => {
    const folderData = getDataOfDraggingElement(event);
    const parentFolder = folder?.id || null;

    if (folderData && validations.isValidData(parentFolder, elementType)) {
      setIsLoading(true);
      await updateFolderData(folderData, parentFolder);
      setIsLoading(false);
    }
  };
  // End of methods to use when is dragging a folder

  // Start of methods to use when is dragging a file
  const updateFileData = async (fileData, newParentFolder) => {
    const {
      document_reference_id: documentReferenceId,
      md_documents: mdDocuments,
      folder: currentParentFolderId,
    } = fileData;

    const selectedMDCurrentDocumentsId = currentDocuments?.id || null;
    const isFileFromSelectedMD = selectedMDCurrentDocumentsId === fileData.md_documents;

    const currentDocumentFoldersIds = currentDocuments?.folders?.map(({ id }) => id) || [];
    const isDestinationFolderInCurrentMdDocuments = currentDocumentFoldersIds.includes(newParentFolder);

    const isMovedToRoot = !newParentFolder && currentParentFolderId;
    const isMovedToAnotherFolder = newParentFolder && newParentFolder !== currentParentFolderId;

    if (isFileFromSelectedMD && (isMovedToRoot || isMovedToAnotherFolder)) {
      const data = {
        folder_id: newParentFolder,
        document_references_ids: [documentReferenceId],
        md_documents_id: mdDocuments,
      };
      await addFilesToFolder(mdDocuments, data);
    } else if (newParentFolder && !isDestinationFolderInCurrentMdDocuments) {
      // do nothing
    } else {
      // create a new document reference
      const data = {
        folder_id: newParentFolder,
        measurement_date_id: selectedMeasurementDateId,
      };
      await addDocumentReference(selectedMeasurementDateId, fileData.file.id, data);
    }
  };

  const saveFileData = async (event, folder, elementType) => {
    const fileData = getDataOfDraggingElement(event);
    const parentFolder = folder?.id || null;

    if (fileData && validations.isValidData(parentFolder, elementType)) {
      setIsLoading(true);
      await updateFileData(fileData, parentFolder);
      setIsLoading(false);
    }
  };
  // End of methods to use when is dragging a file

  const handleSaveDroppedData = async (event, elementType, data) => {
    if (elementType === FOLDER) {
      await saveFolderData(event, data, elementType);
    } else {
      await saveFileData(event, data, elementType);
    }
    await refreshDocuments();
  };

  const handleDrop = (event, data) => {
    const elementType = getElementType();
    handleSaveDroppedData(event, elementType, data);

    removeStylesOf(DRAGGING);
    removeStylesOf(DRAG_OVER);
    setLocalStorageInfo(null);
    setLocalStorageInfo(null, DRAGGING_ELEMENT_TYPE);
    event.stopPropagation();
    event.preventDefault();
    return false;
  };

  return {
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    handleDragLeave,
    handleDrop,
  };
};

export default useDocumentsDragAndDrop;
