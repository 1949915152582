import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    settingsMenuButtons: {
      display: 'flex',
      alignItems: 'center',
      margin: '2rem 0',
    },
    button: {
      marginRight: 10,
    },
  })
);

export default useStyles;
