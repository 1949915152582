import moment from 'moment';
import { calculateYearFrac, swapDatesIfNecessary, validateInputs } from './utils/helperFunctions';

/**
 * Calculates the fraction of the year represented by the number of whole days between two dates
 * (the start_date and the end_date). For example, with start_date as '1-Jan-2011' and end_date as '1-Jan-2012',
 * the function returns 1, because there is one full year between these dates.
 *
 * @param {string | Date} start_date - The start date of the period. Can be a string in 'YYYY-MM-DD' format or a Date object.
 * @param {string | Date} end_date - The end date of the period. Can be a string in 'YYYY-MM-DD' format or a Date object.
 * @param {number} basis [basis=0] - the type of day count basis to use. can be 0 to 4:
 * 0 or omitted: US (NASD) 30/360.
 * 1: Actual/actual.
 * 2: Actual/360.
 * 3: Actual/365.
 * 4: European 30/360.
 * @returns {number | string} - the fraction of the year between start_date and end_date as decimal.
 * If any input parameter is invalid, the function returns an error message is returned: '#VALUE!' for invalid dates,
 * and '#NUM!' for invalid basis.
 *
 *  @see {@link https://support.microsoft.com/en-us/office/yearfrac-function-3844141e-c76d-4143-82b6-208454ddc6a8|YEARFRAC function - Office Support}
 */

export const YEARFRAC_FORMATS = ['MM/DD/YYYY', 'YYYY-MM-DD'];

function YEARFRAC(start_date, end_date, basis = 0) {
  let startDate = moment(start_date, YEARFRAC_FORMATS);
  if (!startDate.isValid()) {
    startDate = moment(new Date(start_date));
  }
  let endDate = moment(end_date, YEARFRAC_FORMATS);

  if (!endDate.isValid()) {
    endDate = moment(new Date(end_date));
  }
  const error = validateInputs(startDate, endDate, basis);
  if (error) return error;

  const dates = swapDatesIfNecessary(startDate, endDate);
  startDate = dates.startDate;
  endDate = dates.endDate;

  return calculateYearFrac({ startDate, endDate, basis });
}

export default YEARFRAC;
