import React from 'react';
import { useStore } from 'common/store';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';

const CapTableListSelection = props => {
  const [{ capTableList }] = useStore();

  return (
    <GridSelect
      menuPosition="fixed"
      options={capTableList}
      objectOptions={{ displayKey: 'name', valueKey: 'id' }}
      {...props}
    />
  );
};

export default CapTableListSelection;
