import { range } from 'mathjs';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { PERFORMANCE_METRICS_SPREADSHEET_COMPANY } from '../../../PerformanceMetrics/common/constants/performanceMetrics/sheetAliases';
import { ADJUSTMENT_METRIC_ALIAS, METRIC_TITLES_AND_ALIASES } from '../../common/constants';

const synchronizeAdjustedMetricDropdowns = (cells, cell, value) => {
  const changes = [];
  Object.values(cells)
    .filter(adjustmentCell => adjustmentCell.alias === cell.alias && adjustmentCell.key !== cell.key)
    .forEach(adjustmentCell => {
      changes.push({
        cell: adjustmentCell,
        value,
      });
    });
  return changes;
};

const handleAdjustmentMetricAlias = (
  cell,
  value,
  cells,
  changes,
  comparisons,
  performanceMetricsSheet,
  performanceMetricsAsOfDateSheet
) => {
  // 1. get metric for columns and gpc range
  const alphabet = alphabetGenerator([], performanceMetricsAsOfDateSheet.columns.length);

  const performanceMetricsIndex = Object.keys(METRIC_TITLES_AND_ALIASES).findIndex(
    option => option === value.toUpperCase()
  );

  const columnLegend = alphabet[performanceMetricsIndex];

  const companyPerformanceMetricRowIndex = performanceMetricsAsOfDateSheet?.rowConfig.findIndex(
    obj => obj.alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY
  );
  const asOfCompanyPerformanceMetricRowNumber = companyPerformanceMetricRowIndex + 1;
  const companyPerformanceMetricRowNumber = companyPerformanceMetricRowIndex + 2;

  // in this case rowNumber is + 2 because the Performance Metric table header has a rowSpan attribute of 2
  const measurementDateGpcRange = range(1, comparisons.length + 1)
    .map(rowNumber => `${performanceMetricsSheet.name}.${columnLegend}${rowNumber + 2}`)
    .toString()
    .replace(/"/g, '');

  const customDateGpcRange = range(1, comparisons.length + 1)
    .map(rowNumber => `${performanceMetricsAsOfDateSheet.name}.${columnLegend}${rowNumber + 1}`)
    .toString()
    .replace(/"/g, '');

  // 2. set changes
  changes.push(...synchronizeAdjustedMetricDropdowns(cells, cell, value));
  changes.push({
    cell: cells.B4,
    value: `=MEDIAN(FILTER_NULL(${customDateGpcRange}))`,
  });
  changes.push({
    cell: cells.B5,
    value: `=${performanceMetricsAsOfDateSheet.name}.${columnLegend}${asOfCompanyPerformanceMetricRowNumber}`,
  });
  changes.push({
    cell: cells.C4,
    value: `=MEDIAN(FILTER_NULL(${measurementDateGpcRange}))`,
  });
  changes.push({
    cell: cells.C5,
    value: `=${performanceMetricsSheet.name}.${columnLegend}${companyPerformanceMetricRowNumber}`,
  });
};

const afterCellChanged = (changes, cells, rowConfig, tableData) => {
  const { comparisons, performanceMetricsSheet, performanceMetricsAsOfDateSheet } = tableData;
  changes.forEach(async change => {
    const { cell, value } = change;

    if (cell.alias === ADJUSTMENT_METRIC_ALIAS) {
      handleAdjustmentMetricAlias(
        cell,
        value,
        cells,
        changes,
        comparisons,
        performanceMetricsSheet,
        performanceMetricsAsOfDateSheet
      );
    }
  });

  return changes;
};

export default afterCellChanged;
