import React from 'react';
import { Button } from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { GENERATE_NEW_CODES } from 'common/constants/login';

const GenerateBackupCodesButton = ({ className, generateNewBackupCodes, disabled }) => (
  <Button
    className={className}
    variant="contained"
    color="primary"
    onClick={generateNewBackupCodes}
    disabled={disabled}
    startIcon={<RefreshIcon />}>
    {GENERATE_NEW_CODES}
  </Button>
);

GenerateBackupCodesButton.propTypes = {
  className: PropTypes.string.isRequired,
  generateNewBackupCodes: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default GenerateBackupCodesButton;
