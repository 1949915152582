import React, { useContext, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import {
  BUTTON,
  FIRM_INFO,
  LINK,
  LOG_IN,
  LOG_OUT,
  MEASUREMENT_DATE,
  QUESTIONNAIRE,
} from 'common/constants/process-management';
import { useStore } from 'common/store';
import { TaskContext } from 'context';
import DocumentUploads from './components/DocumentUploads';
import QuestionsList from './components/QuestionsList';

const RequestDocuments = () => {
  const { task, setHeaderMenuItems, isMobile, questionnaireUrl, documentUrl } = useContext(TaskContext);
  const { requested_files: requestedFiles } = task;
  const { requestId, hmacToken, processType } = useParams();
  // Added 7 days to the task.created_at date to get the expiration date for the link
  const expirationDate = new Date(task.created_at);
  expirationDate.setDate(expirationDate.getDate() + 7);
  const [{ user }] = useStore();
  const pairedQuestionnaire = task.paired_questionnaire;
  const rightHeaderItems = useMemo(
    () =>
      pairedQuestionnaire
        ? [
          {
            id: uuid(),
            label: user ? LOG_OUT : LOG_IN,
            link: `/${processType}/requests/${requestId}/${hmacToken}`,
            component: LINK,
            withArrow: false,
          },
          {
            id: uuid(),
            label: QUESTIONNAIRE,
            link: questionnaireUrl,
            component: BUTTON,
            hideOnMobile: true,
          },
        ]
        : [null, null],
    [pairedQuestionnaire, user, requestId, hmacToken, processType, questionnaireUrl]
  );

  useEffect(() => {
    setHeaderMenuItems({
      left: [
        {
          id: uuid(),
          component: FIRM_INFO,
        },
        {
          id: uuid(),
          component: MEASUREMENT_DATE,
        },
      ],
      right: rightHeaderItems,
    });
  }, [
    documentUrl,
    hmacToken,
    processType,
    questionnaireUrl,
    requestId,
    setHeaderMenuItems,
    user,
    pairedQuestionnaire,
    rightHeaderItems,
  ]);

  return (
    <Grid
      container
      style={
        !pairedQuestionnaire && !isMobile
          ? {
            maxWidth: '70%',
          }
          : null
      }>
      {!pairedQuestionnaire ? (
        <Grid item xs={12} style={{ paddingLeft: !isMobile && '5rem' }}>
          {requestedFiles && <DocumentUploads pairedQuestionnaire={pairedQuestionnaire} />}
        </Grid>
      ) : (
        <>
          <Grid item lg={4} xs={12}>
            <QuestionsList />
          </Grid>
          <Grid item lg={8} xs={12} style={{ paddingLeft: !isMobile && '5rem' }}>
            {requestedFiles && <DocumentUploads pairedQuestionnaire={pairedQuestionnaire} />}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RequestDocuments;
