/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DocumentReferenceObjectCreate = {
  reference_object_type: DocumentReferenceObjectCreate.reference_object_type;
  reference_object_id: number;
  document_reference_id: number;
};

export namespace DocumentReferenceObjectCreate {
  export enum reference_object_type {
    CT = 'ct',
    FI = 'fi',
    FO = 'fo',
    CB = 'cb',
    EV = 'ev',
    SSV = 'ssv',
  }
}
