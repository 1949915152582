import React, { useCallback } from 'react';
import { MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

const CompanyMenuItem = ({ handleDropDown, option, subMenuItem }) => {
  const handleClick = useCallback(() => {
    handleDropDown(option, subMenuItem);
  }, [handleDropDown, option, subMenuItem]);

  return (
    <MenuItem key={option.id} id={`company-dropdown-item-${option.id}`} onClick={handleClick}>
      {option.title}
    </MenuItem>
  );
};

CompanyMenuItem.propTypes = {
  handleDropDown: PropTypes.func.isRequired,
  option: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  subMenuItem: PropTypes.shape({
    id: PropTypes.number,
    idPinnable: PropTypes.bool,
    isPinned: PropTypes.bool,
    measurementDateSlug: PropTypes.string,
    title: PropTypes.string,
    updatePinStatus: PropTypes.func,
    url: PropTypes.string,
  }),
};

export default CompanyMenuItem;
