import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatDateViewLL } from 'utillities';

const UploadedFileData = ({ uploadedFile }) => {
  // The following validation has been performed because "uploadedFile.file" is used in the portal document, while "uploadedFile" is used in the firm summary progress modal.
  const uploadedFileData = uploadedFile.file || uploadedFile;

  return (
    <Box marginBottom="0.5rem">
      <Typography style={{ fontWeight: 400, fontSize: '1rem' }} color="primary">
        {uploadedFileData.filename}.{uploadedFileData.file_type}
      </Typography>
      <Typography key={uploadedFileData.id} style={{ fontWeight: 400, fontSize: '1rem' }}>
        {formatDateViewLL(uploadedFileData.uploaded_date)}
        {uploadedFileData.uploaded_by ? ` by ${uploadedFileData.uploaded_by}` : ''}
      </Typography>
    </Box>
  );
};

UploadedFileData.propTypes = {
  uploadedFile: PropTypes.shape({
    file: PropTypes.shape({
      filename: PropTypes.string,
      file_type: PropTypes.string,
      uploaded_date: PropTypes.string,
      uploaded_by: PropTypes.string,
    }),
  }),
};

export default UploadedFileData;
