/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AllocationScenarioSlimModel = {
  readonly id?: number;
  name: string;
  scenario_type: number;
  weighting_probability: string;
  equity_value: string;
  is_deleted?: boolean | null;
  readonly deleted_at?: string | null;
  exit_date?: string | null;
  discount_rate?: string | null;
  dilution_percentage?: string | null;
  maturity?: string | null;
  volatility?: string | null;
  exit_equity_value?: string | null;
  total_aggregate_value?: string | null;
  order?: number | null;
  scenario_values?: any;
  scenario_method?: AllocationScenarioSlimModel.scenario_method | null;
  backsolve_ledger?: any;
  financials_metric?: number | null;
  financials_metric_value?: string | null;
  gpc_multiple?: number | null;
  gpc_multiple_value?: string | null;
  less_debt?: string | null;
  plus_cash?: string | null;
  allocation: number;
  cap_table: number;
  backsolve_valuation?: number | null;
  future_exit?: number | null;
  specified_share_values?: number | null;
};

export namespace AllocationScenarioSlimModel {
  export enum scenario_method {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }
}
