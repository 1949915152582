import moment from 'moment';
import YEARFRAC from 'common/formulas/math.js/YEARFRAC';

const SUM_FROM_ACCRUED_LEDGER = (
  ledgerList,
  withCompounding,
  issuePrice,
  dividendRate,
  compoundingPeriods,
  measurementDate
) => {
  // In case we update/change this formulas, we need to update the formula on backend as well
  // (app.cap_table.models.Security._recalculate_accrued_dividends_on_ledger)
  const getCalcAccruedDividends = () => {
    if (withCompounding) {
      return ({ date, shares }) =>
        (issuePrice
          * (1 + dividendRate / compoundingPeriods) ** (YEARFRAC(date, measurementDate, 3) * compoundingPeriods)
          - issuePrice)
        * shares;
    }

    return ({ date, shares }) => dividendRate * issuePrice * shares * YEARFRAC(date, measurementDate, 3);
  };

  const returnZeroIfDateIsAfterMeasurementDate = (obj, fn) => {
    if (moment(obj.date, 'MM/DD/YYYY').isAfter(moment(measurementDate, 'MM/DD/YYYY'))) {
      return 0;
    }
    return fn(obj);
  };
  // (E4 * (1+E22/E24) ^ (YEARFRAC(E2, '01/10/2022', 3) * E24) - E4) * E5
  const calcAccruedDividends = getCalcAccruedDividends();
  return ledgerList.reduce(
    (sofar, next) => sofar + returnZeroIfDateIsAfterMeasurementDate(next, calcAccruedDividends),
    0
  );
};

export default SUM_FROM_ACCRUED_LEDGER;
