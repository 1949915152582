import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Grid, Menu, MenuItem } from '@material-ui/core';
import { ArrowRightAlt } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { setCurrency } from 'common/actions/format';
import * as messages from 'common/constants/messages/validations';
import { SHOW_LESS, SHOW_MORE } from 'common/constants/pageFilters';
import { useUsedCurrencies } from 'common/hooks';
import { MILLIONS, THOUSANDS } from 'components/FeaturedSpreadsheet/constants';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { useResponse } from 'services/hooks';
import { getExchangeRate, worldCurrencies } from 'utillities';

const NUMBER_UNITS = ['', THOUSANDS, MILLIONS];

const useStyles = makeStyles(theme => {
  const primaryGreen = theme.palette.green.primary;

  return {
    root: {
      position: 'absolute',
      top: '-2px',
      left: '-5px',
    },
    formatterBtn: props => ({
      padding: '0 14px 0 8px',
      minWidth: 'auto',
      fontSize: '12px',
      borderRadius: '0',
      borderBottomRightRadius: '10px',
      lineHeight: 'inherit',
      backgroundColor: '#fff',
      fontWeight: '800',
      maxHeight: '19px',
      '&:hover': {
        backgroundColor: props.foreignCurrency ? primaryGreen : '#fff',
      },
    }),
    currencyBtn: props => ({
      backgroundColor: props.foreignCurrency ? primaryGreen : '#fff',
      color: props.foreignCurrency ? '#fff' : primaryGreen,
      border: `1px solid ${primaryGreen}`,
      zIndex: 1,
    }),
    numberBtn: {
      color: '#b7b7b7',
      border: '1px solid #b7b7b7',
      borderLeft: 'none',
      paddingLeft: '16px !important',
      left: '-8px',
    },
    icon: {
      padding: '0 2px',
      display: 'block',
      width: '20px !important',
      height: 'auto !important',
      fill: '#fff !important',
    },
    menuSeparator: {
      borderBottom: '1px solid #EFEFEF',
    },
    showMoreLess: {
      fontSize: '0.875rem',
      color: theme.palette.gray[400],
    },
  };
});

const CurrencyFormatter = ({ currency, onCurrencyChanged, isLoading }) => {
  const foreignCurrency = currency.sourceCurrency !== currency.code;
  const classes = useStyles({ foreignCurrency });
  const usedCurrencies = useUsedCurrencies();
  const [currencies, setCurrencies] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const setInitialCurrencies = useCallback(() => {
    const tmpCurrencies = [...new Set([...usedCurrencies])];
    setCurrencies(tmpCurrencies.map(code => ({ code, symbol: worldCurrencies[code] })));
  }, [usedCurrencies]);

  useEffect(() => {
    if (usedCurrencies) {
      setInitialCurrencies();
    }
  }, [setInitialCurrencies, usedCurrencies]);

  function showMore() {
    const tmpCurrencies = [...new Set([...usedCurrencies, ...Object.keys(worldCurrencies)])];
    setCurrencies(tmpCurrencies.map(code => ({ code, symbol: worldCurrencies[code] })));
  }

  function showLess() {
    setInitialCurrencies();
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCurrencyChange = selectedCurrency => () => {
    if (!isLoading) {
      onCurrencyChanged(selectedCurrency);
      handleClose();
    }
  };

  const roundedExchangeRate = useMemo(() => {
    if (currency.exchangeRate) {
      // round exchange rate to 2 decimal places
      return Math.round(currency.exchangeRate * 100) / 100;
    }
  }, [currency]);

  return (
    <>
      <Button className={`${classes.formatterBtn} ${classes.currencyBtn}`} onClick={handleClick} disabled={isLoading}>
        <Grid container alignItems="center">
          <Grid item>
            {foreignCurrency ? '1 ' : ''}
            {currency.sourceCurrency}
          </Grid>
          {foreignCurrency && (
            <>
              <Grid item>
                <ArrowRightAlt classes={{ root: classes.icon }} />
              </Grid>
              <Grid item>
                {roundedExchangeRate} {currency.code}
              </Grid>
            </>
          )}
        </Grid>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {currencies.map(c => (
          <MenuItem
            key={c.code}
            onClick={handleCurrencyChange(c)}
            classes={{
              root: c.code === usedCurrencies[usedCurrencies.length - 1] ? classes.menuSeparator : '',
            }}>
            {`${c.code} (${c.symbol})`}
          </MenuItem>
        ))}
        <MenuItem
          className={classes.showMoreLess}
          onClick={currencies.length === usedCurrencies.length ? showMore : showLess}>
          {currencies.length === usedCurrencies.length ? SHOW_MORE : SHOW_LESS}
        </MenuItem>
      </Menu>
    </>
  );
};

const UnitsFormatter = ({ currency, onUnitsChanged, isLoading }) => {
  const classes = useStyles();
  const { units, symbol } = currency;

  return (
    <Button className={`${classes.formatterBtn} ${classes.numberBtn}`} onClick={onUnitsChanged} disabled={isLoading}>
      {`${symbol} ${units}`}
    </Button>
  );
};

const GridFormatter = ({ currencyFormatter, unitsFormatter }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { errorNotification } = useResponse();
  const classes = useStyles();
  const {
    format,
    formatDispatch,
    setCurrency: updateCurrencyState,
    currency: strCurrency,
  } = useContext(FeaturedSpreadsheetContext);
  const tableFormat = format && formatDispatch;

  const currency = useMemo(() => {
    if (tableFormat) {
      return format.currency;
    }

    const [code, symbol, units = ''] = strCurrency.split(' ');
    return { code, symbol, units };
  }, [format, strCurrency, tableFormat]);

  const onUnitsChanged = () => {
    const currentUnitsIndex = NUMBER_UNITS.indexOf(currency.units);
    const nextNumberFormatIndex = Math.floor((currentUnitsIndex + 1) % NUMBER_UNITS.length);
    const units = NUMBER_UNITS[nextNumberFormatIndex];

    if (tableFormat) {
      formatDispatch(setCurrency({ units }));
    } else {
      const { code, symbol } = currency;
      updateCurrencyState(`${code} ${symbol} ${units}`);
    }
  };

  const onCurrencyChanged = useCallback(
    async newCurrency => {
      setIsLoading(true);
      if (tableFormat) {
        try {
          const exchangeRate = await getExchangeRate(
            currency.sourceCurrency,
            newCurrency.code,
            currency.measurementDateId
          );

          formatDispatch(
            setCurrency({
              ...newCurrency,
              exchangeRate,
            })
          );
        } catch (error) {
          errorNotification(messages.GET_EXCHANGE_RATE_ERROR);
        }
      } else {
        const { units } = currency;
        const { code, symbol } = newCurrency;
        updateCurrencyState(`${code} ${symbol} ${units}`);
      }
      setIsLoading(false);
    },
    [currency, formatDispatch, tableFormat, updateCurrencyState, errorNotification]
  );

  useEffect(() => {
    if (format?.currency && format?.currency?.code !== currency.code) {
      onCurrencyChanged(format.currency);
    }
  }, [format, onCurrencyChanged, currency.code]);

  return (
    <div className={classes.root}>
      {currencyFormatter && (
        <CurrencyFormatter onCurrencyChanged={onCurrencyChanged} currency={currency} isLoading={isLoading} />
      )}
      {unitsFormatter && <UnitsFormatter currency={currency} onUnitsChanged={onUnitsChanged} isLoading={isLoading} />}
    </div>
  );
};

CurrencyFormatter.propTypes = {
  currency: PropTypes.object,
  onCurrencyChanged: PropTypes.func,
  isLoading: PropTypes.bool,
};

UnitsFormatter.propTypes = {
  currency: PropTypes.object,
  onUnitsChanged: PropTypes.func,
  isLoading: PropTypes.bool,
};

GridFormatter.propTypes = {
  currencyFormatter: PropTypes.bool,
  unitsFormatter: PropTypes.bool,
};

export default GridFormatter;
