import { COMPANY_TYPE, FIRM_TYPE, FUND_TYPE } from 'common/constants/pageTypes';

export const DESCRIPTION_TEXT = `Select what you would like to export, all selected data will
be exported in a single Excel Workbook. All data exported will be as of the currently selected
measurement date.`;
export const TITLE = 'Excel Export Ledger';
export const DOWNLOAD_LABEL = 'Download';
export const TABLE_HEADER_FIRST_TITLE_COMPANY = 'Export Company Data';
export const TABLE_HEADER_FIRST_TITLE_FIRM = 'Export Firm Data';
export const TABLE_HEADER_FIRST_TITLE_FUND = 'Export Fund Data';
export const TABLE_HEADER_FIRST_TITLE = {
  [COMPANY_TYPE]: TABLE_HEADER_FIRST_TITLE_COMPANY,
  [FIRM_TYPE]: TABLE_HEADER_FIRST_TITLE_FIRM,
  [FUND_TYPE]: TABLE_HEADER_FIRST_TITLE_FUND,
};
