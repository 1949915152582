export const FUND_SUMMARY_SPREADSHEET_COMPANY_NAME_TITLE = 'Company';
export const FUND_SUMMARY_SPREADSHEET_COMPANY_NAME_TOOLTIP = '';
export const FUND_SUMMARY_SPREADSHEET_PROCESS_MANAGEMENT_TITLE = 'Process Management';
export const FUND_SUMMARY_SPREADSHEET_PROCESS_MANAGEMENT_TOOLTIP = '';
export const FUND_SUMMARY_SPREADSHEET_INITIAL_INVESTMENT_DATE_TITLE = 'Initial Investment Date';
export const FUND_SUMMARY_SPREADSHEET_INITIAL_INVESTMENT_DATE_TOOLTIP = 'Date ownership in the security was acquired';
export const FUND_SUMMARY_SPREADSHEET_MOST_RECENT_INVESTMENT_DATE_TITLE = 'Most Recent Investment Date';
export const FUND_SUMMARY_SPREADSHEET_MOST_RECENT_INVESTMENT_DATE_TOOLTIP
  = 'Most recent date ownership in the security was acquired';
export const FUND_SUMMARY_SPREADSHEET_FULLY_DILUTED_OWNERSHIP_TITLE = 'Fully Diluted Ownership %';
export const FUND_SUMMARY_SPREADSHEET_FULLY_DILUTED_OWNERSHIP_TOOLTIP = 'Fully diluted ownership percentage';
export const FUND_SUMMARY_SPREADSHEET_INVESTED_CAPITAL_TITLE = 'Invested Capital';
export const FUND_SUMMARY_SPREADSHEET_INVESTED_CAPITAL_TOOLTIP = 'Fund investment in the security, cost basis';
export const FUND_SUMMARY_SPREADSHEET_REALIZED_VALUE_TITLE = 'Realized Value';
export const FUND_SUMMARY_SPREADSHEET_REALIZED_VALUE_TOOLTIP
  = 'Total value realized as of date; such as distributions or shares sold';
export const FUND_SUMMARY_SPREADSHEET_UNREALIZED_EQUITY_TITLE = 'Unrealized Value';
export const FUND_SUMMARY_SPREADSHEET_UNREALIZED_EQUITY_TOOLTIP
  = 'Total unrealized value of owned shares based on valuation';
export const FUND_SUMMARY_SPREADSHEET_TOTAL_VALUE_TITLE = 'Total Value';
export const FUND_SUMMARY_SPREADSHEET_TOTAL_VALUE_TOOLTIP = 'Total value of investment';
export const FUND_SUMMARY_SPREADSHEET_GROSS_IRR_TITLE = 'Gross IRR';
export const FUND_SUMMARY_SPREADSHEET_GROSS_IRR_TOOLTIP
  = 'Gross Internal Rate of Return generated from a loan before consideration of management fees and expenses';
export const FUND_SUMMARY_SPREADSHEET_MOIC_TITLE = 'MOIC'; // Multiple of Invested Capital or X Factor
export const FUND_SUMMARY_SPREADSHEET_MOIC_TOOLTIP = 'Multiple of Invested Capital or X Factor';

export const FUND_SUMMARY_SPREADSHEET_TOTAL_TITLE = 'Total';

export const FUND_SUMMARY_SPREADSHEET_COLUMNS = Object.freeze({
  COMPANY_NAME: 0,
  PROCESS_MANAGEMENT: 1,
  INITIAL_INVESTMENT_DATE: 2,
  MOST_MOST_RECENT_INVESTMENT_DATE: 3,
  FULLY_DILUTED_OWNERSHIP: 4,
  INVESTED_CAPITAL: 5,
  REALIZED_VALUE: 6,
  UNREALIZED_EQUITY: 7,
  TOTAL_VALUE: 8,
  GROSS_IRR: 9,
  MOIC: 10,
});

export const FUND_SUMMARY_SPREADSHEET_HEADERS = Object.freeze([
  {
    defaultTooltip: FUND_SUMMARY_SPREADSHEET_COMPANY_NAME_TOOLTIP,
    value: FUND_SUMMARY_SPREADSHEET_COMPANY_NAME_TITLE,
  },
  {
    defaultTooltip: FUND_SUMMARY_SPREADSHEET_PROCESS_MANAGEMENT_TOOLTIP,
    value: FUND_SUMMARY_SPREADSHEET_PROCESS_MANAGEMENT_TITLE,
  },
  {
    defaultTooltip: FUND_SUMMARY_SPREADSHEET_INITIAL_INVESTMENT_DATE_TOOLTIP,
    value: FUND_SUMMARY_SPREADSHEET_INITIAL_INVESTMENT_DATE_TITLE,
  },
  {
    defaultTooltip: FUND_SUMMARY_SPREADSHEET_MOST_RECENT_INVESTMENT_DATE_TOOLTIP,
    value: FUND_SUMMARY_SPREADSHEET_MOST_RECENT_INVESTMENT_DATE_TITLE,
  },
  {
    defaultTooltip: FUND_SUMMARY_SPREADSHEET_FULLY_DILUTED_OWNERSHIP_TOOLTIP,
    value: FUND_SUMMARY_SPREADSHEET_FULLY_DILUTED_OWNERSHIP_TITLE,
  },
  {
    defaultTooltip: FUND_SUMMARY_SPREADSHEET_INVESTED_CAPITAL_TOOLTIP,
    value: FUND_SUMMARY_SPREADSHEET_INVESTED_CAPITAL_TITLE,
  },
  {
    defaultTooltip: FUND_SUMMARY_SPREADSHEET_REALIZED_VALUE_TOOLTIP,
    value: FUND_SUMMARY_SPREADSHEET_REALIZED_VALUE_TITLE,
  },
  {
    defaultTooltip: FUND_SUMMARY_SPREADSHEET_UNREALIZED_EQUITY_TOOLTIP,
    value: FUND_SUMMARY_SPREADSHEET_UNREALIZED_EQUITY_TITLE,
  },
  {
    defaultTooltip: FUND_SUMMARY_SPREADSHEET_TOTAL_VALUE_TOOLTIP,
    value: FUND_SUMMARY_SPREADSHEET_TOTAL_VALUE_TITLE,
  },
  {
    defaultTooltip: FUND_SUMMARY_SPREADSHEET_GROSS_IRR_TOOLTIP,
    value: FUND_SUMMARY_SPREADSHEET_GROSS_IRR_TITLE,
  },
  {
    defaultTooltip: FUND_SUMMARY_SPREADSHEET_MOIC_TOOLTIP,
    value: FUND_SUMMARY_SPREADSHEET_MOIC_TITLE,
  },
]);
