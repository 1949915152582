/* eslint-disable import/no-unresolved */
import React, { useMemo, useState } from 'react';
import { ClickAwayListener, Drawer, Paper } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { firmSummaryUrl, homePath } from 'common/config/urls';
import { SIDEBAR_PERSISTENT_CLOSE_KEY } from 'common/constants/userPreferences';
import { useLocalStorageByUser } from 'common/hooks';
import { useStore } from 'common/store';
import { FirmLogo } from 'components/FirmLogo';
import { BottomMenuItems, TopMenuItems } from './components';

const bottomNavHeight = 170;

const useStyles = makeStyles(theme => ({
  drawer: {
    position: 'relative',
    zIndex: 1200,
    [theme.breakpoints.up('lg')]: {},
  },
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 0,
    position: 'relative',
  },
  drawerTop: {},
  drawerBottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  logo: {
    display: 'flex',
    minHeight: `${theme.toolbarHeight}px`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottom: {
    background: `linear-gradient(0deg, ${theme.palette.primary.dark} -50%, ${theme.palette.primary.main} 100%)`,
    zIndex: 1000,
    maxHeight: bottomNavHeight,
  },
  drawerOpen: {
    width: props => props.width,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: props => props.width,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen / 2,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: props => props.width,
    },
  },
  closeDarkLine: {
    position: 'absolute',
    top: 0,
    right: -5,
    width: 5,
    height: '100%',
    backgroundColor: '#626262',
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
  drawerPersistentCloseEnabled: {
    cursor: 'e-resize',
  },
  drawerPersistentCloseDisabled: {
    cursor: 'w-resize',
  },
}));

const Sidebar = ({ onClose, className, theme }) => {
  const [{ firmInfo, user }] = useStore();
  const { firmSlugParam, tableSlugParam } = useParams();
  const [open, setOpenSidebar] = useState(false);
  const drawerWidth = isOpen => (isOpen ? 300 : 60);
  const width = drawerWidth(open);
  const classes = useStyles({ width });
  const [persistentClose, setPersistentClose] = useLocalStorageByUser(SIDEBAR_PERSISTENT_CLOSE_KEY, false);

  const handleEnter = () => {
    if (!persistentClose && !open) {
      setOpenSidebar(true);
    }
  };

  const handleLeave = () => {
    if (open) {
      setOpenSidebar(false);
    }
  };

  const home = useMemo(() => {
    if (firmInfo && !isEmpty(firmInfo)) {
      const { slug } = firmInfo;
      return firmSummaryUrl(slug);
    }

    return homePath;
  }, [firmInfo]);

  const superuserCompGroupsPageIsRendered = useMemo(
    () => user?.is_superuser && firmSlugParam === '0' && tableSlugParam === 'comp-groups',
    [user, firmSlugParam, tableSlugParam]
  );

  const handlePersistentClose = () => {
    setOpenSidebar(!persistentClose !== true);

    setPersistentClose(!persistentClose);
  };

  const handleDrawerClick = () => {
    if (persistentClose) {
      setOpenSidebar(true);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleLeave}>
      <Drawer
        open={open}
        variant="permanent"
        anchor="left"
        onClose={onClose}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        onClick={handleDrawerClick}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        PaperProps={{
          elevation: open ? 16 : 1,
          style: {
            overflow: 'visible',
            backgroundColor: theme.palette.primary.main,
          },
        }}>
        <div className={clsx(classes.drawerContainer, className)}>
          <div className={classes.drawerTop}>
            <Paper square elevation={1} className={classes.logo}>
              <Link to={home} data-testid="logo">
                <FirmLogo firmInfo={firmInfo} isSidebarOpen={open} />
              </Link>
            </Paper>
          </div>
          <div className={classes.drawerBottom}>
            <TopMenuItems
              open={open}
              setOpenSidebar={setOpenSidebar}
              renderFundsAndCompanies={!superuserCompGroupsPageIsRendered}
            />
            <div id="sidebar-bottom" className={classes.bottom}>
              <BottomMenuItems open={open} setOpenSidebar={setOpenSidebar} />
            </div>
          </div>
          <div
            className={clsx(classes.closeDarkLine, {
              [classes.drawerPersistentCloseEnabled]: persistentClose,
              [classes.drawerPersistentCloseDisabled]: !persistentClose,
            })}
            onClick={handlePersistentClose}
            aria-hidden="true"
          />
        </div>
      </Drawer>
    </ClickAwayListener>
  );
};

Sidebar.defaultProps = {
  open: false,
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.string.isRequired,
  theme: PropTypes.object,
  setOpenSidebar: PropTypes.func,
};

export default withTheme(Sidebar);
