import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import getRunningBrowserOS from 'helpers/getRunningBrowserOS';
import availableShortcuts from './availableShortCuts';
import HorizontalShortcuts from './HorizontalShortcuts';
import VerticalShortcuts from './VerticalShortcuts';

const Shortcuts = ({ upMd }) => {
  const browserOS = getRunningBrowserOS();
  const direction = upMd ? 'row' : 'column';
  const mainContainerSpacing = upMd ? 8 : 2;

  return (
    <Grid container direction={direction} spacing={mainContainerSpacing} style={{ paddingBottom: '4rem' }}>
      {upMd ? (
        <HorizontalShortcuts browserOS={browserOS} shortcuts={availableShortcuts} />
      ) : (
        <VerticalShortcuts browserOS={browserOS} shortcuts={availableShortcuts} />
      )}
    </Grid>
  );
};

Shortcuts.propTypes = {
  upMd: PropTypes.bool,
};

export default Shortcuts;
