import { createStyles, makeStyles } from '@material-ui/styles';
import { getNavigationItemStyles, HORIZONTAL_NAVIGATION_SELECTED_COLOR } from 'components/HorizontalNavigation/styles';
import { ITheme } from 'theme/types';

export const HORIZONTAL_NAVIGATION_Z_INDEX = 1000;

const getTabMinHeight = (theme: ITheme) => ({ minHeight: theme.spacing(6) });

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    container: {
      ...getTabMinHeight(theme),
      zIndex: HORIZONTAL_NAVIGATION_Z_INDEX,
    },
    navItem: {
      borderBottom: isMenuSelected => (isMenuSelected ? `solid 2px ${theme.palette.primary[500]}` : 'none'),
      borderRadius: 0,
      color: HORIZONTAL_NAVIGATION_SELECTED_COLOR,
      opacity: isMenuSelected => (isMenuSelected ? 1 : 0.7),
      paddingBottom: isMenuSelected => `${theme.spacing(isMenuSelected ? 0.75 : 1)}px`,
      paddingRight: 0,
      ...getNavigationItemStyles(theme),
      ...getTabMinHeight(theme),

      '&.selected': {
        color: theme.palette.white,
      },
      '& span': {
        height: '100%',
      },
      '&:hover': {
        color: theme.palette.white,
      },
    },
    navItemIcon: {
      color: isMenuSelected => (isMenuSelected ? theme.palette.white : HORIZONTAL_NAVIGATION_SELECTED_COLOR),
      fontWeight: 500,
      height: '100%',
      marginLeft: `${theme.spacing(0.5)}px`,
      padding: `0px ${theme.spacing(0.44)}px`,
    },
    subMenu: {
      zIndex: 1000,

      '& .MuiPaper-root': {
        backgroundColor: 'transparent',
        borderRadius: 0,
        boxShadow: 'none',

        '& .MuiList-root': {
          paddingTop: 0,

          '& .MuiListItem-root': {
            backgroundColor: theme.palette.primary[600],
            color: HORIZONTAL_NAVIGATION_SELECTED_COLOR,
            marginTop: theme.spacing(0.2),
            padding: `${theme.spacing(1.7)}px ${theme.spacing(3)}px`,

            '&:hover': {
              color: theme.palette.white,
            },
          },
        },
      },
    },
    subMenuItem: {
      fontSize: theme.spacing(1.75),
      textTransform: 'uppercase',
    },
  })
);

export default useStyles;
