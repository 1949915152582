import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';

const ListMenuSkeleton = ({ open, classes }) => (
  <ListItem className={classes.item}>
    <ListItemIcon>
      <Skeleton variant="circle" width={28} height={28} className={classes.skeleton} />
    </ListItemIcon>
    {open && <ListItemText primary={<Skeleton variant="text" className={classes.skeleton} height={30} />} />}
  </ListItem>
);

ListMenuSkeleton.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    item: PropTypes.string,
    skeleton: PropTypes.string,
  }).isRequired,
};

export default ListMenuSkeleton;
