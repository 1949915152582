import { configureStore } from '@reduxjs/toolkit';
import { companyReducer } from 'dashboard409a/states/features/company';
import { globalReducer } from 'dashboard409a/states/features/global';
import { gpcCompsReducer } from 'dashboard409a/states/features/gpcComps';
import { userReducer } from 'dashboard409a/states/features/user';
import { dashboardReducer } from 'dashboard409a/states/features/valuation';
import { waterfallReducer } from 'dashboard409a/states/features/waterfall';

const store = configureStore({
  reducer: {
    company: companyReducer,
    global: globalReducer,
    gpcComps: gpcCompsReducer,
    user: userReducer,
    valuation: dashboardReducer,
    waterfall: waterfallReducer,
  },
});

export type RootStateType = ReturnType<typeof store.getState>;
export type AppDispatchType = typeof store.dispatch;

export default store;
