/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { user2faUrl, userProfileUrl, userSelectedProfileUrl } from 'common/config/urls';
import { ACCOUNT_SETTINGS_TAB, TWO_FACTOR_AUTH_TAB } from 'common/constants/user';
import { useStore } from 'common/store';
import LayoutContext from 'context/LayoutContext';
import { User2FA as User2FAView, UserProfile as UserProfileView } from 'pages';

const USER_BASE_URL = '/firms/:firmSlugParam/users';
const SELECTED_USER_URL = `${USER_BASE_URL}/:userIdParam`;

const UserContainer = () => {
  const history = useHistory();
  const [{ firmInfo }] = useStore();
  const { userIdParam } = useParams();
  const [accountSettingsUrl, setAccountSettingsUrl] = useState('');
  const [otpUrl, setOtpUrl] = useState('');
  const { setNavItems } = useContext(LayoutContext);

  useEffect(() => {
    if (firmInfo?.slug) {
      const accountSettingsTabUrl = userIdParam
        ? userSelectedProfileUrl(firmInfo.slug, userIdParam)
        : userProfileUrl(firmInfo.slug);
      setAccountSettingsUrl(accountSettingsTabUrl);

      setOtpUrl(user2faUrl(firmInfo.slug));
    }
  }, [firmInfo, userIdParam]);

  const menuItems = useMemo(() => {
    if (firmInfo?.slug) {
      return [
        {
          callback: () => history.push(accountSettingsUrl),
          id: 'account-settings',
          itemKey: 0,
          slug: 'account-settings',
          title: 'Account Settings',
        },
        {
          callback: () => history.push(otpUrl),
          id: 'two-factor-authentication',
          itemKey: 1,
          slug: 'two-factor-authentication',
          title: 'Two Factor Authentication',
        },
      ];
    }
    return [];
  }, [firmInfo]);

  useEffect(() => {
    setNavItems(menuItems);
    return () => setNavItems(null);
  }, [menuItems]);

  // If userIdParam is present, then we are viewing a specific user's profile
  if (userIdParam) {
    return (
      <Switch>
        <Route path={`${SELECTED_USER_URL}/${ACCOUNT_SETTINGS_TAB}`}>
          <UserProfileView />
        </Route>
        <Route path={`${SELECTED_USER_URL}/${TWO_FACTOR_AUTH_TAB}`}>
          <User2FAView />
        </Route>
      </Switch>
    );
  }

  // Otherwise, we are viewing the current user's profile
  return (
    <Switch>
      <Route path={`${USER_BASE_URL}/${ACCOUNT_SETTINGS_TAB}`}>
        <UserProfileView />
      </Route>
      <Route path={`${USER_BASE_URL}/${TWO_FACTOR_AUTH_TAB}`}>
        <User2FAView />
      </Route>
    </Switch>
  );
};

export default UserContainer;
