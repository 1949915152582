/* eslint-disable radix */
/* eslint-disable import/no-unresolved */
import { sortBy } from 'lodash';
import * as constants from 'common/constants/financials';
import { updateNeedsActualizationState } from 'pages/Financials/utilities';
import { parseDatabaseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import rowConfig from '../data/rowConfig';

const customReverseParser = ({ cells, columns, allowEmptyValues, rowConfigParam, balanceSheetAttrs }) => {
  let tableColumns = [];

  const sortedColumns = sortBy(columns, ['order']);
  const alphabet = alphabetGenerator([], sortedColumns.length);

  sortedColumns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];
    // copy original values
    let tmpColumn = { ...column };

    // replace updated values
    rowConfigParam.forEach((cell, cellIndex) => {
      const key = columnLegend + (cellIndex + 1);
      const defaultValue = cell.defaultValue || null;
      const type = cell.dbType || null;
      const format = cell.format || null;
      const value = cells[key] ? cells[key].value : defaultValue;
      const { gridType } = cell;
      const decimalPlaces = balanceSheetAttrs[cell.alias]?.decimal_places;

      if (cell.alias && cells[key]) {
        tmpColumn = {
          ...tmpColumn,
          [cell.alias]: parseDatabaseValue({
            type,
            value,
            defaultValue,
            format,
            allowEmptyValues,
            gridType,
            decimalPlaces,
          }),
        };
      }
    });

    // calculate total debt value
    const shortTermDebt = parseInt(tmpColumn.liabilities_short_term_debt);
    const longTermDebt = parseInt(tmpColumn.liabilities_long_term_debt);
    const totalDebt
      = (Number.isNaN(shortTermDebt) ? 0 : shortTermDebt) + (Number.isNaN(longTermDebt) ? 0 : longTermDebt);

    tmpColumn = {
      ...tmpColumn,
      [constants.TOTAL_DEBT_ALIAS]: totalDebt,
    };

    tableColumns = [...tableColumns, tmpColumn];
  });

  return tableColumns;
};

const reverseParser = ({ cells, columns, fieldAttributes, allowEmptyValues = true }) => {
  const localConfig = rowConfig(columns);
  const totalDebt = {
    value: constants.TOTAL_DEBT_TITLE,
    alias: constants.TOTAL_DEBT_ALIAS,
    hidden: false,
    readOnly: true,
    gridType: 'number',
    dbType: 'string',
    shortTermDebt: constants.LIABILITIES_SHORT_TERM_DEBT_ALIAS,
    longTermDebt: constants.LIABILITIES_LONG_TERM_DEBT_ALIAS,
  };
  localConfig.push(totalDebt);

  const baseReverseParsedColumns = customReverseParser({
    cells,
    columns,
    allowEmptyValues,
    balanceSheetAttrs: fieldAttributes,
    rowConfigParam: localConfig,
  });

  return updateNeedsActualizationState(baseReverseParsedColumns, cells);
};
export default reverseParser;
