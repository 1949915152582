import React, { useCallback, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NO_COMPANIES_FOUND, NO_COMPANY_USERS, NO_MEASUREMENT_DATE_SET } from 'common/constants/firms';
import InvalidCompaniesBox from './InvalidCompaniesBox';
import ModalItemCompanyBox from './ModalItemCompanyBox';

const FRModalContent = ({
  companiesWithMeasurementDateAndUsers,
  companiesWithMeasurementDateButNoUsers,
  companiesWithoutMeasurementDateButUsers,
  companiesWithoutMeasurementDateAndUsers,
  selectedCompanies,
  setSelectedCompanies,
}) => {
  const [allCompaniesSelected, setAllCompaniesSelected] = useState(
    companiesWithMeasurementDateAndUsers.length === selectedCompanies.length
  );
  const handleSelectCompanies = useCallback(
    company => {
      if (selectedCompanies.includes(company)) {
        setSelectedCompanies(selectedCompanies.filter(selectedCompany => selectedCompany !== company));
      } else {
        setSelectedCompanies([...selectedCompanies, company]);
      }
    },
    [selectedCompanies, setSelectedCompanies]
  );

  const toggleAllCompanies = () => {
    if (allCompaniesSelected) {
      setSelectedCompanies([]);
      setAllCompaniesSelected(false);
    } else {
      setSelectedCompanies(companiesWithMeasurementDateAndUsers);
      setAllCompaniesSelected(true);
    }
  };

  if (
    isEmpty(companiesWithMeasurementDateAndUsers)
    && isEmpty(companiesWithoutMeasurementDateButUsers)
    && isEmpty(companiesWithMeasurementDateButNoUsers)
  ) {
    return <Typography>{NO_COMPANIES_FOUND}</Typography>;
  }

  return (
    <>
      <Button
        onClick={toggleAllCompanies}
        color="primary"
        style={{
          fontSize: '0.7rem',
        }}>
        {allCompaniesSelected ? 'Unselect all companies' : 'Select all companies'}
      </Button>
      <PerfectScrollbar
        style={{
          maxHeight: '20rem',
          overflow: 'auto',
          padding: '0.5rem 0',
        }}
        options={{
          suppressScrollX: true,
        }}>
        {companiesWithMeasurementDateAndUsers.map(company => (
          <ModalItemCompanyBox
            key={company.id}
            company={company}
            includeCheckbox
            handleSelectCompanies={handleSelectCompanies}
            selectedCompanies={selectedCompanies}
          />
        ))}
      </PerfectScrollbar>
      <InvalidCompaniesBox companies={companiesWithoutMeasurementDateButUsers} title={NO_MEASUREMENT_DATE_SET} />
      <InvalidCompaniesBox companies={companiesWithMeasurementDateButNoUsers} title={NO_COMPANY_USERS} />
      <InvalidCompaniesBox
        companies={companiesWithoutMeasurementDateAndUsers}
        title={`${NO_MEASUREMENT_DATE_SET} & ${NO_COMPANY_USERS}`}
      />
    </>
  );
};

FRModalContent.propTypes = {
  companiesWithMeasurementDateAndUsers: PropTypes.arrayOf(
    PropTypes.shape({
      company_id: PropTypes.number,
      company_name: PropTypes.string,
    })
  ),
  companiesWithoutMeasurementDateButUsers: PropTypes.arrayOf(
    PropTypes.shape({
      company_id: PropTypes.number,
      company_name: PropTypes.string,
    })
  ),
  companiesWithMeasurementDateButNoUsers: PropTypes.arrayOf(
    PropTypes.shape({
      company_id: PropTypes.number,
      company_name: PropTypes.string,
    })
  ),
  companiesWithoutMeasurementDateAndUsers: PropTypes.arrayOf(
    PropTypes.shape({
      company_id: PropTypes.number,
      company_name: PropTypes.string,
    })
  ),
  selectedCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      company_id: PropTypes.number,
      company_name: PropTypes.string,
    })
  ),
  setSelectedCompanies: PropTypes.func.isRequired,
};

export default FRModalContent;
