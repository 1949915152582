import React, { FC } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import useStyles from './styles';

const LoadingSection: FC = () => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.loadingContainer}>
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default LoadingSection;
