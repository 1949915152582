export const filterTableCells = (cells, tableId) => {
  const tmpLedgerCells = {};

  Object.entries(cells).forEach(([key, cell]) => {
    if (cell?.tableId === tableId) {
      tmpLedgerCells[key] = cell;
    }
  });

  return tmpLedgerCells;
};

export default filterTableCells;
