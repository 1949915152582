import { createStyles, makeStyles } from '@material-ui/styles';
import {
  PERFORMANCE_METRICS_SPREADSHEET_HEADER_CLASSNAME,
  PERFORMANCE_METRICS_SPREADSHEET_RANK_VOLATILITY_HEADER_CLASSNAME,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics';
import { ITheme } from 'theme/types';

const SPREADSHEET_FIRST_COLUMN_HEADER = `&.${PERFORMANCE_METRICS_SPREADSHEET_HEADER_CLASSNAME}.formatter-cell.row-label-title`;
const SPREADSHEET_CONTENT_COLUMNS_HEADER = `&.${PERFORMANCE_METRICS_SPREADSHEET_HEADER_CLASSNAME}.formatter-cell`;

const SPREADSHEET_COLUMNS_HEADER = [SPREADSHEET_FIRST_COLUMN_HEADER, SPREADSHEET_CONTENT_COLUMNS_HEADER];

const SPREADSHEET_COLUMNS_HEADER_WITH_2_COL_SPAN = '&[rowspan="2"]';
const SPREADSHEET_CELL_TITLE_CONTAINER = '& #row-title';
const SPREADSHEET_CELL_TITLE_TEXT = '& .text-cell';

const HEADER_HEIGHT = 5.5;

const styles = makeStyles((theme: ITheme) =>
  createStyles({
    titleAndSubtitles: {
      // First Column Header
      [SPREADSHEET_FIRST_COLUMN_HEADER]: {
        // First Column Header -> Cell Title container
        [SPREADSHEET_CELL_TITLE_CONTAINER]: {
          justifyContent: 'flex-start',
          padding: 0,
        },
      },
      // Content Columns Header
      [SPREADSHEET_CONTENT_COLUMNS_HEADER]: {
        height: `${theme.spacing(HEADER_HEIGHT)}px !important`,

        // First Column Header & Content Columns Header with 2 rows span
        [SPREADSHEET_COLUMNS_HEADER_WITH_2_COL_SPAN]: {
          height: `calc(${theme.spacing(HEADER_HEIGHT * 2)}px + 1px) !important`, // 1px for the border
        },

        // Content Columns Header -> Rank and Volatility Header
        [`&.${PERFORMANCE_METRICS_SPREADSHEET_RANK_VOLATILITY_HEADER_CLASSNAME}`]: {
          height: `${theme.spacing(HEADER_HEIGHT / 1.5)}px !important`,
          [SPREADSHEET_CELL_TITLE_CONTAINER]: {
            justifyContent: 'center',
          },
        },
        [SPREADSHEET_CELL_TITLE_CONTAINER]: {
          padding: 0,
        },
      },
      // All Columns Headers
      [SPREADSHEET_COLUMNS_HEADER.join(', ')]: {
        // All Columns Headers -> Cell Title container
        [SPREADSHEET_CELL_TITLE_CONTAINER]: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: `0 ${theme.spacing(2.5)}px`,

          // All Columns Headers -> Cell Title container -> Cell Title text
          [SPREADSHEET_CELL_TITLE_TEXT]: {
            whiteSpace: 'normal',
            padding: 0,
            paddingRight: '20px',
          },
        },
      },
    },
  })
);

export default styles;
