import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { Box, Button, ClickAwayListener, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { NavItems } from 'common/types/store';
import { NavigationParams } from 'components/HorizontalNavigation/types';
import { stopEventPropagation } from 'utillities';
import useStyles from './styles';
import { TabSubMenuProps } from './types';

const TabSubMenu: FC<TabSubMenuProps> = props => {
  const { item, onChange, disabled } = props;
  const { id, itemKey, slug, subMenu, title } = item;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | SVGSVGElement | null>(null);
  const [subMenuConfig, setSubMenuConfig] = useState({ width: 0, top: 0, left: 0 });

  const { tableSlugParam, tabSlugParam } = useParams<NavigationParams>();
  const slugParam = tableSlugParam ?? tabSlugParam;
  const isMenuSelected = slugParam && slug === slugParam;

  const tabMenuRef = useRef<HTMLButtonElement | null>(null);

  const classes = useStyles(isMenuSelected);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (tabMenuRef.current) {
      const element = tabMenuRef?.current;
      const position = element?.getBoundingClientRect();

      setSubMenuConfig({ width: position.width, top: position.bottom, left: position.left });
    }
  }, [tabMenuRef]);

  const openMenu = (event: MouseEvent<HTMLElement | SVGSVGElement>) => setAnchorEl(event?.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const handleOnClickSubMenu = (event: MouseEvent<HTMLLIElement>, key: NavItems['itemKey']) => {
    stopEventPropagation(event);
    setAnchorEl(null);
    onChange(event, key);
  };

  return (
    <Box className={classes.container} onMouseEnter={openMenu} onMouseLeave={closeMenu}>
      <Button
        className={clsx(classes.navItem, { selected: isMenuSelected })}
        id={`tab-${id}`}
        onClick={event => onChange(event, itemKey)}
        ref={tabMenuRef}
        type="button">
        {title}

        {/* Icon */}
        {open ? (
          <ArrowDropUpIcon
            aria-controls={`menu-${id}`}
            aria-expanded={open}
            aria-haspopup="true"
            className={clsx(classes.navItemIcon, 'icon')}
            onClick={openMenu}
          />
        ) : (
          <ArrowDropDownIcon
            aria-controls={`menu-${id}`}
            aria-expanded={open}
            aria-haspopup="true"
            className={clsx(classes.navItemIcon, 'icon')}
            onClick={openMenu}
          />
        )}
      </Button>

      {/* Sub-Menu */}
      <Popper
        anchorEl={tabMenuRef.current}
        className={classes.subMenu}
        open={open}
        placement="bottom-start"
        role={undefined}
        transition>
        <Paper>
          <ClickAwayListener onClickAway={closeMenu}>
            <MenuList aria-labelledby={`tab-${id}`} autoFocusItem={open} id={`menu-${id}`}>
              {subMenu?.map(subMenuItem => (
                <MenuItem
                  className={classes.subMenuItem}
                  key={subMenuItem?.itemKey}
                  onClick={event => handleOnClickSubMenu(event, subMenuItem?.itemKey)}
                  style={{ minWidth: subMenuConfig.width }}
                  disabled={disabled}>
                  {subMenuItem?.title}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Box>
  );
};

export default TabSubMenu;
