import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useStore } from 'common/store';
import { MessageBox } from 'components';
import { MeasurementDateDialog } from 'components/MeasurementDates';

const MissingMeasurementDateMessage = props => {
  const { title, tagline } = props;
  const [{ companyInfo }] = useStore();
  const [openMeasurementDatesDialog, setOpenMeasurementDatesDialog] = useState(false);

  const OpenMeasurementDatesDialog = () => (
    <Button
      id="add-md-btn"
      variant="outlined"
      color="primary"
      onClick={() => {
        setOpenMeasurementDatesDialog(true);
      }}
      endIcon={<AddIcon />}>
      Add Measurement Date
    </Button>
  );

  return (
    <>
      <MessageBox title={title} tagline={tagline} fullWidth={false} action={<OpenMeasurementDatesDialog />} />
      {openMeasurementDatesDialog && companyInfo && (
        <MeasurementDateDialog
          open={openMeasurementDatesDialog}
          activeCompany={companyInfo}
          onClose={() => setOpenMeasurementDatesDialog(false)}
        />
      )}
    </>
  );
};

MissingMeasurementDateMessage.propTypes = {
  title: PropTypes.string,
  tagline: PropTypes.string,
};

export default MissingMeasurementDateMessage;
