import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import {
  EFFECTIVE_TAX_RATE_BY_CMD_DEFAULT_ERROR_MESSAGE,
  EFFECTIVE_TAX_RATE_BY_CMD_QUERY_KEY,
} from 'common/constants/services/valuations';
import { ValuationService } from 'services';
import { throwError } from 'utillities';
import {
  GetEffectiveTaxRateByCMDHook,
  GetEffectiveTaxRateByCMDParams,
  GetEffectiveTaxRateByCMDResponse,
  UseGetEffectiveTaxRateByCMD,
} from './types';

const getEffectiveTaxRateByCMD = async (params: GetEffectiveTaxRateByCMDParams) => {
  const { companyMeasurementDateId } = params;

  if (isNil(companyMeasurementDateId)) return null;

  const valuationService = new ValuationService();

  try {
    const effectiveTaxRate = (await valuationService.getEffectiveTaxRate(
      companyMeasurementDateId
    )) as GetEffectiveTaxRateByCMDResponse;

    if (!effectiveTaxRate) return throwError(EFFECTIVE_TAX_RATE_BY_CMD_DEFAULT_ERROR_MESSAGE);

    return effectiveTaxRate;
  } catch (error) {
    return throwError(EFFECTIVE_TAX_RATE_BY_CMD_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetEffectiveTaxRateByCMD: UseGetEffectiveTaxRateByCMD = params => {
  const { companyMeasurementDate, shouldQueryAutomatically = true } = params;

  const companyMeasurementDateId = companyMeasurementDate?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<
    GetEffectiveTaxRateByCMDHook['data'],
    Error
  >({
    enabled: !isNil(companyMeasurementDateId) && shouldQueryAutomatically,
    queryFn: () => getEffectiveTaxRateByCMD({ companyMeasurementDateId }),
    queryKey: [EFFECTIVE_TAX_RATE_BY_CMD_QUERY_KEY, companyMeasurementDateId],
    refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetEffectiveTaxRateByCMD;
