import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

const CaptableVersionEditButton = ({ onClickHandler }) => (
  <Tooltip title="Edit" arrow>
    <IconButton id="edit-handler-btn" className="edit-icon" onClick={onClickHandler} size="small">
      <EditIcon color="primary" aria-label="edit" style={{ fontSize: '1.2rem' }} />
    </IconButton>
  </Tooltip>
);

CaptableVersionEditButton.propTypes = {
  onClickHandler: PropTypes.func,
};

export default CaptableVersionEditButton;
