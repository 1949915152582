/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import parser from '../config/parser';
import rowConfig from '../config/rowConfig';
import { UseValuationMethodsDetailTableDataProps } from '../types';

const useValuationMethodsDetailTableData = ({ data, format }: UseValuationMethodsDetailTableDataProps) => {
  const [columns, setColumns] = useState<any[]>();
  const [spreadsheet, setSpreadsheet] = useState<SpreadsheetConfig>();

  const config = useMemo(() => rowConfig({ approaches: data?.approaches }), [data?.approaches]);

  useEffect(() => {
    if (data?.approaches?.length) {
      const aproachesObj = data.approaches.reduce(
        (prev, current) => ({ ...prev, [`approach-${current.id}`]: current.equity_value }),
        {}
      );
      setColumns([{ ...aproachesObj, ...data }]);
    }
  }, [data?.approaches]);

  const tableTerms: any = useMemo(
    () => ({
      tableName: 'Valuation Methods Detail',
      tableSlug: 'valuation-methods-detail',
      columnName: 'Market Value of Invested Capital',
    }),
    []
  );

  useEffect(() => {
    if (columns && tableTerms) {
      const spreadsheetConfig = new SpreadsheetConfig({
        parser,
        rowConfig: config,
        name: 'ValuationMethodsDetail',
        columns,
        tableData: { columns },
        tableTerms,
        showTotalColumn: false,
        showToolbar: false,
        showPreviousColsDivider: true,
        hasColTitle: true,
        allowConfirmAndDeleteColumn: false,
        currencyFormatter: true,
        unitsFormatter: true,
        totalParser: undefined,
        alwaysDisplayLegend: false,
        format,
      });

      setSpreadsheet(spreadsheetConfig);
    }
  }, [columns, tableTerms]);

  return {
    spreadsheet,
  };
};

export default useValuationMethodsDetailTableData;
