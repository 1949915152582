import React from 'react';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

const LoadingIndicator = ({ isLoading }) => {
  if (isLoading) {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          height: '60vh',
          width: '100vw',
        }}>
        <CircularProgress />
      </div>
    );
  }

  return null;
};

LoadingIndicator.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default LoadingIndicator;
