import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function ExpandCol(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 27">
      <path d="M13 13H19V11H13H11H5V13H11H13Z" fill="#8A9097" />
      <path d="M13 11L13 5L11 5L11 11L11 13L11 19L13 19L13 13L13 11Z" fill="#8A9097" />
      <mask id="path-4-inside-1" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M24 3C24 1.34315 22.6569 0 21 0H3C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H6.78861C7.58426 24 8.34732 24.3161 8.90993 24.8787L9.88049 25.8492C11.0521 27.0208 12.9516 27.0208 14.1231 25.8492L15.0937 24.8787C15.6563 24.3161 16.4194 24 17.215 24H21C22.6569 24 24 22.6569 24 21V3Z" />
      </mask>
      <path d="M15.0937 24.8787L13.6795 23.4645L15.0937 24.8787ZM14.1231 25.8492L12.7089 24.435L14.1231 25.8492ZM8.90993 24.8787L10.3241 23.4645L8.90993 24.8787ZM3 2H21V-2H3V2ZM2 21V3H-2V21H2ZM6.78861 22H3V26H6.78861V22ZM11.2947 24.435L10.3241 23.4645L7.49572 26.2929L8.46628 27.2635L11.2947 24.435ZM13.6795 23.4645L12.7089 24.435L15.5373 27.2635L16.5079 26.2929L13.6795 23.4645ZM21 22H17.215V26H21V22ZM22 3V21H26V3H22ZM21 26C23.7614 26 26 23.7614 26 21H22C22 21.5523 21.5523 22 21 22V26ZM16.5079 26.2929C16.6954 26.1054 16.9498 26 17.215 26V22C15.8889 22 14.6172 22.5268 13.6795 23.4645L16.5079 26.2929ZM8.46628 27.2635C10.4189 29.2161 13.5847 29.2161 15.5373 27.2635L12.7089 24.435C12.3184 24.8256 11.6852 24.8256 11.2947 24.435L8.46628 27.2635ZM6.78861 26C7.05382 26 7.30818 26.1054 7.49572 26.2929L10.3241 23.4645C9.38646 22.5268 8.11469 22 6.78861 22V26ZM-2 21C-2 23.7614 0.238577 26 3 26V22C2.44772 22 2 21.5523 2 21H-2ZM21 2C21.5523 2 22 2.44772 22 3H26C26 0.238576 23.7614 -2 21 -2V2ZM3 -2C0.238576 -2 -2 0.238576 -2 3H2C2 2.44772 2.44772 2 3 2V-2Z" fill="#8A9097" mask="url(#path-4-inside-1)" />
    </SvgIcon>
  );
}
