import { useEffect, useState } from 'react';
import { UNCONTROLLED_ERROR } from 'common/constants/messages/validations';
import { QUESTION_ANSWERED_SUCCESSFULLY } from 'common/constants/process-management';
import TasksService from 'services/tasks';
import useResponse from './useResponse';

export const useTasks = () => {
  const tasksService = new TasksService();
  const { errorNotification, successNotification } = useResponse();
  const [questionOptions, setQuestionOptions] = useState();

  const validateQuestionnairesRequestToken = async (requestId, token) => {
    try {
      const response = await tasksService.validateQuestionnairesRequestToken(requestId, token);
      return response;
    } catch (error) {
      return error;
    }
  };

  const updateQuestionnaireRequestToken = async (requestId, hmacToken, questionData) => {
    try {
      const response = await tasksService.updateQuestionnaireRequestToken(requestId, hmacToken, questionData);
      if (response.answer) {
        successNotification(QUESTION_ANSWERED_SUCCESSFULLY);
      }
    } catch (error) {
      errorNotification(UNCONTROLLED_ERROR);
    }
  };

  const getQuestionOptions = async () => {
    try {
      const response = await tasksService.getQuestionOptions();
      setQuestionOptions(response);
    } catch (error) {
      errorNotification(UNCONTROLLED_ERROR);
    }
  };

  useEffect(
    () => {
      getQuestionOptions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    validateQuestionnairesRequestToken,
    updateQuestionnaireRequestToken,
    questionOptions,
  };
};

export default useTasks;
