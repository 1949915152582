import React, { FC } from 'react';
import { TextField } from '@material-ui/core';
import { ButtonGroupTextFieldProps } from './types';

const ButtonGroupTextField: FC<ButtonGroupTextFieldProps> = props => {
  const { color, disableElevation, fullWidth, disableFocusRipple, disableRipple, ...iconButtonProps } = props;
  return <TextField {...iconButtonProps} />;
};

export default ButtonGroupTextField;
