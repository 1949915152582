/* eslint-disable no-param-reassign */
import React from 'react';
import { isNull, isUndefined } from 'lodash';
import { range } from 'mathjs';
import { LAST_TWELVE_MONTHS, LTM, NEXT_TWELVE_MONTHS, NTM } from 'common/constants/financials';
import { DEFAULT_ZERO_VALUE, MULTIPLE_PREMIUM_DISCOUNT_ALIAS } from 'common/constants/valuations';
import { currencyFormat, smallCurrencyFormat } from 'common/formats/formats';
import { SelectValueViewer } from 'components';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import {
  COMPANY,
  ENTERPRISE_VALUE_COLUMN_ID,
  ENTERPRISE_VALUE_ID,
  ENTERPRISE_VALUE_OPTION,
  EV_EXCL_OPERATING_LEASES_OPTION,
  LTM_EBITDA,
  LTM_EBITDA_CUSTOM_KEY,
  LTM_REVENUE,
  LTM_REVENUE_CUSTOM_KEY,
  mainColumnLetters,
  middleColumnLetters,
  MULTIPLE_BASIS_ALIAS,
  MULTIPLE_BASIS_OPTIONS,
  NTM_EBITDA,
  NTM_EBITDA_CUSTOM_KEY,
  NTM_REVENUE,
  NTM_REVENUE_CUSTOM_KEY,
  SELECTED_MULTIPLE_VALUE,
  SELECTION,
  WEIGHTED_EQUITY_VALUE,
  WEIGHTED_EV,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import * as constants from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import FinancialStatementPeriods from 'pages/Valuations/approaches/guidelinePublicCompanies/FinancialStatementPeriods';
import {
  addBenchmarkCustomKeys,
  getSelectionValue,
  handleMultipleCell,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/utillities';
import handleOptionsNumberFormat, {
  handleDiscountOptionsNumberFormat,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/handleOptionsNumberFormat';
import { getApproachTableName, getEbitdaValueForViewer, getLtmNtmEBITDAs } from 'pages/ValuationsAllocation/util';
import { parseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { STOCK_PRICE_KEY } from './constants';
import { getMultiplePremiumDiscountOptions } from '../../utils/utilities';

const legendKeyMap = {
  A: constants.TICKER_SYMBOL_ID,
  B: STOCK_PRICE_KEY,
  C: constants.MARKET_CAP_ID,
  D: ENTERPRISE_VALUE_COLUMN_ID,
};

const multipleCustomKeyMap = {
  E: {
    [constants.MEDIAN_ALIAS]: `ltm_ebitda_${constants.MEDIAN_ALIAS}`,
    [constants.MEAN_ALIAS]: `ltm_ebitda_${constants.MEAN_ALIAS}`,
    [constants.SEVENTY_FIFTH_ALIAS]: `ltm_ebitda_${constants.SEVENTY_FIFTH_ALIAS}`,
    [constants.TWENTY_FIFTH_ALIAS]: `ltm_ebitda_${constants.TWENTY_FIFTH_ALIAS}`,
  },
  F: {
    [constants.MEDIAN_ALIAS]: `ltm_revenue_${constants.MEDIAN_ALIAS}`,
    [constants.MEAN_ALIAS]: `ltm_revenue_${constants.MEAN_ALIAS}`,
    [constants.SEVENTY_FIFTH_ALIAS]: `ltm_revenue_${constants.SEVENTY_FIFTH_ALIAS}`,
    [constants.TWENTY_FIFTH_ALIAS]: `ltm_revenue_${constants.TWENTY_FIFTH_ALIAS}`,
  },
};

export const multipleCustomKeyList = Object.values(multipleCustomKeyMap).reduce((sofar, next) => {
  const values = Object.values(next).reduce((s2, n2) => {
    s2.push([n2, `benchmark.${n2}`]);
    return s2;
  }, []);
  values.forEach(([key, val]) => sofar.push([`'${key}'`, val]));
  return sofar;
}, []);

function getExpr(columnLegend, expr) {
  return expr ? expr.replace(/@/g, columnLegend) : '';
}

function getVal(column, row) {
  if (row.gridType === 'gridNumberCheckbox') {
    return column[row.alias]?.number;
  }
  // if the column id is ENTERPRISE_VALUE_COLUMN_ID and the row.alias is 'title' we will need to look up a different value
  // this needs to check the column/row to see if we are on a company row
  if (column.id === ENTERPRISE_VALUE_ID && row.alias === 'title') {
    return column.multiple_basis;
  }
  return !isUndefined(column[row.alias]) ? column[row.alias] : null;
}

const getPeriodsOptions = financialsPeriods => {
  const tmpOptions = [];

  const ltmOption = {
    value: LAST_TWELVE_MONTHS,
    label: LAST_TWELVE_MONTHS,
  };
  const ntmOption = {
    value: NEXT_TWELVE_MONTHS,
    label: NEXT_TWELVE_MONTHS,
  };

  if (Array.isArray(financialsPeriods)) {
    financialsPeriods.forEach(period => {
      if (![LTM, NTM].includes(period.period_type)) {
        tmpOptions.push({
          value: period.id,
          label: period.statement_date.slice(0, 4),
        });
      } else if (period.period_type === LTM) {
        ltmOption.value = period.id;
        ltmOption.label = LAST_TWELVE_MONTHS;
      } else if (period.period_type === NTM) {
        ntmOption.value = period.id;
        ntmOption.label = NEXT_TWELVE_MONTHS;
      }
    });
  }
  tmpOptions.push(ltmOption);
  tmpOptions.push(ntmOption);

  return tmpOptions;
};

const financialPeriodCell = ({ financialPeriodsOptions, isDisabled }) => ({
  colSpan: 2,
  rowSpan: 1,
  hidden: false,
  readOnly: financialPeriodsOptions.length <= 2 || isDisabled,
  dataEditor: props => <FinancialStatementPeriods options={financialPeriodsOptions} {...props} />,
  valueViewer: props => <SelectValueViewer options={financialPeriodsOptions} {...props} />,
  dropdown: true,
});

const getFractionDigitsByKey = key => (key === STOCK_PRICE_KEY ? 2 : 0);

const getValue = (isMiddleColumnValue, row, column, columnLegend, approach) => {
  if (row.alias === SELECTION) {
    return getSelectionValue(row, column, approach);
  }

  // the company rows in a, b, c, d have different stuff than those columns from the rowconfig
  // and need to be handled special

  if (isMiddleColumnValue) {
    return column[row.alias]?.[legendKeyMap[columnLegend]];
  }

  // The company rows have object data types, both a number and an enabled status,
  // and the below code keeps the cellReferenceBar from breaking
  return getVal(column, row);
};

const getCellDefaultProps = params => {
  const {
    approach,
    cell,
    columnLegend,
    expr,
    financialsPeriods,
    isMiddleColumnValue,
    row,
    shouldRevertSelector,
    tmpCell,
    type,
    useFinancialStatementAdjustedEbitda,
    value,
  } = params;

  let cellProps = { ...tmpCell };

  // Copy default props from the row config only if we are in the main columns
  if (mainColumnLetters.indexOf(columnLegend) >= 0) {
    if (shouldRevertSelector) {
      cellProps = {
        ...row,
        ...cell,
        expr: expr(),
      };

      delete cellProps.dataEditor;
      delete cellProps.valueViewer;
      delete cellProps.isEditableTitleCell;
      delete cellProps.useScalarSpreadsheetCell;
    } else {
      cellProps = {
        ...row,
        ...cell,
        // EBITDAs and REVENUEs may be negative
        allowNegativeValue: [LTM_EBITDA, NTM_EBITDA, LTM_REVENUE, NTM_REVENUE].includes(cell.columnId),
        expr: expr(),
        value: parseValue(value, type, null, null, row.dbType),
      };

      const options = getLtmNtmEBITDAs(financialsPeriods);

      if (
        cell.alias === COMPANY
        && [LTM_EBITDA, NTM_EBITDA].includes(cell.columnId)
        && useFinancialStatementAdjustedEbitda
        && options[cell.columnId]?.some(option => option.value !== options[cell.columnId][0].value)
      ) {
        cellProps = {
          ...cellProps,
          readOnly: false,
          dataEditor: props => (
            <GridSelect
              options={options[cell.columnId]}
              handleOptionsNumberFormat={handleOptionsNumberFormat}
              {...props}
            />
          ),
          valueViewer: props => <SelectValueViewer options={options[cell.columnId]} {...props} />,
          value: getEbitdaValueForViewer({
            cell,
            options,
            valuation_approach_adjustments: approach.valuations_approach_gpc,
          }),
        };
      }
      if (cell.alias === MULTIPLE_PREMIUM_DISCOUNT_ALIAS) {
        const mpdReference = getApproachTableName({ approach, tableSuffix: 'multiple_premium_discount' });

        const multiplePremiumDiscount = approach?.valuations_approach_gpc?.multiple_premium_discount;
        const opts = getMultiplePremiumDiscountOptions(mpdReference, multiplePremiumDiscount);
        const values = {
          [LTM_EBITDA]: parseFloat(approach?.valuations_approach_gpc?.ltm_ebitda_multiple_premium_discount || 0),
          [LTM_REVENUE]: parseFloat(approach?.valuations_approach_gpc?.ltm_revenue_multiple_premium_discount || 0),
          [NTM_EBITDA]: parseFloat(approach?.valuations_approach_gpc?.ntm_ebitda_multiple_premium_discount || 0),
          [NTM_REVENUE]: parseFloat(approach?.valuations_approach_gpc?.ntm_revenue_multiple_premium_discount || 0),
        };

        const cellValue = values[cell.columnId] !== 0 ? `=${mpdReference}.C8` : DEFAULT_ZERO_VALUE;
        cellProps = {
          ...cellProps,
          readOnly: false,
          customKey: `${cell.columnId}_MPD`,
          dataEditor: props => (
            <GridSelect options={opts} handleOptionsNumberFormat={handleDiscountOptionsNumberFormat} {...props} />
          ),
          valueViewer: props => <SelectValueViewer useLabel options={opts} {...props} />,
          value: cellValue,
        };
      }

      if (cell.alias === SELECTED_MULTIPLE_VALUE) {
        let customKey;
        switch (cell.columnId) {
          case LTM_EBITDA:
            // eslint-disable-next-line no-param-reassign
            customKey = LTM_EBITDA_CUSTOM_KEY;
            break;
          case NTM_EBITDA:
            // eslint-disable-next-line no-param-reassign
            customKey = NTM_EBITDA_CUSTOM_KEY;
            break;
          case LTM_REVENUE:
            // eslint-disable-next-line no-param-reassign
            customKey = LTM_REVENUE_CUSTOM_KEY;
            break;
          case NTM_REVENUE:
            // eslint-disable-next-line no-param-reassign
            customKey = NTM_REVENUE_CUSTOM_KEY;
            break;
          default:
            break;
        }
        cellProps = {
          ...cellProps,
          customKey,
        };
      }
    }
  } else if (isMiddleColumnValue && columnLegend !== 'A') {
    const fractionDigits = getFractionDigitsByKey(legendKeyMap[columnLegend]);
    cellProps = {
      ...cell,
      gridType: 'number',
      format: currencyFormat({ fractionDigits }),
    };
  }

  return cellProps;
};

const handleEnterpriseValueCell = ({ cell, expr, isMiddleColumnValue, tmpCell }) => {
  let enterpriseValueCell = { ...tmpCell };

  if (cell.alias === 'title' && cell.columnId === ENTERPRISE_VALUE_COLUMN_ID) {
    enterpriseValueCell = {
      ...enterpriseValueCell,
      readOnly: false,
      alias: MULTIPLE_BASIS_ALIAS,
      dataEditor: props => <GridSelect options={MULTIPLE_BASIS_OPTIONS} enumerated {...props} />,
      valueViewer: props => <SelectValueViewer options={MULTIPLE_BASIS_OPTIONS} useLabel {...props} />,
    };
  } else if (cell.alias !== 'title' && cell.columnId === ENTERPRISE_VALUE_COLUMN_ID && isMiddleColumnValue) {
    enterpriseValueCell = {
      ...enterpriseValueCell,
      readOnly: true,
      expr: expr(),
    };
  }

  return enterpriseValueCell;
};

const updateCellProps = params => {
  const {
    approach,
    cell,
    cellProps,
    column,
    columnLegend,
    expr,
    financialsPeriods,
    periodYears,
    row,
    useFinancialStatementAdjustedEbitda,
  } = params;
  const { value, type, isMiddleColumnValue, rowNumber } = cellProps;

  let tmpCell = cell;
  const shouldRevertSelector = constants.PERCENTILE_ROWS.includes(row.alias);

  tmpCell = getCellDefaultProps({
    approach,
    cell,
    columnLegend,
    expr,
    financialsPeriods,
    isMiddleColumnValue,
    row,
    shouldRevertSelector,
    tmpCell,
    type,
    useFinancialStatementAdjustedEbitda,
    value,
  });

  addBenchmarkCustomKeys(cell, tmpCell, column);

  if (typeof column[row.alias] === 'object' && !isNull(column[row.alias])) {
    if (mainColumnLetters.indexOf(columnLegend) >= 0) {
      const rowData = column[row.alias];
      const [periodType, financialKey] = column.id.split('_'); // ltm_revenue => ltm, revenue
      const customYear = periodYears[periodType];
      const financialPeriodValue
        = customYear && rowData?.calendarYears?.[customYear]
          ? rowData.calendarYears[customYear][financialKey]
          : rowData.number;

      const isValidPeriodValue = parseFloat(financialPeriodValue) !== 0;

      tmpCell.ignoreExpr = !isValidPeriodValue;
      tmpCell.enabled = rowData.enabled && isValidPeriodValue;
      tmpCell.expr = isValidPeriodValue ? `=D${rowNumber}/${financialPeriodValue}` : '0';
      tmpCell.revenue = column[row.alias].number;
    }
  }

  tmpCell = handleEnterpriseValueCell({
    cell,
    expr,
    isMiddleColumnValue,
    tmpCell,
  });

  return tmpCell;
};

const cleanReadOnlyCells = params => {
  const { cell, cells, columnLegend, row, weightedEnterpriseValueRow, weightedEquitiveValueRow } = params;

  // Clean Multiple Type and Weighted Enterprise Value cells
  switch (columnLegend) {
    case 'A': // Ticker Symbol
    case 'B': // Stock Price
    case 'C': // Market Cap
    case 'D': // Enterprise Value
      // Clean Multiple Type cells
      if (row?.alias === SELECTION) {
        cell.expr = null;
        cell.gridType = 'string';
        cell.value = '';
      }

      // Clean Weighted Enterprise Value cells
      cells[columnLegend + weightedEnterpriseValueRow] = {
        ...cells[columnLegend + weightedEnterpriseValueRow],
        expr: null,
        gridType: 'string',
        value: '',
      };

      cells[columnLegend + weightedEquitiveValueRow] = {
        ...cells[columnLegend + weightedEquitiveValueRow],
        expr: null,
        gridType: 'string',
        value: '',
      };
      break;

    default:
      break;
  }
};

// need to read database option and put that in multiple type cell
const parser = async ({ columns, rowConfig, tableData }) => {
  const { approach, financialsPeriods, isDisabled } = tableData;
  const { valuations_approach_gpc } = approach;
  const {
    financials: {
      use_adjusted_ebitda: useFinancialStatementAdjustedEbitda,
      total_cash_equivalents: plusCash,
      total_debt: lessDebt,
    },
  } = tableData;

  const getWeightedEquityValueExpr = () => `=E${rowConfig.length - 1} + ${plusCash} - ${lessDebt}`;

  const {
    gpc_comparison,
    ltm_financial_statement_period_id,
    ntm_financial_statement_period_id,
    use_multiple_premium_discount,
  } = valuations_approach_gpc;

  const getPeriodId = type => {
    const id = type === LTM ? ltm_financial_statement_period_id : ntm_financial_statement_period_id;

    if (id) {
      return id;
    }

    return null;
  };

  const financialPeriodsOptions = getPeriodsOptions(financialsPeriods);
  let periodYears = { ltm: null, ntm: null };

  const buildPeriodYears = (id, type) => {
    if (!id) {
      return;
    }

    const ltmPeriod = financialPeriodsOptions.find(p => p.value.toString() === id.toString());

    if (ltmPeriod) {
      periodYears = {
        ...periodYears,
        [type]: ltmPeriod?.label,
      };
    }
  };

  const ltmPeriodId = getPeriodId(LTM);
  const ntmPeriodId = getPeriodId(NTM);

  buildPeriodYears(ltmPeriodId, 'ltm');
  buildPeriodYears(ntmPeriodId, 'ntm');

  const getPeriodValueByLabel = label => financialPeriodsOptions.find(p => p.label === label).value;

  // These default cells consist of some default cell headers
  let cells = {
    E1: {
      key: constants.VALUATION_GPC_LTM_DROPDOWN_KEY,
      ...financialPeriodCell({ financialPeriodsOptions, isDisabled }),
      alias: LAST_TWELVE_MONTHS,
      value: ltmPeriodId || getPeriodValueByLabel(LAST_TWELVE_MONTHS),
      shouldDisplayTooltip: true,
    },
    G1: {
      key: 'G1',
      ...financialPeriodCell({ financialPeriodsOptions, isDisabled }),
      alias: NEXT_TWELVE_MONTHS,
      value: ntmPeriodId || getPeriodValueByLabel(NEXT_TWELVE_MONTHS),
    },
    A1: {
      key: 'A1',
      value: 'CAPITAL IQ EXTRA INFO',
      readOnly: true,
      colSpan: 4,
      rowSpan: 1,
      parentColumn: LTM_REVENUE,
      className: 'table-header full-width-label',
    },
  };
  // end default cell headers

  // Below are custom generated cells
  // this generates the weighted EV cell
  const weightingRow = rowConfig.length - 2;
  const valueRow = rowConfig.length - 3;

  function getExpressionPiece(legend) {
    return `(${legend}${weightingRow}/100 * ${legend}${valueRow})`;
  }

  cells[`E${rowConfig.length - 1}`] = {
    key: `E${rowConfig.length - 1}`,
    colSpan: 4,
    alias: WEIGHTED_EV,
    columnLegend: 'E',
    gridType: 'number',
    customKey: WEIGHTED_EV,
    expr: `=${mainColumnLetters.map(legend => getExpressionPiece(legend)).join(' + ')}`,
    format: smallCurrencyFormat,
    className: 'subtitle large-cell-value',
    isNotNavigable: true,
  };
  cells[`E${rowConfig.length}`] = {
    key: `E${rowConfig.length}`,
    colSpan: 4,
    alias: 'equity_value',
    columnLegend: 'E',
    gridType: 'number',
    customKey: WEIGHTED_EQUITY_VALUE,
    expr: getWeightedEquityValueExpr(),
    format: smallCurrencyFormat,
    className: 'subtitle large-cell-value',
    isNotNavigable: true,
  };

  // these map the extra bottom cells for the very bottom row, as that row is set to ignore
  // generating default cells
  middleColumnLetters
    .flatMap(letter => [
      {
        key: `${letter}${rowConfig.length - 1}`,
        parentColumn: LTM_REVENUE,
        className: 'subtitle',
      },
      {
        key: `${letter}${rowConfig.length}`,
        parentColumn: LTM_REVENUE,
        className: 'subtitle',
      },
    ])
    .forEach(cell => {
      cells[cell.key] = cell;
    });
  // end Custom generated cells

  const alphabet = alphabetGenerator([], columns.length);

  const handleTitleColumnCell = params => {
    const { column, key } = params;

    if (key === 'E1' || key === `E${rowConfig.length - 1}` || key === `E${rowConfig.length}`) {
      cells = {
        ...cells,
        [key]: {
          ...cells[key],
          isParent: column.isParent,
          columnId: column.id,
        },
      };
    }
  };

  const getEnterpriseValueExpression = params => {
    const { cellExpr, column, isMiddleColumnValue, row } = params;
    let expression = cellExpr;

    if (column.id === ENTERPRISE_VALUE_COLUMN_ID && row.alias !== 'title' && isMiddleColumnValue) {
      expression = `=FROM_KEY_VALUE_PAIRS([['${ENTERPRISE_VALUE_OPTION}', ${
        column[row.alias].enterprise_value
      }],['${EV_EXCL_OPERATING_LEASES_OPTION}', ${column[row.alias].ev_excl_operating_leases}]], @2)`;
    }

    return expression;
  };

  function handleRow(column, columnLegend) {
    const rowRange = range(1, gpc_comparison.length + 1)
      .map(rowNumber => `${columnLegend}${rowNumber + 2}`)
      .toString()
      .replace(/"/g, '');

    return (row, index) => {
      const rowNumber = index + 1;
      const key = columnLegend + rowNumber;

      // Add isParent to Title column cell
      handleTitleColumnCell({ column, key });

      if (index === 0 || index === rowConfig.length - 2 || index === rowConfig.length - 1) {
        return;
      }

      const isMiddleColumnValue
        = legendKeyMap[columnLegend]
        && index !== 1
        && index <= approach?.valuations_approach_gpc?.gpc_comparison?.length + 1;

      const value = getValue(isMiddleColumnValue, row, column, columnLegend, valuations_approach_gpc);
      const type = row.gridType || null;

      let cellExpr = row.expr;

      cellExpr = getEnterpriseValueExpression({ cellExpr, column, isMiddleColumnValue, row });

      const expr = () => getExpr(columnLegend, cellExpr);

      // I don't want the default props in some columns as the 'middle'
      // cells represent entirely different kinds of data in each column
      let cell = {
        key,
        alias: row.alias || '',
        columnId: column.id,
        columnLegend,
        columnOrder: column.order,
        customFormat: column.customFormat || null,
        ignoreAutoScroll: row.ignoreAutoScroll,
        isNotNavigable: row.isNotNavigable,
        isParent: column.isParent,
        isVisible: row.isVisible,
        parentColumn: column.parentColumn,
        rowNumber,
        value,
        isGpcRow: true,
      };

      const cellProps = {
        value,
        type,
        isMiddleColumnValue,
        rowNumber,
      };

      cell = updateCellProps({
        approach,
        cell,
        cellProps,
        column,
        columnLegend,
        expr,
        financialsPeriods,
        periodYears,
        row,
        useFinancialStatementAdjustedEbitda,
      });

      const isPercentileCell = constants.PERCENTILE_ROWS.includes(cell.alias);
      if (isPercentileCell) {
        if (constants.LTM_NTM_COLUMNS_ALIAS.includes(cell.columnId)) {
          const percentileExpr = `=PERCENTILE(FILTER_ENABLED(${rowRange}), TITLES_${cell.alias})`;
          cell.expr = percentileExpr;
        }
      }

      // Clean Multiple Type and Weighted Enterprise Value cells
      cleanReadOnlyCells({
        cell,
        cells,
        columnLegend,
        row,
        weightedEnterpriseValueRow: rowConfig.length - 1,
        weightedEquitiveValueRow: rowConfig.length,
      });

      handleMultipleCell(cell, column, use_multiple_premium_discount);

      cells = {
        ...cells,
        [key]: cell,
      };
    };
  }

  // Parse body cells
  columns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];
    rowConfig.forEach(handleRow(column, columnLegend));
  });

  return cells;
};

export default parser;
