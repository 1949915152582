import { sortBy } from 'lodash';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import parseDatabaseValue from 'utillities/parseDatabaseValue';

const reverseParser = ({ cells, columns, allowEmptyValues, rowConfig, fieldAttributes }) => {
  let tableColumns = [];
  const { kpiAttrs } = fieldAttributes;
  const decimalPlaces = kpiAttrs?.kpi_value?.decimal_places ?? 4;

  const sortedColumns = sortBy(columns, ['order']);
  const alphabet = alphabetGenerator([], sortedColumns.length);

  sortedColumns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];

    // copy original values
    const tmpColumn = { ...column };
    const kpiDataSet = [];

    // replace updated values
    rowConfig.forEach((cell, cellIndex) => {
      if (cellIndex < 3) return;

      const key = columnLegend + (cellIndex + 1);
      const defaultValue = cell.defaultValue || null;
      const type = cell.dbType || null;
      const format = cell.format || null;
      const value = cells[key] ? cells[key].value : defaultValue;
      const { gridType } = cell;

      const parsedValue = parseDatabaseValue({
        type,
        value,
        defaultValue,
        format,
        allowEmptyValues,
        gridType,
        decimalPlaces,
      });

      if (cell.alias && cells[key]) {
        kpiDataSet.push({
          company_kpi: {
            ref: cell.alias, // this way we can map things correctly when writing new KPIs in the backend
            sort_order: cellIndex - 3,
          },
          id: cells[key].kpiId,
          kpi_value: parsedValue,
        });
      }
    });
    tmpColumn.kpi_dataset = kpiDataSet;

    tableColumns = [...tableColumns, tmpColumn];
  });

  return tableColumns;
};

export default reverseParser;
