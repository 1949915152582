import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from 'theme';

const FRContentTitle = ({ children }) => (
  <Typography
    style={{
      fontWeight: 700,
      fontSize: '1.125rem',
      color: theme.palette.black,
    }}
    gutterBottom>
    {children}
  </Typography>
);

FRContentTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

export default FRContentTitle;
