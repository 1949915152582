import React, { useContext, useState } from 'react';
import { ListItemText, makeStyles, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DELETE_REFERENCE_CUSTOM_MESSAGE, DELETE_REFERENCE_TOOLTIP, FILE_NAME } from 'common/constants/documents';
import * as messages from 'common/constants/messages/validations';
import { ConfirmationDelete } from 'components/Dialogs';
import { DialogContext } from 'context';
import useDocumentsContext from 'context/DocumentsContext';
import { useDocuments, useResponse } from 'services/hooks';
import { formatDateViewLL } from 'utillities';
import getCancelDialogOptions from 'utillities/getCancelDialogOptions';
import DeleteButton from './DeleteButton';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemText: {
    marginRight: '1rem',
    '&& .MuiTypography-body1': { textDecoration: 'underline' },
    '&:hover': {
      cursor: 'pointer',
    },
    wordBreak: 'break-all',
  },
});

const ReferencedDocument = ({ document, selectedMeasurementDateId, currentPage, isDisabled }) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const dialogContext = useContext(DialogContext);
  const { documents, updateReferencedDocuments } = useDocumentsContext();
  const { addDocumentReferencesObject, downloadDocument, setIsLoading } = useDocuments();
  const { errorNotification, successNotification } = useResponse();
  const uploadedBy = document.file.uploaded_by?.full_name && `by ${document.file.uploaded_by.full_name}`;

  const getReferencesData = referencedFeatureId => ({
    create_list: [],
    delete_list: [referencedFeatureId],
  });

  const handleReferences = async () => {
    try {
      // Get id of the corresponding reference
      const referencedFeatureId = document.referenced_in.find(item => item.reference_object_type === currentPage).id;
      const updatedReferenceData = getReferencesData(referencedFeatureId);
      const requestData = {
        ...updatedReferenceData,
        measurement_date_id: selectedMeasurementDateId,
      };
      await addDocumentReferencesObject(selectedMeasurementDateId, requestData);
      await updateReferencedDocuments(documents, currentPage);
      successNotification(messages.REFERENCE_DELETED_SUCCESS);
    } catch (error) {
      errorNotification(messages.REFERENCE_DELETED_ERROR);
    }
  };

  const handleClick = async () => {
    const { showDialog } = dialogContext;
    const ContentComponent = <ConfirmationDelete customMessage={DELETE_REFERENCE_CUSTOM_MESSAGE(document)} />;

    showDialog(
      getCancelDialogOptions(
        () => ContentComponent,
        () => handleReferences()
      )
    );
  };

  const handleDownload = async documentParam => {
    setIsLoading(true);
    await downloadDocument(documentParam);
    setIsLoading(false);
  };

  return (
    <div className={classes.root} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {hover && <DeleteButton isDisabled={isDisabled} title={DELETE_REFERENCE_TOOLTIP} handleClick={handleClick} />}
      <Tooltip title="Click to download." placement="top-start">
        <ListItemText
          className={classes.listItemText}
          primary={FILE_NAME(document)}
          onClick={() => handleDownload(document)}
          secondary={`${formatDateViewLL(document.file.uploaded_date)} ${uploadedBy || ''}`}
        />
      </Tooltip>
    </div>
  );
};

ReferencedDocument.propTypes = {
  document: PropTypes.shape({
    document_reference_id: PropTypes.number,
    file: PropTypes.shape({
      file_path: PropTypes.string,
      file_type: PropTypes.string,
      filename: PropTypes.string,
      guid: PropTypes.string,
      id: PropTypes.number,
      uploaded_by: PropTypes.shape({
        full_name: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number,
      }),
      uploaded_date: PropTypes.string,
    }),
    folder: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    referenced_in: PropTypes.arrayOf(
      PropTypes.shape({
        document_reference: PropTypes.number,
        id: PropTypes.number,
        reference_object_id: PropTypes.number,
        reference_object_type: PropTypes.string,
      })
    ),
  }),
  selectedMeasurementDateId: PropTypes.number,
  currentPage: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default ReferencedDocument;
