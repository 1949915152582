import React, { memo } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

const firstRowHeight = 50;
const rowHeight = 30;

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'nowrap',
  },
  cell: {
    margin: 2,
    flexGrow: 1,
    '&:first-child': {
      flexGrow: 2,
    },
  },
}));

const Cells = memo(({ row, maxColumns, classes }) => {
  const cols = [];

  for (let i = 0; i < maxColumns; i += 1) {
    cols.push(
      <Skeleton
        variant="rect"
        className={classes.cell}
        height={row === 0 ? firstRowHeight : rowHeight}
        key={`${row}-${i}`}
      />
    );
  }
  return cols;
});

const GridSkeleton = ({ rows, maxRows, maxColumns }) => {
  const classes = useStyles();

  return (
    <div>
      {rows.slice(0, maxRows).map((row, rowIndex) => (
        <div key={uuid()} className={classes.row}>
          <Cells row={rowIndex} maxColumns={maxColumns} classes={classes} />
        </div>
      ))}
    </div>
  );
};

GridSkeleton.defaultProps = {
  maxRows: 10,
  maxColumns: 10,
  rows: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
};

GridSkeleton.propTypes = {
  rows: PropTypes.array,
  maxRows: PropTypes.number,
  maxColumns: PropTypes.number,
};

Cells.propTypes = {
  row: PropTypes.number,
  maxColumns: PropTypes.number,
  classes: PropTypes.object,
};

export default GridSkeleton;
