import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const GoToSubscription = ({ subscriptionUrl }) => {
  const history = useHistory();

  function redirectToSubscription() {
    history.push(subscriptionUrl);
  }

  return (
    <Button variant="outlined" color="primary" onClick={redirectToSubscription}>
      Go to subscription
    </Button>
  );
};

GoToSubscription.propTypes = {
  subscriptionUrl: PropTypes.string,
};

export default GoToSubscription;
