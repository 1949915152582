import React, { FC } from 'react';
import { ConfirmationDelete } from 'components/Dialogs';
import { DeleteApproachDialogProps } from './types';

const NAME = 'approach';
const MESSAGE
  = 'This approach is referenced by one or more allocation scenario(s). Deleting this valuation approach will delete all referenced allocation scenarios. Do you want to continue?';

const DeleteApproachDialog: FC<DeleteApproachDialogProps> = props => {
  const { isReferencedApproach } = props;

  return <ConfirmationDelete itemName={NAME} customMessage={isReferencedApproach ? MESSAGE : ''} />;
};

export default DeleteApproachDialog;
