export const getUserPreferences = (user, item) => {
  if (!item || !user) return false;

  const preferences = JSON.parse(item);

  return preferences?.[user?.id] ?? null;
};

export const setUserPreferences = (user, key, value) => {
  if (!user || !key) return;

  localStorage.setItem(key, JSON.stringify(value));
  window.dispatchEvent(new Event('storage'));
};
