/* eslint-disable @typescript-eslint/no-explicit-any */
import compactFormatterNumber from './compactFormatterNumber';

export const CURRENT_REV_OPTIONS = {
  responsive: true,
  stacked: true,
  plugins: {
    legend: {
      position: 'bottom' as const,
      labels: {
        usePointStyle: true,
      },
    },
  },
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
};

export const VALUATION_TREND = {
  responsive: true,
  stacked: false,
  plugins: {
    legends: {
      positions: 'top' as const,
    },
    title: {
      display: false,
      text: 'Valuation Trends',
    },
    tooltip: {
      callbacks: {
        label(tooltipItems: any) {
          return `$${tooltipItems.formattedValue}`;
        },
      },
    },
  },
  scales: {
    equity: {
      display: true,
      position: 'left' as const,
      ticks: {
        callback: (value: any) => {
          if (typeof value === 'number') {
            return `$${compactFormatterNumber.format(value)}`;
          }

          return value;
        },
      },
      grid: { display: false },
    },
    common: {
      display: true,
      position: 'right' as const,
      ticks: {
        callback: (value: any) => {
          if (typeof value === 'number') {
            return `$${compactFormatterNumber.format(value)}`;
          }

          return value;
        },
      },
      grid: { display: false },
    },
  },
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
};

export const WATERFALL_OPTIONS = {
  responsive: true,
  plugins: {
    title: {
      display: false,
      text: 'Waterfall Breakpoint Analysis',
    },
    legend: {
      position: 'bottom' as const,
      labels: {
        usePointStyle: true,
      },
    },
  },
  scales: {
    y: {
      type: 'linear' as const,
      stacked: true,
      grid: { display: false },
      ticks: {
        callback(value: number | string) {
          if (typeof value === 'number') {
            return `$${compactFormatterNumber.format(value)}`;
          }

          return value;
        },
        autoSkip: true,
        maxTicksLimit: 9,
      },
    },
  },
  interaction: {
    mode: 'index' as const,
    intersect: false,
    includeInvisible: true,
  },
};

export const RATES_OF_CHANGE_OPTIONS = {
  responsive: true,
  stacked: true,
  plugins: {
    legend: {
      position: 'bottom' as const,
      labels: {
        usePointStyle: true,
      },
    },
  },
  scales: {
    x: {
      ticks: {
        autoSkip: true,
        maxTicksLimit: 9,
      },
    },
  },
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
};
