import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

export const FinancialsContext = createContext();

const FinancialsProvider = ({ children, setIncomeStatement, setBalanceSheets, setKpi, ...props }) => {
  useEffect(
    () => () => () => {
      if (window.appGlobal) {
        delete window.appGlobal.incomeStatement;
      }
    },
    []
  );

  const setIncomeStatementData = useCallback(
    data => {
      window.appGlobal = {
        ...window.appGlobal,
        incomeStatement: {
          ...window.appGlobal?.incomeStatement,
          ...data,
        },
      };

      setIncomeStatement(data);
    },
    [setIncomeStatement]
  );

  const setBalanceSheetsData = useCallback(
    data => {
      window.appGlobal = {
        ...window.appGlobal,
        balanceSheets: {
          ...window.appGlobal?.balanceSheets,
          ...data,
        },
      };

      setBalanceSheets(data);
    },
    [setBalanceSheets]
  );

  const setKpiData = useCallback(
    data => {
      window.appGlobal = {
        ...window.appGlobal,
        kpi: {
          ...window.appGlobal?.kpi,
          ...data,
        },
      };

      setKpi(data);
    },
    [setKpi]
  );

  const providerValue = useMemo(
    () => ({
      ...props,
      setIncomeStatement,
      setIncomeStatementData,
      setBalanceSheets,
      setBalanceSheetsData,
      setKpi,
      setKpiData,
    }),
    [props, setIncomeStatement, setIncomeStatementData, setBalanceSheets, setBalanceSheetsData, setKpi, setKpiData]
  );

  return <FinancialsContext.Provider value={providerValue}>{children}</FinancialsContext.Provider>;
};

FinancialsProvider.propTypes = {
  children: PropTypes.any,
  setIncomeStatement: PropTypes.func,
  setBalanceSheets: PropTypes.func,
  setKpi: PropTypes.func,
};

const useFinancials = () => useContext(FinancialsContext);

export { FinancialsProvider, useFinancials };
