import { CompanyMeasurementDate, UserPermission } from 'api';
import { COMPANY, EDIT, FUND } from 'common/constants/user';

interface Permission extends UserPermission {
  remove_permission: boolean;
}

interface UserPermissions {
  user_id: number;
  permissions: Permission[];
}

interface Fund {
  id: number;
  name: string;
  slug: string;
  currency: string;
  is_deleted: boolean;
}

interface Company {
  company_id: number;
  company_name: string;
  company_slug: string;
  measurement_date_slug: string;
  company_measurement_dates: CompanyMeasurementDate[];
}

// This function will be used when we implement a custom role (one that has a mix of permissions)
export const getAllPotentialPermissions = (
  userPermissionsToSave: UserPermissions,
  fundList: Fund[],
  companyList: Company[]
) => {
  const allMissingFundsAndCompsPermissions: Permission[] = [];
  fundList.forEach(fund => {
    // Only include funds that are not already in the selected user's permissions
    const fundPermissionIds = userPermissionsToSave?.permissions
      ?.filter(
        perm => !perm.remove_permission && perm?.feature_object?.object_type === FUND && perm.access_type === EDIT
      )
      .map(perm => perm?.feature_object?.object_id);
    if (!fundPermissionIds?.includes(fund.id)) {
      allMissingFundsAndCompsPermissions.push({
        id: 0,
        feature_object: {
          feature: null,
          object_type: FUND,
          object_id: fund.id,
        },
        access_type: EDIT,
        remove_permission: false,
        firm_role: null,
      });
    }
  });
  companyList.forEach(company => {
    // Only include companies that are not already in the selected user's permissions
    const companyPermissionIds = userPermissionsToSave?.permissions
      ?.filter(
        perm => !perm.remove_permission && perm?.feature_object?.object_type === COMPANY && perm.access_type === EDIT
      )
      .map(perm => perm?.feature_object?.object_id);
    if (!companyPermissionIds?.includes(company.company_id)) {
      allMissingFundsAndCompsPermissions.push({
        id: 0,
        feature_object: {
          feature: null,
          object_type: COMPANY,
          object_id: company.company_id,
        },
        access_type: EDIT,
        remove_permission: false,
        firm_role: null,
      });
    }
  });

  return allMissingFundsAndCompsPermissions;
};
