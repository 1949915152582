import {
  fullyDilutedOwnershipFormat,
  largeCurrencyFormat,
  largeDecimalFormat,
  xStandardSuffixFormat,
} from 'common/formats/formats';
import { Cell, Cells } from 'common/types/scalarSpreadsheet';
import { SHEET_ALIASES_CONSTANTS, SHEET_TITLES_CONSTANTS } from 'pages/Funds/common/constants/scheduleOfInvestments';
import { generateAllRowsExpression, getExpression, getObjectValue, removeFormatIfBlank } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { CellParserParams, CustomParserParams, GetInvestmentsExpressionsParams } from './types';

const { SCHEDULE_OF_INVESTMENTS_SPREADSHEET_HEADER_TITLE, SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TOTAL }
  = SHEET_ALIASES_CONSTANTS;
const { SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS, SCHEDULE_OF_INVESTMENTS_SPREADSHEET_HEADERS }
  = SHEET_TITLES_CONSTANTS;

const DEFAULT_ALLOW_NEGATIVE_VALUE = true;
const DEFAULT_ROW_SPAN = 1;
const EXCLUDE_HEADER = 1;
const EXCLUDE_HEADER_AND_TOTAL = 2;

const cellParser = (params: CellParserParams) => {
  const {
    allInvestmentsExpression,
    alphabet,
    colIndex,
    column,
    row,
    rowIndex,
    totalInvestedCapitalKey,
    totalTotalValueKey,
  } = params;

  const rowNumber = rowIndex + 1;
  const colNumber = colIndex + 1;
  const columnLegend = alphabet[colIndex];
  const key = columnLegend + rowNumber;

  const { alias, tooltipMessages = [] } = getObjectValue(row);
  let { expr = '', format = null, gridType = 'string' } = getObjectValue(row);

  let { value = '' } = getObjectValue(column[alias]);

  // Parse cell based on Row alias
  switch (alias) {
    // Header titles
    case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_HEADER_TITLE:
      value = SCHEDULE_OF_INVESTMENTS_SPREADSHEET_HEADERS[colNumber].value;
      break;

    // Totals
    case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TOTAL:
      switch (colNumber) {
        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.INVESTED_CAPITAL:
        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.REALIZED_VALUE:
        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.UNREALIZED_EQUITY:
        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.TOTAL_VALUE:
          expr = `=SUM(${allInvestmentsExpression})`;
          format = largeCurrencyFormat;
          gridType = 'number';
          break;

        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.SHARES:
        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.CSE_SHARES:
          expr = `=SUM(${allInvestmentsExpression})`;
          format = largeDecimalFormat;
          gridType = 'number';
          break;

        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.FULLY_DILUTED_OWNERSHIP:
          expr = `=SUM(${allInvestmentsExpression})`;
          format = fullyDilutedOwnershipFormat;
          gridType = 'percentage';
          break;

        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.MOIC:
          // Total X Factor or MOIC = Total of Total Value / Total Invested Capital
          expr = `=IF(${totalInvestedCapitalKey}>0,${totalTotalValueKey}/${totalInvestedCapitalKey},0)`;
          format = xStandardSuffixFormat;
          gridType = 'number';
          break;

        default:
          break;
      }
      break;

    // Rest of the rows (excluding Header titles and Totals)
    default:
      // Parse cell based on Column number
      switch (colNumber) {
        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.INVESTED_CAPITAL:
        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.REALIZED_VALUE:
        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.UNREALIZED_EQUITY:
        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.TOTAL_VALUE:
          ({ value, format, gridType } = removeFormatIfBlank(value, largeCurrencyFormat, 'number'));
          break;

        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.SHARES:
        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.CSE_SHARES:
          format = largeDecimalFormat;
          gridType = 'number';
          break;

        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.FULLY_DILUTED_OWNERSHIP:
          format = fullyDilutedOwnershipFormat;
          gridType = 'percentage';
          break;

        case SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.MOIC:
          ({ value, format, gridType } = removeFormatIfBlank(value, xStandardSuffixFormat, 'number'));
          break;

        default:
          break;
      }
      break;
  }

  return {
    [key]: {
      ...row,
      allowNegativeValue: DEFAULT_ALLOW_NEGATIVE_VALUE,
      columnLegend,
      expr: getExpression({ expr, columnLegend }),
      format,
      gridType,
      key,
      rowSpan: DEFAULT_ROW_SPAN,
      tooltipMessages,
      value,
    } as Cell,
  };
};

const getInvestmentsExpressions = (params: GetInvestmentsExpressionsParams) => {
  const { alphabet, rowConfig } = params;

  const allInvestmentsExpression = generateAllRowsExpression({
    rowConfig,
    excludedRows: EXCLUDE_HEADER,
    excludeLastRow: true,
  });

  const totalRowNumber = allInvestmentsExpression.length + EXCLUDE_HEADER_AND_TOTAL; // Same length as rowConfig

  // Columns legends (-1 excluding Type of Security Column)
  const investedCapitalColLegend = alphabet[SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.INVESTED_CAPITAL - 1];
  // Columns legends (-1 excluding Type of Security Column)
  const totalValueColLegend = alphabet[SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS.TOTAL_VALUE - 1];

  // Cell key of total of Total Values
  const totalInvestedCapitalKey = `${investedCapitalColLegend}${totalRowNumber}`;
  // Cell key of total of Total Values
  const totalTotalValueKey = `${totalValueColLegend}${totalRowNumber}`;

  return {
    allInvestmentsExpression,
    totalInvestedCapitalKey,
    totalTotalValueKey,
  };
};

const customParser = (params: CustomParserParams) => {
  const {
    columns,

    rowConfig,
  } = params;

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];

  const { allInvestmentsExpression, totalInvestedCapitalKey, totalTotalValueKey } = getInvestmentsExpressions({
    alphabet,
    rowConfig,
  });

  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      cells = {
        ...cells,
        ...cellParser({
          allInvestmentsExpression,
          alphabet,
          colIndex,
          column,
          row,
          rowIndex,
          totalInvestedCapitalKey,
          totalTotalValueKey,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
