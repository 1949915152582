import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    accordionSummary: {
      // Avoid light grey background on focus
      '&.Mui-focused.Mui-focusVisible': {
        backgroundColor: 'transparent',
      },
      // Accordion Summary Content update width to overflow Text with ellipsis
      '& .MuiAccordionSummary-content': {
        justifyContent: 'space-between',
        width: `calc(100% - ${theme.spacing(4)}px)`,
      },
    },
    dateSelectorContainer: {
      marginLeft: theme.spacing(2.5),
    },
    accordionDetails: {
      display: 'block',
      paddingBottom: theme.spacing(4),
    },
  })
);

export default useStyles;
