/**
 * @name FirmsTypes ActionTypes
 * @memberof module:common/actions
 * @type {ReduxAction}
 * @return {Object} Firms Actions Types
 */
export const SET_FUND_INFO = 'SET_FUND_INFO';
export const SET_FUND_LIST = 'SET_FUND_LIST';
export const SET_CURRENT_MP_BY_FUND = 'SET_CURRENT_MP_BY_FUND';
export const SET_FUND_SUMMARY_INFO = 'SET_FUND_SUMMARY_INFO';
