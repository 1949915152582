import React, { FC, useContext, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty, isEqual } from 'lodash';
import { globalAction } from 'common/actions';
import { ERROR_403 } from 'common/config/api';
import { firmSummaryUrl } from 'common/config/urls';
import { DEFAULT_CURRENCY } from 'common/constants/cap-table';
import {
  COMPANIES_FORBIDDEN_COMPANY_SUMMARY,
  COMPANIES_GET_COMPANY_SUMMARY_ACCESS,
  COMPANIES_NO_INVESTMENTS,
  COMPANIES_SUMMARY_TITLE,
  COMPANIES_TITLE,
} from 'common/constants/companies';
import { CAP_TABLE_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { useFormat } from 'common/hooks';
import { useStore } from 'common/store';
import { UseFormatValues } from 'common/types/format';
import { LayoutContextValues, UseStoreValues } from 'common/types/store';
import { CustomScalarSpreadsheet, MessageBox } from 'components';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import { LayoutContext } from 'context';
import { NewCompanyDialog } from 'layouts/Main/components';
import { useCompanySummarySheet } from 'pages/Portfolio/hooks';
import { useGetAllocationStatusBreadcrumb } from 'services/hooks/allocation';
import { useGetCompanySummaryByMD } from 'services/hooks/companies';
import { getArrayValue, getBooleanValue, getObjectValue, getStringValue } from 'utillities';
import { CompanySummaryProps } from './types';

const CompanySummary: FC<CompanySummaryProps> = props => {
  const { selectedMeasurementDate } = props;

  const [storeValue, dispatch] = useStore() as unknown as UseStoreValues;
  const { companyInfo, firmInfo, otherPermissions } = storeValue;

  const { setPageBreadcrumbs, showPageForm, togglePageForm } = useContext(
    LayoutContext
  ) as unknown as LayoutContextValues;

  const {
    data: companySummary,
    isFetching,
    isLoading,
  } = useGetCompanySummaryByMD({ company: companyInfo, measurementDate: selectedMeasurementDate });

  // If companySummary status is 403
  const forbiddenAccess = useMemo(() => isEqual(companySummary?.status?.toString(), ERROR_403), [companySummary]);

  const [allocationStatusBreadcrumb] = useGetAllocationStatusBreadcrumb();

  const companySummaryBreadcrumbs = useMemo(() => {
    if (firmInfo && companyInfo) {
      const { name, slug } = getObjectValue(firmInfo);
      const { name: companyName } = getObjectValue(companyInfo);

      return name && slug && companyName
        ? [
          {
            title: name,
            url: firmSummaryUrl(slug),
          },
          {
            title: COMPANIES_TITLE,
          },
          {
            title: companyName,
          },
          {
            title: COMPANIES_SUMMARY_TITLE,
          },
          allocationStatusBreadcrumb,
        ]
        : [];
    }

    return [];
  }, [companyInfo, firmInfo, allocationStatusBreadcrumb]);

  const firmSlug = getStringValue(firmInfo?.slug);
  const companySlug = getStringValue(companyInfo?.slug);

  const queryParams = selectedMeasurementDate?.slug ? `?date=${selectedMeasurementDate.slug}` : '';

  const { spreadsheet: companySummarySheet } = useCompanySummarySheet({
    companySlug,
    firmSlug,
    funds: companySummary?.funds,
    queryParams,
  });

  const spreadsheets = useMemo(
    () => (companySummarySheet?.yLength ? [companySummarySheet] : []),
    [companySummarySheet]
  );

  const { onChange, workbook } = useWorkbook(spreadsheets);

  const [format, formatDispatch] = useFormat({
    page: CAP_TABLE_CURRENCY_PAGE,
    sourceCurrency: companyInfo?.captable_currency ?? DEFAULT_CURRENCY,
    units: REGULAR_UNIT,
  }) as UseFormatValues;

  // Create companySummarySheet props
  const companySummarySheetProps = useMemo(() => {
    if (companySummarySheet)
      return {
        ...companySummarySheet,
        data: getArrayValue(companySummarySheet?.data),
        format,
        formatDispatch,
        onChange,
        sheet: companySummarySheet,
        workbook,
      } as unknown as typeof ScalarSpreadsheet;

    return null;
  }, [companySummarySheet, format, formatDispatch, onChange, workbook]);

  // Set Company Summary page breadcrumbs
  useEffect(() => {
    setPageBreadcrumbs?.(companySummaryBreadcrumbs);

    return () => setPageBreadcrumbs?.(null);
  }, [companySummaryBreadcrumbs, setPageBreadcrumbs]);

  // Show Loading Progress
  useEffect(() => {
    dispatch?.(globalAction.showLoadingProgress(isLoading));
  }, [dispatch, isLoading]);

  // Loading Grid Skeleton while fetching data
  if (isLoading || !otherPermissions)
    return (
      <Grid>
        <GridSkeleton maxColumns={11} maxRows={4} />
      </Grid>
    );

  // Forbidden access to the Company Summary page
  if (forbiddenAccess)
    return (
      <MessageBox
        fullWidth={false}
        tagline={COMPANIES_GET_COMPANY_SUMMARY_ACCESS}
        title={COMPANIES_FORBIDDEN_COMPANY_SUMMARY}
      />
    );

  // No Investments on this Company
  if (isEmpty(companySummary?.funds)) return <MessageBox title={COMPANIES_NO_INVESTMENTS} fullWidth={false} />;

  return (
    <>
      <Grid>
        {companySummarySheetProps && companySummary && !isEmpty(companySummary) && (
          <CustomScalarSpreadsheet {...companySummarySheetProps} isLoading={isFetching} />
        )}
      </Grid>

      {/* Edit Company Info Modal */}
      <NewCompanyDialog open={getBooleanValue(showPageForm)} onClose={togglePageForm} editMode />
    </>
  );
};

export default CompanySummary;
