import { isNull, orderBy } from 'lodash';

/*
processDocumentsInformation is a function that allows you to organize the documents
with their respective folders, in the corresponding measurement date.
*/

const getFolderTree = folders => {
  const map = {};
  const folderList = [];

  folders.forEach((folder, idx) => {
    map[folder.id] = idx;
    // eslint-disable-next-line no-param-reassign
    folder.subFolders = [];
  });

  folders.forEach(folder => {
    if (folder.parent_folder) {
      folders[map[folder.parent_folder.id]].subFolders.push(folder);
    } else {
      folderList.push(folder);
    }
  });
  return folderList;
};

const getFoldersWithFiles = (folders, files) => {
  files.forEach(fileItem => {
    const { file, folder: folderId } = fileItem;
    const newFileObj = {
      ...fileItem,
      uploaded_date: file.uploaded_date,
    };
    if (!isNull(folderId)) {
      const folderIdx = folders.findIndex(folder => folder.id === folderId);
      if (folderIdx !== -1) {
        folders[folderIdx].files.push(newFileObj);
      }
    }
  });
  return folders;
};

const addInformationToFolders = (id, measurementDate, folders) =>
  folders.map(folder => ({
    ...folder,
    documentId: id,
    measurementDate,
    files: [],
  }));

const getFoldersData = foundDocuments => {
  const { id, measurement_date: measurementDate, folders, files } = foundDocuments;

  const foldersWithInfo = addInformationToFolders(id, measurementDate, folders || []);
  const foldersWithFiles = getFoldersWithFiles(foldersWithInfo, files || []);
  const folderTree = getFolderTree(foldersWithFiles);
  return orderBy(folderTree, ['name'], ['asc']);
};

const getFilesWithoutFolders = files => {
  const dataFiles = files.filter(itemFile => isNull(itemFile.folder));
  return dataFiles.map(fileItem => ({
    ...fileItem,
    uploaded_date: fileItem.file.uploaded_date,
  }));
};

export const processDocumentsInformation = documentsObject => ({
  documentId: documentsObject.id,
  filesWithoutFolders: getFilesWithoutFolders(documentsObject.files || []),
  folders: getFoldersData(documentsObject),
  measurementDate: documentsObject.measurement_date,
});
