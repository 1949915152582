import React from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { CreateNewVersionButtonProps } from './types';

const CreateNewVersionButton = (params: CreateNewVersionButtonProps) => {
  const { onClickHandler } = params;
  return (
    <Button color="primary" endIcon={<AddIcon />} onClick={onClickHandler} variant="outlined">
      Create new valuation version
    </Button>
  );
};

export default CreateNewVersionButton;
