import { COMPANY, EDIT, FUND } from 'common/constants/user';

const hasEditPermissionsOnAllFundsAndComps = ({ companyList, fundList, userPermissionsToSave }) => {
  if (companyList && fundList) {
    const companyIds = companyList.map(company => company.company_id);
    const fundIds = fundList.map(fund => fund.id);

    const companyEditPermissions
      = userPermissionsToSave?.permissions?.filter(
        permission =>
          permission.feature_object.object_type === COMPANY
          && permission.access_type === EDIT
          && !permission.remove_permission
      ) || [];
    const companyEditPermissionsIds = companyEditPermissions.map(permission => permission.feature_object.object_id);

    const fundEditPermissions
      = userPermissionsToSave?.permissions?.filter(
        permission =>
          permission.feature_object.object_type === FUND
          && permission.access_type === EDIT
          && !permission.remove_permission
      ) || [];
    const fundEditPermissionsIds = fundEditPermissions.map(permission => permission.feature_object.object_id);

    return (
      companyIds.every(companyId => companyEditPermissionsIds.includes(companyId))
      && fundIds.every(fundId => fundEditPermissionsIds.includes(fundId))
    );
  }

  return false;
};

export default hasEditPermissionsOnAllFundsAndComps;
