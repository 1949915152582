import * as constants from 'common/constants/fund-ownership';
import { rowConfig } from '../data';

const getReferences = ({ columns, funds, securities }) => {
  let dictionary = {};
  let fundNames = {};
  let securityNames = {};

  const rows = rowConfig({});

  columns.forEach(c => {
    const fund = funds?.find(fund => fund.id === c.fund);
    const security = securities?.find(security => security.id === c.security);
    fundNames = {
      ...fundNames,
      [c.fund]: fund ? fund.name : '',
    };
    securityNames = {
      ...securityNames,
      [c.security]: security ? security.name : '',
    };
  });

  rows.forEach(row => {
    switch (row.alias) {
      case constants.FUND_ALIAS:
        dictionary = {
          ...dictionary,
          [row.alias]: fundNames,
        };
        break;
      case constants.SECURITY_ALIAS:
        dictionary = {
          ...dictionary,
          [row.alias]: securityNames,
        };
        break;
      default:
    }
  });

  return dictionary;
};

export default getReferences;
