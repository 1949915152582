import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles, withStyles } from '@material-ui/styles';
import { isEmpty, isObject } from 'lodash';
import PropTypes from 'prop-types';
import Select, { components, createFilter } from 'react-select';
import theme from 'theme';
import { toString } from 'utillities';

// JavaScript key codes
const TAB_KEY = 9;
const ENTER_KEY = 13;
const ESCAPE_KEY = 27;

const ITEM_HEIGHT = 28;

const containerStyle = styles => ({
  ...styles,
  height: ITEM_HEIGHT,
  marginTop: '2px',
  fontWeight: 400,
  padding: '2px 2px 4px',
});

const selectStyles = {
  control: styles => ({
    ...styles,
    height: '31px',
    minHeight: ITEM_HEIGHT,
    width: '100%',
    border: 'none',
    borderColor: 'none',
    borderRadius: 'none',
    backgroundColor: 'white',
    position: 'absolute',
    left: 0,
    top: 0,
    boxShadow: 'none',
  }),
  container: () => {
    // This is intentional just to remove the default styles on container
  },
  valueContainer: styles => containerStyle(styles),
  singleValue: styles => ({
    ...styles,
    color: theme.palette.primary[600],
    marginRight: 0,
    right: '0px',
    top: '47%',
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  indicatorsContainer: styles => ({
    ...containerStyle(styles),
    padding: '0px',
  }),
  indicatorContainer: styles => ({
    ...styles,
    padding: '4px',
  }),
  option: styles => ({
    ...styles,
    padding: '5px 12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  menu: styles => ({
    ...styles,
    left: 0,
    borderRadius: 'none',
    marginTop: '-25px',
    fontWeight: 400,
  }),
  menuList: styles => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
    bottom: '100%',
  }),
  menuPortal: styles => ({
    ...styles,
    fontSize: 14,
    margin: '-2rem 0 0',
    height: ITEM_HEIGHT,
    color: theme.palette.primary[600],
    zIndex: 9999,
  }),
};

const CustomTooltip = withStyles(() => ({
  tooltip: {
    margin: '0px',
  },
}))(Tooltip);

const Option = props => {
  const { children, containerWidth, data, ...rest } = props;
  const { label = '' } = data;

  const [optionWidth, setOptionWidth] = useState(0);

  const optionRef = useRef();

  const shouldDisplayTooltip = useMemo(() => optionWidth > containerWidth, [optionWidth, containerWidth]);

  useLayoutEffect(() => {
    if (optionRef?.current) setOptionWidth(optionRef.current.offsetWidth);
  }, [optionRef]);

  return (
    <components.Option {...{ ...rest, data }}>
      <Grid component="span" ref={optionRef}>
        {shouldDisplayTooltip ? (
          <CustomTooltip placement="bottom-start" title={label}>
            <Grid component="span">{children}</Grid>
          </CustomTooltip>
        ) : (
          <Grid component="span">{children}</Grid>
        )}
      </Grid>
    </components.Option>
  );
};

Option.propTypes = {
  children: PropTypes.node,
  containerWidth: PropTypes.number,
  data: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

const DropdownIndicator = props => {
  const useStyles = makeStyles(() => ({
    dropdownIndicator: {
      padding: '0.16em !important',
      marginLeft: '0.26em',
    },
  }));

  const classes = useStyles();
  return (
    <components.DropdownIndicator {...props} className={classes.dropdownIndicator}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};

const CustomSelect = ({
  value,
  options,
  onEmptyValue,
  onChange,
  openMenuOnFocus,
  setLabelAsValue,
  disabled,
  onCommit,
  onRevert,
  enumerated,
  autoFocus,
  menuPlacement,
  usePortal,
  menuPosition,
}) => {
  const [selectedValue, setSelectedValue] = useState();
  const [containerWidth, setContainerWidth] = useState(0);
  const [state, setState] = useState({ e: null });

  const containerRef = useRef();

  const optionList = useMemo(() => {
    const tmpList = [];

    if (options) {
      options.forEach((option, index) => {
        let parsedOption = option;

        if (!isObject(option)) {
          parsedOption = {
            value: toString(index),
            label: toString(option),
          };
        }

        if (parsedOption) {
          tmpList.push(parsedOption);
        }
      });
    }

    return tmpList;
  }, [options]);

  const handleChange = opt => {
    const selected = enumerated ? opt.label : opt.value;

    if (!opt && onRevert) {
      onRevert();
      return;
    }

    const { e } = state;
    onCommit?.(selected, e);

    onChange?.(selected);
  };

  const handleKeyDown = e => {
    // Abort edition
    if (e.which === ESCAPE_KEY && onRevert) {
      onRevert();
    }

    if ([ENTER_KEY, TAB_KEY].includes(e.which)) {
      e.persist();
      setState({ e });
    } else {
      setState({ e: null });
    }
  };

  useLayoutEffect(() => {
    if (containerRef?.current) setContainerWidth(containerRef.current.clientWidth);
  }, [containerRef]);

  useEffect(() => {
    /*
     * These validations are intended for the Select component when we use it directly
     * bypassing the SelectValueViewer. In these cases, we need to set the option label
     * manually here (currently, only in the Fund Ownership and Allocation pages).
     */
    let itemLabel = '';
    let itemValue = null;
    const itemSelected = optionList.find(opt => toString(opt.value) === toString(value));
    // Fund Ownership: In the Shares Ledger modal, for the date field used in the Purchased section.
    if (setLabelAsValue) {
      itemLabel = value || onEmptyValue;
      itemValue = value || null;
    } else {
      itemLabel = itemSelected?.label || onEmptyValue;
      itemValue = itemSelected?.value || itemSelected?.id || null;
    }
    setSelectedValue({ value: itemValue, label: itemLabel });
  }, [value, setLabelAsValue, optionList, onEmptyValue]);

  return (
    <Grid ref={containerRef}>
      <Select
        autoFocus={autoFocus}
        closeMenuOnSelect
        components={{
          Option: props => Option({ ...props, containerWidth }),
          DropdownIndicator,
        }}
        filterOption={createFilter({ matchFrom: 'start' })}
        instanceId="scalar"
        isDisabled={disabled || isEmpty(optionList)}
        menuPlacement={menuPlacement}
        menuPosition={menuPosition}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        openMenuOnClick
        openMenuOnFocus={openMenuOnFocus}
        options={optionList}
        placeholder={onEmptyValue}
        styles={selectStyles}
        value={selectedValue}
        {...(usePortal && { menuPortalTarget: document.body })}
      />
    </Grid>
  );
};

CustomSelect.defaultProps = {
  openMenuOnFocus: true,
  enumerated: false,
  autoFocus: true,
  menuPosition: 'absolute',
};

CustomSelect.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  onEmptyValue: PropTypes.string,
  openMenuOnFocus: PropTypes.bool,
  setLabelAsValue: PropTypes.bool,
  onChange: PropTypes.func,
  onRevert: PropTypes.func,
  onCommit: PropTypes.func,
  enumerated: PropTypes.bool,
  autoFocus: PropTypes.bool,
  menuPlacement: PropTypes.string,
  usePortal: PropTypes.bool,
  menuPosition: PropTypes.string,
};

export default CustomSelect;
