import React, { ChangeEvent, FC, MouseEvent, useState } from 'react';
import { Box } from '@material-ui/core';
import { LoadingSection } from 'components';
import { GPCDateFilter, gpcMetrics, LineBarChart, MetricDropdown, METRICS_OPTIONS } from 'dashboard409a/components';
import { compactFormatterNumber, RATES_OF_CHANGE_OPTIONS } from 'dashboard409a/utilities';
import { IRatesOfChangeProps } from './types';

const RatesOfChange: FC<IRatesOfChangeProps> = ({ chartData, isLoading, adimensional }) => {
  const [currentValue, setCurrentValue] = useState<string>(METRICS_OPTIONS[0]);
  const [dateValue, setDateValue] = useState<string>('month_3');

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setCurrentValue(event.target.value as string);
  };

  const handleDateChange = (event: MouseEvent<{ value: unknown }>) => {
    setDateValue(event.currentTarget.value as string);
  };

  function adimensionalCallback(value: number | string) {
    if (typeof value === 'number') {
      return `${Number(value).toFixed(2)}%`;
    }

    return value;
  }

  function dimensionalCallback(value: number | string) {
    if (typeof value === 'number') {
      return `${compactFormatterNumber.format(value)}${gpcMetrics[currentValue].symbol}`;
    }

    return value;
  }

  const options = {
    ...RATES_OF_CHANGE_OPTIONS,
    scales: {
      y: {
        type: 'linear' as const,
        stacked: false,
        grid: { display: true },
        ticks: {
          callback: adimensional ? adimensionalCallback : dimensionalCallback,
          autoSkip: true,
          maxTicksLimit: 9,
        },
      },
    },
  };

  const data = {
    datasets: chartData?.map((item: any) => ({
      type: 'line',
      label: item.ticker_symbol,
      data: item[dateValue].map((info: any) => ({ x: info.dt, y: info[gpcMetrics[currentValue].default] })),
      backgroundColor: item.color,
      borderColor: item.color,
      pointRadius: 0,
    })),
  };

  return isLoading || !chartData ? (
    <LoadingSection />
  ) : (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 1,
          m: 1,
        }}>
        <MetricDropdown value={currentValue} title="Metric" handleChange={handleChange} />
        <GPCDateFilter value={dateValue} handleChange={handleDateChange} />
      </Box>
      <LineBarChart data={data} options={options} />
    </>
  );
};

export default RatesOfChange;
