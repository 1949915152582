import React, { FC, useEffect, useMemo } from 'react';
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { TAB_QUERY_PARAM } from 'pages/ValuationsAllocation/common/constants/valuations';
import { scrollToTop, setQueryString } from 'utillities';
import useStyles from './styles';
import { InvalidTablesMessageProps } from './types';

const TITLE = 'To proceed, please correct the highlighted errors on:';

const InvalidTablesMessage: FC<InvalidTablesMessageProps> = props => {
  const { invalidTables = [] } = props;

  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const parsedQuery = useMemo(() => queryString.parse(location.search), [location.search]);
  const { [TAB_QUERY_PARAM]: approachId } = parsedQuery;

  useEffect(() => {
    scrollToTop();
  }, [invalidTables]);

  const redirectToTab = (id: string) => {
    if (approachId !== id) {
      setQueryString({
        history,
        paramName: TAB_QUERY_PARAM,
        paramValue: id,
        pathname: location.pathname,
        searchParams: queryString.stringify(parsedQuery),
        shouldPushPath: true,
      });
    }
  };

  return (
    <Grid className={classes.messageContainer}>
      <Grid container alignItems="center" justifyContent="flex-start">
        <Grid item className={classes.iconContainer}>
          <NotificationImportantIcon className={classes.icon} />
        </Grid>

        <Grid item>
          <Typography className={classes.title} variant="h4" component="h3">
            {TITLE}
          </Typography>
        </Grid>
      </Grid>

      {Boolean(invalidTables?.length) && (
        <Grid className={classes.listContainer}>
          <List>
            {invalidTables.map(approach => {
              const { approachType, id, name } = approach;
              return (
                <ListItem key={`${name}-${id}`} className={classes.listItem}>
                  <Box className={classes.listItemBox} onClick={() => redirectToTab(id)}>
                    <ListItemIcon className={classes.listIconContainer}>
                      <ErrorOutlineIcon className={classes.listIcon} />
                    </ListItemIcon>

                    <ListItemText primary={name} secondary={approachType ? `(${approachType})` : null} />
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default InvalidTablesMessage;
