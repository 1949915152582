import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  termsAgreed: {
    fontSize: '0.875rem',
    cursor: 'default',
  },
  termsAgreedLinks: {
    marginLeft: '0.2rem',
  },
});

const TermsAgreed = ({ termsAgreed, setTermsAgreed }) => {
  const classes = useStyles();

  const handleOnChangeTerms = () => setTermsAgreed(!termsAgreed);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={termsAgreed}
          onChange={handleOnChangeTerms}
          name="terms_agreed"
          color="primary"
          disableRipple
        />
      }
      label={
        <p className={classes.termsAgreed}>
          By checking this box, you agree to Scalar's
          <a
            className={classes.termsAgreedLinks}
            href={'https://scalar.io/terms-of-service'}
            target="_blank"
            rel="noopener noreferrer">
            Terms of Service
          </a>{' '}
          and confirm you have read Scalar’s
          <a
            className={classes.termsAgreedLinks}
            href={'https://scalar.io/privacy-policy'}
            target="_blank"
            rel="noopener noreferrer">
            Privacy Policy
          </a>
          .
        </p>
      }
    />
  );
};

TermsAgreed.propTypes = {
  termsAgreed: PropTypes.bool,
  setTermsAgreed: PropTypes.func.isRequired,
};

export default TermsAgreed;
