/**
 * @name ExcelExport Config
 * @memberof module:common/config
 * @type {Config}
 * @return {Object} Configurations of API
 */

// #region common
import API from '../config';

const base = `${API.BASE_DEV_HOST}/api/`;

export const beginReportProcess = reportUUID => `${base}investor-app/excel-report/${reportUUID}`;
export const checkReportFile = reportUUID => `${base}investor-app/check-excel-report/${reportUUID}`;
