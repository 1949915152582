import React from 'react';
import { Button, FormGroup, IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import { DeleteOutline as DeleteIcon } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { ADD_DOCUMENT_REQUEST_BUTTON } from 'common/constants/documents';
import CustomTextField from 'components/CustomTextField';
import theme from 'theme';

const useStyles = makeStyles({
  deleteButton: {
    color: `${theme.palette.gray[500]}`,
    marginLeft: 0,
    opacity: 0.3,
    transition: 'ease opacity 1s',
    '&:hover': {
      opacity: 1,
    },
  },
  addButton: {
    fontSize: '1rem',
    textTransform: 'none',
    fontWeight: 500,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: '0',
    '&& .MuiButton-label': {
      justifyContent: 'start',
    },
  },
});

const RequestFileName = ({ setFormValues, fields, setFields }) => {
  const classes = useStyles();

  const addTextField = () => {
    setFields([...fields, { fileName: '', error: false }]);
  };

  const setErrorInField = (index, error) => {
    const newFields = [...fields];
    newFields[index].error = error;
    setFields(newFields);
  };

  const validateFields = (newFields, index) => {
    setFields(newFields);
    if (newFields[index]?.fileName !== '') {
      setFormValues(currentFormValues => ({
        ...currentFormValues,
        // each field needs to be just the string instead of fileName: string
        requested_files: newFields.map(field => field.fileName),
      }));
    } else {
      setErrorInField(index, true);
    }
  };

  const handleChange = (e, index) => {
    const newFields = [...fields];
    newFields[index].fileName = e.target.value;
    newFields[index].error = false;
    validateFields(newFields, index);
  };

  const handleDelete = (__e, index) => {
    // remove e from fields
    const newFields = [...fields];
    newFields.splice(index, 1);
    validateFields(newFields, index - 1);
  };

  return (
    <FormGroup style={{ marginBottom: '2rem' }}>
      {fields.map((field, index) => (
        <div key={field.id} style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'space-between' }}>
          <CustomTextField
            id="file-requested-name"
            type="text"
            name="fileName"
            label="Name of document requested"
            value={field.fileName}
            onChange={e => handleChange(e, index)}
            onBlur={e => handleChange(e, index)}
            required
            variant="standard"
            error={field.error}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" className={classes.deleteButton}>
                  <IconButton onClick={e => handleDelete(e, index)}>
                    <DeleteIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      ))}
      <Button className={classes.addButton} startIcon={<AddIcon />} color="primary" onClick={addTextField}>
        {ADD_DOCUMENT_REQUEST_BUTTON}
      </Button>
    </FormGroup>
  );
};

RequestFileName.propTypes = {
  setFormValues: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  setFields: PropTypes.func,
};

export default RequestFileName;
