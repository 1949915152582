import React, { FC, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { AnsweredAt } from 'pages/Funds/components/ProcessManagement/AnsweredAt';
import theme from 'theme';
import { TextAnswersProps } from './types';

const TextAnswers: FC<TextAnswersProps> = props => {
  const { answers } = props;

  const [viewMore, setViewMore] = useState(false);

  const handleViewMore = () => {
    setViewMore(!viewMore);
  };

  return (
    <Box marginTop={2}>
      {answers?.map(answer => (
        <div key={answer.id}>
          <Typography style={{ fontWeight: 400, fontSize: '1rem', color: theme.palette.black }}>
            {!viewMore ? null : answer.text_answer}
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={1}>
            <Button
              onClick={handleViewMore}
              color="primary"
              size="small"
              endIcon={viewMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              style={{ fontSize: '0.75rem' }}>
              {viewMore ? 'View less' : 'View more'}
            </Button>
            <AnsweredAt date={answer.updated_at} />
          </Box>
        </div>
      ))}
    </Box>
  );
};

export default TextAnswers;
