import React, { FC } from 'react';
import { Divider, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { largeCurrencyFormat } from 'common/formats/formats';
import { Currency } from 'common/types/format';
import { DEFAULT_CURRENCY, REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import { formatNumbers } from 'utillities';
import useStyles from './styles';
import { ValueCardProps } from './types';

export const VALUE_CARD_DEFAULT_CURRENCY = {
  code: DEFAULT_CURRENCY,
  units: REGULAR_UNIT,
} as Currency;

const ValueCard: FC<ValueCardProps> = props => {
  const { currency = VALUE_CARD_DEFAULT_CURRENCY, isLoading = false, title, value } = props;

  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: formatNumbers is not typed
  const formattedValue = formatNumbers({
    format: largeCurrencyFormat,
    value,
    currency,
  });

  return (
    <Paper className={classes.card} data-testid={`value-card-${title}`}>
      <Typography className={classes.title} component="h2">
        {title}
      </Typography>

      <Divider className={classes.divider} />

      {isLoading ? (
        <Skeleton className={classes.skeleton} variant="text" />
      ) : (
        <Typography className={classes.value} paragraph>
          {formattedValue}
        </Typography>
      )}
    </Paper>
  );
};

export default ValueCard;
