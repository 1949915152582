export const COMPANY_NAME_TITLE = 'Company';
export const COMPANY_NAME_TOOLTIP = null;

export const CSE_SHARES_TITLE = 'CSE Shares';
export const CSE_SHARES_TOOLTIP = 'Common Stock Equivalent number of shares';

export const CONCLUDED_SHARE_VALUE_TITLE = 'Concluded Share Value';
export const CONCLUDED_SHARE_VALUE_TOOLTIP = 'Value of one share based on the concluded valuation';

export const FULLY_DILUTED_PERCENT_TITLE = 'Fully Diluted Ownership %';
export const FULLY_DILUTED_PERCENT_TOOLTIP = 'Fully diluted ownership percentage';

export const GROSS_IRR_TITLE = 'Gross IRR';
export const GROSS_IRR_TOOLTIP
  = 'Gross Internal Rate of Return generated from a loan before consideration of management fees and expenses';

export const INVESTMENT_AMOUNT_TITLE = 'Invested Capital';

export const LOAN_VALUE_TITLE = 'Loan Value';
export const INVESTMENT_AMOUNT_TOOLTIP = 'Fund investment in the security, cost basis.';

export const INVESTMENT_DATE_TITLE = 'Investment Date';
export const INVESTMENT_DATE_TOOLTIP = 'Date ownership in the security was acquired';

export const PROCESS_MANAGEMENT_TITLE = 'Process Management';
export const PROCESS_MANAGEMENT_TOOLTIP = 'Task status of each company';

export const RECENT_INVESTMENT_DATE_TITLE = 'Most Recent Investment Date';
export const RECENT_INVESTMENT_DATE_TOOLTIP = 'Most recent date ownership in the security was acquired';

export const REALIZED_VALUE_TITLE = 'Realized Value';
export const REALIZED_VALUE_TOOLTIP = 'Total value realized as of date; such as distributions or shares sold';

export const SHARES_OWNED_TITLE = 'Shares';
export const SHARES_OWNED_TOOLTIP = 'Number of shares owned';

export const TOTAL_VALUE_TITLE = 'Total Value';
export const TOTAL_VALUE_TOOLTIP = 'Total value of investment';

export const TYPE_OF_SECURITY_TITLE = 'Type of Security';
export const TYPE_OF_SECURITY_TOOLTIP = 'Name of the security from the cap table';

export const UNREALIZED_VALUE_TITLE = 'Unrealized Equity';
export const UNREALIZED_VALUE_TOOLTIP = 'Total unrealized value of owned shares based on valuation';

export const X_FACTOR_TITLE = 'MOIC'; // Multiple of Invested Capital or X Factor
export const X_FACTOR_TOOLTIP = 'Multiple of Invested Capital or X Factor';

export const UNREALIZED_DEBT_TITLE = 'Unrealized Debt';

export const VALUE_WITH_NOT_PUBLISHED_ALLOCATION_TOOLTIP
  = 'This value isn’t shown until the valuation allocation for this company is published.';
