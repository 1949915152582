import React, { useContext } from 'react';
import SelectValueViewer from 'components/SelectValueViewer';
import FutureExitContext from 'pages/ValuationsAllocation/approaches/FutureExit/utils/FutureExitContext';

const MetricValueViewer = (props: any) => {
  const { metricOptions } = useContext(FutureExitContext);
  return <SelectValueViewer options={metricOptions} {...props} useLabel />;
};

export default MetricValueViewer;
