import React, { useCallback, useEffect, useState } from 'react';
import { Button, FormControl, FormGroup, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import {
  ADD_MORE_COLLABORATORS_BUTTON,
  ADD_NEW_FOLDER,
  DOCUMENTS_NEEDED_TITLE,
  REQUEST_NEW_DOCUMENT_DESCRIPTION,
  REQUEST_NEW_DOCUMENT_DIALOG_TITLE,
  REQUEST_NEW_DOCUMENT_SUMMARY,
  SECURE_UPLOAD_LINK_NOTICE,
  SELECT_FOLDER_TITLE,
  WHO_CAN_PROVIDE_TITLE,
} from 'common/constants/documents';
import * as messages from 'common/constants/messages/validations';
import { useStore } from 'common/store';
import { FormDialog } from 'components/Dialogs';
import AddFolderDialog from 'pages/Documents/components/AddFolderDialog/AddFolderDialog';
import { useDocuments, useResponse } from 'services/hooks';
import Subtitle from './RequestDocumentDialogSubtitle';
import RequestFileName from './RequestFileName';
import RequestFolder from './RequestFolder';
import RequestUserInfo from './RequestUserInfo';

const generateEmptyContact = () => ({
  id: uuid(),
  assigned_name: '',
  assigned_last_name: '',
  assigned_email: '',
});

const initialFormValues = {
  folder: 0,
  requested_files: [],
  contacts: [generateEmptyContact()],
};

const RequestDocumentDialog = ({ open, onClose, currentDocuments, companyMeasurementDateId }) => {
  const checkIfAllFilesHaveNames = useCallback(
    files => !isEmpty(files) && files.every(file => file.fileName !== ''),
    []
  );
  const [fields, setFields] = useState([]);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [formState, setFormState] = useState({});
  const [openFolderDialog, setOpenFolderDialog] = useState(false);
  const [newFolder, setNewFolder] = useState();
  const [{ companyInfo }] = useStore();
  const { sendDocumentRequest } = useDocuments();
  const { errorNotification } = useResponse();

  const onCloseFolderDialog = () => {
    setOpenFolderDialog(false);
    setNewFolder(null);
  };

  const getNewFolder = folder => {
    setNewFolder(folder);
  };

  const addRequestedUser = () => {
    setFormValues({
      ...formValues,
      contacts: [...formValues.contacts, generateEmptyContact()],
    });
  };

  const closeAndClean = useCallback(() => {
    setFormValues({ ...initialFormValues, contacts: [generateEmptyContact()] });
    setFields([]);
    onClose();
  }, [onClose]);

  const checkFormState = useCallback(() => {
    const { requested_files: requestedFiles, contacts } = formValues;
    const filesHaveNames = checkIfAllFilesHaveNames(requestedFiles);
    const contactsAreCompleted = contacts.every(
      contact => contact.assigned_name !== '' && contact.assigned_email !== ''
    );
    const formIsValid = filesHaveNames && contactsAreCompleted;
    setFormState({
      isValid: formIsValid,
      values: formValues,
      errors: {},
    });
  }, [formValues, checkIfAllFilesHaveNames]);

  const preparedContacts = useCallback(() => {
    const { contacts } = formValues;
    const contactsToSend = contacts.map(contact => {
      const { assigned_name: name, assigned_last_name: lastName, assigned_email: email } = contact;
      return {
        email,
        first_name: name,
        last_name: lastName,
      };
    });
    return contactsToSend;
  }, [formValues]);

  const handleSave = () => {
    // Verify if the form is valid
    if (formState.isValid && checkIfAllFilesHaveNames(fields)) {
      // Prepare the data to be sent
      const documentRequest = {
        folder: formValues.folder === 0 || isUndefined(formValues.folder) ? null : formValues.folder,
        requested_files: formValues.requested_files,
      };

      const numberOfFiles = formValues.requested_files.length;
      const data = {
        contacts: preparedContacts(),
        document_request: documentRequest,
        company_measurement_date: companyMeasurementDateId,
        category: 1,
        summary: REQUEST_NEW_DOCUMENT_SUMMARY,
        description: REQUEST_NEW_DOCUMENT_DESCRIPTION(numberOfFiles),
        is_done: false,
        assigned_user: null,
      };
      sendDocumentRequest(data);
      closeAndClean();
    } else {
      errorNotification(messages.DOCUMENT_REQUEST_SENT_ERROR);
    }
  };

  const inputsProps = {
    formValues,
    setFormValues,
  };

  const requestFilesNamesProps = {
    ...inputsProps,
    fields,
    setFields,
  };

  useEffect(() => {
    checkFormState();
  }, [checkFormState, formValues]);

  useEffect(() => {
    checkIfAllFilesHaveNames(fields);
  }, [checkIfAllFilesHaveNames, fields]);

  return (
    <FormDialog
      open={open}
      onClose={closeAndClean}
      title={REQUEST_NEW_DOCUMENT_DIALOG_TITLE(companyInfo.name)}
      customButtonLabel="Send"
      isValid={formState.isValid && checkIfAllFilesHaveNames(fields)}
      onSave={handleSave}>
      <>
        <FormControl style={{ width: '100%' }}>
          <Subtitle text={WHO_CAN_PROVIDE_TITLE} />
          {formValues.contacts.map(contact => (
            <RequestUserInfo key={contact.id} contact={contact} {...inputsProps} />
          ))}
          <Button
            style={{
              fontSize: '1rem',
              textTransform: 'none',
              fontWeight: 500,
              marginBottom: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
            startIcon={<AddIcon />}
            color="primary"
            onClick={addRequestedUser}>
            {ADD_MORE_COLLABORATORS_BUTTON}
          </Button>
          <Subtitle text={DOCUMENTS_NEEDED_TITLE} />
          <FormGroup style={{ marginBottom: '2rem' }}>
            <RequestFileName {...requestFilesNamesProps} />
          </FormGroup>
          <Subtitle text={SELECT_FOLDER_TITLE} />
          <RequestFolder
            {...inputsProps}
            currentDocuments={currentDocuments}
            setOpenFolderDialog={setOpenFolderDialog}
            newFolder={newFolder}
            companyName={companyInfo.name}
          />
        </FormControl>
        <Typography variant="body2" style={{ marginTop: '1rem' }}>
          {SECURE_UPLOAD_LINK_NOTICE}
        </Typography>
        <AddFolderDialog
          open={openFolderDialog}
          onClose={onCloseFolderDialog}
          title={ADD_NEW_FOLDER}
          getNewFolder={getNewFolder}
        />
      </>
    </FormDialog>
  );
};

RequestDocumentDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  currentDocuments: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        file: PropTypes.shape({
          id: PropTypes.number,
        }),
        file_name: PropTypes.string,
        file_type: PropTypes.string,
        file_size: PropTypes.number,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
      })
    ),
    folders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
      })
    ),
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }),
  companyMeasurementDateId: PropTypes.number,
};

export default RequestDocumentDialog;
