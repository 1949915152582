import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%',
  },
}));

const ListHeader = ({ onInputChange, searchState, firmList, inputFilterRef, listLimit }) => {
  const classes = useStyles();

  const getFirmBoxCaption = () => {
    if (!isEmpty(firmList)) {
      return 'Select a Firm';
    }
    if (!firmList) {
      return 'Loading Firms';
    }
    return 'There is nothing here yet';
  };

  if (firmList?.length > listLimit) {
    return (
      <TextField
        inputRef={inputFilterRef}
        label="SELECT A FIRM"
        variant="standard"
        className={classes.textField}
        value={searchState}
        onChange={onInputChange}
      />
    );
  }
  return <Typography variant="h2">{getFirmBoxCaption()}</Typography>;
};

ListHeader.propTypes = {
  onInputChange: PropTypes.func,
  searchState: PropTypes.string,
  firmList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  inputFilterRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  listLimit: PropTypes.number,
};

export default ListHeader;
