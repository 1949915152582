import { isNull } from 'lodash';
import { compKeyMap } from './constants';

const checkAsOfComps = asOfValues => {
  /* This should return true if all values are available for all comps */
  if (asOfValues.length === 0) {
    return false;
  }
  const tickerSymbol = 'ticker_symbol';
  const capIqId = 'cap_iq_id';
  const keysToCheck = [...Object.keys(compKeyMap), tickerSymbol, capIqId];
  const someHasMissingValues = asOfValues.some(asOfValue => keysToCheck.some(key => isNull(asOfValue[key])));
  return !someHasMissingValues;
};

export default checkAsOfComps;
