/* eslint-disable import/no-unresolved */
/**
 * @name Allocations Hook
 * @memberof module:common/hooks
 * @type {ReactHook}
 * @return {React} Captable Hook
 */

// #region dependencies
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// #endregion
// #region common
import { useQueryClient } from '@tanstack/react-query';
import { isUndefined } from 'lodash';
import uuid from 'react-uuid';
import { isEmpty, isNull } from 'underscore';
import { allocationsAction, globalAction } from 'common/actions';
// #endregion
// #region service
import { ERROR_403 } from 'common/config/api';
import {
  ALLOCATION_STATUS_TITLE,
  ALLOCATIONS_DRAFT,
  BACKSOLVE,
  CSE,
  CURRENT_VALUE,
  FUTURE_EXIT,
  OPM,
  SPECIFIED_SHARE_VALUES,
  WATERFALL,
} from 'common/constants/allocations';
import { ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY } from 'common/constants/services/companies';
import { useStore } from 'common/store';
import { AllocationStatusBadge } from 'components';
import { LayoutContext } from 'context';
import UnsavedChanges from 'context/UnsavedChanges';
import { useAllocationValuesStore } from 'store';
import { getAllocationStatus } from 'utillities';
import { useResponse } from './useResponse';
import { AllocationService } from '..';
// #endregion

/**
 * @function
 * @name useGetAllocationInfo
 * @memberof Allocations Hook
 * @description Hook to fetch allocation info from allocations service
 * @param {INTEGER} allocationId the allocation id
 * @return {OBJECT} structure for store context.
 */
export const useGetAllocationInfo = () => {
  const [, dispatch] = useStore();
  const [allocationInfo, setAllocationInfo] = useState({});

  const { processErrorResponse, successNotification } = useResponse();

  const getAllocationInfo = useCallback(
    async (allocationId, exchangeRate) => {
      const allocationService = new AllocationService();
      if (allocationId) {
        dispatch(globalAction.showLoadingProgress(true));

        const defaultErrorMessage = 'An error occurred while loading the Allocation information';

        try {
          const allocation = await allocationService.dataById(allocationId, exchangeRate);
          if (allocation) {
            // if weighted_share_values is not defined, we need it to be.
            allocation.weighted_share_values = allocation.weighted_share_values || [];
            setAllocationInfo(allocation);
            dispatch(globalAction.showLoadingProgress(false));

            return allocation;
          }
          successNotification(defaultErrorMessage);
        } catch (error) {
          if (error.status === 403) {
            dispatch(allocationsAction.setAllocationInfo(ERROR_403));
          } else {
            processErrorResponse({ error, defaultErrorMessage });
          }
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
      }
    },
    [dispatch, processErrorResponse, successNotification]
  );

  useEffect(() => {
    dispatch(
      allocationsAction.setAllocationInfo({
        ...allocationInfo,
      })
    );
  }, [allocationInfo, dispatch]);

  return [allocationInfo, getAllocationInfo, setAllocationInfo];
};

/**
 * @function
 * @name useUpdateAllocationInfo
 * @memberof Allocations Hook
 * @description Hook to update allocation info from allocations service
 * @param {INTEGER} dataId the allocation id
 * @param {OBJECT} allocationData allocation structure data
 * @return {OBJECT} structure for store context.
 */

export const useUpdateAllocationInfo = () => {
  const allocationService = new AllocationService();
  const [globalStore, dispatch] = useStore();
  const [updatedAllocation, setUpdatedAllocation] = useState({});

  const { processErrorResponse, successNotification } = useResponse();

  const updateAllocation = async (newAllocationId, newAllocationData) => {
    if (newAllocationId && newAllocationData) {
      dispatch(globalAction.showLoadingProgress(true));
      try {
        const data = await allocationService.updateDataById(newAllocationId, newAllocationData);

        dispatch(
          allocationsAction.setAllocationInfo({
            ...globalStore.allocationInfo,
            ...data,
          })
        );

        setUpdatedAllocation(data);
        successNotification('Allocation updated successfully');
        return data;
      } catch (error) {
        processErrorResponse({
          error,
          defaultErrorMessage: 'An error occurred while updating Allocation information',
          action: 'update allocation information',
        });
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };
  return [updatedAllocation, updateAllocation];
};

/**
 * @function
 * @name useUpdateAllocationScenarios
 * @memberof Allocations Hook
 * @description Hook to update allocation scenarios info from allocations service
 * @param {INTEGER} dataId the allocation id
 * @param {OBJECT} allocationScenariosData allocation scnenarios structure data
 * @return {OBJECT} structure for store context.
 */
export const useUpdateAllocationScenarios = () => {
  const allocationService = new AllocationService();
  const [globalStore, dispatch] = useStore();
  const [updatedAllocationScenarios, setUpdatedAllocationScenarios] = useState({});

  const { processErrorResponse, successNotification } = useResponse();

  const updateAllocationScenarios = async (allocationId, allocationScenariosData) => {
    if (allocationId && allocationScenariosData) {
      dispatch(globalAction.showLoadingProgress(true));

      try {
        const allocationScenarios = await allocationService.updateScenariosById(allocationId, allocationScenariosData);
        // create a tmp state
        const tmpAllocationInfo = {
          ...globalStore.allocationInfo,
          allocation_scenarios: allocationScenarios,
        };
        // update global store
        dispatch(allocationsAction.setAllocationInfo(tmpAllocationInfo));
        // update local state
        setUpdatedAllocationScenarios(tmpAllocationInfo);
        // show notification
        successNotification('Allocation Scenarios updated successfully');
      } catch (error) {
        processErrorResponse({
          error,
          defaultErrorMessage: 'An error occurred while updating Allocation Scenarios',
          action: 'update allocation scenarios',
        });
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };

  return [updatedAllocationScenarios, updateAllocationScenarios];
};

export const useUpdateAllocationAndScenariosInfo = () => {
  const [updatedAllocationAndScenarios, setUpdatedAllocationAndScenarios] = useState({});

  const [globalStore, dispatch] = useStore();
  const { setAction } = useContext(UnsavedChanges);

  const { successNotification, processErrorResponse } = useResponse();

  // React Query Client
  const queryClient = useQueryClient();

  const updateAllocationAndScenarios = useCallback(
    async (allocationId, newAllocationData, newScenariosData, exchangeRate, setter, showLoading = true) => {
      if (allocationId && showLoading) {
        const allocationService = new AllocationService();
        dispatch(globalAction.showLoadingProgress(true));
        let defaultErrorMessage = 'An error occurred while updating Allocation information';

        try {
          let tmpAllocation = globalStore.allocationInfo;
          let tmpScenarios = globalStore?.allocationInfo?.allocation_scenarios ?? [];

          if (!isEmpty(newAllocationData)) {
            tmpAllocation = await allocationService.updateDataById(allocationId, newAllocationData);
            successNotification('Allocation information updated successfully');
          }

          if (!isEmpty(newScenariosData)) {
            defaultErrorMessage = 'An error occurred while updating Allocation Scenarios';
            tmpScenarios = await allocationService.updateScenariosById(allocationId, newScenariosData, exchangeRate);
            successNotification('Allocation Scenarios updated successfully');
          }

          const updatedData = {
            ...tmpAllocation,
            allocation_scenarios: tmpScenarios,
            is_draft: true,
          };
          setter(tmpScenarios.map(sc => ({ ...sc, asRef: uuid() })) || []);
          setAction(false);
          setUpdatedAllocationAndScenarios(updatedData);
          dispatch(allocationsAction.setAllocationInfo(updatedData));
        } catch (error) {
          const servicePath = error.response.req.url.split('/').pop();
          const action = servicePath === 'scenarios' ? 'update Allocation Scenarios' : 'update Allocation information';
          processErrorResponse({
            error,
            defaultErrorMessage,
            action,
          });
        } finally {
          // Invalidate the Allocation Values query to refetch the data with the new values
          queryClient.invalidateQueries({ queryKey: [ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY] });

          dispatch(globalAction.showLoadingProgress(false));
        }
      }
    },
    [dispatch, globalStore.allocationInfo, processErrorResponse, queryClient, setAction, successNotification]
  );

  return [updatedAllocationAndScenarios, updateAllocationAndScenarios];
};

let latestRequestId;

async function handleApiRequest({
  doAPICall,
  scenarioMethod,
  capTable,
  equityValue,
  exitDate,
  discountRate,
  scenarioType,
  maturity,
  volatility,
  presentValues,
  totalAggregateValue,
  allocationService,
  tmpScenarios,
  scenarioIndex,
  setUpdatedScenarioValues,
  errorNotification,
  defaultErrorMessage,
  processErrorResponse,
  tryToUpdate,
  updateWeightedShareValues,
  dispatch,
}) {
  let data;
  try {
    if (doAPICall) {
      const params = {
        scenarioMethod,
        captableId: capTable.id,
        equityValue: Number(equityValue),
        exitDate,
        discountRate,
        scenarioType,
        maturity,
        volatility,
        presentValues,
        totalAggregateValue,
      };

      const requestId = new Date().getTime();
      latestRequestId = requestId;
      data = await allocationService.postScenarioValues({ ...params });
      if (data && requestId === latestRequestId) {
        // Update scenario with the new scenario values
        // eslint-disable-next-line no-param-reassign
        tmpScenarios[scenarioIndex].scenario_values = data;

        // update feedback
        setUpdatedScenarioValues(data);
      } else if (!data && requestId === latestRequestId) {
        errorNotification(defaultErrorMessage);
      }
    }
  } catch (error) {
    processErrorResponse({
      error,
      defaultErrorMessage: error.response.body?.breakpoint_comparation
        ? error.response.body.breakpoint_comparation
        : defaultErrorMessage,
      action: 'get scenario values',
    });
  } finally {
    if (tryToUpdate && data) {
      // if things went well update my new source of truth
      // instead, we want to add the cells we want to change
      // setColumns(tmpScenarios);
      updateWeightedShareValues(tmpScenarios, scenarioIndex);
    }
    dispatch(globalAction.showLoadingProgress(false));
  }
}

export const useUpdateScenarioValues = () => {
  const [, dispatch] = useStore();
  const [updatedScenarioValues, setUpdatedScenarioValues] = useState({});

  const { processErrorResponse, errorNotification } = useResponse();

  const isPositiveNumber = amount => Number(amount) && Number(amount) > 0;

  const updateScenarioValues = useCallback(
    async ({
      scenarioMethod,
      capTable,
      equityValue,
      exitDate,
      discountRate,
      scenarioType,
      maturity,
      volatility,
      presentValues,
      totalAggregateValue,
      scenarioIndex,
      scenarios,
      tryToUpdate,
      setColumns,
      updateWeightedShareValues,
    }) => {
      const allocationService = new AllocationService();
      let doAPICall = true;

      const isEquityValueValid = isPositiveNumber(equityValue);
      const isVolatilityValid = isPositiveNumber(volatility);
      const isMaturityValid = isPositiveNumber(maturity);
      const isOPMValid = isEquityValueValid && isVolatilityValid && isMaturityValid;
      const isCaptableValid = capTable?.id;

      const cancelAPICall = condition => {
        if (condition) {
          doAPICall = false;
        }
      };

      const switchOnScenarioType = () => {
        switch (Number(scenarioType)) {
          case FUTURE_EXIT:
            cancelAPICall(
              !isCaptableValid
                || isNull(scenarios)
                || !isEquityValueValid
                || isNull(exitDate)
                || isNull(discountRate)
                || isNull(scenarioIndex)
            );
            break;
          case CURRENT_VALUE:
            cancelAPICall(!isCaptableValid || !isEquityValueValid || isNull(scenarioIndex));
            break;
          case BACKSOLVE:
            cancelAPICall(!isOPMValid);
            break;
          default:
            cancelAPICall(!isOPMValid);
            break;
        }
      };

      switch (Number(scenarioMethod)) {
        case WATERFALL:
        case CSE:
          cancelAPICall(isUndefined(scenarioType));
          switchOnScenarioType();
          break;
        case OPM:
          cancelAPICall(!isOPMValid);
          break;
        case SPECIFIED_SHARE_VALUES:
          cancelAPICall(false);
          break;
        default:
          cancelAPICall(true);
      }

      if (!doAPICall || !tryToUpdate || !isCaptableValid) {
        return;
      }

      dispatch(globalAction.showLoadingProgress(true));

      const defaultErrorMessage = 'An error occurred while loading the Scenario values';

      // create copy of the updated scenarios
      const tmpScenarios = [...scenarios];
      await handleApiRequest({
        doAPICall,
        scenarioMethod,
        capTable,
        equityValue,
        exitDate,
        discountRate,
        scenarioType,
        maturity,
        volatility,
        presentValues,
        totalAggregateValue,
        allocationService,
        tmpScenarios,
        scenarioIndex,
        setUpdatedScenarioValues,
        errorNotification,
        defaultErrorMessage,
        processErrorResponse,
        tryToUpdate,
        updateWeightedShareValues,
        dispatch,
      });
    },
    [dispatch, errorNotification, processErrorResponse]
  );

  return { updatedScenarioValues, updateScenarioValues };
};

export const useUpdateScenarioEquityValue = () => {
  const service = new AllocationService();
  const [globalStore, dispatch] = useStore();

  const { processErrorResponse, errorNotification } = useResponse();

  const updateScenarioEquityValue = async ({ scenarioIndex, scenarios, ...data }) => {
    if (data) {
      dispatch(globalAction.showLoadingProgress(true));

      const defaultErrorMessage = 'An error ocurred while calculating the Implied Equity Value';

      try {
        const captableId = data.cap_table;

        const response = await service.getScenarioEquityValue(captableId, data);

        if (response) {
          const { equity_value } = response;
          const tmpScenarios = [...scenarios];

          if (tmpScenarios[scenarioIndex].equity_value !== equity_value) {
            tmpScenarios[scenarioIndex].equity_value = equity_value;

            // update allocation info in the global store
            dispatch(
              allocationsAction.setAllocationInfo({
                ...globalStore.allocationInfo,
                allocation_scenarios: tmpScenarios,
              })
            );
          }
        } else {
          errorNotification(defaultErrorMessage);
        }
      } catch (error) {
        processErrorResponse({
          error,
          defaultErrorMessage,
          action: 'update the Scenario Equity value',
        });
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };

  return { updateScenarioEquityValue };
};

export const usePublishAllocation = () => {
  const [globalStore, dispatch] = useStore();

  const { processErrorResponse, successNotification } = useResponse();

  const publishAllocation = useCallback(
    async allocationId => {
      if (allocationId) {
        const allocationService = new AllocationService();
        dispatch(globalAction.showLoadingProgress(true));

        try {
          const response = await allocationService.publishById(allocationId);

          if (response) {
            dispatch(
              allocationsAction.setAllocationInfo({
                ...globalStore.allocationInfo,
                is_published: true,
              })
            );

            successNotification('Allocation was successfully published');
          }
        } catch (error) {
          const defaultErrorMessage = 'An error occurred while trying to publish the Allocation';
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'publish the Allocation',
          });
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
      }
    },
    [dispatch, globalStore, processErrorResponse, successNotification]
  );
  return [publishAllocation];
};

export const useMarkAllocationsAsFinal = () => {
  const [, dispatch] = useStore();

  const { processErrorResponse, successNotification } = useResponse();

  const markAsFinal = async ids => {
    if (ids) {
      dispatch(globalAction.showLoadingProgress(true));

      try {
        const allocationService = new AllocationService();
        await allocationService.markAsFinal(ids.join(','));

        const multiple = ids.length > 1;
        successNotification(`Allocation${multiple ? 's' : ''} updated successfully`);
      } catch (error) {
        const defaultErrorMessage = 'An error occurred while trying to update Allocations';
        processErrorResponse({
          error,
          defaultErrorMessage,
          action: 'update Allocations',
        });
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };
  return [markAsFinal];
};

export const useCreateVersion = () => {
  const [, dispatch] = useStore();
  const [newVersionData, setNewVersionData] = useState();
  const { setAction } = useContext(UnsavedChanges);

  const { processErrorResponse, successNotification } = useResponse();

  const createVersion = useCallback(
    async data => {
      if (data) {
        dispatch(globalAction.showLoadingProgress(true));

        try {
          const allocationService = new AllocationService();
          const response = await allocationService.createVersion(data);
          setNewVersionData(response);
          successNotification('Allocation version created successfully');
          setAction(false);
        } catch (error) {
          const defaultErrorMessage = 'An error occurred while trying to create an Allocation version';
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'create Allocation version',
          });
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
      }
    },
    [dispatch, processErrorResponse, setAction, successNotification]
  );

  return [newVersionData, createVersion];
};

export const useGetRiskFreeRate = () => {
  const { processErrorResponse } = useResponse();

  const getRiskFreeRateByDate = useCallback(
    async (maturity, queryDate) => {
      if (maturity && queryDate) {
        try {
          const allocationService = new AllocationService();
          const riskFreeRate = await allocationService.getRiskFreeRateByDate(maturity, queryDate);
          return riskFreeRate;
        } catch (error) {
          const defaultErrorMessage = 'An error occurred while trying to get risk free rate';
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'get risk free rate',
          });
        }
      }
    },
    [processErrorResponse]
  );

  return [getRiskFreeRateByDate];
};

export const useGetAllocationStatusBreadcrumb = () => {
  const allocationId = useAllocationValuesStore(state => state.currentAllocationId);
  const { companyExchangeRate } = useContext(LayoutContext);
  const [allocationStatusBreadcrumb, setAllocationStatusBreadcrumb] = useState({ title: '', icon: <></> });
  const [allocationInfo, getAllocationInfo] = useGetAllocationInfo();

  useEffect(() => {
    if (allocationId && companyExchangeRate) {
      getAllocationInfo(allocationId, companyExchangeRate);
    }
  }, [allocationId, companyExchangeRate, getAllocationInfo]);

  const allocationStatus = useMemo(() => {
    if (!isEmpty(allocationInfo)) {
      const { is_published: isPublished, is_final: isFinal } = allocationInfo;
      return getAllocationStatus({ isFinal, isPublished });
    }
    return ALLOCATIONS_DRAFT;
  }, [allocationInfo]);

  useEffect(() => {
    setAllocationStatusBreadcrumb({
      title: ALLOCATION_STATUS_TITLE,
      icon: <AllocationStatusBadge status={allocationStatus} />,
    });
  }, [allocationStatus]);

  return [allocationStatusBreadcrumb];
};
