import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ScalarDatasheet from 'components/ScalarDatasheet';
import Row from './Row';

const SingleColumn = ({ className, data, isLegend, ...rest }) => {
  // Disable sheet selection if all cells are read-only
  const disableSheetSelection = useMemo(() => data.flat().every(cell => cell.readOnly), [data]);

  const rowVariableLegend = useCallback(props => <Row {...props} isLegend={isLegend} />, [isLegend]);

  const singleColumnProps = useMemo(
    () => ({
      className,
      data,
      rowRenderer: rowVariableLegend,
      selected: disableSheetSelection ? null : undefined,
    }),
    [className, data, disableSheetSelection, rowVariableLegend]
  );

  return <ScalarDatasheet {...singleColumnProps} {...rest} />;
};

SingleColumn.defaultProps = {
  className: '',
  data: [],
  isLegend: false,
};

SingleColumn.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  isLegend: PropTypes.bool,
};

export default SingleColumn;
