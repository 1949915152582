import { DOCUMENTS } from 'common/endpoints';
import BaseServiceRetryWrapper from './baseRetry';

class FilesService extends BaseServiceRetryWrapper {
  getPreSignedUrl = (mdId, fileType) => this.get(DOCUMENTS.getPreSignedUrl(mdId, fileType));

  getCompanyDocuments = companyId => this.get(DOCUMENTS.getCompanyDocuments(companyId));

  createFileRepresentation = (documentsId, data) => this.post(DOCUMENTS.createFileRepresentation(documentsId), data);

  downloadDocument = (fileId, versionId) => this.get(DOCUMENTS.downloadDocument(fileId, versionId));

  deleteDocument = (documentsId, fileId) => this.delete(DOCUMENTS.updateOrDeleteDocument(documentsId, fileId));

  updateDocument = (documentsId, fileId, data) => this.put(DOCUMENTS.updateOrDeleteDocument(documentsId, fileId), data);

  getFeaturesObjects = mdId => this.get(DOCUMENTS.getFeaturesObjects(mdId));

  addDocumentReference = (mdId, fileId, data) => this.post(DOCUMENTS.addDocumentReference(mdId, fileId), data);

  addDocumentReferencesObject = (mdId, data) => this.post(DOCUMENTS.addDocumentReferencesObject(mdId), data);

  getReferencesByMeasurementDateId = mdId => this.get(DOCUMENTS.getReferencesByMeasurementDateId(mdId));

  createSubFolder = (docsId, data) => this.post(DOCUMENTS.createSubFolder(docsId), data);

  updateSubFolder = (docsId, folderId, data) => this.put(DOCUMENTS.updateSubFolder(docsId, folderId), data);

  deleteFolder = (docsId, folderId) => this.delete(DOCUMENTS.updateSubFolder(docsId, folderId));

  addFilesToFolder = (documentsId, data) => this.post(DOCUMENTS.addFilesToFolder(documentsId), data);

  validateDocumentRequestToken = (requestId, token) =>
    this.get(DOCUMENTS.validateDocumentRequestToken(requestId, token));

  generatePostSignedUrlViaHMACToken = (requestId, token, mdId, type) =>
    this.get(DOCUMENTS.generatePostSignedUrlViaHMACToken(requestId, token, mdId, type));

  updateRequestedFileUploadStatus = (requestId, token, data) =>
    this.post(DOCUMENTS.updateRequestedFileUploadStatus(requestId, token), data);

  sendDocumentRequest = data => this.post(DOCUMENTS.sendDocumentRequest, data);

  updateTaskStatusFromPortal = (requestId, token, taskId, data) =>
    this.patch(DOCUMENTS.updateTaskStatusFromPortal(requestId, token, taskId), data);

  hasDocuments = (companyId, measurementDateId) => this.get(DOCUMENTS.hasDocuments(companyId, measurementDateId));
}

export default FilesService;
