import React from 'react';
import { Box, Button } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

const AddSubQuestionButton = ({ formValues, setFormValues, isEdit, subQuestion }) => {
  const addSubQuestion = () => {
    setFormValues({
      ...formValues,
      children_questions: [...formValues.children_questions, { name: '', description: '' }],
    });
  };

  if (!isEdit && !subQuestion.id && isEmpty(formValues.children_questions)) {
    return (
      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={addSubQuestion}>
          Add Sub-question
        </Button>
      </Box>
    );
  }
  if (isEdit && !subQuestion.id && isEmpty(formValues.children_questions)) {
    return null;
  }
  return null;
};

AddSubQuestionButton.propTypes = {
  setFormValues: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    children_questions: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })).isRequired,
  }).isRequired,
  isEdit: PropTypes.bool.isRequired,
  subQuestion: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default AddSubQuestionButton;
