import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GridDate as GridDateBase } from 'components/Grid';
import { CompGroupsContext } from 'context';

const GridDate = ({ cell }) => {
  const { onCellsChanged } = useContext(CompGroupsContext);

  const handleOnChange = date => {
    onCellsChanged([{ cell, value: date }]);
  };

  const { value, readOnly, disabled } = cell;

  return <GridDateBase value={value} readOnly={readOnly} disabled={disabled} onCellsChanged={handleOnChange} />;
};

GridDate.propTypes = {
  cell: PropTypes.object,
};

export default GridDate;
