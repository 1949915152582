import {
  VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_NAME,
  VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_NAME_PLURAL,
  VALUATIONS_SUMMARY_SPREADSHEET_TABLE,
  VALUATIONS_SUMMARY_SPREADSHEET_TITLE,
} from 'pages/Funds/common/constants/valuationsSummary/valuationsSummary';

export const VALUATIONS_SUMMARY_SPREADSHEET_TABLE_TERMS = Object.freeze({
  columnName: VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_NAME,
  pluralColumnName: VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_NAME_PLURAL,
  tableName: VALUATIONS_SUMMARY_SPREADSHEET_TITLE,
  tableSlug: VALUATIONS_SUMMARY_SPREADSHEET_TABLE,
});
