import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { IValuationChartData } from 'dashboard409a/components';
import { IValuationState } from 'dashboard409a/states/features/valuation';
import { RootStateType } from 'dashboard409a/states/store';
import { ValuationOpinionSummaryData } from './Components/ValuationOpinionSummaryData';
import { ValuationTrendCards } from './Components/ValuationTrendCards';

const Valuation = () => {
  const [showValMethods, setShowValMethods] = useState<boolean>(true);
  const [showTrendsDetails, setShowTrendsDetails] = useState<boolean>(true);
  const { allValuationScalarOpinion } = useSelector<RootStateType, IValuationState>(state => state.valuation);

  const displayTable = (id: string): void => {
    if (id === 'valuationOpinion') {
      setShowValMethods(!showValMethods);
    } else {
      setShowTrendsDetails(!showTrendsDetails);
    }
  };

  const componentShouldRender = (data: IValuationChartData[] | null): boolean => {
    let status;
    if (data && data.length > 1) {
      status = true;
    } else {
      status = false;
    }

    return status;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        {componentShouldRender(allValuationScalarOpinion) ? (
          <>
            <ValuationOpinionSummaryData displayTable={displayTable} showValMethods={showValMethods} shareSpace />
            <ValuationTrendCards showTrendsDetails={showTrendsDetails} displayTable={displayTable} />
          </>
        ) : (
          <ValuationOpinionSummaryData showValMethods={showValMethods} shareSpace={false} displayTable={displayTable} />
        )}
      </Grid>
    </Box>
  );
};

export default Valuation;
