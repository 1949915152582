/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AllocationCapTableModel } from './AllocationCapTableModel';
import type { ValuationApproachWeightModel } from './ValuationApproachWeightModel';

export type AllocationScenarioValuationModel = {
  readonly id?: number;
  cap_table?: AllocationCapTableModel;
  cap_table_id: number;
  allocation?: number;
  name: string;
  scenario_type: number;
  equity_value: string;
  readonly allocations_scenarios_weights?: Array<ValuationApproachWeightModel>;
  approach_uuid?: string | null;
  is_deleted?: boolean | null;
  readonly deleted_at?: string | null;
  weighting_probability: string;
  exit_date?: string | null;
  discount_rate?: string | null;
  dilution_percentage?: string | null;
  maturity?: string | null;
  volatility?: string | null;
  exit_equity_value?: string | null;
  total_aggregate_value?: string | null;
  order?: number | null;
  scenario_values?: any;
  scenario_method?: AllocationScenarioValuationModel.scenario_method | null;
  backsolve_ledger?: any;
  financials_metric?: number | null;
  financials_metric_value?: string | null;
  gpc_multiple?: number | null;
  gpc_multiple_value?: string | null;
  less_debt?: string | null;
  plus_cash?: string | null;
  backsolve_valuation?: number | null;
  future_exit?: number | null;
  specified_share_values?: number | null;
};

export namespace AllocationScenarioValuationModel {
  export enum scenario_method {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }
}
