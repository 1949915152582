import React from 'react';
import { Switch } from '@material-ui/core';
import PropTypes from 'prop-types';

const GridSwitch = ({ cell, state, setState }) => {
  const handleChange = event => {
    const tmpState = { ...state };
    tmpState[cell.key].value = event.target.checked;
    setState(tmpState);
  };

  return (
    <Switch
      color="primary"
      checked={Boolean(cell.value)}
      onChange={event => {
        handleChange(event);
      }}
    />
  );
};

GridSwitch.propTypes = {
  cell: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
};

export default React.memo(GridSwitch);
