/* eslint-disable import/prefer-default-export */
import React from 'react';
import { largeCurrencyFormat, sharesFormat } from 'common/formats/formats';
import { GridDateEditor, GridDateValueViewer } from 'components/FeaturedSpreadsheet/components';
import { LedgerGridDate } from 'components/LedgerTable/components';
import InvestmentDateSelect from '../../../InvestmentDateSelect';
import { AMOUNT_ALIAS, COST_BASIS_ALIAS, PURCHASE_DATE_ALIAS, SHARES_ALIAS } from '../../constants';

export const colConfig = (
  investmentDates,
  modeledAfterConvertibleNote = false,
  isCustomSecurity = false,
  isWarrantOrOption = false
) => {
  let dateColConfigProps = {
    dataEditor: props => <GridDateEditor {...props} />,
    gridDateComponent: LedgerGridDate,
    valueViewer: props => <GridDateValueViewer {...props} slashFormatted />,
  };

  if (Array.isArray(investmentDates) && investmentDates.length > 1) {
    dateColConfigProps = {
      component: <InvestmentDateSelect investmentDates={investmentDates} />,
      disableEvents: true,
      forceComponent: true,
    };
  }

  const dateColConfig = {
    isRequired: true,
    sourceTable: 'purchased',
    value: 'Date',
    width: '175px',
    ...dateColConfigProps,
    alias: PURCHASE_DATE_ALIAS,
  };

  const amountColConfig = {
    alias: modeledAfterConvertibleNote ? AMOUNT_ALIAS : COST_BASIS_ALIAS,
    format: largeCurrencyFormat,
    gridType: 'number',
    isRequired: true,
    value: modeledAfterConvertibleNote ? 'Purchased Amount' : 'Cost Basis',
    width: '175px',
    ...(modeledAfterConvertibleNote || isWarrantOrOption ? null : { greaterThan: 0 }),
    minValue: isWarrantOrOption ? 0 : undefined,
  };

  if (modeledAfterConvertibleNote) {
    return [dateColConfig, amountColConfig];
  }

  const sharesColConfig = {
    alias: SHARES_ALIAS,
    format: sharesFormat,
    gridType: 'number',
    isRequired: !isCustomSecurity,
    value: 'Shares',
    width: '175px',
    ...(!isCustomSecurity ? { minValue: 1 } : null),
  };

  return [dateColConfig, sharesColConfig, amountColConfig];
};
export default colConfig;
