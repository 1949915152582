import { isEmpty } from 'lodash';
import moment from 'moment';
import { NOT_APPLICABLE } from 'common/constants/general';
import { ISO_DATE_FORMAT } from 'utillities';
import { isLastQuarter } from 'utillities/dateUtils';
import { toString } from 'utillities/typesConverter';

const formatDate = date => moment(date).format(ISO_DATE_FORMAT);
const getNTMRevenueGrowth = (ltmRevenue = 0, ntmRevenue = 0) => toString(ntmRevenue / ltmRevenue - 1, NOT_APPLICABLE);
const getLTMRevenueGrowth = (ltmRevenue = 0, pastYearLTMRevenue = 0) =>
  toString(ltmRevenue / pastYearLTMRevenue - 1, NOT_APPLICABLE);
const getEbitdaMargin = (ebitda = 0, revenue = 0) => toString(ebitda / revenue, NOT_APPLICABLE);
const getGrossMargin = (revenue = 0, gross_profit = 0) => toString(gross_profit / revenue, NOT_APPLICABLE);

export const getCompanyPerfMetricsAsOfDate = ({ asOfDate, financialsPeriods, companyName, fiscalYearEnd }) => {
  const selectedFinancialsPeriod
    = financialsPeriods.find(period => formatDate(period.statement_date) === formatDate(asOfDate)) || {};
  const pastYearFinancialsPeriod
    = financialsPeriods.find(
      period => formatDate(period.statement_date) === formatDate(moment(asOfDate).subtract(1, 'year'))
    ) || {};
  const nextYearFinancialsPeriod
    = financialsPeriods.find(
      period => formatDate(period.statement_date) === formatDate(moment(asOfDate).add(1, 'year'))
    ) || {};

  // if the date is not in financialsPeriods, but it is the last quarter, the last day of the fiscal year is searched
  if (isLastQuarter(asOfDate, fiscalYearEnd) && isEmpty(selectedFinancialsPeriod))
    return getCompanyPerfMetricsAsOfDate({ asOfDate: fiscalYearEnd, financialsPeriods, companyName });

  const tmpCustomCompanyPerfMetrics = {
    name: companyName,
    ltm_revenue: selectedFinancialsPeriod?.income_statement?.total_revenue || 0,
    ltm_revenue_growth: isEmpty(pastYearFinancialsPeriod)
      ? 0
      : getLTMRevenueGrowth(
        selectedFinancialsPeriod?.income_statement?.total_revenue,
        pastYearFinancialsPeriod?.income_statement?.total_revenue
      ),
    ltm_ebitda:
      selectedFinancialsPeriod?.income_statement?.adjusted_ebitda
      || selectedFinancialsPeriod?.income_statement?.ebitda
      || 0,
    gross_margin: isEmpty(selectedFinancialsPeriod)
      ? 0
      : getGrossMargin(
        selectedFinancialsPeriod?.income_statement?.total_revenue,
        selectedFinancialsPeriod?.income_statement?.gross_profit
      ),
    ebitda_margin: isEmpty(selectedFinancialsPeriod)
      ? 0
      : getEbitdaMargin(
        selectedFinancialsPeriod?.income_statement?.ebitda,
        selectedFinancialsPeriod?.income_statement?.total_revenue
      ),
    ntm_revenue: nextYearFinancialsPeriod?.income_statement?.total_revenue || 0,
    ntm_revenue_growth: isEmpty(nextYearFinancialsPeriod)
      ? 0
      : getNTMRevenueGrowth(
        selectedFinancialsPeriod?.income_statement?.total_revenue,
        nextYearFinancialsPeriod?.income_statement?.total_revenue
      ),
    ntm_ebitda: nextYearFinancialsPeriod?.income_statement?.ebitda || 0,
  };

  return { tmpCustomCompanyPerfMetrics, rowMustBeReadOnly: !isEmpty(selectedFinancialsPeriod) };
};
