import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    iconButton: {
      edge: 'start',
      color: 'inherit',
    },
    arrowIcon: {
      fontSize: 60,
    },
    typographyTitle: {
      fontSize: 32,
      textTransform: 'uppercase',
      marginBottom: 30,
      marginTop: 20,
    },
    typographySubtitle: {
      fontSize: 20,
      marginBottom: 10,
    },
    typographyDescription: {
      fontSize: 20,
    },
  })
);

export default useStyles;
