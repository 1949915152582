import { currencyFormat, oneDecimalPercentFormatValidateFloatTrueWithNegativeValues } from 'common/formats/formats';

export const TABLE_TITLE = 'Valuation Trends';

// Titles
export const TABLE_SECOND_TITLE = 'HISTORICAL';
export const VT_TRENDS_DATE_TITLE = '';
export const VT_MARKET_VALUE_INVESTED_CAPITAL_TITLE = 'Market Value of Invested Capital';
export const VT_DEBT_TITLE = 'Debt';
export const VT_EQUITY_VALUE_TITLE = 'Equity Value';
export const VT_EQUITY_ALLOCATION_RESULTS_TITLE = 'Equity Allocation Results';
export const VT_COMMON_STOCK_VALUE_MARKETABLE_BASIS_TITLE = 'Common Stock Value, Marketable Basis';
export const VT_DISCOUNT_FOR_LACK_OF_MARKETABILITY_TITLE = 'Discount for Lack of Marketability';
export const VT_COMMON_STOCK_VALUE_NON_MARKETABLE_BASIS_TITLE = 'Common Stock Value, Non Marketable Basis';

// Alias
export const VT_TRENDS_DATE_ALIAS = 'trends_date';
export const VT_MARKET_VALUE_INVESTED_CAPITAL_ALIAS = 'weighted_market_enterprise_value';
export const VT_DEBT_ALIAS = 'less_debt';
export const VT_EQUITY_VALUE_ALIAS = 'weighted_equity_value';
export const VT_EQUITY_ALLOCATION_RESULTS_ALIAS = 'equity_allocation_results';
export const VT_COMMON_STOCK_VALUE_MARKETABLE_BASIS_ALIAS = 'marketable_value_per_common_share';
export const VT_DISCOUNT_FOR_LACK_OF_MARKETABILITY_ALIAS = 'discount_for_lack_of_marketability';
export const VT_COMMON_STOCK_VALUE_NON_MARKETABLE_BASIS_ALIAS = 'non_marketable_value_per_common_share';

const baseCurrencyFormat = currencyFormat({ fractionDigits: 0 });

const commonStockValueCurrencyFormat = currencyFormat({ fractionDigits: 2 });

const rowConfig = () => [
  {
    className: 'table-header trends-table-header division-bottom-only',
    value: TABLE_TITLE,
    alias: 'title',
    readOnly: true,
    gridType: 'string',
    rowSpan: 2,
  },
  {
    className: 'row-label-title division-bottom-only',
    alias: VT_TRENDS_DATE_ALIAS,
    readOnly: true,
    value: '',
    gridType: 'string',
    expr: '',
  },
  {
    value: VT_MARKET_VALUE_INVESTED_CAPITAL_TITLE,
    alias: VT_MARKET_VALUE_INVESTED_CAPITAL_ALIAS,
    readOnly: true,
    gridType: 'number',
    format: baseCurrencyFormat,
    allowNegativeValue: true,
  },
  {
    value: VT_DEBT_TITLE,
    alias: VT_DEBT_ALIAS,
    readOnly: true,
    gridType: 'number',
    format: baseCurrencyFormat,
    allowNegativeValue: true,
    className: 'division-bottom-only',
  },
  {
    value: VT_EQUITY_VALUE_TITLE,
    alias: VT_EQUITY_VALUE_ALIAS,
    readOnly: true,
    gridType: 'number',
    format: baseCurrencyFormat,
    allowNegativeValue: true,
    className: 'row-label-title',
  },
  {
    value: VT_EQUITY_ALLOCATION_RESULTS_TITLE,
    alias: VT_EQUITY_ALLOCATION_RESULTS_ALIAS,
    readOnly: true,
    gridType: 'string',
  },
  {
    value: VT_COMMON_STOCK_VALUE_MARKETABLE_BASIS_TITLE,
    alias: VT_COMMON_STOCK_VALUE_MARKETABLE_BASIS_ALIAS,
    readOnly: true,
    gridType: 'number',
    format: commonStockValueCurrencyFormat,
    allowNegativeValue: true,
  },
  {
    value: VT_DISCOUNT_FOR_LACK_OF_MARKETABILITY_TITLE,
    alias: VT_DISCOUNT_FOR_LACK_OF_MARKETABILITY_ALIAS,
    readOnly: true,
    format: oneDecimalPercentFormatValidateFloatTrueWithNegativeValues,
    dbDecimalPlaces: 1,
    gridType: 'percentage',
    dbType: 'string',
    className: 'division-bottom-only',
  },
  {
    value: VT_COMMON_STOCK_VALUE_NON_MARKETABLE_BASIS_TITLE,
    alias: VT_COMMON_STOCK_VALUE_NON_MARKETABLE_BASIS_ALIAS,
    readOnly: true,
    gridType: 'number',
    format: commonStockValueCurrencyFormat,
    allowNegativeValue: true,
    className: 'row-label-title',
  },
];

export default rowConfig;
