const MARKET_ADJUSTMENT_ERROR_FEEDBACK
  = 'Changes have been made to the related GPC approach. Please review the changes and update the Market Adjustment table as needed.';

// method takes an object with an id and a reference, and an array of objects with ids and references. returns true if the id or the reference can relate to any of the objects in the array
const idOrReferenceMatches = ({ id, reference }, array) =>
  array.some(item => item.id === id || item.reference === reference);
const tableValidations = ({ cell, tableData, addFeedbackFromCell, removeFeedbackFromCell, parsedColumns }) => {
  const { approaches, approach } = tableData;
  const { valuations_approach_backsolve } = approach;
  const { adjust_for_market, public_companies_status } = valuations_approach_backsolve;

  if (cell.alias === 'valuation_approach_gpc' && adjust_for_market && public_companies_status) {
    // find the related gpc approach
    const relatedGpcApproach = approaches.find(
      approach => approach.valuations_approach_gpc?.id?.toString() === cell?.value?.toString()
    );
    if (relatedGpcApproach) {
      const { valuations_approach_gpc } = relatedGpcApproach;
      const { gpc_comparison } = valuations_approach_gpc;
      if (gpc_comparison) {
        // ensure the id of every gpc_comparison matches the public_company attribute of every public_companies_status
        const gpcComparisonIds = gpc_comparison.map(({ cap_iq_id, id }) => ({ reference: cap_iq_id, id }));
        const publicCompanyIds = public_companies_status.map(({ reference_for_backsolve, public_company }) => ({
          id: public_company,
          reference: reference_for_backsolve,
        }));
        const comparisonIdsMatch = publicCompanyIds.every(id => idOrReferenceMatches(id, gpcComparisonIds));
        const publicCompanyIdsMatch = gpcComparisonIds.every(id => idOrReferenceMatches(id, publicCompanyIds));
        const sameLength = gpcComparisonIds.length === publicCompanyIds.length;
        if (!comparisonIdsMatch || !publicCompanyIdsMatch || !sameLength) {
          addFeedbackFromCell(parsedColumns.MarketAdjustment, MARKET_ADJUSTMENT_ERROR_FEEDBACK);
        } else {
          removeFeedbackFromCell(parsedColumns.MarketAdjustment, MARKET_ADJUSTMENT_ERROR_FEEDBACK);
        }
      }
    }
  }
};

export default tableValidations;
