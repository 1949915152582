const updateApproachReference = ({
  approaches,
  column,
  lookup,
  specificApproachKey,
  updateKey,
  referenceKey,
  extraOptions = [],
}) => {
  const tmpColumn = column;
  // find the id of the approach. Can either match the panel id of the id of hte specific approach
  const options = [...approaches, ...extraOptions];
  const selectedOption = options.find(
    option => option.panelId === lookup || option[specificApproachKey]?.id === lookup || option.id === Number(lookup)
  );
  // if the approach ahs been saved, grab the id of the gpc approach,
  // else set it to null if no approach is found or the approach is not saved
  if (selectedOption?.[specificApproachKey]?.id) {
    tmpColumn[updateKey] = selectedOption.valuations_approach_gpc.id;
  } else if (selectedOption?.id === 0) {
    tmpColumn[updateKey] = null;
  } else {
    tmpColumn[updateKey] = undefined;
  }
  // always set the reference. Can be null if no approach was found
  tmpColumn[referenceKey] = selectedOption?.panelId;
};
export default updateApproachReference;
