import { enqueueSnackbar } from 'notistack';

interface SnackbarOptions {
  successMessage?: string;
  errorMessage?: string;
  showSuccessSnackbar?: boolean;
  showErrorSnackbar?: boolean;
}
const defaultSuccessMessage = 'Operation successful';
const defaultErrorMessage = 'An error occurred';

export const manageApiRequest = async <T>(
  promise: Promise<T>,
  {
    successMessage = defaultSuccessMessage,
    errorMessage = defaultErrorMessage,
    showSuccessSnackbar = true,
    showErrorSnackbar = true,
  }: SnackbarOptions = {}
): Promise<T | void> => {
  try {
    const response = await promise;
    if (showSuccessSnackbar) {
      enqueueSnackbar(successMessage, { variant: 'success' });
    }
    return response;
  } catch (error) {
    if (showErrorSnackbar) {
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  }
};
