/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState } from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import OrganizationSelector from 'pages/Login/OrganizationSelector';
import useStyles from './styles';
import { IProductSelector } from './types';

const ProductSelector: FC<IProductSelector> = props => {
  const { permissions } = props;
  const [appSelected, setAppSelected] = useState<'NONE' | 'PORTFOLIO_VALUATION' | 'DASHBOARD_409A'>('NONE');

  const classes = useStyles();

  const continueToPortfolioValuation = () => setAppSelected('PORTFOLIO_VALUATION');
  const continueToDashboard409A = () => setAppSelected('DASHBOARD_409A');

  switch (appSelected) {
    case 'PORTFOLIO_VALUATION':
      return <OrganizationSelector lowerPermissions={permissions} />;

    case 'DASHBOARD_409A':
      return <OrganizationSelector lowerPermissions={permissions} isDashboard />;

    default:
      break;
  }

  return (
    <Paper className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        Select Product
      </Typography>

      <>
        <Button variant="contained" fullWidth className={classes.button} onClick={continueToPortfolioValuation}>
          <p className={classes.buttonText}>Continue to Portfolio Valuation</p>
        </Button>

        <Typography component="p" variant="h3" className={classes.orText}>
          OR
        </Typography>

        <Button variant="contained" fullWidth className={classes.button} onClick={continueToDashboard409A}>
          <p className={classes.buttonText}>Continue to 409A Reports</p>
        </Button>
      </>
    </Paper>
  );
};

export default ProductSelector;
