import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import {
  FUND_INVESTMENTS_BY_MD_DEFAULT_ERROR_MESSAGE,
  FUND_INVESTMENTS_BY_MD_QUERY_KEY,
} from 'common/constants/services/funds';
import { FundService } from 'services';
import { throwError } from 'utillities';
import {
  GetFundInvestmentsByMDHook,
  GetFundInvestmentsByMDParams,
  GetFundInvestmentsByMDResponse,
  UseGetFundInvestmentsByMD,
} from './types';

const getFundInvestmentsByMD = async (params: GetFundInvestmentsByMDParams) => {
  const { fundId, measurementDateId } = params;

  if (isNil(fundId) && isNil(measurementDateId)) return null;

  const fundService = new FundService();

  try {
    const fundInvestments = (await fundService.getFundInvestmentByMD(
      fundId,
      measurementDateId
    )) as GetFundInvestmentsByMDResponse;

    if (!fundInvestments) return throwError(FUND_INVESTMENTS_BY_MD_DEFAULT_ERROR_MESSAGE);

    return fundInvestments;
  } catch (error) {
    return throwError(FUND_INVESTMENTS_BY_MD_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetFundInvestmentsByMD: UseGetFundInvestmentsByMD = params => {
  const { fund, measurementDate, shouldQueryAutomatically = true } = params;

  const fundId = fund?.id;
  const measurementDateId = measurementDate?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<GetFundInvestmentsByMDHook['data'], Error>({
    enabled: !isNil(fundId) && !isNil(measurementDateId) && shouldQueryAutomatically,
    queryFn: () => getFundInvestmentsByMD({ fundId, measurementDateId }),
    queryKey: [FUND_INVESTMENTS_BY_MD_QUERY_KEY, fundId, measurementDateId],
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export { useGetFundInvestmentsByMD, getFundInvestmentsByMD };
