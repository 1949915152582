import { capitalize, isEmpty, isNull } from 'lodash';
import { DROPDOWN_TYPE_LABEL, QUESTION_TYPE_CHOICES, TEXT_TYPE_LABEL } from 'common/constants/firms';
import { FirmCreateDocRequestPayload, FirmCreateQuestionRequestPayload, Option } from 'common/types/firm';
import {
  createIndividualDocument,
  createIndividualQuestion,
  createQuestions,
  createRequestedDocuments,
  retrieveUpdateDestroyCompanyQuestion,
  retrieveUpdateDestroyCompanyRequestedDocument,
  updateDestroyIndividualQuestion,
  updateIndividualDocument,
} from 'dashboard409a/services/dashboard';
import {
  DashboardCreateDocRequestPayload,
  DashboardCreateQuestionRequestPayload,
  PayloadToUpdateRequestedQuestion,
  SubQuestion,
  UseRequestDocsAndQuestionsProps,
} from './types';
import type { UpdateIndividualQuestion } from '../../../api';
import {
  useCreateFirmRequestedDocument,
  useCreateRequestedQuestion,
  useDeleteAndUpdateFirmRequestedDocument,
  useDeleteAndUpdateRequestedQuestion,
} from '../firm';

const useRequestDocsAndQuestions = (props: UseRequestDocsAndQuestionsProps) => {
  const {
    formInitialValues,
    formValues,
    isDocumentRequest,
    firmId,
    companyId,
    taskToDelete,
    setUpdateTaskList,
    setOpenDeleteDialog,
    setOpen,
    setIsEdit,
    setFormValues,
    isInitialSetup,
    primaryInformationRequestId,
  } = props;
  const [deleteRequestedQuestion, updateRequestedQuestion] = useDeleteAndUpdateRequestedQuestion();
  const [deleteFirmRequestedDocument, updateFirmRequestedDocument] = useDeleteAndUpdateFirmRequestedDocument();
  const [createRequestedQuestion] = useCreateRequestedQuestion();
  const [createFirmRequestedDocument] = useCreateFirmRequestedDocument();

  const isFirm = isNull(companyId) && !isInitialSetup;
  const taskId = isFirm ? { firm: firmId } : { company: companyId };

  const foundChoice = QUESTION_TYPE_CHOICES.find(choice => choice.label === DROPDOWN_TYPE_LABEL);
  const dropdownTypeValue = foundChoice ? foundChoice.value : undefined;

  const sanitizeOptions = (optionsParams: Option[]) => {
    if (dropdownTypeValue && formValues.question_type !== dropdownTypeValue) {
      return { options: [], new_options: [] };
    }

    const validOptions = optionsParams.filter((option: Option) => option.name !== '');
    const capitalizedOptions = validOptions.map((option: Option) => ({ ...option, name: capitalize(option.name) }));

    const optionsWithId = capitalizedOptions.filter((option: Option) => option.id);
    const optionsWithoutId = capitalizedOptions.filter((option: Option) => !option.id);

    const optionsPayload = {
      options: optionsWithId.map((option: Option) => option.id),
      new_options: optionsWithoutId.map((option: Option) => option.name),
    };

    return optionsPayload;
  };

  const sanitizeOptionsAndSubQuestions = () => {
    const sanitizedOptions = sanitizeOptions(formValues.options);
    const filteredSubQuestions = formValues.children_questions.filter(
      (subQuestion: SubQuestion) => subQuestion.name !== ''
    );
    // We only want to allow sub-question of type text, since it's the only type of sub-question that can be created in the current frontend
    const foundChoiceTypeLabel = QUESTION_TYPE_CHOICES.find(choice => choice.label === TEXT_TYPE_LABEL);
    const subQuestionType = foundChoiceTypeLabel ? foundChoiceTypeLabel.value : undefined;
    const sanitizedSubQuestion = filteredSubQuestions.map((subQuestion: SubQuestion) => ({
      ...subQuestion,
      question_type: subQuestionType,
    }));

    return {
      sanitizedOptions,
      sanitizedSubQuestion,
    };
  };

  const closeModalAndClearForm = () => {
    setOpen(false);
    setIsEdit(false);
    setFormValues(formInitialValues);
  };

  // Functions to handle requests from the Firm
  const handleUpdateTask = () => {
    let payload: PayloadToUpdateRequestedQuestion = {
      id: formValues.id,
      name: formValues.name,
      description: formValues.description,
    };

    // Question
    if (!isDocumentRequest) {
      const { sanitizedSubQuestion } = sanitizeOptionsAndSubQuestions();
      // sanitizedSubQuestion is an array but with one element (the sub-question object), so we take the first element
      // We build the childrenPayload object based on whether sanitizedSubQuestion is empty or not
      // If sanitizedSubQuestion is empty (because there is no sub-question), we set delete_children to true.
      // This means that if there is no sub-question, the child question should be deleted.
      const childrenPayload = isEmpty(sanitizedSubQuestion)
        ? { delete_children: true }
        : { children_questions: sanitizedSubQuestion };

      payload = {
        ...payload,
        ...childrenPayload,
        options: formValues.options.map((option: Option) => option.name ?? ''),
        question_type: formValues.question_type,
      };

      if (isFirm) {
        payload = {
          ...payload,
          firm: firmId,
        };
        updateRequestedQuestion(formValues.id, payload);
      } else if (isInitialSetup) {
        const questionType = QUESTION_TYPE_CHOICES.find(choice => choice.value === formValues.question_type)?.label;
        if (questionType) {
          const individualPayload: UpdateIndividualQuestion = {
            ...payload,
            company_information_request_status: formValues.company_information_request_status,
            question_type: questionType,
            children_questions: payload.children_questions
              ?.map(question => {
                const childQuestionType = QUESTION_TYPE_CHOICES.find(
                  choice => choice.value === question.question_type
                )?.label;
                if (!childQuestionType) {
                  return undefined;
                }
                return {
                  ...question,
                  question_type: childQuestionType,
                };
              })
              .filter((question): question is NonNullable<typeof question> => question !== undefined),
          };
          // potentially we can udpate the question after making a change here
          updateDestroyIndividualQuestion(formValues.id, individualPayload);
        }
      } else {
        // belongs to the dashboard409a feature
        payload = {
          ...payload,
          company: companyId,
        };
        retrieveUpdateDestroyCompanyQuestion(formValues.id, payload);
      }
    }

    if (isDocumentRequest) {
      if (isFirm) {
        payload = {
          ...payload,
          firm: firmId,
        };
        updateFirmRequestedDocument(formValues.id, payload);
      } else if (isInitialSetup) {
        payload = {
          ...payload,
          company_information_request_status: formValues.company_information_request_status,
        };
        // potentially we can update the appropriate document here after saving
        updateIndividualDocument(formValues.id, payload);
      } else {
        payload = {
          ...payload,
          company: companyId,
        };
        // belongs to the dashboard409a feature
        retrieveUpdateDestroyCompanyRequestedDocument(formValues.id, payload);
      }
    }

    closeModalAndClearForm();
    setUpdateTaskList(true);
  };

  const handleDeleteRequestedItem = () => {
    if (isDocumentRequest && taskToDelete) {
      if (isFirm) {
        deleteFirmRequestedDocument(taskToDelete.id);
      } else if (isInitialSetup) {
        // will delete the document if no payload is provided
        updateIndividualDocument(taskToDelete.id);
      } else {
        // belongs to the dashboard409a feature
        retrieveUpdateDestroyCompanyRequestedDocument(taskToDelete.id);
      }
    } else if (taskToDelete) {
      if (isFirm) {
        deleteRequestedQuestion(taskToDelete.id);
      } else if (isInitialSetup) {
        updateDestroyIndividualQuestion(taskToDelete.id);
      } else {
        // belongs to the dashboard409a feature
        retrieveUpdateDestroyCompanyQuestion(taskToDelete.id);
      }
    }
    setOpenDeleteDialog(false);
    setUpdateTaskList(true);
  };

  const addOptionsAndSubQuestionsToPayload = () => {
    const { sanitizedOptions, sanitizedSubQuestion } = sanitizeOptionsAndSubQuestions();

    return {
      ...taskId,
      questions: [
        {
          ...sanitizedOptions,
          ...taskId,
          name: formValues.name,
          description: formValues.description,
          question_type: formValues.question_type,
          children_questions: sanitizedSubQuestion.map((question: SubQuestion) => ({
            ...question,
            question_type: formValues.question_type,
          })),
        },
      ],
    };
  };

  const handleRequestNewItem = () => {
    if (isDocumentRequest) {
      if (isFirm) {
        const payload = {
          firm: firmId,
          requested_documents: [
            {
              firm: firmId,
              name: formValues.name,
              description: formValues.description,
            },
          ],
        };
        createFirmRequestedDocument(payload as FirmCreateDocRequestPayload);
      } else if (isInitialSetup) {
        createIndividualDocument({
          name: formValues.name,
          company_information_request_status: primaryInformationRequestId,
        });
      } else {
        // belongs to the dashboard409a feature
        const payload = [
          {
            name: formValues.name,
            description: formValues.description,
            company: companyId,
          },
        ];
        createRequestedDocuments(payload as DashboardCreateDocRequestPayload);
      }
      // else if it's a question
    } else if (isFirm) {
      const payload = addOptionsAndSubQuestionsToPayload();
      createRequestedQuestion(payload as FirmCreateQuestionRequestPayload);
    } else if (isInitialSetup) {
      const payload = addOptionsAndSubQuestionsToPayload().questions[0];
      const questionType = QUESTION_TYPE_CHOICES.find(choice => choice.value === payload.question_type)?.label;
      if (questionType) {
        const seasonedOptions = payload.options.map(
          optionId => formValues.options.find(option => option.id === optionId)?.name ?? ''
        );
        const newOptions = payload.new_options.filter((option): option is NonNullable<string> => option !== undefined);
        const options = [...seasonedOptions, ...newOptions];
        const updatedQuestionPayload = {
          ...payload,
          question_type: questionType,
          children_questions: payload.children_questions?.map(question => ({
            name: question.name,
            question_type: TEXT_TYPE_LABEL,
          })),
          options,
          company_information_request_status: primaryInformationRequestId,
        };
        createIndividualQuestion(updatedQuestionPayload);
      }
    } else {
      // belongs to the dashboard409a feature
      const payload = addOptionsAndSubQuestionsToPayload();
      createQuestions(payload as DashboardCreateQuestionRequestPayload);
    }
    closeModalAndClearForm();
    setUpdateTaskList(true);
  };

  const handleCancel = () => {
    closeModalAndClearForm();
  };

  return {
    handleUpdateTask,
    handleRequestNewItem,
    handleDeleteRequestedItem,
    handleCancel,
  };
};

export default useRequestDocsAndQuestions;
