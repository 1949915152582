function FROM_KEY(obj, key) {
  return obj[key];
}

export default FROM_KEY;

export const FROM_KEY_VALUE_PAIRS = (data, key) => {
  const { _data: pairList } = data;
  return pairList.find(([pairKey]) => pairKey === key)?.[1];
};
