import React, { useCallback, useContext, useEffect, useState } from 'react';
import Datasheet from '@scalartech/react-datasheet';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import pasteParser from 'components/FeaturedSpreadsheet/utilities/pasteParser';
import { ValueViewer } from 'components/Spreadsheet/components';

const ScalarDatasheet = props => {
  const [maxAllowedCells, setMaxAllowedCells] = useState({});
  const [selected, setSelected] = useState(null);
  const { data } = props;
  const { clearSelectedFlag, setClearSelected, setSelectedCell } = useContext(FeaturedSpreadsheetContext);

  useEffect(() => {
    if (clearSelectedFlag) {
      setSelected(null);
      setClearSelected(false);
    }
  }, [clearSelectedFlag, setClearSelected]);

  const handleOnPaste = useCallback(clipboard => pasteParser(clipboard, maxAllowedCells), [maxAllowedCells]);

  const attributesRenderer = useCallback(
    cell => ({
      ...(cell.key ? { id: `CELL-${cell.key}` } : {}),
      'data-parent': cell.parent || '',
    }),
    []
  );

  const handleOnSelect = useCallback(
    selection => {
      const totalColumns = data[0]?.length;
      const totalRows = data.length;
      if (selection) {
        const { start } = selection;
        const { i: indexOfRowSelected, j: indexOfColumnSelected } = start;
        setMaxAllowedCells({
          maxAllowedRows: totalRows - indexOfRowSelected,
          maxAllowedColumns: totalColumns - indexOfColumnSelected,
        });
        const isTotalColumn = data[1]?.some(({ isTotal }) => isTotal);
        if (setSelectedCell && !isTotalColumn && !isEmpty(start)) {
          const cellInfo = data?.[start.i]?.[start.j];
          const { insideLedger, allowInsideLedger } = cellInfo;
          if (!insideLedger || allowInsideLedger) {
            const selectedCellData = {
              ...selection,
              rowNumber: start.i,
              cellInfo,
            };
            setSelectedCell(selectedCellData);
          }
        }
      }
      setSelected(selection);
    },
    [data, setSelectedCell]
  );

  return (
    <Datasheet
      parsePaste={handleOnPaste}
      attributesRenderer={attributesRenderer}
      onSelect={handleOnSelect}
      selected={selected}
      {...props}
    />
  );
};

ScalarDatasheet.defaultProps = {
  valueViewer: ValueViewer,
  valueRenderer: cell => cell.value,
};

ScalarDatasheet.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onCellsChanged: PropTypes.func,
  valueRenderer: PropTypes.func,
  dataRenderer: PropTypes.func,
  sheetRenderer: PropTypes.func,
  rowRenderer: PropTypes.func,
  cellRenderer: PropTypes.func,
  valueViewer: PropTypes.func,
  dataEditor: PropTypes.func,
  attributesRenderer: PropTypes.func,
  onSelect: PropTypes.func,
};

export default ScalarDatasheet;
