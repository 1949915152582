import React, { FC, useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import ImageContainer from 'components/ImageContainer';
import { ERROR_UPLOADING_ICON, ERROR_UPLOADING_LOGO } from 'dashboard409a/common/constants';
import { hideLoadingProgressBar, showLoadingProgressBar } from 'dashboard409a/states/features/global';
import { AppDispatchType } from 'dashboard409a/states/store';
import { useDisplaySnack } from 'dashboard409a/utilities';
import { ImageUploadService } from 'services';
import { useImageUpload } from 'services/hooks';
import { FormTextField } from './components/CustomComponents';
import { ICompanySettings, ICompanySettingsProps, ISavedSettings, IUploadedFile } from './types';

const companySettingsDefaultValues: ICompanySettings = {
  name: '',
  address: '',
  phoneNumber: '',
  website: '',
  icon: '',
  logo: '',
};

const defaultValues: ISavedSettings = {
  id: 0,
  companySettings: companySettingsDefaultValues,
};

const phoneRegExp = /^(\\+)?1?\d{10,15}$/;

const companySettingsValidationSchema = Yup.object({
  name: Yup.string()
    .label('Name')
    .min(3, 'must be at least 3 characters')
    .max(100, 'must be at most 100 characters')
    .required(),
  address: Yup.string().label('Address').max(100, 'must be at most 100 characters').notRequired(),
  phoneNumber: Yup.string()
    .label('Phone number')
    .matches(phoneRegExp, {
      message: 'must be at least 10 and at most 15 characters with no spaces, hyphens, or brackets',
      excludeEmptyString: true,
    })
    .notRequired(),
  website: Yup.string().label('Website').url().notRequired(),
  icon: Yup.string().label('Icon').notRequired(),
  logo: Yup.string().label('Logo').notRequired(),
});

const validationSchema = Yup.object({
  companySettings: companySettingsValidationSchema,
});

type FormData = Yup.InferType<typeof validationSchema>;

const CompanySettings: FC<ICompanySettingsProps> = props => {
  const { data, onSave } = props;

  const dispatch = useDispatch<AppDispatchType>();

  const displaySnack = useDisplaySnack();

  // Form
  const {
    reset,
    setValue,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: data ?? defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const imageService = new ImageUploadService();
  const { getCroppedImage } = useImageUpload();

  const [isSaving, setIsSaving] = useState(false);

  // Icon
  const [iconCroppedAreaPixels, setIconCroppedAreaPixels] = useState({});

  const [iconSelectedFile, setIconSelectedFile] = useState<File | null>(null);
  const [iconSrc, setIconSrc] = useState<null | string>(null);
  const [originalIconSrc, setOriginalIconSrc] = useState('');

  const iconLatestPixelCrop = useRef({});

  // Update ref for use in getCroppedImage
  useEffect(() => {
    if (iconCroppedAreaPixels) {
      iconLatestPixelCrop.current = iconCroppedAreaPixels;
    }
  }, [iconCroppedAreaPixels]);

  // Logo
  const [logoCroppedAreaPixels, setLogoCroppedAreaPixels] = useState({});
  const [logoSelectedFile, setLogoSelectedFile] = useState<File | null>(null);
  const [logoSrc, setLogoSrc] = useState<null | string>(null);
  const [originalLogoSrc, setOriginalLogoSrc] = useState('');
  const logoLatestPixelCrop = useRef({});

  // Update ref for use in getCroppedImage
  useEffect(() => {
    if (logoCroppedAreaPixels) {
      logoLatestPixelCrop.current = logoCroppedAreaPixels;
    }
  }, [logoCroppedAreaPixels]);

  useEffect(() => {
    if (data && !isSaving) {
      setValue('companySettings', data.companySettings, { shouldValidate: true });

      setIconSrc(data.companySettings.icon);
      setOriginalIconSrc(data.companySettings.icon);

      setLogoSrc(data.companySettings.logo);
      setOriginalLogoSrc(data.companySettings.logo);
    }
  }, [data, isSaving, setValue]);

  const uploadImage = async (isIcon: boolean) =>
    new Promise<string>((resolve, reject) => {
      // Icon
      getCroppedImage(
        isIcon ? iconSrc : logoSrc,
        isIcon ? iconLatestPixelCrop.current : logoLatestPixelCrop.current
      ).then(file =>
        imageService
          .uploadImage(file)
          .then((response: IUploadedFile) => {
            setValue(isIcon ? 'companySettings.icon' : 'companySettings.logo', response.location);
            resolve(response.location);
          })
          .catch((error: Error) => {
            reject(error);
          })
      );
    });

  const handleSave = async () => {
    setIsSaving(true);
    dispatch(showLoadingProgressBar());

    if (iconSrc && iconSrc !== originalIconSrc) {
      try {
        await uploadImage(true);
      } catch (error) {
        displaySnack(ERROR_UPLOADING_ICON, 'error');
      }
    }

    if (logoSrc && logoSrc !== originalLogoSrc) {
      try {
        await uploadImage(false);
      } catch (error) {
        displaySnack(ERROR_UPLOADING_LOGO, 'error');
      }
    }

    await handleSubmit(async formData => {
      await onSave(formData as ISavedSettings);
      reset();
    })();

    dispatch(hideLoadingProgressBar());
    setIsSaving(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={6} sm={12}>
        <br />
        <Typography variant="h6">Company Settings</Typography>
        <br />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="companySettings.name"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormTextField
                  label="Name"
                  fullWidth
                  variant="outlined"
                  error={invalid}
                  helperText={invalid && error?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="companySettings.address"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormTextField
                  label="Address"
                  fullWidth
                  variant="outlined"
                  error={invalid}
                  helperText={invalid && error?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="companySettings.phoneNumber"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormTextField
                  label="Phone number"
                  fullWidth
                  variant="outlined"
                  error={invalid}
                  helperText={invalid && error?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="companySettings.website"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormTextField
                  label="Website"
                  fullWidth
                  variant="outlined"
                  error={invalid}
                  helperText={invalid && error?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSave}
              disabled={!iconSelectedFile && !logoSelectedFile && !isValid}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} sm={12}>
        <br />

        <ImageContainer
          title="Company icon"
          imageSrc={iconSrc}
          setImageSrc={setIconSrc}
          setSelectedFile={setIconSelectedFile}
          setCroppedAreaPixels={setIconCroppedAreaPixels}
        />

        <br />
        <br />

        <ImageContainer
          title="Company full logo"
          imageSrc={logoSrc}
          setImageSrc={setLogoSrc}
          setSelectedFile={setLogoSelectedFile}
          setCroppedAreaPixels={setLogoCroppedAreaPixels}
        />
      </Grid>
    </Grid>
  );
};

export default CompanySettings;
