import { useCallback, useContext } from 'react';
import { FUNDS_MEASUREMENT_DATE } from 'common/constants/funds';
import { MEASUREMENT_DATE_ACTION_ADD, MEASUREMENT_DATE_ACTION_DELETE } from 'common/constants/measurement-date';
import { FUNDS_MEASUREMENT_DATE_FILTER } from 'common/constants/pageFilters';
import { LayoutContextValues } from 'common/types/store';
import { LayoutContext } from 'context';
import { useUpdateDateOnQueryString } from 'pages/Funds/hooks';
import { onMeasurementDateChange, OnMeasurementDateChangeParams } from 'pages/Funds/utils';
import { formatMeasurementDates, getArrayValue, getMeasurementDate } from 'utillities';
import { SetFiltersHook, UseSetFilters } from './types';

const useSetFilters: UseSetFilters = () => {
  const { setPageFilters, commonMeasurementDate, setCommonMeasurementDate } = useContext(
    LayoutContext
  ) as unknown as LayoutContextValues;

  const { updateDateOnQueryString } = useUpdateDateOnQueryString();

  const setFilters: SetFiltersHook['setFilters'] = useCallback(
    params => {
      const {
        measurementDates,
        openedMeasurementDate,
        openMeasurementDateModal,
        selectedMeasurementDate,
        setSelectedMeasurementDate,
      } = params;

      const selectedValue
        = selectedMeasurementDate?.id
        ?? (getMeasurementDate({
          commonMeasurementDate,
          fallbackValue: openedMeasurementDate?.id,
          measurementDates,
        }) as number);

      setPageFilters?.([
        {
          addOption: () => openMeasurementDateModal(MEASUREMENT_DATE_ACTION_ADD),
          deleteOption: () => openMeasurementDateModal(MEASUREMENT_DATE_ACTION_DELETE),
          id: FUNDS_MEASUREMENT_DATE_FILTER,
          handler: (event: OnMeasurementDateChangeParams['event']) =>
            onMeasurementDateChange({
              event,
              measurementDates,
              setCommonMeasurementDate,
              setSelectedMeasurementDate,
              updateDateOnQueryString,
            }),
          options: formatMeasurementDates(getArrayValue(measurementDates)),
          selectedValue,
          title: FUNDS_MEASUREMENT_DATE,
        },
      ]);
    },
    [commonMeasurementDate, setCommonMeasurementDate, setPageFilters, updateDateOnQueryString]
  );

  return {
    setFilters,
  };
};

export default useSetFilters;
