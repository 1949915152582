const removeParamFromQueryParams = paramName => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // Do not call object.hasOwnProperty directly (see no-prototype-builtins rule)
  if (Object.prototype.hasOwnProperty.call(params, paramName)) {
    delete params[paramName];
    // Build query string from remaining params
    const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    window.location.replace(`?${queryString}`);
  }
};

export default removeParamFromQueryParams;
