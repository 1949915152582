import { multipleAliases, selectMultipleAliases } from 'pages/Valuations/util/constants';

const obtainMapForSelectMultipleAlias = () => {
  const pairsSelectMultipleAppliedMultiple = selectMultipleAliases.map((alias, index) => [
    alias,
    multipleAliases[index],
  ]);
  return new Map(pairsSelectMultipleAppliedMultiple);
};

export default obtainMapForSelectMultipleAlias;
