import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(5),
    },
    emptyMessageContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

export default useStyles;
