/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { ERROR_400, ERROR_401, ERROR_403, ERROR_404, ERROR_409, ERROR_500 } from 'common/config/api';
import { UNCONTROLLED_ERROR } from 'common/constants/messages/validations';
import { isString } from 'utillities';
import { useRefreshAccessToken } from './auth';

const defaultConfig = {
  [ERROR_400]: {
    defaultType: 'error',
    defaultMessage: 'Something went wrong! Review your data or contact support.',
  },
  [ERROR_401]: {
    defaultType: 'error',
    defaultMessage: 'Unauthorized request.',
  },
  [ERROR_403]: {
    defaultType: 'error',
    defaultMessage: 'You do not have permission to perform this action.',
  },
  [ERROR_404]: {
    defaultType: 'error',
    defaultMessage: 'The page or resource doesn\'t exist.',
  },
  [ERROR_409]: {
    defaultType: 'error',
    defaultMessage: 'The resource you are trying to create conflicts with an existing resource.',
  },
  [ERROR_500]: {
    defaultType: 'error',
    defaultMessage: 'We\'re experiencing some technical issues. Try later or contact support.',
  },
};

export const useResponse = () => {
  const { enqueueSnackbar } = useSnackbar();
  const refreshToken = useRefreshAccessToken();

  const processErrorResponse = useCallback(
    ({ error, defaultErrorMessage, config = {}, action = '' }) => {
      const { status } = error;
      const { notification, message, type, callback } = config[status] || {};
      const { defaultType, defaultMessage } = defaultConfig[status] || {};

      const variant = type || defaultType;
      let notificationMsg = defaultErrorMessage || message || defaultMessage;

      if (status === 401) refreshToken();

      if (status === 403) {
        notificationMsg = action ? `You do not have permission to ${action}` : defaultMessage;
      }

      if (notification !== false) {
        enqueueSnackbar(notificationMsg, { variant: variant || 'error' });
      }

      if (callback) {
        callback();
      }
    },
    [enqueueSnackbar]
  );

  const successNotification = useCallback(message => {
    enqueueSnackbar(message, { variant: 'success' });
  }, []);

  const infoNotification = useCallback(message => {
    enqueueSnackbar(message, { variant: 'info' });
  }, []);

  const errorNotification = useCallback(message => {
    const errorMessage = isString(message) ? message : UNCONTROLLED_ERROR;
    enqueueSnackbar(errorMessage, { variant: 'error' });
  }, []);

  return {
    processErrorResponse,
    successNotification,
    errorNotification,
    infoNotification,
  };
};

export default useResponse;
