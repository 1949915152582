import React, { useMemo } from 'react';
import { Button } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { valuationPath } from 'common/constants/paths';
import { MessageBox } from 'components';

const EmptyValuationMessage = () => {
  const history = useHistory();
  const { firmSlugParam, companySlugParam } = useParams();

  const valuationURL = useMemo(() => valuationPath(firmSlugParam, companySlugParam), [firmSlugParam, companySlugParam]);

  const GoToValuation = () => (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => {
        history.push(valuationURL);
      }}
      startIcon={<ArrowBackIcon />}>
      Add Approaches
    </Button>
  );

  return (
    <MessageBox
      title="The Valuation table is incomplete"
      tagline="Please add some approaches first"
      fullWidth={false}
      hasFrame={false}
      action={<GoToValuation />}
    />
  );
};

export default EmptyValuationMessage;
