import React, { useContext, useState } from 'react';
import { Button, ButtonGroup, ListItemIcon, Menu, MenuItem, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MoreVert } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useStore } from 'common/store';
import LayoutContext from 'context/LayoutContext';

const useStyles = makeStyles(theme => ({
  menuButton: {
    maxWidth: '20px',
    minWidth: '20px',
    color: props => props.dotsColor || 'white',
    padding: '0',
  },
  menuList: {
    marginTop: `${theme.spacing(2)}px`,
  },
  buttonGroup: {
    verticalAlign: 'middle',
    marginLeft: '24px',
  },
}));

const ExtraActionsMenu = ({ options, dotsColor, singleItem }) => {
  const classes = useStyles({ dotsColor });
  const { extraPageActions } = useContext(LayoutContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [{ isShowLoadingProgress }] = useStore();
  const optionsToMap = options || extraPageActions || [];

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleSecondaryItemClick = option => {
    setAnchorEl(null);
    if (singleItem) {
      option.callback(singleItem);
    } else {
      option.callback();
    }
  };

  return (
    <>
      <ButtonGroup color="secondary" aria-label="Page extra actions" className={classes.buttonGroup}>
        <Button
          id="extra-actions-dropdown-action"
          className={classes.menuButton}
          onClick={handleClick}
          variant="text"
          disabled={isShowLoadingProgress}>
          <MoreVert />
        </Button>
      </ButtonGroup>

      <Popper open={open} anchorEl={anchorEl} id={open ? 'transitions-popper' : undefined}>
        <Menu
          className={classes.menuList}
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}>
          {optionsToMap.map((option, optionIndex) => (
            <MenuItem
              key={option.label}
              id={`extra-action-item-${optionIndex + 1}`}
              onClick={() => handleSecondaryItemClick(option)}
              disabled={!option.isActive}>
              {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </Popper>
    </>
  );
};

ExtraActionsMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
      isActive: PropTypes.bool,
    })
  ),
  dotsColor: PropTypes.string,
  singleItem: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default ExtraActionsMenu;
