import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { SELECT_OPTION } from 'common/constants/inputs';
import withAction from 'components/WithAction';
import withDialog from 'components/WithDialog';
import withLink from 'components/WithLink';
import withRequired from 'components/WithRequired';
import withTooltip from 'components/WithTooltip';

const SelectValueViewer = props => {
  const { cell, onDoubleClick, options, defaultLabel } = props;

  const selected
    = cell.columnLegend || cell.insideLedger || cell.dropdown ? options[cell.value] || defaultLabel : cell.value;

  let className = 'value-viewer';

  if (cell.readOnly) {
    return (
      <span className={className}>
        <span className="text-cell">{selected}</span>
      </span>
    );
  }

  className = 'select-value-viewer';

  // Custom double click handler for cells not in a spreadsheet component
  const handleDoubleClick = () => {
    const event = new MouseEvent('dblclick', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    document.getElementById(`select-value-viewer-${selected}`).dispatchEvent(event);
  };

  return (
    <div
      id={`select-value-viewer-${selected}`}
      role="button"
      className={className}
      tabIndex={0}
      onClick={onDoubleClick || handleDoubleClick}
      onKeyDown={onDoubleClick}>
      <span className="text-cell">{selected}</span>
      <span className="dropdown-icon">
        <ArrowDropDownIcon />
      </span>
    </div>
  );
};

const enhance = flowRight(withTooltip, withDialog, withRequired, withLink, withAction);

export default enhance(SelectValueViewer);

SelectValueViewer.defaultProps = {
  defaultLabel: SELECT_OPTION,
};

SelectValueViewer.propTypes = {
  value: PropTypes.any,
  cell: PropTypes.object.isRequired,
  onDoubleClick: PropTypes.func,
  options: PropTypes.object.isRequired,
  defaultLabel: PropTypes.string,
};
