import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DialogContext from 'context/DialogContext';

export const DialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState({});
  const [wrapperProps, setWrapperProps] = useState({});
  const [contentProps, setContentProps] = useState({});

  const wrapperComponentRef = useRef(null);
  const contentComponentRef = useRef(null);
  const onCloseCallback = useRef(null);

  const showDialog = ({
    wrapper,
    wrapperProps: dialogWrapperProps,
    content,
    contentProps: dialogContentProps,
    onClose,
    ...restConfig
  }) => {
    setOpen(true);
    setConfig(restConfig);

    if (dialogWrapperProps) {
      setWrapperProps(dialogWrapperProps);
    }

    if (dialogContentProps) {
      setContentProps(dialogContentProps);
    }

    wrapperComponentRef.current = wrapper;
    contentComponentRef.current = content;
    onCloseCallback.current = onClose;
  };

  const hideDialog = () => {
    setOpen(false);
    setWrapperProps({});
    setContentProps({});

    wrapperComponentRef.current = null;
    contentComponentRef.current = null;

    if (onCloseCallback.current) {
      onCloseCallback.current();
    }
  };

  const dialogContextProviderValue = useMemo(
    () => ({
      dialog: wrapperComponentRef.current,
      content: contentComponentRef.current,
      wrapperProps: {
        ...wrapperProps,
        ...config,
        open,
        onClose: hideDialog,
      },
      contentProps,
      showDialog,
    }),
    [config, contentProps, open, wrapperProps]
  );

  return <DialogContext.Provider value={dialogContextProviderValue}>{children}</DialogContext.Provider>;
};

DialogProvider.propTypes = {
  children: PropTypes.element,
};

export const DialogConsumer = DialogContext.Consumer;
