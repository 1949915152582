import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import { QUESTIONS_TYPES } from 'common/constants/firms';
import { DropdownAndDateAnswers } from 'pages/Funds/components/ProcessManagement/DropdownAndDateAnswers';
import { TextAnswers } from 'pages/Funds/components/ProcessManagement/TextAnswers';
import { ModalAnswerRowProps } from './types';

const ModalAnswerRow: FC<ModalAnswerRowProps> = props => {
  const { task } = props;
  const { question_type: questionType, answers } = task;

  if (!isEmpty(answers)) {
    switch (questionType) {
      case QUESTIONS_TYPES.text:
        return <TextAnswers answers={answers} />;
      case QUESTIONS_TYPES.dropdown:
        return <DropdownAndDateAnswers task={task} isDropdown />;
      case QUESTIONS_TYPES.date:
        return <DropdownAndDateAnswers task={task} />;
      default:
        return null;
    }
  }
  return null;
};

export default ModalAnswerRow;
