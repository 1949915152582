import React, { FC } from 'react';
import { OSCSHProps } from './types';

const OpinionSummaryCardSubHeader: FC<OSCSHProps> = ({ displayExpired }) => {
  if (displayExpired) {
    return (
      <div
        style={{
          color: 'red',
          textAlign: 'right',
          fontSize: '0.75rem',
          fontStyle: 'italic',
          width: '50%',
          display: 'inline-block',
        }}>
        Opinion has expired, renew now for an updated opinion
      </div>
    );
  }
  return <span />;
};

export default OpinionSummaryCardSubHeader;
