import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { ARE_YOU_SURE, MODAL_ACTION_BUTTON } from 'common/constants/firms';
import { NO_STRING, YES_STRING } from 'common/constants/general';
import CustomPrimaryButton from 'components/CustomPrimaryButton/CustomPrimaryButton';
import { useCreateRequestedTasks } from 'services/hooks/firm';

const FRModalActionButton = ({
  selectedCompanies,
  setSelectedCompanies,
  handleClose,
  firmId,
  documents,
  questions,
}) => {
  const [needToConfirm, setNeedToConfirm] = useState(false);
  const [createRequestedTasks] = useCreateRequestedTasks();

  const handleConfirm = () => {
    setNeedToConfirm(true);
  };

  const handleCancel = () => {
    setNeedToConfirm(false);
  };

  const getResponsibleUsersIds = company => company.users_with_company_permissions.map(user => user.id);

  const handleSend = () => {
    // 1. Prepare request data
    const tmpQuestions = questions.map(q => q.id);
    const tmpDocuments = documents.map(d => d.id);

    // After completing the feature: User Management, delete tmpSelectedCompanies and use directly selectedCompanies. We are hardcoding this until we have the User Management feature.
    const tmpSelectedCompanies = selectedCompanies.map(company => ({
      ...company,
      responsible_users: getResponsibleUsersIds(company),
    }));

    const requestData = {
      firm: firmId,
      questions: tmpQuestions,
      requested_documents: tmpDocuments,
      send_email: true,
      companies: tmpSelectedCompanies,
    };
    // 2. Send request
    createRequestedTasks(requestData);
    // 3. Close modal
    handleClose();
    // 4. Scroll to top of the page (to see the loading line)
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    // 5. Reset state
    setSelectedCompanies([]);
  };

  if (!needToConfirm) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{
          width: '100%',
        }}>
        <CustomPrimaryButton onClick={handleConfirm} disabled={isEmpty(selectedCompanies)}>
          {MODAL_ACTION_BUTTON}
        </CustomPrimaryButton>
      </Box>
    );
  }

  if (needToConfirm) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{
          width: '100%',
        }}>
        <Typography
          color="primary"
          style={{
            fontSize: '0.75rem',
            textTransform: 'uppercase',
            fontWeight: 700,
          }}
          gutterBottom>
          {ARE_YOU_SURE}
        </Typography>
        <Box display="flex" justifyContent="center">
          <Box marginRight="1rem">
            <CustomPrimaryButton onClick={handleCancel} variant="outlined">
              {NO_STRING}
            </CustomPrimaryButton>
          </Box>
          <CustomPrimaryButton onClick={handleSend}>{YES_STRING}</CustomPrimaryButton>
        </Box>
      </Box>
    );
  }

  return null;
};

FRModalActionButton.propTypes = {
  selectedCompanies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedCompanies: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  firmId: PropTypes.number.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FRModalActionButton;
