import React from 'react';
import { MenuItem } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';

const AddFirmAction = ({ onClickHandler }) => (
  <MenuItem onClick={onClickHandler}>
    <Add />
    Add Firm
  </MenuItem>
);

AddFirmAction.propTypes = {
  onClickHandler: PropTypes.func,
};

export default AddFirmAction;
