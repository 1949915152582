export const MARKET_ADJUSTMENT_TITLE = 'Market Adjustment';
export const MARKET_ADJUSTMENT_ALIAS = 'market_adjustment';
export const ADJUSTMENT_SELECTION_TITLE = 'Value to Adjust';
export const ADJUSTMENT_TYPE_ALIAS = 'adjustment_type_selection';
export const ADJUSTED_ENTERPRISE_TITLE = 'Adjusted Enterprise Value';
export const ADJUSTED_ENTERPRISE_ALIAS = 'adjusted_enterprise_value';
export const ADJUSTED_EQUITY_ALIAS = 'adjusted_equity_value';
export const ADJUSTED_EQUITY_TITLE = 'Adjusted Equity Value';

export const MARKET_ADJUSTMENT_CUSTOM_KEY = 'MarketAdjustment';

export const EQUITY_VALUE_OPTION = 'Equity Value';
export const ENTERPRISE_VALUE_OPTION = 'Enterprise Value';
export const ADJUSTMENT_OPTIONS = [ENTERPRISE_VALUE_OPTION, EQUITY_VALUE_OPTION];
