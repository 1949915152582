import { useQueryClient } from '@tanstack/react-query';
import { hoursToMilliseconds } from 'date-fns';
import { isEmpty, isNil } from 'lodash';
import {
  SCENARIO_VALUES_DEFAULT_ERROR_MESSAGE,
  SCENARIO_VALUES_QUERY_KEY,
} from 'common/constants/services/allocations';
import { ScenarioValues } from 'common/types/allocation';
import { AllocationService } from 'services';
import { throwError } from 'utillities';
import { FetchScenarioValues, GetScenarioValuesHook, GetScenarioValuesParams, UseGetScenarioValues } from './types';

const getScenarioValues = async (params: GetScenarioValuesParams) => {
  const { scenario } = params;

  if (isEmpty(scenario)) return null;

  const allocationService = new AllocationService();

  try {
    const scenarioValues = (await allocationService.postScenarioValues(scenario)) as ScenarioValues;

    if (isNil(scenarioValues)) return throwError(SCENARIO_VALUES_DEFAULT_ERROR_MESSAGE);

    return scenarioValues;
  } catch (error) {
    return throwError(SCENARIO_VALUES_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetScenarioValues: UseGetScenarioValues = () => {
  const queryClient = useQueryClient();

  const fetchScenarioValues: FetchScenarioValues = async params => {
    const { scenario } = params;

    if (isEmpty(scenario)) {
      return null;
    }

    try {
      const data = await queryClient.fetchQuery<GetScenarioValuesHook, Error>(
        [SCENARIO_VALUES_QUERY_KEY, scenario],
        () => getScenarioValues({ scenario }),
        {
          staleTime: hoursToMilliseconds(24),
        }
      );

      return data;
    } catch (error) {
      return null;
    }
  };

  return fetchScenarioValues;
};

export default useGetScenarioValues;
