const sumValuesFilteredCells = ({ cells, year, rowNumber }) => {
  const extractYearFromCellDate = cellDate => {
    /* Use a regex to get the year instead of splitting to account for potential date format changes
    With the g flag we ensure that we get the year match without the associated capturing groups
    */
    const cellDateMatch = cellDate?.match(/(19|20)\d{2}$/g);
    if (Array.isArray(cellDateMatch)) return cellDateMatch[0];

    return null;
  };

  const relatedCells = Object.values(cells).filter(
    tmpCell =>
      extractYearFromCellDate(tmpCell.date) === year
      && tmpCell.rowNumber === rowNumber
      && !tmpCell.isLTM
      && !tmpCell.isNTM
  );

  return relatedCells.reduce((yearTotal, currentCell) => yearTotal + Number(currentCell.value), 0);
};

export default sumValuesFilteredCells;
