/* eslint-disable operator-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Add, Store, Timeline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { isEmpty, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import { allocationsAction, captableAction } from 'common/actions';
import { baseCompanyUrl, companySummaryUrl, fundSummaryUrl } from 'common/config/urls';
import { WATERFALL_SLUG } from 'common/constants/allocations';
import { BREAKPOINT_ANALYSIS_SLUG, CAP_TABLE_SLUG, FUND_OWNERSHIP_SLUG } from 'common/constants/cap-table';
import { COMPANIES_SUMMARY_SLUG } from 'common/constants/companies';
import { DOCUMENTS_SLUG } from 'common/constants/documents';
import { FINANCIAL_SLUG, FINANCIAL_TITLE } from 'common/constants/financials';
import { SIGN_IN_URL } from 'common/constants/login';
import { FUND_PERMISSION_NAME } from 'common/constants/sidebar';
import { PINNED_COMPANIES_KEY } from 'common/constants/userPreferences';
import { VALUATIONS_SLUG, VALUATIONS_TITLE } from 'common/constants/valuations/valuations';
import { useLocalStorageByUser } from 'common/hooks';
import { useStore } from 'common/store';
import LayoutContext from 'context/LayoutContext';
import UnsavedChanges from 'context/UnsavedChanges';
import useHandleClickDialog from 'layouts/Main/components/NewCompanyDialog/utilities/useHandleClickDialog';
import { useDialogFormsStore } from 'store';
import CompanyFilter from './CompanyFilter';
import DropDown from './DropDown';
import ListItems from './ListItems';

const companyServices = [
  {
    id: 0,
    title: 'Summary',
    slug: COMPANIES_SUMMARY_SLUG,
  },
  {
    id: 1,
    title: FINANCIAL_TITLE,
    slug: FINANCIAL_SLUG,
  },
  {
    id: 2,
    title: 'Capitalization Table',
    slug: CAP_TABLE_SLUG,
  },
  {
    id: 3,
    title: 'Fund Ownership',
    slug: FUND_OWNERSHIP_SLUG,
  },
  {
    id: 4,
    title: 'Breakpoint Analysis',
    slug: BREAKPOINT_ANALYSIS_SLUG,
  },
  {
    id: 5,
    title: VALUATIONS_TITLE,
    slug: VALUATIONS_SLUG,
  },
  {
    id: 6,
    title: 'Waterfall',
    slug: WATERFALL_SLUG,
  },
  {
    id: 7,
    title: 'Documents',
    slug: DOCUMENTS_SLUG,
  },
];

const bottomNavHeight = 170;

const useStyles = makeStyles(theme => ({
  top: {
    flexGrow: 1,
    maxHeight: `calc(100vh - (${bottomNavHeight}px + ${theme.toolbarHeight}px))`,
  },
}));

const TopMenuItems = props => {
  const { setOpenSidebar, renderFundsAndCompanies, open } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuItem, setSubMenuItem] = useState();

  const dropDownRef = useRef();

  const [
    { firmInfo, companyInfo, fundList, companyList, isRestrictedUser, otherPermissions, user, fundInfo },
    dispatch,
  ] = useStore();

  const { action } = useContext(UnsavedChanges);
  const { commonMeasurementDate, viewOnlyUser } = useContext(LayoutContext);

  const [pinnedCompanies, setPinnedCompanies] = useLocalStorageByUser(PINNED_COMPANIES_KEY, []);

  const classes = useStyles();

  const history = useHistory();

  // Dialog Forms Store
  const setDisplayFundDialogForm = useDialogFormsStore(state => state.setDisplayFundDialogForm);

  const handleClick = useHandleClickDialog();

  const fundsMenuItems = useMemo(() => {
    if (!fundList && !fundInfo) {
      return null;
    }

    if (fundInfo && isRestrictedUser && firmInfo) {
      return [
        {
          id: fundInfo.id,
          title: fundInfo.name,
          slug: fundInfo.slug,
          url: fundSummaryUrl(firmInfo.slug, fundInfo.slug),
        },
      ];
    }

    if (isEmpty(fundList)) {
      return [];
    }

    const tmpFundList = fundList.map(item => ({
      id: item.id,
      title: item.name,
      slug: item.slug,
      url: fundSummaryUrl(firmInfo.slug, item.slug),
    }));

    return orderBy(tmpFundList, ['title'], ['asc']);
  }, [fundList, fundInfo, isRestrictedUser, firmInfo]);

  const getMeasurementDateSlug = menuItem =>
    menuItem.company_measurement_dates?.find(cmd => cmd.date === commonMeasurementDate)?.slug ||
    menuItem.measurement_date_slug;

  const getCompanySubMenu = menuItem => {
    if (commonMeasurementDate && menuItem?.company_measurement_dates) {
      const measurementDateSlug = getMeasurementDateSlug(menuItem);
      return companyServices.map(item => ({
        ...item,
        measurementDateSlug,
      }));
    }

    return companyServices;
  };

  const hasFundPermission = useMemo(() => {
    if (user?.is_superuser) return true;

    if (fundList && !isEmpty(fundList)) return true;

    if (otherPermissions && !isEmpty(otherPermissions)) {
      return otherPermissions.some(({ feature_object }) => feature_object.object_type === FUND_PERMISSION_NAME);
    }

    return false;
  }, [user, fundList, otherPermissions]);

  const companySubMenuItems = useMemo(() => {
    if (!companyList) {
      return null;
    }

    if (isEmpty(companyList)) {
      return [];
    }

    const updatePinStatus = (id, title, isPinned) => {
      let tmpPinnedOptions = {};
      if (isPinned) {
        tmpPinnedOptions = pinnedCompanies.filter(o => o.value !== id) || [];
      } else {
        tmpPinnedOptions = [...pinnedCompanies, { value: id, label: title }];
      }
      setPinnedCompanies(tmpPinnedOptions);
    };

    let tmpCompanyList = [];
    if (companyList) {
      tmpCompanyList = companyList.map(item => {
        let isPinned = false;

        if (Array.isArray(pinnedCompanies)) {
          isPinned = !!pinnedCompanies.find(c => c.value === item.company_id);
        }

        return {
          id: item.company_id,
          title: item.company_name,
          slug: item.company_slug,
          subMenu: getCompanySubMenu(item),
          url: companySummaryUrl(firmInfo.slug, item.company_slug),
          isPinnable: true,
          updatePinStatus,
          isPinned,
          measurementDateSlug: getMeasurementDateSlug(item),
        };
      });
    }

    return orderBy(tmpCompanyList, 'isPinned', 'desc');
  }, [companyList, pinnedCompanies]);

  const updatePins = useCallback(
    options => {
      setPinnedCompanies(options);
    },
    [setPinnedCompanies]
  );

  const companiesMenuItem = useMemo(() => {
    const minMenuForRestrictedUser = {
      title: 'Companies',
      slug: 'companies',
      url: '/icons',
      icon: <Store />,
      subMenu: companySubMenuItems,
      filterComponent: companySubMenuItems?.length > 1 && <CompanyFilter updatePins={updatePins} />,
    };
    if (!isRestrictedUser) {
      return {
        ...minMenuForRestrictedUser,
        actionMenu: viewOnlyUser
          ? null
          : {
            title: 'New Company',
            slug: 'new-company',
            href: '/#',
            icon: <Add />,
            callback: handleClick,
          },
      };
    }

    if (isRestrictedUser && hasFundPermission) {
      return minMenuForRestrictedUser;
    }

    const {
      company_id: companyId,
      company_name: companyName,
      company_slug: companySlug,
      measurement_date_slug: measurementDateSlug,
    } = companyInfo;

    const companySubMenuItem = [
      {
        id: companyId,
        title: companyName,
        slug: companySlug,
        subMenu: companyServices,
        url: firmInfo && companySummaryUrl(firmInfo.slug, companySlug),
        isPinnable: false,
        measurementDateSlug:
          companyList
            ?.find(c => c.id === companyId)
            .company_measurement_dates.find(cmd => cmd.date === commonMeasurementDate)?.slug || measurementDateSlug,
      },
    ];

    return {
      title: 'Companies',
      slug: 'companies',
      url: '/icons',
      icon: <Store />,
      subMenu: companySubMenuItem,
    };
  }, [isRestrictedUser, firmInfo, companyInfo, companySubMenuItems, viewOnlyUser]);

  const fundsMenuItem = useMemo(() => {
    if (hasFundPermission) {
      return {
        title: 'Funds',
        slug: 'funds',
        url: SIGN_IN_URL,
        icon: <Timeline />,
        subMenu: fundsMenuItems,
        actionMenu:
          isRestrictedUser || viewOnlyUser
            ? undefined
            : {
              title: 'New Fund',
              slug: 'new-fund',
              url: '/#',
              icon: <Add />,
              callback: () => setDisplayFundDialogForm(true),
            },
      };
    }

    return null;
  }, [hasFundPermission, fundsMenuItems, viewOnlyUser]);

  const menuItems = useMemo(() => {
    let menu = [];
    if (fundsMenuItem) {
      menu = [...menu, fundsMenuItem];
    }
    menu = [...menu, companiesMenuItem];
    return menu;
  }, [fundsMenuItem, companiesMenuItem]);

  const closeDropDown = useCallback(() => {
    setSubMenuItem(null);
    setAnchorEl(null);
  }, [setSubMenuItem, setAnchorEl]);

  const handleDropDown = useCallback(
    (option, subMenuItemParam) => {
      setOpenSidebar(false);

      // Do not execute if there are pending changes and the user wants to stay in the page
      if (!action && companyInfo.id !== subMenuItemParam.id) {
        dispatch(captableAction.setCaptableInfo({}));
        dispatch(allocationsAction.setAllocationInfo({}));
      }

      const pathname = `${baseCompanyUrl(firmInfo.slug, subMenuItemParam.slug)}/${option.slug}`;
      const search = queryString.stringify({ date: subMenuItemParam.measurementDateSlug });
      history.push({ pathname, search });
    },
    [action, companyInfo?.id, firmInfo?.slug]
  );

  useEffect(() => {
    if (anchorEl) {
      dropDownRef.current.style.visibility = 'visible';
      dropDownRef.current.style.transform = 'translateX(0px)';
      dropDownRef.current.style.opacity = 1;
      dropDownRef.current.style.top = `${anchorEl.top}px`;
      dropDownRef.current.style.left = '300px';
      dropDownRef.current.style.transition = 'top 250ms linear 250ms';
    }
  }, [anchorEl]);

  return (
    <div onMouseLeave={closeDropDown}>
      <DropDown open={open} handleDropDown={handleDropDown} dropDownRef={dropDownRef} subMenuItem={subMenuItem} />
      <PerfectScrollbar options={{ suppressScrollX: true, wheelPropagation: false, wheelSpeed: 3 }}>
        <div className={classes.top}>
          {renderFundsAndCompanies && (
            <ListItems
              {...props}
              menuItems={menuItems}
              setSubMenuItem={setSubMenuItem}
              setAnchorEl={setAnchorEl}
              dropDownRef={dropDownRef}
              closeDropDown={closeDropDown}
            />
          )}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

TopMenuItems.propTypes = {
  setOpenSidebar: PropTypes.func,
  renderFundsAndCompanies: PropTypes.bool,
  open: PropTypes.bool,
};

export default TopMenuItems;
