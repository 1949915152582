import {
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { GetCollapsableColumnsParams } from './types';

const { VALUATION_SUMMARY_SPREADSHEET_EQUITY_VALUE_COLUMN_KEY } = SHEET_ALIASES_CONSTANTS;

const { getAdjustedColumnNumber, VALUATION_SUMMARY_SPREADSHEET_HEADERS } = SHEET_TITLES_CONSTANTS;
const { APPROACH_TYPE, ENTERPRISE_VALUE, EQUITY_VALUE } = VALUATION_SUMMARY_SPREADSHEET_HEADERS;

const PARENT_COLUMN = EQUITY_VALUE.columnNumber;
const CHILD_COLUMNS = Object.freeze([APPROACH_TYPE.columnNumber, ENTERPRISE_VALUE.columnNumber]);

const getCollapsableColumns = (params: GetCollapsableColumnsParams) => {
  const { colNumber, isUniformCurrency } = params;

  let columnId = null;
  let isParent = false;
  let parentColumn = null;

  const adjustedColumnNumber = getAdjustedColumnNumber({ colNumber, isUniformCurrency });

  if (adjustedColumnNumber === PARENT_COLUMN) {
    columnId = VALUATION_SUMMARY_SPREADSHEET_EQUITY_VALUE_COLUMN_KEY;
    isParent = true;
  }

  if (CHILD_COLUMNS.includes(adjustedColumnNumber)) {
    parentColumn = VALUATION_SUMMARY_SPREADSHEET_EQUITY_VALUE_COLUMN_KEY;
  }

  return { columnId, isParent, parentColumn };
};

export default getCollapsableColumns;
