import { clientWithAuth } from './base';
import { companyEndpoints } from './endpoints';
import { IGetCompanyDetailsResponse } from './types';

export const getCompanyDetails = (companyId: number): Promise<IGetCompanyDetailsResponse> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .get(companyEndpoints.getCompanyDetails(companyId))
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });

export const updateCompanyDetails = (companyDetails: IGetCompanyDetailsResponse): Promise<{ [key: string]: string }> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .put(companyEndpoints.getCompanyDetails(companyDetails.id), companyDetails)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });
