import React from 'react';
import { ADD_INVESTMENTS_TO_CONTINUE, EMPTY_FIRM } from 'common/constants/firms';
import { MessageBox } from 'components';

const NoInformationMessage = () => (
  <>
    <MessageBox title={EMPTY_FIRM} tagline={ADD_INVESTMENTS_TO_CONTINUE} fullWidth={false} />
  </>
);

export default NoInformationMessage;
