import React from 'react';
import { Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import useHandleClickDialog from 'layouts/Main/components/NewCompanyDialog/utilities/useHandleClickDialog';

const AddCompanyButton = () => {
  const handleClick = useHandleClickDialog();
  return (
    <Button variant="outlined" color="primary" onClick={handleClick} endIcon={<AddIcon />}>
      Add Company
    </Button>
  );
};

export default AddCompanyButton;
