import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import ScalarDatasheet from 'components/ScalarDatasheet';

const TransactionsTableComps = ({ GPCDataGrid, onCellsChanged }) => (
  <Grid item xs={6} style={{ marginTop: '2rem' }}>
    <ScalarDatasheet
      className="comp-datasheet transactions-table-comps"
      data={GPCDataGrid}
      onCellsChanged={onCellsChanged}
    />
  </Grid>
);

TransactionsTableComps.propTypes = {
  GPCDataGrid: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  onCellsChanged: PropTypes.func.isRequired,
};

export default TransactionsTableComps;
