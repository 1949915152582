/* eslint-disable react-hooks/exhaustive-deps */
// #region dependencies
import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { useStore } from 'common/store';
import FirstViewLogo from 'layouts/AuthLayout/components/FirstViewLogo';
import { useGetLowerUserPermissions } from 'services/hooks/users';
import { isFalseStrict } from 'utillities';
import LoadingPaper from './components/LoadingPaper';
import ProductSelector from './components/ProductSelector';
import useStyles from './LogIn.styles';
import LogInForm from './LogInForm';
import OrganizationSelector from './OrganizationSelector';
import ResetPasswordForm from '../PasswordReset/ResetPasswordForm';

const Login = props => {
  const classes = useStyles();
  const [{ user }] = useStore();
  const [permissions, fetchPermissions] = useGetLowerUserPermissions();
  const [passwordRecovery, setPasswordRecovery] = useState(false);
  const [loading, setLoading] = useState(true);
  const isAuthenticating = useMemo(
    () => localStorage.getItem('access') && !user,
    [user, localStorage.getItem('access')]
  );

  // Apps Access
  const [hasAccessToBothApps, setHasAccessToBothApps] = useState(null);
  const [hasAccessToInvestor, setHasAccessToInvestor] = useState(null);
  const [hasAccessToDashboard, setHasAccessToDashboard] = useState(null);

  // get other permissions
  useEffect(() => {
    if (user && !isEmpty(user)) {
      fetchPermissions(user.id);
    }

    // missing clean up
  }, [user]);

  // Apps Access
  useEffect(() => {
    if (!isEmpty(user) && !isAuthenticating) {
      // firm permissions are frequently empty for superusers
      // actual standalone companies permissions instances will probably be none for superusers
      // but a serializer will return data as if they existed.
      const hasFirmsAccess = user?.is_superuser || (user?.firms_permissions && user.firms_permissions.length > 0);
      const hasDashboardAccess
        = user?.standalone_companies_permissions && user.standalone_companies_permissions.length > 0;
      setHasAccessToBothApps(hasFirmsAccess && hasDashboardAccess);

      setHasAccessToInvestor(hasFirmsAccess);
      setHasAccessToDashboard(hasDashboardAccess);
    } else {
      setHasAccessToBothApps(null);
      setHasAccessToInvestor(null);
      setHasAccessToDashboard(null);
    }
  }, [user, isAuthenticating]);

  return (
    <>
      <LoadingPaper isFullScreen loading={loading} />
      <Grid container className={classes.root} style={loading ? { display: 'none' } : {}}>
        <FirstViewLogo loading={loading} setLoading={setLoading} />
        <Grid item lg={6} xs={12} className={classes.formContainer}>
          {isEmpty(user) && !isAuthenticating && !passwordRecovery && (
            <LogInForm setPasswordRecovery={setPasswordRecovery} {...props} />
          )}

          {isEmpty(user) && !isAuthenticating && passwordRecovery && (
            <ResetPasswordForm setPasswordRecovery={setPasswordRecovery} />
          )}

          {isEmpty(user) && isAuthenticating && <LoadingPaper />}

          {hasAccessToBothApps ? (
            // Product Selector
            <ProductSelector permissions={permissions} />
          ) : (
            <>
              {hasAccessToInvestor || hasAccessToDashboard ? (
                //  Firm Selector | 409A Company Selector
                <OrganizationSelector lowerPermissions={permissions} />
              ) : (
                /* Default (No Access) Selector -- if feature flag enabled, OrganizationSelector loads Add Firm   */
                isFalseStrict(hasAccessToBothApps)
                && isFalseStrict(hasAccessToInvestor)
                && isFalseStrict(hasAccessToDashboard) && <OrganizationSelector lowerPermissions={permissions} />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(Login);
