import React, { FC } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ExpandIcon from '@material-ui/icons/Add';
import ContractIcon from '@material-ui/icons/Minimize';
import { SYSTEM_EXPAND, USER_EXPAND } from 'common/actions/row-groups/types';

interface CollapsibleIconProps {
  rowGroups?: Record<string, string>;
  alias: string;
  toggleRows: () => void;
}

const useStyles = makeStyles(theme => ({
  collapseButton: {
    padding: theme.spacing(0.4),
  },
  collapseIcon: {
    fontSize: '1.2rem',
  },
}));

const expandActions = [USER_EXPAND, SYSTEM_EXPAND];

const CollapsibleIcon: FC<CollapsibleIconProps> = ({ rowGroups, alias, toggleRows }) => {
  const classes = useStyles();

  return (
    <IconButton color="primary" size="small" className={classes.collapseButton} onClick={toggleRows}>
      {rowGroups && expandActions.includes(rowGroups[alias]) ? (
        <ContractIcon className={classes.collapseIcon} />
      ) : (
        <ExpandIcon className={classes.collapseIcon} />
      )}
    </IconButton>
  );
};

export default CollapsibleIcon;
