/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SELECT_PRIORITY } from 'common/constants/cap-table/capTable';
import { CustomSelect } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import withRequired from 'components/WithRequired';
import withTooltip from 'components/WithTooltip';

const LiquidationPriority = ({ cell, options, ...restProps }) => {
  const { onCommit, onRevert } = restProps;
  const { cells, onCellsChanged } = useContext(FeaturedSpreadsheetContext);
  const [selectedValue, setSelectedValue] = useState(false);

  // On first load set the cell value
  useEffect(() => {
    if (cell.value && !selectedValue) {
      setSelectedValue(cell.value);
    }
  }, []);

  useEffect(() => {
    if (selectedValue) {
      const tmpState = { ...cells };
      if (tmpState[cell.key]) {
        if (tmpState[cell.key].value !== selectedValue) {
          tmpState[cell.key].value = selectedValue;
          onCellsChanged([{ cell, value: selectedValue }]);
        }
      }
    }
  }, [selectedValue]);

  const handleChange = value => {
    const newValue = value;
    setSelectedValue(newValue);
  };

  return (
    <CustomSelect
      id={`liquidation-priority-${cell.key}`}
      value={selectedValue || ''}
      options={options}
      onChange={handleChange}
      onCommit={onCommit}
      onRevert={onRevert}
      disabled={cell.readOnly}
      onEmptyValue={SELECT_PRIORITY}
    />
  );
};

LiquidationPriority.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
};

LiquidationPriority.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    key: PropTypes.string,
    readOnly: PropTypes.bool,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.number,
    })
  ).isRequired,
};

export default withRequired(withTooltip(LiquidationPriority));
