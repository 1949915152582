/* eslint-disable import/no-unresolved */
/* eslint-disable react/display-name */
import React from 'react';
import * as CTC from 'common/constants/cap-table';
import * as FOC from 'common/constants/fund-ownership';
import * as SUMMARY from 'common/constants/summary';
import {
  fullyDilutedOwnershipFormat,
  largeCurrencyFormat,
  largeDecimalFormat,
  sharesFormat,
} from 'common/formats/formats';
import { SelectValueViewer } from 'components';
import { FundList, SecurityList } from '../components';

const rowConfig = (hasDividends, isDisabled) => [
  // 01. Title
  {
    value: '',
    alias: 'title',
    className: 'subtitle-border-bottom blue title-col',
    hidden: false,
    readOnly: true,
    defaultValue: '',
    isTitleOrHeader: true,
  },
  // 02.  Security
  {
    value: 'Security',
    alias: 'security',
    hidden: false,
    readOnly: isDisabled,
    dataEditor: props => <SecurityList {...props} />,
    valueViewer: props => <SelectValueViewer {...props} />,
  },
  // 03.  Fund
  {
    value: 'Fund',
    alias: 'fund',
    hidden: false,
    readOnly: isDisabled,
    dataEditor: props => <FundList {...props} />,
    valueViewer: props => <SelectValueViewer {...props} />,
  },
  // 04.  Investment Date
  {
    value: SUMMARY.INVESTMENT_DATE_TITLE,
    alias: 'investment_date',
    hidden: false,
    readOnly: true,
    dataSourceKey: 'shares_distribution_ledger_{{columnLegend}}',
    defaultValue: null,
    isRequired: true,
    dbType: 'dbDate',
    gridType: 'gridDate',
    disableWhenLedgerOpen: true,
    linkedCellSymbols: [`@${FOC.SHARES_LEDGER_ROW_NUMBER}`],
  },
  // 05.  Invested Capital
  {
    value: SUMMARY.INVESTMENT_AMOUNT_TITLE,
    alias: 'invested_capital',
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    forceComponent: true,
    dataSourceKey: 'shares_distribution_ledger_{{columnLegend}}',
    disableEvents: true,
    hasTotal: true,
    defaultValue: 0,
    dbType: 'string',
    disableWhenLedgerOpen: true,
    linkedCellSymbols: [`@${FOC.SHARES_LEDGER_ROW_NUMBER}`],
    validateWhenReadOnly: true,
  },
  // 06.  Loan Value
  {
    value: SUMMARY.LOAN_VALUE_TITLE,
    alias: 'loan_value',
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    forceComponent: true,
    dataSourceKey: 'shares_distribution_ledger_{{columnLegend}}',
    disableEvents: true,
    hasTotal: true,
    defaultValue: 0,
    dbType: 'string',
    disableWhenLedgerOpen: true,
    gridType: 'number',
    linkedCellSymbols: [`@${FOC.SHARES_LEDGER_ROW_NUMBER}`],
  },
  // 07.  Shares
  {
    value: SUMMARY.SHARES_OWNED_TITLE,
    alias: 'shares',
    hidden: false,
    readOnly: true,
    format: sharesFormat,
    forceComponent: true,
    dataSourceKey: 'shares_distribution_ledger_{{columnLegend}}',
    disableEvents: true,
    hasTotal: true,
    defaultValue: 0,
    dbType: 'string',
    disableWhenLedgerOpen: true,
    linkedCellSymbols: [`@${FOC.SHARES_LEDGER_ROW_NUMBER}`],
    validateWhenReadOnly: true,
  },
  // 08.  Shares (as Converted)
  {
    value: 'Shares (as Converted)',
    alias: 'shares_converted',
    hidden: false,
    readOnly: true,
    format: largeDecimalFormat,
    expr: `={{columnLegend}}${FOC.SHARES_ROW_NUMBER} * CAPTABLE("${CTC.CONVERSION_RATE_TITLE}", {{columnLegend}}${FOC.SECURITY_ROW_NUMBER}) + ({{columnLegend}}${FOC.SHARES_ROW_NUMBER} / CAPTABLE("${CTC.SHARES_OUTSTANDING_TITLE}", {{columnLegend}}${FOC.SECURITY_ROW_NUMBER}) * CAPTABLE("${CTC.DIVIDEND_PAYMENT_TYPE_TITLE}", {{columnLegend}}${FOC.SECURITY_ROW_NUMBER}))`,
    hasTotal: true,
    gridType: 'number',
    dbType: 'string',
    defaultValue: 0,
  },
  // 09.  Fully Diluted Ownership
  {
    value: SUMMARY.FULLY_DILUTED_PERCENT_TITLE,
    alias: 'diluted_own_percent',
    hidden: false,
    readOnly: true,
    format: fullyDilutedOwnershipFormat,
    expr: `=IF(CAPTABLETOTAL("${CTC.TOTAL_SHARES_FULLY_DILUTED_TITLE}") != 0, {{columnLegend}}7 / CAPTABLETOTAL("${CTC.TOTAL_SHARES_FULLY_DILUTED_TITLE}"), 0)`,
    hasTotal: true,
    gridType: 'percentage',
    dbType: 'string',
    defaultValue: 0,
    className: 'row-label-bold',
  },
  // 10.  Initial Liquidation Preference
  {
    value: 'Initial Liquidation Preference',
    alias: 'initial_liquidation_pref',
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    expr: `={{columnLegend}}${FOC.SHARES_ROW_NUMBER} * CAPTABLE("${CTC.ISSUE_PRICE_TITLE}", {{columnLegend}}${FOC.SECURITY_ROW_NUMBER}) * CAPTABLE("${CTC.LIQUIDATION_PREFERENCE_TITLE}", {{columnLegend}}${FOC.SECURITY_ROW_NUMBER})`,
    hasTotal: true,
    gridType: 'number',
    dbType: 'string',
    defaultValue: 0,
    className: 'row-label-bold',
  },
  // 11.  Liquidation Preference Plus Accrued Dividends
  {
    value: hasDividends ? 'Liquidation Preference plus Accrued Dividends' : 'Liquidation Preference',
    alias: 'liquidation_pref_dividends',
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    expr: `={{totalSharesOutstanding}}*CAPTABLE("${CTC.ISSUE_PRICE_TITLE}", {{columnLegend}}${FOC.SECURITY_ROW_NUMBER})*CAPTABLE("${CTC.LIQUIDATION_PREFERENCE_TITLE}", {{columnLegend}}${FOC.SECURITY_ROW_NUMBER})+{{cumulativeDividends}}`,
    hasTotal: true,
    gridType: 'number',
    dbType: 'string',
    defaultValue: 0,
  },
  // 12.  Cash Distributions
  {
    value: 'Cash Distributions',
    alias: 'cash_distribution',
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    disableEvents: true,
    hasTotal: true,
    defaultValue: 0,
    linkedCellSymbols: [`@${FOC.CASH_DISTRIBUTION_LEDGER_ROW_NUMBER}`],
  },
  // 13.  Proceeds from sold shares
  {
    value: 'Proceeds from Sold Shares',
    alias: FOC.PROCEEDS_FROM_SOLD_SHARES_ALIAS,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    forceComponent: true,
    dataSourceKey: 'shares_distribution_ledger_{{columnLegend}}',
    disableEvents: true,
    hasTotal: true,
    defaultValue: 0,
    linkedCellSymbols: [`@${FOC.SHARES_LEDGER_ROW_NUMBER}`],
  },
  // 14. shares_distribution_ledger
  {
    value: 'Shares Distribution Ledger',
    alias: FOC.SHARES_LEDGER_ALIAS,
    isVisible: false,
  },
  // 15. cash_distribution_ledger
  {
    value: 'Cash Distribution Ledger',
    alias: FOC.CASH_DISTRIBUTION_LEDGER_ALIAS,
    isVisible: false,
  },
  // 16.  Custom Security Name
  {
    value: 'Custom Security Name',
    alias: FOC.CUSTOM_SECURITY_NAME_ALIAS,
    gridType: 'string',
    dbType: 'string',
    isVisible: false,
    allowEmptyValues: true,
  },
];

export default rowConfig;
