import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PropTypes from 'prop-types';
import theme from 'theme';

const CircularArrowUpLoader = ({ arrowUpwardIconClass }) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CircularProgress
      size={'5.5rem'}
      style={{
        backgroundColor: theme.palette.gray[100],
        borderRadius: '50%',
        color: theme.palette.secondary[600],
      }}
      thickness={2}
    />
    <Box
      style={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <ArrowUpwardIcon className={arrowUpwardIconClass} />
    </Box>
  </Box>
);

CircularArrowUpLoader.propTypes = {
  arrowUpwardIconClass: PropTypes.string,
};

export default CircularArrowUpLoader;
