import React, { FC, useEffect, useState } from 'react';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import useTrendsDetailData from '../../hooks/useTrendsDetailData';
import { ITrendsDetailTableProps } from '../../types';

const TrendsDetailTable: FC<ITrendsDetailTableProps> = ({ data, isLoading, format, formatDispatch }) => {
  const { spreadsheet: trendsDetailSheet } = useTrendsDetailData({ data, format });

  const [spreadsheets, setSpreadsheets] = useState([trendsDetailSheet]);

  useEffect(() => {
    if (trendsDetailSheet?.yLength > 0) {
      setSpreadsheets([trendsDetailSheet]);
    }
  }, [trendsDetailSheet]);

  const { onChange, workbook } = useWorkbook(spreadsheets);

  const trendsDetailSheetProps = {
    ...trendsDetailSheet,
    sheet: trendsDetailSheet,
    workbook,
    onChange,
    page: 'Valuation Trends',
    format,
    formatDispatch,
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return !isLoading && trendsDetailSheet?.data ? <ScalarSpreadsheet {...trendsDetailSheetProps} /> : <GridSkeleton />;
};

export default TrendsDetailTable;
