const defaultTitleParser = ({ rows }) => {
  let titleCells = [];

  const columnLegend = 'A';
  rows.forEach((row, rowIndex) => {
    const rowNumber = rowIndex + 1;
    const currentKey = `${columnLegend + rowNumber}`;
    const key = `TITLE_${columnLegend + rowNumber}`;

    const cell = {
      ...row,
      key: currentKey,
      ignoreValidations: row.ignoreValidations ?? true,
    };

    titleCells = {
      ...titleCells,
      [key]: cell,
    };
  });

  return titleCells;
};

export default defaultTitleParser;
