/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ChildCompanyQuestion = {
  readonly id?: number;
  company?: number | null;
  name: string;
  is_deleted?: boolean | null;
  readonly deleted_at?: string | null;
  question_type?: ChildCompanyQuestion.question_type;
  description?: string | null;
  parent_question?: number | null;
  valuation_type?: number | null;
  base_question?: number | null;
  options?: Array<number>;
};

export namespace ChildCompanyQuestion {
  export enum question_type {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }
}
