import BaseServiceRetryWrapper from './baseRetry';
import { VALUATIONS } from '../common/endpoints';

class ValuationService extends BaseServiceRetryWrapper {
  getValuation = allocationId => this.get(VALUATIONS.valuationByAllocationId(allocationId));

  createNewVersion = valuation => this.put(VALUATIONS.createNewVersion(valuation.allocation_id), valuation);

  getValuationApproaches = (measurementDateId, allocationVersionId) =>
    this.get(VALUATIONS.getValuationApproaches(measurementDateId, allocationVersionId));

  updateValuation = valuation => this.put(VALUATIONS.valuationByAllocationId(valuation.allocation_id), valuation);

  getGpc = (ticker, md, currency, calendarYears) => this.get(VALUATIONS.getGPC(ticker, md, currency, calendarYears));

  getPublicCompList = (tickerList, md, currency, calendarYears, customDate) =>
    this.get(VALUATIONS.getPublicCompList(md, currency, tickerList.join(','), calendarYears, customDate));

  getEffectiveTaxRate = companyMeasurementDate => this.get(VALUATIONS.getEffectiveTaxRate(companyMeasurementDate));

  saveEffectiveTaxRate = data => {
    if (data.id) {
      return this.put(VALUATIONS.updateEffectiveTaxRate(data.id), data);
    }
    return this.post(VALUATIONS.createEffectiveTaxRate, data);
  };

  getBenchmarkMultiples = allocationId => this.get(VALUATIONS.getBenchmarkMultiples(allocationId));

  getFirmCompGroups = firmId => this.get(VALUATIONS.getFirmCompGroups(firmId));

  getTickerByKeyword = keyword => this.get(VALUATIONS.getTickerByKeyword(keyword));

  getTransaction = transactionId => this.get(VALUATIONS.getTransaction(transactionId));

  setReadyForAudit = (valuationId, data) => this.put(VALUATIONS.setReadyForAudit(valuationId), data);
}

export default ValuationService;
