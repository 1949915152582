export const REVENUE_TOTAL_TITLE = 'Total Revenues';
export const REVENUE_TOTAL_ALIAS = 'revenue_total';
export const REVENUE_TOTAL_ROW_NUMBER = 2;

export const REVENUE_GROWTH_TITLE = 'Revenue Growth Rate';
export const REVENUE_GROWTH_ALIAS = 'revenue_growth';
export const REVENUE_GROWTH_ROW_NUMBER = 3;

export const EBITDA_TOTAL_TITLE = 'EBITDA';
export const EBITDA_TOTAL_ALIAS = 'ebitda_total';
export const EBITDA_TOTAL_ROW_NUMBER = 4;

export const EBITDA_MARGIN_TITLE = 'EBITDA Margin';
export const EBITDA_MARGIN_ALIAS = 'ebitda_margin';
export const EBITDA_MARGIN_ROW_NUMBER = 5;

export const EBIT_TOTAL_TITLE = 'EBIT';
export const EBIT_TOTAL_ALIAS = 'ebit_total';
export const EBIT_TOTAL_ROW_NUMBER = 6;

export const EBIT_CASH_TAXES_TITLE = 'Less: Cash Taxes At Rate Of';
export const EBIT_CASH_TAXES_ALIAS = 'ebit_cash_taxes';
export const EBIT_CASH_TAXES_ROW_NUMBER = 7;

export const EBIT_DEPRECIATION_EXPENSES_TOTAL_TITLE = 'Depreciation Expense';
export const EBIT_DEPRECIATION_EXPENSES_TOTAL_ALIAS = 'ebit_depreciation_expenses_total';
export const EBIT_DEPRECIATION_EXPENSES_TOTAL_ROW_NUMBER = 8;

export const EBIT_DEPRECIATION_EXPENSES_PERCENT_TITLE = 'Depreciation As % Of Sales';
export const EBIT_DEPRECIATION_EXPENSES_PERCENT_ALIAS = 'ebit_depreciation_expenses_percent';
export const EBIT_DEPRECIATION_EXPENSES_PERCENT_ROW_NUMBER = 9;

export const EBIT_AMORTIZATION_EXPENSES_TOTAL_TITLE = 'Amortization Expense';
export const EBIT_AMORTIZATION_EXPENSES_TOTAL_ALIAS = 'ebit_amortization_expenses_total';
export const EBIT_AMORTIZATION_EXPENSES_TOTAL_ROW_NUMBER = 10;

export const EBIT_AMORTIZATION_EXPENSES_PERCENT_TITLE = 'Amortization As % Of Sales';
export const EBIT_AMORTIZATION_EXPENSES_PERCENT_ALIAS = 'ebit_amortization_expenses_percent';
export const EBIT_AMORTIZATION_EXPENSES_PERCENT_ROW_NUMBER = 11;

export const PLUS_DEPRECATION_AMORTIZATION_EXPENSE_TITLE = 'Plus: Depreciation & Amortization Expense';
export const PLUS_DEPRECATION_AMORTIZATION_EXPENSE_ALIAS = 'ebit_depreciation_amortization_total';
export const PLUS_DEPRECATION_AMORTIZATION_EXPENSE_ROW_NUMBER = 12;

export const EBIT_CAPITAL_EXPENSES_TOTAL_TITLE = 'Capital Expenditures';
export const EBIT_CAPITAL_EXPENSES_TOTAL_ALIAS = 'ebit_capital_expenses_total';
export const EBIT_CAPITAL_EXPENSES_TOTAL_ROW_NUMBER = 13;

export const EBIT_CAPITAL_EXPENSES_PERCENT_TITLE = 'Capital Expenditures As % Of Sales';
export const EBIT_CAPITAL_EXPENSES_PERCENT_ALIAS = 'ebit_capital_expenses_percent';
export const EBIT_CAPITAL_EXPENSES_PERCENT_ROW_NUMBER = 14;

export const EBIT_ADDITIONS_TO_INTANGIBLES_TOTAL_TITLE = 'Additions to Intangibles';
export const EBIT_ADDITIONS_TO_INTANGIBLES_TOTAL_ALIAS = 'ebit_additions_to_intangibles_total';
export const EBIT_ADDITIONS_TO_INTANGIBLES_TOTAL_ROW_NUMBER = 15;

export const EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_TITLE = 'Additions to Intangibles As % Of Sales';
export const EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_ALIAS = 'ebit_additions_to_intangibles_percent';
export const EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_ROW_NUMBER = 16;

export const LESS_CAPITAL_EXPENDITURES_ADDITIONS_TITLE = 'Less: Capital Expenditures & Additions To Intangibles';
export const LESS_CAPITAL_EXPENDITURES_ADDITIONS_ALIAS = 'ebit_capital_additions_total';
export const LESS_CAPITAL_EXPENDITURES_ADDITIONS_ROW_NUMBER = 17;

export const EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_TITLE = 'Cash-Free Net Working Capital';
export const EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_ALIAS = 'ebit_cash_free_net_working_capital_total';
export const EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_ROW_NUMBER = 18;

export const EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_TITLE = 'Cash-Free NWC as % Of Sales';
export const EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ALIAS = 'ebit_cash_free_net_working_capital_percent';
export const EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ROW_NUMBER = 19;

export const INCREASE_DECREASE_CASH_FREE_NET_WORKING_CAPITAL_TITLE
  = '(Increase) / Decrease In Cash-Free Net Working Capital';
export const INCREASE_DECREASE_CASH_FREE_NET_WORKING_CAPITAL_ALIAS = 'ebit_increase_cash_free_net_working_capital';
export const INCREASE_DECREASE_CASH_FREE_NET_WORKING_CAPITAL_ROW_NUMBER = 20;

export const NET_CASH_FLOWS_TITLE = 'Net Cash Flows To Invested Capital';
export const NET_CASH_FLOWS_ALIAS = 'net_cash_flows';
export const NET_CASH_FLOWS_ROW_NUMBER = 21;

export const CASH_FLOW_GROWTH_TITLE = 'Cash Flow Growth Rate';
export const CASH_FLOW_GROWTH_ALIAS = 'cash_flow_growth';
export const CASH_FLOW_GROWTH_ROW_NUMBER = 22;

export const CASH_FLOW_REMAINING_TITLE = 'Cash Flows Remaining Through End Of Year';
export const CASH_FLOW_REMAINING_ALIAS = 'cash_flow_remaining';
export const CASH_FLOW_REMAINING_ROW_NUMBER = 23;

export const DISCOUNT_PERIODS_TITLE = 'Discount Periods (Mid-Year Convention)';
export const DISCOUNT_PERIODS_ALIAS = 'discount_periods';
export const DISCOUNT_PERIODS_ROW_NUMBER = 24;

export const DISCOUNT_FACTOR_TITLE = 'Discount Factor - Based On WACC Of';
export const DISCOUNT_FACTOR_ALIAS = 'discount_factor';
export const DISCOUNT_FACTOR_ROW_NUMBER = 25;

export const TOTAL_CASH_FLOWS_TITLE = 'Discounted Cash Flows to Invested Capital';
export const TOTAL_CASH_FLOWS_ALIAS = 'total_cash_flows';
export const TOTAL_CASH_FLOWS_ROW_NUMBER = 26;

export const MAX_VALUATION_NUMBER_DIGITS = 15;

export const ROW_WITH_PAIRS = [
  REVENUE_TOTAL_ALIAS,
  REVENUE_GROWTH_ALIAS,
  EBIT_DEPRECIATION_EXPENSES_TOTAL_ALIAS,
  EBIT_DEPRECIATION_EXPENSES_PERCENT_ALIAS,
  EBIT_AMORTIZATION_EXPENSES_TOTAL_ALIAS,
  EBIT_AMORTIZATION_EXPENSES_PERCENT_ALIAS,
  EBIT_CAPITAL_EXPENSES_TOTAL_ALIAS,
  EBIT_CAPITAL_EXPENSES_PERCENT_ALIAS,
  EBIT_ADDITIONS_TO_INTANGIBLES_TOTAL_ALIAS,
  EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_ALIAS,
  EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_ALIAS,
  EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ALIAS,
];

export const percentagesAlias = [
  EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_ALIAS,
  EBIT_AMORTIZATION_EXPENSES_PERCENT_ALIAS,
  EBIT_CAPITAL_EXPENSES_PERCENT_ALIAS,
  EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ALIAS,
  EBIT_DEPRECIATION_EXPENSES_PERCENT_ALIAS,
];

export const TVTREM_KEY = 'dcfTVTREM';
export const TVTRM_KEY = 'dcfTVTRM';
export const TVTEM_KEY = 'dcfTVTEM';
export const BENCHMARK_PERCENTILE_A = 'benchmark.percentile_selection_a';
export const BENCHMARK_PERCENTILE_B = 'benchmark.percentile_selection_b';
