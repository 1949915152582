import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { sendPageView } from 'scalarAnalytics/googleAnalytics';

const useAnalytics = () => {
  const { pathname, search } = useLocation();
  const currentPath = useMemo(() => pathname + search, [pathname, search]);
  const slug = useMemo(() => pathname.substring(pathname.lastIndexOf('/') + 1), [pathname]);

  useEffect(() => {
    sendPageView(currentPath, slug);
  }, [currentPath, slug]);
};

export default useAnalytics;
