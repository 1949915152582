import React from 'react';
import { Box, Dialog, IconButton, Slide, Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RequestsContent from 'pages/Firms/Requests/components/RequestContent';
import useStyles from './styles';
import { InitialRequestProcessModalProps } from './types';

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => {
  const { children, ...otherProps } = props;
  const validChildren = React.isValidElement(children) ? children : undefined;

  return (
    <Slide direction="up" ref={ref} {...otherProps}>
      {validChildren}
    </Slide>
  );
});

const InitialRequestProcessModal: React.FC<InitialRequestProcessModalProps> = ({ isOpen, setIsOpen, ...props }) => {
  const classes = useStyles();

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
      <Box padding="5rem">
        <Box display="flex" alignItems="center" marginBottom={2}>
          <IconButton className={classes.iconButton} onClick={handleClose} aria-label="close">
            <KeyboardArrowLeftIcon className={classes.arrowIcon} />
          </IconButton>
          <Box>
            <Typography className={classes.typographyTitle}>INFORMATION REQUEST SETUP</Typography>
            <Typography className={classes.typographySubtitle}>
              This page outlines the default document requests and questions sent to the company during the initial
              request process.
            </Typography>
            <Typography className={classes.typographyDescription}>
              You can customize requests for specific companies using the edit button.
            </Typography>
          </Box>
        </Box>
        <RequestsContent {...props} />
      </Box>
    </Dialog>
  );
};

export default InitialRequestProcessModal;
