import slashFormat from 'utillities/slashFormat';

const generatePurchaseDateOptions = acquisitions =>
  acquisitions
    .filter(acq => !!acq.purchase_date)
    .map(acquisition => ({
      label: slashFormat(acquisition.purchase_date),
      value: acquisition.acquisition_ref,
    }));

export default generatePurchaseDateOptions;
