import { useCallback, useState } from 'react';
import { SELECTION_A, SELECTION_B } from 'common/constants/valuations';
import {
  PERCENTILE_SELECTION_A_ALIAS,
  PERCENTILE_SELECTION_B_ALIAS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { transactionIdValidator } from 'pages/ValuationsAllocation/util';
import { useQueryTransaction } from 'services/hooks';

const useCellOptions = (onChange, specificApproach) => {
  const [cellOptions, setCellOptions] = useState({
    percentile_selection_a: specificApproach.percentile_selection_a,
    [SELECTION_A]: specificApproach.percentile_selection_a,
    percentile_selection_b: specificApproach.percentile_selection_b,
    [SELECTION_B]: specificApproach.percentile_selection_b,
  });
  const { queryTransaction, isLoading } = useQueryTransaction();
  const handleOnChange = useCallback(
    async (cell, expr) => {
      const { alias, isGptRow, isEditableTitleCell } = cell;
      if ([PERCENTILE_SELECTION_A_ALIAS, PERCENTILE_SELECTION_B_ALIAS].includes(alias)) {
        setCellOptions({
          ...cellOptions,
          [cell.alias]: expr,
        });
      }
      if (isGptRow && isEditableTitleCell && transactionIdValidator(expr)) {
        const transactionData = await queryTransaction(expr);
        return transactionData;
      }
      onChange(cell, expr);
    },
    [cellOptions, onChange, queryTransaction]
  );
  return [cellOptions, handleOnChange, isLoading];
};

export default useCellOptions;
