import { BREAKPOINT_PRO_RATA } from 'common/constants/cap-table';

const replaceProRata = (series, securities) => {
  const currentOwnPercentMap = new Map(securities.map(security => [security.id, security.current_own_percent]));
  return series.map(serie => ({
    ...serie,
    value: Number(serie.value === BREAKPOINT_PRO_RATA ? currentOwnPercentMap.get(serie.id) : serie.value),
  }));
};

export default replaceProRata;
