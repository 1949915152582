import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Logo } from 'components';

const CommonTitle = ({ bold, text }) => (
  <Typography
    style={{
      fontWeight: bold ? 'bold' : 'normal',
      fontSize: '3rem',
      lineHeight: '3.5rem',
    }}>
    {text}
  </Typography>
);

CommonTitle.propTypes = {
  bold: PropTypes.bool,
  text: PropTypes.string,
};

const TitleAndDescription = ({ processTypeObject }) => (
  <Box>
    <Logo lightBlue variation="onlyWord" />
    <CommonTitle bold={true} text={processTypeObject.titleBold} />
    <CommonTitle bold={false} text={processTypeObject.title} />
    <Typography
      paragraph
      style={{
        marginTop: '1rem',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        maxWidth: '28rem',
      }}>
      {processTypeObject.description}
    </Typography>
  </Box>
);

TitleAndDescription.propTypes = {
  processTypeObject: PropTypes.object,
};

export default TitleAndDescription;
