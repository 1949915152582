import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IconButton, Menu, MenuItem, Popper } from '@material-ui/core';
import { KeyboardArrowDown, MoreHoriz } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { isEmpty, isNil, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { SORT_DESC } from 'pages/Valuations/util/constants';
import { useResponse } from 'services/hooks';
import theme from 'theme';
import { getStringValue } from 'utillities';
import { getColumnIndex, getColumnLetter } from 'utillities/alphabet-utilities';
import AddMultipleMenuItem from './AddMultipleMenuItem';
import { SortAscIcon, SortDescIcon } from './SortIcons';
import FeaturedSpreadsheetContext from '../context/FeaturedSpreadsheetContext';
import { copyToClipboard, getCellRef, getValuesToCopy, hasCellColSpan } from '../utilities/utilities';

const useStyles = makeStyles(() => ({
  columnIndicator: {
    padding: 0,
    position: 'absolute',
    top: '0',
    backgroundColor: theme.palette.primary[500],
    borderRadius: '10px 10px 0px 0px',
    height: '12px',
    textAlign: 'center',
  },
  menuButton: {
    maxWidth: '12px',
    minWidth: '12px',
    color: theme.palette.white,
    padding: '0',
    marginTop: '-14px',
    height: '12px',
  },
  buttonIcon: {
    fill: `${theme.palette.white} !important`,
    width: 'auto !important',
    height: '15px !important',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  cursorMove: {
    cursor: 'move',
  },
  deleteOption: {
    color: theme.palette.pink,
  },
}));

const ColumnIndicator = ({ tableRef, hiddenColumns }) => {
  const classes = useStyles();
  const [showIndicator, setShowIndicator] = useState(false);
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [cell, setCell] = useState(null);
  const [multipleValue, setMultipleValue] = useState(0);
  const {
    addMultipleColumns,
    allowAddMultipleColumns,
    allowCloneColumn,
    allowConfirmAndDeleteColumn,
    allowCopyColumn,
    allowDeleteColumn,
    allowDeleteOnlySpecificColumn,
    allowReorderColumns,
    allowSortColumn,
    cells,
    cloneColumn,
    columns,
    displayColumnIndicator,
    handleDeleteColumn,
    selectedCell,
    setSelectedCell,
    sortColumnFn,
    sortedColumn,
    workbook,
  } = useContext(FeaturedSpreadsheetContext);

  const { infoNotification, errorNotification } = useResponse();

  useEffect(() => {
    if (!selectedCell || !displayColumnIndicator) return;
    const { start, end, cellInfo } = selectedCell;

    setCell(cellInfo);
    const { j: startColumn, i: startRow } = start;
    const { j: endColumn } = end;
    // This condition will hide the column indicator when the user has selected one or more columns.
    if (startColumn === endColumn) {
      const columnLetter = getColumnLetter(startColumn);
      const cellKey = cellInfo.key || `${columnLetter}${startRow}`;
      const cellRef = getCellRef(tableRef, cellKey);
      const hasColSpan = hasCellColSpan(cells, cellKey, cellRef);
      if (!hasColSpan && cellRef) {
        setIndicatorPosition({
          left: cellRef.offsetLeft,
          width: cellRef.offsetWidth,
        });
        setShowIndicator(true);
      } else {
        setShowIndicator(false);
      }
    }
  }, [selectedCell, displayColumnIndicator, cells, tableRef, hiddenColumns]);

  const handleOnMoreOptionsClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  // This uses a regular expression to replace all digits with an empty string
  const getLetterFromKey = key => key?.replace(/\d/g, '');

  const onCloseDropdown = () => setAnchorEl(null);

  const handleOnCopyClick = useCallback(() => {
    onCloseDropdown();
    if (!cell) return;
    const { columnLegend, key } = cell;
    const columnLetter = columnLegend || getLetterFromKey(key);
    if (columnLetter) {
      const valuesToCopy = getValuesToCopy(cells, columnLetter, true);
      copyToClipboard(valuesToCopy, infoNotification, errorNotification);
    }
  }, [cell, cells, infoNotification, errorNotification]);

  const columnIndex = useMemo(() => {
    if (!cell) return null;
    const { y: columnPos, columnLegend, key } = cell;
    const cellColumnLegend = columnLegend || getLetterFromKey(key);
    const index = columnPos || getColumnIndex(cellColumnLegend);
    return index;
  }, [cell]);

  const showDeleteOption = useMemo(() => {
    if (
      (allowDeleteColumn || allowDeleteOnlySpecificColumn)
      && columns
      && !isNil(columnIndex)
      && columnIndex < columns.length
    ) {
      const sortedColumns = sortBy(columns, ['order']);
      const column = sortedColumns[columnIndex];
      const isLTMOrNTMColumnLegend = column?.isLTM || column?.isNTM;
      const isDeletable = !isLTMOrNTMColumnLegend && column && !('parentColumn' in column);
      const allowDelete = (allowDeleteColumn || allowConfirmAndDeleteColumn) && isDeletable;
      const allowDeleteCondition = allowDeleteOnlySpecificColumn
        ? column.isDeleteableColumn && allowDelete
        : allowDelete;
      return allowDeleteCondition;
    }
    return false;
  }, [columnIndex, allowConfirmAndDeleteColumn, allowDeleteOnlySpecificColumn, allowDeleteColumn, columns]);

  const hideIndicator = useCallback(() => {
    setSelectedCell(null);
    setIndicatorPosition(null);
    setShowIndicator(false);
  }, [setSelectedCell]);

  const handleOnDeleteClick = useCallback(() => {
    onCloseDropdown();
    if (cell) {
      const { columnId } = cell;
      const currentColumnId = columnId ?? columns[columnIndex].id ?? columnIndex;
      if (handleDeleteColumn && showDeleteOption) {
        // If handleDeleteColumn expects 3 arguments based on its signature, it comes from ScalarSpreadsheet
        // If so, we will pass columnIndex, columnId, wb
        if (handleDeleteColumn.length === 4 && !isNil(currentColumnId) && workbook) {
          handleDeleteColumn(columnIndex, currentColumnId, workbook, hideIndicator);
        }
        // If it expects 1 argument, it comes from FeaturedSpreadsheet
        // If so, we will pass columnIndex
        if (handleDeleteColumn.length === 2) {
          handleDeleteColumn(columnIndex, hideIndicator);
        }
      }
    }
  }, [cell, handleDeleteColumn, workbook, columnIndex, showDeleteOption, columns, hideIndicator]);

  const handleOnCloneClick = useCallback(() => {
    onCloseDropdown();
    if (cell && cloneColumn) {
      if (!isNil(columnIndex)) {
        cloneColumn(columnIndex);
      }
    }
  }, [cell, cloneColumn, columnIndex]);

  const handleOnAddMultipleClick = () => {
    onCloseDropdown();
    addMultipleColumns(multipleValue);
  };

  const currentColumn = columns?.[columnIndex];
  const field = currentColumn?.fields?.[0];
  const columnKey = field ? Object.values(field)?.[0] : null;
  const fieldIsString = !!currentColumn?.isStringColumn;

  const isSortableColumn = useMemo(
    () => !!allowSortColumn && !!columns?.[columnIndex]?.isSortableColumn,
    [allowSortColumn, columnIndex, columns]
  );

  const SortDescription = useMemo(() => {
    if (isEmpty(sortedColumn)) return SortDescIcon;

    if (sortedColumn && columnKey in sortedColumn) {
      return getStringValue(sortedColumn?.[columnKey]) === SORT_DESC ? SortAscIcon : SortDescIcon;
    }

    return SortDescIcon;
  }, [columnKey, sortedColumn]);

  const handleSortColumnClick = () => {
    onCloseDropdown();

    if (isSortableColumn && columnKey) sortColumnFn?.(columnKey, fieldIsString);
  };

  // With this function we will determine if do we have to show or not the dropdown menu on the column indicator
  const showMenuOptions = useMemo(
    () => allowCopyColumn || allowReorderColumns || showDeleteOption || isSortableColumn,
    [allowCopyColumn, allowReorderColumns, isSortableColumn, showDeleteOption]
  );

  return (
    <div
      id={`column-indicator-${(cell?.columnLegend ?? '') + 0}`}
      className={clsx(allowReorderColumns && 'column-rearrange', classes.columnIndicator, classes.cursorMove)}
      style={{ left: indicatorPosition?.left, width: indicatorPosition?.width }}>
      {showIndicator && allowReorderColumns && (
        <IconButton className={clsx(classes.menuButton, classes.cursorMove)}>
          <MoreHoriz className={classes.buttonIcon} fontSize="small" />
        </IconButton>
      )}
      {showIndicator && showMenuOptions && (
        <>
          <IconButton className={clsx(classes.menuButton, classes.cursorPointer)} onClick={handleOnMoreOptionsClick}>
            <KeyboardArrowDown className={classes.buttonIcon} fontSize="small" />
          </IconButton>
          <Popper open={open} anchorEl={anchorEl} id={open ? 'transitions-popper' : undefined}>
            <Menu
              className={classes.menuList}
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  minWidth: indicatorPosition?.width,
                },
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={onCloseDropdown}>
              {allowCopyColumn && (
                <MenuItem onClick={handleOnCopyClick} disableRipple>
                  COPY
                </MenuItem>
              )}
              {allowCloneColumn && (
                <MenuItem onClick={handleOnCloneClick} disableRipple>
                  CLONE
                </MenuItem>
              )}
              {allowAddMultipleColumns && addMultipleColumns && (
                <AddMultipleMenuItem
                  onAddMultiple={handleOnAddMultipleClick}
                  multipleValue={multipleValue}
                  setMultipleValue={setMultipleValue}
                />
              )}
              {showDeleteOption && (
                <MenuItem className={classes.deleteOption} onClick={handleOnDeleteClick}>
                  DELETE
                </MenuItem>
              )}

              {isSortableColumn && (
                <MenuItem onClick={handleSortColumnClick}>
                  <SortDescription />
                </MenuItem>
              )}
            </Menu>
          </Popper>
        </>
      )}
    </div>
  );
};

ColumnIndicator.propTypes = {
  tableRef: PropTypes.shape({}),
  hiddenColumns: PropTypes.shape({
    right: PropTypes.number,
    left: PropTypes.number,
  }),
};

export default ColumnIndicator;
