/* eslint-disable no-param-reassign */
import {
  ALLOCATION_BACKSOLVE_WEIGHTING_ALIAS,
  ALLOCATION_METHOD_ALIAS,
  ALLOCATION_METHOD_DUPLICATE_ERROR,
  TOTAL_WEIGHT_ERROR,
  WEIGHT_VALUE_ERROR,
} from './constants';
import { updateTooltipMessage } from './handleTooltipMessage';

const customValidations = ({ cell, parsedColumns, addFeedbackFromCell, removeFeedbackFromCell }) => {
  if (cell.sheet && cell.alias === ALLOCATION_METHOD_ALIAS) {
    const posibleCells = [`B${cell.rowNumber}`, `C${cell.rowNumber}`, `D${cell.rowNumber}`];
    const allocationMethodCells = Object.values(
      Object.fromEntries(Object.entries(parsedColumns).filter(([key]) => posibleCells.some(pc => pc === key)))
    );

    const duplicatedMethods = allocationMethodCells?.length
      ? allocationMethodCells.filter(
        amc => allocationMethodCells.filter(x => Number(x.value) === Number(amc.value))?.length > 1
      )
      : [];

    const validMethods = allocationMethodCells.filter(amc => !duplicatedMethods.some(x => x.key === amc.key));

    duplicatedMethods.forEach(({ key }) => {
      updateTooltipMessage({
        message: ALLOCATION_METHOD_DUPLICATE_ERROR,
        key,
        container: parsedColumns,
        currentCell: cell,
        addMessage: true,
      });
    });

    validMethods.forEach(({ key }) => {
      updateTooltipMessage({
        message: ALLOCATION_METHOD_DUPLICATE_ERROR,
        key,
        container: parsedColumns,
        currentCell: cell,
        addMessage: false,
      });
    });
  }

  if (cell.sheet && cell.alias === ALLOCATION_BACKSOLVE_WEIGHTING_ALIAS && !cell.readOnly) {
    const posibleCells = [`B${cell.rowNumber}`, `C${cell.rowNumber}`, `D${cell.rowNumber}`];
    const weightCells = Object.values(
      Object.fromEntries(Object.entries(parsedColumns).filter(([key]) => posibleCells.some(pc => pc === key)))
    );

    const invalidWeightCells = weightCells.filter(w => Number(w?.value ?? 0) === 0);
    const validWeightCells = weightCells.filter(w => Number(w?.value ?? 0) > 0);

    invalidWeightCells.forEach(c => {
      addFeedbackFromCell(c, WEIGHT_VALUE_ERROR);
    });
    validWeightCells.forEach(c => {
      removeFeedbackFromCell(c, WEIGHT_VALUE_ERROR);
    });

    const totalWeight = weightCells?.reduce((prev, current) => prev + Number(current.value), 0) ?? 0;

    const isValidTotalWeight = totalWeight === 1;

    if (isValidTotalWeight) {
      weightCells.forEach(c => {
        const keepsHavingError = c.tooltipMessages.filter(m => m !== TOTAL_WEIGHT_ERROR).length > 0;
        removeFeedbackFromCell(c, TOTAL_WEIGHT_ERROR, 'error', keepsHavingError);
      });
    } else {
      weightCells.forEach(c => {
        addFeedbackFromCell(c, TOTAL_WEIGHT_ERROR);
      });
    }
  }
};

export default customValidations;
