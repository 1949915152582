import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { MEASUREMENT_DATE_ACTION_ADD } from 'common/constants/measurement-date';
import { MeasurementDateAction, SelectedMeasurementDate } from 'common/types/measurementDate';
import { LayoutContextValues } from 'common/types/store';
import { LayoutContext } from 'context';
import { MeasurementDateModal } from 'pages/Funds/components';
import { useGetMeasurementDates, useSetFilters, useSetInitialMeasurementDate } from 'pages/Funds/hooks';
import { checkEmptyObject, extractDate, getObjectValue } from 'utillities';
import { UseFundMeasurementDateFilter } from './types';

const useFundMeasurementDateFilter: UseFundMeasurementDateFilter = params => {
  const { activeFund } = params;

  const [openMeasurementDatesDialog, setOpenMeasurementDatesDialog] = useState(false);
  const [mdHasBeenDeleted, setMdHasBeenDeleted] = useState(false);
  const [selectedMeasurementDate, setSelectedMeasurementDate] = useState<SelectedMeasurementDate>(null);
  const [measurementDateAction, setMeasurementDateAction]
    = useState<MeasurementDateAction>(MEASUREMENT_DATE_ACTION_ADD);

  const { setPageFilters } = useContext(LayoutContext) as unknown as LayoutContextValues;

  const location = useLocation();
  const parsedQuery = queryString.parse(location.search);
  const { date: dateQueryString } = parsedQuery;

  const { setFilters } = useSetFilters();

  const { measurementDates, getMeasurementDates } = useGetMeasurementDates({
    activeFund,
    setSelectedMeasurementDate,
    openMeasurementDatesDialog,
  });

  const openedMeasurementDate = useMemo(
    () => getObjectValue(measurementDates?.find(measurementDate => measurementDate.is_open)),
    [measurementDates]
  );

  const { setInitialMeasurementDate } = useSetInitialMeasurementDate({
    dateQueryString,
    measurementDates,
    openedMeasurementDate,
    setSelectedMeasurementDate,
  });

  const activeMeasurementDate = useMemo(() => {
    if (selectedMeasurementDate) {
      const selectedMD = getObjectValue(
        measurementDates?.find(measurementDate => measurementDate.date === selectedMeasurementDate.date)
      );

      const infoMD = checkEmptyObject(selectedMD) ?? getObjectValue(checkEmptyObject(openedMeasurementDate));

      return infoMD;
    }

    return {};
  }, [measurementDates, openedMeasurementDate, selectedMeasurementDate]);

  const openMeasurementDateModal = (action: MeasurementDateAction = MEASUREMENT_DATE_ACTION_ADD) => {
    let dialogAction = action;
    if (!action || typeof action !== 'string') {
      dialogAction = MEASUREMENT_DATE_ACTION_ADD;
    }
    setOpenMeasurementDatesDialog(true);
    setMeasurementDateAction(dialogAction);
  };

  const closeMeasurementDateModal = () => setOpenMeasurementDatesDialog(false);

  const MemoizedMeasurementDateModal = useMemo(
    () => (
      <MeasurementDateModal
        activeFund={activeFund}
        closeMeasurementDateModal={closeMeasurementDateModal}
        getMeasurementDates={getMeasurementDates}
        openMeasurementDatesDialog={openMeasurementDatesDialog}
        measurementDateAction={measurementDateAction}
        setMdHasBeenDeleted={setMdHasBeenDeleted}
        selectedFmdId={selectedMeasurementDate?.fmd_id}
      />
    ),
    [activeFund, measurementDateAction, getMeasurementDates, openMeasurementDatesDialog, selectedMeasurementDate]
  );

  // Extract date from date query string
  const dateSlugParam = useMemo(
    () =>
      extractDate({
        dateSlug: dateQueryString,
      }),
    [dateQueryString]
  );

  // Set Page Filters
  useEffect(() => {
    if (!isEmpty(measurementDates) && selectedMeasurementDate) {
      setFilters({
        measurementDates,
        openedMeasurementDate,
        openMeasurementDateModal,
        selectedMeasurementDate,
        setSelectedMeasurementDate,
      });
    } else if (isEmpty(measurementDates) && mdHasBeenDeleted) {
      setPageFilters?.(null);
    }

    return () => {
      setPageFilters?.(null);
    };
  }, [measurementDates, openedMeasurementDate, selectedMeasurementDate, setFilters, setPageFilters, mdHasBeenDeleted]);

  // Set initial Measurement Date if there is not date query string or if date query string remains the same
  useEffect(() => {
    if (!isEmpty(measurementDates) && (!selectedMeasurementDate || dateSlugParam === selectedMeasurementDate?.date)) {
      setInitialMeasurementDate();
    }
  }, [dateSlugParam, measurementDates, selectedMeasurementDate, setInitialMeasurementDate]);

  return {
    activeMeasurementDate,
    MeasurementDateModal: () => MemoizedMeasurementDateModal,
    measurementDates,
    openMeasurementDateModal,
  };
};

export default useFundMeasurementDateFilter;
