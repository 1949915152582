/* eslint-disable import/prefer-default-export */
import { FIRST_ROW_ROW_NUMBER, SECOND_ROW_ROW_NUMBER } from 'common/constants/financials';

export const getRowSpan = (rowNumber, column) => {
  if (rowNumber === FIRST_ROW_ROW_NUMBER) {
    if (column.isPrevYear) {
      return 3;
    }
  }
  if (rowNumber === SECOND_ROW_ROW_NUMBER) {
    if (column.isProjectedYear) {
      return 2;
    }
  }

  return 1;
};
