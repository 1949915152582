import { Dispatch, SetStateAction } from 'react';

const getHiddenCompanies = (hiddenBottom: number, setHiddenBottom: Dispatch<SetStateAction<number>>) => {
  const companiesListContainer = document.querySelector('#companiesList') as HTMLDivElement;
  const companiesListItems = document.querySelectorAll('#companiesList > a') as NodeListOf<HTMLAnchorElement>;

  let tmpHiddenBottom = 0;

  companiesListItems.forEach(firm => {
    if (firm.offsetTop >= companiesListContainer.offsetHeight + companiesListContainer.scrollTop) {
      tmpHiddenBottom += 1;
    }
  });

  if (hiddenBottom !== tmpHiddenBottom) setHiddenBottom(tmpHiddenBottom);

  setHiddenBottom(tmpHiddenBottom);
};

export default getHiddenCompanies;
