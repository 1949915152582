import queryString from 'query-string';

const setQueryString = ({
  history,
  isNewMD = false,
  paramName,
  paramValue,
  pathname,
  searchParams,
  shouldPushPath = false,
}) => {
  const parsedQuery = queryString.parse(searchParams);

  parsedQuery[paramName] = paramValue;

  // When we create a new MD, we remove the old URL param while the new one is in the process of being set and selected in the dropdown
  if (isNewMD) delete parsedQuery.version;

  const newPath = {
    pathname,
    search: queryString.stringify(parsedQuery),
  };

  if (shouldPushPath) return history.push(newPath);

  return history.replace(newPath);
};

export default setQueryString;
