import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { ALLOCATIONS_FINAL, ALLOCATIONS_PUBLISHED } from 'common/constants/allocations';
import { useStore } from 'common/store';
import { AllocationsStatuses } from 'common/types/allocation';
import { ParserParams } from 'common/types/scalarSpreadsheet';
import { UseStoreValues } from 'common/types/store';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import {
  SHEET_CONFIG_CONSTANTS,
  VALUATIONS_SUMMARY_MAKE_ALL_ALLOCATIONS_FINAL_LABEL,
  VALUATIONS_SUMMARY_MAKE_ALLOCATION_FINAL_LABEL,
} from 'pages/Funds/common/constants/valuationsSummary';
import {
  VALUATIONS_SUMMARY_MAKE_ALL_ALLOCATIONS_FINAL_MESSAGE,
  VALUATIONS_SUMMARY_MAKE_ALLOCATION_FINAL_MESSAGE,
} from 'pages/Funds/common/constants/valuationsSummary/valuationsSummary';
import { useAllocationStatus, useCellCustomClasses } from 'pages/Funds/hooks';
import {
  createColumns,
  customParser,
  rowConfig,
  ValuationsSummaryColumn,
} from 'pages/Funds/pages/ValuationsSummary/config';
import { sortFundAllocationsByCompany } from 'pages/Funds/utils';
import { getArrayValue, getNumberValue, getObjectValue, getStringValue } from 'utillities';
import { CompanyWithFundAllocationId, HandleAllocationsStatusesParams, UseValuationsSummarySheet } from './types';

const { VALUATIONS_SUMMARY_SPREADSHEET_TABLE_TERMS } = SHEET_CONFIG_CONSTANTS;

const useValuationsSummarySheet: UseValuationsSummarySheet = params => {
  const { format, fund, fundAllocations, measurementDate } = params;

  const [allocationsStatuses, setAllocationsStatuses] = useState<AllocationsStatuses>({});
  const [totalBreakevenEquityExitValue, setTotalBreakevenEquityExitValue] = useState(0);

  const [storeValue] = useStore() as unknown as UseStoreValues;
  const { firmInfo } = storeValue;

  const { cellCustomClasses } = useCellCustomClasses();

  const { updateAllocationsStatuses, confirmMakeAllocationsFinal } = useAllocationStatus({ setAllocationsStatuses });

  // Fund Allocations sorted by Company
  const sortedFundAllocations = useMemo(
    () => (fundAllocations ? sortFundAllocationsByCompany(getArrayValue(fundAllocations?.allocations)) : []),
    [fundAllocations]
  );

  const companies = useMemo(
    () =>
      getArrayValue(
        sortedFundAllocations?.map(fundAllocation => ({
          ...fundAllocation.allocation?.company_measurement_date?.company,
          fundAllocationId: getNumberValue(fundAllocation.id),
          exchangeRateToFundCurrency: fundAllocation.exchange_rate_to_fund_currency,
        })) as CompanyWithFundAllocationId[]
      ),
    [sortedFundAllocations]
  );

  const fundCurrency = useMemo(() => getStringValue(fund?.currency?.toString()), [fund]);

  const totalPercentageChange = useMemo(
    () => getNumberValue(fundAllocations?.total_percentage_change),
    [fundAllocations]
  );

  // Create row config for Valuations Summary Spreadsheet
  const rows = useMemo(
    () => rowConfig({ companies, firmInfo, fundCurrency, measurementDate }),
    [companies, firmInfo, fundCurrency, measurementDate]
  );

  // Create columns and Total Breakeven Equity Exit Value for Valuations Summary Spreadsheet
  const columns = useMemo(() => {
    if (sortedFundAllocations) {
      const createdColumns = createColumns({ allocationsStatuses, format, fundAllocations: sortedFundAllocations });

      // Get and set Total Breakeven Equity Exit Value
      const companiesTotalBreakevenEquityExitValue = getNumberValue(
        createdColumns?.[createdColumns.length - 1] as number
      );
      setTotalBreakevenEquityExitValue(companiesTotalBreakevenEquityExitValue);

      // Remove Total Breakeven Equity Exit Value from Columns
      createdColumns.pop();
      return createdColumns;
    }

    return [];
  }, [allocationsStatuses, format, sortedFundAllocations]);

  // Disable Make All Final button if all Allocations are already Final
  const shouldDisableMakeAllFinalButton = useMemo(() => {
    if (!isEmpty(allocationsStatuses)) {
      const shouldDisableButton = Object.values(allocationsStatuses).every(status => status === ALLOCATIONS_FINAL);

      return shouldDisableButton;
    }

    return false;
  }, [allocationsStatuses]);

  // Handle Allocations Statuses (Make Final)
  const handleAllocationsStatuses = useCallback(
    (handleParams: HandleAllocationsStatusesParams) => {
      const { allocationIds, allocationStatus, applyToAll = false } = handleParams;

      confirmMakeAllocationsFinal({
        dialogMessage: applyToAll
          ? VALUATIONS_SUMMARY_MAKE_ALL_ALLOCATIONS_FINAL_MESSAGE
          : VALUATIONS_SUMMARY_MAKE_ALLOCATION_FINAL_MESSAGE,
        // Handle click on Make Final button
        successCallback: () =>
          updateAllocationsStatuses({
            allocationsIds: applyToAll
              ? Object.entries(allocationsStatuses)
                .filter(([, allocationCurrentStatus]) => allocationCurrentStatus !== ALLOCATIONS_FINAL) // Only get Allocations that are not Final
                .map(([allocationStatusId]) => Number(allocationStatusId)) // Convert string keys to numbers
                .filter(allocationStatusId => !Number.isNaN(allocationStatusId)) // Filter out NaN values
              : allocationIds,
            allocationStatus,
          }),
        successLabel: applyToAll
          ? VALUATIONS_SUMMARY_MAKE_ALL_ALLOCATIONS_FINAL_LABEL
          : VALUATIONS_SUMMARY_MAKE_ALLOCATION_FINAL_LABEL,
      });
    },
    [allocationsStatuses, confirmMakeAllocationsFinal, updateAllocationsStatuses]
  );

  // Create parser with custom props
  const parser = useCallback(
    (props: ParserParams<ValuationsSummaryColumn>) =>
      customParser({
        ...props,
        cellCustomClasses,
        handleAllocationsStatuses,
        shouldDisableMakeAllFinalButton,
        totalBreakevenEquityExitValue,
        totalPercentageChange,
      }),
    [
      cellCustomClasses,
      handleAllocationsStatuses,
      shouldDisableMakeAllFinalButton,
      totalBreakevenEquityExitValue,
      totalPercentageChange,
    ]
  );

  // Create Spreadsheet config
  const spreadsheet = useMemo(() => {
    if (!isEmpty(columns)) {
      const spreadsheetConfig = new SpreadsheetConfig({
        allowConfirmAndDeleteColumn: false,
        alwaysDisplayLegend: false,
        colTitleRow: 0, // Add header row styles
        columns,
        currencyFormatter: true,
        format: undefined,
        hasColTitle: true, // Add styles to header row (colTitleRow)
        name: VALUATIONS_SUMMARY_SPREADSHEET_TABLE_TERMS.tableName,
        page: VALUATIONS_SUMMARY_SPREADSHEET_TABLE_TERMS.tableName as unknown as SpreadsheetConfig['page'],
        parser,
        rowConfig: rows,
        showPreviousColsDivider: false,
        showTitlesColumn: true,
        showToolbar: true,
        showTotalColumn: false,
        tableData: { columns },
        tableTerms: VALUATIONS_SUMMARY_SPREADSHEET_TABLE_TERMS as unknown as SpreadsheetConfig['tableTerms'],
        totalParser: undefined,
        unitsFormatter: true,
      });

      return spreadsheetConfig;
    }

    return null;
  }, [columns, parser, rows]);

  // Set Allocations Statuses
  useEffect(() => {
    if (!isEmpty(fundAllocations)) {
      const fundAllocationStatuses = getObjectValue(
        fundAllocations?.allocations?.reduce((accumulator, current) => {
          const { allocation } = current;
          const status = allocation?.is_final ? ALLOCATIONS_FINAL : ALLOCATIONS_PUBLISHED;

          return {
            ...accumulator,
            [getStringValue(allocation?.id?.toString())]: status,
          };
        }, {}) as AllocationsStatuses
      );

      setAllocationsStatuses(fundAllocationStatuses);
    }
  }, [fundAllocations]);

  return {
    spreadsheet,
  };
};

export default useValuationsSummarySheet;
