import React from 'react';

const AddCardIcon = () => (
  <svg width="23" height="20" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.66536 11.3333C1.2987 11.3333 0.98492 11.2029 0.724031 10.942C0.462698 10.6807 0.332031 10.3667 0.332031 9.99999V1.99999C0.332031 1.63332 0.462698 1.31955 0.724031 1.05866C0.98492 0.797323 1.2987 0.666656 1.66536 0.666656H12.332C12.6987 0.666656 13.0127 0.797323 13.274 1.05866C13.5349 1.31955 13.6654 1.63332 13.6654 1.99999V5.99999H1.66536V9.99999H7.66536C7.85425 9.99999 8.0127 10.064 8.1407 10.192C8.26825 10.3195 8.33203 10.4778 8.33203 10.6667C8.33203 10.8555 8.26825 11.0138 8.1407 11.1413C8.0127 11.2693 7.85425 11.3333 7.66536 11.3333H1.66536ZM1.66536 3.33332H12.332V1.99999H1.66536V3.33332ZM12.332 12.6667C12.1431 12.6667 11.9849 12.6027 11.8574 12.4747C11.7294 12.3471 11.6654 12.1889 11.6654 12V10.6667H10.332C10.1431 10.6667 9.98492 10.6027 9.85736 10.4747C9.72936 10.3471 9.66536 10.1889 9.66536 9.99999C9.66536 9.8111 9.72936 9.65288 9.85736 9.52532C9.98492 9.39732 10.1431 9.33332 10.332 9.33332H11.6654V7.99999C11.6654 7.8111 11.7294 7.65266 11.8574 7.52466C11.9849 7.3971 12.1431 7.33332 12.332 7.33332C12.5209 7.33332 12.6791 7.3971 12.8067 7.52466C12.9347 7.65266 12.9987 7.8111 12.9987 7.99999V9.33332H14.332C14.5209 9.33332 14.6791 9.39732 14.8067 9.52532C14.9347 9.65288 14.9987 9.8111 14.9987 9.99999C14.9987 10.1889 14.9347 10.3471 14.8067 10.4747C14.6791 10.6027 14.5209 10.6667 14.332 10.6667H12.9987V12C12.9987 12.1889 12.9347 12.3471 12.8067 12.4747C12.6791 12.6027 12.5209 12.6667 12.332 12.6667ZM1.66536 9.99999V1.99999V9.99999Z"
      fill="#037DE8"
    />
  </svg>
);

export default AddCardIcon;
