/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGlobal } from './types';

const initialState: IGlobal = {
  showLoadingProgressBar: false,
  currentCompany: undefined,
  currentMeasurementDateId: undefined,
  currentCompanyMeasurementDateId: undefined,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    updateGlobalState: (state, action: PayloadAction<IGlobal>) => ({ ...state, ...action.payload }),
    showLoadingProgressBar: state => ({
      ...state,
      showLoadingProgressBar: true,
    }),
    hideLoadingProgressBar: state => ({
      ...state,
      showLoadingProgressBar: false,
    }),
  },
});

export const { updateGlobalState, showLoadingProgressBar, hideLoadingProgressBar } = globalSlice.actions;

export default globalSlice.reducer;
