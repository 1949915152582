import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AnswerProgressItem } from 'pages/Funds/components/ProcessManagement/AnswerProgressItem';
import { TaskProgressModalContentBodyQuestionnaireProps } from './types';

const TaskProgressModalContentBodyQuestionnaire: FC<TaskProgressModalContentBodyQuestionnaireProps> = props => {
  const { taskProgressDetails } = props;

  return (
    <PerfectScrollbar style={{ overflowY: 'scroll', height: '30rem' }}>
      <Box margin="1rem 0">
        {taskProgressDetails?.progress?.map(task => (
          <AnswerProgressItem key={task.id} task={task} />
        ))}
      </Box>
    </PerfectScrollbar>
  );
};

export default TaskProgressModalContentBodyQuestionnaire;
