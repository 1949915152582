/* eslint-disable import/no-unresolved */
import { sortBy } from 'lodash';
import { parseDatabaseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import rowConfig from '../data/rowConfig';

const reverseParser = ({ cells, columns, fieldAttributes = {} }) => {
  const tmpColumns = columns || cells.columns;
  const sortedColumns = sortBy(tmpColumns, ['order']);
  const rows = rowConfig();
  const tableColumns = [];
  const alphabet = alphabetGenerator([], tmpColumns.length);

  sortedColumns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];
    const cell = cells[columnLegend + 1];
    const isCustomSecurityColumn = column.emptyColumn?.is_custom_security;

    let tmpColumn = column;
    let rowIndex = 1;

    tmpColumn.columnRef = cell ? cell.columnRef : '';
    tmpColumn.order = columnIndex;

    // Replace updated values
    rows.forEach(row => {
      const { alias, allowEmptyValues, gridType } = row;
      const key = columnLegend + rowIndex;
      const defaultValue = row.defaultValue || null;
      const type = row.dbType || null;
      const format = row.format || null;
      const value = cells[key] ? cells[key].value : defaultValue;

      let parsedValue = parseDatabaseValue({
        type,
        value,
        defaultValue,
        format,
        allowEmptyValues,
        gridType,
        decimalPlaces: fieldAttributes[row.alias]?.decimal_places,
      });
      if (alias === 'security' && isCustomSecurityColumn) {
        parsedValue = null;
      }

      const { invested_capital, shares } = tmpColumn;

      if (alias && cells[key]) {
        tmpColumn = {
          ...tmpColumn,
          invested_capital: Number(invested_capital),
          shares: Number(shares),
          [alias]: parsedValue,
        };
      }
      if (cell?.acquisitions) {
        tmpColumn.acquisitions = cell.acquisitions;
      }
      if (cell?.deleted_acquisitions) {
        tmpColumn.deleted_acquisitions = cell.deleted_acquisitions;
      }

      rowIndex += 1;
    });

    tableColumns.push(tmpColumn);
  });

  return tableColumns;
};

export default reverseParser;
