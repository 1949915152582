import { Cells } from 'common/types/scalarSpreadsheet';
import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { getNumberValue } from 'utillities';

const { VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE_KEY } = SHEET_ALIASES_CONSTANTS;

const getWeightedEquityValue = (cells: Cells) => {
  const weightedEquityValue = getNumberValue(
    cells?.[VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE_KEY]?.value as number
  );

  return weightedEquityValue;
};

export default getWeightedEquityValue;
