import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { useFormat } from 'common/hooks';
import { Alert } from 'components';
import { NO_STRING, YES_STRING } from 'pages/Valuations/approaches/backsolveApproach/constants';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { useGetPublicCompListBatchByDate } from 'services/hooks';
import useStyles from './RefreshGPCPrompt.styles';
import { useRefreshHelpersWithContext } from '../RefreshGPCOption/hooks';

const RefreshGPCPrompt = ({ approaches, setApproaches, doRefresh, setDoRefresh }) => {
  const classes = useStyles();
  const { financialsPeriods, valuation, measurementDate } = useContext(ValuationContext);
  const [isLoading, setIsLoading] = useState(false);

  // Always use the financials currency. That's what we do in the export and in the rollover. Using a differnet currency
  // can actually result in slightly different values
  const [format] = useFormat();
  const [publicCompsDataByDate, fetchGPCCompListsByDate] = useGetPublicCompListBatchByDate();
  const { getTickerListsByDate, getRefreshedApproaches } = useRefreshHelpersWithContext();

  const isGPCApproachOutdated = useCallback((lastRefreshDate, measurementDateValue) => {
    const today = new Date(new Date().toISOString().split('T')[0]);
    // If the measurement date is in the future, the approach is outdated if the last refresh date is before today
    if (today <= measurementDateValue) {
      return lastRefreshDate < today;
    }
    // Otherwise, the approach is outdated if the last refresh date is before the measurement date
    return lastRefreshDate < measurementDateValue;
  }, []);

  const outdatedApproaches = useMemo(() => {
    if (approaches && approaches.length > 0) {
      const measurementDateValue = new Date(measurementDate.date);
      const auxApproaches = approaches.filter(approach => {
        const isGPC = !isNil(approach.valuations_approach_gpc);

        const lastRefreshDate
          = isGPC && approach.valuations_approach_gpc.last_refresh_date
            ? new Date(approach.valuations_approach_gpc.last_refresh_date.split('T')[0])
            : null;

        return isGPC && !isNil(lastRefreshDate) && isGPCApproachOutdated(lastRefreshDate, measurementDateValue);
      });

      return auxApproaches;
    }
    return [];
  }, [approaches, measurementDate.date, isGPCApproachOutdated]);

  useEffect(
    () => {
      if (!isEmpty(publicCompsDataByDate)) {
        const refreshedApproaches = getRefreshedApproaches(publicCompsDataByDate, outdatedApproaches, approaches);
        setDoRefresh(false);
        setIsLoading(false);

        // This is for not lost the user introduced and not saved data when
        // the useEffect on src/pages/Valuations/Valuation.js try to syncronize
        // the valuation.valuations_approaches with the "approaches" state variable.
        // Actually we do the same when we add/remove an approach
        valuation.valuations_approaches = [...refreshedApproaches];

        // redraw approaches Accordion with the updated approach
        setApproaches([...refreshedApproaches]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [publicCompsDataByDate, getRefreshedApproaches, setApproaches, setDoRefresh, setIsLoading]
  );

  const refreshApproaches = useCallback(() => {
    setIsLoading(true);
    const tickerListsByDate = getTickerListsByDate(outdatedApproaches, approaches);
    fetchGPCCompListsByDate(tickerListsByDate, format.currency.code, financialsPeriods);
  }, [
    outdatedApproaches,
    approaches,
    getTickerListsByDate,
    fetchGPCCompListsByDate,
    format.currency.code,
    financialsPeriods,
    setIsLoading,
  ]);

  return (
    <Alert
      isAlertVisible={outdatedApproaches.length > 0 && doRefresh}
      severity="warning"
      variant="outlined"
      icon={<ErrorIcon fontSize="small" />}
      actions={[
        {
          label: NO_STRING,
          buttonProps: {
            size: 'small',
            className: classes.noButton,
            disabled: isLoading,
          },
          callback: () => {
            setDoRefresh(false);
          },
        },
        {
          label: YES_STRING,
          buttonProps: {
            variant: 'contained',
            size: 'small',
            className: classes.yesButton,
            disabled: isLoading,
          },
          callback: refreshApproaches,
        },
      ]}>
      The <b>Public Comps</b> need to be updated. The data needs to be refreshed so it is valid as of the current
      measurement date. Ready to refresh now?
    </Alert>
  );
};

RefreshGPCPrompt.propTypes = {
  approaches: PropTypes.array,
  setApproaches: PropTypes.func,
  doRefresh: PropTypes.bool,
  setDoRefresh: PropTypes.func,
};

export default RefreshGPCPrompt;
