import React, { FC, useState } from 'react';
import { Collapse as CollapseComponent, ListItem, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import useStyles from './styles';
import { ICollapse } from './types';

const Collapse: FC<ICollapse> = props => {
  const { title, children, unmountOnExit = false } = props;
  const [isOpen, setIsOpen] = useState(false);

  const classes = useStyles();

  const handleCollapse = () => {
    setIsOpen(previousState => !previousState);
  };

  return (
    <>
      <ListItem component="li" className={clsx(classes.item)} onClick={handleCollapse}>
        <ListItemText className={clsx(classes.itemText)} primary={title} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <CollapseComponent in={isOpen} timeout="auto" unmountOnExit={unmountOnExit} style={{ width: '100%' }}>
        {children}
      </CollapseComponent>
    </>
  );
};

export default Collapse;
