import { useEffect, useState } from 'react';
import { useStore } from 'common/store';
import { getCurrency } from 'utillities';

const useCurrency = page => {
  const [{
    companyInfo
  }] = useStore();

  const [currencyCode, setCurrencyCode] = useState();

  useEffect(() => {
    const currency = getCurrency({ companyInfo, page });
    if (currency) {
      setCurrencyCode(currency);
    }
  }, [companyInfo, page]);

  return currencyCode;
};

export default useCurrency;
