import React from 'react';
import GridRowLabel from 'components/Grid/GridRowLabel';

const HEADER_CONFIG = {
  readOnly: true,
  valueViewer: props => <GridRowLabel {...props} />,
};

export const TABLE_HEADER_CONFIG = {
  ...HEADER_CONFIG,
  className: 'table-header read-only--white padded subtitle-border-bottom',
};

export const ROW_HEADER_CONFIG = {
  ...HEADER_CONFIG,
  className: 'read-only--white padded',
};

export const BOLD_ROW_HEADER_CONFIG = {
  ...HEADER_CONFIG,
  className: 'read-only--white padded subtitle-bold backsolve-subtitle',
};
export const BOLD_BLUE_ROW_HEADER_CONFIG = {
  className: 'read-only--white padded subtitle-bold',
};
export const BACKSOLVE_ROW_HEADER_CONFIG = {
  ...HEADER_CONFIG,
  className: 'read-only--white padded backsolve-subtitle',
};
export const BACKSOLVE_ROW_SECURITY_CONFIG = {
  ...HEADER_CONFIG,
  className: 'read-only padded backsolve-subtitle',
};

export const ALLOCATION_METHOD_ALIAS = 'allocation_method';
export const VALUATION_APPROACH_GPC_ALIAS = 'valuation_approach_gpc';
export const TARGET_VALUE_ALIAS = 'target_value';
export const CAP_TABLE_ALIAS = 'cap_table';

export const METRICS = ['Market Cap', 'Enterprise Value', 'LTM Revenue', 'LTM EBITDA', 'NTM Revenue', 'NTM EBITDA'];

export const METRICS_KEYS_MAP = {
  'Market Cap': 'market_cap',
  'Enterprise Value': 'enterprise_value',
  'LTM Revenue': 'ltm_revenue',
  'LTM EBITDA': 'ltm_ebitda',
  'NTM Revenue': 'ntm_revenue',
  'NTM EBITDA': 'ntm_ebitda',
};

export const BACKSOLVE_TABLE_NAMES = {
  BACKSOLVE_SUMMARY: 'backsolveSummary',
  BACKSOLVE: 'backsolve',
  MARKET_ADJUSTMENT: 'marketAdjustment',
  OPM_INPUTS: 'opmInputs',
  SECURITIES: 'securities',
};

export const TABLE_HEADER_ROW = 0;
export const PRESENT_SHARE_VALUES_ROW_NUMBER = 5;

export const NO_STRING = 'NO';
export const YES_STRING = 'YES';
