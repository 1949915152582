import { largeCurrencyFormat, oneDecimalPercentFormat } from 'common/formats/formats';
import {
  DISCOUNT_FACTOR_CONFIG,
  HIGH_GROWTH_RATE_CONFIG,
  LONG_TERM_GROWTH_RATE_CONFIG,
  PV_OF_TERMINAL_VALUE_CONFIG,
  TERMINAL_VALUE_CONFIG,
  TOTAL_CASH_FLOWS_CONFIG,
  WACC_CONFIG,
  YEARS_OF_HIGH_GROWTH_CONFIG,
} from 'pages/Valuations/approaches/discountCashFlow/dcfTerminalValue/util/rowConfigConstants';
import { HALF_LIFE, TERMINAL_YEAR_H_MODEL } from 'pages/Valuations/util/constants';

export default columnsProps => [
  TERMINAL_VALUE_CONFIG(columnsProps.isDisabled),
  TOTAL_CASH_FLOWS_CONFIG(columnsProps.mainTableName),
  WACC_CONFIG(columnsProps.dcfWaccSheet),
  YEARS_OF_HIGH_GROWTH_CONFIG(columnsProps.isDisabled),
  {
    readOnly: true,
    value: 'Half-Life',
    gridType: 'number',
    format: oneDecimalPercentFormat,
    expr: '=@4/2',
    alias: HALF_LIFE,
  },
  LONG_TERM_GROWTH_RATE_CONFIG(columnsProps.isDisabled),
  HIGH_GROWTH_RATE_CONFIG(columnsProps.isDisabled),
  {
    readOnly: true,
    value: 'Terminal Year H-Model Value',
    gridType: 'number',
    format: largeCurrencyFormat,
    alias: TERMINAL_YEAR_H_MODEL,
    expr: '=(@2*(1+@6)+@2*@5*(@7-@6))/(@3-@6)',
  },
  DISCOUNT_FACTOR_CONFIG(columnsProps.mainTableName),
  PV_OF_TERMINAL_VALUE_CONFIG(8, 9),
];
