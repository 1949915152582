import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useStore } from 'common/store';
import UploadedFileData from './UploadedFileData';

const RequestedFileNameAndData = ({ requestedFile, companyDocuments }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [{ user }] = useStore();

  useEffect(() => {
    if (!isEmpty(requestedFile.files) && !isEmpty(companyDocuments)) {
      const requestedFilesIds = requestedFile.files.map(file => file.id);
      const tmpUploadedFiles = companyDocuments.filter(companyDocument =>
        requestedFilesIds.includes(companyDocument.file.id)
      );
      setUploadedFiles(tmpUploadedFiles);
    }
  }, [requestedFile, companyDocuments, user]);

  return (
    <Box display="flex" flexDirection="column" marginRight="1.5rem">
      <Typography style={{ fontWeight: 600, fontSize: '1.25rem' }} gutterBottom>
        {requestedFile.name}
      </Typography>
      <>
        {!companyDocuments && requestedFile.files.map(file => <UploadedFileData key={file.id} uploadedFile={file} />)}
        {user
          && uploadedFiles.map(uploadedFile => (
            <UploadedFileData key={uploadedFile.file.id} uploadedFile={uploadedFile} />
          ))}
      </>
    </Box>
  );
};

RequestedFileNameAndData.propTypes = {
  requestedFile: PropTypes.shape({
    name: PropTypes.string.isRequired,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  companyDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.shape({
        id: PropTypes.number.isRequired,
        filename: PropTypes.string.isRequired,
        file_type: PropTypes.string.isRequired,
        uploaded_date: PropTypes.string.isRequired,
        uploaded_by: PropTypes.string,
      }),
    })
  ),
};

export default RequestedFileNameAndData;
