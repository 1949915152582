/* eslint-disable no-param-reassign */
import { sortBy } from 'lodash';
import { VALUATIONS_OTHER_LABEL } from 'common/constants/valuations';
import { DCF_FINANCIAL_PERIOD_TABLE_NAME } from 'pages/Valuations/approaches/discountCashFlow/utilities/constants';
import { multipleAliases, OTHER_MULTIPLE_VALUE } from 'pages/Valuations/util/constants';
import { getValueByPropOrFallback, parseDatabaseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import swapMap from 'utillities/swapMap';
import getSelectionFromTVT from './getSelectionFromTVT';
import obtainMapForSelectMultipleAlias from './obtainMapForSelectMultipleAlias';

const reverseParser = ({ cells, columns, allowEmptyValues, rowConfig, tableData, sheet, fieldAttributes }) => {
  const sortedColumns = sortBy(columns, ['order']);
  const alphabet = alphabetGenerator([], sortedColumns.length);
  const { dcfApproachAttrs } = fieldAttributes;
  sortedColumns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];

    // replace updated values
    rowConfig.forEach((cell, cellIndex) => {
      const key = `${columnLegend}${cellIndex + 1}`;
      const defaultValue = getValueByPropOrFallback(cell, 'defaultValue', null);
      const type = getValueByPropOrFallback(cell, 'dbType', null);
      const format = getValueByPropOrFallback(cell, 'format', null);
      const value = cells[key] ? cells[key].value : defaultValue;
      const { gridType } = cell;
      const decimalPlaces = dcfApproachAttrs[cell.alias]?.decimal_places || cell?.dbDecimalPlaces || 2;

      const calcValue = parseDatabaseValue({
        type,
        value,
        defaultValue,
        format,
        allowEmptyValues,
        gridType,
        decimalPlaces,
      });
      // need to send multiple selection as label, not number
      const selection = getSelectionFromTVT(cell, value, rowConfig);

      if (cell.origin !== DCF_FINANCIAL_PERIOD_TABLE_NAME) {
        // this is to temporarily save the 'Other' multiple numeric value
        if (multipleAliases.includes(cell.alias)) {
          const reversedMap = swapMap(obtainMapForSelectMultipleAlias());
          const selectMultipleAlias = reversedMap.get(cell.alias);
          const selectionStored = column[selectMultipleAlias];
          if (selectionStored === VALUATIONS_OTHER_LABEL) {
            tableData.approach.valuations_approach_dcf[OTHER_MULTIPLE_VALUE] = calcValue;
          }
        }
        tableData.approach.valuations_approach_dcf[cell.alias] = selection || calcValue;
      }

      columns.forEach(column => {
        column[cell.alias] = selection || calcValue;
      });

      sheet.columns = columns;
    });
  });
};
export default reverseParser;
