import { useQueryClient } from '@tanstack/react-query';
import { hoursToMilliseconds } from 'date-fns';
import { isNil } from 'lodash';
import {
  RISK_FREE_RATE_BY_MD_DEFAULT_ERROR_MESSAGE,
  RISK_FREE_RATE_BY_MD_QUERY_KEY,
} from 'common/constants/services/allocations';
import { AllocationService } from 'services';
import { throwError } from 'utillities';
import {
  FetchRiskFreeRateByMD,
  GetRiskFreeRateByMDHook,
  GetRiskFreeRateByMDParams,
  UseGetRiskFreeRateByMD,
} from './types';

export const getRiskFreeRateByMD = async (params: GetRiskFreeRateByMDParams) => {
  const { backsolveDate, maturity, measurementDateId } = params;

  if ((isNil(backsolveDate) && isNil(measurementDateId)) || isNil(maturity)) return null;

  const allocationService = new AllocationService();

  try {
    const riskFreeRate = (await allocationService.getRiskFreeRateByDate(
      maturity,
      backsolveDate ?? measurementDateId
    )) as number;

    if (isNil(riskFreeRate)) return throwError(RISK_FREE_RATE_BY_MD_DEFAULT_ERROR_MESSAGE);

    return riskFreeRate;
  } catch (error) {
    return throwError(RISK_FREE_RATE_BY_MD_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetRiskFreeRateByMD: UseGetRiskFreeRateByMD = () => {
  const queryClient = useQueryClient();

  const fetchRiskFreeRateByMD: FetchRiskFreeRateByMD = async params => {
    const { backsolveDate, maturity, measurementDateId } = params;

    if ((isNil(backsolveDate) && isNil(measurementDateId)) || isNil(maturity)) {
      return null;
    }

    try {
      const data = await queryClient.fetchQuery<GetRiskFreeRateByMDHook, Error>(
        [RISK_FREE_RATE_BY_MD_QUERY_KEY, backsolveDate, maturity, measurementDateId],
        () => getRiskFreeRateByMD({ backsolveDate, maturity, measurementDateId }),
        {
          staleTime: hoursToMilliseconds(24),
        }
      );

      return data;
    } catch (error) {
      return null;
    }
  };

  return fetchRiskFreeRateByMD;
};

export default useGetRiskFreeRateByMD;
