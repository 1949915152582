import React from 'react';
import PropTypes from 'prop-types';
import CustomTextField from 'components/CustomTextField';

const WebsiteTextField = ({ id, type, name, label, value, onChange, formErrors, dbErrors, ...restProps }) => {
  const onWebsiteFocus = () => {
    if (!value?.length) {
      onChange({
        target: {
          name,
          value: 'https://',
        },
      });
    }
  };
  const onURLPaste = event => {
    const fromClipboard = event.clipboardData.getData('text/plain');
    const hasHttpsOrHttp = /^https?:\/\/.*$/.test(fromClipboard);
    // if no protocol 'prefix', behaviour is default and uses the available 'https://' prefix
    if (hasHttpsOrHttp) {
      event.preventDefault();
      onChange({
        target: {
          name,
          value: fromClipboard,
        },
      });
    }
  };

  return (
    <CustomTextField
      fullWidth
      id={id}
      name={name}
      type="text"
      label={label}
      value={value}
      onChange={onChange}
      onPaste={onURLPaste}
      formErrors={formErrors}
      dbErrors={dbErrors}
      onFocus={onWebsiteFocus}
      {...restProps}
    />
  );
};

WebsiteTextField.defaultProps = {
  value: '',
  formErrors: {},
  dbErrors: {},
};

WebsiteTextField.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  dbErrors: PropTypes.object,
};

export default WebsiteTextField;
