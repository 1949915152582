import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { useStore } from 'common/store';
import { MessageBox } from 'components';
import { GridSkeleton } from 'components/Grid';
import { PUBLIC_COMPANIES } from 'pages/Valuations/util/constants';
import ValuationService from 'services/valuations';
import { getInitials } from 'utillities';
import AccordionCompGroup from './AccordionCompGroup';
import AddCompButton from './AddCompButton';
import NewCompDialog from './NewCompDialog';
import { ADD_PUBLIC_COMP_GROUP, PRIVATE_TRANSACTIONS } from '../constants/constants';

const CompGroups = ({
  GPCCompsGroups,
  setGPCCompsGroups,
  transactionCompsGroups,
  setTransactionCompsGroups,
  firmId,
  setCompsToDelete,
  expandedState,
  setExpandedState,
  transactionsValidatedGrids,
  setTransactionsValidatedGrids,
  showTransactionCellsWithErrors,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [{ user }] = useStore();

  const saveAsGlobal = useMemo(() => user?.is_superuser && firmId === 0, [user, firmId]);

  const getFirmCompGroups = useCallback(async () => {
    if (firmId || firmId === 0) {
      const valuationSvc = new ValuationService();
      const firmCompGroups = await valuationSvc.getFirmCompGroups(firmId);

      if (firmCompGroups && !isUndefined(saveAsGlobal)) {
        // if super user we only want globals to display/update
        const filterCriteria = (group, type) =>
          group.group_type === type && (saveAsGlobal ? group.is_global : !group.is_global);

        const receivedGPC = firmCompGroups.results.filter(group => filterCriteria(group, PUBLIC_COMPANIES));
        const receivedTransactions = firmCompGroups.results.filter(group =>
          filterCriteria(group, PRIVATE_TRANSACTIONS)
        );
        setGPCCompsGroups(receivedGPC);
        setTransactionCompsGroups(receivedTransactions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmId, saveAsGlobal]);

  useEffect(() => {
    getFirmCompGroups();
  }, [getFirmCompGroups]);

  const addComp = () => {
    setOpenDialog(true);
  };

  const handleCompClose = () => {
    setOpenDialog(false);
  };

  const handleApproachTypeClick = compGroup => {
    const newCompGroup = {
      // eslint-disable-next-line radix
      id: uuid(),
      name: '',
      initials: getInitials({ names: compGroup, maxInitials: 3 }),
      is_global: saveAsGlobal,
      firm_id: saveAsGlobal ? 0 : firmId,
      version: '',
      versions: [],
    };

    if (compGroup === ADD_PUBLIC_COMP_GROUP) {
      const gpc = {
        ...newCompGroup,
        group_type: PUBLIC_COMPANIES,
        gpc_comps: [],
      };
      setGPCCompsGroups(prevState => [...prevState, gpc]);
    } else if (compGroup === 'Add Transaction Comp Group') {
      const gpt = {
        ...newCompGroup,
        group_type: PRIVATE_TRANSACTIONS,
        pt_comps: [],
      };
      if (!transactionCompsGroups) {
        setTransactionCompsGroups([]);
      }
      setTransactionCompsGroups(prevState => [...prevState, gpt]);
    }
    setOpenDialog(false);
  };

  if (isUndefined(GPCCompsGroups) && isEmpty(transactionCompsGroups)) {
    return <GridSkeleton />;
  }
  if (GPCCompsGroups?.length === 0 && isEmpty(transactionCompsGroups)) {
    return (
      <div style={{ marginTop: '1rem' }}>
        <MessageBox
          title={`There are no ${firmId === 0 ? 'global comp groups yet' : 'comp groups associated to this firm'}.`}
          tagline="You can add one from the Add Comp Group button."
          fullWidth={false}
        />
        <NewCompDialog
          handleCompClose={handleCompClose}
          openDialog={openDialog}
          handleApproachTypeClick={handleApproachTypeClick}
        />
        <AddCompButton openDialog={openDialog} addComp={addComp} />
      </div>
    );
  }

  const accordionCompGroupsProps = {
    expandedState,
    setExpandedState,
    setCompsToDelete,
    showTransactionCellsWithErrors,
  };

  return (
    <>
      {GPCCompsGroups?.map(group => (
        <AccordionCompGroup
          key={group.id}
          group={group}
          GPCCompsGroups={GPCCompsGroups}
          setGPCCompsGroups={setGPCCompsGroups}
          {...accordionCompGroupsProps}
        />
      ))}
      {transactionCompsGroups?.map(group => (
        <AccordionCompGroup
          key={group.id}
          group={group}
          transactionCompsGroups={transactionCompsGroups}
          setTransactionCompsGroups={setTransactionCompsGroups}
          transactionsValidatedGrids={transactionsValidatedGrids}
          setTransactionsValidatedGrids={setTransactionsValidatedGrids}
          {...accordionCompGroupsProps}
        />
      ))}
      <NewCompDialog
        handleCompClose={handleCompClose}
        openDialog={openDialog}
        handleApproachTypeClick={handleApproachTypeClick}
      />
      <AddCompButton openDialog={openDialog} addComp={addComp} />
    </>
  );
};

export default CompGroups;

const compGroupShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  initials: PropTypes.string,
  is_global: PropTypes.bool,
  firm_id: PropTypes.number,
  version: PropTypes.string,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      initials: PropTypes.string,
      is_global: PropTypes.bool,
      firm_id: PropTypes.number,
      version: PropTypes.string,
    })
  ),
});

CompGroups.propTypes = {
  GPCCompsGroups: PropTypes.arrayOf(compGroupShape),
  transactionCompsGroups: PropTypes.arrayOf(compGroupShape),
  setGPCCompsGroups: PropTypes.func,
  setTransactionCompsGroups: PropTypes.func,
  firmId: PropTypes.number,
  setCompsToDelete: PropTypes.func,
  expandedState: PropTypes.shape({}),
  setExpandedState: PropTypes.func,
  compsToDelete: PropTypes.arrayOf(PropTypes.shape({})),
  setCellsToValidate: PropTypes.func,
  transactionsValidatedGrids: PropTypes.shape({}),
  setTransactionsValidatedGrids: PropTypes.func,
  showTransactionCellsWithErrors: PropTypes.bool,
};
