import React, { useMemo, useState } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomTextField = ({
  id,
  type,
  name,
  label,
  value,
  onChange,
  formErrors,
  dbErrors,
  onBlur,
  onFocus,
  ...restProps
}) => {
  const [wasFocused, setWasFocused] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const errors = useMemo(() => {
    if (formErrors) {
      const tmpErrors = [];

      if (formErrors?.[name]) {
        tmpErrors.push(...formErrors[name]);
      }

      if (dbErrors?.[name]) {
        tmpErrors.push(formErrors[name]);
      }
      return tmpErrors.length ? tmpErrors.join('. ') : '';
    }
    return '';
  }, [formErrors, dbErrors, name]);

  const onInputFocus = e => {
    setIsEditing(true);
    setWasFocused(true);

    onFocus?.(e);
  };

  const onInputBlur = e => {
    setIsEditing(false);

    onBlur?.(e);
  };

  return (
    <TextField
      id={id}
      name={name}
      label={label}
      onChange={onChange}
      value={value}
      error={!isEditing && wasFocused && !!errors}
      helperText={!isEditing && wasFocused && errors}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      autoComplete="off"
      type={type || 'text'}
      {...restProps}
    />
  );
};

CustomTextField.defaultProps = {
  formErrors: {},
  dbErrors: {},
};

CustomTextField.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  formErrors: PropTypes.shape({}),
  dbErrors: PropTypes.shape({}),
};

export default CustomTextField;
