import { GRAY_CELL } from 'common/constants/general';
import { Cell } from 'common/types/scalarSpreadsheet';
import { getObjectValue } from 'utillities';
import { CustomAfterCellChangedParams, GetCellStatusUpdatedParams } from './types';

const getCellStatusUpdated = (params: GetCellStatusUpdatedParams) => {
  const { cell, isCalculated } = params;
  cell.isCellCalculated = isCalculated;
  cell.defaultTooltip = isCalculated ? 'This cell has been calculated automatically' : '';
  cell.className = isCalculated ? GRAY_CELL : '';
};

// define a function that takes an approach id, a multiple value, and generates a formula
const customAfterCellChanged = (params: CustomAfterCellChangedParams) => {
  const { cells, initialChanges } = getObjectValue(params);

  const changes = [...initialChanges];

  changes.forEach(change => {
    const { cell, value } = change;
    const { rowNumberPair, columnLegend } = cell;

    // If is a cell with pair cell
    if (rowNumberPair) {
      const pairCellKey = `${columnLegend}${rowNumberPair}`;
      const pairCell = cells[pairCellKey] as Cell;
      const { exprPair } = pairCell;
      cell.pairCell = pairCell;
      pairCell.pairCell = cell;

      getCellStatusUpdated({ cell, isCalculated: false });
      changes.push({ cell, value });

      // Update current cell changed pair status
      getCellStatusUpdated({ cell: pairCell, isCalculated: true });
      changes.push({ cell: pairCell, value: exprPair as string });
    } else {
      changes.push({ cell, value });
    }
  });

  return changes;
};

export default customAfterCellChanged;
