import React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  dialogTitle: {
    padding: '8px 40px 16px 40px',
  },
  dialogContent: {
    padding: '12px 0px',
  },
  dialogActions: {
    padding: '24px 40px',
  },
  cancelBtn: {
    background: 'white',
    color: '#004A8B',
    '&:hover': {
      backgroundColor: '#f7f7f7',
      boxShadow: 'none',
    },
  },
});

const LedgerDialog = ({
  id,
  title,
  children,
  onClose,
  onSave,
  disabled,
  disableSaveButton,
  dialogHeader,
  actionButtonText,
}) => {
  const classes = useStyles();

  return (
    <div id={id} data-testid={id}>
      {!isEmpty(title) && (
        <DialogTitle id={`${id}-title`} className={classes.dialogTitle}>
          {title}
        </DialogTitle>
      )}
      {dialogHeader}
      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button id={`${id}-cancel-btn`} className={classes.cancelBtn} variant="contained" onClick={onClose}>
          {actionButtonText?.cancel || 'Cancel'}
        </Button>
        {onSave && (
          <Button
            id={`${id}-save-btn`}
            disabled={disableSaveButton || disabled}
            variant="contained"
            color="secondary"
            onClick={onSave}>
            {actionButtonText?.save || 'Save'}
          </Button>
        )}
      </DialogActions>
    </div>
  );
};

LedgerDialog.defaultProps = {
  disabled: false,
  disableSaveButton: false,
};

LedgerDialog.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  disableSaveButton: PropTypes.bool,
  dialogHeader: PropTypes.any,
  actionButtonText: PropTypes.shape({
    save: PropTypes.string,
    cancel: PropTypes.string,
  }),
};

export default LedgerDialog;
