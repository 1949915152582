import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    formContainer: {
      width: '100%',
    },
    optionSelector: {
      '& .MuiSelect-select': {
        paddingBottom: theme.spacing(0.4),
        paddingTop: theme.spacing(0.4),

        // Removing white background after select an option
        '&:focus': {
          backgroundColor: 'transparent !important',
        },
      },

      // Do not display the arrow icon when disabled
      '&.Mui-disabled': {
        '& .MuiSelect-icon': {
          display: 'none',
        },
      },
    },
    optionContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    optionText: {
      color: theme.palette.primary[600],
      fontSize: theme.spacing(1.75),
      marginLeft: theme.spacing(1),
    },
  })
);

export default useStyles;
