import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { boldTextFooter, MODAL_ACTION_BUTTON, MODAL_TITLE, SEND_REQUESTS, TEXT_FOOTER } from 'common/constants/firms';
import useCompanyCategoriesProcessManagement from 'common/hooks/useCompanyCategoriesProcessManagement';
import { useStore } from 'common/store';
import { FormDialog } from 'components';
import CustomPrimaryButton from 'components/CustomPrimaryButton/CustomPrimaryButton';
import theme from 'theme';
import FRModalActionButton from './FRModalActionButton';
import FRModalContent from './FRModalContent';

const FirmRequestsFooter = ({ firmId, documents, questions, companies }) => {
  const [{ firmInfo }] = useStore();
  const { name: firmName } = firmInfo;
  const [open, setOpen] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const { companyCategories } = useCompanyCategoriesProcessManagement({ firmId, companies });
  const {
    companiesWithMeasurementDateAndUsers,
    companiesWithMeasurementDateButNoUsers,
    companiesWithoutMeasurementDateButUsers,
    companiesWithoutMeasurementDateAndUsers,
  } = companyCategories;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const FRModalContentProps = {
    companiesWithMeasurementDateAndUsers,
    companiesWithMeasurementDateButNoUsers,
    companiesWithoutMeasurementDateButUsers,
    companiesWithoutMeasurementDateAndUsers,
    selectedCompanies,
    setSelectedCompanies,
  };

  const FRModalActionButtonProps = {
    selectedCompanies,
    setSelectedCompanies,
    handleClose,
    firmId,
    documents,
    questions,
  };

  return (
    <Grid
      container
      spacing={2}
      style={{
        borderTop: `1px solid ${theme.palette.gray[300]}`,
        padding: '2rem 0',
      }}>
      <Grid item xs={12} sm={6}>
        <Typography
          style={{
            fontSize: '1rem',
          }}>
          <b>{boldTextFooter}</b>
          {TEXT_FOOTER.substring(boldTextFooter.length)}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CustomPrimaryButton onClick={handleClickOpen}>{SEND_REQUESTS}</CustomPrimaryButton>
        <FormDialog
          open={open}
          onClose={handleClose}
          title={MODAL_TITLE}
          subtitle={firmName}
          customButtonLabel={MODAL_ACTION_BUTTON}
          disableTitleClose={false}
          customButton={<FRModalActionButton {...FRModalActionButtonProps} />}
          showSaveButton={false}
          showCancelButton={false}>
          <FRModalContent {...FRModalContentProps} />
        </FormDialog>
      </Grid>
    </Grid>
  );
};

FirmRequestsFooter.propTypes = {
  firmId: PropTypes.number.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  questions: PropTypes.arrayOf(PropTypes.shape({})),
  companies: PropTypes.arrayOf(PropTypes.shape({})),
};

export default FirmRequestsFooter;
