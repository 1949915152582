/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { DELETE_MEASUREMENT_DATE, DELETE_MEASUREMENT_DATE_CONFIRMATION } from 'common/constants/messages/validations';
import { useStore } from 'common/store';
import { UseStoreValues } from 'common/types/store';
import { ConfirmationDelete, ConfirmationDialog, Dialog } from 'components/Dialogs';
import { CompanyList, FundList } from 'components/MeasurementDates/components';
import { DialogContext } from 'context';
import { MDContext } from 'context/MDContext';
import {
  useDeleteCompaniesAndFundByMd,
  useGetAllMeasurementDatesByFirmId,
  useGetCompaniesAndFundByMd,
} from 'services/hooks/firm';
import DateSelector from './DateSelector/DateSelector';
import useStyles from './styles';
import { DeleteMeasurementDateDialogProps, FundsAndCompaniesToDelete, SelectedItem, SelectedItemId } from './types';

// @ts-ignore
const WrapperComponent = props => <ConfirmationDialog {...props} title="Delete" />;
// @ts-ignore
const ContentComponent = props => <ConfirmationDelete {...props} />;

const DeleteMeasurementDateDialog: FC<DeleteMeasurementDateDialogProps> = ({
  open,
  onClose,
  getMeasurementDates,
  setMdHasBeenDeleted,
  selectedCmdId,
  selectedFmdId,
}) => {
  const classes = useStyles();
  const [storeValue] = useStore() as unknown as UseStoreValues;
  const { firmInfo } = storeValue;
  const [measurementDates, fetchMeasurementDates] = useGetAllMeasurementDatesByFirmId();
  const [companiesAndFunds, fetchCompaniesAndFunds] = useGetCompaniesAndFundByMd();
  const [deleteFundsAndCompaniesByMd] = useDeleteCompaniesAndFundByMd();
  const [fundList, setFundList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [measurementDate, setMeasurementDate] = useState();
  const [selectedFunds, setSelectedFunds] = useState<SelectedItem>({});
  const [selectedCompanies, setSelectedCompanies] = useState<SelectedItem>({});
  const [isMdSelected, setIsMdSelected] = useState(false);
  const { showDialog } = useContext(DialogContext);

  const firmId = useMemo(() => firmInfo?.id ?? null, [firmInfo]);

  useEffect(() => {
    if (firmId && fetchMeasurementDates) {
      fetchMeasurementDates(firmId);
    }
  }, [firmId, fetchMeasurementDates]);

  const handleOnSelectMD = useCallback(
    md => {
      if (md && firmId) {
        setIsMdSelected(true);
        fetchCompaniesAndFunds(firmId, md);
      }
    },
    [fetchCompaniesAndFunds, firmId]
  );

  const getListOfId = useCallback(
    (data: SelectedItem): SelectedItemId[] =>
      Object.keys(data)
        .filter(key => data[parseInt(key, 10)] === true)
        .map(key => ({ id: parseInt(key, 10) })),
    []
  );

  const parsedSelectedItems = useMemo(
    () =>
      ({
        company_measurement_dates: getListOfId(selectedCompanies),
        fund_measurement_dates: getListOfId(selectedFunds),
      } as FundsAndCompaniesToDelete),
    [selectedCompanies, selectedFunds, getListOfId]
  );

  const selectedItemsMsg = useMemo(() => {
    const data = parsedSelectedItems || {};
    const companiesLength = data?.company_measurement_dates?.length || 0;
    const fundsLength = data?.fund_measurement_dates?.length || 0;

    const hasCompanies = companiesLength > 0;
    const hasFunds = fundsLength > 0;

    let companyTxt = '';
    if (hasCompanies) {
      companyTxt = companiesLength > 1 ? 'Companies' : 'Company';
    }

    let fundTxt = '';
    if (hasFunds) {
      fundTxt = fundsLength > 1 ? 'Funds' : 'Fund';
    }

    const hasCompaniesAndFundsSelected = hasCompanies && hasFunds;

    const selectedItemText = `selected ${fundTxt}${hasCompaniesAndFundsSelected ? ' and ' : ''}${companyTxt}`;
    return selectedItemText;
  }, [parsedSelectedItems]);

  const onDeleteMd = useCallback(async () => {
    const data = parsedSelectedItems;
    const mdInData
      = !!data.company_measurement_dates.find(({ id }) => id === selectedCmdId)
      || !!data.fund_measurement_dates.find(({ id }) => id === selectedFmdId);
    const result = await deleteFundsAndCompaniesByMd(data, selectedItemsMsg);
    if (result) {
      setMdHasBeenDeleted(mdInData);
      // we pass in an empty object, as that will cause the page to be set to not being initialized anymore
      await getMeasurementDates({});
    }
  }, [
    deleteFundsAndCompaniesByMd,
    getMeasurementDates,
    parsedSelectedItems,
    setMdHasBeenDeleted,
    selectedItemsMsg,
    selectedCmdId,
    selectedFmdId,
  ]);

  const handleDelete = useCallback(() => {
    const confirmationMsgTxt = DELETE_MEASUREMENT_DATE_CONFIRMATION(selectedItemsMsg);
    onClose();
    // @ts-ignore
    showDialog({
      wrapper: WrapperComponent,
      content: ContentComponent,
      contentProps: {
        customMessage: confirmationMsgTxt,
      },
      actions: [
        {
          label: 'Cancel',
          type: 'cancel',
        },
        {
          label: 'Delete',
          variant: 'contained',
          type: 'danger',
          callback: () => onDeleteMd(),
        },
      ],
    });
  }, [onClose, onDeleteMd, selectedItemsMsg, showDialog]);

  const allowDeleteMd = useMemo(
    () => isMdSelected && (!isEmpty(selectedCompanies) || !isEmpty(selectedFunds)),
    [isMdSelected, selectedCompanies, selectedFunds]
  );

  useEffect(() => {
    if (companiesAndFunds) {
      setFundList(companiesAndFunds.fund_measurement_dates ?? []);
      setCompanyList(companiesAndFunds.company_measurement_dates ?? []);
    }
  }, [companiesAndFunds]);

  const mdContextValue = useMemo(
    () => ({
      fundList,
      companyList,
      measurementDate,
      selectedFunds,
      selectedCompanies,
      setMeasurementDate,
      setSelectedFunds,
      setSelectedCompanies,
      isLoading: false,
      isDeletingMD: true,
    }),
    [
      fundList,
      companyList,
      measurementDate,
      selectedFunds,
      selectedCompanies,
      setMeasurementDate,
      setSelectedFunds,
      setSelectedCompanies,
    ]
  );

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      title={DELETE_MEASUREMENT_DATE}
      onClose={onClose}
      classes={{
        paperWidthSm: classes.paperSm,
      }}
      fullWidth
      dialogProps={{
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        disableTitleClose: false,
      }}>
      <MDContext.Provider value={mdContextValue}>
        <Grid item xs={6}>
          <Grid item>
            <DateSelector options={measurementDates} onSelectMD={handleOnSelectMD} />
          </Grid>
        </Grid>

        <Grid
          container
          classes={{
            root: classes.marginTop,
          }}
          spacing={4}>
          <Grid xs={6} container item>
            <FundList />
          </Grid>
          <Grid xs={6} container item>
            <CompanyList />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2} classes={{ root: classes.actionButtons }}>
          <Grid item>
            <Button
              id="delete-md-btn"
              className={classes.dangerButton}
              variant="contained"
              color="secondary"
              disabled={!allowDeleteMd}
              onClick={handleDelete}>
              DELETE
            </Button>
          </Grid>
        </Grid>
      </MDContext.Provider>
    </Dialog>
  );
};

export default DeleteMeasurementDateDialog;
