import { isUndefined } from 'lodash';

export const getSymbols = (node, symbols) => {
  if (isUndefined(node)) {
    throw new Error('node is undefined');
  }
  if (node.isSymbolNode) {
    symbols.add(node.name);
  } else if (node.isConstantNode) {
    return undefined;
  } else if (node.isParenthesisNode) {
    getSymbols(node.content, symbols);
  } else if (node.isArrayNode) {
    node.items.forEach(item => {
      getSymbols(item, symbols);
    });
  } else if (node.isAccessorNode) {
    const accessorSet = new Set();
    getSymbols(node.object, accessorSet);
    const accessorSymbolArr = Array.from(accessorSet);
    accessorSymbolArr.push(node.name);
    symbols.add(accessorSymbolArr.join('.'));
  } else if (node.isObjectNode) {
    Object.values(node.properties).forEach(item => {
      getSymbols(item, symbols);
    });
  } else {
    if (isUndefined(node.args)) {
      throw new Error('implementation undefined for node');
    }
    node.args.forEach(arg => {
      getSymbols(arg, symbols);
    });
  }
};

export const getGetSymbolSet = node => {
  const symbolSet = new Set();
  getSymbols(node, symbolSet);
  return symbolSet;
};
