import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { GridSkeleton } from 'components/Grid';
import {
  deletePaymentMethod as deletePaymentMethodService,
  getSubscription as getSubscriptionService,
  listPaymentMethods as listCardsService,
  setSubscriptionActivePaymentMethod as setSubscriptionActivePaymentMethodService,
  setSubscriptionRenewal as setSubscriptionRenewalService,
} from 'dashboard409a/services/subscription';
import { IGetSubscription } from 'dashboard409a/services/types';
import { RootStateType } from 'dashboard409a/states/store';
import CreditCardTable from './components/CreditCardTable';
import { CreditCardManagementContainer } from './components/CustomComponets';
import RenewalToggle from './components/RenewalToggle';
import { Payload } from './types';

const CreditCardsManagment: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<Payload[]>([]);
  const [subscription, setSubscription] = useState<IGetSubscription | null>(null);
  const { currentCompany } = useSelector((state: RootStateType) => state.global);
  const { enqueueSnackbar } = useSnackbar();

  const renewal = useMemo(() => subscription?.auto_renewal ?? false, [subscription]);

  const getPaymentMethods = useCallback(
    async (companyId: number) => {
      try {
        setIsLoading(true);
        const paymentMethodsResponse = await listCardsService(companyId);
        setPaymentMethods(paymentMethodsResponse?.results ?? []);
      } catch (e) {
        enqueueSnackbar('Ooops! A problem occurred while obtaining payment methods', { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  const handleChangeActivePaymentMethod = useCallback(
    async (paymentId: string): Promise<void> => {
      try {
        if (currentCompany?.id) {
          const subscriptionResponse = await setSubscriptionActivePaymentMethodService(currentCompany?.id, paymentId);
          if (subscriptionResponse) {
            setSubscription(subscriptionResponse);
            enqueueSnackbar('Active payment method has been updated', { variant: 'success' });
          }
        }
      } catch (e) {
        enqueueSnackbar('Ooops! A problem occurred while updating the active payment method', { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
    [currentCompany, enqueueSnackbar]
  );

  const handleChangeRenewal = useCallback(
    async (value: boolean): Promise<void> => {
      try {
        if (currentCompany?.id) {
          const subscriptionResponse = await setSubscriptionRenewalService(currentCompany?.id, value);
          if (subscriptionResponse) {
            setSubscription(subscriptionResponse);
            enqueueSnackbar(`Auto renewal status has been updated to ${value}`, { variant: 'success' });
          }
        }
      } catch (e) {
        enqueueSnackbar('Ooops! A problem occurred while updating the auto renewal', { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
    [currentCompany, enqueueSnackbar]
  );

  const getSubscription = useCallback(
    async (companyId: number): Promise<void> => {
      try {
        const subscriptionResponse = await getSubscriptionService(companyId);
        if (subscriptionResponse) {
          setSubscription(subscriptionResponse);
        }
      } catch (e) {
        enqueueSnackbar('Ooops! A problem occurred while getting the subscription information', { variant: 'error' });
      }
    },
    [enqueueSnackbar]
  );

  const handleCleanPaymentMethod = useCallback(async () => {
    if (currentCompany?.id) {
      await setSubscriptionRenewalService(currentCompany?.id, false);
      await setSubscriptionActivePaymentMethodService(currentCompany?.id, null);
      await getSubscription(currentCompany.id);
    }
  }, [currentCompany, getSubscription]);

  useEffect(() => {
    if (currentCompany?.id) {
      // eslint-disable-next-line no-console
      getPaymentMethods(currentCompany.id).catch(ex => console.error(ex));
      // eslint-disable-next-line no-console
      getSubscription(currentCompany.id).catch(ex => console.error(ex));
    }
  }, [currentCompany, getPaymentMethods, getSubscription]);

  useEffect(() => {
    if (!isLoading && paymentMethods.length === 0 && subscription?.active_payment_method) {
      // eslint-disable-next-line no-console
      handleCleanPaymentMethod().catch(ex => console.error(ex));
    }
  }, [isLoading, paymentMethods, subscription, handleCleanPaymentMethod]);

  const handleAdd = async (): Promise<void> => {
    if (currentCompany?.id) {
      await getPaymentMethods(currentCompany.id);
    }
  };

  const handleDelete = async (paymentMethodId: string): Promise<void> => {
    const companyId = currentCompany?.id;
    if (!companyId) {
      return;
    }

    await deletePaymentMethodService(companyId, [paymentMethodId]);

    if (currentCompany?.id) {
      await getPaymentMethods(currentCompany.id);
    }
  };

  return (
    <CreditCardManagementContainer container item sm={12} md={6}>
      <RenewalToggle value={renewal} onChange={handleChangeRenewal} />
      <Typography variant="h2" style={{ marginBottom: 10 }}>
        Saved Cards
      </Typography>
      {isLoading ? (
        <div style={{ width: '100%' }}>
          <GridSkeleton maxRows={5} maxColumns={4} />
        </div>
      ) : (
        <CreditCardTable
          data={paymentMethods}
          onAdd={handleAdd}
          onDelete={handleDelete}
          defaultPaymentMethod={subscription?.active_payment_method ?? null}
          onActivePaymentMethod={handleChangeActivePaymentMethod}
        />
      )}
    </CreditCardManagementContainer>
  );
};

export default CreditCardsManagment;
