import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    messageContainer: {
      backgroundColor: theme.palette.error.veryLight,
      border: `2px solid ${theme.palette.error.main}`,
      borderRadius: theme.shape.borderRadius,
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2.5),
    },
    iconContainer: {
      marginRight: theme.spacing(1),
    },
    icon: {
      color: theme.palette.error.main,
      fontSize: theme.spacing(4),
    },
    title: {
      color: theme.palette.error.main,
      fontWeight: 700,
    },
    listContainer: {
      borderTop: `1px solid ${theme.palette.error.main}`,
      marginTop: theme.spacing(1),
    },
    listItem: {
      padding: 0,

      '& + &': {
        marginTop: theme.spacing(0.5),
      },

      '& .MuiListItemText-multiline': {
        margin: 0,
      },

      '& .MuiListItemText-root': {
        margin: 0,
      },

      '& .MuiListItemText-primary': {
        color: theme.palette.error.dark,
        display: 'inline-block',
        fontSize: theme.spacing(1.8),
        fontWeight: 600,
      },

      '& .MuiListItemText-secondary': {
        color: theme.palette.black,
        display: 'inline-block',
        fontSize: theme.spacing(1.7),
        marginLeft: theme.spacing(1),
      },
    },
    listItemBox: {
      cursor: 'pointer',
      display: 'flex',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      position: 'relative',

      '&:before': {
        backgroundColor: theme.palette.error.main,
        bottom: theme.spacing(0.25),
        content: '""',
        height: theme.spacing(0.25),
        left: theme.spacing(6),
        position: 'absolute',
        transition: 'width 0.3s ease-in-out',
        width: 0,
      },

      '&:hover:before': {
        width: `calc(100% - ${theme.spacing(6)}px - ${theme.spacing(2)}px)`,
      },
    },
    listIconContainer: {
      minWidth: theme.spacing(4),
    },
    listIcon: {
      color: theme.palette.error.dark,
      fontSize: theme.spacing(3),
    },
  })
);

export default useStyles;
