/* eslint-disable no-param-reassign */

import { isEmpty } from 'lodash';

const GA_MEASUREMENT_ID = 'G-66HY7R7H0L';
const USER_PROPERTIES = 'user_properties';

export const initAnalytics = user => {
  window.gtag('js', new Date());
  window.gtag('config', GA_MEASUREMENT_ID, {
    transport_url: 'https://metrics.scalar.io',
  });

  if (user && !isEmpty(user)) {
    const findEmailProvider = address => {
      const lastIndex = address?.lastIndexOf('@');
      return address.slice(lastIndex + 1, address.length);
    };

    window.gtag('set', USER_PROPERTIES, {
      user_id: user.id,
      user_email_domain: findEmailProvider(user.email),
      is_superuser: user.is_superuser,
    });
  }
};

const formattedSlug = slugParam => {
  const arr = slugParam.replace(/-/g, ' ').split(' ');

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr.join(' ');
};

export const sendPageView = (path, title, location) => {
  const sendData = () => {
    document.title = formattedSlug(title);

    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_title: title || document.title,
        page_location: location || window.location.href,
        page_path: path,
      });
    }
  };

  if (path.includes('companies') && path.includes(title) && title !== 'summary') {
    sendData();
  } else {
    const PATH = nameToMatch => path.includes(nameToMatch);

    switch (true) {
      case PATH('companies'):
        title = 'company-summary';
        break;
      case PATH('investments'):
        title = 'fund-investments';
        break;
      case PATH('funds'):
        title = 'fund-summary';
        break;
      case PATH('account-settings'):
        title = 'account-settings';
        break;
      case PATH('permissions'):
        title = 'permissions';
        break;
      case PATH('user-management'):
        title = 'user-management';
        break;
      case PATH('comp-groups'):
        title = 'comp-groups';
        break;
      case PATH('firms'):
        title = 'firm-summary';
        break;
      case PATH('questionnaires'):
        title = 'questionnaire';
        break;
      case PATH('requests'):
        title = 'document-request';
        break;

      default:
        break;
    }
    sendData();
  }
};
