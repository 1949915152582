import moment from 'moment';

// Day count basis
const US_NASD = 0;
const ACT_ACT = 1;
const ACT_360 = 2;
const ACT_365 = 3;
const EU_360 = 4;

/**
 * Validates the inputs for the YEARFRAC function.
 * Checks if the start and end dates are valid dates,
 * if the basis is in the acceptable range, and if the start and end dates are the same.
 *
 * @param {moment} startDate - the start date of the period as a moment object.
 * @param {moment} endDate - the end date of the period as a moment object.
 * @param {number} basis - The type of day count convention.
 *
 * @returns {null|string|number} - Return null if all inputs are valid. If any input is valid, return an error message.
 * If one of the dates is invalid, return '#VALUE!'.
 * If the basis is not in the range 0-4, return '#NUM!'.
 * If startDate and endDate are the same, return 0.
 */

export function validateInputs(startDate, endDate, basis) {
  if (!startDate.isValid() || !endDate.isValid()) return '#VALUE!';

  // Day count basis
  if (![US_NASD, ACT_ACT, ACT_360, ACT_365, EU_360].includes(basis)) return '#NUM!';

  if (startDate === endDate) return 0;

  return null;
}

/**
 * Swaps the start and end date if the start date is after the end date.
 *
 * @param {moment} startDate - the start date of the period as a moment object.
 * @param {moment} endDate - the end date of the period as a moment object.
 * @returns {Object} - Returns an object that contains the start date and end date,
 * if the start date was later than the end date, these are swapped
 * Otherwise, the dates are returned as they were inputted.
 */

export function swapDatesIfNecessary(startDate, endDate) {
  if (startDate.diff(endDate) > 0) {
    return { endDate: moment(new Date(startDate)), startDate: moment(new Date(endDate)) };
  }
  return { startDate, endDate };
}

/**
 * Calculates the US (NASD) 30/360 day count fraction between two dates.
 *
 * @param {moment} startDate - the start date of the period as a moment object.
 * @param {moment} endDate - the end date of the period as a moment object.
 *
 * @return {number} The US (NASD) 30/360 day count fraction between the two dates.
 */

export function calculateUSNASD30360({ startDate, endDate }) {
  const startDay = startDate.date();
  const startMonth = startDate.month();
  const startYear = startDate.year();
  const endDay = endDate.date();
  const endMonth = endDate.month();
  const endYear = endDate.year();
  const adjustedSday = startDay === 31 ? 30 : startDay;
  const adjustedEday = (startDay === 31 && endDay === 31) || (startDay === 30 && endDay === 31) ? 30 : endDay;

  return (adjustedEday + endMonth * 30 + endYear * 360 - (adjustedSday + startMonth * 30 + startYear * 360)) / 360;
}

/**
 * Calculates the actual/actual day count fraction between two dates.
 *
 * @param {moment} startDate - The start date of the period as a moment object.
 * @param {moment} endDate - The end date of the period as a moment object.
 * @param {number} average - The average number of days per year over the period.
 *
 * @return {number} The actual/actual day count fraction between the two dates.
 */

export const feb29Between = (firstDate, secondDate) => {
  const march1FirstYear = moment(new Date(firstDate.year(), 2, 1));
  const march1SecondYear = moment(new Date(secondDate.year(), 2, 1));

  return (
    (moment([firstDate.year()]).isLeapYear()
      && firstDate.diff(march1FirstYear) < 0
      && secondDate.diff(march1FirstYear) >= 0)
    || (moment([secondDate.year()]).isLeapYear()
      && secondDate.diff(march1SecondYear) >= 0
      && firstDate.diff(march1SecondYear) < 0)
  );
};

export function calculateActualActual({ startDate, endDate, average }) {
  const startDay = startDate.date();
  const startMonth = startDate.month();
  const startYear = startDate.year();
  const endDay = endDate.date();
  const endMonth = endDate.month();
  const endYear = endDate.year();

  let ylength = 365;

  if (
    (startYear === endYear || startYear + 1 === endYear)
    && (startMonth > endMonth || (startMonth === endMonth && startDay >= endDay))
  ) {
    if (
      (startYear === endYear && moment([startYear]).isLeapYear())
      || feb29Between(startDate, endDate)
      || (endMonth === 1 && endDay === 29)
    ) {
      ylength = 366;
    }

    return endDate.diff(startDate, 'days') / ylength;
  }

  return endDate.diff(startDate, 'days') / average;
}

/**
 * Calculates the European 30/360 day count fraction between two dates.
 *
 * @param {moment} startDate - The start date as a moment object.
 * @param {moment} endDate - The end date as a moment object.
 *
 * @return {number} The European 30/360 day count fraction between the two dates.
 */

function calculateEuropean30360({ startDate, endDate }) {
  const startDay = startDate.date();
  const startMonth = startDate.month();
  const startYear = startDate.year();
  const endDay = endDate.date();
  const endMonth = endDate.month();
  const endYear = endDate.year();
  const adjustedStartday = startDay === 31 ? 30 : startDay;
  const adjustedEndDay = endDay === 31 ? 30 : endDay;

  return (
    (adjustedEndDay + endMonth * 30 + endYear * 360 - (adjustedStartday + startMonth * 30 + startYear * 360)) / 360
  );
}

/**
 * Calculates the fraction of the year represented by the number of whole days between two dates.
 * The calculation is based on the specified day count convention (basis).
 *
 * @param {moment} sdate - The start date as a moment object.
 * @param {moment} edate - The end date as a moment object.
 * @param {number} basis - The type of day count convention to use.
 *
 * @returns {number} The fraction of the year between the start and end date, according to the specified day count convention.
 *                   If the basis is not one of the above specified numbers, the function will return nothing (undefined).
 */

export function calculateYearFrac({ startDate, endDate, basis }) {
  const startYear = startDate.year();
  const endYear = endDate.year();
  const year = endYear - startYear + 1;
  const days = moment(new Date(endYear + 1, 0, 1)).diff(moment(new Date(startYear, 0, 1)), 'days');
  const average = days / year;

  switch (basis) {
    case US_NASD:
      return calculateUSNASD30360({
        startDate,
        endDate,
      });
    case ACT_ACT:
      return calculateActualActual({
        startDate,
        endDate,
        average,
      });
    case ACT_360:
      return endDate.diff(startDate, 'days') / 360;
    case ACT_365:
      return endDate.diff(startDate, 'days') / 365;
    case EU_360:
      return calculateEuropean30360({
        startDate,
        endDate,
      });
    default:
      return null;
  }
}
