/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useContext, useState } from 'react';
import { Radio, TableBody, TableRow, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlineRounded';
import Alert from '@material-ui/lab/Alert';
import { ConfirmationDelete, ConfirmationDialog } from 'components/Dialogs';
import { DialogContext } from 'context';
import CardDialog from './CardDialogStripe';
import {
  ActionsCell,
  AddCardButton,
  CreditCardBrandTextField,
  RemoveActionsButton,
  SelectedCell,
  Table,
  TableCell,
  TypeCell,
} from './CustomComponets';
import { AmexIcon, MasterCardIcon, VisaIcon } from '../assets/img';
import { ICreditCardTableProps } from '../types';

const cardType: { [key: string]: JSX.Element } = {
  0: <></>,
  amex: <AmexIcon />,
  mastercard: <MasterCardIcon />,
  visa: <VisaIcon />,
};

const cardTypeLength: { [key: string]: number } = {
  amex: 15,
  mastercard: 16,
  visa: 16,
};

const DEFAULT_LABEL = 'Default, ';

// @ts-ignore
const WrapperComponent = props => <ConfirmationDialog {...props} title="Delete" />;
const ContentComponent = () => <ConfirmationDelete itemName="Saved Card" />;

const maskCreditCardNumber = (last4Digits: string, brand: string): string => {
  let maskedNumber = last4Digits.padStart(cardTypeLength[brand], '*');

  switch (maskedNumber.length) {
    case 16:
      maskedNumber = maskedNumber.replace(/.{4}/g, '$& ');
      break;
    case 15:
      maskedNumber = [maskedNumber.slice(0, 4), ' ', maskedNumber.slice(4, 10), ' ', maskedNumber.slice(10)].join('');
      break;
    default:
      break;
  }

  return maskedNumber;
};

const CreditCardTable: FC<ICreditCardTableProps> = ({
  data,
  onAdd,
  onDelete,
  defaultPaymentMethod,
  onActivePaymentMethod,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { showDialog } = useContext(DialogContext);

  const handleSave = (): void => {
    setIsModalOpen(false);
    // eslint-disable-next-line no-console
    onAdd().catch(ex => console.error(ex));
  };

  const handleAdd = () => {
    setIsModalOpen(true);
  };

  const handleChangeActivePaymentMethod = (id: string): void => {
    // eslint-disable-next-line no-console
    onActivePaymentMethod(id).catch(ex => console.error(ex));
  };

  const handleDelete = (id: string) => {
    // @ts-ignore
    showDialog({
      wrapper: WrapperComponent,
      content: ContentComponent,
      actions: [
        {
          label: 'Cancel',
          type: 'cancel',
        },
        {
          label: 'Delete',
          variant: 'contained',
          type: 'danger',
          callback: () => onDelete(id),
        },
      ],
    });
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const creditCardBrandTextFieldProps = {
    component: 'span',
  };

  return (
    <>
      {data?.length ? (
        <Table>
          <TableBody>
            {data.map(item => (
              <TableRow key={item.id}>
                <SelectedCell>
                  <Radio
                    color="primary"
                    checked={defaultPaymentMethod === item.id}
                    onClick={() => handleChangeActivePaymentMethod(item.id)}
                  />
                </SelectedCell>
                <TypeCell>{cardType[item.card.brand ?? 0]}</TypeCell>
                <TableCell>
                  <Typography variant={item.card.networks.preferred ? 'body1' : 'body2'}>
                    {maskCreditCardNumber(item.card.last4, item.card.brand)}
                  </Typography>
                  <Typography variant={item.card.networks.preferred ? 'body1' : 'body2'}>
                    {defaultPaymentMethod === item.id && DEFAULT_LABEL}{' '}
                    <CreditCardBrandTextField
                      variant={item.card.networks.preferred ? 'body1' : 'body2'}
                      display="inline"
                      {...creditCardBrandTextFieldProps}>
                      {item.card.brand}
                    </CreditCardBrandTextField>
                  </Typography>
                </TableCell>
                <ActionsCell>
                  <RemoveActionsButton aria-label="delete" onClick={() => handleDelete(item.id)}>
                    <DeleteIcon />
                  </RemoveActionsButton>
                </ActionsCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Alert variant="outlined" severity="warning">
          Auto-renew is turned off. Add a payment method and enable auto-renew to keep access to this report.
        </Alert>
      )}
      <AddCardButton label="Add New Card" onAdd={handleAdd} />
      <CardDialog open={isModalOpen} onSave={handleSave} onClose={handleClose} />
    </>
  );
};

export default CreditCardTable;
