export const NET_OPERATING_LOSS_ROW = 1;
export const EBIT_ROW = 2;
export const NOL_BALANCE_APPLIED_UP_TO_100_ROW = 3;
export const PRE_TAX_CUTS_JOBS_ACT_ROW = 4;
export const EBIT_AFTER_PRE_TCJA_NOL_APPLICATION_ROW = 5;
export const NOL_BALANCE_APPLIED_UP_TO_80_ROW = 6;
export const FULLY_TAXABLE_INCOME_ROW = 7;
export const POST_TAX_CUTS_JOBS_ACT_NOL_BALANCE_ROW = 8;
export const TOTAL_NOL_BALANCE_APPLIED_ROW = 9;
export const TAX_SAVINGS_AT_RATE_ROW = 10;
export const DISCOUNT_PERIODS_ROW = 11;
export const DISCOUNT_FACTOR_ROW = 12;
export const PRESENT_VALUE_OF_NOL_TAX_SAVINGS_ROW = 13;

export const NET_OPERATING_LOSS_TITLE = 'NOL Carryforward Analysis';
export const NET_OPERATING_LOSS_ALIAS = 'year';

export const EBIT_TITLE = 'EBIT';
export const EBIT_ALIAS = 'ebit_total';

export const NOL_BALANCE_APPLIED_UP_TO_100_TITLE = 'NOL Balance Applied up to 100%';
export const NOL_BALANCE_APPLIED_UP_TO_100_ALIAS = 'nol_balance_applied_up_to_100';
export const NOL_BALANCE_APPLIED_UP_TO_100_EXPR = `=IF(@${EBIT_ROW}<0,0,MIN(@${EBIT_ROW},-@PREVCOL${PRE_TAX_CUTS_JOBS_ACT_ROW}))`;

export const PRE_TAX_CUTS_JOBS_ACT_TITLE = 'Pre-Tax-Cuts-&-Jobs-Act NOL Balance';
export const PRE_TAX_CUTS_JOBS_ACT_ALIAS = 'nol_ebit_pre_tax_cut';
export const PRE_TAX_CUTS_JOBS_ACT_EXPR = `=@PREVCOL${PRE_TAX_CUTS_JOBS_ACT_ROW}+@${NOL_BALANCE_APPLIED_UP_TO_100_ROW}`;

export const EBIT_AFTER_PRE_TCJA_NOL_APPLICATION_TITLE = 'EBIT After Pre-TCJA NOL Application';
export const EBIT_AFTER_PRE_TCJA_NOL_APPLICATION_ALIAS = 'ebit_after_pre_tcja_nol_application';
export const EBIT_AFTER_PRE_TCJA_NOL_APPLICATION_EXPR = `=@${EBIT_ROW}-@${NOL_BALANCE_APPLIED_UP_TO_100_ROW}`;

export const NOL_BALANCE_APPLIED_UP_TO_80_TITLE = 'NOL Balance Applied up to 80%';
export const NOL_BALANCE_APPLIED_UP_TO_80_ALIAS = 'nol_balance_applied_up_to_80';
export const NOL_BALANCE_APPLIED_UP_TO_80_EXPR = `=MAX(MIN(-@PREVCOL${POST_TAX_CUTS_JOBS_ACT_NOL_BALANCE_ROW},MIN(@${EBIT_ROW}*0.8-@${NOL_BALANCE_APPLIED_UP_TO_100_ROW},@${EBIT_AFTER_PRE_TCJA_NOL_APPLICATION_ROW})),0)`;

export const FULLY_TAXABLE_INCOME_TITLE = 'Fully Taxable Income';
export const FULLY_TAXABLE_INCOME_ALIAS = 'fully_taxable_income';
export const FULLY_TAXABLE_INCOME_EXPR = `=MAX(@${EBIT_AFTER_PRE_TCJA_NOL_APPLICATION_ROW}-@${NOL_BALANCE_APPLIED_UP_TO_80_ROW},0)`;

export const POST_TAX_CUTS_JOBS_ACT_NOL_BALANCE_TITLE = 'Post-Tax-Cuts-&-Jobs-Act NOL Balance';
export const POST_TAX_CUTS_JOBS_ACT_NOL_BALANCE_ALIAS = 'nol_ebit_post_tax_cut';
export const POST_TAX_CUTS_JOBS_ACT_NOL_BALANCE_EXPR = `=@PREVCOL${POST_TAX_CUTS_JOBS_ACT_NOL_BALANCE_ROW}+@${NOL_BALANCE_APPLIED_UP_TO_80_ROW}+IF(@${EBIT_AFTER_PRE_TCJA_NOL_APPLICATION_ROW}<0,@${EBIT_AFTER_PRE_TCJA_NOL_APPLICATION_ROW},0)`;

export const TOTAL_NOL_BALANCE_APPLIED_TITLE = 'Total NOL Balance Applied';
export const TOTAL_NOL_BALANCE_APPLIED_ALIAS = 'total_nol_balance_applied';
export const TOTAL_NOL_BALANCE_APPLIED_EXPR = `=SUM(@${NOL_BALANCE_APPLIED_UP_TO_100_ROW},@${NOL_BALANCE_APPLIED_UP_TO_80_ROW})`;

export const TAX_SAVINGS_AT_RATE_TITLE = 'Tax Savings at Rate of';
export const TAX_SAVINGS_AT_RATE_ALIAS = 'tax_savings_at_rate';
export const TAX_SAVINGS_AT_RATE_EXPR = `=@${TOTAL_NOL_BALANCE_APPLIED_ROW}*0.257`;

export const DISCOUNT_PERIODS_TITLE = 'Discount Periods (Mid-Year Convention)';
export const DISCOUNT_PERIODS_ALIAS = 'discount_periods';

export const DISCOUNT_FACTOR_TITLE = 'Discount Factor - Based on WACC of';
export const DISCOUNT_FACTOR_ALIAS = 'discount_factor';

export const PRESENT_VALUE_OF_NOL_TAX_SAVINGS_TITLE = 'Present Value of NOL Tax Savings';
export const PRESENT_VALUE_OF_NOL_TAX_SAVINGS_ALIAS = 'nol_present_value_savings';
export const PRESENT_VALUE_OF_NOL_TAX_SAVINGS_EXPR = `=@${TAX_SAVINGS_AT_RATE_ROW}*@${DISCOUNT_FACTOR_ROW}`;
