import React, { useCallback } from 'react';
import { Button, DialogActions, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from './Dialog';

const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: theme.spacing(2),
  },
  content: {
    backgroundColor: '#f0f4f6',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  contentSpaced: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  actions: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(2),
    color: '#000',
  },
  successButton: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  dangerButton: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const ConfirmationDialog = ({ open, title, maxWidth, children, onClose, actions, isLoading }) => {
  const classes = useStyles();

  const confirmAction = useCallback(
    async callback => {
      if (callback) {
        await callback();
      }
      onClose();
    },
    [onClose]
  );

  return (
    <Dialog title={title || 'Confirmation'} maxWidth={maxWidth} fullWidth onClose={onClose} open={open}>
      {isLoading && <LinearProgress color="primary" />}
      <div className={`${classes.content} ${maxWidth !== 'xs' && classes.contentSpaced}`}>{children}</div>
      <DialogActions className={classes.actions}>
        {actions?.map(action => (
          <Button
            key={action.label}
            variant={action.variant}
            disabled={action.disabled}
            color="primary"
            className={classes[`${action.type}Button`]}
            onClick={() => (action.callback ? confirmAction(action.callback) : onClose())}>
            <span className={classes.actionButtonLabel}>{action.label}</span>
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = {
  maxWidth: 'sm',
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.shape({}),
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool,
};

export default ConfirmationDialog;
