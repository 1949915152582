import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import LogInForm from 'pages/Login/LogInForm';
import TitleAndDescription from './TitleAndDescription';

const ProcessManagementLanding = ({ processTypeObject, location, history }) => {
  const [isLogInFormVisible, setIsLogInFormVisible] = useState(false);

  return (
    <Box width="100%" display="flex" justifyContent="center" alignItems="center">
      <Box>
        <TitleAndDescription processTypeObject={processTypeObject} />
        <LogInForm
          isProcessManagementForm={true}
          isLogInFormVisible={isLogInFormVisible}
          setIsLogInFormVisible={setIsLogInFormVisible}
          location={location}
          history={history}
        />
      </Box>
    </Box>
  );
};

ProcessManagementLanding.propTypes = {
  processTypeObject: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default ProcessManagementLanding;
