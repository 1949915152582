import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import { useFormat } from 'common/hooks';
import { LoadingSection } from 'components';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import { WaterfallCalculator } from 'dashboard409a/components';
import { IWaterfallState } from 'dashboard409a/states/features/waterfall';
import { RootStateType } from 'dashboard409a/states/store';
import { BreakpointChart } from './components/BreakpointAnalysisChart';
import { BreakpointChartProps } from './components/BreakpointAnalysisChart/types';
import { SubscriptionExpiredBox } from '../SubscriptionExpiredBox';
import { IWaterfallCalculatorProps } from '../WaterfallCalculator/types';

const Waterfall = () => {
  const { loading, waterfallBreakpoint, currentCaptable } = useSelector<RootStateType, IWaterfallState>(
    state => state.waterfall
  );
  const { currentCompany } = useSelector((state: RootStateType) => state.global);
  const subscriptionIsActive = currentCompany?.is_active;
  const displayExpired = !isUndefined(subscriptionIsActive) && subscriptionIsActive === false;
  const [format, formatDispatch] = useFormat({ units: REGULAR_UNIT });

  const waterfallCalculatorProps: IWaterfallCalculatorProps = {
    isLoading: loading,
    data: currentCaptable,
    format,
    formatDispatch,
  };

  const breakpointChartProps: BreakpointChartProps = {
    isLoading: loading,
    data: waterfallBreakpoint,
  };

  if (displayExpired) {
    return (
      <Grid spacing={2} justifyContent="center">
        <Card>
          <SubscriptionExpiredBox />
        </Card>
      </Grid>
    );
  }

  return loading || !waterfallBreakpoint ? (
    <LoadingSection />
  ) : (
    <Grid container spacing={2} justifyContent="flex-start">
      <Grid item xs={12} lg={6}>
        <Card>
          <CardHeader title="Breakpoint Analysis" />
          <CardContent>
            <BreakpointChart {...breakpointChartProps} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Card>
          <CardContent>
            <WaterfallCalculator {...waterfallCalculatorProps} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Waterfall;
