import { CAP_TABLE_ALIAS } from 'pages/Valuations/approaches/backsolveApproach/constants';

const afterCellsChanged = (initialChanges, cells) => {
  const changes = [...initialChanges];
  initialChanges.forEach(change => {
    const { cell, value } = change;
    if (cell.alias === CAP_TABLE_ALIAS) {
      const updatedCaptableCells = Object.values(cells)
        .filter(c => c.alias === CAP_TABLE_ALIAS && c.key !== cell.key && !cell.totalKey)
        .map(c => ({ cell: c, value }));
      // add in updatedCaptableCells to the changes array
      changes.push(...updatedCaptableCells);
    }
  });
  return changes;
};

export default afterCellsChanged;
