/**
 * @name GlobalTypes ActionTypes
 * @memberof module:common/actions
 * @type {ReduxAction}
 * @return {Object} Global Actions Types
 */

export const SHOW_COMPANY_DIALOG = 'SHOW_COMPANY_DIALOG';
export const SHOW_COMPANY_DIALOG_FOR_EDIT = 'SHOW_COMPANY_DIALOG_FOR_EDIT';
export const SHOW_FIRM_DRAWER = 'SHOW_FIRM_DRAWER';
export const SHOW_ALLOCATION_DRAWER = 'SHOW_ALLOCATION_DRAWER';
export const SHOW_LOADING_PROGRESS = 'SHOW_LOADING_PROGRESS';
export const SHOW_CAPTABLE_DRAWER = 'SHOW_CAPTABLE_DRAWER';
export const SHOW_FIRM_STEPPER = 'SHOW_FIRM_STEPPER';
export const SHOW_MEASUREMENT_DATES_LEDGER = 'SHOW_MEASUREMENT_DATES_LEDGER';
export const SHOW_APPROACH_DIALOG = 'SHOW_APPROACH_DIALOG';
export const SET_DIALOG_FLAG = 'SET_DIALOG_FLAG';
export const SET_FIELD_ATTRS = 'SET_FIELD_ATTRS';
