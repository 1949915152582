import { createStyles, makeStyles } from '@material-ui/styles';
import { COLUMN_INDICATOR_ADJUSTMENT } from 'components/ScalarSpreadsheet/components/ContentColumns';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    heading: {
      paddingBottom: 0,
      paddingLeft: COLUMN_INDICATOR_ADJUSTMENT,
      paddingTop: theme.spacing(1),
    },
  })
);

export default useStyles;
