import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import {
  CAP_TABLE_VERSIONS_BY_COMPANY_AND_MD_DEFAULT_ERROR_MESSAGE,
  CAP_TABLE_VERSIONS_BY_COMPANY_AND_MD_QUERY_KEY,
} from 'common/constants/services/companies';
import { CompaniesService } from 'services';
import { throwError } from 'utillities';
import {
  GetCapTableVersionsByCompanyAndMDHook,
  GetCapTableVersionsByCompanyAndMDParams,
  GetCapTableVersionsByCompanyAndMDResponse,
  UseGetCapTableVersionsByCompanyAndMD,
} from './types';

const getCapTableVersionsByCompanyAndMD = async (params: GetCapTableVersionsByCompanyAndMDParams) => {
  const { companyId, measurementDateId } = params;

  if (isNil(companyId) && isNil(measurementDateId)) return null;

  const companiesService = new CompaniesService();

  try {
    const capTableVersions = (await companiesService.getCapTableList(
      companyId,
      measurementDateId
    )) as GetCapTableVersionsByCompanyAndMDResponse;

    if (!capTableVersions) return throwError(CAP_TABLE_VERSIONS_BY_COMPANY_AND_MD_DEFAULT_ERROR_MESSAGE);

    return capTableVersions;
  } catch (error) {
    return throwError(CAP_TABLE_VERSIONS_BY_COMPANY_AND_MD_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetCapTableVersionsByCompanyAndMD: UseGetCapTableVersionsByCompanyAndMD = params => {
  const { company, measurementDate, shouldQueryAutomatically = true } = params;

  const companyId = company?.id;
  const measurementDateId = measurementDate?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<
    GetCapTableVersionsByCompanyAndMDHook['data'],
    Error
  >({
    enabled: !isNil(companyId) && !isNil(measurementDateId) && shouldQueryAutomatically,
    queryFn: () => getCapTableVersionsByCompanyAndMD({ companyId, measurementDateId }),
    queryKey: [CAP_TABLE_VERSIONS_BY_COMPANY_AND_MD_QUERY_KEY, companyId, measurementDateId],
    refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetCapTableVersionsByCompanyAndMD;
