import { uploadFile } from 'react-s3';
import { S3_BUCKET_NOT_CONFIGURED } from 'common/constants/user';
import BaseServiceRetryWrapper from './baseRetry';

class ImageUploadService extends BaseServiceRetryWrapper {
  uploadImage = file => {
    const awsConfig = {
      bucketName: process.env.REACT_APP_AWS_S3_BUCKET,
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    };
    const {
      bucketName,
      region,
      accessKeyId,
      secretAccessKey
    } = awsConfig;

    if (!bucketName || !region || !accessKeyId || !secretAccessKey) {
      return Promise.reject(S3_BUCKET_NOT_CONFIGURED);
    }
    return uploadFile(file, awsConfig);
  }
}

export default ImageUploadService;
