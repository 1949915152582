import React, { useContext } from 'react';
import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { TaskContext } from 'context';
import theme from 'theme';

const useStyles = makeStyles({
  circleNumberBox: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const CircularProgressWithLabel = ({ progress, numberStyles, circleSize }) => {
  const classes = useStyles();
  const { isMobile } = useContext(TaskContext);
  const { color: customColor, fontSize: customFontSize } = numberStyles || {
    color: theme.palette.primary[900],
    fontSize: '0.75rem',
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={100}
        size={isMobile ? '6rem' : circleSize || '3.75rem'}
        style={{
          color: theme.palette.grey[300],
          opacity: 0.4,
        }}
      />
      <CircularProgress
        variant="determinate"
        value={progress}
        size={isMobile ? '6rem' : circleSize || '3.75rem'}
        style={{
          color: theme.palette.secondary[600],
          position: 'absolute',
          left: 0,
        }}
      />
      <Box className={classes.circleNumberBox}>
        <Typography
          variant="caption"
          component="div"
          style={{
            fontWeight: 800,
            fontSize: isMobile ? '1.5rem' : customFontSize,
            color: customColor,
          }}>
          {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

CircularProgressWithLabel.propTypes = {
  progress: PropTypes.number.isRequired,
  numberStyles: PropTypes.object,
  circleSize: PropTypes.string,
};

export default CircularProgressWithLabel;
