export const ERROR_UPDATING_COMPANY_DETAILS = 'There was a problem while updating the company details';
export const SUCCESS_UPDATING_COMPANY_DETAILS = 'The company details were updated successfully';

export const ERROR_UPLOADING_LOGO = 'There was a problem while uploading the company logo';
export const ERROR_UPLOADING_ICON = 'There was a problem while uploading the company icon';

export const SUCCESS_CREATING_INFORMATION_REQUEST = 'Information request created successfully';
export const ERROR_CREATING_INFORMATION_REQUEST = 'Error sending information request';

export const SUCCESS_CREATING_NEW_DOCUMENTS = 'New documents added successfully';
export const ERROR_CREATING_NEW_DOCUMENTS = 'Error adding new documents';
export const SUCCESS_MODIFYING_INFORMATION_REQUEST = 'Information request was modified';
export const SUCCESS_DISMISSING_REQUESTED_DOCUMENT = 'Requested document was dismissed';
export const ERROR_DISMISSING_REQUESTED_DOCUMENT = 'Error dismissing requested document';
export const SUCCESS_DELETING_REQUESTED_QUESTION = 'Question removed successfully';
export const ERROR_DELETING_REQUESTED_QUESTION = 'Error removing question';

export const createItemSuccessMessage = (entity: string) => `${entity} created successfully`;
export const createItemErrorMessage = (entity: string) => `Error creating ${entity}`;
export const updateItemSuccessMessage = (entity: string) => `${entity} updated successfully`;
export const updateItemErrorMessage = (entity: string) => `Error updating ${entity}`;
