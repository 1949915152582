import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { TOGGLE_COLLAPSE } from 'common/actions/row-groups/types';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import SpreadsheetContext from 'components/Spreadsheet/context/SpreadsheetContext';
import { useRevealEllipsisInTooltip } from 'services/hooks';
import CollapsibleIcon from './components/CollapsibleIcon';
import RowTitle from './components/RowTitle';
import GridFormatter from './GridFormatter';

const useStyles = makeStyles(theme => ({
  label: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '14px',
    height: '100%',
    justifyContent: 'space-between',
    position: 'relative',
    textAlign: 'left',
    width: '100%',
  },
  collapseButton: {
    marginLeft: `${theme.spacing(1)}px`,
    marginRight: 0,
  },
  titleRow: {
    color: theme.palette.primary.main,
    cursor: 'cell',
    fontWeight: 700,
  },
  isValuedCompanyCell: {
    fontWeight: 'bold',
  },
}));

const GridRowLabel = ({ cell, currencyFormatter, unitsFormatter, formatterCell }) => {
  const classes = useStyles();
  const context = FeaturedSpreadsheetContext || SpreadsheetContext;
  const { rowGroups, setRowGroups } = useContext(context);
  const { tooltipRef, width } = useRevealEllipsisInTooltip();

  const toggleRows = () => {
    if (setRowGroups) setRowGroups({ type: TOGGLE_COLLAPSE, row: cell.alias, collapseGroups: cell.collapseGroups });
  };

  return (
    <div ref={tooltipRef} className={classes.label}>
      {formatterCell && <GridFormatter currencyFormatter={currencyFormatter} unitsFormatter={unitsFormatter} />}
      <RowTitle
        cellProps={{
          title: cell.value,
          link: cell?.link?.url,
          displayBold: cell?.displayBold,
        }}
        width={width}
        classes={{
          titleRow: classes.titleRow,
          isValuedCompanyCell: classes.isValuedCompanyCell,
        }}
      />
      {!cell.sideComponent && cell.hasSubRows && rowGroups && (
        <CollapsibleIcon rowGroups={rowGroups} alias={cell.alias} toggleRows={toggleRows} />
      )}
      {cell.sideComponent && (
        <div
          onClick={toggleRows}
          onKeyDown={event => {
            if (event.key === 'Enter' || event.key === ' ') {
              toggleRows();
            }
          }}
          tabIndex={0}
          role="button">
          {cell.sideComponent}
        </div>
      )}
    </div>
  );
};

GridRowLabel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  cell: PropTypes.object,
  formatterCell: PropTypes.bool,
  currencyFormatter: PropTypes.bool,
  unitsFormatter: PropTypes.bool,
};

export default GridRowLabel;
