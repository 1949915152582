import React, { FC, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { FINANCIALS_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { VALUATIONS_SLUG } from 'common/constants/valuations';
import { useFormat } from 'common/hooks';
import { UseFormatValues } from 'common/types/format';
import { CustomScalarSpreadsheet } from 'components';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_SLUG } from 'pages/ValuationsAllocation/common/constants/weightedShareValues';
import { getObjectValue, getStringValue } from 'utillities';
import useStyles from './styles';
import { FundSharesProps } from './types';

const ALLOCATION_WEIGHTED_SHARE_VALUES_CONTAINER_CLASS = `${VALUATIONS_SLUG}-${ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_SLUG}-container`;

const FundShares: FC<FundSharesProps> = props => {
  const {
    isLoading,
    isLoadingValuationSummary,
    isSaving,
    onSpreadsheetChange,
    weightedShareValuesSpreadsheet,
    workbook,
  } = props;

  const { data: weightedShareValuesSpreadsheetData } = getObjectValue(weightedShareValuesSpreadsheet);

  const classes = useStyles();

  const [format, formatDispatch] = useFormat({ page: FINANCIALS_CURRENCY_PAGE }) as UseFormatValues;

  // Handle transition from GridSkeleton to Weighted Share Values
  const isLoadingWeightedShareValues = useMemo(
    () => isEmpty(weightedShareValuesSpreadsheetData),
    [weightedShareValuesSpreadsheetData]
  );

  // Create Fund Shares Sheet props
  const fundSharesSheetProps = useMemo(() => {
    if (weightedShareValuesSpreadsheet) {
      return {
        ...weightedShareValuesSpreadsheet,
        format,
        formatDispatch,
        key: getStringValue(weightedShareValuesSpreadsheet?.name),
        onChange: onSpreadsheetChange,
        sheet: weightedShareValuesSpreadsheet,
        workbook,
      } as unknown as typeof ScalarSpreadsheet;
    }

    return null;
  }, [format, formatDispatch, onSpreadsheetChange, weightedShareValuesSpreadsheet, workbook]);

  // Loading Weighted Share Values
  if (isLoadingWeightedShareValues || isLoadingValuationSummary) {
    return (
      <Grid className={clsx(classes.container, ALLOCATION_WEIGHTED_SHARE_VALUES_CONTAINER_CLASS)}>
        <GridSkeleton />
      </Grid>
    );
  }

  return (
    <Grid className={clsx(classes.container, ALLOCATION_WEIGHTED_SHARE_VALUES_CONTAINER_CLASS)}>
      <CustomScalarSpreadsheet
        {...fundSharesSheetProps}
        isLoading={isLoading || isLoadingWeightedShareValues}
        isSaving={isSaving}
      />
    </Grid>
  );
};

export default FundShares;
