import React, { FC, useContext, useState } from 'react';
import { Avatar, Box, Chip, Divider, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import uuid from 'react-uuid';
import { ANSWERED, NOT_ANSWERED } from 'common/constants/process-management';
import { ExtraActionsMenu } from 'components';
import { ConfirmationDialog } from 'components/Dialogs';
import { InformationDashboard409AContext } from 'context/InformationDashboard409AContext';
import {
  ERROR_DELETING_REQUESTED_QUESTION,
  SUCCESS_DELETING_REQUESTED_QUESTION,
  SUCCESS_MODIFYING_INFORMATION_REQUEST,
} from 'dashboard409a/common/constants';
import { modifyExistingRequest } from 'dashboard409a/services/dashboard';
import theme from 'theme';
import { CurrentRequestedQuestionsListProps, QuestionProgress } from './types';

const CurrentRequestedQuestionsList: FC<CurrentRequestedQuestionsListProps> = ({ questions }) => {
  const responsibleUsers = questions?.responsible_users || [];
  const lastTaskId = questions?.id || null;
  const [isOpenDeleteQuestionRequestModal, setIsOpenDeleteQuestionRequestModal] = useState(false);
  const [questionToRemove, setQuestionToRemove] = useState<QuestionProgress | null>(null);
  const { handleGetCurrentInformationRequests } = useContext(InformationDashboard409AContext);
  const { enqueueSnackbar } = useSnackbar();

  const openQuestionRequestModal = (document: QuestionProgress) => {
    setQuestionToRemove(document);
    setIsOpenDeleteQuestionRequestModal(true);
  };

  const removeQuestion = async (question: QuestionProgress) => {
    try {
      if (!lastTaskId || !question.id) {
        enqueueSnackbar(ERROR_DELETING_REQUESTED_QUESTION, { variant: 'error' });
        return false;
      }
      const data = {
        task: lastTaskId,
        questions_to_delete: [question.id],
        requested_documents: [],
      };
      const response = await modifyExistingRequest(data);

      if (response?.message === SUCCESS_MODIFYING_INFORMATION_REQUEST) {
        enqueueSnackbar(SUCCESS_DELETING_REQUESTED_QUESTION, { variant: 'success' });
        return true;
      }
      enqueueSnackbar(ERROR_DELETING_REQUESTED_QUESTION, { variant: 'error' });
      return false;
    } catch (error) {
      enqueueSnackbar(ERROR_DELETING_REQUESTED_QUESTION, { variant: 'error' });
      return false;
    } finally {
      setQuestionToRemove(null);
      setIsOpenDeleteQuestionRequestModal(false);
      handleGetCurrentInformationRequests();
    }
  };

  const optionsForExtraActionsMenu = (questionToRemoveParam: QuestionProgress | null) => [
    {
      label: 'EDIT QUESTION',
      callback: () => '',
      isActive: false,
    },
    {
      label: 'DELETE QUESTION',
      callback: () => {
        if (questionToRemoveParam !== null) {
          openQuestionRequestModal(questionToRemoveParam);
        }
      },
      isActive: true,
    },
  ];

  const actions = [
    {
      label: 'CANCEL',
      variant: 'outlined',
      disabled: false,
      callback: () => setIsOpenDeleteQuestionRequestModal(false),
    },
    {
      label: 'YES, DELETE IT',
      variant: 'contained',
      disabled: false,
      callback: () => {
        if (questionToRemove !== null) {
          removeQuestion(questionToRemove);
        }
      },
    },
  ];

  const stringAvatar = (name: string) => ({
    style: {
      bgcolor: theme.palette.primary.main,
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  });

  return (
    <>
      <List>
        {questions?.progress.map((question, i) => (
          <div key={question.id}>
            <ListItem>
              <Typography component="div">
                <Box fontWeight="fontWeightBold">{`${i + 1} - ${question.name}`}</Box>
                <Box display="flex" alignItems="center" marginTop={1}>
                  <Chip
                    label={isEmpty(question.answers) ? NOT_ANSWERED : ANSWERED}
                    variant={isEmpty(question.answers) ? 'outlined' : 'default'}
                  />
                  {!isEmpty(responsibleUsers)
                    && responsibleUsers.map(user => (
                      <Tooltip key={uuid()} title={user.full_name} placement="top">
                        <Avatar
                          {...stringAvatar(user.full_name)}
                          style={{ marginLeft: '0.5rem', width: '2rem', height: '2rem', fontSize: '1rem' }}
                          alt={user.full_name}
                        />
                      </Tooltip>
                    ))}
                </Box>
              </Typography>
              <Box marginLeft="auto">
                <ExtraActionsMenu options={optionsForExtraActionsMenu(question)} dotsColor={theme.palette.gray[500]} />
              </Box>
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
      <ConfirmationDialog
        open={isOpenDeleteQuestionRequestModal}
        title="Do you think this question will not be necessary?"
        onClose={() => setIsOpenDeleteQuestionRequestModal(false)}
        actions={actions}>
        <span>
          This modification will affect the original request to the company user, eliminating the question from its
          portal.
        </span>
      </ConfirmationDialog>
    </>
  );
};

export default CurrentRequestedQuestionsList;
