import parser from 'pages/Valuations/approaches/discountCashFlow/dcfTerminalValue/util/getParser';
import getCombinedColumns from './getCombinedColumns';
import rowConfig from './rowConfig';

const parseMixin = (cell, cfTVTRMSheetName, dcfTVTEMSheetName) => {
  switch (cell.rowNumber) {
    case 3: {
      if (cell.columnLegend === 'A') {
        return {
          ...cell,
          expr: `=${cfTVTRMSheetName}.A2`,
        };
      }
      if (cell.columnLegend === 'B') {
        return {
          ...cell,
          expr: `=${dcfTVTEMSheetName}.A2`,
        };
      }
      return cell;
    }
    case 4: {
      if (cell.columnLegend === 'A') {
        return {
          ...cell,
          expr: `=${cfTVTRMSheetName}.A3`,
          isExpr: true,
        };
      }
      if (cell.columnLegend === 'B') {
        return {
          ...cell,
          expr: `=${dcfTVTEMSheetName}.A3`,
          isExpr: true,
        };
      }
      return cell;
    }
    case 5: {
      return { ...cell, readOnly: true };
    }
    case 1:
    case 7:
    case 8:
    case 9:
    case 10:
      if (cell.columnLegend === 'B') {
        return {
          ignoreCell: true,
        };
      }
      return {
        ...cell,
        colSpan: 2,
      };
    default:
      return cell;
  }
};

const getConfig = ({ columnsProps }) => ({
  className: 'revenue-ebitda-multiple-terminal-table',
  rowConfig: rowConfig(columnsProps),
  parser: parser(parseMixin, columnsProps.dcfTVTRMSheetName, columnsProps.dcfTVTEMSheetName),
  columns: getCombinedColumns(columnsProps),
});

export default getConfig;
