// TODO: Check if we need to remove this file

import { BREAKPOINT_ANALYSIS_SLUG, CAP_TABLE_SLUG, FUND_OWNERSHIP_SLUG } from './cap-table';
import { COMPANIES_SUMMARY_SLUG } from './companies';
import { VALUATIONS_SLUG } from './valuations';

export const homePath = '/';
export const notFoundPath = '/not-found';
export const forbiddenPath = '/not-permission';
export const logIn = '/sign-in';
export const firmPath = slug => `/firms/${slug}`;
export const baseCompanyPath = (firmSlug, companySlug) => `${firmPath(firmSlug)}/companies/${companySlug}`;
export const companySummaryPath = (firmSlug, companySlug) =>
  `${baseCompanyPath(firmSlug, companySlug)}/${COMPANIES_SUMMARY_SLUG}`;
export const captablePath = (firmSlug, companySlug) => `${baseCompanyPath(firmSlug, companySlug)}/${CAP_TABLE_SLUG}`;
export const fundOwnershipPath = (firmSlug, companySlug) =>
  `${baseCompanyPath(firmSlug, companySlug)}/${FUND_OWNERSHIP_SLUG}`;
export const breakpointAnalysisPath = (firmSlug, companySlug) =>
  `${baseCompanyPath(firmSlug, companySlug)}/${BREAKPOINT_ANALYSIS_SLUG}`;
export const fundSummary = (firmSlug, fundSlug) => `/firms/${firmSlug}/funds/${fundSlug}/summary`;
export const userProfileTab = (firmSlug, tab, userId) => {
  if (userId) {
    return `/firms/${firmSlug}/users/${userId}/${tab}`;
  }
  return `/firms/${firmSlug}/users/${tab}`;
};

export const valuationPath = (firmSlug, companySlug) => `${baseCompanyPath(firmSlug, companySlug)}/${VALUATIONS_SLUG}`;
export const valuationPathByDateAndVersion = (firmSlug, companySlug, dateSlug, versionSlug) =>
  `${valuationPath(firmSlug, companySlug)}?date=${dateSlug}&version=${versionSlug}`;
