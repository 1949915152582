import { isEmpty } from 'lodash';
import { ValuationsApproach } from 'common/types/valuation';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getObjectValue } from 'utillities';
import { PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX } from './common/constants/performanceMetrics';
import { PERFORMANCE_METRICS_SPREADSHEET_TABLE_TERMS } from './common/constants/performanceMetrics/sheetConfigs';
import { createColumns, customParser, rowConfig } from './config';
import reverseParser from './config/reverseParser/reverseParser';
import afterCellChanged from './config/utils/afterCellChanged';
import conditions from './config/utils/conditions';
import { CreatePerformanceMetricsDataParams } from './types';

const createPerformanceMetrics = (params: CreatePerformanceMetricsDataParams) => {
  const { approach, companyName, customClasses, financials, isDisabled } = params;

  const { valuations_approach_gpc: valuationsApproachGpc } = getObjectValue(approach);

  // Generate Approach table name
  const name = getApproachTableName({
    approach: approach as ValuationsApproach,
    tableSuffix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
  });

  // Create columns for Performance Metrics Spreadsheet
  const columns
    = !isEmpty(valuationsApproachGpc) && !isEmpty(financials)
      ? createColumns({
        valuationsApproachGpc,
        financials,
      })
      : [];

  const config = rowConfig({
    companyName,
    customClasses,
    valuationsApproachGpc,
    isDisabled,
  });

  // Create Spreadsheet config
  const spreadsheet = !isEmpty(columns)
    ? new SpreadsheetConfig({
      columns,
      currencyFormatter: true,
      format: undefined,
      name,
      page: PERFORMANCE_METRICS_SPREADSHEET_TABLE_TERMS.tableName as unknown as SpreadsheetConfig['page'],
      parser: customParser,
      rowConfig: config,
      showToolbar: true,
      tableData: { columns, isDisabled },
      tableTerms: PERFORMANCE_METRICS_SPREADSHEET_TABLE_TERMS as unknown as SpreadsheetConfig['tableTerms'],
      totalParser: undefined,
      unitsFormatter: true,
      conditions: conditions as unknown as SpreadsheetConfig['conditions'],
      afterCellChanged: afterCellChanged as unknown as SpreadsheetConfig['afterCellChanged'],
      reverseParser: reverseParser as unknown as SpreadsheetConfig['reverseParser'],
      initialObj: approach as unknown as SpreadsheetConfig['initialObj'],
    })
    : undefined;

  return { spreadsheet };
};

export default createPerformanceMetrics;
