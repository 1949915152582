const getRowConfig = referencedSecurities => [
  {
    largeHeader: true,
    skipParse: true,
  },
  ...referencedSecurities.map(security => ({
    ignoreRowCopy: true,
    ...security
  })),
  {
    className: 'border-top',
    skipParse: true,
  }
];

export default getRowConfig;
