/**
 * @name Global Action
 * @memberof module:common/actions
 * @type {ReduxAction}
 * @return {Object} Global Actions
 */
import {
  SET_DIALOG_FLAG,
  SET_FIELD_ATTRS,
  SHOW_ALLOCATION_DRAWER,
  SHOW_APPROACH_DIALOG,
  SHOW_CAPTABLE_DRAWER,
  SHOW_COMPANY_DIALOG,
  SHOW_COMPANY_DIALOG_FOR_EDIT,
  SHOW_FIRM_DRAWER,
  SHOW_FIRM_STEPPER,
  SHOW_LOADING_PROGRESS,
} from './types';

/**
 * @function
 * @name showDrawer
 * @description bolean control open and close drawer.
 * @param {BOOLEAN} openCompanyDrawer  - reducer state
 * @return {OBJECT} Action context
 */

export const showCompanyDialog = openCompanyDialog => ({ type: SHOW_COMPANY_DIALOG, openCompanyDialog });

export const showCompanyDialogForEdit = showCompanyDialogForEdit => ({
  type: SHOW_COMPANY_DIALOG_FOR_EDIT,
  openCompanyDialog: true,
  showCompanyDialogForEdit,
});

/**
 * @function
 * @name showFirmDrawer
 * @description bolean control open and close drawer.
 * @param {BOOLEAN} openFirmDrawer  - reducer state
 * @return {OBJECT} Action context
 */
export const showFirmDrawer = openFirmDrawer => ({ type: SHOW_FIRM_DRAWER, openFirmDrawer });

/**
 * @function
 * @name showAllocationDrawer
 * @description bolean control open and close drawer.
 * @param {BOOLEAN} openFundDrawer  - reducer state
 * @return {OBJECT} Action context
 */
export const showAllocationDrawer = openAllocationDrawer => ({ type: SHOW_ALLOCATION_DRAWER, openAllocationDrawer });
/**
 * @function
 * @name showCaptableDrawer
 * @description bolean control open and close drawer.
 * @param {BOOLEAN} openCaptableDrawer  - reducer state
 * @return {OBJECT} Action context
 */
export const showCaptableDrawer = openCaptableDrawer => ({ type: SHOW_CAPTABLE_DRAWER, openCaptableDrawer });

/**
 * @function
 * @name showLoadingProgress
 * @description bolean control show or hide loading progress.
 * @param {BOOLEAN} isShowLoadingProgress  - reducer state
 * @return {OBJECT} Action context
 */
export const showLoadingProgress = isShowLoadingProgress => ({ type: SHOW_LOADING_PROGRESS, isShowLoadingProgress });

export const showFirmStepper = isFirmStepperVisible => ({ type: SHOW_FIRM_STEPPER, isFirmStepperVisible });

export const showApproachDialog = openApproachDialog => ({ type: SHOW_APPROACH_DIALOG, openApproachDialog });

export const setDialogFlag = dialogFlag => ({ type: SET_DIALOG_FLAG, dialogFlag });

export const setFieldAttrs = fieldAttributes => ({ type: SET_FIELD_ATTRS, fieldAttributes });
