import { times } from 'lodash';
import { securityTypes } from 'common/constants';
import { toString } from 'utillities';

// CAP-TABLE
// 15.  Seniority - LiquidationPriority

export const getPreferredSecurities = ({ columns }) =>
  columns.filter(col =>
    [securityTypes.PREFERRED_STOCK, securityTypes.CONVERTIBLE_NOTES].includes(Number(col.security_type))
  ) || [];
export const seniorityOptions = ({ cell: cellInfo }) => {
  const {
    sheet: { columns },
  } = cellInfo;
  const tmpOptions = [];

  const preferredSecurities = getPreferredSecurities({ columns });

  if (preferredSecurities.length > 0) {
    times(preferredSecurities.length, i => {
      const priority = i + 1;
      tmpOptions.push({
        value: priority,
        label: priority,
      });
    });
  }
  return tmpOptions;
};

/* FUND-OWNERSHIP
 * 02.  Fund */
export const fundOptions = fundList => {
  const tmpOptions = [];

  if (Array.isArray(fundList)) {
    fundList.forEach(fund => {
      tmpOptions.push({
        value: fund.id,
        label: fund.name,
      });
    });
  }

  return tmpOptions;
};

// 03.  Security type
export const securityOptions = captableInfo => {
  const tmpOptions = [];
  const securities = captableInfo?.securities || [];
  const unissuedOptions = securityTypes.UNISSUED_OPTIONS;
  const optList = securities.filter(s => toString(s.security_type) !== toString(unissuedOptions));

  if (Array.isArray(optList)) {
    optList.forEach(opt => {
      if (opt.convertible_notes.length === 0) {
        tmpOptions.push({
          value: opt.id,
          label: opt.name,
        });
      }
    });
  }

  return tmpOptions;
};
