import React from 'react';
import { NOLCarryoverLedger } from 'pages/Valuations/approaches/discountCashFlow/components/NOLCarryoverLedger';
import WACCLedger from 'pages/Valuations/approaches/discountCashFlow/components/WACCLedger';
import { TOTAL_CASH_FLOWS_ROW_NUMBER } from 'pages/Valuations/approaches/discountCashFlow/dcf/config/constants';
import { PRESENT_VALUE_OF_NOL_TAX_SAVINGS_ROW } from 'pages/Valuations/approaches/discountCashFlow/dcfNOLCarryover/config/constants';
import { NOL_ALIAS, SUMMED_PRESENT_VALUE_ALIAS, WACC_ALIAS } from 'pages/Valuations/util/constants';
import { alphabetGenerator } from 'utillities/alphabet-utilities';

export default async ({ columns = [], rowConfig, dcfWacc, dcfNol, dcf }) => {
  let cells = {};
  const alphabet = alphabetGenerator([], columns.length);
  const dcfAlphabet = alphabetGenerator([], dcf.columns.length);
  const getExpr = (expr, columnLegend) => expr.replace(/@/g, `${columnLegend}`);

  rowConfig.forEach((row, rowIdx) => {
    columns.forEach((col, colIdx) => {
      const rowNumber = rowIdx + 1;
      const columnLegend = alphabet[colIdx];
      const key = columnLegend + rowNumber;
      let dialog = null;
      let expr = getExpr(row.expr, columnLegend);
      const value = col[row.alias];

      switch (row.alias) {
        case WACC_ALIAS: {
          dialog = {
            content: <WACCLedger dcfWacc={dcfWacc} />,
          };
          expr = `=${dcfWacc.name}.wacc`;
          break;
        }
        case NOL_ALIAS: {
          dialog = {
            content: <NOLCarryoverLedger dcfNol={dcfNol} />,
          };
          const dcfNolLength = dcfNol.columns.length - 1;
          if (dcfNolLength > 0) {
            const nolTaxSavingSumFormula = dcfAlphabet.reduce((formula, columnLegend, index) => {
              if (index > 0) {
                return index === 1
                  ? `${formula}${dcfNol.name}.${columnLegend}${PRESENT_VALUE_OF_NOL_TAX_SAVINGS_ROW}`
                  : `${formula}+${dcfNol.name}.${columnLegend}${PRESENT_VALUE_OF_NOL_TAX_SAVINGS_ROW}`;
              }
              return formula;
            }, '=');
            expr = nolTaxSavingSumFormula;
          }
          break;
        }
        case SUMMED_PRESENT_VALUE_ALIAS: {
          const dcfLength = dcf.columns.length - 1;
          if (dcfLength > 0) {
            const summedPresentValueFormula = dcfAlphabet.reduce((formula, columnLegend, index) => {
              if (index > 0) {
                return index === 1
                  ? `${formula}${dcf.name}.${columnLegend}${TOTAL_CASH_FLOWS_ROW_NUMBER}`
                  : `${formula}+${dcf.name}.${columnLegend}${TOTAL_CASH_FLOWS_ROW_NUMBER}`;
              }
              return formula;
            }, '=');
            expr = summedPresentValueFormula;
          }
          break;
        }
        default:
          break;
      }

      cells = {
        ...cells,
        [key]: {
          ...row,
          expr,
          key,
          columnLegend,
          value,
          dialog,
          className: '',
          alias: row.alias || '',
        },
      };
    });
  });

  return cells;
};
