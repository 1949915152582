import React, { useEffect, useState } from 'react';
import { Box, Grid, Tooltip } from '@material-ui/core';
import { InfoTwoTone as InfoIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ENTER_DATA } from 'common/constants/inputs';
import { useStore } from 'common/store';
import CompGroupLabel from 'components/FeaturedSpreadsheet/components/CompGroupLabel';
import RowLabelTooltip from 'components/RowLabelTooltip/RowLabelTooltip';
import { useRevealEllipsisInTooltip } from 'services/hooks';

const ICON_HEIGHT = 24;

const useStyles = makeStyles(theme => ({
  fullCell: {
    height: '28px',
  },
  contentValue: {
    display: 'flex',
    textAlign: 'start',
  },
  iconContainer: {
    background: `linear-gradient(90deg, #ffffffe6 0%, ${theme.palette.white} 50%)`,
    display: 'flex',
    opacity: 0,
    paddingLeft: theme.spacing(2.5),
    position: 'absolute',
    right: '-100%',
    top: `calc(50% - (${ICON_HEIGHT}px / 2))`,
    transition: 'ease all 0.25s',
    '&.active': {
      opacity: 1,
      right: 5,
    },
  },
  icon: {
    fill: `${theme.palette.cellIconColor} !important`,
    cursor: 'pointer',
  },
  textContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
}));

const CompGroupRowHeader = ({
  value,
  compGroupData,
  comparisonData,
  allowShowCompanyDialog,
  isGPC = false,
  cell,
  companyDialogComponent,
}) => {
  const classes = useStyles(isGPC);
  const [isHovering, setIsHovering] = useState(false);
  const [openCompanyInfoDialog, setOpenCompanyInfoDialog] = useState(false);
  const { tooltipRef, width } = useRevealEllipsisInTooltip();
  const [
    {
      companyInfo: { financials_currency: financialsCurrency },
    },
  ] = useStore();
  const CompanyDialogComponent = companyDialogComponent;

  const handleClick = () => {
    setOpenCompanyInfoDialog(true);
  };

  useEffect(() => {
    if (!openCompanyInfoDialog) {
      setIsHovering(false);
    }
  }, [openCompanyInfoDialog]);

  const titleValue = value || cell?.value || ENTER_DATA;
  const VIEW_COMPANY_INFO = companyName => `View ${companyName} info`;

  return (
    <div onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      <Box ref={tooltipRef} className={classes.fullCell} display="flex" justifyContent="flex-start" alignItems="center">
        <div className={classes.contentValue}>
          <Grid className={classes.textContainer}>
            {compGroupData && <CompGroupLabel compGroupData={compGroupData} setCompanyInfoHovering={setIsHovering} />}
            <RowLabelTooltip title={titleValue} containerWidth={width}>
              <span>{titleValue}</span>
            </RowLabelTooltip>
          </Grid>
          {allowShowCompanyDialog && companyDialogComponent && (
            <>
              <Grid className={clsx(classes.iconContainer, { active: isHovering })}>
                <Tooltip placement="top-end" title={VIEW_COMPANY_INFO(titleValue)} arrow>
                  <InfoIcon className={classes.icon} onClick={handleClick} />
                </Tooltip>
              </Grid>
              <CompanyDialogComponent
                openDialog={openCompanyInfoDialog}
                setOpenDialog={setOpenCompanyInfoDialog}
                data={comparisonData}
                financialsCurrency={financialsCurrency}
              />
            </>
          )}
        </div>
      </Box>
    </div>
  );
};

CompGroupRowHeader.propTypes = {
  value: PropTypes.string,
  compGroupData: PropTypes.object,
  comparisonData: PropTypes.object,
  allowShowCompanyDialog: PropTypes.bool,
  isGPC: PropTypes.bool,
  cell: PropTypes.object,
  companyDialogComponent: PropTypes.func,
};

export default CompGroupRowHeader;
