import BaseServiceRetryWrapper from './baseRetry';
import { USERS } from '../common/endpoints';

class UsersService extends BaseServiceRetryWrapper {
  getUsers = firmId => {
    const usersUrl = firmId ? `${USERS.getUsers()}?firm__id=${firmId}` : USERS.getUsers();
    return this.get(usersUrl);
  };

  getUserProfileById = userId => this.get(USERS.userProfileById(userId));

  getUserPermissions = (userId, firmId) => this.get(USERS.userPermissions(userId, firmId));

  getLowerUserPermissions = userId => this.get(USERS.lowerUserPermissions(userId));

  create = data => this.post(USERS.create(), data);

  savePermissions = permissions => this.put(USERS.savePermissions(), permissions);

  updateUserProfile = (userId, payload) => this.put(USERS.updateUserProfile(userId), payload);

  deleteUser = userId => this.delete(USERS.deleteUser(userId));

  createBulkUsers = data => this.post(USERS.createBulkUsers(), data);
}

export default UsersService;
