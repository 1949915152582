import React from 'react';
import { Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

const AddRowButton = ({ addRow, tableTerms, disabled }) => (
  <Button size="small" variant="outlined" color="primary" startIcon={<AddIcon />} onClick={addRow} disabled={disabled}>
    Add {tableTerms.columnName}
  </Button>
);

AddRowButton.propTypes = {
  addRow: PropTypes.func,
  tableTerms: PropTypes.object,
  disabled: PropTypes.bool,
};

export default AddRowButton;
