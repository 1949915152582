import React from 'react';

const UnsavedChanges = React.createContext({
  action: false,
  setAction: () => null,
  showDialog: () => null,
  cleanAction: () => null,
});

export default UnsavedChanges;
