import { FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE } from 'pages/ValuationsAllocation/common/constants/futureExit/sheetAliases';
import { parseCellValue } from 'pages/ValuationsAllocation/util';
import { getNumberValue, getObjectValue } from 'utillities';
import { CustomReverseParserParams } from './types';

const customReverseParser = (params: CustomReverseParserParams) => {
  const { cells, fieldAttributes, approach } = getObjectValue(params);
  const { valuations_approach_future_exit: valuationFEApproach } = getObjectValue(approach);
  const updatedFEApproach = getObjectValue(valuationFEApproach);

  Object.values(getObjectValue(cells)).forEach(cell => {
    const { alias } = getObjectValue(cell);

    if (alias === FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE) {
      // Handle Enterprise Value
      const fieldDecimalPlaces = getNumberValue(fieldAttributes?.discount_rate?.decimal_places);
      const updatedCellValue = parseCellValue({ cell, fieldDecimalPlaces });

      updatedFEApproach.discount_rate = updatedCellValue;
    }
  });
};

export default customReverseParser;
