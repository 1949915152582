import { isEmpty, isUndefined } from 'lodash';

const calculateWeightedPerShareValues = (
  methodologiesPerShareValues,
  appliedMethodologies,
  useSecurityObject = false
) =>
  methodologiesPerShareValues?.reduce((nrprev, nrcurrent) => {
    const shareValues = nrcurrent.map(s => {
      const currentAllocationData = appliedMethodologies.find(
        am => Number(am.allocation_method) === Number(s.allocationMethod)
      );
      const value = Number(s.value) * Number(currentAllocationData?.weight ?? 0);
      if (useSecurityObject) {
        return {
          allocationMethod: Number(s.allocationMethod),
          securityId: Number(s.security.id),
          security: { name: s.security.name, id: s.security.id },
          value,
        };
      }
      return {
        allocationMethod: Number(s.allocationMethod),
        securityId: Number(s.security.id),
        value,
      };
    });
    if (isEmpty(nrprev)) {
      return shareValues;
    }
    return nrprev.map(x => {
      const prevObj = useSecurityObject
        ? shareValues.find(y => y.security.id === x.security.id)
        : shareValues.find(y => y.securityId === x.securityId);
      return {
        ...x,
        value: !isUndefined(prevObj?.value) ? Number(prevObj.value) + Number(x.value) : 0,
      };
    });
  }, []);

export default calculateWeightedPerShareValues;
