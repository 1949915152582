const transformPermissions = ({ permissions }) =>
  permissions?.map(permission => ({
    id: permission.id,
    object_type: permission.feature_object?.object_type,
    object_id: permission.feature_object?.object_id,
    access_type: permission.access_type,
    remove_permission: permission.remove_permission,
    firm_role: permission.firm_role,
  }));

export default transformPermissions;
