// Aliases

// Modified Present Equity Value
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE
  = 'FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_EXIT_DATE
  = 'FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_EXIT_DATE';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS
  = 'FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_HEADER_TITLE
  = 'FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_HEADER_TITLE';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_LESS_CASH
  = 'FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_LESS_CASH';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_MEASUREMENT_DATE
  = 'FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_MEASUREMENT_DATE';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PLUS_DEBT
  = 'FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PLUS_DEBT';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_ENTERPRISE_VALUE
  = 'FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_ENTERPRISE_VALUE';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_EQUITY_VALUE
  = 'FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_EQUITY_VALUE';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_YEARS_UNTIL_EXIT
  = 'FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_YEARS_UNTIL_EXIT';

// Future Equity Value
export const FE_FUTURE_EQUITY_SPREADSHEET_HEADER_TITLE = 'FE_FUTURE_EQUITY_SPREADSHEET_HEADER_TITLE';
export const FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE = 'FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE';
export const FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE_CUSTOM_KEY = 'EXIT_DATE';
export const FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE = 'FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE';
export const FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE_CUSTOM_KEY = 'METRIC_VALUE';
export const FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH = 'FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH';
export const FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE = 'FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE';
export const FE_FUTURE_EQUITY_SPREADSHEET_ENTERPRISE_VALUE = 'FE_FUTURE_EQUITY_SPREADSHEET_ENTERPRISE_VALUE';
export const FE_FUTURE_EQUITY_SPREADSHEET_CASH = 'FE_FUTURE_EQUITY_SPREADSHEET_CASH';
export const FE_FUTURE_EQUITY_SPREADSHEET_PLUS_CASH_CUSTOM_KEY = 'PLUS_CASH';
export const FE_FUTURE_EQUITY_SPREADSHEET_DEBT = 'FE_FUTURE_EQUITY_SPREADSHEET_DEBT';
export const FE_FUTURE_EQUITY_SPREADSHEET_LESS_DEBT_CUSTOM_KEY = 'LESS_DEBT';
export const FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_EQUITY_VALUE = 'FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_EQUITY_VALUE';
export const FE_FUTURE_EQUITY_SPREADSHEET_DILUTION_FUTURE_INVESTMENTS
  = 'FE_FUTURE_EQUITY_SPREADSHEET_DILUTION_FUTURE_INVESTMENTS';
export const FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS
  = 'FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS';
export const FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS_CUSTOM_KEY = 'FUTURE_VALUE';

// Allocation Method
// CSE
export const FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD = 'FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD';
export const FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE = 'FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE';
// OPM
export const FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS = 'FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS';
export const FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS = 'FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS';
export const FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_DATE = 'FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_DATE';
export const FE_ALLOCATION_METHOD_SPREADSHEET_RISK_FREE_RATE = 'FE_ALLOCATION_METHOD_SPREADSHEET_RISK_FREE_RATE';
export const FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE = 'FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE';
export const FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY = 'FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY';
