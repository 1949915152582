import { isNull } from 'lodash';
import { DRAGGING, DRAGGING_ELEMENT } from 'common/constants/documents';

const useFolderValidations = () => {
  const isFolder = elementType => elementType === 'folder';

  const getDraggingFolderInfo = () => {
    const currentParentFolder = localStorage.getItem(DRAGGING_ELEMENT);
    if (currentParentFolder === 'null') return null;
    return JSON.parse(currentParentFolder);
  };

  const isDestinationChildFolder = (newParentFolderId, draggingFolder) =>
    draggingFolder.subFolders.some(item => item.id === newParentFolderId);

  const isValidData = (newParentFolder, elementType) => {
    const isFolderType = isFolder(elementType);
    const draggingElement = getDraggingFolderInfo();
    const currentFolderId = isFolderType ? draggingElement?.parent_folder?.id || null : draggingElement?.folder || null;

    // Scenario when the file is moved from a diferent measurement date
    // to the current measurement date without a folder
    if (isNull(currentFolderId)) return true;

    if (isFolderType && isDestinationChildFolder(newParentFolder, draggingElement)) return false;
    return newParentFolder !== currentFolderId;
  };

  const shouldApplyDragOverStyle = (dragOverFolder, elementType) => {
    const isFolderType = isFolder(elementType);
    if (isFolderType) {
      if (!dragOverFolder) return false;
      if (dragOverFolder?.hasChildNodes()) {
        return !dragOverFolder.firstChild?.classList?.contains(DRAGGING);
      }
    }
    return true;
  };

  return {
    isValidData,
    shouldApplyDragOverStyle,
  };
};

export default useFolderValidations;
