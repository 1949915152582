/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Collapse, IconButton, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { isEmpty, isNull, size } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { SYSTEM_EXPAND } from 'common/actions/row-groups/types';
import {
  CONVERTIBLE_NOTES_ROW_NUMBER,
  ISSUE_PRICE_ROW_NUMBER,
  PREFERRED_TERMS_ALIAS,
  SHARES_FULLY_DILUTED_ROW_NUMBER,
  SHARES_OUTSTANDING_ROW_NUMBER,
} from 'common/constants/cap-table';
import { FIX_BEFORE_CONTINUE } from 'common/constants/messages/validations';
import { EmptyTableMessage, FeaturedSpreadsheet, InfoCard } from 'components';
import { LedgerDialog } from 'components/Dialogs';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import CapTableContext from 'context/CapTableContext';
import { useTableValidation } from 'services/hooks';
import {
  afterCellChanged,
  conditions,
  convertibleNoteTemplate,
  customValidations,
  getRowConfig,
  parser,
  reverseParser,
  totalParser,
} from './config';
import { PREFERRED_STOCK_VALUE, TOTAL_SHARES_OUTSTANDING_KEY } from './config/constants';

const useStyles = makeStyles({
  spreadsheet: {
    '& td': {
      maxWidth: '140px',
    },
  },
  addColumnBtn: {
    textAlign: 'center',
    marginTop: '1.5rem',
  },
  infoCards: {
    display: 'flex',
    padding: '1rem 2.5rem',
    backgroundColor: '#f1f4f6',

    '& .MuiPaper-root': {
      flexGrow: 1,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
});

const ConvertibleNoteLedger = ({ cell, closeDialog }) => {
  const classes = useStyles();

  const { cells, onCellsChanged, setRowGroups } = useContext(FeaturedSpreadsheetContext);
  const { selectedMeasurementDate, getLinkedCellUpdates, convertibleNotesFieldAttrs } = useContext(CapTableContext);
  const totalSharesOutstanding = cells[TOTAL_SHARES_OUTSTANDING_KEY]?.value;
  const [columns, setColumns] = useState();
  const [persistentDeletedColumns, setPersistentDeletedColumns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [ledgerCells, setLedgerCells] = useState(null);
  const [totalsCells, setTotalsCells] = useState({});
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const { validateTable } = useTableValidation();

  const { columnLegend } = cell;

  const convertibleNoteKey = useMemo(() => columnLegend + CONVERTIBLE_NOTES_ROW_NUMBER, []);
  const sharesFullyDilutedKey = useMemo(() => columnLegend + SHARES_FULLY_DILUTED_ROW_NUMBER, []);
  const sharesOutstandingKey = useMemo(() => columnLegend + SHARES_OUTSTANDING_ROW_NUMBER, []);

  const issuePriceKey = useMemo(() => columnLegend + ISSUE_PRICE_ROW_NUMBER, []);

  const rowConfig = useMemo(() => {
    if (selectedMeasurementDate) {
      return getRowConfig(selectedMeasurementDate, totalSharesOutstanding);
    }
  }, [selectedMeasurementDate]);

  const tableTerms = {
    tableName: 'Convertible Note Ledger',
    tableSlug: 'convertible-note-ledger',
    columnName: 'Convertible Note',
    pluralColumnName: 'data',
  };

  useEffect(() => {
    if (columns) {
      const visibleColumnsTemp = columns.filter(({ is_deleted }) => !is_deleted);
      setVisibleColumns(visibleColumnsTemp);
    }
  }, [columns]);

  const getConvertibleNotes = (isSaving = false) => {
    if (!isEmpty(ledgerCells) && !isEmpty(visibleColumns) && !isEmpty(rowConfig)) {
      setDisableSaveButton(false);
      return reverseParser({
        cells: ledgerCells,
        columns: visibleColumns,
        rowConfig,
        allowEmptyValues: !isSaving,
        fieldAttributes: convertibleNotesFieldAttrs,
      });
    }
    setDisableSaveButton(true);
    return null;
  };

  const deletedColumns = useMemo(() => {
    if (columns?.length) {
      return columns.filter(column => column.is_deleted && column.id > 0);
    }
    return [];
  }, [columns]);

  const addColumn = () => {
    const tmpPersistedColumns = getConvertibleNotes() || [];
    const newConvertibleNote = {
      ...convertibleNoteTemplate,
      security_group_ref: uuid(),
      pre_money_share_count: totalSharesOutstanding,
    };
    const allColumns = [newConvertibleNote, ...tmpPersistedColumns, ...deletedColumns];
    setColumns(allColumns);
  };

  const deleteConvertibleNote = columnIndex => {
    const tmpColumns = [...getConvertibleNotes()];
    tmpColumns[columnIndex].is_deleted = true;
    setPersistentDeletedColumns([...persistentDeletedColumns, tmpColumns[columnIndex]]);
    setColumns([...tmpColumns, ...deletedColumns]);
  };

  const save = () => {
    if (ledgerCells) {
      const cellsToValidate = Object.entries(ledgerCells).map(([, ledgerCell]) => ledgerCell);
      const { areCellsValid, validatedCells } = validateTable({
        cellsToValidate,
        customValidations,
        parsedColumns: ledgerCells,
      });
      if (areCellsValid) {
        const convertibleNotes = getConvertibleNotes(true);
        const tmpState = { ...cells };
        const changes = [];

        // Reset "Shares Outstanding" and "Original Issue Price"
        if (isEmpty(convertibleNotes)) {
          // Reset "Original Issue Price"
          changes.push({
            cell: tmpState[issuePriceKey],
            value: tmpState[issuePriceKey].defaultValue,
          });
          // Reset "Shares Outstanding"
          changes.push({
            cell: tmpState[sharesFullyDilutedKey],
            value: tmpState[sharesFullyDilutedKey].defaultValue,
          });
        } else {
          // The Original Issue Price (row 4), should be the sum of all conversion price per share
          const totalOriginalIssuePrice = convertibleNotes
            .map(convertibleNote => Number(convertibleNote.conversion_price_per_share))
            .reduce((previous, current) => previous + current, 0);
          changes.push({
            cell: tmpState[issuePriceKey],
            value: totalOriginalIssuePrice.toString(),
          });

          // The shares outstanding (row 5), should be the sum of all expected_shares in the ledger
          const totalExpectedShares = convertibleNotes
            .map(convertibleNote => Number(convertibleNote.expected_shares))
            .reduce((previous, current) => previous + current, 0);
          changes.push({
            cell: tmpState[sharesFullyDilutedKey],
            value: totalExpectedShares.toString(),
          });
          changes.push({
            cell: tmpState[sharesOutstandingKey],
            value: totalExpectedShares.toString(),
          });
        }

        const allConvertibleNotes = [...convertibleNotes, ...deletedColumns];

        // Save Convertible Note ledger
        changes.push({
          cell: tmpState[convertibleNoteKey],
          value: allConvertibleNotes,
        });

        // Update global state
        const linkedUpdates = getLinkedCellUpdates(tmpState);

        onCellsChanged([...changes, ...linkedUpdates]);
        closeDialog();
        // if any of the convertible notes is modeled as preferred expand the preferred rows
        const containsNoteModeledAsPreferred = convertibleNotes.some(
          ({ model_as_equity }) => model_as_equity === PREFERRED_STOCK_VALUE
        );

        if (containsNoteModeledAsPreferred) {
          setRowGroups({ type: SYSTEM_EXPAND, row: PREFERRED_TERMS_ALIAS });
        }
      } else {
        setIsAlertVisible(true);
        // Replace current cells with the validated ones
        setLedgerCells(validatedCells.reduce((acc, curr) => ({ ...acc, [curr.key]: curr }), {}));
      }
    }
  };

  const getInitialData = () => {
    const ledgerData = [...(cells[convertibleNoteKey].value || [])];
    setColumns(ledgerData);
  };

  useEffect(() => {
    if (cell) {
      getInitialData();
    }
  }, [cell]);

  useEffect(() => {
    const convertibleNotes = getConvertibleNotes();
    if (
      cell.columnId === 0
      || (isNull(ledgerCells) && isNull(convertibleNotes) && visibleColumns?.length === 0)
      || (convertibleNotes?.length > 0 && size(ledgerCells) > 0 && visibleColumns?.length > 0)
    ) {
      setIsLoading(false);
    }
  }, [ledgerCells, visibleColumns]);

  const InfoCards = () => (
    <div className={classes.infoCards}>
      <InfoCard title="Convertible Notes Ledger" body={' '} />
    </div>
  );

  return (
    <LedgerDialog
      id="convertible-note-ledger"
      title=""
      onSave={save}
      disableSaveButton={disableSaveButton}
      onClose={closeDialog}
      dialogHeader={<InfoCards />}>
      <Collapse in={isAlertVisible}>
        <Alert
          variant="outlined"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsAlertVisible(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {FIX_BEFORE_CONTINUE}
        </Alert>
        <br />
      </Collapse>
      {isLoading && <LinearProgress color="primary" />}
      {isEmpty(visibleColumns) && !isLoading && (
        <div>
          <EmptyTableMessage tableTerms={tableTerms} />
        </div>
      )}
      {!isEmpty(visibleColumns) && (
        <FeaturedSpreadsheet
          className={classes.spreadsheet}
          cells={ledgerCells}
          rowConfig={rowConfig}
          afterCellChanged={afterCellChanged}
          columns={visibleColumns}
          totalsCells={totalsCells}
          totalParser={totalParser}
          updateCells={setLedgerCells}
          updateTotalsCells={setTotalsCells}
          parser={parser}
          reverseParser={reverseParser}
          tableTerms={tableTerms}
          conditions={conditions}
          deleteColumn={deleteConvertibleNote}
          validations={customValidations}
          setColumns={null}
          tableData={null}
          setTableData={null}
          page="captable"
          hasColTitle
          showToolbar
          showTotalColumn
          allowDeleteColumn
        />
      )}
      <div className={classes.addColumnBtn}>
        <Button
          id="add-convertible-note-btn"
          size="small"
          variant="outlined"
          color="primary"
          endIcon={<AddIcon />}
          onClick={addColumn}>
          Add {tableTerms.columnName}
        </Button>
      </div>
    </LedgerDialog>
  );
};

ConvertibleNoteLedger.propTypes = {
  columnLegend: PropTypes.string,
  cell: PropTypes.object,
  closeDialog: PropTypes.func,
};

export default ConvertibleNoteLedger;
