import { VALUATIONS_OTHER_LABEL } from 'common/constants/valuations';
import {
  DCF_SUMMARY_TABLE_NAME,
  PV_OF_TERMINAL_VALUE_CUSTOM_KEY,
  TERMINAL_VALUE_CUSTOM_KEY,
  TERMINAL_VALUE_EXPR_CUSTOM_KEY,
  TERMINAL_VALUE_OPTIONS,
  TVT_EM_TABLE_NAME,
  TVT_HM_TABLE_NAME,
  TVT_PG_TABLE_NAME,
  TVT_REM_TABLE_NAME,
  TVT_RM_TABLE_NAME,
} from 'pages/Valuations/approaches/discountCashFlow/utilities/constants';
import { OTHER_MULTIPLE_VALUE, selectMultipleAliases, TERMINAL_VALUE } from 'pages/Valuations/util/constants';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getBenchmarkColumnLegend } from './getParser';
import obtainMapForSelectMultipleAlias from './obtainMapForSelectMultipleAlias';

const getCell = (allCells, sheetName, key) => {
  const sheet = allCells[sheetName];
  return sheet[key];
};

const addCellToChangeList = (changeList, cell, value) => {
  const cellInChangeList = changeList.find(change => change.cell === cell);
  if (!cellInChangeList) {
    changeList.push({ cell, value });
  }
};

// map of possible terminal value options to table names
const terminalValueOptionsMap = {
  [TERMINAL_VALUE_OPTIONS.PERPETUITY_GROWTH]: TVT_PG_TABLE_NAME,
  [TERMINAL_VALUE_OPTIONS.H_MODEL]: TVT_HM_TABLE_NAME,
  [TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE]: TVT_RM_TABLE_NAME,
  [TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE]: TVT_EM_TABLE_NAME,
  [TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE]: TVT_REM_TABLE_NAME,
};

const afterCellChanged = (changes, cells, rowConfig, tableData, allCells) => {
  const changeList = [];
  const cellObjects = Object.values(cells);
  changes.forEach(change => {
    const { cell, value } = change;

    changeList.push({ cell, value });

    if (cell.alias === TERMINAL_VALUE) {
      // use TVT_REM_TABLE_NAME, TVT_EM_TABLE_NAME, TVT_RM_TABLE_NAME, TVT_HM_TABLE_NAME, and TVT_PG_TABLE_NAME
      // combined with the getApproachTableName() function and the tableData.approach object to get the
      // names of the relevant sheets in the allCells object.
      const relatedSheetNames = [
        getApproachTableName({ approach: tableData.approach, tableSuffix: TVT_REM_TABLE_NAME }),
        getApproachTableName({ approach: tableData.approach, tableSuffix: TVT_EM_TABLE_NAME }),
        getApproachTableName({ approach: tableData.approach, tableSuffix: TVT_RM_TABLE_NAME }),
        getApproachTableName({ approach: tableData.approach, tableSuffix: TVT_HM_TABLE_NAME }),
        getApproachTableName({ approach: tableData.approach, tableSuffix: TVT_PG_TABLE_NAME }),
      ];
      // get the terminal value cells, add them to the changeList
      relatedSheetNames
        .map(sheetName => getCell(allCells, sheetName, TERMINAL_VALUE_CUSTOM_KEY))
        .forEach(changeCell => addCellToChangeList(changeList, changeCell, value));

      // finally get the table name of the DCF_SUMMARY sheet, create a new expression, and add it to the changeList
      const dcfSummarySheetName = getApproachTableName({
        approach: tableData.approach,
        tableSuffix: DCF_SUMMARY_TABLE_NAME,
      });
      const dcfSummaryCell = getCell(allCells, dcfSummarySheetName, TERMINAL_VALUE_EXPR_CUSTOM_KEY);
      // use the value to determine the related table name and then create the expression
      // that says =table_name.terminal_value
      const tableName = getApproachTableName({
        approach: tableData.approach,
        tableSuffix: terminalValueOptionsMap[value],
      });
      const expression = `=${tableName}.${PV_OF_TERMINAL_VALUE_CUSTOM_KEY}`;
      addCellToChangeList(changeList, dcfSummaryCell, expression);
    }

    if (selectMultipleAliases.includes(cell.alias)) {
      const multipleAlias = obtainMapForSelectMultipleAlias().get(cell.alias);
      const cellToUpdate = cellObjects.find(
        ({ alias, columnLegend }) => alias === multipleAlias && columnLegend === cell.columnLegend
      );
      const indexValue = parseInt(value, 10);
      const indexedOption = cellToUpdate.options[indexValue];
      const changedCell = cells[cellToUpdate.key];
      // if new value is 'Other'
      // update the read only property of the "Multiple" cell and value/expression as needed
      if (indexedOption === VALUATIONS_OTHER_LABEL) {
        changedCell.readOnly = false;
        const otherValue = cell.sheet.tableData.approach.valuations_approach_dcf[OTHER_MULTIPLE_VALUE];
        changeList.push({ cell: changedCell, value: otherValue });
      } else {
        // if we go from 'Other' to, for example, 'Median' => need complex expression back.
        changedCell.readOnly = true;
        const columnLegend = cell.key[0];
        const benchmarkColumnLegend = getBenchmarkColumnLegend(
          changedCell.benchmarkType,
          changedCell.alias,
          changedCell.columnLegend
        );
        const recoveredExpression = changedCell.baseExpression
          .replace(/@/g, columnLegend)
          .replace(/#/g, `benchmark.${benchmarkColumnLegend}`);
        changeList.push({ cell: changedCell, value: recoveredExpression });
      }
    }
  });

  return changeList;
};

export default afterCellChanged;
