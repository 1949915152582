/* eslint-disable import/no-unresolved */
import React, { useContext, useMemo } from 'react';
import { isEmpty, toString } from 'lodash';
import PropTypes from 'prop-types';
import { SELECT_FUND } from 'common/constants/funds';
import { useStore } from 'common/store';
import { CustomSelect, withTooltip } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';

const FundList = ({ cell, ...restProps }) => {
  const { onCommit, onRevert } = restProps;
  const [globalStore] = useStore();
  const { fundList } = globalStore;
  const { onCellsChanged } = useContext(FeaturedSpreadsheetContext);

  const handleChange = value => {
    const newValue = toString(value);
    onCellsChanged([{ cell, value: newValue }]);
  };

  const getOptions = useMemo(() => {
    const tmpOptions = [];

    if (Array.isArray(fundList)) {
      fundList.forEach(fund => {
        tmpOptions.push({
          value: fund.id,
          label: fund.name,
        });
      });
    }

    return tmpOptions;
  }, [fundList]);

  if (isEmpty(fundList)) {
    // Return a message as JSX element
    return <div>No funds available</div>;
  }

  return (
    <CustomSelect
      id={`security-list-${cell.key}`}
      value={cell.value}
      options={getOptions}
      onChange={handleChange}
      onCommit={onCommit}
      onRevert={onRevert}
      onEmptyValue={SELECT_FUND}
      disabled={cell.readOnly}
      disableUnderline
    />
  );
};

FundList.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
};

FundList.propTypes = {
  cell: PropTypes.object,
};

export default withTooltip(FundList);
