export const ENTER_OTP_TITLE = 'Authentication App Code';
export const ENTER_OTP_BACKUP_CODE_TITLE = 'Authentication Backup Code';
export const ENTER_OTP_SUBTITLE = 'Enter the 6-digit code from your authentication app';
export const ENTER_OTP_BACKUP_CODE_SUBTITLE
  = 'Enter one of the backup codes provided when setting up two factor authentication';
export const AUTH_BACKUP_CODE_LABEL = 'Backup code';
export const AUTH_BACKUP_CODE_MESSAGE
  = 'Individual backup codes can only be used once. Update your two factor authentication settings and generate new codes after logging in.';
export const AUTH_CODE_LABEL = 'Authentication Code';
export const AUTH_CODE_ERROR_LENGTH_OR_TYPE = 'must be a 6-digit number';
export const AUTH_BACKUP_CODE_ERROR_LENGTH_OR_TYPE
  = 'should be 8 characters long (letters a through z, numbers 2 through 9).';
export const INVALID_OTP_ERROR = 'Invalid code. Please wait for the next code and try again.';
export const REMEMBER_OTP_LABEL = 'Remember this computer for 30 days';
export const SETUP_2FA_TITLE = 'Two Factor Setup';
export const SETUP_2FA_SUBTITLE
  = 'Take these few steps to enable two-factor authentication and make your account more secure.';
export const SETUP_2FA_STEP_1
  = '1. Install an authenticator app on your mobile device. Recommended options: Google Authenticator, Authy, 1Password or Microsoft Authenticator.';
export const SETUP_2FA_STEP_2 = '2. Scan the QR code with your authenticator app or enter the key manually.';
export const SETUP_2FA_STEP_3 = '3. Enter the code provided by your authenticator app.';
export const SCALAR_OTP_COOKIE = 'scalar_otp';
export const MESSAGE_BOX_TITLE_SUCCESS = 'Confirmation';
export const MESSAGE_BOX_TITLE_ERROR = 'Error';
export const RESULT_SUCCESS = 'enabled!';
export const RESULT_ERROR = 'failed';
export const CONFIRMATION_BUTTON_NEXT = 'Next';
export const CONFIRMATION_BUTTON_BACK = 'Back';
export const ERROR_BUTTON_NEXT = 'Try Again';
export const ERROR_BUTTON_BACK = 'Remind me later';
export const SUCCESS_INSIDE = 'success-inside';
export const ERROR_INSIDE = 'error-inside';
export const KEEP_STATIC_CODES_SAFE = 'Keep these codes safe, but accessible.';
export const BACKUP_CODES = 'Backup Codes';
export const USE_BACKUP_CODES = `If you lose access to your authentication device, you can use one of these ${BACKUP_CODES.toLowerCase()} to login to your account. Each code may be used only once. Make a copy of these codes, and store it somewhere safe.`;
export const PAGE_TITLE = 'Two Factor Authentication';
export const NO_BACKUP_CODES_AVAILABLE = `No ${BACKUP_CODES.toLowerCase()} available`;
export const GENERATE_NEW_CODES = 'Generate new codes';
export const PRINT_CODES = 'Print codes';
export const COPY_CODES = 'Copy codes';
export const OK_COPY_CODES = `The ${BACKUP_CODES.toLowerCase()} were copied to the clipboard`;
export const ERROR_COPY_CODES = `An error occurred while copying the ${BACKUP_CODES} to the clipboard`;
export const CANNOT_UPDATE_2FA = 'Cannot update 2FA settings at this time. Please try again later.';
export const GOOGLE = 'google';
export const MICROSOFT = 'microsoft';
export const BACKUP_CODES_NOT_AVAILABLE = 'Backup codes not available';
export const PLEASE_GENERATE_NEW_BACKUP_CODES = 'Please generate new backup codes by clicking the button below';
export const THESE_ARE_YOUR_NEW_BACKUP_CODES = 'These are your new backup codes';
export const AFTER_YOU_USE_A_BACKUP_CODE
  = 'After you use a backup code to sign in, it becomes inactive. You can generate a new set of 10 codes whenever you want. After creating a new set, the old set automatically becomes inactive.';
export const DONE_CODES = 'Done';
export const DOWNLOAD_CODES = 'Download codes';
export const SUCCESS_DOWNLOAD_CODES = `The file with your ${BACKUP_CODES.toLowerCase()} has been downloaded successfully`;
export const ERROR_DOWNLOAD_CODES = `An error occurred while downloading the file with your ${BACKUP_CODES}`;
export const COMPANY_NOT_FOUND = 'Company not found';
export const SELECT_A_COMPANY = 'Select a Company';
export const SHOW_KEY = 'Show Key';
export const HIDE_KEY = 'Hide Key';
export const KEY_COPIED = 'Copied!';
export const REAUTHENTICATION_MESSAGE
  = 'We\'ve noticed that you need to reauthenticate to generate new backup codes. To do so, please log out and then log back in again.';
export const COUNTDOWN_INTERVAL = 1000; // milliseconds
export const INITIAL_COUNTDOWN = 10; // seconds
export const FORGOT_RESET_PASSWORD = 'FORGOT OR NEED TO RESET YOUR PASSWORD?';
export const SIGN_IN_URL = '/sign-in';
