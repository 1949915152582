export const getClientSecret = () => 'api/subscriptions/subscription/initiate-payment';
export const setPaymentMethod = () => 'api/subscriptions/subscription/setup-intent';
export const paymentMethods = (companyId: number) => `api/subscriptions/company/${companyId}/payment-methods`;
export const getSubscription = (companyId: number) => `api/subscriptions/company/${companyId}/subscription`;

export default {
  getClientSecret,
  setPaymentMethod,
  paymentMethods,
  getSubscription,
};
