import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { VALUATION_GPC_EXTRA_INFO_TOOLTIP_TITLE } from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';

export const VALUATION_GPC_EXTRA_INFO_TOOLTIP_VISIBLE_TIME = 10000;

export const useStylesGPCHideInfoTooltip = makeStyles(theme => ({
  container: {
    left: '-1.5px',
    position: 'absolute',
    top: '-13px',
    transform: 'translateX(-50%)',
    transition: 'opacity 1s ease-out',
    zIndex: 110,
    opacity: 1,

    '&.hide-container': {
      opacity: 0,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.primary[600],
    border: `1px solid ${theme.palette.primary[700]}`,
    borderRadius: 4,
    color: theme.palette.common.white,
    display: 'inline-block',
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.body1.fontWeight,
    padding: theme.spacing(1),
    position: 'relative',
    textAlign: 'center',

    // Arrow
    '&::before': {
      borderColor: `${theme.palette.primary[600]} transparent transparent transparent`,
      borderStyle: 'solid',
      borderWidth: '5px',
      content: '""',
      left: '50%',
      marginLeft: '-5px',
      position: 'absolute',
      top: '100%',
    },
  },
}));

const GPCHideInfoTooltip = props => {
  const { hideTooltip } = props;
  const classes = useStylesGPCHideInfoTooltip();

  return (
    <Grid
      className={clsx(classes.container, {
        'hide-container': hideTooltip,
      })}>
      <Grid className={classes.tooltip}>{VALUATION_GPC_EXTRA_INFO_TOOLTIP_TITLE}</Grid>
    </Grid>
  );
};

GPCHideInfoTooltip.propTypes = {
  hideTooltip: PropTypes.bool.isRequired,
};

export default GPCHideInfoTooltip;
