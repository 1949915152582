/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { globalAction } from 'common/actions';
import { useStore } from 'common/store';
import CompanyMeasurementDatesService from 'services/companyMeasurementDates';
import useResponse from './useResponse';

export const useGetCompanyMeasurementDateByMD = () => {
  const [, dispatch] = useStore();

  const { processErrorResponse } = useResponse();

  const retrieveByMeasurementDate = useCallback(
    async measurementDateId => {
      const service = new CompanyMeasurementDatesService();
      if (measurementDateId) {
        dispatch(globalAction.showLoadingProgress(true));
        let response = null;
        try {
          response = await service.byMeasurementDate(measurementDateId);
        } catch (error) {
          const defaultErrorMessage = 'An error ocurred while retrieving the company measurement date';
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'get the company measurement date',
          });
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
        return response;
      }
    },
    [dispatch, processErrorResponse]
  );

  return [retrieveByMeasurementDate];
};
