import { dcfPercentFormatWithNegativeValues } from 'common/formats/formats';
import { RowConfigProps } from '../types';

const MEASUREMENT_DATE_TITLE = 'Measurement Date';
const EFFECTIVE_TAX_RATE_TITLE = 'Effective Tax Rate';

export const MEASUREMENT_DATE_ALIAS = 'measurement_date';
export const EFFECTIVE_TAX_RATE_ALIAS = 'effective_tax_rate';

const MEASUREMENT_DATE_ROW_NUMBER = 1;
const EFFECTIVE_TAX_RATE_ROW_NUMBER = 2;

const getRowConfig = (): RowConfigProps[] => [
  {
    className: 'title-second',
    value: MEASUREMENT_DATE_TITLE,
    alias: MEASUREMENT_DATE_ALIAS,
    rowNumber: MEASUREMENT_DATE_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    expr: '',
    dbType: 'dbDate',
    gridType: 'gridDate',
  },
  {
    className: 'title-second',
    value: EFFECTIVE_TAX_RATE_TITLE,
    alias: EFFECTIVE_TAX_RATE_ALIAS,
    rowNumber: EFFECTIVE_TAX_RATE_ROW_NUMBER,
    hidden: false,
    readOnly: false,
    expr: '',
    format: dcfPercentFormatWithNegativeValues,
    defaultValue: '0',
    isRequired: true,
  },
];

export default getRowConfig;
