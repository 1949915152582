import React, { FC } from 'react';
import { ExtendedFabButton } from 'components/Buttons';
import { AddScenarioApproachButtonProps } from './types';

const ADD_APPROACH_BUTTON_LABEL = 'Add';
const ADD_APPROACH_BUTTON_ID = 'valuation-add-btn';

const AddScenarioApproachButton: FC<AddScenarioApproachButtonProps> = props => {
  const { disabled, onClick } = props;

  return (
    <ExtendedFabButton
      disabled={disabled}
      id={ADD_APPROACH_BUTTON_ID}
      label={ADD_APPROACH_BUTTON_LABEL}
      onClick={onClick}
    />
  );
};

export default AddScenarioApproachButton;
