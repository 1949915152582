import * as Yup from 'yup';

// Regex for currency validation (max 6 decimal places)
const currencyRegex = /^(?!0\d)\d+(?:\.\d{1,6})?$/;

// Based on Fund model: apps/funds/models/fund.py
export const FUND_FORM_SCHEMA = Yup.object({
  name: Yup.string()
    .label('Name')
    .min(3, 'must be at least 3 characters')
    .max(255, 'must be at most 255 characters')
    .required('is required'),
  total_committed_capital: Yup.string()
    .matches(currencyRegex, 'must contain only numbers and at most 6 decimal places')
    .max(21, 'must be at most 21 digits')
    .required('is required'),
  currency: Yup.string().max(3, 'must be at most 3 characters'),
});
