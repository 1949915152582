import { useQuery } from '@tanstack/react-query';
import { hoursToMilliseconds } from 'date-fns';
import { isEmpty, isNil } from 'lodash';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import { ALLOCATION_SCENARIOS_VALUES_QUERY_KEY } from 'common/constants/services/allocations';
import { ALLOCATION_SCENARIO_METHOD_OPM } from 'pages/ValuationsAllocation/common/constants/allocation';
import { useGetScenarioValues } from 'services/hooks/allocations/useGetScenarioValues';
import { currentISODate, generateColumnKey, getNumberValue, getObjectValue } from 'utillities';
import {
  GetAllocationScenariosValuesHook,
  GetAllocationScenariosValuesParams,
  GetAllocationScenariosValuesResponse,
  UseGetAllocationScenariosValuesParams,
} from './types';

const getAllocationScenariosValues = (params: GetAllocationScenariosValuesParams) => {
  const { fetchScenarioValues, scenarios } = params;

  const result = scenarios?.reduce(async (accumulatorPromise, currentPromise) => {
    const { scenarioIdOrRef, isBacksolve, scenarioMethods, ...scenario } = currentPromise;

    const accumulator = await accumulatorPromise;

    const scenariosValuesPromise = scenarioMethods?.reduce(async (accumulatorMethodPromise, currentMethod) => {
      const methodAccumulator = await accumulatorMethodPromise;

      const { maturity, volatility, exitDate } = getObjectValue(scenario);

      const isOPM = [ALLOCATION_SCENARIO_METHOD_OPM].includes(getNumberValue(currentMethod));

      try {
        const scenarioValues = await fetchScenarioValues({
          scenario: {
            ...scenario,
            exitDate: isBacksolve ? currentISODate() : exitDate,
            maturity: isOPM ? maturity : '0',
            scenarioMethod: currentMethod,
            volatility: isOPM ? volatility : '0',
          },
        });

        if (isNil(scenarioValues)) return methodAccumulator;

        return {
          ...methodAccumulator,
          [generateColumnKey({
            id: scenarioIdOrRef,
            name: currentMethod?.toString(),
          })]: scenarioValues,
        } as GetAllocationScenariosValuesResponse;
      } catch (error) {
        return methodAccumulator;
      }
    }, Promise.resolve({} as GetAllocationScenariosValuesResponse));

    const scenariosValues = await scenariosValuesPromise;

    return { ...accumulator, ...scenariosValues } as GetAllocationScenariosValuesResponse;
  }, Promise.resolve({} as GetAllocationScenariosValuesResponse));

  return result;
};

const useGetAllocationScenariosValues = (params: UseGetAllocationScenariosValuesParams) => {
  const { scenarios, shouldQueryAutomatically = true } = params;

  const fetchScenarioValues = useGetScenarioValues();

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<
    GetAllocationScenariosValuesHook['data'],
    Error
  >({
    enabled: !isEmpty(scenarios) && shouldQueryAutomatically,
    queryFn: () => getAllocationScenariosValues({ fetchScenarioValues, scenarios }),
    queryKey: [ALLOCATION_SCENARIOS_VALUES_QUERY_KEY, scenarios, fetchScenarioValues],
    refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
    staleTime: hoursToMilliseconds(24),
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetAllocationScenariosValues;
