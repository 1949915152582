import {
  COMPANIES_SLUG,
  DASHBOARD_SLUG,
  INFORMATION_SLUG,
  OVERVIEW_SLUG,
  PREVIOUS_REPORTS_SLUG,
  SETTINGS_SLUG,
  SUBSCRIPTION_SLUG,
} from 'dashboard409a/common/constants/urls';

export const DASHBOARD_COMPANY_SLUG = `/${DASHBOARD_SLUG}/${COMPANIES_SLUG}/:companySlug`;
export const DASHBOARD_COMPANY_OVERVIEW_SLUG = `${DASHBOARD_COMPANY_SLUG}/${OVERVIEW_SLUG}`;
export const DASHBOARD_COMPANY_PREVIOUS_REPORTS_SLUG = `${DASHBOARD_COMPANY_SLUG}/${PREVIOUS_REPORTS_SLUG}`;
export const DASHBOARD_COMPANY_SETTINGS = `${DASHBOARD_COMPANY_SLUG}/${SETTINGS_SLUG}`;
export const DASHBOARD_COMPANY_SUBSCRIPTION = `${DASHBOARD_COMPANY_SLUG}/${SUBSCRIPTION_SLUG}`;
export const DASHBOARD_COMPANY_INFORMATION = `${DASHBOARD_COMPANY_SLUG}/${INFORMATION_SLUG}`;
