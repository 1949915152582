import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    loadingContainer: {
      marginTop: '30vh',
    },
  })
);

export default useStyles;
