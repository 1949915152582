import React from 'react';
import { Button } from '@material-ui/core';
import { ExitToAppOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useLogOutUser } from 'services/hooks/auth';

const useStyles = makeStyles({
  logoutBtn: {
    textAlign: 'right',
    marginTop: '1.5em',
    '& button': {
      fontSize: '0.875rem'
    }
  }
});

const LogoutButton = () => {
  const classes = useStyles();
  const logOutUser = useLogOutUser();
  return (
    <div className={classes.logoutBtn}>
      <Button
        onClick={logOutUser}
        variant="contained"
        color="secondary"
        endIcon={<ExitToAppOutlined />}
        type="submit">
        Log Out
      </Button>
    </div>
  );
};

export default LogoutButton;
