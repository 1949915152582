import React, { FC, useContext, useMemo, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon';
import { ExtraActionsMenu } from 'components';
import { ConfirmationDialog } from 'components/Dialogs';
import EnhancedTableHead from 'components/EnhancedTableHead';
import { InformationDashboard409AContext } from 'context/InformationDashboard409AContext';
import {
  ERROR_DISMISSING_REQUESTED_DOCUMENT,
  SUCCESS_DISMISSING_REQUESTED_DOCUMENT,
  SUCCESS_MODIFYING_INFORMATION_REQUEST,
} from 'dashboard409a/common/constants';
import { modifyExistingRequest } from 'dashboard409a/services/dashboard';
import useStyles from 'pages/Documents/components/DocumentsTable/useStyles';
import theme from 'theme';
import { gridShortDate } from 'utillities';
import {
  CurrentRequestedDocumentTableProps,
  DocumentProgress,
  FileRequestMap,
  FolderFileMap,
  FolderMap,
} from './types';

const CurrentRequestedDocumentTable: FC<CurrentRequestedDocumentTableProps> = ({ documents }) => {
  const responsibleUsers = documents?.responsible_users || [];
  const lastTaskId = documents?.id || null;
  const [isOpenDismissDocumentRequestModal, setIsOpenDismissDocumentRequestModal] = useState(false);
  const [documentToRemove, setDocumentToRemove] = useState<DocumentProgress | null>(null);
  const { handleGetCurrentInformationRequests, currentDocuments } = useContext(InformationDashboard409AContext);
  const tableClasses = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const tableHeadRows = [
    { id: 'format', label: 'Format' },
    { id: 'name', label: 'Name' },
    { id: 'source', label: 'Source' },
    { id: 'sentUploadDate', label: 'Sent Date/Upload Date' },
    { id: 'references', label: 'References' },
    { id: 'actions', label: 'Actions' },
  ];

  const unmetRequests = useMemo(() => documents?.progress.filter(request => request.files.length === 0), [documents]);

  const fileRequestMap = useMemo(
    () =>
      documents.progress.reduce((acc, request) => {
        request.files.forEach(file => {
          const fileId = file.id ?? 0;
          if (!acc[fileId]) {
            acc[fileId] = [];
          }
          acc[fileId].push(request);
        });
        return acc;
      }, {} as FileRequestMap),
    [documents]
  );

  // we need to create an object that maps files in currentDocuments to corresponding folders in currentDocuments
  const folderFileMap = useMemo(() => {
    const map = currentDocuments?.files?.reduce((acc, file) => {
      const index = file.folder ?? 0;
      if (!acc[index]) {
        acc[index] = [];
      }
      acc[file.folder ?? 0].push(file);
      return acc;
    }, {} as FolderFileMap);
    return map;
  }, [currentDocuments]);

  const folderMap = useMemo(
    () =>
      currentDocuments?.folders?.reduce((acc, folder) => {
        acc[folder.id ?? 0] = folder;
        return acc;
      }, {} as FolderMap),
    [currentDocuments]
  );

  const openDismissDocumentRequestModal = (document: DocumentProgress) => {
    setDocumentToRemove(document);
    setIsOpenDismissDocumentRequestModal(true);
  };

  const removeDocument = async (document: DocumentProgress) => {
    try {
      if (!lastTaskId || !document.id) {
        enqueueSnackbar(ERROR_DISMISSING_REQUESTED_DOCUMENT, { variant: 'error' });
        return false;
      }
      const data = {
        task: lastTaskId,
        requested_documents_to_delete: [document.id],
        requested_documents: [],
      };
      const response = await modifyExistingRequest(data);

      if (response?.message === SUCCESS_MODIFYING_INFORMATION_REQUEST) {
        enqueueSnackbar(SUCCESS_DISMISSING_REQUESTED_DOCUMENT, { variant: 'success' });
        return true;
      }
      enqueueSnackbar(ERROR_DISMISSING_REQUESTED_DOCUMENT, { variant: 'error' });
      return false;
    } catch (error) {
      enqueueSnackbar(ERROR_DISMISSING_REQUESTED_DOCUMENT, { variant: 'error' });
      return false;
    } finally {
      setDocumentToRemove(null);
      setIsOpenDismissDocumentRequestModal(false);
      handleGetCurrentInformationRequests();
    }
  };

  const optionsForExtraActionsMenu = (documentToRemoveParam: DocumentProgress | null) => [
    {
      label: 'UPLOAD THIS DOCUMENTS',
      callback: () => '',
      isActive: false,
    },
    {
      label: 'DISMISS DOCUMENT REQUEST',
      callback: () => {
        if (documentToRemoveParam !== null) {
          openDismissDocumentRequestModal(documentToRemoveParam);
        }
      },
      isActive: true,
    },
  ];

  const actions = [
    {
      label: 'CANCEL',
      variant: 'outlined',
      disabled: false,
      callback: () => setIsOpenDismissDocumentRequestModal(false),
    },
    {
      label: 'YES, CONFIRM',
      variant: 'contained',
      disabled: false,
      callback: () => {
        if (documentToRemove !== null) {
          removeDocument(documentToRemove);
        }
      },
    },
  ];

  const getFormattedDate = (date: string | null) => (date ? gridShortDate(date) : '');

  const getSentAndUploadDate = (document: DocumentProgress) => {
    const { created_at: sentDate, updated_at: uploadedDate } = document || {};
    const formattedCreatedDate = getFormattedDate(sentDate);
    const formattedUploadedDate = getFormattedDate(uploadedDate);
    if (formattedCreatedDate && formattedUploadedDate) {
      return formattedCreatedDate === formattedUploadedDate
        ? `${formattedCreatedDate}`
        : `${formattedCreatedDate} - ${formattedUploadedDate}`;
    }
    return formattedCreatedDate || formattedUploadedDate;
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table style={{ minWidth: 650 }} aria-label="documents table">
          <EnhancedTableHead
            classes={tableClasses}
            onRequestSort={() => {}}
            order="asc"
            orderBy="name"
            columns={tableHeadRows}
          />
          <TableBody>
            {unmetRequests?.map(document => (
              <TableRow hover key={document.id}>
                <TableCell />
                <TableCell>{document.name}</TableCell>
                <TableCell>{responsibleUsers.map(user => user.full_name).join(', ')}</TableCell>
                <TableCell>{getSentAndUploadDate(document)}</TableCell>
                <TableCell />
                <TableCell>
                  <ExtraActionsMenu
                    options={optionsForExtraActionsMenu(document)}
                    dotsColor={theme.palette.gray[500]}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {folderMap
            && folderFileMap
            && Object.entries(folderFileMap).map(([folderId, files]) => {
              const numericFolderId = Number(folderId);
              return (
                <TableBody>
                  {folderMap?.[numericFolderId] && (
                    <TableRow key={numericFolderId}>
                      <TableCell>{folderMap?.[numericFolderId]?.name}</TableCell>
                    </TableRow>
                  )}
                  {files.map(row => (
                    <TableRow hover key={row.file.id}>
                      <TableCell>
                        {row.file.file_type in defaultStyles && (
                          <div
                            style={{
                              width: '2rem',
                              height: '3rem',
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            <FileIcon
                              extension={row.file.file_type}
                              {...defaultStyles[row.file.file_type as DefaultExtensionType]}
                            />
                          </div>
                        )}
                      </TableCell>
                      <TableCell>{row.file.filename}</TableCell>
                      <TableCell>{row.file.uploaded_by?.full_name}</TableCell>
                      <TableCell>{getFormattedDate(row.file.uploaded_date ?? null)}</TableCell>
                      <TableCell>
                        {row.file.id
                          && fileRequestMap[row.file.id]?.map(request => (
                            <ul key={request.id}>
                              <li>
                                <Typography variant="body2">{request.name}</Typography>
                              </li>
                            </ul>
                          ))}
                      </TableCell>
                      <TableCell>
                        <ExtraActionsMenu
                          options={optionsForExtraActionsMenu(null)}
                          dotsColor={theme.palette.gray[500]}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              );
            })}
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={isOpenDismissDocumentRequestModal}
        title="Do you confirm that you want to dismiss this document request?"
        onClose={() => setIsOpenDismissDocumentRequestModal(false)}
        actions={actions}>
        <span>
          This modification will affect the original request to the company user, eliminating it from its portal.
        </span>
      </ConfirmationDialog>
    </>
  );
};

export default CurrentRequestedDocumentTable;
