import React, { ChangeEvent, ReactNode, useMemo, useState } from 'react';
import { Box, Card, CardContent, Grid, Tab, Tabs } from '@material-ui/core';
import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import { useFormat } from 'common/hooks';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import { ComparisonCompanies, CurrentRevenue, RatesOfChange, ValueOverTime } from 'dashboard409a/components';
import { HistoricalData } from 'dashboard409a/services/types';
import { IGpcCompsState } from 'dashboard409a/states/features/gpcComps';
import { RootStateType } from 'dashboard409a/states/store';
import { getHexColor } from 'dashboard409a/utilities';
import { ComparisonCompaniesProps } from '../ComparisonCompanies/types';
import { SubscriptionExpiredBox } from '../SubscriptionExpiredBox';

const MULTIPLES = 'multiples';
const CURRENT_REVENUE = 'current revenue';
const RATES_OF_CHANGE = 'rates of change';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const tabProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'arial-controls': `simple-tabpanels-${index}`,
});

const TabPanels = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {children}
    </div>
  );
};

const GPCComp = () => {
  const { currentCompany } = useSelector((state: RootStateType) => state.global);
  const { loading, gpcComps } = useSelector<RootStateType, IGpcCompsState>(state => state.gpcComps);
  const [format, formatDispatch] = useFormat({ units: REGULAR_UNIT });
  const [tabValue, setTabValue] = useState(0);
  const subscriptionIsActive = currentCompany?.is_active;
  const displayExpired = !isUndefined(subscriptionIsActive) && subscriptionIsActive === false;

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChange = (event: ChangeEvent<{}>, newValue: number): void => {
    setTabValue(newValue);
  };

  const comparisonCompaniesProps: ComparisonCompaniesProps = {
    data: gpcComps,
    isLoading: loading,
    format,
    formatDispatch,
    currentCompanyName: currentCompany?.name,
  };

  const valueOverTimeProps = useMemo(
    () => ({
      chartData: gpcComps?.value_over_time?.map((element: HistoricalData) => ({ ...element, color: getHexColor() })),
      isLoading: loading,
    }),
    [gpcComps, loading]
  );

  const currentRevenueProps = useMemo(
    () => ({
      chartData: gpcComps?.current_value,
      isLoading: loading,
    }),
    [gpcComps, loading]
  );

  const RatesOfChangeProps = useMemo(
    () => ({
      chartData: gpcComps?.rate_of_change?.map((element: HistoricalData) => ({ ...element, color: getHexColor() })),
      isLoading: loading,
    }),
    [gpcComps, loading]
  );

  if (displayExpired) {
    return (
      <Grid spacing={1}>
        <Card>
          <SubscriptionExpiredBox />
        </Card>
      </Grid>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <Card>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                <Tab label={MULTIPLES} {...tabProps(0)} />
                <Tab label={CURRENT_REVENUE} {...tabProps(0)} />
                <Tab label={RATES_OF_CHANGE} {...tabProps(0)} />
              </Tabs>
            </Box>
            <CardContent>
              <TabPanels value={tabValue} index={0}>
                <ValueOverTime {...valueOverTimeProps} />
              </TabPanels>
              <TabPanels value={tabValue} index={1}>
                <CurrentRevenue {...currentRevenueProps} />
              </TabPanels>
              <TabPanels value={tabValue} index={2}>
                <RatesOfChange {...RatesOfChangeProps} adimensional />
              </TabPanels>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Card>
            <CardContent>
              <ComparisonCompanies {...comparisonCompaniesProps} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GPCComp;
