import React, { useContext, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SYSTEM_COLLAPSE, USER_COLLAPSE } from 'common/actions/row-groups/types';
import { DeleteRowButton } from 'components/LedgerTable/components';
import FeaturedSpreadsheetContext from '../context/FeaturedSpreadsheetContext';

const useStyles = makeStyles({
  row: {
    '&:hover .delete-button': {
      opacity: 1,
    },
    backgroundColor: 'transparent',
  },
});

const Row = ({ cells, children, row, isLegend }) => {
  const { rowGroups, displayLegend, isToolbarVisible, rows, displayDeleteRowBtn, deleteRowFn }
    = useContext(FeaturedSpreadsheetContext);

  const classes = useStyles();

  const isRowVisible = useMemo(() => {
    if (rowGroups) {
      // Look if any of the cells has a parent
      const result = cells.find(cell => cell?.parent);

      // Check if the collapsible rows has the parent
      if (result?.parent) {
        const rowGroup = rowGroups?.[result.parent];
        const isRowGroupCollapsed = rowGroup === SYSTEM_COLLAPSE || rowGroup === USER_COLLAPSE;

        if (isRowGroupCollapsed) return false;
      }
    }

    return !(!displayLegend && row === 0 && !isToolbarVisible);
  }, [rowGroups, cells, displayLegend, row, isToolbarVisible]);

  const visibleClass = useMemo(() => (isRowVisible ? 'visible' : 'hidden'), [isRowVisible]);

  return (
    <tr className={`${visibleClass} ${classes.row}`}>
      {children}
      {displayDeleteRowBtn && !isLegend && row > 1 && row < rows.length && (
        <td className="delete-row">
          <DeleteRowButton rowIndex={row - 1} deleteRow={deleteRowFn} />
        </td>
      )}
    </tr>
  );
};

Row.propTypes = {
  cells: PropTypes.array,
  children: PropTypes.array,
  row: PropTypes.number,
  isLegend: PropTypes.bool,
};

export default Row;
