import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FolderIcon } from './FolderIcon';
import RequestedFileNameAndData from './RequestedFileNameAndData';

const FileNameAndIcon = ({ requestedFile, companyDocuments }) => (
  <Box display="flex">
    <FolderIcon requestedFile={requestedFile} />
    <RequestedFileNameAndData requestedFile={requestedFile} companyDocuments={companyDocuments} />
  </Box>
);

FileNameAndIcon.propTypes = {
  requestedFile: PropTypes.object,
  companyDocuments: PropTypes.array,
};

export default FileNameAndIcon;
