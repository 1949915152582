import React, { useState } from 'react';
import { Avatar, CardHeader, makeStyles, Menu, MenuItem } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { ArrowDropDown } from '@material-ui/icons';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import { USER_ROLES } from 'common/constants/user';

const useStyles = makeStyles(theme => ({
  badge: {
    backgroundColor: theme.palette.gray[400],
    borderRadius: '0.25rem',
    border: 'none',
    padding: '0.225rem 0.225rem 0.225rem 0.5rem',
    cursor: 'pointer',
    outline: 'none',
    width: 'auto',
    color: theme.palette.white,
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'auto',
  },
  list: {
    padding: '0.5rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  arrowIcon: {
    marginLeft: '0.25rem',
    fontSize: '1.25rem',
  },
}));

const UserCard = ({ name, email, lastLogin, pictureUrl, roleType, setSelectedRole, disabled, isPrimaryUser }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleBadgeClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleBadgeKeyDown = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRoleSelect = event => {
    const value = event.target.getAttribute('value');
    const newRole = Object.values(USER_ROLES).find(role => role.value === Number(value))?.value;
    setSelectedRole({ roleType: newRole });
    handleMenuClose();
  };

  return (
    <div className={classes.container}>
      <div>
        <Avatar alt={name} src={pictureUrl} style={{ width: '4.5rem', height: '4.5rem' }} />
      </div>
      <div>
        {!!roleType && (
          <button
            disabled={disabled}
            title={disabled ? 'You cannot change your own role' : ''}
            type="button"
            className={classes.badge}
            onClick={handleBadgeClick}
            onKeyDown={handleBadgeKeyDown}
            aria-label="Select role">
            {Object.values(USER_ROLES)
              .find(r => r.value === roleType)
              ?.label.toUpperCase()}
            <ArrowDropDown className={classes.arrowIcon} />
          </button>
        )}
        {isPrimaryUser && <Chip label="Primary Contact" color="primary" deleteIcon={<DoneIcon />} />}
      </div>
      <CardHeader
        title={name}
        subheader={
          <div>
            <p style={{ marginTop: '0.375rem' }}>{email}</p>
            <p style={{ marginTop: '0.375rem' }}>{lastLogin}</p>
          </div>
        }
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{ align: 'center' }}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} classes={{ list: classes.list }}>
        {Object.values(USER_ROLES).map(r => (
          <MenuItem key={r.value} value={r.value} onClick={handleRoleSelect}>
            {r.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

UserCard.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  lastLogin: PropTypes.string,
  pictureUrl: PropTypes.string,
  roleType: PropTypes.number,
  setSelectedRole: PropTypes.func,
  disabled: PropTypes.bool,
  isPrimaryUser: PropTypes.bool,
};

export default UserCard;
