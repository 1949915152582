import { SERVICES_AN_ERROR_OCCURRED_CHECKING } from 'common/constants/services/services';

// Check Measurement Date
export const CHECK_MEASUREMENT_DATE_BY_CAP_TABLE_QUERY_KEY = 'CHECK_MEASUREMENT_DATE_BY_CAP_TABLE';
export const CHECK_MEASUREMENT_DATE_BY_CAP_TABLE_TITLE = 'Measurement Date';
export const CHECK_MEASUREMENT_DATE_BY_CAP_TABLE_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_CHECKING(
  CHECK_MEASUREMENT_DATE_BY_CAP_TABLE_TITLE
);

// Primary Cap Table
export const PRIMARY_CAP_TABLE_BY_CMD_QUERY_KEY = 'PRIMARY_CAP_TABLE_BY_CMD';
export const PRIMARY_CAP_TABLE_BY_CMD_TITLE = 'Primary Cap Table';
export const PRIMARY_CAP_TABLE_BY_CMD_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_CHECKING(PRIMARY_CAP_TABLE_BY_CMD_TITLE);
