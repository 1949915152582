/* eslint-disable no-param-reassign */
import getSelectionFromTVT from 'pages/Valuations/approaches/discountCashFlow/dcfTerminalValue/util/getSelectionFromTVT';
import { DCF_FINANCIAL_PERIOD_TABLE_NAME } from 'pages/Valuations/approaches/discountCashFlow/utilities/constants';
import {
  EBITDA_GPC_MULTIPLE,
  EBITDA_GPC_MULTIPLE_SELECTION,
  GPC_MULTIPLE,
  GPC_MULTIPLE_SELECTION,
  REM_TVT_SECOND_COLUMN,
  REVENUE_GPC_MULTIPLE,
  REVENUE_GPC_MULTIPLE_SELECTION,
} from 'pages/Valuations/util/constants';
import { parseDatabaseValue } from 'utillities';

const reverseParser = ({ cells, tableData, rowConfig }) => {
  Object.values(cells).forEach(cell => {
    if (cell.alias) {
      const { value } = cell;
      const defaultValue = null;
      const decimalPlaces = cell.dbDecimalPlaces;

      const dbValue = parseDatabaseValue({
        type: cell?.dbType,
        value,
        defaultValue,
        format: cell?.format,
        allowEmptyValues: true,
        dontMultiply: true,
        decimalPlaces,
      });
      const modifiedPropToWriteTo = [GPC_MULTIPLE, GPC_MULTIPLE_SELECTION].includes(cell.alias);

      if (cell.origin !== DCF_FINANCIAL_PERIOD_TABLE_NAME && !modifiedPropToWriteTo) {
        tableData.approach.valuations_approach_dcf[cell.alias] = dbValue;
      } else if (cell.alias === GPC_MULTIPLE_SELECTION) {
        const selection = getSelectionFromTVT(cell, value, rowConfig);
        const propToWriteTo
          = cell.columnLegend === REM_TVT_SECOND_COLUMN ? EBITDA_GPC_MULTIPLE_SELECTION : REVENUE_GPC_MULTIPLE_SELECTION;
        tableData.approach.valuations_approach_dcf[propToWriteTo] = selection;
      } else if (cell.alias === GPC_MULTIPLE) {
        const propToWriteTo = cell.columnLegend === REM_TVT_SECOND_COLUMN ? EBITDA_GPC_MULTIPLE : REVENUE_GPC_MULTIPLE;
        tableData.approach.valuations_approach_dcf[propToWriteTo] = dbValue;
      }
    }
  });
};
export default reverseParser;
