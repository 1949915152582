const formulaRowAndLegendsCombination = {
  combination: {
    windows: 'Ctrl + `',
    macOS: 'Control + =',
  },
};

const availableShortcuts = [
  {
    action: 'Add',
    combination: {
      windows: 'Alt + N',
      macOS: 'Control + N',
    },
  },
  {
    action: 'Next Tab',
    combination: {
      windows: 'Alt + Page Up',
      macOS: 'Option + Fn + ArrowKey Up',
    },
  },
  {
    action: 'Previous Tab',
    combination: {
      windows: 'Alt + Page Down',
      macOS: 'Option + Fn + ArrowKey Up',
    },
  },
  {
    action: 'Edit Selected Cell',
    combination: {
      windows: 'F2',
      macOS: 'F2',
    },
  },
  {
    action: 'Save',
    combination: {
      windows: 'Ctrl + .',
      macOS: 'Control + .',
    },
  },
  {
    action: 'Show row numbers and column labels (1st time)',
    ...formulaRowAndLegendsCombination,
  },
  {
    action: 'Show Formula (2nd time)',
    ...formulaRowAndLegendsCombination,
  },
  {
    action: 'Hide Formula, Row Numbers and Column Labels (3rd time)',
    ...formulaRowAndLegendsCombination,
  },
];

export default availableShortcuts;
