/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useReducer, useState } from 'react';
import { groupBy, isEmpty, isUndefined, sortBy, sumBy, uniqBy } from 'lodash';
import { CAP_TABLE_TERMS, INITIAL_CAP_TABLE_STATE } from 'common/constants/cap-table';
import { OPTION, WARRANT } from 'common/constants/securityTypes';
import { rowsGroupsReducer } from 'common/reducers/rowsGroups';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import conditions from 'pages/CapTable/cap-table/utilities/conditions';
import { customValidations, reverseParser } from 'pages/CapTable/cap-table/utilities/index';
import parser from 'pages/CapTable/cap-table/utilities/parser';
import afterCellChanged from './afterCellChanged';
import totalParser from './totalParser';
import { rowConfig, securityTemplate } from '../data';

const useCaptableData = ({ captableInfo, measurementDate, fieldAttributes, isDisabled }) => {
  const [columns, setColumns] = useState();
  const [visibleColumns, setVisibleColumns] = useState();
  const [virtualColumns, setVirtualColumns] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState();

  useEffect(() => {
    if (captableInfo?.securities) {
      setColumns(captableInfo?.securities);
    }
  }, [captableInfo?.securities]);

  const createVirtualColumns = cols => {
    const sortedColumns = sortBy(cols, 'created_at');
    // security_group_ref identifies the securities that derive from the same column
    const uniqueSecurityGroupRefs = uniqBy(
      sortedColumns
        .filter(s => s.security_group_ref && s.id && [WARRANT, OPTION].includes(s.security_type))
        .map(s => ({
          // security_ref in the virtual columns is the security_group_ref of its hidden securities
          security_ref: s.security_group_ref,
          security_group_ref: s.security_group_ref,
          // Build the name of the virtual column based on the security:
          // "$2 Common Warrant" --> "Common Warrant"
          name: s.name.substr(s.name.indexOf(' ') + 1),
          security_type: s.security_type,
          underlying_security_ref: s.underlying_security_ref,
          underlying_security: s.underlying_security,
          order: s.order,
        })),
      'security_group_ref'
    );

    const calculateStrikePrice = hiddenCols => {
      let sharesByStrikePrices = 0;
      let totalShares = 0;

      hiddenCols.forEach(({ shares_outstanding, strike_price }) => {
        // Get the total of shares
        totalShares += Number(shares_outstanding);
        // Multiply each strike price by the number of associated shares
        sharesByStrikePrices += Number(shares_outstanding) * Number(strike_price);
      });
      return sharesByStrikePrices / totalShares;
    };

    if (uniqueSecurityGroupRefs.length > 0) {
      return uniqueSecurityGroupRefs.map(groupRef => ({
        ...securityTemplate,
        id: null, // this identifies a virtual column
        security_ref: groupRef.security_ref,
        cap_table: captableInfo?.id,
        name: groupRef.name,
        shares_outstanding: sumBy(groupBy(sortedColumns, 'security_group_ref')[groupRef.security_group_ref], security =>
          Number(security.shares_outstanding)
        ),
        security_type: groupRef.security_type,
        underlying_security: groupRef.underlying_security,
        underlying_security_ref: groupRef.underlying_security_ref,
        shares_diluted: sumBy(groupBy(sortedColumns, 'security_group_ref')[groupRef.security_group_ref], security =>
          Number(security.shares_diluted)
        ),
        strike_price: calculateStrikePrice(groupBy(sortedColumns, 'security_group_ref')[groupRef.security_group_ref]),
        options_ledger: groupBy(sortedColumns, 'security_group_ref')[groupRef.security_group_ref].map(col => ({
          shares: col.shares_outstanding,
          price: col.strike_price,
          security_ref: col.security_ref,
        })),
        order: groupRef.order,
        security_group_ref: groupRef.security_group_ref,
      }));
    }
    return [];
  };

  useEffect(() => {
    if (columns?.length) {
      setVirtualColumns(createVirtualColumns(columns));
    } else {
      setVirtualColumns([]);
    }
  }, [columns]);

  // Update the visible columns when a column is deleted or added
  useEffect(() => {
    if (columns && measurementDate) {
      const tmpVisibleColumns = columns.filter(column => !column.is_deleted && !column.is_hidden);
      const visibleVirtualColumnRefs = tmpVisibleColumns
        .filter(({ isVirtualColumn }) => isVirtualColumn)
        .map(({ security_ref }) => security_ref);

      if (virtualColumns && !isEmpty(virtualColumns)) {
        virtualColumns.forEach(vc => {
          // Insert virtual columns
          if (!visibleVirtualColumnRefs.includes(vc.security_ref)) {
            // eslint-disable-next-line no-param-reassign
            vc.isVirtualColumn = true;
            tmpVisibleColumns.push(vc);
          }
        });
      }
      setVisibleColumns([...tmpVisibleColumns]);
    } else {
      setVisibleColumns(undefined);
    }
  }, [columns, virtualColumns, measurementDate]);

  const config = useMemo(() => rowConfig({ measurementDate, isDisabled }), [measurementDate, isDisabled]);

  const [rowGroups, setRowGroups] = useReducer(rowsGroupsReducer, INITIAL_CAP_TABLE_STATE);

  useEffect(() => {
    if (captableInfo?.securities?.length) {
      const { securities: cols } = captableInfo;
      setColumns(cols);
      setIsButtonDisabled(false);
    }
  }, [captableInfo]);

  const [spreadsheet, setSpreadsheet] = useState();
  useEffect(() => {
    if (!isUndefined(visibleColumns)) {
      setSpreadsheet(
        new SpreadsheetConfig({
          columns: visibleColumns || [],
          parser,
          totalParser,
          rowConfig: config,
          conditions,
          name: 'captable',
          tableData: {
            columns,
            ...captableInfo,
            isDisabled,
          },
          reverseParser,
          afterCellChanged,
          customValidations,
          showTotalColumn: true,
          showToolbar: true,
          tableTerms: CAP_TABLE_TERMS,
          allowConfirmAndDeleteColumn: !isDisabled,
          customDeleteConfirmation: !isDisabled,
          hasColTitle: true,
          currencyFormatter: true,
          allowEmptyValues: false,
          allowReorderColumns: !isDisabled,
          fieldAttributes,
          allowDeleteColumn: false,
          allowCopyRows: !isDisabled,
          allowCloneColumn: !isDisabled,
          allowAddMultipleColumns: !isDisabled,
          displayRowIndicator: true,
          displayColumnIndicator: true,
        })
      );
    } else {
      setSpreadsheet(null);
    }
  }, [visibleColumns, isDisabled]);

  return {
    spreadsheet,
    rowGroups,
    setRowGroups,
    tableTerms: CAP_TABLE_TERMS,
    totalParser,
    visibleColumns,
    setVisibleColumns,
    virtualColumns,
    setColumns,
    columns,
    isButtonDisabled,
    setIsButtonDisabled,
  };
};

export default useCaptableData;
