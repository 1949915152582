/* eslint-disable react/display-name */
import React from 'react';
import * as inputOptions from 'common/constants/inputs';
import {
  largeCurrencyFormat,
  largeDecimalFormat,
  oneDecimalPercentFormat,
  smallCurrencyFormat,
  xLargeSuffixFormat,
} from 'common/formats/formats';
import { SelectValueViewer } from 'components';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import * as constants from './constants';

const getRowConfig = (md, totalSharesOutstanding) => [
  // 1. Note name
  {
    value: constants.NOTE_NAME_TITLE,
    alias: constants.NOTE_NAME_ALIAS,
    defaultValue: 'ENTER NAME',
    hidden: false,
    readOnly: false,
    gridType: 'string',
    dbType: 'string',
  },
  // 2. Issuance Date
  {
    value: constants.ISSUANCE_DATE_TITLE,
    alias: constants.ISSUANCE_DATE_ALIAS,
    gridType: 'gridDate',
    dbType: 'dbDate',
    readOnly: false,
    isRequired: true,
  },
  // 3. Maturity Date
  {
    value: constants.MATURITY_DATE_TITLE,
    alias: constants.MATURITY_DATE_ALIAS,
    gridType: 'gridDate',
    dbType: 'dbDate',
    readOnly: false,
    isRequired: true,
  },
  // 4. Note Principle Amount
  {
    value: constants.NOTE_PRINCIPLE_AMOUNT_TITLE,
    alias: constants.NOTE_PRINCIPLE_AMOUNT_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: false,
    gridType: 'number',
    dbType: 'string',
    hasTotal: true,
    defaultValue: 0,
    isRequired: true,
  },
  // 5. Interest Rate
  {
    value: constants.INTEREST_RATE_TITLE,
    alias: constants.INTEREST_RATE_ALIAS,
    hidden: false,
    readOnly: false,
    format: oneDecimalPercentFormat,
    gridType: 'percentage',
    maxValue: 100,
    dbType: 'string',
    defaultValue: null,
    isRequired: true,
    dbDecimalPlaces: 1,
  },
  // 6. Compounding
  {
    value: constants.COMPOUNDING_TITLE,
    alias: constants.COMPOUNDING_ALIAS,
    dataEditor: props => <GridSelect options={inputOptions.YES_NO} {...props} />,
    valueViewer: props => <SelectValueViewer {...props} />,
    hidden: false,
    readOnly: false,
    gridType: 'number',
    dbType: 'boolean',
    isRequired: true,
  },
  // 7. Compounding periods per year
  {
    value: constants.COMPOUNDING_PERIODS_PER_YEAR_TITLE,
    alias: constants.COMPOUNDING_PERIODS_PER_YEAR_ALIAS,
    hidden: false,
    readOnly: false,
    format: xLargeSuffixFormat,
    gridType: 'number',
    dbType: 'string',
    allowDecimals: false,
    allowNegativeValue: false,
  },
  // 8. Conversion discount
  {
    value: constants.CONVERSION_DISCOUNT_TITLE,
    alias: constants.CONVERSION_DISCOUNT_ALIAS,
    hidden: false,
    readOnly: false,
    format: oneDecimalPercentFormat,
    gridType: 'percentage',
    maxValue: 100,
    dbType: 'string',
    dbDecimalPlaces: 1,
  },
  // 9. Conversion cap
  {
    className: 'division-bottom-only',
    value: constants.CONVERSION_CAP_TITLE,
    alias: constants.CONVERSION_CAP_ALIAS,
    hidden: false,
    readOnly: false,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
  },
  // 10. Terms Anytime prior
  {
    value: constants.TERMS_ANYTIME_PRIOR_TITLE,
    alias: constants.TERMS_ANYTIME_PRIOR_ALIAS,
    hidden: false,
    readOnly: false,
    gridType: 'string',
    dbType: 'string',
    defaultValue: 'ENTER DATA',
  },
  // 11. Terms At qualified financing
  {
    value: constants.TERMS_AT_QUALIFIED_FINANCING_TITLE,
    alias: constants.TERMS_AT_QUALIFIED_FINANCING_ALIAS,
    hidden: false,
    readOnly: false,
    gridType: 'string',
    dbType: 'string',
    defaultValue: 'ENTER DATA',
  },
  // 12. Terms At change of control
  {
    value: constants.TERMS_AT_CHANGE_OF_CONTROL_TITLE,
    alias: constants.TERMS_AT_CHANGE_OF_CONTROL_ALIAS,
    hidden: false,
    readOnly: false,
    gridType: 'string',
    dbType: 'string',
    defaultValue: 'ENTER DATA',
  },
  // 13. Terms At Maturity
  {
    value: constants.TERMS_AT_MATURITY_TITLE,
    alias: constants.TERMS_AT_MATURITY_ALIAS,
    hidden: false,
    readOnly: false,
    gridType: 'string',
    dbType: 'string',
    className: 'division-bottom-only',
    defaultValue: 'ENTER DATA',
  },
  // 14. Equity Conversion Model
  {
    value: constants.EQUITY_CONVERSION_MODEL_TITLE,
    alias: constants.EQUITY_CONVERSION_MODEL_ALIAS,
    dataEditor: props => <GridSelect options={constants.EQUITY_CONVERSION_MODEL_VALUES} {...props} />,
    valueViewer: props => <SelectValueViewer {...props} />,
    hidden: false,
    readOnly: false,
    gridType: 'number',
    dbType: 'number',
    defaultValue: null,
    isRequired: true,
  },
  // 15. LoanValue
  {
    value: constants.LOAN_VALUE_TITLE,
    alias: constants.LOAN_VALUE_ALIAS,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    hasTotal: true,
  },
  // 16. Conversion Date
  {
    value: constants.CONVERSION_DATE_TITLE,
    alias: constants.CONVERSION_DATE_ALIAS,
    gridType: 'gridDate',
    dbType: 'dbDate',
    readOnly: false,
    isRequired: true,
    tooltipMessages: '',
  },
  // In case we update/change this formula, we need to update the formula in the backend as well (app.cap_table.models.ConvertibleNote.calculate_interest_at_measurement_date)
  // 17. Interest at Measurement Date
  {
    value: constants.INTEREST_AT_MEASUREMENT_DATE_TITLE,
    alias: constants.INTEREST_AT_MEASUREMENT_DATE_ALIAS,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    hasTotal: true,
    expr: constants.INTEREST_AT_MEASUREMENT_DATE_EXPR(md.name),
  },
  // 18. Value at Measurement Date
  {
    value: constants.VALUE_AT_MEASUREMENT_DATE_TITLE,
    alias: constants.VALUE_AT_MEASUREMENT_DATE_ALIAS,
    expr: constants.VALUE_AT_MEASUREMENT_DATE_EXPR,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    hasTotal: true,
  },
  // 19. Value at Conversion
  {
    value: constants.VALUE_AT_CONVERSION_TITLE,
    alias: constants.VALUE_AT_CONVERSION_ALIAS,
    expr: constants.VALUE_AT_CONVERSION_EXPR,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    hasTotal: true,
  },
  // 20. Next Round PreMoney
  {
    value: constants.NEXT_ROUND_PREMONEY_TITLE,
    alias: constants.NEXT_ROUND_PREMONEY_ALIAS,
    hidden: true,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    isRequired: false,
    rowNumberPair: constants.CONVERSION_PRE_MONEY_ROW,
    exprPair: constants.NEXT_ROUND_PREMONEY_EXPR,
  },
  // 21. Conversion Premoney
  {
    value: constants.CONVERSION_PRE_MONEY_TITLE,
    alias: constants.CONVERSION_PRE_MONEY_ALIAS,
    hidden: true,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    isRequired: false,
    rowNumberPair: constants.NEXT_ROUND_PREMONEY_ROW,
    expr: constants.CONVERSION_PRE_MONEY_EXPR,
    exprPair: constants.CONVERSION_PRE_MONEY_EXPR,
    isCellCalculated: true,
  },
  // 22. Pre money share count
  {
    value: constants.PRE_MONEY_SHARE_COUNT_TITLE,
    alias: constants.PRE_MONEY_SHARE_COUNT_ALIAS,
    hidden: true,
    readOnly: true,
    format: largeDecimalFormat,
    gridType: 'number',
    dbType: 'string',
    defaultValue: totalSharesOutstanding,
  },
  // 23. Conversion price per share
  {
    value: constants.CONVERSION_PRICE_PER_SHARE_TITLE,
    alias: constants.CONVERSION_PRICE_PER_SHARE_ALIAS,
    expr: constants.CONVERSION_PRICE_PER_SHARE_EXPR,
    hidden: true,
    readOnly: true,
    format: smallCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
  },
  // 24. Expected shares
  {
    value: constants.EXPECTED_SHARES_TITLE,
    alias: constants.EXPECTED_SHARES_ALIAS,
    expr: constants.EXPECTED_SHARES_EXPR,
    hidden: true,
    readOnly: true,
    format: largeDecimalFormat,
    gridType: 'number',
    dbType: 'string',
    hasTotal: true,
  },
  // 25. Current Ownership
  {
    alias: constants.CURRENT_OWNERSHIP_ALIAS,
    isVisible: false,
  },
];

export default getRowConfig;
