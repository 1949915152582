import { TVTEM_KEY, TVTREM_KEY, TVTRM_KEY } from 'pages/Valuations/approaches/discountCashFlow/dcf/config/constants';
import { DCFSpreadsheets } from 'pages/ValuationsAllocation/util/updateDCFOptions';
import { getObjectValue } from 'utillities';

const getTVTsThatDependOnBenchmark = (spreadsheets?: DCFSpreadsheets) => {
  const names = [TVTRM_KEY, TVTEM_KEY, TVTREM_KEY];
  // keyValuePair : [spreadsheetKey, spreadsheetConfig]
  return Object.entries(getObjectValue(spreadsheets)).filter(keyValuePair => names.includes(keyValuePair[0]));
};

export default getTVTsThatDependOnBenchmark;
