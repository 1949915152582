import React, { useMemo } from 'react';
import Chip from '@material-ui/core/Chip';
import { withTheme } from '@material-ui/core/styles';
import { ArrowDownward as ArrowDownwardIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  chip: {
    padding: `${theme.spacing(0.25)}px ${theme.spacing(2)}px`,
    borderRadius: 25,
  },
  top: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginBottom: '-2px',
  },
  right: {
    borderLeftLeftRadius: 0,
    borderLeftRightRadius: 0,
    marginLeft: '-2px',
  },
  bottom: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginTop: '-2px',
  },
  left: {
    borderRightLeftRadius: 0,
    borderRightRightRadius: 0,
    marginRight: '-2px',
  },
}));

const HiddenContentChip = ({ id, isVisible, label, position, theme }) => {
  const classes = useStyles(position);

  const key = useMemo(() => {
    switch (position) {
      case 'top':
        return 'Bottom';
      case 'right':
        return 'Left';
      case 'bottom':
        return 'Top';
      case 'left':
        return 'Right';
      default:
        break;
    }
  }, [position]);

  if (isVisible) {
    return (
      <div
        id={id}
        data-testid={id}
        className={classes.root}
        style={{
          [`border${key}`]: `2px solid ${theme.palette.primary.main}`,
        }}>
        <Chip
          icon={<ArrowDownwardIcon />}
          label={label}
          color="primary"
          className={`${classes[position]} ${classes.chip}`}
          size="small"
        />
      </div>
    );
  }

  return null;
};

HiddenContentChip.defaultPropTypes = {
  id: '',
  isVisible: false,
  label: 'Read More',
};

HiddenContentChip.propTypes = {
  id: PropTypes.string,
  isVisible: PropTypes.bool,
  label: PropTypes.string,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  theme: PropTypes.object,
};

export default withTheme(HiddenContentChip);
