import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'theme/types';

export const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    tableCellValue: {
      width: '8rem',
      paddingLeft: 0,
      paddingRight: '0.75rem',
    },
    dealCommentsSection: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
  })
);
