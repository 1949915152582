import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ANALYST_VALUE } from 'common/constants/user';
import { transformPermissions, translateRoleIntoPermissions } from 'pages/UserPermissions/utils';
import { FirmService, UsersService } from 'services';

const addUserToFirm = async ({ firmId, userData, permissions }) => {
  const firmService = new FirmService();
  const response = await firmService.addUserToFirm(firmId, userData);
  if (response) {
    const usersService = new UsersService();
    const transformedPermissions = transformPermissions({ permissions });
    await usersService.savePermissions([
      {
        user_id: response.new_user,
        permissions: transformedPermissions.map(p => ({
          ...p,
          firm_role: response.firm_role,
        })),
        skip_user_firm_role_creation: true,
      },
    ]);
  }
  if (!response) throw new Error('An error occurred while adding user to firm');
  return response;
};

const useAddUserToFirm = () => {
  const queryClient = useQueryClient();
  const addFirmUserMutation = useMutation({
    mutationFn: ({ firmId, userData, permissions }) =>
      addUserToFirm({
        firmId,
        userData,
        permissions,
      }),
    onSuccess: () => {
      // Invalidate the firmUsers query to refetch the data including the new user
      queryClient.invalidateQueries('firmUsers');
    },
  });

  const { mutate, isLoading, error, isSuccess } = addFirmUserMutation;

  if (isSuccess) {
    // Clean the mutation internal state
    addFirmUserMutation.reset();
  }

  return { mutate, isLoading, error, isSuccess };
};

const removeUserFromFirm = async ({ firm_id, user_id }) => {
  const firmService = new FirmService();
  const response = await firmService.removeUserFromFirm(firm_id, user_id);
  if (!response) throw new Error('An error occurred while removing user from firm');
  return response;
};

const useRemoveUserFromFirm = () => {
  const queryClient = useQueryClient();
  const removeFirmUserMutation = useMutation({
    mutationFn: ({ firm_id, user_id }) =>
      removeUserFromFirm({
        firm_id,
        user_id,
      }),
    onSuccess: () => {
      // Invalidate the firmUsers query to refetch the data excluding the removed user
      queryClient.invalidateQueries('firmUsers');
    },
  });

  const { mutate, isLoading, error, isSuccess } = removeFirmUserMutation;

  if (isSuccess) {
    // Clean the mutation internal state
    removeFirmUserMutation.reset();
  }

  return { mutate, isLoading, error, isSuccess };
};

const createBulkUsers = async ({ firm_name, firm_id, users, companyList, fundList, skipEmail }) => {
  const data = {
    firm_name,
    firm_id,
    users,
    role_id: ANALYST_VALUE,
    skip_email: skipEmail,
  };
  const usersService = new UsersService();
  const response = await usersService.createBulkUsers(data);
  if (!response) throw new Error('An error occurred while creating users');
  const roleToPermissions = translateRoleIntoPermissions({
    role: { id: ANALYST_VALUE, firmItem: { itemId: null } },
    firmId: firm_id,
    companyList,
    fundList,
  });

  const transformedPermissions = transformPermissions({ permissions: roleToPermissions });

  const usersAndPermissions = response.map(user => ({
    user_id: user.id,
    permissions: transformedPermissions,
    firm_id,
    role_type: ANALYST_VALUE,
    firm_role: user.firm_role_id,
    // Skip user firm role creation since it is already created in the previous step
    skip_user_firm_role_creation: true,
  }));

  await usersService.savePermissions(usersAndPermissions);

  return response;
};

const useCreateBulkUsers = () => {
  const queryClient = useQueryClient();
  const createBulkUsersMutation = useMutation({
    mutationFn: ({ firm_name, firm_id, users, companyList, fundList, skipEmail = false }) =>
      createBulkUsers({
        firm_name,
        firm_id,
        users,
        companyList,
        fundList,
        skipEmail,
      }),
    onSuccess: () => {
      // Invalidate the firmUsers query to refetch the data including the new users
      queryClient.invalidateQueries('firmUsers');
    },
  });

  const { mutate, isLoading, error, isSuccess } = createBulkUsersMutation;

  if (isSuccess) {
    // Clean the mutation internal state
    createBulkUsersMutation.reset();
  }

  return { mutate, isLoading, error, isSuccess };
};

const changeUserFirmRole = async ({ firmId, userId, roleId }) => {
  const firmService = new FirmService();
  const response = await firmService.changeUserFirmRole(firmId, userId, roleId);
  if (!response) throw new Error('An error occurred while changing user firm role');
  return response;
};

const useChangeUserFirmRole = () => {
  const queryClient = useQueryClient();
  const changeUserFirmRoleMutation = useMutation({
    mutationFn: ({ firmId, userId, roleId }) =>
      changeUserFirmRole({
        firmId,
        userId,
        roleId,
      }),
    onSuccess: () => {
      // Invalidate the firmUsers query to refetch the data including the new user
      queryClient.invalidateQueries('firmUsers');
    },
  });

  const { mutate, isLoading, error, isSuccess } = changeUserFirmRoleMutation;

  if (isSuccess) {
    // Clean the mutation internal state
    changeUserFirmRoleMutation.reset();
  }

  return { mutate, isLoading, error, isSuccess };
};

const saveUserPermissions = async ({ userId, roleType, firmId, userPermissionsToSave }) => {
  // Sort permissions by access type DESC so that view permissions are saved first
  userPermissionsToSave.permissions.sort((a, b) =>
    a.feature_object.access_type < b.feature_object.access_type ? -1 : 1
  );
  const usersService = new UsersService();
  const response = await usersService.savePermissions([
    {
      user_id: userId,
      role_type: roleType,
      firm_id: firmId,
      permissions: transformPermissions({
        permissions: userPermissionsToSave?.permissions,
      }),
    },
  ]);
  if (!response) throw new Error('An error occurred while saving user permissions');
  return response;
};

const useSaveUserPermissions = () => {
  const queryClient = useQueryClient();
  const saveUserPermissionsMutation = useMutation({
    mutationFn: ({ userId, roleType, firmId, userPermissionsToSave }) =>
      saveUserPermissions({
        userId,
        roleType,
        firmId,
        userPermissionsToSave,
      }),
    onSuccess: () => {
      // Invalidate the firmUsers query to refetch the data including the new user
      queryClient.invalidateQueries('firmUsers');
    },
  });

  const { mutate, isLoading, error, isSuccess } = saveUserPermissionsMutation;

  if (isSuccess) {
    // Clean the mutation internal state
    saveUserPermissionsMutation.reset();
  }

  return { mutate, isLoading, error, isSuccess };
};

export { useAddUserToFirm, useRemoveUserFromFirm, useCreateBulkUsers, useChangeUserFirmRole, useSaveUserPermissions };
