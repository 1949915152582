/* eslint-disable no-param-reassign */
import { isEmpty } from 'lodash';
import { ONE_HUNDRED_VALUE } from 'common/config/app';
import { GRID_NUMBER_CHECKBOX } from 'common/constants/gridType';
import {
  MEAN_ALIAS,
  MEDIAN_ALIAS,
  PERCENTILE_SELECTION_A_ALIAS,
  PERCENTILE_SELECTION_B_ALIAS,
  SELECTED_MULTIPLE,
  SELECTION,
  WEIGHTED_EV,
  WEIGHTING,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { reverseSelectionCell } from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/reverseParser';
import {
  EBITDA_MULTIPLE_ID,
  EDITABLE_COLUMNS,
  ENTERPRISE_VALUE_ID,
  FINANCIALS_COMPANY_INFO_KEYS,
  GPC_NTM_EBITDA_APPROACH_ALIAS,
  GPC_NTM_REVENUE_APPROACH_ALIAS,
  LTM_EBITDA_ID,
  LTM_REVENUE_ID,
  MULTIPLE_COLUMNS_LETTER,
  MULTIPLE_DISCOUNT_ALIAS,
  NTM_MULTIPLES_IDS,
  REFERENCE_FOR_NTM_EBITDA,
  REFERENCE_FOR_NTM_REVENUE,
  REVENUE_MULTIPLE_ID,
} from 'pages/Valuations/approaches/GuidelineTransactions/config/constants';
import { getFixedValue, trimUpdateFields } from 'pages/Valuations/util/util';
import {
  VALUATIONS_SPECIFIED_ID,
  VALUATIONS_SPECIFIED_LABEL,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { APPROACH_GPC_PROPERTY } from '../../../util/constants';
import updateApproachReference from '../../../util/updateApproachReference';

const GPT_TRANSACTIONS_FIELDS = [
  ENTERPRISE_VALUE_ID,
  LTM_REVENUE_ID,
  LTM_EBITDA_ID,
  REVENUE_MULTIPLE_ID,
  EBITDA_MULTIPLE_ID,
];

const extractMultiple = (cell, updated_gpt_approach, alias, financialsCompanyInfoKey) => {
  // these aren't stored in DB -> don't fix decimal places
  const key = `${financialsCompanyInfoKey}_multiple_options`;
  if (!updated_gpt_approach[key]) {
    updated_gpt_approach[key] = {};
  }
  updated_gpt_approach[key][alias] = cell.value;
};

const setValue = (cell, updated_gpt_approach, fieldAttributes, gpcApproaches) => {
  if (cell.columnId && cell.isGptRow && EDITABLE_COLUMNS.includes(cell.columnId)) {
    const key = updated_gpt_approach.gpt_transactions.findIndex(transaction => {
      const transactionId = transaction.row_ref || transaction.id;
      return transactionId === cell.alias;
    });
    if (key !== -1) {
      updated_gpt_approach.gpt_transactions[key][cell.columnId] = cell.value;
    }
  }
  if (cell.isTitleCell && [PERCENTILE_SELECTION_B_ALIAS, PERCENTILE_SELECTION_A_ALIAS].includes(cell.alias)) {
    updated_gpt_approach[cell.alias] = cell.value;
  }
  // add condition to check if the cell is from  multiple discount and update the value in updated_gpt_approach
  if (cell.alias === MULTIPLE_DISCOUNT_ALIAS && NTM_MULTIPLES_IDS.includes(cell.columnId)) {
    const metricKey = cell.columnId.split('_')[1]; // this is either revenue or ebitda
    const propertyToUpdate = `ntm_${metricKey}_discount`;
    const decimalPlaces = fieldAttributes[propertyToUpdate]?.decimal_places;
    updated_gpt_approach[propertyToUpdate] = Number(cell.value).toFixed(decimalPlaces);
  }

  if (cell.alias === GPC_NTM_EBITDA_APPROACH_ALIAS) {
    updateApproachReference({
      approaches: gpcApproaches,
      column: updated_gpt_approach,
      lookup: cell.value,
      specificApproachKey: APPROACH_GPC_PROPERTY,
      updateKey: GPC_NTM_EBITDA_APPROACH_ALIAS,
      referenceKey: REFERENCE_FOR_NTM_EBITDA,
      extraOptions: [{ id: VALUATIONS_SPECIFIED_ID, name: VALUATIONS_SPECIFIED_LABEL }],
    });
  }

  if (cell.alias === GPC_NTM_REVENUE_APPROACH_ALIAS) {
    updateApproachReference({
      approaches: gpcApproaches,
      column: updated_gpt_approach,
      lookup: cell.value,
      specificApproachKey: APPROACH_GPC_PROPERTY,
      updateKey: GPC_NTM_REVENUE_APPROACH_ALIAS,
      referenceKey: REFERENCE_FOR_NTM_REVENUE,
      extraOptions: [{ id: VALUATIONS_SPECIFIED_ID, name: VALUATIONS_SPECIFIED_LABEL }],
    });
  }
};

function reverseParse(cell, transactionMap, updated_gpt_approach, fieldAttributes, gpcApproaches) {
  if (cell.gridType === GRID_NUMBER_CHECKBOX) {
    const ltmCompanyInfoKey = FINANCIALS_COMPANY_INFO_KEYS[cell.columnId];
    const transactionItem = transactionMap[cell?.alias] ?? transactionMap[cell?.row_ref] ?? transactionMap[cell?.id];

    if (!isEmpty(transactionItem)) {
      transactionItem[`${ltmCompanyInfoKey}_enabled`] = cell?.enabled;
      transactionItem[`${cell.columnId}`] = parseFloat(cell?.revenue).toFixed(2);
    }
  }

  setValue(cell, updated_gpt_approach, fieldAttributes, gpcApproaches);

  if (MULTIPLE_COLUMNS_LETTER.indexOf(cell.columnLegend) >= 0) {
    const financialsCompanyInfoKey = FINANCIALS_COMPANY_INFO_KEYS[cell.columnId];

    if (cell.alias === SELECTION) {
      // make a variable made up of all characters in the cell.columnId before the underscore
      const prefix = cell.columnId.split('_').slice(0, 2).join('_');
      reverseSelectionCell(cell, updated_gpt_approach, prefix);
    } else if (cell.alias === SELECTED_MULTIPLE) {
      const decimalPlaces = fieldAttributes[`${financialsCompanyInfoKey}_${cell.alias}`]?.decimal_places;
      updated_gpt_approach[`${financialsCompanyInfoKey}_${SELECTED_MULTIPLE}`] = getFixedValue(
        cell.value,
        decimalPlaces
      );
      extractMultiple(cell, updated_gpt_approach, SELECTED_MULTIPLE, financialsCompanyInfoKey);
    } else if (cell.alias === WEIGHTING) {
      updated_gpt_approach[`${financialsCompanyInfoKey}_${WEIGHTING}`] = parseInt(cell.value, 10) / ONE_HUNDRED_VALUE;
    } else if (cell.alias === MEAN_ALIAS) {
      extractMultiple(cell, updated_gpt_approach, MEAN_ALIAS, financialsCompanyInfoKey);
    } else if (cell.alias === MEDIAN_ALIAS) {
      extractMultiple(cell, updated_gpt_approach, MEDIAN_ALIAS, financialsCompanyInfoKey);
    } else if (cell.alias === PERCENTILE_SELECTION_A_ALIAS) {
      extractMultiple(cell, updated_gpt_approach, PERCENTILE_SELECTION_A_ALIAS);
    } else if (cell.alias === PERCENTILE_SELECTION_B_ALIAS) {
      extractMultiple(cell, updated_gpt_approach, PERCENTILE_SELECTION_B_ALIAS);
    }
  } else if (cell.alias === WEIGHTED_EV) {
    const enterpriseValueDecimals = fieldAttributes.weighted_enterprise_value.decimal_places;
    updated_gpt_approach.weighted_enterprise_value = getFixedValue(cell.value, enterpriseValueDecimals);
  }
}

const reverseParser = ({ cells, tableData, fieldAttributes }) => {
  const { gptApproachAttrs, transactionAttrs } = fieldAttributes;
  if (cells) {
    const { approach, gpcApproaches } = tableData;
    const transactions = approach.valuations_approach_gpt?.gpt_transactions || [];

    const transactionMap = transactions.reduce((map, transaction) => {
      const transactionId = transaction?.alias ?? transaction?.row_ref ?? transaction?.id ?? '';
      // eslint-disable-next-line no-param-reassign
      map[transactionId] = {
        ...transaction,
        ...trimUpdateFields({
          comparison: transaction,
          fieldAttributes: transactionAttrs,
          fieldList: GPT_TRANSACTIONS_FIELDS,
        }),
      };
      // eslint-disable-next-line no-param-reassign
      const updatedTransaction
        = Object.values(cells).find(titleCell => titleCell.row_ref === transaction.row_ref) ?? {};
      map[transactionId].name = updatedTransaction.value || transaction.name;
      map[transactionId].target_name = updatedTransaction.value || transaction.target_name;
      return map;
    }, {});

    const updated_gpt_approach = {
      ...approach.valuations_approach_gpt,
    };

    updated_gpt_approach.gpt_transactions = Object.values(transactionMap);
    Object.values(cells).forEach(cell => {
      reverseParse(cell, transactionMap, updated_gpt_approach, gptApproachAttrs, gpcApproaches);
    });
    tableData.approach.valuations_approach_gpt = updated_gpt_approach;
  }
};

export default reverseParser;
