import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, GPCComp, Valuation, Waterfall } from 'dashboard409a/components';
import { IGetCaptableByCompanyResponse } from 'dashboard409a/services/types';
import { hideLoadingProgressBar, showLoadingProgressBar } from 'dashboard409a/states/features/global';
import { getGpcComps } from 'dashboard409a/states/features/gpcComps';
import { findCaptable, getCaptablebyCompany, getWaterfallBreakpoint } from 'dashboard409a/states/features/waterfall';
import { AppDispatchType, RootStateType } from 'dashboard409a/states/store';

const Overview: FC = () => {
  const [lastCompanyMeasurementDateId, setLastCompanyMeasurementDateId] = useState<number | undefined>(undefined);
  const [lastMeasurementDateId, setLastMeasurementDateId] = useState<number | undefined>(undefined);

  const { currentCompany } = useSelector((state: RootStateType) => state.global);

  const dispatch = useDispatch<AppDispatchType>();

  const { currentCompanyMeasurementDateId, currentMeasurementDateId } = useSelector(
    (state: RootStateType) => state.global
  );

  useEffect(() => {
    dispatch(showLoadingProgressBar());

    if (currentCompany?.id) {
      if (currentCompanyMeasurementDateId && currentCompanyMeasurementDateId !== lastCompanyMeasurementDateId) {
        setLastCompanyMeasurementDateId(currentCompanyMeasurementDateId);

        dispatch(getGpcComps(currentCompanyMeasurementDateId));
      }

      if (currentMeasurementDateId && currentMeasurementDateId !== lastMeasurementDateId) {
        setLastMeasurementDateId(currentMeasurementDateId);

        dispatch(getCaptablebyCompany({ companyId: currentCompany.id, measurementDateId: currentMeasurementDateId }))
          .then(response => {
            const captableList = response.payload as IGetCaptableByCompanyResponse[];
            const captableId = captableList?.find(c => c.is_primary)?.id;

            if (captableId) {
              dispatch(getWaterfallBreakpoint(captableId));
              dispatch(findCaptable(captableId));
            }
          })
          // eslint-disable-next-line no-console
          .catch(error => console.error(error));
      }

      dispatch(hideLoadingProgressBar());
    }
  }, [
    dispatch,
    currentCompany,
    currentCompanyMeasurementDateId,
    currentMeasurementDateId,
    lastCompanyMeasurementDateId,
    lastMeasurementDateId,
  ]);

  return (
    <>
      <Collapse title="Valuation">
        <Valuation />
      </Collapse>

      <Collapse title="GPC Comps">
        <GPCComp />
      </Collapse>

      <Collapse title="Waterfall">
        <Waterfall />
      </Collapse>
    </>
  );
};

export default Overview;
