import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useParams } from 'react-router-dom';
import { notFoundPath } from 'common/config/urls';
import { CustomRoute } from 'components';
import {
  DASHBOARD_COMPANY_INFORMATION,
  DASHBOARD_COMPANY_OVERVIEW_SLUG,
  DASHBOARD_COMPANY_PREVIOUS_REPORTS_SLUG,
  DASHBOARD_COMPANY_SETTINGS,
  DASHBOARD_COMPANY_SUBSCRIPTION,
} from 'dashboard409a/common/constants';
import { Information, Overview, Settings, Subscription } from 'dashboard409a/pages';
import { getCompanyDetails } from 'dashboard409a/states/features/company';
import { updateGlobalState } from 'dashboard409a/states/features/global';
import { findValuationScalarOpinion, getValuationScalarOpinion } from 'dashboard409a/states/features/valuation';
import { AppDispatchType, RootStateType } from 'dashboard409a/states/store';
import { IDashboardParams } from './types';
import { useStore } from '../../../common/store';
import { UseStoreValues } from '../../../common/types/store';

const Dashboard = () => {
  const { companySlug } = useParams<IDashboardParams>();
  const companies = useSelector((state: RootStateType) => state.user.standalone_companies_permissions);
  const dispatch = useDispatch<AppDispatchType>();
  const { currentCompanyMeasurementDateId } = useSelector((state: RootStateType) => state.global);

  const [{ user }] = useStore() as unknown as UseStoreValues;

  const currentCompany = useMemo(
    () => companies?.find(company => company.slug === companySlug) || null,
    [companies, companySlug]
  );

  const userIsStaff = useMemo(() => {
    if (user) {
      return user.is_staff;
    }
    return false;
  }, [user]);

  useEffect(() => {
    if (currentCompany?.id) {
      dispatch(updateGlobalState({ currentCompany }));
      dispatch(getValuationScalarOpinion(currentCompany?.id));
      dispatch(getCompanyDetails(currentCompany?.id));
    }

    if (currentCompanyMeasurementDateId) {
      dispatch(findValuationScalarOpinion(currentCompanyMeasurementDateId));
    }
  }, [currentCompany, currentCompanyMeasurementDateId, dispatch]);

  return (
    <Switch>
      {/* Reports */}
      <CustomRoute component={Overview} path={DASHBOARD_COMPANY_OVERVIEW_SLUG} isPrivate />
      <CustomRoute component={Overview} path={DASHBOARD_COMPANY_PREVIOUS_REPORTS_SLUG} isPrivate />

      {/* Information */}
      {userIsStaff && <CustomRoute component={Information} path={DASHBOARD_COMPANY_INFORMATION} isPrivate />}

      {/* Company */}
      {userIsStaff && <CustomRoute component={Settings} path={DASHBOARD_COMPANY_SETTINGS} isPrivate />}

      {userIsStaff && <CustomRoute component={Subscription} path={DASHBOARD_COMPANY_SUBSCRIPTION} isPrivate />}

      {/* Not Found */}
      {companySlug ? <Redirect to={DASHBOARD_COMPANY_OVERVIEW_SLUG} /> : <Redirect to={notFoundPath} />}
    </Switch>
  );
};

export default Dashboard;
