import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InputBase, makeStyles } from '@material-ui/core';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import withRequired from 'components/WithRequired/withRequired';
import withTooltip from 'components/WithTooltip/withTooltip';
import { gridShortDate, isValidDate, TEXT_MASK_DATE_FORMAT } from 'utillities';
import slashFormat from 'utillities/slashFormat';

const useStyles = makeStyles(() => ({
  valueViewer: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
  },
  valueContainer: {
    position: 'relative',
    height: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  dateField: {
    '& > input': {
      fontFamily: '"Open Sans", sans-serif, arial !important',
      padding: '6px 0',
      textAlign: 'right !important',
    },
  },
}));

const TextMaskDate = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      showMask
      guide
      keepCharPositions
      pipe={createAutoCorrectedDatePipe(TEXT_MASK_DATE_FORMAT, {
        minYear: 1900,
      })}
    />
  );
};

TextMaskDate.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const GridDateEditor = ({ cell, onChange, onKeyDown }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [date, setDate] = useState(cell.value ? slashFormat(cell.value) : cell.value);
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, [cell]);

  useEffect(() => {
    if (isValidDate(date)) {
      setError(false);
      onChange(gridShortDate(date));
    } else {
      setError(true);
    }
  }, [date, onChange]);

  const handleChange = useCallback(event => {
    const dateParam = event.target.value;
    setDate(dateParam);
  }, []);

  const handleFocus = useCallback(() => {
    /* Place the cursor at the start of the input when it is empty.
    Otherwise (if it has a previous value), put it at the end)  */
    if (!date) {
      inputRef.current.setSelectionRange(0, 0);
    }
  }, [date]);

  if (cell.hidden) {
    return null;
  }

  return (
    <div className={classes.valueViewer}>
      <div className={classes.valueContainer}>
        <InputBase
          id={`${cell.key}-date_editor`}
          inputRef={inputRef}
          value={date}
          inputComponent={TextMaskDate}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          onFocus={handleFocus}
          className={`${classes.dateField} ${error ? 'error-alert' : ''}`}
        />
      </div>
    </div>
  );
};

GridDateEditor.propTypes = {
  cell: PropTypes.object,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
};

const enhance = flowRight(withTooltip, withRequired);

export default enhance(GridDateEditor);
