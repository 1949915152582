import React, { FC, useMemo } from 'react';
import { getArrayValue, getObjectValue, getStringValue } from 'utillities';
import { FundShares } from './components';
import { WeightedShareValuesProps } from './types';

const WeightedShareValues: FC<WeightedShareValuesProps> = props => {
  const {
    isLoading,
    isLoadingValuationSummary,
    isSaving,
    onSpreadsheetChange,
    shouldDisplayWeightedShareValues,
    weightedShareValuesNames,
    workbook,
  } = props;

  const weightedShareValuesSpreadsheets = useMemo(
    () =>
      getArrayValue(Object.values(getObjectValue(workbook))).filter(spreadsheet =>
        weightedShareValuesNames.includes(getStringValue(spreadsheet?.name))
      ),
    [weightedShareValuesNames, workbook]
  );

  if (!shouldDisplayWeightedShareValues) {
    return null;
  }

  return (
    <>
      {weightedShareValuesSpreadsheets?.map(weightedShareValuesSpreadsheet => (
        <FundShares
          isLoading={isLoading}
          isLoadingValuationSummary={isLoadingValuationSummary}
          isSaving={isSaving}
          key={getStringValue(weightedShareValuesSpreadsheet?.name)}
          onSpreadsheetChange={onSpreadsheetChange}
          weightedShareValuesSpreadsheet={weightedShareValuesSpreadsheet}
          workbook={workbook}
        />
      ))}
    </>
  );
};

export default WeightedShareValues;
