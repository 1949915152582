import { useContext } from 'react';
import FeaturesContext from 'context/FeaturesContext';

const useCheckFeatures = () => {
  const { features } = useContext(FeaturesContext);
  const enabledFeatures = Object.entries(features)
    .filter(keyValuePair => keyValuePair[1])
    .map(keyValuePair => keyValuePair[0]);

  const checkFeature = enabler => enabledFeatures.includes(enabler);

  return [features, checkFeature];
};

export default useCheckFeatures;
