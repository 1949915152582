import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '1rem',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
  },
}));

const FormLabelCompGroup = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.title}>{title}</span>
    </div>
  );
};

export default FormLabelCompGroup;

FormLabelCompGroup.propTypes = {
  title: PropTypes.string,
};
