import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'validate.js';
import { MessageBox } from 'components';
import DocumentsTable from './DocumentsTable/DocumentsTable';
import { columns } from '../data/colConfig';

const DocumentsWithoutFolder = ({ documents, isCurrentData, displayOnly, documentsInFolders, measurementDate }) => {
  const rowsToDisplay = displayOnly ? documents.filter(({ file }) => file.filename === displayOnly) : documents;

  return (
    <div style={{ width: '100%' }}>
      {isEmpty(documents) && isEmpty(documentsInFolders) ? (
        <MessageBox title="This measurement date does not have any document." fullWidth hasFrame={false} />
      ) : (
        <DocumentsTable
          columns={columns}
          rows={rowsToDisplay}
          isCurrentData={isCurrentData}
          measurementDate={measurementDate}
        />
      )}
    </div>
  );
};

DocumentsWithoutFolder.propTypes = {
  documents: PropTypes.array,
  isCurrentData: PropTypes.bool,
  displayOnly: PropTypes.string,
  measurementDate: PropTypes.object,
  documentsInFolders: PropTypes.array,
};

export default DocumentsWithoutFolder;
