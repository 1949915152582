import { doesNotExistValue } from 'utillities';

const areAllObjectsValid = ({ objects, keys }) =>
  // We need a non-empty array of objects to check
  objects.length > 0
  // Each object must have all the keys we're checking for
  && objects.every(obj => keys.every(key => Object.keys(obj).includes(key)))
  // Each object must have a non-empty value for each key we're checking for
  && objects.every(obj => keys.every(key => !doesNotExistValue(obj[key])));

export default areAllObjectsValid;
