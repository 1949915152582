import React, { useContext, useState } from 'react';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { DeleteOutline as DeleteIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { ENTER_DATA } from 'common/constants/inputs';
import { ConfirmationDelete } from 'components/Dialogs';
import RowLabelTooltip from 'components/RowLabelTooltip/RowLabelTooltip';
import { DialogContext } from 'context';
import { useRevealEllipsisInTooltip } from 'services/hooks';
import getCancelDialogOptions from 'utillities/getCancelDialogOptions';

const useStyles = makeStyles(() => ({
  fullCell: {
    width: '18.75rem',
    height: '1.75rem',
    textAlign: 'start',
  },
  cellPreviousVersion: {
    width: '11rem',
    height: '1rem',
    marginRight: '16rem',
    textAlign: 'left',
  },
  value: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  deleteButton: {
    width: '1rem',
  },
}));

const DeleteableRowHeader = ({ updateObject, value, removeItem, isPreviousVersion, cell }) => {
  const classes = useStyles();
  const [isHovering, setIsHovering] = useState(false);
  const dialogContext = useContext(DialogContext);
  const titleValue = cell?.value || value || ENTER_DATA;
  const { tooltipRef, width } = useRevealEllipsisInTooltip();

  const handleClick = () => {
    const CUSTOM_MESSAGE = `Are you sure you want to delete ${titleValue}?`;
    const { showDialog } = dialogContext;
    const ContentComponent = <ConfirmationDelete customMessage={CUSTOM_MESSAGE} />;

    showDialog(
      getCancelDialogOptions(
        () => ContentComponent,
        () => removeItem(updateObject)
      )
    );
  };

  if (isPreviousVersion) {
    return (
      <div ref={tooltipRef}>
        <Box
          className={classes.cellPreviousVersion}
          onMouseEnter={() => setIsHovering(false)}
          onMouseLeave={() => setIsHovering(false)}>
          <RowLabelTooltip title={value} containerWidth={width}>
            <span>{titleValue}</span>
          </RowLabelTooltip>
        </Box>
      </div>
    );
  }

  return (
    <div onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      <Box ref={tooltipRef} className={classes.fullCell} display="flex" justifyContent="flex-start" alignItems="center">
        {isHovering && (
          <IconButton className={classes.deleteButton} onClick={handleClick}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
        <RowLabelTooltip title={cell?.value || value || ''} containerWidth={width}>
          <span className={classes.value}>{titleValue}</span>
        </RowLabelTooltip>
      </Box>
    </div>
  );
};

DeleteableRowHeader.propTypes = {
  value: PropTypes.string,
  updateObject: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }),
  cell: PropTypes.shape({
    value: PropTypes.string,
  }),
  removeItem: PropTypes.func,
  isPreviousVersion: PropTypes.bool,
};

export default DeleteableRowHeader;
