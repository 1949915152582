import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { GRID_NUMBER_CHECKBOX, LEDGER, NUMBER, PERCENTAGE } from 'common/constants/gridType';
import { GET_ENTER_DATA } from 'common/constants/inputs';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import RowLabelTooltip from 'components/RowLabelTooltip/RowLabelTooltip';
import withAction from 'components/WithAction/withAction';
import withChip from 'components/WithChip/withChip';
import withDialog from 'components/WithDialog/withDialog';
import withLink from 'components/WithLink/withLink';
import withRequired from 'components/WithRequired/withRequired';
import withTooltip from 'components/WithTooltip/withTooltip';
import { useRevealEllipsisInTooltip } from 'services/hooks';
import { formatNumbers, toString } from 'utillities';

const FastValueViewer = ({ cell, selected, onDoubleClick, setDoubleClicked }) => {
  const { tooltipRef, width } = useRevealEllipsisInTooltip();

  const keyUpHandler = useCallback(
    event => {
      const key = event.key || event.keyCode;
      if (selected && key && key.toUpperCase() === 'F2') {
        //  We used this two functions and setters together because we wanted to adapt our
        //  code to work as the "new double click", a bunch of setters made by Jordan in ValueEditor
        //  to preserve the last value that was before the double click
        setDoubleClicked(true);
        onDoubleClick(event);
      }
    },
    [onDoubleClick, selected, setDoubleClicked]
  );

  const { format } = useContext(FeaturedSpreadsheetContext);

  const cellFormat = useMemo(() => {
    if (format && format !== cell.format) {
      return format;
    }
    return {};
  }, [format, cell.format]);

  const gridTypeHandlers = useMemo(
    () => [
      {
        // eslint-disable-next-line no-restricted-globals
        canHandle: currentCell =>
          [NUMBER, PERCENTAGE, GRID_NUMBER_CHECKBOX].includes(currentCell.gridType) && !isNaN(currentCell.value),
        getValue: currentCell => {
          if (!currentCell.readOnly && !toString(currentCell.value).length) {
            return currentCell.enterDataMessage || GET_ENTER_DATA(currentCell.isRequired, currentCell.enterDataMessage);
          }
          if (currentCell.readOnly && currentCell.allowEmptyValue && !toString(currentCell.value).length) {
            return '';
          }
          return formatNumbers({ ...currentCell, ...cellFormat, ...currentCell.customFormat });
        },
      },
      {
        canHandle: currentCell => currentCell.gridType === LEDGER,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getValue: currentCell => 0,
      },
    ],
    [cellFormat]
  );

  const calcValue = useCallback(() => {
    if (cell.hidden) {
      return '';
    }
    const handler = gridTypeHandlers.find(h => h.canHandle(cell));
    if (handler) {
      return handler.getValue(cell);
    }
    if (!cell.readOnly && !cell.hidden && !cell.value && cell.placeholder) {
      return cell.placeholder;
    }
    return cell.value;
  }, [cell, gridTypeHandlers]);

  const [value, setValue] = useState();

  const getValue = useCallback(() => {
    setValue(calcValue());
  }, [calcValue]);

  useEffect(() => {
    document.addEventListener('keyup', keyUpHandler);
    return () => document.removeEventListener('keyup', keyUpHandler);
  }, [keyUpHandler]);

  useEffect(() => {
    getValue();
  }, [cell.value, cell.currency, cell.suffix, cell.hidden, getValue]);

  const spanId = useMemo(() => {
    if (cell.extraAttr) {
      return `${cell.extraAttr}-${cell.alias}-${cell.key}_viewer`;
    }

    return `${cell.alias}-${cell.key}_viewer`;
  }, [cell]);
  const valueOrNA = useMemo(() => (cell.value !== null ? value : 'N/A'), [cell, value]);

  if (cell.className?.includes('table-header') || cell.className?.includes('header-no-border')) {
    return (
      <span className="value-viewer" ref={tooltipRef}>
        {value ? (
          <RowLabelTooltip title={value} containerWidth={width}>
            <span id={`${cell.alias}-${cell.key}`} className="text-cell">
              {value}
            </span>
          </RowLabelTooltip>
        ) : (
          <span id={`${cell.alias}-${cell.key}`} className="text-cell">
            {value}
          </span>
        )}
      </span>
    );
  }

  return (
    <span className="value-viewer">
      <span id={spanId} className={`text-cell ${cell.className}`}>
        {!cell.displayNAforNull ? value : valueOrNA}
      </span>
    </span>
  );
};

FastValueViewer.propTypes = {
  value: PropTypes.any,
  cell: PropTypes.object,
  selected: PropTypes.bool,
  onDoubleClick: PropTypes.func,
  setDoubleClicked: PropTypes.func,
};

// flowRight is a compose method alternative
const enhance = flowRight(withTooltip, withDialog, withChip, withRequired, withLink, withAction);

export default enhance(FastValueViewer);
