import { useCallback, useEffect, useRef, useState } from 'react';
import { isUndefined } from 'lodash';
import {
  CONVERSION_DATE_ALIAS,
  DATE_ALIAS,
  PURCHASE_DATE_ALIAS,
  SALE_DATE_ALIAS,
} from 'pages/CapTable/fund-ownership/components/shares-ledger/constants';
import { getExchangeRate } from 'utillities';
import slashedToIsoFormat from 'utillities/slashedToIsoFormat';

const DATE_ALIASES = [DATE_ALIAS, PURCHASE_DATE_ALIAS, CONVERSION_DATE_ALIAS, SALE_DATE_ALIAS];

const useExchangeRateMap = ({
  fundCurrency,
  format,
  shares,
  selectedMeasurementDate,
  isDifferentCurrency,
  cellKeys,
  acquisitionsData,
  setAcquisitionsData,
  dateProp = 'date',
}) => {
  const [exchangeRateMap, setExchangeRateMap] = useState({ fundCurrency });

  const exchangeRateMapRef = useRef(exchangeRateMap);

  useEffect(() => {
    const newRateDates = shares?.filter(row => isUndefined(exchangeRateMapRef.current[row[dateProp]]));
    if (newRateDates?.length && isDifferentCurrency) {
      Promise.all(
        newRateDates.map(row =>
          getExchangeRate(format.currency.code, fundCurrency, selectedMeasurementDate.id, row[dateProp])
            .then(response => ({
              date: row[dateProp],
              exchangeRate: response,
            }))
            .catch(() => ({
              date: row[dateProp],
              exchangeRate: -1,
              error: true,
            }))
        )
      ).then(response => {
        setExchangeRateMap(prevState => {
          const tmpExchangeRateMap = response.reduce(
            (acc, row) => {
              acc[row.date] = row.exchangeRate;
              return acc;
            },
            { ...prevState }
          );
          return tmpExchangeRateMap;
        });
      });
    }
  }, [shares, isDifferentCurrency, format, fundCurrency, selectedMeasurementDate, dateProp]);

  useEffect(() => {
    exchangeRateMapRef.current = exchangeRateMap;
  }, [exchangeRateMap]);

  const linkedCellChanges = useCallback(
    (changes, tmpCells) => {
      const { cell, value } = changes[0];
      if (isDifferentCurrency && DATE_ALIASES.includes(cell.alias)) {
        cellKeys.forEach(cellKey => {
          const costCell = tmpCells[`${cellKey}${cell.rowNumber + 1}`];
          costCell.relatedDate = value;
        });
      } else if (
        !isDifferentCurrency
        && cell.alias === PURCHASE_DATE_ALIAS
        && !cell.soldTable
        && !cell.convertedTable
      ) {
        // there might be a better way to update options in another table
        const rowToAlter = cell.rowNumber;
        const tempData = [...acquisitionsData];
        const acquisitionToUpdate = tempData[rowToAlter];
        acquisitionToUpdate.purchase_date = value ? slashedToIsoFormat({ label: value }) : null;
        setAcquisitionsData({ acquisitions: tempData });
      } else if (!isDifferentCurrency && cell.alias === PURCHASE_DATE_ALIAS) {
        // means a child changed its selection for acquisition/purchase date
        const acquisitionRefCell = tmpCells[`acquisition_ref${cell.rowNumber + 1}`];
        acquisitionRefCell.acquisition_ref = cell.acquisition_ref;
        changes.push({ cell: acquisitionRefCell, value: cell.acquisition_ref });
      }
      return changes;
    },
    [isDifferentCurrency, cellKeys, acquisitionsData, setAcquisitionsData]
  );

  return {
    exchangeRateMap,
    linkedCellChanges,
  };
};

export default useExchangeRateMap;
