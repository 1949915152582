export const FILTER = 'filter';
export const DATE_FILTER = `date-${FILTER}`;
export const VERSION = 'Version';
export const VERSION_QUERY_PARAM = VERSION.toLowerCase();
export const VERSION_FILTER = `${VERSION_QUERY_PARAM}-${FILTER}`;

// Measurement Date
export const MEASUREMENT_DATE_FILTER = `measurement-${DATE_FILTER}`;

// Alocation
export const ALLOCATION_VERSION_FILTER = `allocation-${VERSION_FILTER}`;
export const ALLOCATION_VERSION_FILTER_TITLE = `Valuations ${VERSION}`;

// Captable
export const CAPTABLE_VERSION_FILTER = `captable-${VERSION_FILTER}`;
export const CAPTABLE_VERSION_FILTER_TITLE = `Cap Table ${VERSION}`;

// Financial
export const FINANCIAL_VERSION_FILTER = `financials-${VERSION_FILTER}`;
export const FINANCIAL_VERSION_FILTER_TITLE = `Financials ${VERSION}`;

// Fund
export const FUNDS_MEASUREMENT_DATE_FILTER = `funds-${MEASUREMENT_DATE_FILTER}`;

// Company
export const COMPANY_SUMMARY_MEASUREMENT_DATE_FILTER = `company-summary-${MEASUREMENT_DATE_FILTER}`;

// Waterfall
export const WATERFALL_MEASUREMENT_DATE_FILTER = `waterfall-${MEASUREMENT_DATE_FILTER}`;

// Documents
export const DOCUMENTS_MEASUREMENT_DATE_FILTER = `documents-${MEASUREMENT_DATE_FILTER}`;

// Misc
export const SHOW_MORE_ID = 'show_more';
export const SHOW_LESS_ID = 'show_less';
export const SHOW_MORE = 'Show More';
export const SHOW_LESS = 'Show Less';
export const DEFAULT_MAX_OPTIONS = 10;
