/**
 * @file services/funds.js
 * @since v1.0
 */

// #region common
import { NoteData } from 'components/Notes/types';
import BaseServiceRetryWrapper from './baseRetry';
import { NOTES } from '../common/endpoints';

// #endregion

class NotesService extends BaseServiceRetryWrapper {
  /**
   * @function
   * @name getNotes
   * @description Get the note list by page type and page type id
   * @param {string} pageType
   * @param {string} pageTypeKey
   * @param {number} pageTypeValue
   * @return {array}
   */
  getNotes = (pageType: string, pageTypeKey: string, pageTypeId: number) =>
    this.get(NOTES.getNotes(pageType, pageTypeKey, pageTypeId));

  /**
   * @function
   * @name saveNotes
   * @description Save notes: With this endpoint will be possible add, edit and delete a note
   * @return {array}
   */
  saveNotes = (data: NoteData[]) => this.post(NOTES.save, data);
}

export default NotesService;
