import axios from 'axios';
import config from './config';

const client = axios.create({
  baseURL: config.ApiUrl,
  headers: {
    'Content-type': 'application/json',
  },
});

export default client;
