import { useMemo } from 'react';
import { useStore } from 'common/store';
import * as modelConstants from './constants';

const useReadFieldAttributes = page => {
  const [{ fieldAttributes }] = useStore();
  const attributeResult = useMemo(() => {
    const entries = fieldAttributes
      ? Object.entries(fieldAttributes).map(([key, value]) => [key.split('.')[1], value])
      : [];
    const modelsPerPage = {
      fundOwnership: [modelConstants.FUND_OWNERSHIP_MODEL_NAME, modelConstants.FUND_OWNERSHIP_DETAIL_MODEL_NAME],
      capTable: [
        modelConstants.CAPTABLE_MODEL_NAME,
        modelConstants.SECURITY_MODEL_NAME,
        modelConstants.CONVERTIBLE_NOTE_MODEL_NAME,
      ],
      allocations: [
        modelConstants.ALLOCATION_MODEL_NAME,
        modelConstants.ALLOCATION_SCENARIO_MODEL_NAME,
        modelConstants.VALUATION_APPROACH_WEIGHT_MODEL_NAME,
      ],
      financials: [
        modelConstants.INCOME_STATEMENT_MODEL_NAME,
        modelConstants.BALANCE_SHEET_MODEL_NAME,
        modelConstants.KPI_DATA_MODEL_NAME,
      ],
      valuations: [
        modelConstants.VALUATION_APPROACH_MODEL_NAME,
        modelConstants.VALUATION_APPROACH_WEIGHT_MODEL_NAME,
        modelConstants.GPC_COMPARISON_MODEL_NAME,
        modelConstants.GPC_APPROACH_MODEL_NAME,
        modelConstants.GPT_TRANSACTION_MODEL_NAME,
        modelConstants.GPT_APPROACH_MODEL_NAME,
        modelConstants.EFFECTIVE_TAX_RATE_MODEL_NAME,
        modelConstants.DCF_PERIOD_MODEL_NAME,
        modelConstants.DCF_APPROACH_MODEL_NAME,
        modelConstants.EXTERNAL_VALUE_APPROACH_MODEL_NAME,
        modelConstants.SSV_APPROACH_MODEL_NAME,
        modelConstants.BACKSOLVE_APPROACH_MODEL_NAME,
        modelConstants.PUBLIC_COMPANY_ADJUSTMENT_STATUS_MODEL_NAME,
        modelConstants.GPC_BACKSOLVE_RELATION_MODEL_NAME,
        modelConstants.FUTURE_EXIT_MODEL_NAME,
        modelConstants.METHODOLOGY_PRESENT_SHARE_VALUE_MODEL_NAME,
        modelConstants.MULTIPLE_PREMIUM_DISCOUNT_MODEL_NAME,
        modelConstants.CUSTOM_DATE_PERFORMANCE_METRIC_MODEL_NAME,
        modelConstants.COMPANY_AS_OF_DATE_PERFORMANCE_METRIC_MODEL_NAME,
      ],
    };
    const pageModels = modelsPerPage[page] || [];

    const results = entries.filter(([key]) => pageModels.includes(key));
    return new Map(results);
  }, [fieldAttributes, page]);

  return attributeResult;
};

export default useReadFieldAttributes;
