import React from 'react';
import moment from 'moment';
import { oneDecimalPercentFormatValidateFloatTrue, yearsDecimalFormat } from 'common/formats/formats';
import { SelectValueViewer } from 'components';
import { GridDate, GridDateEditor, GridDateValueViewer, GridSelect } from 'components/FeaturedSpreadsheet/components';
import {
  MATURITY_ALIAS,
  MATURITY_DATE_ALIAS,
  MATURITY_DATE_TITLE,
  MATURITY_TITLE,
  OPM_BACKSOLVE_DATE_ALIAS,
  OPM_BACKSOLVE_DATE_TITLE,
  RISK_ALIAS,
  RISK_TITLE,
  VOLATILITY_ALIAS,
  VOLATILITY_SOURCE_ALIAS,
  VOLATILITY_SOURCE_TITLE,
  VOLATILITY_TITLE,
} from 'pages/Valuations/approaches/backsolveApproach/OPMInputTable/constants';
import { gridShortDate } from 'utillities';

export default ({ tableData }) => {
  const backsolveDate = gridShortDate(tableData.data.opm_backsolve_date || tableData.measurementDate.date);
  const maturity = tableData.data[MATURITY_ALIAS];
  const { isDisabled, volatilitySourceOptions } = tableData;
  const cells = {
    A1: {
      key: 'A1',
      value: OPM_BACKSOLVE_DATE_TITLE,
    },
    A2: {
      key: 'A2',
      value: MATURITY_TITLE,
    },
    A3: {
      key: 'A3',
      value: MATURITY_DATE_TITLE,
    },
    A4: {
      key: 'A4',
      value: VOLATILITY_SOURCE_TITLE,
    },
    A5: {
      key: 'A5',
      value: VOLATILITY_TITLE,
    },
    A6: {
      key: 'A6',
      value: RISK_TITLE,
    },
    B1: {
      key: 'B1',
      value: backsolveDate,
      alias: OPM_BACKSOLVE_DATE_ALIAS,
      readOnly: isDisabled,
      gridType: 'gridDate',
      dataEditor: props => <GridDateEditor {...props} />,
      valueViewer: props => <GridDateValueViewer {...props} />,
      gridDateComponent: GridDate,
      format: null,
      isRequired: true,
      dbType: 'dbDate',
      customKey: 'OPM_BACKSOLVE_DATE',
    },
    B2: {
      alias: MATURITY_ALIAS,
      customKey: 'MATURITY',
      dbType: 'string',
      format: yearsDecimalFormat,
      greaterThanIfOPM: 0,
      gridType: 'number',
      isRequiredIfOPM: true,
      key: 'B2',
      readOnly: isDisabled,
      value: maturity,
    },
    B3: {
      alias: MATURITY_DATE_ALIAS,
      gridType: 'string',
      key: 'B3',
      readOnly: true,
      value:
        tableData.data.maturity_date
        || moment(backsolveDate, 'MM/DD/YYYY').clone().add(Number(maturity), 'years').format('MM/DD/YYYY'),
    },
    B4: {
      key: 'B4',
      value: tableData.data.volatility_source || tableData.data.reference_for_volatility || null,
      alias: VOLATILITY_SOURCE_ALIAS,
      readOnly: isDisabled,
      dbType: 'number',
      dropdown: true,
      dataEditor: props => <GridSelect menuPosition="fixed" options={volatilitySourceOptions} {...props} />,
      valueViewer: props => <SelectValueViewer options={volatilitySourceOptions} {...props} />,
    },
    B5: {
      alias: VOLATILITY_ALIAS,
      customKey: 'VOLATILITY',
      dbDecimalPlaces: 4,
      dbType: 'string',
      format: oneDecimalPercentFormatValidateFloatTrue,
      greaterThanIfOPM: 0,
      gridType: 'percentage',
      isRequiredIfOPM: true,
      key: 'B5',
      readOnly: isDisabled,
      value: tableData.data[VOLATILITY_ALIAS],
    },
    B6: {
      alias: RISK_ALIAS,
      customKey: 'RISK_FREE_RATE',
      format: oneDecimalPercentFormatValidateFloatTrue,
      gridType: 'percentage',
      key: 'B6',
      value: tableData.data[RISK_ALIAS],
    },
  };
  return cells;
};
