/* eslint-disable import/no-unresolved,react/prop-types */
import React, { useCallback } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { formatNumbers } from 'utillities';

const isIconVisible = true;

// eslint-disable-next-line react/display-name
const withLink = WrappedComponent => props => {
  const history = useHistory();

  const openLink = useCallback(() => {
    if (props.cell?.link?.url) {
      history.push(props.cell.link.url);
    }
  }, [history, props.cell]);

  if (isEmpty(props.cell.link)) {
    return <WrappedComponent {...props} />;
  }

  return (
    <div className="link">
      <span className="value-viewer link__value">
        {props.cell.component ? (
          React.cloneElement(props.cell.component, { cell: props.cell }, null)
        ) : (
          <Link to={props.cell.link.url}>{formatNumbers(props.cell)}</Link>
        )}
      </span>
      {isIconVisible && (
        <div>
          <Tooltip
            title={props.cell.link.tooltip || ''}
            placement="top"
            arrow
            disableHoverListener={!props.cell.link.tooltip}>
            <IconButton onClick={openLink} size="small">
              <LinkIcon className="link__icon" size="small" color="secondary" />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

withLink.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
};

withLink.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default withLink;
