import React, { FC, useEffect, useState } from 'react';
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FormDialog } from 'components/Dialogs';
import { ICardDialogProps } from '../types';

const ADD_TITLE = 'Add New Card';

const CardDialog: FC<ICardDialogProps> = ({ open, onSave, onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(
    () => () => {
      elements?.getElement(PaymentElement)?.unmount();
      elements?.getElement(AddressElement)?.unmount();
    },
    [elements]
  );

  const handleSave = async () => {
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: '',
      },
      redirect: 'if_required',
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      onSave();
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <FormDialog
      title={ADD_TITLE}
      open={open}
      isValid
      onSave={handleSave}
      onClose={handleClose}
      allowDelete
      shouldCloseOnDelete={false}>
      <form>
        <PaymentElement />
        <AddressElement
          options={{
            mode: 'billing',
            fields: { phone: 'always' },
            validation: { phone: { required: 'always' } },
          }}
        />
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    </FormDialog>
  );
};

export default CardDialog;
