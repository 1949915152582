import { globalAction } from 'common/actions';
import { useStore } from 'common/store';

const useHandleClickDialog = () => {
  const [, dispatch] = useStore();

  // Per SonarCloud and the React docs, JSX props should not use arrow functions
  function handleClick() {
    dispatch(globalAction.showCompanyDialog(true));
  }

  return handleClick;
};

export default useHandleClickDialog;
