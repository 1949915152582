/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { LedgerTable } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { cellsParser } from 'components/LedgerTable/utilities/ledgerParser';
import CurrencyAdjustedValueViewer from 'components/Spreadsheet/components/CurrencyAdjustedValueViewer';
import {
  AMOUNT_ALIAS,
  COST_BASIS_ALIAS,
  CURRENCY_CELLS,
  PROCEEDS_ALIAS,
  SALE_DATE_ALIAS,
  SHARES_ALIAS,
  SOLD_PERCENT_ALIAS,
} from 'pages/CapTable/fund-ownership/components/shares-ledger/constants';
import useExchangeRateMap from 'pages/CapTable/fund-ownership/components/shares-ledger/hooks/useExchangeRateMap';
import { colConfig } from './colConfig';
import conditions from '../../conditions';

const preEmptyRow = {
  sale_date: null,
  purchase_date: null,
  acquisition_ref: null,
};

const SoldTable = ({
  cell,
  isTableValid,
  setIsTableValid,
  setShowAlert,
  doFullValidation,
  setIsValid,
  updateValidationStatus,
  isDisabled,
  modeledAfterConvertibleNote,
  fundCurrency,
  isDifferentCurrency,
  selectedMeasurementDate,
  format,
  isLoanNote,
  acquisitionsData,
  setAcquisitionsData,
  reverseParsedAcquisitions,
  soldCells,
  setSoldCells,
}) => {
  const emptyRow = useMemo(() => {
    if (modeledAfterConvertibleNote) {
      if (isLoanNote) {
        return {
          ...preEmptyRow,
          sold_percent: null,
          proceeds: null,
          sale_ref: uuid(),
        };
      }
      return { ...preEmptyRow, amount: null };
    }
    return {
      ...preEmptyRow,
      shares: null,
      cost_basis: null,
      proceeds: null,
      sale_ref: uuid(),
    };
  }, [modeledAfterConvertibleNote, isLoanNote]);

  const disabledCondition = useMemo(
    () => isDisabled || isEmpty(acquisitionsData?.acquisitions),
    [isDisabled, acquisitionsData]
  );

  const { cells } = useContext(FeaturedSpreadsheetContext);
  const [rows, setRows] = useState();

  const cellKeys = useMemo(() => {
    if (modeledAfterConvertibleNote) {
      if (isLoanNote) {
        return [PROCEEDS_ALIAS, SOLD_PERCENT_ALIAS];
      }
      return [AMOUNT_ALIAS];
    }
    return [PROCEEDS_ALIAS, COST_BASIS_ALIAS, SHARES_ALIAS];
  }, [modeledAfterConvertibleNote, isLoanNote]);

  const { linkedCellChanges, exchangeRateMap } = useExchangeRateMap({
    fundCurrency,
    format,
    shares: rows,
    selectedMeasurementDate,
    isDifferentCurrency,
    cellKeys,
    dateProp: SALE_DATE_ALIAS,
  });

  const addRow = useCallback(() => {
    // this needs to update the acquisitionsData so it re renders top down
    // sales data must be inside an acquisitions data, set to the newest
    if (isEmpty(acquisitionsData?.acquisitions)) {
      return;
    }
    const newestAcquisition = reverseParsedAcquisitions.reduce((acc, curr) =>
      acc.purchase_date > curr.purchase_date ? acc : curr
    );
    const newRow = {
      ...emptyRow,
      acquisition_ref: newestAcquisition.acquisition_ref,
      purchase_date: newestAcquisition.purchase_date,
    };
    newestAcquisition.sales.push(newRow);
    const insertionIndex = acquisitionsData?.acquisitions.findIndex(
      acq => acq.acquisition_ref === newestAcquisition.acquisition_ref
    );
    const updatedAcquisitions = [...reverseParsedAcquisitions];
    updatedAcquisitions[insertionIndex] = newestAcquisition;
    setAcquisitionsData({ ...acquisitionsData, acquisitions: updatedAcquisitions });
  }, [acquisitionsData, setAcquisitionsData, reverseParsedAcquisitions, emptyRow]);

  const deleteRow = useCallback(
    rowIndex => {
      if (!rows) {
        return;
      }
      // find the acquisition the share sale belongs to
      const updatedAcquisitionsData = { ...acquisitionsData };
      const acquisitionRef = rows[rowIndex].acquisition_ref;
      const acquisition = updatedAcquisitionsData?.acquisitions.find(acq => acq.acquisition_ref === acquisitionRef);
      // find the sale in the acquisition and then remove it
      const saleIndex = acquisition.sales.findIndex(sale => sale.sale_ref === rows[rowIndex].sale_ref);
      if (acquisition.sales[saleIndex]?.id) {
        acquisition.deleted_sales.push(acquisition.sales[saleIndex].id);
      }
      acquisition.sales.splice(saleIndex, 1);
      setAcquisitionsData(updatedAcquisitionsData);
    },
    [acquisitionsData, setAcquisitionsData, rows]
  );

  const config = useMemo(
    () => colConfig(modeledAfterConvertibleNote, cell.isCustomSecurity, !!isLoanNote, acquisitionsData?.acquisitions),
    [modeledAfterConvertibleNote, cell.isCustomSecurity, acquisitionsData, isLoanNote]
  );

  // Set Rows
  useEffect(() => {
    if (cells && acquisitionsData?.acquisitions) {
      if (isEmpty(acquisitionsData?.acquisitions)) {
        setRows([]);
      } else if (modeledAfterConvertibleNote && acquisitionsData?.acquisitions.length) {
        const sales = acquisitionsData?.acquisitions.map(acq => acq.sales).flat();
        const fromExistingSold = sales.map(row => {
          const purchase_date = acquisitionsData?.acquisitions.find(
            acq => acq.acquisition_ref === row.acquisition_ref
          ).purchase_date;
          const commonValues = {
            purchase_date,
            sale_date: row.sale_date,
            acquisition_ref: row.acquisition_ref,
            sale_ref: row.sale_ref,
            id: row.id ?? 0,
          };

          if (isLoanNote) {
            return {
              ...commonValues,
              sold_percent: row.sold_percent,
              proceeds: row.proceeds || null,
            };
          }
          return {
            ...commonValues,
            amount: row.cost_basis || row.amount,
          };
        });
        setRows(fromExistingSold || []);
      } else {
        const sales = acquisitionsData?.acquisitions.map(acq => acq.sales).flat();
        const salesWithReferences = sales.map(row => ({
          purchase_date: acquisitionsData?.acquisitions.find(acq => acq.acquisition_ref === row.acquisition_ref)
            ?.purchase_date,
          sale_date: row.sale_date,
          cost_basis: row.cost_basis || null,
          shares: row.shares || null,
          proceeds: row.proceeds || null,
          acquisition_ref: row.acquisition_ref,
          sale_ref: row.sale_ref,
          id: row.id ?? 0,
        }));
        setRows(salesWithReferences || []);
      }
    }
  }, [cells, acquisitionsData, isLoanNote, modeledAfterConvertibleNote]);

  // Parse cells
  useEffect(() => {
    if (Array.isArray(rows)) {
      const parsedCells = cellsParser(rows, config);
      Object.values(parsedCells).forEach(parsedCell => {
        if (cellKeys.includes(parsedCell.alias) && isDifferentCurrency) {
          if (CURRENCY_CELLS.includes(parsedCell.alias)) {
            parsedCell.valueViewer = CurrencyAdjustedValueViewer;
          }
          parsedCell.relatedDate = parsedCells[`sale_date${parsedCell.rowNumber + 1}`].value;
        }
      });
      setSoldCells(parsedCells);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isDifferentCurrency, setSoldCells, cellKeys]);

  return (
    <>
      <h4>Sold</h4>
      <br />
      <LedgerTable
        colConfig={config}
        rows={rows}
        emptyRow={emptyRow}
        tableTerms={{
          tableName: 'Sold Shares',
          tableSlug: 'sold-table',
          columnName: `${modeledAfterConvertibleNote ? 'Note' : 'Shares'}`,
          pluralColumnName: 'Sold Shares',
        }}
        isTableValid={isTableValid}
        setIsTableValid={setIsTableValid}
        setShowAlert={setShowAlert}
        disabled={disabledCondition}
        addRow={addRow}
        deleteRow={deleteRow}
        cells={soldCells}
        setCells={setSoldCells}
        data={rows}
        doFullValidation={doFullValidation}
        setIsValid={setIsValid}
        updateValidationStatus={updateValidationStatus}
        tableData={{ acquisitions: reverseParsedAcquisitions }}
        conditions={conditions}
        currency={format.currency}
        linkedCellUpdates={linkedCellChanges}
        exchangeRateMap={exchangeRateMap}
      />
    </>
  );
};

SoldTable.propTypes = {
  acquisitionsData: PropTypes.shape({
    acquisitions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setAcquisitionsData: PropTypes.func,
  reverseParsedAcquisitions: PropTypes.arrayOf(PropTypes.shape({})),
  cell: PropTypes.object,
  isTableValid: PropTypes.bool,
  modeledAfterConvertibleNote: PropTypes.bool,
  isDisabled: PropTypes.bool,
  setIsTableValid: PropTypes.func,
  setShowAlert: PropTypes.func,
  doFullValidation: PropTypes.bool,
  setIsValid: PropTypes.func,
  updateValidationStatus: PropTypes.func,
  fundCurrency: PropTypes.string,
  isDifferentCurrency: PropTypes.bool,
  selectedMeasurementDate: PropTypes.object,
  format: PropTypes.object,
  isLoanNote: PropTypes.bool,
  soldCells: PropTypes.shape({}),
  setSoldCells: PropTypes.func,
};

export default SoldTable;
