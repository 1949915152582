import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import VerticalShortcuts from './VerticalShortcuts';

const HorizontalShortcuts = ({ browserOS, shortcuts }) => {
  const rowsPerColumn = 4;
  const columns = [];

  for (let i = 0; i < shortcuts.length; i += rowsPerColumn) {
    const chunk = shortcuts.slice(i, i + rowsPerColumn);
    columns.push(chunk);
  }

  return columns.map((column, idx) => {
    const fragmentKey = `fragment-${idx}`;
    const columnKey = `column-${idx}`;
    return (
      <React.Fragment key={fragmentKey}>
        {idx !== 0 ? <Divider orientation="vertical" flexItem /> : null}
        <Grid item container direction="column" xs={12 / columns.length} spacing={2} key={columnKey}>
          <VerticalShortcuts browserOS={browserOS} shortcuts={column} key={columnKey} />
        </Grid>
      </React.Fragment>
    );
  });
};

export default HorizontalShortcuts;
