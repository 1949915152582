export default {
  openNewCompanyDialog: false,
  openFirmDrawer: false,
  openFundDrawer: false,
  openAllocationDrawer: false,
  openCaptableDrawer: false,
  openAlertNotification: false,
  isShowLoadingProgress: false,
  firmId: null,
  firmList: null,
  userManagementFirmList: null,
  fundList: null,
  fundInfo: {},
  currentMeasurementDateByFund: null,
  companyList: null,
  user: null,
  activationEmail: null,
  userSelected: null,
  selectedUserPermissions: null,
  dialogFlag: {
    openDialogFlag: false,
    dialogs: [],
  },
};
