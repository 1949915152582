import { isNil } from 'lodash';
import { NOT_APPLICABLE as NOT_AVAILABLE } from 'common/constants/general';
import { VALUATIONS_DEFAULT_CURRENCY } from 'common/constants/valuations';
import { getCurrency } from 'pages/ValuationsAllocation/util/getCurrency';

const formatCurrencyValue = (value: string | null | undefined, currency: string | undefined): string => {
  if (isNil(value)) return NOT_AVAILABLE;
  const currencySymbol = getCurrency({ currencyKey: currency, defaultValue: VALUATIONS_DEFAULT_CURRENCY });
  const locale = currency === VALUATIONS_DEFAULT_CURRENCY ? 'en-US' : currency;
  return `${currencySymbol} ${Number(value).toLocaleString(locale, { maximumFractionDigits: 0 })}`;
};

export default formatCurrencyValue;
