import { isUndefined } from 'lodash';
import { MathCollection, MathScalarType, mean, median } from 'mathjs';
import { GPC, GPT } from 'api';
import { Q25, Q75 } from 'common/formulas/math.js';
import {
  CommonGPCGPTKeys,
  CommonSelectedMultiplesGPCGPTKeys,
  MapApproachesParams,
  MapValues,
  MapValuesParams,
} from './types';

const mapValues = (params: MapValuesParams): MapValues => {
  const { comps, specificApproach, ltmOnly } = params;
  const periodKeys = ['ltm'];
  if (!ltmOnly) {
    periodKeys.push('ntm');
  }
  const typeKeys = ['revenue', 'ebitda'];
  return periodKeys.reduce((sofar: MapValues, periodKey: string) => {
    typeKeys.forEach(typeKey => {
      const key = `${periodKey}_${typeKey}`;
      const values = comps
        .map((comp: GPC | GPT) => {
          const keyComp = `${key}_enabled` as keyof CommonGPCGPTKeys;
          if (comp[keyComp]) {
            if (Number(comp[keyComp])) {
              return Number(comp.enterprise_value) / Number(comp[keyComp]);
            }
          }
          return undefined;
        })
        .filter(c => c);
      const selectedMultipleKey = `${key}_selected_multiple` as keyof CommonSelectedMultiplesGPCGPTKeys;
      // eslint-disable-next-line no-param-reassign
      sofar[`${key}_selected_multiple`] = specificApproach[selectedMultipleKey] as unknown as MathScalarType;
      // eslint-disable-next-line no-param-reassign
      sofar[`${key}_median`] = median(values as MathCollection);
      // eslint-disable-next-line no-param-reassign
      sofar[`${key}_mean`] = mean(values as MathCollection);
      // eslint-disable-next-line no-param-reassign
      sofar[`${key}_75th`] = Q75(values);
      // eslint-disable-next-line no-param-reassign
      sofar[`${key}_25th`] = Q25(values);
    });
    return sofar;
  }, {});
};

const mapApproaches = (params: MapApproachesParams) => {
  const { approaches } = params;

  return approaches?.reduce((sofar: any, approach: any) => {
    if (approach?.valuations_approach_gpc && !isUndefined(approach.id)) {
      // eslint-disable-next-line no-param-reassign
      sofar[approach.id] = mapValues({
        comps: approach.valuations_approach_gpc.gpc_comparison,
        specificApproach: approach.valuations_approach_gpc,
        ltmOnly: true,
      });
    }
    if (approach?.valuations_approach_gpt && !isUndefined(approach.id)) {
      // eslint-disable-next-line no-param-reassign
      sofar[approach.id] = mapValues({
        comps: approach.valuations_approach_gpt.gpt_transactions,
        specificApproach: approach.valuations_approach_gpt,
        ltmOnly: true,
      });
    }
    return sofar;
  }, {});
};

export default mapApproaches;
