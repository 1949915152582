import moment from 'moment';
import { MOMENT_DEFAULT_DATE_FORMAT } from 'utillities/datesFormats';

const getYearQuarters = (yearDate, fiscalYearData) => {
  const yearKey = yearDate?.year();
  if (fiscalYearData.years[yearKey]) {
    return Object.entries(fiscalYearData.years[yearKey]).map(([name, date]) => ({ name, date }));
  }
  return [];
};

export default getYearQuarters;

export const getRecentQuarters = (fiscalYearData, currentPeriod) =>
  Object.entries(fiscalYearData.years)
    .filter(([year]) => year >= currentPeriod.year() - 2 && year <= currentPeriod.year() + 2)
    .flatMap(([, quarters]) =>
      Object.entries(quarters).map(([name, date]) => ({
        name,
        date: moment(date, MOMENT_DEFAULT_DATE_FORMAT, false),
      }))
    );

export const getLtmQuarters = (proximalQuarters, currentPeriod, firstQuarterDate) => {
  const quarterNames = ['LTM Q1', 'LTM Q2', 'LTM Q3', 'LTM Q4'];
  return proximalQuarters
    .filter(
      ({ date: proximalQuarter }) =>
        proximalQuarter.isSameOrBefore(currentPeriod) && proximalQuarter.isAfter(firstQuarterDate)
    )
    .map(({ date }) => ({
      name: quarterNames.shift(),
      date,
    }));
};

export const getNtmQuarters = (proximalQuarters, currentPeriod, lastQuarterDate) => {
  const quarterNames = ['NTM Q1', 'NTM Q2', 'NTM Q3', 'NTM Q4'];
  return proximalQuarters
    .filter(
      ({ date: proximalQuarter }) =>
        proximalQuarter.isAfter(currentPeriod) && proximalQuarter.isSameOrBefore(lastQuarterDate)
    )
    .map(({ date }) => ({
      name: quarterNames.shift(),
      date,
    }));
};
