import React, { useMemo } from 'react';
import { List, ListSubheader } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import useMDContext from 'context/MDContext';
import StatusListItem from './components/StatusListItem';

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'auto',
    maxHeight: '60vh',
  },
}));

const RolloverStatus = () => {
  const classes = useStyles();
  const { items, statusList, subItems } = useMDContext();

  const fundIdNameMap = useMemo(
    () =>
      items.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item.name,
        }),
        {}
      ),
    [items]
  );

  const allocationIdCompanyNameMap = useMemo(
    () =>
      subItems.reduce(
        (acc, subItem) => ({
          ...acc,
          [subItem.allocationId]: subItem.name,
        }),
        {}
      ),
    [subItems]
  );
  const companyIdNameMap = useMemo(
    () =>
      subItems.reduce(
        (acc, subItem) => ({
          ...acc,
          [subItem.id]: subItem.name,
        }),
        {}
      ),
    [subItems]
  );

  return (
    <div className={classes.container}>
      {statusList?.funds?.length !== 0 && (
        <span>
          <List
            subheader={
              <ListSubheader disableSticky>
                <strong>Funds</strong>
              </ListSubheader>
            }>
            {fundIdNameMap
              && statusList.funds.map((fund, index) => (
                <StatusListItem key={fund.id} item={fund} index={index} idNameMap={fundIdNameMap} />
              ))}
          </List>
          <br />
        </span>
      )}
      {(statusList?.allocations?.length !== 0 || statusList.capTablesOnly?.length !== 0) && (
        <List
          subheader={
            <ListSubheader disableSticky>
              <strong>Companies</strong>
            </ListSubheader>
          }>
          {allocationIdCompanyNameMap
            && statusList.allocations.map((allocation, index) => (
              <StatusListItem
                key={allocation.id}
                item={allocation}
                index={index}
                idNameMap={allocationIdCompanyNameMap}
              />
            ))}
          {companyIdNameMap
            && statusList.capTablesOnly.map((capTable, index) => (
              <StatusListItem key={capTable.id} item={capTable} index={index} idNameMap={companyIdNameMap} />
            ))}
        </List>
      )}
    </div>
  );
};

export default RolloverStatus;
