import { SERVICES_AN_ERROR_OCCURRED_LOADING } from 'common/constants/services/services';

// Firm Tasks Progress
export const TASKS_PROGRESS_BY_FIRM_QUERY_KEY = 'TASKS_PROGRESS_BY_FIRM';
export const TASKS_PROGRESS_BY_FIRM_TITLE = 'Firm Tasks Progress';
export const TASKS_PROGRESS_BY_FIRM_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_LOADING(TASKS_PROGRESS_BY_FIRM_TITLE);

// Firm Task Progress Details
export const TASK_PROGRESS_DETAILS_QUERY_KEY = 'TASK_PROGRESS_DETAILS';
export const TASK_PROGRESS_DETAILS_TITLE = 'Firm Tasks Progress';
export const TASK_PROGRESS_DETAILS_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_LOADING(TASK_PROGRESS_DETAILS_TITLE);

// Funds by Firm
export const FUNDS_BY_FIRM_QUERY_KEY = 'FUNDS_BY_FIRM';
export const FUNDS_BY_FIRM_TITLE = 'Funds';
export const FUNDS_BY_FIRM_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_LOADING(FUNDS_BY_FIRM_TITLE);
