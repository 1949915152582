import React, { useState } from 'react';
import { Grid, IconButton, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { defaultStyles, FileIcon } from 'react-file-icon';

const useStyles = makeStyles({
  textField: {
    width: '25.563rem',
    marginBottom: '1rem',
    backgroundColor: '#ffff',
    borderRadius: '0.25rem',
  },
  optionReference: {
    opacity: '0.4',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'right',
  },
});

const SearchInDocumentsPage = ({ documents, setSelectedValue, mapDates }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const searchInput = document.querySelector('#search-documents');

  const handleOnChange = (_event, newValue, reason) => {
    if (reason === 'select-option') {
      setSelectedValue(newValue.file.filename);
      searchInput.blur();
    }
  };

  const handleOnClose = (_event, reason) => {
    if (reason === 'escape') {
      setSelectedValue(undefined);
      setInputValue('');
      searchInput.blur();
    }
  };

  return (
    <>
      {documents && (
        <Autocomplete
          id="search-documents"
          style={{ width: '25em' }}
          freeSolo
          options={documents}
          getOptionLabel={option => option.file.filename || ''}
          disableClearable
          forcePopupIcon={false}
          onChange={handleOnChange}
          onClose={handleOnClose}
          inputValue={inputValue}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
            if (!newInputValue) setSelectedValue(undefined);
          }}
          renderOption={option => (
            <Grid container direction="row" alignItems="center" spacing={1} justifyContent="space-between">
              <Grid item xs={1}>
                <FileIcon extension={option.file.file_type} {...defaultStyles[option.file.file_type]} />
              </Grid>
              <Grid item xs={7}>
                <Typography>{option.file.filename}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.optionReference}>
                  {mapDates[option.md_documents]} {option.file.folder ? ` | ${option.file.folder}` : ''}
                </Typography>
              </Grid>
            </Grid>
          )}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Search..."
              variant="outlined"
              className={classes.textField}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton data-testid="search-ticker-btn">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: false,
              }}
            />
          )}
        />
      )}
    </>
  );
};

SearchInDocumentsPage.propTypes = {
  documents: PropTypes.array,
  setSelectedValue: PropTypes.func,
  mapDates: PropTypes.object,
};

export default SearchInDocumentsPage;
