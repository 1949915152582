import { isEmpty } from 'lodash';
import { getNumberValue } from 'utillities';
import { HandleScenarioWeightingProbabilityParams } from './types';

const handleScenarioWeightingProbability = (params: HandleScenarioWeightingProbabilityParams) => {
  const { scenario, scenarioWeightingProbability, updateWeightingProbabilities, weightingProbability } = params;

  if (!isEmpty(scenario)) {
    scenario.weighting_probability = scenarioWeightingProbability;

    updateWeightingProbabilities(getNumberValue(scenario.id), weightingProbability);
  }
};

export default handleScenarioWeightingProbability;
