import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AllocationValuesStore } from './types';

const useAllocationValuesStore = create<AllocationValuesStore>()(
  devtools(
    set => ({
      currentAllocationStatus: null,
      currentAllocationVersion: null,
      currentEquityValue: null,
      currentFirmTotal: null,
      currentAllocationId: null,
      setCurrentAllocationStatus: currentAllocationStatus => set(() => ({ currentAllocationStatus })),
      setCurrentAllocationVersion: currentAllocationVersion => set(() => ({ currentAllocationVersion })),
      setCurrentEquityValue: currentEquityValue => set(() => ({ currentEquityValue })),
      setCurrentFirmTotal: currentFirmTotal => set(() => ({ currentFirmTotal })),
      setCurrentAllocationId: currentAllocationId => set(() => ({ currentAllocationId })),
    }),
    {
      name: 'AllocationValuesStore',
    }
  )
);

export default useAllocationValuesStore;
