import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { useGetMeasurementDatesByFund } from 'services/hooks/funds';
import { getObjectValue } from 'utillities';
import { GetMeasurementDatesHook, UseGetMeasurementDates } from './types';
import { FundMd } from '../useFundMeasurementDateFilter/types';

const useGetMeasurementDates: UseGetMeasurementDates = params => {
  const { activeFund, setSelectedMeasurementDate, openMeasurementDatesDialog } = params;

  const { data: measurementDates, refetch } = useGetMeasurementDatesByFund({
    fund: activeFund,
    shouldQueryAutomatically: !openMeasurementDatesDialog,
  });

  const getMeasurementDates: GetMeasurementDatesHook['getMeasurementDates'] = useCallback(
    async newMeasurementDates => {
      await refetch();

      const getFundMd = (mds: FundMd[]) => {
        if (activeFund?.id) {
          return mds.find(md => md.fund === activeFund.id) ?? mds?.[0];
        }
        return mds?.[0];
      };

      if (newMeasurementDates) {
        const recentMD = getObjectValue(
          newMeasurementDates?.fund_mds && getFundMd(newMeasurementDates?.fund_mds)?.measurement_date
        );

        if (!isEmpty(recentMD)) {
          setSelectedMeasurementDate(recentMD);
        }
      }
    },
    [refetch, setSelectedMeasurementDate, activeFund]
  );

  return {
    getMeasurementDates,
    measurementDates,
  };
};

export default useGetMeasurementDates;
