import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    '&:hover': {},
  },
});

const Row = ({ row, deleteRow, children }) => {
  const classes = useStyles();

  return (
    <tr className={classes.root}>
      {children}
      <Tooltip title="Delete">
        <IconButton
          aria-label="delete"
          onClick={() => {
            deleteRow(row);
          }}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </tr>
  );
};

Row.propTypes = {
  children: PropTypes.array,
  row: PropTypes.number,
  deleteRow: PropTypes.func,
};

export default Row;
