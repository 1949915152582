import React, { useCallback, useState } from 'react';
import { Box, makeStyles, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  helperText: {
    '& .MuiFormHelperText-contained': {
      marginLeft: 'auto',
    },
  },
});

const QuestionInputText = ({
  setIsDisabled,
  setAnswer,
  question,
  answer,
  placeholder,
  disabledInputText,
  isChildQuestion = false,
}) => {
  const classes = useStyles();
  const maxLetters = 500;
  const [error, setError] = useState(false);

  const validateAnswer = useCallback(
    valueParam => {
      const areMaxCharactersExceeded = valueParam.length > maxLetters;
      setIsDisabled(areMaxCharactersExceeded);
      setError(areMaxCharactersExceeded);
    },
    [setIsDisabled]
  );

  const updateAnswerInput = inputValue => {
    if (isChildQuestion) {
      // the prop value for setAnswer is actually setChildrenAnswer
      setAnswer(prevState => ({ ...prevState, [question.id]: inputValue }));
    } else {
      setAnswer(inputValue);
    }
  };

  function handleOnChange(event) {
    const { value } = event.target;
    updateAnswerInput(value);
    validateAnswer(value);
  }

  return (
    <Box maxWidth="40rem" width="100%">
      <TextField
        id="outlined-multiline-flexible"
        multiline
        minRows={10}
        variant="outlined"
        fullWidth
        helperText={`${maxLetters} letters max`}
        className={classes.helperText}
        onChange={handleOnChange}
        error={error}
        value={answer}
        placeholder={placeholder}
        disabled={disabledInputText}
      />
    </Box>
  );
};

QuestionInputText.propTypes = {
  setIsDisabled: PropTypes.func,
  setAnswer: PropTypes.func,
  question: PropTypes.object,
  answer: PropTypes.string,
  placeholder: PropTypes.string,
  disabledInputText: PropTypes.bool,
  isChildQuestion: PropTypes.bool,
};

export default QuestionInputText;
