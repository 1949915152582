import React from 'react';
import { Box } from '@material-ui/core';
import { Lock as LockIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  circleContainer: {
    backgroundColor: theme.palette.error.light,
    marginBottom: theme.spacing(3),
    borderRadius: '50%',
    width: '80px',
    height: '80px',
  },
  deleteIcon: {
    color: '#fff',
  },
  textContainer: {
    textAlign: 'center',
    lineHeight: 2,
  },
}));

const ConfirmationUnlockFundOwnershipTable = () => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box display="flex" className={classes.circleContainer} justifyContent="center" alignItems="center">
        <LockIcon className={classes.deleteIcon} fontSize="large" />
      </Box>
      <div className={classes.textContainer}>
        <p>Are you sure you want to unlock this version?</p>
        <p>This will affect a final Allocation.</p>
      </div>
    </Box>
  );
};

export default ConfirmationUnlockFundOwnershipTable;
