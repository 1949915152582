import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { Header } from 'dashboard409a/components';
import useStyles from './styles';
import { IMainContainer } from './types';

const MainContainer: FC<IMainContainer> = props => {
  const { children, currentCompany, isLoading, navigationItems, openSidebar } = props;
  const classes = useStyles();

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.root}>
      <Header
        isLoading={isLoading}
        navigationItems={navigationItems}
        openSidebar={openSidebar}
        pageTitle={currentCompany?.name ?? ''}
      />

      <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.main}>
        {children}
      </Grid>
    </Grid>
  );
};

export default MainContainer;
