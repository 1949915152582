import React, { useCallback } from 'react';
import { MenuItem, Tooltip } from '@material-ui/core';
import { ApproachActionOptionProps } from './types';

const ApproachActionOption = <T, R>(props: ApproachActionOptionProps<T, R>) => {
  const {
    actionText,
    argumentsList,
    children,
    disabled = false,
    onClick,
    closeApproachMenu,
    tooltipMessage,
    shouldDisplayTooltip = false,
  } = props;

  const onClickHandler = useCallback(() => {
    closeApproachMenu?.();

    if (argumentsList) onClick?.(...argumentsList);
  }, [closeApproachMenu, onClick, argumentsList]);

  return (
    <Tooltip title={shouldDisplayTooltip && tooltipMessage ? tooltipMessage : ''}>
      <span>
        <MenuItem disabled={disabled} onClick={onClickHandler}>
          {actionText}
          {children}
        </MenuItem>
      </span>
    </Tooltip>
  );
};

export default ApproachActionOption;
