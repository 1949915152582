import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  logo: {
    width: 'auto',
    height: '100%',
    maxHeight: '80px',
  },
});

export default useStyles;
