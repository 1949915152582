/* eslint-disable no-param-reassign */
import { isUndefined } from 'lodash';
import { DEFAULT_CURRENCY, MILLIONS, THOUSANDS } from 'components/FeaturedSpreadsheet/constants';

const MINIMUM_PERCENTAGE = 0.001;

export const getCurrencyDivisor = currencyUnit => {
  if (currencyUnit.includes(THOUSANDS)) {
    return 1000;
  }
  if (currencyUnit.includes(MILLIONS)) {
    return 1000000;
  }
  return 1;
};

export const getPercentValue = (number, isDecimal, allowNegative) => {
  const minimumPercentage = isDecimal ? number : MINIMUM_PERCENTAGE;

  if (Number(number) === 0) {
    return number;
  }

  if (isDecimal) {
    return Math.max(number, minimumPercentage);
  }

  number /= 100;

  if (allowNegative) {
    return number;
  }

  return Math.max(number, minimumPercentage);
};

export const getCurrencyValue = ({ units, value, exchangeRate = 1 }) => {
  let amount = value;

  if (units) {
    amount = Number(value) / getCurrencyDivisor(units);
  }

  return amount * exchangeRate;
};

export const roundToN = (num, n = 2) => {
  const roundPrecision = Number(`1${'0'.repeat(n)}`);
  return Math.round((Number(num) + Number.EPSILON) * roundPrecision) / roundPrecision;
};

const formatNumbers = ({
  format,
  value,
  prefix,
  suffix,
  allowNegativeValue,
  currency,
  currencyCode,
  blankIfNull,
  staticCurrency,
}) => {
  let tmpNumber = value !== Infinity ? value : 0;
  let units = '';

  if (currency) {
    currencyCode = currency.code; // New version { code, symbol }
    units = currency.units;
  }
  if (staticCurrency) {
    currencyCode = staticCurrency.code;
  }

  let tmpCurrency = currency;
  if (staticCurrency && currency) {
    tmpCurrency = {
      ...staticCurrency,
      units: currency.units,
    };
  }
  // Do not apply any format if the value is undefined or
  // if we want to display a blank cell when the value is null
  if (!isUndefined(value) && !blankIfNull) {
    const tmpSuffix = suffix || format?.suffix || '';

    if (format) {
      switch (format.style) {
        case 'percent':
          tmpNumber = getPercentValue(tmpNumber, format.isDecimal, allowNegativeValue);
          break;
        case 'currency':
          tmpNumber = getCurrencyValue({
            units,
            ...tmpCurrency,
            value: tmpNumber,
          });
          break;
        default:
          break;
      }

      // format number
      tmpNumber = new Intl.NumberFormat('en-US', {
        style: format.style,
        currency: currencyCode || DEFAULT_CURRENCY,
        minimumFractionDigits: format.minimumFractionDigits,
        maximumFractionDigits: format.maximumFractionDigits,
      }).format(tmpNumber);
    }
    tmpNumber = [prefix, tmpNumber, tmpSuffix].join('');
  }
  return tmpNumber;
};

export default formatNumbers;
