import {
  PERFORMANCE_METRICS_SPREADSHEET_COLUMN_NAME,
  PERFORMANCE_METRICS_SPREADSHEET_COLUMN_NAME_PLURAL,
  PERFORMANCE_METRICS_SPREADSHEET_TABLE,
  PERFORMANCE_METRICS_SPREADSHEET_TITLE,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics/performanceMetrics';

export const PERFORMANCE_METRICS_SPREADSHEET_TABLE_TERMS = Object.freeze({
  columnName: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_NAME,
  pluralColumnName: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_NAME_PLURAL,
  tableName: PERFORMANCE_METRICS_SPREADSHEET_TITLE,
  tableSlug: PERFORMANCE_METRICS_SPREADSHEET_TABLE,
});
