// model names
export const FUND_OWNERSHIP_MODEL_NAME = 'fundownership';
export const FUND_OWNERSHIP_DETAIL_MODEL_NAME = 'fundownershipdetail';
export const CAPTABLE_MODEL_NAME = 'captable';
export const SECURITY_MODEL_NAME = 'security';
export const CONVERTIBLE_NOTE_MODEL_NAME = 'convertiblenote';
export const ALLOCATION_MODEL_NAME = 'allocation';
export const ALLOCATION_SCENARIO_MODEL_NAME = 'allocationscenario';
export const VALUATION_APPROACH_WEIGHT_MODEL_NAME = 'valuationapproachweight';
export const INCOME_STATEMENT_MODEL_NAME = 'incomestatement';
export const BALANCE_SHEET_MODEL_NAME = 'balancesheet';
export const KPI_DATA_MODEL_NAME = 'kpidata';
export const VALUATION_APPROACH_MODEL_NAME = 'valuationapproach';
export const GPC_COMPARISON_MODEL_NAME = 'gpccomparison';
export const MULTIPLE_PREMIUM_DISCOUNT_MODEL_NAME = 'multiplepremiumdiscount';
export const CUSTOM_DATE_PERFORMANCE_METRIC_MODEL_NAME = 'customdateperformancemetric';
export const COMPANY_AS_OF_DATE_PERFORMANCE_METRIC_MODEL_NAME = 'companyasofdateperformancemetric';
export const GPC_APPROACH_MODEL_NAME = 'valuationapproachgpc';
export const GPT_TRANSACTION_MODEL_NAME = 'gpttransaction';
export const GPT_APPROACH_MODEL_NAME = 'valuationapproachgpt';
export const EFFECTIVE_TAX_RATE_MODEL_NAME = 'effectivetaxrate';
export const EXTERNAL_VALUE_APPROACH_MODEL_NAME = 'externalvalueapproach';
export const DCF_APPROACH_MODEL_NAME = 'valuationapproachdcf';
export const DCF_PERIOD_MODEL_NAME = 'dcffinancialperiod';
export const SSV_APPROACH_MODEL_NAME = 'valuationapproachspecifiedsharevalues';
export const BACKSOLVE_APPROACH_MODEL_NAME = 'valuationapproachbacksolve';
export const PUBLIC_COMPANY_ADJUSTMENT_STATUS_MODEL_NAME = 'publiccompanyadjustmentstatus';
export const GPC_BACKSOLVE_RELATION_MODEL_NAME = 'gpcbacksolverelation';
export const FUTURE_EXIT_MODEL_NAME = 'valuationapproachfutureexit';
export const METHODOLOGY_PRESENT_SHARE_VALUE_MODEL_NAME = 'methodologypresentsharevalue';

// pages keys
export const ALLOCATIONS_PAGE = 'allocations';
export const CAPTABLE_PAGE = 'capTable';
export const FUND_OWNERSHIP_PAGE = 'fundOwnership';
export const FINANCIALS_PAGE = 'financials';
export const VALUATIONS_PAGE = 'valuations';
export const COMP_GROUPS_PAGE = 'compGroups';
