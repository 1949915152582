import { useMemo } from 'react';
import { useStore } from 'common/store';
import { useLocalStorage } from 'services/hooks';

const useLocalStorageByUser = (key, initialValue) => {
  const [{ user }] = useStore();
  const [localStorage, setLocalStorage] = useLocalStorage(key);
  const valueByUser = useMemo(
    () => (localStorage && user && user.id in localStorage ? localStorage[user.id] : initialValue),
    [localStorage, user, initialValue]
  );

  const setValue = value => {
    if (user) {
      setLocalStorage({ ...localStorage, [user.id]: value });
    }
  };

  return [valueByUser, setValue];
};

export default useLocalStorageByUser;
