import React, { useState } from 'react';
import { Button, ButtonGroup, MenuItem } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ButtonGroupIconButton, ButtonGroupTextField } from 'components/ButtonGroup';

const useStyles = makeStyles(theme => ({
  addMultipleOption: {
    padding: '2px 10px',
  },
  addMultipleOptionLabel: {
    fontWeight: 'normal',
    padding: '2px 6px',
    transition: 'background-color 0.3s, color 0.3s, transform 0.3s',
  },
  addMultipleOptionLabelActive: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: theme.palette.white,
  },
  addMultipleOptionGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1),
  },
  addMultipleOptionButton: {
    fontWeight: 'normal',
    padding: theme.spacing(0.5),
  },
  addMultipleOptionButtonLeft: {
    borderRadius: '5px 0px 0px 5px',
  },
  addMultipleOptionButtonRight: {
    borderRadius: '0px 5px 5px 0px',
  },
  addMultipleOptionButtonClicked: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.white,
    transition: 'background-color 0.3s, transform 0.3s',
  },
}));

const ONCLICKED_TIMEOUT = 300;

const AddMultipleMenuItem = ({ multipleValue, setMultipleValue, onAddMultiple }) => {
  const classes = useStyles();
  const [decreaseClicked, setDecreaseClicked] = useState(false);
  const [increaseClicked, setIncreaseClicked] = useState(false);

  const onClickChangeStyle = setState => {
    setState(true);
    setTimeout(() => {
      setState(false);
    }, ONCLICKED_TIMEOUT);
  };

  const handleDecreaseValueOnClick = () => {
    if (multipleValue > 0) {
      setMultipleValue(prevMultipleValue => prevMultipleValue - 1);
      onClickChangeStyle(setDecreaseClicked);
    }
  };

  const handleIncreaseValueOnClick = () => {
    setMultipleValue(prevMultipleValue => prevMultipleValue + 1);
    onClickChangeStyle(setIncreaseClicked);
  };

  const handleInputChange = event => {
    const newValue = parseInt(event.target.value, 10);
    if (!Number.isNaN(newValue)) {
      setMultipleValue(newValue);
    }
  };

  const handleOnAddMultipleClick = () => {
    if (multipleValue > 0) {
      onAddMultiple();
      setMultipleValue(0);
    }
  };

  return (
    <MenuItem disableRipple className={classes.addMultipleOption}>
      <Button
        disableRipple
        className={clsx(classes.addMultipleOptionLabel, multipleValue > 0 && classes.addMultipleOptionLabelActive)}
        onClick={handleOnAddMultipleClick}>
        ADD MULTIPLE
      </Button>
      <ButtonGroup className={classes.addMultipleOptionGroup}>
        <ButtonGroupIconButton
          onClick={handleDecreaseValueOnClick}
          className={clsx(
            classes.addMultipleOptionButton,
            classes.addMultipleOptionButtonLeft,
            decreaseClicked && classes.addMultipleOptionButtonClicked
          )}>
          <Remove />
        </ButtonGroupIconButton>
        <ButtonGroupTextField
          variant="standard"
          value={multipleValue}
          onChange={handleInputChange}
          InputProps={{
            disableUnderline: true,
            style: {
              padding: '0px',
              width: '40px',
              borderRadius: '0px',
              backgroundColor: 'white',
            },
            inputProps: {
              style: { textAlign: 'center' },
            },
          }}
        />
        <ButtonGroupIconButton
          onClick={handleIncreaseValueOnClick}
          className={clsx(
            classes.addMultipleOptionButton,
            classes.addMultipleOptionButtonRight,
            increaseClicked && classes.addMultipleOptionButtonClicked
          )}>
          <Add />
        </ButtonGroupIconButton>
      </ButtonGroup>
    </MenuItem>
  );
};

AddMultipleMenuItem.propTypes = {
  multipleValue: PropTypes.number,
  setMultipleValue: PropTypes.func,
  onAddMultiple: PropTypes.func,
};

export default AddMultipleMenuItem;
