export const COMPANY_NAME_ALIAS = 'company_name';
export const MD_VALUE_ALIAS = 'md_value';
export const PERCENT_CHANGE_ALIAS = 'percent_change';
export const AS_OF_VALUE_ALIAS = 'as_of_value';
export const MEDIAN_ALIAS = 'median';
export const MEAN_ALIAS = 'mean';
export const MEDIAN_TITLE = 'Median';
export const MEAN_TITLE = 'Mean';
export const MEDIAN_MEDIAN_PARSE = 'Mean/Median';
export const KEY_VALUE_PARSE = 'Key/Value';
export const PERCENTILE_PARSE = 'Percentile';
export const PERCENTILE_SELECTION_B = 'percentile_selection_b';
export const PERCENTILE_SELECTION_A = 'percentile_selection_a';
export const SPECIFIED_ADJUSTMENT_ALIAS = 'specified_adjustment';
export const SPECIFIED_ADJUSTMENT_TITLE = 'Specified Adjustment';
export const SELECT_ADJUSTMENT_TITLE = 'Select Adjustment';
export const SELECT_ADJUSTMENT_ALIAS = 'adjustment_selected';
export const APPLIED_ADJUSTMENT_ALIAS = 'applied_adjustment';
export const APPLIED_ADJUSTMENT_TITLE = 'Applied Market Adjustment';
export const MARKET_ADJUSTMENT_LEDGER_TITLE = 'Market Adjustment Ledger';
export const AS_OF_VALUE_TITLE = 'Public Comps As Of:';
export const AS_OF_DATE_ALIAS = 'as_of_date';
export const MD_DATE_ALIAS = 'md_date';
export const PERCENT_CHANGE_TITLE = 'Percent Change';
export const METRIC_ALIAS = 'metric';
export const MULTIPLE_DECIMAL_PLACES = 4;

export const compKeyMap = {
  market_cap: 'Market Cap',
  enterprise_value: 'Enterprise Value',
  ltm_revenue: 'LTM Revenue',
  ltm_ebitda: 'LTM EBITDA',
  ntm_revenue: 'NTM Revenue',
  ntm_ebitda: 'NTM EBITDA',
};
