import React, { useContext } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { SELECT_DATE } from 'common/constants/inputs';
import { LedgerContext } from 'context';
import slashedToIsoFormat from 'utillities/slashedToIsoFormat';

const PurchaseDateSelect = ({ cell, options }) => {
  const { onCellsChange } = useContext(LedgerContext);

  const handleChange = event => {
    const newValue = event.target.value.toString();
    // find option with same value and convert it to iso format date
    const option = options.find(opt => opt.value === newValue);

    const date = option ? slashedToIsoFormat(option) : '';
    if (option?.value) {
      // eslint-disable-next-line no-param-reassign
      cell.acquisition_ref = option.value;
    }
    onCellsChange([{ cell, value: date }]);
  };

  const renderFormattedDate = value => {
    try {
      // Assuming the value is in 'YYYY-MM-DD' format
      return format(parseISO(value), 'MM/dd/yyyy');
    } catch {
      return '';
    }
  };

  return (
    <Select value={cell.value ?? SELECT_DATE} onChange={handleChange} renderValue={value => renderFormattedDate(value)}>
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

PurchaseDateSelect.propTypes = {
  cell: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default PurchaseDateSelect;
