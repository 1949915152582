import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import QuestionInputText from './QuestionInputText';

const ChildrenTextQuestionInput = ({ setIsDisabled, childrenAnswers = {}, setChildrenAnswers, question }) => (
  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop="1rem">
    <QuestionInputText
      setIsDisabled={setIsDisabled}
      answer={childrenAnswers[question.id] || ''}
      setAnswer={setChildrenAnswers}
      placeholder={question.name}
      question={question}
      isOptionalLongText
      isChildQuestion
    />
  </Box>
);

ChildrenTextQuestionInput.propTypes = {
  setIsDisabled: PropTypes.func,
  setChildrenAnswers: PropTypes.func,
  question: PropTypes.object,
  childrenAnswers: PropTypes.object,
};

export default ChildrenTextQuestionInput;
