import { enqueueSnackbar } from 'notistack';
import {
  createItemErrorMessage,
  createItemSuccessMessage,
  updateItemErrorMessage,
  updateItemSuccessMessage,
} from 'dashboard409a/common/constants';
import { CurrentInformationRequest } from 'dashboard409a/pages/Information/CurrentRequestedDocumentsAndQuestions/types';
import {
  CompanyData,
  CreateRequestData,
  ModifyExistingRequestData,
  ResponseMessage,
} from 'dashboard409a/pages/Information/types';
import {
  CreatedDocumentResponse,
  CreateQuestionResponse,
  DashboardCreateDocRequestPayload,
  DashboardCreateQuestionRequestPayload,
  PayloadToUpdateRequestedDocument,
  PayloadToUpdateRequestedQuestion,
  UpdateDeleteRequestedDocumentResponse,
  UpdateDeleteRequestedQuestionResponse,
} from 'services/hooks/useRequestDocsAndQuestions/types';
import { clientWithAuth } from './base';
import { dashboardEndpoints } from './endpoints';
import {
  IFindValuationScalarOpinionResponse,
  IGetGpcCompsResponse,
  IGetValuationScalarOpinionsResponse,
} from './types';
import { ApiService, IndividualRequestedFile, type UpdateIndividualQuestion } from '../../api';

export const getScalarOpinion = (companyId: number): Promise<IGetValuationScalarOpinionsResponse[]> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .get(dashboardEndpoints.getScalarOpinion(companyId))
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });

export const findScalarOpinion = (dateId: number): Promise<IFindValuationScalarOpinionResponse> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .get(dashboardEndpoints.findScalarOpinion(dateId))
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });

export const getGpcComps = (dateId: number): Promise<IGetGpcCompsResponse> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .get(dashboardEndpoints.getGpcComps(dateId))
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });

export const getCompanyRequestedInformation = (companyId: number): Promise<CompanyData> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .get(dashboardEndpoints.getCompanyRequestedInformation(companyId))
      .then(response => {
        if (response.status === 200) {
          resolve(response.data as CompanyData);
        }
        reject(new Error(response.data || response.statusText));
      })
      .catch(error => {
        reject(new Error(error));
      });
  });

export const createInformationRequest = (companyId: number, data: CreateRequestData): Promise<ResponseMessage> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .post(dashboardEndpoints.createInformationRequest(companyId), data)
      .then(response => {
        if (response.status === 201) {
          resolve(response.data);
        }
        reject(new Error(response.data || response.statusText));
      })
      .catch(error => {
        reject(new Error(error));
      });
  });

export const getCurrentInformationRequests = (companyMeasurementDateId: number): Promise<CurrentInformationRequest[]> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .get(dashboardEndpoints.getCurrentInformationRequests(companyMeasurementDateId))
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(new Error(response.statusText));
        }
      })
      .catch(error => {
        reject(error instanceof Error ? error : new Error('An unknown error occurred'));
      });
  });

export const modifyExistingRequest = (data: ModifyExistingRequestData): Promise<ResponseMessage> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .post(dashboardEndpoints.modifyExistingRequest(), data)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }

        reject(new Error(response.data || response.statusText));
      })
      .catch(error => {
        reject(new Error(error));
      });
  });

export const createRequestedDocuments = (data: DashboardCreateDocRequestPayload): Promise<CreatedDocumentResponse> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .post(dashboardEndpoints.createRequestedDocuments(), data)
      .then(response => {
        if (response.status === 201) {
          enqueueSnackbar(createItemSuccessMessage('Document request'), { variant: 'success' });
          resolve(response.data);
        }

        reject(new Error(response.data || response.statusText));
      })
      .catch(error => {
        enqueueSnackbar(createItemErrorMessage('new document request'), { variant: 'error' });
        reject(new Error(error));
      });
  });

export const createQuestions = (data: DashboardCreateQuestionRequestPayload): Promise<CreateQuestionResponse> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .post(dashboardEndpoints.createQuestions(), data)
      .then(response => {
        if (response.status === 201) {
          enqueueSnackbar(createItemSuccessMessage('Question'), { variant: 'success' });
          resolve(response.data);
        }

        reject(new Error(response.data || response.statusText));
      })
      .catch(error => {
        enqueueSnackbar(createItemErrorMessage('new question'), { variant: 'error' });
        reject(new Error(error));
      });
  });

export const retrieveUpdateDestroyCompanyRequestedDocument = (
  requestedDocumentId: number,
  data?: PayloadToUpdateRequestedDocument
): Promise<UpdateDeleteRequestedDocumentResponse> =>
  new Promise((resolve, reject) => {
    const request = data
      ? clientWithAuth.put(dashboardEndpoints.retrieveUpdateDestroyCompanyRequestedDocument(requestedDocumentId), data)
      : clientWithAuth.delete(dashboardEndpoints.retrieveUpdateDestroyCompanyRequestedDocument(requestedDocumentId));

    request
      .then(response => {
        if (response.status === 200 || response.status === 204) {
          enqueueSnackbar(updateItemSuccessMessage('Document request'), { variant: 'success' });
          resolve(response.data);
        }

        reject(new Error(response.data || response.statusText));
      })
      .catch(error => {
        enqueueSnackbar(updateItemErrorMessage('document request'), { variant: 'error' });
        reject(new Error(error));
      });
  });

export const retrieveUpdateDestroyCompanyQuestion = (
  questionId: number,
  data?: PayloadToUpdateRequestedQuestion
): Promise<UpdateDeleteRequestedQuestionResponse> =>
  new Promise((resolve, reject) => {
    const request = data
      ? clientWithAuth.put(dashboardEndpoints.retrieveUpdateDestroyCompanyQuestion(questionId), data)
      : clientWithAuth.delete(dashboardEndpoints.retrieveUpdateDestroyCompanyQuestion(questionId));

    request
      .then(response => {
        if (response.status === 200 || response.status === 204) {
          enqueueSnackbar(updateItemSuccessMessage('Question'), { variant: 'success' });
          resolve(response.data);
        }

        reject(new Error(response.data || response.statusText));
      })
      .catch(error => {
        enqueueSnackbar(updateItemErrorMessage('question'), { variant: 'error' });
        reject(new Error(error));
      });
  });

export const updateDestroyIndividualQuestion = async (
  id: number,
  data?: UpdateIndividualQuestion
): Promise<UpdateIndividualQuestion | void> => {
  try {
    const response = await (data
      ? ApiService.apiIndividualQuestionsUpdate(id, data)
      : ApiService.apiIndividualQuestionsDelete(id));

    enqueueSnackbar(updateItemSuccessMessage('Question'), { variant: 'success' });
    return response;
  } catch (error) {
    enqueueSnackbar(updateItemErrorMessage('question'), { variant: 'error' });
    throw new Error(error instanceof Error ? error.message : 'An unknown error occurred');
  }
};
export const updateIndividualDocument = async (
  id: number,
  data?: IndividualRequestedFile
): Promise<IndividualRequestedFile | void> => {
  try {
    const response = await (data
      ? ApiService.apiDocumentsRequestedFileUpdate(id, data)
      : ApiService.apiDocumentsRequestedFileDelete(id));

    enqueueSnackbar(updateItemSuccessMessage('Document'), { variant: 'success' });
    return response;
  } catch (error) {
    enqueueSnackbar(updateItemErrorMessage('Document'), { variant: 'error' });
    throw new Error(error instanceof Error ? error.message : 'An unknown error occurred');
  }
};

export const createIndividualDocument = async (data: IndividualRequestedFile): Promise<IndividualRequestedFile> => {
  try {
    const response = await ApiService.apiDocumentsRequestedFileCreate(data);
    if (response) {
      enqueueSnackbar(createItemSuccessMessage('Document'), { variant: 'success' });
      return response;
    }
    throw new Error();
  } catch (error) {
    enqueueSnackbar(createItemErrorMessage('Document'), { variant: 'error' });
    throw new Error(error instanceof Error ? error.message : 'An unknown error occurred');
  }
};

export const createIndividualQuestion = async (data: UpdateIndividualQuestion): Promise<UpdateIndividualQuestion> => {
  try {
    const response = await ApiService.apiIndividualQuestionsCreate(data);
    if (response) {
      enqueueSnackbar(createItemSuccessMessage('Question'), { variant: 'success' });
      return response;
    }
    throw new Error();
  } catch (error) {
    enqueueSnackbar(createItemErrorMessage('Question'), { variant: 'error' });
    throw new Error(error instanceof Error ? error.message : 'An unknown error occurred');
  }
};
