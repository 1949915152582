import React from 'react';
import { Button } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const GoToCapTable = ({ capTableURL }) => {
  const history = useHistory();

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => {
        history.push(capTableURL);
      }}
      startIcon={<ArrowBackIcon />}>
      Add Security
    </Button>
  );
};

GoToCapTable.propTypes = {
  capTableURL: PropTypes.string,
};

export default GoToCapTable;
