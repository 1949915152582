// Aliases

export const VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_METHOD = 'VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_METHOD';
export const VALUATION_SUMMARY_SPREADSHEET_HEADER_TITLE = 'VALUATION_SUMMARY_SPREADSHEET_HEADER_TITLE';
export const VALUATION_SUMMARY_SPREADSHEET_LESS_CASH = 'VALUATION_SUMMARY_SPREADSHEET_LESS_CASH';
export const VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT = 'VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT';
export const VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE
  = 'VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE';
export const VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY
  = 'VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY';
export const VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_ENTERPRISE_VALUE
  = 'VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_ENTERPRISE_VALUE';
export const VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE
  = 'VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE';

// Keys

export const VALUATION_SUMMARY_SPREADSHEET_EQUITY_VALUE_COLUMN_KEY = 'equity_value';
export const VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY = 'less_cash';
export const VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY = 'plus_debt';
export const VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_ENTERPRISE_VALUE_KEY = 'weighted_enterprise_value';
export const VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE_KEY = 'weighted_equity_value';
