/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UpdateChildFirmQuestion } from './UpdateChildFirmQuestion';

export type UpdateFirmQuestion = {
  readonly id?: number;
  name: string;
  question_type?: UpdateFirmQuestion.question_type;
  options?: Array<string>;
  firm: number;
  description?: string | null;
  delete_children?: boolean;
  children_questions?: Array<UpdateChildFirmQuestion>;
};

export namespace UpdateFirmQuestion {
  export enum question_type {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }
}
