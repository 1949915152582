import React, { useContext, useState } from 'react';
import {
  Stepper as BaseStepper,
  Box,
  Button,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Step,
  StepLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { useStore } from 'common/store';
import { ConfirmationCancelDialog, Dialog } from 'components/Dialogs';
import DialogContext from 'context/DialogContext';

const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(2),
    color: '#000',
  },
  stepContent: {
    paddingBottom: theme.spacing(2),
  },
  actionButtonLabel: {
    fontSize: '0.8750em',
  },
  arrowBackIcon: {
    fontSize: '1.4em',
    marginRight: theme.spacing(1),
  },
  arrowForwardIcon: {
    fontSize: '1.4em',
    marginLeft: theme.spacing(1),
  },
}));

const DialogTitle = props => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title} variant="h6">
        {children}
      </Typography>
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Stepper = ({
  id,
  isVisible,
  title,
  stepperTitles = {},
  steps,
  onClose,
  onSubmit,
  confirmCancel,
  customFinishButtonTitle = 'Save and Finish',
}) => {
  const customId = `${id}-stepper`;
  const classes = useStyles();

  const [{ isShowLoadingProgress }] = useStore();
  const [activeStep, setActiveStep] = useState(0);
  const [hasErrors, setHasErrors] = useState(false);
  const { showDialog } = useContext(DialogContext);

  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    const success = await onSubmit();
    setHasErrors(!success);

    if (success) {
      onClose();
    }
  };

  const handleClose = () => {
    if (confirmCancel) {
      showDialog({
        wrapper: ConfirmationCancelDialog,
        actions: [
          {
            label: 'Cancel Anyways',
            variant: 'contained',
            type: 'danger',
            callback: onClose,
          },
        ],
      });
    } else {
      onClose();
    }
  };

  return (
    <Dialog
      id={customId}
      title={`${stepperTitles[activeStep]}` || title}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={isVisible}
      isLoading={isShowLoadingProgress}
      dialogProps={{
        disableBackdropClick: true,
        disableTitleClose: true,
      }}>
      <BaseStepper activeStep={activeStep} alternativeLabel>
        {steps.map(step => (
          <Step id={`step-${step.id}`} key={step.id}>
            <StepLabel>
              <Typography variant="h5" component="h5">
                {step.title}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </BaseStepper>
      <DialogContent>
        <>
          <div className={classes.stepContent}>
            {hasErrors ? (
              <Alert variant="outlined" severity="error">
                Please review the information before continuing
              </Alert>
            ) : (
              ''
            )}
          </div>
          {steps[activeStep].component}
        </>
        <DialogActions>
          <Button id={`${customId}-cancel-btn`} color="primary" onClick={handleClose} disabled={isShowLoadingProgress}>
            <span className={classes.actionButtonLabel}>Cancel</span>
          </Button>
          {!isFirstStep ? (
            <Button
              id={`${customId}-prev-btn`}
              color="primary"
              variant="outlined"
              disabled={activeStep === 0 || isShowLoadingProgress}
              onClick={handleBack}>
              <Box display="flex" alignItems="center">
                <ArrowBackIcon className={classes.arrowBackIcon} />
                <span className={classes.actionButtonLabel}>Prev</span>
              </Box>
            </Button>
          ) : (
            ''
          )}
          <Button
            id={`${customId}-continue-btn`}
            variant="contained"
            color="secondary"
            disabled={!steps[activeStep].isValid || isShowLoadingProgress}
            onClick={!isLastStep ? handleNext : handleSubmit}>
            <Box display="flex" alignItems="center">
              <span className={classes.actionButtonLabel}>{isLastStep ? customFinishButtonTitle : 'Continue'}</span>
              {!isLastStep ? <ArrowForwardIcon className={classes.arrowForwardIcon} /> : ''}
            </Box>
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

DialogTitle.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
};

Stepper.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isVisible: PropTypes.bool,
  confirmCancel: PropTypes.bool,
  title: PropTypes.string,
  stepperTitles: PropTypes.objectOf(PropTypes.string),
  steps: PropTypes.array,
  customFinishButtonTitle: PropTypes.string,
};

export default Stepper;
