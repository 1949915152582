import * as constants from './constants';

const rowConfig = ({ data, isCustomBreakpoint }) => {
  const firstColumn = data?.[0] || null;
  const setSeriesTitles = firstColumn
    ? firstColumn.series.map(item => ({
      value: item.security,
      isCustomBreakpoint,
    }))
    : [];
  return [
    {
      value: isCustomBreakpoint ? constants.CUSTOM_BREAKPOINTS : constants.CALCULATED_BREAKPOINTS,
      isTitleOrHeader: true,
    },
    {
      value: constants.BREAKPOINT_RANGE,
      titleClassName: 'subtitle double-height',
    },
    {
      value: constants.BREAKPOINT_PRICE_PER_COMMON_SHARE,
      titleClassName: 'subtitle',
    },
    ...setSeriesTitles,
    {
      value: constants.TOTAL,
      titleClassName: 'subtitle',
    },
  ];
};

export default rowConfig;
