/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'common/store';
import { LedgerTable } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import CurrencyAdjustedValueViewer from 'components/Spreadsheet/components/CurrencyAdjustedValueViewer';
import FundOwnershipContext from 'context/FundOwnershipContext';
import { AMOUNT_ALIAS, COST_BASIS_ALIAS } from 'pages/CapTable/fund-ownership/components/shares-ledger/constants';
import useExchangeRateMap from 'pages/CapTable/fund-ownership/components/shares-ledger/hooks/useExchangeRateMap';
import { shortDate } from 'utillities/datesFormats';
import colConfig from './colConfig';
import calculateOutstandingFromCells from '../../utilities/calculateRegularOutstandingFromCells';

function adjustIfDifferentCurrency(isDifferentCurrency, cellData, outstandingDate) {
  if (isDifferentCurrency) {
    return {
      ...cellData,
      valueViewer: CurrencyAdjustedValueViewer,
      relatedDate: outstandingDate,
    };
  }
  return cellData;
}

const Outstanding = ({
  outstandingShares,
  outstandingCostBasis,
  outstandingAmount,
  doFullValidation,
  setIsValid,
  updateValidationStatus,
  isDisabled,
  modeledAfterConvertibleNote,
  relatedSecurity,
  fundCurrency,
  isDifferentCurrency,
  selectedMeasurementDate,
  format,
  outstandingLoanValue,
  isLoanNote,
  purchasedCells,
  soldCells,
  outstandingValuesForDividends,
}) => {
  const [{ captableInfo }] = useStore();

  const { securities } = captableInfo;
  const security = securities.find(sec => sec.id === relatedSecurity);
  const [rows, setRows] = useState();
  const [ledgerCells, setLedgerCells] = useState();
  const { cells } = useContext(FeaturedSpreadsheetContext);
  const { measurementDate } = useContext(FundOwnershipContext);

  const { exchangeRateMap } = useExchangeRateMap({
    fundCurrency,
    format,
    shares: rows,
    selectedMeasurementDate,
    isDifferentCurrency,
    cellKeys: [COST_BASIS_ALIAS, AMOUNT_ALIAS],
  });
  // outstandingDate should be the measurementDate
  const outstandingDate = useMemo(() => (measurementDate ? shortDate(measurementDate) : ''), [measurementDate]);

  const config = useMemo(() => colConfig(modeledAfterConvertibleNote, security?.has_dividends, isLoanNote), []);

  // Parse cells
  useEffect(() => {
    if (modeledAfterConvertibleNote) {
      const amount = outstandingAmount || 0;

      // Define an object for the row data
      const rowData = {
        date: outstandingDate || '',
        amount,
      };

      // Define an object for the ledger cells data
      const ledgerCellsData = {
        date1: { value: outstandingDate || '', readOnly: true },
        amount1: adjustIfDifferentCurrency(
          isDifferentCurrency,
          { value: amount, readOnly: true, alias: AMOUNT_ALIAS },
          outstandingDate || ''
        ),
      };

      // Include loan_value if loanNote is truthy
      if (isLoanNote) {
        rowData.loan_value = outstandingLoanValue;
        ledgerCellsData.loan_value1 = adjustIfDifferentCurrency(
          isDifferentCurrency,
          { value: outstandingLoanValue, readOnly: true, alias: 'loan_value' },
          outstandingDate || ''
        );
      }
      setRows([rowData]);
      setLedgerCells(ledgerCellsData);
    } else {
      const { sharesOutstanding, costBasisOutstanding } = calculateOutstandingFromCells({ purchasedCells, soldCells });

      setRows([
        {
          date: outstandingDate || '',
          shares: sharesOutstanding || 0,
          cost_basis: costBasisOutstanding || 0,
        },
      ]);

      const cellData = {
        value: costBasisOutstanding || 0,
        readOnly: true,
        alias: COST_BASIS_ALIAS,
      };

      const costBasis1 = adjustIfDifferentCurrency(isDifferentCurrency, cellData, outstandingDate);
      const { cumulative_dividends, total_shares_outstanding } = outstandingValuesForDividends;

      setLedgerCells({
        date1: { value: outstandingDate, readOnly: true, alias: 'date' },
        shares1: { value: sharesOutstanding || 0, readOnly: true },
        cost_basis1: costBasis1,
        cumulative_dividends1: {
          value: cumulative_dividends,
          readOnly: true,
        },
        total_shares_outstanding1: {
          value: total_shares_outstanding,
          readOnly: true,
        },
      });
    }
  }, [
    purchasedCells,
    soldCells,
    outstandingAmount,
    outstandingShares,
    outstandingCostBasis,
    cells,
    security,
    measurementDate,
    outstandingDate,
    outstandingValuesForDividends,
  ]);

  return (
    <div className="data-grid-container">
      <h4>Outstanding</h4>
      <br />
      <LedgerTable
        colConfig={config}
        rows={rows}
        showAddRowButton={false}
        showDeleteColumn={false}
        disabled={isDisabled}
        tableTerms={{
          tableName: 'Outstanding',
          tableSlug: 'outstanding',
          columnName: 'Outstanding',
          pluralColumnName: 'Outstandings',
        }}
        cells={ledgerCells}
        setCells={setLedgerCells}
        data={rows}
        doFullValidation={doFullValidation}
        setIsValid={setIsValid}
        updateValidationStatus={updateValidationStatus}
        currency={format.currency}
        exchangeRateMap={exchangeRateMap}
      />
    </div>
  );
};

Outstanding.propTypes = {
  outstandingShares: PropTypes.number,
  outstandingCostBasis: PropTypes.number,
  outstandingAmount: PropTypes.number,
  isDisabled: PropTypes.bool,
  modeledAfterConvertibleNote: PropTypes.bool,
  doFullValidation: PropTypes.bool,
  setIsValid: PropTypes.func,
  updateValidationStatus: PropTypes.func,
  relatedSecurity: PropTypes.number,
  fundCurrency: PropTypes.string,
  isDifferentCurrency: PropTypes.bool,
  selectedMeasurementDate: PropTypes.object,
  format: PropTypes.object,
  outstandingLoanValue: PropTypes.number,
  isLoanNote: PropTypes.bool,
  outstandingValuesForDividends: PropTypes.shape({
    cumulative_dividends: PropTypes.number,
    total_shares_outstanding: PropTypes.number,
  }),
  purchasedCells: PropTypes.shape({}),
  soldCells: PropTypes.shape({}),
};

export default Outstanding;
