import { CONVERTIBLE_NOTES } from 'common/constants/securityTypes';

const checkIfExistConvertibleNoteInCapTable = updatedColumns => {
  const convertibleNotes = updatedColumns
    .filter(columnItem => columnItem.security_type?.toString() === CONVERTIBLE_NOTES.toString());

  return convertibleNotes.length > 0;
};

export default checkIfExistConvertibleNoteInCapTable;
