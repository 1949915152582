const template = {
  cash_flow_growth: '0',
  cash_flow_remaining: '0',
  discount_factor: '0',
  discount_periods: '0',
  ebit_additions_to_intangibles_percent_enabled: true,
  ebit_additions_to_intangibles_percent: null,
  ebit_additions_to_intangibles_total_enabled: false,
  ebit_additions_to_intangibles_total: '0',
  ebit_amortization_expenses_percent_enabled: false,
  ebit_amortization_expenses_percent: null,
  ebit_amortization_expenses_total_enabled: true,
  ebit_amortization_expenses_total: '0',
  ebit_capital_expenses_percent_enabled: true,
  ebit_capital_expenses_percent: null,
  ebit_capital_expenses_total_enabled: false,
  ebit_capital_expenses_total: '0',
  ebit_cash_free_net_working_capital_percent_enabled: true,
  ebit_cash_free_net_working_capital_percent: null,
  ebit_cash_free_net_working_capital_total_enabled: false,
  ebit_cash_free_net_working_capital_total: null,
  ebit_cash_taxes: '0',
  ebit_depreciation_expenses_percent_enabled: false,
  ebit_depreciation_expenses_percent: null,
  ebit_depreciation_expenses_total_enabled: true,
  ebit_depreciation_expenses_total: '0',
  ebit_total: null,
  ebitda_margin: '0',
  ebitda_total: null,
  increase_decrease_cash_free_net_working_capital_total: '0',
  less_capital_expenditures_additions: '0',
  net_cash_flows: '0',
  plus_deprecation_amortization_expense: '0',
  revenue_growth_enabled: false,
  revenue_growth: '0',
  revenue_total_enabled: true,
  revenue_total: null,
  title: '0',
  total_cash_flows: '0',
};

export default template;
