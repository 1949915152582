import { createStyles, makeStyles } from '@material-ui/styles';
import { HORIZONTAL_NAVIGATION_Z_INDEX } from 'components/HorizontalNavigation';
import { DESKTOP_HEADER_STYLES, FIXED_HEADER_STYLES } from 'theme';
import { ITheme } from 'theme/types';
import { textWithEllipsisStyle } from 'utillities';

const HEADER_AND_FOOTER_HEIGHT = 250;
const TABS_MIN_HEIGHT = 40;

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.white,
      flexGrow: 1,
      margin: -theme.spacing(2),
      marginTop: `calc(-${theme.spacing(2)}px - ${theme.progressBarHeight}px)`,
      width: `calc(100% + ${theme.spacing(2 * 2)}px)`,
    },
    appBar: {
      ...FIXED_HEADER_STYLES,
      zIndex: HORIZONTAL_NAVIGATION_Z_INDEX - 2,
    },
    desktopAppBar: {
      ...DESKTOP_HEADER_STYLES,
      zIndex: HORIZONTAL_NAVIGATION_Z_INDEX - 2,
    },
    tabsContainer: {
      minHeight: `${TABS_MIN_HEIGHT}px`,
    },
    tab: {
      color: theme.palette.primary.main,
      maxWidth: 'none',
      minHeight: `${TABS_MIN_HEIGHT}px`,
      minWidth: 'auto',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,

      '&.Mui-selected': {
        backgroundColor: theme.palette.white,
      },
    },
    labelContainer: {
      alignItems: 'center',
      display: 'flex',
      overflow: 'hidden',
    },
    disabledIcon: {
      color: theme.palette.warning.main,
      fontSize: theme.spacing(2),
      marginLeft: theme.spacing(0.5),
    },
    errorIcon: {
      color: theme.palette.error.main,
      fontSize: theme.spacing(2),
      marginLeft: theme.spacing(0.5),
    },
    label: {
      ...textWithEllipsisStyle,
      color: theme.palette.gray[400],
      fontSize: theme.spacing(2),
      fontWeight: 700,
      marginTop: 0,

      '&.active': {
        color: theme.palette.gray[900],
      },

      '&.hovered': {
        color: theme.palette.gray[700],
      },
    },
    iconContainer: {
      marginLeft: theme.spacing(1),
      opacity: 0,
      transition: 'opacity 0.2s ease-in-out',

      '&.active': {
        opacity: 1,
      },
    },
    childrenContainer: {
      marginTop: theme.spacing(5),
      padding: theme.spacing(2),
      paddingBottom: 0,
      width: '100%',
    },
    tabPanel: {
      display: 'none',
      minHeight: `calc(100vh - ${HEADER_AND_FOOTER_HEIGHT}px - ${TABS_MIN_HEIGHT}px)`,
      paddingBottom: theme.spacing(8),

      '&.active': {
        display: 'block',
      },
    },
  })
);

export default useStyles;
