import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { DialogFormsStore } from './types';

const useDialogFormsStore = create<DialogFormsStore>()(
  devtools(
    set => ({
      displayFundDialogForm: false,
      shouldUpdateFund: false,
      setDisplayFundDialogForm: displayFundDialogForm => set(() => ({ displayFundDialogForm })),
      setShouldUpdateFund: shouldUpdateFund => set(() => ({ shouldUpdateFund })),
    }),
    {
      name: 'DialogFormsStore',
    }
  )
);

export default useDialogFormsStore;
