import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    icon: {
      color: theme.palette.black,
      height: theme.spacing(2.5),
      top: `calc(50% - ${theme.spacing(2.5)}px / 2)`,
      transform: 'rotate(0deg)',
      width: theme.spacing(2.5),
    },
  })
);

export default useStyles;
