/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { logIn } from 'common/constants/paths';
import { useIsUserLoggedIn, useVerifyAccessToken } from 'services/hooks/auth';

const CustomRoute = props => {
  const isUserLoggedIn = useIsUserLoggedIn();
  const verifyAccesToken = useVerifyAccessToken();
  const [verified, setVerified] = useState(false);
  const accessToken = localStorage.getItem('access');

  const { layout: Layout, component: Component, isPrivate, ...rest } = props;

  useEffect(() => {
    // Log in the user if has valid access token
    if (!isUserLoggedIn && accessToken && !verified) {
      verifyAccesToken();
      setVerified(true);
    }

    return () => {
      setVerified(false);
    };
  }, []);

  const render = props => {
    // Protect private page from inathorized users
    if (!isUserLoggedIn && !accessToken && isPrivate) {
      return <Redirect to={{ pathname: logIn, state: { from: props.location } }} />;
    }

    if (Layout) {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }

    return <Component {...props} />;
  };

  return <Route {...rest} render={render} />;
};

CustomRoute.defaultProps = {
  isPrivate: false,
};

CustomRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any,
  path: PropTypes.string,
  isPrivate: PropTypes.bool,
  location: PropTypes.object,
};

export default CustomRoute;
