/* eslint-disable no-param-reassign */

const mergeRows = ({
  rows,
  cell,
  col,
  rowIndex
}) => {
  if (cell && cell.rowSpan > 1) {
    let mergedVerticalCells = 1;

    while (mergedVerticalCells < cell.rowSpan) {
      const nextCell = rowIndex + mergedVerticalCells;

      if (!cell.value) {
        rows[rowIndex][col].value = rows[nextCell][col].value;
      }
      delete rows[nextCell][col];
      mergedVerticalCells += 1;
    }
  }
};

const mergeColumns = ({
  rows,
  cell,
  col,
  rowIndex
}) => {
  if (cell && cell.colSpan > 1) {
    let mergedHorizontalCells = 1;

    while (mergedHorizontalCells < cell.colSpan) {
      const tmpCol = col + mergedHorizontalCells;

      mergeRows({
        rows,
        cell,
        col: tmpCol,
        rowIndex
      });

      delete rows[rowIndex][tmpCol];
      mergedHorizontalCells += 1;
    }
  }
};

const mergeCells = (rows = []) => {
  for (let rowIndex = 0; rowIndex < rows.length; rowIndex += 1) {
    const row = rows[rowIndex];

    for (let col = 0; col < row.length; col += 1) {
      const cell = row[col];
      mergeRows({
        rows,
        cell,
        col,
        rowIndex
      });

      mergeColumns({
        rows,
        cell,
        col,
        rowIndex
      });
    }
  }
};

export default mergeCells;
