/**
 * @name Notes Config
 * @memberof module:common/config
 * @type {Config}
 * @return {Object} Configurations of API
 */

import queryString from 'query-string';
import { API } from '../config';

export const base = `${API.BASE_DEV_HOST}/api/notes`;

export const list = `${base}/list`;
export const save = `${base}/`;

export const getNotes = (pageType: string, pageTypeKey: string, pageTypeValue: number) => {
  const query = queryString.stringify({ page_type: pageType, [pageTypeKey]: pageTypeValue });
  return `${list}?${query}`;
};
