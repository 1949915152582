import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { GO_TO_QUESTION, GO_TO_QUESTIONS, QUESTIONNAIRE } from 'common/constants/process-management';
import { useStore } from 'common/store';
import { TaskContext } from 'context';
import { useTasks } from 'services/hooks';
import theme from 'theme';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import MobileProgressList from './MobileProgressList';
import ViewAllItemsButton from './ViewAllItemsButton';

const QuestionsList = () => {
  const [progress, setProgress] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [questionsToShow, setQuestionsToShow] = useState([]);
  const { isMobile, task, questionnaireUrl } = useContext(TaskContext);
  const { paired_questionnaire } = task;
  const { validateQuestionnairesRequestToken } = useTasks();
  const [{ user }] = useStore();

  async function getQuestions() {
    try {
      if (paired_questionnaire) {
        const response = await validateQuestionnairesRequestToken(
          paired_questionnaire.questionnaire_id,
          paired_questionnaire.questionnaire_token
        );
        setQuestions(response.questions);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  function getTypographyColor(item, themeParam) {
    return hasAnswers(item) ? themeParam.palette.secondary[600] : themeParam.palette.gray[400];
  }

  function getTypographyStyle(item, themeParam) {
    const color = getTypographyColor(item, themeParam);
    return {
      fontWeight: 'bold',
      marginRight: '0.5rem',
      color,
    };
  }

  function hasAnswers(question) {
    if (question.answers) {
      return question.answers.length > 0;
    }
    return question.answered;
  }

  function getQuestionAnswerStatus(question) {
    return hasAnswers(question) ? 'Answered' : 'Not answered';
  }

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  useEffect(() => {
    // get number of answered questions
    const answered = questions.filter(item => (user ? item.answers.length > 0 : item.answered)).length;
    setProgress((answered / questions.length) * 100);
    setQuestionsToShow(questions.slice(0, isMobile ? 0 : 6));
  }, [isMobile, questions, user]);

  return (
    <Grid
      container
      style={{
        alignItems: 'center',
        borderRight: `1px solid ${theme.palette.gray[300]}`,
      }}
      spacing={2}>
      <Grid item sm={6} xs={12}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: isMobile ? '2rem' : '2.25rem',
          }}>
          {QUESTIONNAIRE}
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        {!isMobile && <CircularProgressWithLabel progress={progress} />}
        <MobileProgressList
          progress={progress}
          itemsToShow={questionsToShow}
          setItemsToShow={setQuestionsToShow}
          quantityParam={0}
          itemList={questions}
          itemCategory="Questions"
        />
      </Grid>
      <Grid item sm={12}>
        {questionsToShow.map(item => (
          <Box key={item.id} padding={'1rem 0'}>
            <Typography>{item.name}</Typography>
            <Box style={{ textTransform: 'uppercase' }} display="flex" alignItems="center" fontSize={'0.875rem'}>
              <Typography style={getTypographyStyle(item, theme)}>{getQuestionAnswerStatus(item)}</Typography>
              <Link
                to={questionnaireUrl}
                style={{
                  fontWeight: 500,
                  fontSize: '0.875rem',
                  color: `${theme.palette.primary[500]}`,
                }}>
                {GO_TO_QUESTION}
              </Link>
            </Box>
          </Box>
        ))}
      </Grid>
      <Grid
        item
        sm={12}
        style={{
          display: isMobile ? 'none' : 'block',
        }}>
        <ViewAllItemsButton
          itemsToShow={questionsToShow}
          setItemsToShow={setQuestionsToShow}
          quantityParam={6}
          itemList={questions}
          itemCategory="Questions"
        />
      </Grid>
      <Grid item sm={12}>
        <Button
          variant="contained"
          color="primary"
          style={{
            fontWeight: 600,
            fontSize: '1rem',
            marginTop: '1.5rem',
          }}
          component={Link}
          to={questionnaireUrl}>
          <Typography
            style={{
              padding: '0.5rem 1rem',
              color: `${theme.palette.white}`,
            }}>
            {GO_TO_QUESTIONS}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default QuestionsList;
