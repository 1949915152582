import {
  VT_COMMON_STOCK_VALUE_MARKETABLE_BASIS_ALIAS,
  VT_COMMON_STOCK_VALUE_NON_MARKETABLE_BASIS_ALIAS,
  VT_DEBT_ALIAS,
  VT_DISCOUNT_FOR_LACK_OF_MARKETABILITY_ALIAS,
  VT_EQUITY_ALLOCATION_RESULTS_ALIAS,
  VT_EQUITY_VALUE_ALIAS,
  VT_MARKET_VALUE_INVESTED_CAPITAL_ALIAS,
  VT_TRENDS_DATE_ALIAS,
} from './rowConfig';

export default {
  [VT_TRENDS_DATE_ALIAS]: '',
  [VT_MARKET_VALUE_INVESTED_CAPITAL_ALIAS]: '',
  [VT_DEBT_ALIAS]: '',
  [VT_EQUITY_VALUE_ALIAS]: '',
  [VT_EQUITY_ALLOCATION_RESULTS_ALIAS]: '',
  [VT_COMMON_STOCK_VALUE_MARKETABLE_BASIS_ALIAS]: '',
  [VT_DISCOUNT_FOR_LACK_OF_MARKETABILITY_ALIAS]: '',
  [VT_COMMON_STOCK_VALUE_NON_MARKETABLE_BASIS_ALIAS]: '',
};
