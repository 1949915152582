import React, { useContext, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { SELECT_DATE } from 'common/constants/inputs';
import withTooltip from 'components/WithTooltip';
import LedgerContext from 'context/LedgerContext';
import { gridShortDate } from 'utillities/datesFormats';

const DateInput = ({ cell, minDate }) => {
  const { onCellsChange } = useContext(LedgerContext);
  const [date, setDate] = useState(cell.value);

  const handleChange = date => {
    if (date) {
      setDate(gridShortDate(date));
    }
  };

  const handleAccept = date => {
    onCellsChange([{ cell, value: gridShortDate(date) }]);
  };

  const handleFocus = () => {
    if (!cell.value) {
      setDate(gridShortDate(minDate || date));
    }
  };

  const handleBlur = event => {
    handleAccept(event.target.value);
  };

  return (
    <KeyboardDatePicker
      value={date}
      format="MM/dd/yyyy"
      onChange={handleChange}
      onAccept={handleAccept}
      onFocus={handleFocus}
      onBlur={handleBlur}
      openTo={cell.value ? 'date' : 'year'}
      views={['year', 'month', 'date']}
      disabled={!!cell.readOnly}
      InputProps={{ disableUnderline: true }}
      KeyboardButtonProps={{ 'aria-label': 'change date' }}
      className="ledger-table-date-picker"
      minDate={minDate}
      {...(cell.isRequired ? { emptyLabel: SELECT_DATE } : {})}
      {...(!cell.value ? { helperText: '' } : {})}
    />
  );
};

DateInput.defaultProps = {
  defaultValue: gridShortDate(),
  minDate: undefined,
};

DateInput.propTypes = {
  cell: PropTypes.object,
  defaultValue: PropTypes.string,
  minDate: PropTypes.any,
};

export default withTooltip(DateInput);
