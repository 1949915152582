/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateDecisionsView = {
  readonly id?: number;
  firm: number;
  keep_updated?: boolean | null;
  causing_object_type?: UpdateDecisionsView.causing_object_type | null;
  causing_object_id?: number | null;
  readonly causing_object_name?: string;
  readonly resolved_by?: string;
};

export namespace UpdateDecisionsView {
  export enum causing_object_type {
    CG = 'cg',
  }
}
