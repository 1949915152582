import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import {
  CANCEL,
  OTHER,
  SELECT_WITH_WHICH_DOCUMENT_REQUEST,
  SELECTION_MODAL_DESCRIPTION,
  UPLOAD,
} from 'common/constants/process-management';
import { TaskContext } from 'context';
import theme from 'theme';

const useStyles = makeStyles({
  actionButtonsContainer: {
    padding: '2rem',
    justifyContent: 'center',
  },
  textDescription: {
    color: theme.palette.black,
    fontSize: '0.875rem',
  },
  checkbox: {
    color: theme.palette.gray[300],
  },
  checkboxLabel: {
    fontSize: 16,
    fontWeight: 600,
  },
  actionButton: {
    padding: '0.5rem 2rem',
    fontWeight: '600',
    fontSize: '1rem',
  },
});

const SelectionModal = ({
  open,
  setOpenSelectionModal,
  requestedFiles,
  filesToUpload,
  setUploadedFiles,
  uploadRequestedFiles,
  uploadToOtherRequestedFile,
}) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { task, validateToken } = useContext(TaskContext);
  const [allFilesAreSimilar, setAllFilesAreSimilar] = useState(false);
  const [requestedFileChoices, setRequestedFileChoices] = useState([]);

  const checkIfOtherRequestedFileExist = useCallback(() => {
    const otherRequestedFile = requestedFiles.find(item => item.name === OTHER);
    return otherRequestedFile;
  }, [requestedFiles]);

  const handleClose = useCallback(() => {
    setOpenSelectionModal(false);
  }, [setOpenSelectionModal]);

  const addRequestedFile = (fileToUpload, requestedFile, selectedFilesParam) => {
    const selectedFile = selectedFilesParam.find(item => item.fileToUpload.tmpId === fileToUpload.tmpId);
    if (selectedFile) {
      selectedFile.requestedFiles.push(requestedFile);
      return [...selectedFilesParam];
    }
    return [...selectedFilesParam, { fileToUpload, requestedFiles: [requestedFile] }];
  };

  const removeRequestedFile = (fileToUpload, requestedFile, selectedFilesParam) => {
    const selectedFile = selectedFilesParam.find(item => item.fileToUpload.tmpId === fileToUpload.tmpId);
    if (selectedFile) {
      const requestedFileIndex = selectedFile.requestedFiles.findIndex(item => item.id === requestedFile.id);
      if (requestedFileIndex !== -1) {
        selectedFile.requestedFiles.splice(requestedFileIndex, 1);
      }
      return [...selectedFilesParam];
    }
    return [...selectedFilesParam];
  };

  const updateSelectedFiles = useCallback((event, fileToUpload, requestedFile, selectedFilesParam) => {
    if (event.target.checked) {
      return addRequestedFile(fileToUpload, requestedFile, selectedFilesParam);
    }
    return removeRequestedFile(fileToUpload, requestedFile, selectedFilesParam);
  }, []);

  const handleCheckboxChange = useCallback(
    (event, fileToUpload, requestedFile) => {
      const newSelectedFiles = updateSelectedFiles(event, fileToUpload, requestedFile, selectedFiles);
      setSelectedFiles(newSelectedFiles);
    },
    [selectedFiles, updateSelectedFiles]
  );

  const isSimilarName = useCallback((fileToUpload, requestedFile) => {
    const list = [requestedFile.name, fileToUpload.file.name];
    const options = {
      includeScore: true,
      threshold: 0.55,
    };
    const fuse = new Fuse(list, options);
    const result = fuse.search(fileToUpload.file.name);

    return result.length > 1;
  }, []);

  const handleUpload = useCallback(() => {
    handleClose();

    if (selectedFiles.length === 0) {
      uploadToOtherRequestedFile();
    }

    selectedFiles.forEach(async file => {
      const { fileToUpload, requestedFiles: tmpRequestedFiles } = file;
      const requestedFilesIds = tmpRequestedFiles.map(item => item.id);

      await uploadRequestedFiles({
        droppedFile: fileToUpload.file,
        requestedFilesIds,
        task,
        setUploadedFiles,
      });
      validateToken();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, selectedFiles, setUploadedFiles, task, uploadRequestedFiles, uploadToOtherRequestedFile]);

  useEffect(() => {
    setAllFilesAreSimilar(
      filesToUpload.every(fileToUpload =>
        requestedFiles.some(requestedFile => isSimilarName(fileToUpload, requestedFile))
      )
    );
  }, [filesToUpload, isSimilarName, requestedFiles]);

  useEffect(() => {
    if (allFilesAreSimilar) {
      handleClose();
      const newSelectedFiles = [];
      filesToUpload.forEach(fileToUpload => {
        const tmpRequestedFiles = [];
        tmpRequestedFiles.forEach(requestedFile => {
          if (isSimilarName(fileToUpload, requestedFile)) {
            tmpRequestedFiles.push(requestedFile);
          }
        });
        newSelectedFiles.push({ fileToUpload, tmpRequestedFiles });
      });
      setSelectedFiles(newSelectedFiles);
      handleUpload();
    }
  }, [allFilesAreSimilar, filesToUpload, handleClose, handleUpload, isSimilarName]);

  useEffect(() => {
    const newSelectedFiles = [];
    filesToUpload.forEach(fileToUpload => {
      requestedFiles.forEach(requestedFile => {
        if (isSimilarName(fileToUpload, requestedFile)) {
          newSelectedFiles.push({
            fileToUpload,
            requestedFiles: [requestedFile],
          });
        }
      });
    });
    setSelectedFiles(newSelectedFiles);
  }, [filesToUpload, requestedFiles, isSimilarName]);

  useEffect(() => {
    if (!checkIfOtherRequestedFileExist()) {
      setRequestedFileChoices([...requestedFiles, { id: OTHER, name: OTHER }]);
    } else {
      setRequestedFileChoices(requestedFiles);
    }
  }, [checkIfOtherRequestedFileExist, requestedFiles]);

  useEffect(() => {
    if (task.task.is_done) {
      handleClose();
      uploadToOtherRequestedFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
      <Container style={{ marginTop: '3rem' }}>
        <DialogContentText className={classes.textDescription}>{SELECTION_MODAL_DESCRIPTION}</DialogContentText>
      </Container>
      {filesToUpload.map(file => (
        <div key={file.tmpId}>
          <DialogTitle
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {file.file.name}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.textDescription}>
              {SELECT_WITH_WHICH_DOCUMENT_REQUEST}
            </DialogContentText>
            <Grid container spacing={2}>
              {requestedFileChoices.map(item => (
                <Grid key={item.id} item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        onChange={event => handleCheckboxChange(event, file, item)}
                        checked={
                          !allFilesAreSimilar
                          && selectedFiles.some(
                            selectedFile =>
                              selectedFile.fileToUpload.tmpId === file.tmpId
                              && selectedFile.requestedFiles.some(requestedFile => requestedFile.id === item.id)
                          )
                        }
                      />
                    }
                    label={<Typography className={classes.checkboxLabel}>{item.name}</Typography>}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          {file.tmpId !== filesToUpload[filesToUpload.length - 1].tmpId && <Divider style={{ margin: '1rem 0' }} />}
        </div>
      ))}
      <DialogActions className={classes.actionButtonsContainer}>
        <Button
          size="large"
          variant="contained"
          onClick={handleUpload}
          color="primary"
          className={classes.actionButton}
          style={{ marginRight: '0.5rem' }}>
          {UPLOAD}
        </Button>
        <Button size="large" variant="outlined" onClick={handleClose} color="primary" className={classes.actionButton}>
          {CANCEL}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SelectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpenSelectionModal: PropTypes.func.isRequired,
  requestedFiles: PropTypes.array,
  filesToUpload: PropTypes.array,
  setUploadedFiles: PropTypes.func,
  uploadRequestedFiles: PropTypes.func,
  uploadToOtherRequestedFile: PropTypes.func,
};

export default SelectionModal;
