/**
 * @name Global Reducer
 * @memberof module:common/reducers
 * @type {ReduxReducer}
 * @return {Object} Reducer Specification
 */

import { setCapTableList, setCompanyInfo, setCompanyList, setFundList } from './reducer';
import { SET_CAPTABLE_LIST, SET_COMPANY_INFO, SET_COMPANY_LIST, SET_FUND_LIST } from '../../actions/companies/types';

export default {
  [SET_COMPANY_INFO]: setCompanyInfo,
  [SET_CAPTABLE_LIST]: setCapTableList,
  [SET_FUND_LIST]: setFundList,
  [SET_COMPANY_LIST]: setCompanyList,
};
