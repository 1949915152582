import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { PERFORMANCE_METRICS_TITLE } from 'common/constants/financials';
import { USD_THOUSANDS } from 'components/FeaturedSpreadsheet/constants';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { rowConfig, template } from 'pages/Financials/performance-metrics/data';
import { parser, parser as pFParser } from 'pages/Financials/performance-metrics/utilities';

const usePerformancemetricsData = ({ periods, fiscalYearData, isDisabled, incomeStatementSheet }) => {
  const [columns, setColumns] = useState();
  const [visibleColumns, setVisibleColumns] = useState();
  const [cells, setCells] = useState();

  const config = useMemo(() => rowConfig(), []);

  const customTitles = useMemo(() => {
    const newConfig = [...config];

    // Reset First Row
    newConfig[0] = {
      ...newConfig[0],
      rowSpan: 3,
      value: PERFORMANCE_METRICS_TITLE,
      className: 'spreadsheet-table-title',
    };

    return newConfig;
  }, [config]);

  const updateCells = useCallback(newCells => {
    setCells(newCells);
  }, []);

  const tableTerms = useMemo(
    () => ({
      tableName: PERFORMANCE_METRICS_TITLE,
      tableSlug: 'financials-performance-metrics',
      columnName: 'Performance Metric',
      pluralColumnName: 'Performance Metrics',
    }),
    []
  );

  useEffect(() => {
    if (columns?.length) {
      const newVisibleColumns = columns.filter(column => !column.is_deleted);
      setVisibleColumns(newVisibleColumns);
    }
  }, [columns]);

  useEffect(() => {
    if (periods) {
      const generatedColumns = [];

      periods.forEach(column => {
        generatedColumns.push({
          ...column,
          ...template,
        });
      });

      setColumns(generatedColumns);
    }
  }, [periods]);

  const applyParser = useCallback(async () => {
    try {
      const parsedCells = await parser({
        columns,
        tableData: {
          incomeStatementSheet,
        },
        rowConfig: config,
      });

      updateCells(parsedCells);
    } catch (error) {
      throw new Error(error);
    }
  }, [columns, config, incomeStatementSheet, updateCells]);

  const customParser = useCallback(params => pFParser({ ...params, fiscalYearData }), [fiscalYearData]);

  useEffect(() => {
    if (!isEmpty(incomeStatementSheet?.cells)) {
      applyParser();
    }
  }, [applyParser, incomeStatementSheet]);

  const [spreadsheet, setSpreadsheet] = useState();

  useEffect(() => {
    if (visibleColumns) {
      setSpreadsheet(
        new SpreadsheetConfig({
          columns: visibleColumns,
          parser: customParser,
          rowConfig: config,
          name: 'performanceMetrics',
          tableData: { incomeStatementSheet, columns: visibleColumns },
          setColumns,
          customTitles,
          tableTerms,
          currency: USD_THOUSANDS,
          showTotalColumn: false,
          showToolbar: true,
          showPreviousColsDivider: true,
          allowConfirmAndDeleteColumn: !isDisabled,
          hasColTitle: false,
          allowDeleteColumn: !isDisabled,
          allowCopyColumn: !isDisabled,
        })
      );
    }
  }, [config, customParser, customTitles, incomeStatementSheet, tableTerms, visibleColumns, isDisabled]);

  return {
    tableTerms,
    customTitles,
    cells,
    visibleColumns,
    config,
    updateCells,
    setColumns,
    spreadsheet,
  };
};

export default usePerformancemetricsData;
