/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { all as getCountries } from 'country-codes-list';
import { debounce, isEmpty, sortBy } from 'lodash';
import validate from 'validate.js';
import { PROCESS_MANAGEMENT_ENABLER } from 'common/constants/features';
import {
  FIRM_FIELDS_CONSTRAINTS as constraints,
  CONTACT_INFORMATION,
  FIRM_SETTINGS,
  PHONE_NUMBER,
  REQUIRES_2FA,
  SHOW_PROCESS_MANAGEMENT_COLUMN,
  WEBSITE,
} from 'common/constants/firms';
import { DEBOUNCE_DELAY } from 'common/constants/general';
import { useStore } from 'common/store';
import { WebsiteTextField } from 'components';
import FeatureEnabler from 'components/FeatureEnabler';
import ImageContainer from 'components/ImageContainer';
import { LayoutContext } from 'context';
import { ImageUploadService } from 'services';
import { FirmHk, useImageUpload, useResponse } from 'services/hooks';
import handleOptionalFieldErrors from './utilities/handleOptionalFieldErrors';
import { globalAction } from '../../common/actions';

const useStyles = makeStyles(theme => ({
  firmLogoContainer: {
    marginTop: '-2rem',
  },
  helperText: {
    color: `${theme.palette.pink} !important`,
  },
  textField: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.primary[700],
      fontSize: '1rem',
    },
  },
  checkBoxContainer: {
    display: 'block',
  },
  checkBoxLabel: {
    display: 'initial',
  },
}));

const FirmSettings = () => {
  const classes = useStyles();
  const [{ firmInfo }] = useStore();
  const imageService = new ImageUploadService();
  const { errorNotification } = useResponse();
  const { useUpdateFirmById } = FirmHk;
  const [, updateFirm] = useUpdateFirmById();
  const [globalStore, dispatch] = useStore();

  const [iconImageSrc, setIconImageSrc] = useState(null);
  const [logoImageSrc, setLogoImageSrc] = useState(null);
  const [selectedIconFile, setSelectedIconFile] = useState(null);
  const [selectedLogoFile, setSelectedLogoFile] = useState(null);
  const [croppedIconAreaPixels, setCroppedIconAreaPixels] = useState(null);
  const latestIconCrop = useRef({});
  const [croppedLogoAreaPixels, setCroppedLogoAreaPixels] = useState(null);
  const latestLogoCrop = useRef({});

  const [formState, setFormState] = useState({
    isValid: true,
    values: {
      name: '',
      street_address: '',
      country: '',
      state: '',
      zip_code: '',
      phone_number: '',
      website: '',
      icon_url: '',
      logo_url: '',
      requires_2fa: false,
      show_process_management_column: false,
    },
    errors: {},
  });

  const { updatePageActions } = useContext(LayoutContext);

  const { getCroppedImage: getCroppedIconImage } = useImageUpload();
  const { getCroppedImage: getCroppedLogoImage } = useImageUpload();

  const countries = getCountries().map(({ countryCode, countryNameEn }) => ({
    value: countryCode,
    label: countryNameEn,
  }));

  const handleCountryChange = (_event, option) => {
    setFormState(prevFormState => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        country: option.value,
      },
    }));
  };

  const handleChange = event => {
    // Do not fire on the initial focus
    if (event.type) {
      event.persist();

      const tmpValues = {
        ...formState.values,
        [event.target.name]: event.target.value,
      };

      const errors = validate(tmpValues, constraints) || {};

      // This is a workaround for optional fields because ValidateJS
      // does not allow empty strings when you include a pattern or an URL
      if (
        errors?.phone_number
        && handleOptionalFieldErrors({
          fieldName: PHONE_NUMBER,
          fieldValue: formState.values[PHONE_NUMBER],
          targetName: event.target.name,
          targetValue: event.target.value,
        })
      ) {
        delete errors.phone_number;
      }
      if (
        errors?.website
        && handleOptionalFieldErrors({
          fieldName: WEBSITE,
          fieldValue: formState.values[WEBSITE],
          targetName: event.target.name,
          targetValue: event.target.value,
        })
      ) {
        delete errors.website;
      }
      setFormState(prevFormState => ({
        ...prevFormState,
        values: tmpValues,
        errors,
        isValid: isEmpty(errors),
      }));
    }
  };

  const handleCheckboxChange = event => {
    event.persist();
    setFormState(prevFormState => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.checked,
      },
    }));
  };

  useEffect(() => {
    if (croppedIconAreaPixels) {
      latestIconCrop.current = croppedIconAreaPixels;
    }
  }, [croppedIconAreaPixels]);

  useEffect(() => {
    if (croppedLogoAreaPixels) {
      latestLogoCrop.current = croppedLogoAreaPixels;
    }
  }, [croppedLogoAreaPixels]);

  const uploadFirmImages = async files => {
    const { values: formData } = formState;
    const firmImages = {};
    // Upload the firm icon only when it has changed
    if (files[0].content) {
      const iconUpload = await imageService.uploadImage(files[0].content);
      if (iconUpload.result.ok) {
        firmImages.icon_url = iconUpload.location;
      }
    } else if (!iconImageSrc) {
      firmImages.icon_url = null;
    }

    // Upload the firm logo only when it has changed
    if (files[1].content) {
      const logoUpload = await imageService.uploadImage(files[1].content);
      if (logoUpload.result.ok) {
        firmImages.logo_url = logoUpload.location;
      }
    } else if (!logoImageSrc) {
      firmImages.logo_url = null;
    }
    updateFirm(firmInfo.id, { ...formData, ...firmImages });
  };

  const saveFirmSettings = async () => {
    // Display the progress bar and disable the Save button while the request is running
    dispatch(globalAction.showLoadingProgress(true), globalStore);

    updatePageActions({
      mainAction: {
        id: 'firm-settings-save',
        label: 'Save',
        isActive: true,
        callback: saveFirmSettings,
      },
    });

    try {
      const iconFile = await getCroppedIconImage(iconImageSrc, croppedIconAreaPixels);
      const logoFile = await getCroppedLogoImage(logoImageSrc, croppedLogoAreaPixels);
      uploadFirmImages([
        { content: iconFile, imageType: 'icon_url' },
        { content: logoFile, imageType: 'logo_url' },
      ]);
    } catch (err) {
      errorNotification(err);
    }
  };

  const onInputChange = debounce(() => {
    updatePageActions({
      mainAction: {
        id: 'firm-settings-save',
        label: 'Save',
        isActive: !formState.isValid,
        callback: saveFirmSettings,
      },
    });
  }, DEBOUNCE_DELAY);

  const countryValue = useMemo(() => {
    if (countries && formState.values.country) {
      return countries.find(c => c.value === formState.values.country);
    }

    return null;
  }, [formState.values.country, countries]);

  useEffect(() => {
    updatePageActions({
      mainAction: {
        id: 'firm-images-save',
        label: 'Save',
        isActive: !isEmpty(selectedIconFile) || !isEmpty(selectedLogoFile),
        callback: saveFirmSettings,
      },
    });

    return () => {
      updatePageActions(null);
    };
  }, [
    selectedIconFile,
    iconImageSrc,
    croppedIconAreaPixels,
    selectedLogoFile,
    logoImageSrc,
    croppedLogoAreaPixels,
    updatePageActions,
  ]);

  useEffect(() => {
    if (firmInfo) {
      const icon_url = firmInfo?.icon_url || '';
      const logo_url = firmInfo?.logo_url || '';
      setFormState(prevFormState => ({
        ...prevFormState,
        values: {
          ...prevFormState.values,
          name: firmInfo.name,
          street_address: firmInfo.street_address,
          country: firmInfo.country,
          state: firmInfo.state,
          zip_code: firmInfo.zip_code,
          phone_number: firmInfo.phone_number,
          website: firmInfo.website,
          requires_2fa: firmInfo.requires_2fa,
          show_process_management_column: firmInfo.show_process_management_column,
          logo_url,
          icon_url,
        },
      }));
      setIconImageSrc(icon_url);
      setLogoImageSrc(logo_url);
    }
  }, [firmInfo]);

  useEffect(() => {
    onInputChange();
  }, [formState.values]);

  return (
    <Grid container spacing={3}>
      <Grid item sm={6}>
        <Typography variant="h6">{FIRM_SETTINGS}</Typography>
        <br />
        <Grid container spacing={4}>
          <Grid item sm={12}>
            <TextField
              className={classes.textField}
              label="Name"
              fullWidth
              variant="outlined"
              name="name"
              onChange={handleChange}
              type="text"
              inputProps={{ 'aria-label': 'name' }}
              id="name"
              helperText={formState.errors?.name?.join(', ')}
              value={formState.values.name}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              className={classes.textField}
              label="Street Address"
              fullWidth
              variant="outlined"
              name="street_address"
              onChange={handleChange}
              type="text"
              id="street_address"
              value={formState.values.street_address || ''}
              helperText={formState.errors?.street_address?.join(', ')}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
          </Grid>
          <Grid item sm={12}>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <Autocomplete
                  id="country"
                  options={sortBy(countries, 'label')}
                  style={{ width: '100%' }}
                  autoHighlight
                  getOptionLabel={option => option.label}
                  onChange={handleCountryChange}
                  value={countryValue}
                  renderInput={params => (
                    <TextField
                      {...params}
                      className={classes.textField}
                      fullWidth
                      required
                      label="Country"
                      name="country"
                      variant="outlined"
                    />
                  )}
                  disableClearable
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  className={classes.textField}
                  label="State"
                  fullWidth
                  variant="outlined"
                  name="state"
                  onChange={handleChange}
                  type="text"
                  id="state"
                  value={formState.values.state || ''}
                  helperText={formState.errors?.state?.join(', ')}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  className={classes.textField}
                  label="Zip Code"
                  fullWidth
                  variant="outlined"
                  name="zip_code"
                  onChange={handleChange}
                  type="text"
                  id="zip_code"
                  value={formState.values.zip_code || ''}
                  helperText={formState.errors?.zip_code?.join(', ')}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <FormGroup className={classes.checkBoxContainer}>
              <FormControlLabel
                className={classes.checkBoxLabel}
                control={
                  <Checkbox
                    checked={formState.values.requires_2fa}
                    onChange={handleCheckboxChange}
                    name="requires_2fa"
                  />
                }
                label={REQUIRES_2FA}
              />
              <FeatureEnabler hideComponent enabler={PROCESS_MANAGEMENT_ENABLER}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.show_process_management_column}
                      onChange={handleCheckboxChange}
                      name="show_process_management_column"
                    />
                  }
                  label={SHOW_PROCESS_MANAGEMENT_COLUMN}
                />
              </FeatureEnabler>
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6}>
        <ImageContainer
          imageSrc={iconImageSrc}
          setImageSrc={setIconImageSrc}
          setSelectedFile={setSelectedIconFile}
          setCroppedAreaPixels={setCroppedIconAreaPixels}
          title="Firm icon"
          cropShape="rect"
        />
      </Grid>
      <Grid item sm={6}>
        <Typography variant="h6">{CONTACT_INFORMATION}</Typography>
        <br />
        <Grid container spacing={4}>
          <Grid item sm={12}>
            <TextField
              className={classes.textField}
              label="Phone Number"
              fullWidth
              variant="outlined"
              name="phone_number"
              onChange={handleChange}
              type="text"
              id="phone_number"
              value={formState.values.phone_number || ''}
              helperText={formState.errors?.phone_number?.join(', ')}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
          </Grid>
          <Grid item sm={12}>
            <WebsiteTextField
              className={classes.textField}
              label="Website"
              fullWidth
              variant="outlined"
              name="website"
              onChange={handleChange}
              type="text"
              inputProps={{ 'aria-label': 'website' }}
              id="website"
              helperText={formState.errors?.website?.join(', ')}
              value={formState.values.website || ''}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6} className={classes.firmLogoContainer}>
        <ImageContainer
          imageSrc={logoImageSrc}
          setImageSrc={setLogoImageSrc}
          setSelectedFile={setSelectedLogoFile}
          setCroppedAreaPixels={setCroppedLogoAreaPixels}
          title="Firm full logo"
          cropShape="rect"
          aspect={{ horizontal: 3.5, vertical: 1 }}
        />
      </Grid>
    </Grid>
  );
};

export default FirmSettings;
