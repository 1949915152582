/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import parser from '../config/parser';
import rowConfig from '../config/rowConfig';
import { UseComparisonCompaniesTableDataProps } from '../types';

const useComparisonCompaniesTableData = ({
  data,
  format,
  currentCompanyName,
}: UseComparisonCompaniesTableDataProps) => {
  const [columns, setColumns] = useState<any[]>();
  const [spreadsheet, setSpreadsheet] = useState<SpreadsheetConfig>();

  const config = useMemo(
    () => rowConfig({ companies: data?.current_value, currentCompanyName }),
    [data?.current_value, currentCompanyName]
  );

  useEffect(() => {
    if (data?.current_value?.length) {
      const companiesLtm = data.current_value.reduce(
        (prev, current) => ({ ...prev, [`company-${current.name}`]: current.ltm_revenue }),
        {}
      );
      const companiesLtmGrowth = data.current_value.reduce(
        (prev, current) => ({ ...prev, [`company-${current.name}`]: current.ev_divided_by_ltm_revenue }),
        {}
      );
      const companiesNtmGrowth = data.current_value.reduce(
        (prev, current) => ({ ...prev, [`company-${current.name}`]: current.ev_divided_by_ntm_revenue }),
        {}
      );
      const grossMargin = data.current_value.reduce(
        (prev, current) => ({ ...prev, [`company-${current.name}`]: current.gross_margin }),
        {}
      );
      const ebitdaMargin = data.current_value.reduce(
        (prev, current) => ({ ...prev, [`company-${current.name}`]: current.ebitda_margin }),
        {}
      );
      setColumns([companiesLtm, companiesLtmGrowth, companiesNtmGrowth, grossMargin, ebitdaMargin]);
    }
  }, [data?.current_value]);

  const tableTerms: any = useMemo(
    () => ({
      tableName: 'Comparison Companies',
      tableSlug: 'comparison-companies',
    }),
    []
  );

  useEffect(() => {
    if (columns && tableTerms) {
      const spreadsheetConfig = new SpreadsheetConfig({
        parser,
        rowConfig: config,
        name: 'ComparisonCompanies',
        columns,
        tableData: { columns },
        tableTerms,
        showTotalColumn: false,
        showToolbar: true,
        showPreviousColsDivider: true,
        hasColTitle: true,
        allowConfirmAndDeleteColumn: false,
        currencyFormatter: true,
        unitsFormatter: true,
        totalParser: undefined,
        alwaysDisplayLegend: false,
        format,
      });

      setSpreadsheet(spreadsheetConfig);
    }
  }, [columns, tableTerms]);

  return {
    spreadsheet,
  };
};

export default useComparisonCompaniesTableData;
