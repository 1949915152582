import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const FirmInfoHeader = ({ taskData }) => (
  <Box marginRight={'3rem'}>
    <Box>
      <Typography
        style={{
          fontWeight: '700',
          fontSize: '2rem',
          marginBottom: '1rem',
        }}>
        {taskData.firm.name}
      </Typography>
    </Box>
    <Box>
      <Typography fontWeight={400} fontSize={14} style={{ lineHeight: '1.188rem' }}>
        {taskData.company_name}
      </Typography>
    </Box>
  </Box>
);

FirmInfoHeader.propTypes = {
  taskData: PropTypes.object.isRequired,
};

export default FirmInfoHeader;
