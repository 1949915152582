import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { FUNDS_ADD_MEASUREMENT_DATE } from 'common/constants/funds';
import { AddMeasurementDateButtonProps } from './types';

const AddMeasurementDateButton: FC<AddMeasurementDateButtonProps> = props => {
  const { openMeasurementDateModal } = props;

  return (
    <Button color="primary" endIcon={<AddIcon />} onClick={openMeasurementDateModal} variant="outlined">
      {FUNDS_ADD_MEASUREMENT_DATE}
    </Button>
  );
};

export default AddMeasurementDateButton;
