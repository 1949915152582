/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { CropFree as CropFreeIcon } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { flowRight, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { SELECT_OPTION } from 'common/constants/inputs';
import useDataSheetDialogActions from 'common/hooks/useDataSheetDialogActions';
import { Dialog } from 'components/Dialogs';
import withRequired from 'components/WithRequired/withRequired';
import withTooltip from 'components/WithTooltip/withTooltip';

const useStyles = makeStyles(theme => ({
  valueViewer: {
    position: 'absolute',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    right: 0,
    top: 0,
  },
  valueContainer: {
    position: 'relative',
    height: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  margin: {
    marginRight: theme.spacing(2.5),
  },
  dropdownIcon: {
    paddingTop: '4px',
    paddingLeft: '5px',
    marginRight: '2px',
  },
  actionBtn: {
    position: 'absolute',
    top: '2px',
    right: 0,
  },
  dialogIcon: {
    fill: `${theme.palette.cellIconColor} !important`,
  },
}));

const GridDialogValueViewer = props => {
  const classes = useStyles();
  const customDialogRef = useRef(uuid());

  const { setDialogFlag } = useDataSheetDialogActions();

  const [open, setOpen] = React.useState(false);

  const { value, cell, options, onDoubleClick, disableBackdropClick: disableclick, isDisabled } = props;

  const openDialog = useCallback(() => {
    cell.disableEvents = true;
    setOpen(true);
  }, [cell]);

  const cellValue = useMemo(() => value || cell.value, [value, cell.value]);

  const showDialogIcon = useMemo(() => !(cell.dbType === 'boolean' && !Number(cell.value)), [cell.dbType, cell.value]);

  const disableBackdropClick = useMemo(() => (!isUndefined(disableclick) ? disableclick : true), [disableclick]);

  const handleClick = useCallback(() => {
    cell.disableEvents = false;
    onDoubleClick();
  }, [cell, onDoubleClick]);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  const selectedOption = useMemo(() => {
    if (Array.isArray(options) && cellValue?.toString()) {
      return options[cellValue];
    }

    return SELECT_OPTION;
  }, [options, cellValue]);

  useEffect(() => {
    const dialogId = customDialogRef.current;
    setDialogFlag({
      open: true,
      dialogId,
      cellId: cell.key,
      disableWhenLedgerOpen: false,
    });
    return () => {
      if (setDialogFlag) {
        setDialogFlag({ open: false, dialogId, cellId: null });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (cell.hidden) {
    return null;
  }
  return (
    <>
      <div className={classes.valueViewer}>
        <div
          id={`${cell.key}-date_viewer`}
          role="button"
          tabIndex={0}
          onClick={handleClick}
          onKeyDown={handleClick}
          className={`${classes.valueContainer} ${showDialogIcon && classes.margin} ${isDisabled && classes.margin}`}>
          <span>{selectedOption}</span>
          {!isDisabled && (
            <span className={classes.dropdownIcon}>
              <ArrowDropDownIcon />
            </span>
          )}
        </div>
      </div>
      {showDialogIcon && (
        <div>
          <IconButton
            className={classes.actionBtn}
            disabled={cell.disabled}
            onClick={openDialog}
            size="small"
            color="primary">
            <CropFreeIcon
              id={`${cell.key}-cell_icon-value`}
              size="small"
              color="secondary"
              className={classes.dialogIcon}
            />
          </IconButton>
        </div>
      )}
      {open && (
        <Dialog
          open={open}
          customDialogRef
          className="ledger-dialog"
          onClose={closeDialog}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            square: true,
          }}
          hideTitle
          maxWidth={props.cell?.dialog?.maxWidth || 'md'}
          dialogProps={{
            disableBackdropClick,
            disableEscapeKeyDown: true,
            disableTitleClose: true,
            cellKey: props.cell.key,
            disableWhenLedgerOpen: props.cell.disableWhenLedgerOpen,
          }}>
          {props.cell.dialog?.content
            && React.cloneElement(
              props.cell.dialog.content,
              {
                closeDialog,
                cell: props.cell,
                className: 'read-only--white',
              },
              null
            )}
        </Dialog>
      )}
    </>
  );
};

GridDialogValueViewer.propTypes = {
  cell: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.any,
    dbType: PropTypes.any,
    hidden: PropTypes.bool,
    dialog: PropTypes.shape({
      content: PropTypes.node,
      maxWidth: PropTypes.string,
    }),
    disableWhenLedgerOpen: PropTypes.bool,
    disableEvents: PropTypes.bool,
    disabled: PropTypes.bool,
  }).isRequired,
  value: PropTypes.any,
  options: PropTypes.any,
  onDoubleClick: PropTypes.func,
  disableBackdropClick: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

const enhance = flowRight(withTooltip, withRequired);

export default enhance(GridDialogValueViewer);
