import React from 'react';
import { Divider, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  card: {
    padding: '16px 0',
    textAlign: 'center',
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  cardBody: {
    fontFamily: theme.typography.mainFont,
    fontSize: '1.125em',
  },
  cardSkeleton: {
    width: '25%',
    height: 36,
    margin: 'auto',
  },
  cardDivider: {
    backgroundColor: theme.palette.secondary.main,
    height: 2,
    width: '20%',
    margin: `${theme.spacing()}px auto`,
  },
}));

const AllocationMethodCard = ({ title, isLoading, allocationMethods }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.card}>
      <small className={classes.cardTitle}>{title}</small>
      <Divider className={classes.cardDivider} />
      {!isLoading ? (
        <p data-testid="allocation-methods" className={classes.cardBody}>
          {allocationMethods}
        </p>
      ) : (
        <div data-testid="allocation-methods-skeleton">
          <Skeleton className={classes.cardSkeleton} variant="text" />
        </div>
      )}
    </Paper>
  );
};

AllocationMethodCard.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  allocationMethods: PropTypes.string,
};

export default AllocationMethodCard;
