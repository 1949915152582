import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import getRowConfig from './data/getRowConfig';
import parser from './utilities/parser';
import reverseParser from './utilities/reverseParser';

const createSecuritySharesTable = ({ capTable, approach, hideEnterpriseValueRow, isDisabled }) => {
  const name = getApproachTableName({ approach });
  const columns = [{}, {}, {}, {}];
  const rowConfig = getRowConfig({
    securities: capTable.securities,
    hideEnterpriseValueRow,
  });

  return new SpreadsheetConfig({
    name,
    parser,
    reverseParser,
    rowConfig,
    initialObj: approach,
    tableData: { capTable, securities: capTable.securities, isDisabled },
    columns,
    showTitlesColumn: false,
    showToolbar: true,
    allowCopyColumns: false,
  });
};

export default createSecuritySharesTable;
