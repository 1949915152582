import { useCallback } from 'react';
import uuid from 'react-uuid';
import { useStore } from 'common/store';
import rowConfig from 'pages/Financials/kpi/config/rowConfig';

const updateKpiColumnsAfterDelete = (columns, newKpiConfig) =>
  // remove item from kpi_dataset to match newKpiConfig and set correctly sort order
  columns.map(column => {
    const remainingKpis = column.kpi_dataset.filter(kpi =>
      newKpiConfig.some(newKpi => newKpi.alias === kpi.company_kpi.ref)
    );
    const newKpiDataset = remainingKpis.map((kpi, index) => ({
      ...kpi,
      company_kpi: {
        ...kpi.company_kpi,
        sort_order: index,
      },
      sort_order: index,
    }));
    return { ...column, kpi_dataset: newKpiDataset };
  });
const useKpiActions = (kpiSheet, workbook) => {
  const [{ companyInfo }] = useStore();
  const updateUI = useCallback(
    async ({ newKpiConfig, newPropsForTableData = {}, deletingRow = false }) => {
      let updatedColumns = workbook.KPI.reverseParse();
      const updatedRowConfig = rowConfig({ existingKpis: newKpiConfig, isDisabled: false });
      if (deletingRow) {
        updatedColumns = updateKpiColumnsAfterDelete(updatedColumns, newKpiConfig);
      }
      const updatedTableData = {
        ...workbook.KPI.tableData,
        ...newPropsForTableData,
        columns: updatedColumns,
        companyKPIs: newKpiConfig,
      };
      await workbook.KPI.reset({
        rowConfig: updatedRowConfig,
        columns: updatedColumns,
        tableData: updatedTableData,
      });
      await workbook.evaluateSpreadsheets([workbook.KPI]);
    },
    [workbook]
  );

  const onAddKPI = useCallback(async () => {
    const existingKpis = kpiSheet.tableData?.companyKPIs ?? [];
    const newKpiRowBase = {
      id: 0,
      name: '',
      sort_order: existingKpis.length,
      data_type: 1,
      alias: uuid(),
      company: companyInfo.id,
    };
    const updatedKpis = [...existingKpis, newKpiRowBase];

    updateUI({ newKpiConfig: updatedKpis });
  }, [kpiSheet, companyInfo, updateUI]);

  const onDeleteKPI = useCallback(
    item => {
      const existingKpis = kpiSheet.tableData?.companyKPIs ?? [];
      const newKpis = existingKpis
        .filter(kpi => kpi.alias !== item.alias)
        .map((kpi, index) => ({ ...kpi, sort_order: index }));

      const newPropsForTableData = {
        deletedKPIs: [...(kpiSheet.tableData?.deletedKPIs ?? []), item.id],
      };
      updateUI({ newKpiConfig: newKpis, newPropsForTableData, deletingRow: true });
    },
    [updateUI, kpiSheet.tableData]
  );

  const onChangeDataType = useCallback(
    (cell, newValue) => {
      const existingKpis = kpiSheet.tableData?.companyKPIs ?? [];
      const newKpis = existingKpis.map(kpi => {
        if (kpi.alias === cell.alias) {
          return { ...kpi, data_type: newValue };
        }
        return kpi;
      });

      updateUI({ newKpiConfig: newKpis });
    },
    [updateUI, kpiSheet]
  );
  return {
    onAddKPI,
    onDeleteKPI,
    onChangeDataType,
  };
};

export default useKpiActions;
