import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

const NumberFormatWhileTyping = props => {
  const { inputRef, onChange, name, ...other } = props;

  const handleOnValueChange = values => {
    onChange({
      target: {
        name,
        value: values.value,
      },
    });
  };

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handleOnValueChange}
      thousandSeparator
      valueIsNumericString
    />
  );
};

NumberFormatWhileTyping.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NumberFormatWhileTyping;
