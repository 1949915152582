import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormDialog } from 'components';
import { FinancialsForm } from 'components/Forms';

const FinancialsNameDialog = ({ open, onClose, onSave, versionName }) => {
  const [formState, setFormState] = useState();

  const tableTerms = {
    tableName: 'Financial Statement',
  };

  return (
    <FormDialog
      open={open}
      title="Edit Financial Statement name"
      editMode={false}
      onClose={onClose}
      onSave={() => onSave(formState?.values.name)}
      isValid={formState?.isValid}>
      <FinancialsForm
        tableTerms={tableTerms}
        initialValues={{ name: versionName }}
        onFormChange={state => setFormState(state)}
      />
    </FormDialog>
  );
};

FinancialsNameDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  versionName: PropTypes.string,
};

export default FinancialsNameDialog;
