import { useCallback } from 'react';
import { isNull, isUndefined } from 'lodash';
import moment from 'moment';
import {
  EBITDA_MARGIN_ALIAS,
  GROSS_MARGIN_ALIAS,
  LTM_REVENUE_GROWTH_ALIAS,
  NTM_REVENUE_GROWTH_ALIAS,
} from 'common/constants/financials';
import { NOT_APPLICABLE } from 'common/constants/general';
import { useStore } from 'common/store';
import { useGetCompsByDate } from 'services/hooks';
import { ISO_DATE_FORMAT } from 'utillities';
import { LTM_EBITDA, LTM_REVENUE, NTM_EBITDA, NTM_REVENUE } from '../constants';

const useUpdateCustomDatePerformanceMetrics = ({ gpcComparison, format, gpcAttributes }) => {
  const { firmId } = useStore();
  const [fetchGpcCompData] = useGetCompsByDate();
  const getValue = (comp, key) => (!isUndefined(comp[key]?.value) ? comp[key]?.value : comp[key]);

  const getParsedValue = useCallback(
    (comp, key) => {
      const value = getValue(comp, key);
      return value.toFixed(gpcAttributes?.customDatePerformanceMetricAttrs?.[key]?.decimal_places);
    },
    [gpcAttributes]
  );

  const getPublicCompanyId = useCallback(
    capIqId => {
      const publicCompany = gpcComparison.find(comp => comp.cap_iq_id === capIqId);
      return publicCompany?.id;
    },
    [gpcComparison]
  );

  const createPerformanceMetric = useCallback(
    (comps, formattedDate) =>
      comps.map(comp => ({
        name: comp.company_name.value,
        as_of_date: formattedDate,
        cap_iq_id: comp.cap_iq_id.value,
        ltm_revenue: getParsedValue(comp, LTM_REVENUE),
        ltm_ebitda: getParsedValue(comp, LTM_EBITDA),
        ltm_revenue_growth: getParsedValue(comp, LTM_REVENUE_GROWTH_ALIAS),
        ntm_revenue: getParsedValue(comp, NTM_REVENUE),
        ntm_ebitda: getParsedValue(comp, NTM_EBITDA),
        ntm_revenue_growth: getParsedValue(comp, NTM_REVENUE_GROWTH_ALIAS),
        gross_margin: getParsedValue(comp, GROSS_MARGIN_ALIAS),
        ebitda_margin: getParsedValue(comp, EBITDA_MARGIN_ALIAS),
      })),
    [getParsedValue]
  );

  const createGPCComparison = useCallback(
    (asOfDateComps, formattedDate) =>
      asOfDateComps?.map(comp => {
        const customDatePerformanceMetrics = {
          public_company: getPublicCompanyId(comp.cap_iq_id.value),
          name: comp.company_name || comp.name || comp.company_name.value || comp.name.value,
          as_of_date: formattedDate,
          ltm_revenue: getParsedValue(comp, LTM_REVENUE),
          ltm_ebitda: getParsedValue(comp, LTM_EBITDA),
          ltm_revenue_growth: getParsedValue(comp, LTM_REVENUE_GROWTH_ALIAS),
          ntm_revenue: getParsedValue(comp, NTM_REVENUE),
          ntm_ebitda: getParsedValue(comp, NTM_EBITDA),
          ntm_revenue_growth: getParsedValue(comp, NTM_REVENUE_GROWTH_ALIAS),
          gross_margin: getParsedValue(comp, GROSS_MARGIN_ALIAS),
          ebitda_margin: getParsedValue(comp, EBITDA_MARGIN_ALIAS),
        };

        const compIndex = gpcComparison.findIndex(comparison => comparison.cap_iq_id === comp.cap_iq_id.value);
        return { ...gpcComparison[compIndex], custom_date_performance_metrics: [customDatePerformanceMetrics] };
      }),
    [getParsedValue, getPublicCompanyId, gpcComparison]
  );

  const validateCustomDatePerformanceMetrics = useCallback(
    compsWithCustomDatePerformanceMetrics =>
      compsWithCustomDatePerformanceMetrics?.map(comp => {
        const { custom_date_performance_metrics: customDatePerformanceMetrics } = comp;
        const customCompanyPerfMetricsFirstResult = customDatePerformanceMetrics[0];

        Object.keys(customDatePerformanceMetrics[0]).forEach(key => {
          if (key === 'as_of_date') {
            return;
          }
          if (
            customCompanyPerfMetricsFirstResult[key] === NOT_APPLICABLE
            || customCompanyPerfMetricsFirstResult[key] === 0
          ) {
            customCompanyPerfMetricsFirstResult[key] = 0;
          } else if (
            typeof customCompanyPerfMetricsFirstResult[key] === 'object'
            && !isNull(customCompanyPerfMetricsFirstResult[key])
            && 'value' in customCompanyPerfMetricsFirstResult[key]
          ) {
            customCompanyPerfMetricsFirstResult[key] = customCompanyPerfMetricsFirstResult[key].value;
          } else if (
            typeof customCompanyPerfMetricsFirstResult[key] === 'string'
            && !Number.isNaN(parseFloat(customCompanyPerfMetricsFirstResult[key]))
          ) {
            customCompanyPerfMetricsFirstResult[key] = parseFloat(customCompanyPerfMetricsFirstResult[key]).toFixed(
              gpcAttributes.customDatePerformanceMetricAttrs[key]?.decimal_places || 2
            );
          } else if (
            typeof customCompanyPerfMetricsFirstResult[key] === 'number'
            && !Number.isInteger(customCompanyPerfMetricsFirstResult[key])
          ) {
            customCompanyPerfMetricsFirstResult[key] = customCompanyPerfMetricsFirstResult[key].toFixed(
              gpcAttributes.customDatePerformanceMetricAttrs[key]?.decimal_places || 2
            );
          }
        });
        return { ...comp, custom_date_performance_metrics: customDatePerformanceMetrics };
      }),
    [gpcAttributes]
  );

  const currencyCode = format?.currency.code;

  const getCompsWithCustomDatePerformanceMetrics = useCallback(
    async asOfDateParam => {
      const formattedDate = moment(asOfDateParam).format(ISO_DATE_FORMAT);
      const identifiers = gpcComparison.map(({ cap_iq_id }) => cap_iq_id);
      const asOfDateComps = await fetchGpcCompData(identifiers, formattedDate, currencyCode, firmId, true);
      const comparisonsWithCustomDatePerformanceMetrics = createGPCComparison(asOfDateComps?.results, formattedDate);
      return comparisonsWithCustomDatePerformanceMetrics;
    },
    [createGPCComparison, fetchGpcCompData, firmId, currencyCode, gpcComparison]
  );

  const getCustomPerformanceMetricsForGPCComparison = useCallback(
    async (asOfDateParam, capIqIds) => {
      const formattedDate = moment(asOfDateParam).format(ISO_DATE_FORMAT);
      const asOfDateComps = await fetchGpcCompData(capIqIds, formattedDate, currencyCode, firmId, true);
      return createPerformanceMetric(asOfDateComps.results, formattedDate);
    },
    [createPerformanceMetric, currencyCode, fetchGpcCompData, firmId]
  );

  return {
    getCompsWithCustomDatePerformanceMetrics,
    validateCustomDatePerformanceMetrics,
    getCustomPerformanceMetricsForGPCComparison,
  };
};

export default useUpdateCustomDatePerformanceMetrics;
