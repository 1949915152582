import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialogs/Dialog';

const ActionDialog = ({ open, title, subtitle, children, handleDialogClose }) => (
  <Dialog
    title={title}
    subtitle={subtitle}
    open={open}
    onClose={handleDialogClose}
    PaperProps={{ style: { width: '25vw' } }}>
    {children}
  </Dialog>
);

ActionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element.isRequired),
  handleDialogClose: PropTypes.func.isRequired,
};

export default ActionDialog;
