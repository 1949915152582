import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import CompGroupContext from 'context/CompGroupContext';
import CompGroupsDialog from 'pages/Valuations/components/CompGroups/components/CompGroupsDialog';
import { getInitials } from 'utillities';

const useStyles = makeStyles(() => ({
  compGroupLabelInitials: props => ({
    border: `solid 1px ${props.color}`,
    color: props.color,
    marginRight: '0.2em',
    fontWeight: 'bold',
    padding: '0 0.1em 0 0.1em',
    cursor: 'pointer',
  }),
  compGroupLabel: props => ({
    border: `solid 1px ${props.color}`,
    color: props.color,
    marginRight: '0.2em',
    fontWeight: 'bold',
    padding: '0.2em',
    cursor: 'pointer',
    position: 'absolute',
    top: '-90%',
    backgroundColor: '#FFFFFF',
    borderRadius: '6px',

    '&::after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: '10%',
      marginLeft: '-5px',
      borderWidth: '7px',
      borderStyle: 'solid',
      borderColor: `${props.color} transparent transparent transparent`,
    },
  }),
}));

const CompGroupLabel = ({ compGroupData, setCompanyInfoHovering }) => {
  const [openCompGroupsDialog, setOpenCompGroupsDialog] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const { saveCompGroups, deleteCompGroup, tableData, approachType } = useContext(CompGroupContext);

  const handleClick = () => {
    setOpenCompGroupsDialog(true);
    setIsHovering(false);
    if (setCompanyInfoHovering) setCompanyInfoHovering(false);
  };

  const handleOnMouseEnter = () => {
    setIsHovering(true);
    if (setCompanyInfoHovering) setCompanyInfoHovering(false);
  };

  const handleOnMouseLeave = () => {
    setIsHovering(false);
    if (setCompanyInfoHovering) setCompanyInfoHovering(true);
  };

  const classes = useStyles(compGroupData);
  const compGroupLabel = compGroupData?.id ? getInitials({ names: compGroupData.name }) : null;

  return (
    <div onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      <button type="button" onClick={handleClick} className={classes.compGroupLabelInitials}>
        <span>{compGroupLabel}</span>
      </button>

      {isHovering && (
        <div className={classes.compGroupLabel}>
          <span>{compGroupData.name}</span>
        </div>
      )}
      {openCompGroupsDialog && (
        <CompGroupsDialog
          openCompGroupsDialog
          setOpenCompGroupsDialog={setOpenCompGroupsDialog}
          compGroupsOptionSelected={compGroupData}
          saveCompGroups={saveCompGroups}
          deleteCompGroup={deleteCompGroup}
          tableData={tableData}
          approachType={approachType}
          defaultCompGroupId={compGroupData.id}
        />
      )}
    </div>
  );
};

CompGroupLabel.propTypes = {
  compGroupData: PropTypes.object,
  setCompanyInfoHovering: PropTypes.func,
};

export default CompGroupLabel;
