import { Cell } from 'common/types/scalarSpreadsheet';
import {
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/weightedShareValues';
import { getNumberValue } from 'utillities';

const { ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADER_TITLE } = SHEET_ALIASES_CONSTANTS;

const { ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADERS } = SHEET_TITLES_CONSTANTS;
const { VALUE } = ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADERS;

const getFirmTotal = (weightedShareValuesCells: Cell[]) => {
  const firmTotal = getNumberValue(
    weightedShareValuesCells
      ?.filter(
        spreadsheetCell =>
          spreadsheetCell?.alias !== ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADER_TITLE
          && spreadsheetCell.y === VALUE.columnNumber - 1
      )
      ?.reduce((accumulator, current) => accumulator + getNumberValue(current?.value as number), 0)
  );

  return firmTotal;
};

export default getFirmTotal;
