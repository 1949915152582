import { ResetCellsParams } from './types';

const resetCells = (params: ResetCellsParams) => {
  const { cells, changes } = params;

  cells.forEach(cell => {
    if (!cell) return;

    const currentCell = cell;

    currentCell.greaterThan = undefined;
    currentCell.hidden = true;
    currentCell.isRequired = false;
    currentCell.readOnly = true;

    changes.push({ cell: currentCell, value: 0 });
  });
};

export default resetCells;
