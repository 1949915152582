import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useStore } from 'common/store';

const useUsedCurrencies = () => {
  const [{ companyInfo, fundList }] = useStore();

  const [usedCurrencies, setUsedCurrencies] = useState([]);

  useEffect(() => {
    const USD = 'USD';
    const COMMON_CURRENCIES = ['AUD', 'CAD', 'CNY', 'EUR', 'GBP', 'HKD', 'INR', 'JPY'];

    const currencies = [];

    // The first 3 currencies should be what we are using in the cap table, financials, and USD
    if (companyInfo?.captable_currency) {
      currencies.push(companyInfo.captable_currency);
    }

    if (companyInfo?.financials_currency) {
      currencies.push(companyInfo.financials_currency);
    }

    if (!currencies.includes(USD)) currencies.push(USD);

    // Next, we add the currencies currently in use by the funds
    if (!isEmpty(fundList)) {
      fundList.forEach(fund => {
        if (fund.currency) {
          currencies.push(fund.currency);
        }
      });
    }

    // Finally, we append the list of common currencies and remove duplicates
    setUsedCurrencies([...new Set(currencies.concat(COMMON_CURRENCIES))]);
  }, [companyInfo, fundList]);

  return usedCurrencies;
};

export default useUsedCurrencies;
