import { VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY } from 'pages/ValuationsAllocation/common/constants/valuations';

export const MULTIPLE_DISCOUNT_ROW_NUMBER_OFFSET = 4;
export const GPT_SUMMARY = 'gpt_summary';
export const GPT_TRANSACTION = 'transaction comp';

export const STOCK_PRICE_ID = 'close_price';
export const MARKET_CAP_ID = 'market_cap';

export const LTM_REVENUE = 'ltm_revenue';

export const STOCK_PRICE = 'Stock Price as of Measurement Date';
export const MARKET_CAP_USD_MILLIONS = 'Market Cap in US ($) Millions';
export const REVENUE = 'Revenue Multiple';
export const EBITDA = 'EBITDA Multiple';
export const ENTERPRISE_VALUE_USD_MILLIONS = 'Enterprise Value in US ($) Millions';

export const ACQUIRER_NAME_ID = 'acquirer_name';
export const ACQUIRER_NAME_TITLE = 'Acquirer Name';
export const ACQUIRER_NAME_LETTER = 'A';

export const TRANSACTION_DATE_ID = 'transaction_date';
export const TRANSACTION_DATE_TITLE = 'Transaction Date';
export const TRANSACTION_DATE_LETTER = 'B';

export const ENTERPRISE_VALUE_ID = VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY;
export const ENTERPRISE_VALUE_TITLE = 'Enterprise Value';
export const ENTERPRISE_VALUE_LETTER = 'C';

export const LTM_REVENUE_ID = 'ltm_revenue';
export const LTM_REVENUE_TITLE = 'LTM Revenue';
export const LTM_REVENUE_LETTER = 'D';

export const LTM_EBITDA_ID = 'ltm_ebitda';
export const LTM_EBITDA_TITLE = 'LTM EBITDA';
export const LTM_EBITDA_LETTER = 'E';

export const NTM_REVENUE_ID = 'ntm_revenue';
export const NTM_REVENUE_TITLE = 'NTM Revenue';
export const NTM_EBITDA_ID = 'ntm_ebitda';
export const NTM_EBITDA_TITLE = 'NTM EBITDA';

export const REVENUE_MULTIPLE_ID = 'revenue_multiple';
export const REVENUE_MULTIPLE_TITLE = 'Revenue Multiple';
export const LTM_REVENUE_MULTIPLE_ENABLED = 'ltm_revenue_enabled';
export const LTM_REVENUE_MULTIPLE_LETTER = 'F';
export const NTM_REVENUE_MULTIPLE_LETTER = 'H';

export const EBITDA_MULTIPLE_ID = 'ebitda_multiple';
export const EBITDA_MULTIPLE_TITLE = 'EBITDA Multiple';
export const LTM_EBITDA_MULTIPLE_ENABLED = 'ltm_ebitda_enabled';
export const LTM_EBITDA_MULTIPLE_LETTER = 'G';
export const NTM_EBITDA_MULTIPLE_LETTER = 'I';
export const NTM_REVENUE_MULTIPLE_ID = `ntm_${REVENUE_MULTIPLE_ID}`;
export const NTM_EBITDA_MULTIPLE_ID = `ntm_${EBITDA_MULTIPLE_ID}`;

export const NTM_REVENUE_MULTIPLE_ENABLED = 'ntm_revenue_enabled';
export const NTM_EBITDA_MULTIPLE_ENABLED = 'ntm_ebitda_enabled';

export const LTM_COLUMNS = [LTM_REVENUE_LETTER, LTM_EBITDA_LETTER];
export const MULTIPLE_COLUMNS_LETTER = [
  LTM_REVENUE_MULTIPLE_LETTER,
  LTM_EBITDA_MULTIPLE_LETTER,
  NTM_REVENUE_MULTIPLE_LETTER,
  NTM_EBITDA_MULTIPLE_LETTER,
];
export const LTM_REVENUE_MULTIPLE_ID = `ltm_${REVENUE_MULTIPLE_ID}`;
export const LTM_EBITDA_MULTIPLE_ID = `ltm_${EBITDA_MULTIPLE_ID}`;
export const LTM_MULTIPLE_COLUMNS_ALIAS = [LTM_REVENUE_MULTIPLE_ID, LTM_EBITDA_MULTIPLE_ID];
export const MULTIPLE_COLUMNS_ALIAS = [
  LTM_REVENUE_MULTIPLE_ID,
  LTM_EBITDA_MULTIPLE_ID,
  NTM_REVENUE_MULTIPLE_ID,
  NTM_EBITDA_MULTIPLE_ID,
];
export const EDITABLE_COLUMNS = [
  ACQUIRER_NAME_ID,
  TRANSACTION_DATE_ID,
  ENTERPRISE_VALUE_ID,
  LTM_REVENUE_ID,
  LTM_EBITDA_ID,
];

export const FINANCIALS_COMPANY_INFO_KEYS = {
  [LTM_REVENUE_MULTIPLE_ID]: LTM_REVENUE_ID,
  [LTM_EBITDA_MULTIPLE_ID]: LTM_EBITDA_ID,
  [NTM_REVENUE_MULTIPLE_ID]: NTM_REVENUE_ID,
  [NTM_EBITDA_MULTIPLE_ID]: NTM_EBITDA_ID,
};
export const LTM_COMPANY_INFO_KEYS_DB = {
  [LTM_REVENUE_ID]: REVENUE_MULTIPLE_ID,
  [LTM_EBITDA_ID]: EBITDA_MULTIPLE_ID,
};

export const NTM_MULTIPLES_IDS = [NTM_REVENUE_MULTIPLE_ID, NTM_EBITDA_MULTIPLE_ID];
export const MULTIPLE_DISCOUNT_ALIAS = 'multiple_discount';

export const SPECIFIED = 'Specified';
export const SPECIFIED_ID = null;
export const GPC_MULTIPLE_DISCOUNT_ALIAS = 'gpc_multiple_discount';
export const GPC_NTM_EBITDA_APPROACH_ALIAS = 'ntm_ebitda_gpc_approach';
export const GPC_NTM_REVENUE_APPROACH_ALIAS = 'ntm_revenue_gpc_approach';
export const REFERENCE_FOR_NTM_EBITDA = 'ntm_ebitda_gpc_reference';
export const REFERENCE_FOR_NTM_REVENUE = 'ntm_revenue_gpc_reference';
export const NTM_GPC_APPROACH = [GPC_NTM_REVENUE_APPROACH_ALIAS, GPC_NTM_EBITDA_APPROACH_ALIAS];
export const NTM_EBITDA_COLUMN_ID = 'ntm_ebitda_multiple';
export const NTM_REVENUE_COLUMN_ID = 'ntm_revenue_multiple';
export const SPECIFIED_LABEL = 'Specified';
export const GPC_APPROACH_CELL_ALIAS = [
  GPC_MULTIPLE_DISCOUNT_ALIAS,
  GPC_NTM_EBITDA_APPROACH_ALIAS,
  GPC_NTM_REVENUE_APPROACH_ALIAS,
];
