import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GridDate as GridDateBase } from 'components/Grid';
import LedgerContext from 'context/LedgerContext';

const LedgerGridDate = ({ cell, minDate }) => {
  const { onCellsChange } = useContext(LedgerContext);

  const handleOnChange = date => {
    onCellsChange([{ cell, value: date }]);
  };

  const { value, readOnly, disabled } = cell;

  return (
    <GridDateBase
      value={value}
      readOnly={readOnly}
      disabled={disabled}
      onCellsChanged={handleOnChange}
      minDate={minDate}
    />
  );
};

LedgerGridDate.defaultProps = {
  minDate: undefined,
};

LedgerGridDate.propTypes = {
  cell: PropTypes.object,
  minDate: PropTypes.any,
};

export default LedgerGridDate;
