import { smallCurrencyFormat } from 'common/formats/formats';
import { Row } from 'common/types/scalarSpreadsheet';
import { generateColumnKey } from 'utillities';
import {
  FUTURE_VALUES_PER_SHARE_TABLE_NAME,
  PRESENT_VALUES_PER_SHARE_TABLE_NAME,
  VALUES_PER_SHARE_SPREADSHEET_COLUMN_KEY_PREFIX,
  VALUES_PER_SHARE_SPREADSHEET_HEADER,
} from './constants';
import { RowConfigParams } from '../types';

const createSecuritiesRows = (securities: any) =>
  securities.map(({ security }: any) => {
    const { name, id } = security;

    const key = generateColumnKey({
      id,
      name,
      prefix: VALUES_PER_SHARE_SPREADSHEET_COLUMN_KEY_PREFIX,
    });

    return {
      alias: key,
      format: smallCurrencyFormat,
      gridType: 'number',
      ignoreRowCopy: true,
      readOnly: true,
      value: name,
    } as Row;
  });

const rowConfig = (params: RowConfigParams) => {
  const { securities = [], isFutureValuesPerShare } = params;

  return [
    {
      alias: VALUES_PER_SHARE_SPREADSHEET_HEADER,
      gridType: 'string',
      ignoreRowCopy: true,
      largeHeader: true,
      value: isFutureValuesPerShare ? FUTURE_VALUES_PER_SHARE_TABLE_NAME : PRESENT_VALUES_PER_SHARE_TABLE_NAME,
    } as Row,
    ...createSecuritiesRows(securities),
  ];
};

export default rowConfig;
