const blankPreferred = {
  conversion_rate: '1',
  strike_price: '0',
  pik_shares: '0',
  liquidation_preference: '0',
  seniority: 0,
  is_participating: false,
  is_participation_cap: false,
  participation_cap: '0',
  has_dividends: false,
  has_multiple_investments: false,
  dividend_payment_type: 0,
  divident_rate: '0',
  has_compounding: false,
  compounding_period: '0',
  accrued_dividends: '0',
  initial_liquidation_pref: '0',
  total_preference: '0'
};

export default blankPreferred;
