import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import afterCellChanged from 'pages/Valuations/approaches/backsolveApproach/BacksolveTable/util/afterCellChanged';
import parser from 'pages/Valuations/approaches/backsolveApproach/BacksolveTable/util/parser';
import reverseParser from 'pages/Valuations/approaches/backsolveApproach/BacksolveTable/util/reverseParser';
import getRowConfig from 'pages/Valuations/approaches/backsolveApproach/BacksolveTable/util/rowconfig';
import conditions from './util/conditions';
import customValidations from './util/customValidations';
import totalParser from './util/totalParser';

const createBacksolveTableData = ({ approach, capTableList, attrs, tableName, isDisabled }) => {
  const capTableListActual = Array.isArray(capTableList) ? capTableList : [];
  const name = tableName;
  const appliedMethodologies = approach.valuations_approach_backsolve.applied_methodologies;
  const updatedAppliedMethodologies = appliedMethodologies.map(item => ({
    ...item,
    isDeleteableColumn: appliedMethodologies.length > 1,
  }));
  const columns = [[], ...updatedAppliedMethodologies];
  // eslint-disable-next-line no-param-reassign
  approach.valuations_approach_backsolve = {
    ...approach.valuations_approach_backsolve,
    applied_methodologies: updatedAppliedMethodologies,
    deleted_methodologies: [],
  };

  return new SpreadsheetConfig({
    name,
    parser,
    reverseParser,
    rowConfig: getRowConfig({ captableList: capTableListActual, approach, isDisabled }),
    tableData: { approach, isDisabled },
    columns,
    showTitlesColumn: false,
    showToolbar: true,
    fieldAttributes: attrs,
    showTotalColumn: true,
    totalParser,
    conditions,
    customValidations,
    afterCellChanged,
    allowReorderColumns: false,
    allowCopyColumn: false,
    allowDeleteOnlySpecificColumn: !isDisabled,
  });
};

export default createBacksolveTableData;
