import React, { ChangeEvent } from 'react';
import { FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

type MetricDropdownProps = {
  value: string;
  title: string;
  handleChange: (event: ChangeEvent<{ value: unknown }>) => void;
};

export const METRICS_OPTIONS = ['EV/NTM Revenue', 'EV/LTM Revenue', 'Enterprise Value', 'Gross Margin'];

export const gpcMetrics: { [key: string]: { [key: string]: string } } = {
  'EV/NTM Revenue': {
    symbol: 'x',
    currentVal: 'ntm_revenue',
    default: 'ev_divided_by_ntm_revenue',
  },
  'EV/LTM Revenue': {
    symbol: 'x',
    currentVal: 'ltm_revenue',
    default: 'ev_divided_by_ltm_revenue',
  },
  'Enterprise Value': {
    symbol: '$',
    currentVal: 'enterprise_value',
    default: 'enterprise_value',
  },
  'Gross Margin': {
    symbol: '%',
    currentVal: 'gross_margin',
    default: 'gross_margin',
  },
};

const useStyles = makeStyles(() =>
  createStyles({
    dropdownTitle: {
      marginRight: 15,
      fontSize: 14,
    },
    dropdown: {
      fontSize: 14,
      '& .MuiOutlinedInput-input': {
        padding: 14,
        paddingRight: 35,
      },
    },
  })
);

const MetricDropdown = ({ value, title = 'Metric', handleChange }: MetricDropdownProps) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="flex-start" alignItems="center" direction="row">
      <Typography className={classes.dropdownTitle}>{title}</Typography>
      <FormControl>
        <Select value={value} onChange={handleChange} displayEmpty variant="outlined" className={classes.dropdown}>
          {METRICS_OPTIONS.map((metric, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={`metric-${index}`} value={metric}>
              {metric}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default MetricDropdown;
