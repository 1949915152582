import moment from 'moment';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import reverseParser from 'pages/Valuations/approaches/discountCashFlow/utilities/reverseParser';
import { getThresholdDate } from 'pages/Valuations/util/util';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import getParser from './config/parser';
import rowConfig from './config/rowConfig';

export const getColumns = (approach, thresholdDate) => {
  const periods = approach.valuations_approach_dcf.dcf_financial_period;
  const auxCols = [];
  periods.forEach((period, index) => {
    const { discount_factor, discount_periods, ebit_total, financial_statement_period } = period;
    let date;
    if (financial_statement_period) {
      date = moment(financial_statement_period.statement_date);
    } else {
      date = moment(period.date);
    }
    const year = date.year();
    auxCols.push({
      discount_factor,
      discount_periods,
      ebit_total,
      year,
      date,
      nol_ebit_pre_tax_cut: index === 0 ? approach.valuations_approach_dcf.nol_ebit_pre_tax_cut : 0,
      nol_ebit_post_tax_cut: index === 0 ? approach.valuations_approach_dcf.nol_ebit_post_tax_cut : 0,
    });
  });
  const filteredColumns = auxCols.filter(({ date }) => moment(date).isSameOrAfter(thresholdDate, 'day')) || [];
  return filteredColumns;
};

const createDCFNOLCarryoverData = ({ approach, mainTableName, dcfProps, dcfAttributes, isDisabled }) => {
  const { companyMeasurementDate } = dcfProps;
  const thresholdDate = getThresholdDate(companyMeasurementDate);
  const name = getApproachTableName({ approach, tableSuffix: 'nol' });
  const columns = getColumns(approach, thresholdDate);

  const parser = ({ columns, rowConfig }) =>
    getParser({ columns, rowConfig, mainTableRefence: mainTableName, isDisabled });

  return new SpreadsheetConfig({
    name,
    tableData: { approach },
    rowConfig,
    columns,
    reverseParser,
    parser,
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    tableTerms: {
      tableSlug: 'dcf-nol-ledger',
    },
    fieldAttributes: dcfAttributes,
    page: 'financials',
  });
};

export default createDCFNOLCarryoverData;
