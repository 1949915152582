import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  dangerButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.pink,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  cancelButton: {
    color: theme.palette.primary.main,
  },
  gridContainer: {
    backgroundColor: theme.palette.gray[200],
    padding: theme.spacing(2),
    borderRadius: 5,
  },
  deleteIcon: {
    fontSize: '5rem',
    color: theme.palette.white,
  },
}));

const DisconnectApproachDialog = ({ open, actions, onClose }) => {
  const theme = useTheme();
  const classes = useStyles();
  const message = `
  Not keeping your comp groups up to date with Scalar's comp groups will disconnect your valuations
  from the Scalar provided comp group and you will no longer be prompted with future updates.
  You can re-add Scalar's comp group to any Valuation again in the future if desired.
  `;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
        <Grid container direction="row" className={classes.gridContainer}>
          <Grid item container justifyContent="center">
            <DeleteOutline className={`${classes.deleteIcon} message-box__inside-icon-error`} />
          </Grid>
          <Grid item>
            <p className="message-box__tagline" style={{ textAlign: 'center' }}>
              {message}
            </p>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ margin: 0, padding: theme.spacing(2) }}>
        {actions.map(action => (
          <Button
            key={action.label}
            onClick={action.callback}
            variant={action.variant}
            className={action.type === 'danger' ? classes.dangerButton : classes.cancelButton}>
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

DisconnectApproachDialog.propTypes = {
  actions: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DisconnectApproachDialog;
