import React from 'react';
import { fundOwnershipUrl, fundSummaryUrl } from 'common/config/urls';
import { Row, Rows } from 'common/types/scalarSpreadsheet';
import { ValueWithLinkViewer } from 'components';
import {
  COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
  COMPANY_SUMMARY_SPREADSHEET_TABLE,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/Portfolio/common/constants/companySummary';
import { createColumnKeyName } from 'pages/Portfolio/pages/CompanySummary/config/createColumns';
import { generateColumnKey, getArrayValue, getObjectValue, getStringValue } from 'utillities';
import { CreateSecuritiesRowsParams, RowConfigParams, RowData } from './types';

const createSecuritiesRows = (params: CreateSecuritiesRowsParams): Rows => {
  const { companyUrl, fundSlug, investments: securities } = params;

  return getArrayValue(
    securities?.map(security => {
      const securityName = getStringValue(security?.security_name);

      return {
        alias: generateColumnKey({
          id: security.id,
          name: createColumnKeyName({ name: securityName, slug: fundSlug }),
          prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
        }),
        gridType: 'string',
        readOnly: true,
        value: <ValueWithLinkViewer name={securityName} url={companyUrl} />,
      } as Row;
    })
  );
};

const rowConfig = (params: RowConfigParams): Rows => {
  const { companySlug, firmSlug, funds, queryParams } = params;

  // Get Company Fund Ownership URL
  const companyUrl = `${fundOwnershipUrl(firmSlug, companySlug)}${queryParams}`;

  // Get Funds Securities
  const fundsSecurities = getArrayValue(
    funds?.reduce((accumulator, current) => {
      const { fund_name: fundName, fund_slug: currentFundSlug, investments } = getObjectValue(current);

      const fundSlug = getStringValue(currentFundSlug);
      const fundUrl = `${fundSummaryUrl(firmSlug, fundSlug)}${queryParams}`;

      const currentFund = [
        // Fund Title
        {
          alias: SHEET_ALIASES_CONSTANTS.COMPANY_SUMMARY_SPREADSHEET_FUND_TITLE,
          className: `${COMPANY_SUMMARY_SPREADSHEET_TABLE}-fund-title-header subheader-division`,
          gridType: 'string',
          hidden: true,
          readOnly: true,
          value: <ValueWithLinkViewer name={getStringValue(fundName)} isDarkMode url={fundUrl} />,
        } as Row,
        // Fund Securities
        ...createSecuritiesRows({ companyUrl, fundSlug, investments }),
        // Fund Total
        {
          alias: SHEET_ALIASES_CONSTANTS.COMPANY_SUMMARY_SPREADSHEET_FUND_TOTAL,
          className: `${COMPANY_SUMMARY_SPREADSHEET_TABLE}-fund-total subtotal-division`,
          data: { fund: fundSlug },
          format: null,
          gridType: 'string',
          readOnly: true,
          value: SHEET_TITLES_CONSTANTS.COMPANY_SUMMARY_SPREADSHEET_FUND_TOTAL,
        } as Row<RowData>,
      ];

      return [...accumulator, ...currentFund];
    }, [] as Rows)
  );

  return [
    // Header title
    {
      alias: SHEET_ALIASES_CONSTANTS.COMPANY_SUMMARY_SPREADSHEET_HEADER_TITLE,
      className: `${COMPANY_SUMMARY_SPREADSHEET_TABLE}-header division-bottom-only`,
      gridType: 'string',
      largeHeader: true,
      readOnly: true,
      value: SHEET_TITLES_CONSTANTS.COMPANY_SUMMARY_SPREADSHEET_HEADERS[0].value,
    } as Row,
    // Fund Securities
    ...fundsSecurities,
    // Firm Total
    {
      alias: SHEET_ALIASES_CONSTANTS.COMPANY_SUMMARY_SPREADSHEET_FIRM_TOTAL,
      className: `${COMPANY_SUMMARY_SPREADSHEET_TABLE}-firm-total division`,
      format: null,
      gridType: 'string',
      readOnly: true,
      value: SHEET_TITLES_CONSTANTS.COMPANY_SUMMARY_SPREADSHEET_FIRM_TOTAL,
    } as Row,
  ];
};

export default rowConfig;
