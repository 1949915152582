import {
  COMMON_STOCK as COMMON,
  CONVERTIBLE_NOTES,
  PREFERRED_STOCK as PREFERRED,
} from 'common/constants/securityTypes';

const isModeledAfterNote = ({ securityInfo }) =>
  [COMMON, PREFERRED, CONVERTIBLE_NOTES].includes(Number(securityInfo?.security_type)) && securityInfo.is_hidden;

export default isModeledAfterNote;
