import { underScoreSpaces } from 'pages/Allocation/allocations/utilities/util';
import {
  EQUITY_ALLOCATION_KEY,
  SHEET_TITLES_CONSTANTS as EQUITY_ALLOCATION_SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import { getColumnLetter } from 'utillities/alphabet-utilities';
import { GenerateWeightedValuePerShareExpressionParams } from './types';

const {
  EQUITY_ALLOCATION_SCENARIO_WEIGHTING_PROBABILITY_ROW,
  FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER,
  getAdjustedColumnNumber,
} = EQUITY_ALLOCATION_SHEET_TITLES_CONSTANTS;

const generateWeightedValuePerShareExpression = (params: GenerateWeightedValuePerShareExpressionParams) => {
  const { allocationScenarios, isUniformCurrency, security: securityName } = params;

  const firstScenarioColumn = getAdjustedColumnNumber({
    colNumber: FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER,
    includeAllColumns: true,
    isUniformCurrency: !isUniformCurrency,
  });

  const scenariosColumnsNumbers = Array.from(
    { length: allocationScenarios.length },
    (_, index) => index + firstScenarioColumn
  );

  const scenariosColumnsLetters = scenariosColumnsNumbers.map(colNumber => getColumnLetter(colNumber));

  // Scenario Weighting Probability
  const scenarioWeightingProbabilityCells = scenariosColumnsLetters
    .map(letter => `${EQUITY_ALLOCATION_KEY}.${letter}${EQUITY_ALLOCATION_SCENARIO_WEIGHTING_PROBABILITY_ROW}`)
    .join(',');

  // Present Value Per Share
  const presentValuePerShareCells = scenariosColumnsLetters
    .map(letter => {
      const securityRef = `${underScoreSpaces(securityName)}_${letter}`;
      return `${EQUITY_ALLOCATION_KEY}.${securityRef}`;
    })
    .join(',');

  return { scenarioWeightingProbabilityCells, presentValuePerShareCells };
};

export default generateWeightedValuePerShareExpression;
