import React, { useRef, useState } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { FILE_TYPE_NOT_ALLOWED, MAX_FILE_SIZE_ALLOWED } from 'common/constants/user';
import CircleIconWithText from 'components/CircleIconWithText';
import { useResponse } from 'services/hooks';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    padding: 10,
    border: `2px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',
    borderRadius: '0.2em',
  },
  overContainer: {
    textAlign: 'center',
    padding: 10,
    border: `2px dashed ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.gray[100],
    borderRadius: '0.2em',
  },
  fileInput: {
    display: 'none',
  },
  cloudIconStyle: {
    color: theme.palette.primary.main,
    fontSize: '2.2rem',
  },
}));

const DropZone = ({
  setFileSrc,
  title,
  subTitle,
  acceptFileTypes,
  validFileTypes,
  maxSizeAllowedInMb,
  titleArray = [],
  fromDocumentFeature = false,
  fileId = null,
}) => {
  const classes = useStyles();
  const fileInputRef = useRef();
  const [isOver, setIsOver] = useState(false);
  const { errorNotification } = useResponse();

  const preventDefault = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const dragOver = event => {
    preventDefault(event);
  };

  const dragEnter = event => {
    preventDefault(event);
    setIsOver(true);
  };

  const dragLeave = event => {
    preventDefault(event);
    setIsOver(false);
  };

  const validateFile = files => {
    // files is a FileList variable. Only one file is expected.
    const file = files[0];
    const fileSize = file.size / 1024 / 1024;
    if (validFileTypes.indexOf(file.type) === -1) {
      const validExtensions = validFileTypes.map(fileType => fileType.split('/')[1]);
      if (!fromDocumentFeature) {
        errorNotification(FILE_TYPE_NOT_ALLOWED(file.name, validExtensions.join('/')));
      }
    }
    if (fileSize > maxSizeAllowedInMb) {
      errorNotification(MAX_FILE_SIZE_ALLOWED(maxSizeAllowedInMb));
      return false;
    }
    return true;
  };

  const handleFiles = files => {
    if (!fromDocumentFeature && validateFile(files)) {
      setFileSrc(files[0]);
    } else {
      setFileSrc(files, fileId);
    }
  };

  const onDrop = event => {
    setIsOver(false);
    preventDefault(event);
    const { files } = event.dataTransfer;
    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  return (
    <div
      tabIndex={0}
      role="button"
      className={isOver ? classes.overContainer : classes.container}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={onDrop}
      onClick={fileInputClicked}
      onKeyDown={fileInputClicked}>
      <CircleIconWithText title={title} subTitle={subTitle} alternative titleArray={titleArray}>
        <CloudUploadIcon className={classes.cloudIconStyle} />
      </CircleIconWithText>
      <input
        ref={fileInputRef}
        className={classes.fileInput}
        type="file"
        accept={acceptFileTypes}
        onChange={filesSelected}
      />
    </div>
  );
};

DropZone.propTypes = {
  setFileSrc: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  acceptFileTypes: PropTypes.string,
  validFileTypes: PropTypes.array,
  maxSizeAllowedInMb: PropTypes.number,
  titleArray: PropTypes.array,
  fromDocumentFeature: PropTypes.bool,
  fileId: PropTypes.number,
};

export default DropZone;
