/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, isEqual, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { getCellRef } from 'components/FeaturedSpreadsheet/utilities/utilities';
import FeaturedSpreadsheetContext from '../../context/FeaturedSpreadsheetContext';

const DRAGGING_COLUMN = 'dragging-column';
const DRAGGING_COLUMN_CONTAINER = 'dragging-column-container';
const DRAGGED_CELL = 'dragged-cell';
const LTR_LEFT = 'ltr-left';
const LTR_RIGHT = 'ltr-right';
const RTL_LEFT = 'rtl-left';
const RTL_RIGHT = 'rtl-right';

const DraggableColumns = ({ tableRef, children }) => {
  const {
    cells,
    columns,
    setColumns,
    allowReorderColumns,
    reverseParser,
    disabled,
    pageFieldAttributes,
    selectedCell,
  } = useContext(FeaturedSpreadsheetContext);

  const [draggingCell, setDraggingCell] = useState();
  const [draggingCells, setDraggingCells] = useState();
  const [draggingOverCell, setDraggingOverCell] = useState();

  const table = useMemo(() => {
    if (tableRef.current) {
      return document.querySelector('#spreadsheet-table__content-columns table');
    }
    return null;
  }, [tableRef.current]);

  const draggableCells = useMemo(() => {
    if (tableRef.current) {
      return document.querySelectorAll('#spreadsheet-table__content-columns table > tbody > tr > td:not(.legend)');
    }
    return null;
  }, [table, columns]);

  const draggableButtons = useMemo(() => {
    if (!disabled && tableRef.current) {
      return document.querySelectorAll('#spreadsheet-table__content-columns .column-rearrange');
    }
    return null;
  }, [table, disabled, columns]);

  const setCustomPreview = (e, draggingCell) => {
    const previewTable = table.cloneNode(false);

    // Clone the table without its childrens
    const newTbody = document.createElement('tbody');
    const newRow = document.createElement('tr');

    // Add some new styles to the table
    previewTable.classList.add(DRAGGING_COLUMN);
    newTbody.appendChild(newRow);
    previewTable.appendChild(newTbody);

    // Filter all the cells outside the column dragged
    const tempDraggingCells = [
      ...document.querySelectorAll(`tr.visible > td[data-column-ref="${draggingCell.dataset.columnRef}"]:not(.legend)`),
    ];
    setDraggingCells(tempDraggingCells);

    tempDraggingCells.forEach(cell => {
      // Insert cells in the new filtered table
      const newCell = cell.cloneNode(true);
      newCell.style.display = 'block';
      previewTable.querySelector('tr').appendChild(newCell);

      // Adding styles to the originall cells while draging
      cell.classList.add(DRAGGED_CELL);
    });

    // Set the custom preview
    const previewContainer = document.createElement('div');
    previewContainer.classList.add(DRAGGING_COLUMN_CONTAINER);
    previewContainer.appendChild(previewTable);

    document.body.appendChild(previewContainer);
    e.dataTransfer.setDragImage(previewTable, 0, 0);
  };

  const getDropSide = e => {
    if (!draggingOverCell) return null;
    if (draggingCell) {
      const startingPosition = draggingCell.getBoundingClientRect().left;
      const hoveredCell = draggingOverCell.getBoundingClientRect();
      const mousePosition = e.clientX;
      const cellsCenter = hoveredCell.left + hoveredCell.width / 2;

      // Dragging from left to right
      if (startingPosition < mousePosition) {
        return mousePosition < cellsCenter ? LTR_LEFT : LTR_RIGHT;
      }

      // Dragging from right to left
      if (startingPosition > mousePosition) {
        return mousePosition > cellsCenter ? RTL_RIGHT : RTL_LEFT;
      }
    }
  };

  const showDropIndicator = e => {
    const dropSide = getDropSide(e);
    const hoveredCell = e.target.closest('tr.visible > td');
    setDraggingOverCell(hoveredCell);

    if (dropSide) {
      // Get the column legend of the dragged over cell
      const hoveredCellColumnLegend = hoveredCell.dataset.columnLegend || '';

      const hoveredColumnCells = [
        ...document.querySelectorAll(`tr > td[data-column-legend="${hoveredCellColumnLegend}"]`),
      ];

      // Filter the draggable cells by this column legend
      hoveredColumnCells.forEach(cell => {
        cell.classList.remove(LTR_LEFT, LTR_RIGHT, RTL_LEFT, RTL_RIGHT);
        cell.classList.add(dropSide);
      });
    }
  };

  const hideDropIndicator = () => {
    draggableCells.forEach(cell => {
      cell.classList.remove(LTR_LEFT, LTR_RIGHT, RTL_LEFT, RTL_RIGHT);
    });
  };

  const cleanDraggingCellsStyles = () => {
    const tmpDraggingCells = Array.isArray(draggingCells) ? [...draggingCells] : [];
    tmpDraggingCells.forEach(cell => cell.classList.remove(DRAGGED_CELL));
  };

  const rearrangeColumns = e => {
    const originColumnRef = draggingCell?.dataset?.columnRef ? draggingCell.dataset.columnRef : '';
    const destinationColumnRef = draggingOverCell?.dataset.columnRef || '';
    const updatedColumns = sortBy(reverseParser(cells, columns, pageFieldAttributes), ['order']);
    const originColumnIndex = updatedColumns.findIndex(
      column => column.columnRef.toString() === originColumnRef.toString()
    );

    if (originColumnIndex === -1) return;

    const destinationColumnIndex = updatedColumns.findIndex(
      column => column.columnRef.toString() === destinationColumnRef.toString()
    );

    // Cut the origin column from the updated list
    const originColumn = { ...updatedColumns[originColumnIndex] };

    updatedColumns.splice(originColumnIndex, 1);

    // Paste the origin column in the updated list using the new position
    switch (getDropSide(e)) {
      case LTR_LEFT:
        updatedColumns.splice(destinationColumnIndex - 1, 0, originColumn);
        break;
      case RTL_RIGHT:
        updatedColumns.splice(destinationColumnIndex + 1, 0, originColumn);
        break;
      default:
        updatedColumns.splice(destinationColumnIndex, 0, originColumn);
        break;
    }

    // Change only if the order is different
    const oldOrder = columns.map(column => column.order);
    const newOrder = updatedColumns.map(column => column.order);
    const hasOrder = columns.reduce((a, b) => Number(a) + Number(b), 0) > 0;

    if (isEqual(oldOrder, newOrder) && hasOrder) {
      cleanDraggingCellsStyles();
    } else {
      // Update the order prop
      const sortedColumns = updatedColumns.map((column, columnIndex) => ({
        ...column,
        order: columnIndex,
      }));

      setColumns(sortedColumns);
    }
  };

  const draggStartHandler = event => {
    const { cellInfo } = selectedCell;
    const tempDraggingCell = getCellRef(tableRef, cellInfo.key);
    setDraggingCell(tempDraggingCell);
    setCustomPreview(event, tempDraggingCell);
  };

  const draggOverHandler = e => {
    e.preventDefault();
    hideDropIndicator();
    showDropIndicator(e);
  };

  const draggLeaveHandler = () => {
    hideDropIndicator();
  };

  const draggEndHandler = e => {
    hideDropIndicator();
    document.body.removeChild(document.querySelector('.dragging-column-container'));
    if (draggingOverCell) {
      rearrangeColumns(e);
    }
  };

  useEffect(() => {
    if (columns.length && draggingCells) {
      cleanDraggingCellsStyles();
    }
  }, [columns]);

  const addEvents = () => {
    draggableButtons.forEach(button => {
      button.setAttribute('draggable', 'true');
      button.addEventListener('dragstart', draggStartHandler);
      button.addEventListener('dragover', e => {
        e.preventDefault();
        const hoveredCell = e.target.closest('tr.visible > td');
        setDraggingOverCell(hoveredCell);
      });
      button.addEventListener('dragend', draggEndHandler);
    });

    draggableCells.forEach(cell => {
      cell.setAttribute('draggable', 'true');
      cell.addEventListener('dragstart', e => e.preventDefault());
      cell.addEventListener('dragover', draggOverHandler);
      cell.addEventListener('dragleave', draggLeaveHandler);
    });
  };

  const removeEvents = () => {
    draggableButtons.forEach(button => {
      button.setAttribute('draggable', 'false');
      button.removeEventListener('dragstart', draggStartHandler);
      button.removeEventListener('dragend', draggEndHandler);
    });

    draggableCells.forEach(cell => {
      cell.setAttribute('draggable', 'false');
      cell.removeEventListener('dragover', draggOverHandler);
      cell.removeEventListener('dragleave', draggLeaveHandler);
    });
  };

  useEffect(() => {
    if (selectedCell && allowReorderColumns && !disabled && !isEmpty(draggableCells && draggableButtons)) {
      addEvents();
    }
    return () => !isEmpty(draggableButtons) && removeEvents();
  }, [columns, draggableCells, draggingCell, draggingOverCell, disabled, table, draggableButtons, selectedCell]);

  return <>{children}</>;
};

DraggableColumns.propTypes = {
  tableRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  children: PropTypes.element.isRequired,
};

export default DraggableColumns;
