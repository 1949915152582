import React from 'react';
import { Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { ERROR_403 } from 'common/config/api';
import FeatureEnabler from 'components/FeatureEnabler';
import AddFirmAction from './AddFirmAction';
import ContactOrInquireLicensing from './ContactAdminOrInquireLicensing';
import FirmOption from './FirmOption';
import LogoutButton from './LogoutBtn';

const FirmList = ({ firmList, addFirmHandler, searchState, user }) => {
  if (isEmpty(firmList) && user && user.is_superuser) {
    return <AddFirmAction onClickHandler={addFirmHandler} />;
  }

  if (!firmList || firmList === ERROR_403 || firmList.length === 0) {
    return (
      <FeatureEnabler enabler="createFirm" alternativeComponent={ContactOrInquireLicensing}>
        <>
          <AddFirmAction onClickHandler={addFirmHandler} />
          <LogoutButton />
        </>
      </FeatureEnabler>
    );
  }

  if (firmList !== ERROR_403) {
    if (!searchState) {
      // if Search is empty return the full list;
      return firmList.map(firm => <FirmOption key={firm.id} {...firm} />);
    }

    const filteredList = firmList.filter(firm => firm.name.toLowerCase().includes(searchState.toLowerCase()));

    if (filteredList.length === 0) return <Typography variant="h4">FIRM NOT FOUND</Typography>;
    return filteredList.map(firm => <FirmOption key={firm.id} {...firm} />);
  }

  return null;
};

FirmList.propTypes = {
  firmList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  addFirmHandler: PropTypes.func,
  searchState: PropTypes.string,
  user: PropTypes.shape({
    is_superuser: PropTypes.bool,
  }),
};

export default FirmList;
