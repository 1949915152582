import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { isEmpty, isObject } from 'lodash';
import { memoizedRefreshToken } from '../refreshToken';
import { IInterceptorConfigRequest } from '../types';

const API_DEFAULT_MESSAGE_REQUEST = 'The request is invalid';

const getAccessToken = () => localStorage.getItem('access') ?? '';

const handleError = (serverError: unknown) => {
  if (isObject(serverError)) {
    Object.entries(serverError).forEach(([, value]) => {
      const errorMessage = isEmpty(value) ? API_DEFAULT_MESSAGE_REQUEST : value;
      // eslint-disable-next-line no-console
      console.error(`${errorMessage}`);
    });
  }
};

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const token = `Bearer ${getAccessToken()}`;

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token;
  }

  return config;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  let config: IInterceptorConfigRequest | undefined = error?.config;

  if (error?.response?.status === 401 && !config?.sent) {
    config = {
      ...config,
      sent: true,
    };

    const result = await memoizedRefreshToken();

    if (result?.access) {
      const token = `bearer ${result.access}`;

      config.headers = {
        ...config.headers,
        authorization: token,
      };
    }

    return axios(config);
  }

  handleError(error?.response?.data);

  return Promise.reject(error);
};

const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, undefined);
  axiosInstance.interceptors.response.use(undefined, onResponseError);

  return axiosInstance;
};

export default setupInterceptorsTo;
