import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { PICK_ANSWER_TYPE, QUESTION_TYPE_CHOICES } from 'common/constants/firms';
import { useTasks } from 'services/hooks';
import AddSubQuestionButton from './AddSubQuestionButton';
import AnswerTypeChip from './AnswerTypeChip';
import ModalItemBox from './ModalItemBox';
import OptionAutocomplete from './OptionAutocomplete';

const QuestionContent = ({ formValues, setFormValues, setIsMaxCharactersError, dropdownTypeValue, isEdit }) => {
  const subQuestion = formValues.children_questions[0] || { name: '', description: '' };
  const isMaxOptions = formValues.options.length >= 5;
  const { questionOptions } = useTasks();

  const handleInputSubQuestionChange = (index, e) => {
    const newSubQuestions = [...formValues.children_questions];
    newSubQuestions[index][e.target.name] = e.target.value;

    setFormValues({
      ...formValues,
      children_questions: newSubQuestions,
    });
  };

  const handleAnswerTypeClick = value => {
    setFormValues({
      ...formValues,
      question_type: value,
    });
  };

  return (
    <>
      <Box mb={4}>
        <Typography variant="body1" gutterBottom style={{ fontWeight: 700 }}>
          {PICK_ANSWER_TYPE}
        </Typography>
        {QUESTION_TYPE_CHOICES.map(type => (
          <AnswerTypeChip
            key={type.value}
            type={type}
            value={formValues.question_type}
            handleAnswerTypeClick={handleAnswerTypeClick}
          />
        ))}
      </Box>

      {/* Options */}
      {formValues.question_type === dropdownTypeValue && (
        <Box mb={4}>
          <Divider style={{ marginBottom: 16 }} />
          <Typography variant="body2" gutterBottom style={{ fontWeight: 700 }}>
            Options:
          </Typography>
          <OptionAutocomplete
            formValues={formValues}
            setFormValues={setFormValues}
            isMaxOptions={isMaxOptions}
            questionOptions={questionOptions}
          />
        </Box>
      )}

      <Divider />

      {/* Sub-question */}
      <AddSubQuestionButton
        setFormValues={setFormValues}
        formValues={formValues}
        isEdit={isEdit}
        subQuestion={subQuestion}
      />
      {!isEmpty(formValues.children_questions) && (
        <ModalItemBox
          key={subQuestion.id || 0}
          index={0}
          item={subQuestion}
          handleInputChange={handleInputSubQuestionChange}
          setIsMaxCharactersError={setIsMaxCharactersError}
          formValues={formValues}
          setFormValues={setFormValues}
          attribute="children_questions"
          inputLabel="Sub-question"
          additionalInputs={[
            {
              id: 'description',
              name: 'description',
              label: 'Description',
              placeholder: 'Description',
              maxCharacters: 500,
            },
          ]}
        />
      )}
    </>
  );
};

QuestionContent.propTypes = {
  formValues: PropTypes.shape({
    question_type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })).isRequired,
    children_questions: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })).isRequired,
  }).isRequired,
  setFormValues: PropTypes.func.isRequired,
  setIsMaxCharactersError: PropTypes.func.isRequired,
  dropdownTypeValue: PropTypes.number.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default QuestionContent;
