import React from 'react';
import PropTypes from 'prop-types';
import { HELPER_TEXT } from 'common/constants/documents';
import CustomAutoSelect from 'pages/Documents/components/AddFolderDialog/CustomAutoSelect/CustomAutoSelect';
import { processDocumentsInformation } from 'pages/Documents/utilities/processDocuments';

const RequestFolder = ({ setFormValues, currentDocuments, setOpenFolderDialog, newFolder, companyName }) => {
  const currentDocumentsProcessed = currentDocuments ? processDocumentsInformation(currentDocuments) : [];
  const updatedCurrentFolders = [{ id: 0, name: 'No Option' }].concat(currentDocumentsProcessed.folders);

  const handleParentFolderChange = id => {
    const parentFolderId = id === 0 ? null : id;
    setFormValues(currentFormValues => ({
      ...currentFormValues,
      folder: Number(parentFolderId),
    }));
  };

  return (
    <CustomAutoSelect
      inputProps={{
        id: 'parent-folder',
        name: 'parent-folder',
        label: 'Select an option',
      }}
      onChange={handleParentFolderChange}
      data={updatedCurrentFolders}
      isAddNewFolderAtBottom={true}
      setOpenFolderDialog={setOpenFolderDialog}
      newFolder={newFolder}
      helperText={HELPER_TEXT(companyName)}
    />
  );
};

RequestFolder.propTypes = {
  setFormValues: PropTypes.func,
  currentDocuments: PropTypes.object,
  setOpenFolderDialog: PropTypes.func,
  newFolder: PropTypes.object,
  companyName: PropTypes.string,
};

export default RequestFolder;
