import { useEffect, useState } from 'react';
import { isUndefined } from 'lodash';

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(initialValue);

  useEffect(() => {
    const item = window.localStorage.getItem(key);
    if (item) {
      setStoredValue(JSON.parse(item));
    }
  }, [key]);

  const setNewValue = value => {
    if (!isUndefined(value)) {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  };

  return [storedValue, setNewValue];
};

export default useLocalStorage;
