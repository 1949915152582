const getQuarterRows = ({ alphabet, col, row }) => {
  const rowQuarters = [];

  for (let i = col; i > col - 4; i -= 1) {
    const quarterCellKey = alphabet[i - 1] + row;
    rowQuarters.push(quarterCellKey);
  }

  return rowQuarters.reverse();
};

export default getQuarterRows;
