/**
 * @file common/config/index.js
 * @module common/config
 * @desc This is the encapsulation for all global configurations
 * @since v1.0
 * @author @clenondavis <dev@carloslenon.com>
 * @return {Object} List of configurations
 */
import * as API from './api';
import * as APP from './app';

export default API;
export {
  APP,
  API,
};
