const removeNonAlpha = str => {
  const nonAlphaRegex = /[^\w\s]/gi;
  if (typeof str !== 'string') {
    throw new TypeError('removeNonAlpha: parameter should be a string.');
  }
  const strNonAlpha = str.replace(nonAlphaRegex, '');
  return strNonAlpha.replace(/\s+/g, ' ').trim();
};

export default removeNonAlpha;
