import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { styled, withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { useStore } from 'common/store';
import {
  COMPANY_OR_TICKER,
  COMPANY_TICKER,
  ENTER_SEARCH_TEXT,
  MULTIPLE_COMPANIES_FOUND,
  MULTIPLE_TICKERS,
  NO_COMPANIES_FOUND,
  ONE_COMPANY_FOUND,
  SEARCH_BY,
  SEARCH_DELAY,
  SEARCHING,
  SINGLE_COMPANY_RESULTS,
  TICKER_SEPARATORS,
  TICKERS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import ValuationService from 'services/valuations';
import tickerRegexTest from '../utils/tickerRegexTest';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  helperTextDefault: {
    margin: '0.5rem 0',
    fontSize: '0.875rem',
  },
  helperTextSearchResults: {
    color: theme.palette.primary[500],
  },
  searchResultsBorder: {
    border: `2px solid ${theme.palette.gray[300]}`,
  },
});

const StyledTextField = styled(TextField)({
  width: '100%',
});

const Search = withStyles(styles)(props => {
  const [{ isShowLoadingProgress }] = useStore();
  const [searchTicker, setSearchTicker] = useState('');
  const [searchContent, setSearchContent] = useState('');
  const [companyNameResults, setCompanyNameResults] = useState();
  const [searchingMessage, setSearchingMessage] = useState('');

  const {
    classes,
    setTableData,
    initialValues,
    fetchPCFromCompGroup,
    fetchCompanyData,
    measurementDate,
    financialsPeriods,
    financialsCurrency,
    multipleTickerSymbols,
    setMultipleTickerSymbols,
    removeCompsData,
    searchCriteria,
    setSearchCriteria,
  } = props;

  const disabledSearch = useMemo(() => {
    if (isShowLoadingProgress) {
      return true;
    }
    if (searchCriteria === TICKERS) {
      return !searchTicker || searchTicker.length === 0;
    }

    return true;
  }, [isShowLoadingProgress, searchCriteria, searchTicker]);

  const helperText = useMemo(() => {
    if (companyNameResults) {
      switch (companyNameResults.length) {
        case 0:
          return NO_COMPANIES_FOUND;
        case 1:
          return ONE_COMPANY_FOUND;
        default:
          return `${companyNameResults.length} ${MULTIPLE_COMPANIES_FOUND}`;
      }
    }

    if (isShowLoadingProgress) return searchingMessage;

    return ENTER_SEARCH_TEXT;
  }, [companyNameResults, searchingMessage, isShowLoadingProgress]);

  useEffect(() => {
    // Multiple tickers separated by comma, space, or semi-colon
    if (searchCriteria === TICKERS && TICKER_SEPARATORS.some(separator => searchContent.includes(separator))) {
      // 'NYSE:SHOP,  NYSE:SQ;NYSE:VEEV; NasdaqGS:WIX, ASDF' -->
      // 'NYSE:SHOP,NYSE:SQ,NYSE:VEEV,NasdaqGS:WIX,ASDF'

      const tickers = searchContent
        .split(/[,\s;]/)
        .filter(ticker => ticker.length !== 0)
        // Add comparable companies with ticker symbols that include shares or securities
        .map(ticker => {
          if (tickerRegexTest(ticker)) return ticker;
          return ticker.split('.')[0];
        });

      setMultipleTickerSymbols(tickers);
    } else {
      setMultipleTickerSymbols([]);
    }
  }, [searchContent, searchCriteria, setMultipleTickerSymbols]);

  // Search by company name or ticker
  const handleCriteriaChange = event => {
    setCompanyNameResults();
    removeCompsData();
    setSearchContent('');
    setMultipleTickerSymbols([]);
    setSearchCriteria(event.target.value);
  };

  // The MD may be undefined if the user is performing the search at the firm level
  const handleSearch = async ticker => {
    setCompanyNameResults();
    setSearchingMessage(SEARCHING);

    // Allow us to add comparable companies searching by multiple ticker symbols with Shares or Securities
    const tickerSymbol = tickerRegexTest(ticker) ? ticker : ticker.split('.')[0];

    if (!isShowLoadingProgress) {
      const tickers = [...multipleTickerSymbols];
      const isSearchingBySingleCompany = searchCriteria === COMPANY_TICKER;

      if (isSearchingBySingleCompany || !multipleTickerSymbols.length) {
        if (!tickers.includes(tickerSymbol)) {
          tickers.push(tickerSymbol);
          setMultipleTickerSymbols(tickers);
        }
      }
      const periods = financialsPeriods ?? [];
      if (isSearchingBySingleCompany) {
        // Single ticker or company name
        const companyData = companyNameResults?.find(company => company.ticker_symbol === tickerSymbol);
        const keyword = companyData?.company_id || tickerSymbol;
        await fetchCompanyData(keyword, measurementDate?.id || 0, financialsCurrency, periods);
      } else {
        setTableData();
        await fetchPCFromCompGroup(tickers, measurementDate?.id || 0, financialsCurrency, periods);
      }
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' && !!searchTicker) {
      if (multipleTickerSymbols.length === 0) {
        setTableData({
          ...initialValues,
          ticker: `Search ${TICKERS}: ${searchTicker}`,
        });
      }
      handleSearch(searchTicker);
    }
  };

  const debouncedTickerSearch = useRef(
    debounce(async keyword => {
      if (keyword) {
        const valuationSvc = new ValuationService();
        try {
          // Add comparable companies with ticker symbols that include shares or securities
          const response = await valuationSvc.getTickerByKeyword(
            tickerRegexTest(keyword) ? keyword : keyword.split('.')[0]
          );
          const numberOfResults = response.length;
          setCompanyNameResults(response);
          // Prepare search by clicking the magnifying glass or pressing Enter
          if (numberOfResults === SINGLE_COMPANY_RESULTS) {
            setSearchTicker(response[0].company_id);
          }
        } catch {
          setCompanyNameResults([]);
        }
      }
    }, SEARCH_DELAY)
  ).current;

  const handleChange = ({ target: { value } }) => {
    setSearchContent(value);
    if (searchCriteria === TICKERS) {
      setSearchTicker(value);
    } else {
      // Search by company name
      debouncedTickerSearch(value);
    }
  };

  // Clean helper text when switching between search by ticker and by company name
  useEffect(() => {
    setTableData({ companyName: '', ticker: '', tableRows: [] });
    if (searchCriteria === TICKERS) {
      setCompanyNameResults();
      setSearchContent('');
      setSearchingMessage('');
    }
  }, [searchCriteria, setTableData]);

  return (
    <div className={classes.root}>
      <Typography variant="h3" align="justify">
        {SEARCH_BY}
      </Typography>
      <Box display="flex" justifyContent="start">
        <RadioGroup
          aria-label="searchCriteria"
          name="searchCriteria"
          value={searchCriteria}
          onChange={handleCriteriaChange}>
          <div id="filters" style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.6rem' }}>
            <FormControlLabel
              value={COMPANY_TICKER}
              control={<Radio />}
              label={COMPANY_OR_TICKER}
              disabled={isShowLoadingProgress}
            />
            <FormControlLabel
              value={TICKERS}
              control={<Radio />}
              label={MULTIPLE_TICKERS}
              disabled={isShowLoadingProgress}
            />
          </div>
        </RadioGroup>
      </Box>
      {isShowLoadingProgress && <LinearProgress color="primary" />}
      <Paper>
        <StyledTextField
          onChange={handleChange}
          value={searchContent}
          onKeyDown={handleKeyDown}
          label="Search"
          id="gpc-search"
          data-testid="gpc-search"
          type="text"
          name="SearchText"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  data-testid="search-ticker-btn"
                  onClick={() => handleSearch(searchContent)}
                  disabled={disabledSearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          disabled={isShowLoadingProgress}
        />
      </Paper>
      {!isEmpty(companyNameResults) && (
        <Box className={classes.searchResultsBorder}>
          {companyNameResults?.map(option => (
            <MenuItem key={uuid()} value={option.ticker_symbol} onClick={() => handleSearch(option.ticker_symbol)}>
              {`${option.ticker_symbol} - ${option.name}`}
            </MenuItem>
          ))}
        </Box>
      )}
      <Typography className={clsx(classes.helperTextDefault, companyNameResults && classes.helperTextSearchResults)}>
        {helperText}
      </Typography>
    </div>
  );
});

Search.propTypes = {
  setTableData: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    companyName: PropTypes.string,
    tableRows: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  fetchPCFromCompGroup: PropTypes.func.isRequired,
  fetchCompanyData: PropTypes.func.isRequired,
  measurementDate: PropTypes.shape({
    cmd_id: PropTypes.number,
    date: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    is_open: PropTypes.bool,
    slug: PropTypes.string,
  }),
  financialsPeriods: PropTypes.arrayOf(PropTypes.shape({})),
  financialsCurrency: PropTypes.string.isRequired,
  multipleTickerSymbols: PropTypes.arrayOf(PropTypes.string),
  setMultipleTickerSymbols: PropTypes.func.isRequired,
  removeCompsData: PropTypes.func.isRequired,
  searchCriteria: PropTypes.string.isRequired,
  setSearchCriteria: PropTypes.func.isRequired,
};

export default Search;
