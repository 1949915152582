import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    button: {
      position: 'fixed',
      right: theme.spacing(2),
      bottom: theme.spacing(2),
      zIndex: 3,
    },
    icon: {
      paddingRight: theme.spacing(1),
    },
    label: {
      fontSize: '15px',
      fontWeight: 700,
    },
    disabled: {
      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
      color: 'rgba(0, 0, 0, 0.26) !important',
    },
  })
);

export default useStyles;
