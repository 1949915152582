import { VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY } from 'pages/ValuationsAllocation/common/constants/valuations';

export const MEDIAN_ALIAS = 'median';
export const MEAN_ALIAS = 'mean';
export const SEVENTY_FIFTH_ALIAS = '75th';
export const TWENTY_FIFTH_ALIAS = '25th';
export const PERCENTILE_SELECTION_A_ALIAS = 'percentile_selection_a';
export const PERCENTILE_SELECTION_B_ALIAS = 'percentile_selection_b';
export const PERCENTILE_ROWS = [PERCENTILE_SELECTION_A_ALIAS, PERCENTILE_SELECTION_B_ALIAS];
export const PERCENTILE_75 = '75th Percentile';
export const PERCENTILE_25 = '25th Percentile';

export const SPECIFIED_LABEL = 'Specified';
export const MEAN = 'Mean';
export const MEDIAN = 'Median';

// WARNING: DON'T rearrange these, as their order effects a cell in the GPC
// Note: Valid value for database is 'Selected' (not 'Specified'). Handled in reverseParser.
export const VALUES_OPTIONS = [MEDIAN, MEAN, PERCENTILE_75, PERCENTILE_25, SPECIFIED_LABEL];
export const LIMITED_VALUES_OPTIONS = [MEDIAN, MEAN, SPECIFIED_LABEL];

export const ADD_COMP = 'Add Comparable Company';
export const ADD_PUBLIC = 'Add Public Company';
export const SELECTED_DATABASE_VALUE = 'Selected';
export const GPC_SUMMARY = 'gpc_summary';
export const COMPANY_TICKER = 'company-ticker';
export const COMPANY = 'company';
export const COMPANIES = 'companies';
export const SELECTION = 'selection';
export const SELECTED_MULTIPLE_VALUE = 'selected_multiple_value';
export const SELECTED_MULTIPLE = 'selected_multiple';
export const WEIGHTING = 'weighting';
export const WEIGHTED_EV = VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY;
export const WEIGHTED_EQUITY_VALUE = 'equity_value';

export const STOCK_PRICE_ID = 'close_price';
export const MARKET_CAP_ID = 'market_cap';
export const TICKER_SYMBOL_ID = 'ticker_symbol';

export const BENCHMARK_ALIASES = [
  MEDIAN_ALIAS,
  MEAN_ALIAS,
  SEVENTY_FIFTH_ALIAS,
  TWENTY_FIFTH_ALIAS,
  PERCENTILE_SELECTION_A_ALIAS,
  PERCENTILE_SELECTION_B_ALIAS,
  SELECTED_MULTIPLE,
];

export const ENTERPRISE_VALUE_ID = VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY;

export const LTM_REVENUE = 'ltm_revenue';
export const LTM_EBITDA = 'ltm_ebitda';
export const NTM_REVENUE = 'ntm_revenue';
export const NTM_EBITDA = 'ntm_ebitda';

export const LTM_NTM_COLUMNS_ALIAS = [LTM_REVENUE, LTM_EBITDA, NTM_REVENUE, NTM_EBITDA];

export const middleColumnLetters = ['A', 'B', 'C', 'D'];
export const mainColumnLetters = ['E', 'F', 'G', 'H'];
export const ltmColumnLetters = mainColumnLetters.slice(0, 2);
export const ntmColumnLetters = mainColumnLetters.slice(2, 4);

export const STOCK_PRICE = 'Stock Price as of Measurement Date';
export const MARKET_CAP_MILLIONS = ({ currencyCode, currencySymbol }) =>
  `Market Cap in ${currencyCode} (${currencySymbol}) Millions`;
export const REVENUE = 'Revenue Multiple';
export const EBITDA = 'EBITDA Multiple';
export const ENTERPRISE_VALUE_MILLIONS = ({ currencyCode, currencySymbol }) =>
  `Enterprise Value in ${currencyCode} (${currencySymbol}) Millions`;

export const WARNING_GETTING_COMPANIES
  = 'Could not get valid information from all the companies in the comp group, only the valid ones will be added.';
export const ERROR_GETTING_COMPANIES = 'Could not get valid information from the companies in the comp group';
export const NO_COMPANIES_FOUND = 'No companies found. Please enter another search term and try again.';
export const ONE_COMPANY_FOUND = '1 company found. Select it to add it.';
export const MULTIPLE_COMPANIES_FOUND = 'companies found. Select a company to add.';
export const TICKERS = 'tickers';
export const MULTIPLE_TICKERS = 'Enter Multiple Tickers';
export const COMPANY_OR_TICKER = 'Company or Ticker';
export const RETRIEVING_COMPANY = 'Retrieving company data...';
export const SEARCHING = 'Searching...';
export const SEARCH_DELAY = 300;
export const TICKER_SEPARATORS = [';', ', ', ' '];
export const SYMBOL_NOT_FOUND = 'symbol not found';
export const SINGLE_COMPANY_RESULTS = 1;
export const ENTER_SEARCH_TEXT = 'Type in the box to search for companies';
export const SEARCH_BY = 'Search By:';
export const ENTERPRISE_VALUE_OPTION = 'Enterprise Value';
export const EV_EXCL_OPERATING_LEASES_OPTION = 'EV Excl Op Leases';
export const EV_EXCL_OP_LEASES_ALIAS = 'ev_excl_operating_leases';
export const MULTIPLE_BASIS_OPTIONS = [ENTERPRISE_VALUE_OPTION, EV_EXCL_OPERATING_LEASES_OPTION];
export const ENTERPRISE_VALUE_COLUMN_ID = VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY;
export const MULTIPLE_BASIS_ALIAS = 'multiple_basis';
export const PERCENTILE_RANKING_TITLE = 'Percentile Ranking';

export const COMPANY_NOT_PUBLIC = ({ company_name, measurement_date }) =>
  `There isn't available data for ${company_name} because it isn't public or is no longer public as of  
  the ${measurement_date ? 'selected measurement date' : 'current date'}. This company will not be added.`;

export const COMPANY_PRESENT_IN_TABLE = ({ company_name }) =>
  `The company ${company_name} is already present in the table.`;

export const SOME_COMPANIES_NOT_PUBLIC = measurementDate =>
  `There are no available data for some companies as they were not public or no longer public at the ${
    measurementDate ? 'selected measurement date' : 'current date'
  }. These companies will not added.`;

export const PUBLIC_COMPS_APPROACH_ALIAS = 'public_comps_approach';

export const VALUATION_GPC_TABLE_SLUG = 'valuation-gpc';
export const VALUATION_GPC_LTM_DROPDOWN_KEY = 'E1';
export const VALUATION_GPC_EXTRA_INFO_TOOLTIP_TITLE = 'Click here to hide the extra info';
export const VALUATION_EXPAND_GPC_EXTRA_INFO_KEY = 'expandGPCExtraInfo';
export const NTM_REVENUE_MULTIPLE = 'ntm_revenue_multiple';
export const NTM_EBITDA_MULTIPLE = 'ntm_ebitda_multiple';
export const MULTIPLE_DISCOUNT_COLUMN_LEGEND = [NTM_REVENUE_MULTIPLE, NTM_EBITDA_MULTIPLE];

export const LTM_EBITDA_CUSTOM_KEY = 'ltm_ebitda_selected_multiple_value';
export const NTM_EBITDA_CUSTOM_KEY = 'ntm_ebitda_selected_multiple_value';
export const LTM_REVENUE_CUSTOM_KEY = 'ltm_revenue_selected_multiple_value';
export const NTM_REVENUE_CUSTOM_KEY = 'ntm_revenue_selected_multiple_value';
