import React, { FC, RefObject, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import clsx from 'clsx';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useRevealEllipsisInTooltip } from 'services/hooks';
import { getStringValue, setQueryString } from 'utillities';
import useStyles from './styles';
import { TextContentProps, UseRevealEllipsisInTooltipHook, ValueWithLinkViewerProps } from './types';

const TextContent: FC<TextContentProps> = props => {
  const { classes, isDarkMode, isTitle, name, shouldDisplayTooltip, textRef } = props;

  return (
    <Grid className={clsx(classes?.textContainer, { dark: isDarkMode })}>
      {isTitle ? (
        <Typography component="h3" variant="h4">
          {name}
        </Typography>
      ) : (
        <Tooltip placement="bottom-start" title={shouldDisplayTooltip ? getStringValue(name) : ''}>
          <p className={classes?.text} ref={textRef}>
            {name}
          </p>
        </Tooltip>
      )}
    </Grid>
  );
};

const ValueWithLinkViewer: FC<ValueWithLinkViewerProps> = props => {
  const { exchangeRateMessage = '', isDarkMode = false, isTitle = false, name = '', url = '' } = props;

  const [isHovered, setIsHovered] = useState(false);
  const [textWidth, setTextWidth] = useState(0);

  const textRef = useRef<HTMLParagraphElement | null>(null);

  const classes = useStyles();

  const history = useHistory();

  const { tooltipRef: textContainerRef, width: textContainerWidth }
    = useRevealEllipsisInTooltip() as unknown as UseRevealEllipsisInTooltipHook<HTMLAnchorElement | HTMLDivElement>;

  const shouldDisplayTooltip = useMemo(() => textWidth > textContainerWidth, [textContainerWidth, textWidth]);

  // Set Text width
  useLayoutEffect(() => {
    if (textRef.current) setTextWidth(textRef.current.offsetWidth);
  }, []);

  const handleHover = () => setIsHovered(true);

  const handleUnhover = () => setIsHovered(false);

  const redirectToUrl = () => {
    const parsedUrl = new URL(url, window.location.origin);
    const { pathname, search } = parsedUrl;

    const parsedQuery = queryString.parse(search);

    setQueryString({
      history,
      paramName: '',
      paramValue: '',
      pathname,
      searchParams: queryString.stringify(parsedQuery),
      shouldPushPath: true,
    });
  };

  return (
    <Tooltip placement="top-start" title={exchangeRateMessage} arrow>
      {url ? (
        <Grid
          className={clsx(classes.container, { title: isTitle, link: true })}
          onClick={redirectToUrl}
          onMouseEnter={handleHover}
          onMouseLeave={handleUnhover}
          ref={textContainerRef as RefObject<HTMLDivElement>}>
          <TextContent
            classes={classes}
            isDarkMode={isDarkMode}
            isTitle={isTitle}
            name={name}
            shouldDisplayTooltip={shouldDisplayTooltip}
            textRef={textRef}
          />
          <Grid className={clsx(classes.iconContainer, { active: isHovered, dark: isDarkMode })}>
            <LinkIcon className={clsx(classes.icon, { dark: isDarkMode })} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          className={clsx(classes.container, { title: isTitle })}
          ref={textContainerRef as RefObject<HTMLDivElement>}>
          <TextContent
            classes={classes}
            isDarkMode={isDarkMode}
            isTitle={isTitle}
            name={name}
            shouldDisplayTooltip={shouldDisplayTooltip}
            textRef={textRef}
          />
        </Grid>
      )}
    </Tooltip>
  );
};

export default ValueWithLinkViewer;
