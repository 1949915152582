import React from 'react';
import { ALLOCATIONS_FINAL } from 'common/constants/allocations';
import { largeCurrencyFormat, oneDecimalPercentFormat } from 'common/formats/formats';
import { AllocationStatus } from 'common/types/allocation';
import { Cell, Cells } from 'common/types/scalarSpreadsheet';
import { SHEET_ALIASES_CONSTANTS, SHEET_TITLES_CONSTANTS } from 'pages/Funds/common/constants/valuationsSummary';
import { AllocationStatusSelect, MakeAllFinalButton } from 'pages/Funds/components';
import { generateTotalExpression, getArrayValue, getExpression, getObjectValue, handleDisabledCells } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { CellParserParams, CustomParserParams, GenerateAllValuationsExpression } from './types';

const { VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS, VALUATIONS_SUMMARY_SPREADSHEET_HEADERS } = SHEET_TITLES_CONSTANTS;
const { VALUATION_SUMMARY_SPREADSHEET_HEADER_TITLE, VALUATIONS_SUMMARY_SPREADSHEET_TOTAL } = SHEET_ALIASES_CONSTANTS;

const DEFAULT_ALLOW_NEGATIVE_VALUE = true;
const DEFAULT_ROW_SPAN = 1;
const EXCLUDE_HEADER_AND_TOTAL = 2;

const HEADER_AND_TOTAL = Object.freeze([
  VALUATION_SUMMARY_SPREADSHEET_HEADER_TITLE,
  VALUATIONS_SUMMARY_SPREADSHEET_TOTAL,
]);

const generateAllValuationsExpression = (params: GenerateAllValuationsExpression) => {
  const { rowConfig } = params;

  const allValuations = Array.from({ length: rowConfig.length - EXCLUDE_HEADER_AND_TOTAL }, () => ({}));
  const allValuationsExpression = generateTotalExpression({
    items: allValuations,
    skippedIndexes: EXCLUDE_HEADER_AND_TOTAL,
  });

  return {
    allValuationsExpression,
  };
};

const cellParser = (params: CellParserParams) => {
  const {
    allValuationsExpression,
    alphabet,
    cellCustomClasses,
    colIndex,
    column,
    handleAllocationsStatuses,
    row,
    rowIndex,
    shouldDisableMakeAllFinalButton,
    totalBreakevenEquityExitValue,
    totalPercentageChange,
  } = params;

  const rowNumber = rowIndex + 1;
  const colNumber = colIndex + 1;
  const columnLegend = alphabet[colIndex];
  const key = columnLegend + rowNumber;

  const { alias } = getObjectValue(row);
  let {
    component = null,
    expr = '',
    forceComponent = false,
    format = null,
    gridType = 'string',
    tooltipMessages = [],
  } = getObjectValue(row);

  const { id: allocationId = 0 } = getObjectValue(column[alias]);
  let { value = '' } = getObjectValue(column[alias]);

  // Parse cell based on Row alias
  switch (alias) {
    // Header titles
    case VALUATION_SUMMARY_SPREADSHEET_HEADER_TITLE:
      value = VALUATIONS_SUMMARY_SPREADSHEET_HEADERS[colNumber].value;
      break;

    // Totals
    case VALUATIONS_SUMMARY_SPREADSHEET_TOTAL:
      switch (colNumber) {
        case VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.VALUATION_STATUS:
          component = (
            <MakeAllFinalButton
              isDisabled={shouldDisableMakeAllFinalButton}
              onClick={() =>
                handleAllocationsStatuses({
                  allocationIds: [],
                  allocationStatus: ALLOCATIONS_FINAL,
                  applyToAll: true,
                })
              }
            />
          );
          forceComponent = true;
          break;

        case VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.ENTERPRISE_VALUE:
        case VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.EQUITY_VALUE:
        case VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.CURRENT_FUND_VALUE:
          expr = `=SUM(${allValuationsExpression})`;
          format = largeCurrencyFormat;
          gridType = 'number';
          break;

        case VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.BREAKEVEN_EQUITY_EXIT_VALUE:
          format = largeCurrencyFormat;
          gridType = 'number';
          value = totalBreakevenEquityExitValue;
          break;

        case VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.CHANGE_FROM_PREVIOUS:
          format = oneDecimalPercentFormat;
          gridType = 'percentage';
          value = totalPercentageChange;
          break;

        default:
          break;
      }
      break;

    // Rest of the rows (excluding Header titles and Totals)
    default:
      // Parse cell based on Column number
      switch (colNumber) {
        case VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.VALUATION_STATUS:
          component = (
            <AllocationStatusSelect
              allocationId={allocationId}
              allocationStatus={Number(value) as AllocationStatus}
              setAllocationStatus={handleAllocationsStatuses}
            />
          );
          forceComponent = true;
          break;

        case VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.ENTERPRISE_VALUATION_METHOD:
          tooltipMessages = getArrayValue(column[alias]?.tooltipMessages);
          break;

        case VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.ENTERPRISE_VALUE:
        case VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.EQUITY_VALUE:
        case VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.BREAKEVEN_EQUITY_EXIT_VALUE:
        case VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.CURRENT_FUND_VALUE:
          format = largeCurrencyFormat;
          gridType = 'number';
          tooltipMessages = getArrayValue(column[alias]?.tooltipMessages);
          break;

        case VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.CHANGE_FROM_PREVIOUS:
          format = oneDecimalPercentFormat;
          gridType = 'percentage';

          break;

        default:
          break;
      }
      break;
  }

  // Handle disabled cells (has only Custom Ownerships)

  const shouldCheckDisabledCell
    = !HEADER_AND_TOTAL.includes(alias) // Ignore Header titles and Total
    && (
      [
        VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.ENTERPRISE_VALUATION_METHOD,
        VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.ENTERPRISE_VALUE,
        VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.EQUITY_VALUE,
        VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.BREAKEVEN_EQUITY_EXIT_VALUE,
        VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS.ALLOCATION_METHOD,
      ] as number[]
    ).includes(colNumber);

  const { cellClassName, cellGridType, cellTooltipMessages, cellValue } = handleDisabledCells({
    cellCustomClasses,
    colNumber,
    column,
    gridType,
    row,
    shouldCheckDisabledCell,
    tooltipMessages,
    value,
  });

  return {
    [key]: {
      ...row,
      allowNegativeValue: DEFAULT_ALLOW_NEGATIVE_VALUE,
      className: cellClassName,
      columnLegend,
      component,
      expr: getExpression({ expr, columnLegend }),
      forceComponent,
      format,
      gridType: cellGridType,
      key,
      rowSpan: DEFAULT_ROW_SPAN,
      tooltipMessages: cellTooltipMessages,
      value: cellValue,
    } as Cell,
  };
};

const customParser = (params: CustomParserParams) => {
  const {
    cellCustomClasses,
    columns,
    handleAllocationsStatuses,
    rowConfig,
    shouldDisableMakeAllFinalButton,
    totalBreakevenEquityExitValue,
    totalPercentageChange,
  } = params;

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];

  const { allValuationsExpression } = generateAllValuationsExpression({
    rowConfig,
  });

  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      cells = {
        ...cells,
        ...cellParser({
          allValuationsExpression,
          alphabet,
          cellCustomClasses,
          colIndex,
          column,
          handleAllocationsStatuses,
          row,
          rowIndex,
          shouldDisableMakeAllFinalButton,
          totalBreakevenEquityExitValue,
          totalPercentageChange,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
