const getValue = (currencySymbol, valueCell, letterCell) => {
  const numberValue = valueCell.replace('$', '');
  const isZero = Number(numberValue) === 0;
  const formattedValue = isZero ? letterCell : numberValue;
  return `${currencySymbol}${formattedValue}`;
};

const BP_RANGE = (
  currencySymbol,
  previousCellValue,
  currentCellValue,
  isFirstColumn,
  isLastColumn,
  previousCellLeter,
  currentCellLeter
) => {
  const fixedPreviousCell = isFirstColumn
    ? `${currencySymbol}0`
    : getValue(currencySymbol, previousCellValue, previousCellLeter);
  const fixedCurrentCell = isLastColumn
    ? currentCellValue
    : getValue(currencySymbol, currentCellValue, currentCellLeter);

  return `${fixedPreviousCell} \nto ${fixedCurrentCell}`;
};

export default BP_RANGE;
