import React, { useEffect, useRef } from 'react';
import {
  DialogContent,
  IconButton,
  LinearProgress,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { DIALOG_TITLES_NOT_GRID } from 'common/constants/messages/validations';
import useDataSheetDialogActions from 'common/hooks/useDataSheetDialogActions';

const useStyles = makeStyles(theme => ({
  paperSm: {
    maxWidth: '700px !important',
  },
  title: {
    paddingTop: theme.spacing(2),
    width: '90%',
  },
  subtitle: {
    paddingTop: 0,
    marginTop: 0,
    fontSize: '0.8em',
    fontWeight: 'bold',
  },
  content: {
    padding: theme.spacing(3),
  },
  actionButtonLabel: {
    fontSize: '0.8750em',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(2),
    color: '#000',
  },
  success: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  danger: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  loadingText: {
    padding: theme.spacing(3),
    textAlign: 'center',
  },
}));

const DialogTitle = ({ children, onClose, dialogProps, ...other }) => {
  const { disableTitleClose } = dialogProps;
  const style = useStyles();
  return (
    <MuiDialogTitle disableTypography className={style.root} {...other}>
      <Typography className={style.title} variant="h6">
        {children}
      </Typography>
      {onClose && !disableTitleClose && (
        <IconButton aria-label="close" className={style.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

const Dialog = props => {
  const {
    open,
    title,
    children,
    onClose,
    fullWidth,
    maxWidth,
    classes,
    dialogProps,
    isLoading = false,
    hideTitle = false,
    subtitle,
    PaperProps,
    loadingText,
    customDialogRef,
    loadingIncrement,
    ...additionalProps
  } = props;
  const style = useStyles();
  const { disableBackdropClick, disableEscapeKeyDown } = dialogProps;
  const dialogRef = useRef(customDialogRef || uuid());
  const shouldShowContent = !isUndefined(loadingText) ? !isLoading && !loadingText : true;

  const { setDialogFlag } = useDataSheetDialogActions();

  useEffect(() => {
    const dialogId = dialogRef.current;

    if (setDialogFlag && !DIALOG_TITLES_NOT_GRID.includes(title)) {
      setDialogFlag({
        open,
        dialogId,
        cellId: dialogProps.cellKey,
        disableWhenLedgerOpen: dialogProps.disableWhenLedgerOpen,
      });
    }

    return () => {
      if (setDialogFlag && !DIALOG_TITLES_NOT_GRID.includes(title)) {
        setDialogFlag({ open: false, dialogId, cellId: null });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = (event, reason) => {
    if (disableBackdropClick && reason && reason === 'backdropClick') {
      return;
    }

    onClose();
  };

  return (
    <MuiDialog
      fullWidth={fullWidth}
      maxWidth={maxWidth || 'md'}
      onClose={handleClose}
      open={open}
      classes={classes}
      disableEscapeKeyDown={disableEscapeKeyDown}
      PaperProps={PaperProps}
      {...additionalProps}>
      {!hideTitle && (
        <DialogTitle onClose={onClose} dialogProps={dialogProps}>
          {dialogProps.title || title}
        </DialogTitle>
      )}

      {subtitle && <DialogContent className={style.subtitle}>{subtitle}</DialogContent>}
      {isLoading && (
        <LinearProgress
          color="primary"
          value={loadingIncrement}
          variant={loadingIncrement ? 'determinate' : 'indeterminate'}
        />
      )}
      {isLoading && loadingText && <DialogContent className={style.loadingText}>{loadingText}</DialogContent>}
      {shouldShowContent && <DialogContent className={style.content}>{children}</DialogContent>}
    </MuiDialog>
  );
};

Dialog.defaultProps = {
  dialogProps: {
    disableEscapeKeyDown: false,
  },
  fullWidth: false,
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  disableEscapeKeyDown: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.any.isRequired,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  dialogProps: PropTypes.object,
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  hideTitle: PropTypes.bool,
  PaperProps: PropTypes.object,
  loadingText: PropTypes.string,
  customDialogRef: PropTypes.any,
  loadingIncrement: PropTypes.number,
};

DialogTitle.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.string,
  dialogProps: PropTypes.object,
};

export default Dialog;
