/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { all as getCountries } from 'country-codes-list';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { FIRM_FIELDS_CONSTRAINTS, REQUIRES_2FA } from 'common/constants/firms';
import { CustomTextField, WebsiteTextField } from 'components';
import { useFormValidation } from 'services/hooks/useFormValidation';

const useStyles = makeStyles({
  formHeader: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  margin: {
    marginBottom: 20,
  },
  textField: {
    width: '100%',
  },
  autocomplete: {
    zIndex: 1300,
  },
  checkbox: {
    margin: '0.5rem 0',
  },
});

const FirmForm = ({ defaultFormState: { values, errors, dbErrors }, onFormChange }) => {
  const classes = useStyles();

  const { formValidated, validateForm } = useFormValidation(FIRM_FIELDS_CONSTRAINTS);
  const [formValues, setFormValues] = useState(values);
  const countries = getCountries().map(({ countryCode, countryNameEn }) => ({
    value: countryCode,
    label: countryNameEn,
  }));

  useEffect(() => {
    validateForm(formValues);
  }, [validateForm, formValues]);

  useEffect(() => {
    onFormChange(formValidated);
  }, [formValidated]);

  const handleChange = event => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckboxChange = event => {
    event.persist();
    setFormValues({
      ...formValues,
      requires_2fa: event.target.checked,
    });
  };

  const handleCountryChange = (_event, option) => {
    setFormValues({
      ...formValues,
      country: option.value,
    });
  };

  const countryValue = useMemo(() => {
    if (formValues?.country) {
      return countries.find(c => c.value === formValues.country);
    }

    return null;
  }, [formValues]);

  return (
    <Grid container>
      <CustomTextField
        fullWidth
        required
        id="name"
        type="text"
        name="name"
        label="Name"
        value={formValues.name || ''}
        className={classes.margin}
        onChange={handleChange}
        formErrors={errors}
        dbErrors={dbErrors}
      />
      <CustomTextField
        fullWidth
        id="address"
        type="text"
        name="address"
        label="Street Address"
        value={formValues.address || ''}
        className={classes.margin}
        onChange={handleChange}
        formErrors={errors}
        dbErrors={dbErrors}
      />
      <Grid container spacing={1}>
        <Grid item md={4} className={classes.margin}>
          <Autocomplete
            id="country"
            options={sortBy(countries, 'label')}
            style={{ width: '100%' }}
            autoHighlight
            getOptionLabel={option => option.label}
            onChange={handleCountryChange}
            value={countryValue}
            classes={{
              popper: classes.autocomplete,
            }}
            renderInput={params => <TextField {...params} fullWidth required label="Choose a country" name="country" />}
            disableClearable
          />
        </Grid>
        <Grid item md={4} className={classes.margin}>
          <CustomTextField
            fullWidth
            id="state"
            type="text"
            name="state"
            label="State"
            value={formValues.state || ''}
            onChange={handleChange}
            formErrors={errors}
            dbErrors={dbErrors}
          />
        </Grid>
        <Grid item md={4} className={classes.margin}>
          <CustomTextField
            fullWidth
            id="zip_code"
            type="text"
            name="zip_code"
            label="Zip Code"
            value={formValues.zip_code || ''}
            onChange={handleChange}
            formErrors={errors}
            dbErrors={dbErrors}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.checkbox}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={formValues?.requires_2fa || false} onChange={handleCheckboxChange} />}
            label={REQUIRES_2FA}
          />
        </FormGroup>
      </Grid>
      <Typography variant="h4" component="h3" className={classes.formHeader}>
        Contact information
      </Typography>
      <CustomTextField
        fullWidth
        id="phone_number"
        type="text"
        name="phone_number"
        label="Phone number"
        value={formValues.phone_number || ''}
        className={classes.margin}
        onChange={handleChange}
        formErrors={errors}
        dbErrors={dbErrors}
      />
      <WebsiteTextField
        fullWidth
        id="website"
        type="text"
        name="website"
        label="Website"
        value={formValues.website || ''}
        className={classes.margin}
        placeholder="http(s)://www.example.com"
        onChange={handleChange}
        formErrors={errors}
        dbErrors={dbErrors}
      />
    </Grid>
  );
};

FirmForm.defaultProps = {
  defaultFormState: {},
};

FirmForm.propTypes = {
  defaultFormState: PropTypes.object,
  onFormChange: PropTypes.func.isRequired,
};

export default FirmForm;
