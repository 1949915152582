import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { UnsavedChangesConsumer, UnsavedChangesProvider } from 'providers/UnsavedChangesProvider';
import UnsavedChangesGuard from './UnsavedChangesGuard';

const getNextAction = (history, targetLocation) => {
  const { pathname, search } = targetLocation;
  const parsedQuery = queryString.parse(search);
  if (parsedQuery.date) {
    delete parsedQuery.version;
  }
  return history.push({ pathname, search: queryString.stringify(parsedQuery) });
};

const UnsavedChangesWrapper = ({ children, history }) => (
  <UnsavedChangesProvider>
    <>
      <UnsavedChangesConsumer>
        {({ action, isVisible, showDialog }) => {
          if (!!action && !!showDialog && !isVisible) {
            return (
              <UnsavedChangesGuard
                when={!!action}
                next={targetLocation => getNextAction(history, targetLocation)}
                shouldBlockNavigation={() => !!action}
                showDialog={showDialog}
                isVisible={isVisible}
              />
            );
          }
        }}
      </UnsavedChangesConsumer>
      {children}
    </>
  </UnsavedChangesProvider>
);

UnsavedChangesWrapper.propTypes = {
  children: PropTypes.element,
  history: PropTypes.object,
};

export default withRouter(UnsavedChangesWrapper);
