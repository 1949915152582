/* eslint-disable max-classes-per-file */
class NodeList {
  constructor(data) {
    this.data = data;
    this.next = null;
  }
}

class LinkedList {
  constructor(head = null) {
    this.head = head;
    this.uniques = [];
  }

  add = element => {
    // Add element only if it wasn't added previously
    if (!this.uniques.includes(element)) {
      // creates a new node
      const node = new NodeList(element);

      // to store current node
      let current;

      // if list is Empty add the
      // element and make it head
      if (this.head == null) this.head = node;
      else {
        current = this.head;

        // iterate to the end of the
        // list
        while (current.next) {
          current = current.next;
        }

        // add node
        current.next = node;
      }

      this.uniques.push(element);
    }
  }
}

export default LinkedList;
