import React, { ChangeEvent, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { FUNDS_DEFAULT_CURRENCY } from 'common/constants/funds';
import { CustomTextField, NumberFormattedField } from 'components';
import CurrencyList from 'components/CurrencyList';
import {
  FUND_DIALOG_FORM_FUND_CURRENCY,
  FUND_DIALOG_FORM_NAME,
  FUND_DIALOG_FORM_TOTAL_COMMITTED_CAPITAL,
} from 'pages/Funds/common/constants/fundDialogForm';
import { getFieldError } from 'utillities';
import { FUND_FORM_SCHEMA } from './schema';
import useStyles from './styles';
import { FundFormSchema, FundFormState, UseFundFormParams } from './types';

const FUND_FORM_DEFAUT_VALUES: FundFormSchema = {
  name: '',
  total_committed_capital: '0',
  currency: FUNDS_DEFAULT_CURRENCY,
};

const useFundForm = (params: UseFundFormParams) => {
  const { fund } = params;

  const classes = useStyles();

  // Form state
  const {
    control,
    formState: { errors, isValid },
    watch,
  } = useForm<FundFormSchema>({
    mode: 'onChange',
    defaultValues: fund ?? FUND_FORM_DEFAUT_VALUES,
    resolver: yupResolver(FUND_FORM_SCHEMA),
  });

  const currentValues = watch();

  const formState = useMemo(
    () => ({ errors, isValid, values: currentValues } as FundFormState),
    [currentValues, errors, isValid]
  );

  const FundForm = (
    <Grid container>
      {/* Fund Name */}
      <Grid className={classes.section} container>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { invalid, error } }) => {
            const { name, onBlur, onChange, value } = field;

            const label = FUND_DIALOG_FORM_NAME;

            return (
              <CustomTextField
                dbErrors={undefined}
                formErrors={getFieldError({ error, invalid, name, label })}
                fullWidth
                id={name}
                label={label}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={undefined}
                required
                type="text"
                value={value}
              />
            );
          }}
        />
      </Grid>

      <Grid container spacing={1}>
        {/* Total Committed Capital */}
        <Grid item xs={8}>
          <Controller
            name="total_committed_capital"
            control={control}
            render={({ field, fieldState: { invalid, error } }) => {
              const { name, onBlur, onChange, value } = field;

              const fundCurrency = currentValues.currency;

              const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value);

              const label = FUND_DIALOG_FORM_TOTAL_COMMITTED_CAPITAL;

              return (
                <NumberFormattedField
                  currencyCode={fundCurrency}
                  dbErrors={undefined}
                  format="currency"
                  formErrors={getFieldError({ error, invalid, name, label })}
                  fullWidth
                  id={name}
                  label={label}
                  name={name}
                  onBlur={onBlur}
                  onChange={handleOnChange}
                  required
                  type="text"
                  value={value}
                />
              );
            }}
          />
        </Grid>

        {/* Fund Currency */}
        <Grid item xs={4}>
          <Controller
            name="currency"
            control={control}
            render={({ field }) => {
              const { onChange, value } = field;

              const handleOnChange = (
                _event: ChangeEvent<HTMLSelectElement>,
                option: ChangeEvent<HTMLSelectElement>['target']
              ) => onChange(option.value);

              const label = FUND_DIALOG_FORM_FUND_CURRENCY;

              return <CurrencyList label={label} formCurrency={value} handleCurrencyChange={handleOnChange} />;
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return {
    formState,
    FundForm,
  };
};

export default useFundForm;
