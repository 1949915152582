import uuid from 'react-uuid';
import getCroppedImg from 'components/ImageContainer/utilities/getCroppedImg';

const useImageUpload = () => {
  const getCroppedImage = async (imageSrc, croppedAreaPixels) => {
    try {
      return await getCroppedImg(uuid(), imageSrc, croppedAreaPixels);
    } catch (e) {
      return null;
    }
  };

  return { getCroppedImage };
};

export default useImageUpload;
