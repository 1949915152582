import { ValuationApproachFutureExit } from 'api';
import { LTM_REVENUE, SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/futureExit/';
import { getObjectValue, getStringValue, shortDate } from 'utillities';
import { CreateColumns, FutureEquityColumnCellData } from './types';
import { getApproachFromColumn } from '../utils/getApproachFromColumn';
import getFinancialMetricValue from '../utils/getFinancialMetricValue';

const {
  FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE,
  FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE,
  FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH,
  FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE,
  FE_FUTURE_EQUITY_SPREADSHEET_ENTERPRISE_VALUE,
  FE_FUTURE_EQUITY_SPREADSHEET_CASH,
  FE_FUTURE_EQUITY_SPREADSHEET_DEBT,
  FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_EQUITY_VALUE,
  FE_FUTURE_EQUITY_SPREADSHEET_DILUTION_FUTURE_INVESTMENTS,
  FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS,
} = SHEET_ALIASES_CONSTANTS;

const createColumns: CreateColumns = params => {
  const { valuationsApproachFutureExitApproach, financials, approaches } = params;
  const {
    exit_date: exitDate,
    financials_metric: financialsMetric,
    financials_metric_value: financialsMetricValue,
    referenced_approach_multiple_value: referencedApproachMultipleValue,
    plus_cash: plusCash,
    less_debt: lessDebt,
    dilution_percentage: dilutionPercentage,
  } = getObjectValue(valuationsApproachFutureExitApproach);

  const exitDateValue = getStringValue(shortDate(exitDate));
  const { metricValue } = getFinancialMetricValue({
    financials,
    financialsMetric: financialsMetric ?? LTM_REVENUE,
    financialsMetricValue,
  });

  const valuationApproachColumn = valuationsApproachFutureExitApproach ?? ({} as ValuationApproachFutureExit);
  const valuationApproachValue = getApproachFromColumn(valuationApproachColumn, approaches ?? [])?.id;

  // Future Equity Value
  const futureEquityValue = {
    [FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE]: {
      value: exitDateValue,
    } as FutureEquityColumnCellData,
    [FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE]: {
      value: metricValue,
    } as FutureEquityColumnCellData,
    [FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH]: {
      value: valuationApproachValue,
    } as FutureEquityColumnCellData,
    [FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE]: {
      value: referencedApproachMultipleValue,
    } as FutureEquityColumnCellData,
    [FE_FUTURE_EQUITY_SPREADSHEET_ENTERPRISE_VALUE]: {
      value: null,
    } as FutureEquityColumnCellData,
    [FE_FUTURE_EQUITY_SPREADSHEET_CASH]: {
      value: plusCash,
    } as FutureEquityColumnCellData,
    [FE_FUTURE_EQUITY_SPREADSHEET_DEBT]: {
      value: lessDebt,
    } as FutureEquityColumnCellData,
    [FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_EQUITY_VALUE]: {
      value: null,
    } as FutureEquityColumnCellData,
    [FE_FUTURE_EQUITY_SPREADSHEET_DILUTION_FUTURE_INVESTMENTS]: {
      value: dilutionPercentage,
    } as FutureEquityColumnCellData,
    [FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS]: {
      value: null,
    } as FutureEquityColumnCellData,
  };

  return [futureEquityValue];
};

export default createColumns;
