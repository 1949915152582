import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const history = useHistory();
  const { search } = useLocation();

  const getQuery = useMemo(() => new URLSearchParams(search), [search]);
  const setQuery = useCallback(
    (name: string, value: string) =>
      history.push({
        search: `?${new URLSearchParams({ [name]: value })}`,
      }),
    [history]
  );

  const clearQuery = useCallback(
    () =>
      history.push({
        search: '',
      }),
    [history]
  );

  return {
    getQuery,
    setQuery,
    clearQuery,
  };
};

export default useQueryParams;
