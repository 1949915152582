/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import { makeStyles } from '@material-ui/styles';
import { debounce, isEmpty, isNull, isUndefined } from 'lodash';
import { useParams } from 'react-router-dom';
import validate from 'validate.js';
import { authAction, globalAction } from 'common/actions';
import { ERROR_403 } from 'common/config/api';
import { DEBOUNCE_DELAY } from 'common/constants/general';
import * as messages from 'common/constants/messages/validations';
import {
  DO_YOU_WANT_RESET_PASSWORD,
  SEND_PASSWORD_RESET_EMAIL,
  USER_ACCESS_DENIED,
  USER_PROFILE_ERROR,
  USER_PROFILE_INFO_UPDATE,
} from 'common/constants/user';
import { userSchemaBase } from 'common/constants/userSchemaBase';
import { useStore } from 'common/store';
import { MessageBox } from 'components';
import CircleIconWithText from 'components/CircleIconWithText';
import { LayoutContext } from 'context';
import { ImageUploadService } from 'services';
import { useImageUpload, useResponse, UsersHk } from 'services/hooks';
import { useSendPasswordReset } from 'services/hooks/auth';
import { useGetUserDetails } from 'services/hooks/useUserSelected';
import UserFormSkeleton from './components/UserFormSkeleton';
import ImageContainer from '../../components/ImageContainer/ImageContainer';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    padding: 20,
    border: `0.1em solid ${theme.palette.primary.main}`,
    cursor: 'pointer',
    borderRadius: '0.2em',
    fontFamily: theme.typography.secondaryFont,
  },
  emailIconStyle: {
    fontSize: '2rem',
  },
  textField: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.primary[700],
      fontSize: '1rem',
    },
  },
}));

const schema = userSchemaBase;

const UserProfile = () => {
  const { userIdParam } = useParams();
  const imageService = new ImageUploadService();
  const { useUpdateUserProfile } = UsersHk;
  const sendPasswordReset = useSendPasswordReset();
  const updateUserProfile = useUpdateUserProfile();
  const [{ user }, dispatch] = useStore();
  const { data: userSelected, refetch: refetchUserDetails } = useGetUserDetails(userIdParam || user?.id);
  const { setPageTitle, setPageActions, setPageBreadcrumbs } = useContext(LayoutContext);
  const classes = useStyles();
  const [userInfo, setUserInfo] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageSrc, setImageSrc] = useState('');
  const [originalImageSrc, setOriginalImageSrc] = useState('');
  const [saveButtonHasClicked, setSaveButtonHasClicked] = useState(false);
  const [imageHasBeenUploaded, setImageHasBeenUploaded] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      profile: {
        id: '',
        picture_url: '',
      },
    },
    touched: {},
    errors: {},
  });
  const latestPixelCrop = useRef({});
  const { successNotification, errorNotification } = useResponse();
  const { getCroppedImage } = useImageUpload({ imageSrc, croppedAreaPixels });

  const handleResetPassword = async event => {
    event.preventDefault();

    if (formState.values.email) {
      const response = await sendPasswordReset(formState.values.email);
      if (isNull(response)) {
        // status code 204 => null body
        successNotification(messages.PASSWORD_CHANGE_SEND_MAIL);
      } else {
        errorNotification(messages.ERROR_OCCURRED);
      }
    }
  };

  // Update ref for use in getCroppedImage
  useEffect(() => {
    if (croppedAreaPixels) {
      latestPixelCrop.current = croppedAreaPixels;
    }
  }, [croppedAreaPixels]);

  const saveUserProfileData = async () => {
    if (formState.isValid) {
      const [response, error] = await updateUserProfile(userInfo.id, formState.values);
      if (error) {
        if (error.status === 400) {
          errorNotification(messages.ERROR_OCCURRED);
          setFormState({
            ...formState,
            errors: JSON.parse(error.response.text),
            isValid: false,
          });
        }
      } else {
        if (!userIdParam || (userIdParam && user && user.id === parseInt(userIdParam, 0))) {
          dispatch(authAction.setUserInfo(response));
        }
        setOriginalImageSrc(imageSrc);
        refetchUserDetails();
        successNotification(USER_PROFILE_INFO_UPDATE);
      }
    }
    setSaveButtonHasClicked(false);
    setImageHasBeenUploaded(false);
  };

  const uploadImageProfile = async () => {
    dispatch(globalAction.showLoadingProgress(true));
    // Leverage a ref to always get the latest value of the state
    const file = await getCroppedImage(imageSrc, latestPixelCrop.current);
    imageService
      .uploadImage(file)
      .then(response => {
        setFormState({
          ...formState,
          values: {
            ...formState.values,
            profile: {
              ...formState.values.profile,
              picture_url: response.location,
            },
          },
        });
        setImageHasBeenUploaded(true);
      })
      .catch(error => {
        errorNotification(error);
        setImageHasBeenUploaded(false);
      })
      .finally(() => {
        dispatch(globalAction.showLoadingProgress(false));
        setSaveButtonHasClicked(false);
      });
  };

  const handleSaveButton = () => {
    setSaveButtonHasClicked(true);
    if (formState.isValid) {
      if (imageSrc && imageSrc !== originalImageSrc) {
        uploadImageProfile();
      } else {
        saveUserProfileData();
      }
    }
  };

  const onInputChange = debounce(() => {
    setPageActions({
      mainAction: {
        id: 'user-profile',
        label: 'Save',
        isActive: !formState.isValid,
        callback: handleSaveButton,
      },
    });
  }, DEBOUNCE_DELAY);

  useEffect(() => {
    onInputChange();
  }, [formState]);

  useEffect(() => {
    setPageActions({
      mainAction: {
        id: 'user-picture',
        label: 'Save',
        isActive: !formState.isValid,
        callback: handleSaveButton,
      },
    });

    return () => {
      setPageActions(null);
    };
  }, [selectedFile, imageSrc, croppedAreaPixels]);

  const breadcrumbs = useMemo(() => {
    if (userInfo && userInfo !== ERROR_403) {
      return [
        {
          title: 'Account Settings',
        },
        {
          title: userInfo.email,
        },
      ];
    }

    return [];
  }, [userInfo]);

  useEffect(() => {
    setPageTitle('Account Settings');
    setPageBreadcrumbs(breadcrumbs);

    return () => {
      setPageTitle(null);
      setPageBreadcrumbs(null);
    };
  }, [breadcrumbs, setPageTitle, setPageBreadcrumbs]);

  useEffect(() => {
    if (userInfo && userInfo !== ERROR_403) {
      const defaultImageUrl = userInfo.profile?.picture_url || '';
      setFormState(formStateData => ({
        ...formStateData,
        values: {
          username: userInfo.username || '',
          first_name: userInfo.first_name || '',
          last_name: userInfo.last_name || '',
          email: userInfo.email || '',
          profile: {
            id: userInfo.profile?.id || '',
            picture_url: defaultImageUrl,
          },
        },
      }));
      setImageSrc(defaultImageUrl);
      setOriginalImageSrc(defaultImageUrl);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userSelected) {
      setUserInfo(userSelected);
    }
  }, [userSelected]);

  const getErrors = () => validate(formState.values, schema);

  const isValidForm = () => {
    const errors = getErrors();
    return isUndefined(errors) || isEmpty(errors);
  };

  useEffect(() => {
    if (saveButtonHasClicked && imageHasBeenUploaded && formState.values.profile.picture_url !== originalImageSrc) {
      saveUserProfileData();
    }
  }, [formState.values.profile.picture_url, imageHasBeenUploaded]);

  useEffect(() => {
    setFormState(formStateData => ({
      ...formStateData,
      values: {
        ...formStateData.values,
        profile: {
          ...formStateData.values.profile,
          picture_url: imageSrc,
        },
      },
      isValid: isValidForm(),
    }));
  }, [imageSrc]);

  const handleChange = event => {
    event.persist();
    const key = event.target.name;
    const { value } = event.target;
    const errors = getErrors();

    setFormState(formStateData => ({
      ...formStateData,
      values: {
        ...formStateData.values,
        [key]: value,
      },
      touched: {
        ...formStateData.touched,
        [event.target.name]: true,
      },
      isValid: isValidForm(),
      errors: errors || {},
    }));
  };

  const hasError = field => formState.touched[field] && formState.errors[field]?.length > 0;

  if (isNull(userInfo)) {
    return <UserFormSkeleton />;
  }

  if (userInfo === ERROR_403) {
    return <MessageBox title={USER_ACCESS_DENIED} fullWidth={false} />;
  }

  if (isUndefined(userInfo)) {
    return <MessageBox title={USER_PROFILE_ERROR} fullWidth={false} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={6} sm={12}>
        <Typography variant="h6">Personal Data</Typography>
        <br />
        <Grid container spacing={4}>
          <Grid item md={6}>
            <TextField
              className={classes.textField}
              error={hasError('first_name')}
              fullWidth
              helperText={hasError('first_name') ? formState.errors.first_name[0] : null}
              label="First name"
              name="first_name"
              variant="outlined"
              onChange={handleChange}
              type="text"
              value={formState.values.first_name}
              inputProps={{ 'aria-label': 'first_name' }}
              id="first_name"
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              className={classes.textField}
              error={hasError('last_name')}
              fullWidth
              helperText={hasError('last_name') ? formState.errors.last_name[0] : null}
              label="Last name"
              name="last_name"
              variant="outlined"
              onChange={handleChange}
              type="text"
              value={formState.values.last_name}
              inputProps={{ 'aria-label': 'last_name' }}
              id="last_name"
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid container spacing={2}>
          <Grid item md={6}>
            <TextField
              className={classes.textField}
              error={hasError('email')}
              fullWidth
              helperText={hasError('email') ? formState.errors.email[0] : null}
              label="Email"
              name="email"
              variant="outlined"
              onChange={handleChange}
              type="text"
              value={formState.values.email}
              inputProps={{ 'aria-label': 'email' }}
              id="email"
            />
          </Grid>
        </Grid>
        <br />
        <Typography variant="h6">Reset Password</Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item md={12}>
            <div className={classes.container}>
              <CircleIconWithText title={DO_YOU_WANT_RESET_PASSWORD} subTitle="">
                <EmailIcon className={classes.emailIconStyle} />
              </CircleIconWithText>
              <Button onClick={handleResetPassword} variant="outlined" color="primary">
                {SEND_PASSWORD_RESET_EMAIL}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} sm={12}>
        <ImageContainer
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          setSelectedFile={setSelectedFile}
          setCroppedAreaPixels={setCroppedAreaPixels}
        />
      </Grid>
    </Grid>
  );
};

export default UserProfile;
