import { MATURITY_ALIAS, RISKFREERATE_ALIAS, VOLATILITY_ALIAS } from 'common/constants/allocations';
import { oneDecimalPercentFormat, yearsDecimalFormat } from 'common/formats/formats';

export default [
  {
    value: 'Volatility',
    alias: VOLATILITY_ALIAS,
    width: '175px',
    format: oneDecimalPercentFormat,
    greaterThan: 0,
    gridType: 'percentage',
    isRequired: true,
    readOnly: false,
    dbDecimalPlaces: 4,
  },
  {
    value: 'Maturity (Years)',
    alias: MATURITY_ALIAS,
    width: '175px',
    format: yearsDecimalFormat,
    greaterThan: 0,
    isRequired: true,
    gridType: 'number',
    readOnly: false,
  },
  {
    value: 'Risk Free Rate',
    alias: RISKFREERATE_ALIAS,
    width: '175px',
    gridType: 'number',
    readOnly: true,
    format: { ...oneDecimalPercentFormat, isDecimal: true },
  },
];
