import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from 'components/AllocationStatusBadge/styles';

const ReadyForAuditBadge = () => {
  const classes = useStyles();
  return (
    <Grid className={clsx(classes.badge, 'ready-for-audit')} component="span" data-testid="ready-for-audit-badge">
      <Typography className={clsx(classes.title, 'ready-for-audit')} component="span" paragraph>
        READY FOR AUDIT
      </Typography>
    </Grid>
  );
};
export default ReadyForAuditBadge;
