import React from 'react';
import { SelectValueViewer } from 'components';
import { SHEET_TITLES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { getStringValue } from 'utillities';
import { HandleAllocationMethodColumnParams } from './types';

const { FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER, getValuationSummaryHeader } = SHEET_TITLES_CONSTANTS;

const handleAllocationMethodColumn = (params: HandleAllocationMethodColumnParams) => {
  const { adjustedColumnNumber, allocationMethodsOptions, cell, colNumber, financialsCurrency, isUniformCurrency }
    = params;

  const isScenarioColumn = FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER <= adjustedColumnNumber;

  if (isScenarioColumn) {
    cell.forceComponent = true;
    cell.gridType = 'string';
    cell.isRequired = true;
    cell.readOnly = true;
    cell.valueViewer = props => (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: SelectValueViewer is not typed
      <SelectValueViewer {...props} options={allocationMethodsOptions} />
    );
  } else {
    cell.value = getStringValue(
      getValuationSummaryHeader({
        colNumber,
        financialsCurrency,
        isUniformCurrency,
      })?.title
    );
  }
};

export default handleAllocationMethodColumn;
