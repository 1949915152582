import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { ALLOCATIONS_FINAL } from 'common/constants/allocations';
import {
  MAKE_ALLOCATION_FINAL_DEFAULT_ERROR_MESSAGE,
  MAKE_ALLOCATION_FINAL_DEFAULT_SUCCESS_MESSAGE,
  MAKE_ALLOCATIONS_FINAL_DEFAULT_ERROR_MESSAGE,
  MAKE_ALLOCATIONS_FINAL_DEFAULT_SUCCESS_MESSAGE,
} from 'common/constants/services/allocations';
import { FUND_ALLOCATIONS_BY_MD_QUERY_KEY } from 'common/constants/services/funds';
import { AllocationService } from 'services';
import { throwError } from 'utillities';
import { MakeAllocationsFinalParams, MakeAllocationsFinalResponse } from './types';

const makeAllocationsFinal = async (params: MakeAllocationsFinalParams) => {
  const { allocationsIds } = params;

  const areMultiple = allocationsIds && allocationsIds?.length > 1;

  if (isEmpty(allocationsIds))
    return throwError(
      areMultiple ? MAKE_ALLOCATIONS_FINAL_DEFAULT_ERROR_MESSAGE : MAKE_ALLOCATION_FINAL_DEFAULT_ERROR_MESSAGE
    );

  const allocationService = new AllocationService();

  try {
    const finalAllocations = await allocationService.makeFinal(allocationsIds?.join(','));

    return {
      ...finalAllocations,
      allocationsIds,
      allocationsStatuses: ALLOCATIONS_FINAL,
      areMultiple,
      responseMessage: areMultiple
        ? MAKE_ALLOCATIONS_FINAL_DEFAULT_SUCCESS_MESSAGE
        : MAKE_ALLOCATION_FINAL_DEFAULT_SUCCESS_MESSAGE,
      updated: true,
    } as MakeAllocationsFinalResponse;
  } catch (error) {
    return throwError(
      areMultiple ? MAKE_ALLOCATIONS_FINAL_DEFAULT_ERROR_MESSAGE : MAKE_ALLOCATION_FINAL_DEFAULT_ERROR_MESSAGE
    );
  }
};

const useMakeAllocationsFinal = () => {
  const queryClient = useQueryClient();

  const makeAllocationsFinalMutation = useMutation<MakeAllocationsFinalResponse, Error, MakeAllocationsFinalParams>({
    mutationFn: params => makeAllocationsFinal(params),
  });

  const { isSuccess, mutateAsync } = makeAllocationsFinalMutation;

  if (isSuccess) {
    // Invalidate the Fund Allocations by MD query to refetch the data with the updated allocations status
    queryClient.invalidateQueries({ queryKey: [FUND_ALLOCATIONS_BY_MD_QUERY_KEY] });

    // Clean the mutation internal state
    makeAllocationsFinalMutation.reset();
  }

  return {
    mutateAsync,
  };
};

export default useMakeAllocationsFinal;
