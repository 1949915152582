import { useCallback, useMemo, useState } from 'react';
import FileType from 'file-type/browser';
import { isEmpty, isUndefined } from 'lodash';
import { useParams } from 'react-router-dom';
import { globalAction } from 'common/actions';
import { ERROR_403 } from 'common/config/api';
import { EXTERNAL_VALUATION_REFERENCE_TITLE } from 'common/constants/documents';
import * as messages from 'common/constants/messages/validations';
import { OTHER } from 'common/constants/process-management';
import { useStore } from 'common/store';
import validFileTypes from 'components/Documents/validFileTypes';
import FilesService from 'services/documents';
import useResponse from './useResponse';
import validMimeTypeMN from '../../components/Documents/validMIMETypes';

export const useDocuments = selectedMeasurementDate => {
  const filesService = useMemo(() => new FilesService(), []);
  const [companyDocuments, setCompanyDocuments] = useState();
  const [currentDocuments, setCurrentDocuments] = useState();
  const [featuresObjects, setFeaturesObjects] = useState();
  const [filesToSave, setFilesToSave] = useState([]);
  const [progress, setProgress] = useState();
  const [isValidType, setIsValidType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [{ companyInfo }] = useStore();
  const { errorNotification, successNotification, processErrorResponse } = useResponse();
  const { id: company_id } = companyInfo;
  const [{ user }, dispatch] = useStore();
  const { requestId, hmacToken } = useParams();

  const getPreSignedUrl = async (selectedMD, fileExtension) => {
    try {
      return await filesService.getPreSignedUrl(selectedMD, fileExtension);
    } catch (error) {
      return error;
    }
  };

  const createFileRepresentation = async (id, data, fileName) => {
    try {
      const response = await filesService.createFileRepresentation(id, data);
      successNotification(messages.DOCUMENT_UPLOAD_SUCCESS(fileName));
      return response;
    } catch (error) {
      errorNotification(messages.DOCUMENT_UPLOAD_ERROR(fileName));
      return error;
    }
  };

  const getDocuments = async (mdId, companyId) => {
    try {
      setCompanyDocuments();
      setIsLoading(true);
      const response = await filesService.getCompanyDocuments(company_id || companyId);

      if (response.company_documents) {
        const foundCurrentDocuments = response.company_documents?.find(
          d => d.measurement_date.id === mdId || selectedMeasurementDate
        );
        setCurrentDocuments(foundCurrentDocuments);
      }
      // Is array files empty in response.company_documents?
      const measurementDateWithFiles = response.company_documents.filter(md => md.files.length > 0);
      if (isEmpty(measurementDateWithFiles)) {
        setCompanyDocuments([]);
      } else {
        setCompanyDocuments(response.company_documents);
      }
      setIsLoading(false);
      return response.company_documents;
    } catch (error) {
      setCompanyDocuments([]);
    }
  };

  const uploadFile = async (url, file) => {
    const otherParams = {
      method: 'PUT',
      body: file,
    };

    try {
      return await fetch(url, otherParams).then(res => res);
    } catch (error) {
      errorNotification(messages.DOCUMENT_UPLOAD_ERROR(file.name));
      return error;
    }
  };

  const deleteFile = async (documentsId, file) => {
    try {
      const response = await filesService.deleteDocument(documentsId, file.id);
      successNotification(messages.DOCUMENT_DELETE_SUCCESS(`${file.filename}.${file.file_type}`));
      return response;
    } catch (error) {
      errorNotification(messages.DOCUMENT_DELETE_ERROR(`${file.filename}.${file.file_type}`));
      return error;
    }
  };

  const updateFile = async (documentsId, fileId, data, showMsg) => {
    try {
      const response = await filesService.updateDocument(documentsId, fileId, data);
      if (showMsg) {
        successNotification(messages.DOCUMENT_UPDATED_SUCCESS(`${data.filename}`));
      }
      return response;
    } catch (error) {
      if (showMsg) {
        errorNotification(messages.DOCUMENT_UPDATED_ERROR(`${data.filename}`));
      }
      return error;
    }
  };

  const downloadDocument = async fileData => {
    const FILE_NAME = `${fileData.file.filename}.${fileData.file.file_type}`;
    try {
      const response = await filesService.downloadDocument(fileData.file.id);
      if (response?.signed_url) {
        window.location.href = response.signed_url;
      }
      successNotification(messages.DOCUMENT_DOWNLOAD_SUCCESS(FILE_NAME));
      return true;
    } catch (error) {
      errorNotification(messages.DOCUMENT_DOWNLOAD_ERROR(FILE_NAME));
      return false;
    }
  };

  const getReferencesByMeasurementDateId = async measurementDateId => {
    try {
      return await filesService.getReferencesByMeasurementDateId(measurementDateId);
    } catch (error) {
      return error;
    }
  };

  const getFeaturesObjects = async () => {
    try {
      setIsLoading(true);
      const response = await filesService.getFeaturesObjects(selectedMeasurementDate.id);
      if (response) {
        setFeaturesObjects(response);
      }
      setIsLoading(false);
    } catch (error) {
      setFeaturesObjects(null);
    }
  };

  const addDocumentReference = async (mdId, fileId, data) => {
    try {
      const response = await filesService.addDocumentReference(mdId, fileId, data);
      successNotification(messages.MOVE_FILE_SUCCESS);
      return response;
    } catch (error) {
      const fileAlreadyExists = error.response.text.includes('already exists');
      errorNotification(fileAlreadyExists ? messages.FILE_ALREADY_EXISTS : messages.MOVE_FILE_ERROR);
      return error;
    }
  };

  const addDocumentReferencesObject = useCallback(
    async (mdId, data, showMsg) => {
      try {
        const response = await filesService.addDocumentReferencesObject(mdId, data);
        if (showMsg) {
          successNotification(messages.DOCUMENT_UPDATED_SUCCESS(`${data.filename}`));
        }
        return response;
      } catch (error) {
        if (showMsg) {
          errorNotification(messages.DOCUMENT_UPDATED_ERROR(`${data.filename}`));
        }
        return error;
      }
    },
    [errorNotification, filesService, successNotification]
  );

  const foundReferencedDocuments = (docs, currentPage, referencedFeatureId) => {
    const documentsWithReference = docs ? docs.filter(document => document.referenced_in.length > 0) : [];
    const referencedDocuments = documentsWithReference.map(document => document.referenced_in).flat();
    let referencedInCurrentPage = [];

    if (currentPage === EXTERNAL_VALUATION_REFERENCE_TITLE) {
      referencedInCurrentPage = referencedDocuments.filter(
        document => document.reference_object_id === referencedFeatureId
      );
    } else {
      referencedInCurrentPage = referencedDocuments.filter(document => document.reference_object_type === currentPage);
    }
    const referencedIds = referencedInCurrentPage.map(data => data.document_reference);

    return docs.filter(document => referencedIds.includes(document.document_reference_id));
  };

  // Update documents references from widget
  const getReferenceCreateList = (selectedValues, referencedFeatureId, referenceType) =>
    selectedValues.map(value => ({
      reference_object_id: referencedFeatureId,
      reference_object_type: referenceType,
      document_reference_id: value.id || value.document_reference_id,
    }));

  const getReferencesData = useCallback(
    (selectedValues, referencedFeatureId, referenceType) => ({
      create_list: getReferenceCreateList(selectedValues, referencedFeatureId, referenceType),
      delete_list: [],
    }),
    []
  );

  const addReferenceForExistingDocuments = useCallback(
    async (selectedMeasurementDateParam, selectedValues, referencedFeatureId, referenceType) => {
      try {
        const updatedReferenceData = getReferencesData(selectedValues, referencedFeatureId, referenceType);
        const requestData = {
          ...updatedReferenceData,
          measurement_date_id: selectedMeasurementDateParam,
        };
        await addDocumentReferencesObject(selectedMeasurementDateParam, requestData);
        setFilesToSave([]);
        successNotification(messages.REFERENCE_ADDED_SUCCESS);
      } catch (error) {
        errorNotification(messages.REFERENCE_ADDED_ERROR);
      }
    },
    [addDocumentReferencesObject, errorNotification, getReferencesData, successNotification]
  );

  const createSubFolder = async (docsId, data) => {
    try {
      const response = await filesService.createSubFolder(docsId, data);
      successNotification(messages.FOLDER_CREATED_SUCCESS(`${data.name}`));
      return response;
    } catch (error) {
      errorNotification(messages.FOLDER_CREATED_ERROR(`${data.name}`));
      return error;
    }
  };

  const updateSubFolder = async (docsId, folderId, data) => {
    try {
      const response = await filesService.updateSubFolder(docsId, folderId, data);
      successNotification(messages.FOLDER_UPDATED_SUCCESS(`${data.name}`));
      return response;
    } catch (error) {
      errorNotification(messages.FOLDER_UPDATED_ERROR(`${data.name}`));
      return error;
    }
  };

  const deleteFolder = async (docsId, folderId, folderName) => {
    try {
      const response = await filesService.deleteFolder(docsId, folderId, folderName);
      successNotification(messages.FOLDER_DELETED_SUCCESS(folderName));
      return response;
    } catch (error) {
      errorNotification(messages.FOLDER_DELETE_ERROR(folderName));
      return error;
    }
  };

  const addFilesToFolder = async (documentsId, data, showMsg) => {
    try {
      const response = await filesService.addFilesToFolder(documentsId, data);
      if (showMsg) {
        successNotification(messages.DOCUMENT_UPDATED_SUCCESS(`${data.filename}`));
      }
      return response;
    } catch (error) {
      if (showMsg) {
        errorNotification(messages.DOCUMENT_UPDATED_ERROR(`${data.filename}`));
      }
      return error;
    }
  };

  const uploadAndCreateRepresentation = async (fileProps, fileExtension) => {
    const {
      file,
      fromReferenceWidget,
      mdId,
      currentDocumentsId,
      setCompanyDocumentsParam,
      setIsLoadingParam,
      setIsFileUploaded,
      setFilesToSaveParam,
    } = fileProps;
    // If is valid, upload file to server
    setIsValidType(true);
    const preSignedUrl = await getPreSignedUrl(mdId, fileExtension);
    setProgress(25);
    // Upload file to S3
    const response = await uploadFile(preSignedUrl.url, file);
    setProgress(50);
    if (response.status === 200) {
      setProgress(85);
      // Create file representation
      const extractedFileType = preSignedUrl.key.split('.').pop();
      const takePathBeforeLastSlash = preSignedUrl.key.split('/').slice(0, -1).join('/');
      const extractedPath = preSignedUrl.key.split('/').pop();
      const extractKeyWithoutType = extractedPath.split('.').slice(0, -1).join('.');
      const extractFileNameWithoutType = file.name.split('.').slice(0, -1).join('.');

      const data = {
        guid: extractKeyWithoutType,
        folder_id: null,
        filename: extractFileNameWithoutType,
        file_path: takePathBeforeLastSlash,
        file_type: extractedFileType,
        md_documents_id: currentDocumentsId,
        uploaded_by: user.id,
      };
      const res = await createFileRepresentation(currentDocumentsId, data, file.name);

      const fileToSave = {
        document_reference_id: res.document_reference_id,
        filename: data.filename,
        file_type: data.file_type,
      };

      if (res.file === 'File created successfully') {
        setProgress(95);
        setIsLoadingParam(true);
        // Render documents
        if (setCompanyDocumentsParam) {
          setCompanyDocumentsParam(await getDocuments(company_id));
        }
        setProgress(100);
        setIsLoadingParam(false);
      } else {
        setIsValidType(false);
      }

      if (fromReferenceWidget) {
        setFilesToSaveParam(prevState => [...prevState, fileToSave]);
        setIsFileUploaded(true);
      }
    } else {
      setIsValidType(false);
    }
  };

  const validateAndUploadFile = async fileProps => {
    const { file } = fileProps;

    const fileExtension = file.name?.split('.').pop();
    // Verify magic numbers (byte pattern inside a file that is used to determine which
    // is the type of the file) for file type, to prevent uploading of malicious files.
    const mimeMN = await FileType.fromBlob(file).then(res => {
      // Added specific validation for csv files, because they don't have magic numbers,
      // they are plain text'
      if (['csv', 'txt'].includes(fileExtension) && isUndefined(res)) {
        return fileExtension;
      }
      if (res?.mime) {
        return res?.mime;
      }
      return null;
    });

    try {
      // Verify file type
      if (validFileTypes.includes(fileExtension.toLowerCase()) && validMimeTypeMN.includes(mimeMN)) {
        setProgress(10);
        await uploadAndCreateRepresentation(fileProps, fileExtension);
      } else {
        setIsValidType(false);
      }
    } catch (error) {
      setIsValidType(false);
      throw new Error(error);
    }
  };

  const sendDocumentRequest = async data => {
    try {
      dispatch(globalAction.showLoadingProgress(true));
      const response = await filesService.sendDocumentRequest(data);
      successNotification(messages.DOCUMENT_REQUEST_SENT_SUCCESS);
      dispatch(globalAction.showLoadingProgress(false));
      return response;
    } catch (error) {
      errorNotification(messages.DOCUMENT_REQUEST_SENT_ERROR);
      dispatch(globalAction.showLoadingProgress(false));
      return error;
    }
  };

  const validateDocumentRequestToken = async (requestId, token) => {
    try {
      return await filesService.validateDocumentRequestToken(requestId, token);
    } catch (error) {
      return error;
    }
  };

  const generatePostSignedUrlViaHMACToken = async (requestId, token, mdId, type) => {
    try {
      return await filesService.generatePostSignedUrlViaHMACToken(requestId, token, mdId, type);
    } catch (error) {
      return error;
    }
  };

  const updateRequestedFileUploadStatus = async (requestId, token, data) => {
    try {
      return await filesService.updateRequestedFileUploadStatus(requestId, token, data);
    } catch (error) {
      return error;
    }
  };

  const updateTaskStatusFromPortal = async (requestId, token, taskId, data) => {
    try {
      return await filesService.updateTaskStatusFromPortal(requestId, token, taskId, data);
    } catch (error) {
      return error;
    }
  };

  const uploadRequestedFiles = async uploadedFilesProps => {
    const { droppedFile, requestedFilesIds, task, setUploadedFiles } = uploadedFilesProps;
    const { task: taskData } = task;
    // check if one of the requestedFilesIds is OTHER
    const pureRequestedFilesId = requestedFilesIds.filter(id => id !== OTHER);
    const createOther = requestedFilesIds.length !== pureRequestedFilesId.length;

    try {
      setIsUploading(true);
      setProgress(10);
      const fileType = droppedFile?.name.split('.').pop();
      // Generate Post Signed Url Via HMAC Token
      const response = await generatePostSignedUrlViaHMACToken(
        requestId,
        hmacToken,
        taskData.measurement_date.id,
        fileType
      );
      setProgress(40);
      if (response.key) {
        // Prepare data for upload
        const res = await uploadFile(response.url, droppedFile);
        setProgress(80);
        // Create file representation
        if (res.status === 200) {
          const guid = response.key.split('/').pop().split('.').shift();
          const file_path = response.key.replace(`/${guid}.${fileType}`, '');
          const postData = {
            md_documents_id: taskData.measurement_date_documents_id,
            guid,
            filename: droppedFile?.name.split('.').shift(),
            file_type: fileType,
            file_path,
            requested_files: pureRequestedFilesId,
            create_other: createOther,
          };
          const updatedFile = await updateRequestedFileUploadStatus(requestId, hmacToken, postData);
          if (updatedFile.requested_file.length > 0) {
            setProgress(100);
            setUploadedFiles(prevState => [...prevState, droppedFile]);
            successNotification(messages.DOCUMENT_UPLOAD_SUCCESS(droppedFile.name));
          } else {
            errorNotification(messages.DOCUMENT_UPLOAD_ERROR(droppedFile.name));
          }
        }
      }
    } catch (error) {
      errorNotification(messages.DOCUMENT_UPLOAD_ERROR(droppedFile.name));
    }
    setIsUploading(false);
  };

  const hasDocuments = async (companyId, measurementDateId) => {
    dispatch(globalAction.showLoadingProgress(true));

    try {
      const response = await filesService.hasDocuments(companyId, measurementDateId);
      return response;
    } catch (error) {
      processErrorResponse({
        error,
        config: {
          [ERROR_403]: {
            notification: false,
          },
        },
        action: 'has documents',
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return {
    getPreSignedUrl,
    createFileRepresentation,
    getDocuments,
    uploadFile,
    deleteFile,
    updateFile,
    downloadDocument,
    getReferencesByMeasurementDateId,
    isLoading,
    setIsLoading,
    companyInfo,
    company_id,
    companyDocuments,
    setCompanyDocuments,
    getFeaturesObjects,
    featuresObjects,
    addDocumentReferencesObject,
    foundReferencedDocuments,
    addReferenceForExistingDocuments,
    createSubFolder,
    updateSubFolder,
    deleteFolder,
    addFilesToFolder,
    currentDocuments,
    setCurrentDocuments,
    filesToSave,
    setFilesToSave,
    validateAndUploadFile,
    progress,
    isValidType,
    addDocumentReference,
    sendDocumentRequest,
    validateDocumentRequestToken,
    generatePostSignedUrlViaHMACToken,
    updateRequestedFileUploadStatus,
    updateTaskStatusFromPortal,
    uploadRequestedFiles,
    isUploading,
    hasDocuments,
  };
};

export default useDocuments;
