import moment from 'moment';
import SUM_FROM_ACCRUED_LEDGER from './SUM_FROM_ACCRUED_LEDGER';

const ACCRUED_FROM_CN_LEDGER = (
  notesLedger,
  withCompounding,
  issuePrice,
  dividendRate,
  compoundingPeriods,
  measurementDate
) => {
  const notes = notesLedger ?? [];
  const values = notes.map(({ conversion_date, expected_shares }) => ({
    date: moment(conversion_date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
    shares: expected_shares,
  }));
  return SUM_FROM_ACCRUED_LEDGER(
    values,
    withCompounding,
    issuePrice,
    dividendRate,
    compoundingPeriods,
    measurementDate
  );
};

export default ACCRUED_FROM_CN_LEDGER;
