import { getEnterpriseValueFormula, getEquityValueFormula } from 'common/formulas/math.js/ENTERPRISE_AND_EQUITY_VALUE';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import {
  ADJUSTMENT_TYPE_ALIAS,
  ENTERPRISE_VALUE_OPTION,
  EQUITY_VALUE_OPTION,
  MARKET_ADJUSTMENT_CUSTOM_KEY,
} from './constants';
import { BACKSOLVE_TABLE_NAMES } from '../../constants';

const afterCellsChanged = ([change], cells, rowConfig, tableData, workbookCells) => {
  const changes = [change];
  if (change.cell.alias === ADJUSTMENT_TYPE_ALIAS) {
    const cash = 'summary.less_cash';
    const debt = 'summary.plus_debt';
    const adjustedEquityValueCell = cells.equity_value;
    const adjustedEnterpriseValueCell = cells.enterprise_value;
    const summaryTableName = getApproachTableName({
      approach: tableData.approach,
      tableSuffix: BACKSOLVE_TABLE_NAMES.BACKSOLVE_SUMMARY,
    });
    const backsolveSummaryCells = workbookCells[summaryTableName];
    const enterpriseValueCell = backsolveSummaryCells.summary_enterprise_value;
    const equityValueCell = backsolveSummaryCells.implied_equity_value;
    let adjustedEquityExpr;
    let adjustedEnterpriseExpr;
    if (change.value === EQUITY_VALUE_OPTION) {
      adjustedEquityExpr = `=${summaryTableName}.${equityValueCell.key} + ${summaryTableName}.${equityValueCell.key} * ${MARKET_ADJUSTMENT_CUSTOM_KEY}`;
      adjustedEnterpriseExpr = `=${getEnterpriseValueFormula(adjustedEquityValueCell.key, cash, debt)}`;
      // because these cells interplay with one another we need to delete dependency to avoid a stack overflow
      adjustedEnterpriseValueCell.dependencies.delete(adjustedEquityValueCell);
      changes.push({ cell: adjustedEquityValueCell, value: adjustedEquityExpr });
      changes.push({ cell: adjustedEnterpriseValueCell, value: adjustedEnterpriseExpr });
    } else if (change.value === ENTERPRISE_VALUE_OPTION) {
      adjustedEnterpriseExpr = `=${summaryTableName}.${enterpriseValueCell.key} + ${summaryTableName}.${enterpriseValueCell.key} * ${MARKET_ADJUSTMENT_CUSTOM_KEY}`;
      adjustedEquityExpr = `=${getEquityValueFormula(adjustedEnterpriseValueCell.key, cash, debt)}`;
      adjustedEquityValueCell.dependencies.delete(adjustedEnterpriseValueCell);
      changes.push({ cell: adjustedEnterpriseValueCell, value: adjustedEnterpriseExpr });
      changes.push({ cell: adjustedEquityValueCell, value: adjustedEquityExpr });
    }
  }
  return changes;
};

export default afterCellsChanged;
