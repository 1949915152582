import React, { FC, useContext } from 'react';
import { Check as CheckIcon } from '@material-ui/icons';
import { GridActionButton } from 'components/Spreadsheet/components';
import { LayoutContext } from 'context';
import { VALUATIONS_SUMMARY_MAKE_ALL_ALLOCATIONS_FINAL_LABEL } from 'pages/Funds/common/constants/valuationsSummary';
import useStyles from './styles';
import { MakeAllFinalButtonProps } from './types';

const MakeAllFinalButton: FC<MakeAllFinalButtonProps> = props => {
  const { isDisabled, onClick } = props;
  const { viewOnlyUser } = useContext(LayoutContext);
  const classes = useStyles();

  return (
    <GridActionButton
      disabled={isDisabled || viewOnlyUser}
      label={VALUATIONS_SUMMARY_MAKE_ALL_ALLOCATIONS_FINAL_LABEL}
      onClick={onClick}
      startIcon={!isDisabled && <CheckIcon className={classes.icon} />}
    />
  );
};

export default MakeAllFinalButton;
