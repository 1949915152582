import * as constants from 'common/constants/financials';
import { largeCurrencyFormat } from 'common/formats/formats';
import { baseRowConfig } from 'pages/Financials/data';

const rows = [
  constants.LIABILITIES_LONG_TERM_DEBT_ALIAS,
  constants.LIABILITIES_SHORT_TERM_DEBT_ALIAS,
  constants.CASH_AND_EQUIVALENTS_ALIAS,
];

const rowConfig = isDisabled => [
  ...baseRowConfig,
  {
    value: constants.CASH_AND_EQUIVALENTS_TITLE,
    alias: constants.CASH_AND_EQUIVALENTS_ALIAS,
    collapseGroups: rows,
    hidden: false,
    readOnly: isDisabled,
    hasSubRows: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.ASSETS_ACCOUNTS_RECEIVABLE_TITLE,
    alias: constants.ASSETS_ACCOUNTS_RECEIVABLE_ALIAS,
    parent: constants.CASH_AND_EQUIVALENTS_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: isDisabled,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.ASSETS_INVENTORY_TITLE,
    alias: constants.ASSETS_INVENTORY_ALIAS,
    parent: constants.CASH_AND_EQUIVALENTS_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: isDisabled,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.ASSETS_OTHER_CURRENT_TITLE,
    alias: constants.ASSETS_OTHER_CURRENT_ALIAS,
    parent: constants.CASH_AND_EQUIVALENTS_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: isDisabled,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.ASSETS_TOTAL_CURRENT_TITLE,
    alias: constants.ASSETS_TOTAL_CURRENT_ALIAS,
    parent: constants.CASH_AND_EQUIVALENTS_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: true,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-bold',
    validExpr: constants.ASSETS_TOTAL_CURRENT_EXPR,
    allowEmptyValue: true,
  },
  {
    value: constants.ASSETS_PPE_TITLE,
    alias: constants.ASSETS_PPE_ALIAS,
    parent: constants.CASH_AND_EQUIVALENTS_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: isDisabled,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.ASSETS_INTANGIBLES_TITLE,
    alias: constants.ASSETS_INTANGIBLES_ALIAS,
    parent: constants.CASH_AND_EQUIVALENTS_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: isDisabled,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.ASSETS_OTHER_LONG_TERM_TITLE,
    alias: constants.ASSETS_OTHER_LONG_TERM_ALIAS,
    parent: constants.CASH_AND_EQUIVALENTS_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: isDisabled,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.ASSETS_TOTAL_LONG_TERM_TITLE,
    alias: constants.ASSETS_TOTAL_LONG_TERM_ALIAS,
    parent: constants.CASH_AND_EQUIVALENTS_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: true,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-bold',
    validExpr: constants.ASSETS_TOTAL_LONG_TERM_EXPR,
    allowEmptyValue: true,
  },
  {
    value: constants.ASSETS_TOTAL_TITLE,
    alias: constants.ASSETS_TOTAL_ALIAS,
    parent: constants.CASH_AND_EQUIVALENTS_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: true,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-bold',
    validExpr: constants.ASSETS_EXPR,
    allowEmptyValue: true,
  },
  {
    value: constants.LIABILITIES_SHORT_TERM_DEBT_TITLE,
    alias: constants.LIABILITIES_SHORT_TERM_DEBT_ALIAS,
    collapseGroups: rows,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: isDisabled,
    hasSubRows: true,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.LIABILITIES_ACCOUNT_PAYABLE_TITLE,
    alias: constants.LIABILITIES_ACCOUNT_PAYABLE_ALIAS,
    parent: constants.LIABILITIES_SHORT_TERM_DEBT_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: isDisabled,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.LIABILITIES_ACCRUED_LIABILITIES_TITLE,
    alias: constants.LIABILITIES_ACCRUED_LIABILITIES_ALIAS,
    parent: constants.LIABILITIES_SHORT_TERM_DEBT_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: isDisabled,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.LIABILITIES_DEFERRED_REVENUE_TITLE,
    alias: constants.LIABILITIES_DEFERRED_REVENUE_ALIAS,
    parent: constants.LIABILITIES_SHORT_TERM_DEBT_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: isDisabled,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.LIABILITIES_OTHER_CURRENT_TITLE,
    alias: constants.LIABILITIES_OTHER_CURRENT_ALIAS,
    parent: constants.LIABILITIES_SHORT_TERM_DEBT_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: isDisabled,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.LIABILITIES_TOTAL_CURRENT_TITLE,
    alias: constants.LIABILITIES_TOTAL_CURRENT_ALIAS,
    parent: constants.LIABILITIES_SHORT_TERM_DEBT_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: true,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-bold',
    validExpr: constants.LIABILITIES_TOTAL_CURRENT_EXPR,
    allowEmptyValue: true,
  },
  {
    value: constants.LIABILITIES_LONG_TERM_DEBT_TITLE,
    alias: constants.LIABILITIES_LONG_TERM_DEBT_ALIAS,
    collapseGroups: rows,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: isDisabled,
    hasSubRows: true,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.LIABILITIES_OTHER_LONG_TERM_TITLE,
    alias: constants.LIABILITIES_OTHER_LONG_TERM_ALIAS,
    parent: constants.LIABILITIES_LONG_TERM_DEBT_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: isDisabled,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.LIABILITIES_TOTAL_LONG_TERM_TITLE,
    alias: constants.LIABILITIES_TOTAL_LONG_TERM_ALIAS,
    parent: constants.LIABILITIES_LONG_TERM_DEBT_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: true,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-bold',
    validExpr: constants.LIABILITIES_TOTAL_LONG_TERM_EXPR,
    allowEmptyValue: true,
  },
  {
    value: constants.LIABILITIES_TOTAL_TITLE,
    alias: constants.LIABILITIES_TOTAL_ALIAS,
    parent: constants.LIABILITIES_LONG_TERM_DEBT_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: true,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-bold',
    validExpr: constants.LIABILITIES_TOTAL_EXPR,
    allowEmptyValue: true,
  },
  {
    value: constants.EQUITY_TOTAL_TITLE,
    alias: constants.EQUITY_TOTAL_ALIAS,
    parent: constants.LIABILITIES_LONG_TERM_DEBT_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: isDisabled,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.TOTAL_LIABILITIES_AND_EQUITY_TITLE,
    alias: constants.TOTAL_LIABILITIES_AND_EQUITY_ALIAS,
    parent: constants.LIABILITIES_LONG_TERM_DEBT_ALIAS,
    format: largeCurrencyFormat,
    hidden: false,
    readOnly: true,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-bold',
    validExpr: constants.TOTAL_LIABILITIES_AND_EQUITY_EXPR,
    allowEmptyValue: true,
  },
];

export default rowConfig;
