import { createStyles, makeStyles } from '@material-ui/styles';
import { SIDEBAR_DESKTOP } from 'dashboard409a/components';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: '100vh',
      zIndex: 100,
      backgroundColor: theme.palette.white,

      '@media (min-width: 960px)': {
        paddingLeft: SIDEBAR_DESKTOP.width,
      },
    },
    main: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  })
);

export default useStyles;
