import {
  FE_ALLOCATION_METHOD_OPM_TABLE,
  FE_ALLOCATION_METHOD_OPM_TITLE,
  FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE,
  FE_ALLOCATION_METHOD_WATERFALL_CSE_TITLE,
  FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE,
  FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TITLE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TABLE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TITLE,
  FUTURE_EXIT_SPREADSHEET_COLUMN_NAME,
  FUTURE_EXIT_SPREADSHEET_COLUMN_NAME_PLURAL,
  MEAN_LOWERCASE,
  MEDIAN_LOWERCASE,
  SELECTED_MULTIPLE,
} from 'pages/ValuationsAllocation/common/constants/futureExit/futureExit';
import {
  VALUATIONS_APPROACH_GPC_PROPERTY,
  VALUATIONS_APPROACH_GPC_REFERENCE,
  VALUATIONS_APPROACH_GPT_PROPERTY,
  VALUATIONS_APPROACH_GPT_REFERENCE,
  VALUATIONS_PUBLIC_COMPANIES_APPROACH,
  VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH,
  VALUATIONS_SPECIFIED_LABEL,
  VALUATIONS_VALUATION_APPROACH_GPC,
  VALUATIONS_VALUATION_APPROACH_GPT,
} from '../../valuations';

export const MODIFIED_PRESENT_EQUITY_SPREADSHEET_TABLE_TERMS = Object.freeze({
  columnName: FUTURE_EXIT_SPREADSHEET_COLUMN_NAME,
  pluralColumnName: FUTURE_EXIT_SPREADSHEET_COLUMN_NAME_PLURAL,
  tableName: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TITLE,
  tableSlug: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TABLE,
});

export const FUTURE_EQUITY_SPREADSHEET_TABLE_TERMS = Object.freeze({
  columnName: FUTURE_EXIT_SPREADSHEET_COLUMN_NAME,
  pluralColumnName: FUTURE_EXIT_SPREADSHEET_COLUMN_NAME_PLURAL,
  tableName: FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TITLE,
  tableSlug: FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE,
});

export const FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES = Object.freeze({
  FUTURE_EQUITY_VALUE: 'future_equity_value',
  MODIFIED_PRESENT_EQUITY_VALUE: 'modified_present_equity_value',
  ALLOCATION_METHOD_VALUE_WATERFALL_CSE: 'allocation_method_value_waterfall_cse',
  ALLOCATION_METHOD_VALUE_OPM: 'allocation_method_value_opm',
});

export const ALLOCATION_METHOD_WATERFALL_CSE_TABLE_TERMS = Object.freeze({
  columnName: FUTURE_EXIT_SPREADSHEET_COLUMN_NAME,
  pluralColumnName: FUTURE_EXIT_SPREADSHEET_COLUMN_NAME_PLURAL,
  tableName: FE_ALLOCATION_METHOD_WATERFALL_CSE_TITLE,
  tableSlug: FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE,
});

export const ALLOCATION_METHOD_OPM_TABLE_TERMS = Object.freeze({
  columnName: FUTURE_EXIT_SPREADSHEET_COLUMN_NAME,
  pluralColumnName: FUTURE_EXIT_SPREADSHEET_COLUMN_NAME_PLURAL,
  tableName: FE_ALLOCATION_METHOD_OPM_TITLE,
  tableSlug: FE_ALLOCATION_METHOD_OPM_TABLE,
});

export const relatedApproachAliasMap = {
  [VALUATIONS_PUBLIC_COMPANIES_APPROACH]: {
    approachProperty: VALUATIONS_APPROACH_GPC_PROPERTY,
    name: VALUATIONS_VALUATION_APPROACH_GPC,
    referenceName: VALUATIONS_APPROACH_GPC_REFERENCE,
    opposite: VALUATIONS_VALUATION_APPROACH_GPT,
    referenceOpposite: VALUATIONS_APPROACH_GPT_REFERENCE,
  },
  [VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH]: {
    approachProperty: VALUATIONS_APPROACH_GPT_PROPERTY,
    name: VALUATIONS_VALUATION_APPROACH_GPT,
    referenceName: VALUATIONS_APPROACH_GPT_REFERENCE,
    opposite: VALUATIONS_VALUATION_APPROACH_GPC,
    referenceOpposite: VALUATIONS_APPROACH_GPC_REFERENCE,
  },
};

// Multiple options constants
export const PERCENTILE_75 = '75th Percentile';
export const PERCENTILE_25 = '25th Percentile';
export const SPECIFIED_LABEL = VALUATIONS_SPECIFIED_LABEL;
export const MEDIAN_LABEL = 'Median';
export const MEAN_LABEL = 'Mean';
export const OTHER_LABEL = 'Other';
export const SPECIFIED_ID = null;

export const multipleMap = {
  [SPECIFIED_LABEL]: SELECTED_MULTIPLE,
  [MEAN_LABEL]: MEAN_LOWERCASE,
  [MEDIAN_LABEL]: MEDIAN_LOWERCASE,
  [PERCENTILE_75]: '75th',
  [PERCENTILE_25]: '25th',
};
