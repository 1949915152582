import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    sectionContainer: {
      // Subsequent Sections
      '& + &': {
        paddingTop: theme.spacing(4),
      },
    },
  })
);

export default useStyles;
