import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ScalarDatasheet from 'components/ScalarDatasheet';
import { Cell, ValueEditor, ValueViewer } from 'components/Spreadsheet/components';
import { CompGroupsContext } from 'context';

const GpcTableComps = ({ onCellsChanged, transactionDataGrid }) => {
  const contextValue = React.useMemo(() => ({ onCellsChanged }), [onCellsChanged]);

  return (
    <CompGroupsContext.Provider value={contextValue}>
      <Grid item xs={12} style={{ marginTop: '2rem' }}>
        {transactionDataGrid && (
          <PerfectScrollbar>
            <ScalarDatasheet
              data={transactionDataGrid}
              className="comp-datasheet gpc-table-comps"
              onCellsChanged={onCellsChanged}
              dataEditor={ValueEditor}
              valueViewer={ValueViewer}
              cellRenderer={Cell}
            />
          </PerfectScrollbar>
        )}
      </Grid>
    </CompGroupsContext.Provider>
  );
};

GpcTableComps.propTypes = {
  onCellsChanged: PropTypes.func.isRequired,
  transactionDataGrid: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
};

export default GpcTableComps;
