import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormDialog from 'components/Dialogs/FormDialog';
import { FinancialsForm } from 'components/Forms';

const FinancialsVersionDialog = props => {
  const { open, onClose, onSave } = props;

  const [formState, setFormState] = useState();

  const tableTerms = {
    tableName: 'Version',
    tableSlug: 'financial-statement-version',
  };

  return (
    <FormDialog
      open={open}
      title="New Financial Statement Version"
      editMode={false}
      onClose={onClose}
      onSave={() => onSave(formState?.values)}
      isValid={formState?.isValid}>
      <FinancialsForm tableTerms={tableTerms} onFormChange={state => setFormState(state)} />
    </FormDialog>
  );
};

FinancialsVersionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default FinancialsVersionDialog;
