import { isEmpty } from 'lodash';
import { parseCellValue } from 'pages/ValuationsAllocation/util';
import { getNumberValue, getStringValue } from 'utillities';
import { HandleScenariosWeightsParams } from './types';

const handleScenariosWeights = (params: HandleScenariosWeightsParams) => {
  const { approach, cell, fieldAttributes, shouldSetFullWeight = false, scenario } = params;

  // Allocation Scenario
  const allocationScenarioWeight = scenario?.allocations_scenarios_weights?.find(
    weight => weight?.approach_uuid === approach?.panel_id
  );

  // Value
  const fieldDecimalPlaces = getNumberValue(fieldAttributes?.weight?.decimal_places);
  const updatedWeight = shouldSetFullWeight
    ? 1 // 100%
    : parseCellValue({
      cell,
      fieldDecimalPlaces,
    });

  const parsedUpdatedWeight = getStringValue((updatedWeight as string) ?? 0);

  // Handle Allocation Scenarios Weights and Approaches Scenarios Weights
  if (!isEmpty(allocationScenarioWeight)) {
    allocationScenarioWeight.weight = parsedUpdatedWeight;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: allocations_scenarios_weights is not readonly
    allocationScenarioWeight.equity_value = getStringValue(approach.equity_value ?? '0');
  }
};

export default handleScenariosWeights;
