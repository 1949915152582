import React, { useState } from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const FormInput = ({
  name,
  label,
  value,
  placeholder,
  handleInputChange,
  type = 'text',
  maxCharacters,
  setIsMaxCharactersError,
}) => {
  const [error, setError] = useState(false);

  const handleChange = e => {
    if (e.target.value.length > maxCharacters) {
      setError(true);
      setIsMaxCharactersError(true);
    } else {
      setError(false);
      setIsMaxCharactersError(false);
      handleInputChange(e);
    }
  };

  return (
    <Box mb={4}>
      <Typography variant="body1" gutterBottom style={{ fontWeight: 700 }}>
        {label}
      </Typography>
      <TextField
        name={name}
        variant="outlined"
        fullWidth
        value={value || ''}
        onChange={handleChange}
        placeholder={placeholder}
        type={type}
        multiline={type === 'textarea'}
        error={error}
        helperText={error ? `Max characters: ${maxCharacters}` : ''}
      />
    </Box>
  );
};

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  maxCharacters: PropTypes.number,
  setIsMaxCharactersError: PropTypes.func,
};

export default FormInput;
