import { KeyboardEvent, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { UseHotKeyParams } from './types';

const useHotKey = (params: UseHotKeyParams) => {
  // pressedKeys is a subset of 'ctrlKey', shiftKey, 'altKey', 'metaKey'
  const { callback, key, node = null, pressedKeys } = params;

  // Implement the callback ref pattern
  const callbackRef = useRef(callback);

  // Check if all the keys in pressedKeys are pressed
  const areKeysPressed = useCallback(
    (event: KeyboardEvent<HTMLElement>) => pressedKeys.every(pressedKey => event[pressedKey as keyof KeyboardEvent]),
    [pressedKeys]
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if (event.key === key && areKeysPressed(event)) {
        event.preventDefault();
        callbackRef.current(event);
      }
    },
    [areKeysPressed, key]
  ) as unknown as EventListener;

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    // Target is either the provided node or the document
    const targetNode = node ?? document;

    targetNode?.addEventListener('keydown', handleKeyPress);

    // Remove the event listener
    return () => targetNode?.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, node]);
};

export default useHotKey;
