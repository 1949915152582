import React, { FC, useMemo } from 'react';
import { FINANCIALS_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { useFormat } from 'common/hooks';
import { UseFormatValues } from 'common/types/format';
import { CustomScalarSpreadsheet } from 'components';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { getObjectValue, getStringValue } from 'utillities';
import { ModifiedPresentEquityProps } from './types';

const ModifiedPresentEquity: FC<ModifiedPresentEquityProps> = props => {
  const { isLoading, isSaving, onChange, spreadsheets, workbook } = props;
  const { presentValueSheet: presentValueSpreadsheet } = getObjectValue(spreadsheets);

  const [format, formatDispatch] = useFormat({
    page: FINANCIALS_CURRENCY_PAGE,
  }) as UseFormatValues;

  // Create Modified Present Equity Value Sheet props
  const presentValueSheetProps = useMemo(() => {
    if (presentValueSpreadsheet) {
      return {
        ...presentValueSpreadsheet,
        format,
        formatDispatch,
        key: getStringValue(presentValueSpreadsheet?.name),
        onChange,
        sheet: presentValueSpreadsheet,
        workbook,
      } as unknown as typeof ScalarSpreadsheet;
    }

    return null;
  }, [presentValueSpreadsheet, format, formatDispatch, onChange, workbook]);

  return <CustomScalarSpreadsheet {...presentValueSheetProps} isLoading={isLoading} isSaving={isSaving} />;
};

export default ModifiedPresentEquity;
