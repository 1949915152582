import React from 'react';
import { Button, Tooltip, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    maxWidth: '16.25rem',
    boxShadow: '0rem 0rem 1.25rem 0rem grey',
    padding: '15px',
  },
  popper: {
    zIndex: '1300 !important',
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  autoMarginRight: {
    marginRight: 'auto',
  },
  dangerButton: {
    color: theme.palette.pink,
  },
  disabledButton: {
    color: theme.palette.grey,
  },
}));

const DeleteButton = ({ deleteText, toolTipText, allowDelete, enableDeleteButton, handleDelete }) => {
  const classes = useStyles();
  const tooltipTitle = allowDelete ? '' : toolTipText;
  const htmlTooltipTitle = enableDeleteButton ? '' : tooltipTitle;

  return (
    <HtmlTooltip title={htmlTooltipTitle} placement="top">
      <Button
        id="delete-btn"
        className={clsx(
          enableDeleteButton && classes.dangerButton,
          !enableDeleteButton && classes.disabledButton,
          classes.autoMarginRight
        )}
        variant="text"
        color="inherit"
        {...(enableDeleteButton && { onClick: handleDelete })}>
        {deleteText}
      </Button>
    </HtmlTooltip>
  );
};

DeleteButton.propTypes = {
  deleteText: PropTypes.string,
  toolTipText: PropTypes.string,
  handleDelete: PropTypes.func,
  allowDelete: PropTypes.bool,
  enableDeleteButton: PropTypes.bool,
};

export default DeleteButton;
