export const AMOUNT_ALIAS = 'amount';
export const COST_BASIS_ALIAS = 'cost_basis';
export const DATE_ALIAS = 'date';
export const PROCEEDS_ALIAS = 'proceeds';
export const SHARES_ALIAS = 'shares';
export const SOLD_PERCENT_ALIAS = 'sold_percent';
export const PURCHASE_DATE_ALIAS = 'purchase_date';
export const SALE_DATE_ALIAS = 'sale_date';
export const CONVERSION_DATE_ALIAS = 'conversion_date';
export const DISTRIBUTION_DATE_ALIAS = 'distribution_date';
export const ACQUISITION_REF = 'acquisition_ref';

export const CURRENCY_CELLS = [AMOUNT_ALIAS, COST_BASIS_ALIAS, PROCEEDS_ALIAS];
