import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useStore } from 'common/store';
import { CustomSelect, withTooltip } from 'components';

const CaptableList = ({ cell, ...restProps }) => {
  const { onCommit, onRevert } = restProps;
  const [{ capTableList }] = useStore();

  const getOptions = useMemo(() => {
    const tmpOptions = [];

    if (!isEmpty(capTableList)) {
      capTableList.forEach(capTable => {
        tmpOptions.push({
          label: capTable.name,
          value: capTable.id,
        });
      });
    }

    return tmpOptions;
  }, [capTableList]);

  return (
    <CustomSelect
      id="captable-list"
      options={getOptions}
      value={cell.value?.id || ''}
      onCommit={onCommit}
      onRevert={onRevert}
    />
  );
};

CaptableList.propTypes = {
  cell: PropTypes.object,
};

export default withTooltip(CaptableList);
