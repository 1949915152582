export const TERMINAL_VALUE_OPTIONS = {
  PERPETUITY_GROWTH: 'Perpetuity Growth',
  H_MODEL: 'H-Model',
  REVENUE_MULTIPLE: 'Revenue Multiple',
  EBITDA_MULTIPLE: 'EBITDA Multiple',
  REVENUE_AND_EBITDA_MULTIPLE: 'Rev & EBITDA Multiple',
};

export const MULTIPLE_OPTIONS = {
  MEAN: 'Mean',
  MEDIAN: 'Median',
  TWENTY_FIFTH: '25th',
  SEVENTY_FIFTH: '75th',
  OTHER: 'Other',
};

export const TOTAL_CASH_FLOWS = 'total_cash_flows';
export const TERMINAL_VALUE = 'terminal_value';
export const WACC = 'wacc';
export const LONG_TERM_GROWTH_RATE = 'long_term_growth_rate';
export const DISCOUNT_FACTOR = 'discount_factor';
export const PV_OF_TERMINAL_VALUE = 'present_value_of_terminal_value';
export const YEARS_OF_HIGH_GROWTH = 'years_of_high_growth';
export const HIGH_GROWTH_RATE = 'high_growth_rate';
export const PERPETUITY_VALUE = 'perpetuity_value';
export const TERMINAL_YEAR_H_MODEL = 'terminal_year_h_model_value';
export const HALF_LIFE = 'half_life';
export const REVENUE_TOTAL = 'revenue_total';
export const EBITDA_TOTAL = 'ebitda_total';
export const METRIC_TOTAL = 'metric_total';
export const ESTIMATED_EXIT_VALUE = 'estimated_exit_value';
export const REVENUE_GPC_MULTIPLE_SELECTION = 'revenue_gpc_multiple_selection';
export const REVENUE_GPC_MULTIPLE = 'revenue_gpc_multiple';
export const EBITDA_GPC_MULTIPLE_SELECTION = 'ebitda_gpc_multiple_selection';
export const EBITDA_GPC_MULTIPLE = 'ebitda_gpc_multiple';
export const EBITDA_GPC_TITLE = 'ebitda_gpc_title';
export const GPC_MULTIPLE_SELECTION = 'gpc_multiple_selection';
export const GPC_MULTIPLE = 'gpc_multiple';

export const WACC_LABEL = 'Weighted Average Cost of Capital (WACC)';

export const VALUATION_APPROACH_DCF_TABLE_NAME = 'valuations_approach_dcf';
export const DCF_FINANCIAL_PERIOD_TABLE_NAME = 'dcf_financial_period';

export const DISCOUNT_PERIODS = 'terminal_discount_periods';

export const TVT_REM_TABLE_NAME = 'tvt_rem';
export const TVT_EM_TABLE_NAME = 'tvt_em';
export const TVT_RM_TABLE_NAME = 'tvt_rm';
export const TVT_HM_TABLE_NAME = 'tvt_hm';
export const TVT_PG_TABLE_NAME = 'tvt_pg';

export const TERMINAL_VALUE_CUSTOM_KEY = 'terminalValueMetric';

export const PV_OF_TERMINAL_VALUE_CUSTOM_KEY = 'pv_terminal_value';

export const TERMINAL_VALUE_EXPR_CUSTOM_KEY = 'terminalValueExpr';

export const DCF_SUMMARY_TABLE_NAME = 'summary';
