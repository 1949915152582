import React, { FC } from 'react';
import { LoadingSection } from 'components';
import { getHexColor, WATERFALL_OPTIONS } from 'dashboard409a/utilities';
import { BreakpointChartProps, IWaterfallData, ParsedDataType } from './types';
import { LineBarChart } from '../../../LineBarChart';

const getParsedData = (originalData: IWaterfallData) => {
  const {
    waterfall_breakpoints: { breakpoints },
  } = originalData;
  const payload: ParsedDataType = [];

  if (breakpoints)
    breakpoints.forEach(breakpoint => {
      // eslint-disable-next-line consistent-return
      breakpoint.series.forEach(serie => {
        if (!payload.find(el => el.security === serie.security)) {
          return payload.push({
            security: serie.security,
            breakpoints: [
              { breakpoint: 'breakpoint 0', value: 0 },
              { breakpoint: breakpoint.name, value: serie.value },
            ],
          });
        }

        if (payload.find(el => el.security === serie.security)) {
          return payload
            .find(el => el.security === serie.security)
            ?.breakpoints.push({ breakpoint: breakpoint.name, value: serie.value });
        }
      });
    });

  return payload;
};

const BreakpointChart: FC<BreakpointChartProps> = ({ data, isLoading }) => {
  let parsedData: ParsedDataType = [];

  if (data) {
    parsedData = getParsedData(data);
  }

  const dataChart = {
    // labels: totals,
    datasets: parsedData.map((item: any) => ({
      type: 'line' as const,
      label: item.security,
      data: item.breakpoints.map((info: any) => {
        const { breakpoint, value } = info;
        return { x: breakpoint, y: value };
      }),
      backgroundColor: getHexColor(),
      pointRadius: 0,
      fill: true,
      showLine: false,
    })),
  };

  return isLoading || !data ? <LoadingSection /> : <LineBarChart data={dataChart} options={WATERFALL_OPTIONS} />;
};

export default BreakpointChart;
