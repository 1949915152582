import React, { useContext } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PropTypes from 'prop-types';
import { ExtendedFabButton } from 'components/Buttons';
import { LayoutContext } from 'context';

const AddCompButton = ({ openDialog, addComp }) => {
  const { viewOnlyUser } = useContext(LayoutContext);
  return (
    <ExtendedFabButton
      id="add-comp-btn"
      label="Add Comp Group"
      customIcon={openDialog ? ArrowDropUpIcon : ArrowDropDownIcon}
      onClick={addComp}
      disabled={viewOnlyUser}
    />
  );
};

AddCompButton.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  addComp: PropTypes.func.isRequired,
};

export default AddCompButton;
