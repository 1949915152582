import React from 'react';
import PropTypes from 'prop-types';

const CenterAlignedParagraph = ({ message }) => <p style={{ textAlign: 'center' }}>{message}</p>;

CenterAlignedParagraph.propTypes = {
  message: PropTypes.string,
};

export default CenterAlignedParagraph;
