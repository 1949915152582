import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    icon: {
      height: `${theme.spacing(2)}px !important`,
      width: `${theme.spacing(2)}px !important`,
    },
    publishedIcon: {
      fill: `${theme.palette.gray[600]} !important`,
    },
    finalIcon: {
      fill: `${theme.palette.secondary.main} !important`,
    },
  })
);

export default useStyles;
