import { getNumberValue, getObjectValue } from 'utillities';
import { HandleSpecifiedShareValuesScenarioParams } from './types';

const handleSpecifiedShareValuesScenario = (params: HandleSpecifiedShareValuesScenarioParams) => {
  const { capTableSelectionCell, changes, primaryCapTable, specifiedShareValuesCapTable } = params;

  const { id: primaryCapTableId } = getObjectValue(primaryCapTable);

  if (capTableSelectionCell) {
    capTableSelectionCell.readOnly = true;
    changes?.push({
      cell: capTableSelectionCell,
      value: getNumberValue(specifiedShareValuesCapTable ?? primaryCapTableId),
    });
  }
};

export default handleSpecifiedShareValuesScenario;
