/* eslint-disable no-param-reassign */
import React from 'react';
import { isNull, isUndefined } from 'lodash';
import { range } from 'mathjs';
import { ONE_HUNDRED_VALUE } from 'common/config/app';
import { ENTERPRISE_VALUE_ALIAS } from 'common/constants/allocations';
import { LTM, NTM } from 'common/constants/financials';
import { MAX_CHAR_LENGTH } from 'common/constants/general';
import { GRID_NUMBER_CHECKBOX } from 'common/constants/gridType';
import { ENTER_DATA } from 'common/constants/inputs';
import { currencyFormat, largeCurrencyFormat, twoDecimalPercentFormat } from 'common/formats/formats';
import { SelectValueViewer } from 'components';
import { GridDate, GridDateEditor, GridDateValueViewer, GridSelect } from 'components/FeaturedSpreadsheet/components';
import {
  BENCHMARK_ALIASES,
  PERCENTILE_ROWS,
  SELECTED_MULTIPLE,
  SELECTION,
  WEIGHTED_EQUITY_VALUE,
  WEIGHTED_EV,
  WEIGHTING,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import {
  addBenchmarkCustomKeys,
  getSelectionValue,
  handleMultipleCell,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/utillities';
import {
  ACQUIRER_NAME_ID,
  ENTERPRISE_VALUE_LETTER,
  GPC_MULTIPLE_DISCOUNT_ALIAS,
  GPC_NTM_EBITDA_APPROACH_ALIAS,
  GPC_NTM_REVENUE_APPROACH_ALIAS,
  GPT_SUMMARY,
  LTM_COLUMNS,
  LTM_EBITDA_MULTIPLE_ID,
  LTM_EBITDA_MULTIPLE_LETTER,
  LTM_MULTIPLE_COLUMNS_ALIAS,
  LTM_REVENUE_MULTIPLE_LETTER,
  MULTIPLE_COLUMNS_ALIAS,
  MULTIPLE_COLUMNS_LETTER,
  MULTIPLE_DISCOUNT_ALIAS,
  MULTIPLE_DISCOUNT_ROW_NUMBER_OFFSET,
  NTM_EBITDA_COLUMN_ID,
  NTM_EBITDA_MULTIPLE_ID,
  NTM_MULTIPLES_IDS,
  NTM_REVENUE_COLUMN_ID,
  TRANSACTION_DATE_ID,
} from 'pages/Valuations/approaches/GuidelineTransactions/config/constants';
import {
  COMPANY_ROW_ALIAS,
  VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY,
  VALUATIONS_SPREADSHEET_EQUITY_VALUE_KEY,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { getEbitdaValueForViewer, getLtmNtmEBITDAs } from 'pages/ValuationsAllocation/util';
import { getValueByPropOrFallback, gridShortDate, isValidDate, parseValue } from 'utillities';
import { alphabetGenerator, getColumnIndex, getColumnLetter } from 'utillities/alphabet-utilities';
import handleOptionsNumberFormat from '../../guidelinePublicCompanies/gpc/handleOptionsNumberFormat';

function getExpr(row, columnLegend) {
  return row.expr ? row.expr.replace(/@/g, columnLegend) : '';
}

const replaceColumnLegendInRowRange = ({ rowRange, columnLegend, offset = 2 }) => {
  const regex = new RegExp(columnLegend, 'g');
  const currentColumnNumber = getColumnIndex(columnLegend);
  const referencedColumnLegend = getColumnLetter(currentColumnNumber - offset);
  const updatedRowRange = rowRange.replace(regex, referencedColumnLegend);
  return updatedRowRange;
};

const generateDiscountedExpression = (cell, rowRange) => {
  const { columnLegend } = cell;
  const multipleDiscountRowNumber = rowRange.split(',').length + MULTIPLE_DISCOUNT_ROW_NUMBER_OFFSET;
  const multipleDiscountKey = `${columnLegend}${multipleDiscountRowNumber}`;
  const updatedRowRange = replaceColumnLegendInRowRange({ rowRange, columnLegend });
  const discountedExpression
    = rowRange?.length > 2 ? `${cell.expr.replace(rowRange, updatedRowRange)} * (1 + ${multipleDiscountKey})` : '';
  return discountedExpression;
};

const completeTransactionCell = ({ cell, row, columnId }) => {
  /* Gives extra props according to nature of cell (columnId) */
  if (columnId === TRANSACTION_DATE_ID) {
    return {
      ...cell,
      readOnly: row.readOnly,
      gridDateComponent: GridDate,
      dataEditor: props => <GridDateEditor {...props} />,
      valueViewer: props => <GridDateValueViewer {...props} />,
    };
  }
  if (columnId === ACQUIRER_NAME_ID) {
    return {
      ...cell,
      placeholder: ENTER_DATA,
      maxNumberChars: MAX_CHAR_LENGTH,
    };
  }
  if (NTM_MULTIPLES_IDS.includes(cell.columnId)) {
    // remove checkbox from NTM multiples in transaction rows
    return {
      ...cell,
      valueViewer: null,
      forceComponent: false,
      gridType: 'string',
      expr: null,
      value: '',
      isRequired: false,
      readOnly: true,
    };
  }
  return cell;
};

function generatePercentilesExpression(cell, rowRange) {
  if (LTM_MULTIPLE_COLUMNS_ALIAS.includes(cell.columnId)) {
    return `=PERCENTILE(FILTER_ENABLED(${rowRange}), TITLES_${cell.alias})`;
  }
  // fix row range and add discount factor
  const { columnLegend } = cell;
  const multipleDiscountRowNumber = rowRange.split(',').length + MULTIPLE_DISCOUNT_ROW_NUMBER_OFFSET;
  const multipleDiscountKey = `${columnLegend}${multipleDiscountRowNumber}`;
  const updatedRowRange = replaceColumnLegendInRowRange({ rowRange, columnLegend: cell.columnLegend });
  return `=PERCENTILE(FILTER_ENABLED(${updatedRowRange}), TITLES_${cell.alias}) * (1 + ${multipleDiscountKey})`;
}

const updatePercentileCellProps = ({ cell, rowRange }) => {
  const updatedCell = { ...cell };
  if (MULTIPLE_COLUMNS_ALIAS.includes(cell.columnId)) {
    const percentileExpr = generatePercentilesExpression(cell, rowRange);
    updatedCell.expr = percentileExpr;
  }
  updatedCell.readOnly = true;
  delete updatedCell.dataEditor;
  delete updatedCell.valueViewer;
  delete updatedCell.isEditableTitleCell;
  delete updatedCell.useScalarSpreadsheetCell;
  delete updatedCell.dropdown;
  return updatedCell;
};

function getVal(column, row) {
  let value = null;
  const columProps = column[row.alias];

  if (row.gridType === GRID_NUMBER_CHECKBOX) {
    const columnId = column.row_ref || column.id;
    value = columProps?.number || columProps?.[columnId];
    if (isValidDate(value)) {
      value = gridShortDate(value);
    }
  } else {
    value = columProps;
  }
  return !Number.isNaN(value) ? value : null;
}

const getValue = (column, row, specificApproach) => {
  if (row.alias === SELECTION) {
    return getSelectionValue(row, column, specificApproach);
  }
  if (row.alias === MULTIPLE_DISCOUNT_ALIAS && column.id) {
    const metricKey = column.id.split('_')[1];
    const propertyToRead = `ntm_${metricKey}_discount`;
    return specificApproach[propertyToRead];
  }

  // The company rows have object data types, both a number and an enabled status,
  // and the below code keeps the cellReferenceBar from breaking
  return getVal(column, row);
};

const getValueToUse = (columnValue, tempValue, preferenceForTemp) => {
  if (preferenceForTemp && tempValue) {
    return tempValue;
  }
  return tempValue && isNull(columnValue) ? tempValue : columnValue;
};

const checkForTempData = (
  column,
  row,
  transactionsInTable,
  multiplesAndWeightings,
  isRelevantRow,
  specificApproach
) => {
  if (multiplesAndWeightings && isRelevantRow) {
    if (row.alias === SELECTION) {
      return getSelectionValue(row, column, specificApproach);
    }
    if (row.alias === WEIGHTING) {
      return Number(multiplesAndWeightings[row.alias]) * ONE_HUNDRED_VALUE;
    }
    return multiplesAndWeightings[row.alias];
  }
  // in column, row doesn't have data, but in tableData it has information
  const propInRow = column.id;
  const matchedRow = transactionsInTable.find(({ row_ref }) => row.row_ref === row_ref);
  const tempValue = matchedRow?.[propInRow];
  return !Number.isNaN(tempValue) ? tempValue : null;
};

const updateMultipleCell = (cell, rowNumber, columnLegend, columns) => {
  const enterpriseValueItem = columns.find(column => column.id === ENTERPRISE_VALUE_ALIAS);
  const enterpriseValueCell = enterpriseValueItem[cell.alias];
  const enterpriseValue = enterpriseValueCell[ENTERPRISE_VALUE_ALIAS];
  const { value, enabled } = cell;
  const isEmptyCells = isUndefined(value) && isNull(enterpriseValue);
  const expr
    = columnLegend === LTM_REVENUE_MULTIPLE_LETTER ? `=C${rowNumber}/D${rowNumber}` : `=C${rowNumber}/E${rowNumber}`;
  if (!isEmptyCells) {
    const shouldIgnoreExpr = parseFloat(value) === 0 || isUndefined(value);
    cell.ignoreExpr = shouldIgnoreExpr;
    cell.enabled = enabled && !shouldIgnoreExpr;
  }
  cell.expr = expr;
};

const cellParser = params => {
  let { cell } = params;
  const {
    column,
    columnLegend,
    existAliasAndIsNotTitle,
    expr,
    inLTM,
    inMultipleCell,
    row,
    type,
    value,
    rowNumber,
    columns,
    rowRange,
    isDisabled,
  } = params;
  if (columnLegend === ENTERPRISE_VALUE_LETTER && existAliasAndIsNotTitle) {
    cell = {
      ...cell,
      gridType: 'number',
      format: largeCurrencyFormat,
    };
  } else if (inLTM && existAliasAndIsNotTitle) {
    cell = {
      ...cell,
      gridType: 'number',
      format: largeCurrencyFormat,
      expr: expr(),
      value: parseValue(value, type, null, null, row.dbType),
    };
  } else if (inMultipleCell && cell.alias !== 'multiple_discount') {
    cell = {
      ...row,
      ...cell,
      expr: expr(),
      value: parseValue(value, type, 0, null, row.dbType),
    };
  } else if (NTM_MULTIPLES_IDS.includes(cell.columnId) && cell.alias === MULTIPLE_DISCOUNT_ALIAS) {
    cell = {
      ...cell,
      gridType: 'percentage',
      readOnly: isDisabled,
      format: twoDecimalPercentFormat({ minimumFractionDigits: 0 }),
      defaultValue: 0,
      isExpr: false,
      value,
      dbDecimalPlaces: 4, // this would be better to have it read from field attributes
    };
  }

  if (cell.isMultipleCell) {
    cell.customKey = `${column.fields[0].number}_${cell.alias}`;
  }
  if (NTM_MULTIPLES_IDS.includes(cell.columnId) && BENCHMARK_ALIASES.includes(cell.alias) && cell.expr) {
    // replace row range and apply factor
    const discountedExpression = generateDiscountedExpression(cell, rowRange);
    cell = {
      ...cell,
      expr: discountedExpression,
    };
  }
  if (
    typeof column[row.alias] === 'object'
    && !isNull(column[row.alias])
    && inMultipleCell
    && !NTM_MULTIPLES_IDS.includes(cell.columnId)
  ) {
    cell.enabled = column[row.alias].enabled;
    cell.revenue = column[row.alias].number;
    updateMultipleCell(cell, rowNumber, columnLegend, columns);
  }

  addBenchmarkCustomKeys(cell, cell, column);

  if (row.isGptRow) {
    const columnId = getValueByPropOrFallback(column, 'row_ref', column.id);
    cell = {
      ...cell,
      isRequired: true,
      readOnly: isDisabled || row.readOnly,
      isGptRow: row.isGptRow,
    };
    cell = completeTransactionCell({ cell, row, columnId });
  }

  const isPercentileCell = PERCENTILE_ROWS.includes(cell.alias);
  if (isPercentileCell) {
    cell = updatePercentileCellProps({ cell, rowRange });
  }

  return cell;
};

const parser = async ({ columns, rowConfig, tableData }) => {
  const sourceData = tableData.approach || tableData;
  const {
    financials: {
      total_cash_equivalents: plusCash,
      total_debt: lessDebt,
      ltm_ebitda: ltmEbitda,
      ltm_adjusted_ebitda: ltmAdjustedEbitda,
      ntm_ebitda: ntmEbitda,
      ntm_adjusted_ebitda: ntmAdjustedEbitda,
      use_adjusted_ebitda: useFinancialStatementAdjustedEbitda,
    },
    isDisabled,
  } = tableData;
  const financialsPeriods = [
    {
      period_type: LTM,
      income_statement: {
        ebitda: ltmEbitda.toString(),
        adjusted_ebitda: ltmAdjustedEbitda.toString(),
      },
    },
    {
      period_type: NTM,
      income_statement: {
        ebitda: ntmEbitda.toString(),
        adjusted_ebitda: ntmAdjustedEbitda.toString(),
      },
    },
  ];
  const ebitdaOptions = getLtmNtmEBITDAs(financialsPeriods, true);

  const {
    gpt_transactions: tableDataTransactions,
    ltm_revenue_selection: revenueSelection,
    ltm_ebitda_selection: ebitdaSelection,
    ltm_ebitda_weighting: ebitdaWeighting,
    ltm_revenue_weighting: revenueWeighting,
    ltm_ebitda_selected_multiple: ebitdaSpecifiedMultiple,
    ltm_revenue_selected_multiple: revenueSpecifiedMultiple,
    ntm_ebitda_gpc_approach: ntmEbitdaGpcApproach,
    ntm_revenue_gpc_approach: ntmRevenueGpcApproach,
    ntm_revenue_gpc_reference: ntmRevenueGpcReference,
    ntm_ebitda_gpc_reference: ntmEbitdaGpcReference,
  } = sourceData.valuations_approach_gpt;

  const tableDataMultiplesAndWeightings = {
    [LTM_REVENUE_MULTIPLE_LETTER]: {
      selection: revenueSelection,
      weighting: revenueWeighting,
      selected_multiple: revenueSpecifiedMultiple,
    },
    [LTM_EBITDA_MULTIPLE_LETTER]: {
      selection: ebitdaSelection,
      weighting: ebitdaWeighting,
      selected_multiple: ebitdaSpecifiedMultiple,
    },
  };

  // These default cells consist of some default cell headers
  let cells = {
    F1: {
      key: 'F1',
      className: 'align-center',
      value: 'LAST TWELVE MONTHS',
      readOnly: true,
      colSpan: 2,
      rowSpan: 1,
    },
    H1: {
      key: 'H1',
      className: 'align-center',
      value: 'NEXT TWELVE MONTHS',
      readOnly: true,
      colSpan: 2,
      rowSpan: 1,
    },
    B1: {
      key: 'B1',
      className: 'align-center',
      value: 'TRANSACTIONS DETAILS',
      readOnly: true,
      colSpan: 4,
      rowSpan: 1,
    },
    A1: {
      key: 'A1',
      value: 'Acquirer Name',
      readOnly: true,
      colSpan: 1,
      rowSpan: 2,
    },
  };

  const weightingRow = rowConfig.length - 2;
  const valueRow = rowConfig.length - 3;

  function getExpressionPiece(legend) {
    return `${legend}${weightingRow}/100 * ${legend}${valueRow}`;
  }

  cells[`A${rowConfig.length - 1}`] = {
    key: `A${rowConfig.length - 1}`,
    colSpan: 9,
    alias: WEIGHTED_EV,
    columnLegend: 'A',
    gridType: 'number',
    customKey: VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY,
    expr: `=${MULTIPLE_COLUMNS_LETTER.map(legend => getExpressionPiece(legend)).join(' + ')}`,
    format: currencyFormat({ fractionDigits: 2 }),
    className: 'subtitle large-cell-value',
  };
  cells[`A${rowConfig.length}`] = {
    key: `A${rowConfig.length}`,
    colSpan: 9,
    alias: WEIGHTED_EQUITY_VALUE,
    columnLegend: 'A',
    gridType: 'number',
    customKey: VALUATIONS_SPREADSHEET_EQUITY_VALUE_KEY,
    expr: `=A${rowConfig.length - 1} + ${plusCash} - ${lessDebt}`,
    format: currencyFormat({ fractionDigits: 2 }),
    className: 'subtitle large-cell-value',
  };

  const alphabet = alphabetGenerator([], columns.length);

  function handleRow(column, columnLegend) {
    const rowRange = range(1, tableDataTransactions.length + 1)
      .map(rowNumber => `${columnLegend}${rowNumber + 2}`)
      .toString()
      .replace(/"/g, '');

    return (row, index) => {
      if (index === 0 || index === rowConfig.length - 1 || index === rowConfig.length - 2) {
        return;
      }
      const rowNumber = index + 1;
      const key = columnLegend + rowNumber;

      const multiplesAndWeightings = tableDataMultiplesAndWeightings[columnLegend];
      const selectionOrWeightingOrSpecifiedMultipleRow = [SELECTION, WEIGHTING, SELECTED_MULTIPLE].includes(row.alias);

      const tempValue = checkForTempData(
        column,
        row,
        tableDataTransactions,
        multiplesAndWeightings,
        selectionOrWeightingOrSpecifiedMultipleRow,
        sourceData.valuations_approach_gpt
      );

      const valueInColumn = getValue(column, row, sourceData.valuations_approach_gpt);
      const value = getValueToUse(valueInColumn, tempValue, selectionOrWeightingOrSpecifiedMultipleRow);
      const type = getValueByPropOrFallback(row, 'gridType', null);

      const expr = () => getExpr(row, columnLegend);

      let cell = {
        key,
        className: row.className,
        alias: getValueByPropOrFallback(row, 'alias', ''),
        value,
        columnId: getValueByPropOrFallback(column, 'row_ref', column.id),
        parentColumn: column.parentColumn,
        columnLegend,
        columnOrder: column.order,
        rowNumber,
        isVisible: row.isVisible,
        displayNAforNull: row.displayNAforNull,
      };

      const inLTM = LTM_COLUMNS.indexOf(columnLegend) >= 0;
      const inMultipleCell = MULTIPLE_COLUMNS_LETTER.indexOf(columnLegend) >= 0;
      const existAliasAndIsNotTitle = row.alias !== 'title' && column[row.alias];

      cell = cellParser({
        cell,
        column,
        columnLegend,
        existAliasAndIsNotTitle,
        expr,
        inLTM,
        inMultipleCell,
        row,
        type,
        value,
        rowNumber,
        columns,
        rowRange,
        isDisabled,
      });

      if (
        cell.alias === COMPANY_ROW_ALIAS
        && [LTM_EBITDA_MULTIPLE_ID, NTM_EBITDA_MULTIPLE_ID].includes(cell.columnId)
        && useFinancialStatementAdjustedEbitda
        && ebitdaOptions[cell.columnId]?.some(option => option.value !== ebitdaOptions[cell.columnId][0].value)
      ) {
        cell = {
          ...cell,
          readOnly: false,
          dataEditor: props => (
            <GridSelect
              options={ebitdaOptions[cell.columnId]}
              handleOptionsNumberFormat={handleOptionsNumberFormat}
              {...props}
            />
          ),
          valueViewer: props => <SelectValueViewer options={ebitdaOptions[cell.columnId]} {...props} />,
          value: getEbitdaValueForViewer({
            cell,
            options: ebitdaOptions,
            valuation_approach_adjustments: sourceData.valuations_approach_gpt,
            is_transaction_comps: true,
          }),
        };
      }
      // Clean all Non GPT Companies cells
      if (!row.isGptRow && rowNumber > 2) {
        switch (columnLegend) {
          case 'A': // Acquirer Name
          case 'B': // Transaction Date
          case 'C': // Enterprise Value
          case 'D': // LTM Revenue
          case 'E': // LTM EBITDA
            cell.expr = null;
            cell.gridType = 'string';
            cell.value = '';
            break;
          case 'F':
          case 'G':
            if (row.alias === MULTIPLE_DISCOUNT_ALIAS || row.alias === GPC_MULTIPLE_DISCOUNT_ALIAS) {
              cell.expr = null;
              cell.gridType = 'string';
              cell.value = '';
              cell.valueViewer = null;
              cell.dataEditor = null;
              cell.readOnly = true;
            }
            if (row.alias === GPT_SUMMARY) {
              cell.expr = null;
              cell.gridType = 'string';
              cell.value = '';
            }
            break;
          case 'H':
          case 'I':
            if (row.alias === GPC_MULTIPLE_DISCOUNT_ALIAS) {
              const { columnId } = cell;
              if (columnId === NTM_EBITDA_COLUMN_ID) {
                cell.alias = GPC_NTM_EBITDA_APPROACH_ALIAS;
                cell.value = ntmEbitdaGpcApproach || ntmEbitdaGpcReference;
              } else if (columnId === NTM_REVENUE_COLUMN_ID) {
                cell.alias = GPC_NTM_REVENUE_APPROACH_ALIAS;
                cell.value = ntmRevenueGpcApproach || ntmRevenueGpcReference;
              }
            }
            if (row.alias === GPT_SUMMARY) {
              cell.expr = null;
              cell.gridType = 'string';
              cell.value = '';
            }
            break;
          default:
            break;
        }
      }

      handleMultipleCell(cell, column);

      cells = {
        ...cells,
        [key]: cell,
      };
    };
  }

  // Parse body cells
  columns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];
    rowConfig.forEach(handleRow(column, columnLegend));
  });
  return cells;
};

export default parser;
