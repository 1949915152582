// import react
import React, { useContext, useMemo } from 'react';
import { SELECT_SECURITY } from 'common/constants/inputs';
import SelectionViewer from 'components/SelectionViewer/SelectionViewer';
import BacksolveContext from 'context/BacksolveContext';

// arrow function named SecuritySelectionViewer
const SecuritySelectionViewer = props => {
  // import securitis from BacksolveContext
  const { securityList } = useContext(BacksolveContext);
  // options variable that is an object of securities mapped by their id
  const optionsList = useMemo(
    () => securityList?.map(security => ({ value: security.id, label: security.name })),
    [securityList]
  );
  // convert optionsList to an object
  const options = useMemo(
    () =>
      optionsList?.reduce((acc, option) => {
        acc[option.value] = option.label;
        return acc;
      }, {}),
    [optionsList]
  );
  // Wrap a SelectionViewer component, passing in options and props
  return <SelectionViewer options={options || []} defaultLabel={SELECT_SECURITY} {...props} />;
};

export default SecuritySelectionViewer;
