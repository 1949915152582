import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'common/store';
import { initAnalytics } from 'scalarAnalytics/googleAnalytics';
import useAnalytics from 'scalarAnalytics/useAnalytics';

const AnalyticsProvider = ({ children }) => {
  const [{ user }] = useStore();
  initAnalytics(user);
  useAnalytics();
  return <span>{children}</span>;
};

AnalyticsProvider.propTypes = {
  children: PropTypes.element,
};

export default AnalyticsProvider;
