import {
  COMPANIES_SLUG,
  DASHBOARD_SLUG,
  INFORMATION_SLUG,
  OVERVIEW_SLUG,
  PREVIOUS_REPORTS_SLUG,
  SETTINGS_SLUG,
  SUBSCRIPTION_SLUG,
} from 'dashboard409a/common/constants';

const DASHBOARD_COMPANY_URL = (companySlug: string) => `/${DASHBOARD_SLUG}/${COMPANIES_SLUG}/${companySlug}`;

export const dashboardCompanyOverviewUrl = (companySlug: string) =>
  `${DASHBOARD_COMPANY_URL(companySlug)}/${OVERVIEW_SLUG}`;

export const dashboardCompanyPreviousReportsUrl = (companySlug: string) =>
  `${DASHBOARD_COMPANY_URL(companySlug)}/${PREVIOUS_REPORTS_SLUG}`;

export const dashboardCompanySettingsUrl = (companySlug: string) =>
  `${DASHBOARD_COMPANY_URL(companySlug)}/${SETTINGS_SLUG}`;

export const dashboardCompanySubscriptionUrl = (companySlug: string) =>
  `${DASHBOARD_COMPANY_URL(companySlug)}/${SUBSCRIPTION_SLUG}`;

export const dashboardCompanyInformationUrl = (companySlug: string) =>
  `${DASHBOARD_COMPANY_URL(companySlug)}/${INFORMATION_SLUG}`;
