import React, { useContext } from 'react';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import FutureExitContext from 'pages/ValuationsAllocation/approaches/FutureExit/utils/FutureExitContext';

const MetricValueSelect = (props: any) => {
  const { metricOptions } = useContext(FutureExitContext);

  return <GridSelect menuPosition="fixed" options={metricOptions} enumerated {...props} />;
};

export default MetricValueSelect;
