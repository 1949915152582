/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { isNull } from 'lodash';
import { captableAction } from 'common/actions';
import { useStore } from 'common/store';

const useHasMeasurementDates = () => {
  const [{ captableInfo, companyInfo }] = useStore();
  const [hasMeasurementDates, setHasMeasurementDates] = useState(undefined);

  const checkIfHasMeasurementDates = async () => {
    const { measurement_date_id } = companyInfo;
    if (captableInfo?.id) {
      const response = await captableAction.checkForMeasurementDates(captableInfo.id);
      setHasMeasurementDates(response);
    } else if (companyInfo && isNull(measurement_date_id)) {
      setHasMeasurementDates(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (companyInfo && isMounted) checkIfHasMeasurementDates();
    return () => {
      isMounted = false;
      setHasMeasurementDates(undefined);
    };
  }, [companyInfo?.measurement_date_id, captableInfo?.id]);

  return hasMeasurementDates;
};

export default useHasMeasurementDates;
