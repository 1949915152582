/**
 * NoteList Component
 *
 * @component
 * @description This component displays a list of notes in an accordion-style container.
 * It allows adding new notes and managing the visibility of existing notes.
 *
 * @param {NoteData[]} props.notes - An array of NoteData objects representing the notes to be displayed.
 * @returns {JSX.Element} - A JSX element representing the NoteList component.
 */

import React, { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, Typography } from '@material-ui/core';
import { AddCircle as AddCircleIcon, ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { isEmpty, isNull } from 'lodash';
import { ADD_NOTES_TITLE, NOTES_ADD_NEW, NOTES_ADDED, NOTES_STATUS, NOTES_TITLE } from 'common/constants/notes';
import useNotesContext from 'context/NotesContext';
import { stopEventPropagation } from 'utillities';
import Note from './note/Note';
import useStyles from './styles';
import { NoteData, NoteListProps } from './types';

const NoteList: FC<NoteListProps> = props => {
  const { pageType, pageTypeId, onAddNote } = useNotesContext();
  const classes = useStyles();
  const { notes, isDisabled = false } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [visibleNotes, setVisibleNotes] = useState<NoteData[]>([]);

  useEffect(() => {
    if (!isNull(notes)) {
      const tempVisibleNotes = notes.filter(note => !note.is_deleted);
      setVisibleNotes(tempVisibleNotes);
    }
  }, [notes]);

  const notesState = isEmpty(visibleNotes) ? `${NOTES_STATUS}: 0 ` : `${NOTES_ADDED}: ${visibleNotes.length}`;

  const handleAccordionOnChange = useCallback(
    (event, expandedState) => {
      setOpen(expandedState);
      stopEventPropagation(event);
    },
    [setOpen]
  );

  const handleOnAddNote = (event: MouseEvent<HTMLButtonElement>) => {
    stopEventPropagation(event);
    setOpen(true);
    onAddNote({ pageType, pageTypeId });
  };

  return (
    <Accordion
      className={classes.boxContainer}
      expanded={open}
      onChange={(event, expanded) => handleAccordionOnChange(event, expanded)}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon className={classes.expandIcon} />}
        aria-controls="notes-widget-content"
        id="notes-widget-header">
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="overline" className={classes.notesContainerTitle}>
                  {isDisabled ? NOTES_TITLE : ADD_NOTES_TITLE}
                </Typography>
              </Grid>
              {}
              <Grid item>
                <IconButton
                  className={classes.addNote}
                  size="small"
                  color="primary"
                  onClick={handleOnAddNote}
                  disabled={isDisabled}>
                  <AddCircleIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="caption" className={classes.notesContainerStatus}>
              {notesState}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.notesContainerDetails}>
        {isEmpty(visibleNotes) ? (
          !isDisabled && (
            <Button onClick={handleOnAddNote} className={classes.notesEmptyBtn} disabled={isDisabled}>
              <Typography align="center" className={classes.notesEmptyBtnText}>
                {NOTES_ADD_NEW}
              </Typography>
            </Button>
          )
        ) : (
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
            {visibleNotes.map((note: NoteData) => (
              <Note key={note.id ?? note.temp_id} note={note} isDisabled={isDisabled} />
            ))}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default NoteList;
