import React, { useMemo } from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { ValueEditor } from 'components/Spreadsheet/components';
import { useKpiActions } from 'pages/Financials/kpi/utils';

const KpiTable = props => {
  const { kpiSheet, sharedProps, setTableData, isDisabled, ...rest } = props;
  const { workbook } = sharedProps;

  const companyKPIsExist = useMemo(() => kpiSheet.tableData?.companyKPIs?.length > 0, [kpiSheet.tableData]);
  const { onAddKPI, onDeleteKPI, onChangeDataType } = useKpiActions(kpiSheet, workbook);

  return (
    <>
      {companyKPIsExist ? (
        <ScalarSpreadsheet
          {...kpiSheet}
          {...rest}
          {...sharedProps}
          sheet={kpiSheet}
          deleteRowFn={onDeleteKPI}
          allowChangeDataType
          changeRowDataTypeFn={onChangeDataType}
          editorForTitles={ValueEditor}
        />
      ) : (
        <h3 style={{ marginLeft: '1rem' }}>KPI Table</h3>
      )}

      <Button
        variant="outlined"
        color="primary"
        onClick={onAddKPI}
        style={{ marginTop: '1rem', marginLeft: '1rem' }}
        disabled={isDisabled}>
        ADD KPI ROW
      </Button>
    </>
  );
};

KpiTable.propTypes = {
  kpiSheet: PropTypes.shape({
    tableData: PropTypes.shape({
      companyKPIs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          sort_order: PropTypes.number.isRequired,
          data_type: PropTypes.number.isRequired,
          alias: PropTypes.string.isRequired,
        })
      ).isRequired,
      deletedKPIs: PropTypes.arrayOf(PropTypes.number.isRequired),
    }).isRequired,
    reverseParse: PropTypes.func.isRequired,
  }).isRequired,
  sharedProps: PropTypes.shape({
    workbook: PropTypes.shape({
      sheets: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          cells: PropTypes.object.isRequired,
          tableData: PropTypes.object.isRequired,
          reverseParse: PropTypes.func.isRequired,
        })
      ).isRequired,
    }).isRequired,
  }),
  setTableData: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default KpiTable;
