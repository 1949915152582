import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { COMPANY_NOT_FOUND } from 'common/constants/login';
import { Company } from 'pages/Login/components/CompanySelector/components';
import { ICompaniesList } from './types';

const CompaniesList: FC<ICompaniesList> = props => {
  const { filteredCompaniesList } = props;

  // Return company not found
  if (filteredCompaniesList.length === 0) {
    return <Typography variant="h4">{COMPANY_NOT_FOUND.toUpperCase()}</Typography>;
  }

  // Return companies list
  return (
    <>
      {filteredCompaniesList.map(company => (
        <Company key={company.id} name={company.name} slug={company.slug} />
      ))}
    </>
  );
};

export default CompaniesList;
