/* eslint-disable no-param-reassign */
import { parseDatabaseValue } from 'utillities';
import { IReverseParserParams } from './types';
import {
  FIVE_YEAR_VOLATILITY,
  FIVE_YEAR_VOLATILITY_PERCENTILES_SELECTION,
  ONE_YEAR_VOLATILITY,
  ONE_YEAR_VOLATILITY_PERCENTILES_SELECTION,
  TWO_YEAR_VOLATILITY,
  TWO_YEAR_VOLATILITY_PERCENTILES_SELECTION,
} from '../../common/constants/performanceMetrics';

const reverseParser = (params: IReverseParserParams) => {
  Object.values(params.cells).forEach(cell => {
    switch (cell.alias) {
      case ONE_YEAR_VOLATILITY:
      case TWO_YEAR_VOLATILITY:
      case FIVE_YEAR_VOLATILITY:
        if (params.initialObj.valuations_approach_gpc) {
          params.initialObj.valuations_approach_gpc[cell.alias] = parseDatabaseValue({
            value: cell.value,
            format: cell.format,
            gridType: cell.gridType,
            type: 'string',
            decimalPlaces: 10,
            allowEmptyValues: undefined,
            dontMultiply: undefined,
          });
        }
        break;
      case ONE_YEAR_VOLATILITY_PERCENTILES_SELECTION:
      case TWO_YEAR_VOLATILITY_PERCENTILES_SELECTION:
      case FIVE_YEAR_VOLATILITY_PERCENTILES_SELECTION:
        if (params.initialObj.valuations_approach_gpc) {
          params.initialObj.valuations_approach_gpc[cell.alias] = cell.value?.toString();
        }
        break;
      default:
        break;
    }
  });
};

export default reverseParser;
