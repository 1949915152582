import { isUndefined } from 'lodash';
import moment from 'moment';
import { isEmpty } from 'validate.js';
import { CASH_AND_EQUIVALENTS_ALIAS, FOURTH_ROW_ROW_NUMBER, THIRD_ROW_ALIAS } from 'common/constants/financials';
import { MOMENT_SUPPORTED_DATE_FORMATS, parseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { getColumnValue } from './auxiliaryFunctions';
import { getRowSpan } from './getCellSpan';
import getClassName from './getClassName';
import getPrevQuarterColumn from './getPrevQuarterColumn';
import getPrevYearColumn from './getPrevYearColumn';
import getQuarterRows from './getQuarterRows';
import { ntmAndLtmHeadersStyle } from '../data/baseRowConfig';

const getPairedCell = (cell, otherCells, column, currentFiscalYearEnd) => {
  if (cell.year && !cell.date) {
    const { reported_statement_date, subtitle } = column;
    // sometimes year will be the same but month and day not
    const dateInColumn = moment(reported_statement_date || subtitle, MOMENT_SUPPORTED_DATE_FORMATS);

    const sameDate = dateInColumn.date() === currentFiscalYearEnd.date();
    const sameMonth = dateInColumn.month() === currentFiscalYearEnd.month();

    if (!sameDate || !sameMonth) return null;
    return otherCells.find(c => !c.date && c.year === cell.year);
  }
  if (cell.date && cell.isParent) {
    return otherCells.find(c => c.date === cell.date && c.isParent);
  }
  if (cell.date) {
    return otherCells.find(c => c.date === cell.date);
  }
  return null;
};

const baseParser = async ({ columns = [], rowConfig, currencyCode, tableData: { currentFiscalYearEnd } }) => {
  let cells = {};
  /* baseParser is also used in Income statement. With this variable we can
  modify just the parent columns expressions in Balance Sheet */
  const balanceSheetOrigin = rowConfig[3].alias === CASH_AND_EQUIVALENTS_ALIAS;

  const alphabet = alphabetGenerator([], columns.length);

  const getExpr = (expr, columnLegend) => (expr ? expr.replace(/@/g, columnLegend) : '');
  const getValue = v => (!isUndefined(v) ? v : null);

  const sumQuartersFormula = ({ col, row }) => {
    const quarterRows = getQuarterRows({ alphabet, col, row });
    const cellKeys = quarterRows.join(',');
    return `=SUM(${cellKeys})`;
  };

  const getDefaultExpr = ({ columnIndex, column, rowNumber, legends }) => {
    if (column.isParent && balanceSheetOrigin) {
      const previousColumnLegend = alphabet[columnIndex - 1];
      return `=${previousColumnLegend}${rowNumber}`;
    }
    if (column.isParent) {
      return sumQuartersFormula({
        col: columnIndex,
        row: rowNumber,
      });
    }

    if (column.isLTM || column.isNTM) {
      const targetColumnLegend = legends[column.date];
      return `=${targetColumnLegend + rowNumber}`;
    }

    return null;
  };

  let quarterLegends = {};

  columns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];
    const { quarter, isLTM, isNTM, isLTMQuarter, isNTMQuarter } = column;

    rowConfig.forEach((row, index) => {
      const rowNumber = index + 1;
      const key = columnLegend + rowNumber;
      const type = row.gridType || null;

      const columnValue = getColumnValue({
        rowAlias: row.alias,
        column,
        isColumnWithReportedDate: column.isLTMYear || column.isNTMYear,
      });
      const value = getValue(columnValue);
      const expr = getExpr(row.validExpr ?? row.expr, columnLegend);
      let parsedValue = parseValue(value, type, null, null, row.dbType);

      const date = column.statement_date || column.date;
      const statementDate = moment(date).format('MM-DD-YYYY');

      if (column.quarter && !(column.isLTM || column.isNTM)) {
        if (row.alias === THIRD_ROW_ALIAS && column.subtitle === column.date) parsedValue = '';

        if (!quarterLegends[statementDate]) {
          quarterLegends = {
            ...quarterLegends,
            [statementDate]: columnLegend,
          };
        }
      }

      const defaultExpr = getDefaultExpr({
        column,
        rowNumber,
        columnIndex,
        legends: quarterLegends,
      });

      const { needs_actualization } = column;
      const className = getClassName(needs_actualization, row);

      const isAdditionalHistoricalYear = Boolean(column.isAdditionalHistoricalYear);
      const showAdditionalHistoricalYear = Boolean(column.showAdditionalHistoricalYear);
      const isVisibleColumn = isAdditionalHistoricalYear
        ? isAdditionalHistoricalYear && showAdditionalHistoricalYear
        : null;

      const currentCell = {
        ...row,
        date,
        key,
        columnLegend,
        columnIndex,
        needs_actualization,
        className,
        columnId: column.id,
        rowNumber,
        isLTM,
        isLTMQuarter,
        isNTM,
        isNTMQuarter,
        quarter,
        defaultExpr,
        expr: defaultExpr && isEmpty(expr) && !parsedValue ? defaultExpr : expr,
        value: parsedValue,
        alias: row.alias || '',
        isParent: Boolean(column.isParent),
        parentColumn: column.parentColumn,
        childIndex: column.quarter,
        rowSpan: getRowSpan(rowNumber, column),
        prevQuarter: getPrevQuarterColumn({ columnIndex, rowNumber, alphabet, cells }),
        prevYear: getPrevYearColumn({ columnIndex, column, alphabet }),
        isProjectionPeriod: column.isProjectedQuarter || column.isProjectedYear,
        isChildWithDivider: Boolean(column.isChildWithDivider),
        isParentWithDivider: Boolean(column.isParentWithDivider),
        isAdditionalHistoricalYear,
        showAdditionalHistoricalYear,
        isVisibleColumn,
        mdMatchesFiscalYearEnd: column.mdMatchesFiscalYearEnd,
        year: column.year,
        style: (isNTM || isLTM) && rowNumber < FOURTH_ROW_ROW_NUMBER ? ntmAndLtmHeadersStyle : row.style,
        currencyCode,
      };
      const isSpecialPeriod = isLTM || isNTM || isNTMQuarter || isLTMQuarter;

      if (isSpecialPeriod && !currentCell.readOnly) {
        const sameRowCells = Object.values(cells).filter(
          c => c.key !== currentCell.key && currentCell.rowNumber === c.rowNumber
        );
        // find cell with same row and same date and add it as child of controller by expression
        const pairedCell = getPairedCell(currentCell, sameRowCells, column, currentFiscalYearEnd);
        if (pairedCell && column.mdMatchesFiscalYearEnd) {
          currentCell.customKey = `controller_${currentCell.key}_${pairedCell.key}`;
          pairedCell.expr = `=${currentCell.key}`;
        }
      }

      cells = {
        ...cells,
        [key]: currentCell,
      };
    });
  });

  return cells;
};

export default baseParser;
