import { isUndefined } from 'lodash';
import { APPROACH_WEIGHTING_ALIAS } from 'common/constants/allocations';
import percentageLinkedCellUpdate from 'pages/Allocation/allocations/components/current-value-ledger/components/approach-table/utils/percentageLinkedCellUpdates';

const linkedCellUpdates = (changes, cells) => {
  const updates = [];
  changes.forEach(change => {
    const { cell, row } = change;
    if (cell.alias === APPROACH_WEIGHTING_ALIAS) {
      // eslint-disable-next-line no-case-declarations
      const updateCell = cells[`APP_WEIGHTEDEQUITYVALUE_${row - 1}`];
      updates.push({
        cell: updateCell,
        row,
        col: updateCell.colNumber,
        value: updateCell.expr,
      });
      // eslint-disable-next-line no-case-declarations
      const percentageCells = Object.values(cells).filter(
        c => c.alias === cell.alias && (isUndefined(c.isValid) || c.isValid === true) && c.key !== cell.key
      );
      // eslint-disable-next-line no-case-declarations
      const updatedCell = {
        ...cell,
        value: change.value,
      };

      percentageCells.push(updatedCell);

      percentageLinkedCellUpdate({
        cell: updatedCell,
        prevCells: cells,
        cellsUpdates: changes,
        percentageCells,
        MAX_PERCENTAGE: 1,
      });
    }
  });
  return [...changes, ...updates];
};

export default linkedCellUpdates;
