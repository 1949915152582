import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { debounce } from 'lodash';
import { currencyFormat } from 'common/formats/formats';
import formatNumbers from 'utillities/formatNumbers';
import { useExitEquityValueStyles } from '../styles';
import { IExitEquityValueTextFieldProps } from '../types';

const baseCurrencyFormat = currencyFormat({ fractionDigits: 0 });

const applyFormat = (value: string): string =>
  formatNumbers({
    format: baseCurrencyFormat,
    value,
    prefix: '',
    suffix: '',
    allowNegativeValue: false,
    currency: {
      code: 'USD',
      symbol: '$',
      units: '',
    },
    currencyCode: 'USD',
    blankIfNull: true,
    staticCurrency: null,
  });

const ExitEquityValueTextField: FC<IExitEquityValueTextFieldProps> = props => {
  const { exitEquityValue = '0', setExitEquityValue } = props;
  const [exitEquityValueFormated, setExitEquityValueFormated] = useState<string>(applyFormat(exitEquityValue));

  const classes = useExitEquityValueStyles();

  const debouncedSetData = useRef(
    debounce(async value => {
      setExitEquityValue(value);
    }, 500)
  ).current;

  useEffect(() => () => debouncedSetData.cancel(), [debouncedSetData]);

  const onChangeExitEquitityValue = (event: ChangeEvent<HTMLInputElement>) => {
    setExitEquityValueFormated(event.target.value);
    debouncedSetData(event.target.value);
  };

  const onBlurExitEquitityValue = () => {
    setExitEquityValueFormated(applyFormat(exitEquityValueFormated));
  };

  const onFocusExitEquitityValue = () => {
    setExitEquityValueFormated(Number.parseFloat(exitEquityValue) === 0 ? '' : exitEquityValue);
  };

  return (
    <Grid container item justifyContent="flex-end" alignItems="center" className={classes.exitEquitityValueContainer}>
      <Typography variant="overline" display="block" className={classes.exitEquitityValueLabel}>
        EXIT EQUITY VALUE
      </Typography>
      <TextField
        variant="outlined"
        className={classes.exitEquitityValueInput}
        onChange={onChangeExitEquitityValue}
        onBlur={onBlurExitEquitityValue}
        onFocus={onFocusExitEquitityValue}
        value={exitEquityValueFormated}
      />
    </Grid>
  );
};

export default ExitEquityValueTextField;
