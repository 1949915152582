/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useContext, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import { useStore } from 'common/store';
import { CustomSelect } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import withTooltip from 'components/WithTooltip';
import AllocationContext from 'context/AllocationContext';
import { CaptableService } from 'services';
// eslint-disable-next-line import/no-cycle
import reverseParser from '../utilities/reverseParser';

const useStyles = makeStyles(theme => ({
  root: {
    width: `calc(100% - ${theme.cellPadding * 2}px)`,
  },
  select: {
    color: `${theme.palette.primary.dark} !important`,
  },
  selectMenu: {
    height: 0,
  },
}));

const CapTableSelection = ({ cell, disabled, ...restProps }) => {
  const classes = useStyles();
  const [{ capTableList = [] }] = useStore();
  const { cells, columns, pageFieldAttributes } = useContext(FeaturedSpreadsheetContext);
  const { setColumns, primaryCaptable } = useContext(AllocationContext);
  const { onCommit, onRevert } = restProps;

  // 4. Expand all rows to avoid issues with the hidden ones
  const expandRows = () => {
    const rows = document.querySelectorAll('#allocation .data-grid tr');

    [...rows].forEach(row => {
      row.removeAttribute('hidden');
    });
  };

  // 3. Generate row config again
  const updateState = capTable => {
    const tmpParsedColumns = { ...cells };

    // Update state with the new cap table
    tmpParsedColumns[cell.key].value = capTable;

    // Get updated columns
    const tmpScenarios = reverseParser(tmpParsedColumns, columns, pageFieldAttributes);

    // Generate rowConfig again
    setColumns(tmpScenarios);

    expandRows();
  };

  // 2. map data
  const mapCapTable = selectedCapTable => {
    const newSecurities = selectedCapTable.securities.map(security => ({
      id: security.id,
      name: security.name,
      security_type: security.security_type,
      shares_outstanding: security.shares_outstanding,
      order: security.order,
    }));

    updateState({
      id: selectedCapTable.id,
      name: selectedCapTable.name,
      is_primary: selectedCapTable.is_primary,
      securities: newSecurities,
    });
  };

  const getCaptableInfo = async captableId => {
    const service = new CaptableService();
    const captableInfo = await service.dataById(captableId);
    if (!isEmpty(captableInfo)) {
      mapCapTable(captableInfo);
    }
  };

  // 1. Handle change
  const handleChange = async value => {
    // Load Cap Table data
    await getCaptableInfo(value);
  };

  useEffect(() => {
    if (!isEmpty(primaryCaptable) && isEmpty(cell.value)) {
      mapCapTable(primaryCaptable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryCaptable, cell.value]);

  const getOptions = useMemo(() => {
    const tmpOptions = [];

    if (Array.isArray(capTableList)) {
      capTableList.forEach(security => {
        tmpOptions.push({
          value: security.id,
          label: security.name,
        });
      });
    }

    return tmpOptions;
  }, [capTableList]);

  return (
    <CustomSelect
      id={`captable-${cell.key}`}
      className={`${classes.select} ${cell.alias}`}
      onEmptyValue="SELECT CAP TABLE"
      value={cell.value?.id || ''}
      onChange={handleChange}
      disabled={isEmpty(capTableList) || disabled}
      classes={{
        selectMenu: classes.selectMenu,
      }}
      options={getOptions}
      displayEmpty
      autoFocus
      disableUnderline
      openMenuOnFocus
      onCommit={onCommit}
      onRevert={onRevert}
    />
  );
};

CapTableSelection.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
  disabled: false,
};

CapTableSelection.propTypes = {
  cell: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default withTooltip(CapTableSelection);
