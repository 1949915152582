import React, { FC } from 'react';
import { Button, Typography } from '@material-ui/core';
import { SHOW_FULL_DESCRIPTION, SHOW_LESS_DESCRIPTION } from 'pages/Valuations/util/constants';
import useStyles from './styles';
import { CompanyDescriptionProps } from './types';

const COMPANY_DESCRIPTION_MAX_LENGTH = 1000;

const CompanyDescription: FC<CompanyDescriptionProps> = ({
  description,
  truncate,
  toggleDescription,
  maxCharsDisplay = COMPANY_DESCRIPTION_MAX_LENGTH,
}) => {
  const classes = useStyles();
  if (truncate) {
    return (
      <>
        <Typography>
          {description.slice(0, maxCharsDisplay)}
          {description.length > maxCharsDisplay && '... '}
        </Typography>
        {description.length > maxCharsDisplay && (
          <Button color="primary" onClick={toggleDescription} className={classes.showMoreLessBtn}>
            {SHOW_FULL_DESCRIPTION}
          </Button>
        )}
      </>
    );
  }
  return (
    <>
      <Typography>{description}</Typography>
      <Button color="primary" onClick={toggleDescription} className={classes.showMoreLessBtn}>
        {SHOW_LESS_DESCRIPTION}
      </Button>
    </>
  );
};
export default CompanyDescription;
