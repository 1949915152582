import { getObjectValue } from 'utillities';

export const ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_MOIC_TITLE = 'MOIC';
export const ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_NUMBER_OF_SHARES_TITLE = '# of Shares';
export const ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_SHARES_TITLE = 'Shares';
export const ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_VALUE_TITLE = 'Value';
export const ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_WEIGHTED_VALUE_PER_SHARE_TITLE = 'Weighted Value per Share';

export const ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADERS = Object.freeze({
  SECURITY_NAME: {
    columnNumber: 0,
    title: '',
  },
  WEIGHTED_VALUE_PER_SHARE: {
    columnNumber: 1,
    title: ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_WEIGHTED_VALUE_PER_SHARE_TITLE,
  },
  NUMBER_OF_SHARES: {
    columnNumber: 2,
    title: ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_NUMBER_OF_SHARES_TITLE,
  },
  VALUE: {
    columnNumber: 3,
    title: ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_VALUE_TITLE,
  },
  MOIC: {
    columnNumber: 4,
    title: ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_MOIC_TITLE,
  },
});

export const getAllocationWeightedShareValuesHeader = (colNumber: number) => {
  const allocationWeightedShareValueHeader = Object.values(ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADERS).find(
    header => header?.columnNumber === colNumber
  );

  return {
    ...getObjectValue(allocationWeightedShareValueHeader),
  };
};
