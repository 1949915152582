import BacksolveApproach from 'pages/Valuations/approaches/backsolveApproach/BacksolveApproach';
import createBacksolveSummaryData from 'pages/Valuations/approaches/backsolveApproach/BacksolveSummaryTable/createBacksolveSummaryData';
import createBacksolveTableData from 'pages/Valuations/approaches/backsolveApproach/BacksolveTable/createBacksolveTableData';
import { BACKSOLVE_TABLE_NAMES } from 'pages/Valuations/approaches/backsolveApproach/constants';
import createMarketAdjustmentData from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentTable/createMarketAdjustmentData';
import createOpmInputData from 'pages/Valuations/approaches/backsolveApproach/OPMInputTable/createOpmInputData';
import createSecurityTableData from 'pages/Valuations/approaches/backsolveApproach/SecurityTable/createSecurityTableData';
import { EMPTY_BASKET, getApproachTableName } from 'pages/ValuationsAllocation/util';

const createBacksolveConfig = ({
  approach,
  capTableList,
  captable,
  approaches,
  backsolveAttributes,
  isDisabled,
  measurementDate,
  financials,
}) => {
  const name = getApproachTableName({ approach });
  const attrs = backsolveAttributes.backsolveApproachAttrs;
  const backsolveAndPVAttrs = { ...backsolveAttributes.backsolveApproachAttrs, ...backsolveAttributes.shareValueAttrs };

  if (!approach.valuations_approach_backsolve.securities_basket) {
    // eslint-disable-next-line no-param-reassign
    approach.valuations_approach_backsolve.securities_basket = {
      ...EMPTY_BASKET,
    };
  }

  const backsolveSheetName = getApproachTableName({ approach, tableSuffix: BACKSOLVE_TABLE_NAMES.BACKSOLVE });

  const backsolveSheet = createBacksolveTableData({
    approach,
    capTableList,
    attrs: backsolveAndPVAttrs,
    tableName: backsolveSheetName,
    isDisabled,
  });
  const securitySheetName = getApproachTableName({ approach, tableSuffix: BACKSOLVE_TABLE_NAMES.SECURITIES });
  const securitiesBasket = approach.valuations_approach_backsolve.securities_basket;
  const securitySheet = createSecurityTableData({
    captable,
    tableName: securitySheetName,
    securitiesBasket,
    isDisabled,
  });

  const opmTableName = getApproachTableName({ approach, tableSuffix: BACKSOLVE_TABLE_NAMES.OPM_INPUTS });
  const opmInputSheet = createOpmInputData({
    attrs,
    tableName: opmTableName,
    data: approach.valuations_approach_backsolve,
    measurementDate,
    approaches,
    isDisabled,
  });

  const backsolveSummarySheet = createBacksolveSummaryData({ approach, attrs, isDisabled, financials });

  const marketAdjustmentSheet = createMarketAdjustmentData({
    approach,
    approaches,
    backsolveSummarySheet,
    attrs,
    isDisabled,
  });

  return {
    name,
    approach,
    value: approach.enterprise_value,
    spreadsheets: {
      backsolveSheet,
      securitySheet,
      backsolveSummarySheet,
      marketAdjustmentSheet,
      opmInputSheet,
    },
    enterpriseValueReference: marketAdjustmentSheet.name,
    component: BacksolveApproach,
  };
};

export default createBacksolveConfig;
