/* eslint-disable no-param-reassign */
import { isNull, isUndefined } from 'lodash';
import {
  BENCHMARK_MULTIPLE_VALUE_ALIAS,
  FINANCIALS_METRIC_VALUE_ALIAS,
  FUTURE_EQUITY_VALUE_TABLE_ID,
  GPC_MULTIPLE_VALUE_ALIAS,
  SPECIFIED_METRIC,
} from 'common/constants/allocations';
import { SELECTION_A, SELECTION_B } from 'common/constants/valuations';
import { largeCurrencyFormat } from 'common/formats/formats';
import {
  MEAN_LABEL,
  MEDIAN_LABEL,
  PERCENTILE_25,
  PERCENTILE_75,
  SPECIFIED_LABEL,
} from 'pages/Valuations/util/constants';
import { determineDatabaseOption as translateToStoredOption } from 'pages/Valuations/util/percentileOptionsTools';
import { getSelectionCellOptions } from 'pages/ValuationsAllocation/util/getSelectionCellOptions';
import { addClassName, getKey, removeClassName } from 'utillities';
import { METRIC_LABEL, MULTIPLE_LABEL, NO_FINANCIAL_STATEMENT, NO_GPC_MULTIPLES } from './constants';

// Keys to retrieve cells from tableCells
const BENCHMARK_MULTIPLE_SELECT_KEY = getKey(BENCHMARK_MULTIPLE_VALUE_ALIAS, 0, FUTURE_EQUITY_VALUE_TABLE_ID);
const FINANCIAL_METRIC_SELECT_KEY = getKey(FINANCIALS_METRIC_VALUE_ALIAS, 0, FUTURE_EQUITY_VALUE_TABLE_ID);
const BENCHMARK_MULTIPLE_VALUE_KEY = getKey(BENCHMARK_MULTIPLE_VALUE_ALIAS, 1, FUTURE_EQUITY_VALUE_TABLE_ID);
const FINANCIALS_METRIC_VALUE_KEY = getKey(FINANCIALS_METRIC_VALUE_ALIAS, 1, FUTURE_EQUITY_VALUE_TABLE_ID);

const handleMetric = ({
  alias,
  cell,
  ltmData,
  selectedMetric,
  enableCell,
  enableOrDisableCell,
  metricValue,
  tableCells,
  mapNumberToMetricKey,
  readOnlyProps,
  specifiedMultipleOptionPosition,
}) => {
  if (alias === FINANCIALS_METRIC_VALUE_ALIAS && cell.colAlias === 'value') {
    if (!isUndefined(ltmData)) {
      const financialMetrics = {
        ebitda: ltmData.ebitda,
        revenue: ltmData.total_revenue,
      };
      // if there are multiples, but haven't selected any yet
      if (selectedMetric.value === '') {
        // consider that there are future exit scenarios already stored
        enableOrDisableCell({ queriedValue: metricValue, isMetric: true, cellKey: cell.key });
        // if not using select for Metric or select is on 'Other'
      } else if ([SPECIFIED_METRIC, METRIC_LABEL].includes(selectedMetric.value)) {
        enableCell(cell.key);
        /* set multiple to 'Other' if Metric is 'Other, because multiples are related
        to the metric used. Thus, we have to input multiple manually if metric is 'Other'.
        If not using the Metric selector, use a regular label. */
        tableCells[BENCHMARK_MULTIPLE_SELECT_KEY].value
          = selectedMetric.value === SPECIFIED_METRIC ? MULTIPLE_LABEL : specifiedMultipleOptionPosition;
      } else {
        const metricKey = mapNumberToMetricKey[selectedMetric.value];
        const requiredValue = financialMetrics[metricKey];
        tableCells[cell.key] = {
          ...readOnlyProps,
          value: requiredValue,
          format: largeCurrencyFormat,
        };
      }
    } else if (!cell.value) {
      tableCells[cell.key] = {
        ...tableCells[cell.key],
        tooltipMessages: [NO_FINANCIAL_STATEMENT],
        className: addClassName(cell.className, 'warning'),
      };
    } else {
      tableCells[cell.key] = {
        ...tableCells[cell.key],
        tooltipMessages: null,
        className: removeClassName(cell.className, 'warning'),
      };
    }
  }
};

const handleMultiples = ({
  alias,
  cell,
  benchmarkData,
  mapNumberToMetricKey,
  selectedMetric,
  enableCell,
  selectedMultiple,
  enableOrDisableCell,
  multipleValue,
  tableCells,
  readOnlyProps,
  specifiedMultipleOptionPosition,
  availableOptions,
}) => {
  const { approach_multiples, percentile_selection_a, percentile_selection_b } = benchmarkData;
  const approachPercentiles = { percentile_selection_a, percentile_selection_b };
  const mapLabelToMultipleKey = new Map([
    [SELECTION_A, 'percentile_a'],
    [SELECTION_B, 'percentile_b'],
    [MEAN_LABEL, 'mean'],
    [MEDIAN_LABEL, 'median'],
    [PERCENTILE_25, 'twenty_fifth'],
    [PERCENTILE_75, 'seventy_fifth'],
  ]);

  if (alias === BENCHMARK_MULTIPLE_VALUE_ALIAS && cell.colAlias === 'value') {
    if (approach_multiples) {
      const validMultiples = {
        ebitda: { ...approach_multiples.ltm_ebitda_multiples },
        revenue: { ...approach_multiples.ltm_revenue_multiples },
      };
      const metricKey = mapNumberToMetricKey[selectedMetric.value];
      const specifiedIsSelected = [
        specifiedMultipleOptionPosition,
        MULTIPLE_LABEL,
        specifiedMultipleOptionPosition.toString(),
      ].includes(selectedMultiple.value);

      if (selectedMultiple.value === '') {
        enableOrDisableCell({ queriedValue: multipleValue, cellKey: cell.key });
      } else if (specifiedIsSelected) {
        enableCell(cell.key);
      } else {
        const option = translateToStoredOption(
          { options: availableOptions, value: selectedMultiple.value },
          approachPercentiles
        );
        const multipleKey = mapLabelToMultipleKey.get(option);
        const requiredValue = validMultiples[metricKey][multipleKey];
        tableCells[cell.key] = {
          ...readOnlyProps,
          value: requiredValue,
          suffix: 'x',
        };
      }
    } else if (!cell.value) {
      tableCells[cell.key] = {
        ...tableCells[cell.key],
        tooltipMessages: [NO_GPC_MULTIPLES],
        className: addClassName(cell.className, 'warning'),
      };
    } else {
      tableCells[cell.key] = {
        ...tableCells[cell.key],
        tooltipMessages: null,
        className: removeClassName(cell.className, 'warning'),
      };
    }
  }
};

const conditions = ({
  cell,
  tableCells,
  tableData = {}, // this is multiples or external data
}) => {
  const { alias } = cell || {};

  const { ltmData, ...benchmarkData } = tableData;

  const cleanConditionProps = {
    ...cell,
    hidden: true,
    readOnly: true,
    forceComponent: false,
    disableEvents: false,
  };
  const readOnlyProps = {
    ...cleanConditionProps,
    hidden: false,
    format: {},
    value: '',
  };

  const setAsReadOnly = isMetric => {
    tableCells[cell.key] = {
      ...readOnlyProps,
      ...(isMetric && { format: largeCurrencyFormat }),
    };
  };

  const enableCell = (key, isRequired = true, readOnly = false) => {
    const currentValue = !isNull(cell.value) ? Number(cell.value) : null;

    tableCells[key] = {
      ...cell,
      hidden: false,
      readOnly,
      isRequired,
      value: currentValue,
      tooltipMessages: null,
      ...(cell.alias === GPC_MULTIPLE_VALUE_ALIAS && { format: {}, suffix: 'x' }),
      ...(cell.alias === FINANCIALS_METRIC_VALUE_ALIAS && !isNull(cell.value) && { format: largeCurrencyFormat }),
    };
  };

  const enableOrDisableCell = ({ queriedValue, cellKey, isMetric = false }) =>
    queriedValue === '' ? setAsReadOnly(isMetric) : enableCell(cellKey);

  const mapNumberToMetricKey = {
    0: 'revenue',
    1: 'ebitda',
  };

  const multipleValue = tableCells[BENCHMARK_MULTIPLE_VALUE_KEY];
  const metricValue = tableCells[FINANCIALS_METRIC_VALUE_KEY];
  const selectedMultiple = tableCells[BENCHMARK_MULTIPLE_SELECT_KEY];
  const selectedMetric = tableCells[FINANCIAL_METRIC_SELECT_KEY];

  const { percentile_selection_a, percentile_selection_b } = benchmarkData;
  const approachPercentiles = { percentile_selection_a, percentile_selection_b };
  const availableOptions = getSelectionCellOptions({
    specificApproach: approachPercentiles,
  });
  const specifiedMultipleOptionPosition = availableOptions?.indexOf(SPECIFIED_LABEL);

  const handleMetricAndMultipleProps = {
    alias,
    cell,
    ltmData,
    selectedMetric,
    tableCells,
    mapNumberToMetricKey,
    specifiedMultipleOptionPosition,
    readOnlyProps,
    enableCell,
    enableOrDisableCell,
  };

  //  Metric Row
  handleMetric({
    ...handleMetricAndMultipleProps,
    metricValue,
  });

  // Multiple row
  handleMultiples({
    ...handleMetricAndMultipleProps,
    multipleValue,
    selectedMultiple,
    benchmarkData,
    availableOptions,
  });

  // disable Multiple select events if Metric is not selected
  if (alias === GPC_MULTIPLE_VALUE_ALIAS && cell.colAlias === 'title' && !isUndefined(tableData)) {
    tableCells[cell.key] = {
      ...cell,
      disableEvents: selectedMetric.value === '',
    };
  }
};

export default conditions;
