import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty, isNil } from 'lodash';
import {
  UPDATE_VALUATION_DEFAULT_ERROR_MESSAGE,
  UPDATE_VALUATION_DEFAULT_SUCCESS_MESSAGE,
  VALUATION_BY_ALLOCATION_QUERY_KEY,
} from 'common/constants/services/valuations';
import { ValuationService } from 'services';
import { throwError } from 'utillities';
import { UpdateValuationParams, UpdateValuationResponse } from './types';

const updateValuation = async (params: UpdateValuationParams) => {
  const { valuation } = params;

  if (isEmpty(valuation) || isNil(valuation?.id)) return throwError(UPDATE_VALUATION_DEFAULT_ERROR_MESSAGE);

  const valuationService = new ValuationService();

  try {
    const updatedValuation = await valuationService.updateValuation(valuation);

    if (updatedValuation?.id)
      return {
        valuation: updatedValuation,
        responseMessage: UPDATE_VALUATION_DEFAULT_SUCCESS_MESSAGE,
      } as UpdateValuationResponse;

    return throwError(UPDATE_VALUATION_DEFAULT_ERROR_MESSAGE);
  } catch (error) {
    return throwError(UPDATE_VALUATION_DEFAULT_ERROR_MESSAGE);
  }
};

const useUpdateValuation = () => {
  const queryClient = useQueryClient();

  const updateValuationMutation = useMutation<UpdateValuationResponse, Error, UpdateValuationParams>({
    mutationFn: params => updateValuation(params),
  });

  const { data, isError, isLoading, isSuccess, mutateAsync } = updateValuationMutation;

  if (isSuccess) {
    // Invalidate the queries to refetch the updated data of the Valuation
    queryClient.invalidateQueries({
      queryKey: [
        VALUATION_BY_ALLOCATION_QUERY_KEY, // Valuation by Allocation
      ],
    });

    // Clean the mutation internal state
    updateValuationMutation.reset();
  }

  return {
    data,
    isError,
    isLoading,
    isSuccess,
    mutateAsync,
  };
};

export default useUpdateValuation;
