import React from 'react';
import { getEnterpriseValueFormula } from 'common/formulas/math.js/ENTERPRISE_AND_EQUITY_VALUE';
import GridRowLabel from 'components/Grid/GridRowLabel';
import {
  BACKSOLVE_SUMMARY_TITLE,
  ENTERPISE_VALUE_TITLE,
  IMPLIED_EQUITY_VALUE_TITLE,
} from 'pages/Valuations/approaches/backsolveApproach/BacksolveSummaryTable/util/constants';
import { TABLE_HEADER_CONFIG } from 'pages/Valuations/approaches/backsolveApproach/constants';

export default async ({ columns, rowConfig, tableData }) => {
  let cells = {};
  const getExpr = (expr, columnLegend) => {
    if (expr) {
      return expr.replace(/@/g, `${columnLegend}`);
    }
    return '';
  };
  cells.A1 = {
    ...TABLE_HEADER_CONFIG,
    key: 'A1',
    value: BACKSOLVE_SUMMARY_TITLE,
    rowNumber: 1,
    colSpan: 2,
    expr: BACKSOLVE_SUMMARY_TITLE,
    largeHeader: true,
    checkPrevCol: true,
    valueViewer: props => <GridRowLabel {...props} unitsFormatter currencyFormatter formatterCell />,
  };
  cells.A2 = {
    key: 'A2',
    className: 'name-table',
    rowNumber: 2,
    readOnly: false,
    dbType: 'string',
    expr: IMPLIED_EQUITY_VALUE_TITLE,
    value: IMPLIED_EQUITY_VALUE_TITLE,
  };
  cells.A3 = {
    key: 'A3',
    className: 'name-table',
    rowNumber: 3,
    readOnly: false,
    dbType: 'string',
    expr: ENTERPISE_VALUE_TITLE,
    value: ENTERPISE_VALUE_TITLE,
  };

  const VALUE_LEGEND = 'B';

  rowConfig.forEach((row, rowIdx) => {
    const rowNumber = rowIdx + 1;
    const col = columns[1];
    const columnLegend = VALUE_LEGEND;
    const key = columnLegend + rowNumber;
    let expr = getExpr(row.expr, columnLegend);
    const value = col[row.alias];
    const customKey = row.alias;

    if (tableData.approach.financial_statement !== tableData.primaryFinancials?.id && key === 'B3') {
      const cash = `constants.ltm_cash_${tableData.approach.financial_statement}`;
      const debt = `constants.ltm_debt_${tableData.approach.financial_statement}`;
      expr = `=${getEnterpriseValueFormula('B2', cash, debt)}`;
    }

    cells = {
      ...cells,
      [key]: {
        ...row,
        expr,
        key,
        columnLegend,
        value,
        className: '',
        customKey,
      },
    };
  });
  return cells;
};
