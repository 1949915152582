import { AppliedMethodology } from 'api';
import { BacksolveApproach } from 'common/types/valuation';
import {
  ALLOCATION_SCENARIO_METHOD_OPM,
  ALLOCATION_SCENARIO_METHODS_NAMES,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import { getArrayValue, getNumberValue, getObjectValue } from 'utillities';

const getBacksolveScenarioMethods = (backsolveValuationApproach?: BacksolveApproach) => {
  const { applied_methodologies: backsolveAppliedMethodologies } = getObjectValue(backsolveValuationApproach);

  const backsolveAllocationMethods = getArrayValue(
    backsolveAppliedMethodologies?.map(
      appliedMethodology =>
        getNumberValue(appliedMethodology?.allocation_method) as AppliedMethodology['allocation_method']
    )
  );

  const backsolveScenarioMethodsNames = backsolveAllocationMethods
    .map(allocationMethod => ALLOCATION_SCENARIO_METHODS_NAMES[allocationMethod])
    .join(', ');

  const isBacksolveWithOPM = backsolveAllocationMethods.includes(ALLOCATION_SCENARIO_METHOD_OPM);

  return {
    backsolveAllocationMethods,
    backsolveScenarioMethodsNames,
    isBacksolveWithOPM,
  };
};

export default getBacksolveScenarioMethods;
