import React, { useMemo } from 'react';
import { useFormat } from 'common/hooks';
import { CustomScalarSpreadsheet } from 'components';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { getArrayValue } from 'utillities';

const ValuesPerShareTable = ({ onChange, valuesPerShareSheet, workbook }: any) => {
  const [format, formatDispatch] = useFormat({
    page: 'captable',
    units: REGULAR_UNIT,
  });

  const valuesPerShareSheetProps = useMemo(() => {
    if (valuesPerShareSheet)
      return {
        ...valuesPerShareSheet,
        data: getArrayValue(valuesPerShareSheet?.data),
        format,
        formatDispatch,
        onChange,
        sheet: valuesPerShareSheet,
        workbook,
      } as unknown as typeof ScalarSpreadsheet;

    return null;
  }, [format, formatDispatch, onChange, valuesPerShareSheet, workbook]);

  return (
    <>
      {valuesPerShareSheetProps && valuesPerShareSheet && (
        <CustomScalarSpreadsheet {...valuesPerShareSheetProps} isLoading={false} />
      )}
    </>
  );
};
export default ValuesPerShareTable;
