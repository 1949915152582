import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { OTHER } from 'common/constants/process-management';
import { useStore } from 'common/store';
import { TaskContext } from 'context';
import { useDocuments } from 'services/hooks';
import DocumentList from './DocumentList';
import DocumentUploadModal from './DocumentUploadModal';
import MobileProgressList from './MobileProgressList';
import SelectionModal from './SelectionModal';

const DocumentUploads = ({ pairedQuestionnaire }) => {
  const [progress, setProgress] = useState(0);
  const [openSelectionModal, setOpenSelectionModal] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [documentsToShow, setDocumentsToShow] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showUploadBox, setShowUploadBox] = useState(true);
  const { task, isMobile, setCompanyDocuments, companyDocuments, validateToken } = useContext(TaskContext);
  const { isUploading, uploadRequestedFiles, getDocuments } = useDocuments();
  const { requested_files: requestedFiles } = task;
  const { company_id: companyId } = task.task;
  const { id: mdId } = task.task.measurement_date;
  const [{ user }] = useStore();

  const handleGetDocuments = async () => {
    if (!mdId || !companyId) return;
    try {
      const documents = await getDocuments(mdId, companyId);
      setCompanyDocuments(documents[0].files);
    } catch (e) {
      throw new Error(e);
    }
  };

  const uploadToOtherRequestedFile = useCallback(() => {
    const otherRequestedFile = requestedFiles.find(item => item.name === OTHER) || { id: OTHER, name: OTHER };
    const requestedFilesIds = [otherRequestedFile.id];
    filesToUpload.forEach(async fileToUpload => {
      await uploadRequestedFiles({
        droppedFile: fileToUpload.file,
        requestedFilesIds,
        task,
        setUploadedFiles,
      });
      validateToken();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToUpload, requestedFiles, setUploadedFiles, task, uploadRequestedFiles]);

  useEffect(() => {
    if (user) {
      handleGetDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles, user]);

  useEffect(() => {
    const answered = requestedFiles.filter(item => item.is_uploaded).length;
    setProgress((answered / requestedFiles.length) * 100);
    if (isMobile) {
      setDocumentsToShow([]);
    } else {
      setDocumentsToShow(requestedFiles);
    }
  }, [isMobile, requestedFiles, uploadedFiles, companyDocuments]);

  useEffect(() => {
    if (task.task.is_done) {
      setShowUploadBox(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task.task.is_done]);

  const documentUploadsProps = {
    isUploading,
    setOpenSelectionModal,
    progress,
    requestedFiles,
    openSelectionModal,
    filesToUpload,
    setFilesToUpload,
    uploadToOtherRequestedFile,
    documentsToShow,
    companyDocuments,
    showUploadBox,
    setShowUploadBox,
    pairedQuestionnaire,
  };

  return (
    <>
      <Grid container spacing={isMobile ? 0 : 2} style={isMobile ? { marginTop: '2rem' } : {}}>
        <Grid item sm={6} xs={12}>
          <Typography variant="h1" gutterBottom>
            <strong>Document</strong> Uploads
          </Typography>
          {!isMobile ? (
            <DocumentUploadModal {...documentUploadsProps} />
          ) : (
            <MobileProgressList
              progress={progress}
              itemsToShow={documentsToShow}
              setItemsToShow={setDocumentsToShow}
              quantityParam={0}
              itemList={requestedFiles}
              itemCategory="Documents"
            />
          )}
        </Grid>
        <Grid item sm={6} xs={12}>
          <DocumentList {...documentUploadsProps} />
        </Grid>
      </Grid>
      {openSelectionModal && (
        <SelectionModal
          {...documentUploadsProps}
          uploadRequestedFiles={uploadRequestedFiles}
          open={openSelectionModal}
          setUploadedFiles={setUploadedFiles}
        />
      )}
    </>
  );
};

DocumentUploads.propTypes = {
  pairedQuestionnaire: PropTypes.shape({}),
};

export default DocumentUploads;
