import { FUND_ALIAS, SECURITY_ALIAS, SHARES_ALIAS } from 'common/constants/fund-ownership';
import { IS_REQUIRED_ERROR, SHARES_EXCEEDS_CAPTABLE_SHARES } from 'common/constants/messages/validations';
import { sumArrayValues, toString } from 'utillities';

const getOwnershipTotalShares = (cellsToValidate, securityId) => {
  const fundOwnershipShares = [];

  cellsToValidate.forEach(item => {
    if (item.alias === SHARES_ALIAS && item.value) {
      if (toString(item.relatedSecurity) === toString(securityId)) {
        fundOwnershipShares.push(Number(item.value));
      }
    }
  });

  return sumArrayValues(fundOwnershipShares);
};

const customValidations = ({ cell, cellsToValidate, tableData, addFeedback }) => {
  const { capTable: capTableInfo } = tableData || {};
  const { alias, relatedSecurity } = cell;

  switch (alias) {
    case SECURITY_ALIAS:
    case FUND_ALIAS:
      if (!cell.readOnly && cell.value === 0) {
        addFeedback(IS_REQUIRED_ERROR);
      }
      break;
    case SHARES_ALIAS:
      if (cellsToValidate?.length && capTableInfo) {
        const { securities } = capTableInfo;
        const captableSecurity = securities?.find(s => toString(s.id) === toString(relatedSecurity));
        if (
          captableSecurity
          && getOwnershipTotalShares(cellsToValidate, relatedSecurity) > Number(captableSecurity.shares_outstanding)
        ) {
          addFeedback(SHARES_EXCEEDS_CAPTABLE_SHARES);
        }
      }
      break;
    default:
      break;
  }
};

export default customValidations;
