// Return the previous column base on the current one (Current: B => Previous: A)
const getPrevQuarterColumn = ({ columnIndex, rowNumber, alphabet, cells }) => {
  const prevColLegend = alphabet[columnIndex - 1];

  /* If the previous column is a year we need to use the previous column to that
    which will the last quarter for that year */
  if (cells[prevColLegend + rowNumber]?.isParent) {
    return alphabet[columnIndex - 2];
  }

  return prevColLegend;
};

export default getPrevQuarterColumn;
