import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import CustomTextField from 'components/CustomTextField';
import { useFormValidation } from 'services/hooks/useFormValidation';

const constraints = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 3, message: 'must be at least 3 characters' },
  },
};

const FinancialsForm = ({ initialValues, onFormChange, tableTerms }) => {
  const { formValidated, validateForm } = useFormValidation(constraints);
  const [formValues, setFormValues] = useState(initialValues);
  const { errors } = formValidated;

  useEffect(() => {
    validateForm(formValues);
  }, [validateForm, formValues]);

  useEffect(() => {
    onFormChange(formValidated);
  }, [onFormChange, formValidated]);

  const handleChange = event => {
    event.persist();
    const {
      target: { name: fieldName, value },
    } = event;

    setFormValues({
      ...formValues,
      [fieldName]: value,
    });
  };

  return (
    <Grid container>
      <CustomTextField
        required
        fullWidth
        id="name"
        name="name"
        type="text"
        label={`${tableTerms.tableName} name`}
        value={formValues?.name || ''}
        onChange={handleChange}
        formErrors={errors}
      />
    </Grid>
  );
};

FinancialsForm.defaultProps = {
  initialValues: {},
};

FinancialsForm.propTypes = {
  initialValues: PropTypes.object,
  onFormChange: PropTypes.func.isRequired,
  tableTerms: PropTypes.object.isRequired,
};

export default FinancialsForm;
