import moment from 'moment';
import {
  FINANCIALS_PERFORMANCE_METRICS_LTM_REVENUE_GROWTH_ACURATE_TOOLTIP,
  FISCAL_YEAR,
  INCOME_STATEMENT_SHEET_NAME,
  LTM,
  TOTAL_REVENUE_ROW_NUMBER,
} from 'common/constants/financials';
import { NOT_APPLICABLE } from 'common/constants/general';
import {
  CalculateComparisonPeriodRevenueParams,
  GenerateColumnKeys,
  GenerateDates,
  GetLTMRevenueGrowthRate,
  NAValue,
} from './types';
import { FinancialsPerformanceMetricsColumn } from '../../../../common/types/financials';

export const QUARTER_AND_YEAR_COLUMN = 5; // Every year has 4 Quarters Columns + 1 Year column

export const NA_VALUE: NAValue = tooltipMessages => ({
  expr: '',
  format: null,
  gridType: 'string',
  ignoreExpr: true,
  tooltipMessages,
  value: NOT_APPLICABLE,
});

const GET_FISCAL_YEAR_COLUMN = (historicalFiscalYears: number) => historicalFiscalYears * QUARTER_AND_YEAR_COLUMN - 1; // To return the position of the Year column

const generateColumnKeys: GenerateColumnKeys = params => {
  const { alphabet, beforeLastHistoricalFiscalYear, lastHistoricalFiscalYear, LTMColumnIndex } = params;

  // const keyDifference = historicalPeriodIsAfterEndFiscal ? QUARTER_AND_YEAR_COLUMN : 0;
  // Columns Legends
  const beforeLastYearTotalRevenueColumnLegend = alphabet[beforeLastHistoricalFiscalYear];
  const lastYearTotalRevenueColumnLegend = alphabet[lastHistoricalFiscalYear];
  const LTMColumnLegend = alphabet[LTMColumnIndex];

  // Columns Keys
  const beforeLastYearTotalRevenueKey = beforeLastYearTotalRevenueColumnLegend + TOTAL_REVENUE_ROW_NUMBER;
  const lastYearTotalRevenueKey = lastYearTotalRevenueColumnLegend + TOTAL_REVENUE_ROW_NUMBER;
  const LTMKey = LTMColumnLegend + TOTAL_REVENUE_ROW_NUMBER;

  return {
    beforeLastYearTotalRevenueKey,
    lastYearTotalRevenueKey,
    LTMKey,
  };
};

function getPreviousYear(
  LTMColumn: FinancialsPerformanceMetricsColumn,
  historicalFiscalYears: FinancialsPerformanceMetricsColumn[],
  yearLTMDate: string,
  yearsPrevious: number
) {
  if (LTMColumn?.year) {
    const compYear = parseInt(yearLTMDate, 10) - yearsPrevious;
    const previousHistoricalYear = historicalFiscalYears
      .filter(({ year }) => year)
      .find(({ year }) => {
        if (!year) return false;
        return parseInt(year, 10) === compYear;
      }) || { year: compYear.toString() };
    return previousHistoricalYear;
  }
  return {};
}

const generateDates: GenerateDates = params => {
  const { columns, incomeStatementColumns, fiscalYearData, historicalFiscalYears, LTMColumnIndex } = params;

  const LTMColumn = columns[LTMColumnIndex];
  const ltmIncomeStatementColumn = incomeStatementColumns[LTMColumnIndex];

  const [yearLTMDate, monthLTMDate, dayLTMDate] = ltmIncomeStatementColumn?.reported_statement_date?.split('-')
    ?? LTMColumn?.statement_date?.split('-') ?? ['', '', '']; // YYYY-MM-DD
  const ltmDate = moment(`${monthLTMDate}-${dayLTMDate}-${yearLTMDate}`, 'MM-DD-YYYY');

  const fiscalYearEnd = moment(
    `${fiscalYearData?.fiscal_year_end?.end_month}-${fiscalYearData?.fiscal_year_end?.end_day}-${yearLTMDate}`,
    'MM-DD-YYYY'
  );
  const yearOffset = fiscalYearEnd.isBefore(ltmDate) ? [0, 1] : [1, 2];

  const beforeLastYearLTM = getPreviousYear(LTMColumn, historicalFiscalYears, yearLTMDate, yearOffset[1]);
  const lastYearLTM = getPreviousYear(LTMColumn, historicalFiscalYears, yearLTMDate, yearOffset[0]);
  const beforeLastYearLTMDate = `${monthLTMDate}-${dayLTMDate}-${ltmDate.year() - 2}`; // MM-DD-YYYY
  const lastYearLTMDate = `${monthLTMDate}-${dayLTMDate}-${ltmDate.year() - 1}`; // MM-DD-YYYY
  const isMissingHistoricalYear = beforeLastYearLTM.period_type === undefined;

  // Get the Historical Period as Date
  const historicalPeriod = isMissingHistoricalYear ? lastYearLTMDate : beforeLastYearLTMDate;
  const historicalDate = moment(historicalPeriod);

  // Check if the Historical period is after the End Fiscal year
  const historicalPeriodIsAfterEndFiscal = ltmDate.isAfter(fiscalYearEnd);
  const lastYearIndex = historicalFiscalYears.indexOf(lastYearLTM) + 1;
  const beforeLastYearIndex = historicalFiscalYears.indexOf(beforeLastYearLTM) + 1;
  const lastYearColumnIndex = GET_FISCAL_YEAR_COLUMN(lastYearIndex);
  const beforeLastYearColumnIndex = GET_FISCAL_YEAR_COLUMN(beforeLastYearIndex);

  // mid period fiscal year end
  const midPeriodFiscalYearEndYearDifference = historicalPeriodIsAfterEndFiscal ? 1 : 2;
  const midPeriodFiscalYearEnd = fiscalYearEnd
    .clone()
    .subtract(midPeriodFiscalYearEndYearDifference, 'years')
    .format('MM-DD-YYYY');
  const historicalFiscalEndDate = isMissingHistoricalYear
    ? lastYearColumnIndex - 1 // If there is only one historical period use the LTM Date - 1 year column
    : beforeLastYearColumnIndex - 1; // If there are more than one historical period use the LTM Date - 2 year column

  // If LTM Date is the End of Fiscal Year
  const isEndOfFiscalYear = fiscalYearEnd.isSame(ltmDate);
  return {
    beforeLastYearLTMDate,
    beforeLastYearColumnIndex,
    historicalDate,
    historicalFiscalEndDate,
    historicalPeriodIsAfterEndFiscal,
    isEndOfFiscalYear,
    lastYearColumnIndex,
    lastYearLTMDate,
    yearLTMDate,
    isMissingHistoricalYear,
    midPeriodFiscalYearEnd,
  };
};

const calculateComparisonPeriodRevenue = (params: CalculateComparisonPeriodRevenueParams) => {
  const { isMissingHistoricalYear, secondPartRevenue, firstPartRevenue, isEndOfFiscalYear, lastYearTotalRevenueKey }
    = params;

  let comparisonPeriodRevenue = isMissingHistoricalYear
    ? secondPartRevenue // If there is only one historical period use the LTM Date - 1 year Revenue part
    : `SUM(${firstPartRevenue}, ${secondPartRevenue})`; // If there are more than one historical period use the LTM Date - 2 year Revenue part + LTM Date - 1 year Revenue part

  // If LTM Date is the End of Fiscal Year and there are more than one historical period
  if (!isMissingHistoricalYear && isEndOfFiscalYear) {
    comparisonPeriodRevenue = `${INCOME_STATEMENT_SHEET_NAME}.${lastYearTotalRevenueKey}`; // Comparison Period Revenue = LTM Date - 1 year Total Revenue
  }

  return { comparisonPeriodRevenue };
};

const getLTMRevenueGrowthRate: GetLTMRevenueGrowthRate = params => {
  const { alphabet, columns, incomeStatementColumns, fiscalYearData, format, gridType, ignoreExpr, value } = params;

  const tooltipMessages = [] as string[];
  let expr = '';

  const historicalFiscalYears = columns.filter(column => column?.period_type === FISCAL_YEAR && column?.isPrevYear);

  // If there are more than one historical period
  if (historicalFiscalYears.length > 0) {
    const LTMColumnIndex = columns.findIndex(
      column => column?.period_type === LTM && column?.isLTM && column?.isLTMYear
    );

    const ltmColumn = columns[LTMColumnIndex];
    if (!ltmColumn?.year) {
      return NA_VALUE(tooltipMessages);
    }
    // Dates
    const {
      beforeLastYearLTMDate,
      isEndOfFiscalYear,
      lastYearLTMDate,
      yearLTMDate,
      lastYearColumnIndex,
      beforeLastYearColumnIndex,
      isMissingHistoricalYear,
      midPeriodFiscalYearEnd,
    } = generateDates({
      incomeStatementColumns,
      columns,
      fiscalYearData,
      historicalFiscalYears,
      LTMColumnIndex,
    });

    // Columns Keys
    const { beforeLastYearTotalRevenueKey, lastYearTotalRevenueKey, LTMKey } = generateColumnKeys({
      alphabet,
      beforeLastHistoricalFiscalYear: beforeLastYearColumnIndex,
      lastHistoricalFiscalYear: lastYearColumnIndex,
      LTMColumnIndex,
    });

    const historicalPeriosFiscalEndDate = `"${midPeriodFiscalYearEnd}"`;

    // YEARFRAC(LTM Date - 2 years, End Date of LTM Date - 2 years, 3) * LTM Date - 2 years Total Revenue
    const firstPartYearFrac = `YEARFRAC("${beforeLastYearLTMDate}", ${historicalPeriosFiscalEndDate}, 3)`;
    const firstPartRevenue = `(${firstPartYearFrac} * (${INCOME_STATEMENT_SHEET_NAME}.${beforeLastYearTotalRevenueKey}))`;

    // YEARFRAC(End Date of LTM Date - 2 years, LTM Date - 1 year, 3) * LTM Date - 1 year Total Revenue
    const secondPartYearFrac = `YEARFRAC(${historicalPeriosFiscalEndDate}, "${lastYearLTMDate}", 3)`;
    const secondPartRevenue = `(${secondPartYearFrac} * ${INCOME_STATEMENT_SHEET_NAME}.${lastYearTotalRevenueKey})`;

    // If there is only one historical period display the tooltip message
    if (isMissingHistoricalYear) {
      tooltipMessages.push(FINANCIALS_PERFORMANCE_METRICS_LTM_REVENUE_GROWTH_ACURATE_TOOLTIP);

      // If there is only one historical period and the LTM Date is the End of Fiscal Year display N/A
      if (isEndOfFiscalYear) return NA_VALUE(tooltipMessages);
    }

    // LTM Total Revenue
    const LTMTotalRevenue = `${INCOME_STATEMENT_SHEET_NAME}.${LTMKey}`;

    const { comparisonPeriodRevenue } = calculateComparisonPeriodRevenue({
      isMissingHistoricalYear,
      secondPartRevenue,
      firstPartRevenue,
      isEndOfFiscalYear,
      yearLTMDate,
      beforeLastYearTotalRevenueKey,
      lastYearTotalRevenueKey,
    });

    // LTM Revenue Growth = (LTM Total Revenue / Comparison Period Revenue) - 1
    expr = `=IF(OR(${comparisonPeriodRevenue} == 0, ${LTMTotalRevenue} == 0), "${NOT_APPLICABLE}", (${LTMTotalRevenue} / ${comparisonPeriodRevenue}) - 1)`;
  }
  // Without any historical period
  else return NA_VALUE(tooltipMessages);

  return {
    expr,
    format,
    gridType,
    ignoreExpr,
    tooltipMessages,
    value,
  };
};

export default getLTMRevenueGrowthRate;
