import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';

const useStyles = makeStyles(() => ({
  spreadsheet: {
    '& td.table-header': {
      height: '53px',
      whiteSpace: 'normal !important',
    },
  },
}));

const DCFSummaryTable = ({ format, formatDispatch, spreadsheets, onChange, workbook }) => {
  const dcfSummarySpreadsheet = spreadsheets.dcfSummary;

  const classes = useStyles();

  return (
    <div>
      <ScalarSpreadsheet
        {...dcfSummarySpreadsheet}
        className={classes.spreadsheet}
        key={dcfSummarySpreadsheet.name}
        onChange={onChange}
        sheet={dcfSummarySpreadsheet}
        workbook={workbook}
        format={format}
        formatDispatch={formatDispatch}
      />
    </div>
  );
};

DCFSummaryTable.propTypes = {
  spreadsheets: PropTypes.object,
  onChange: PropTypes.func,
  workbook: PropTypes.object,
  format: PropTypes.object,
  formatDispatch: PropTypes.func,
};

export default DCFSummaryTable;
