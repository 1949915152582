import { isEmpty } from 'lodash';
import { Cells } from 'common/types/scalarSpreadsheet';
import { ValuationsApproach } from 'common/types/valuation';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import {
  ALLOCATION_METHOD_OPM_TABLE_TERMS,
  FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES,
} from 'pages/ValuationsAllocation/common/constants/futureExit/sheetConfigs';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getNumberValue, getObjectValue } from 'utillities';
import { createColumns, customParser, customReverseParser, rowConfig } from './config';
import { CustomParserParams } from './config/customParser';
import { CustomReverseParserParams } from './config/customReverseParser';
import { AllocationMethodCellCustomData, CreateAllocationMethodConfigParams } from '../types';
import { customAfterCellsChanged } from '../utils/customAfterCellsChanged';
import { Change } from '../utils/types';
import { cellsShouldBeDisabled } from '../utils/utils';

const createAllocationMethodOPMConfig = (params: CreateAllocationMethodConfigParams) => {
  const { approach, valuationsApproachFutureExit, approaches, isDisabled, measurementDate, futureExitRiskFreeRate }
    = params;
  const name = getApproachTableName({
    approach,
    tableSuffix: FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES.ALLOCATION_METHOD_VALUE_OPM,
  });

  const { allocation_method: allocationMethod } = getObjectValue(valuationsApproachFutureExit);

  const currentAllocationMethod = getNumberValue(allocationMethod);

  const isDisabledCells = cellsShouldBeDisabled({ currentAllocationMethod, approach, isDisabled });

  const columns = createColumns({ valuationsApproachFutureExit, riskFreeRate: getNumberValue(futureExitRiskFreeRate) });

  const config = rowConfig({ isDisabled: isDisabledCells });

  const parser = (parserParams: CustomParserParams) =>
    customParser({ ...parserParams, approaches, isDisabled: isDisabledCells, valuationsApproachFutureExit });

  const reverseParser = (reverseParserParams: CustomReverseParserParams) =>
    customReverseParser({ ...reverseParserParams, approach: approach as ValuationsApproach });

  const afterCellsChanged = (
    initialChanges: Array<Change>,
    cells: Cells<AllocationMethodCellCustomData>,
    customRowConfig: any,
    tableData: any,
    allCells: any
  ) =>
    customAfterCellsChanged({
      initialChanges,
      cells,
      approaches,
      approach,
      rowConfig: customRowConfig,
      tableData,
      allCells,
    });

  const allocationMethodOPM = !isEmpty(columns)
    ? new SpreadsheetConfig({
      allowConfirmAndDeleteColumn: false,
      allowCopyColumn: false,
      allowReorderColumns: false as unknown as SpreadsheetConfig['allowReorderColumns'],
      alwaysDisplayLegend: false,
      afterCellChanged: afterCellsChanged as unknown as SpreadsheetConfig['afterCellChanged'],
      columns,
      currencyFormatter: false,
      format: undefined,
      showFirstRowAsTitle: false,
      name,
      page: ALLOCATION_METHOD_OPM_TABLE_TERMS.tableName as unknown as SpreadsheetConfig['page'],
      parser,
      reverseParser: reverseParser as unknown as SpreadsheetConfig['reverseParser'],
      rowConfig: config,
      showPreviousColsDivider: false,
      showToolbar: true,
      showTotalColumn: false,
      tableData: { measurementDate },
      tableTerms: ALLOCATION_METHOD_OPM_TABLE_TERMS as unknown as SpreadsheetConfig['tableTerms'],
      totalParser: undefined,
      unitsFormatter: false,
    })
    : null;

  return allocationMethodOPM;
};

export default createAllocationMethodOPMConfig;
