const getFlattenedData = ({ data, attributes }) =>
  Object.keys(data).reduce((flattenedObj, key) => {
    const { value } = data[key];
    // eslint-disable-next-line no-param-reassign
    flattenedObj[key]
      = typeof value === 'number' && attributes[key] ? parseFloat(value).toFixed(attributes[key].decimal_places) : value;
    return flattenedObj;
  }, {});

export default getFlattenedData;
