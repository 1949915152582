/* eslint-disable no-console */
import { SET_ACTIVATION_EMAIL, SET_IS_RESTRICTED_USER, SET_USER_INFO } from './types';

export const setUserInfo = userInfo => ({
  type: SET_USER_INFO,
  user: userInfo,
});

export const setActivationEmail = activationEmail => ({
  type: SET_ACTIVATION_EMAIL,
  activationEmail,
});
// this is for users that only have access to only a fund or to only a company
export const setIsRestrictedUser = value => ({
  type: SET_IS_RESTRICTED_USER,
  isRestrictedUser: value,
});

export default setUserInfo;
