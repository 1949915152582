import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { notFoundPath } from 'common/config/urls';
import { COMPANIES_AVAILABLE_SLUG_PARAMS } from 'common/constants/companies';
import { useStore } from 'common/store';

const withCompany = WrappedComponent =>
  function WithCompany(props) {
    const [{ companyList, companyInfo }] = useStore();
    const [company, setCompany] = useState();
    const { companySlugParam, tableSlugParam } = useParams();
    const history = useHistory();

    useEffect(() => {
      if (companyList?.length && companySlugParam) {
        const tmpCompany = companyList.find(
          c => c.company_slug === companySlugParam || c.company_id === companyInfo.id
        );
        setCompany(tmpCompany || {});
      }
    }, [companyList, companySlugParam, companyInfo]);

    if ((company && isEmpty(company)) || !COMPANIES_AVAILABLE_SLUG_PARAMS.includes(tableSlugParam)) {
      history.push(notFoundPath);
      return null;
    }

    return <WrappedComponent {...props} />;
  };

export default withCompany;
