import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    listContainer: {
      marginTop: theme.spacing(2),
      overflow: 'hidden',
    },
    top: {
      flexGrow: 1,
      // maxHeight: 'calc(100vh - 200px)'
    },
  })
);

export default useStyles;
