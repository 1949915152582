/**
 * NoteEdit Component
 *
 * @component
 * @description This component displays an interface for editing a note's content.
 * It provides a text input field for editing the note's content, along with "Cancel" and "Save" buttons.
 *
 * @param {NoteEditProps} props - The properties and event handlers required by the component.
 * @returns {JSX.Element} - A JSX element representing the NoteEdit component.
 */
import React, { ChangeEvent, FC, MouseEvent } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import useStyles from '../styles';

interface NoteEditProps {
  noteContent: string;
  isDisabled?: boolean;
  handleOnChangeContentNote: (event: ChangeEvent<HTMLInputElement>) => void;
  handleOnCancelEditNote: (event: MouseEvent<HTMLButtonElement>) => void;
  handleOnSaveSingleNote: (event: MouseEvent<HTMLButtonElement>) => void;
}

const NoteEdit: FC<NoteEditProps> = props => {
  const { noteContent, isDisabled, handleOnChangeContentNote, handleOnCancelEditNote, handleOnSaveSingleNote } = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.noteEditContainer}>
      <Grid item xs={12}>
        <TextField
          id="note-content"
          className={classes.noteEditInput}
          multiline
          variant="outlined"
          value={noteContent}
          onChange={handleOnChangeContentNote}
          autoFocus
        />
      </Grid>
      <Grid item xs={12} className={classes.noteEditButtonContainer}>
        <Button
          className={classes.noteCancelButton}
          variant="outlined"
          color="default"
          onClick={handleOnCancelEditNote}
          disabled={isDisabled}>
          Cancel
        </Button>
        <Button
          className={classes.noteEditButton}
          variant="contained"
          color="primary"
          onClick={handleOnSaveSingleNote}
          disabled={isDisabled || !noteContent.length}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default NoteEdit;
