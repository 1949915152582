import React, { useContext } from 'react';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import FutureExitContext from 'pages/ValuationsAllocation/approaches/FutureExit/utils/FutureExitContext';

const FilteredMultipleSelect = (props: any) => {
  const { multipleOptions } = useContext(FutureExitContext);

  return <GridSelect menuPosition="fixed" options={multipleOptions} enumerated {...props} />;
};

export default FilteredMultipleSelect;
