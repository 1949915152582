import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import {
  BUTTON,
  DOC_UPLOAD_PAGE,
  FIRM_INFO,
  LINK,
  LOG_IN,
  LOG_OUT,
  MEASUREMENT_DATE,
} from 'common/constants/process-management';
import { useStore } from 'common/store';
import { TaskContext } from 'context';
import QuestionSlide from './components/QuestionSlide';
import WelcomeToTheQuestionnaire from './components/WelcomeToTheQuestionnaire';

const ProcessManagementQuestions = () => {
  const { isMobile, setHeaderMenuItems, task, questionnaireUrl, documentUrl } = useContext(TaskContext);
  const { questions } = task;
  const { requestId, hmacToken, processType } = useParams();
  const [isStarted, setIsStarted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [orderedQuestions, setOrderedQuestions] = useState([]);
  const [{ user }] = useStore();
  const maxSteps = questions?.length;

  function orderQuestionsById(questionsParam) {
    return questionsParam.sort((a, b) => a.id - b.id);
  }

  function handleStart() {
    setIsStarted(true);
  }

  useEffect(() => {
    if (questions) setOrderedQuestions(orderQuestionsById(questions));
  }, [questions]);

  useEffect(() => {
    setHeaderMenuItems({
      left: [
        {
          id: uuid(),
          component: FIRM_INFO,
        },
        {
          id: uuid(),
          component: MEASUREMENT_DATE,
        },
      ],
      right: [
        {
          id: uuid(),
          label: user ? LOG_OUT : LOG_IN,
          link: `/${processType}/requests/${requestId}/${hmacToken}`,
          component: LINK,
          withArrow: false,
        },
        {
          id: uuid(),
          label: DOC_UPLOAD_PAGE,
          link: documentUrl,
          component: BUTTON,
          hideOnMobile: true,
        },
      ],
    });
  }, [documentUrl, hmacToken, processType, questionnaireUrl, requestId, setHeaderMenuItems, user]);

  return (
    <>
      {isStarted ? (
        <QuestionSlide
          question={orderedQuestions[activeStep]}
          allQuestions={orderedQuestions}
          setActiveStep={setActiveStep}
          maxSteps={maxSteps}
        />
      ) : (
        <WelcomeToTheQuestionnaire isMobile={isMobile} handleStart={handleStart} />
      )}
    </>
  );
};

export default ProcessManagementQuestions;
