/**
 * @name FirmsTypes ActionTypes
 * @memberof module:common/actions
 * @type {ReduxAction}
 * @return {Object} Firms Actions Types
 */
export const SET_FIRM_INFO = 'SET_FIRM_INFO';
export const SET_FIRM_INFO_ID = 'SET_FIRM_INFO_ID';
export const GET_FIRM_LIST = 'GET_FIRM_LIST';
export const GET_USER_MANAGEMENT_FIRM_LIST = 'GET_USER_MANAGEMENT_FIRM_LIST';
export const GET_FUND_LIST_BY_FIRM_ID = 'GET_FUND_LIST_BY_FIRM_ID';
export const SET_FIRM_ID = 'SET_FIRM_ID';
export const GET_FIRM_INFO = 'GET_FIRM_INFO';
export const SET_FIRM_LIST = 'SET_FIRM_LIST';
export const SET_USER_MANAGEMENT_FIRM_LIST = 'SET_USER_MANAGEMENT_FIRM_LIST';
export const SET_FIRM_USER = 'SET_FIRM_USER';
export const GET_SELECTED_USER_PERMISSIONS = 'GET_SELECTED_USER_PERMISSIONS';
export const SET_SELECTED_USER_PERMISSIONS = 'SET_SELECTED_USER_PERMISSIONS';
