import { useQuery } from '@tanstack/react-query';
import { isEmpty, isNil } from 'lodash';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import {
  PUBLIC_COMPS_BY_DATE_DEFAULT_ERROR_MESSAGE,
  PUBLIC_COMPS_BY_DATE_QUERY_KEY,
} from 'common/constants/services/valuations';
import { CapIQPublicCompsList, CompsByDate, CompsList, CompsListByBatch } from 'pages/ValuationsAllocation/types';
import { ValuationService } from 'services';
import { getCompsListByBatch } from 'services/hooks/valuation';
import { getArrayValue, getObjectValue, getYearFromDate, throwError } from 'utillities';
import { GetPublicCompsByDateHook, GetPublicCompsByDateParams, UseGetPublicCompsByDate } from './types';

const getPublicCompsByDate = async (params: GetPublicCompsByDateParams) => {
  const { financialPeriodsYears, currency, tickersSymbolsByDate } = params;

  if (isNil(currency) || isEmpty(financialPeriodsYears) || isEmpty(tickersSymbolsByDate)) return null;

  const valuationService = new ValuationService();

  try {
    const compsList = Object.entries(getObjectValue(tickersSymbolsByDate)).map(async ([date, tickerSymbols]) => {
      const getCapIQPublicCompsList = async (tickerSymbol: string) => {
        const response = (await valuationService.getPublicCompList(
          tickerSymbol,
          0, // Measurement Date
          currency,
          financialPeriodsYears,
          date
        )) as CapIQPublicCompsList;

        return response;
      };

      const publicCompsList = (await getCompsListByBatch(tickerSymbols, getCapIQPublicCompsList)) as CompsListByBatch;

      const { results: companies } = publicCompsList;

      return [date, companies] as CompsList;
    });

    if (isEmpty(compsList)) return throwError(PUBLIC_COMPS_BY_DATE_DEFAULT_ERROR_MESSAGE);

    const results = await Promise.all(compsList);

    const compsByDate = getObjectValue(
      results?.reduce((accumulator, current) => {
        const [date, comparisons] = current;

        return {
          ...accumulator,
          [date]: comparisons,
        };
      }, {} as CompsByDate)
    );

    return compsByDate;
  } catch (error) {
    return throwError(PUBLIC_COMPS_BY_DATE_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetPublicCompsByDate: UseGetPublicCompsByDate = params => {
  const { currency, financialPeriods, tickersSymbolsByDate, shouldQueryAutomatically = true } = params;

  const financialPeriodsYears = getArrayValue([
    ...new Set(
      financialPeriods
        ?.map(financialPeriod => getYearFromDate(financialPeriod?.statement_date))
        ?.filter(financialPeriodYear => Boolean(financialPeriodYear))
    ),
  ]).join(',');

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<GetPublicCompsByDateHook['data'], Error>({
    enabled: !isEmpty(tickersSymbolsByDate) && shouldQueryAutomatically,
    queryFn: () => getPublicCompsByDate({ financialPeriodsYears, currency, tickersSymbolsByDate }),
    queryKey: [PUBLIC_COMPS_BY_DATE_QUERY_KEY, financialPeriodsYears, currency, tickersSymbolsByDate],
    refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetPublicCompsByDate;
