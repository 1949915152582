/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import * as messages from 'common/constants/messages/validations';
import { userSchemaBase } from 'common/constants/userSchemaBase';
import { useStore } from 'common/store';
import { CustomTextField } from 'components';
import { useFormValidation } from 'services/hooks/useFormValidation';

const useStyles = makeStyles({
  formHeader: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  margin: {
    marginBottom: 20,
  },
  textField: {
    width: '100%',
  },
});

const constraints = {
  ...userSchemaBase,
  password: {
    presence: { allowEmpty: false, message: messages.IS_REQUIRED },
    length: {
      maximum: 128,
    },
    format: {
      pattern: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_]).{9,}$/,
      message: `^${messages.PASSWORD_ERROR_MESSAGE}`,
    },
  },
  re_password: {
    presence: { allowEmpty: false, message: messages.IS_REQUIRED },
    length: {
      maximum: 128,
    },
    equality: 'password',
  },
  firm: {
    presence: { allowEmpty: false, message: messages.IS_REQUIRED },
  },
};

const UserCreateForm = ({ defaultFormState: { values, errors, dbErrors }, onFormChange }) => {
  const classes = useStyles();
  const { formValidated, validateForm } = useFormValidation(constraints);
  const [formValues, setFormValues] = useState(values);

  const [{ userManagementFirmList, firmInfo, user }] = useStore();

  useEffect(() => {
    validateForm(formValues);
  }, [validateForm, formValues]);

  useEffect(() => {
    onFormChange(formValidated);
  }, [formValidated]);

  const handleChange = event => {
    const {
      target: { name, value },
    } = event;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  useEffect(() => {
    if (user && !user.is_superuser) {
      setFormValues({
        ...formValues,
        firm: firmInfo.id,
      });
    }
  }, [user]);

  const filterFirmOptions = createFilterOptions({
    stringify: option => option.name,
  });

  const handleFirmChange = (event, new_value) => {
    setFormValues({
      ...formValues,
      firm: new_value?.id,
    });
  };

  return (
    <Grid container>
      <CustomTextField
        required
        fullWidth
        id="user-name"
        name="first_name"
        type="text"
        label="First name"
        className={classes.margin}
        value={formValues.first_name}
        onChange={handleChange}
        formErrors={errors}
        dbErrors={dbErrors}
      />
      <CustomTextField
        required
        fullWidth
        id="last-name"
        name="last_name"
        type="text"
        label="Last name"
        className={classes.margin}
        value={formValues.last_name}
        onChange={handleChange}
        formErrors={errors}
        dbErrors={dbErrors}
      />
      <CustomTextField
        required
        fullWidth
        id="user-email"
        name="email"
        type="text"
        label="Email address"
        className={classes.margin}
        value={formValues.email}
        onChange={handleChange}
        formErrors={errors}
        dbErrors={dbErrors}
      />
      <CustomTextField
        required
        fullWidth
        id="user-password"
        name="password"
        type="password"
        label="Password"
        className={classes.margin}
        value={formValues.password}
        onChange={handleChange}
        formErrors={errors}
        dbErrors={dbErrors}
      />
      <CustomTextField
        required
        fullWidth
        id="user-confirm-password"
        name="re_password"
        type="password"
        label="Confirm password"
        className={classes.margin}
        value={formValues.re_password}
        onChange={handleChange}
        formErrors={errors}
        dbErrors={dbErrors}
      />
      {user?.is_superuser && userManagementFirmList && (
        <Autocomplete
          id="firms-autocomplete-user-create-form"
          options={userManagementFirmList}
          filterOptions={filterFirmOptions}
          getOptionLabel={option => option.name}
          className={classes.textField}
          onChange={handleFirmChange}
          value={formValues.firm}
          renderInput={params => (
            <CustomTextField
              required
              fullWidth
              name="firm"
              label="Select a Firm"
              {...params}
              formErrors={errors}
              dbErrors={dbErrors}
            />
          )}
        />
      )}
    </Grid>
  );
};

UserCreateForm.propTypes = {
  defaultFormState: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
};

export default UserCreateForm;
