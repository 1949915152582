import {
  FUND_SUMMARY_SPREADSHEET_COLUMN_NAME,
  FUND_SUMMARY_SPREADSHEET_COLUMN_NAME_PLURAL,
  FUND_SUMMARY_SPREADSHEET_TABLE,
  FUND_SUMMARY_SPREADSHEET_TITLE,
} from 'pages/Funds/common/constants/fundSummary/fundSummary';

export const FUND_SUMMARY_SPREADSHEET_TABLE_TERMS = Object.freeze({
  columnName: FUND_SUMMARY_SPREADSHEET_COLUMN_NAME,
  pluralColumnName: FUND_SUMMARY_SPREADSHEET_COLUMN_NAME_PLURAL,
  tableName: FUND_SUMMARY_SPREADSHEET_TITLE,
  tableSlug: FUND_SUMMARY_SPREADSHEET_TABLE,
});
