import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SYSTEM_COLLAPSE, USER_COLLAPSE } from 'common/actions/row-groups/types';
import SpreadsheetContext from 'components/Spreadsheet/context/SpreadsheetContext';

const Row = ({ cells, children }) => {
  const { rowGroups } = useContext(SpreadsheetContext);
  const isRowVisible = useMemo(() => {
    if (rowGroups) {
      // Look if any of the cells has a parent
      const result = cells.find(cell => cell.parent);

      // Check if the collapsible rows has the parent
      if (result?.parent) {
        const rowGroup = rowGroups?.[result.parent];
        const isRowGroupCollapsed = rowGroup === SYSTEM_COLLAPSE || rowGroup === USER_COLLAPSE;

        if (isRowGroupCollapsed) return false;
      }
    }

    return true;
  }, [rowGroups, cells]);

  return <tr className={isRowVisible ? 'visible' : 'hidden'}>{children}</tr>;
};

Row.propTypes = {
  cells: PropTypes.array,
  children: PropTypes.array,
};

export default Row;
