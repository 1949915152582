const countDecimalPlaces = (number: number): number => {
  const decimalString = number.toString();
  const decimalIndex = decimalString.indexOf('.');

  if (decimalIndex !== -1) {
    return decimalString.length - decimalIndex - 1;
  }
  return 0; // If there's no decimal part, it has 0 decimals
};

export default countDecimalPlaces;
