import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  listContainer: {
    marginTop: theme.spacing(1),
    border: '1px solid #8A9097',
    height: 300,
    borderRadius: 5,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .scrollbar-container': {
      paddingTop: 0,
    },
  },
  itemContainer: {
    marginTop: theme.spacing(1.5),
  },
  listTitle: {
    color: '#444',
    fontWeight: 600,
  },
  itemTitle: {
    zIndex: 1,
    fontSize: 12,
    color: theme.palette.gray[300],
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  // Filter
  input: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)} ${theme.spacing(0.5)} !important`,
  },
  inputAdornEnd: {
    paddingRight: `${theme.spacing(1)}px !important`,
  },
  icon: {
    color: '#8A9097',
  },
  notchedOutline: {
    borderColor: '#8A9097 !important',
  },
}));

export default useStyles;
