import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    disabledCell: {
      backgroundColor: `${theme.palette.disabled} !important`,
      borderColor: `${theme.palette.disabled} !important`,
      boxShadow: 'none !important',
      color: `${theme.palette.gray[400]} !important`,
      cursor: 'auto !important',
    },
  })
);

export default useStyles;
