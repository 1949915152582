import { useLayoutEffect, useRef, useState } from 'react';

const useRevealEllipsisInTooltip = cellWidth => {
  const tooltipRef = useRef();
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    if (tooltipRef.current) {
      setWidth(tooltipRef.current.offsetWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipRef.current, cellWidth]);

  return {
    tooltipRef,
    width,
  };
};

export default useRevealEllipsisInTooltip;
