import { ValuationApproachFutureExit } from 'api';
import { FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES } from 'pages/ValuationsAllocation/common/constants/futureExit/sheetConfigs';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getNumberValue, getObjectValue } from 'utillities';
import { createAllocationMethodOPMConfig, createAllocationMethodWaterfallCSEConfig } from './AllocationMethodTable';
import { createFutureEquityConfiguration } from './FutureEquity/config';
import FutureExit from './FutureExitApproach';
import { createModifiedPresentEquityConfiguration } from './ModifiedPresentEquity/config';
import { CreateFutureExitConfigParams } from './types';

const createFutureExitConfig = (params: CreateFutureExitConfigParams) => {
  const {
    approach,
    approaches,
    company,
    companyExchangeRate,
    financials,
    futureExitAttributes,
    isDisabled,
    measurementDate,
    futureExitRiskFreeRate,
  } = params;
  const { valuations_approach_future_exit: valuationsApproachFutureExit } = getObjectValue(approach);
  const ValuationsApproachFutureExit = valuationsApproachFutureExit as ValuationApproachFutureExit;
  const name = getApproachTableName({ approach });
  const { futureExitApproachAttrs } = futureExitAttributes;

  // FUTURE EQUITY VALUE - TABLE
  const futureEquitySheet = createFutureEquityConfiguration({
    approach,
    approaches,
    futureExitApproachAttrs,
    isDisabled,
    financials,
    measurementDate,
  });

  const { total_cash_equivalents: totalCashEquivalents, total_debt: totalDebt } = getObjectValue(financials);

  const { captable_currency: captableCurrency, financials_currency: financialsCurrency } = getObjectValue(company);

  // Handling and adjusting cells if the Company has different currencies
  const isUniformCurrency = captableCurrency === financialsCurrency;

  // If is not uniform currency, multiply the values by the Company exchange rate
  const plusDebtTotal = isUniformCurrency ? totalDebt : totalDebt * getNumberValue(companyExchangeRate ?? 1);
  const lessCashTotal = isUniformCurrency
    ? totalCashEquivalents
    : totalCashEquivalents * getNumberValue(companyExchangeRate ?? 1);

  // MODIFIED PRESENT EQUITY VALUE - TABLE
  const presentValueSheet = createModifiedPresentEquityConfiguration({
    approach,
    approaches,
    futureEquitySheet,
    futureExitApproachAttrs,
    isDisabled,
    lessCashTotal,
    ltmData: financials,
    measurementDate,
    plusDebtTotal,
  });

  const allocationMethodWaterfallCSEName = getApproachTableName({
    approach,
    tableSuffix: FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES.ALLOCATION_METHOD_VALUE_WATERFALL_CSE,
  });

  // ALLOCATION METHOD CSE - TABLE
  const allocationMethodWaterfallCSESheet = createAllocationMethodWaterfallCSEConfig({
    approach,
    approaches,
    valuationsApproachFutureExit: ValuationsApproachFutureExit,
    name: allocationMethodWaterfallCSEName,
    measurementDate,
    isDisabled,
  });
  const allocationMethodOPMName = getApproachTableName({
    approach,
    tableSuffix: FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES.ALLOCATION_METHOD_VALUE_OPM,
  });

  // ALLOCATION METHOD OPM - TABLE
  const allocationMethodOPMSheet = createAllocationMethodOPMConfig({
    approach,
    approaches,
    valuationsApproachFutureExit: ValuationsApproachFutureExit,
    name: allocationMethodOPMName,
    measurementDate,
    isDisabled,
    futureExitRiskFreeRate,
  });

  return {
    name,
    approach,
    value: approach?.enterprise_value,
    spreadsheets: {
      futureEquitySheet,
      presentValueSheet,
      allocationMethodWaterfallCSESheet,
      allocationMethodOPMSheet,
    },
    enterpriseValueReference: presentValueSheet?.name,
    measurementDate,
    component: FutureExit,
  };
};

export default createFutureExitConfig;
