import {
  ADMIN,
  ANALYST_VALUE,
  AUDITOR_VALUE,
  COMPANY,
  COMPANY_USER_VALUE,
  DOCUMENT_VIEWER_VALUE,
  EDIT,
  EXPORT_VIEWER_VALUE,
  FIRM,
  FIRM_ADMIN_VALUE,
  FULL_ACCESS_VIEWER_VALUE,
  FUND,
  FUND_ADMIN_VALUE,
  LIMITED_VIEWER_VALUE,
  USE_PORTAL,
  VIEW,
} from 'common/constants/user';

// Analysts and auditors need a firm-level permission to access the firm summary page.
// Fund admins need EDIT permission on the firm, the relevant fund and all companies.
const translateRoleIntoPermissions = ({ role, firmId, companyList, fundList }) => {
  if (!role) return [];
  const preparePermissionsForAllItems = ({ items, itemType, accessType }) => {
    const permissions = [];
    items.forEach(item => {
      permissions.push({
        id: 0,
        feature_object: {
          feature: null,
          object_type: itemType,
          object_id: item.id,
        },
        access_type: accessType,
        remove_permission: false,
      });
    });
    return permissions;
  };
  const getPermissions = ({ accessType }) => [
    {
      id: 0,
      feature_object: {
        feature: null,
        object_type: FIRM,
        object_id: firmId,
      },
      access_type: accessType,
      remove_permission: false,
    },
    ...preparePermissionsForAllItems({
      items: companyList.map(c => ({
        id: c.company_id,
        name: c.company_name,
      })),
      itemType: COMPANY,
      accessType,
    }),
    ...preparePermissionsForAllItems({
      items: fundList,
      itemType: FUND,
      accessType,
    }),
  ];

  const rolePermissionsMap = new Map([
    [
      FIRM_ADMIN_VALUE,
      [
        {
          id: 0,
          feature_object: {
            feature: null,
            object_type: FIRM,
            object_id: firmId,
          },
          access_type: ADMIN,
          remove_permission: false,
        },
      ],
    ],
    [
      COMPANY_USER_VALUE,
      [
        {
          id: 0,
          feature_object: {
            feature: null,
            object_type: COMPANY,
            object_id: role.firmItem.itemId,
          },
          access_type: USE_PORTAL,
          remove_permission: false,
        },
      ],
    ],
    [ANALYST_VALUE, getPermissions({ accessType: EDIT })],
    [
      FUND_ADMIN_VALUE,
      [
        {
          id: 0,
          feature_object: {
            feature: null,
            object_type: FIRM,
            object_id: firmId,
          },
          access_type: EDIT,
          remove_permission: false,
        },
        ...preparePermissionsForAllItems({
          items: companyList.map(c => ({
            id: c.company_id,
            name: c.company_name,
          })),
          itemType: COMPANY,
          accessType: EDIT,
        }),
        {
          id: 0,
          feature_object: {
            feature: null,
            object_type: FUND,
            object_id: role.firmItem.itemId,
          },
          access_type: EDIT,
          remove_permission: false,
        },
      ],
    ],
    [AUDITOR_VALUE, getPermissions({ accessType: VIEW })],
    [FULL_ACCESS_VIEWER_VALUE, getPermissions({ accessType: VIEW })],
    [LIMITED_VIEWER_VALUE, getPermissions({ accessType: VIEW })],
    [EXPORT_VIEWER_VALUE, getPermissions({ accessType: VIEW })],
    [DOCUMENT_VIEWER_VALUE, getPermissions({ accessType: VIEW })],
  ]);

  return rolePermissionsMap.get(role.id);
};

export default translateRoleIntoPermissions;
