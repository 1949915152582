import React, { FC } from 'react';
import { OSCHProps } from './types';

const mockValuationOpinionDate = '12/12/12';

const OpinionSummaryCardHeader: FC<OSCHProps> = ({ displayExpired }) => (
  <div style={{ fontWeight: 700, ...(displayExpired && { width: '50%', display: 'inline-block' }) }}>
    Valuation Opinion as of {mockValuationOpinionDate}
  </div>
);

export default OpinionSummaryCardHeader;
