import React, { useCallback, useMemo, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { PersonAdd, Queue } from '@material-ui/icons';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import ActionDialogPicker from './ActionDialogPicker';
import UsersTable from './UsersTable';
import { ApiService } from '../../../api';
import { BULK_ADD, DIRECTORY, INVITE_USER } from '../../../common/constants/user';
import { manageApiRequest } from '../../../hooks/apiRequest';
import useGetCompanyUsers from '../../../services/hooks/useGetCompanyUsers';
import useGetFirmUsers from '../../../services/hooks/useGetFirmUsers';
import usePermissionStyles from '../utils/usePermissionStyles';

const UserDirectory = ({
  selectedFirm,
  selectedCompany,
  handleAddUser,
  companyList,
  fundList,
  setCurrentUser,
  isSuperUser,
  isFirmAdmin,
  hideBulk,
}) => {
  const classes = usePermissionStyles();

  const [dialogUserData, setDialogUserData] = useState({
    open: false,
    type: '',
  });

  const handleDialogClose = useCallback(() => {
    setDialogUserData({
      open: false,
      type: '',
    });
  }, []);

  const [userSearchValue, setUserSearchValue] = useState('');
  const { data: firmUsers, isLoading: isFirmLoading } = useGetFirmUsers(selectedFirm?.id, userSearchValue);
  const { data: companyUsers, isLoading: isCompanyDataLoading } = useGetCompanyUsers(
    selectedCompany?.id,
    userSearchValue
  );
  const queryClient = useQueryClient();

  const afterAddUser = useCallback(() => {
    if (selectedCompany?.id) {
      queryClient.invalidateQueries(['companyUsers', selectedCompany.id]);
    }
    handleDialogClose();
  }, [selectedCompany, queryClient, handleDialogClose]);

  const { users, isLoading } = useMemo(() => {
    if (selectedCompany?.id) {
      return { users: companyUsers, isLoading: isCompanyDataLoading };
    }
    return { users: firmUsers, isLoading: isFirmLoading };
  }, [selectedCompany, companyUsers, firmUsers, isCompanyDataLoading, isFirmLoading]);

  const handleAddUserFn = useCallback(
    (userData, role) => {
      handleAddUser(userData, role, afterAddUser);
    },
    [handleAddUser, afterAddUser]
  );

  const togglePrimaryUser = useCallback(
    user =>
      manageApiRequest(
        ApiService.apiDashboardCompanyAddUserCreate({
          ...user,
          is_primary_contact: !user.is_primary_contact,
          company_id: selectedCompany?.id,
        })
      ).then(afterAddUser),
    [afterAddUser, selectedCompany]
  );

  return (
    <>
      {(selectedFirm || selectedCompany) && (
        <>
          <Typography variant="h6">User Permissions</Typography>
          <br />
          <Grid container spacing={1} className={classes.subtitle}>
            <Grid item sm={6}>
              <Typography variant="inherit">{DIRECTORY}</Typography>
            </Grid>
            <Grid item sm={3}>
              <Button
                color="primary"
                style={{ padding: 0 }}
                startIcon={<Queue />}
                onClick={() =>
                  setDialogUserData({
                    type: 'bulkAdd',
                    open: true,
                  })
                }>
                {BULK_ADD}
              </Button>
            </Grid>
            <Grid item sm={3}>
              <Button
                color="primary"
                style={{ padding: 0 }}
                startIcon={<PersonAdd />}
                onClick={() =>
                  setDialogUserData({
                    type: 'inviteUser',
                    open: true,
                  })
                }>
                {INVITE_USER}
              </Button>
            </Grid>
          </Grid>
          {(selectedFirm || selectedCompany) && (
            <ActionDialogPicker
              dialogUserData={dialogUserData}
              handleDialogClose={handleDialogClose}
              firm={{
                id: selectedFirm?.id,
                name: selectedFirm?.name,
              }}
              company={{
                id: selectedCompany?.id,
                name: selectedCompany?.name,
              }}
              selectedCompany={selectedCompany}
              handleAddUserToFirm={handleAddUserFn}
              companyList={companyList?.map(({ company_id, company_name }) => ({ company_id, company_name })) || []}
              fundList={fundList?.map(({ id, name }) => ({ id, name })) || []}
            />
          )}
          <UsersTable
            firm={selectedFirm}
            setCurrentUser={setCurrentUser}
            isSuperUser={isSuperUser}
            isFirmAdmin={isFirmAdmin}
            users={users}
            isLoading={isLoading}
            userSearchValue={userSearchValue}
            setUserSearchValue={setUserSearchValue}
            togglePrimaryUser={togglePrimaryUser}
          />
        </>
      )}
    </>
  );
};

export default UserDirectory;

UserDirectory.propTypes = {
  selectedFirm: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  selectedCompany: PropTypes.shape({
    id: PropTypes,
    name: PropTypes.string,
  }),
  handleAddUser: PropTypes.func.isRequired,
  companyList: PropTypes.arrayOf(
    PropTypes.shape({
      company_id: PropTypes,
    })
  ),
  fundList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  setCurrentUser: PropTypes.func.isRequired,
  isSuperUser: PropTypes.bool.isRequired,
  isFirmAdmin: PropTypes.bool.isRequired,
  hideBulk: PropTypes.bool,
};
