import React, { FC, useMemo } from 'react';
import { Divider, Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { currencyFormat } from 'common/formats/formats';
import { formatNumbers } from 'utillities';
import { EnterpriseValueProps } from './types';

const useStyle = makeStyles((theme: Theme) => ({
  infoPaper: {
    padding: '1em',
    textAlign: 'center',
    minWidth: '200px',
  },
  enterpriseVal: {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '1.225em',
  },
  enterpriseValSkeleton: {
    width: '20%',
    margin: 'auto',
  },
  divider: {
    backgroundColor: theme.palette.grey[300],
    height: 2,
    width: '20%',
    margin: `${theme.spacing()}px auto`,
  },
  valuePCommon: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '3.125em',
    fontWeight: 700,
  },
  valuePCommonSkeleton: {
    width: '20%',
    margin: 'auto',
  },
  textTittle: {
    fontSize: '1.005em',
  },
}));

const EnterpriseValue: FC<EnterpriseValueProps> = ({ enterpriseData, isLoading, format }) => {
  const classes = useStyle();

  const { enterpriseValue, valuePerCommon } = useMemo(() => {
    if (enterpriseData && !isLoading && format) {
      const {
        weighted_market_enterprise_value: enterpriseValueData,
        marketable_value_per_common_share: valuePerCommonData,
      } = enterpriseData;

      const { currency } = format;

      const enterpriseValueFormated = formatNumbers({
        format: currencyFormat({ fractionDigits: 0 }),
        value: enterpriseValueData,
        prefix: null,
        suffix: null,
        allowNegativeValue: true,
        currency,
        currencyCode: currency.code,
        blankIfNull: false,
        staticCurrency: null,
      });

      const valuePerCommonFormated = formatNumbers({
        format: currencyFormat({ fractionDigits: 2 }),
        value: valuePerCommonData,
        prefix: null,
        suffix: null,
        allowNegativeValue: true,
        currency,
        currencyCode: currency.code,
        blankIfNull: false,
        staticCurrency: null,
      });

      return { enterpriseValue: enterpriseValueFormated, valuePerCommon: valuePerCommonFormated };
    }

    return { enterpriseValue: null, valuePerCommon: null };
  }, [enterpriseData, isLoading, format]);

  return (
    <Paper className={classes.infoPaper}>
      {!isLoading && enterpriseValue ? (
        <small className={classes.enterpriseVal}>{enterpriseValue}</small>
      ) : (
        <Skeleton className={classes.enterpriseValSkeleton} variant="text" />
      )}
      <Typography className={classes.textTittle}>Enterprise Value</Typography>
      <Divider className={classes.divider} />
      {!isLoading && valuePerCommon ? (
        <p className={classes.valuePCommon}>{valuePerCommon}</p>
      ) : (
        <Skeleton className={classes.valuePCommonSkeleton} variant="text" />
      )}
      <Typography>Value per Common Share</Typography>
    </Paper>
  );
};

export default EnterpriseValue;
