import { currencyFormat } from 'common/formats/formats';
import { ComparisonCompany } from 'dashboard409a/services/types';
import { RowConfigProps } from '../types';

// Titles
export const TABLE_TITLE = 'Comparison Companies';
export const TABLE_SECOND_TITLE = 'LTM Revenue';
export const TABLE_THIRD_TITLE = 'LTM Revenue Growth';
export const TABLE_FOURTH_TITLE = 'NTM Revenue Growth';
export const TABLE_FIFTH_TITLE = 'Gross Margin';
export const TABLE_SIXTH_TITLE = 'Ebitda Margin';

// Alias
export const CURRENT_COMPANY_NAME_ALIAS = 'current_company_name';

const baseCurrencyFormat = currencyFormat({ fractionDigits: 1 });
const vandelayCurrencyFormat = currencyFormat({ fractionDigits: 0 });

const getCompanyRows = (companies: ComparisonCompany[] | undefined) =>
  companies && companies.length > 0
    ? companies.map((company: ComparisonCompany) => ({
      value: company.name,
      alias: `company-${company.name}`,
      readOnly: true,
      gridType: 'number',
      format: baseCurrencyFormat,
      allowNegativeValue: true,
    }))
    : [];

const getVandelayIndustriesExpr = (companies: ComparisonCompany[] | undefined) =>
  companies?.map((_: unknown, index: number) => `@@${index + 2}`);

const rowConfig = ({ companies, currentCompanyName }: RowConfigProps) => [
  {
    className: 'table-header comparison-companies-table-header division-bottom-only',
    value: TABLE_TITLE,
    alias: 'title',
    readOnly: true,
    gridType: 'string',
  },
  ...getCompanyRows(companies),
  {
    value: currentCompanyName,
    alias: CURRENT_COMPANY_NAME_ALIAS,
    readOnly: true,
    gridType: 'number',
    format: vandelayCurrencyFormat,
    allowNegativeValue: true,
    className: 'row-label-title division',
    expr: `=SUM(${getVandelayIndustriesExpr(companies)})`,
  },
];

export default rowConfig;
