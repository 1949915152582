import React from 'react';
import { CAP_TABLE_SELECTION_TITLE } from 'common/constants/valuations';
import GridRowLabel from 'components/Grid/GridRowLabel';
import {
  ALLOCATION_BACKSOLVE_WEIGHTING_TITLE,
  ALLOCATION_METHOD_TITLE,
  BACKSOLVE_TITLE,
  PRESENT_SHARE_VALUES_TITLE,
} from 'pages/Valuations/approaches/backsolveApproach/BacksolveTable/util/constants';
import {
  BACKSOLVE_ROW_HEADER_CONFIG,
  BACKSOLVE_ROW_SECURITY_CONFIG,
  BOLD_ROW_HEADER_CONFIG,
  PRESENT_SHARE_VALUES_ROW_NUMBER,
  TABLE_HEADER_CONFIG,
} from 'pages/Valuations/approaches/backsolveApproach/constants';
import { getValueByPropOrFallback } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';

const generatePSVMap = presentShareValues => {
  if (presentShareValues?.length) {
    return new Map(presentShareValues.map(psv => [psv.security.toString(), psv.value]));
  }
  return null;
};

export default async ({ columns, rowConfig }) => {
  let cells = {};
  const getExpr = (expr, columnLegend) => {
    if (expr) {
      return expr.replace(/@/g, `${columnLegend}`);
    }
    return '';
  };
  cells.A1 = {
    ...TABLE_HEADER_CONFIG,
    className: `${TABLE_HEADER_CONFIG.className} header-no-border-right`,
    key: 'A1',
    value: BACKSOLVE_TITLE,
    rowNumber: 1,
    colSpan: columns.length,
    style: { height: '62px' },
    expr: BACKSOLVE_TITLE,
    checkPrevCol: true,
    largeHeader: true,
    valueViewer: props => <GridRowLabel {...props} />,
  };
  cells.A2 = {
    ...BOLD_ROW_HEADER_CONFIG,
    key: 'A2',
    rowNumber: 2,
    dbType: 'string',
    expr: ALLOCATION_METHOD_TITLE,
    value: ALLOCATION_METHOD_TITLE,
    style: { minWidth: '250px' },
  };
  cells.A3 = {
    ...BACKSOLVE_ROW_HEADER_CONFIG,
    key: 'A3',
    rowNumber: 3,
    dbType: 'string',
    expr: CAP_TABLE_SELECTION_TITLE,
    value: CAP_TABLE_SELECTION_TITLE,
    style: { minWidth: '250px' },
  };
  cells.A4 = {
    ...BACKSOLVE_ROW_HEADER_CONFIG,
    key: 'A4',
    rowNumber: 4,
    dbType: 'string',
    expr: ALLOCATION_BACKSOLVE_WEIGHTING_TITLE,
    value: ALLOCATION_BACKSOLVE_WEIGHTING_TITLE,
    style: { minWidth: '250px' },
  };
  cells.A5 = {
    ...BOLD_ROW_HEADER_CONFIG,
    key: 'A5',
    rowNumber: 5,
    dbType: 'string',
    expr: PRESENT_SHARE_VALUES_TITLE,
    value: PRESENT_SHARE_VALUES_TITLE,
    style: { minWidth: '250px' },
  };

  const alphabet = alphabetGenerator([], columns.length);

  columns.forEach((column, columnIdx) => {
    const columnLegend = alphabet[columnIdx];
    const psvMap = generatePSVMap(column.present_share_values);

    rowConfig.forEach((row, rowIdx) => {
      const rowNumber = rowIdx + 1;
      const key = columnLegend + rowNumber;

      if (columnIdx === 0 && rowNumber <= PRESENT_SHARE_VALUES_ROW_NUMBER) {
        return;
      }

      if (rowNumber === PRESENT_SHARE_VALUES_ROW_NUMBER) {
        cells = {
          ...cells,
          [key]: {
            ...BACKSOLVE_ROW_HEADER_CONFIG,
            rowNumber: PRESENT_SHARE_VALUES_ROW_NUMBER,
            dbType: 'string',
            expr: '',
            value: '',
          },
        };

        return;
      }

      if (columnIdx === 0 && rowNumber > PRESENT_SHARE_VALUES_ROW_NUMBER) {
        cells = {
          ...cells,
          [key]: {
            ...row,
            ...BACKSOLVE_ROW_SECURITY_CONFIG,
          },
        };

        return;
      }

      const expr = getExpr(row.expr, columnLegend);
      const value = column[row.alias] || row.defaultValue;

      let customKey = getValueByPropOrFallback(row, 'customKey', '');

      if (columnIdx > 0 && rowNumber > PRESENT_SHARE_VALUES_ROW_NUMBER) {
        customKey = `${row.alias}-${columnIdx}`;
      }

      const psv = psvMap?.get(row.alias);

      cells = {
        ...cells,
        [key]: {
          ...row,
          expr,
          key,
          columnLegend,
          value: psv ?? value,
          className: getValueByPropOrFallback(row, 'className', ''),
          customKey,
        },
      };
    });
  });
  return cells;
};
