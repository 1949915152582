import React from 'react';
import PropTypes from 'prop-types';
import { useFormat } from 'common/hooks';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';

const SecuritySharesTable = ({ spreadsheet, onChange, workbook, setIsDisplayingRowNumbers }) => {
  const [format, formatDispatch] = useFormat({
    page: 'captable',
    units: '',
  });

  const tableKey = 'security-shares-table';
  return (
    <ScalarSpreadsheet
      {...spreadsheet}
      id={tableKey}
      tableTerms={{
        tableSlug: tableKey,
      }}
      onChange={onChange}
      sheet={spreadsheet}
      isToolbarVisible
      workbook={workbook}
      format={format}
      formatDispatch={formatDispatch}
      disableVerticalScroll
      isLedgerTable
      setIsDisplayingRowNumbers={setIsDisplayingRowNumbers}
    />
  );
};

SecuritySharesTable.propTypes = {
  spreadsheet: PropTypes.object,
  onChange: PropTypes.func,
  workbook: PropTypes.object,
  setIsDisplayingRowNumbers: PropTypes.func,
};

export default SecuritySharesTable;
