import React from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NO_BACKUP_CODES_AVAILABLE } from 'common/constants/login';
import useStyles from '../styles';

const BackupCodesInfo = props => {
  const { backupCodes, disabledGenerate, isGeneratingBackupCodes, isModal } = props;

  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.modalItemsContainer, !isModal && classes.modalItemsContainerMd)}
      container
      justifyContent="center"
      alignItems="center">
      {disabledGenerate && (
        <Grid container justifyContent="center" alignItems="center">
          <Typography className={classes.noBackupCodes}>{NO_BACKUP_CODES_AVAILABLE}</Typography>
        </Grid>
      )}

      {!disabledGenerate && (backupCodes.length === 0 || isGeneratingBackupCodes) ? (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        !disabledGenerate
        && backupCodes.map(code => (
          <Grid
            key={`bk-codes-${code}`}
            xs={12}
            sm={isModal ? 6 : 'auto'}
            className={clsx(classes.modalItem, !isModal && classes.modalItemMd)}
            item>
            <Typography className={classes.modalItemCode}>{code}</Typography>
          </Grid>
        ))
      )}
    </Grid>
  );
};

BackupCodesInfo.propTypes = {
  backupCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabledGenerate: PropTypes.bool,
  isGeneratingBackupCodes: PropTypes.bool,
  isModal: PropTypes.bool,
};

export default BackupCodesInfo;
