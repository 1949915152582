import { CaptableService } from 'services';
import { CREATE_CAPTABLE_PROFORMA, GET_CAPTABLE_INFO, SET_CAPTABLE_INFO } from './types';

export const setCaptableInfo = captableInfo => ({
  type: SET_CAPTABLE_INFO,
  captableInfo,
});

export const getCapTableInfo = capTableId => ({
  type: GET_CAPTABLE_INFO,
  capTableId,
});

export const createCapTableProForma = capTableInfo => ({
  type: CREATE_CAPTABLE_PROFORMA,
  capTableInfo,
});

export const verifyIfHasFinalAllocation = async capTableId => {
  const captableService = new CaptableService();
  try {
    const hasFinalAllocation = await captableService.gotFinalAllocation(capTableId);
    return hasFinalAllocation;
  } catch (error) {
    return { ...error };
  }
};

export const checkForMeasurementDates = async capTableId => {
  const captableService = new CaptableService();
  try {
    const hasMeasurementDates = await captableService.hasMeasurementDates(capTableId);
    return hasMeasurementDates;
  } catch (error) {
    return { ...error };
  }
};
