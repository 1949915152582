import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/futureExit/';
import { getNumberValue, getObjectValue } from 'utillities';
import { AllocationMethodColumnCellData, CreateColumns } from './types';

const { FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD, FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE }
  = SHEET_ALIASES_CONSTANTS;

const createColumns: CreateColumns = params => {
  const { valuationsApproachFutureExit } = params;
  const { allocation_method: allocationMethod, cap_table: capTable } = getObjectValue(valuationsApproachFutureExit);

  const currentAllocationMethod = getNumberValue(allocationMethod);

  // Allocation Method
  const allocationMethodTable = {
    [FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD]: {
      value: currentAllocationMethod,
    } as AllocationMethodColumnCellData,
    [FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE]: {
      value: capTable,
    } as AllocationMethodColumnCellData,
  };

  return [allocationMethodTable];
};

export default createColumns;
