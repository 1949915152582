import client from './client';
import { authEndpoints } from './endpoints';
import { IRefreshAccessTokenResponse } from './types';

export const refreshAccessToken = async (): Promise<IRefreshAccessTokenResponse | null> => {
  const refresh = localStorage.getItem('refresh');

  if (refresh) {
    try {
      const response = await client.post(authEndpoints.refreshAccessToken, { refresh });
      const newAccessToken = response.data;

      if (!newAccessToken?.access) {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
      }

      localStorage.setItem('access', newAccessToken.access);

      return newAccessToken;
    } catch (error) {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
    }
  }
  return null;
};

export const memoizedRefreshToken = refreshAccessToken;
