/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import { FINANCIALS_REFERENCE_TITLE, FINANCIALS_REFERENCE_TYPE } from 'common/constants/documents';
import { FINANCIALS_PAGE_KEY } from 'common/constants/notes';
import { Widgets } from 'components';
import { GridSkeleton } from 'components/Grid';
import { FinancialsProvider } from 'context/FinancialsContext';
import LayoutContext from 'context/LayoutContext';
import { CaptableService } from 'services';
import { useCompanyMeasurementDateFilter, useDocuments } from 'services/hooks';
import { useGetPrimaryCaptableByCompanyDate } from 'services/hooks/captable';
import { useNotes } from 'services/hooks/notes';
import FinancialsTables from './income-statement';
import useLoadFinancials from './utilities/useLoadFinancials';

const useStyles = makeStyles(theme => ({
  marginLg: {
    marginTop: theme.spacing(9),
  },
}));
const Financial = () => {
  /* State */
  const classes = useStyles();

  /* Hooks */
  const { viewOnlyUser } = useContext(LayoutContext);
  const { filesToSave, setFilesToSave } = useDocuments();
  const { notes, setNotes, notesHasChanged, saveNotes, onAddNote, onUpdateNotes, onDeleteNote } = useNotes();
  const { selectedMeasurementDate, MDDialogue: MDDialog } = useCompanyMeasurementDateFilter();
  const {
    periods,
    changePeriodDate,
    collapsibleColumns,
    setCollapsibleColumns,
    incomeStatement,
    setIncomeStatement,
    balanceSheets,
    setBalanceSheets,
    kpi,
    setKpi,
    financialStatement,
    setFinancialStatement,
    filesParams,
    notesParams,
    selectedVersion,
    companyMeasurementDate,
    fieldAttributes,
    measurementDates,
    createdVersion,
    createVersion,
    fiscalYearData,
    setPeriods,
    setShowAdditionalHistoricalYears,
    additionalHistoricalYears,
    setAdditionalHistoricalYears,
    showAdditionalHistoricalYears,
    getVersions,
    notShowingQuarters,
    currentFiscalYearEnd,
  } = useLoadFinancials({ selectedMeasurementDate, filesToSave, setFilesToSave, notes, saveNotes, notesHasChanged });
  const [isDisplayingRowNumbers, setIsDisplayingRowNumbers] = useState(false);
  const [useAdjustedEBITDA, setUseAdjustedEBITDA] = useState();
  const [isAllocationFinal, setIsAllocationFinal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [primaryCaptable, getPrimaryCaptable] = useGetPrimaryCaptableByCompanyDate();

  useEffect(() => {
    if (financialStatement) {
      setUseAdjustedEBITDA(financialStatement?.use_adjusted_ebitda);
    }
  }, [financialStatement]);

  useEffect(() => {
    if (companyMeasurementDate?.id) {
      getPrimaryCaptable(companyMeasurementDate.id);
    }
  }, [companyMeasurementDate, getPrimaryCaptable]);

  useEffect(() => {
    if (!isEmpty(primaryCaptable)) {
      const verifyAllocationStatus = async () => {
        try {
          const captableService = new CaptableService();
          const finalAllocation = await captableService.gotFinalAllocation(primaryCaptable?.id);
          setIsAllocationFinal(!isEmpty(finalAllocation));
          setIsDisabled(!isEmpty(finalAllocation) || viewOnlyUser);
        } catch (error) {
          setIsAllocationFinal(false);
          setIsDisabled(false);
          throw new Error(error);
        }
      };
      verifyAllocationStatus();
    }
  }, [primaryCaptable, viewOnlyUser]);

  const baseProps = useMemo(
    () => ({
      periods,
      setPeriods,
      changePeriodDate,
      collapsibleColumns,
      setCollapsibleColumns,
      measurementDate: selectedMeasurementDate,
      fieldAttributes,
      financialStatement,
      setFinancialStatement,
      companyMeasurementDate,
      selectedVersion,
      measurementDates,
      createdVersion,
      createVersion,
      fiscalYearData,
      filesParams,
      notesParams,
      setShowAdditionalHistoricalYears,
      additionalHistoricalYears,
      setAdditionalHistoricalYears,
      showAdditionalHistoricalYears,
      getVersions,
      notShowingQuarters,
      currentFiscalYearEnd,
      setIsDisplayingRowNumbers,
      useAdjustedEBITDA,
      isAllocationFinal,
      isDisabled,
      setIsDisabled,
    }),
    [
      filesParams,
      notesParams,
      changePeriodDate,
      collapsibleColumns,
      fieldAttributes,
      periods,
      setPeriods,
      selectedMeasurementDate,
      setCollapsibleColumns,
      financialStatement,
      setFinancialStatement,
      companyMeasurementDate,
      measurementDates,
      selectedVersion,
      createdVersion,
      createVersion,
      fiscalYearData,
      setShowAdditionalHistoricalYears,
      additionalHistoricalYears,
      setAdditionalHistoricalYears,
      showAdditionalHistoricalYears,
      getVersions,
      notShowingQuarters,
      currentFiscalYearEnd,
      setIsDisplayingRowNumbers,
      useAdjustedEBITDA,
      isAllocationFinal,
      isDisabled,
      setIsDisabled,
    ]
  );

  if (!periods) {
    return <GridSkeleton />;
  }

  return (
    <>
      <MDDialog />
      <FinancialsProvider
        periods={periods}
        collapsibleColumns={collapsibleColumns}
        setCollapsibleColumns={setCollapsibleColumns}
        incomeStatement={incomeStatement}
        setIncomeStatement={setIncomeStatement}
        balanceSheets={balanceSheets}
        useAdjustedEBITDA={useAdjustedEBITDA}
        setUseAdjustedEBITDA={setUseAdjustedEBITDA}
        setBalanceSheets={setBalanceSheets}
        kpi={kpi}
        setKpi={setKpi}>
        <div id="financials">
          <div className={classes.marginSm}>
            <div>
              <FinancialsTables {...baseProps} />
              <Widgets
                documentReferencesProps={{
                  selectedMeasurementDate,
                  currentPage: FINANCIALS_REFERENCE_TITLE,
                  referenceType: FINANCIALS_REFERENCE_TYPE,
                  referencedFeatureId: financialStatement?.id,
                  filesToSave,
                  setFilesToSave,
                  isDisplayingRowNumbers,
                  isDisabled,
                }}
                notesProps={{
                  pageType: FINANCIALS_REFERENCE_TYPE,
                  pageTypeKey: FINANCIALS_PAGE_KEY,
                  pageTypeId: financialStatement?.id,
                  notes,
                  setNotes,
                  onAddNote,
                  onUpdateNotes,
                  onDeleteNote,
                  isDisabled,
                }}
              />
            </div>
          </div>
        </div>
      </FinancialsProvider>
    </>
  );
};

export default Financial;
