import { ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES } from 'pages/ValuationsAllocation/common/constants/allocation';
import { getObjectValue } from 'utillities';
import { GetAllocationSpecifiedShareValuesParams } from './types';

const getAllocationSpecifiedShareValues = (params: GetAllocationSpecifiedShareValuesParams) => {
  const { approaches, scenario } = params;

  const { specified_share_values: specifiedShareValuesId, scenario_type: scenarioType } = getObjectValue(scenario);

  const { valuations_approach_ssv: specifiedShareValuesApproach } = getObjectValue(
    approaches?.find(approach => approach?.valuations_approach_ssv?.id === specifiedShareValuesId)
  );

  const scenearioTypeIsSpecifiedShareValues = scenarioType === ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES;

  return {
    specifiedShareValuesApproach,
    scenearioTypeIsSpecifiedShareValues,
  };
};

export default getAllocationSpecifiedShareValues;
