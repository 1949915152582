import React, { useCallback, useContext, useRef, useState } from 'react';
import { Box, Button, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { isEmpty } from 'validate.js';
import {
  DRAG_AND_DROP_DOCUMENTS,
  HOW_DO_I_USE_THIS,
  HOW_DO_I_USE_THIS_INSTRUCTIONS,
  UPLOAD_MORE_DOCUMENTS_DESCRIPTION,
} from 'common/constants/process-management';
import { FILE_TYPE_NOT_ALLOWED, MAX_FILE_SIZE_ALLOWED } from 'common/constants/user';
import validFileTypes from 'components/Documents/validFileTypes';
import { TaskContext } from 'context';
import { useResponse } from 'services/hooks';
import theme from 'theme';
import CircularArrowUpLoader from './CircularArrowUpLoader';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import TaskCompletedBox from './TaskCompletedBox';

const useStyles = makeStyles({
  topBox: isMobile => ({
    borderWidth: isMobile ? '0.125rem' : '0.125rem 0.125rem 0 0.125rem',
    borderColor: theme.palette.primary[500],
    borderRadius: isMobile ? '0.5rem' : '0.5rem 0.5rem 0 0',
    height: isMobile ? '100vw' : '18.75rem',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  arrowUpwardBox: {
    width: '5.5rem',
    height: '5.5rem',
    borderRadius: '50%',
    backgroundColor: theme.palette.gray[100],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowUpwardIcon: {
    color: theme.palette.primary[500],
    width: '3rem',
    height: '3rem',
  },
  uploadLink: {
    color: theme.palette.primary[500],
    textDecoration: 'underline',
    textTransform: 'none',
    padding: '0',
    marginRight: '0.2rem',
  },
  bottomBox: isMobile => ({
    borderWidth: !isMobile && '0 0.125rem 0.125rem 0.125rem',
    borderStyle: 'solid',
    borderColor: theme.palette.primary[900],
    borderRadius: !isMobile && '0 0 0.5rem 0.5rem',
    backgroundColor: theme.palette.primary[900],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '1.5rem 0',
  }),
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  howDoIUseThisBox: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
    color: theme.palette.primary[500],
    marginTop: '1.5rem',
    fontSize: '1.125rem',
  },
  howDoIUseThisIcon: {
    marginRight: '0.5rem',
    width: '1.2rem',
  },
  howDoIUseThisText: {
    color: theme.palette.primary[500],
    cursor: 'default',
  },
  tooltip: {
    backgroundColor: theme.palette.black,
    padding: '1rem 2rem',
  },
});

const HOW_DO_I_USE_THIS_TOOLTIP = (
  <ol>
    {HOW_DO_I_USE_THIS_INSTRUCTIONS.map(item => (
      <li key={item.id} style={{ color: theme.palette.white }}>
        {item.instruction}
      </li>
    ))}
  </ol>
);

const DocumentUploadModal = ({
  progress,
  requestedFiles,
  setOpenSelectionModal,
  setFilesToUpload,
  isUploading,
  showUploadBox,
  setShowUploadBox,
  pairedQuestionnaire,
}) => {
  const { isMobile, task } = useContext(TaskContext);
  const [isOver, setIsOver] = useState(false);
  const classes = useStyles(isMobile, isOver);
  const fileInputRef = useRef();
  const maxSizeAllowedInMb = 15;
  const { errorNotification } = useResponse();

  const handleNeedToUploadMore = () => {
    setShowUploadBox(true);
  };

  const handleOpenSelectionModal = useCallback(() => {
    setOpenSelectionModal(true);
  }, [setOpenSelectionModal]);

  const validateFiles = useCallback(files => {
    const filesTooBig = Array.from(files).filter(file => file.size > MAX_FILE_SIZE_ALLOWED);
    const filesWithInvalidExtensions = Array.from(files).filter(file => {
      const extension = file.name.split('.').pop();
      return !validFileTypes.includes(extension);
    });
    return { filesTooBig, filesWithInvalidExtensions };
  }, []);

  const handleFiles = useCallback(
    files => {
      const { filesTooBig, filesWithInvalidExtensions } = validateFiles(files);
      if (isEmpty(filesTooBig) && isEmpty(filesWithInvalidExtensions)) {
        const filesArray = Array.from(files);
        setFilesToUpload(filesArray.map(file => ({ file, tmpId: uuid() })));
        handleOpenSelectionModal();
      } else {
        filesTooBig.forEach(file => {
          errorNotification(MAX_FILE_SIZE_ALLOWED(maxSizeAllowedInMb, file.name));
        });
        filesWithInvalidExtensions.forEach(file => {
          errorNotification(FILE_TYPE_NOT_ALLOWED(file.name, validFileTypes));
        });
      }
    },
    [errorNotification, handleOpenSelectionModal, setFilesToUpload, validateFiles]
  );

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const multipleFilesInputClicked = useCallback(() => {
    fileInputRef.current.value = null;
    fileInputRef.current.click();
  }, [fileInputRef]);

  const fileInputClicked = useCallback(() => {
    fileInputRef.current.value = null;
    fileInputRef.current.multiple = false;
    fileInputRef.current.click();
  }, [fileInputRef]);

  const preventDefault = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const dragEnter = useCallback(event => {
    preventDefault(event);
    setIsOver(true);
  }, []);

  const onDrop = useCallback(
    event => {
      setIsOver(false);
      preventDefault(event);
      const { files } = event.dataTransfer;
      if (files.length) {
        handleFiles(files);
      }
    },
    [handleFiles]
  );

  const dragOver = useCallback(event => {
    preventDefault(event);
  }, []);

  const taskCompletedBoxProps = {
    progress,
    requestedFiles,
    isMobile,
    handleNeedToUploadMore,
    pairedQuestionnaire,
  };

  if (!showUploadBox) {
    return <TaskCompletedBox {...taskCompletedBoxProps} />;
  }

  return (
    <Box mt={isMobile ? '50%' : '0'}>
      <div
        tabIndex={0}
        role="button"
        className={classes.topBox}
        style={{
          borderStyle: isOver ? 'dashed' : 'solid',
          backgroundColor: isOver ? theme.palette.gray[100] : theme.palette.white,
        }}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDrop={onDrop}>
        <div>
          <Box display="flex" justifyContent="center">
            {!isUploading ? (
              <Button className={classes.arrowUpwardBox} onClick={multipleFilesInputClicked}>
                <ArrowUpwardIcon className={classes.arrowUpwardIcon} />
                <input ref={fileInputRef} hidden type="file" multiple onChange={filesSelected} />
              </Button>
            ) : (
              <CircularArrowUpLoader arrowUpwardIconClass={classes.arrowUpwardIcon} />
            )}
          </Box>
          <Box margin="1.5rem 0" fontSize="1rem" padding="0 2rem">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button onClick={fileInputClicked} className={classes.uploadLink}>
                Upload
              </Button>
              <span>a single document or</span>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button onClick={multipleFilesInputClicked} className={classes.uploadLink}>
                Upload Multiple
              </Button>
              <span>documents</span>
            </Box>
          </Box>
          <div>
            <Typography style={{ fontSize: '0.875rem', padding: '0 2rem' }}>{DRAG_AND_DROP_DOCUMENTS}</Typography>
          </div>
        </div>
      </div>
      <Grid container className={classes.bottomBox}>
        <Grid item xs={6} className={classes.gridItem}>
          <CircularProgressWithLabel
            progress={progress}
            numberStyles={{ fontSize: '1.35rem', color: theme.palette.white }}
            circleSize="5.375rem"
          />
        </Grid>
        <Grid item xs={6} className={classes.gridItem} style={{ flexDirection: 'column' }}>
          <Typography style={{ color: theme.palette.white, fontSize: '1rem' }}>
            {`${requestedFiles.length} Requested Documents`}
          </Typography>
          {!task.task.is_done && requestedFiles.filter(item => item.is_uploaded).length > 0 && (
            <Typography style={{ color: theme.palette.white, fontSize: '1rem' }}>
              {`${requestedFiles.filter(item => !item.is_uploaded).length} Missing`}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          {task.task.is_done && (
            <Typography style={{ color: theme.palette.white, fontSize: '0.875rem', marginTop: '1rem' }} align="center">
              {UPLOAD_MORE_DOCUMENTS_DESCRIPTION}
            </Typography>
          )}
        </Grid>
      </Grid>
      {!isMobile && (
        <Box className={classes.howDoIUseThisBox}>
          <ContactSupportOutlinedIcon className={classes.howDoIUseThisIcon} />
          <Tooltip title={HOW_DO_I_USE_THIS_TOOLTIP} arrow placement="bottom" classes={{ tooltip: classes.tooltip }}>
            <Typography className={classes.howDoIUseThisText}>{HOW_DO_I_USE_THIS}</Typography>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

DocumentUploadModal.propTypes = {
  progress: PropTypes.number,
  requestedFiles: PropTypes.arrayOf(PropTypes.shape({})),
  setOpenSelectionModal: PropTypes.func,
  setFilesToUpload: PropTypes.func,
  isUploading: PropTypes.bool,
  showUploadBox: PropTypes.bool,
  setShowUploadBox: PropTypes.func,
  handleNeedToUploadMore: PropTypes.func,
  pairedQuestionnaire: PropTypes.shape({}),
};

export default DocumentUploadModal;
