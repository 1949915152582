export const ADD_PUBLIC_COMP_GROUP = 'Add Public Comp Group';
export const ADD_TRANSACTION_COMP_GROUP = 'Add Transaction Comp Group';
export const PRIVATE_TRANSACTIONS = 'Private Transactions';
export const VERSIONS_ALIAS = 'Comp Group Versions';
export const NAME_ALIAS = 'Comp Group Name';

export const TARGET_NAME_ALIAS = 'target_name';
export const ACQUIRER_NAME_ALIAS = 'acquirer_name';
export const LTM_REVENUE_ALIAS = 'ltm_revenue';
export const LTM_REVENUE_GROWTH_ALIAS = 'ltm_revenue_growth';
export const LTM_EBITDA_ALIAS = 'ltm_ebitda';
export const LTM_EBITDA_MARGIN_ALIAS = 'ltm_ebitda_margin';
export const LTM_GROSS_MARGIN_ALIAS = 'ltm_gross_margin';
export const TRANSACTION_DATE_ALIAS = 'transaction_date';
export const ENTERPRISE_VALUE_ALIAS = 'enterprise_value';

export const aliasToAPIProp = {
  target_name: 'transaction_target_name',
  acquirer_name: 'transaction_buyer_name',
  transaction_date: 'transaction_closed_date',
  enterprise_value: 'transaction_implied_enterprise_value',
  ltm_revenue: 'transaction_target_ltm_revenue',
  ltm_revenue_growth: 'transaction_ltm_revenue_growth',
  ltm_ebitda: 'transaction_target_ltm_ebitda',
  ltm_ebitda_margin: 'transaction_ebitda_margin',
  ltm_gross_margin: 'transaction_gross_margin',
};
