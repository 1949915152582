import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { debounce, isEmpty, isEqual, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useStayScrolled from 'react-stay-scrolled';
import useDataSheetDialogActions from 'common/hooks/useDataSheetDialogActions';
import ColumnIndicator from 'components/FeaturedSpreadsheet/components/ColumnIndicator';
import DraggableColumns from 'components/FeaturedSpreadsheet/components/DraggableColumns';
import HiddenColumnsChip from 'components/FeaturedSpreadsheet/components/HiddenColumnsChip';
import Row from 'components/FeaturedSpreadsheet/components/Row';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import ScalarDatasheet from 'components/ScalarDatasheet';
import FastValueViewer from 'components/ScalarSpreadsheet/utilities/FastValueViewer';
import { Cell, ValueEditor } from 'components/Spreadsheet/components';
import LayoutContext from 'context/LayoutContext';
import { useScalarSpreadsheetStore } from 'store';
import { getNumberValue, getObjectValue, isCellNavigable } from 'utillities';
import { VALUATION_SUMMARY_SLUG } from './constants';

export const COLUMN_INDICATOR_ADJUSTMENT = '12px';

const FIRST_ROWS = [1, 2, 3];

const commonStylesBorders = backgroundColor => ({
  backgroundColor,
  bottom: `calc(-${COLUMN_INDICATOR_ADJUSTMENT} + 2.5px)`,
  position: 'absolute',
  top: COLUMN_INDICATOR_ADJUSTMENT,
  width: '2px',
  zIndex: 1,
});

const prependClassname = (prefix, className) => {
  if (prefix) {
    return `${prefix}-${className}`;
  }

  return className;
};

const useStyles = makeStyles(theme => ({
  absolute: {
    position: 'absolute',
  },
  columnsContent: {
    position: 'relative',
  },
  borderedLeft: {
    ...commonStylesBorders(theme.palette.primary.main),
    left: 0,
  },
  borderedRight: {
    ...commonStylesBorders(theme.palette.primary.main),
    right: 0,
  },
  separator: {
    position: 'absolute',
    backgroundColor: '#8A9097',
    zIndex: 1,
    width: '2px',
  },
  divider: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    zIndex: 1,
    width: '4px',
  },
  brace: {
    position: 'absolute',
    backgroundColor: '#8A9097',
    zIndex: 1,
    top: 16,
    height: 2,
    '&:before, &:after': {
      content: '""',
      width: 20,
      height: 12,
      position: 'absolute',
      borderTop: '2px solid #8A9097',
    },
  },
  braceLeft: {
    '&:before': {
      borderTopLeftRadius: 20,
      left: -20,
    },
  },
  braceRight: {
    '&:after': {
      borderTopRightRadius: 20,
      right: -20,
    },
  },
}));

const ContentColumns = ({ data, className, tableSlug, isLedgerTable, disableVerticalScroll }) => {
  const classes = useStyles();
  const [hiddenColumns, setHiddenColumns] = useState({ right: 0, left: 0 });
  const [containerRect, setContainerRect] = useState({});
  const [tableHeight, setHeight] = useState({});
  const scrollbarId = `scrollbar-${tableSlug}`;
  const { showAlert } = useContext(LayoutContext);
  const [isLedgerOpen, setIsLedgerOpen] = useState(false);
  const { dialogs } = useDataSheetDialogActions();

  const scrollbarRef = useRef();
  const scrollbarContainer = scrollbarRef.current;

  const { cells, onCellsChanged, collapsibleColumns = {}, displayLegend } = useContext(FeaturedSpreadsheetContext);

  const contentColumnsRef = useRef(null);
  const tableContainerRef = useRef(null);

  // ScalarSpreadsheet Store
  const synchronizedBy = useScalarSpreadsheetStore(state => state.synchronizedBy);
  const synchronizedScrollLeft = useScalarSpreadsheetStore(state => state.synchronizedScrollLeft);
  const synchronizedSpreadsheets = useScalarSpreadsheetStore(state => state.synchronizedSpreadsheets);
  const setSynchronizedScroll = useScalarSpreadsheetStore(state => state.setSynchronizedScroll);

  useLayoutEffect(() => {
    const additionalHeight = tableSlug === VALUATION_SUMMARY_SLUG ? 7 : 1;
    const height = {
      ...(tableContainerRef.current?.offsetHeight
        ? { height: `${getNumberValue(tableContainerRef.current?.offsetHeight) + additionalHeight}px` }
        : {}),
    };
    setHeight(height);
  }, [displayLegend, tableContainerRef, data, tableSlug]);

  useStayScrolled(contentColumnsRef);

  // Synchronize Scroll
  const handleSynchronizedScroll = useCallback(() => {
    requestAnimationFrame(() => {
      const { scrollLeft = 0 } = getObjectValue(scrollbarContainer);

      setSynchronizedScroll({ synchronizedBy: tableSlug, synchronizedScrollLeft: scrollLeft });
    });
  }, [scrollbarContainer, setSynchronizedScroll, tableSlug]);

  const getHiddenColumns = useCallback(
    container => {
      if (!scrollbarContainer) {
        return;
      }

      let currentHiddenColumnsRight = 0;
      let currentHiddenColumnsLeft = 0;

      let { scrollLeft = 0 } = getObjectValue(scrollbarContainer);

      if (container) {
        scrollLeft = container.scrollLeft;
      }

      if (tableContainerRef.current) {
        const containerWidth = tableContainerRef.current?.offsetWidth;

        const columns = {};
        const columnsOffsetLeft = {};

        FIRST_ROWS.forEach(row => {
          const cols = tableContainerRef.current?.querySelectorAll(`table tbody tr:nth-child(${row}) td`);
          const totalOffsetLeft = Array.from(columns).reduce(
            (accumulator, current) => accumulator + current.offsetLeft,
            0
          );

          columns[row] = cols;
          columnsOffsetLeft[row] = totalOffsetLeft;
        });

        const longestRow = Object.keys(columnsOffsetLeft).reduce(
          (a, b) => (columnsOffsetLeft[a] > columnsOffsetLeft[b] ? a : b),
          0
        );

        columns?.[longestRow]?.forEach(col => {
          if (col.offsetLeft >= containerWidth + scrollLeft) {
            currentHiddenColumnsRight += 1;
          } else if (col.offsetLeft > containerWidth) {
            currentHiddenColumnsLeft += 1;
          }
        });

        const currentHiddenColumns = Object.freeze({
          right: currentHiddenColumnsRight,
          left: currentHiddenColumnsLeft,
        });

        if (!isEqual(hiddenColumns, currentHiddenColumns)) {
          setHiddenColumns(currentHiddenColumns);
        }
      }
    },
    [hiddenColumns, scrollbarContainer]
  );

  useEffect(() => {
    const handleResize = () => {
      getHiddenColumns();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getHiddenColumns]);

  useLayoutEffect(() => {
    getHiddenColumns();

    if (contentColumnsRef.current) {
      const el = contentColumnsRef?.current?.getBoundingClientRect();
      setContainerRect(el);
    }
  }, [contentColumnsRef, getHiddenColumns]);

  useEffect(() => {
    if (!isEmpty(collapsibleColumns) || tableContainerRef.current?.offsetWidth > 0) {
      getHiddenColumns();
    }
  }, [collapsibleColumns, getHiddenColumns]);

  // Synchronize scroll between Spreadsheets
  useEffect(() => {
    if (scrollbarContainer && synchronizedSpreadsheets.includes(tableSlug)) {
      // Add Event Listener
      scrollbarContainer.addEventListener('scroll', debounce(handleSynchronizedScroll, 0), { passive: true });
    }

    // Remove Event Listener
    return () => {
      scrollbarContainer?.removeEventListener('scroll', handleSynchronizedScroll);
    };
  }, [handleSynchronizedScroll, scrollbarContainer, synchronizedScrollLeft, synchronizedSpreadsheets, tableSlug]);

  // Update Other Spreadsheets scroll position
  useEffect(() => {
    if (scrollbarContainer && synchronizedSpreadsheets.includes(tableSlug) && synchronizedBy !== tableSlug) {
      scrollbarContainer.scrollLeft = synchronizedScrollLeft;
    }
  }, [scrollbarContainer, synchronizedBy, synchronizedScrollLeft, synchronizedSpreadsheets, tableSlug]);

  const getInvalidCellElement = invalidCell => {
    if (invalidCell) {
      return document.getElementById(`CELL-${invalidCell?.key}`);
    }
    return undefined;
  };

  const moveScrollbarToInvalidCell = useCallback(
    cellElement => {
      if (cellElement && scrollbarContainer) {
        scrollbarContainer.scrollLeft = cellElement.offsetLeft;
      }
    },
    [scrollbarContainer]
  );

  useEffect(() => {
    if (showAlert && cells && scrollbarContainer) {
      const invalidCell = Object.values(cells).find(cell => !isEmpty(cell.tooltipMessages));
      const invalidCellElement = getInvalidCellElement(invalidCell);
      moveScrollbarToInvalidCell(invalidCellElement);
    }
  }, [cells, moveScrollbarToInvalidCell, scrollbarContainer, showAlert]);

  const handleChipClickRight = useCallback(() => {
    if (scrollbarContainer) {
      scrollbarContainer.scrollLeft = tableContainerRef.current?.scrollWidth;
    }
  }, [scrollbarContainer]);

  const handleChipClickLeft = useCallback(() => {
    if (scrollbarContainer) {
      scrollbarContainer.scrollLeft = 0;
    }
  }, [scrollbarContainer]);

  useEffect(() => {
    if (!isLedgerTable && dialogs.find(dialog => !isUndefined(dialog.cellId) && dialog.disableWhenLedgerOpen)) {
      setIsLedgerOpen(true);
    }

    return () => {
      setIsLedgerOpen(false);
    };
  }, [dialogs, isLedgerTable]);

  const dataRenderer = useCallback(cell => (cell?.expr ? cell.expr : cell?.value), []);

  const attributesRenderer = cell => ({
    ...(cell.key ? { id: `CELL-${cell.key}` } : {}),
    'data-parent': cell.parent || '',
    'data-is-parent': (cell.isParent && !isEmpty(collapsibleColumns)) || '',
    'data-col-parent': cell.parentColumn || '',
    'data-child-index': cell.childIndex || '',
    'data-column-legend': cell.columnLegend || cell.colLegend,
    'data-column-id': cell.columnId || 0,
    'data-column-ref': cell.columnRef || 0,
  });

  return (
    <div ref={contentColumnsRef} className={classes.columnsContent} style={tableHeight}>
      <div className={clsx(hiddenColumns.left && classes.borderedLeft)} />
      <div className={clsx(hiddenColumns.right && classes.borderedRight)} />
      <HiddenColumnsChip
        isVisible={!!hiddenColumns.right}
        label={`${hiddenColumns.right} column${hiddenColumns.right > 1 ? 's' : ''}`}
        handleClick={handleChipClickRight}
        position={{
          top: getNumberValue(contentColumnsRef?.current?.offsetHeight) / 2,
          left: containerRect.right,
        }}
        atRight
      />
      <HiddenColumnsChip
        isVisible={!!hiddenColumns.left}
        label={`${hiddenColumns.left} column${hiddenColumns.left > 1 ? 's' : ''}`}
        handleClick={handleChipClickLeft}
        position={{
          top: getNumberValue(contentColumnsRef?.current?.offsetHeight) / 2,
          left: containerRect.left - 66,
        }}
        atRight={false}
      />
      <PerfectScrollbar
        onScrollX={debounce(getHiddenColumns, 100)}
        containerRef={el => {
          scrollbarRef.current = el;
        }}
        className={prependClassname(className, 'scrollbar')}
        options={{ suppressScrollY: disableVerticalScroll }}
        id={scrollbarId}>
        <ColumnIndicator tableRef={tableContainerRef} hiddenColumns={hiddenColumns} />
        <DraggableColumns tableRef={tableContainerRef}>
          <div ref={tableContainerRef}>
            <ScalarDatasheet
              className={prependClassname(className, 'spreadsheet')}
              dataRenderer={dataRenderer}
              onCellsChanged={onCellsChanged}
              data={data}
              rowRenderer={Row}
              dataEditor={ValueEditor}
              valueViewer={FastValueViewer}
              cellRenderer={Cell}
              attributesRenderer={attributesRenderer}
              isCellNavigable={cell => isCellNavigable({ cell, containerRef: tableContainerRef })}
              {...(isLedgerOpen && { selected: null })}
            />
          </div>
        </DraggableColumns>
      </PerfectScrollbar>
    </div>
  );
};

ContentColumns.defaultProps = {
  className: '',
  tableSlug: '',
  isLedgerTable: false,
};

ContentColumns.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
  className: PropTypes.string,
  tableSlug: PropTypes.string,
  isLedgerTable: PropTypes.bool,
  disableVerticalScroll: PropTypes.bool,
};

export default React.memo(ContentColumns, (prevProps, nextProps) => prevProps.data === nextProps.data);
