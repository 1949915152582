/**
 * @file services/companies.js
 * @since v1.0
 * @author Carlos Lenon <code@carloslenon.com>
 */

// #region constant
// #endregion
// #region common
import BaseServiceRetryWrapper from './baseRetry';
import { CAPTABLE } from '../common/endpoints';
// #endregion

class CapatableService extends BaseServiceRetryWrapper {
  /**
   * @function
   * @name dataById
   * @description load captable by id selected.
   * @param {INTEGER} dataId the captable id
   */
  dataById = dataId => this.get(`${CAPTABLE.byId}/${dataId}`);

  /**
   * @function
   * @name getCaptableById
   * @description load captable by id selected.
   * @param {integer} captableId the captable id
   */
  getCaptableById = captableId => this.get(CAPTABLE.captableById(captableId));

  /**
   * @function
   * @name updateCaptableById
   * @description update captable by id selected.
   * @param {INTEGER} captableId the captable id
   */
  updateCaptableById = (captableId, data) => this.put(CAPTABLE.captableById(captableId), data);

  /**
   * @function
   * @name updateSecuritiesById
   * @description update captable by id selected.
   * @param {INTEGER} captableId the captable id
   */
  updateSecuritiesById = (captableId, data) => this.put(CAPTABLE.updateSecurititesById(captableId), data);

  /**
   * @function
   * @name updateFundOwnershipById
   * @description update captable fund ownership by id selected.
   * @param {INTEGER} captableId the captable id
   */
  updateFundOwnershipById = (captableId, data) => this.put(CAPTABLE.updateFundOwnershipById(captableId), data);

  createCapTableProforma = data => this.post(CAPTABLE.createProforma(), data);

  getPrimaryCaptableByCompanyDate = companyDate => this.get(CAPTABLE.primaryCaptableByCompanyDate(companyDate));

  gotFinalAllocation = id => this.get(CAPTABLE.gotFinalAllocation(id));

  hasMeasurementDates = id => this.get(CAPTABLE.hasMeasurementDates(id));

  /**
   * @function
   * @name getAllBreakpoints
   * @description get all breakpoints (waterfall and custom)
   * @param {INTEGER} captableId the captable id
   */
  getAllBreakpointsData = id => this.get(CAPTABLE.allBreakpointsData(id));

  /**
   * @function
   * @name updateCustomBreakpoints
   * @description update custom breakpoints by cap table id.
   * @param {INTEGER} captableId the captable id
   */
  updateCustomBreakpoints = (captableId, data) => this.put(CAPTABLE.allBreakpointsData(captableId), data);
}

export default CapatableService;
