import { CustomUser as UserData } from 'api';
import { ANALYST, HAS_NOT_LOGGED_IN_YET, USER_ROLES } from 'common/constants/user';

export const prettyUsername = (userSelected: UserData) => {
  if (userSelected) {
    const { first_name: firstName, last_name: lastName, email } = userSelected;
    if (firstName && lastName) {
      const fullName = `${firstName} ${lastName}`;
      return fullName.trim().length > 0 ? fullName : email;
    }
    return email;
  }

  return '';
};

export const prettyLastLogin = (userSelected: UserData) => {
  if (userSelected?.last_login) {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return `Last Login: ${new Date(userSelected.last_login).toLocaleString('en-US', options)}`;
  }
  return HAS_NOT_LOGGED_IN_YET;
};

export const prettyRoleType = (roleType: number | null, userSelected: UserData, selectedFirmId: number | null) => {
  const firmsPermissions = userSelected?.firms_permissions || [];
  const userFirmRole
    = firmsPermissions.length > 0 && firmsPermissions.find(p => p.id === selectedFirmId)?.user_firm_role;
  return roleType ?? userFirmRole ?? USER_ROLES[ANALYST].value;
};
