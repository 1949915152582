import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import RowLabelTooltip from 'components/RowLabelTooltip/RowLabelTooltip';

interface RowTitleProps {
  cellProps: {
    title: string;
    link: {
      url: string;
    };
    displayBold: boolean;
  };
  width: number;
  classes: {
    isValuedCompanyCell: string;
    titleRow: string;
  };
}

const RowTitle: FC<RowTitleProps> = ({ cellProps, width, classes }) => {
  const title = cellProps.title || '';
  if (isEmpty(cellProps.link)) {
    return (
      <RowLabelTooltip containerWidth={width} title={title}>
        <span className={cellProps.displayBold ? classes.isValuedCompanyCell : undefined}>{title}</span>
      </RowLabelTooltip>
    );
  }

  return (
    <Link to={cellProps.link.url || '#'} className={classes.titleRow}>
      <small>{cellProps.title}</small>
    </Link>
  );
};

export default RowTitle;
