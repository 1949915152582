import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import validate from 'validate.js';
import { authAction } from 'common/actions';
import {
  ERROR_OCCURRED,
  FIX_BEFORE_CONTINUE,
  IS_REQUIRED,
  PASSWORD_ERROR_MESSAGE,
  PASSWORD_MISMATCH,
} from 'common/constants/messages/validations';
import { userSchemaBase } from 'common/constants/userSchemaBase';
import { useStore } from 'common/store';
import { Alert } from 'components';
import { useCreateUser } from 'services/hooks/auth';
import { dbLongDate } from 'utillities/datesFormats';
import TermsAgreed from './TermsAgreed';
import SocialLogin from '../../Login/components/SocialLogin';

const useStyles = makeStyles(theme => ({
  root: {},
  form: {
    width: '100%',
    maxWidth: 600,
    boxSizing: 'border-box',
    padding: theme.spacing(3),

    '& h2': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    '& .scar-textField': {
      marginBottom: theme.spacing(2),
      width: '28.563rem',
      '& input': {
        borderBottom: `2px solid ${theme.palette.gray[300]}`,
      },
    },
  },
  btn: {
    marginTop: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(3),
    fontSize: '1.125rem',
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signUpButton: {
    margin: theme.spacing(2, 0),
    fontSize: '0.875rem',
    width: '28.563rem',
  },
  helperText: {
    color: `${theme.palette.pink}!important`,
  },
  label: {
    '&$erroredLabel': {
      color: theme.palette.pink,
    },
  },
  erroredLabel: {},
  underline: {
    '&$error:after': {
      borderBottomColor: theme.palette.pink,
    },
  },
  error: {},
  termsAgreed: {
    fontSize: '0.875rem',
    cursor: 'default',
  },
  termsAgreedLinks: {
    marginLeft: '0.2rem',
  },
  text: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const emptyForm = {
  isValid: false,
  values: {},
  touched: {},
  errors: {},
};

const schema = {
  ...userSchemaBase,
  password: {
    presence: { allowEmpty: false, message: IS_REQUIRED },
    length: {
      maximum: 128,
    },
    format: {
      pattern: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_]).{9,}$/,
      message: `^${PASSWORD_ERROR_MESSAGE}`,
    },
  },
  re_password: {
    presence: { allowEmpty: false, message: IS_REQUIRED },
    length: {
      maximum: 128,
    },
    equality: {
      attribute: 'password',
      message: `^${PASSWORD_MISMATCH}`,
    },
  },
};

const SignUpForm = ({ setIsAccountCreated }) => {
  const classes = useStyles();

  const createUser = useCreateUser();
  const [, dispatch] = useStore();
  const [formState, setFormState] = useState(emptyForm);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState('success');

  const handleChange = event => {
    event.persist();

    setFormState(prevFormState => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const addtermsAgreedDate = data => ({
    ...data,
    profile: {
      terms_last_agreed: dbLongDate(),
    },
  });

  const handleSignUp = async event => {
    setIsAlertVisible(false);
    event.preventDefault();

    if (termsAgreed && formState.isValid) {
      const newUserData = addtermsAgreedDate(formState.values);
      const [data, error] = await createUser(newUserData);

      if (error) {
        setSeverity('error');
        if (error.status === 400) {
          setMessage(FIX_BEFORE_CONTINUE);
          setFormState({
            ...formState,
            errors: JSON.parse(error.response.text),
            isValid: false,
          });
        } else {
          setMessage(ERROR_OCCURRED);
        }
        setIsAlertVisible(true);
      } else {
        setFormState(emptyForm);
        dispatch(authAction.setActivationEmail(data.email));
        setIsAccountCreated(true);
      }
    }
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(prevFormState => ({
      ...prevFormState,
      isValid: isUndefined(errors) || isEmpty(errors),
      errors: errors || {},
    }));
  }, [formState.values]);

  const hasError = field => formState.touched[field] && formState.errors[field];

  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={handleSignUp}>
        <Typography className={classes.title} variant="h2">
          Create new account
        </Typography>
        <Alert severity={severity} isAlertVisible={isAlertVisible}>
          {message}
        </Alert>
        <TextField
          className="scar-textField"
          error={!!hasError('first_name')}
          fullWidth
          helperText={hasError('first_name') ? formState.errors.first_name[0] : null}
          label="First name"
          name="first_name"
          onChange={handleChange}
          type="text"
          value={formState.values.first_name || ''}
          inputProps={{ 'aria-label': 'first_name' }}
          id="user-name"
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
        />
        <TextField
          className="scar-textField"
          error={!!hasError('last_name')}
          fullWidth
          helperText={hasError('last_name') ? formState.errors.last_name[0] : null}
          label="Last name"
          name="last_name"
          onChange={handleChange}
          type="text"
          value={formState.values.last_name || ''}
          inputProps={{ 'aria-label': 'last_name' }}
          id="user-last-name"
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
        />
        <TextField
          className="scar-textField"
          error={!!hasError('email')}
          fullWidth
          helperText={hasError('email') ? formState.errors.email[0] : null}
          label="Email address"
          name="email"
          onChange={handleChange}
          type="text"
          value={formState.values.email || ''}
          inputProps={{ 'aria-label': 'email' }}
          id="user-email"
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
        />
        <TextField
          className="scar-textField"
          error={!!hasError('password')}
          fullWidth
          helperText={hasError('password') ? formState.errors.password[0] : null}
          label="Password"
          name="password"
          onChange={handleChange}
          type="password"
          value={formState.values.password || ''}
          inputProps={{ 'aria-label': 'password' }}
          id="user-password"
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
        />
        <TextField
          className="scar-textField"
          error={!!hasError('re_password')}
          fullWidth
          helperText={hasError('re_password') ? formState.errors.re_password[0] : null}
          label="Confirm password"
          name="re_password"
          onChange={handleChange}
          type="password"
          value={formState.values.re_password || ''}
          inputProps={{ 'aria-label': 're_password' }}
          id="user-confirm-password"
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
        />
        <TermsAgreed setTermsAgreed={setTermsAgreed} termsAgreed={termsAgreed} />
        <Button
          className={classes.signUpButton}
          color="secondary"
          disabled={!termsAgreed || !formState.isValid}
          fullWidth
          type="submit"
          variant="contained"
          name="submit">
          Sign up now
        </Button>
        <Typography component="p" variant="h3" className={classes.text}>
          OR
        </Typography>
        <SocialLogin setAlert={setIsAlertVisible} setError={setMessage} />
      </form>
    </div>
  );
};

SignUpForm.propTypes = {
  setIsAccountCreated: PropTypes.func.isRequired,
};

export default withRouter(SignUpForm);
