import React from 'react';
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_SYMBOL } from 'components/FeaturedSpreadsheet/constants';
import { GridRowLabel } from 'components/Grid';
import { Cell } from 'components/ScalarSpreadsheet/utilities/Cell';
import { mergeCells, removeClassName } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import defaultTitleParser from './defaultTitleParser';

export class SpreadsheetConfig {
  constructor({
    columns,
    parser,
    totalParser,
    titleParser = defaultTitleParser,
    rowConfig,
    name,
    tableData,
    afterCellChanged = changes => changes,
    conditions = undefined,
    disabled = false,
    currency = `${DEFAULT_CURRENCY} ${DEFAULT_CURRENCY_SYMBOL}`,
    ignoreCurrencyChangeNumber = 0,
    hasColTitle = true,
    colTitleRow = 0,
    reverseParser = undefined,
    setDataSource = undefined,
    tableTerms = undefined,
    customTitles = undefined,
    setColumns = undefined,
    showTotalColumn = false,
    showTitlesColumn = true,
    showToolbar = false,
    showPreviousColsDivider = false,
    showFirstRowAsTitle = true,
    allowConfirmAndDeleteColumn = true,
    matchCurrencyPadding = false,
    handleDeleteColumn = undefined,
    allowCloneColumn = undefined,
    cloneColumn = undefined,
    allowReorderColumns = undefined,
    allowEmptyValues = true,
    page = undefined,
    currencyFormatter,
    unitsFormatter,
    format,
    initialObj = undefined,
    customValidations = undefined,
    shouldValidate = true,
    fieldAttributes = {},
    allowDeleteOnlySpecificColumn = false,
    disableDeleteBtnOnFirstColumn = false,
    customDeleteConfirmation = false,
    alwaysDisplayLegend = false,
    reverseParseAfterLoad = false,
    isReadOnly = false,
    displayRowIndicator = true,
    displayColumnIndicator = true,
    allowCopyColumn = false,
    allowDeleteColumn = false,
    allowCopyRows = false,
    allowAddMultipleRows = false,
    allowAddMultipleColumns = false,
    allowAddSingleRow = false,
    allowDeleteRow = false,
  }) {
    this.handleDeleteColumn = handleDeleteColumn;
    this.allowCloneColumn = allowCloneColumn;
    this.cloneColumn = cloneColumn;
    this.allowReorderColumns = allowReorderColumns;
    this.matchCurrencyPadding = matchCurrencyPadding;
    this.showTotalColumn = showTotalColumn;
    this.showTitlesColumn = showTitlesColumn;
    this.showToolbar = showToolbar;
    this.showPreviousColsDivider = showPreviousColsDivider;
    this.showFirstRowAsTitle = showFirstRowAsTitle;
    this.allowConfirmAndDeleteColumn = allowConfirmAndDeleteColumn;
    this.afterCellChanged = afterCellChanged;
    this.conditions = conditions;
    this.tableData = tableData;
    this.rowConfig = rowConfig;
    this.columns = columns;
    this.name = name;
    this.disabled = disabled;
    this.currency = currency;
    this.initialCurrencyType = currency;
    this.ignoreCurrencyChangeNumber = ignoreCurrencyChangeNumber;
    this.hasColTitle = hasColTitle;
    this.parser = parser;
    this.xLength = rowConfig.length;
    this.yLength = columns.length;
    this.alphabet = alphabetGenerator([], this.yLength);
    this.colTitleRow = colTitleRow;
    this.reverseParser = reverseParser;
    this.setDataSource = setDataSource;
    this.tableTerms = tableTerms;
    this.customTitles = customTitles;
    this.setColumns = setColumns;
    this.allowEmptyValues = allowEmptyValues;
    this.page = page;
    this.currencyFormatter = currencyFormatter;
    this.unitsFormatter = unitsFormatter;
    this.format = format;
    this.initialObj = initialObj;
    this.customValidations = customValidations;
    this.shouldValidate = shouldValidate;
    this.fieldAttributes = fieldAttributes;
    this.allowDeleteOnlySpecificColumn = allowDeleteOnlySpecificColumn;
    this.disableDeleteBtnOnFirstColumn = disableDeleteBtnOnFirstColumn;
    this.totalParser = totalParser;
    this.titleParser = titleParser;
    this.customDeleteConfirmation = customDeleteConfirmation;
    this.showTitlesColumn = showTitlesColumn;
    this.alwaysDisplayLegend = alwaysDisplayLegend;
    this.reverseParseAfterLoad = reverseParseAfterLoad;
    this.isReadOnly = isReadOnly;
    this.displayRowIndicator = displayRowIndicator;
    this.displayColumnIndicator = displayColumnIndicator;
    this.allowCopyColumn = allowCopyColumn;
    this.allowDeleteColumn = allowDeleteColumn;
    this.allowCopyRows = allowCopyRows;
    this.allowAddMultipleRows = allowAddMultipleRows;
    this.allowAddMultipleColumns = allowAddMultipleColumns;
    this.allowAddSingleRow = allowAddSingleRow;
    this.allowDeleteRow = allowDeleteRow;
  }

  async evaluate() {
    this.cells = await this.parser({
      columns: this.columns,
      rowConfig: this.rowConfig,
      tableData: this.tableData,
      currencyCode: this.currency.substring(0, 3), // 'USD', 'EUR', 'GBP', etc.
      initialObject: this.initialObj,
    });

    if (this.showTotalColumn) {
      this.totalCells = this.totalParser({
        columns: this.columns,
        rows: this.rowConfig,
        cells: this.cells,
      });
    }

    const titleRows = this.customTitles || this.rowConfig;
    this.titleCells = this.titleParser({
      columns: this.columns,
      rows: titleRows,
      cells: this.cells,
    });
    this.applyConditions({ [this.name]: this.cells });

    const [generatedTitles, generatedContent, generatedTotals] = this.generateData();
    this.titleData = generatedTitles;
    this.data = generatedContent;
    this.totalData = generatedTotals;
    this.generateCells([[this.titleData, 'TITLES'], [this.data], [this.totalData, 'TOTALS']]);
  }

  reverseShouldValidate() {
    this.shouldValidate = !this.shouldValidate;
  }

  setShouldValidate(shouldValidate) {
    this.shouldValidate = shouldValidate;
  }

  applyChangeConditions(changes, scope, onChange) {
    if (this.conditions) {
      changes.forEach(changeCell => {
        this.conditions({
          cell: changeCell,
          cells: scope,
          sheet: this,
          tableData: this.tableData,
          rowConfig: this.rowConfig,
          onCellsChanged: cellChanges => {
            cellChanges.forEach(({ cell, value }) => {
              onChange(cell, value);
            });
          },
        });
      });
    }
  }

  applyConditions(scope) {
    if (this.conditions) {
      Object.values(this.cells)
        .filter(c => !c.totalKey)
        .forEach(cell => {
          this.conditions({
            cell,
            cells: scope,
            sheet: this,
            tableData: this.tableData,
            rowConfig: this.rowConfig,
            onCellsChanged: (changes, changeScope) => {
              changes.forEach(({ cell: changeCell, value }) => {
                changeCell.onChange(value, changeScope);
              });
            },
          });
        });
    }
  }

  updateAllCellsDisplayConfig() {
    Object.values(this.cells).forEach(cell => {
      this.setCellDisplayConfig(cell);
    });
  }

  setCellDisplayConfig(cell) {
    cell.setDisplayConfig(this.format, cell.customFormat);
    cell.dispatchEvent();
  }

  resetData() {
    this.data = [...this.data];
  }

  reverseParse() {
    if (this.reverseParser) {
      return this.reverseParser({
        titleCells: this.titleCells,
        cells: this.cells,
        columns: this.columns,
        allowEmptyValues: this.allowEmptyValues,
        rowConfig: this.rowConfig,
        tableData: this.tableData,
        fieldAttributes: this.fieldAttributes,
        initialObj: this.initialObj,
        sheet: this,
      });
    }
    return undefined;
  }

  generateCells(dataList) {
    const soFar = {};
    const obj = this;

    const mapCellKey = (cells, cell, indexKey) => {
      // eslint-disable-next-line no-param-reassign
      cells[indexKey] = obj.cells?.[indexKey]?.evaluated ? obj.cells[cell.key] : cell;
      if (cell.isTitleCell && cell.key !== indexKey) {
        // eslint-disable-next-line no-param-reassign
        cell.key = indexKey;
      }
    };

    const generateKey = (keyExtension, configKey) => {
      if (keyExtension && configKey) {
        return `${keyExtension}_${configKey}`;
      }
      return configKey;
    };

    dataList
      .filter(d => d)
      .forEach(([data, keyExtension]) => {
        data.forEach(row => {
          row.forEach(cell => {
            const key = generateKey(keyExtension, cell.key);
            if (key) {
              mapCellKey(soFar, cell, key);
              if (cell.customKey) {
                const customKey = (keyExtension ? `${keyExtension}_` : '') + cell.customKey;
                mapCellKey(soFar, cell, customKey);
              }
            }
          });
        });
      });
    this.cells = soFar;
  }

  async addColumns(index, columns) {
    this.columns.splice(index, 0, ...columns);
    this.yLength = this.columns.length;
    this.alphabet = alphabetGenerator([], this.yLength);
  }

  async addRows(index, rows) {
    this.rowConfig.splice(index, 0, ...rows);
    this.xLength = this.rowConfig.length;
  }

  async reset({ rowConfig, columns, tableData }) {
    if (rowConfig) {
      this.rowConfig = rowConfig;
      this.xLength = rowConfig.length;
    }

    if (columns) {
      this.columns = columns;
      this.yLength = this.columns.length;
      this.alphabet = alphabetGenerator([], this.yLength);
    }

    if (tableData) {
      this.tableData = tableData;
    }
  }

  deleteColumns(index, deleteCount) {
    for (let i = index; i < deleteCount + index; i++) {
      this.columns[i].is_deleted = true;
    }
    this.setColumns(this.columns);
    this.yLength = this.columns.length;
    this.alphabet = alphabetGenerator([], this.yLength);
  }

  generateCell({ cell, colIndex, rowIndex }) {
    const inputCell = Object.assign(new Cell(cell.expr), cell);

    if (rowIndex === 0 && (this.currencyFormatter || this.unitsFormatter)) {
      inputCell.className = cell.className ? `${cell.className} formatter-cell` : 'formatter-cell';
    }

    this.setCellDisplayConfig(inputCell);
    inputCell.setKey(cell.key, this);
    inputCell.setXY(rowIndex, colIndex);

    return inputCell;
  }

  generateData() {
    const titleRows = [];
    const updatedRows = [];
    const totalRows = [];

    this.rowConfig.forEach((row, rowIndex) => {
      const isFirstRow = rowIndex === 0;
      const titleRow = [];
      const tableRow = [];
      const totalRow = [];
      const isColTitle = rowIndex === this.colTitleRow;
      for (let colIndex = 0; colIndex < this.yLength; colIndex += 1) {
        const colLegend = this.alphabet[colIndex];
        const cellKey = colLegend + (rowIndex + 1);
        let cell;
        // ignoreRowCopy allows us to optionally ignore adding default props to a given cell
        if (row.ignoreRowCopy) {
          cell = {
            readOnly: true,
            ...(this.cells[cellKey] ? this.cells[cellKey] : {}),
          };
        } else {
          cell = {
            readOnly: true,
            ...row,
            ...(this.cells[cellKey] ? this.cells[cellKey] : {}),
          };
        }

        if (this.isReadOnly) {
          cell.readOnly = true;
        }

        if (this.hasColTitle && isColTitle) {
          cell.className = cell.className ? `${cell.className} table-header` : 'table-header';
        }

        if (row.largeHeader) {
          cell.className = cell.className ? `${cell.className} large-header` : 'large-header';
        }

        if (rowIndex === 0 && (this.currencyFormatter || this.unitsFormatter)) {
          cell.className = cell.className ? `${cell.className} formatter-cell` : 'formatter-cell';
        }

        if (cell.hidden) cell.value = null;

        if (this.disabled) {
          cell.readOnly = true;
          cell.className = removeClassName(cell.className, 'read-only--white');
        }

        if (row.ignoreRowCopy && row.isClassNameRequired) {
          const { className } = row;
          cell.className = cell.className ? `${cell.className} ${className}` : className;
        }

        if (cell.ignoreExpr) {
          cell.expr = undefined;
        }

        cell.expr = cell.expr || cell.value;

        const inputCell = Object.assign(new Cell(cell.expr), cell);

        if (cell.component) {
          const newProps = { cell: inputCell, disabled: this.disabled };
          inputCell.component = React.cloneElement(cell.component, newProps);
        }

        this.setCellDisplayConfig(inputCell);
        inputCell.setKey(cell.key, this);
        inputCell.setXY(rowIndex, colIndex);
        tableRow.push(inputCell);
      }

      if (this.showTotalColumn && Object.entries(this.totalCells)[rowIndex]) {
        const [totalCellKey, totalCell] = Object.entries(this.totalCells)[rowIndex];
        const totalTableCell = this.generateCell({
          cell: { totalKey: totalCellKey, ...totalCell },
          rowIndex,
          colIndex: 0,
        });

        totalRow.push(totalTableCell);
      }

      if (this.titleCells && Object.entries(this.titleCells)[rowIndex]) {
        const [titleCellKey, titleCell] = Object.entries(this.titleCells)[rowIndex];

        let cell = {
          ...titleCell,
          readOnly: true,
        };

        const copyOfCell = { ...cell };

        // Generate Column Legends (A, B, C, D...)
        let legendClassName = 'legend row-legend cell read-only';
        // if cell has largeHeader == true and this.showTitlesColumn == false, append large-header class to legendClassName
        if (cell.largeHeader && !this.showTitlesColumn) {
          legendClassName += ' large-header';
        }
        const legendCell = this.generateCell({
          cell: {
            readOnly: true,
            className: legendClassName,
            forceComponent: false,
            expr: rowIndex + 1,
            value: rowIndex + 1,
            isLegend: true,
            valueViewer: null,
            isVisible: row.isVisible,
            hasSubRows: row.hasSubRows,
            showSubRows: row.showSubRows,
          },
          rowIndex,
          colIndex: 0,
        });
        titleRow.push(legendCell);
        if (this.showTitlesColumn) {
          const isEditable = row.isEditableTitleCell;
          const classNames = ['row-label'];

          if (isFirstRow && this.showFirstRowAsTitle) {
            classNames.push('row-label-title');
          }

          if (row.className) {
            classNames.push(row.className);
          }

          if (isFirstRow && this.hasColTitle && this.showFirstRowAsTitle) {
            classNames.push('table-header');
          }

          if (isFirstRow && (this.currencyFormatter || this.unitsFormatter)) {
            classNames.push('formatter-cell');
          }

          if (titleCell.rowSpan && titleCell.rowSpan > 1) {
            classNames.push(`row-span-${titleCell.rowSpan}`);
          }

          cell = {
            ...cell,
            alias: isEditable ? row.alias : titleCellKey,
            linkedCellSymbols: isEditable ? row.linkedCellSymbols : undefined,
            expr: isEditable ? row.expr : '',
            key: titleCellKey,
            titleAlias: row.alias,
            className: classNames.join(' '),
            component: (
              <GridRowLabel
                cell={{ ...titleCell }}
                currencyFormatter={isFirstRow && this.currencyFormatter}
                unitsFormatter={isFirstRow && this.unitsFormatter}
                formatterCell={isFirstRow}
              />
            ),
            forceComponent: true,
            rowSpan: titleCell.rowSpan || 1,
            style: titleCell.style || {},
            isTitleCell: true,
          };

          if (cell.titleClassName) {
            cell.className = `${cell.className} ${cell.titleClassName}`;
          }

          if (cell.isEditableTitleCell || cell.isTitleWithLabel) {
            classNames.push('editable');
            cell = {
              ...cell,
              key: row.alias,
              component: null,
              forceComponent: false,
              readOnly: cell.isTitleWithLabel,
              valueViewer: copyOfCell.valueViewer,
              dataEditor: copyOfCell.dataEditor,
              value: row.value,
              expr: row.value,
              isRequired: true,
              sheet: this.accessedSheet,
              gridType: 'string',
            };
            if (cell.useScalarSpreadsheetCell && cell.dropdown) {
              cell = Object.assign(new Cell(cell.expr), {
                ...cell,
                className: `${cell.className} titles-column-selector`,
              });
            }
          }

          const tableCell = this.generateCell({ cell, rowIndex, colIndex: 0 });
          titleRow.push(tableCell);
        }
      }

      titleRows.push(titleRow);
      updatedRows.push(tableRow);
      totalRows.push(totalRow);
    });

    mergeCells(titleRows);
    mergeCells(updatedRows);
    return [titleRows, updatedRows, totalRows];
  }
}
