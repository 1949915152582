/* eslint-disable import/no-unresolved */

import React, { FC, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { globalAction } from 'common/actions';
import { FUNDS_ADD_INVESTMENTS, FUNDS_INVESTMENTS_TITLE, FUNDS_NO_INVESTMENTS } from 'common/constants/funds';
import { useStore } from 'common/store';
import { UseStoreValues } from 'common/types/store';
import { MessageBox } from 'components';
import { SectionHeading } from 'pages/Funds/components';
import { useGetFundInvestmentsByMD } from 'services/hooks/funds';
import { getNumberValue } from 'utillities';
import { CompanyInvestments, LoadingFundInvestments } from './components';
import { ScheduleOfInvestmentsProps } from './types';

const ScheduleOfInvestments: FC<ScheduleOfInvestmentsProps> = props => {
  const { fund, measurementDate } = props;

  const [, dispatch] = useStore() as unknown as UseStoreValues;

  const { data: fundInvestments, isFetching, isLoading } = useGetFundInvestmentsByMD({ fund, measurementDate });

  // Show Loading Progress
  useEffect(() => {
    dispatch?.(globalAction.showLoadingProgress(isLoading));
  }, [dispatch, isLoading]);

  // If there are no investments made on this Fund and the data is still loading
  if (isEmpty(fundInvestments?.companies)) {
    if (isLoading) return <LoadingFundInvestments />;

    // No Investments made on this Fund
    return <MessageBox fullWidth={false} tagline={FUNDS_ADD_INVESTMENTS} title={FUNDS_NO_INVESTMENTS} />;
  }

  return (
    <Grid>
      {!isLoading && <SectionHeading title={FUNDS_INVESTMENTS_TITLE} />}

      {fundInvestments?.companies?.map(company => (
        <CompanyInvestments
          company={company}
          isLoading={isLoading}
          isFetching={isFetching}
          key={getNumberValue(company?.company_id)}
          measurementDate={measurementDate}
        />
      ))}
    </Grid>
  );
};

export default ScheduleOfInvestments;
