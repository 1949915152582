/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import { range } from 'lodash';

const useStyles = makeStyles(() => ({
  skeleton: {
    backgroundColor: 'rgba(255,255,255, 25%)',
  },
}));

const BottomMenuItems = () => {
  const classes = useStyles();

  return (
    <List>
      {range(2).map(item => (
        <div key={item}>
          <ListItem>
            <ListItemIcon>
              <Skeleton variant="circle" width={28} height={28} className={classes.skeleton} />
            </ListItemIcon>
            <ListItemText
              primary={<Skeleton variant="text" width={'90%'} height={30} className={classes.skeleton} />}
              secondary={<Skeleton variant="text" width={'50%'} height={15} className={classes.skeleton} />}
              className={classes.bottomMenuItemText}
            />
          </ListItem>
        </div>
      ))}
    </List>
  );
};

export default BottomMenuItems;
