/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConvertibleNoteModel = {
  readonly id?: number;
  readonly created_at?: string;
  readonly updated_at?: string;
  is_deleted?: boolean | null;
  readonly deleted_at?: string | null;
  note_name: string;
  issuance_date: string;
  maturity_date: string;
  note_principle_amount: string;
  interest_rate: string;
  compounding?: boolean;
  compounding_periods_per_year?: number | null;
  conversion_discount?: string | null;
  conversion_cap?: string | null;
  terms_anytime_prior?: string | null;
  terms_at_qualified_financing?: string | null;
  terms_at_change_of_control?: string | null;
  terms_at_maturity: string;
  model_as_equity?: ConvertibleNoteModel.model_as_equity;
  loan_value?: string | null;
  conversion_date?: string | null;
  interest_at_md?: string | null;
  value_at_md?: string | null;
  value_at_conversion?: string | null;
  next_round_premoney?: string | null;
  next_round_premoney_enabled?: boolean;
  conversion_premoney?: string | null;
  conversion_premoney_enabled?: boolean;
  pre_money_share_count?: string | null;
  conversion_price_per_share?: string | null;
  current_ownership?: string | null;
  expected_shares?: string | null;
  security_group_ref?: string | null;
  convertible_note_security?: number | null;
};

export namespace ConvertibleNoteModel {
  export enum model_as_equity {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }
}
