import React from 'react';
import PropTypes from 'prop-types';

// Userback is a 3rd party tool for tracking bugs and feedback
// This adds the Userback script to the app
const UserbackProvider = ({ children }) => {
  if (process.env.REACT_APP_USERBACK_ACCESS_TOKEN) {
    window.Userback = window.Userback || {};
    window.Userback.access_token = process.env.REACT_APP_USERBACK_ACCESS_TOKEN;

    // Add Userback JavaScript SDK
    (function (d) {
      const s = d.createElement('script');
      s.async = true;
      s.src = 'https://userback.scalar.io/widget/v1.js';
      (d.head || d.body).appendChild(s);
    })(document);
  }

  return <span>{children}</span>;
};

UserbackProvider.propTypes = {
  children: PropTypes.element,
};

export default UserbackProvider;

// Separate method used to trigger the Userback model/dialog
export const ShowUserback = user => {
  // Check if Userback is loaded
  if (!window.Userback?.isLoaded()) {
    return false;
  }

  // Indentify user so feedback includes user information
  window.Userback.identify(user.id, user);
  window.Userback.email = user.email;
  window.Userback.name = `${user.first_name} ${user.last_name}`;
  // Add additional data to Userback that might be helpful for debugging
  window.Userback.setData(process.env);
  // Open feedback modal
  window.Userback.open();

  return true;
};
