export const colors = [
  'Red',
  'Blue',
  'Green',
  'Purple',
  'Orange',
  'Pink',
  'Brown',
  'Gray',
  'Cyan',
  'Magenta',
  'Lime',
  'Teal',
  'Lavender',
  'Maroon',
  'Navy',
  'Olive',
  'Coral',
  'Aquamarine',
  'Salmon',
  'Gold',
  'Turquoise',
  'Indigo',
  'Violet',
  'Crimson',
  'Chocolate',
  'Tomato',
  'Plum',
  'Orchid',
  'Beige',
  'CornflowerBlue',
  'LemonChiffon',
  'PaleGreen',
  'SeaGreen',
  'SteelBlue',
  'SlateBlue',
  'SandyBrown',
  'FireBrick',
  'PaleTurquoise',
  'PeachPuff',
];
const convertColorNamesToRgba = (colorNames, alpha = 0.1) =>
  colorNames.map(colorName => {
    const tempElement = document.createElement('div');
    tempElement.style.color = colorName;
    document.body.appendChild(tempElement);

    const computedStyle = window.getComputedStyle(tempElement);

    // Remove spaces from color
    let rgb = computedStyle.getPropertyValue('color').replace(/ /g, '');

    // Remove rgb() from color
    const rgbRegExp = /^rgb\((\d+),(\d+),(\d+)\)$/;

    rgb = rgbRegExp.exec(rgb);

    // Convert to rgba
    const rgbaValue = `rgba(${rgb[1]},${rgb[2]},${rgb[3]},${alpha})`;

    document.body.removeChild(tempElement);

    return rgbaValue;
  });
export const convertedColors = convertColorNamesToRgba(colors, 0.1);
