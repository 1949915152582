// Aliases

export const EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD = 'EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD';
export const EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION = 'EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION';
export const EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE = 'EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE';
export const EQUITY_ALLOCATION_SPREADSHEET_HEADER_TITLE = 'EQUITY_ALLOCATION_SPREADSHEET_HEADER_TITLE';
export const EQUITY_ALLOCATION_SPREADSHEET_MATURITY = 'EQUITY_ALLOCATION_SPREADSHEET_MATURITY';
export const EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS = 'EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS';
export const EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE = 'EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE';
export const EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE
  = 'EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE';
export const EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE = 'EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE';
export const EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY
  = 'EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY';
export const EQUITY_ALLOCATION_SPREADSHEET_TOTAL = 'EQUITY_ALLOCATION_SPREADSHEET_TOTAL';
export const EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS
  = 'EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS';
export const EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY = 'EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY';

// Keys
