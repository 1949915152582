import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import ConfirmationDialog from './ConfirmationDialog';

const ConfirmationCancelDialog = ({ open, title, onClose, actions }) => (
  <ConfirmationDialog open={open} title={title} onClose={onClose} actions={actions} maxWidth="xs">
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <p>Are you sure you want to continue?</p>
      <br />
      <p>All changes will be lost.</p>
    </Box>
  </ConfirmationDialog>
);

ConfirmationCancelDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
};

export default ConfirmationCancelDialog;
