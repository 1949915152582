import { createStyles, makeStyles } from '@material-ui/styles';
import { SMALL_FONT_SIZE } from 'dashboard409a/common/constants';
import { ITheme } from 'theme/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const elementWidth = 175;

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),

      // [theme.breakpoints.up('lg')]: {
      //   minWidth: elementWidth,
      // },
    },
    label: {
      color: theme.palette.gray[400],
      fontSize: '0.75em',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    select: {
      color: theme.palette.gray[500],
      paddingRight: theme.spacing(1),

      // [theme.breakpoints.up('lg')]: {
      //   maxWidth: elementWidth,
      // },
    },
    previousReports: {
      color: theme.palette.primary.main,
      fontSize: SMALL_FONT_SIZE,
      marginTop: theme.spacing(0.5),
      textAlign: 'right',
    },
  })
);

export default useStyles;
