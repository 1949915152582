import React from 'react';
import { Grid } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon } from '@material-ui/icons';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { LTM, NTM } from 'common/constants/financials';
import {
  DATA_UNAVAILABLE,
  DEFAULT_ZERO_VALUE,
  SELECTION_A,
  SELECTION_B,
  VALUATIONS_DEFAULT_CURRENCY,
} from 'common/constants/valuations';
import {
  BENCHMARK_ALIASES,
  MEAN_ALIAS,
  MEDIAN_ALIAS,
  PERCENTILE_25,
  PERCENTILE_75,
  PERCENTILE_SELECTION_A_ALIAS,
  PERCENTILE_SELECTION_B_ALIAS,
  SELECTED_MULTIPLE,
  SELECTED_MULTIPLE_VALUE,
  SEVENTY_FIFTH_ALIAS,
  SPECIFIED_LABEL,
  TWENTY_FIFTH_ALIAS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { percentileOptions } from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/getRowConfig';
import { PUBLIC_COMPANIES, SORT_ASC } from 'pages/Valuations/util/constants';
import ValuationService from 'services/valuations';

export const parseGpcComparison = ({ data, attributes }) => {
  const gpc = data;
  const { comp_group_id, comp_group_name, ...newGpc } = gpc;

  let calendarYearsFinancials = {};

  const flattened = Object.keys(newGpc).reduce((flattenedObj, key) => {
    // if key starts with year, 2016_revenue
    if (Date.parse(key.slice(0, 4))) {
      const [year, prop] = key.split('_');
      if (calendarYearsFinancials[year]) {
        calendarYearsFinancials[year] = {
          ...calendarYearsFinancials[year],
          [prop]: gpc[key].value,
        };
      } else {
        calendarYearsFinancials = {
          ...calendarYearsFinancials,
          [year]: {
            [prop]: gpc[key].value,
          },
        };
      }
    } else {
      const { value } = gpc[key];
      // eslint-disable-next-line no-param-reassign
      flattenedObj[key]
        = typeof value === 'number' && attributes[key]
          ? parseFloat(value).toFixed(attributes[key].decimal_places)
          : value;
    }
    return flattenedObj;
  }, {});

  flattened.ltm_revenue_enabled = true;
  flattened.ltm_ebitda_enabled = true;
  flattened.ntm_revenue_enabled = true;
  flattened.ntm_ebitda_enabled = true;
  flattened.name = flattened.company_name;
  flattened.calendar_years_financials = calendarYearsFinancials;
  flattened.reference_for_backsolve = flattened.cap_iq_id;

  if (comp_group_id && comp_group_name) {
    flattened.comp_group_id = comp_group_id;
    flattened.comp_group_name = comp_group_name;
    flattened.temp_ref = comp_group_id;
  }
  return flattened;
};

/**
 * @param {Object} comparison Comparison Object.
 * @param {Array} periods Financials Statement Periods.
 * @param {number} periodId Selected financial statement period id.
 * @param {string} type ebitda or revenue.
 */
export const getFinancialPeriodValue = ({ comparison, periods, periodId, type }) => {
  if (!periodId) {
    return '';
  }

  const period = periods.find(p => p.id.toString() === periodId.toString());

  if (period) {
    if (Number(period.period_type) === LTM) {
      return comparison[`ltm_${type}`];
    }

    if (Number(period.period_type) === NTM) {
      return comparison[`ntm_${type}`];
    }

    const year = period.statement_date.slice(0, 4);
    return comparison.calendar_years_financials?.[year]?.[type];
  }

  return '';
};

export const getTransposedFinancials = ({ periods, financials, approach }) => {
  let financialsInfo = { ...financials };

  // eslint-disable-next-line max-len
  const { ltm_financial_statement_period_id: ltmPeriodId, ntm_financial_statement_period_id: ntmPeriodId } = approach;

  if (ltmPeriodId) {
    const ltmPeriod = periods.find(p => p.id.toString() === ltmPeriodId.toString());

    if (ltmPeriod) {
      financialsInfo = {
        ...financialsInfo,
        ltm_ebitda: ltmPeriod.income_statement.ebitda,
        ltm_revenue: ltmPeriod.income_statement.total_revenue,
      };
    }
  }

  if (ntmPeriodId) {
    const ntmPeriod = periods.find(p => p.id.toString() === ntmPeriodId.toString());

    if (ntmPeriod) {
      financialsInfo = {
        ...financialsInfo,
        ntm_ebitda: ntmPeriod.income_statement.ebitda,
        ntm_revenue: ntmPeriod.income_statement.total_revenue,
      };
    }
  }

  return financialsInfo;
};

/**
 * Parse tickerSymbol
 * @param {string} tickerSymbol - Ticker Symbol of the GPC
 * @returns {string} Ticker Symbol parsed
 */
export const getTicker = tickerSymbol =>
  tickerSymbol ? tickerSymbol.toLowerCase().replaceAll('.', '') : DATA_UNAVAILABLE.toLowerCase().replaceAll(' ', '');

/**
 *
 * @param {Object} comparison
 * @param {number} comparison.id
 * @param {number} comparison.valuation_approach_gpc
 * @param {string} comparison.comp_group_name
 * @param {Date} comparison.created_at
 * @param {Date} comparison.updated_at
 * @param {string} comparison.name
 * @param {string} comparison.ticker_symbol
 * @param {string} comparison.company_name
 * @param {string} comparison.cap_iq_id
 * @param {string} comparison.exchange
 * @param {string} comparison.currency
 * @param {Date} comparison.as_of_date
 * @param {string} comparison.close_price - Stock Price
 * @param {string} comparison.market_cap - Market Cap
 * @param {string} comparison.enterprise_value - Enterprise Value
 * @param {string} comparison.ltm_revenue
 * @param {string} comparison.ltm_revenue_growth
 * @param {string} comparison.ltm_ebitda
 * @param {string} comparison.ltm_ebitda_margin
 * @param {string} comparison.ltm_gross_margin
 * @param {string} comparison.ntm_revenue
 * @param {string} comparison.ntm_revenue_growth
 * @param {string} comparison.fy_plus1_revenue
 * @param {string} comparison.fy_plus2_revenue
 * @param {string} comparison.ntm_ebitda
 * @param {string} comparison.ntm_gross_margin
 * @param {string} comparison.ntm_ebitda_margin
 * @param {string} comparison.fy_plus1_ebitda
 * @param {string} comparison.fy_plus2_ebitda
 * @param {string} comparison.current_portion_of_debt
 * @param {string} comparison.short_term_debt
 * @param {string} comparison.current_debt
 * @param {string} comparison.current_portion_of_leases
 * @param {string} comparison.long_term_debt
 * @param {string} comparison.non_current_debt
 * @param {string} comparison.capital_lease_liabilities
 * @param {string} comparison.effective_tax_rate
 * @param {string} comparison.cost_borrowing
 * @param {string} comparison.beta_5yr
 * @param {string} comparison.volume
 * @param {string} comparison.price_volume_historical_1yr
 * @param {string} comparison.price_volume_historical_2yr
 * @param {string} comparison.price_volume_historical_5yr
 * @param {boolean} comparison.ltm_revenue_enabled
 * @param {boolean} comparison.ltm_ebitda_enabled
 * @param {boolean} comparison.ntm_revenue_enabled
 * @param {boolean} comparison.ntm_ebitda_enabled
 * @param {string} comparison.calendar_years_financials
 * @param {string} comparison.gpc_approach_comp_group
 * @returns {comparison} Comparison checked and parsed
 */

export const checkGpcComparison = comparison => {
  const requiredFieldsNumbers = ['close_price', 'market_cap', 'enterprise_value'];
  const requiredFieldsNumbersNonZeros = ['ltm_revenue', 'ltm_ebitda', 'ntm_revenue', 'ntm_ebitda'];
  const requiredFieldsBooleans = [
    'ltm_revenue_enabled',
    'ltm_ebitda_enabled',
    'ntm_revenue_enabled',
    'ntm_ebitda_enabled',
  ];

  const comparisonChecked = Object.fromEntries(
    Object.entries(comparison).map(([key, value]) => {
      if (key === 'cap_iq_id') {
        return [key, value || uuid()];
      }

      if (key === 'company_name') {
        return [key, value || comparison.name || ''];
      }

      if (key === 'ticker_symbol') {
        return [key, value || DATA_UNAVAILABLE];
      }

      if (requiredFieldsNumbers.includes(key)) {
        return [key, value || DEFAULT_ZERO_VALUE];
      }

      if (requiredFieldsNumbersNonZeros.includes(key)) {
        return [key, value || DEFAULT_ZERO_VALUE];
      }

      if (requiredFieldsBooleans.includes(key)) {
        return [key, typeof value === 'boolean' ? value : false];
      }

      return [key, value];
    })
  );

  requiredFieldsNumbersNonZeros.forEach(comparisonKey => {
    if (comparisonChecked[comparisonKey] === DEFAULT_ZERO_VALUE) {
      comparisonChecked[`${comparisonKey}_enabled`] = false;
    }
  });

  return comparisonChecked;
};

/**
 * Synchronize Calendar Years for GPC
 * @param {array} valuationApproaches
 * @param {array} financialsPeriods
 * @param {Object} measurementDate
 * @param {Object} companyInfo
 * @param {Function} setSyncComparisons
 * @param {Function} setApproaches
 * @returns {void}
 */
export const synchronizeCalendarYears = async (
  valuationApproaches,
  financialsPeriods,
  measurementDate,
  companyInfo,
  setSyncComparisons,
  setApproaches,
  combinedGPCComparisonPerfMetricAttrs
) => {
  if (isEmpty(financialsPeriods)) {
    return;
  }

  const calendarYears = [...new Set(financialsPeriods.map(p => p.statement_date.slice(0, 4)))].join(',');

  const valuationSvc = new ValuationService();

  const tmpApproaches = valuationApproaches.map(async approach => {
    if (approach.approach_type === PUBLIC_COMPANIES) {
      const { gpc_comparison: comparisons } = approach.valuations_approach_gpc;

      // eslint-disable-next-line no-param-reassign
      approach.valuations_approach_gpc.gpc_comparison = await Promise.all(
        comparisons.map(async comparison => {
          Object.assign(comparison, checkGpcComparison(comparison));

          if (!comparison.calendar_years_financials) {
            const { id: md } = measurementDate;
            const currency = companyInfo?.financials_currency || VALUATIONS_DEFAULT_CURRENCY;

            const defaultCalendarYearsFinancials = calendarYears.split(',').reduce((sofar, year) => {
              // eslint-disable-next-line no-param-reassign
              sofar[Number(year)] = {
                revenue: 0,
                ebitda: 0,
              };
              return sofar;
            }, {});

            try {
              const gpc
                = comparison?.ticker_symbol === DATA_UNAVAILABLE
                  ? {}
                  : await valuationSvc.getGpc(getTicker(comparison?.ticker_symbol), md, currency, calendarYears);

              return {
                ...comparison,
                calendar_years_financials: isEmpty(gpc)
                  ? defaultCalendarYearsFinancials
                  : parseGpcComparison({
                    data: gpc,
                    attributes: combinedGPCComparisonPerfMetricAttrs,
                  }).calendar_years_financials,
              };
            } catch (e) {
              return {
                ...comparison,
                calendar_years_financials: defaultCalendarYearsFinancials,
              };
            }
          }

          return comparison;
        })
      );
    }

    return approach;
  });

  const updatedApproaches = await Promise.all(tmpApproaches);

  setSyncComparisons(true);
  setApproaches(updatedApproaches);
};

/**
 * Synchronize and update GPC Approaches
 * @param {Object} valuation
 * @param {Object} approaches
 * @param {array} financialsPeriods
 * @param {Object} measurementDate
 * @param {Object} companyInfo
 * @param {Function} setSyncComparisons
 * @param {Function} setApproaches
 * @returns {void}
 */
export const synchronizeAndUpdateApproaches = (
  valuation,
  financialsPeriods,
  measurementDate,
  companyInfo,
  setSyncComparisons,
  setApproaches,
  combinedGPCComparisonPerfMetricAttrs
) => {
  if (!isUndefined(valuation) && !isEmpty(valuation) && financialsPeriods) {
    const valuationsApproaches = valuation.valuations_approaches
      .map(approach => ({ ...approach, panelId: approach?.panelId || approach?.panel_id || uuid() }))
      .sort((approach1, approach2) => approach1.id - approach2.id);

    if (financialsPeriods.length) {
      synchronizeCalendarYears(
        valuationsApproaches,
        financialsPeriods,
        measurementDate,
        companyInfo,
        setSyncComparisons,
        setApproaches,
        combinedGPCComparisonPerfMetricAttrs
      );
    } else {
      setSyncComparisons(true);
      setApproaches(valuationsApproaches);
    }
  }
};

export function getSelectionValue(row, column, approach) {
  if (!row.options.includes(column.selection) && column.selection) {
    // we get the percentile
    const percentileMap = {
      [SELECTION_A]: PERCENTILE_SELECTION_A_ALIAS,
      [SELECTION_B]: PERCENTILE_SELECTION_B_ALIAS,
    };
    return percentileOptions[approach[percentileMap[column.selection]] - 1].label;
  }
  return column.selection;
}

export function addBenchmarkCustomKeys(cell, tmpCell, column) {
  if (BENCHMARK_ALIASES.includes(cell.alias)) {
    // eslint-disable-next-line no-param-reassign
    tmpCell.customKey = `${column.id}_${cell.alias}`;
  }
}

export function handleMultipleCell(cell, column, useMultiplePremiumDiscount = false) {
  if (cell.alias === SELECTED_MULTIPLE_VALUE && column.selection) {
    const selectionMap = {
      Mean: MEAN_ALIAS,
      Median: MEDIAN_ALIAS,
      [SPECIFIED_LABEL]: SELECTED_MULTIPLE,
      [PERCENTILE_75]: SEVENTY_FIFTH_ALIAS,
      [PERCENTILE_25]: TWENTY_FIFTH_ALIAS,
      [SELECTION_A]: PERCENTILE_SELECTION_A_ALIAS,
      [SELECTION_B]: PERCENTILE_SELECTION_B_ALIAS,
    };
    const selection = selectionMap[column.selection];

    if (!useMultiplePremiumDiscount) {
      // eslint-disable-next-line no-param-reassign
      cell.expr = `=${column.id}_${selection}`;
    } else {
      // eslint-disable-next-line no-param-reassign
      cell.expr = `=${column.id}_${selection} * (1 + ${column.id}_MPD)`;
    }
  }
}

const TitleIconWrapper = ({ Icon, title }) => (
  <Grid component="span" container justifyContent="flex-end" alignItems="center">
    {title} <Icon fontSize="large" />
  </Grid>
);

export const getTitleWithIcon = (title, columnKey, sortedColumn) => {
  if (sortedColumn && columnKey in sortedColumn) {
    return sortedColumn?.[columnKey] === SORT_ASC ? (
      <TitleIconWrapper Icon={ArrowDropUpIcon} title={title} />
    ) : (
      <TitleIconWrapper Icon={ArrowDropDownIcon} title={title} />
    );
  }

  return title;
};

TitleIconWrapper.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
};
