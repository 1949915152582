import { createStyles, makeStyles } from '@material-ui/styles';
import { SMALL_FONT_SIZE } from 'dashboard409a/common/constants';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    breadcrumbsContainer: {
      marginBottom: theme.spacing(1.5),
      fontSize: SMALL_FONT_SIZE,

      '& .MuiBreadcrumbs-separator': {
        color: theme.palette.gray[300],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },

      // display: 'none',

      // [theme.breakpoints.up('md')]: {
      //   display: 'block',
      // },
    },
    breadcrumbLink: {
      fontSize: SMALL_FONT_SIZE,
      color: theme.palette.primary.main,
    },
    breadcrumbText: {
      fontSize: SMALL_FONT_SIZE,
      color: theme.palette.gray[400],
    },
  })
);

export default useStyles;
