/**
 * @name Documents Config
 * @memberof module:common/config
 * @type {Config}
 * @return {Object} Configurations of API
 */

// #region common
import API from '../config';

const base = `${API.BASE_DEV_HOST}/api/documents`;

export const getPreSignedUrl = (mdId, fileType) => `${base}/file-upload-request/${mdId}?file_type=${fileType}`;
export const getCompanyDocuments = companyId => `${base}/company/${companyId}`;
export const createFileRepresentation = documentsId => `${base}/${documentsId}/files`;
export const downloadDocument = fileId => `${base}/download/${fileId}`;
export const updateOrDeleteDocument = (documentsId, fileId) => `${base}/${documentsId}/files/${fileId}`;
export const getFeaturesObjects = mdId => `${base}/feature-objects/${mdId}`;
export const addDocumentReference = (mdId, fileId) => `${base}/reference/${mdId}/${fileId}`;
export const addDocumentReferencesObject = mdId => `${base}/document-reference-objects/${mdId}`;
export const getReferencesByMeasurementDateId = mdId => `${base}/${mdId}/references`;
export const createSubFolder = docsId => `${base}/${docsId}/folder`;
export const updateSubFolder = (docsId, folderId) => `${base}/${docsId}/folder/${folderId}`;
export const addFilesToFolder = docsId => `${base}/${docsId}/add-to-folder`;
export const validateDocumentRequestToken = (requestId, token) =>
  `${base}/requests/validate-token/${requestId}/${token}`;
export const generatePostSignedUrlViaHMACToken = (requestId, token, mdId, type) =>
  `${base}/requests/${requestId}/${token}/signed-url/${mdId}?file_type=${type}`;
export const updateRequestedFileUploadStatus = (requestId, token) =>
  `${base}/requests/file-upload-status/${requestId}/${token}`;
export const sendDocumentRequest = `${base}/request`;
export const updateTaskStatusFromPortal = (requestId, token, taskId) =>
  `${base}/requests/${requestId}/${token}/task-status/${taskId}`;
export const hasDocuments = (companyId, measurementDateId) => `${base}/has-documents/${measurementDateId}/${companyId}`;
