import React from 'react';
import { IconButton } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import { SnackbarKey, useSnackbar } from 'notistack';

const SnackCloseIcon = (key: SnackbarKey) => {
  const { closeSnackbar } = useSnackbar();

  const closeSelectedSnackbar = () => closeSnackbar(key);

  return (
    <IconButton size="small" onClick={closeSelectedSnackbar}>
      <ClearIcon style={{ color: 'white' }} />
    </IconButton>
  );
};

export default SnackCloseIcon;
