import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useStyles from './styles';
import { ITitle } from './types';

const Title: FC<ITitle> = props => {
  const { title } = props;
  const classes = useStyles();

  return (
    <Typography variant="h1" className={classes.title}>
      {title || <Skeleton />}
    </Typography>
  );
};

export default Title;
