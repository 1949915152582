import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { SYSTEM_COLLAPSE, USER_EXPAND } from 'common/actions/row-groups/types';
import {
  ALIASES_TO_UPDATE_IN_BALANCE_SHEET,
  ALIASES_TO_UPDATE_INCOME_STATEMENT,
  EBITDA_ALIAS,
  INITIAL_BALANCE_SHEET_STATE,
} from 'common/constants/financials';
import { rowsGroupsReducer } from 'common/reducers/rowsGroups';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { template } from 'pages/Financials/balance-sheet/data';
import { conditions as balanceSheetConditions } from 'pages/Financials/balance-sheet/utilities';
import {
  conditions as incomeStatementConditions,
  reverseParser as incomeStatementReverseParser,
} from 'pages/Financials/income-statement/utilities';
import afterCellChanged from 'pages/Financials/utilities/afterCellChanged';
import baseParser from 'pages/Financials/utilities/baseParser';
import updateFiscalQuarters from 'pages/Financials/utilities/updateFiscalQuarters';
import setColumnsOnBalanceSheet from './setColumnsOnBalanceSheet';
import setRowGroupsOnSheet from './setRowGroupsOnSheet';

const useIncomeStatementOrBalanceSheetData = ({
  config,
  customTitlesData,
  fieldAttributes,
  isBalanceSheet = false,
  measurementDate,
  periods,
  setData,
  tableTermsData,
  currentFiscalYearEnd,
  useAdjustedEBITDA,
  isDisabled,
}) => {
  const [cells, setCells] = useState();
  const [columns, setColumns] = useState();
  const [spreadsheet, setSpreadsheet] = useState();
  const [visibleColumns, setVisibleColumns] = useState();
  const [tableData, setTableData] = useState();

  const [rowGroups, setRowGroups] = useReducer(
    rowsGroupsReducer,
    isBalanceSheet
      ? INITIAL_BALANCE_SHEET_STATE
      : {
        [EBITDA_ALIAS]: useAdjustedEBITDA ? USER_EXPAND : SYSTEM_COLLAPSE,
      }
  );

  const updateCells = useCallback(async newCells => {
    setCells(newCells);
  }, []);

  const customTitles = useMemo(() => {
    const newConfig = [...config];

    // Reset First Row
    newConfig[0] = {
      ...newConfig[0],
      className: customTitlesData.className,
      rowSpan: 3,
      value: customTitlesData.value,
    };

    return newConfig;
  }, [config, customTitlesData.className, customTitlesData.value]);

  const tableTerms = useMemo(
    () => ({
      columnName: tableTermsData.columnName,
      pluralColumnName: tableTermsData.pluralColumnName,
      tableName: tableTermsData.tableName,
      tableSlug: tableTermsData.tableSlug,
    }),
    [tableTermsData.columnName, tableTermsData.pluralColumnName, tableTermsData.tableName, tableTermsData.tableSlug]
  );

  // Set visible columns
  useEffect(() => {
    if (columns?.length) {
      //  NTM columns do not belong in the balance sheet
      const newVisibleColumns = columns.filter(column =>
        isBalanceSheet ? !column.is_deleted && !column.isNTM : !column.is_deleted
      );
      setVisibleColumns(newVisibleColumns);
    }
  }, [columns, isBalanceSheet]);

  useEffect(() => {
    if (visibleColumns) {
      const tempTableData = setColumnsOnBalanceSheet({
        isBalanceSheet,
        setColumns,
        tableData: {
          columns: visibleColumns,
          measurementDate,
          currentFiscalYearEnd,
          isDisabled,
        },
      });

      setTableData(tempTableData);
    }
  }, [isBalanceSheet, measurementDate, visibleColumns, currentFiscalYearEnd, isDisabled]);

  useEffect(() => {
    if (isBalanceSheet) setData({ cells, columns: visibleColumns, isDisabled });
  }, [cells, isBalanceSheet, setData, visibleColumns, isDisabled]);

  useEffect(() => {
    if (periods) {
      const generatedColumns = [];

      periods.forEach(column => {
        const data = (isBalanceSheet ? column?.balance_sheet : column?.income_statement) ?? {};
        const info = {
          ...column,
        };

        // Remove unnecessary keys (we've already extracted the balance sheet or income statement above)
        omit(info, [
          'balance_sheet',
          'income_statement',
          'kpi_dataset',
          'statement_date',
          'period_type',
          'is_projection',
        ]);

        const generatedColumn = {
          ...template,
          ...data,
          ...info,
        };

        generatedColumns.push(generatedColumn);
      });

      const updatedPeriods = updateFiscalQuarters({
        aliasesToUpdate: isBalanceSheet ? ALIASES_TO_UPDATE_IN_BALANCE_SHEET : ALIASES_TO_UPDATE_INCOME_STATEMENT,
        allColumns: generatedColumns,
        conditions: ['isLTMQuarter', 'isNTMQuarter', 'isParent'],
      });

      setColumns(updatedPeriods);
    }
  }, [isBalanceSheet, periods]);

  useEffect(() => {
    if (visibleColumns && tableData) {
      setSpreadsheet(
        new SpreadsheetConfig({
          afterCellChanged,
          allowConfirmAndDeleteColumn: !isDisabled,
          columns: visibleColumns,
          conditions: isBalanceSheet ? balanceSheetConditions : incomeStatementConditions,
          currencyFormatter: true,
          customTitles,
          fieldAttributes: isBalanceSheet ? fieldAttributes.balanceSheetAttrs : fieldAttributes.incomeStatementAttrs,
          hasColTitle: false,
          name: isBalanceSheet ? 'balanceSheet' : 'incomeStatement',
          parser: baseParser,
          reverseParser: isBalanceSheet ? undefined : incomeStatementReverseParser,
          rowConfig: config,
          setColumns,
          setDataSource: isBalanceSheet ? undefined : setData,
          showPreviousColsDivider: true,
          showToolbar: true,
          showTotalColumn: false,
          tableData,
          tableTerms,
          unitsFormatter: true,
          allowCopyColumn: !isDisabled,
          allowDeleteColumn: !isDisabled,
        })
      );
    }
  }, [
    config,
    customTitles,
    fieldAttributes.balanceSheetAttrs,
    fieldAttributes.incomeStatementAttrs,
    isBalanceSheet,
    setData,
    tableData,
    tableTerms,
    visibleColumns,
    isDisabled,
  ]);

  const incomeStatementOrBalanceSheetData = {
    cells,
    config,
    customTitles,
    setColumns,
    spreadsheet,
    tableTerms,
    updateCells,
    visibleColumns,
  };

  return setRowGroupsOnSheet({
    incomeStatementOrBalanceSheetData,
    rowGroups,
    setRowGroups,
  });
};

const assetsAccountsReceivableShape = PropTypes.shape({
  decimal_places: PropTypes.number,
  max_digits: PropTypes.number,
  type: PropTypes.string,
});

const configShape = PropTypes.shape({
  alias: PropTypes.string,
  allowNegativeValue: PropTypes.bool,
  dbType: PropTypes.string,
  format: PropTypes.shape({
    maximumFractionDigits: PropTypes.number,
    minimumFractionDigits: PropTypes.number,
    style: PropTypes.string,
    validateFloat: PropTypes.bool,
  }),
  gridType: PropTypes.string,
  hidden: PropTypes.bool,
  parent: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
});

const customTitlesDataShape = PropTypes.shape({
  className: PropTypes.string,
  value: PropTypes.string,
});

const fieldAttributesShape = PropTypes.shape({
  balanceSheetAttrs: PropTypes.shape({
    assets_accounts_receivable: assetsAccountsReceivableShape,
    assets_cash: assetsAccountsReceivableShape,
    assets_intangibles: assetsAccountsReceivableShape,
    assets_inventory: assetsAccountsReceivableShape,
    assets_other_current: assetsAccountsReceivableShape,
    assets_other_long_term: assetsAccountsReceivableShape,
    assets_ppe: assetsAccountsReceivableShape,
    assets_total: assetsAccountsReceivableShape,
    assets_total_current: assetsAccountsReceivableShape,
    assets_total_long_term: assetsAccountsReceivableShape,
    created_at: assetsAccountsReceivableShape,
    equity_total: assetsAccountsReceivableShape,
    liabilities_account_payable: assetsAccountsReceivableShape,
    liabilities_accrued_liabilities: assetsAccountsReceivableShape,
    liabilities_and_equity_total: assetsAccountsReceivableShape,
    liabilities_deferred_revenue: assetsAccountsReceivableShape,
    liabilities_long_term_debt: assetsAccountsReceivableShape,
    liabilities_other_current: assetsAccountsReceivableShape,
    liabilities_other_long_term: assetsAccountsReceivableShape,
    liabilities_short_term_debt: assetsAccountsReceivableShape,
    liabilities_total: assetsAccountsReceivableShape,
    liabilities_total_current: assetsAccountsReceivableShape,
    liabilities_total_long_term: assetsAccountsReceivableShape,
    needs_actualization: assetsAccountsReceivableShape,
    reported_statement_date: assetsAccountsReceivableShape,
    total_cash_equivalents: assetsAccountsReceivableShape,
    total_debt: assetsAccountsReceivableShape,
    updated_at: assetsAccountsReceivableShape,
  }),
  incomeStatementAttrs: PropTypes.shape({
    adjusted_ebitda: assetsAccountsReceivableShape,
    amortization_expense: assetsAccountsReceivableShape,
    created_at: assetsAccountsReceivableShape,
    depreciation_expense: assetsAccountsReceivableShape,
    ebit: assetsAccountsReceivableShape,
    ebitda: assetsAccountsReceivableShape,
    gross_profit: assetsAccountsReceivableShape,
    income_taxes: assetsAccountsReceivableShape,
    interest_expense: assetsAccountsReceivableShape,
    needs_actualization: assetsAccountsReceivableShape,
    net_income: assetsAccountsReceivableShape,
    operating_expenses: assetsAccountsReceivableShape,
    other_expense: assetsAccountsReceivableShape,
    pretax_income: assetsAccountsReceivableShape,
    reported_statement_date: assetsAccountsReceivableShape,
    total_revenue: assetsAccountsReceivableShape,
    total_sales_cost: assetsAccountsReceivableShape,
    updated_at: assetsAccountsReceivableShape,
  }),
});

const measurementDateShape = PropTypes.shape({
  cmd_id: PropTypes.number,
  date: PropTypes.string,
  id: PropTypes.number,
  is_open: PropTypes.bool,
  name: PropTypes.string,
  slug: PropTypes.string,
});

const periodsShape = PropTypes.shape({
  balance_sheet: PropTypes.shape({
    assets_accounts_receivable: PropTypes.instanceOf(null),
    assets_cash: PropTypes.instanceOf(null),
    assets_intangibles: PropTypes.instanceOf(null),
    assets_inventory: PropTypes.instanceOf(null),
    assets_other_current: PropTypes.instanceOf(null),
    assets_other_long_term: PropTypes.instanceOf(null),
    assets_ppe: PropTypes.instanceOf(null),
    assets_total: PropTypes.instanceOf(null),
    assets_total_current: PropTypes.instanceOf(null),
    assets_total_long_term: PropTypes.instanceOf(null),
    created_at: PropTypes.string,
    equity_total: PropTypes.instanceOf(null),
    financial_statement_period: PropTypes.number,
    id: PropTypes.number,
    liabilities_account_payable: PropTypes.instanceOf(null),
    liabilities_accrued_liabilities: PropTypes.instanceOf(null),
    liabilities_and_equity_total: PropTypes.instanceOf(null),
    liabilities_deferred_revenue: PropTypes.instanceOf(null),
    liabilities_long_term_debt: PropTypes.instanceOf(null),
    liabilities_other_current: PropTypes.instanceOf(null),
    liabilities_other_long_term: PropTypes.instanceOf(null),
    liabilities_short_term_debt: PropTypes.instanceOf(null),
    liabilities_total: PropTypes.instanceOf(null),
    liabilities_total_current: PropTypes.instanceOf(null),
    liabilities_total_long_term: PropTypes.instanceOf(null),
    name: PropTypes.string,
    needs_actualization: PropTypes.bool,
    reported_statement_date: PropTypes.instanceOf(null),
    total_cash_equivalents: PropTypes.instanceOf(null),
    total_debt: PropTypes.string,
    updated_at: PropTypes.string,
  }),
  column_ref: PropTypes.instanceOf(null),
  created_at: PropTypes.string,
  date: PropTypes.string,
  deleted_at: PropTypes.string,
  financial_statement: PropTypes.number,
  id: PropTypes.number,
  income_statement: PropTypes.shape({
    adjusted_ebitda: PropTypes.instanceOf(null),
    amortization_expense: PropTypes.instanceOf(null),
    created_at: PropTypes.string,
    depreciation_expense: PropTypes.instanceOf(null),
    ebit: PropTypes.instanceOf(null),
    ebitda: PropTypes.instanceOf(null),
    financial_statement_period: PropTypes.number,
    gross_profit: PropTypes.instanceOf(null),
    id: PropTypes.number,
    income_taxes: PropTypes.instanceOf(null),
    interest_expense: PropTypes.instanceOf(null),
    name: PropTypes.string,
    needs_actualization: PropTypes.bool,
    net_income: PropTypes.instanceOf(null),
    operating_expenses: PropTypes.instanceOf(null),
    other_expense: PropTypes.instanceOf(null),
    pretax_income: PropTypes.instanceOf(null),
    reported_statement_date: PropTypes.instanceOf(null),
    total_revenue: PropTypes.instanceOf(null),
    total_sales_cost: PropTypes.instanceOf(null),
    updated_at: PropTypes.string,
  }),
  isPrevQuarter: PropTypes.bool,
  is_deleted: PropTypes.bool,
  is_projection: PropTypes.bool,
  kpi_dataset: PropTypes.arrayOf(PropTypes.instanceOf(null)),
  name: PropTypes.string,
  parentColumn: PropTypes.number,
  period_type: PropTypes.number,
  quarter: PropTypes.number,
  statement_date: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  updated_at: PropTypes.string,
});

const tableTermsDataShape = PropTypes.shape({
  columnName: PropTypes.string,
  pluralColumnName: PropTypes.string,
  tableName: PropTypes.string,
  tableSlug: PropTypes.string,
});

// Custom Hook PropTypes
useIncomeStatementOrBalanceSheetData.propTypes = {
  config: PropTypes.arrayOf(configShape).isRequired,
  customTitlesData: customTitlesDataShape.isRequired,
  fieldAttributes: fieldAttributesShape.isRequired,
  isBalanceSheet: PropTypes.bool,
  measurementDate: measurementDateShape.isRequired,
  periods: PropTypes.arrayOf(periodsShape).isRequired,
  setData: PropTypes.func.isRequired,
  tableTermsData: tableTermsDataShape.isRequired,
};

export default useIncomeStatementOrBalanceSheetData;
