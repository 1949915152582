import uuid from 'react-uuid';
import {
  ALLOCATIONS_PAGE_VALUE,
  BREAKPOINTS_PAGE_VALUE,
  CAP_TABLE_PAGE_VALUE,
  COMPANY_SUMMARY_PAGE_VALUE,
  FINANCIALS_PAGE_VALUE,
  FUND_OWNERSHIP_PAGE_VALUE,
  FUND_SUMMARY_PAGE_VALUE,
  VALUATIONS_PAGE_VALUE,
  WATERFALL_PAGE_VALUE,
} from 'common/constants/notes';
import { GetPageTypeResult, NoteData } from 'components/Notes/types';
import { hasProperty } from 'utillities';

export const getPageTypes = (pageTypeValue: number): GetPageTypeResult => ({
  ct: {
    page_type: CAP_TABLE_PAGE_VALUE,
    cap_table: pageTypeValue,
  },
  fo: {
    page_type: FUND_OWNERSHIP_PAGE_VALUE,
    fund_ownership: pageTypeValue,
  },
  fi: {
    page_type: FINANCIALS_PAGE_VALUE,
    financial_statement: pageTypeValue,
  },
  va: {
    page_type: VALUATIONS_PAGE_VALUE,
    valuation_approach: pageTypeValue,
  },
  alloc: {
    page_type: ALLOCATIONS_PAGE_VALUE,
    allocation: pageTypeValue,
  },
  brkpts: {
    page_type: BREAKPOINTS_PAGE_VALUE,
    cap_table: pageTypeValue,
  },
  cs: {
    page_type: COMPANY_SUMMARY_PAGE_VALUE,
    company_measurement_date: pageTypeValue,
  },
  fs: {
    page_type: FUND_SUMMARY_PAGE_VALUE,
    fund_measurement_date: pageTypeValue,
  },
  wtrfl: {
    page_type: WATERFALL_PAGE_VALUE,
    cap_table: pageTypeValue,
  },
});

const getPageTypeProps = (pageType: string, pageTypeId: number) => {
  const pageTypeProps = getPageTypes(pageTypeId);
  return pageTypeProps[pageType as keyof typeof pageTypeProps];
};

type NotesStates = {
  notes: NoteData[];
  setNotes: (data: NoteData[]) => void;
};

type OnAddNoteType = {
  pageType: string;
  pageTypeId: number;
} & NotesStates;

export const onAddNote = (props: OnAddNoteType) => {
  const { notes, pageType, pageTypeId, setNotes } = props;
  const pageTypeProps = getPageTypeProps(pageType, pageTypeId);
  const newNote: NoteData = {
    temp_id: uuid(),
    author: null,
    content: '',
    isNew: true,
    ...pageTypeProps,
  };
  const updatedNotes = [newNote, ...(notes ?? [])];
  setNotes(updatedNotes);
};

type OnUpdateNotesType = {
  updatedNote: NoteData;
} & NotesStates;

export const onUpdateNotes = (props: OnUpdateNotesType) => {
  const { notes, setNotes, updatedNote } = props;
  const updatedNoteList = (notes ?? []).map((noteItem: NoteData) => {
    const { id: noteItemId, temp_id: noteItemTempId } = noteItem;
    const { id: updatedNoteId, temp_id: updatedNoteTempId } = updatedNote;
    if (
      (noteItemTempId && updatedNoteTempId && noteItemTempId === updatedNoteTempId)
      || (noteItemId && updatedNoteId && noteItemId === updatedNoteId)
    ) {
      return updatedNote;
    }
    return noteItem;
  });
  setNotes(updatedNoteList);
};

type OnDeleteNoteType = {
  updatedNote: NoteData;
} & NotesStates;

export const onDeleteNote = (props: OnDeleteNoteType) => {
  const { notes, setNotes, updatedNote } = props;
  const noteId = updatedNote.id ?? updatedNote.temp_id;
  if (!hasProperty(updatedNote, 'temp_id')) {
    onUpdateNotes(props);
  } else if (notes && noteId) {
    const updatedNotes = notes.filter(
      note => (note.id && note.id !== Number(noteId)) || (note.temp_id && note.temp_id !== noteId)
    );
    setNotes(updatedNotes);
  }
};
