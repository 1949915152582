const updateNeedsActualizationState = (columns, cells) => (
  columns.map((column, colIndex) => {
    const cellObjects = Object.values(cells).filter(cell => cell.columnIndex === colIndex);
    const columnHasBeenUpdated = cellObjects.some(
      cell => !cell.needs_actualization && cell.columnIndex === colIndex
    );
    if (columnHasBeenUpdated) {
      return { ...column, needs_actualization: false };
    }
    return column;
  })
);

export default updateNeedsActualizationState;
