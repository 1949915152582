const generateColorFromString = str => {
  const saturation = 75;
  const lightness = 45;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return `hsl(${hash}, ${saturation}%, ${lightness}%)`;
};

export default generateColorFromString;
