export const DOCUMENT_TOOL = 'Documents';
export const DONE_HERE = option => `Done here? Go to ${option}`;
export const PROCESS_MANAGEMENT = 'Secure Portal';
export const QUESTIONNAIRE = 'Questions';
export const DOCUMENT = 'Secure Portal';
export const UPLOAD_TOOL = 'Document Uploads';
export const UPLOAD_ACTION = 'upload the requested files';
export const QUESTIONNAIRE_ACTION = 'answer the provided questions';
export const LOG_IN = 'Log In';
export const LOG_OUT = 'Log Out';
export const SKIP = 'Skip';
export const getWelcomeMessage = (title, action) => `Welcome to the ${PROCESS_MANAGEMENT} powered by Scalar.  \
    ${LOG_IN} to view previous ${title} \
    or feel free to ${SKIP} and immediately continue to ${action}.`;
export const DOCUMENT_REQUEST_CATEGORY = 1;
export const QUESTIONNAIRE_REQUEST_CATEGORY = 2;

// Components for menu items
export const LINK = 'link';
export const BUTTON = 'button';
export const MEASUREMENT_DATE = 'measurementDate';
export const FIRM_INFO = 'firmInfo';
export const DOCUMENTS_URL_PARAM = 'documents';
export const QUESTIONS_URL_PARAM = 'questionnaires';
export const DOC_UPLOAD_PAGE = 'Doc Upload Page';

// List of questions
export const GO_TO_QUESTION = 'Go to question';
export const GO_TO_QUESTIONS = 'Go to questions';
export const VIEW_ALL = 'View all';
export const SHOW_LESS = 'Show less';

// List of questions with optional answers
export const LIST_OF_QUESTIONS_WITH_OPTIONAL_ANSWERS = ['How much was the most recent round and when did it occur?'];

// Document upload modal
export const DRAG_AND_DROP_DOCUMENTS
  = 'Drag & drop documents inside this area or upload them by clicking the above icon.';
export const HOW_DO_I_USE_THIS = 'How do I use this?';
export const HOW_DO_I_USE_THIS_INSTRUCTIONS = [
  { id: 1, instruction: 'Review the requested documents displayed on this page.' },
  {
    id: 2,
    instruction:
      'Select the document to upload by either browsing your computer\'s files or dragging and dropping the file into the upload area.',
  },
  {
    id: 3,
    instruction:
      'Wait for the upload to complete. The time it takes to upload a document will vary depending on the size of the file and your internet connection.',
  },
  {
    id: 4,
    instruction:
      'Once the upload is complete, you may assign the upload to one or more of the document requests. This will indicate that request has been completed.',
  },
];
export const CONNECTORS = [
  'and',
  'of',
  'the',
  'for',
  'with',
  'to',
  'from',
  'by',
  'on',
  'in',
  'at',
  'a',
  'an',
  'as',
  'but',
  'or',
  'nor',
  'is',
  'if',
  'then',
  'else',
  'when',
  'at',
  'all',
  'any',
  'both',
  'each',
  'few',
  'more',
  'most',
  'other',
  'some',
  'such',
  'no',
  'nor',
  'not',
  'only',
  'own',
  'same',
  'so',
  'than',
  'too',
  'very',
  'can',
  'will',
  'just',
  'don',
  'should',
  'now',
];
export const THANK_YOU_FOR_UPLOADING_DOCUMENTS = 'Thank you for uploading the documents';
export const UPLOAD_MORE_DOCUMENTS = 'I need to upload more';
export const UPLOAD_MORE_DOCUMENTS_DESCRIPTION
  = 'All the pending items were uploaded successfully, now your uploads will be marked as \'Other documents\'.';

// Document progress list
export const ANSWER = 'answer';
export const ANSWERED = 'answered';
export const NOT_ANSWERED = 'not answered';
export const IS_UPLOADED = 'is_uploaded';

// Document Selection Modal
export const SELECTION_MODAL_DESCRIPTION
  = 'If you are uploading a document that shares different requests, please select which requests are related to this document.';
export const SELECT_WITH_WHICH_DOCUMENT_REQUEST = 'Select with which document request this file is associated with:';
export const OTHER = 'Other';
export const UPLOAD = 'Upload';
export const CANCEL = 'Cancel';

// Questionnaire portal
export const WELCOME_TO_THE_QUESTIONNAIRE = 'Welcome to the Process Management Questionnaire';
export const START = 'Start';
export const ENTER_DATE = 'Enter Date';
export const PREVIOUS = 'Previous';
export const NEXT = 'Next';
export const THANK_YOU_FOR_COMPLETING_ALL_QUESTIONS = 'Thanks for completing all of the questions.';
export const FOR_ANY_FOLLOW_UPS_LOOK_FOR_COMMUNICATIONS_FROM = firmName =>
  `For any follow ups look for communications from ${firmName}.`;
export const GO_TO_DOCUMENT_UPLOAD_TOOL = 'Go to Document Upload Tool';
export const QUESTION_ANSWERED_SUCCESSFULLY = 'Question answered successfully';
export const TEXT = 'text';
export const TEXT_TYPE = 'text';
export const DATE_TYPE = 'date';
export const DROPDOWN_TYPE = 'dropdown';
export const TEXT_ANSWER = 'text_answer';
export const DATE_ANSWER = 'date_answer';
export const MULTIPLE_TYPE = 'multiple';
export const SELECTED_CHOICE = 'selected_choice';
export const QUESTION = 'question';
export const YES_ID = 1;
export const DATE = 'date';
export const DROPDOWN = 'dropdown';
export const PROVIDE_DETAILS_FUNDING_PLACEHOLDER = 'Provide details funding';
export const SELECT_A_DATE_FIRST_TO_PROVIDE_DETAILS_FUNDING = 'Select a date first to provide details funding';

export const mapToAnswerProp = {
  [TEXT_TYPE]: TEXT_ANSWER,
  [DATE_TYPE]: DATE_ANSWER,
  [DROPDOWN_TYPE]: SELECTED_CHOICE,
  [MULTIPLE_TYPE]: SELECTED_CHOICE,
};

// Footer
export const CONTACT_US = 'Contact Us';
export const SECURE_PAGE = 'Secure Portal';
export const CONTACT_US_LINK = 'https://scalar.io/contact';
export const PRIVACY_POLICY_LINK = 'https://scalar.io/privacy-policy';
export const PRIVACY_POLICY = 'Privacy Policy';

export const POWERED_BY = 'Powered by';

// Mobile
export const UPLOADS_DOCUMENTS = 'Upload Documents';
