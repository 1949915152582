/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from './types';

const initialState: IUser = {} as IUser;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserState: (state, action: PayloadAction<IUser>) => ({ ...state, ...action.payload }),
    resetUserState: () => initialState,
  },
});

export const { updateUserState, resetUserState } = userSlice.actions;

export default userSlice.reducer;
