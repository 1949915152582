import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const elementWidth = 250;

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    formControl: {
      minWidth: elementWidth,
    },
    select: {
      maxWidth: elementWidth,
      color: theme.palette.gray[500],
    },
    skeleton: {
      backgroundColor: 'rgba(255,255,255,0.5)',
    },
  })
);

export default useStyles;
