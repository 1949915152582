import { useCallback, useState } from 'react';
import validate from 'validate.js';
import { getObjectValue, objToArray, toString } from 'utillities';
import { FormConstraints, FormState, FormValues } from './types';

const FORM_DEFAULT_VALUES = Object.freeze({
  isValid: false,
  values: {},
  errors: {},
} as FormState);

const useFormValidation = (constraints: FormConstraints) => {
  const [formValidated, setFormValidated] = useState<FormState>(FORM_DEFAULT_VALUES);

  const validateForm = useCallback(
    (values: FormState['values']) => {
      const currentValues = getObjectValue(values);
      const deletedFields = {} as FormValues;

      objToArray(currentValues).forEach(([prop, value]) => {
        if (toString(value).length) {
          currentValues[prop] = value;
        } else {
          deletedFields[prop] = value;
          delete currentValues[prop];
        }
      });

      const errors = validate(currentValues, constraints);

      setFormValidated({
        errors: getObjectValue(errors),
        isValid: Boolean(!errors),
        values: { ...currentValues, ...deletedFields },
      } as FormState);
    },
    [constraints]
  );

  return { formValidated, validateForm };
};

export default useFormValidation;
