/* eslint-disable import/prefer-default-export */
import React from 'react';
import { largeCurrencyFormat, roundedPercentFormatIsDecimalTrue, sharesFormat } from 'common/formats/formats';
import { GridDateEditor, GridDateValueViewer } from 'components/FeaturedSpreadsheet/components';
import { LedgerGridDate } from 'components/LedgerTable/components';
import { SALE_DATE_ALIAS } from '../../constants';
import generatePurchaseDateBaseConfig from '../../utilities/generatePurchaseDateBaseConfig';
import generatePurchaseDateOptions from '../../utilities/generatePurchaseDateOptions';

export const colConfig = (
  modeledAfterConvertibleNote = false,
  isCustomSecurity = false,
  isModeledAfterLoanNote = false,
  acquisitions = []
) => {
  const acquisitionOptions = generatePurchaseDateOptions(acquisitions);

  const dateColConfig = {
    value: 'Date',
    alias: SALE_DATE_ALIAS,
    width: '175px',
    gridDateComponent: LedgerGridDate,
    dataEditor: props => <GridDateEditor {...props} />,
    valueViewer: props => <GridDateValueViewer {...props} slashFormatted />,
    isRequired: true,
  };

  const purchaseDateConfig = generatePurchaseDateBaseConfig(acquisitionOptions);
  purchaseDateConfig.soldTable = true;

  if (modeledAfterConvertibleNote) {
    const soldAmountColConfig = {
      value: 'Sold Amount',
      alias: 'amount',
      width: '175px',
      format: largeCurrencyFormat,
      minValue: 0,
      gridType: 'number',
      isRequired: true,
    };
    if (isModeledAfterLoanNote) {
      const soldPercentColConfig = {
        value: 'Sold Percent',
        alias: 'sold_percent',
        width: '175px',
        format: roundedPercentFormatIsDecimalTrue,
        minValue: 0,
        gridType: 'percentage',
        isRequired: true,
        dbDecimalPlaces: 4,
      };
      const proceeds = {
        value: 'Proceeds',
        alias: 'proceeds',
        width: '175px',
        format: largeCurrencyFormat,
        minValue: 0,
        gridType: 'number',
        isRequired: true,
      };
      return [purchaseDateConfig, dateColConfig, soldPercentColConfig, proceeds];
    }
    return [purchaseDateConfig, dateColConfig, soldAmountColConfig];
  }
  return [
    purchaseDateConfig,
    dateColConfig,
    {
      value: 'Shares',
      alias: 'shares',
      width: '175px',
      format: sharesFormat,
      minValue: 0,
      gridType: 'number',
      isRequired: !isCustomSecurity,
      sourceTable: 'sold',
    },
    {
      value: 'Cost Basis',
      alias: 'cost_basis',
      width: '175px',
      format: largeCurrencyFormat,
      minValue: 0,
      gridType: 'number',
      isRequired: true,
    },
    {
      value: 'Proceeds',
      alias: 'proceeds',
      width: '175px',
      format: largeCurrencyFormat,
      minValue: 0,
      gridType: 'number',
      isRequired: true,
    },
  ];
};

export default colConfig;
