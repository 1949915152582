import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { captableUrl } from 'common/config/urls';
import { EMPTY_COLUMNS } from 'common/constants/cap-table';
import { MessageBox } from 'components';
import GoToCapTable from './GoToCapTable';

const EmptyCapTableMessage = props => {
  const { title, tagline, secondaryAction } = props;
  const { firmSlugParam, companySlugParam } = useParams();

  const capTableURL = useMemo(() => captableUrl(firmSlugParam, companySlugParam), [firmSlugParam, companySlugParam]);

  return (
    <MessageBox
      title={title || EMPTY_COLUMNS({ tableName: 'Cap Table', columnName: 'Securities' })}
      tagline={tagline || 'Please add a valid Security first'}
      fullWidth={false}
      action={<GoToCapTable capTableURL={capTableURL} />}
      secondaryAction={secondaryAction}
    />
  );
};

EmptyCapTableMessage.propTypes = {
  title: PropTypes.string,
  tagline: PropTypes.string,
  secondaryAction: PropTypes.element,
};

export default EmptyCapTableMessage;
