import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    zIndex: 3,
  },
  button: {
    boxShadow: 'none',
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
  label: {
    fontSize: '0.9375rem',
    fontWeight: 'bold',
  },
  leftButton: {
    borderRadius: '2.6875rem 0 0 2.6875rem',
    borderRight: '0!important',
  },
  rightButton: {
    borderRadius: '0 2.6875rem 2.6875rem 0!important',
    borderLeft: '1px solid #ffff!important',
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
  popper: {
    zIndex: 1,
    transform: 'translate3d(0, -7rem, 0)!important',
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  menuList: {
    padding: '0',
    margin: '-1rem 0',
  },
  menuItem: {
    fontSize: '1rem',
    fontWeight: 'bold',
    borderRadius: '2.6875rem',
    margin: '1rem 0',
    color: '#ffff',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'uppercase',
    height: '2.5rem',
    width: 'fit-content',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const SplitFab = ({ label, options, children: icon, mainAction, disabled = false }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current?.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box className={classes.root}>
      <ButtonGroup
        color="primary"
        className={classes.button}
        variant="contained"
        ref={anchorRef}
        aria-label="split button">
        <Button onClick={mainAction} className={classes.leftButton} disabled={disabled}>
          {icon}
          {label}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          className={classes.rightButton}
          disabled={disabled}>
          <ArrowDropDownIcon className={open ? classes.iconOpen : ''} />
        </Button>
      </ButtonGroup>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" className={classes.menuList}>
                  {options.map(option => (
                    <MenuItem
                      key={option.label}
                      onClick={option.callback}
                      className={classes.menuItem}
                      disabled={!option.callback}>
                      {icon}
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

SplitFab.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  children: PropTypes.node,
  mainAction: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SplitFab;
