import React from 'react';
import { isNil } from 'lodash';
import moment from 'moment';
import { oneDecimalPercentFormatValidateFloatTrueWithNegativeValues, yearsDecimalFormat } from 'common/formats/formats';
import { Cell } from 'common/types/scalarSpreadsheet';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import SelectValueViewer from 'components/SelectValueViewer/SelectValueViewer';
import { extractGPCApproaches } from 'pages/Valuations/util/util';
import {
  ALLOCATION_METHOD_OPTIONS,
  ALLOCATION_METHOD_OPTIONS_KEYS,
  AllocationMethodsMapKeys,
  SHEET_ALIASES_CONSTANTS,
  SPECIFIED_LABEL,
} from 'pages/ValuationsAllocation/common/constants/futureExit';
import { CapTableVersionSelector } from 'pages/ValuationsAllocation/components';
import { dbShortDate, getObjectValue, getStringValue } from 'utillities';
import { CellParserParams, CustomParserParams, MaturityDateProps } from './types';
import { getCellValue, getCustomParser } from '../../../utils/utils';

const {
  FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD,
  FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE,
  FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS,
  FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS,
  FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_DATE,
  FE_ALLOCATION_METHOD_SPREADSHEET_RISK_FREE_RATE,
  FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE,
  FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY,
} = SHEET_ALIASES_CONSTANTS;

const getMaturityDateValue = (props: MaturityDateProps): string => {
  const { column, exitDate } = props;
  if (!exitDate) return '';
  // Check if we have the maturity date value from API
  const { value: defaultMaturityDate } = getObjectValue(column[FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_DATE]);
  // If we have data, we return it
  if (defaultMaturityDate) return defaultMaturityDate as string;

  // Otherwise... We have to calculate the maturity date
  const { value: maturityYears } = getObjectValue(column[FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS]);

  const maturityDate = moment(dbShortDate(exitDate)).clone().add(Number(maturityYears), 'years').format('MM/DD/YYYY');
  return maturityDate;
};

const cellParser = (params: CellParserParams) => {
  const { alphabet, approaches, colIndex, column, isDisabled, row, rowIndex, valuationsApproachFutureExit } = params;
  const exitDate = getStringValue(valuationsApproachFutureExit?.exit_date);
  const publicCompsList = [{ value: null, label: SPECIFIED_LABEL }, ...(extractGPCApproaches(approaches) ?? [])];

  const ROW_NUMBER = rowIndex + 1;
  const columnLegend = alphabet[colIndex];
  const cellKey = columnLegend + ROW_NUMBER;

  const { value: columnValue } = getObjectValue(column[row.alias]);
  const { value: volatilitySource } = column[FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE];

  // Creating Cell from Row and Column
  const cell = { ...row, key: cellKey, value: columnValue };

  // Parse Cell based on Row alias
  switch (row.alias) {
    case FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD:
      cell.value
        = typeof columnValue === 'number'
          ? ALLOCATION_METHOD_OPTIONS_KEYS[columnValue as keyof AllocationMethodsMapKeys]
          : columnValue;
      cell.dbType = 'number';
      cell.gridType = 'string';
      cell.valueViewer = (props: any) => (
        <SelectValueViewer options={Object.values(ALLOCATION_METHOD_OPTIONS)} useLabel {...props} />
      );
      cell.dataEditor = (props: any) => (
        <GridSelect enumerated useHandleChange={false} options={Object.values(ALLOCATION_METHOD_OPTIONS)} {...props} />
      );
      cell.forceComponent = true;
      break;

    case FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE:
      cell.value = columnValue;
      cell.isRequired = true;
      cell.dbType = 'number';
      cell.gridType = 'number';
      cell.dataEditor = props => <CapTableVersionSelector {...props} />;
      cell.valueViewer = props => <SelectValueViewer {...props} />;
      cell.forceComponent = true;
      break;

    case FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS:
      cell.value = columnValue;
      cell.hasSubRows = true;
      cell.showSubRows = true;
      cell.hidden = true;
      break;

    case FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS:
      cell.value = columnValue;
      cell.gridType = 'number';
      cell.dbType = 'string';
      cell.format = yearsDecimalFormat;
      break;

    case FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_DATE:
      cell.value = getMaturityDateValue({ column, exitDate });
      cell.gridType = 'gridDate';
      break;

    case FE_ALLOCATION_METHOD_SPREADSHEET_RISK_FREE_RATE:
      cell.value = columnValue;
      cell.gridType = 'percentage';
      cell.dbDecimalPlaces = 4;
      cell.format = oneDecimalPercentFormatValidateFloatTrueWithNegativeValues;
      break;

    case FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE:
      cell.value = columnValue;
      cell.gridType = 'number';
      cell.dbType = 'number';
      cell.valueViewer = (props: any) => <SelectValueViewer options={publicCompsList} {...props} />;
      cell.dataEditor = (props: any) => <GridSelect menuPosition="fixed" options={publicCompsList} {...props} />;
      break;

    case FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY:
      cell.value = columnValue || 0;
      cell.gridType = 'percentage';
      cell.dbType = 'string';
      cell.dbDecimalPlaces = 4;
      cell.readOnly = !isNil(volatilitySource) || isDisabled;
      cell.format = oneDecimalPercentFormatValidateFloatTrueWithNegativeValues;
      break;

    default:
      break;
  }

  const cellData = getCellValue(cell, columnLegend);

  return {
    [cell.key]: {
      ...cellData,
    } as Cell,
  };
};

const customParser = (params: CustomParserParams) => getCustomParser({ parserParams: params, cellParser });

export default customParser;
