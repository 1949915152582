import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty, pickBy } from 'lodash';
import { ERROR_409 } from 'common/config/api';
import { FUNDS_BY_FIRM_QUERY_KEY } from 'common/constants/services/firms';
import {
  CREATE_FUND_DEFAULT_ERROR_MESSAGE,
  CREATE_FUND_DEFAULT_SUCCESS_MESSAGE,
  CREATE_FUND_NAME_ALREADY_EXISTS_ERROR_MESSAGE,
} from 'common/constants/services/funds';
import { FundService } from 'services';
import { getStringValue, throwError } from 'utillities';
import { CreateFundParams, CreateFundResponse } from './types';

const createFund = async (params: CreateFundParams) => {
  const { fund } = params;

  if (isEmpty(pickBy(fund))) return throwError(CREATE_FUND_DEFAULT_ERROR_MESSAGE);

  const fundService = new FundService();

  const errorMessages: { [status: number]: string } = {
    [ERROR_409]: CREATE_FUND_NAME_ALREADY_EXISTS_ERROR_MESSAGE,
  };

  try {
    const createdFund = await fundService.newFund(fund);

    if (createdFund?.id)
      return {
        fund: createdFund,
        responseMessage: CREATE_FUND_DEFAULT_SUCCESS_MESSAGE(getStringValue(createdFund?.name)),
      } as CreateFundResponse;

    return throwError(CREATE_FUND_DEFAULT_ERROR_MESSAGE);
  } catch (error: any) {
    const { status }: { status: number } = error;
    return throwError(errorMessages[status] || CREATE_FUND_DEFAULT_ERROR_MESSAGE);
  }
};

const useCreateFund = () => {
  const queryClient = useQueryClient();

  const createFundMutation = useMutation<CreateFundResponse, Error, CreateFundParams>({
    mutationFn: params => createFund(params),
  });

  const { isSuccess, isError, mutateAsync } = createFundMutation;

  if (isSuccess) {
    // Invalidate the Funds List query to refetch the data without the deleted Fund
    queryClient.invalidateQueries({ queryKey: [FUNDS_BY_FIRM_QUERY_KEY] });

    // Clean the mutation internal state
    createFundMutation.reset();
  }

  return {
    isError,
    mutateAsync,
  };
};

export default useCreateFund;
