import React from 'react';
import { twoDecimalPercentFormat } from 'common/formats/formats';
import { SelectValueViewer } from 'components';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import MarketAdjustmentGridSelect from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentLedger/MarketAdjustmentGridSelect';
import MarketAdjustmentSelectValueViewer from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentLedger/MarketAdjustmentSelectValueViewer';
import {
  APPLIED_ADJUSTMENT_ALIAS,
  APPLIED_ADJUSTMENT_TITLE,
  KEY_VALUE_PARSE,
  MEAN_ALIAS,
  MEAN_TITLE,
  MEDIAN_ALIAS,
  MEDIAN_MEDIAN_PARSE,
  MEDIAN_TITLE,
  MULTIPLE_DECIMAL_PLACES,
  PERCENTILE_PARSE,
  PERCENTILE_SELECTION_A,
  PERCENTILE_SELECTION_B,
  SELECT_ADJUSTMENT_ALIAS,
  SELECT_ADJUSTMENT_TITLE,
  SPECIFIED_ADJUSTMENT_ALIAS,
  SPECIFIED_ADJUSTMENT_TITLE,
} from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentLedger/util/constants';
import { percentileOptions } from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/getRowConfig';

export const formulaProps = {
  gridType: 'percentage',
  readOnly: true,
  ignoreRowCopy: true,
  dbType: 'string',
  format: { ...twoDecimalPercentFormat({}), validateFloat: true },
  dbDecimalPlaces: MULTIPLE_DECIMAL_PLACES,
};

const getRowConfig = (publicComps, approach, isDisabled) => [
  {
    largeHeader: true,
    ignoreRowCopy: true,
  },
  {
    ignoreRowCopy: true,
  },
  {
    ignoreRowCopy: true,
  },
  ...publicComps,
  {
    ...formulaProps,
    value: MEDIAN_TITLE,
    alias: MEDIAN_ALIAS,
    formula: 'MEDIAN',
    parseAs: MEDIAN_MEDIAN_PARSE,
  },
  {
    ...formulaProps,
    value: MEAN_TITLE,
    alias: MEAN_ALIAS,
    formula: 'MEAN',
    parseAs: MEDIAN_MEDIAN_PARSE,
  },
  {
    ...formulaProps,
    dataEditor: props => <GridSelect menuPosition="fixed" options={percentileOptions} {...props} />,
    valueViewer: props => <SelectValueViewer options={percentileOptions} {...props} />,
    className: 'row-label-indented grid-column-selector',
    dropdown: true,
    defaultValue: approach.valuations_approach_backsolve?.percentile_selection_b || 75,
    readOnly: isDisabled,
    value: '',
    alias: PERCENTILE_SELECTION_B,
    parseAs: PERCENTILE_PARSE,
  },
  {
    ...formulaProps,
    dataEditor: props => <GridSelect menuPosition="fixed" options={percentileOptions} {...props} />,
    valueViewer: props => <SelectValueViewer options={percentileOptions} {...props} />,
    className: 'row-label-indented grid-column-selector',
    dropdown: true,
    defaultValue: approach.valuations_approach_backsolve?.percentile_selection_a || 25,
    readOnly: isDisabled,
    value: '',
    alias: PERCENTILE_SELECTION_A,
    parseAs: PERCENTILE_PARSE,
  },
  {
    ...formulaProps,
    defaultValue: '',
    value: SPECIFIED_ADJUSTMENT_TITLE,
    customKey: 'SpecifiedAdjustment',
    alias: SPECIFIED_ADJUSTMENT_ALIAS,
    parseAs: KEY_VALUE_PARSE,
  },
  {
    ignoreRowCopy: true,
    gridType: 'string',
    customKey: 'SelectedAdjustment',
    value: SELECT_ADJUSTMENT_TITLE,
    alias: SELECT_ADJUSTMENT_ALIAS,
    defaultValue: 'Median',
    dataEditor: props => <MarketAdjustmentGridSelect {...props} />,
    valueViewer: props => <MarketAdjustmentSelectValueViewer {...props} />,
    parseAs: KEY_VALUE_PARSE,
    dropdown: true,
  },
  {
    ...formulaProps,
    defaultValue: '',
    readOnly: true,
    customKey: 'AppliedAdjustment',
    value: APPLIED_ADJUSTMENT_TITLE,
    alias: APPLIED_ADJUSTMENT_ALIAS,
    parseAs: KEY_VALUE_PARSE,
  },
];

export default getRowConfig;
