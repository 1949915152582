import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../api';

const useGetCompanyUsers = (companyId: number | undefined, userSearchValue: string) => {
  const getCompanyUsers = useCallback(async () => {
    try {
      if (companyId) {
        return await ApiService.apiCompanyUsersRead(companyId.toString());
      }
    } catch (e) {
      throw new Error('An error occurred while retrieving company users');
    }
  }, [companyId]);

  const { data, error, isLoading } = useQuery({
    enabled: !!companyId,
    queryKey: ['companyUsers', companyId],
    queryFn: getCompanyUsers,
    select: data => (data || []).filter(user => user.email?.includes(userSearchValue)),
  });

  return { data, error, isLoading };
};

export default useGetCompanyUsers;
