/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { withStyles } from '@material-ui/styles';
import { flowRight, isObject } from 'lodash';
import PropTypes from 'prop-types';
import { securityTypes } from 'common/constants';
import * as allocations from 'common/constants/allocations';
import * as CAPTABLE from 'common/constants/cap-table';
import { FUND_ALIAS, SECURITY_ALIAS } from 'common/constants/fund-ownership';
import { SELECT_FUND } from 'common/constants/funds';
import * as inputOptions from 'common/constants/inputs';
import { MULTIPLE_PREMIUM_DISCOUNT_ALIAS } from 'common/constants/valuations';
import { useStore } from 'common/store';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import withAction from 'components/WithAction/withAction';
import withDialog from 'components/WithDialog/withDialog';
import withLink from 'components/WithLink/withLink';
import withRequired from 'components/WithRequired/withRequired';
import withTooltip from 'components/WithTooltip/withTooltip';
import { CompGroupsContext } from 'context';
import {
  METRIC_LABEL,
  MULTIPLE_LABEL,
} from 'pages/Allocation/allocations/components/future-exit-ledger/data/future-equity-value/constants';
import * as CONVERTIBLE_NOTES from 'pages/CapTable/cap-table/components/convertible-notes-ledger/config/constants';
import { fundOptions, securityOptions, seniorityOptions } from 'pages/CapTable/cap-table/utilities/getOptionsForSelect';
import { VERSIONS_ALIAS } from 'pages/CompGroups/constants/constants';
import {
  ALLOCATION_METHOD_ALIAS,
  VALUATION_APPROACH_GPC_ALIAS,
} from 'pages/Valuations/approaches/backsolveApproach/constants';
import {
  COMPANY,
  LTM_EBITDA,
  LTM_REVENUE,
  NTM_EBITDA,
  NTM_REVENUE,
  PUBLIC_COMPS_APPROACH_ALIAS,
  SELECTION,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import handleOptionsNumberFormat from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/handleOptionsNumberFormat';
import {
  LTM_EBITDA_MULTIPLE_ID,
  NTM_EBITDA_MULTIPLE_ID,
} from 'pages/Valuations/approaches/GuidelineTransactions/config/constants';
import { EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION } from 'pages/ValuationsAllocation/common/constants/equityAllocation/sheetAliases';
import { FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE } from 'pages/ValuationsAllocation/common/constants/futureExit/sheetAliases';
import { formatNumbers, gridShortDate, toString } from 'utillities';

const aliasesForGenericOptions = [
  CAPTABLE.PARTICIPATION_ALIAS, // 16.  Participating
  CAPTABLE.IS_PARTICIPATION_CAP_ALIAS, // 17.  Participation Cap
  CAPTABLE.CUMMULATIVE_DIVIDENDS_ALIAS, // 19.  Cumulative Dividends
  CAPTABLE.HAS_MULTIPLE_INVESTMENT_DATES_ALIAS, // 20.  Multiple Investment Dates?
  CAPTABLE.COMPOUNDING_ALIAS, // 23.  Compounding
  CONVERTIBLE_NOTES.COMPOUNDING_ALIAS,
];

const getOptionsForSecurityType = (optionList, optionTypes) => {
  const tmpOptions = [];
  if (Array.isArray(optionList)) {
    optionTypes.forEach(type => {
      tmpOptions.push({
        value: type,
        label: optionList[type - 1],
      });
    });
  }
  return tmpOptions;
};

const getOptionsFrom = (optionList = []) => {
  const tmpOptions = [];

  optionList.forEach((option, index) => {
    let parsedOption = option;

    if (!isObject(option)) {
      parsedOption = {
        value: index,
        label: option,
      };
    }

    if (parsedOption) {
      tmpOptions.push(parsedOption);
    }
  });

  return tmpOptions;
};

// cell.value can be an integer or an object
const getCellValue = cell => {
  if (cell.hidden) {
    return '';
  }
  if (cell?.value?.id) {
    return cell.value.id;
  }
  return cell.value;
};

/* Handlers for specific aliases */
const handleVersionsAlias = ({ group }) => {
  const optionList = [];
  if (Array.isArray(group?.versions)) {
    group.versions.forEach(version => {
      optionList.push({
        label: `${gridShortDate(version.created_at)} | V-${version.version_number}`,
        value: version.id,
      });
    });
    const defaultLabel = group.versions.find(version => version.id === version.latest_version);
    return { optionList, defaultLabel };
  }

  return {
    optionList,
    defaultLabel: inputOptions.SELECT_OPTION,
  };
};

const handleSecurityTypeAlias = () => ({
  optionList: getOptionsForSecurityType(securityTypes.LIST, securityTypes.TYPES),
  defaultLabel: inputOptions.SELECT_SECURITY,
});

const handleUnderlyingSecurityAlias = ({ optionsFromCell }) => ({
  optionList: getOptionsFrom(optionsFromCell),
  defaultLabel: inputOptions.SELECT_SECURITY,
});

const handleSeniorityAlias = ({ props }) => ({
  defaultLabel: CAPTABLE.SELECT_PRIORITY,
  optionList: seniorityOptions(props),
});

const handleFundAlias = ({ fundList }) => ({
  optionList: fundOptions(fundList),
  defaultLabel: SELECT_FUND,
});

const handleSecurityAlias = ({ captableInfo }) => ({
  optionList: securityOptions(captableInfo),
  defaultLabel: inputOptions.SELECT_SECURITY,
});

const handleCapTableAlias = ({ capTableList }) => {
  if (capTableList) {
    const optionList = [];
    capTableList.forEach(captable => {
      optionList.push({
        value: captable.id,
        label: captable.name,
      });
    });
    const defaultLabel = capTableList.find(item => item.is_primary).name;
    return { optionList, defaultLabel };
  }
  return {};
};

const handleGpcMultipleValueAlias = () => ({
  optionList: getOptionsFrom(allocations.MULTIPLE_OPTIONS),
  defaultLabel: MULTIPLE_LABEL,
});

const handleBenchmarkMultipleValueAlias = ({ optionsFromCell }) => ({
  optionList: getOptionsFrom(optionsFromCell),
  defaultLabel: MULTIPLE_LABEL,
});

const handleFinancialsMetricValueAlias = () => ({
  optionList: getOptionsFrom(allocations.METRIC_OPTIONS),
  defaultLabel: METRIC_LABEL,
});

const handleDividendPaymentTypeAlias = () => ({
  optionList: getOptionsFrom(inputOptions.DIVIDEND_PAYMENT_TYPE),
  defaultLabel: inputOptions.SELECT_OPTION,
});

const handleAllocationMethodAlias = ({ cell }) => {
  let optionList = [];
  if (cell?.customOptionLabel) {
    optionList = getOptionsFrom([cell.customOptionLabel]);
  } else {
    optionList = getOptionsFrom(allocations.SCENARIO_METHODS);
  }
  return { optionList, defaultLabel: inputOptions.SELECT_OPTION };
};

const handleScenarioTypeAlias = () => ({
  optionList: getOptionsFrom(allocations.SCENARIO_TYPES),
  defaultLabel: inputOptions.SELECT_OPTION,
});

const handleSelectionAlias = ({ props }) => {
  const optionList = getOptionsFrom(props.options);
  return { optionList, defaultLabel: inputOptions.SELECT_OPTION };
};

const handleConvNoteEquityConversionModelAlias = () => ({
  optionList: getOptionsFrom(CONVERTIBLE_NOTES.EQUITY_CONVERSION_MODEL_VALUES),
  defaultLabel: inputOptions.SELECT_OPTION,
});
/* End Handlers for specific aliases */

// TODO: Remove Allocations Handlers
const handlers = {
  /* These handlers update the default label AND the option list */
  [allocations.BENCHMARK_MULTIPLE_VALUE_ALIAS]: handleBenchmarkMultipleValueAlias,
  [allocations.CAP_TABLE_ALIAS]: handleCapTableAlias,
  [FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE]: handleCapTableAlias,
  [allocations.FINANCIALS_METRIC_VALUE_ALIAS]: handleFinancialsMetricValueAlias,
  [allocations.GPC_MULTIPLE_VALUE_ALIAS]: handleGpcMultipleValueAlias,
  [CAPTABLE.SECURITY_TYPE_ALIAS]: handleSecurityTypeAlias,
  [CAPTABLE.SENIORITY_ALIAS]: handleSeniorityAlias,
  [CAPTABLE.UNDERLYING_SECURITY_ALIAS]: handleUnderlyingSecurityAlias,
  [EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION]: handleCapTableAlias,
  [FUND_ALIAS]: handleFundAlias,
  [SECURITY_ALIAS]: handleSecurityAlias,
  [VERSIONS_ALIAS]: handleVersionsAlias,
  /* The following handlers only update the option list */
  [ALLOCATION_METHOD_ALIAS]: handleAllocationMethodAlias,
  [allocations.SCENARIO_METHOD_ALIAS]: handleAllocationMethodAlias,
  [allocations.SCENARIO_TYPE_ALIAS]: handleScenarioTypeAlias,
  [CAPTABLE.DIVIDEND_PAYMENT_TYPE_ALIAS]: handleDividendPaymentTypeAlias,
  [CONVERTIBLE_NOTES.EQUITY_CONVERSION_MODEL_ALIAS]: handleConvNoteEquityConversionModelAlias,
  [SELECTION]: handleSelectionAlias,
};

const getOptionSelected = ({
  props,
  fundList,
  captableInfo,
  capTableList,
  useLabel,
  group,
  currency,
  defaultOptionList = [],
}) => {
  const { cell } = props;
  const { alias, columnId, readOnly, format, options: optionsFromCell } = cell;
  let optionList = [];
  let defaultLabel = inputOptions.SELECT_OPTION;

  if (handlers[alias]) {
    const { optionList: newOptionList, defaultLabel: newDefaultLabel } = handlers[alias]({
      props,
      fundList,
      captableInfo,
      capTableList,
      useLabel,
      group,
      currency,
      defaultOptionList,
      cell,
      optionsFromCell,
    });
    optionList = newOptionList;
    defaultLabel = newDefaultLabel;
  }

  if (aliasesForGenericOptions.includes(alias)) {
    optionList = getOptionsFrom(inputOptions.YES_NO);
  }

  if (defaultOptionList.length !== 0 && optionList.length === 0) {
    optionList = getOptionsFrom(defaultOptionList);
  }

  if (alias === PUBLIC_COMPS_APPROACH_ALIAS) {
    optionList = optionList.map(opt => ({ id: opt.panelId, label: opt.name }));
  }

  if (alias === VALUATION_APPROACH_GPC_ALIAS) {
    optionList = getOptionsFrom(props.options);
  }

  // Only apply this logic on EBITDA columns for the company row
  if (
    !readOnly
    && alias === COMPANY
    && [LTM_EBITDA, NTM_EBITDA, LTM_EBITDA_MULTIPLE_ID, NTM_EBITDA_MULTIPLE_ID].includes(columnId)
  ) {
    optionList = handleOptionsNumberFormat({
      options: defaultOptionList,
      format,
      currency,
    });
  }

  // Only apply this logic for multiple premium discount row
  if (
    !readOnly
    && alias === MULTIPLE_PREMIUM_DISCOUNT_ALIAS
    && [LTM_REVENUE, LTM_EBITDA, NTM_REVENUE, NTM_EBITDA].includes(columnId)
  ) {
    return formatNumbers({
      value: cell.value,
      currency: format?.style === 'currency' ? currency : null,
      format,
    });
  }

  const itemSelected = optionList.find(opt => {
    // opt.value is used for all cases with the exception of Underlying Security which uses opt.id
    let optValue = opt.value ?? opt.id;
    if (useLabel) {
      optValue = opt.label;
    }
    return toString(optValue) === toString(getCellValue(cell));
  });

  return itemSelected?.label || itemSelected?.name || (!cell.readOnly && defaultLabel);
};

const CustomTooltip = withStyles(() => ({
  tooltip: {
    margin: '0px',
  },
}))(Tooltip);

const TextCell = props => {
  const { children, shouldDisplayTooltip, textRef } = props;

  return (
    <Grid component="span" className="text-cell">
      <Grid component="span" ref={textRef}>
        {shouldDisplayTooltip ? (
          <CustomTooltip placement="bottom-end" title={children?.toString()}>
            <Grid component="span">{children}</Grid>
          </CustomTooltip>
        ) : (
          <Grid component="span">{children}</Grid>
        )}
      </Grid>
    </Grid>
  );
};

TextCell.propTypes = {
  children: PropTypes.node,
  shouldDisplayTooltip: PropTypes.bool,
  textRef: PropTypes.shape({}),
};

const ICON_CONTAINER_WIDTH = 20 + 10; // Icon + Padding

const SelectValueViewer = props => {
  const { value, cell, onDoubleClick, useLabel, options } = props;
  const { format } = useContext(FeaturedSpreadsheetContext);
  const currency = format?.currency;

  const [containerWidth, setContainerWidth] = useState(0);
  const [textWidth, setTextWidth] = useState(0);

  const [{ captableInfo, fundList, capTableList }] = useStore();

  const compGroups = useContext(CompGroupsContext);

  const containerRef = useRef();
  const textRef = useRef();

  const shouldDisplayTooltip = useMemo(
    () => textWidth + ICON_CONTAINER_WIDTH >= containerWidth,
    [textWidth, containerWidth]
  );

  const selected = useMemo(
    () =>
      cell.columnLegend || cell.insideLedger || cell.dropdown
        ? getOptionSelected({
          props,
          fundList,
          captableInfo,
          capTableList,
          useLabel,
          group: compGroups?.group,
          currency,
          defaultOptionList: options,
        })
        : value,
    [
      capTableList,
      captableInfo,
      cell.columnLegend,
      cell.dropdown,
      cell.insideLedger,
      fundList,
      options,
      props,
      useLabel,
      value,
      compGroups,
      currency,
    ]
  );

  let className = 'value-viewer';

  const id = useMemo(() => {
    let key = '';
    if (cell?.getFullKey) {
      key = cell.getFullKey();
    } else if (cell?.key) {
      key = `${selected}-${cell.key}`;
    } else {
      key = selected;
    }
    return `select-value-viewer-${key}`;
  }, [cell, selected]);

  // Custom double click handler for cells not in a spreadsheet component
  const handleDoubleClick = useCallback(() => {
    const event = new MouseEvent('dblclick', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    document.getElementById(id).dispatchEvent(event);
  }, [id]);

  useLayoutEffect(() => {
    if (containerRef?.current) setContainerWidth(containerRef.current.offsetWidth);
    if (textRef?.current) setTextWidth(textRef.current.offsetWidth);
  }, [containerRef, textRef]);

  if (cell.readOnly) {
    return (
      <Grid component="span" className={className} ref={containerRef}>
        <TextCell textRef={textRef} shouldDisplayTooltip={shouldDisplayTooltip}>
          {selected}
        </TextCell>
      </Grid>
    );
  }

  className = 'select-value-viewer';

  return (
    <Grid
      className={className}
      id={id}
      onClick={onDoubleClick || handleDoubleClick}
      onKeyDown={onDoubleClick}
      ref={containerRef}
      role="button"
      tabIndex={0}>
      <TextCell textRef={textRef} shouldDisplayTooltip={shouldDisplayTooltip}>
        {selected}
      </TextCell>

      <Grid component="span" className="dropdown-icon">
        <ArrowDropDownIcon />
      </Grid>
    </Grid>
  );
};

const enhance = flowRight(withTooltip, withDialog, withRequired, withLink, withAction);

export default enhance(SelectValueViewer);
