/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PortraitIcon from '@material-ui/icons/Portrait';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import { PROFILE_PICTURE, PROFILE_PICTURE_SPECS } from 'common/constants/user';
import { DropZone } from 'components/';

const readFile = async (file, onLoadCallback) => {
  const reader = new FileReader();
  reader.onload = onLoadCallback;
  reader.readAsDataURL(file);
};

const ImageContainer = props => {
  const { imageSrc, setImageSrc, setSelectedFile, setCroppedAreaPixels, title, cropShape, aspect } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const validFileTypes = ['image/png', 'image/gif', 'image/jpeg', 'image/svg+xml'];

  const onCropComplete = useCallback((_croppedArea, completeCroppedAreaPixels) => {
    setCroppedAreaPixels(completeCroppedAreaPixels);
  }, []);

  const processImage = async file => {
    setSelectedFile(file);
    await readFile(file, element => {
      const { result } = element.target;
      setImageSrc(result);
    });
  };

  const resetImage = () => {
    setSelectedFile(null);
    setImageSrc(null);
  };

  const useStyles = makeStyles(theme => ({
    cropContainer: {
      position: 'relative',
      width: '18.75rem',
      height: '14.563rem',
      '& .reactEasyCrop_CropArea': {
        color: 'rgba(240, 240, 240, 0.65)',
      },
    },
    cropButton: {
      flexShrink: 0,
    },
    controls: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      width: '18.75rem',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    imageSrcContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    sliderContainer: {
      display: 'flex',
      flex: '1',
      alignItems: 'center',
    },
    sliderLabel: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 65,
      },
    },
    slider: {
      padding: '22px 0px',
      color: theme.palette.primary[700],
      '& .MuiSlider-rail': {
        color: '#73F1F7',
      },
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        margin: '5',
      },
    },
    handlerIcons: {
      marginTop: '10px',
    },
    smallPortraitIcon: {
      paddingTop: '0.15rem',
    },
    pointer: {
      cursor: 'pointer',
    },
    defaultHtmlColor: {
      color: theme.palette.primary[700],
    },
  }));

  const titleArray = [
    [`Drag & Drop ${title || PROFILE_PICTURE}`, null],
    ['or', null],
    ['Select a file', { textDecoration: 'underline' }],
  ];
  const classes = useStyles();

  const setHtmlColor = condition => (condition ? classes.defaultHtmlColor.color : 'lightgrey');

  const getAspect = () => {
    if (aspect) return aspect.horizontal / aspect.vertical;
    return 1;
  };

  return (
    <div>
      <Typography variant="h6">{title || PROFILE_PICTURE}</Typography>
      <br />
      <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
        <Grid item sm={12}>
          {imageSrc ? (
            <div className={classes.imageSrcContainer}>
              <div className={classes.cropContainer}>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={getAspect()}
                  cropShape={cropShape || 'round'}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  showGrid={false}
                  minZoom={0.1}
                  restrictPosition={false}
                />
              </div>
              <div className={classes.controls}>
                <div className={classes.sliderContainer}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <PortraitIcon
                        className={clsx(classes.handlerIcons, classes.smallPortraitIcon)}
                        fontSize="small"
                        htmlColor={setHtmlColor(zoom > 1)}
                      />
                    </Grid>
                    <Grid item xs>
                      <Slider
                        value={zoom}
                        min={0.1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        classes={{ root: classes.slider }}
                        onChange={(_e, sliderZoom) => setZoom(sliderZoom)}
                      />
                    </Grid>
                    <Grid item>
                      <PortraitIcon
                        className={classes.handlerIcons}
                        fontSize="medium"
                        htmlColor={setHtmlColor(zoom < 3)}
                      />
                    </Grid>
                    <Grid item>
                      <DeleteOutlineIcon
                        className={`${classes.handlerIcons} ${classes.pointer}`}
                        fontSize="medium"
                        onClick={resetImage}
                        htmlColor={setHtmlColor(true)}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          ) : (
            <DropZone
              setFileSrc={processImage}
              subTitle={PROFILE_PICTURE_SPECS}
              acceptFileTypes="image/*"
              validFileTypes={validFileTypes}
              maxSizeAllowedInMb={10}
              titleArray={titleArray}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

ImageContainer.propTypes = {
  imageSrc: PropTypes.string,
  setImageSrc: PropTypes.func,
  setSelectedFile: PropTypes.func,
  setCroppedAreaPixels: PropTypes.func,
  title: PropTypes.string,
  cropShape: PropTypes.string,
  aspect: PropTypes.object,
};

export default ImageContainer;
