import moment from 'moment';

export const generatePreviousPeriods = (currentFiscalYearEnd, fiscalYears = []) => {
  if (fiscalYears.length) {
    return fiscalYears.filter(fyp => moment(fyp.statement_date).isBefore(currentFiscalYearEnd))
      .map(p => moment(p.statement_date));
  }
  const previousPeriods = [];
  previousPeriods.push(moment(currentFiscalYearEnd).subtract(2, 'years'));
  previousPeriods.push(moment(currentFiscalYearEnd).subtract(1, 'years'));

  return previousPeriods;
};

export const generateNextPeriods = (currentFiscalYearEnd, fiscalYears = []) => {
  if (fiscalYears.length) {
    return fiscalYears.filter(fyp => moment(fyp.statement_date).isAfter(currentFiscalYearEnd))
      .map(p => moment(p.statement_date));
  }
  const nextPeriods = [];
  nextPeriods.push(moment(currentFiscalYearEnd).add(1, 'years'));
  nextPeriods.push(moment(currentFiscalYearEnd).add(2, 'years'));
  nextPeriods.push(moment(currentFiscalYearEnd).add(3, 'years'));
  return nextPeriods;
};

export const generateParentColumns = ({
  id,
  period,
  tmpCollapsibleColumns,
  generatedPeriods,
  ...info
}) => {
  const auxtmpCollapsibleColumns = {
    ...tmpCollapsibleColumns,
    [id]: true,
  };

  generatedPeriods.push({
    ...info,
    ...period,
    isParent: true,
    name: info?.title || period.name,
    id,
  });

  return { ...auxtmpCollapsibleColumns };
};
