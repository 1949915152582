import { largeCurrencyFormat } from 'common/formats/formats';
import { ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT } from 'pages/ValuationsAllocation/common/constants/allocation';
import { getNumberValue } from 'utillities';
import { HandleFutureEquityValueParams } from './types';

const handleFutureEquityValue = (params: HandleFutureEquityValueParams) => {
  const { cell, scenarioType } = params;

  cell.className = `${cell.className} subtitle-bold`;
  cell.format = largeCurrencyFormat;
  cell.gridType = 'number';

  if (![ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT].includes(getNumberValue(scenarioType))) {
    cell.hidden = true;
  }
};

export default handleFutureEquityValue;
