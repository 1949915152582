import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white,
    height: '100%',
  },
  // Right column
  formContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(8),
    flexDirection: 'column'
  },
  form: {
    width: '100%',
    maxWidth: 600,
    boxSizing: 'border-box',
    padding: theme.spacing(3),

    '& h2': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    '& .scar-textField': {
      marginBottom: theme.spacing(2),
    },
  },
  btn: {
    marginTop: theme.spacing(1),
  },
  linkContainer: {
    textAlign: 'center',
    marginTop: 20
  },
  link: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    letterSpacing: 1,
  },
}));

export default useStyles;
