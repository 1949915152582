import { alpha } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  boxContainer: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(5),
  },
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 512,
    boxShadow: 24,
    borderRadius: 8,
  },
  printLogo: {
    paddingBottom: theme.spacing(4),
    width: '25%',
  },
  logo: {
    maxWidth: '100%',
    height: 'auto',
  },
  modalTitle: {
    paddingBottom: theme.spacing(1.5),
  },
  modalSubtitle: {
    margin: '1rem 0',
  },
  modalSubtitleGrey: {
    color: alpha(theme.palette.black, 0.7),
  },
  modalItemsContainer: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(5),
  },
  modalItemsContainerMd: {
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(0),
    },
  },
  modalItem: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  modalItemMd: {
    [theme.breakpoints.up('md')]: {
      maxWidth: `${100 / 5}%`,
      flexBasis: `${100 / 5}%`,
    },
  },
  modalItemCode: {
    color: alpha(theme.palette.black, 0.6),
    fontWeight: 600,
    fontSize: '1.125rem',
  },
  generateButtonContainer: {
    marginTop: theme.spacing(3.5),
  },
  buttonsContainer: {
    marginTop: theme.spacing(4.5),
  },
  actionButton: {
    margin: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(1)}px 0`,
    },
  },
  downloadButton: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
    },
  },
  whiteButton: {
    background: theme.palette.white,
    color: alpha(theme.palette.black, 0.75),

    '&:hover, &:active, &:focus': {
      backgroundColor: theme.palette.grey[200],
      boxShadow: 'none',
    },
  },
  generateButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,

    '&:hover, &:active, &:focus': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      boxShadow: 'none',
    },

    '&:disabled': {
      borderColor: alpha(theme.palette.black, 0.26),
    },
  },
  generateButtonMd: {
    padding: `${theme.spacing(0.75) - 2}px ${theme.spacing(2) - 2}px`,
  },
  hideGrid: {
    display: 'none',
  },
  printGrid: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(5),
  },
  centerContainer: {
    alignItems: 'center',
  },
  noBackupCodes: {
    padding: `${theme.spacing(3)}px 0`,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
