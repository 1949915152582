/* eslint-disable no-case-declarations */
/* eslint-disable no-restricted-globals */

import { isNull } from 'lodash';
import moment from 'moment';
import { GRAY_CELL, PERCENTAGE } from 'common/constants/general';
import { parseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import {
  CASH_FLOW_REMAINING_ROW_NUMBER,
  DISCOUNT_PERIODS_ROW_NUMBER,
  EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ROW_NUMBER,
  EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_ROW_NUMBER,
  EBIT_TOTAL_ALIAS,
  EBITDA_TOTAL_ALIAS,
  EBITDA_TOTAL_ROW_NUMBER,
  REVENUE_TOTAL_ALIAS,
  REVENUE_TOTAL_ROW_NUMBER,
  ROW_WITH_PAIRS,
} from './constants';

const firstColumnValidateRows = [
  1, // Empty Title Column
  REVENUE_TOTAL_ROW_NUMBER,
  EBITDA_TOTAL_ROW_NUMBER,
  EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_ROW_NUMBER,
  EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ROW_NUMBER,
];

const isCellWithPairAndIsPercent = cell => cell.rowNumberPair && cell.gridType === PERCENTAGE;

const comesFromFinancials = alias => [REVENUE_TOTAL_ALIAS, EBIT_TOTAL_ALIAS, EBITDA_TOTAL_ALIAS].includes(alias);

const parser = async ({ columns = [], rowConfig, tableData }) => {
  let cells = {};
  const alphabet = alphabetGenerator([], columns.length);
  const getExpr = ({ expr, columnLegend, prevColumnLegend, rowNumber, date }) => {
    const startDate = tableData.measurementDate.date;
    const endDate = `${moment(date).year()}-12-31`;
    let auxExpr = expr;
    if (rowNumber === DISCOUNT_PERIODS_ROW_NUMBER) {
      switch (columnLegend) {
        case 'A':
          auxExpr = '';
          break;
        case 'B':
          auxExpr = `=YEARFRAC("${startDate}","${endDate}",3)/2`;
          break;
        case 'C':
          auxExpr = '=SUM(@@24*2,0.5)';
          break;
        default:
          auxExpr = '=@@24+1';
          break;
      }
    }
    if (auxExpr) {
      // Column B represents the calendar year where the MD falls. Thus, the
      // calculation should use the year fraction instead of the entire year.
      if (rowNumber === CASH_FLOW_REMAINING_ROW_NUMBER && columnLegend === 'B') {
        auxExpr = `=YEARFRAC("${startDate}","${endDate}",3)*@21`;
      }
      if (prevColumnLegend && auxExpr.match(/@@/g)) {
        auxExpr = auxExpr.replace(/@@/g, `${prevColumnLegend}`);
      } else if (!prevColumnLegend && auxExpr.match(/@@/g)) {
        // first column no prev, the formula its gonna fail
        return '';
      }
      return auxExpr.replace(/@/g, `${columnLegend}`);
    }
    return '';
  };

  const getValue = auxValue => (isNaN(auxValue) || isNull(auxValue) || auxValue === '' ? null : auxValue);

  const checkIfCellIsCalculated = (isFirstColumn, row, wasManuallyEntered) => {
    let { className } = row;
    const isCellCalculated = !isFirstColumn && !isNaN(row.rowNumberPair) && !wasManuallyEntered;
    if (isCellCalculated) {
      className = `${className} ${GRAY_CELL}`;
      return { className, isCellCalculated };
    }
    return { className };
  };

  const rowWithPairs = (expr, row, isFirstColumn, column, columnLegend, prevColumnLegend, rowNumber) => {
    let cellExpr = expr;
    let exprPair = '';
    let wasManuallyEntered = null;

    if (ROW_WITH_PAIRS.includes(row.alias) && !isFirstColumn) {
      wasManuallyEntered = column[`${row.alias}_enabled`] || false;
      exprPair = getExpr({
        expr: row.exprPair,
        columnLegend,
        prevColumnLegend,
        rowNumber,
        date: column.date,
      });
      cellExpr = wasManuallyEntered ? cellExpr : exprPair;
    }
    return {
      cellExpr,
      exprPair,
      wasManuallyEntered,
    };
  };

  const formatValue = (isPercentCell, isFirstColumn, rowNumber, row, value) => {
    let parsedValue = null;
    const { gridType } = row;
    if (isPercentCell && (!isFirstColumn || firstColumnValidateRows.includes(rowNumber))) {
      const decimalPlaces = row.dbDecimalPlaces || 2;
      parsedValue = parseValue(value, gridType, null, null, row.dbType, null);
      parsedValue = Number(parsedValue).toFixed(decimalPlaces);
      parsedValue = parsedValue.toString();
      return parsedValue;
    }
    const newLocal = parsedValue === comesFromFinancials(row.alias);
    return newLocal ? Number(value).toFixed(2) : parseValue(value, gridType, null, null, row.dbType);
  };

  columns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];
    const prevColumnLegend = alphabet[columnIndex - 1];
    const isFirstColumn = columnIndex === 0;

    rowConfig.forEach((row, index) => {
      let tmpRow = { ...row };
      const rowNumber = index + 1;
      const key = columnLegend + rowNumber;
      const expr = getExpr({
        expr: tmpRow.expr,
        columnLegend,
        prevColumnLegend,
        rowNumber,
        date: column.date,
      });
      const tmpExpr = rowWithPairs(expr, tmpRow, isFirstColumn, column, columnLegend, prevColumnLegend, rowNumber);
      const { cellExpr, exprPair, wasManuallyEntered } = tmpExpr || {};

      const auxValue = column[tmpRow.alias];
      const value = getValue(auxValue);
      const isPercentCell = isCellWithPairAndIsPercent(tmpRow);
      const parsedValue = formatValue(isPercentCell, isFirstColumn, rowNumber, tmpRow, value);

      const tmpCellIsCalculated = checkIfCellIsCalculated(isFirstColumn, tmpRow, wasManuallyEntered);

      const { className: cellClassName, isCellCalculated } = tmpCellIsCalculated || {};

      if (isFirstColumn && !firstColumnValidateRows.includes(rowNumber)) {
        tmpRow = {
          ...tmpRow,
          hidden: true,
          readOnly: true,
          isRequired: false,
          maxValue: false,
          allowNegativeValue: true,
          greaterThan: null,
          gridType: null,
          expr: '',
        };
      } else if (isFirstColumn && rowNumber === EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_ROW_NUMBER) {
        tmpRow = {
          ...tmpRow,
          isRequired: true,
          expr: cellExpr,
        };
      } else {
        tmpRow = {
          ...tmpRow,
          expr: cellExpr,
        };
      }
      const customKey
        = columnIndex === columns.length - 1 ? `col_last_${rowNumber}` : `col_${columnLegend}_${rowNumber}`;
      cells = {
        ...cells,
        [key]: {
          ...tmpRow,
          key,
          columnLegend,
          customKey,
          exprPair,
          value: parsedValue,
          alias: tmpRow.alias || '',
          isParent: column.isParent || false,
          parentColumn: column.parentColumn,
          cellNotRelated: !!(columnLegend === 'B' && rowNumber === DISCOUNT_PERIODS_ROW_NUMBER),
          cellTitle: tmpRow.value,
          isCellCalculated,
          className: cellClassName,
          defaultTooltip: isCellCalculated ? 'This cell has been calculated automatically' : null,
        },
      };
    });
  });

  return cells;
};

export default parser;
