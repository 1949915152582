import React, { useCallback, useState } from 'react';
import { Divider, FormControl, InputLabel, ListItemIcon, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { DELETE_MEASUREMENT_DATES_ENABLER } from 'common/constants/features';
import {
  DEFAULT_MAX_OPTIONS,
  MEASUREMENT_DATE_FILTER,
  SHOW_LESS,
  SHOW_LESS_ID,
  SHOW_MORE,
  SHOW_MORE_ID,
} from 'common/constants/pageFilters';
import FeatureEnabler from 'components/FeatureEnabler';
import { toString } from 'utillities';

const elementWidth = 175;

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: elementWidth,
    margin: theme.spacing(1),
  },
  label: {
    fontWeight: 600,
    fontSize: '0.75em',
    textTransform: 'uppercase',
    color: theme.palette.gray[400],
  },
  select: {
    maxWidth: elementWidth,
    color: theme.palette.gray[500],
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addIcon: {
    marginTop: '-1px',
  },
  listItemIcon: {
    minWidth: theme.spacing(5),
  },
  deleteOption: {
    color: theme.palette.error.pink,
  },
  showMoreLess: {
    fontSize: '0.875rem',
    color: theme.palette.gray[400],
  },
}));

const Filter = ({
  id: filterId,
  label,
  options,
  addOption,
  deleteOption,
  handleFilter,
  selectedValue,
  hideActions = false,
}) => {
  const [showAll, setShowAll] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const renderValue = useCallback(
    value => {
      const currentOption = options.find(o => toString(o.id) === toString(value));

      if (!isEmpty(options) && currentOption) {
        return currentOption.label ?? currentOption.name;
      }

      return `No ${label.toLowerCase()}s available`;
    },
    [label, options]
  );

  if (isUndefined(options)) {
    return <Skeleton variant="text" className={classes.skeleton} width={150} height={50} />;
  }

  let displayOptions = [...options];
  if (filterId === MEASUREMENT_DATE_FILTER && options.length > DEFAULT_MAX_OPTIONS) {
    if (showAll) {
      displayOptions = [...displayOptions, { name: SHOW_LESS, id: SHOW_LESS_ID }];
    } else {
      displayOptions = [...displayOptions.slice(0, DEFAULT_MAX_OPTIONS), { name: SHOW_MORE, id: SHOW_MORE_ID }];
    }
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`${filterId}-label`} className={classes.label} shrink>
        {label}
      </InputLabel>
      <Select
        id={filterId}
        className="filter"
        value={selectedValue}
        onChange={handleFilter}
        classes={{
          root: classes.select,
        }}
        renderValue={renderValue}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        displayEmpty>
        <MenuItem selected disabled value=" ">
          Select a {label}
        </MenuItem>
        {displayOptions.map(({ name, id }, index) => (
          <MenuItem
            value={id.toString()}
            key={id}
            id={`${filterId}-option-${index + 1}`}
            onClick={() => {
              if (id === SHOW_MORE_ID) {
                setShowAll(true);
                setOpen(true);
              } else if (id === SHOW_LESS_ID) {
                setShowAll(false);
                setOpen(true);
              }
            }}
            className={clsx(id === SHOW_MORE_ID || id === SHOW_LESS_ID ? classes.showMoreLess : '')}>
            {name}
          </MenuItem>
        ))}
        {addOption && !hideActions && (
          <div>
            <Divider className={classes.divider} />
            <MenuItem onClick={addOption}>
              <ListItemIcon className={classes.listItemIcon}>
                <AddIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Add {label}</Typography>
            </MenuItem>
          </div>
        )}
        {deleteOption && !hideActions && (
          <div>
            <FeatureEnabler enabler={DELETE_MEASUREMENT_DATES_ENABLER} hideComponent>
              <>
                <Divider className={classes.divider} />
                <MenuItem onClick={deleteOption} className={classes.deleteOption}>
                  <ListItemIcon className={clsx(classes.listItemIcon, classes.deleteOption)}>
                    <RemoveIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Delete {label}</Typography>
                </MenuItem>
              </>
            </FeatureEnabler>
          </div>
        )}
      </Select>
    </FormControl>
  );
};

Filter.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addOption: PropTypes.func,
  deleteOption: PropTypes.func,
  handleFilter: PropTypes.func,
  selectedValue: PropTypes.number,
  id: PropTypes.string,
  hideActions: PropTypes.bool,
};

Filter.defaultProps = {
  label: '',
  addOption: undefined,
  selectedValue: ' ',
};

export default Filter;
