import {
  VALUATIONS_PERCENTILE_PATTERN as patterns,
  SELECTION_A,
  SELECTION_B,
  VALUATIONS_OTHER_LABEL,
} from 'common/constants/valuations';
import {
  PERCENTILE_25,
  PERCENTILE_75,
  SELECTED_DATABASE_VALUE,
  SPECIFIED_LABEL,
  VALUES_OPTIONS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { matchPattern } from 'pages/ValuationsAllocation/util';
import { SEVENTY_FIVE_STRING, TWENTY_FIVE_STRING } from './constants';

export const determineDatabaseOption = ({ options, value }, specificApproach) => {
  // handle different name between frontend label and database value
  const selection = parseInt(value, 10);
  // index of the penultimate option, which is the specified value
  const penultimateOption = options.length - 2;
  const isSpecifiedValue = selection > VALUES_OPTIONS.length || selection === penultimateOption;

  if (Number.isNaN(selection)) return null;

  if (options.length === VALUES_OPTIONS.length || [0, 1, penultimateOption].includes(selection)) {
    return isSpecifiedValue ? SELECTED_DATABASE_VALUE : VALUES_OPTIONS[selection];
  }

  if (selection === options.length - 1) return SELECTED_DATABASE_VALUE;
  // order and amount in frontend != order and amount in backend options
  const currentSelectionA = specificApproach.percentile_selection_a;
  const currentSelectionB = specificApproach.percentile_selection_b;
  const indexedOption = options[selection];
  const matchedPattern = matchPattern({
    label: indexedOption,
    patterns,
  });
  const pickedPercentile = indexedOption.split(matchedPattern)[0];
  const optionsMap = new Map([
    [currentSelectionA.toString(), SELECTION_A],
    [currentSelectionB.toString(), SELECTION_B],
    [TWENTY_FIVE_STRING, PERCENTILE_25],
    [SEVENTY_FIVE_STRING, PERCENTILE_75],
  ]);
  return optionsMap.get(pickedPercentile);
};

const getAdHocOptionLabel = (option, specificApproach) => {
  if (option === SELECTED_DATABASE_VALUE) return SPECIFIED_LABEL;
  const validFixedoptions = [...VALUES_OPTIONS, VALUATIONS_OTHER_LABEL];
  if (validFixedoptions.includes(option)) return option;
  const availablePercentiles = {
    [specificApproach.percentile_selection_a]: SELECTION_A,
    [specificApproach.percentile_selection_b]: SELECTION_B,
  };
  const matchedPattern = matchPattern({
    label: option,
    patterns,
  });
  if (matchedPattern) return availablePercentiles[option.split(matchedPattern.toString())[0]];
};

export const mapVariableLabelsToSelectionIndex = ({ options, selection, specificApproach }) => {
  // if there isn't a benchmark approach, we only display 'Other' as choice => index = 0
  if (options.length === 1) return 0;

  const labelIndexPair = options.map((option, index) => {
    const adHocLabel = getAdHocOptionLabel(option, specificApproach);
    return [adHocLabel, index];
  });
  const optionsMap = new Map(labelIndexPair);
  const selected = selection === SELECTED_DATABASE_VALUE ? SPECIFIED_LABEL : selection;
  return optionsMap.get(selected) ?? null;
};
