import React, { useContext, useEffect, useState } from 'react';
import { Box, Chip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { isEmpty } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CompGroupDialogContext from 'pages/Valuations/components/CompGroups/components/common/CompGroupDialogContext';
import FormLabelCompGroup from 'pages/Valuations/components/CompGroups/components/common/FormLabelCompGroup';
import theme from 'theme';

const Companies = () => {
  const {
    currentCompGroups,
    currentComparisons,
    compGroupSelected,
    setCompGroupSelected,
    setCompaniesToDelete,
    setCompGroupToDelete,
    setValid,
    isGPC,
  } = useContext(CompGroupDialogContext);
  const [allowedCompanies, setAllowedCompanies] = useState([]);

  const findPTMatch = (currentItem, newItem) =>
    currentItem.target_name === newItem.target_name && currentItem.acquirer_name === newItem.acquirer_name;

  const gpcFiltered = (gpcData, comp, matchDistinct = true) =>
    gpcData.filter(item => {
      if (matchDistinct) return item.cap_iq_id !== comp.cap_iq_id;
      return item.cap_iq_id === comp.cap_iq_id;
    });
  const ptFiltered = (ptData, comp, matchDistinct = true) =>
    ptData.filter(current => {
      if (matchDistinct) return !findPTMatch(current, comp);
      return findPTMatch(current, comp);
    });

  const setCompaniesToBeRemoved = comp => {
    const comparisonsData = isGPC
      ? gpcFiltered(currentComparisons, comp, false)
      : ptFiltered(currentComparisons, comp, false);
    const matchCompanyKeys = comparisonsData.map(item => item.id || item.temp_id);
    setCompaniesToDelete(currentState => [...new Set([...currentState, ...matchCompanyKeys])]);
  };

  const setCompGroupsToBeRemoved = () => {
    const matchCompGroup = currentCompGroups.find(compGroupItem => compGroupItem.comp_group === compGroupSelected.id);
    if (matchCompGroup) {
      setCompGroupToDelete(matchCompGroup.comp_group);
    }
  };

  const updateCompSelected = comp => {
    const currentComps = compGroupSelected.companies;
    const currentCompGroupInfo = {
      ...compGroupSelected,
      companies: isGPC ? gpcFiltered(currentComps, comp) : ptFiltered(currentComps, comp),
      use_latest_comp_group_version: false,
    };

    setCompGroupSelected(currentCompGroupInfo);
  };

  const handleDelete = async comp => {
    const newAllowedCompanies = allowedCompanies.filter(item => item.id !== comp.id);
    setCompaniesToBeRemoved(comp);
    setCompGroupsToBeRemoved();
    setAllowedCompanies(newAllowedCompanies);
    updateCompSelected(comp);
    setValid(!isEmpty(newAllowedCompanies));
  };

  useEffect(() => {
    if (compGroupSelected.companies) {
      setAllowedCompanies(compGroupSelected.companies);
    } else {
      setAllowedCompanies([]);
    }
  }, [compGroupSelected.companies, currentComparisons]);

  const getName = comp => {
    if (isGPC) return comp.company_name;
    return `${comp.acquirer_name} | ${comp.target_name}`;
  };

  return (
    <div>
      <FormLabelCompGroup title={isGPC ? 'Companies' : 'Transaction'} />
      <Box width="100%" sx={{ borderRadius: 4, border: `1px solid ${theme.palette.gray[300]}`, minHeight: '8rem' }}>
        <div style={{ padding: '1rem', overflowX: 'auto' }}>
          <PerfectScrollbar>
            {allowedCompanies?.map(comp => (
              <Chip
                key={comp.id}
                color="primary"
                variant="outlined"
                label={getName(comp)}
                size="small"
                deleteIcon={<ClearIcon />}
                onDelete={() => handleDelete(comp)}
                style={{ marginRight: '1rem', marginBottom: '1rem' }}
              />
            ))}
          </PerfectScrollbar>
        </div>
      </Box>
    </div>
  );
};

export default Companies;
