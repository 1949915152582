import React, { FC, useContext, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty, isNil, isUndefined } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { companiesAction, fundsAction } from 'common/actions';
import { firmSummaryUrl, fundScheduleOfInvestmentsUrl, fundSummaryUrl } from 'common/config/urls';
import {
  FUNDS_INVESTMENTS_SLUG,
  FUNDS_INVESTMENTS_TITLE,
  FUNDS_SUMMARY_SLUG,
  FUNDS_SUMMARY_TITLE,
  FUNDS_TITLE,
} from 'common/constants/funds';
import { EXCEL_EXPORT } from 'common/constants/pageActions';
import { useStore } from 'common/store';
import { LayoutContextValues, NavItems, UseStoreValues } from 'common/types/store';
import { LoadingSection } from 'components';
import { ExtendedFabButton } from 'components/Buttons';
import { LayoutContext } from 'context';
import { useGetCompaniesWithOwnershipByFund } from 'services/hooks/funds';
import { useDialogFormsStore } from 'store';
import { capitalizeWords, getBooleanValue, getObjectValue, getStringValue, handleCatchError } from 'utillities';
import { NoMeasurementDatesBox } from './components';
import { useFundMeasurementDateFilter } from './hooks';
import { FundSummary, ScheduleOfInvestments, ValuationsSummary } from './pages';
import useStyles from './styles';
import { FundsParams } from './types';

const Funds: FC = () => {
  const {
    setNavItems,
    setPageBreadcrumbs,
    setPageTitle,
    toggleExportDialog,
    updateExtraPageActions,
    setCurrentMeasurementDate,
    viewOnlyUser,
  } = useContext(LayoutContext) as unknown as LayoutContextValues;

  const [storeValue, dispatch] = useStore() as unknown as UseStoreValues;
  const { firmInfo, fundInfo, fundList, isRestrictedUser, isShowLoadingProgress } = storeValue;

  const classes = useStyles();

  const history = useHistory();

  const { firmSlugParam, fundSlugParam, tableSlugParam } = useParams<FundsParams>();

  // Dialog Forms Store
  const setDisplayFundDialogForm = useDialogFormsStore(state => state.setDisplayFundDialogForm);

  const activeFund = useMemo(() => {
    if (!isEmpty(fundInfo) && isRestrictedUser) {
      return fundInfo;
    }

    if (fundSlugParam && !isEmpty(fundList)) {
      const currentFund = fundList?.find(fund => fund.slug === fundSlugParam) ?? null;

      return currentFund;
    }

    return null;
  }, [fundInfo, fundList, fundSlugParam, isRestrictedUser]);

  const { activeMeasurementDate, MeasurementDateModal, measurementDates, openMeasurementDateModal }
    = useFundMeasurementDateFilter({
      activeFund,
    });

  const { data: companiesWithOwnershipByFund, refetch: getCompaniesWithOwnershipByFund }
    = useGetCompaniesWithOwnershipByFund({ fund: activeFund, shouldQueryAutomatically: false });

  // Pages / Tabs
  const MemoizedSelectedPage = useMemo(() => {
    switch (tableSlugParam) {
      case FUNDS_SUMMARY_SLUG:
        return (
          <>
            {!isEmpty(activeFund) && !isEmpty(activeMeasurementDate) && (
              <>
                <Grid className={classes.sectionContainer}>
                  <FundSummary fund={activeFund} measurementDate={activeMeasurementDate} />
                </Grid>

                <Grid className={classes.sectionContainer}>
                  <ValuationsSummary fund={activeFund} measurementDate={activeMeasurementDate} />
                </Grid>
              </>
            )}
          </>
        );

      case FUNDS_INVESTMENTS_SLUG:
        return (
          <>
            {!isEmpty(activeFund) && !isEmpty(activeMeasurementDate) && (
              <Grid className={classes.sectionContainer}>
                <ScheduleOfInvestments fund={activeFund} measurementDate={activeMeasurementDate} />
              </Grid>
            )}
          </>
        );

      default:
        return null;
    }
  }, [tableSlugParam, activeFund, activeMeasurementDate, classes.sectionContainer]);

  const queryParamsMD = useMemo(
    () => (activeMeasurementDate?.slug ? `?date=${activeMeasurementDate?.slug}` : ''),
    [activeMeasurementDate]
  );

  const fundMenuItems = useMemo(
    () =>
      [
        // Fund Summary
        {
          callback: () =>
            history.push(
              `${fundSummaryUrl(getStringValue(firmSlugParam), getStringValue(fundSlugParam))}${queryParamsMD}`
            ),
          id: FUNDS_SUMMARY_SLUG,
          itemKey: 0,
          slug: FUNDS_SUMMARY_SLUG,
          title: FUNDS_SUMMARY_TITLE,
        },
        // Schedule of Investments
        {
          callback: () =>
            history.push(
              `${fundScheduleOfInvestmentsUrl(
                getStringValue(firmSlugParam),
                getStringValue(fundSlugParam)
              )}${queryParamsMD}`
            ),
          id: FUNDS_INVESTMENTS_SLUG,
          itemKey: 1,
          slug: FUNDS_INVESTMENTS_SLUG,
          title: FUNDS_INVESTMENTS_TITLE,
        },
      ] as NavItems[],

    [firmSlugParam, fundSlugParam, history, queryParamsMD]
  );

  const fundsBreadcrumbs = useMemo(() => {
    if (firmInfo) {
      const { name, slug } = firmInfo;
      const { name: fundName } = getObjectValue(activeFund);

      return name && slug && tableSlugParam && fundName
        ? [
          {
            title: name,
            url: firmSummaryUrl(slug),
          },
          {
            title: FUNDS_TITLE,
          },
          {
            title: fundName,
          },
          {
            title: capitalizeWords(tableSlugParam),
          },
        ]
        : [];
    }

    return [];
  }, [activeFund, firmInfo, tableSlugParam]);

  // Set Fund page title and Fund info
  useEffect(() => {
    if (activeFund) {
      setPageTitle?.(getStringValue(activeFund?.name));

      dispatch?.(fundsAction.setFundInfo(activeFund));
    }

    return () => {
      setPageTitle?.(null);

      dispatch?.(fundsAction.setFundInfo(null));
    };
  }, [activeFund, dispatch, setPageTitle]);

  // Set Fund page breadcrumbs
  useEffect(() => {
    setPageBreadcrumbs?.(fundsBreadcrumbs);

    return () => setPageBreadcrumbs?.(null);
  }, [fundsBreadcrumbs, setPageBreadcrumbs]);

  // Set Fund navigation menu items
  useEffect(() => {
    setNavItems?.(fundMenuItems);

    return () => setNavItems?.(null);
  }, [fundMenuItems, setNavItems]);

  // Set Measurement Date by Fund (currentMeasurementDateByFund)
  useEffect(() => {
    dispatch?.(fundsAction.setMeasurementDateByFund(!isEmpty(activeMeasurementDate) ? activeMeasurementDate : null));

    return () => dispatch?.(fundsAction.setMeasurementDateByFund(null));
  }, [dispatch, activeMeasurementDate]);

  // When the selected measurement date changes
  useEffect(() => {
    if (setCurrentMeasurementDate) setCurrentMeasurementDate(activeMeasurementDate);
  }, [activeMeasurementDate, setCurrentMeasurementDate]);

  useEffect(() => {
    const extraActions = [
      {
        label: EXCEL_EXPORT,
        callback: toggleExportDialog,
        isActive: true,
      },
    ];

    if (!isUndefined(updateExtraPageActions)) updateExtraPageActions(extraActions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFund, updateExtraPageActions]);

  // If User is restricted (have access to only a specific Fund or particular Company), get Companies by Fund
  useEffect(() => {
    if (activeFund && !isNil(activeFund?.id) && isRestrictedUser)
      getCompaniesWithOwnershipByFund().catch(handleCatchError);
  }, [activeFund, getCompaniesWithOwnershipByFund, isRestrictedUser]);

  // Set Companies with Ownership by Fund (triggered when User is restricted)
  useEffect(() => {
    if (isRestrictedUser) dispatch?.(companiesAction.setCompanyList(companiesWithOwnershipByFund));
  }, [companiesWithOwnershipByFund, dispatch, isRestrictedUser]);

  const handleAddFund = () => setDisplayFundDialogForm(true);

  // Loading Component if Measurement Dates are not loaded
  if (!measurementDates) return <LoadingSection />;

  // No Measurement Dates for this fund
  if (measurementDates && isEmpty(measurementDates)) {
    return (
      <NoMeasurementDatesBox
        openMeasurementDateModal={openMeasurementDateModal}
        MeasurementDateModal={MeasurementDateModal}
      />
    );
  }

  return (
    <>
      {/* Render selected Fund page (Summary or Investments) */}
      {MemoizedSelectedPage}

      {/* New Fund Button */}
      <ExtendedFabButton
        disabled={getBooleanValue(isShowLoadingProgress) || viewOnlyUser}
        id="fund-add-fund-btn"
        label="New Fund"
        onClick={handleAddFund}
      />

      {/* Measurement Date Modal */}
      <MeasurementDateModal />
    </>
  );
};
export default Funds;
