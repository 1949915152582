import React, { useContext } from 'react';
import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import { ContactSupportOutlined as ContactSupportOutlinedIcon, Lock as LockIcon } from '@material-ui/icons';
import { FOOTER } from 'common/constants/documents';
import {
  CONTACT_US,
  CONTACT_US_LINK,
  POWERED_BY,
  PRIVACY_POLICY,
  PRIVACY_POLICY_LINK,
  SECURE_PAGE,
} from 'common/constants/process-management';
import { Logo } from 'components';
import { TaskContext } from 'context';
import theme from 'theme';
import IconAndText from './IconAndText';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '0.3rem',
    width: '1.2rem',
  },
  text: {
    color: theme.palette.primary[500],
  },
});

const ProcessManagementFooter = () => {
  const { task, isMobile } = useContext(TaskContext);
  const classes = useStyles(isMobile);

  return (
    <Box display="flex" justifyContent="center" marginTop={'3rem'}>
      <Box className={classes.root} justifyContent={isMobile ? 'center' : 'space-between'}>
        <Box display="flex" alignItems={isMobile ? 'center' : 'start'} flexDirection={'column'}>
          <Box display="flex" flexDirection={isMobile ? 'row' : 'column'}>
            <Link href={CONTACT_US_LINK}>
              <IconAndText icon={<ContactSupportOutlinedIcon className={classes.icon} />} text={CONTACT_US} />
            </Link>
            <IconAndText
              icon={<LockIcon className={classes.icon} />}
              text={SECURE_PAGE}
              tooltip={FOOTER(task?.task.firm.name)}
            />
          </Box>
          <Box display="flex" justifyContent="center" paddingBottom={isMobile ? '0.5rem' : ''}>
            {isMobile && (
              <Link href={PRIVACY_POLICY_LINK}>
                <Typography className={classes.text}>{PRIVACY_POLICY}</Typography>
              </Link>
            )}
          </Box>
        </Box>
        {!isMobile && (
          <Box display="flex" alignItems="end" flexDirection="column">
            <Link href={PRIVACY_POLICY_LINK}>
              <Typography className={classes.text}>{PRIVACY_POLICY}</Typography>
            </Link>
            <Box display="flex" alignItems="center">
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                style={{ marginRight: '0.5rem', fontWeight: 'bold' }}>
                {POWERED_BY}
              </Typography>
              <Logo variation="onlyWord" lightBlue customWidth="45" />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProcessManagementFooter;
