import React from 'react';
import PropTypes from 'prop-types';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';

const CapTableSelection = ({ spreadsheets, onChange, workbook }) => {
  const { capTableSelectionSheet } = spreadsheets;

  return (
    <ScalarSpreadsheet
      {...capTableSelectionSheet}
      id="cap-table-selection"
      key={capTableSelectionSheet.name}
      onChange={onChange}
      sheet={capTableSelectionSheet}
      isToolbarVisible
      workbook={workbook}
      disableVerticalScroll
    />
  );
};

CapTableSelection.propTypes = {
  spreadsheets: PropTypes.object,
  onChange: PropTypes.func,
  workbook: PropTypes.object,
};

export default CapTableSelection;
