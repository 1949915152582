/* eslint-disable @typescript-eslint/no-explicit-any */
import { oneDecimalPercentFormatValidateFloatTrueWithNegativeValues } from 'common/formats/formats';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import {
  TABLE_FIFTH_TITLE,
  TABLE_FOURTH_TITLE,
  TABLE_SECOND_TITLE,
  TABLE_SIXTH_TITLE,
  TABLE_THIRD_TITLE,
  TABLE_TITLE,
} from './rowConfig';

export default async ({ columns, rowConfig }: any) => {
  let cells = {};
  const alphabet = alphabetGenerator([], columns.length);

  rowConfig.forEach((row: any, rowIdx: any) => {
    columns.forEach((col: any, colIdx: any) => {
      const rowNumber = rowIdx + 1;
      const columnLegend = alphabet[colIdx];
      const key = columnLegend + rowNumber;

      let value = col[row.alias];

      if (rowNumber === 1 || row.alias === TABLE_TITLE) {
        switch (colIdx) {
          case 0:
            value = TABLE_SECOND_TITLE;
            break;
          case 1:
            value = TABLE_THIRD_TITLE;
            break;
          case 2:
            value = TABLE_FOURTH_TITLE;
            break;
          case 3:
            value = TABLE_FIFTH_TITLE;
            break;
          case 4:
            value = TABLE_SIXTH_TITLE;
            break;
          default:
            value = '';
            break;
        }
      }

      let { format } = row;

      if (colIdx >= 1) {
        format = oneDecimalPercentFormatValidateFloatTrueWithNegativeValues;
      }

      let { expr } = row;

      if (expr?.match(/@@/g)) {
        expr = expr.replace(/@@/g, `${columnLegend}`);
      }

      cells = {
        ...cells,
        [key]: {
          ...row,
          key,
          expr,
          rowSpan: 1,
          columnLegend,
          value,
          format,
        },
      };
    });
  });

  return cells;
};
