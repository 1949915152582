import API from '../config';

const base = `${API.BASE_DEV_HOST}/api`;
const userBaseUrl = `${base}/user`;

export const getUsers = () => `${base}/users`;
export const create = () => `${base}/profiles/`;
export const savePermissions = () => `${base}/users/permissions`;
export const userPermissions = (userId, firmId) => `${userBaseUrl}/${userId}/permissions/${firmId}`;
export const lowerUserPermissions = userId => `${userBaseUrl}/${userId}/lower-permissions`;
export const userProfileById = userId => `${base}/profiles/${userId}`;
export const updateUserProfile = userId => `${base}/profiles/${userId}`;
export const deleteUser = userId => `${userBaseUrl}/${userId}/delete`;
export const createBulkUsers = () => `${base}/users/create-bulk-users`;

export default getUsers;
