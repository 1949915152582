import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const IconTextField = ({ iconStart, iconEnd, InputProps, ...props }) => (
  <TextField
    {...props}
    InputProps={{
      ...InputProps,
      startAdornment: iconStart ? <InputAdornment position="start">{iconStart}</InputAdornment> : null,
      endAdornment: iconEnd ? <InputAdornment position="end">{iconEnd}</InputAdornment> : null,
    }}
  />
);

IconTextField.propTypes = {
  iconStart: PropTypes.element,
  iconEnd: PropTypes.element,
  InputProps: PropTypes.object,
};

export default IconTextField;
