import React, { FC } from 'react';
import { Avatar, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useStyles } from './styles';

const BottomMenuItemSkeleton: FC = () => {
  const classes = useStyles();

  return (
    <ListItem>
      <ListItemIcon>
        <Skeleton variant="circle" className={classes.skeleton}>
          <Avatar className={classes.avatar} />
        </Skeleton>
      </ListItemIcon>

      <ListItemText
        primary={<Skeleton className={classes.skeleton} />}
        secondary={
          <Typography className={classes.secondaryLink}>
            <Skeleton className={classes.skeleton} />
          </Typography>
        }
        primaryTypographyProps={{ className: classes.bottomMenuItemPrimaryText }}
        secondaryTypographyProps={{ className: classes.bottomMenuItemSecondaryText }}
      />
    </ListItem>
  );
};

export default BottomMenuItemSkeleton;
