const validMIMETypes = [
  'application/x-cfb',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'csv',
  'txt',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/png',
  'image/jpeg',
  'image/gif',
  'application/pdf',
  'application/rtf',
  'application/ppt',
  'application/zip',
  'application/vnd.apple.keynote',
  'application/vnd.apple.numbers',
  'application/vnd.apple.pages'
];

export default validMIMETypes;
