import { isNull } from 'lodash';
import * as constants from './constants';

const afterCellChanged = (changes, cells) => {
  const getExpr = (exr, columnLegend) => exr.replace(/@/g, columnLegend);

  const updateCell = ({ cell, isCalculated }) => {
    const { exprPair, columnLegend } = cell;
    const cellExprPair = isCalculated ? getExpr(exprPair, columnLegend) : '';
    changes.push({ cell, value: cellExprPair });
  };

  const setFirstTimeConfigCellPairs = columnLegend => {
    // The default should be that the Next Round PreMoney is shown as editable
    // and the Conversion PreMoney is shown as read-only.
    const nextRoundPremoneyKey = `${columnLegend}${constants.NEXT_ROUND_PREMONEY_ROW}`;
    const nextRoundPremoneyCell = cells[nextRoundPremoneyKey];
    updateCell({ cell: nextRoundPremoneyCell, isCalculated: false });

    const conversionPreMoneyKey = `${columnLegend}${constants.CONVERSION_PRE_MONEY_ROW}`;
    const conversionPreMoneyCell = cells[conversionPreMoneyKey];
    updateCell({ cell: conversionPreMoneyCell, isCalculated: true });

    return changes;
  };

  const isEquityConversionModelPreferredOrCommon = ({ cell, value }) => {
    const { columnLegend } = cell;
    let equityConversionModelValue = null;
    if (cell.alias === constants.EQUITY_CONVERSION_MODEL_ALIAS) {
      equityConversionModelValue = value;
    } else {
      const equityConversionModelKey = `${columnLegend}${constants.EQUITY_CONVERSION_MODEL_ROW}`;
      const equityConversionModelCell = cells[equityConversionModelKey];
      equityConversionModelValue = equityConversionModelCell.value || '';
    }
    return [constants.PREFERRED_STOCK_VALUE.toString(), constants.COMMON_STOCK_VALUE.toString()].includes(
      equityConversionModelValue.toString()
    );
  };

  const checkIfIsConversionCapValid = ({ cell, value: newValue }) => {
    const { alias, columnLegend, value: currentValue } = cell;
    const isConversionCapChanging = alias === constants.CONVERSION_CAP_ALIAS;
    if (isConversionCapChanging) {
      return isNull(currentValue) && newValue > 0;
    }
    const isEquityConversionModelChanging = alias === constants.EQUITY_CONVERSION_MODEL_ALIAS;
    if (isEquityConversionModelChanging) {
      const isEquityConversionModelChangingFirstTime
        = (isNull(currentValue) || currentValue === constants.LOAN_VALUE) && newValue > 0;
      if (isEquityConversionModelChangingFirstTime) {
        const conversionCapKey = `${columnLegend}${constants.CONVERSION_CAP_ROW}`;
        const conversionCapCell = cells[conversionCapKey];
        return conversionCapCell.value > 0;
      }
      return false;
    }

    return false;
  };

  changes.forEach(change => {
    const { cell } = change;
    const { rowNumberPair, columnLegend } = cell;
    const isValidEquityConversionModel = isEquityConversionModelPreferredOrCommon(change);
    const isConversionCapValid = checkIfIsConversionCapValid(change);
    // If equity conversion model is preferred or common
    if (isValidEquityConversionModel) {
      // If conversion cap is valid and has change by first time
      if (isConversionCapValid) {
        setFirstTimeConfigCellPairs(columnLegend);
      } else if (rowNumberPair) {
        // Update pair cell
        // Update pair cell with expr
        const pairCellKey = `${columnLegend}${rowNumberPair}`;
        const pairCell = cells[pairCellKey];
        updateCell({ cell: pairCell, isCalculated: true });
      }
    }
    if (
      change.cell.alias === constants.EQUITY_CONVERSION_MODEL_ALIAS
      && change.value.toString() === constants.LOAN_VALUE.toString()
    ) {
      const loanValueKey = `${columnLegend}${constants.LOAN_VALUE_ROW}`;
      const principleAmountKey = `${columnLegend}${constants.NOTE_PRINCIPLE_AMOUNT_ROW}`;
      changes.push({ cell: cells[loanValueKey], value: cells[principleAmountKey].value });
    }
  });

  return changes;
};

export default afterCellChanged;
