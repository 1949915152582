import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { PROCESS_MANAGEMENT, REQUEST_DESCRIPTION, REQUEST_TITLE } from 'common/constants/firms';
import theme from 'theme';

const FirmRequestsHeader = ({ name }) => (
  <>
    <Typography
      gutterBottom
      style={{
        fontWeight: 400,
        fontSize: '0.75rem',
        color: theme.palette.gray[400],
      }}>
      {PROCESS_MANAGEMENT}
    </Typography>
    <Typography
      style={{
        fontWeight: 700,
        fontSize: '1.375rem',
        color: theme.palette.black[300],
        marginBottom: '1rem',
      }}>
      {REQUEST_TITLE(name)}
    </Typography>
    <Typography
      gutterBottom
      style={{
        fontWeight: 400,
        fontSize: '0.875rem',
        color: theme.palette.gray[400],
        width: '50%',
      }}>
      {REQUEST_DESCRIPTION}
    </Typography>
  </>
);

FirmRequestsHeader.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FirmRequestsHeader;
