export const SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS = false;

// Loading
export const SERVICES_AN_ERROR_OCCURRED_LOADING = (title: string) => `An error occurred while loading the ${title}`;

// Checking
export const SERVICES_AN_ERROR_OCCURRED_CHECKING = (title: string) => `An error occurred while checking for ${title}`;

// Creating
export const SERVICES_AN_ERROR_OCCURRED_CREATING = (title: string) => `An error occurred while creating the ${title}`;
export const SERVICES_SUCCESSFULLY_CREATED = (title: string) => `${title} created successfully`;

// Updating
export const SERVICES_AN_ERROR_OCCURRED_UPDATING = (title: string) => `An error occurred while updating the ${title}`;
export const SERVICES_SUCCESSFULLY_UPDATED = (title: string) => `${title} updated successfully`;

// Deleting
export const SERVICES_AN_ERROR_OCCURRED_DELETING = (title: string) => `An error occurred while deleting the ${title}`;
export const SERVICES_SUCCESSFULLY_DELETED = (title: string) => `${title} deleted successfully`;

// Publishing
export const SERVICES_AN_ERROR_OCCURRED_PUBLISHING = (title: string) =>
  `An error occurred while publishing the ${title}`;
export const SERVICES_SUCCESSFULLY_PUBLISHED = (title: string) => `${title} published successfully`;
