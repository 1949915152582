import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    card: {
      display: 'inline-block',
      margin: theme.spacing(1),
      minWidth: 150,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    title: {
      fontSize: theme.spacing(1.75),
      textTransform: 'uppercase',
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 2,
      margin: `${theme.spacing(0.5)}px auto`,
    },
    value: {
      fontWeight: 600,
      fontSize: theme.spacing(2.25),
      marginBottom: 0,
    },
    skeleton: {
      height: 21,
    },
  })
);

export default useStyles;
