import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LoadingSection } from 'components';
import { dashboardCompanySettingsUrl } from 'dashboard409a/common/config/urls';
import { ERROR_UPDATING_COMPANY_DETAILS, SUCCESS_UPDATING_COMPANY_DETAILS } from 'dashboard409a/common/constants';
import {
  COMPANY_SETTINGS_ID,
  SUBSCRIPTION_ID,
  USER_MANAGEMENT_ID,
} from 'dashboard409a/common/constants/layouts/companySettings';
import { CompanySettings, ISavedSettings } from 'dashboard409a/components';
import SettingsMenuButtons from 'dashboard409a/components/CompanySettings/components/SettingsMenuButtons';
import UserManagement from 'dashboard409a/components/UserManagement/UserManagement';
import { IGetCompanyDetailsResponse } from 'dashboard409a/services/types';
import { updateCompanyDetails } from 'dashboard409a/states/features/company';
import { AppDispatchType, RootStateType } from 'dashboard409a/states/store';
import { useDisplaySnack } from 'dashboard409a/utilities';
import { useGetUserInfo } from 'services/hooks/auth';
import { Subscription } from '../Subscription';

const Settings: FC = () => {
  const dispatch = useDispatch<AppDispatchType>();

  const displaySnack = useDisplaySnack();

  const { currentCompany } = useSelector((state: RootStateType) => state.global);
  const { companyDetails } = useSelector((state: RootStateType) => state.company);

  const history = useHistory();

  // todo: get from store
  const getUserInfo = useGetUserInfo();

  const [componentToRenderId, setComponentToRenderId] = useState<number>(1);

  useEffect(() => {
    history.push(window.location.pathname);
  }, [history]);

  const onSave = async (formData: ISavedSettings) =>
    new Promise<void>((resolve, reject) => {
      if (companyDetails) {
        const { name, address, phoneNumber, website, logo, icon } = formData.companySettings;

        const updatedCompanyDetails: IGetCompanyDetailsResponse = {
          ...companyDetails,
          name,
          address,
          phone_number: phoneNumber,
          website,
          icon_url: icon,
          logo_url: logo,
        };

        const nameHasChanged = name !== companyDetails.name;

        dispatch(updateCompanyDetails(updatedCompanyDetails))
          .then(async response => {
            if (response.meta.requestStatus === 'fulfilled') {
              await getUserInfo();

              if (nameHasChanged) {
                const newResponseData = response.payload as IGetCompanyDetailsResponse;
                history.push(dashboardCompanySettingsUrl(newResponseData.slug ?? ''));
              }

              displaySnack(SUCCESS_UPDATING_COMPANY_DETAILS, 'success');
            }

            if (response.meta.requestStatus === 'rejected') displaySnack(ERROR_UPDATING_COMPANY_DETAILS, 'error');

            resolve();
          })
          .catch(() => {
            displaySnack(ERROR_UPDATING_COMPANY_DETAILS, 'error');

            reject();
          });
      }
    });

  if (!companyDetails) return <LoadingSection />;

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Typography variant="h2" gutterBottom>
          Settings
        </Typography>
        <SettingsMenuButtons
          componentToRenderId={componentToRenderId}
          setComponentToRenderId={setComponentToRenderId}
        />
      </Box>
      {componentToRenderId === COMPANY_SETTINGS_ID && (
        <CompanySettings
          data={{
            id: currentCompany?.id ?? 0,
            companySettings: {
              address: companyDetails?.address ?? '',
              icon: companyDetails?.icon_url ?? '',
              logo: companyDetails?.logo_url ?? '',
              name: companyDetails?.name ?? '',
              phoneNumber: companyDetails?.phone_number ?? '',
              website: companyDetails?.website ?? '',
            },
          }}
          onSave={onSave}
        />
      )}
      {componentToRenderId === USER_MANAGEMENT_ID && <UserManagement />}
      {componentToRenderId === SUBSCRIPTION_ID && <Subscription />}
    </>
  );
};

export default Settings;
