import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Grid, IconButton, Paper, Tab, Tabs } from '@material-ui/core';
import { CloudDownloadOutlined, ShareOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { useFindSlug } from 'dashboard409a/utilities';
import useStyles from './styles';
import { INavigation } from './types';

const Navigation: FC<INavigation> = props => {
  const { items, handleDownload, handleShare } = props;

  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();

  const currentSlug = useFindSlug();

  useEffect(() => {
    const slugIndex = items.findIndex(item => item.id === currentSlug);

    setSelectedTab(slugIndex >= 0 ? slugIndex : 0);
  }, [currentSlug, items]);

  const handleChange = (_event: ChangeEvent<Record<string, unknown>>, selected: number) => {
    items[selected].handleAction();
    setSelectedTab(selected);
  };

  return (
    <Paper square elevation={0} className={clsx(classes.navigation)}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        className={clsx(classes.container)}>
        <Grid item className={clsx(classes.tabsContainer)}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            scrollButtons="on"
            className={classes.tabsItems}>
            {items.map((tabItem, tabIndex) => (
              <Tab
                className={clsx(classes.tabItem, { [classes.selected]: selectedTab === tabIndex })}
                disabled={tabItem?.disabled ?? false}
                key={`tab-${tabItem.id}`}
                label={tabItem.title}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item className={clsx(classes.navChildren)}>
          {handleDownload && (
            <IconButton aria-controls="share" aria-label="more" onClick={handleDownload}>
              <CloudDownloadOutlined fontSize="small" />
            </IconButton>
          )}

          {handleShare && (
            <IconButton aria-controls="share" aria-label="more" onClick={handleShare}>
              <ShareOutlined fontSize="small" />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Navigation;
