import React from 'react';
import { ValuationsContextValues } from './types';

const ValuationContext = React.createContext<ValuationsContextValues>({
  backsolveAttributes: null,
  companyMeasurementDate: null,
  compGroups: null,
  disableFinancialsSelection: null,
  filesInApproach: null,
  financials: null,
  financialsPeriods: null,
  financialStatementPeriods: null,
  financialsVersions: null,
  gpcAttributes: null,
  isChangingFinancialStatement: null,
  isEditingApproachName: null,
  measurementDate: null,
  notesInApproach: null,
  onChange: null,
  publicCompsAttrs: null,
  resetConfiguration: null,
  selectedFinancialsVersion: null,
  setAreThereChanges: null,
  setCashTaxRate: () => {},
  setFilesInApproach: null,
  setNotesInApproach: null,
  valuation: null,
  workbook: null,
});

export default ValuationContext;
