/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @name Company Hook
 * @memberof module:common/hooks
 * @type {ReactHook}
 * @return {React} Firm Hook
 */

import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { ERROR_400, ERROR_403, ERROR_404, ERROR_409 } from 'common/config/api';
import { firmSettingsUrl } from 'common/config/urls';
import useResponse from './useResponse';
import { CompaniesService, FirmService } from '..';
import { companiesAction, firmsAction, fundsAction, globalAction } from '../../common/actions';
import { useStore } from '../../common/store';

/**
 * @function
 * @name useGetFundByFirmId
 * @description Hook that return the summary by its firm id
 * @param {integer} firmId the firm id
 * @return {array}
 */
const useGetFundByFirmId = () => {
  const firmService = new FirmService();
  const [data, setData] = useState();
  const [, dispatch] = useStore();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessage = 'An error occurred while loading the Firm information';

  const fetchData = useCallback(
    async firmId => {
      if (firmId) {
        setData(null);
        dispatch(globalAction.showLoadingProgress(true));
        try {
          const data = await firmService.getFundByFirmId(firmId);

          if (data?.funds) {
            setData(data.funds);
          }
        } catch (error) {
          switch (error.status) {
            case 403:
              setData(ERROR_403);
              break;
            case 404:
              setData([]);
              break;
            default:
              processErrorResponse({
                error,
                defaultErrorMessage,
                config: {
                  [ERROR_400]: {
                    notification: false,
                    callback: () => setData(ERROR_400),
                  },
                },
              });
              break;
          }
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
      }
    },
    [dispatch]
  );

  return [data, fetchData];
};

/**
 * @function
 * @name useCreateNewFirm
 * @memberof Firm Hook
 * @description Hook to create a new firm
 * @param {OBJECT} Firm data
 * @return {OBJECT} structure for Hook context.
 */
export const useCreateNewFirm = () => {
  const firmSvc = new FirmService();
  const [, dispatch] = useStore();
  const [data, setData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessage = 'An error occurred while creating the Firm';

  const createFirm = async firmData => {
    // eslint-disable-next-line no-param-reassign
    try {
      dispatch(globalAction.showLoadingProgress(true));
      const firm = await firmSvc.createFirm(firmData);
      if (firm?.id) {
        enqueueSnackbar(`${firm.name} successfully created`, { variant: 'success' });
        const newFirmListInfo = await firmsAction.getFirmList();
        dispatch(firmsAction.setFirmList(newFirmListInfo.firms));
        setData(firm);
        return firm;
      }
    } catch (error) {
      const servicePath = error.response.req.url.split('/').pop();
      const action = servicePath === 'new' ? 'create the firm' : 'get firm list';
      processErrorResponse({
        error,
        defaultErrorMessage,
        action,
      });
      return error;
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return [{ data }, createFirm];
};

export const useUpdateFirmById = () => {
  const firmSvc = new FirmService();
  const [, dispatch] = useStore();
  const [data, setData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessage = 'An error occurred updating the Firm';
  const history = useHistory();

  const updateFirm = async (firmId, firmData) => {
    try {
      dispatch(globalAction.showLoadingProgress(true));
      const firm = await firmSvc.updateFirmById(firmId, firmData);
      if (firm?.id) {
        enqueueSnackbar(`${firm.name} successfully updated`, { variant: 'success' });
        const updatedFirmListInfo = await firmsAction.getFirmList();
        dispatch(firmsAction.setFirmList(updatedFirmListInfo.firms));

        setData(firm);
        history.push({ pathname: firmSettingsUrl(firm.slug) });
      }
    } catch (error) {
      const servicePath = error.response.req.url.split('/').pop();
      const action = servicePath === 'new' ? 'update the firm' : 'get firm list';
      processErrorResponse({
        error,
        defaultErrorMessage,
        action,
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return [{ data }, updateFirm];
};

/**
 * @function
 * @name useGetMeasurementDatesByFirmId
 * @param {integer} firmId
 * @description Hook that fetch the measument dates by the firm id
 * @returns {array}
 */
export const useGetMeasurementDatesByFirmId = () => {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessage = 'An error occurred while loading Measurement Dates';

  const fetchData = useCallback(
    async (firmId, returnData) => {
      if (firmId) {
        try {
          const firmService = new FirmService();
          const measurementDates = await firmService.getMeasurementDatesByFirmId(firmId);
          if (measurementDates) {
            setData(measurementDates);
            if (returnData) return measurementDates;
          }
        } catch (error) {
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'get the measurement dates of the current firm',
          });
        }
      }
    },
    [enqueueSnackbar]
  );

  return [data, fetchData];
};

/**
 * @function
 * @name useGetMeasurementDatesByCompanyId
 * @param {integer} companyId
 * @description Hook that fetch the measument dates by the firm id
 * @returns {array}
 */
export const useGetMeasurementDatesByCompanyId = () => {
  const [data, setData] = useState();
  const [{ companyInfo }] = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessage = 'An error occurred while loading Measurement Dates';

  const fetchData = useCallback(
    async (companyId, returnData) => {
      if (companyId) {
        try {
          const companyService = new CompaniesService();
          const measurementDates = await companyService.measurementDates(companyId);
          if (measurementDates) {
            setData(measurementDates);
            if (returnData) return measurementDates;
          }
        } catch (error) {
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'get the measurement dates of the current company',
          });
        }
      }
    },
    [enqueueSnackbar]
  );

  useEffect(
    () => () => {
      setData([]);
    },
    [companyInfo?.id]
  );

  return [data, fetchData];
};

export default useGetFundByFirmId;

/**
 * @function
 * @name useCreateFirmCompany
 * @memberof Firm Hook
 * @description Hook to create a new company for specific firm
 * @param {OBJECT} companyData the company information
 * @return {OBJECT} structure for Hook context.
 */
export const useCreateFirmCompany = () => {
  const [, dispatch] = useStore();
  const [data, setData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessage = 'An error occurred while creating the Company';
  const errorMessages = {
    [ERROR_409]: 'A company with this name already exists.',
  };

  const createCompany = async data => {
    dispatch(globalAction.showLoadingProgress(true));
    try {
      const firmService = new FirmService();
      const newCompany = await firmService.createFirmCompany(data);
      if (newCompany?.id) {
        enqueueSnackbar(`${newCompany.name} successfully created`, { variant: 'success' });
        const newCompanyList = await firmService.getCompanyListByFirmId(newCompany.firm);

        dispatch(companiesAction.setCompanyList(newCompanyList));
        setData(newCompany);
      }
    } catch (error) {
      const { status } = error;
      const servicePath = error.response.req.url.split('/').pop();
      const action = servicePath === 'new' ? 'create a new company' : 'get companies';
      processErrorResponse({
        error,
        defaultErrorMessage: errorMessages[status] || defaultErrorMessage,
        action,
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return [data, createCompany];
};

/**
 * @function
 * @name useGetCompanyListByFirmId
 * @param {integer} firmId
 * @description Hook that fetch the company list by the firm id
 * @returns {array}
 */
export const useGetCompanyListByFirmId = () => {
  const [, dispatch] = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();

  const getCompanies = useCallback(
    async firmId => {
      if (firmId) {
        try {
          const firmService = new FirmService();
          dispatch(globalAction.showLoadingProgress(true));
          const companies = await firmService.getCompanyListByFirmId(firmId);
          dispatch(companiesAction.setCompanyList(companies));
          return companies;
        } catch (error) {
          dispatch(companiesAction.setCompanyList([]));
          const defaultErrorMessage = 'An error occurred while loading Companies';
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'get companies',
          });
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
      }
    },
    [enqueueSnackbar]
  );

  return { getCompanies };
};

/**
 * @function
 * @name useGetCompaniesFromPreviousMD
 * @param {integer} firmId
 * @description Hook that fetch the companies by the firm id
 * @returns {array}
 */
export const useGetCompaniesFromPreviousMD = () => {
  const [isLoading, setIsLoading] = useState();
  const [companies, setCompanies] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessage = 'An error occurred while loading Companies';

  const getCompanies = useCallback(
    async (firmId, date) => {
      if (firmId) {
        try {
          setIsLoading(true);

          const firmService = new FirmService();
          const companies = await firmService.getCompaniesFromPreviousMD(firmId, date);
          if (companies) {
            setCompanies(companies);
          }
        } catch (error) {
          processErrorResponse({
            error,
            defaultErrorMessage,
            config: {
              [ERROR_404]: {
                notification: false,
                callback: () => setCompanies([]),
              },
            },
            action: 'get companies',
          });
        } finally {
          setIsLoading(false);
        }
      }
    },
    [enqueueSnackbar]
  );

  return { isLoading, companies, getCompanies };
};

/**
 * @function
 * @name useGetFundListByFirmId
 * @param {integer} firmId
 * @description Hook that fetch the fund list by the firm id
 * @returns {array}
 */
export const useGetFundListByFirmId = () => {
  const [, dispatch] = useStore();
  const { enqueueSnackbar } = useSnackbar();

  const getFunds = useCallback(
    async firmId => {
      if (firmId) {
        try {
          const firmService = new FirmService();
          dispatch(globalAction.showLoadingProgress(true));
          const funds = await firmService.getFundListByFirmId(firmId);
          dispatch(fundsAction.setFundList(funds));
          return funds;
        } catch (error) {
          dispatch(fundsAction.setFundList([]));
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
      }
    },
    [enqueueSnackbar]
  );

  return { getFunds };
};

export const useCreateOrUpdateCompGroups = () => {
  const [data, setData] = useState();
  const [, dispatch] = useStore();
  const { processErrorResponse } = useResponse();
  const { enqueueSnackbar } = useSnackbar();
  const defaultErrorMessage = 'An error ocurred while trying to create or update comp groups';

  const createOrUpdateCompGroups = async (firmId, requestData) => {
    const firmService = new FirmService();
    dispatch(globalAction.showLoadingProgress(true));
    try {
      const response = await firmService.createOrUpdateCompGroups(firmId, requestData);
      if (response) {
        enqueueSnackbar('Comp groups successfully updated', { variant: 'success' });
        setData(response?.results);
        return response;
      }
    } catch (error) {
      const action = 'creating or updating comp groups';
      processErrorResponse({
        error,
        defaultErrorMessage,
        action,
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return [data, createOrUpdateCompGroups];
};

export const useGetBaseSetOfQuestionsAndDocuments = () => {
  const [data, setData] = useState();
  const [, dispatch] = useStore();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessage = 'An error ocurred while trying to get base set of questions and documents';

  const getFirmSetOfQuestionsAndDocuments = useCallback(
    async firmId => {
      const firmService = new FirmService();
      dispatch(globalAction.showLoadingProgress(true));
      try {
        const response = await firmService.getFirmSetOfQuestionsAndDocuments(firmId);
        if (response && 'requested_documents' in response && 'questions' in response && 'companies' in response) {
          setData(response);
          return response;
        }
      } catch (error) {
        const action = 'getting base set of questions and documents';
        processErrorResponse({
          error,
          defaultErrorMessage,
          action,
        });
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    },
    [dispatch, setData, globalAction, defaultErrorMessage, processErrorResponse]
  );

  return [data, getFirmSetOfQuestionsAndDocuments];
};

export const useCreateRequestedTasks = () => {
  const [, dispatch] = useStore();
  const { processErrorResponse } = useResponse();
  const { enqueueSnackbar } = useSnackbar();
  const defaultErrorMessage = 'An error ocurred while trying to create requested tasks';

  const createRequestedTasks = async requestData => {
    const firmService = new FirmService();
    dispatch(globalAction.showLoadingProgress(true));
    try {
      const response = await firmService.createRequestedTasks(requestData);
      if (response) {
        enqueueSnackbar('Requested tasks successfully created', { variant: 'success' });
        return response;
      }
    } catch (error) {
      const action = 'creating requested tasks';
      processErrorResponse({
        error,
        defaultErrorMessage,
        action,
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return [createRequestedTasks];
};

/**
 * @function
 * @name useGetAllMeasurementDatesByFirmId
 * @param {integer} firmId
 * @description Hook that fetch the full list of measument dates by the firm id. This list include the fund and the companies md.
 * @returns {array}
 */
export const useGetAllMeasurementDatesByFirmId = () => {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessage = 'An error occurred while loading Measurement Dates';

  const fetchData = useCallback(
    async (firmId, returnData) => {
      if (firmId) {
        try {
          const firmService = new FirmService();
          const measurementDates = await firmService.getAllMeasurementDatesByFirmId(firmId);
          if (measurementDates) {
            setData(measurementDates);
            if (returnData) return measurementDates;
          }
        } catch (error) {
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'get the measurement dates of the current firm',
          });
        }
      }
    },
    [enqueueSnackbar]
  );

  return [data, fetchData];
};

/**
 * @function
 * @name useGetCompaniesAndFundByMd
 * @param {integer} mdId
 * @description Hook that fetch the companies and funds by measurement date id
 * @returns {array}
 */
export const useGetCompaniesAndFundByMd = () => {
  const [data, setData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessage = 'An error occurred while loading Measurement Dates';

  const fetchData = useCallback(
    async (firmId, date, returnData) => {
      if (firmId && date) {
        try {
          const firmService = new FirmService();
          const measurementDates = await firmService.getCompaniesAndfundsByFirmAndDate(firmId, date);
          if (measurementDates) {
            setData(measurementDates);
            if (returnData) return measurementDates;
          }
        } catch (error) {
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'get the companies and funds by the mdId',
          });
        }
      }
    },
    [enqueueSnackbar]
  );

  return [data, fetchData];
};

/**
 * @function
 * @name useDeleteMD
 * @description Hook that Delete the companies and funds by measurement date selected
 * @returns {array}
 */
export const useDeleteCompaniesAndFundByMd = () => {
  const firmSvc = new FirmService();
  const [, dispatch] = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessage = 'An error occurred while deleting the Firm';

  const deleteFundsAndCompaniesByMd = async (data, selectedItems) => {
    try {
      dispatch(globalAction.showLoadingProgress(true));
      const response = await firmSvc.deleteCompaniesAndFundByMd(data);
      if (response) {
        const message = `The ${selectedItems} has been deleted succesfully!`;
        enqueueSnackbar(message, { variant: 'success' });
        return response;
      }
    } catch (error) {
      const action = 'Delete Funds and Companies';
      processErrorResponse({
        error,
        defaultErrorMessage,
        action,
      });
      return error;
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return [deleteFundsAndCompaniesByMd];
};

/**
 * @function
 * @name useCreateRequestedQuestion
 * @description Hook that create a new requested question for a firm
 * @returns {array}
 */
export const useCreateRequestedQuestion = () => {
  const [, dispatch] = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessage = 'An error occurred while creating the requested question';

  const createRequestedQuestion = async requestData => {
    const firmService = new FirmService();
    dispatch(globalAction.showLoadingProgress(true));
    try {
      const response = await firmService.createRequestedQuestion(requestData);
      if (response) {
        enqueueSnackbar('Requested question successfully created', { variant: 'success' });
        return response;
      }
    } catch (error) {
      const action = 'creating requested question';
      processErrorResponse({
        error,
        defaultErrorMessage,
        action,
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return [createRequestedQuestion];
};

/**
 * @function
 * @name useDeleteAndUpdateRequestedQuestion
 * @description Hook that delete or update a requested question for a firm
 * @returns {array}
 */
export const useDeleteAndUpdateRequestedQuestion = () => {
  const [, dispatch] = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessage = 'An error occurred while deleting the requested question';

  const deleteRequestedQuestion = async questionId => {
    const firmService = new FirmService();
    dispatch(globalAction.showLoadingProgress(true));
    try {
      await firmService.deleteRequestedQuestion(questionId);
      enqueueSnackbar('Requested question successfully deleted', { variant: 'success' });
    } catch (error) {
      const action = 'deleting requested question';
      processErrorResponse({
        error,
        defaultErrorMessage,
        action,
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  const updateRequestedQuestion = async (questionId, requestData) => {
    const firmService = new FirmService();
    dispatch(globalAction.showLoadingProgress(true));
    try {
      const response = await firmService.updateRequestedQuestion(questionId, requestData);
      if (response) {
        enqueueSnackbar('Requested question successfully updated', { variant: 'success' });
        return response;
      }
    } catch (error) {
      const action = 'updating requested question';
      processErrorResponse({
        error,
        defaultErrorMessage,
        action,
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return [deleteRequestedQuestion, updateRequestedQuestion];
};

/**
 * @function
 * @name useGetFirmRequestedDocuments
 * @description Hook that create a new firm requested document
 * @returns {array}
 */
export const useCreateFirmRequestedDocument = () => {
  const firmSvc = new FirmService();
  const [, dispatch] = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessage = 'An error occurred while creating firm requested document';

  const createFirmRequestedDocument = async data => {
    try {
      dispatch(globalAction.showLoadingProgress(true));
      const response = await firmSvc.createFirmRequestedDocument(data);
      if (response) {
        enqueueSnackbar('Firm requested document created successfully', { variant: 'success' });
        return response;
      }
    } catch (error) {
      const action = 'Create Firm Requested Document';
      processErrorResponse({
        error,
        defaultErrorMessage,
        action,
      });
      return error;
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return [createFirmRequestedDocument];
};

/**
 * @function
 * @name useFirmRequestedDocument
 * @description Hook that delete or update a firm requested document
 * @returns {array}
 */
export const useDeleteAndUpdateFirmRequestedDocument = () => {
  const firmSvc = new FirmService();
  const [, dispatch] = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();
  const defaultErrorMessageDelete = 'An error occurred while deleting firm requested document';
  const defaultErrorMessageUpdate = 'An error occurred while updating firm requested document';

  const deleteFirmRequestedDocument = async requestedDocumentId => {
    try {
      dispatch(globalAction.showLoadingProgress(true));
      await firmSvc.deleteFirmRequestedDocument(requestedDocumentId);
      enqueueSnackbar('Firm requested document deleted successfully', { variant: 'success' });
    } catch (error) {
      const action = 'Delete Firm Requested Document';
      processErrorResponse({
        error,
        defaultErrorMessage: defaultErrorMessageDelete,
        action,
      });
      return error;
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  const updateFirmRequestedDocument = async (requestedDocumentId, data) => {
    try {
      dispatch(globalAction.showLoadingProgress(true));
      const response = await firmSvc.updateFirmRequestedDocument(requestedDocumentId, data);
      if (response) {
        enqueueSnackbar('Firm requested document updated successfully', { variant: 'success' });
        return response;
      }
    } catch (error) {
      const action = 'Update Firm Requested Document';
      processErrorResponse({
        error,
        defaultErrorMessage: defaultErrorMessageUpdate,
        action,
      });
      return error;
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return [deleteFirmRequestedDocument, updateFirmRequestedDocument];
};
