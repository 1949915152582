import { NOT_APPLICABLE } from 'common/constants/general';
import { largeCurrencyFormat, oneDecimalPercentFormat } from 'common/formats/formats';
import {
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics';
import { PERFORMANCE_METRICS_SPREADSHEET_RANK_ALIAS } from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics/sheetAliases';
import { ensureNoMoreThanTwoDecimalPlaces } from 'pages/Valuations/approaches/guidelinePublicCompanies/utils/utilities';
import { countDecimalPlaces, generateAllRowsExpression, getExpression, getObjectValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';

const {
  PERFORMANCE_METRICS_SPREADSHEET_COMPANY,
  PERFORMANCE_METRICS_SPREADSHEET_HEADER_SUBTITLE,
  PERFORMANCE_METRICS_SPREADSHEET_HEADER_TITLE,
} = SHEET_ALIASES_CONSTANTS;
const { PERFORMANCE_METRICS_SPREADSHEET_COLUMNS, PERFORMANCE_METRICS_SPREADSHEET_HEADERS } = SHEET_TITLES_CONSTANTS;

const EXCLUDE_TITLE_AND_SUBTITLE = 1;

const generateNTMRevenueGrowthExpression = ({ alphabet, rowNumber }) => {
  // Column legend (-1 excluding Company Column)
  const columnLTMRevenueLegend = alphabet[PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_REVENUE - 1];
  const columnNTMRevenueLegend = alphabet[PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_REVENUE - 1];

  const columnLTMRevenueKey = columnLTMRevenueLegend + rowNumber;
  const columnNTMRevenueKey = columnNTMRevenueLegend + rowNumber;

  // NTM Revenue Growth formula expression
  const NTMRevenueGrowthExpression = `=IF(OR(${columnNTMRevenueKey} == 0, ${columnLTMRevenueKey} == 0), "N/A", (${columnNTMRevenueKey} / ${columnLTMRevenueKey}) - 1)`;

  return NTMRevenueGrowthExpression;
};

const evaluateNTMRevenueGrowth = params => {
  const { alias, alphabet, rowNumber } = params;
  let { value, expr } = params;

  if (value === NOT_APPLICABLE && alias !== PERFORMANCE_METRICS_SPREADSHEET_COMPANY)
    return {
      expr: '',
      value,
    };

  // If the NTM Revenue Growth has 2 or less decimal places, we need to use the Expression to display the accurate value
  // Because that means that the value is not using the new decimal places coming from the CAPIQ API
  // We also need to use the Expression for the Company
  if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY || countDecimalPlaces(Number(value)) <= 2) {
    value = 0;
    expr = generateNTMRevenueGrowthExpression({ alphabet, rowNumber });
  }

  // The value is in percentage, we only need to apply the format
  if (alias !== PERFORMANCE_METRICS_SPREADSHEET_COMPANY) value = Number(value ?? 0);

  return { expr, value };
};

function getRankExpression({ colNumber, allComparisonsExpression }) {
  let expr = null;
  let hidden = true;
  if (colNumber < PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.VOLATILITY_1_YEAR) {
    expr = `=PERCENTRANK([${allComparisonsExpression}], ${allComparisonsExpression.at(-1)})`;
    hidden = false;
  }
  return { expr, hidden };
}

const cellParser = ({
  companyAsOfDatePerformanceMetrics,
  alphabet,
  colIndex,
  column,
  row,
  rowIndex,
  allComparisonsExpression,
}) => {
  const rowNumber = rowIndex + 1;
  const colNumber = colIndex + 1;
  const columnLegend = alphabet[colIndex];
  const key = columnLegend + rowNumber;

  const { alias } = getObjectValue(row);
  const { className = '', colSpan = 1, rowSpan } = getObjectValue(row);
  let { expr = '', format = null, gridType = 'string', hidden, readOnly, tooltipMessages } = getObjectValue(row);
  let value = column[alias]?.value ?? '';
  let NTMRevenueGrowthEvaluation = {};

  const compAsOfDatePerfMetricsFallbackValue = [];

  // Parse cell based on Row alias
  switch (alias) {
    // Header titles
    case PERFORMANCE_METRICS_SPREADSHEET_HEADER_TITLE:
      value = PERFORMANCE_METRICS_SPREADSHEET_HEADERS[colNumber].value;
      break;
    // Header subtitles
    case PERFORMANCE_METRICS_SPREADSHEET_HEADER_SUBTITLE:
      value = PERFORMANCE_METRICS_SPREADSHEET_HEADERS[colNumber].subtitle;
      break;

    case PERFORMANCE_METRICS_SPREADSHEET_RANK_ALIAS:
      {
        const rankValues = getRankExpression({ colNumber, allComparisonsExpression });
        expr = rankValues.expr;
        hidden = rankValues.hidden;
      }
      break;
    // Rest of the rows (excluding Header titles and subtitles)
    default:
      // Parse cell based on Column number
      switch (colNumber) {
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_REVENUE: {
          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            value = ensureNoMoreThanTwoDecimalPlaces(
              (companyAsOfDatePerformanceMetrics || compAsOfDatePerfMetricsFallbackValue)[0]?.ltm_revenue
            );
          }
          format = largeCurrencyFormat;
          gridType = 'number';
          break;
        }
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_REVENUE:
          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            value = ensureNoMoreThanTwoDecimalPlaces(
              (companyAsOfDatePerformanceMetrics || compAsOfDatePerfMetricsFallbackValue)[0]?.ntm_revenue
            );
          }
          format = largeCurrencyFormat;
          gridType = 'number';
          break;
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_EBITDA: {
          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            value = ensureNoMoreThanTwoDecimalPlaces(
              (companyAsOfDatePerformanceMetrics || compAsOfDatePerfMetricsFallbackValue)[0]?.ltm_ebitda
            );
          }
          format = largeCurrencyFormat;
          gridType = 'number';
          break;
        }
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_EBITDA:
          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            value = ensureNoMoreThanTwoDecimalPlaces(
              (companyAsOfDatePerformanceMetrics || compAsOfDatePerfMetricsFallbackValue)[0]?.ntm_ebitda
            );
          }
          format = largeCurrencyFormat;
          gridType = 'number';
          break;

        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_REVENUE_GROWTH:
          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            value = ensureNoMoreThanTwoDecimalPlaces(
              (companyAsOfDatePerformanceMetrics || compAsOfDatePerfMetricsFallbackValue)[0]?.ltm_revenue_growth
            );
          }
          format = oneDecimalPercentFormat;
          gridType = 'percentage';
          break;
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.GROSS_MARGIN:
          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            value = ensureNoMoreThanTwoDecimalPlaces(
              (companyAsOfDatePerformanceMetrics || compAsOfDatePerfMetricsFallbackValue)[0]?.gross_margin
            );
          }
          format = oneDecimalPercentFormat;
          gridType = 'percentage';
          break;
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.EBITDA_MARGIN:
          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            value = ensureNoMoreThanTwoDecimalPlaces(
              (companyAsOfDatePerformanceMetrics || compAsOfDatePerfMetricsFallbackValue)[0]?.ebitda_margin
            );
          }
          format = oneDecimalPercentFormat;
          gridType = 'percentage';
          break;

        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_REVENUE_GROWTH:
          format = oneDecimalPercentFormat;
          gridType = 'percentage';

          NTMRevenueGrowthEvaluation = evaluateNTMRevenueGrowth({ alphabet, alias, expr, rowNumber, value });
          expr = NTMRevenueGrowthEvaluation?.expr ?? expr;
          value = NTMRevenueGrowthEvaluation?.value ?? value;
          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            tooltipMessages = [
              'NTM Revenue Growth is calculated as NTM Revenue / LTM Revenue - 1 or N/A if either value is 0.',
            ];
          }
          readOnly = true;
          break;

        default:
          break;
      }
      break;
  }

  return {
    [key]: {
      ...row,
      className,
      colSpan,
      columnLegend,
      expr: getExpression({ expr, columnLegend }),
      format,
      gridType,
      key,
      hidden,
      rowSpan,
      value,
      readOnly,
      tooltipMessages,
    },
  };
};

const customParser = ({ columns, rowConfig, tableData }) => {
  let cells = {};
  const { companyAsOfDatePerformanceMetrics } = tableData;
  const alphabet = alphabetGenerator([], columns.length);

  const allComparisonsExpression = generateAllRowsExpression({
    rowConfig,
    excludedRows: EXCLUDE_TITLE_AND_SUBTITLE,
    excludeLastRow: true,
  });

  rowConfig.forEach((row, rowIndex) => {
    columns.forEach((column, colIndex) => {
      cells = {
        ...cells,
        ...cellParser({
          companyAsOfDatePerformanceMetrics,
          allComparisonsExpression,
          alphabet,
          colIndex,
          column,
          row,
          rowIndex,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
