import React, { useState } from 'react';
import { Button, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
    margin: theme.spacing(1),
    borderRadius: '0.313rem',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  menuItem: {
    fontSize: '1.1rem',
  },
  inputLabel: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));
const SelectButton = ({ addNew, valuationApproaches, selectApproach, label }) => {
  const classes = useStyles();
  const [value, setValue] = useState(null);

  const handleChange = event => {
    const valuationApproach = valuationApproaches.filter(valuation => valuation.id === event.target.value);
    setValue(event.target.value);
    selectApproach(valuationApproach[0]);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel className={classes.inputLabel} id="select-label">
        {label}
      </InputLabel>
      <Select
        labelId="select-outlined-label"
        id="select-outlined"
        value={value}
        onChange={handleChange}
        label={label}
        border="2px solid #037DE8">
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {valuationApproaches.map(approach => (
          <MenuItem key={approach.id} className={classes.menuItem} value={approach.id}>
            {approach.name}
          </MenuItem>
        ))}
        <Button className={classes.button} onClick={addNew}>
          Add New
        </Button>
      </Select>
    </FormControl>
  );
};

SelectButton.propTypes = {
  addNew: PropTypes.func.isRequired,
  valuationApproaches: PropTypes.array.isRequired,
  selectApproach: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default SelectButton;
