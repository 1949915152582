/**
 * @file common/store/index.js
 * @module common/store
 * @desc This is the encapsulation of all reducers in a store
 * https://github.com/vanderleisilva/react-context.git
 * @since v1.0
 * @author @vanderleisilva <vanderlei.alves.da.silva@gmail.com>
 * @return {Object} List of store reducers
 */

import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const StateContext = createContext(null);

export const StoreProvider = ({ initialState, reducer, children }) => (
  <StateContext.Provider
    value={useReducer(reducer, initialState)}
  >
    {children}
  </StateContext.Provider>
);

StoreProvider.propTypes = {
  initialState: PropTypes.object,
  reducer: PropTypes.func,
  children: PropTypes.element,
};

export const useStore = () => useContext(StateContext);
