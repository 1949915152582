import React, { useCallback, useMemo } from 'react';

const useValidateDateGridComponent = (
  { cell, onDoubleClick, selected, editing, children, value },
  doubleClicked,
  setDoubleClicked
) => {
  const DatePicker = cell.gridDateComponent || React.Fragment;

  const { minDate, maxDate } = cell;

  const doubleClickWrapper = useCallback(
    args => {
      setDoubleClicked(true);
      onDoubleClick(args);
    },
    [onDoubleClick, setDoubleClicked]
  );

  const doubleClickEvent = useMemo(() => {
    if (cell.gridDateComponent && !cell.readOnly && !editing) {
      return {};
    }
    return { onDoubleClick: doubleClickWrapper, onTouchEnd: onDoubleClick };
  }, [cell.gridDateComponent, cell.readOnly, editing, doubleClickWrapper, onDoubleClick]);

  const cellContent = useMemo(() => {
    if (cell.gridDateComponent && !cell.readOnly && !editing) {
      return (
        <>
          <div onDoubleClick={onDoubleClick} onTouchEnd={onDoubleClick}>
            {React.cloneElement(children, { selected, onDoubleClick, value }, null)}
          </div>
          {cell.gridDateComponent && !cell.readOnly && <DatePicker cell={cell} minDate={minDate} maxDate={maxDate} />}
        </>
      );
    }
    return React.cloneElement(
      children,
      {
        selected,
        onDoubleClick,
        doubleClicked,
        setDoubleClicked,
        value,
      },
      null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cell, editing, children, selected, onDoubleClick, setDoubleClicked, value, minDate, maxDate]);

  return { doubleClickEvent, cellContent };
};

export default useValidateDateGridComponent;
