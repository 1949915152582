import { isNil } from 'lodash';
import { largeCurrencyFormat } from 'common/formats/formats';
import { HandleScenarioEquityValueColumnParams } from './types';

const handleScenarioEquityValueColumn = (params: HandleScenarioEquityValueColumnParams) => {
  const { cell, scenarioEquityValueCustomKey, scenarioEquityValueExpression } = params;

  cell.customKey = scenarioEquityValueCustomKey;
  cell.defaultZero = true;
  cell.format = largeCurrencyFormat;
  cell.gridType = 'number';

  if (isNil(cell.value)) {
    cell.hidden = true;
  }

  cell.expr = scenarioEquityValueExpression;
};

export default handleScenarioEquityValueColumn;
