import React from 'react';
import PropTypes from 'prop-types';
import EmptyTableMessage from 'components/EmptyTableMessage';
import useCheckFeatures from './hooks';
/*
  localStorage should be something like
  {
    ...otherThings,
    features: {
      documents: true,
      exports: false,
      newerFeature: true
    }
  }
*/
const FeatureEnabler = ({ children, enabler, hideComponent, alternativeComponent }) => {
  const [, checkFeature] = useCheckFeatures();
  const isCurrentFeatureEnabled = checkFeature(enabler);

  if (hideComponent && !isCurrentFeatureEnabled) {
    return null;
  }

  const AlternativeComponent = alternativeComponent;

  if (alternativeComponent && !isCurrentFeatureEnabled) {
    return <AlternativeComponent />;
  }

  if (isCurrentFeatureEnabled) {
    return children;
  }

  return (
    <EmptyTableMessage
      title={'This feature is coming soon!'}
      tableTerms={{}}
      tagline={'Contact us if you would like early access.'}
    />
  );
};

FeatureEnabler.propTypes = {
  children: PropTypes.element,
  enabler: PropTypes.string,
  hideComponent: PropTypes.bool,
  alternativeComponent: PropTypes.elementType,
};

export default FeatureEnabler;
