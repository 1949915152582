import React from 'react';
import PropTypes from 'prop-types';

export default function Scalar(props) {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9999 7.23215C13.9999 3.36617 10.8659 0.232178 6.99997 0.232178C3.13399 0.232178 0 3.36617 0 7.23215C0 11.0981 3.13399 14.2321 6.99997 14.2321C10.8659 14.2321 13.9999 11.0981 13.9999 7.23215ZM7.54345 4.79901V2.99259H4.44622V9.26497H7.54345V7.4585H5.99483V4.79901H7.54345ZM6.39452 11.472V9.66553H7.94316V7.00605H6.39452V5.19961H9.49178V11.472H6.39452Z"
        fill="#037DE8"
      />
    </svg>
  );
}

Scalar.propTypes = {
  className: PropTypes.string,
};
