import React, { FC } from 'react';
import { Drawer, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { withTheme } from '@material-ui/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { BottomMenu, CompanyLogo, TopMenu } from 'dashboard409a/components';
import useStyles from './styles';
import { ISidebar } from './types';

const Sidebar: FC<ISidebar> = props => {
  const { className = '', homePath = '/', isOpen, setIsOpen, topMenuItems, bottomMenuItems, logoUrl = '' } = props;

  const classes = useStyles({ isOpen });

  // TODO: Toggle sidebar with Redux
  const handleLeave = () => {
    if (isOpen) setIsOpen(false);
  };

  return (
    <Drawer
      anchor="left"
      onClose={handleLeave}
      open={isOpen}
      variant="permanent"
      classes={{
        paper: clsx(classes.drawer, {
          [classes.openingDrawer]: isOpen,
          [classes.closingDrawer]: !isOpen,
        }),
      }}
      PaperProps={{
        elevation: 0,
      }}>
      <div className={clsx(classes.drawerContainer, className)}>
        {/* Close Button */}
        <div className={classes.closeButtonContainer}>
          <IconButton size="medium" onClick={handleLeave}>
            <CloseIcon className={classes.closeButton} />
          </IconButton>
        </div>

        {/* Firm logo */}
        <div className={classes.companyLogoContainer}>
          <Link to={homePath || '#'}>
            <CompanyLogo logoUrl={logoUrl} />
          </Link>
        </div>

        <div className={classes.drawerBottom}>
          <TopMenu items={topMenuItems} />

          <div className={classes.bottom}>
            <BottomMenu items={bottomMenuItems} />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default withTheme(Sidebar);
