import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

export const HORIZONTAL_NAVIGATION_SELECTED_COLOR = '#80A4C5';

export const getNavigationItemStyles = (theme: ITheme) => ({
  fontSize: theme.spacing(1.65),
  fontWeight: 500,
  minWidth: 0,
  padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
});

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary[600],
    },
    container: {
      margin: 0,
      overflowX: 'auto',
      padding: `0px ${theme.spacing(2)}px`,
      width: '100%',
    },
    menuItems: {
      padding: '0px !important',
    },
    navItem: {
      color: theme.palette.white,
      ...getNavigationItemStyles(theme),
    },
    navActions: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    selected: {
      color: HORIZONTAL_NAVIGATION_SELECTED_COLOR,

      '&:hover': {
        color: theme.palette.white,
      },
    },
    hidden: {
      display: 'none',
    },
  })
);

export default useStyles;
