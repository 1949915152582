export default {
  name: '',
  total_revenue: '',
  total_sales_cost: '',
  gross_profit: '',
  operating_expenses: '',
  ebitda: '',
  adjusted_ebitda: '',
  deprecation_expense: '',
  amortization_expense: '',
  ebit: '',
  interest_expense: '',
  other_expense: '',
  pretax_income: '',
  income_taxes: '',
  net_income: ''
};
