/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import React from 'react';
import { securityTypes } from 'common/constants';
import * as capCells from 'common/constants/cap-table';
import { LEDGER } from 'common/constants/gridType';
import {
  capTableCurrencyFormat,
  capTableTwoDecimalPercentFormat,
  capTableXSuffixFormat,
  fullyDilutedOwnershipFormat,
  largeCurrencyFormat,
  largeDecimalFormat,
  oneDecimalPercentFormatValidateFloatTrue,
  xLargeSuffixFormat,
  xStandardSuffixFormat,
} from 'common/formats/formats';
import { SelectValueViewer } from 'components';
import { CONVERTIBLE_NOTES } from '../../../../common/constants/securityTypes';
import { SecurityType, UnderlyingSecurity } from '../components';

export const getAccruedDividendsTxt = measurementDate => `Accrued Dividends (as of ${measurementDate})`;

const rowConfig = ({ customTitles = {}, measurementDate, isDisabled }) => {
  const tmpConfig = [
    // 01.  Security Name
    {
      value: 'Security Name',
      alias: capCells.SECURITY_NAME_ALIAS,
      hidden: false,
      readOnly: false,
      expr: '',
      defaultValue: 'ENTER NAME',
      tooltipPlacement: 'bottom',
      className: 'subtitle-border-bottom row-label-bold blue',
    },
    // 02.  Investment Date
    {
      value: 'Investment Date',
      alias: capCells.INVESTMENT_DATE_ALIAS,
      dataSource: 'investment_dates',
      expr: '',
      dbType: 'dbDate',
      gridType: 'gridDate',
      allowEmptyValues: true,
      isRequired: true,
    },
    // 03.  Security type
    {
      value: 'Security Type',
      alias: capCells.SECURITY_TYPE_ALIAS,
      dataEditor: props => <SecurityType options={securityTypes.LIST} {...props} />,
      valueViewer: props => <SelectValueViewer {...props} />,
      hidden: false,
      readOnly: isDisabled,
      expr: '',
      gridType: 'number',
      dbType: 'number',
      displayUndefined: true,
      linkedCellSymbols: [
        `@${capCells.INVESTMENT_DATE_ROW_NUMBER}`,
        `@${capCells.ISSUE_PRICE_ROW_NUMBER}`,
        `@${capCells.SHARES_OUTSTANDING_ROW_NUMBER}`,
        `@${capCells.SHARES_FULLY_DILUTED_ROW_NUMBER}`,
        `@${capCells.STRIKE_PRICE_ROW_NUMBER}`,
        `@@${capCells.UNDERLYING_SECURITY_ROW_NUMBER}`,
        `@${capCells.CONVERSION_RATE_ROW_NUMBER}`,
        `@${capCells.PIK_SHARES_ROW_NUMBER}`,
        `@${capCells.LIQUIDATION_PREFERENCE_ROW_NUMBER}`,
        `@${capCells.SENIORITY_ROW_NUMBER}`,
        `@${capCells.PARTICIPATON_ROW_NUMBER}`,
        `@${capCells.CUMMULATIVE_DIVIDENDS_ROW_NUMBER}`,
      ],
    },
    // 04.  Original Issue Price
    {
      value: 'Original Issue Price',
      defaultValue: '',
      alias: capCells.ISSUE_PRICE_ALIAS,
      readOnly: true,
      format: capTableCurrencyFormat,
      expr: '',
      gridType: 'number',
      dbType: 'string',
    },
    // 05.  Shares Outstanding
    {
      value: 'Shares Outstanding',
      alias: capCells.SHARES_OUTSTANDING_ALIAS,
      hidden: false,
      hasTotal: true,
      format: largeDecimalFormat,
      dataSource: 'options_ledger',
      readOnly: false,
      expr: '',
      gridType: 'number',
      dbType: 'string',
      defaultValue: '',
    },
    // 06.  Conversion Rate
    {
      value: 'Conversion Rate',
      defaultValue: '',
      alias: capCells.CONVERSION_RATE_ALIAS,
      readOnly: true,
      hidden: true,
      format: capTableXSuffixFormat,
      expr: '',
      gridType: 'number',
      dbType: 'string',
    },
    // 07.  PIK Shares as of today
    {
      value: 'PIK Shares as of Today',
      alias: capCells.PIK_SHARES_ALIAS,
      readOnly: true,
      format: largeDecimalFormat,
      expr: '=IF(@21==Pik,@25/@4,0)',
      gridType: 'number',
      dbType: 'string',
      evalOrder: 1,
    },
    // 08.  Shares Fully Diluted (as converted)
    {
      value: 'Shares Fully Diluted (as converted)',
      alias: capCells.SHARES_FULLY_DILUTED_ALIAS,
      hidden: false,
      readOnly: true,
      hasTotal: true,
      format: largeDecimalFormat,
      expr: '=IF(OR(@3==Preferred_Stock,@3==Warrant),(@5+@7)*@6,@5)',
      gridType: 'number',
      dbType: 'string',
      evalOrder: 2,
    },
    // 09.  Current Ownership
    {
      value: 'Current Ownership',
      defaultValue: '0',
      alias: capCells.CURRENT_OWNERSHIP_ALIAS,
      readOnly: true,
      hidden: false,
      className: 'subtitle',
      hasTotal: true,
      format: oneDecimalPercentFormatValidateFloatTrue,
      dbDecimalPlaces: 15,
      gridType: 'percentage',
      dbType: 'string',
    },
    // 10.  Fully Diluted Ownership
    {
      value: 'Fully Diluted Ownership',
      defaultValue: '0',
      alias: capCells.FULLY_DILUTED_OWNERSHIP_ALIAS,
      readOnly: true,
      hidden: false,
      hasTotal: true,
      format: fullyDilutedOwnershipFormat,
      dbDecimalPlaces: 15,
      gridType: 'percentage',
      dbType: 'string',
      className: 'row-label-bold',
    },
    // 11.  Strike Price
    {
      value: 'Strike Price',
      defaultValue: '',
      alias: capCells.STRIKE_PRICE_ALIAS,
      hasSubRows: true,
      showSubRows: true,
      dataSource: 'options_ledger',
      format: capTableCurrencyFormat,
      expr: '',
      gridType: 'number',
      dbType: 'string',
      disableWhenLedgerOpen: true,
    },
    // 12.  Underlying Security
    {
      value: 'Underlying Security',
      alias: capCells.UNDERLYING_SECURITY_ALIAS,
      dataEditor: props => <UnderlyingSecurity {...props} />,
      valueViewer: props => <SelectValueViewer {...props} />,
      parent: capCells.STRIKE_PRICE_ALIAS,
      expr: '',
      linkedCellSymbols: [
        `@${capCells.STRIKE_PRICE_ROW_NUMBER}`,
        `@${capCells.SHARES_OUTSTANDING_ROW_NUMBER}`,
        `@${capCells.CONVERSION_RATE_ROW_NUMBER}`,
      ],
    },
    // 13.  Preferred Terms
    {
      value: 'Preferred Terms',
      defaultValue: '0',
      alias: capCells.PREFERRED_TERMS_ALIAS,
      hasSubRows: true,
      showSubRows: true,
      expr: '',
    },
    // 14.  Liquidation Preference
    {
      value: 'Liquidation Preference',
      defaultValue: '',
      alias: capCells.LIQUIDATION_PREFERENCE_ALIAS,
      parent: capCells.PREFERRED_TERMS_ALIAS,
      format: capTableXSuffixFormat,
      expr: '',
      gridType: 'number',
      dbType: 'string',
    },
    // 15.  Seniority
    {
      parent: capCells.PREFERRED_TERMS_ALIAS,
      value: 'Liquidation Priority',
      defaultTooltip:
        'The most senior liquidation priority should be given a \'1\', next senior assigned a \'2\', etc. Securities that are pari passu should be assigned the same liquidation priority number.',
      alias: capCells.SENIORITY_ALIAS,
      expr: '',
      gridType: 'number',
      dbType: 'number',
      displayUndefined: true,
    },
    // 16.  Participating
    {
      parent: capCells.PREFERRED_TERMS_ALIAS,
      value: 'Participating',
      alias: capCells.PARTICIPATION_ALIAS,
      expr: '',
      gridType: 'number',
      dbType: 'boolean',
      displayUndefined: true,
      linkedCellSymbols: [`@${capCells.IS_PARTICIPATION_CAP_ROW_NUMBER}`],
    },
    // 17.  Participation Cap
    {
      parent: capCells.PREFERRED_TERMS_ALIAS,
      value: 'Participation Cap',
      defaultValue: '0',
      alias: capCells.IS_PARTICIPATION_CAP_ALIAS,
      expr: '',
      gridType: 'number',
      dbType: 'boolean',
      displayUndefined: true,
      linkedCellSymbols: [`@${capCells.PARTICIPATION_CAP_ROW_NUMBER}`],
    },
    // 18.  Participation Cap (X)
    {
      value: 'Participation Cap (X)',
      defaultValue: '0',
      alias: capCells.PARTICIPATION_CAP_ALIAS,
      parent: capCells.PREFERRED_TERMS_ALIAS,
      format: xStandardSuffixFormat,
      expr: '',
      gridType: 'number',
      dbType: 'string',
    },
    // 19.  Cumulative Dividends
    {
      value: 'Cumulative Dividends',
      alias: capCells.CUMMULATIVE_DIVIDENDS_ALIAS,
      parent: capCells.PREFERRED_TERMS_ALIAS,
      readOnly: true,
      expr: '',
      gridType: 'number',
      dbType: 'boolean',
      displayUndefined: true,
      linkedCellSymbols: [
        `@${capCells.HAS_MULTIPLE_INVESTMENT_DATES_ROW_NUMBER}`,
        `@${capCells.DIVIDEND_PAYMENT_TYPE_ROW_NUMBER}`,
        `@${capCells.DIVIDEND_RATE_ROW_NUMBER}`,
        `@${capCells.COMPOUNDING_ROW_NUMBER}`,
        `@${capCells.COMPOUNDING_PERIOD_ROW_NUMBER}`,
      ],
    },
    // 20.  Multiple Investment Dates?
    {
      value: 'Multiple Investment Dates?',
      alias: capCells.HAS_MULTIPLE_INVESTMENT_DATES_ALIAS,
      parent: capCells.PREFERRED_TERMS_ALIAS,
      readOnly: true,
      hidden: true,
      disableEvents: false,
      expr: '',
      gridType: 'number',
      dbType: 'boolean',
      disableWhenLedgerOpen: true,
      displayUndefined: true,
      linkedCellSymbols: [`@${capCells.SHARES_OUTSTANDING_ROW_NUMBER}`, `@${capCells.ACCRUED_DIVIDENDS_ROW_NUMBER}`],
    },
    // 21.  Cash or PIK
    {
      value: 'Cash or PIK',
      alias: capCells.DIVIDEND_PAYMENT_TYPE_ALIAS,
      parent: capCells.PREFERRED_TERMS_ALIAS,
      readOnly: true,
      expr: '',
      gridType: 'number',
      dbType: 'number',
      displayUndefined: true,
    },
    // 22.  Dividend Rate
    {
      value: 'Dividend Rate',
      defaultValue: '0',
      alias: capCells.DIVIDEND_RATE_ALIAS,
      parent: capCells.PREFERRED_TERMS_ALIAS,
      readOnly: true,
      format: capTableTwoDecimalPercentFormat,
      dbDecimalPlaces: 3,
      expr: '',
      gridType: 'percentage',
      dbType: 'string',
    },
    // 23.  Compounding
    {
      value: 'Compounding',
      alias: capCells.COMPOUNDING_ALIAS,
      parent: capCells.PREFERRED_TERMS_ALIAS,
      expr: '',
      gridType: 'number',
      dbType: 'boolean',
      displayUndefined: true,
      linkedCellSymbols: [`@${capCells.COMPOUNDING_PERIOD_ROW_NUMBER}`, `@${capCells.ACCRUED_DIVIDENDS_ROW_NUMBER}`],
    },
    // 24.  Compounding Periods/Year
    {
      value: 'Compounding Periods/Year',
      alias: capCells.COMPOUNDING_PERIOD_ALIAS,
      parent: capCells.PREFERRED_TERMS_ALIAS,
      format: xLargeSuffixFormat,
      expr: '',
      gridType: 'number',
      dbType: 'string',
      minValue: 1,
      allowDecimals: false,
      allowNegativeValue: false,
    },
    // 25.  Accrued Dividends (as of 12/31/2018)
    {
      value: getAccruedDividendsTxt(measurementDate),
      defaultValue: '0',
      readonly: true,
      alias: capCells.ACCRUED_DIVIDENDS_ALIAS,
      format: largeCurrencyFormat,
      type: 'string',
      // In case we update/change this formula, we need to update the formula
      // on the backend as well (app.cap_table.models.Security.calculate_accrued_dividends)
      expr: `=IF(@3!=${CONVERTIBLE_NOTES}, IF(@20==N, IF(@23==Y,(@4 * (1+@22/@24) ^ (YEARFRAC(@2, '${measurementDate}', 3) * @24) - @4) * @5,@22*@4*@5*YEARFRAC(@2, '${measurementDate}', 3)), SUM_FROM_ACCRUED_LEDGER(@28, @23, @4, @22, @24, '${measurementDate}')), ACCRUED_FROM_CN_LEDGER(@31, @23, @4, @22, @24, '${measurementDate}'))`,
      altExpr: '=@28',
      gridType: 'number',
      dbType: 'string',
      className: 'row-label-bold',
      evalOrder: 0,
      disableWhenLedgerOpen: true,
    },
    // 26.  Initial Liquidation Preference
    {
      value: 'Initial Liquidation Preference',
      defaultValue: '0',
      alias: capCells.INITIAL_LIQUIDATION_PREFERENCE_ALIAS,
      hidden: false,
      readOnly: true,
      hasTotal: true,
      className: 'subtitle row-label-bold',
      format: largeCurrencyFormat,
      expr: `=IF(@3==${CONVERTIBLE_NOTES}, CONVERTIBLE_NOTE_INIT_LIQUIDATION_PREFERENCE(@31,@14), @5*@4*@14)`, // Shares outsanding * original issue price * liquidation preference
      gridType: 'number',
      dbType: 'string',
    },
    // 27.  Total Preference (with Dividends)
    {
      value: 'Total Preference (with Dividends)',
      defaultValue: '0',
      alias: capCells.TOTAL_PREFERENCE_ALIAS,
      readOnly: true,
      hasTotal: true,
      className: 'subtitle row-label-bold',
      format: largeCurrencyFormat,
      expr: '=@25+@26',
      gridType: 'number',
      dbType: 'string',
    },
    // 28.  multiple_investments
    {
      value: 'Multiple Investments',
      alias: capCells.MULTIPLE_INVESTMENT_LEDGER_ALIAS,
      gridType: LEDGER,
      isVisible: false,
      linkedCellSymbols: [`@${capCells.HAS_MULTIPLE_INVESTMENT_DATES_ROW_NUMBER}`],
    },
    // 29.  options_ledger
    {
      value: 'Options Ledger',
      alias: capCells.OPTIONS_LEDGER_ALIAS,
      gridType: LEDGER,
      isVisible: false,
    },
    // 30. Security Reference
    {
      value: 'Security Reference',
      alias: capCells.SECURITY_REFERENCE_ALIAS,
      isVisible: false,
    },
    // 31. Convertible Notes
    {
      value: capCells.CONVERTIBLE_NOTES_TITLE,
      alias: capCells.CONVERTIBLE_NOTES_ALIAS,
      isVisible: false,
      hidden: true,
      gridType: LEDGER,
      linkedCellSymbols: [
        `@${capCells.LIQUIDATION_PREFERENCE_ROW_NUMBER}`,
        `@${capCells.SENIORITY_ROW_NUMBER}`,
        `@${capCells.PARTICIPATON_ROW_NUMBER}`,
        `@${capCells.CUMMULATIVE_DIVIDENDS_ROW_NUMBER}`,
      ],
    },
    // 32. Total Note Principle
    {
      value: capCells.TOTAL_NOTE_PRINCIPLE_TITLE,
      alias: capCells.TOTAL_NOTE_PRINCIPLE_ALIAS,
      isVisible: false,
    },
    // 32. Total Note Value At Measurement Date
    {
      value: capCells.TOTAL_NOTE_VALUE_AT_MD_TITLE,
      alias: capCells.TOTAL_NOTE_VALUE_AT_MD_ALIAS,
      isVisible: false,
    },
  ];
  return tmpConfig.map(config => {
    config.value = customTitles[config.alias] || config.value;
    return config;
  });
};

export default rowConfig;
