/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, FC, useState } from 'react';
import { LoadingSection } from 'components';
import { gpcMetrics, LineBarChart, MetricDropdown, METRICS_OPTIONS } from 'dashboard409a/components';
import { compactFormatterNumber, CURRENT_REV_OPTIONS } from 'dashboard409a/utilities';
import { ICurrentRevenueProps } from './types';

const CurrentRevenue: FC<ICurrentRevenueProps> = ({ chartData, isLoading }) => {
  const [currentValue, setCurrentValue] = useState<string>(METRICS_OPTIONS[0]);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setCurrentValue(event.target.value as string);
  };

  const options = {
    ...CURRENT_REV_OPTIONS,
    scales: {
      equity: {
        display: true,
        position: 'left' as const,
        ticks: {
          stepSize: 10,
          callback: (value: number | string) => {
            if (typeof value === 'number') {
              return `${compactFormatterNumber.format(value)}${gpcMetrics[currentValue].symbol}`;
            }

            return value;
          },
        },
        grid: { display: true },
        autoSkip: true,
        maxTicksLimit: 9,
      },
      common: {
        display: false,
        position: 'right' as const,
      },
    },
  };

  const data = {
    labels: chartData?.map((item: any) => item.name),
    datasets: [
      {
        type: 'bar' as const,
        label: 'Guideline Public Companies', // TODO: Replace this to not be hardcoded
        yAxisID: 'equity',
        data: chartData?.map((item: any) => item[gpcMetrics[currentValue].default]),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: 'rgba(53, 162, 235, 0.5)',
      },
      // TODO: This data set is comment because at this moment I don't see this values in the api
      // {
      //   type: 'line' as const,
      //   label: 'Vandeley Industries', // TODO: Replace this to not be hardcoded
      //   yAxisID: 'common',
      //   data: chartData?.map((item: any) => 50),
      //   backgroundColor: 'rgba(60, 179, 113, 0.5)',
      //   borderColor: 'rgba(60, 179, 113, 0.5)',
      // },
    ],
  };

  return isLoading || !chartData ? (
    <LoadingSection />
  ) : (
    <>
      <MetricDropdown value={currentValue} title="Metric" handleChange={handleChange} />
      <LineBarChart data={data} options={options} />
    </>
  );
};

export default CurrentRevenue;
