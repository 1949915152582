import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Switch,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { CANNOT_UPDATE_2FA, PAGE_TITLE, REAUTHENTICATION_MESSAGE } from 'common/constants/login';
import { useStore } from 'common/store';
import { BackupCodes } from 'components';
import { LayoutContext } from 'context';
import LogoutButton from 'pages/Login/components/LogoutBtn';
import Otp from 'pages/Login/components/OTP';
import { AuthService } from 'services';
import { useResponse } from 'services/hooks';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  messageContainer: {
    marginTop: '1.5rem',
  },
  message: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: '1.5rem',
    marginRight: '1rem',
    display: 'inline-block',
  },
  user2fa: {
    paddingBottom: '1.5rem',
  },
  useStaticCodes: {
    marginTop: '1rem',
    color: theme.palette.gray[300],
  },
  staticCodesBox: {
    border: `1px solid ${theme.palette.gray[300]}`,
  },
  actionButton: {
    margin: '0.5rem',
  },
  buttonsArea: {
    marginRight: '2rem',
  },
  whiteBtn: {
    background: theme.palette.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#f7f7f7',
      boxShadow: 'none',
    },
  },
}));

const User2FA = () => {
  const [user2faStatus, setUser2faStatus] = useState({
    user_has_totp_device: false,
    confirmed: false,
    persistent_id: '',
  });
  const [backupCodes, setBackupCodes] = useState([]);
  const [showOTP, setShowOTP] = useState(false);
  const [isGeneratingBackupCodes, setIsGeneratingBackupCodes] = useState(false);
  const [displayReauthenticate, setDisplayReauthenticate] = useState(false);
  const [{ user }] = useStore();
  const classes = useStyles();
  const { processErrorResponse } = useResponse();
  const { setPageTitle, setPageBreadcrumbs } = useContext(LayoutContext);

  const handleDialogClose = async (_event, reason) => {
    if (reason && reason === 'backdropClick') {
      setShowOTP(false);
    }
  };

  const handle2faChange = async event => {
    const { checked } = event.target;

    // Launch the OTP dialog if the user is setting up 2FA for the first time
    if (!user2faStatus.user_has_totp_device && checked) {
      setShowOTP(true);
      return;
    }

    const updateTOTP = async () => {
      const authService = AuthService.getInstance();
      try {
        if (!checked) {
          await authService.deleteTOTP();
          setUser2faStatus({
            user_has_totp_device: false,
            confirmed: false,
          });
        } else {
          setShowOTP(true);
        }
      } catch (err) {
        processErrorResponse({
          error: err,
          defaultErrorMessage: CANNOT_UPDATE_2FA,
        });
        // Revert the switch status to the previous state if there was an error
        setUser2faStatus(prevStatus => ({
          ...prevStatus,
          confirmed: !checked,
        }));
      }
    };

    if (user2faStatus.user_has_totp_device) {
      updateTOTP();
    }
  };

  const generateNewBackupCodes = useCallback(async () => {
    setIsGeneratingBackupCodes(true);

    const authService = AuthService.getInstance();

    try {
      const response = await authService.createBackupCodes(user2faStatus);
      if (response) {
        setBackupCodes(response.tokens);
      }
    } catch (error) {
      // If we recieve a Forbidden error we need to reauthenticate the user
      if (error?.status === 403) setDisplayReauthenticate(true);
    }
    setIsGeneratingBackupCodes(false);
  }, [user2faStatus]);

  const breadcrumbs = useMemo(() => {
    if (user) {
      return [
        {
          title: PAGE_TITLE,
        },
        {
          title: user.email,
        },
      ];
    }

    return [];
  }, [user]);

  useEffect(() => {
    setPageTitle(PAGE_TITLE);
    setPageBreadcrumbs(breadcrumbs);

    return () => {
      setPageTitle(null);
      setPageBreadcrumbs(null);
    };
  }, [breadcrumbs, setPageTitle, setPageBreadcrumbs]);

  useEffect(() => {
    const getUserTOTP = async () => {
      try {
        const authService = AuthService.getInstance();
        const response = await authService.getTOTP();

        if (response) {
          setUser2faStatus(response);
        }
      } catch (error) {
        throw new Error(error);
      }
    };

    if (user) {
      getUserTOTP();
    }
  }, [user]);

  useEffect(() => {
    const getUserBackupCodes = async () => {
      const authService = AuthService.getInstance();

      try {
        const response = await authService.getBackupCodes();

        if (response) {
          setBackupCodes(response.tokens);
          if (response.tokens.length === 0) {
            await generateNewBackupCodes();
          }
        }
      } catch (error) {
        // If we receive a 404 error - "No TOTP device found", we need to generate new backup codes
        if (error?.status === 404) await generateNewBackupCodes();
      }
    };

    if (user2faStatus.user_has_totp_device) {
      getUserBackupCodes();
    }
  }, [generateNewBackupCodes, user2faStatus]);

  return (
    <>
      <Accordion>
        <AccordionSummary className="accordionSummary" expandIcon={<ExpandMoreIcon className="icon" />}>
          <Typography className={classes.heading}>Activate/Deactivate {PAGE_TITLE}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup row className={classes.user2fa}>
            <FormControlLabel
              control={
                <Switch
                  checked={user2faStatus.confirmed && backupCodes.length > 0}
                  onChange={handle2faChange}
                  name="user2fa"
                  color="primary"
                  disabled={false}
                  disableRipple
                />
              }
              label={
                <Typography variant="body2" color="textPrimary">
                  Enable {PAGE_TITLE}
                </Typography>
              }
              labelPlacement="start"
            />
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      {displayReauthenticate
        ? user2faStatus?.confirmed && (
          <Grid className={classes.messageContainer} alignItems="center" container>
            <Grid item>
              <Typography className={classes.message}>{REAUTHENTICATION_MESSAGE}</Typography>
            </Grid>

            <Grid item>
              <LogoutButton />
            </Grid>
          </Grid>
        )
        : user2faStatus?.confirmed
          && backupCodes.length > 0 && (
          <Accordion>
            <AccordionSummary className="accordionSummary" expandIcon={<ExpandMoreIcon className="icon" />}>
              <Typography className={classes.heading}>{PAGE_TITLE} Backup Codes</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <BackupCodes
                backupCodes={backupCodes}
                disabledGenerate={!user2faStatus?.confirmed}
                handleGenerateNewCodes={generateNewBackupCodes}
                isGeneratingBackupCodes={isGeneratingBackupCodes}
                username={user?.email}
              />
            </AccordionDetails>
          </Accordion>
        )}

      <Dialog open={showOTP} onClose={handleDialogClose}>
        <Otp
          userHas2FA={false}
          showActionButtons={false}
          setDeviceData={setUser2faStatus}
          setDisplayReauthenticate={setDisplayReauthenticate}
        />
      </Dialog>
    </>
  );
};

export default User2FA;
