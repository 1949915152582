import { chipPercentFormat, currencyFormat, dashboardPercentFormatWithNegativeValues } from 'common/formats/formats';
import { Approach } from 'dashboard409a/services/types';
import { RowConfigProps } from '../types';

// Titles
export const TABLE_TITLE = 'Valuation Methods';
export const TABLE_SECOND_TITLE = 'Market Value of Invested Capital';
export const WEIGHTED_ENTERPRISE_MARKET_VALUE_TITLE = 'Weighted Enterprise Market Value';
export const LESS_DEBT_TITLE = 'Less Debt';
export const WEIGHTED_EQUITY_VALUE_TITLE = 'Weighted Equity Value of Invested Capital';
export const EQUITY_ALLOCATION_SUMMARY_TITLE = 'Equity Allocation Summary';
export const VALUE_PER_COMMON_SHARE_TITLE = 'Value per Common Share Marketable minority basis';
export const DISCOUNT_FOR_LACK_OF_MARKETABILITY_TITLE = 'Discount for Lack of Marketability';
export const VALUE_PER_COMMON_SHARE2_TITLE = 'Value per Common Share Marketable minority basis';

// Alias
export const WEIGHTED_ENTERPRISE_MARKET_VALUE_ALIAS = 'weighted_market_enterprise_value';
export const LESS_DEBT_ALIAS = 'less_debt';
export const WEIGHTED_EQUITY_VALUE_ALIAS = 'weighted_equity_value';
export const EQUITY_ALLOCATION_SUMMARY_ALIAS = 'equity_allocation_summary';
export const VALUE_PER_COMMON_SHARE_ALIAS = 'marketable_value_per_common_share';
export const DISCOUNT_FOR_LACK_OF_MARKETABILITY_ALIAS = 'discount_for_lack_of_marketability';
export const VALUE_PER_COMMON_SHARE2_ALIAS = 'non_marketable_value_per_common_share';

const baseCurrencyFormat = currencyFormat({ fractionDigits: 0 });

const valuePerCommonCurrencyFormat = currencyFormat({ fractionDigits: 2 });

const getApproachesRows = (approaches: Approach[] | undefined) =>
  approaches && approaches.length > 0
    ? approaches.map((approach: Approach) => ({
      value: approach.name,
      alias: `approach-${approach.id}`,
      readOnly: true,
      gridType: 'number',
      format: baseCurrencyFormat,
      allowNegativeValue: true,
      chip: {
        value: approach.weight,
        format: chipPercentFormat,
        dbDecimalPlaces: 0,
        gridType: 'percentage',
      },
    }))
    : [];

const rowConfig = ({ approaches }: RowConfigProps) => [
  {
    className: 'table-header val-methods-table-header division-bottom-only',
    value: TABLE_TITLE,
    alias: 'title',
    readOnly: true,
    gridType: 'string',
  },
  ...getApproachesRows(approaches),
  {
    value: WEIGHTED_ENTERPRISE_MARKET_VALUE_TITLE,
    alias: WEIGHTED_ENTERPRISE_MARKET_VALUE_ALIAS,
    readOnly: true,
    gridType: 'number',
    format: baseCurrencyFormat,
    allowNegativeValue: true,
    className: 'division-top-only',
  },
  {
    value: LESS_DEBT_TITLE,
    alias: LESS_DEBT_ALIAS,
    readOnly: true,
    gridType: 'number',
    format: baseCurrencyFormat,
    allowNegativeValue: true,
  },
  {
    value: WEIGHTED_EQUITY_VALUE_TITLE,
    alias: WEIGHTED_EQUITY_VALUE_ALIAS,
    readOnly: true,
    gridType: 'number',
    format: baseCurrencyFormat,
    allowNegativeValue: true,
    className: 'row-label-title',
  },
  {
    value: EQUITY_ALLOCATION_SUMMARY_TITLE,
    alias: EQUITY_ALLOCATION_SUMMARY_ALIAS,
    readOnly: true,
    gridType: 'string',
    className: 'row-with-primary-bg',
  },
  {
    value: VALUE_PER_COMMON_SHARE_TITLE,
    alias: VALUE_PER_COMMON_SHARE_ALIAS,
    readOnly: true,
    gridType: 'number',
    format: valuePerCommonCurrencyFormat,
    allowNegativeValue: true,
  },
  {
    value: DISCOUNT_FOR_LACK_OF_MARKETABILITY_TITLE,
    alias: DISCOUNT_FOR_LACK_OF_MARKETABILITY_ALIAS,
    readOnly: true,
    format: dashboardPercentFormatWithNegativeValues,
    dbDecimalPlaces: 2,
    gridType: 'percentage',
    dbType: 'string',
  },
  {
    value: VALUE_PER_COMMON_SHARE2_TITLE,
    alias: VALUE_PER_COMMON_SHARE2_ALIAS,
    readOnly: true,
    gridType: 'number',
    format: valuePerCommonCurrencyFormat,
    allowNegativeValue: true,
    className: 'row-label-title',
  },
];

export default rowConfig;
