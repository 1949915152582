import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    width: '100%',
    fontSize: 10,
    padding: '2px 12px',
  },
});

const GridActionButton = ({ onClick, label, ...restProps }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.root}
      color="secondary"
      id="grid-action-btn"
      onClick={onClick}
      variant="contained"
      {...restProps}>
      {label}
    </Button>
  );
};

GridActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default GridActionButton;
