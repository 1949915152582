import API from '../config';

const base = `${API.BASE_DEV_HOST}/api/financials/financial_page`;

export const retrieve = id => `${base}/${id}/`;
export const update = id => `${base}/${id}/`;
export const destroy = id => `${base}/${id}/`;
export const create = () => `${base}/`;
export const list = () => `${base}/`;
export const createVersion = id => `${base}/${id}/create_version/`;
export const listByMeasurementPeriod = measurementPeriodId =>
  `${base}/${measurementPeriodId}/list_by_measurement_period/`;
export const getByMeasurementDateId = measurementPeriodId => `${base}/${measurementPeriodId}/get_by_measurement_date/`;
export const financialLtmNtm = (measurementPeriodId, versionId) => {
  if (versionId) {
    return `${base}/${measurementPeriodId}/financial_ltm_ntm/?version=${versionId}`;
  }
  return `${base}/${measurementPeriodId}/financial_ltm_ntm/`;
};
export const getFinancialPeriodsFiscalYear = (companyMeasurementDateId, versionId) => {
  if (versionId) {
    return `${base}/${companyMeasurementDateId}/financial_rfyd/?version=${versionId}`;
  }
  return `${base}/${companyMeasurementDateId}/financial_rfyd/`;
};

export const getFinancialsBasicDataListByMD = measurementDateId =>
  `${base}/${measurementDateId}/financials_basic_data_list/`;
