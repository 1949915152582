export const TOTAL_REVENUE_TITLE = 'Total Revenue';
export const TOTAL_REVENUE_ALIAS = 'total_revenue';
export const TOTAL_REVENUE_ROW_NUMBER = 4;

export const TOTAL_SALES_COST_TITLE = 'Total Cost of Sales';
export const TOTAL_SALES_COST_ALIAS = 'total_sales_cost';
export const TOTAL_SALES_COST_ROW_NUMBER = 5;

export const GROSS_PROFIT_TITLE = 'Gross Profit';
export const GROSS_PROFIT_ALIAS = 'gross_profit';
export const GROSS_PROFIT_ROW_NUMBER = 6;
export const GROSS_PROFIT_EXPR = `=@${TOTAL_REVENUE_ROW_NUMBER}-@${TOTAL_SALES_COST_ROW_NUMBER}`;

export const OPERATING_EXPENSES_TITLE = 'Operating Expenses';
export const OPERATING_EXPENSES_ALIAS = 'operating_expenses';
export const OPERATING_EXPENSES_ROW_NUMBER = 7;

export const EBITDA_TITLE = 'EBITDA';
export const EBITDA_ALIAS = 'ebitda';
export const EBITDA_ROW_NUMBER = 8;
export const EBITDA_EXPR = `=@${GROSS_PROFIT_ROW_NUMBER}-@${OPERATING_EXPENSES_ROW_NUMBER}`;

export const ADJUSTED_EBITDA_TITLE = 'Adjusted EBITDA';
export const ADJUSTED_EBITDA_ALIAS = 'adjusted_ebitda';
export const ADJUSTED_EBITDA_ROW_NUMBER = 9;

export const DEPRECIATION_EXPENSE_TITLE = 'Depreciation Expense';
export const DEPRECIATION_EXPENSE_ALIAS = 'depreciation_expense';
export const DEPRECIATION_EXPENSE_ROW_NUMBER = 10;

export const AMORTIZATION_EXPENSE_TITLE = 'Amortization Expense';
export const AMORTIZATION_EXPENSE_ALIAS = 'amortization_expense';
export const AMORTIZATION_EXPENSE_ROW_NUMBER = 11;

export const EBIT_TITLE = 'EBIT';
export const EBIT_ALIAS = 'ebit';
export const EBIT_EXPR = `=@${EBITDA_ROW_NUMBER}-(@${DEPRECIATION_EXPENSE_ROW_NUMBER}+@${AMORTIZATION_EXPENSE_ROW_NUMBER})`;
export const EBIT_ROW_NUMBER = 12;

export const INTEREST_EXPENSE_TITLE = 'Interest Expense / (Income)';
export const INTEREST_EXPENSE_ALIAS = 'interest_expense';
export const INTEREST_EXPENSE_ROW_NUMBER = 13;

export const OTHER_EXPENSE_TITLE = 'Other Expense / (Income)';
export const OTHER_EXPENSE_ALIAS = 'other_expense';
export const OTHER_EXPENSE_ROW_NUMBER = 14;

export const PRETAX_INCOME_TITLE = 'Pretax Income';
export const PRETAX_INCOME_ALIAS = 'pretax_income';
export const PRETAX_INCOME_EXPR = `=@${EBIT_ROW_NUMBER}-(@${INTEREST_EXPENSE_ROW_NUMBER}+@${OTHER_EXPENSE_ROW_NUMBER})`;
export const PRETAX_INCOME_ROW_NUMBER = 15;

export const INCOME_TAXES_TITLE = 'Income Taxes';
export const INCOME_TAXES_ALIAS = 'income_taxes';
export const INCOME_TAXES_ROW_NUMBER = 16;

export const NET_INCOME_TITLE = 'Net Income';
export const NET_INCOME_ALIAS = 'net_income';
export const NET_INCOME_EXPR = `=@${PRETAX_INCOME_ROW_NUMBER}-@${INCOME_TAXES_ROW_NUMBER}`;
export const NET_INCOME_ROW_NUMBER = 17;

export const ALIASES_TO_UPDATE_INCOME_STATEMENT = [
  TOTAL_REVENUE_ALIAS,
  TOTAL_SALES_COST_ALIAS,
  OPERATING_EXPENSES_ALIAS,
  ADJUSTED_EBITDA_ALIAS,
  DEPRECIATION_EXPENSE_ALIAS,
  AMORTIZATION_EXPENSE_ALIAS,
  INTEREST_EXPENSE_ALIAS,
  OTHER_EXPENSE_ALIAS,
  INCOME_TAXES_ALIAS,
];
