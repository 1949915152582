/* eslint-disable import/prefer-default-export */
import React from 'react';
import { largeCurrencyFormat, sharesFormat } from 'common/formats/formats';
import { GridDateEditor, GridDateValueViewer } from 'components/FeaturedSpreadsheet/components';
import { LedgerGridDate } from 'components/LedgerTable/components';
import { CONVERSION_DATE_ALIAS } from 'pages/CapTable/fund-ownership/components/shares-ledger/constants';
import generatePurchaseDateOptions from 'pages/CapTable/fund-ownership/components/shares-ledger/utilities/generatePurchaseDateOptions';
import generatePurchaseDateBaseConfig from '../../utilities/generatePurchaseDateBaseConfig';

export const colConfig = acquisitions => {
  const acquisitionOptions = generatePurchaseDateOptions(acquisitions);
  const purchaseDatePickerConfig = generatePurchaseDateBaseConfig(acquisitionOptions);
  purchaseDatePickerConfig.convertedTable = true;

  return [
    purchaseDatePickerConfig,
    {
      value: 'Date Converted',
      alias: CONVERSION_DATE_ALIAS,
      width: '175px',
      gridDateComponent: LedgerGridDate,
      dataEditor: props => <GridDateEditor {...props} />,
      valueViewer: props => <GridDateValueViewer {...props} slashFormatted />,
      isRequired: true,
    },
    {
      value: 'Shares',
      alias: 'shares',
      width: '175px',
      format: sharesFormat,
      minValue: 1,
      gridType: 'number',
      isRequired: true,
    },
    {
      value: 'Converted Amount',
      alias: 'amount',
      width: '175px',
      format: largeCurrencyFormat,
      gridType: 'number',
      isRequired: true,
    },
  ];
};

export default colConfig;
