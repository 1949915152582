import React, { useContext, useMemo } from 'react';
import { Button, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { DELETE_COMPANY_ENABLER } from 'common/constants/features';
import { useStore } from 'common/store';
import FeatureEnabler from 'components/FeatureEnabler';
import DialogContext from 'context/DialogContext';
import ConfirmationCancelDialog from './ConfirmationCancelDialog';
import DeleteButton from './DeleteButton';
import Dialog from './Dialog';

const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: theme.spacing(2),
  },
  content: {
    marginBottom: theme.spacing(4),
  },
  actions: {
    margin: 0,
    padding: theme.spacing(1),
  },
  actionButtonLabel: {
    fontSize: '0.8750em',
  },
  autoMarginRight: {
    marginRight: 'auto',
  },
  dangerButton: {
    color: theme.palette.pink,
  },
  disabledButton: {
    color: theme.palette.grey,
  },
}));

const FormDialog = ({
  title,
  hideTitle,
  open,
  editMode,
  isValid,
  children,
  onSave,
  onDelete,
  deleteText,
  onClose,
  confirmCancel,
  subtitle,
  bigDialog,
  PaperProps,
  isLoading,
  loadingText,
  customButtonLabel,
  shouldCloseOnDelete,
  allowDelete,
  toolTipText,
  turnOnDeleteFunctionality,
  customActionButtonLabel,
  onCustomAction,
  showCancelButton = true,
  disableTitleClose = true,
  showSaveButton = true,
  customCancelButton,
  customButton,
  classesContent,
  shouldCheckFeatureEnabler = false,
}) => {
  const classes = useStyles();
  const [{ isShowLoadingProgress }] = useStore();
  const { showDialog } = useContext(DialogContext);

  const handleSave = () => {
    const result = onSave();
    if (result instanceof Promise) {
      result
        .then(aux => onClose(aux))
        .catch(() => {
          onClose(result);
        });
    } else {
      onClose(result);
    }
  };

  const handleDelete = async () => {
    if (shouldCloseOnDelete) {
      const aux = await onDelete();
      onClose(aux);
    } else {
      await onDelete();
    }
  };

  const handleClose = () => {
    if (confirmCancel && !editMode) {
      showDialog({
        wrapper: ConfirmationCancelDialog,
        actions: [
          {
            label: 'Cancel Anyways',
            variant: 'contained',
            type: 'danger',
            callback: onClose,
          },
        ],
      });
    } else {
      onClose();
    }
  };

  const getActionButtonText = () => {
    if (editMode) return 'Update';
    return 'Save';
  };

  const showCustomActionButton = customActionButtonLabel && onCustomAction;
  let showDeleteButton = !isUndefined(onDelete) && (editMode || allowDelete);

  if (turnOnDeleteFunctionality === false) {
    // Hide the button when the delete functionality is not allowed
    showDeleteButton = false;
  }

  const enableDeleteButton = useMemo(
    () => shouldCheckFeatureEnabler || allowDelete,
    [shouldCheckFeatureEnabler, allowDelete]
  );

  const displayDeleteBtn = () => {
    if (shouldCheckFeatureEnabler) {
      return (
        <FeatureEnabler enabler={DELETE_COMPANY_ENABLER} hideComponent>
          <DeleteButton
            toolTipText={toolTipText}
            allowDelete={allowDelete}
            enableDeleteButton={enableDeleteButton}
            deleteText={deleteText}
            handleDelete={handleDelete}
          />
        </FeatureEnabler>
      );
    }
    return (
      <DeleteButton
        toolTipText={toolTipText}
        allowDelete={allowDelete}
        enableDeleteButton={enableDeleteButton}
        deleteText={deleteText}
        handleDelete={handleDelete}
      />
    );
  };

  return (
    <Dialog
      title={title}
      hideTitle={hideTitle}
      subtitle={subtitle}
      maxWidth={bigDialog ? 'md' : 'sm'}
      fullWidth
      onClose={onClose}
      open={open}
      dialogProps={{
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        disableTitleClose,
      }}
      PaperProps={PaperProps}
      isLoading={isLoading}
      loadingText={loadingText}>
      <div className={classesContent || classes.content}>{children}</div>
      <DialogActions className={classes.actions}>
        {showDeleteButton && displayDeleteBtn()}
        {showCustomActionButton && (
          <Button
            id="custom-btn"
            className={clsx(classes.actionButton, classes.autoMarginRight)}
            color="primary"
            variant="text"
            onClick={onCustomAction}>
            {customActionButtonLabel}
          </Button>
        )}
        {showCancelButton && !customCancelButton && (
          <Button
            id="company-cancel-btn"
            className={classes.actionButton}
            color="primary"
            onClick={handleClose}
            disabled={isShowLoadingProgress}>
            Cancel
          </Button>
        )}
        {showSaveButton && (
          <Button
            id="company-save-btn"
            className={classes.actionButton}
            variant="contained"
            color="secondary"
            onClick={handleSave}
            disabled={!isValid || isShowLoadingProgress}>
            {customButtonLabel || getActionButtonText()}
          </Button>
        )}
        {customCancelButton}
        {customButton}
      </DialogActions>
    </Dialog>
  );
};

FormDialog.defaultProps = {
  confirmCancel: false,
  bigDialog: false,
  isLoading: false,
  shouldCloseOnDelete: true,
};

FormDialog.propTypes = {
  title: PropTypes.string,
  hideTitle: PropTypes.bool,
  subtitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  isValid: PropTypes.bool,
  confirmCancel: PropTypes.bool,
  onSave: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Promise)]),
  onDelete: ({ onDelete, deleteText }, propName, componentName) => {
    if (onDelete && !deleteText) {
      return new Error(`If onDelete is specified in '${componentName}', then deleteText must also be defined`);
    }
    if (!isUndefined(onDelete) && typeof onDelete !== 'function') {
      return new Error(`onDelete should be a function in ${componentName}`);
    }
    return null;
  },
  deleteText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  children: PropTypes.element.isRequired,
  bigDialog: PropTypes.bool,
  PaperProps: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  customButtonLabel: PropTypes.string,
  shouldCloseOnDelete: PropTypes.bool,
  allowDelete: PropTypes.bool,
  toolTipText: PropTypes.string,
  turnOnDeleteFunctionality: PropTypes.bool,
  customActionButtonLabel: PropTypes.string,
  onCustomAction: PropTypes.func,
  showCancelButton: PropTypes.bool,
  disableTitleClose: PropTypes.bool,
  showSaveButton: PropTypes.bool,
  customCancelButton: PropTypes.element,
  customButton: PropTypes.element,
  classesContent: PropTypes.shape({}),
  shouldCheckFeatureEnabler: PropTypes.bool,
};

export default FormDialog;
