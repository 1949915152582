import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';

const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort, columns }) => {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const orderHandler = (sortArg, sortDefault) => {
    if (orderBy === sortArg) {
      return order;
    }
    return sortDefault;
  };

  const isActive = headCell => orderBy === headCell.id;

  const getSortIconStyle = headcell => {
    const iconStatus = isActive(headcell) ? classes.activeSortIcon : classes.inactiveSortIcon;
    return { icon: `${classes.sortIcon} ${iconStatus}` };
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {columns.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={orderHandler(headCell.id, false)}>
            {headCell.isSortable ? (
              <TableSortLabel
                className={classes.sortLabel}
                active={isActive(headCell)}
                direction={orderHandler(headCell.id, 'asc')}
                onClick={createSortHandler(headCell.id)}
                classes={getSortIconStyle(headCell)}
                IconComponent={ArrowDropDownIcon}>
                <span className={classes.tableHeadLabel}>{headCell.label}</span>
                {isActive(headCell) && <span className={classes.visuallyHidden}>{`sorted ${order}ending`}</span>}
              </TableSortLabel>
            ) : (
              <span className={classes.tableHeadLabel}>{headCell.label}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.shape({
    tableHead: PropTypes.string.isRequired,
    tableHeadLabel: PropTypes.string.isRequired,
    sortLabel: PropTypes.string.isRequired,
    sortIcon: PropTypes.string.isRequired,
    activeSortIcon: PropTypes.string.isRequired,
    inactiveSortIcon: PropTypes.string.isRequired,
    visuallyHidden: PropTypes.string.isRequired,
    tableUser: PropTypes.string.isRequired,
    table: PropTypes.string.isRequired,
    paper: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
    emptyFilesMsg: PropTypes.string.isRequired,
  }).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      isSortable: PropTypes.bool,
      align: PropTypes.string,
      disablePadding: PropTypes.bool,
    })
  ).isRequired,
};

export default EnhancedTableHead;
