/**
 * @name Allocations Config
 * @memberof module:common/config
 * @type {Config}
 * @return {Object} Configurations of API
 */

import { API } from '../config';

const allocations = `${API.BASE_DEV_HOST}/api/allocations`;
const allocationScenarios = `${API.BASE_DEV_HOST}/api/allocation-scenario`;
const allocationObservation = `${API.BASE_DEV_HOST}/api/observations`;

export const byId = `${allocations}`;
export const updateById = `${allocations}`;
export const updateScenariosById = `${allocations}`;
export const postScenarioValues = `${allocationScenarios}/allocation-results`;
export const publishAllocation = `${allocations}`;
export const makeFinal = ids => `${allocations}/final?ids=${ids}`;
export const createVersion = `${allocations}/version`;
export const getScenarioEquityValue = id => `${allocationScenarios}/${id}/backsolve`;
export const calculateBacksolveEquityValue = `${allocations}/calculate-backsolve`;
export const getRiskFreeRateByDate = (maturity, queryDate) => `${allocationObservation}/${maturity}/${queryDate}`;
