import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import { MAX_HISTORICAL_YEARS } from 'common/config/app';
import { FINANCIAL_TITLE } from 'common/constants/financials';
import { useCompanyBreadcrumb } from 'common/hooks';
import { ConfirmationDeleteWithList, ConfirmationDialog } from 'components/Dialogs';
import { DialogContext } from 'context';
import { useFinancials } from 'context/FinancialsContext';
import LayoutContext from 'context/LayoutContext';
import bsReverseParser from 'pages/Financials/balance-sheet/utilities/reverseParser';
import addFinancialYear from 'pages/Financials/utilities/addFinancialYear';
import getFinancialsData from 'pages/Financials/utilities/getFinancialsData';
import saveFinancialStatement from 'pages/Financials/utilities/saveFinancialStatement';
import updateOrCreateFinancialStatement from 'pages/Financials/utilities/updateOrCreateFinancialStatement';
import { useGetAllocationStatusBreadcrumb } from 'services/hooks/allocation';
import { useCreateFinancialStatement, useUpdateFinancialStatement } from 'services/hooks/financialStatement';
import { getStringValue, toString } from 'utillities';
import ConfirmationFinal from '../components/ConfirmationFinal';

// this hook is used to render UI related to the financials page, but does not load any data or make any API calls
const useFinancialsPageHelpers = ({
  financialStatement,
  setFinancialStatement,
  tableData,
  periods,
  companyMeasurementDate,
  getVersions,
  measurementDates,
  measurementDate,
  filesParams,
  notesParams,
  createVersion,
  fiscalYearData,
  setCollapsibleColumns,
  collapsibleColumns,
  showAdditionalHistoricalYears,
  setShowAdditionalHistoricalYears,
  additionalHistoricalYears,
  setAdditionalHistoricalYears,
  selectedVersion,
  isDisabled,
  isAllocationFinal,
}) => {
  const [isNewVersionDialogOpen, setOpenNewVersionDialog] = useState(false);
  const [isNameDialogOpen, setIsNameDialogOpen] = useState(false);
  const { updatePageActions } = useContext(LayoutContext);
  const [create] = useCreateFinancialStatement();
  const [update] = useUpdateFinancialStatement();
  const [addingNewYear, setAddingNewYear] = useState(false);
  const { showDialog } = useContext(DialogContext);
  const { useAdjustedEBITDA } = useFinancials();
  const [allocationStatusBreadcrumb] = useGetAllocationStatusBreadcrumb();

  const { icon: status, title: label } = allocationStatusBreadcrumb;

  const financialsBreadcrumb = useMemo(
    () => ({
      page: FINANCIAL_TITLE,
      item: selectedVersion
        ? {
          label: getStringValue(selectedVersion?.name),
          allocationVersion: {
            label,
            status,
          },
        }
        : {},
      customAction: () => setIsNameDialogOpen(true),
    }),
    [label, selectedVersion, status]
  );

  useCompanyBreadcrumb(financialsBreadcrumb);

  useEffect(() => {
    if (addingNewYear && !showAdditionalHistoricalYears && !isEmpty(additionalHistoricalYears)) {
      setShowAdditionalHistoricalYears(true);
      setAddingNewYear(false);
    }
  }, [addingNewYear, additionalHistoricalYears, showAdditionalHistoricalYears, setShowAdditionalHistoricalYears]);

  const getUpdatedTableData = useCallback(
    showAdditionalHistoricalYearValue => {
      if (tableData) {
        const additionalHistoricalCallback = item => {
          if (item.isParent && item.isAdditionalHistoricalYear) {
            return {
              ...item,
              showAdditionalHistoricalYear: showAdditionalHistoricalYearValue,
            };
          }
          return item;
        };
        const incomeStatement = tableData?.incomeStatement?.reverseParse().map(additionalHistoricalCallback);
        const performanceMetrics = tableData?.performanceMetrics?.columns.map(additionalHistoricalCallback);
        const balanceSheet = bsReverseParser(tableData?.balanceSheet).map(additionalHistoricalCallback);
        tableData.incomeStatement.reset({ columns: incomeStatement });
        tableData.performanceMetrics.reset({ columns: performanceMetrics });
        tableData.balanceSheet.reset({ columns: balanceSheet });

        if (!isEmpty(tableData.KPI)) {
          const kpi = tableData.KPI.reverseParse().map(additionalHistoricalCallback);
          tableData.KPI.reset({ columns: kpi });
        }
        return tableData;
      }
    },
    [tableData]
  );

  const showOrHideEarliestHistoricalYears = useCallback(
    async (shouldEvaluateSpreadsheet = true) => {
      if (tableData) {
        const shouldShowAdditionalHistoricalYears = !showAdditionalHistoricalYears;
        setShowAdditionalHistoricalYears(shouldShowAdditionalHistoricalYears);
        const updatedTableData = getUpdatedTableData(shouldShowAdditionalHistoricalYears);
        if (!shouldEvaluateSpreadsheet) return updatedTableData;

        const sheetsToEvaluate = [
          updatedTableData.incomeStatement,
          updatedTableData.balanceSheet,
          updatedTableData.performanceMetrics,
        ];
        if (!isEmpty(updatedTableData.KPI)) sheetsToEvaluate.push(updatedTableData.KPI);
        await tableData.evaluateSpreadsheets(sheetsToEvaluate);
      }
    },
    [getUpdatedTableData, setShowAdditionalHistoricalYears, showAdditionalHistoricalYears, tableData]
  );

  const addCollapsedColumn = useCallback(
    id => {
      setCollapsibleColumns(prevColumns => ({
        ...prevColumns,
        [id]: true,
      }));
    },
    [setCollapsibleColumns]
  );

  const scrollToEndOfTables = useCallback(isPrevYear => {
    const financialTables = document.querySelectorAll('#financials .scrollbar-container');

    if (isPrevYear) {
      Array.from(financialTables).forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.scrollLeft = 0;
      });
    } else {
      Array.from(financialTables).forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.scrollLeft = item.scrollWidth;
      });
    }
  }, []);

  const addYearFn = useCallback(
    async ({ isPrevYear }) => {
      let updatedTableData = tableData;
      if (!showAdditionalHistoricalYears && !isEmpty(additionalHistoricalYears)) {
        updatedTableData = await showOrHideEarliestHistoricalYears(false);
      }
      const currentPeriod = moment(measurementDate?.date);
      const currentPeriodYear = currentPeriod.year();
      const defaultLimitYear = currentPeriodYear - MAX_HISTORICAL_YEARS;
      await addFinancialYear({
        tableData: updatedTableData,
        financialStatement,
        periods,
        fiscalYearData,
        addCollapsedColumn,
        scrollToEndOfTables,
        defaultLimitYear,
        additionalHistoricalYears,
        setAdditionalHistoricalYears,
        isPrevYear,
      });
      setAddingNewYear(true);
    },
    [
      addCollapsedColumn,
      additionalHistoricalYears,
      financialStatement,
      fiscalYearData,
      periods,
      scrollToEndOfTables,
      measurementDate,
      setAdditionalHistoricalYears,
      showOrHideEarliestHistoricalYears,
      tableData,
      showAdditionalHistoricalYears,
    ]
  );

  /* periodIndex/colIndex is not reliable as an indicator of where to delete
  (periods remain untouched, but colIndex doesn't) */
  const deleteColumn = useCallback(
    (columnIndex, periodId, wb) => {
      /*
    - if fs is saved periodId is number string and period.id is integer, and we need casting
    - delete icon should be enabled only in years. Need to delete here their quarters.
    */
      const tmpPeriods = [...periods];
      const yearToDelete = tmpPeriods.find(period => toString(period.id) === toString(periodId));
      yearToDelete.is_deleted = true;

      tmpPeriods
        .filter(period => period.parentColumn?.toString() === toString(periodId))
        .forEach(period => {
          // eslint-disable-next-line no-param-reassign
          period.is_deleted = true;
        });
      // remove from collapsibleColumns
      const tmpCollapsibleColumns = { ...collapsibleColumns };
      delete tmpCollapsibleColumns[periodId];
      wb.incomeStatement.deleteColumns(columnIndex - 4, 5);
      wb.balanceSheet.deleteColumns(columnIndex - 4, 5);
      wb.performanceMetrics.deleteColumns(columnIndex - 4, 5);
      const spreadsheetsToEvaluate = [wb.incomeStatement, wb.balanceSheet, wb.performanceMetrics];

      if (!isEmpty(wb.KPI)) {
        wb.KPI.deleteColumns(columnIndex - 4, 5);
        spreadsheetsToEvaluate.push(wb.KPI);
      }

      wb.evaluateSpreadsheets(spreadsheetsToEvaluate);
      setCollapsibleColumns(tmpCollapsibleColumns);

      const currentPeriodId = Number.isNaN(Number(periodId)) ? periodId : Number(periodId);
      const updatedAdditionalHistoricalYears = additionalHistoricalYears.filter(year => year.id !== currentPeriodId);
      setAdditionalHistoricalYears(updatedAdditionalHistoricalYears);
    },
    [additionalHistoricalYears, collapsibleColumns, periods, setAdditionalHistoricalYears, setCollapsibleColumns]
  );

  const handleDeleteColumn = (columnIndex, wb) => {
    const firstValue = 4;
    const lastValue = wb.incomeStatement.columns.length - 1 - 10;
    return columnIndex === firstValue || columnIndex === lastValue;
  };

  const PROJECTION_YEAR_LIMIT = 2;
  const HISTORICAL_YEAR_LIMIT = 1;

  const checkProjectionYearLimit = (columnIndex, wb) => {
    if (wb.incomeStatement.columns[columnIndex].isProjectedYear) {
      return wb.incomeStatement.columns.filter(column => column.isProjectedYear).length <= PROJECTION_YEAR_LIMIT;
    }
    return false;
  };

  const checkHistoricalYearLimit = (columnIndex, wb) => {
    if (wb.incomeStatement.columns[columnIndex].isPrevYear) {
      return wb.incomeStatement.columns.filter(column => column.isPrevYear).length <= HISTORICAL_YEAR_LIMIT;
    }
    return false;
  };

  const showDeleteConfirmation = useCallback(
    (columnIndex, columnPeriodId, workbook) => {
      const isOutsideProjectionYearLimit = checkProjectionYearLimit(columnIndex, workbook);
      const isOutsideHistoricalYearLimit = checkHistoricalYearLimit(columnIndex, workbook);
      const CAN_DELETE
        = isOutsideProjectionYearLimit || isOutsideHistoricalYearLimit
          ? false
          : handleDeleteColumn(columnIndex, workbook);
      const notAllowedToDeleteMessage
        = isOutsideProjectionYearLimit || isOutsideHistoricalYearLimit
          ? [
            'At least two projection years are required in order to sync values with the NTM column, and at least one historical year is required to sync with the LTM column.',
          ]
          : ['This column cannot be deleted because it affects the sequence of the following years.'];

      const CAN_DELETE_MESSAGE = [
        'Income statement for the fiscal year and its quarters',
        'Balance sheet for the fiscal year and its quarters',
        'The performance metrics relative to this period',
      ];
      if (!isEmpty(workbook.KPI)) {
        CAN_DELETE_MESSAGE.push('The KPIs for the fiscal year and its quarters');
      }
      const messages = CAN_DELETE ? CAN_DELETE_MESSAGE : notAllowedToDeleteMessage;
      const notDeleteMessage = ['You are not be able to delete this column'];
      const dialogTitle = CAN_DELETE ? 'Are you sure you want to delete this column?' : 'You cannot delete this column';
      const dialogBtnLabel = CAN_DELETE ? 'Delete' : 'Done';
      const dialogBtnType = CAN_DELETE ? 'danger' : 'success';
      const dialogBtnAction = CAN_DELETE ? () => deleteColumn(columnIndex, columnPeriodId, workbook) : () => {};
      const name = 'Delete column';

      showDialog({
        wrapper: ConfirmationDialog,
        wrapperProps: {
          title: dialogTitle,
        },
        content: ConfirmationDeleteWithList,
        contentProps: { messages, name, notDeleteMessage },
        actions: [
          {
            label: dialogBtnLabel,
            variant: 'contained',
            type: dialogBtnType,
            callback: dialogBtnAction,
          },
        ],
      });
    },
    [deleteColumn, showDialog]
  );

  const measurementDateParams = useMemo(
    () => ({
      companyMeasurementDate,
      selectedMeasurementDate: measurementDate,
      measurementDates,
    }),
    [companyMeasurementDate, measurementDates, measurementDate]
  );

  const saveConfirmation = useCallback(
    callback => {
      if (isAllocationFinal) {
        showDialog({
          wrapper: ConfirmationDialog,
          wrapperProps: {
            title: 'Confirmation',
          },
          content: ConfirmationFinal,
          actions: [
            {
              label: 'Cancel',
              variant: 'text',
              type: 'primary',
            },
            {
              label: 'Save',
              variant: 'contained',
              type: 'success',
              callback,
            },
          ],
        });
      }
    },
    [isAllocationFinal, showDialog]
  );

  const saveFn = useCallback(async () => {
    if (isAllocationFinal) {
      saveConfirmation(() =>
        saveFinancialStatement(
          tableData,
          periods,
          financialStatement,
          create,
          getVersions,
          update,
          setFinancialStatement,
          { ...measurementDateParams },
          { ...filesParams },
          showAdditionalHistoricalYears,
          useAdjustedEBITDA
        )
      );
    } else {
      saveFinancialStatement(
        tableData,
        periods,
        financialStatement,
        create,
        getVersions,
        update,
        setFinancialStatement,
        { ...measurementDateParams },
        { ...filesParams },
        { ...notesParams },
        showAdditionalHistoricalYears,
        useAdjustedEBITDA
      );
    }
  }, [
    create,
    filesParams,
    notesParams,
    financialStatement,
    getVersions,
    measurementDateParams,
    periods,
    setFinancialStatement,
    showAdditionalHistoricalYears,
    tableData,
    update,
    isAllocationFinal,
    saveConfirmation,
    useAdjustedEBITDA,
  ]);
  // Set page actions
  useEffect(() => {
    if (!isEmpty(financialStatement)) {
      const isActive = financialStatement?.id && !financialStatement?.versioning;
      updatePageActions({
        mainAction: {
          id: 'financials',
          label: 'Save',
          callback: saveFn,
          isActive: isDisabled,
          isLock: isAllocationFinal,
        },
        secondaryActions: [
          {
            label: 'Save as New Version',
            callback: () => setOpenNewVersionDialog(true),
            isActive,
          },
        ],
      });
    }
    return () => updatePageActions(null);
  }, [financialStatement, updatePageActions, saveFn, isDisabled, isAllocationFinal]);

  const saveStatementWithNewName = useCallback(
    newName => {
      const financialStamentData = getFinancialsData(
        tableData?.incomeStatement,
        tableData?.balanceSheet,
        tableData?.KPI,
        financialStatement,
        periods
      );
      if (newName && newName !== financialStamentData.name) {
        const updatedFinancialStatementData = { ...financialStamentData, name: newName };
        updateOrCreateFinancialStatement({
          financialStatementData: updatedFinancialStatementData,
          create,
          update,
          getVersions,
        });
      }
    },
    [create, financialStatement, getVersions, periods, tableData, update]
  );

  const saveAsNewVersion = useCallback(
    values => {
      const financialStatementData = getFinancialsData(
        tableData?.incomeStatement,
        tableData?.balanceSheet,
        tableData?.KPI,
        financialStatement,
        periods
      );
      financialStatementData.name = values?.name;
      financialStatementData.company_measurement_date = companyMeasurementDate?.id;
      financialStatementData.show_additional_historical_years = showAdditionalHistoricalYears;
      financialStatementData.company_kpis = tableData?.KPI.tableData.companyKPIs;
      financialStatementData.deleted_kpis = tableData?.KPI.tableData.deletedKPIs || [];

      createVersion(financialStatement?.id, financialStatementData);
    },
    [companyMeasurementDate, createVersion, financialStatement, periods, showAdditionalHistoricalYears, tableData]
  );
  return {
    setOpenNewVersionDialog,
    isNewVersionDialogOpen,
    setIsNameDialogOpen,
    isNameDialogOpen,
    saveStatementWithNewName,
    saveAsNewVersion,
    addFinancialYear: addYearFn,
    showDeleteConfirmation,
    showOrHideEarliestHistoricalYears,
  };
};

export default useFinancialsPageHelpers;
