import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useSeparateFinancialStatements } from 'pages/ValuationsAllocation/hooks/useSeparateFinancialStatements';
import { transposeFinancials } from 'pages/ValuationsAllocation/util/transposeFinancials';
import { GetFinancialStatementsByMDHook } from 'services/hooks/financials';
import { getArrayValue, getObjectValue } from 'utillities';

const useGetFinancials = (financialStatementsList?: GetFinancialStatementsByMDHook['data']) => {
  const { financialStatements, otherFinancialStatements } = useSeparateFinancialStatements(
    getArrayValue(financialStatementsList)
  );

  // Get Financial Statements Periods and Quarters before the Measurement Date
  const { past_financial_statement_periods_per_md: financialPeriodsPerMD = [] } = getObjectValue(financialStatements);

  const primaryFinancialStatement = useMemo(
    () => (financialStatementsList && financialStatementsList?.length > 0 ? financialStatementsList[0] : null),
    [financialStatementsList]
  );

  const financialPeriods = useMemo(
    () =>
      !isEmpty(financialStatements)
        ? [
          ...getArrayValue(financialStatements?.fiscal_years),
          getObjectValue(financialStatements?.ltm),
          getObjectValue(financialStatements?.ntm),
          ...getArrayValue(
            otherFinancialStatements
              .map(otherFinancialStatement => getArrayValue(otherFinancialStatement?.fiscal_years))
              .flat()
          ),
          ...getArrayValue(
            otherFinancialStatements.map(otherFinancialStatement => getObjectValue(otherFinancialStatement?.ntm))
          ),
          ...getArrayValue(
            otherFinancialStatements.map(otherFinancialStatement => getObjectValue(otherFinancialStatement?.ltm))
          ),
        ]
        : [],
    [financialStatements, otherFinancialStatements]
  );

  const financials = useMemo(() => transposeFinancials(getObjectValue(financialStatements)), [financialStatements]);

  return {
    financialPeriods,
    financialPeriodsPerMD,
    financials,
    otherFinancialStatements,
    primaryFinancialStatement,
  };
};

export default useGetFinancials;
