import React, { useEffect, useState } from 'react';
import { Divider, ListItemIcon, MenuItem, Popover, TextField, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { alpha, withStyles } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import RemoveIcon from '@material-ui/icons/Remove';
import { TreeItem, TreeView } from '@material-ui/lab';
import PropTypes from 'prop-types';

const CustomAutoSelect = props => {
  const {
    inputProps,
    data: dataParam,
    onChange,
    isAddNewFolderAtBottom,
    setOpenFolderDialog,
    newFolder,
    helperText,
  } = props;
  const { id, name, label } = inputProps;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [selected, setSelected] = useState([]);
  const [popOverWidth, setPopOverWidth] = useState();
  const [data, setData] = useState(dataParam);
  let allowSelectFolder = true;

  const handleClick = event => {
    const anchorElement = event.currentTarget;
    setAnchorEl(anchorElement);
    setPopOverWidth(anchorElement.offsetWidth);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popOverId = open ? 'custom-auto-select-popover' : undefined;

  function TransitionComponent(collapseProps) {
    return (
      <div>
        <Collapse {...collapseProps} />
      </div>
    );
  }

  const StyledTreeItem = withStyles(theme => ({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  }))(treeProps => (
    <TreeItem
      {...treeProps}
      TransitionComponent={TransitionComponent}
      onIconClick={() => {
        allowSelectFolder = false;
      }}
      onLabelClick={() => {
        allowSelectFolder = true;
      }}
    />
  ));

  const renderTree = nodes => (
    <StyledTreeItem key={nodes.id} nodeId={nodes.id.toString()} label={nodes.name}>
      {Array.isArray(nodes.subFolders) ? nodes.subFolders.map(node => renderTree(node)) : null}
    </StyledTreeItem>
  );

  const handleSelect = (event, nodeId) => {
    if (allowSelectFolder) {
      const selectedFolder = nodeId.toString();
      setSelected(selectedFolder);
      onChange(selectedFolder);
      setSelectedOption(event.target.innerText);
      handleClose();
    }
  };

  const handleOpenDialog = () => {
    setOpenFolderDialog(true);
  };

  useEffect(() => {
    if (newFolder) {
      const newData = [...data, newFolder];
      setSelected(newFolder.id.toString());
      onChange(newFolder.id.toString());
      setSelectedOption(newFolder.name);
      setOpenFolderDialog(false);
      setData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFolder]);

  return (
    <>
      <TextField
        fullWidth
        id={id}
        name={name}
        placeholder={label}
        variant="outlined"
        onClick={handleClick}
        value={selectedOption}
        helperText={helperText || ''}
        className="custom-tree-container"
      />
      <Popover
        id={popOverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: popOverWidth },
        }}>
        <TreeView
          className="custom-tree-container"
          selected={selected}
          defaultExpanded={['1']}
          defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />}
          defaultExpandIcon={<AddBoxOutlinedIcon />}
          defaultEndIcon={<RemoveIcon />}
          onNodeSelect={handleSelect}>
          {data.map(item => renderTree(item))}
          {isAddNewFolderAtBottom && (
            <div className="add-new-folder-button">
              <Divider />
              <MenuItem onClick={handleOpenDialog}>
                <ListItemIcon>
                  <AddIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Add new folder</Typography>
              </MenuItem>
            </div>
          )}
        </TreeView>
      </Popover>
    </>
  );
};

CustomAutoSelect.propTypes = {
  inputProps: PropTypes.object,
  data: PropTypes.array,
  onChange: PropTypes.func,
  isAddNewFolderAtBottom: PropTypes.bool,
  setOpenFolderDialog: PropTypes.func,
  newFolder: PropTypes.object,
  helperText: PropTypes.string,
};

export default CustomAutoSelect;
