import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { CONTROL_CODE, MINUS_CODE, SLASH_CODE } from 'common/constants/general';
import { useStore } from 'common/store';
import Logo from 'components/Logo';
import Shortcuts from './Shortcuts';

const useStyles = makeStyles(() => ({
  title: {
    paddingLeft: '2.5rem',
  },
}));

const ShortcutHelper = ({ children }) => {
  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();
  // use user state additionally to know if we should display the helper or not
  const [{ user }] = useStore();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  const startPressingControl = useRef(null);

  const onCloseHandler = useCallback(() => setShowDialog(false), [setShowDialog]);
  const maxWidth = upMd ? 'lg' : 'xs';

  useEffect(() => {
    const listenForControlDown = event => {
      const pressedKey = event.key || event.code;
      if (pressedKey === CONTROL_CODE && !event.repeat && !isEmpty(user)) {
        startPressingControl.current = event.timeStamp;
      }
    };

    const listenForCombination = event => {
      /* account for different keyboard language configurations
      valid codes (not keys) to get a question mark:
        - "Slash" in English Keyboard
        - "Minus" in Spanish keyboard
        (despite showing Quote and Question Mark symbols, the key code is Minus)
      */
      const isValidCode = [SLASH_CODE, MINUS_CODE].includes(event.code);
      if (event.ctrlKey && event.shiftKey && isValidCode && !isEmpty(user)) setShowDialog(true);
    };

    const listenForControlUp = event => {
      const pressedKey = event.key || event.code;
      if (pressedKey === CONTROL_CODE) {
        const stopPressingControl = event.timeStamp;
        const timeElapsed = stopPressingControl - startPressingControl.current;
        const threeOrMoreSeconds = timeElapsed >= 3000;
        if (threeOrMoreSeconds && !isEmpty(user)) {
          setShowDialog(true);
        }
        startPressingControl.current = null;
      }
    };
    document.addEventListener('keydown', listenForControlDown);
    document.addEventListener('keyup', listenForControlUp);
    document.addEventListener('keydown', listenForCombination);
    return () => {
      document.removeEventListener('keydown', listenForControlDown);
      document.removeEventListener('keyup', listenForControlUp);
      document.removeEventListener('keydown', listenForCombination);
    };
  }, [user]);

  return (
    <>
      {children}
      <Dialog onClose={onCloseHandler} open={showDialog} fullWidth maxWidth={maxWidth}>
        <DialogTitle className={classes.title}>
          <Logo variation="onlyWord" lightBlue />
        </DialogTitle>
        <DialogContent style={{ padding: '2.5rem' }}>
          <Shortcuts upMd={upMd} />
        </DialogContent>
      </Dialog>
    </>
  );
};

ShortcutHelper.propTypes = {
  children: PropTypes.element,
};

export default React.memo(ShortcutHelper);
