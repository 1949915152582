import React from 'react';
import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LOG_IN, SKIP } from 'common/constants/process-management';
import { useStore } from 'common/store';

const PMActionButtons = ({ handleOnClick }) => {
  const { processType, requestId, hmacToken } = useParams();
  const [{ user }] = useStore();
  const history = useHistory();

  const sharedStyles = {
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: '1.125rem',
    padding: '1rem 2rem',
  };

  if (user) {
    history.push(`/${processType}/requests/${requestId}/${hmacToken}/portal`);
  }

  return (
    <Box
      style={{
        marginTop: '2rem',
      }}>
      <Button
        variant="contained"
        color="primary"
        style={{ ...sharedStyles, marginRight: '0.7rem' }}
        size="large"
        onClick={handleOnClick}>
        {LOG_IN}
      </Button>
      <Button
        variant="outlined"
        color="primary"
        style={sharedStyles}
        size="large"
        component={Link}
        to={`/${processType}/requests/${requestId}/${hmacToken}/portal`}>
        {SKIP}
      </Button>
    </Box>
  );
};

PMActionButtons.propTypes = {
  handleOnClick: PropTypes.func,
};

export default PMActionButtons;
