import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { START, WELCOME_TO_THE_QUESTIONNAIRE } from 'common/constants/process-management';

const WelcomeToTheQuestionnaire = ({ isMobile, handleStart }) => (
  <Box
    width={isMobile ? '100%' : '50%'}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center">
    <Typography
      align="center"
      style={{
        fontSize: isMobile ? '1.7rem' : '2.5rem',
        fontWeight: isMobile ? '700' : '400',
        lineHeight: '3rem',
      }}>
      {WELCOME_TO_THE_QUESTIONNAIRE}
    </Typography>
    <Button
      variant="contained"
      color="primary"
      style={{
        padding: '1rem 3.875rem',
        marginTop: '2rem',
        fontWeight: 400,
      }}
      onClick={handleStart}>
      {START}
    </Button>
  </Box>
);

WelcomeToTheQuestionnaire.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  handleStart: PropTypes.func.isRequired,
};

export default WelcomeToTheQuestionnaire;
