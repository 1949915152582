import { SPECIFIED_LABEL } from 'pages/Valuations/util/constants';
import {
  METRIC_OPTION_MAP,
  MetricOptionMapKeys,
  MultipleMapKeys,
} from 'pages/ValuationsAllocation/approaches/FutureExit/FutureEquity/config/types';
import getFinancialMetricValue from 'pages/ValuationsAllocation/approaches/FutureExit/FutureEquity/config/utils/getFinancialMetricValue';
import { SHEET_ALIASES_CONSTANTS, SPECIFIED_MULTIPLE } from 'pages/ValuationsAllocation/common/constants/futureExit';
import { multipleMap } from 'pages/ValuationsAllocation/common/constants/futureExit/sheetConfigs';
import { VALUATIONS_PUBLIC_COMPANIES_APPROACH } from 'pages/ValuationsAllocation/common/constants/valuations';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getObjectValue } from 'utillities';
import {
  CustomAfterCellChangedParams,
  GetFormulaFunctionParams,
  GetFunctionParams,
  GetMetricAndMultipleKeyParams,
  LtmAttributes,
} from './types';

function getMetricAndMultipleKey(params: GetMetricAndMultipleKeyParams) {
  const { metric, multiple, approachType } = params;
  const mediumPart = approachType === VALUATIONS_PUBLIC_COMPANIES_APPROACH ? '_' : '_multiple_';
  return `${metric}${mediumPart}${multiple}`.toLowerCase().replaceAll(' ', '_');
}

// define a function that takes an approach id, a multiple value, and generates a formula
const getStaticValue = (params: GetFunctionParams): string => {
  const { approachId, multipleValue, metricValue, staticApproachValues } = params;
  const multiple = multipleMap[multipleValue as keyof MultipleMapKeys];
  if (!multiple) {
    return '';
  }

  const metric = METRIC_OPTION_MAP[metricValue as keyof MetricOptionMapKeys];
  if (!metric) {
    return '';
  }
  return staticApproachValues[approachId][
    getMetricAndMultipleKey({ metric, multiple }) as keyof LtmAttributes
  ] as string;
};

const getFormula = (params: GetFormulaFunctionParams): string => {
  const { approachId, approaches, multipleValue, metricValue, staticApproachValues } = params;
  if (staticApproachValues) {
    return getStaticValue({ approachId, multipleValue, metricValue, staticApproachValues });
  }

  if ([approachId, multipleValue, metricValue].includes(undefined)) {
    return '';
  }
  const approach = approaches?.find((a: any) => {
    const id = a.id?.toString() || a.panelId;
    return id === approachId?.toString();
  });
  if (approach) {
    const name = getApproachTableName({ approach });
    const multiple = multipleMap[multipleValue as keyof MultipleMapKeys];
    if (!multiple) {
      return '';
    }
    const metric = METRIC_OPTION_MAP[metricValue as keyof MetricOptionMapKeys];

    const metricAndMultiple = getMetricAndMultipleKey({ metric, multiple, approachType: approach.approach_type });
    return `=${name}.${metricAndMultiple}`;
  }
  return '';
};

const customAfterCellChanged = (params: CustomAfterCellChangedParams) => {
  const { cells, initialChanges, approaches, financials, staticApproachValues } = getObjectValue(params);

  const {
    FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE,
    FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH,
    FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE,
  } = SHEET_ALIASES_CONSTANTS;

  const changes = [...initialChanges];
  const { cell, value } = changes[0];
  if (cell.alias === FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE) {
    const metricCell = cells.A3;

    const financialsMetricValue = cell.isTitleCell ? metricCell.value : value;
    const financialsMetric = cell.isTitleCell ? value : cells.TITLES_FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE.value;

    const { isSpecified, metricValue } = getFinancialMetricValue({
      financials,
      financialsMetric,
      financialsMetricValue,
    });

    metricCell.readOnly = !isSpecified;

    if (!isSpecified) {
      const multipleValueExpr = getFormula({
        approaches,
        staticApproachValues,
        approachId: cells.A4.value as string,
        multipleValue: cells.TITLES_FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE.value,
        metricValue: value,
      });

      const multipleValueCell = cells.A5;

      if (multipleValueExpr) {
        changes.push({ cell: multipleValueCell, value: multipleValueExpr });
      }
    }

    changes.push({ cell: metricCell, value: metricValue });
  }

  if (cell.alias === FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH) {
    // B5 contains the specified multiple value
    const multipleValueCell = cells.A5;
    // The TITLES_FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE cell contains the multiple reference and A3 contains LTM/NTM selection
    const expr = getFormula({
      approaches,
      staticApproachValues,
      approachId: value,
      multipleValue: cells.TITLES_FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE.value,
      metricValue: cells.TITLES_METRIC_VALUE.value,
    });
    if (expr) {
      changes.push({ cell: multipleValueCell, value: expr });
    }
  }

  if (cell.alias === FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE) {
    const selectedMultipleValue = cell.isTitleCell
      ? value
      : cells.TITLES_FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE.value;
    const isSpecified = selectedMultipleValue === SPECIFIED_MULTIPLE;
    const multipleCell = cells.A5;
    multipleCell.readOnly = !isSpecified;
    let expr = '';
    if (!isSpecified) {
      // find the related approach from the value of cells.A4 and the LTM/NTM selection from cells.A3
      expr
        = getFormula({
          approaches,
          staticApproachValues,
          approachId: cells.A4.value as string,
          multipleValue: value === SPECIFIED_MULTIPLE ? SPECIFIED_LABEL : value,
          metricValue: cells.TITLES_METRIC_VALUE.value,
        }) ?? '';
      changes.push({ cell: multipleCell, value: expr });
    } else {
      multipleCell.className = '';
      changes.push({ cell: multipleCell, value: value === SPECIFIED_MULTIPLE ? '' : value });
    }
  }

  return changes;
};

export default customAfterCellChanged;
