import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { GridDate } from '../../Grid';

const useStyles = makeStyles(theme => ({
  root: {
    width: `calc(100% - ${theme.cellPadding * 2}px)`,
    padding: `0px ${theme.cellPadding}px`,
  },
  select: {
    color: `${theme.palette.primary.dark} !important`,
  },
}));

const InvestmentDateValue = ({ cell, state, setState }) => {
  const classes = useStyles();
  const [investmentDates, setInvestmentDates] = useState([]);

  // Verify if data source have more than one investment date
  const checkInvestments = useCallback(() => {
    const investments = state[cell.dataSourceKey].value || [];
    const dates = investments.map(investment => investment.date);

    setInvestmentDates(dates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkInvestments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, checkInvestments]);

  // 3.   When choose another option update the state
  const handleChange = e => {
    const tmpState = { ...state };
    tmpState[cell.key].value = moment(e.target.value).format('YYYY-MM-DD');
    setState(tmpState);
  };

  const InvestmentDatesDropDown = () => (
    <FormControl className={classes.root}>
      <Select
        id={`date-select-${cell.key}`}
        className={classes.select}
        disableUnderline
        value={cell.value || ''}
        displayEmpty={true}
        format="YYYY-MM-DD"
        onChange={e => {
          handleChange(e);
        }}
        disabled={isEmpty(investmentDates)}>
        {investmentDates.map(investmentDate => (
          <MenuItem id={`date-select-${cell.key}-opt-${investmentDate}`} value={investmentDate} key={investmentDate}>
            {investmentDate}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <span className="value-viewer">
      {investmentDates.length > 1 ? (
        // If have more than one render a dropdown to show the options
        <InvestmentDatesDropDown />
      ) : (
        <GridDate cell={cell} state={state} setState={setState} className={classes.date} />
      )}
    </span>
  );
};

InvestmentDateValue.propTypes = {
  cell: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
};

export default InvestmentDateValue;
