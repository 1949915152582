/**
 * @name Company Action
 * @memberof module:common/actions
 * @type {ReduxAction}
 * @return {Object} Global Actions
 */
import { SET_CAPTABLE_LIST, SET_COMPANY_INFO, SET_COMPANY_LIST, SET_FUND_LIST } from './types';

/**
 * @function
 * @name setCompanyInfo
 * @description set firm data selected.
 * @param {object} companyName  - reducer state
 * @return {object} Action context
 */
export const setCompanyInfo = companyInfo => ({
  type: SET_COMPANY_INFO,
  companyInfo,
});

export const setCompanyList = companyList => ({
  type: SET_COMPANY_LIST,
  companyList,
});

export const setCapTableList = capTableList => ({
  type: SET_CAPTABLE_LIST,
  capTableList,
});

export const setFundList = fundList => ({
  type: SET_FUND_LIST,
  fundList,
});
