/* eslint-disable import/no-unresolved */
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { forbiddenPath, notFoundPath } from 'common/config/urls';
import { SIGN_IN_URL } from 'common/constants/login';
import { COMPANY_TYPE, FIRM_TYPE, FUND_TYPE, USER_TYPE } from 'common/constants/pageTypes';
import { CompanyContainer as CompanyContainerView, CustomRoute, UserContainer as UserContainerView } from 'components';
import { DASHBOARD_COMPANY_SLUG } from 'dashboard409a/common/constants';
import { DashboardLayout } from 'dashboard409a/layouts';
import { Dashboard as DashboardView } from 'dashboard409a/pages';
import { AuthLayout, Main as MainLayout, Minimal as MinimalLayout, TaskLayout } from './layouts';
import {
  ActivateAccount as ActivateAccountView,
  Firms as FirmsView,
  Funds as FundsView,
  Login as LoginView,
  NotFound as NotFoundView,
  ProcessManagement as ProcessManagementView,
  ResetPasswordConfirm as ResetPasswordConfirmView,
  SignUp as SignUpView,
} from './pages';

const mainLayoutHandler = params => {
  const { props, pageType } = params;

  return <MainLayout pageType={pageType} {...props} />;
};

const Routes = () => (
  <Switch>
    {/* Auth */}
    <Redirect exact from="/" to={SIGN_IN_URL} />
    <CustomRoute component={SignUpView} exact layout={AuthLayout} path="/sign-up" />
    <CustomRoute
      component={ResetPasswordConfirmView}
      exact
      isPrivate={false}
      path="/users/reset_password/:uid/:token"
    />
    <CustomRoute component={ActivateAccountView} exact layout={MinimalLayout} path="/activate-account/:uid/:token" />
    <CustomRoute component={LoginView} exact path={SIGN_IN_URL} />

    {/* Error handlers */}
    <CustomRoute component={NotFoundView} exact path={notFoundPath} />
    <CustomRoute component={NotFoundView} exact path={forbiddenPath} />

    {/* Firms */}
    <CustomRoute
      component={FirmsView}
      exact
      isPrivate
      layout={props => mainLayoutHandler({ props, pageType: FIRM_TYPE })}
      path="/firms/:firmSlugParam/:tableSlugParam"
    />

    {/* Funds */}
    <CustomRoute
      component={FundsView}
      exact
      isPrivate
      layout={props => mainLayoutHandler({ props, pageType: FUND_TYPE })}
      path="/firms/:firmSlugParam/funds/:fundSlugParam/:tableSlugParam"
    />

    {/* Companies */}
    <CustomRoute
      component={CompanyContainerView}
      isPrivate
      layout={props => mainLayoutHandler({ props, pageType: COMPANY_TYPE })}
      path="/firms/:firmSlugParam/companies/:companySlugParam/:tableSlugParam?"
    />

    {/* Users */}
    <CustomRoute
      component={UserContainerView}
      exact
      isPrivate
      layout={props => mainLayoutHandler({ props, pageType: USER_TYPE })}
      path="/firms/:firmSlugParam/users/:tabSlugParam"
    />
    <CustomRoute
      component={UserContainerView}
      exact
      isPrivate
      layout={props => mainLayoutHandler({ props, pageType: USER_TYPE })}
      path="/firms/:firmSlugParam/users/:userIdParam/:tabSlugParam"
    />

    {/* Process Management */}
    <CustomRoute
      component={ProcessManagementView}
      exact
      layout={TaskLayout}
      path="/:processType/requests/:requestId/:hmacToken/:portal?"
    />

    {/* 409A Dashboard */}
    <CustomRoute component={DashboardView} isPrivate layout={DashboardLayout} path={DASHBOARD_COMPANY_SLUG} />

    {/* Not found */}
    <Redirect to={notFoundPath} />
  </Switch>
);

export default Routes;
