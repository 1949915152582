import React, { FC } from 'react';
import { Dialog, List } from '@material-ui/core';
import { EQUITY_ALLOCATION_SCENARIO } from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import { ApproachScenarioTypeOption, ApproachTypes } from 'pages/ValuationsAllocation/components';
import useStyles from './styles';
import { NewScenarioApproachDialogProps } from './types';

const NewScenarioApproachDialog: FC<NewScenarioApproachDialogProps> = props => {
  const {
    approachTypes,
    disableAddScenarioButton,
    handleApproachTypeClick,
    handleScenarioTypeClick,
    onDialogClose,
    openDialog,
  } = props;

  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} onClose={onDialogClose} open={openDialog}>
      <List>
        {/* Approaches */}
        {approachTypes.map(approach => (
          <ApproachScenarioTypeOption approach={approach} onClick={handleApproachTypeClick} key={approach} />
        ))}

        {/* Allocation Scenario */}
        <ApproachScenarioTypeOption
          approach={EQUITY_ALLOCATION_SCENARIO as ApproachTypes}
          isDisabled={disableAddScenarioButton}
          key={EQUITY_ALLOCATION_SCENARIO}
          onClick={handleScenarioTypeClick}
        />
      </List>
    </Dialog>
  );
};

export default NewScenarioApproachDialog;
