/* eslint-disable import/no-unresolved */
import React, { useContext } from 'react';
import { FormControl, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import SpreadsheetContext from 'components/Spreadsheet/context/SpreadsheetContext';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: '100%',
  },
  input: {
    paddingLeft: '5px',
    paddingRight: '5px',
    color: theme.palette.primary.dark,
  },
}));

const GridDate = ({ cell, disabled }) => {
  const classes = useStyles();
  const { parsedColumns, setParsedColumns } = useContext(SpreadsheetContext);

  const formatDate = date => moment(date).format('YYYY-MM-DD');

  const handleChange = event => {
    const tmpState = { ...parsedColumns };
    tmpState[cell.key] = { ...tmpState[cell.key], value: formatDate(event.target.value) };
    setParsedColumns(tmpState);
  };

  return (
    <FormControl className={classes.formControl}>
      <Input
        id={`date-input-${cell.key}`}
        type="date"
        margin="none"
        disableUnderline
        className={classes.input}
        value={cell.value || formatDate(Date.now())}
        onChange={event => {
          handleChange(event);
        }}
        disabled={disabled}
      />
    </FormControl>
  );
};

GridDate.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
  disabled: false,
};

GridDate.propTypes = {
  cell: PropTypes.object,
  disabled: PropTypes.bool,
};

export default GridDate;
