import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { FIRM_FIELDS_CONSTRAINTS as constraints } from 'common/constants/firms';
import { useStore } from 'common/store';
import { FormDialog } from 'components';
import { FirmForm } from 'components/Forms';
import { FirmHk } from 'services/hooks';

const FirmDialog = ({ open, onClose }) => {
  const [{ firmInfo = {} }] = useStore();
  const { useCreateNewFirm } = FirmHk;
  const [, createFirm] = useCreateNewFirm();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    errors: {},
  });

  useEffect(() => {
    if (!isEmpty(firmInfo)) {
      setFormState({
        ...formState,
        values: firmInfo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmInfo]);

  useEffect(() => {
    const errors = validate(formState.values, constraints);
    setFormState(formCurrentState => ({ ...formCurrentState, isValid: !errors, errors: errors || {} }));
  }, [formState.values]);

  const saveData = async () => {
    const { values: formData } = formState;
    await createFirm(formData);
  };

  return (
    <FormDialog
      open={open}
      title={'New Firm'}
      editMode={false}
      isValid={formState.isValid}
      onSave={saveData}
      onClose={onClose}>
      <FirmForm defaultFormState={formState} onFormChange={formCurrentState => setFormState(formCurrentState)} />
    </FormDialog>
  );
};

FirmDialog.defaultProps = {
  open: false,
};

FirmDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default FirmDialog;
