import { SHEET_TITLES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { HandleHeaderTitleColumnParams } from './types';

const {
  FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER,
  getAdjustedColumnNumber,
  VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_SCENARIOS_TITLE,
} = SHEET_TITLES_CONSTANTS;

const handleHeaderTitleColumn = (params: HandleHeaderTitleColumnParams) => {
  const { allocationScenariosQuantity, cell, colNumber, isUniformCurrency } = params;

  cell.value = '';

  // First Allocation Scenarios column
  if (FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER === getAdjustedColumnNumber({ colNumber, isUniformCurrency })) {
    cell.value = VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_SCENARIOS_TITLE;
    cell.colSpan = allocationScenariosQuantity;
    cell.className = `${cell.className} w-full text-center`;
  }
};

export default handleHeaderTitleColumn;
