import React from 'react';
import { smallCurrencyFormat, twoDecimalPercentFormat } from 'common/formats/formats';
import { GridDateEditor, GridDateValueViewer } from 'components/FeaturedSpreadsheet/components';
import { GPC_HEADER_ROW, TRANSACTION_HEADER_ROW } from 'pages/CompGroups/constants/header';
import { shortDate } from 'utillities';
import DeleteableRowHeader from './components/DeleteableRowHeader';
import GridDate from './components/GridDate';
import {
  ENTERPRISE_VALUE_ALIAS,
  LTM_EBITDA_ALIAS,
  LTM_EBITDA_MARGIN_ALIAS,
  LTM_GROSS_MARGIN_ALIAS,
  LTM_REVENUE_ALIAS,
  LTM_REVENUE_GROWTH_ALIAS,
  TRANSACTION_DATE_ALIAS,
} from './constants/constants';

export const GPCCompGroups = (companies, removeItem, isPreviousVersion) => {
  const header = GPC_HEADER_ROW;

  const data = companies.map(item => [
    {
      value: (
        <DeleteableRowHeader
          value={item.company_name}
          updateObject={item}
          removeItem={removeItem}
          isPreviousVersion={isPreviousVersion}
        />
      ),
      readOnly: true,
      className: 'deleteable-row-comp-name',
    },
    {
      value: item.ticker_symbol,
      readOnly: true,
    },
    {
      value: item.cap_iq_id,
      readOnly: true,
    },
  ]);

  return [header, ...data];
};

const decideFallBack = (prop, row, comesFromCapIQ) => (comesFromCapIQ ? row[prop] : row[prop] ?? 0);

export const transactionCompGroups = (rows, removeItem, isPreviousVersion) => {
  const header = TRANSACTION_HEADER_ROW;

  const data = rows.map((row, index) => {
    const rowIndex = index + 1;
    const comesFromCapIQ = row.comes_from_capital_iq;
    const readOnly = comesFromCapIQ || isPreviousVersion;
    const displayNAforNull = comesFromCapIQ;
    return [
      {
        key: `target_name_${rowIndex}`,
        alias: 'target_name',
        rowIndex,
        valueViewer: props => (
          <DeleteableRowHeader
            value={row.target_name}
            updateObject={row}
            removeItem={removeItem}
            isPreviousVersion={isPreviousVersion}
            {...props}
          />
        ),
        value: row.target_name,
        gridType: 'string',
        isRequired: true,
        readOnly,
        displayNAforNull,
      },
      {
        key: `acquirer_name_${rowIndex}`,
        value: row.acquirer_name,
        placeholder: 'ENTER DATA',
        alias: 'acquirer_name',
        rowIndex,
        gridType: 'string',
        isRequired: true,
        readOnly,
        displayNAforNull,
      },
      {
        key: `${TRANSACTION_DATE_ALIAS}_${rowIndex}`,
        value: shortDate(row.transaction_date ?? row.transaction_agreement_date),
        alias: TRANSACTION_DATE_ALIAS,
        gridDateComponent: GridDate,
        dataEditor: props => <GridDateEditor {...props} />,
        valueViewer: props => <GridDateValueViewer {...props} />,
        rowIndex,
        gridType: 'date',
        isRequired: true,
        readOnly,
      },
      {
        key: `${ENTERPRISE_VALUE_ALIAS}_${rowIndex}`,
        value: decideFallBack(ENTERPRISE_VALUE_ALIAS, row, comesFromCapIQ),
        alias: ENTERPRISE_VALUE_ALIAS,
        gridType: 'number',
        format: smallCurrencyFormat,
        rowIndex,
        isRequired: true,
        readOnly,
        displayNAforNull,
      },
      {
        key: `${LTM_REVENUE_ALIAS}_${rowIndex}`,
        value: decideFallBack(LTM_REVENUE_ALIAS, row, comesFromCapIQ),
        alias: LTM_REVENUE_ALIAS,
        gridType: 'number',
        format: smallCurrencyFormat,
        rowIndex,
        isRequired: true,
        readOnly,
        displayNAforNull,
      },
      {
        key: `${LTM_REVENUE_GROWTH_ALIAS}_${rowIndex}`,
        value: decideFallBack(LTM_REVENUE_GROWTH_ALIAS, row, comesFromCapIQ),
        alias: LTM_REVENUE_GROWTH_ALIAS,
        gridType: 'number',
        format: twoDecimalPercentFormat({ minimumFractionDigits: 0, isDecimal: false }),
        rowIndex,
        isRequired: false,
        readOnly,
        displayNAforNull,
      },
      {
        key: `${LTM_EBITDA_ALIAS}_${rowIndex}`,
        value: decideFallBack(LTM_EBITDA_ALIAS, row, comesFromCapIQ),
        alias: LTM_EBITDA_ALIAS,
        gridType: 'number',
        format: smallCurrencyFormat,
        rowIndex,
        isRequired: true,
        readOnly,
        displayNAforNull,
      },
      {
        key: `${LTM_EBITDA_MARGIN_ALIAS}_${rowIndex}`,
        value: decideFallBack(LTM_EBITDA_MARGIN_ALIAS, row, comesFromCapIQ),
        alias: LTM_EBITDA_MARGIN_ALIAS,
        gridType: 'number',
        format: twoDecimalPercentFormat({ minimumFractionDigits: 0, isDecimal: false }),
        rowIndex,
        isRequired: false,
        readOnly,
        displayNAforNull,
      },
      {
        key: `${LTM_GROSS_MARGIN_ALIAS}_${rowIndex}`,
        value: decideFallBack(LTM_GROSS_MARGIN_ALIAS, row, comesFromCapIQ),
        alias: LTM_GROSS_MARGIN_ALIAS,
        gridType: 'number',
        format: twoDecimalPercentFormat({ minimumFractionDigits: 0, isDecimal: false }),
        rowIndex,
        isRequired: false,
        readOnly,
        displayNAforNull,
      },
    ];
  });

  return [header, ...data];
};
