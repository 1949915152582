import React from 'react';
import { useSelector } from 'react-redux';
import { MessageBox } from 'components';
import { dashboardCompanySubscriptionUrl } from 'dashboard409a/common/config/urls';
import { RootStateType } from 'dashboard409a/states/store';
import GoToSubscription from './components/GoToSubscription';

const SubscriptionExpiredBox = () => {
  const { currentCompany } = useSelector((state: RootStateType) => state.global);
  const companySlug = currentCompany?.slug ?? '';
  const title = 'Valuation expired. Renew in order to regain access to this dashboard';

  return (
    <MessageBox
      type={'error-disabled'}
      title={title}
      action={<GoToSubscription subscriptionUrl={dashboardCompanySubscriptionUrl(companySlug)} />}
    />
  );
};

export default SubscriptionExpiredBox;
