/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import { isUndefined } from 'lodash';
import { parseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import * as c from './constants';

const editableRows = [c.PRE_TAX_CUTS_JOBS_ACT_ROW, c.POST_TAX_CUTS_JOBS_ACT_NOL_BALANCE_ROW];

const visibleRows = [c.NET_OPERATING_LOSS_ROW, ...editableRows];

const getExpr = ({ columnIndex, row, prevColumnLegend, columnLegend, mainTableRefence }) => {
  if (columnIndex !== 0) {
    switch (row.alias) {
      case c.EBIT_ALIAS:
        return `=${mainTableRefence}.${columnLegend}6`;
      case c.DISCOUNT_PERIODS_ALIAS:
        return `=${mainTableRefence}.${columnLegend}24`;
      case c.DISCOUNT_FACTOR_ALIAS:
        return `=${mainTableRefence}.${columnLegend}25`;
      default: {
        if (row.expr) {
          let tmpExpr = row.expr.replace(/@PREVCOL/g, prevColumnLegend);
          tmpExpr = tmpExpr.replace(/@/g, columnLegend);
          return tmpExpr;
        }
      }
    }
  }
  return '';
};

const parser = async ({ columns, rowConfig, mainTableRefence, isDisabled }) => {
  const alphabet = alphabetGenerator([], columns.length);

  let cells = {};

  // Parse body cells
  columns.forEach((column, columnIndex) => {
    const prevColumnLegend = alphabet[Math.max(0, columnIndex - 1)];
    const columnLegend = alphabet[columnIndex];

    rowConfig.forEach((row, rowIndex) => {
      const rowNumber = rowIndex + 1;
      const key = columnLegend + rowNumber;
      const value = !isUndefined(column[row.alias]) ? column[row.alias] : null;
      const type = row.gridType || null;
      const expr = getExpr({
        columnIndex,
        row,
        prevColumnLegend,
        columnLegend,
        mainTableRefence,
      });
      const editableColumn = columnIndex === 0;

      const cell = {
        ...row,
        key,
        expr,
        value: parseValue(value, type, null, null, row.dbType),
        alias: row.alias || '',
        columnId: column.id,
        columnLegend,
        columnOrder: column.order,
      };

      cells = {
        ...cells,
        [key]: cell,
      };

      if (editableColumn) {
        if (editableRows.includes(rowNumber)) {
          cell.readOnly = isDisabled;
        }

        if (!visibleRows.includes(rowNumber)) {
          cell.value = null;
          cell.format = null;
          cell.expr = null;
        }
      }
    });
  });

  return cells;
};

export default parser;
