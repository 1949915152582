import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UsersService } from 'services';

const getUserDetails = async userId => {
  const usersService = new UsersService();
  const userDetails = await usersService.getUserProfileById(userId);
  if (!userDetails) throw new Error('An error occurred while retrieving user details');
  return userDetails;
};

const useGetUserDetails = userId => {
  const { data, isLoading, error, refetch } = useQuery({
    enabled: !!userId,
    queryKey: ['userDetails', userId],
    queryFn: () => getUserDetails(userId),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
  return { data, isLoading, error, refetch };
};

const getUserPermissions = async (userId, firmId) => {
  const usersService = new UsersService();
  const userPermissions = await usersService.getUserPermissions(userId, firmId);
  if (!userPermissions) throw new Error('An error occurred while retrieving user permissions');
  return userPermissions;
};

const useGetUserPermissions = (userId, firmId) => {
  const { data, isLoading, error } = useQuery({
    enabled: !!userId && !!firmId,
    queryKey: ['userPermissions', userId, firmId],
    queryFn: () => getUserPermissions(userId, firmId),
  });
  return { data, isLoading, error };
};

const updateUserProfile = async ({ userId, updatedUserProfile }) => {
  const usersService = new UsersService();
  const updatedUser = await usersService.updateUserProfile(userId, updatedUserProfile);
  if (!updatedUser) throw new Error('An error occurred while updating user details');
  return updatedUser;
};

const useUpdateUserProfile = () => {
  const queryClient = useQueryClient();
  const updateUserProfileMutation = useMutation({
    mutationFn: ({ userId, updatedUserProfile }) =>
      updateUserProfile({
        userId,
        updatedUserProfile,
      }),
    onSuccess: () => {
      // Invalidate the firmUsers query to refetch the data including the new user
      queryClient.invalidateQueries('firmUsers');
      // Clean the mutation internal state
      updateUserProfileMutation.reset();
    },
  });

  const { mutate, isLoading, error, isSuccess } = updateUserProfileMutation;

  return { mutate, isLoading, error, isSuccess };
};

export { useGetUserDetails, useGetUserPermissions, useUpdateUserProfile, getUserPermissions };
