import React from 'react';
import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon } from '@material-ui/icons';
import { SORT_ASC, SORT_DESC, SORT_LABEL } from 'pages/Valuations/util/constants';

export const SortAscIcon = () => (
  <>
    <ArrowDropUpIcon /> {SORT_LABEL} {SORT_ASC.toLocaleUpperCase()}
  </>
);
export const SortDescIcon = () => (
  <>
    <ArrowDropDownIcon /> {SORT_LABEL} {SORT_DESC.toLocaleUpperCase()}
  </>
);
