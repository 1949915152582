import { Row, Rows } from 'common/types/scalarSpreadsheet';
import {
  FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/futureExit/';
import { getObjectValue, getSlugValue } from 'utillities';
import { RowConfigParams } from './types';

const {
  FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD,
  FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE,
  FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS,
  FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS,
  FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_DATE,
  FE_ALLOCATION_METHOD_SPREADSHEET_RISK_FREE_RATE,
  FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE,
  FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY,
} = SHEET_ALIASES_CONSTANTS;

const { FE_ALLOCATION_METHOD_SPREADSHEET } = SHEET_TITLES_CONSTANTS;

const rowConfig = (params: RowConfigParams): Rows => {
  const { isDisabled } = getObjectValue(params);

  return [
    {
      alias: FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD,
      className: `${FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE}-${getSlugValue(
        FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      isRequired: !isDisabled,
      value: FE_ALLOCATION_METHOD_SPREADSHEET.ALLOCATION_METHOD.TITLE,
    } as Row,
    {
      alias: FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE,
      className: `${FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE}-${getSlugValue(
        FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      isRequired: !isDisabled,
      value: FE_ALLOCATION_METHOD_SPREADSHEET.CAP_TABLE.TITLE,
    } as Row,
    {
      alias: FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS,
      className: `${FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE}-${getSlugValue(
        FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      hasSubRows: true,
      showSubRows: true,
      hidden: true,
      value: FE_ALLOCATION_METHOD_SPREADSHEET.OPM_INPUTS.TITLE,
    } as Row,
    {
      alias: FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS,
      parent: FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS,
      className: `subtitle-indented ${FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE}-${getSlugValue(
        FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      isRequired: !isDisabled,
      greaterThan: !isDisabled ? 0 : null,
      value: FE_ALLOCATION_METHOD_SPREADSHEET.MATURITY_YEARS.TITLE,
    } as Row,
    {
      alias: FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_DATE,
      parent: FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS,
      className: `subtitle-indented ${FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE}-${getSlugValue(
        FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_DATE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: FE_ALLOCATION_METHOD_SPREADSHEET.MATURITY_DATE.TITLE,
    } as Row,
    {
      alias: FE_ALLOCATION_METHOD_SPREADSHEET_RISK_FREE_RATE,
      parent: FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS,
      className: `subtitle-indented ${FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE}-${getSlugValue(
        FE_ALLOCATION_METHOD_SPREADSHEET_RISK_FREE_RATE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: FE_ALLOCATION_METHOD_SPREADSHEET.RISK_FREE_RATE.TITLE,
    } as Row,
    {
      alias: FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE,
      parent: FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS,
      className: `subtitle-indented ${FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE}-${getSlugValue(
        FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      value: FE_ALLOCATION_METHOD_SPREADSHEET.VOLATILITY_SOURCE.TITLE,
    } as Row,
    {
      alias: FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY,
      parent: FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS,
      className: `subtitle-indented ${FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE}-${getSlugValue(
        FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      isRequired: !isDisabled,
      greaterThan: isDisabled ? null : 0,
      value: FE_ALLOCATION_METHOD_SPREADSHEET.VOLATILITY.TITLE,
    } as Row,
  ];
};

export default rowConfig;
