import ebitdaRowConfig from './ebitdaRowConfig';
import revenueRowConfig from './revenueRowConfig';
import getColumns from '../../util/getColumns';

const getConfig = ({ columnsProps, ebitda }) => ({
  className: 'multiple-terminal-table',
  rowConfig: ebitda ? ebitdaRowConfig(columnsProps) : revenueRowConfig(columnsProps),
  columns: getColumns(columnsProps),
});

export default getConfig;
