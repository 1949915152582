import PropTypes from 'prop-types';

// Basic and Common Attributes
const ID = { id: PropTypes.number };
const BasicInfo = { ...ID, name: PropTypes.string };
const DateInfo = {
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  is_deleted: PropTypes.bool,
  deleted_at: PropTypes.string,
};
const CommonProps = { ...BasicInfo, ...DateInfo };

// Reusable Shapes
const FinancialMetrics = { equity_value: PropTypes.string, enterprise_value: PropTypes.string };
const ShareDetails = {
  value: PropTypes.number,
  shares: PropTypes.string,
  security: PropTypes.number,
  share_price: PropTypes.number,
  security_type: PropTypes.number,
};

// Detailed Structures
const ShareValue = PropTypes.shape({ ...CommonProps, ...ShareDetails });
const ValuationApproach = PropTypes.shape({
  ...CommonProps,
  ...FinancialMetrics,
  share_values: PropTypes.arrayOf(ShareValue),
  valuation_approach: PropTypes.number,
  cap_table: PropTypes.number,
});

const Fund = PropTypes.shape({ ...BasicInfo });
const WeightedShareValue = PropTypes.shape({
  fund: Fund,
  share_values: PropTypes.arrayOf(
    PropTypes.shape({
      ...ID,
      price: PropTypes.number,
      shares: PropTypes.number,
      xfactor: PropTypes.number,
      security: PropTypes.string,
      unrealized_value: PropTypes.number,
    })
  ),
});

const CapTableSecurity = PropTypes.shape({
  ...BasicInfo,
  security_type: PropTypes.number,
  shares_outstanding: PropTypes.string,
  order: PropTypes.number,
});

const CapTable = PropTypes.shape({
  ...BasicInfo,
  is_primary: PropTypes.bool,
  securities: PropTypes.arrayOf(CapTableSecurity),
});

const AllocationScenario = PropTypes.shape({
  ...CommonProps,
  cap_table: CapTable,
  scenario_type: PropTypes.number,
  equity_value: PropTypes.string,
  scenario_values: PropTypes.shape({}),
  allocation: PropTypes.number,
});

const Allocation = PropTypes.shape({
  ...CommonProps,
  allocation_scenarios: PropTypes.arrayOf(AllocationScenario),
  weighted_share_values: PropTypes.arrayOf(WeightedShareValue),
});

const ScenarioValuesShareValue = PropTypes.shape({
  ...ID,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  share: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  x_factor: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
});

const ScenarioValues = PropTypes.shape({
  aggregate_values: PropTypes.arrayOf(PropTypes.shape({ ...ID, value: PropTypes.number })),
  present_values: PropTypes.arrayOf(PropTypes.shape({ ...ID, value: PropTypes.number })),
  share_values: PropTypes.oneOfType([PropTypes.arrayOf(ScenarioValuesShareValue), PropTypes.shape({})]),
});

const Scenario = PropTypes.shape({
  ...CommonProps,
  cap_table: CapTable,
  scenario_type: PropTypes.number,
  equity_value: PropTypes.string,
  allocations_scenarios_weights: PropTypes.arrayOf(PropTypes.shape({ ...ID, weight: PropTypes.string })),
  scenario_values: ScenarioValues,
});

const WeightedSVShareValue = PropTypes.shape({
  ...ID,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shares: PropTypes.number,
  xfactor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unrealized_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

const WeightedSV = PropTypes.shape({
  fund: PropTypes.shape(BasicInfo),
  name: PropTypes.string,
  share_values: PropTypes.arrayOf(WeightedSVShareValue),
});

const AllocationInfo = PropTypes.shape({
  ...CommonProps,
  allocation_scenarios: PropTypes.arrayOf(Scenario),
  weighted_share_values: PropTypes.arrayOf(WeightedSV),
});

const Multiples = PropTypes.shape({});
const TmData = PropTypes.shape({ ...CommonProps, is_projection: PropTypes.bool, statement_date: PropTypes.string });
const CapTableList = PropTypes.shape({
  ...CommonProps,
  is_primary: PropTypes.bool,
  is_empty: PropTypes.bool,
  securities: PropTypes.arrayOf(
    PropTypes.shape({ ...ID, security: PropTypes.number, shares: PropTypes.number, security_type: PropTypes.number })
  ),
});

// New or Missed PropTypes
const SelectedMeasurementDate = PropTypes.shape({
  ...CommonProps,
  date: PropTypes.string,
  is_open: PropTypes.bool,
  cmd_id: PropTypes.number,
  company_id: PropTypes.number,
});

const PrimaryCaptableSecurity = PropTypes.shape({
  ...CommonProps,
  shares_outstanding: PropTypes.string,
  conversion_rate: PropTypes.string,
  security_type: PropTypes.number,
});
const PrimaryCaptable = PropTypes.shape({
  ...CommonProps,
  securities: PropTypes.arrayOf(PrimaryCaptableSecurity),
  is_primary: PropTypes.bool,
});

const ValuationInfo = PropTypes.shape({
  ...CommonProps,
  valuations_approaches: PropTypes.arrayOf(ValuationApproach),
  allocation: Allocation,
});

// AllocationsPropTypes with added missing types
const AllocationsPropTypes = {
  allocationInfo: AllocationInfo,
  capTableList: PropTypes.arrayOf(CapTableList),
  identifier: PropTypes.number,
  isForbidden: PropTypes.bool,
  ltmData: TmData,
  multiples: Multiples,
  ntmData: TmData,
  primaryCaptable: PrimaryCaptable,
  scenarios: PropTypes.arrayOf(Scenario),
  selectedMeasurementDate: SelectedMeasurementDate,
  valuationInfo: ValuationInfo,
  weightedSV: PropTypes.arrayOf(WeightedSV),
};

export default AllocationsPropTypes;
