export const extractCells = (formula = []) => {
  if (formula.includes('-') || formula.includes('+')) {
    // Regular expression to extract cells in format A4 or A5
    const regex = /[A-Z]{1,10}\d+(?=\D|$)/g;
    // Return all matches found in the formula
    return formula.match(regex);
  }
  if (formula.includes('SUM')) {
    // Regular expression to extract values within parentheses
    const sumRegex = /\(([^()]*)\)/;
    // Find the content inside parentheses
    const values = formula.match(sumRegex)[1];
    // Split comma-separated values and trim spaces
    return values.split(',').map(cell => cell.trim());
  }
  return [];
};
