import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    position: 'absolute',
    width: '100%',
    height: '100%',
    right: 0,
  },
  chip: {
    zIndex: 2,
    padding: `${theme.spacing(0.25)}px ${theme.spacing(2)}px`,
    borderRadius: 25,
    position: 'absolute',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

const HiddenContentChip = ({ isVisible, label, handleClick, atRight, smallLabel = false }) => {
  const classes = useStyles();

  const calculateChipXShift = () => (smallLabel ? -69 : -66) - (label.length - 9) * 5;
  const calculateChipWidth = () => 160 + (label.length - 9) * 10;

  if (isVisible) {
    return (
      <div className={classes.root}>
        <Chip
          style={
            atRight
              ? {
                transform: 'rotate(-90deg)',
                right: calculateChipXShift(),
                width: calculateChipWidth(),
              }
              : {
                transform: 'rotate(-270deg)',
                left: calculateChipXShift(),
                width: calculateChipWidth(),
              }
          }
          icon={<AddIcon />}
          label={label}
          color="primary"
          className={classes.chip}
          size="small"
          onClick={handleClick}
          clickable
        />
      </div>
    );
  }

  return null;
};

HiddenContentChip.defaultPropTypes = {
  isVisible: false,
};

HiddenContentChip.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  atRight: PropTypes.bool,
  smallLabel: PropTypes.bool,
};

export default withTheme(HiddenContentChip);
