import * as messages from 'common/constants/messages/validations';

/**
 *
 * @param {any} value - The value to be validated
 * @param {object} options - Additional options for the validation.
 * @param {string} options.field - The name of the field to compare with.
 * @param {object} attributes - The object containing the attributes or values of the form or model.
 * @returns {string|null} - Returns an error message if the value is invalid, otherwise null.
 */

const differentThanValidator = (value, options, key, attributes) => {
  const tempValue = value ?? '';
  const field = options.field || '';
  const fieldValue = attributes[field] || '';
  const areEqual = tempValue.toString().toLowerCase().includes(fieldValue.toString().toLowerCase());

  return areEqual ? messages.SIMILAR_TO(field) : null;
};

export default differentThanValidator;
