import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  yesButton: {
    '&.MuiButton-containedSizeSmall': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.white,
      marginRight: '1rem',
    },
  },
  noButton: {
    '&.MuiButton-sizeSmall': {
      color: theme.palette.warning.main,
      marginRight: '1rem',
      '&:hover': {
        backgroundColor: theme.palette.warning.veryLight,
      },
    },
  },
}));

export default useStyles;
