import React, { useCallback, useContext, useState } from 'react';
import { AppBar, Box, Button, Dialog, DialogContent, Toolbar, Typography } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, useParams } from 'react-router-dom';
import { LOG_IN, UPLOADS_DOCUMENTS } from 'common/constants/process-management';
import { TaskContext } from 'context';
import MeasurementDateHeader from 'layouts/TaskLayout/components/MeasurementDateHeader';
import FirmInfoHeader from 'pages/ProcessManagement/components/FirmInfoHeader';
import theme from 'theme';
import DocumentUploadModal from './DocumentUploadModal';
import FileNameAndIcon from './FileNameAndIcon';

const DocumentList = ({
  documentsToShow,
  progress,
  requestedFiles,
  companyDocuments,
  isUploading,
  setOpenSelectionModal,
  setFilesToUpload,
  showUploadBox,
  setShowUploadBox,
}) => {
  const [open, setOpen] = useState(false);
  const { isMobile, task } = useContext(TaskContext);
  const { task: taskData } = task;
  const { processType, requestId, hmacToken } = useParams();

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const documentUploadModalProps = {
    progress,
    requestedFiles,
    isUploading,
    setOpenSelectionModal,
    setFilesToUpload,
    setShowUploadBox,
    showUploadBox,
  };

  return (
    <PerfectScrollbar
      style={
        !isMobile ? { overflowY: 'scroll', height: '40rem' } : { overflowY: 'none', height: '100%', marginTop: '1rem' }
      }>
      {documentsToShow.map(requestedFile => (
        <Box key={requestedFile.id} marginBottom="1rem">
          <FileNameAndIcon requestedFile={requestedFile} companyDocuments={companyDocuments} />
        </Box>
      ))}
      {isMobile && (
        <>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleOpen}
            style={{
              fontWeight: 400,
              marginTop: '1.5rem',
              marginLeft: '1rem',
            }}>
            <Typography
              style={{
                padding: '0.5rem 1rem',
                color: `${theme.palette.white}`,
              }}>
              {UPLOADS_DOCUMENTS}
            </Typography>
          </Button>
          <Dialog fullScreen open={open} onClose={handleClose}>
            <DialogContent
              style={{
                background:
                  'linear-gradient(254.7deg, #FFFFFF 50.36%, rgba(255, 255, 255, 0) 120.31%), url(/images/login-white-bg.jpg)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'left',
                padding: isMobile && '0',
              }}>
              <AppBar color="inherit" elevation={0} style={{ background: 'transparent' }}>
                <Toolbar>
                  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <Button color="inherit" onClick={handleClose}>
                      <ArrowBackIcon style={{ color: theme.palette.primary.main }} />
                    </Button>
                    <Link
                      to={`/${processType}/requests/${requestId}/${hmacToken}`}
                      style={{
                        color: theme.palette.primary.main,
                        padding: '0.5rem 1rem',
                        textDecoration: 'underline',
                      }}>
                      {LOG_IN}
                    </Link>
                  </Box>
                </Toolbar>
                <Toolbar>
                  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <FirmInfoHeader taskData={taskData} />
                    <MeasurementDateHeader date={taskData.measurement_date.date} />
                  </Box>
                </Toolbar>
              </AppBar>
              <DocumentUploadModal {...documentUploadModalProps} />
            </DialogContent>
          </Dialog>
        </>
      )}
    </PerfectScrollbar>
  );
};

DocumentList.propTypes = {
  documentsToShow: PropTypes.array,
  progress: PropTypes.number,
  requestedFiles: PropTypes.array,
  companyDocuments: PropTypes.array,
  isUploading: PropTypes.bool,
  setOpenSelectionModal: PropTypes.func,
  setFilesToUpload: PropTypes.func,
  showUploadBox: PropTypes.bool,
  setShowUploadBox: PropTypes.func,
};

export default DocumentList;
