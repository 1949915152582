/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getGpcComps as getGpcCompsService } from 'dashboard409a/services/dashboard';
import { IGetGpcCompsResponse } from 'dashboard409a/services/types';
import { IGpcCompsState } from './types';

const initialState: IGpcCompsState = {
  loading: false,
  gpcComps: null,
};

export const getGpcComps = createAsyncThunk<IGetGpcCompsResponse, number>(
  'gpcComps/getGpcComps',
  async (dateId, thunkAPI) => {
    try {
      return await getGpcCompsService(dateId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const gpcCompsSlice = createSlice({
  name: 'gpcComps',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getGpcComps.pending, state => {
      state.loading = true;
    });
    builder.addCase(getGpcComps.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.gpcComps = payload;
    });
    builder.addCase(getGpcComps.rejected, state => {
      state.loading = false;
    });
  },
});

export default gpcCompsSlice.reducer;
