/* eslint-disable import/no-unresolved */
import { range } from 'lodash';
import {
  ALLOCATION_PERCENTAGE,
  BREAKPOINT_INFINITY,
  BREAKPOINT_PRICE_ALIAS,
  BREAKPOINT_PRO_RATA,
  BREAKPOINT_RANGE,
  BREAKPOINT_SERIE_ALIAS,
  BREAKPOINT_TITLE,
  BREAKPOINT_TOTAL_ALIAS,
  MAX_DECIMAL_PLACES_WHEN_EDITING_BPS,
} from 'common/constants/cap-table/capTable';
import { currencyFormat, twoDecimalPercentFormat } from 'common/formats/formats';
import { alphabetGenerator } from 'utillities/alphabet-utilities';

const ALLOCATION_PERCENTAGE_DECIMAL_PLACES = 17;

const getFormat = ({ isLastColumn, digits = 0, isCustomBreakpointsTable }) => {
  if (isLastColumn && !isCustomBreakpointsTable) return null;
  if (isLastColumn && isCustomBreakpointsTable)
    return twoDecimalPercentFormat({ minimumFractionDigits: 2, validateFloat: true });
  return currencyFormat({ fractionDigits: digits });
};

const getGridType = ({ isProRata, isCustomBreakpointsTable, isLastColumn }) => {
  if (isProRata) return 'string';
  if (isCustomBreakpointsTable && isLastColumn) return 'percentage';
  return 'number';
};

const parser = async ({ columns, tableData }) => {
  const { currencySymbol, isCustomBreakpointsTable, isDisabled } = tableData;
  let parsedColumns = {};
  let colCounter = 0;

  // Generate Alphabet
  const alphabet = alphabetGenerator([], columns.length);
  // get max number of rows to then locate the total row
  let totalRowIndexes = [];
  const totalColumns = Object.keys(columns[0]).length;
  const numberOfRows = isCustomBreakpointsTable ? totalColumns - 1 : totalColumns;
  const totalRowIndex = () => Math.max(...totalRowIndexes);
  // set total index numbers to later define the total position
  columns.forEach(column => {
    if (column.series) {
      totalRowIndexes = [...totalRowIndexes, column.series.length + numberOfRows - 2];
    }
  });

  // generate each cell
  columns.forEach((column, columnIndex) => {
    let rowCounter = 0;
    const columnLegend = rowIndex => {
      rowCounter += 1;
      return alphabet[colCounter] + (rowIndex || rowCounter);
    };

    const isLastColumn = columnIndex === columns.length - 1;

    const series = bpColumn => {
      if (bpColumn.series) {
        let bpSeries = {};
        bpColumn.series.forEach(serie => {
          const isProRata = serie.value === BREAKPOINT_PRO_RATA;
          bpSeries = {
            ...bpSeries,
            [columnLegend()]: {
              value: serie.value,
              format: {
                ...getFormat({ isLastColumn, isCustomBreakpointsTable, digits: 0 }),
                maximumFractionDigitsWhenEditing: MAX_DECIMAL_PLACES_WHEN_EDITING_BPS,
              },
              gridType: getGridType({ isProRata, isCustomBreakpointsTable, isLastColumn }),
              key: `${alphabet[columnIndex]}${rowCounter}`,
              name: bpColumn.name,
              alias: isLastColumn && isCustomBreakpointsTable ? ALLOCATION_PERCENTAGE : BREAKPOINT_SERIE_ALIAS,
              isBpValue: !isProRata,
              readOnly: isDisabled || (isCustomBreakpointsTable ? isProRata : true),
              columnId: column.id,
              dbDecimalPlaces:
                isLastColumn && isCustomBreakpointsTable ? ALLOCATION_PERCENTAGE_DECIMAL_PLACES : undefined,
            },
          };
        });

        return bpSeries;
      }
      return {};
    };

    const getTotalSeries = () => {
      if (isLastColumn) {
        return BREAKPOINT_INFINITY;
      }
      const totalSeries = column.series.reduce((acc, serie) => acc + Number(serie.value), 0) || 0;
      return totalSeries;
    };

    const getConcatExpr = () => {
      const isFirstColumn = columnIndex === 0;
      const isLastColumn = columnIndex === columns.length - 1;
      const previousCellLeter = alphabet[columnIndex - 1];
      const currentCellLeter = alphabet[columnIndex];
      const previousCell = isFirstColumn ? `"${currencySymbol}0"` : `${previousCellLeter}${totalRowIndex()}`;
      const currentCell = isLastColumn ? `"${BREAKPOINT_INFINITY}"` : `${currentCellLeter}${totalRowIndex()}`;
      return `=BP_RANGE(
        "${currencySymbol}",
        ${previousCell},
        ${currentCell},
        ${isFirstColumn},
        ${isLastColumn},
        "${previousCellLeter}",
        "${currentCellLeter}"
      )`;
    };

    const isEmptyColumn = column.price === column.total && column.total === 0;
    const getExpr = () => {
      const cell = columns[columnIndex];
      const { series } = cell;
      const hasProRateValue = series.findIndex(serie => serie.value === BREAKPOINT_PRO_RATA) !== -1;
      if (hasProRateValue) return null;
      const rangeCells = range(4, totalRowIndex()).map(rowIndex => `${alphabet[columnIndex]}${rowIndex}`);
      return `=SUM(${rangeCells})`;
    };

    parsedColumns = {
      ...parsedColumns,
      // name:
      [columnLegend()]: {
        value: `Breakpoint ${columnIndex + 1}`,
        alias: BREAKPOINT_TITLE,
        key: `${alphabet[columnIndex]}${rowCounter}`,
        isValid: !isEmptyColumn,
      },
      // range:
      [columnLegend()]: {
        alias: BREAKPOINT_RANGE,
        gridType: 'string',
        format: null,
        key: `${alphabet[columnIndex]}${rowCounter}`,
        className: 'subtitle double-height',
        ...(isCustomBreakpointsTable ? { expr: getConcatExpr() } : null),
        value: column.range.replace(' to ', '\nto '),
      },
      // price:
      [columnLegend()]: {
        value: column.price,
        format: getFormat({ isLastColumn, digits: 3, isCustomBreakpointsTable }),
        gridType: 'number',
        key: `${alphabet[columnIndex]}${rowCounter}`,
        name: 'price',
        alias: BREAKPOINT_PRICE_ALIAS,
        className: 'subtitle',
        readOnly: isCustomBreakpointsTable ? column.price === BREAKPOINT_INFINITY : true,
      },
      // series:
      ...series(column),
      // total:
      [columnLegend(totalRowIndex())]: {
        value: getTotalSeries(),
        format: getFormat({ isLastColumn, isCustomBreakpointsTable }),
        gridType: 'number',
        isTotal: true,
        key: `${alphabet[columnIndex]}${rowCounter}`,
        expr: getExpr(),
        alias: BREAKPOINT_TOTAL_ALIAS,
        className: 'subtitle',
      },
    };

    colCounter += 1;
  });

  return parsedColumns;
};

export default parser;
