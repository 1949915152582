import {
  AS_OF_DATE_ALIAS,
  AS_OF_DATE_COLUMN_LEGEND,
  AS_OF_DATE_EQUAL_TO_MD,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/MultiplePremiumDiscount/common/constants/sheetTitlesAndAliases';

const validations = ({ cell, addFeedback, removeFeedback, parsedColumns }) => {
  try {
    const { alias, value, columnLegend, sheet } = cell;
    const measurementDateValue = parsedColumns?.C2?.value;
    if (sheet && alias === AS_OF_DATE_ALIAS && columnLegend === AS_OF_DATE_COLUMN_LEGEND && measurementDateValue) {
      const feedbackMessage = AS_OF_DATE_EQUAL_TO_MD(measurementDateValue);
      if (value === measurementDateValue) {
        addFeedback(feedbackMessage);
      } else {
        removeFeedback(feedbackMessage);
      }
    }
  } catch (error) {
    throw new Error(`Error in validations: ${error}`);
  }
};

export default validations;
