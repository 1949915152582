import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BUTTON, FIRM_INFO, LINK, MEASUREMENT_DATE } from 'common/constants/process-management';
import { useStore } from 'common/store';
import MeasurementDateHeader from 'layouts/TaskLayout/components/MeasurementDateHeader';
import { useLogOutUser } from 'services/hooks/auth';
import FirmInfoHeader from './FirmInfoHeader';

const PMHeaderMenuItem = ({ item, taskData, isMobile, classes }) => {
  const showItem = !isMobile || (isMobile && !item.hideOnMobile);
  const logOutUser = useLogOutUser();
  const [{ user }] = useStore();

  function handleLogOut() {
    logOutUser();
  }

  if (showItem && item.component === LINK) {
    return (
      <Link to={item.link} className={classes.link} onClick={user && handleLogOut}>
        <Typography component={classes.title}>{item.label}</Typography>
        {item.withArrow && <ArrowForwardIcon className={classes.arrow} />}
      </Link>
    );
  }
  if (showItem && item.component === BUTTON) {
    return (
      <Link to={item.link}>
        <Button variant="outlined" color="primary" style={{ padding: '0.5rem 2rem' }}>
          {item.label}
        </Button>
      </Link>
    );
  }
  if (showItem && item.component === MEASUREMENT_DATE) {
    return (
      <Box className={classes.measurementDateHeader} marginLeft={isMobile ? 'auto' : undefined}>
        <MeasurementDateHeader date={taskData.measurement_date.date} />
      </Box>
    );
  }
  if (showItem && item.component === FIRM_INFO) {
    return <FirmInfoHeader taskData={taskData} />;
  }
  return null;
};

PMHeaderMenuItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    component: PropTypes.string,
    label: PropTypes.string,
    link: PropTypes.string,
    withArrow: PropTypes.bool,
    hideOnMobile: PropTypes.bool,
  }),
  taskData: PropTypes.shape({
    measurement_date: PropTypes.shape({
      date: PropTypes.string,
    }),
  }),
  isMobile: PropTypes.bool,
  classes: PropTypes.shape({
    link: PropTypes.string,
    title: PropTypes.string,
    arrow: PropTypes.string,
    measurementDateHeader: PropTypes.string,
  }),
};

export default PMHeaderMenuItem;
