import React, { FC } from 'react';
import {
  ButtonProps,
  Grid,
  GridProps,
  IconButton,
  IconButtonProps,
  InputBaseComponentProps,
  Button as MuiButton,
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableCellProps,
  TableProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { AddCardIcon } from '../assets/img';
import { IAddCardButtonProps, ICreditCardTextFieldProps } from '../types';

export const TableCell = withStyles(theme => ({
  body: {
    fontSize: 14,
    backgroundColor: theme.palette.common.white,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[300],
    padding: 5,
    textAlign: 'right',
  },
}))(MuiTableCell) as FC<TableCellProps>;

export const SelectedCell = withStyles(() => ({
  body: {
    width: 40,
    paddingRight: 20,
    paddingLeft: 10,
  },
}))(TableCell) as FC<TableCellProps>;

export const TypeCell = withStyles(() => ({
  body: {
    width: 40,
    padding: 2,
  },
}))(TableCell) as FC<TableCellProps>;

export const ActionsCell = withStyles(() => ({
  body: {
    width: 60,
    padding: 0,
    textAlign: 'right',
  },
}))(TableCell) as FC<TableCellProps>;

export const ActionsButton = withStyles(theme => ({
  root: {
    color: theme.palette.grey[300],
    padding: 8,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))(IconButton) as FC<IconButtonProps>;

export const RemoveActionsButton = withStyles(theme => ({
  root: {
    color: theme.palette.grey[300],
    padding: 8,
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
}))(IconButton) as FC<IconButtonProps>;

export const Table = withStyles(() => ({
  root: {
    borderCollapse: 'separate',
    width: '100%',
    marginTop: 20,
  },
}))(MuiTable) as FC<TableProps>;

const Button = withStyles(() => ({
  root: {
    borderRadius: 20,
    fontSize: 14,
    textTransform: 'uppercase',
  },
}))(MuiButton) as FC<ButtonProps>;

const AddCardButtonContainer = withStyles(() => ({
  root: {
    marginTop: 20,
  },
}))(Grid) as FC<GridProps>;

export const AddCardButton: FC<IAddCardButtonProps> = ({ label, onAdd }) => (
  <AddCardButtonContainer container justifyContent="flex-end">
    <Button variant="outlined" color="primary" startIcon={<AddCardIcon />} onClick={onAdd}>
      {label}
    </Button>
  </AddCardButtonContainer>
);

const CreditCardTextFieldBase = withStyles(() => ({
  root: {
    marginBottom: 20,
  },
}))(TextField) as FC<TextFieldProps>;

export const CreditCardBrandTextField = withStyles(() => ({
  root: {
    textTransform: 'uppercase',
  },
}))(Typography) as FC<TypographyProps>;

export const CreditCardTextField: FC<ICreditCardTextFieldProps & TextFieldProps> = props => (
  <CreditCardTextFieldBase
    variant="outlined"
    InputLabelProps={{
      shrink: true,
    }}
    {...props}
  />
);

const CreditCardExpDateTextMask: FC<InputBaseComponentProps> = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...(other as MaskedInputProps)}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/]}
      showMask
      guide
      keepCharPositions
      pipe={createAutoCorrectedDatePipe('mm/yy', {
        minYear: 1900,
      })}
    />
  );
};

export const CreditCardExpDateTextField: FC<ICreditCardTextFieldProps & TextFieldProps> = props => (
  <CreditCardTextField {...props} InputProps={{ inputComponent: CreditCardExpDateTextMask }} />
);

export const FormContentContainer = withStyles(() => ({
  root: {
    padding: 10,
  },
}))(Grid);

export const CreditCardManagementContainer = withStyles(() => ({
  root: {
    padding: 30,
  },
}))(Grid) as FC<GridProps>;

export const RenewalContainer: FC = ({ children }) => (
  <div
    style={{
      marginBottom: 30,
      border: '1px solid #e0e0e0',
      borderRadius: 5,
      paddingRight: 15,
      paddingTop: 10,
      paddingBottom: 10,
    }}>
    {children}
  </div>
);
