import React, { FC, useContext, useEffect, useMemo } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { companySummaryUrl } from 'common/config/urls';
import { useStore } from 'common/store';
import { LayoutContextValues, PageBreadcrumbs, UseStoreValues } from 'common/types/store';
import { ReadyForAuditBadge } from 'components/ReadyForAuditBadge';
import LayoutContext from 'context/LayoutContext';
import { getStringValue } from 'utillities';
import { BreadcrumbEditButtonProps, UseCompanyBreadcrumbParams } from './types';

const VALUATION_PAGE = 'Valuations';

const BreadcrumbEditButton: FC<BreadcrumbEditButtonProps> = props => {
  const { handleOnClick } = props;

  return (
    <Tooltip arrow title="Edit">
      <IconButton className="edit-icon" id="edit-handler-btn" onClick={handleOnClick} size="small">
        <EditIcon aria-label="edit" color="primary" style={{ fontSize: '1.2rem' }} />
      </IconButton>
    </Tooltip>
  );
};

const getPageTitleForFirstBreadcrumb = (
  page: string | undefined,
  firmOwned: boolean | undefined,
  firmName: string | undefined,
  companyName: string | undefined
) => {
  if (page === VALUATION_PAGE) {
    return firmOwned ? firmName : 'Scalar Valuation';
  }
  return companyName;
};

const useCompanyBreadcrumb = (params: UseCompanyBreadcrumbParams) => {
  const { customAction, item, page } = params;

  const [storeValues] = useStore() as unknown as UseStoreValues;
  const { companyInfo, firmInfo, valuationInfo } = storeValues;
  const { is_firm_owned: firmOwned, is_ready_for_audit: isValuationReadyForAudit } = valuationInfo ?? {};
  const { name: firmName } = firmInfo ?? {};

  const { setPageBreadcrumbs, togglePageForm, viewOnlyUser } = useContext(
    LayoutContext
  ) as unknown as LayoutContextValues;

  const companyURL = useMemo(
    () => companySummaryUrl(getStringValue(firmInfo?.slug), getStringValue(companyInfo?.slug)),
    [companyInfo, firmInfo]
  );

  const versionName = useMemo(() => getStringValue(item?.label), [item]);
  const versionStatus = useMemo(() => item?.status ?? null, [item]);
  const allocationVersion = useMemo(() => item?.allocationVersion ?? null, [item]);
  const handleOnClickEditButton = useMemo(() => customAction ?? togglePageForm, [customAction, togglePageForm]);

  const companyBreadcrumbs = useMemo(() => {
    if (isEmpty(companyInfo)) return [];

    const breadcrumbs = [
      {
        title: getPageTitleForFirstBreadcrumb(page, firmOwned, firmName, companyInfo.name),
        ...(page === VALUATION_PAGE && { isScalarOwned: !firmOwned }),
        ...(page !== VALUATION_PAGE && { url: companyURL }),
      },
      {
        title: page,
      },
    ] as PageBreadcrumbs[];

    if (versionName) {
      // Only show version status
      if (isEmpty(allocationVersion)) {
        breadcrumbs.push({
          title: versionName,
          icon: (
            <>
              {versionStatus}
              {isValuationReadyForAudit && !viewOnlyUser && <ReadyForAuditBadge />}
              <BreadcrumbEditButton handleOnClick={handleOnClickEditButton} />
            </>
          ),
        });
      }
      // Show version status and allocation version status
      else {
        const { label: allocationVersionName, status: AllocationVersionStatus } = allocationVersion;

        breadcrumbs.push(
          ...[
            {
              title: versionName,
            },
            {
              title: allocationVersionName,
              icon: (
                <>
                  {AllocationVersionStatus}
                  <BreadcrumbEditButton handleOnClick={handleOnClickEditButton} />
                </>
              ),
            },
          ]
        );
      }
    }

    return breadcrumbs;
  }, [
    allocationVersion,
    companyInfo,
    companyURL,
    handleOnClickEditButton,
    page,
    versionName,
    versionStatus,
    firmOwned,
    firmName,
    isValuationReadyForAudit,
    viewOnlyUser,
  ]);

  // Set Company breadcrumbs
  useEffect(() => {
    setPageBreadcrumbs?.(companyBreadcrumbs);

    return () => setPageBreadcrumbs?.(null);
  }, [companyBreadcrumbs, setPageBreadcrumbs, isValuationReadyForAudit]);
};

export default useCompanyBreadcrumb;
