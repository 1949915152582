import React, { useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useGoogleLogin } from '@react-oauth/google';
import PropTypes from 'prop-types';
import { ERROR_500 } from 'common/config/api';
import { GOOGLE, MICROSOFT } from 'common/constants/login';
import * as messages from 'common/constants/messages/validations';
import { UNCONTROLLED_ERROR } from 'common/constants/messages/validations';
import { Google as GoogleIcon, Microsoft as MicrosoftIcon } from 'icons';
import { useGetSocialAccessToken } from 'services/hooks/auth';

const useStyles = makeStyles(theme => ({
  buttonGM: {
    textTransform: 'none',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: '0.875rem',
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    transition: 'background-color 0.5s',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      transition: 'background-color 0.5s',
      cursor: 'pointer',
    },
  },
  text: {
    textAlign: 'center',
    marginLeft: theme.spacing(),
  },
}));

const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_AZURE_ID}`,
  },
};

const SocialLogin = ({ setError, setAlert }) => {
  const classes = useStyles();
  const getSocialAccessToken = useGetSocialAccessToken();
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [isLoadingMicrosoft, setIsLoadingMicrosoft] = useState(false);
  const msalInstance = new PublicClientApplication(msalConfig);

  const handleSocialLogin = async payload => {
    try {
      const response = await getSocialAccessToken(payload);
      if (response && response.statusCode === ERROR_500) {
        if (payload.provider === GOOGLE) {
          setIsLoadingGoogle(false);
          setAlert(true);
          setError(UNCONTROLLED_ERROR);
        } else if (payload.provider === MICROSOFT) {
          setIsLoadingMicrosoft(false);
          setAlert(true);
          setError(UNCONTROLLED_ERROR);
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const login = async (accessToken, provider) => {
    try {
      if (provider === GOOGLE) {
        const payload = {
          credential: `Bearer ${accessToken}`,
          provider,
        };
        await handleSocialLogin(payload);
      } else if (provider === MICROSOFT) {
        const payload = {
          id_token: accessToken,
          provider,
        };
        await handleSocialLogin(payload);
      }
    } catch (error) {
      setAlert(true);
      setError(messages.UNABLE_TO_LOGIN_TRY_AGAIN);
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: credentialResponse => {
      setIsLoadingGoogle(true);
      login(credentialResponse.access_token, GOOGLE);
    },
    onError: () => {
      setAlert(true);
      setError(messages.UNABLE_TO_LOGIN_TRY_AGAIN);
    },
    ux_mode: 'popup',
    flow: 'implicit',
  });

  const handleMicrosoftLogin = () => {
    msalInstance
      .loginPopup()
      .then(response => {
        setIsLoadingMicrosoft(true);
        const accessToken = {
          rawIdToken: response.idToken,
          claims: response.idTokenClaims,
        };
        login(accessToken, MICROSOFT);
      })
      .catch(() => {
        setAlert(true);
        setError(messages.UNABLE_TO_LOGIN_TRY_AGAIN);
      });
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={!isLoadingGoogle && <GoogleIcon />}
        fullWidth
        className={classes.buttonGM}
        onClick={handleGoogleLogin}>
        {isLoadingGoogle ? (
          <CircularProgress size={28} color="inherit" />
        ) : (
          <p className={classes.text}> Continue with Google</p>
        )}
      </Button>
      <Button
        variant="contained"
        startIcon={!isLoadingMicrosoft && <MicrosoftIcon />}
        fullWidth
        className={classes.buttonGM}
        onClick={handleMicrosoftLogin}>
        {isLoadingMicrosoft ? (
          <CircularProgress size={28} color="inherit" />
        ) : (
          <p className={classes.text}> Continue with Microsoft</p>
        )}
      </Button>
    </>
  );
};

SocialLogin.propTypes = {
  setError: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};
export default SocialLogin;
