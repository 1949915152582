import React from 'react';
import { isNil } from 'lodash';
import { largeCurrencyFormat, weightingPercentFormat } from 'common/formats/formats';
import { Cell, Cells } from 'common/types/scalarSpreadsheet';
import { ValueWithLinkViewer } from 'components';
import { ValuationSummaryCellCustomData } from 'pages/ValuationsAllocation/approaches/ValuationSummary/config';
import { VALUATIONS_CAP_TABLE_CURRENCY_APPROACHES } from 'pages/ValuationsAllocation/common/constants/valuations';
import {
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import {
  generateColumnKey,
  generateTotalExpression,
  getExpression,
  getNumberValue,
  getObjectValue,
  getStringValue,
} from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { CellParserParams, ValuationSummaryParserParams } from './types';
import {
  generateScenarioEquityValueExpression,
  generateWeightedEquityValueExpression,
  getCollapsableColumns,
  handleAllocationMethodColumn,
  handleAllocationScenariosWeights,
  handleEnterpriseValueColumn,
  handleHeaderTitleColumn,
  handleScenarioEquityValueColumn,
} from './utils';

const {
  VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_METHOD,
  VALUATION_SUMMARY_SPREADSHEET_HEADER_TITLE,
  VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY,
  VALUATION_SUMMARY_SPREADSHEET_LESS_CASH,
  VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY,
  VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT,
  VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE,
  VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
  VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_ENTERPRISE_VALUE_KEY,
  VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_ENTERPRISE_VALUE,
  VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE_KEY,
  VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE,
} = SHEET_ALIASES_CONSTANTS;

const { FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER, getAdjustedColumnNumber, VALUATION_SUMMARY_SPREADSHEET_HEADERS }
  = SHEET_TITLES_CONSTANTS;
const { APPROACH_TYPE, ENTERPRISE_VALUE, EQUITY_VALUE } = VALUATION_SUMMARY_SPREADSHEET_HEADERS;

const HEADER_TITLE_AND_SUB_TITLE_ROWS = 2;

const cellParser = (params: CellParserParams) => {
  const {
    allocationMethodsOptions,
    allocationScenariosQuantity,
    alphabet,
    approachesRowNumbers,
    colIndex,
    column,
    columnsLength,
    companyExchangeRate,
    equityValueColumnLegend,
    financialsCurrency,
    isDisabled,
    isUniformCurrency,
    lessCashTotal,
    plusDebtTotal,
    row,
    rowIndex,
    weightedEquityValueExpression,
  } = params;

  const colNumber = colIndex + 1;
  const rowNumber = rowIndex + 1;
  const columnLegend = alphabet[colIndex];
  const cellKey = columnLegend + rowNumber;

  const {
    approachType,
    panelId,
    scenarioId,
    scenarioRef,
    scenarioType,
    value: columnValue,
  } = getObjectValue(column[row.alias]);

  // Creating Cell from Row and Column
  const cell = { ...row, key: cellKey, value: columnValue };

  const adjustedColumnNumber = getAdjustedColumnNumber({ colNumber, isUniformCurrency });

  const previousColumnLegend = alphabet[colIndex - 1];
  const previousKey = previousColumnLegend + rowNumber;
  const isLastColumn = colNumber === columnsLength;

  const isCapTableCurrency = VALUATIONS_CAP_TABLE_CURRENCY_APPROACHES.includes(getStringValue(approachType));

  // For Cap Table Approaches, the Enterprise Value should not be displayed in the Enterprise Value (Financials) column
  const shouldNotDisplayEnterpriseValue = isCapTableCurrency && !isUniformCurrency;

  // Scenario Equity Value Expression (Sum of All Approaches - Allocation Scenarios -> Equity Value * Weight)
  const scenarioEquityValueExpression = `=SUM(${approachesRowNumbers
    .map(approachRowNumber =>
      generateScenarioEquityValueExpression({
        approachRowNumber,
        columnLegend,
        equityValueColumnLegend,
      })
    )
    .join(',')})`;

  const scenarioEquityValueCustomKey = generateColumnKey({
    id: scenarioId,
    name: VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE,
    prefix: columnLegend,
  });

  // Parse Cell based on Row alias
  switch (row.alias) {
    case VALUATION_SUMMARY_SPREADSHEET_HEADER_TITLE:
      handleHeaderTitleColumn({ allocationScenariosQuantity, cell, colNumber, isUniformCurrency });
      break;

    case VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_METHOD:
      handleAllocationMethodColumn({
        adjustedColumnNumber,
        allocationMethodsOptions,
        cell,
        colNumber,
        financialsCurrency,
        isUniformCurrency,
      });
      break;

    case VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE:
      handleScenarioEquityValueColumn({
        cell,
        scenarioEquityValueCustomKey,
        scenarioEquityValueExpression,
      });
      break;

    case VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY:
      cell.dbDecimalPlaces = 1;
      cell.dbType = 'number';
      cell.defaultZero = true;
      cell.format = weightingPercentFormat;
      cell.gridType = 'percentage';

      // Custom Data for Scenario Weighting/Probability
      cell.data = {
        ...getObjectValue(cell.data),
        scenarioId,
        scenarioRef,
      } as ValuationSummaryCellCustomData;

      if (isNil(cell.value)) {
        cell.hidden = true;
        cell.readOnly = true;
      }
      break;

    case VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE:
      cell.hidden = true;

      if (isLastColumn) {
        cell.customKey = VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE_KEY;
        cell.expr = weightedEquityValueExpression;
        cell.format = largeCurrencyFormat;
        cell.gridType = 'number';
        cell.hidden = false;
      }
      break;

    case VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT:
      cell.hidden = true;

      if (isLastColumn) {
        cell.customKey = VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY;
        cell.format = largeCurrencyFormat;
        cell.gridType = 'number';
        cell.hidden = false;
        cell.value = plusDebtTotal;
      }
      break;

    case VALUATION_SUMMARY_SPREADSHEET_LESS_CASH:
      cell.hidden = true;

      if (isLastColumn) {
        cell.customKey = VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY;
        cell.format = largeCurrencyFormat;
        cell.gridType = 'number';
        cell.hidden = false;
        cell.value = lessCashTotal;
      }
      break;

    case VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_ENTERPRISE_VALUE:
      cell.hidden = true;

      if (isLastColumn) {
        cell.customKey = VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_ENTERPRISE_VALUE_KEY;
        cell.format = largeCurrencyFormat;
        cell.gridType = 'number';
        cell.hidden = false;
        cell.expr = `=${VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE_KEY} + ${VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY} - ${VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY}`;
      }
      break;

    // Rest of the Rows (Approaches)
    default:
      // Parse Cell based on Column number
      switch (adjustedColumnNumber) {
        case APPROACH_TYPE.columnNumber:
          cell.component = <ValueWithLinkViewer name={getStringValue(cell.value as string)} />;
          cell.forceComponent = true;
          cell.readOnly = true;
          break;

        case ENTERPRISE_VALUE.columnNumber:
          handleEnterpriseValueColumn({
            cell,
            colNumber,
            companyExchangeRate,
            financialsCurrency,
            isUniformCurrency,
            panelId,
            previousKey,
            shouldNotDisplayEnterpriseValue,
          });
          break;

        case EQUITY_VALUE.columnNumber:
          // Equity Value = Enterprise Value - Debt + Cash
          cell.expr = `=${previousKey} - ${VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY} + ${VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY}`;
          cell.format = largeCurrencyFormat;
          cell.gridType = 'number';
          cell.readOnly = true;

          cell.data = {
            ...getObjectValue(cell.data),
            isEquityValue: true,
            panelId,
          } as ValuationSummaryCellCustomData;
          break;

        default:
          // Handle Approaches Allocation Scenarios Weights
          handleAllocationScenariosWeights({
            cell,
            colNumber,
            isDisabled,
            isUniformCurrency,
            panelId,
            scenarioId,
            scenarioRef,
            scenarioType,
          });
          break;
      }
      break;
  }

  // Handle Collapsable Columns
  const { columnId, isParent, parentColumn } = getCollapsableColumns({ colNumber, isUniformCurrency });

  // Set default Cell values
  const {
    allowNegativeValue = true,
    className = '',
    colSpan = 1,
    component = null,
    customKey = null,
    data = {},
    dbDecimalPlaces = 0,
    dbType = 'string',
    defaultZero = false,
    expr = '',
    forceComponent = false,
    format = null,
    gridType = 'string',
    hidden = false,
    readOnly = false,
    staticCurrency = null,
    value = '',
  } = getObjectValue(cell);

  return {
    [cell.key]: {
      ...cell,
      allowNegativeValue,
      className,
      colSpan,
      columnId,
      columnLegend,
      component,
      customKey,
      data,
      dbDecimalPlaces,
      dbType,
      defaultZero,
      expr: getExpression({ expr, columnLegend }),
      forceComponent,
      format,
      gridType,
      hidden,
      isParent,
      parentColumn,
      readOnly,
      staticCurrency,
      value,
    } as Cell,
  };
};

const customParser = (params: ValuationSummaryParserParams) => {
  const {
    allocationMethodsOptions,
    allocationScenarios,
    approaches,
    columns,
    companyExchangeRate,
    financialsCurrency,
    isDisabled,
    isUniformCurrency,
    lessCashTotal,
    plusDebtTotal,
    rowConfig,
  } = params;

  const nonCalibrationApproaches = approaches.filter(approach => !approach.is_calibration);

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];
  const columnsLength = columns.length;

  const allocationScenariosQuantity = allocationScenarios.length;

  const allocationScenariosColumnNumbers = generateTotalExpression({
    identifier: '',
    items: allocationScenarios,
    skippedIndexes: getAdjustedColumnNumber({
      colNumber: FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER,
      includeAllColumns: true,
      isUniformCurrency: !isUniformCurrency,
    }),
  }).map(columnNumber => getNumberValue(columnNumber));

  const allocationScenariosColumnLegends = allocationScenariosColumnNumbers.map(columnNumber => alphabet[columnNumber]);

  const approachesQuantity = nonCalibrationApproaches.length;
  const scenarioEquityValueRowNumber = HEADER_TITLE_AND_SUB_TITLE_ROWS + approachesQuantity + 1;

  // Weighted Equity Value Expression (Sum of All Allocation Scenarios -> Equity Value * Weighting Probability)
  const weightedEquityValueExpression = `=SUM(${allocationScenariosColumnLegends
    .map(columnLegend => generateWeightedEquityValueExpression({ columnLegend, scenarioEquityValueRowNumber }))
    .join(',')})`;

  const approachesRowNumbers = generateTotalExpression({
    identifier: '',
    items: nonCalibrationApproaches,
    skippedIndexes: HEADER_TITLE_AND_SUB_TITLE_ROWS + 1,
  }).map(rowNumber => getNumberValue(rowNumber));

  const equityValueColumnNumber = getAdjustedColumnNumber({
    colNumber: EQUITY_VALUE.columnNumber,
    includeAllColumns: true,
    isUniformCurrency: !isUniformCurrency,
  });

  const equityValueColumnLegend = alphabet[equityValueColumnNumber];

  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      cells = {
        ...cells,
        ...cellParser({
          allocationMethodsOptions,
          allocationScenariosQuantity,
          alphabet,
          approachesRowNumbers,
          colIndex,
          column,
          columnsLength,
          companyExchangeRate,
          equityValueColumnLegend,
          financialsCurrency,
          isDisabled,
          isUniformCurrency,
          lessCashTotal,
          plusDebtTotal,
          row,
          rowIndex,
          weightedEquityValueExpression,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
