import React, { useLayoutEffect, useRef, useState } from 'react';
import { makeStyles, Tooltip, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  title: {
    cursor: 'cell',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
});

const CustomTooltip = withStyles(() => ({
  tooltip: {
    margin: '0px',
  },
}))(Tooltip);

const RowLabelTooltip = ({ title, containerWidth, children }) => {
  const classes = useStyles({ containerWidth });
  const ref = useRef();
  const [titleWidth, setTitleWidth] = useState(0);
  const hasEllipsis = titleWidth >= containerWidth;

  useLayoutEffect(() => {
    if (ref.current) {
      setTitleWidth(ref.current.offsetWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <span id="row-title" className={classes.title}>
      <span ref={ref}>
        {hasEllipsis ? (
          <CustomTooltip title={typeof title === 'string' ? title : ''} placement="bottom-start">
            {children}
          </CustomTooltip>
        ) : (
          children
        )}
      </span>
    </span>
  );
};

RowLabelTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  containerWidth: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default RowLabelTooltip;
