import React from 'react';
import { Paper } from '@material-ui/core';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Check as CheckIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const MessageBox = ({
  title,
  tagline,
  type,
  action,
  secondaryAction,
  fullWidth,
  hasFrame,
  showIcon = true,
  hasBgColor,
  titleStyleOverride,
}) => {
  const Icon = () => {
    switch (type) {
      case 'success':
        return <CheckIcon className="message-box__icon" data-testid="message-box__icon" />;
      case 'success-inside':
        return <CheckCircleOutlineIcon className="message-box__inside-icon-ok" data-testid="message-box__icon" />;
      case 'error-inside':
        return <ErrorOutlineIcon className="message-box__inside-icon-error" data-testid="message-box__icon" />;
      case 'error-disabled':
        return <ErrorOutlineIcon className="message-box__icon-error-disabled" data-testid="message-box__icon" />;
      default:
        return <ErrorOutlineIcon className="message-box__icon" data-testid="message-box__icon" />;
    }
  };

  return (
    <div
      className={clsx(
        'message-box',
        hasFrame && 'message-box--with-frame',
        type && `message-box--${type}`,
        fullWidth && 'message-box--full-with',
        hasBgColor && 'message-box--with-bg-color'
      )}>
      <Paper elevation={hasFrame ? 2 : 0} className="message-box__container">
        {showIcon && <Icon />}
        <h3 className="message-box__title" style={titleStyleOverride}>
          {title}
        </h3>
        <p className="message-box__tagline">{tagline}</p>
        {action}
        {secondaryAction}
      </Paper>
    </div>
  );
};

MessageBox.defaultProps = {
  type: 'info',
  fullWidth: true,
  hasFrame: true,
  hasBgColor: false,
};

MessageBox.propTypes = {
  title: PropTypes.string.isRequired,
  tagline: PropTypes.string,
  type: PropTypes.oneOf(['info', 'error', 'success', 'success-inside', 'error-inside']),
  fullWidth: PropTypes.bool,
  hasFrame: PropTypes.bool,
  showIcon: PropTypes.bool,
  action: PropTypes.element,
  secondaryAction: PropTypes.element,
  hasBgColor: PropTypes.bool,
  titleStyleOverride: PropTypes.object,
};

export default MessageBox;
