import AuthService from './auth';
import BaseService from './base';

const refreshAccessToken = async () => {
  const authService = AuthService.getInstance();
  const refreshToken = localStorage.getItem('refresh');

  const payload = {
    refresh: refreshToken,
  };
  if (refreshToken) {
    try {
      const newAccessToken = await authService.refreshAccessToken(JSON.stringify(payload));

      if (newAccessToken) {
        localStorage.setItem('access', newAccessToken.access);
      }
    } catch (error) {
      localStorage.removeItem('refresh');
    }
  }
};

const refreshPassThrough = async next => {
  let response;
  try {
    response = await next();
  } catch (e) {
    if (e.status === 401) {
      try {
        await refreshAccessToken();
        response = await next();
      } catch (err) {
        return Promise.reject(e);
      }
      return Promise.resolve(response);
    }
    return Promise.reject(e);
  }
  return Promise.resolve(response);
};

class BaseServiceRetryWrapper extends BaseService {
  async get(url, query, moduleView, isList, headers) {
    return refreshPassThrough(() => super.get(url, query, moduleView, isList, headers));
  }

  async getFile(url, data, moduleView, isList, headers) {
    return refreshPassThrough(() => super.getFile(url, data, moduleView, isList, headers));
  }

  async put(url, data, moduleView, isList, headers) {
    return refreshPassThrough(() => super.put(url, data, moduleView, isList, headers));
  }

  async post(url, data, moduleView, isList, headers) {
    return refreshPassThrough(() => super.post(url, data, moduleView, isList, headers));
  }

  async delete(url, query, moduleView, isList, headers) {
    return refreshPassThrough(() => super.delete(url, query, moduleView, isList, headers));
  }

  async patch(url, data, moduleView, isList, headers) {
    return refreshPassThrough(() => super.patch(url, data, moduleView, isList, headers));
  }
}

export default BaseServiceRetryWrapper;
