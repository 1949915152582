import React, { FC, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { LoadingSection } from 'components';
import { CreditCardsManagment } from 'dashboard409a/components';
import { setPaymentMethod as subscriptionService } from 'dashboard409a/services/subscription';
import { RootStateType } from 'dashboard409a/states/store';
import { PromiseType } from './types';

const fetchData = async (companyId: number): Promise<PromiseType> => {
  const response = await subscriptionService(companyId);
  return response;
};

const appearance: Appearance = {
  theme: 'flat',
  variables: {
    fontFamily: ' "Gill Sans", sans-serif',
    fontLineHeight: '1.5',
    borderRadius: '10px',
    colorBackground: '#F6F8FA',
    colorPrimaryText: '#262626',
  },
  rules: {
    '.Block': {
      backgroundColor: 'var(--colorBackground)',
      boxShadow: 'none',
      padding: '12px',
    },
    '.Input': {
      padding: '12px',
    },
    '.Input:disabled, .Input--invalid:disabled': {
      color: 'lightgray',
    },
    '.Tab': {
      padding: '10px 12px 8px 12px',
      border: 'none',
    },
    '.Tab:hover': {
      border: 'none',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
    },
    '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
      border: 'none',
      backgroundColor: '#fff',
      boxShadow:
        '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
    },
    '.Label': {
      fontWeight: '500',
    },
  },
};

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const Subscription: FC = () => {
  const { currentCompany } = useSelector((state: RootStateType) => state.global);

  const companyId = useMemo(() => currentCompany?.id ?? null, [currentCompany]);
  const [customerId, setCustomerId] = useState<string>();

  const getCustomerId = async (currentCompanyId: number) => {
    try {
      const response = await fetchData(currentCompanyId);
      setCustomerId(response.client_secret);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    if (companyId) {
      getCustomerId(companyId);
    }
  }, [companyId]);

  const options: StripeElementsOptions = { clientSecret: customerId, appearance };

  return options?.clientSecret ? (
    <Grid container>
      <Elements stripe={stripePromise} options={options}>
        <CreditCardsManagment />
      </Elements>
    </Grid>
  ) : (
    <LoadingSection />
  );
};

export default Subscription;
