import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import LogoutButton from './LogoutBtn';

const useStyles = makeStyles(theme => ({
  alertStyle: {
    color: theme.palette.error.pink,
    fontSize: '1rem',
    padding: '1rem',
    border: `2px solid ${theme.palette.error.pink}`,
  },
}));

const ContactAdminOrInquireLicensing = () => {
  const classes = useStyles();
  return (
    <>
      <Alert
        icon={<ErrorIcon fontSize="medium" style={{ alignSelf: 'center' }} />}
        severity="error"
        variant="outlined"
        className={classes.alertStyle}>
        You do not have access to any Scalar valuation products. Please contact your account manager at Scalar, or if
        you would like to inquire about licensing, please visit:
        <a href="https://scalar.io/product" target="_blank" rel="noopener noreferrer">
          scalar.io/product
        </a>
      </Alert>
      <LogoutButton />
    </>
  );
};

export default React.memo(ContactAdminOrInquireLicensing);
