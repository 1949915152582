import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, pickBy } from 'lodash';
import { useParams } from 'react-router-dom';
import {
  FUNDS_DEFAULT_CURRENCY,
  FUNDS_TOOLTIP_TEXT_FUND_FUND_OWNERSHIP,
  FUNDS_TOOLTIP_TEXT_FUND_MEASUREMENT_DATE,
} from 'common/constants/funds';
import { useStore } from 'common/store';
import { UseStoreValues } from 'common/types/store';
import { FormDialog } from 'components';
import { FUND_DIALOG_FORM_ADD_FUND, FUND_DIALOG_FORM_DELETE_FUND } from 'pages/Funds/common/constants/fundDialogForm';
import { useFund } from 'pages/Funds/hooks';
import { FundsParams } from 'pages/Funds/types';
import { useGetCompaniesWithOwnershipByFund } from 'services/hooks/funds';
import { useDialogFormsStore } from 'store';
import { getBooleanValue, getStringValue } from 'utillities';
import { FundFormSchema, useFundForm } from './hooks';
import { FundDialogFormProps } from './types';

const FundDialogForm: FC<FundDialogFormProps> = props => {
  const { handleOnClose, open } = props;

  const [allowDeleteFund, setAllowDeleteFund] = useState(false);

  // Dialog Forms Store
  const shouldUpdateFund = useDialogFormsStore(state => state.shouldUpdateFund);

  const [storeValue] = useStore() as unknown as UseStoreValues;
  const { currentMeasurementDateByFund, fundInfo, fundList } = storeValue;

  const { firmSlugParam } = useParams<FundsParams>();

  const { confirmDeleteFund, handleCreateFund, handleUpdateFund } = useFund({ firmSlugParam });

  const { data: companiesWithOwnershipByFund } = useGetCompaniesWithOwnershipByFund({
    fund: fundInfo,
    shouldQueryAutomatically: Boolean(fundInfo?.id),
  });

  const activeFund = useMemo(
    () => ({
      name: getStringValue(fundInfo?.name),
      total_committed_capital: fundInfo?.total_committed_capital ?? '0',
      currency: fundInfo?.currency ?? FUNDS_DEFAULT_CURRENCY,
    }),
    [fundInfo]
  ) as FundFormSchema;

  const { formState, FundForm } = useFundForm({ fund: shouldUpdateFund ? activeFund : undefined });

  const fundIsEmpty = useMemo(() => isEmpty(pickBy(formState.values)), [formState]);

  const handleOnSave = useCallback(
    () => (shouldUpdateFund ? handleUpdateFund(formState.values) : handleCreateFund(formState.values)),
    [formState, handleCreateFund, handleUpdateFund, shouldUpdateFund]
  );

  // Allow Delete the Fund if the Fund hasn't Companies with Ownership and Measurement Dates associated, and is not the only Fund in the Firm
  useEffect(() => {
    if (companiesWithOwnershipByFund?.length === 0 && !isEmpty(fundInfo) && !currentMeasurementDateByFund)
      setAllowDeleteFund(true);
  }, [companiesWithOwnershipByFund, currentMeasurementDateByFund, fundInfo]);

  return (
    <FormDialog
      allowDelete={allowDeleteFund}
      confirmCancel={!fundIsEmpty}
      deleteText={FUND_DIALOG_FORM_DELETE_FUND}
      editMode={shouldUpdateFund}
      isValid={getBooleanValue(formState?.isValid)}
      onClose={handleOnClose}
      onDelete={confirmDeleteFund}
      onSave={handleOnSave}
      open={open}
      title={shouldUpdateFund ? getStringValue(fundInfo?.name) : FUND_DIALOG_FORM_ADD_FUND}
      toolTipText={
        currentMeasurementDateByFund ? FUNDS_TOOLTIP_TEXT_FUND_MEASUREMENT_DATE : FUNDS_TOOLTIP_TEXT_FUND_FUND_OWNERSHIP
      }
      turnOnDeleteFunctionality={fundList && fundList.length > 1 && shouldUpdateFund}>
      {FundForm}
    </FormDialog>
  );
};

export default FundDialogForm;
