import { largeCurrencyFormat, sharesFormat } from 'common/formats/formats';

const colConfig = [
  {
    value: 'Shares',
    alias: 'shares',
    width: '100%',
    format: sharesFormat,
    minValue: 1,
    gridType: 'number',
    className: 'align-left',
  },
  {
    value: 'Strike Price',
    alias: 'price',
    width: '100%',
    format: {
      ...largeCurrencyFormat,
      maximumFractionDigits: 15,
    },
    greaterThan: 0,
    gridType: 'number',
  },
];

export default colConfig;
