import { createStyles, makeStyles } from '@material-ui/styles';
import { UseStylesProps } from './types';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '0.125rem',
      border: (props: UseStylesProps) => `1px solid ${props.borderColor}`,
      backgroundColor: (props: UseStylesProps) => props.backgroundColor,
      width: '100%',
      margin: '0 0.1rem',
    },
    svgIcon: {
      borderRadius: 0,
      color: (props: UseStylesProps) => props.color,
      fontSize: '1rem',
      fontWeight: 700,
      padding: '0 1.2rem',
      width: '100%',

      '& .MuiSvgIcon-root': {
        fill: (props: UseStylesProps) => `${props.color} !important`,
      },
    },
  })
);

export default useStyles;
