import { useCallback, useContext, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { globalAction } from 'common/actions';
import { NO_FINANCIAL_STATEMENT } from 'common/constants/financials';
import { ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY } from 'common/constants/services/companies';
import { useStore } from 'common/store';
import UnsavedChanges from 'context/UnsavedChanges';
import FinancialStatementService from 'services/financialStatement';
import { useResponse } from './useResponse';

export const useCreateFinancialStatement = () => {
  const [, dispatch] = useStore();
  const { setAction } = useContext(UnsavedChanges);

  const { processErrorResponse, successNotification } = useResponse();

  // React Query Client
  const queryClient = useQueryClient();

  const create = useCallback(
    async data => {
      if (data) {
        const service = new FinancialStatementService();
        dispatch(globalAction.showLoadingProgress(true));
        let response = null;
        try {
          response = await service.create(data);
          setAction(false);
          successNotification('The new financial statement was created successfully');
        } catch (error) {
          const defaultErrorMessage = 'An error ocurred while creating the new financial statement';
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'create the new financial statement',
          });
        } finally {
          // Invalidate the Allocation Values query to refetch the data with the new values
          queryClient.invalidateQueries({ queryKey: [ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY] });

          dispatch(globalAction.showLoadingProgress(false));
        }

        return response;
      }

      return null;
    },
    [dispatch, processErrorResponse, queryClient, setAction, successNotification]
  );

  return [create];
};

export const useCreateFinancialStatementVersion = () => {
  const [createdVersion, setVersion] = useState();

  const [, dispatch] = useStore();
  const { setAction } = useContext(UnsavedChanges);

  const { processErrorResponse, successNotification } = useResponse();

  // React Query Client
  const queryClient = useQueryClient();

  const createVersion = useCallback(
    async (mdId, data) => {
      if (data) {
        const service = new FinancialStatementService();
        dispatch(globalAction.showLoadingProgress(true));

        try {
          const newVersion = await service.createVersion(mdId, data);
          setVersion(newVersion);
          setAction(false);
          successNotification('The financial statement version was created successfully');
        } catch (error) {
          const defaultErrorMessage = 'An error ocurred while creating the new financial statement version';
          processErrorResponse({ error, defaultErrorMessage });
        } finally {
          // Invalidate the Allocation Values query to refetch the data with the new values
          queryClient.invalidateQueries({ queryKey: [ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY] });

          dispatch(globalAction.showLoadingProgress(false));
        }
      }
    },
    [dispatch, processErrorResponse, queryClient, setAction, successNotification]
  );

  return [createdVersion, createVersion];
};

export const useUpdateFinancialStatement = () => {
  const [, dispatch] = useStore();
  const { setAction } = useContext(UnsavedChanges);

  const { processErrorResponse, successNotification } = useResponse();

  // React Query Client
  const queryClient = useQueryClient();

  const update = useCallback(
    async (id, data) => {
      if (data) {
        const service = new FinancialStatementService();
        dispatch(globalAction.showLoadingProgress(true));
        let response = null;
        try {
          response = await service.update(id, data);
          setAction(false);
          successNotification('The financial statement was updated successfully');
        } catch (error) {
          const defaultErrorMessage
            = error.response?.body?.detail || 'An error ocurred while updating the new financial statement';
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'update the financial statement',
          });
        } finally {
          // Invalidate the Allocation Values query to refetch the data with the new values
          queryClient.invalidateQueries({ queryKey: [ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY] });
          dispatch(globalAction.showLoadingProgress(false));
        }
        return response;
      }
    },
    [dispatch, processErrorResponse, queryClient, setAction, successNotification]
  );

  return [update];
};

export const useCreateVersion = () => {
  const service = new FinancialStatementService();
  const [, dispatch] = useStore();

  const { setAction } = useContext(UnsavedChanges);
  const { processErrorResponse, successNotification } = useResponse();

  const createVersion = async (id, data) => {
    if (data) {
      dispatch(globalAction.showLoadingProgress(true));
      let response = null;
      try {
        response = await service.createVersion(id, data);
        setAction(false);
        successNotification('The financial statement version was creating successfully');
      } catch (error) {
        const defaultErrorMessage = 'An error ocurred while creating the financial statement version';
        processErrorResponse({
          error,
          defaultErrorMessage,
          action: 'create the financial statement version',
        });
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
      return response;
    }
  };

  return [createVersion];
};

export const useDeleteFinancialStatement = () => {
  const service = new FinancialStatementService();
  const [, dispatch] = useStore();
  const { processErrorResponse } = useResponse();

  const deleteFinancialStatement = async id => {
    if (id) {
      dispatch(globalAction.showLoadingProgress(true));

      try {
        await service.delete(id);
      } catch (error) {
        const defaultErrorMessage = 'An error ocurred while deleting the financial statement';
        processErrorResponse({
          error,
          defaultErrorMessage,
          action: 'delete the financial statement',
        });
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };

  return [deleteFinancialStatement];
};

export const useRetrieveFinancialStatement = () => {
  const [, dispatch] = useStore();

  const { processErrorResponse } = useResponse();

  const retrieve = useCallback(
    async id => {
      const service = new FinancialStatementService();
      if (id) {
        dispatch(globalAction.showLoadingProgress(true));
        let response = null;
        try {
          response = await service.retrieve(id);
        } catch (error) {
          const defaultErrorMessage = 'An error ocurred while retrieving the financial statement';
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'get the financial statement',
          });
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
        return response;
      }
    },
    [dispatch, processErrorResponse]
  );

  return [retrieve];
};

export const useListFinancialStatement = () => {
  const service = new FinancialStatementService();
  const [, dispatch] = useStore();

  const list = async () => {
    dispatch(globalAction.showLoadingProgress(true));
    let response = null;
    try {
      response = await service.list();
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
    return response;
  };
  return [list];
};

export const useListByMeasurementPeriod = () => {
  const [, dispatch] = useStore();

  const listByMeasurementPeriod = useCallback(
    async measurementPeriodId => {
      const service = new FinancialStatementService();
      dispatch(globalAction.showLoadingProgress(true));
      let response = null;
      try {
        response = await service.listByMeasurementPeriod(measurementPeriodId);
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
      return response;
    },
    [dispatch]
  );

  return [listByMeasurementPeriod];
};

export const useGetByMeasurementDateId = () => {
  const service = new FinancialStatementService();
  const [, dispatch] = useStore();

  const getByMeasurementDateId = async measurementPeriodId => {
    dispatch(globalAction.showLoadingProgress(true));
    let response = null;
    try {
      response = await service.getByMeasurementDateId(measurementPeriodId);
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
    return response;
  };

  return [getByMeasurementDateId];
};

export const useFinancialLtmNtm = () => {
  const [, dispatch] = useStore();

  const financialLtmNtm = useCallback(
    async (measurementPeriodId, versionId) => {
      const service = new FinancialStatementService();
      if (measurementPeriodId) {
        dispatch(globalAction.showLoadingProgress(true));
        let response;
        try {
          response = await service.financialLtmNtm(measurementPeriodId, versionId);
        } catch (error) {
          response = null;
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
        return response;
      }
    },
    [dispatch]
  );

  return [financialLtmNtm];
};

export const useFinancialPeriodsFiscalYear = () => {
  const service = useMemo(() => new FinancialStatementService(), []);
  const [, dispatch] = useStore();
  const { enqueueSnackbar } = useSnackbar();

  const getFinancialPeriodsFiscalYear = useCallback(
    async (companyMeasurementDateId, versionId) => {
      if (companyMeasurementDateId) {
        dispatch(globalAction.showLoadingProgress(true));
        let response = null;
        try {
          response = await service.getFinancialPeriodsFiscalYear(companyMeasurementDateId, versionId);
        } catch (error) {
          const defaultWarningMessage = NO_FINANCIAL_STATEMENT;
          enqueueSnackbar(defaultWarningMessage, { variant: 'warning' });
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
        return response;
      }
    },
    [dispatch, service, enqueueSnackbar]
  );

  return [getFinancialPeriodsFiscalYear];
};

export const useGetFinancialStatementBasicDataByMD = () => {
  const [, dispatch] = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const service = useMemo(() => new FinancialStatementService(), []);

  const fetchFinancialsBasicDataList = useCallback(
    async measurementDateId => {
      if (measurementDateId) {
        dispatch(globalAction.showLoadingProgress(true));
        let response = null;
        try {
          response = await service.getFinancialsBasicDataListByMD(measurementDateId);
        } catch (error) {
          const defaultWarningMessage = `${NO_FINANCIAL_STATEMENT} list`;
          enqueueSnackbar(defaultWarningMessage, { variant: 'warning' });
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
        return response;
      }
    },
    [dispatch, service, enqueueSnackbar]
  );

  return [fetchFinancialsBasicDataList];
};
