import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { EDIT, VIEW } from 'common/constants/user';

// A firm item is either a fund or company within that firm
const FirmItem = ({ item, disabled, userPermissions, itemType, shouldCheckAll, setUserPermissionsToSave }) => {
  const getMatchingPermission = useCallback(
    (id, firmItem, permission) =>
      userPermissions?.find(
        perm =>
          perm.feature_object.object_id === id // Fund or company id
          && perm.feature_object.object_type === firmItem // Fund or company
          && perm.access_type === permission // Edit or view
          && !perm.remove_permission // The permission is not set to be removed
      ),
    [userPermissions]
  );

  // Check if the user has a explicit permission (edit or view) for the given fund or company
  const checkPermission = useCallback(
    (id, firmItem, permission) => {
      const matchingPermission = getMatchingPermission(id, firmItem, permission);
      return !!matchingPermission;
    },
    [getMatchingPermission]
  );

  const addPermissionConditionally = useCallback((currentPermissions, permission) => {
    const {
      access_type: accessType,
      remove_permission: removePermission,
      feature_object: { object_id: objectId, object_type: objectType },
    } = permission;
    const existingPermission = currentPermissions?.find(
      perm =>
        perm.feature_object.object_id === objectId
        && perm.feature_object.object_type === objectType
        && perm.access_type === accessType
    );
    if (existingPermission) {
      if (existingPermission.id === 0 && removePermission) {
        return [];
      }
      return [
        {
          ...existingPermission,
          remove_permission: removePermission,
        },
      ];
    }
    return [
      {
        id: 0,
        access_type: accessType,
        feature_object: {
          object_id: objectId,
          object_type: objectType,
        },
        remove_permission: removePermission,
      },
    ];
  }, []);

  // If the user has implicit permissions, there is no need to check for explicit ones
  const hasInitialEditPermission = useMemo(
    () => shouldCheckAll || checkPermission(item.id, itemType, EDIT),
    [shouldCheckAll, checkPermission, item.id, itemType]
  );

  const hasInitialViewPermission = useMemo(
    () => shouldCheckAll || checkPermission(item.id, itemType, VIEW),
    [shouldCheckAll, checkPermission, item.id, itemType]
  );

  const [hasEditPermission, setHasEditPermission] = useState(hasInitialEditPermission);
  const [hasViewPermission, setHasViewPermission] = useState(hasInitialViewPermission);

  useEffect(() => setHasEditPermission(hasInitialEditPermission), [hasInitialEditPermission]);
  useEffect(() => setHasViewPermission(hasInitialViewPermission), [hasInitialViewPermission]);

  const handleChecked = useCallback(
    (checkBoxItemId, accessType, matchingPermission) => {
      // The permission does not exist in the database
      if (!checkPermission(checkBoxItemId, itemType, accessType)) {
        // Add permission
        const newPermission = {
          id: 0,
          access_type: accessType,
          feature_object: {
            object_id: checkBoxItemId,
            object_type: itemType,
          },
          remove_permission: false,
        };
        setUserPermissionsToSave(prevUserPermissions => ({
          ...prevUserPermissions,
          permissions: [
            ...prevUserPermissions.permissions,
            ...addPermissionConditionally(prevUserPermissions.permissions, newPermission),
          ],
        }));
      } else {
        // Delete the remove_permission flag set to true
        setUserPermissionsToSave(prevUserPermissions => ({
          ...prevUserPermissions,
          permissions: [
            ...prevUserPermissions.permissions.filter(perm => perm.id !== matchingPermission.id),
            matchingPermission,
          ],
        }));
      }
    },
    [addPermissionConditionally, checkPermission, itemType, setUserPermissionsToSave]
  );

  const handleUnchecked = useCallback(
    (checkBoxItemId, accessType, matchingPermission) => {
      const permissionToRemove = {
        access_type: accessType,
        feature_object: {
          object_id: checkBoxItemId,
          object_type: itemType,
        },
        remove_permission: true,
      };
      if (matchingPermission) {
        // Remove a permission that exists in the database
        setUserPermissionsToSave(prevUserPermissions => ({
          ...prevUserPermissions,
          permissions: [
            ...prevUserPermissions.permissions.filter(perm => perm.id !== matchingPermission.id),
            ...addPermissionConditionally(prevUserPermissions.permissions, permissionToRemove),
          ],
        }));
      } else {
        // The permission does not exist in the database and is not checked
        setUserPermissionsToSave(prevUserPermissions => ({
          ...prevUserPermissions,
          permissions: [
            ...prevUserPermissions.permissions.filter(
              perm =>
                perm.feature_object.object_id !== checkBoxItemId
                || perm.feature_object.object_type !== itemType
                || perm.access_type !== accessType
            ),
            ...addPermissionConditionally(prevUserPermissions.permissions, permissionToRemove),
          ],
        }));
      }
    },
    [addPermissionConditionally, itemType, setUserPermissionsToSave]
  );

  const handleCheckboxChange = (event, checkBoxItem, accessType) => {
    const matchingPermission = getMatchingPermission(checkBoxItem.id, itemType, accessType);
    const { checked } = event.target;
    if (accessType === EDIT) {
      setHasEditPermission(checked);
      setHasViewPermission(checked);
    } else {
      // Clicking on the view checkbox does nothing if the user has edit permission
      if (hasEditPermission) return;
      setHasViewPermission(checked);
    }

    if (checked) {
      handleChecked(checkBoxItem.id, accessType, matchingPermission);
    } else {
      handleUnchecked(checkBoxItem.id, accessType, matchingPermission);
    }
  };

  return (
    <Grid item sm={12}>
      <Grid container spacing={1} style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Grid item sm={8} style={{ fontSize: '1rem' }}>
          <Typography variant="inherit" style={{ marginLeft: '0.875rem' }}>
            {item.name}
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <Checkbox
            onChange={event => handleCheckboxChange(event, item, EDIT)}
            disabled={disabled}
            checked={hasEditPermission}
          />
        </Grid>
        <Grid item sm={2}>
          <Checkbox
            onChange={event => handleCheckboxChange(event, item, VIEW)}
            disabled={disabled}
            // Edit permission implies view permission
            checked={hasEditPermission || hasViewPermission}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

FirmItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  userPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      access_type: PropTypes.string,
      id: PropTypes.number,
      object_id: PropTypes.number,
      feature: PropTypes.string,
      object_type: PropTypes.string,
      remove_permission: PropTypes.bool,
    })
  ),
  itemType: PropTypes.string,
  shouldCheckAll: PropTypes.bool,
  setUserPermissionsToSave: PropTypes.func,
};

export default FirmItem;
