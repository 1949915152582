/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ChildCompanyQuestion } from './ChildCompanyQuestion';

export type CompanyQuestionModelWithListSerializerNumberQuestionType = {
  readonly id?: number;
  company?: number;
  children_questions?: Array<ChildCompanyQuestion>;
  question_type: CompanyQuestionModelWithListSerializerNumberQuestionType.question_type;
  options?: Array<number>;
  new_options?: Array<string>;
  name: string;
  is_deleted?: boolean | null;
  readonly deleted_at?: string | null;
  description?: string | null;
  parent_question?: number | null;
  valuation_type?: number | null;
  base_question?: number | null;
};

export namespace CompanyQuestionModelWithListSerializerNumberQuestionType {
  export enum question_type {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }
}
