/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import {
  ADD_NEW_DOCUMENT,
  ADD_NEW_FOLDER,
  ADD_SUBFOLDER_LABEL,
  DOCUMENTS_TITLE,
  NO_DOCUMENTS_FOR_THIS_COMPANY,
  REQUEST_NEW_DOCUMENTS,
} from 'common/constants/documents';
import { AUDITOR_VALUE, EXPORT_VIEWER_VALUE, LIMITED_VIEWER_VALUE } from 'common/constants/user';
import { useStore } from 'common/store';
import { MessageBox } from 'components';
import { ExtendedFabButton } from 'components/Buttons';
import DocumentDialog from 'components/Documents/DocumentDialog';
import RequestDocumentDialog from 'components/Documents/RequestDocumentDialog';
import { GridSkeleton } from 'components/Grid';
import { LayoutContext } from 'context';
import { DocumentsContext } from 'context/DocumentsContext';
import { useCompanyMeasurementDateFilter, useDocuments } from 'services/hooks';
import { useGetAllocationStatusBreadcrumb } from 'services/hooks/allocation';
import getBreadcrumbs from 'utillities/getBreadcrumbs';
import AddFolderDialog from './components/AddFolderDialog/AddFolderDialog';
import Documents from './Documents';
import { processDocumentsInformation } from './utilities/processDocuments';

const DocumentsView = () => {
  const [openRequestNewDocument, setOpenRequestNewDocument] = useState(false);
  const [openFolderDialog, setOpenFolderDialog] = useState(false);
  const [openNewDocumentDialog, setOpenNewDocumentDialog] = useState(false);
  const [{ firmInfo }] = useStore();
  const { selectedMeasurementDate, MDDialogue, measurementDates } = useCompanyMeasurementDateFilter();
  const {
    currentDocuments,
    setCurrentDocuments,
    companyInfo,
    company_id,
    getDocuments,
    companyDocuments,
    setCompanyDocuments,
    setIsLoading,
    isLoading,
    getFeaturesObjects,
    featuresObjects,
  } = useDocuments(selectedMeasurementDate);
  const { setPageBreadcrumbs, viewOnlyUser, currentFirmRole } = useContext(LayoutContext);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [allocationStatusBreadcrumb] = useGetAllocationStatusBreadcrumb();

  const secondaryActions = [
    {
      label: REQUEST_NEW_DOCUMENTS,
      callback: () => setOpenRequestNewDocument(true),
    },
    {
      label: ADD_NEW_FOLDER,
      callback: () => setOpenFolderDialog(true),
    },
  ];

  const currentDocumentsProcessed = useMemo(
    () => (currentDocuments ? processDocumentsInformation(currentDocuments) : {}),
    [currentDocuments]
  );

  useEffect(() => {
    if (!isEmpty(companyInfo)) {
      setPageBreadcrumbs(getBreadcrumbs(firmInfo, companyInfo, DOCUMENTS_TITLE, [allocationStatusBreadcrumb]));
    }
    return () => setPageBreadcrumbs(null);
  }, [companyInfo, allocationStatusBreadcrumb]);

  useEffect(() => {
    if (selectedMeasurementDate) {
      getFeaturesObjects();
      getDocuments();
    }
  }, [selectedMeasurementDate]);

  const handleOpen = () => {
    setOpenNewDocumentDialog(true);
  };

  const handleClose = () => {
    setDroppedFiles([]);
    setOpenNewDocumentDialog(false);
  };

  const isUserAllowedToDownload = useMemo(
    () => ![LIMITED_VIEWER_VALUE, EXPORT_VIEWER_VALUE, AUDITOR_VALUE].includes(currentFirmRole),
    [currentFirmRole]
  );

  const documentContextValue = useMemo(
    () => ({
      setCompanyDocuments,
      company_id,
      getDocuments,
      featuresObjects,
      setIsLoading,
      isLoading,
      setCurrentDocuments,
      currentDocuments,
      selectedMeasurementDate,
      viewOnly: viewOnlyUser,
      allowDownload: isUserAllowedToDownload,
    }),
    [
      setCompanyDocuments,
      company_id,
      getDocuments,
      featuresObjects,
      setIsLoading,
      isLoading,
      setCurrentDocuments,
      currentDocuments,
      selectedMeasurementDate,
      isUserAllowedToDownload,
    ]
  );

  const AccordionSkeleton = () => <Skeleton variant="rect" width="100%" height="3.188rem" />;

  const DocumentsSkeleton = () => (
    <div>
      <Skeleton variant="rect" style={{ margin: '1rem 0' }} width="24.438rem" height="2.375rem" />
      <div style={{ margin: '1rem 0' }}>
        <AccordionSkeleton />
        <AccordionSkeleton />
        <AccordionSkeleton />
      </div>
    </div>
  );

  if (!selectedMeasurementDate) {
    return <GridSkeleton />;
  }

  if (!companyDocuments) {
    return <DocumentsSkeleton />;
  }

  return (
    <DocumentsContext.Provider value={documentContextValue}>
      {isEmpty(companyDocuments) ? (
        <MessageBox title={NO_DOCUMENTS_FOR_THIS_COMPANY} fullWidth={false} hasFrame />
      ) : (
        <Documents
          selectedMeasurementDate={selectedMeasurementDate.id}
          measurementDates={measurementDates}
          companyDocuments={companyDocuments}
          currentDocumentsProcessed={currentDocumentsProcessed}
        />
      )}
      <MDDialogue />
      {selectedMeasurementDate && (
        <ExtendedFabButton
          label={ADD_NEW_DOCUMENT}
          onClick={handleOpen}
          secondaryActions={secondaryActions}
          disabled={viewOnlyUser}
        />
      )}
      <DocumentDialog
        open={openNewDocumentDialog}
        onClose={handleClose}
        title={ADD_NEW_DOCUMENT}
        isValid
        confirmCancel
        addSelectComponent={false}
        droppedFiles={droppedFiles}
        setDroppedFiles={setDroppedFiles}
        selectedMeasurementDateId={selectedMeasurementDate.id}
        customButtonLabel="Done"
        fromReferenceWidget={false}
      />
      {openFolderDialog && (
        <AddFolderDialog
          open={openFolderDialog}
          onClose={() => setOpenFolderDialog(false)}
          title={ADD_SUBFOLDER_LABEL}
          currentFolders={currentDocumentsProcessed.folders}
        />
      )}
      {openRequestNewDocument && (
        <RequestDocumentDialog
          open={openRequestNewDocument}
          onClose={() => setOpenRequestNewDocument(false)}
          currentDocuments={currentDocuments}
          companyMeasurementDateId={selectedMeasurementDate?.cmd_id}
        />
      )}
    </DocumentsContext.Provider>
  );
};

export default DocumentsView;
