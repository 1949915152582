import React, { FC } from 'react';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { dashboardCompanyOverviewUrl } from 'dashboard409a/common/config/urls';
import { COMPANY_DETAILS_SLUGS } from 'dashboard409a/common/constants';
import { RootStateType } from 'dashboard409a/states/store';
import useStyles from './styles';
import { IBreadcrumb } from './types';

const Breadcrumb: FC<IBreadcrumb> = props => {
  const { navigationItems, currentSlug } = props;

  const classes = useStyles();

  const { currentCompany } = useSelector((state: RootStateType) => state.global);

  if (!currentCompany)
    return <Skeleton className={classes.breadcrumbsContainer} variant="text" width={200} height={22} />;

  return (
    <Breadcrumbs className={classes.breadcrumbsContainer}>
      <Link to={dashboardCompanyOverviewUrl(currentCompany.slug)}>
        <Typography className={classes.breadcrumbLink}>409A Dashboard</Typography>
      </Link>

      {COMPANY_DETAILS_SLUGS.includes(currentSlug) ? (
        <Typography className={classes.breadcrumbText}>Company Settings</Typography>
      ) : (
        <Typography className={classes.breadcrumbText}>Reports</Typography>
      )}

      {navigationItems.length > 0 && (
        <Typography className={classes.breadcrumbText}>
          {navigationItems.find(item => item.id === currentSlug)?.title}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
