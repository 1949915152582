import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const wrapText: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    // Items
    item: {
      color: theme.palette.black,
      cursor: 'pointer',
      padding: `${theme.spacing(2)}px 0`,
      borderBottom: `2px solid ${theme.palette.black}`,
      marginBottom: theme.spacing(1),

      // '&.Mui-selected': {
      //   backgroundColor: theme.palette.primary['100'],
      // },
      // '&:hover': {
      //   backgroundColor: theme.palette.primary['500'],
      // },
    },
    itemText: {
      ...wrapText,
      textTransform: 'capitalize',

      '& .MuiTypography-body1': {
        fontSize: '18px',
        fontWeight: 700,
        color: theme.palette.black,
      },
    },

    itemIcon: {
      // display: 'inline-block',
      color: theme.palette.white,
      marginLeft: theme.spacing(2),
    },
  })
);

export default useStyles;
