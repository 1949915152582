import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { DeleteOutline as DeleteIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

const DeleteButton = ({ title, handleClick, isDisabled }) => (
  <Tooltip title={title} placement="top" arrow>
    <IconButton disabled={isDisabled} onClick={handleClick}>
      <DeleteIcon fontSize="small" />
    </IconButton>
  </Tooltip>
);

DeleteButton.propTypes = {
  title: PropTypes.string,
  handleClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default DeleteButton;
