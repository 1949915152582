import React from 'react';
import PropTypes from 'prop-types';
import KeyboardShortcutItem from './KeyboardShortcutItem';

const VerticalShortcuts = ({ browserOS, shortcuts }) =>
  shortcuts.map(({ action, combination }) => (
    <KeyboardShortcutItem action={action} combination={combination[browserOS]} key={action} />
  ));

VerticalShortcuts.propTypes = {
  browserOS: PropTypes.string,
  shortcuts: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string.isRequired,
      combination: PropTypes.shape({
        windows: PropTypes.string.isRequired,
        macOS: PropTypes.string.isRequired,
      }),
    })
  ),
};

export default VerticalShortcuts;
