/* eslint-disable import/no-unresolved */
import React, { useContext, useState } from 'react';
import { Checkbox, FormControl, ListItemText, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import SpreadsheetContext from 'components/Spreadsheet/context/SpreadsheetContext';
import withTooltip from 'components/WithTooltip';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  select: {
    color: `${theme.palette.primary.dark} !important`,
  },
  bold: {
    fontWeight: 'bold',
  },
  icon: {
    padding: 0,
    paddingRight: theme.spacing(2),
  },
}));

const SelectMultiple = ({ cell, options, optionsMask, disabled, defaultOption }) => {
  const classes = useStyles();
  const { onCellsChanged } = useContext(SpreadsheetContext);
  const [value, setValue] = useState(cell.value || []);

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    if (cell.value && cell.value.toString() !== value.toString()) {
      onCellsChanged([{ cell, value }]);
    }
  };

  const renderValue = selected => {
    if (selected.length === 0) return defaultOption.toUpperCase();
    const optionList = optionsMask || options;
    return selected.map(value => optionList[value]).join(', ');
  };

  return (
    <FormControl className={classes.root}>
      <Select
        multiple
        displayEmpty
        disableUnderline
        value={value}
        onChange={handleChange}
        onClose={handleClose}
        renderValue={renderValue}
        className={classes.select}
        id={`grid-select-${cell.key}`}
        disabled={disabled || cell.readOnly || isEmpty(options)}>
        <MenuItem disabled value="">
          {defaultOption.toUpperCase()}
        </MenuItem>
        {options.map((option, optionIndex) => (
          <MenuItem value={optionIndex} key={option} dense id={`grid-select-${cell.key}-opt-${optionIndex}`}>
            <Checkbox checked={value.includes(optionIndex)} size="small" className={classes.icon} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectMultiple.defaultProps = {
  disabled: false,
  defaultOption: 'Select an Option',
  cell: {
    value: null,
    key: null,
  },
};

SelectMultiple.propTypes = {
  cell: PropTypes.object,
  options: PropTypes.array.isRequired,
  optionsMask: PropTypes.array,
  disabled: PropTypes.bool,
  defaultOption: PropTypes.string,
};

export default withTooltip(SelectMultiple);
