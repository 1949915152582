import React, { useCallback, useContext, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEmpty, isNil } from 'lodash';
import CompGroupDialogContext from 'pages/Valuations/components/CompGroups/components/common/CompGroupDialogContext';
import FormLabelCompGroup from 'pages/Valuations/components/CompGroups/components/common/FormLabelCompGroup';

const AutomaticUpdates = () => {
  const options = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  const {
    currentCompGroups,
    compGroupSelected,
    setCompGroupSelected,
    setCompaniesToDelete,
    setCompGroupToDelete,
    setValid,
    isGPC,
  } = useContext(CompGroupDialogContext);

  const resetCompGroupInfo = useCallback(
    (useLatestVersion, newCompanies) => {
      setCompGroupSelected(currentState => ({
        ...currentState,
        use_latest_comp_group_version: useLatestVersion,
        companies: newCompanies || currentState.companies,
      }));
    },
    [setCompGroupSelected]
  );

  const getMatchedCompGroup = (data, optionId) => data.find(item => item.comp_group === optionId);

  const handleChange = (__event, selectedOption) => {
    const useLatestVersion = selectedOption.value;
    const { originalData } = compGroupSelected;
    let newCompaniesData = null;
    if (useLatestVersion) {
      newCompaniesData = isGPC ? originalData?.gpc_comps : originalData?.pt_comps;
      setValid(true);
      setCompaniesToDelete([]);
      setCompGroupToDelete(null);
    } else {
      const matchedCompGroup = getMatchedCompGroup(currentCompGroups, compGroupSelected.id);
      if (matchedCompGroup) {
        setCompGroupToDelete(matchedCompGroup.comp_group);
      }
    }
    resetCompGroupInfo(useLatestVersion, newCompaniesData);
  };

  useEffect(() => {
    resetCompGroupInfo(null);
    if (compGroupSelected?.id) {
      if (!isEmpty(currentCompGroups)) {
        const matchedCompGroup = getMatchedCompGroup(currentCompGroups, compGroupSelected.id);
        if (matchedCompGroup) {
          const useLatestVersion = matchedCompGroup.use_latest_comp_group_version;
          resetCompGroupInfo(useLatestVersion);
        } else {
          resetCompGroupInfo(false);
        }
      } else {
        resetCompGroupInfo(false);
      }
    } else {
      setValid(false);
    }
  }, [compGroupSelected.id, currentCompGroups, setValid, resetCompGroupInfo]);

  const getAutomaticUpdatesValue = () => {
    const useLatestVersion = compGroupSelected?.use_latest_comp_group_version;
    if (!isNil(useLatestVersion)) {
      return options.find(item => item.value === useLatestVersion);
    }
    return useLatestVersion;
  };

  return (
    <>
      <FormLabelCompGroup title="Automatic Updates:" />
      <Autocomplete
        id="comp-groups-autocomplete"
        disabled={isNil(compGroupSelected?.id)}
        options={options}
        getOptionLabel={option => option.label || ''}
        getOptionSelected={(option, current) => option.value === current.value}
        renderInput={params => <TextField {...params} placeholder="Select" variant="outlined" />}
        onChange={handleChange}
        value={getAutomaticUpdatesValue() || null}
        disableClearable
      />
    </>
  );
};

export default AutomaticUpdates;
