import React from 'react';
import * as rows from 'common/constants/financials';
import { largeCurrencyFormat } from 'common/formats/formats';
import { baseRowConfig } from 'pages/Financials/data';
import AdjustEBITDAChip from 'pages/Financials/income-statement/components/AdjustEBITDAChip';

const rowConfig = isDisabled => [
  ...baseRowConfig,
  {
    value: rows.TOTAL_REVENUE_TITLE,
    alias: rows.TOTAL_REVENUE_ALIAS,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: rows.TOTAL_SALES_COST_TITLE,
    alias: rows.TOTAL_SALES_COST_ALIAS,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: rows.GROSS_PROFIT_TITLE,
    alias: rows.GROSS_PROFIT_ALIAS,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-indented row-label-bold',
    validExpr: rows.GROSS_PROFIT_EXPR,
    allowEmptyValue: true,
    skipIfNullProviders: true,
  },
  {
    value: rows.OPERATING_EXPENSES_TITLE,
    alias: rows.OPERATING_EXPENSES_ALIAS,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: rows.EBITDA_TITLE,
    alias: rows.EBITDA_ALIAS,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-indented row-label-bold',
    hasSubRows: true,
    showSubRows: true,
    sideComponent: <AdjustEBITDAChip />,
    validExpr: rows.EBITDA_EXPR,
    allowEmptyValue: true,
    skipIfNullProviders: true,
  },
  {
    value: rows.ADJUSTED_EBITDA_TITLE,
    alias: rows.ADJUSTED_EBITDA_ALIAS,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    className: 'row-label-indented row-label-bold',
    parent: rows.EBITDA_ALIAS,
  },
  {
    value: rows.DEPRECIATION_EXPENSE_TITLE,
    alias: rows.DEPRECIATION_EXPENSE_ALIAS,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: rows.AMORTIZATION_EXPENSE_TITLE,
    alias: rows.AMORTIZATION_EXPENSE_ALIAS,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    dbDecimalPlaces: 15,
  },
  {
    value: rows.EBIT_TITLE,
    alias: rows.EBIT_ALIAS,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-indented row-label-bold',
    validExpr: rows.EBIT_EXPR,
    allowEmptyValue: true,
    skipIfNullProviders: true,
  },
  {
    value: rows.INTEREST_EXPENSE_TITLE,
    alias: rows.INTEREST_EXPENSE_ALIAS,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: rows.OTHER_EXPENSE_TITLE,
    alias: rows.OTHER_EXPENSE_ALIAS,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: rows.PRETAX_INCOME_TITLE,
    alias: rows.PRETAX_INCOME_ALIAS,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-indented row-label-bold',
    validExpr: rows.PRETAX_INCOME_EXPR,
    allowEmptyValue: true,
    skipIfNullProviders: true,
  },
  {
    value: rows.INCOME_TAXES_TITLE,
    alias: rows.INCOME_TAXES_ALIAS,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: rows.NET_INCOME_TITLE,
    alias: rows.NET_INCOME_ALIAS,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-indented row-label-bold',
    validExpr: rows.NET_INCOME_EXPR,
    allowEmptyValue: true,
    skipIfNullProviders: true,
  },
];

export default rowConfig;
