import {
  ACQUIRER_NAME,
  CAP_IQ_ID,
  COMPANY_NAME_GPC,
  ENTERPRISE_VALUE,
  LTM_EBITDA,
  LTM_EBITDA_MARGIN,
  LTM_GROSS_MARGIN,
  LTM_REVENUE,
  LTM_REVENUE_GROWTH,
  SYMBOL_GPC,
  TARGET_NAME,
  TRANSACTION_DATE,
} from 'common/constants/comp-groups/compGroups';

export const GPC_HEADER_ROW = [
  {
    value: COMPANY_NAME_GPC,
    className: 'table-header-comp-groups first-row align-left',
    readOnly: true,
  },
  {
    value: SYMBOL_GPC,
    className: 'table-header-comp-groups-resize-column first-row',
    readOnly: true,
  },
  {
    value: CAP_IQ_ID,
    className: 'table-header-comp-groups-resize-column first-row',
    readOnly: true,
  },
];

export const TRANSACTION_HEADER_ROW = [
  {
    value: TARGET_NAME,
    className: 'table-header-comp-groups first-row align-left',
    readOnly: true,
  },
  {
    value: ACQUIRER_NAME,
    className: 'table-header-comp-groups-resize-column first-row',
    readOnly: true,
  },
  {
    value: TRANSACTION_DATE,
    className: 'table-header-comp-groups-resize-column first-row',
    readOnly: true,
  },
  {
    value: ENTERPRISE_VALUE,
    className: 'table-header-comp-groups-resize-column first-row',
    readOnly: true,
  },
  {
    value: LTM_REVENUE,
    className: 'table-header-comp-groups-resize-column first-row',
    readOnly: true,
  },
  {
    value: LTM_REVENUE_GROWTH,
    className: 'table-header-comp-groups-resize-column first-row',
    readOnly: true,
  },
  {
    value: LTM_EBITDA,
    className: 'table-header-comp-groups-resize-column first-row',
    readOnly: true,
  },
  {
    value: LTM_EBITDA_MARGIN,
    className: 'table-header-comp-groups-resize-column first-row',
    readOnly: true,
  },
  {
    value: LTM_GROSS_MARGIN,
    className: 'table-header-comp-groups-resize-column first-row',
    readOnly: true,
  },
  // Commented this column because it's not used in the table for now. Probably we will need.
  // {
  //   value: CAP_IQ_ID,
  //   className: 'table-header-comp-groups-resize-column first-row',
  //   readOnly: true,
  // },
];
