import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomPrimaryButton = ({ children, onClick, variant, disabled = false, tooltip = '', className }) => {
  const button = (
    <Button
      onClick={onClick}
      variant={variant || 'contained'}
      color="primary"
      disabled={disabled}
      style={{
        fontWeight: 600,
        fontSize: '1rem',
        padding: '1rem 2rem',
      }}
      className={className}>
      {children}
    </Button>
  );

  return tooltip !== '' ? (
    <Tooltip title={tooltip} placement="top">
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
};

CustomPrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  className: PropTypes.string,
};

export default CustomPrimaryButton;
