/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { COUNTDOWN_INTERVAL, INITIAL_COUNTDOWN } from 'common/constants/login';
import { ACCOUNT_ACTIVATED, ERROR_OCCURRED, REDIRECT_TO } from 'common/constants/messages/validations';
import { MessageBox } from 'components';
import { useActivateUser } from 'services/hooks/auth';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 500,
    margin: 'auto',
  },
  actions: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

const ActivateAccount = ({ history }) => {
  const classes = useStyles();
  const { uid, token } = useParams();
  const activateUser = useActivateUser();

  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  const [countDown, setCountDown] = useState(INITIAL_COUNTDOWN);

  useEffect(() => {
    if (firstLoad) {
      const activate = async () => {
        const [, error] = await activateUser(uid, token);

        if (error) {
          setSeverity('error');
          setMessage(ERROR_OCCURRED);
        } else {
          setSeverity('success');
          setMessage(ACCOUNT_ACTIVATED);
        }
        setIsLoading(false);
      };
      activate();
      setFirstLoad(false);
    }
  }, [activateUser, uid, token, firstLoad]);

  const goToSignIn = () => history.push('/');

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(prevCountDown => prevCountDown - 1);
      } else {
        clearInterval(interval);
        goToSignIn();
      }
    }, COUNTDOWN_INTERVAL);
    return () => clearInterval(interval);
  }, [countDown]);

  if (isLoading) {
    // Return a loading message as JSX element
    return <div>Loading...</div>;
  }

  return (
    <div className={classes.root}>
      <MessageBox title={message} tagline={REDIRECT_TO('Sign In page', countDown)} type={severity} />
      <div className={classes.actions}>
        <Button variant="text" color="primary" onClick={goToSignIn} endIcon={<ArrowForwardIcon />}>
          Sign In
        </Button>
      </div>
    </div>
  );
};

ActivateAccount.propTypes = {
  history: PropTypes.func,
};

export default ActivateAccount;
