import React from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { colors } from '../../ScalarSpreadsheet/constants';

const ColoredSymbol = ({ symbol, colorIndex, value }) => (
  <Tooltip placement="top" title={value ?? ''}>
    <span style={{ color: colors[colorIndex] }}>{symbol}</span>
  </Tooltip>
);

ColoredSymbol.propTypes = {
  symbol: PropTypes.string.isRequired,
  colorIndex: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};
export default ColoredSymbol;
