/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { isEmpty, isEqual, sortBy, times, toLower } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useMDContext from 'context/MDContext';
import { objToArray, toString } from 'utillities';
import useStyles from './List.styles';

const FundList = () => {
  const classes = useStyles();

  const {
    items,
    fundList,
    isLoading,
    selectedFunds,
    selectedCompanies,
    setSelectedCompanies,
    setSelectedFunds,
    isDeletingMD,
  } = useMDContext();

  const [filteredItems, setFilteredItems] = useState();

  const fundItems = useMemo(() => {
    if (items) {
      return items;
    }
    if (fundList) {
      return fundList.map(({ id, fund }) => ({ id, name: fund.name, subItems: [] }));
    }
    return [];
  }, [items, fundList]);

  useEffect(() => {
    if (!isEmpty(selectedFunds) && !isDeletingMD) {
      let affectedCompanies = {};

      fundItems.forEach(item => {
        if (selectedFunds[item.id] && item.subItems) {
          item.subItems.forEach(company => {
            affectedCompanies = {
              ...affectedCompanies,
              [company.id]: selectedCompanies[company.id] || true,
            };
          });
        }
      });

      setSelectedCompanies({
        ...selectedCompanies,
        ...affectedCompanies,
      });
    }
  }, [selectedFunds, fundItems]);

  const handleFilterChange = e => {
    const filter = e.target.value;
    const tmpFilteredItems = fundItems.filter(item => item.id && toLower(item.name).includes(toLower(filter)));

    setFilteredItems(tmpFilteredItems);
  };

  const handleChange = (e, option) => {
    const { checked } = e.target;

    let tmpSelectedFunds = {
      ...selectedFunds,
    };

    if (checked) {
      tmpSelectedFunds = {
        ...tmpSelectedFunds,
        [option.id]: selectedFunds[option.id] || checked,
      };
    } else {
      delete tmpSelectedFunds[option.id];
    }

    setSelectedFunds(tmpSelectedFunds);

    if (!isDeletingMD) {
      setSelectedFunds(tmpSelectedFunds);
      const affectedCompanies = fundItems.reduce(
        (filtered, item) => {
          if (toString(option.id) === toString(item.id) && item.subItems) {
            item.subItems.forEach(company => {
              if (checked) {
                filtered = {
                  ...filtered,
                  [company.id]: checked,
                };
              } else {
                delete filtered[company.id];
              }
            });
          }

          return filtered;
        },
        { ...selectedCompanies }
      );
      setSelectedCompanies(affectedCompanies);
    }
  };

  const isChecked = useCallback(id => {
    if (selectedFunds?.[id]) {
      return true;
    }

    const tmpSelectedItems = { ...selectedCompanies };
    const { subItems: companies } = fundItems.find(item => toString(item.id) === toString(id)) || {};
    const availableCompanyIds = companies ? companies.map(({ id }) => toString(id)) : [];

    const selectedCompanyIds = objToArray(tmpSelectedItems).reduce((filtered, [id, selected]) => {
      if (selected && availableCompanyIds.includes(id)) {
        filtered.push(toString(id));
      }
      return filtered;
    }, []);

    const allSelected = availableCompanyIds.length === selectedCompanyIds.length;
    return availableCompanyIds.length > 0 && allSelected;
  });

  const isIndeterminate = useCallback(
    id => {
      if (Array.isArray(fundItems)) {
        const fundItem = fundItems?.find(fund => toString(fund.id) === toString(id)) ?? {};

        if (fundItem) {
          const { subItems: companies } = fundItem;
          const availabeCompanyIds = companies?.map(({ id: companyId }) => toString(companyId)) ?? [];

          const selectedCompanyIds = objToArray(selectedCompanies).reduce((filtered, [id, selected]) => {
            if (selected && availabeCompanyIds.includes(id)) {
              filtered.push(toString(id));
            }
            return filtered;
          }, []);

          if (selectedCompanyIds.length) {
            return !isEqual(sortBy(selectedCompanyIds), sortBy(availabeCompanyIds));
          }
        }
        return false;
      }
      return false;
    },
    [fundItems, selectedCompanies]
  );

  const renderItems = () =>
    (filteredItems || []).map(item => (
      <FormControl key={`checkbox-${item.id}`} component="fieldset">
        <FormGroup>
          <FormControlLabel
            key={item.id}
            control={
              <Checkbox
                id={`checkbox-${item.id}`}
                size="small"
                name={item.name}
                checked={isChecked(item.id)}
                indeterminate={isIndeterminate(item.id)}
                onChange={e => handleChange(e, item)}
              />
            }
            label={item.name}
          />
        </FormGroup>
      </FormControl>
    ));

  const renderSkeleton = () => {
    const rows = 4;
    return times(rows, key => <Skeleton key={key} variant="text" height={40} />);
  };

  useEffect(() => {
    if (!isEqual(fundItems, filteredItems)) {
      const tmpItems = fundItems.filter(fund => fund.id) || [];
      setFilteredItems(tmpItems);
    }
  }, [fundItems]);

  return (
    <Grid classes={{ root: classes.root }}>
      <Typography variant="subtitle2" gutterBottom classes={{ root: classes.listTitle }}>
        Select one or more Funds:
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Find a Fund"
        onChange={handleFilterChange}
        InputProps={{
          classes: {
            root: classes.input,
            notchedOutline: classes.notchedOutline,
            adornedEnd: classes.inputAdornEnd,
          },
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon className={classes.icon} />
            </InputAdornment>
          ),
        }}
      />
      <Grid classes={{ root: classes.listContainer }}>
        <PerfectScrollbar options={{ minScrollbarLength: 50 }}>
          <Grid container direction="column" wrap="nowrap">
            {Array.isArray(filteredItems) && !isLoading ? renderItems() : renderSkeleton()}
          </Grid>
        </PerfectScrollbar>
      </Grid>
    </Grid>
  );
};

export default FundList;
