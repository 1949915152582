import React from 'react';
import { Button, Grid, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

const GridButtonWithTooltip = ({ buttonText, tooltipText, onClick, disabled, className }) => (
  <Grid item>
    <div>
      <Tooltip arrow title={tooltipText}>
        <span>
          <Button disabled={disabled} onClick={onClick} className={className}>
            {buttonText}
          </Button>
        </span>
      </Tooltip>
    </div>
  </Grid>
);

GridButtonWithTooltip.propTypes = {
  buttonText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default GridButtonWithTooltip;
