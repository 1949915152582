import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import UploadedFileAlert from './UploadedFileAlert';

const FileAlerts = ({
  droppedFiles,
  selectedMeasurementDateId,
  validFileTypes,
  fromReferenceWidget,
  setIsFileUploaded,
  setFilesToSave,
}) => {
  const uploadedFileAlertProps = {
    droppedFiles,
    selectedMeasurementDateId,
    validFileTypes,
    fromReferenceWidget,
    setIsFileUploaded,
    setFilesToSave,
  };

  return (
    <div>
      {droppedFiles?.map(file => (
        <div key={file.lastModified}>
          <Box style={{ marginBottom: '1rem' }}>
            <UploadedFileAlert {...uploadedFileAlertProps} file={file} />
          </Box>
        </div>
      ))}
    </div>
  );
};

FileAlerts.propTypes = {
  droppedFiles: PropTypes.array,
  selectedMeasurementDateId: PropTypes.number,
  validFileTypes: PropTypes.array,
  fromReferenceWidget: PropTypes.bool,
  setIsFileUploaded: PropTypes.func,
  setFilesToSave: PropTypes.func,
};

export default FileAlerts;
