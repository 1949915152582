import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import SubTitleText from './SubTitleText';
import TitleText from './TitleText';

const useStyles = makeStyles(theme => ({
  imageContainerCircle: {
    width: '5.375rem',
    height: '5.375rem',
    borderRadius: '100%',
    backgroundColor: theme.palette.primary.main,
    margin: '0 auto',
    marginTop: 15,
  },
  alternativeImageContainerCircle: {
    width: '5.375rem',
    height: '5.375rem',
    borderRadius: '100%',
    backgroundColor: 'rgb(230,242,253)',
    margin: '0 auto',
    marginTop: '1rem',
  },
  icon: {
    color: theme.palette.white,
    paddingTop: '1.7rem',
  },
  content: {
    marginTop: '0.8em',
    marginBottom: '1em',
  },
  title: {
    fontSize: '1rem',
    color: theme.palette.primary.main,
    fontWeight: 'bolder',
    paddingBottom: '0.5rem',
  },
}));

const CircleIconWithText = ({ children, title, subTitle, alternative, titleArray }) => {
  const classes = useStyles();

  return (
    <>
      <div className={alternative ? classes.alternativeImageContainerCircle : classes.imageContainerCircle}>
        <div className={classes.icon}>{children}</div>
      </div>
      <div className={classes.content}>
        <h1 className={classes.title}>
          <TitleText title={title} titleArray={titleArray} />
        </h1>
        {!isUndefined(subTitle) && <SubTitleText subTitle={subTitle} />}
      </div>
    </>
  );
};

CircleIconWithText.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  alternative: PropTypes.bool,
  titleArray: PropTypes.array,
};

export default CircleIconWithText;
