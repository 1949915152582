import { getBooleanValue } from 'utillities';
import { getStringValue } from 'utillities/getStringValue';
import { HandleDisabledCellsParams } from './types';

const handleDisabledCells = (params: HandleDisabledCellsParams) => {
  const {
    cellCustomClasses,
    column,
    customValue = '',
    disabledTooltipMessage = [],
    gridType,
    row,
    shouldCheckDisabledCell,
    tooltipMessages,
    value,
  } = params;

  let cellClassName = getStringValue(row?.className);
  let cellGridType = gridType;
  let cellTooltipMessages = tooltipMessages;
  let cellValue = value;

  if (shouldCheckDisabledCell) {
    const disabled = getBooleanValue(column[row.alias]?.disabled);

    if (disabled) {
      cellClassName = getStringValue(cellCustomClasses?.disabledCell);
      cellGridType = 'string';
      cellTooltipMessages = disabledTooltipMessage;
      cellValue = customValue;
    }
  }

  return {
    cellClassName,
    cellGridType,
    cellTooltipMessages,
    cellValue,
  };
};

export default handleDisabledCells;
