import { VOLATILITY_SOURCE_ALIAS } from './constants';

const conditions = ({ cell, tableData, sheet }) => {
  const { isDisabled } = tableData;
  const { cells } = sheet;
  const { alias } = cell;
  if (alias === VOLATILITY_SOURCE_ALIAS) {
    const volatilityCell = cells.B5;

    volatilityCell.readOnly = ![null, ''].includes(cell.value) || isDisabled;
  }
};

export default conditions;
