/**
 * @name Captable Action
 * @memberof module:common/actions
 * @type {ReduxAction}
 * @return {Object} Global Actions
 */
// #region contant
import { FundService } from 'services';
import { SET_CURRENT_MP_BY_FUND, SET_FUND_INFO, SET_FUND_LIST } from './types';
// #endregion

const fundService = new FundService();

/**
 * @function
 * @name setFundInfo
 * @description set firm data selected.
 * @param {object | null} fundInfo  - fund name
 * @return {object} Action context
 */
export const setFundInfo = fundInfo => ({
  type: SET_FUND_INFO,
  fundInfo,
});

export const setFundList = fundList => ({
  type: SET_FUND_LIST,
  fundList,
});

export const setMeasurementDateByFund = currentMeasurementDateByFund => ({
  type: SET_CURRENT_MP_BY_FUND,
  currentMeasurementDateByFund,
});

export const getFundSummaryInfo = async (fundId, mdId) => {
  try {
    return await fundService.getFundSummaryByMD(fundId, mdId);
  } catch (error) {
    return error;
  }
};

export const getFundById = async fundId => {
  try {
    return fundService.getFundById(fundId);
  } catch (error) {
    return error;
  }
};
