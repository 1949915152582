/**
 * @name Global Reducer
 * @memberof module:common/reducers
 * @type {ReduxReducer}
 * @return {Object} Reducer Specification
 */

import { SET_OTHER_PERMISSIONS } from 'common/actions/users/types';
import { setOtherPermissions } from './reducer';

export default {
  [SET_OTHER_PERMISSIONS]: setOtherPermissions,
};
