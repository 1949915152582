import { format, isValid, parse } from 'date-fns';

const slashFormat = date => {
  let tmpDate = date;
  // Convert number to string if date is a number
  if (typeof date === 'number') {
    tmpDate = date.toString();
  }

  // Define possible date formats
  const formats = ['yyyy-MM-dd', 'MM/dd/yyyy', 'M/d/yyyy', 'MMddyyyy', 'Mddyyyy'];

  // Find the first format that successfully parses the date
  const parsedDate = formats.map(fmt => parse(tmpDate, fmt, new Date())).find(parsed => isValid(parsed));

  // If a valid date is found, format it to MM/dd/yyyy
  if (parsedDate) {
    return format(parsedDate, 'MM/dd/yyyy');
  }

  // If none of the formats match, return the input date
  return tmpDate;
};

export default slashFormat;
