/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserProfile } from './UserProfile';

export type NoteGet = {
  readonly id?: number;
  author: UserProfile;
  updated_by: UserProfile;
  readonly created_at?: string;
  readonly updated_at?: string;
  is_deleted?: boolean | null;
  readonly deleted_at?: string | null;
  content: string;
  page_type: NoteGet.page_type;
  cap_table?: number | null;
  financial_statement?: number | null;
  fund_measurement_date?: number | null;
  fund_ownership?: number | null;
  allocation?: number | null;
  valuation_approach?: number | null;
  company_measurement_date?: number | null;
};

export namespace NoteGet {
  export enum page_type {
    CT = 'ct',
    FO = 'fo',
    FI = 'fi',
    VA = 'va',
    ALLOC = 'alloc',
    BRKPTS = 'brkpts',
    CS = 'cs',
    FS = 'fs',
    WTRFL = 'wtrfl',
  }
}
