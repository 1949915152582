import React from 'react';
import { useCompanyMeasurementDateFilter } from 'services/hooks';
import { CompanySummary } from './pages';
import { UseCompanyMeasurementDateFilter } from './types';

const Portfolio = () => {
  const { selectedMeasurementDate, MDDialogue }
    = useCompanyMeasurementDateFilter() as unknown as UseCompanyMeasurementDateFilter;

  return (
    <>
      {/* Company Summary */}
      <CompanySummary selectedMeasurementDate={selectedMeasurementDate} />

      {/* Measurement Date Modal */}
      <MDDialogue />
    </>
  );
};

export default Portfolio;
