import React from 'react';
import PropTypes from 'prop-types';
import FRContentDescription from './FRContentDescription';
import FRContentTitle from './FRContentTitle';
import RequestContentListOfTasks from './RequestContentListOfTasks';

const RequestTask = ({
  isDocumentRequest = false,
  title,
  description,
  tasks,
  firmId,
  companyId,
  setUpdateTaskList,
  isInitialSetup,
  primaryInformationRequestId,
}) => (
  <>
    <FRContentTitle>{title}</FRContentTitle>
    <FRContentDescription>{description}</FRContentDescription>
    <RequestContentListOfTasks
      isDocumentRequest={isDocumentRequest}
      tasks={tasks}
      firmId={firmId}
      companyId={companyId}
      setUpdateTaskList={setUpdateTaskList}
      isInitialSetup={isInitialSetup}
      primaryInformationRequestId={primaryInformationRequestId}
    />
  </>
);

RequestTask.propTypes = {
  isDocumentRequest: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  firmId: PropTypes.number,
  companyId: PropTypes.number,
  setUpdateTaskList: PropTypes.func,
  isInitialSetup: PropTypes.bool,
  primaryInformationRequestId: PropTypes.number,
};

export default RequestTask;
