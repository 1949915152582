import { makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

export const useWaterfallCalculatorStyles = makeStyles<ITheme>(() => ({
  mainContainer: {
    width: 'fit-content',
  },
}));

export const useExitEquityValueStyles = makeStyles<ITheme>(theme => ({
  exitEquitityValueContainer: {
    marginBottom: 10,
  },
  exitEquitityValueInput: {
    width: 160,
    '& .MuiOutlinedInput-input': {
      padding: '8px 20px 8px 10px',
      textAlign: 'right',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      backgroundColor: '#fff',
      color: theme.palette.primary[800],
      fontSize: 12,
      '&.Mui-focused fieldset': {
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
  },
  exitEquitityValueLabel: {
    fontWeight: 800,
    fontSize: 12,
    marginRight: 10,
    color: 'rgba(0, 0, 0, 0.40)',
  },
}));
