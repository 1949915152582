import React from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';

const AddActionButton = ({ onClickHandler, disabled, text }) => (
  <div>
    <Button
      variant="outlined"
      startIcon={<AddIcon />}
      onClick={onClickHandler}
      disabled={disabled}
      className="btn-table-aligned">
      {text}
    </Button>
  </div>
);

AddActionButton.propTypes = {
  onClickHandler: PropTypes.func,
  disabled: PropTypes.bool,
  text: PropTypes.string,
};

export default React.memo(AddActionButton);
