/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { sortBy } from 'lodash';
import {
  POST_TAX_CUTS_JOBS_ACT_NOL_BALANCE_ALIAS,
  PRE_TAX_CUTS_JOBS_ACT_ALIAS,
  PRESENT_VALUE_OF_NOL_TAX_SAVINGS_ALIAS,
} from 'pages/Valuations/approaches/discountCashFlow/dcfNOLCarryover/config/constants';
import { WEIGHTED_EV } from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { parseDatabaseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';

const reverseParser = ({ cells, columns, allowEmptyValues, rowConfig, tableData, sheet, fieldAttributes }) => {
  const sortedColumns = sortBy(columns, ['order']);
  const alphabet = alphabetGenerator([], sortedColumns.length);
  const { dcfApproachAttrs, dcfPeriodAttrs } = fieldAttributes;
  sortedColumns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];

    // replace updated values
    rowConfig.forEach((cell, cellIndex) => {
      const key = columnLegend + (cellIndex + 1);
      const defaultValue = cell.defaultValue || null;
      const type = cell.dbType || null;
      const format = cell.format || null;
      const value = cells[key] ? cells[key].value : defaultValue;
      const { gridType } = cell;
      const decimalPlaces
        = dcfApproachAttrs[cell.alias]?.decimal_places
        || dcfPeriodAttrs[cell.alias]?.decimal_places
        || cell?.dbDecimalPlaces;

      const calcValue = parseDatabaseValue({
        type,
        value,
        defaultValue,
        format,
        allowEmptyValues,
        gridType,
        decimalPlaces,
      });

      if (columns.length === 1) {
        tableData.approach.valuations_approach_dcf[cell.alias] = calcValue;
        columns.forEach(column => {
          column[cell.alias] = calcValue;
        });
        if (cell.alias === WEIGHTED_EV) {
          tableData.approach.enterprise_value = calcValue;
        }
        sheet.columns = columns;
      } else if (
        [PRE_TAX_CUTS_JOBS_ACT_ALIAS, POST_TAX_CUTS_JOBS_ACT_NOL_BALANCE_ALIAS].includes(cell.alias)
        && columnIndex === 0
      ) {
        tableData.approach.valuations_approach_dcf[cell.alias] = calcValue;
      } else if (cell.alias === PRESENT_VALUE_OF_NOL_TAX_SAVINGS_ALIAS) {
        if (columnIndex === 0) {
          tableData.approach.valuations_approach_dcf[cell.alias] = 0;
        } else {
          tableData.approach.valuations_approach_dcf[cell.alias] += calcValue;
        }
      } else {
        const period = tableData.approach.valuations_approach_dcf.dcf_financial_period.find(
          ({ year }) => year.toString() === column.year.toString()
        );
        period[cell.alias] = calcValue;
        const sheetPeriod = sheet.columns.find(({ year }) => year.toString() === column.year.toString());
        sheetPeriod[cell.alias] = calcValue;
      }
    });
  });
};

export default reverseParser;
