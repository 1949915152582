import {
  SERVICES_AN_ERROR_OCCURRED_CREATING,
  SERVICES_AN_ERROR_OCCURRED_LOADING,
  SERVICES_AN_ERROR_OCCURRED_UPDATING,
  SERVICES_SUCCESSFULLY_CREATED,
  SERVICES_SUCCESSFULLY_UPDATED,
} from 'common/constants/services/services';

// Valuation by Allocation
export const VALUATION_BY_ALLOCATION_QUERY_KEY = 'VALUATION_BY_ALLOCATION';
export const VALUATION_BY_ALLOCATION_TITLE = 'Valuation';
export const VALUATION_BY_ALLOCATION_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_LOADING(VALUATION_BY_ALLOCATION_TITLE);

// Effective Tax Rate by Company Measurement Date
export const EFFECTIVE_TAX_RATE_BY_CMD_QUERY_KEY = 'EFFECTIVE_TAX_RATE_BY_CMD';
export const EFFECTIVE_TAX_RATE_BY_CMD_TITLE = 'Effective Tax Rate';
export const EFFECTIVE_TAX_RATE_BY_CMD_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_LOADING(
  EFFECTIVE_TAX_RATE_BY_CMD_TITLE
);

// Comp Groups by Firm
export const COMP_GROUPS_BY_FIRM_QUERY_KEY = 'COMP_GROUPS_BY_FIRM';
export const COMP_GROUPS_BY_FIRM_TITLE = 'Comp Groups';
export const COMP_GROUPS_BY_FIRM_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_LOADING(COMP_GROUPS_BY_FIRM_TITLE);

// Public Comps by Date
export const PUBLIC_COMPS_BY_DATE_QUERY_KEY = 'PUBLIC_COMPS_BY_DATE';
export const PUBLIC_COMPS_BY_DATE_TITLE = 'Public Comps';
export const PUBLIC_COMPS_BY_DATE_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_LOADING(PUBLIC_COMPS_BY_DATE_TITLE);

// Update Valuation
export const UPDATE_VALUATION_TITLE = 'Valuation';
export const UPDATE_VALUATION_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_UPDATING(UPDATE_VALUATION_TITLE);
export const UPDATE_VALUATION_DEFAULT_SUCCESS_MESSAGE = SERVICES_SUCCESSFULLY_UPDATED(UPDATE_VALUATION_TITLE);

// Create New Version
export const CREATE_NEW_VERSION_TITLE = 'New Version';
export const CREATE_NEW_VERSION_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_CREATING(CREATE_NEW_VERSION_TITLE);
export const CREATE_NEW_VERSION_DEFAULT_SUCCESS_MESSAGE = SERVICES_SUCCESSFULLY_CREATED(CREATE_NEW_VERSION_TITLE);

// Set Ready For Audit
export const SET_READY_FOR_AUDIT_TITLE = 'ready for audit status';
export const SET_READY_FOR_AUDIT_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_UPDATING(SET_READY_FOR_AUDIT_TITLE);
export const SET_READY_FOR_AUDIT_DEFAULT_SUCCESS_MESSAGE = SERVICES_SUCCESSFULLY_UPDATED(SET_READY_FOR_AUDIT_TITLE);
