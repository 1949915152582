/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Activation } from '../models/Activation';
import type { CustomSendEmailReset } from '../models/CustomSendEmailReset';
import type { CustomTokenObtainPair } from '../models/CustomTokenObtainPair';
import type { CustomUser } from '../models/CustomUser';
import type { CustomUserCreatePasswordRetype } from '../models/CustomUserCreatePasswordRetype';
import type { PasswordResetConfirmRetype } from '../models/PasswordResetConfirmRetype';
import type { SendEmailReset } from '../models/SendEmailReset';
import type { SetPasswordRetype } from '../models/SetPasswordRetype';
import type { SetUsername } from '../models/SetUsername';
import type { TokenRefresh } from '../models/TokenRefresh';
import type { TokenVerify } from '../models/TokenVerify';
import type { UsernameResetConfirm } from '../models/UsernameResetConfirm';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {
  /**
   * @param data
   * @returns CustomTokenObtainPair
   * @throws ApiError
   */
  public static authJwtCreateCreate(data: CustomTokenObtainPair): CancelablePromise<CustomTokenObtainPair> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/jwt/create',
      body: data,
    });
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   * @param data
   * @returns TokenRefresh
   * @throws ApiError
   */
  public static authJwtRefreshCreate(data: TokenRefresh): CancelablePromise<TokenRefresh> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/jwt/refresh',
      body: data,
    });
  }

  /**
   * Takes a token and indicates if it is valid.  This view provides no
   * information about a token's fitness for a particular use.
   * @param data
   * @returns TokenVerify
   * @throws ApiError
   */
  public static authJwtVerifyCreate(data: TokenVerify): CancelablePromise<TokenVerify> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/jwt/verify',
      body: data,
    });
  }

  /**
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static authUsersList(page?: number): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<CustomUser>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/auth/users/',
      query: {
        page: page,
      },
    });
  }

  /**
   * @param data
   * @returns CustomUserCreatePasswordRetype
   * @throws ApiError
   */
  public static authUsersCreate(
    data: CustomUserCreatePasswordRetype
  ): CancelablePromise<CustomUserCreatePasswordRetype> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/users/',
      body: data,
    });
  }

  /**
   * @param data
   * @returns Activation
   * @throws ApiError
   */
  public static authUsersActivation(data: Activation): CancelablePromise<Activation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/users/activation/',
      body: data,
    });
  }

  /**
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static authUsersMeRead(page?: number): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<CustomUser>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/auth/users/me/',
      query: {
        page: page,
      },
    });
  }

  /**
   * @param data
   * @returns CustomUser
   * @throws ApiError
   */
  public static authUsersMeUpdate(data: CustomUser): CancelablePromise<CustomUser> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/auth/users/me/',
      body: data,
    });
  }

  /**
   * @param data
   * @returns CustomUser
   * @throws ApiError
   */
  public static authUsersMePartialUpdate(data: CustomUser): CancelablePromise<CustomUser> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/auth/users/me/',
      body: data,
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static authUsersMeDelete(): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/auth/users/me/',
    });
  }

  /**
   * @param data
   * @returns CustomSendEmailReset
   * @throws ApiError
   */
  public static authUsersResendActivation(data: CustomSendEmailReset): CancelablePromise<CustomSendEmailReset> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/users/resend_activation/',
      body: data,
    });
  }

  /**
   * @param data
   * @returns CustomSendEmailReset
   * @throws ApiError
   */
  public static authUsersResetPassword(data: CustomSendEmailReset): CancelablePromise<CustomSendEmailReset> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/users/reset_password/',
      body: data,
    });
  }

  /**
   * @param data
   * @returns PasswordResetConfirmRetype
   * @throws ApiError
   */
  public static authUsersResetPasswordConfirm(
    data: PasswordResetConfirmRetype
  ): CancelablePromise<PasswordResetConfirmRetype> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/users/reset_password_confirm/',
      body: data,
    });
  }

  /**
   * @param data
   * @returns SendEmailReset
   * @throws ApiError
   */
  public static authUsersResetUsername(data: SendEmailReset): CancelablePromise<SendEmailReset> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/users/reset_username/',
      body: data,
    });
  }

  /**
   * @param data
   * @returns UsernameResetConfirm
   * @throws ApiError
   */
  public static authUsersResetUsernameConfirm(data: UsernameResetConfirm): CancelablePromise<UsernameResetConfirm> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/users/reset_username_confirm/',
      body: data,
    });
  }

  /**
   * @param data
   * @returns SetPasswordRetype
   * @throws ApiError
   */
  public static authUsersSetPassword(data: SetPasswordRetype): CancelablePromise<SetPasswordRetype> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/users/set_password/',
      body: data,
    });
  }

  /**
   * @param data
   * @returns SetUsername
   * @throws ApiError
   */
  public static authUsersSetUsername(data: SetUsername): CancelablePromise<SetUsername> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/users/set_username/',
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this user.
   * @returns CustomUser
   * @throws ApiError
   */
  public static authUsersRead(id: number): CancelablePromise<CustomUser> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/auth/users/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this user.
   * @param data
   * @returns CustomUser
   * @throws ApiError
   */
  public static authUsersUpdate(id: number, data: CustomUser): CancelablePromise<CustomUser> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/auth/users/{id}/',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this user.
   * @param data
   * @returns CustomUser
   * @throws ApiError
   */
  public static authUsersPartialUpdate(id: number, data: CustomUser): CancelablePromise<CustomUser> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/auth/users/{id}/',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this user.
   * @returns void
   * @throws ApiError
   */
  public static authUsersDelete(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/auth/users/{id}/',
      path: {
        id: id,
      },
    });
  }
}
