import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import {
  VALUATION_BY_ALLOCATION_DEFAULT_ERROR_MESSAGE,
  VALUATION_BY_ALLOCATION_QUERY_KEY,
} from 'common/constants/services/valuations';
import { ValuationService } from 'services';
import { throwError } from 'utillities';
import {
  GetValuationByAllocationHook,
  GetValuationByAllocationParams,
  GetValuationByAllocationResponse,
  UseGetValuationByAllocation,
} from './types';

const getValuationByAllocation = async (params: GetValuationByAllocationParams) => {
  const { allocationId } = params;

  if (isNil(allocationId)) return null;

  const valuationService = new ValuationService();

  try {
    const valuation = (await valuationService.getValuation(allocationId)) as GetValuationByAllocationResponse;

    if (!valuation) return throwError(VALUATION_BY_ALLOCATION_DEFAULT_ERROR_MESSAGE);

    return valuation;
  } catch (error) {
    return throwError(VALUATION_BY_ALLOCATION_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetValuationByAllocation: UseGetValuationByAllocation = params => {
  const { allocation, shouldQueryAutomatically = true } = params;

  const allocationId = allocation?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<
    GetValuationByAllocationHook['data'],
    Error
  >({
    enabled: !isNil(allocationId) && shouldQueryAutomatically,
    queryFn: () => getValuationByAllocation({ allocationId }),
    queryKey: [VALUATION_BY_ALLOCATION_QUERY_KEY, allocationId],
    refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetValuationByAllocation;
