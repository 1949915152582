import React from 'react';
import { CSE, CSE_METHOD, OPM, OPM_METHOD, WATERFALL, WATERFALL_METHOD } from 'common/constants/allocations';
import { CAP_TABLE_SELECTION_ALIAS, CAP_TABLE_SELECTION_TITLE } from 'common/constants/valuations';
import { smallCurrencyFormat, weightingPercentFormat } from 'common/formats/formats';
import { SelectValueViewer } from 'components';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import {
  ALLOCATION_BACKSOLVE_WEIGHTING_ALIAS,
  ALLOCATION_BACKSOLVE_WEIGHTING_TITLE,
  ALLOCATION_METHOD_ALIAS,
  ALLOCATION_METHOD_TITLE,
  PRESENT_SHARE_VALUES_ALIAS,
  PRESENT_SHARE_VALUES_TITLE,
} from 'pages/Valuations/approaches/backsolveApproach/BacksolveTable/util/constants';
import { BOLD_BLUE_ROW_HEADER_CONFIG } from 'pages/Valuations/approaches/backsolveApproach/constants';
import CapTableListSelection from 'pages/Valuations/components/CapTableListSelection';

const scenariosMethods = [
  {
    id: WATERFALL.toString(),
    name: WATERFALL_METHOD,
  },
  {
    id: CSE.toString(),
    name: CSE_METHOD,
  },
  {
    id: OPM.toString(),
    name: OPM_METHOD,
  },
];

const getSecurityRows = ({ captableList, approach }) => {
  const {
    valuations_approach_backsolve: { applied_methodologies: appliedMethodologies },
  } = approach;
  // if there is at least a primary captable, it will be default value for the applied methodologies
  const pickedCaptableId = appliedMethodologies[0].cap_table;
  const captableSecurities
    = captableList.find(({ id }) => pickedCaptableId.toString() === id.toString())?.securities || [];

  return captableSecurities.map(s => ({
    value: s.name,
    alias: s.security.toString(),
    securityName: s.name,
    readOnly: true,
    gridType: 'number',
    customKey: s.security,
    format: smallCurrencyFormat,
    hasTotal: true,
  }));
};

const getRowConfig = ({ captableList, approach, isDisabled }) => [
  {
    largeHeader: true,
    ignoreRowCopy: true,
  },
  {
    ...BOLD_BLUE_ROW_HEADER_CONFIG,
    value: ALLOCATION_METHOD_TITLE,
    alias: ALLOCATION_METHOD_ALIAS,
    customKey: 'allocationMethod',
    rowNumber: 2,
    readOnly: isDisabled,
    dbType: 'string',
    expr: '',
    defaultValue: WATERFALL,
    dataEditor: props => (
      <GridSelect
        menuPosition="fixed"
        options={scenariosMethods}
        objectOptions={{ displayKey: 'name', valueKey: 'id' }}
        {...props}
      />
    ),
    valueViewer: props => (
      <SelectValueViewer options={scenariosMethods} objectOptions={{ displayKey: 'name', valueKey: 'id' }} {...props} />
    ),
    ignoreRowCopy: true,
  },
  {
    value: CAP_TABLE_SELECTION_TITLE,
    alias: CAP_TABLE_SELECTION_ALIAS,
    rowNumber: 3,
    customKey: 'capTableSelection',
    readOnly: isDisabled,
    dbType: 'string',
    defaultValue: captableList.find(item => item.is_primary)?.id,
    expr: '',
    dataEditor: props => <CapTableListSelection {...props} />,
    valueViewer: props => (
      <SelectValueViewer options={captableList} objectOptions={{ displayKey: 'name', valueKey: 'id' }} {...props} />
    ),
    ignoreRowCopy: true,
  },
  {
    value: ALLOCATION_BACKSOLVE_WEIGHTING_TITLE,
    alias: ALLOCATION_BACKSOLVE_WEIGHTING_ALIAS,
    rowNumber: 4,
    customKey: 'allocationBacksolveWeighting',
    readOnly: isDisabled,
    dbType: 'string',
    format: weightingPercentFormat,
    gridType: 'percentage',
    dbDecimalPlaces: 2,
    expr: '',
    hasTotal: true,
  },
  {
    value: PRESENT_SHARE_VALUES_TITLE,
    defaultValue: '',
    rowNumber: 5,
    alias: PRESENT_SHARE_VALUES_ALIAS,
    expr: '',
    readOnly: true,
  },
  ...getSecurityRows({ captableList, approach }),
];
export default getRowConfig;
