import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { fundOwnershipUrl } from 'common/config/urls';
import { EmptyTableMessage } from 'components';
import GoToFundOwnership from 'pages/Waterfall/components/GoToFundOwnership';

const NeedsFundOwnershipFirst = ({ measurementDates, selectedMeasurementDate, title, tagline }) => {
  const { firmSlugParam, companySlugParam } = useParams();

  const fundOwnershipTableTerms = useMemo(
    () => ({
      tableName: 'Fund Ownership',
      tableSlug: 'fund-ownership',
      columnName: 'Ownership',
      pluralColumnName: 'Ownership',
    }),
    []
  );

  const fundOwnershipURL = useMemo(
    () => fundOwnershipUrl(firmSlugParam, companySlugParam),
    [firmSlugParam, companySlugParam]
  );

  return (
    <EmptyTableMessage
      tableTerms={fundOwnershipTableTerms}
      component={
        <GoToFundOwnership
          fundOwnershipURL={fundOwnershipURL}
          measurementDateSlug={(measurementDates || []).find(md => md.id === Number(selectedMeasurementDate?.id))?.slug}
        />
      }
      title={title}
      tagline={tagline}
    />
  );
};

NeedsFundOwnershipFirst.propTypes = {
  measurementDates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      name: PropTypes.string,
      date: PropTypes.string,
      is_open: PropTypes.bool,
      cmd_id: PropTypes.number,
      company_id: PropTypes.number,
    })
  ),
  selectedMeasurementDate: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
    is_open: PropTypes.bool,
    cmd_id: PropTypes.number,
    company_id: PropTypes.number,
  }),
  title: PropTypes.string,
  tagline: PropTypes.string,
};

export default NeedsFundOwnershipFirst;
