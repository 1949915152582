import {
  SERVICES_AN_ERROR_OCCURRED_LOADING,
  SERVICES_AN_ERROR_OCCURRED_UPDATING,
} from 'common/constants/services/services';

export const NOTES_GETTING_NOTES_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_LOADING('notes');
export const NOTES_UPDATING_NOTES_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_UPDATING('notes');
export const GET_NOTES_KEY = 'GET_NOTES_KEY';
export const SAVE_NOTES_EMPTY_ERROR = 'Notes data are empty';
export const NOTES_SAVED_SUCCESS = 'The notes were saved successfully';
export const NOTES_SAVED_ERROR = 'An error occurred while saving the notes';
export const NOTES_SHOULD_NOT_BE_EMPTY = 'Please ensure that the notes contain content and are not left empty';
