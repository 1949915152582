import React, { useCallback, useMemo, useState } from 'react';
import { useStore } from 'common/store';
import FirmsService from 'services/firms';
import { useResponse } from 'services/hooks/useResponse';
import DecisionAlert from './components/DecisionAlert';
import DisconnectApproachDialog from './components/DisconnectApproachDialog';

const usePendingDecisionsByFirm = () => {
  const [decisions, setDecisions] = useState();
  const [decisionData, setDecisionData] = useState();
  const [isFetchingDecision, setIsFetchingDecision] = useState(false);
  const [showDisconnectDialog, setShowDisconnectDialog] = useState(false);

  const [{ firmId }] = useStore();
  const { successNotification, errorNotification } = useResponse();

  const firmService = useMemo(() => new FirmsService(), []);

  const fetchPendingDecisions = useCallback(
    async firmIdParam => {
      try {
        setIsFetchingDecision(true);
        const response = await firmService.getPendingDecisionsByFirm(firmIdParam);
        if (response) {
          setDecisions(response.results);
        }
        setIsFetchingDecision(false);
      } catch (error) {
        setDecisions();
        errorNotification('An error occurred while retrieving the pending decisions for this firm.');
        setIsFetchingDecision(false);
      }
    },
    [errorNotification, firmService]
  );

  const updateData = useCallback(
    async (firmIdParam, decisionDataParam) => {
      try {
        const response = await firmService.updatePendingDecisionsByFirm(firmIdParam, decisionDataParam);
        if (response) {
          setDecisions(response.results);
          successNotification('Valuations using global comp group were updated.');
        }
      } catch (error) {
        errorNotification('An error occurred while updating the pending decisions for this firm.');
      }
    },
    [errorNotification, firmService, successNotification]
  );

  const displayDecisionAlerts = useCallback(
    () =>
      decisions?.length > 0 && (
        <>
          {decisions?.map(decision => (
            <DecisionAlert
              key={decision.id}
              cause={decision}
              updateDecisionService={updateData}
              setDecisionData={setDecisionData}
              setShowDisconnectDialog={setShowDisconnectDialog}
            />
          ))}
        </>
      ),
    [decisions, updateData]
  );

  const confirmDisconnect = useCallback(() => {
    updateData(firmId, decisionData);
    setShowDisconnectDialog(false);
  }, [decisionData, firmId, updateData]);

  const disconnectDialogActions = useMemo(
    () => [
      {
        label: 'Cancel',
        callback: () => setShowDisconnectDialog(false),
      },
      {
        label: 'Disconnect',
        callback: confirmDisconnect,
        variant: 'contained',
        type: 'danger',
      },
    ],
    [confirmDisconnect]
  );

  const onCloseDisconnectDialog = () => {
    setShowDisconnectDialog(false);
    setDecisionData();
  };

  const getDisconnectDialog = useCallback(
    () =>
      showDisconnectDialog && (
        <DisconnectApproachDialog
          open={showDisconnectDialog}
          decisionData={decisionData}
          onClose={onCloseDisconnectDialog}
          actions={disconnectDialogActions}
        />
      ),
    [decisionData, disconnectDialogActions, showDisconnectDialog]
  );

  return {
    decisions,
    displayDecisionAlerts,
    fetchPendingDecisions,
    getDisconnectDialog,
    isFetchingDecision,
    updateData,
  };
};

export default usePendingDecisionsByFirm;
