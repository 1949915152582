/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getCompanyDetails as getCompanyDetailsService,
  updateCompanyDetails as updateCompanyDetailsService,
} from 'dashboard409a/services/company';
import { IGetCompanyDetailsResponse } from 'dashboard409a/services/types';
import { ICompanyState } from './types';

const initialState: ICompanyState = {
  loading: false,
  companyDetails: null,
};

export const getCompanyDetails = createAsyncThunk<IGetCompanyDetailsResponse, number>(
  'company/getCompanyDetails',
  async (companyId, thunkAPI) => {
    try {
      return await getCompanyDetailsService(companyId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCompanyDetails = createAsyncThunk(
  'company/updateCompanyDetails',
  async (companyDetails: IGetCompanyDetailsResponse, thunkAPI) => {
    try {
      return (await updateCompanyDetailsService(companyDetails)) as unknown as IGetCompanyDetailsResponse;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // getCompanyDetails
    builder.addCase(getCompanyDetails.pending, state => {
      state.loading = true;
    });
    builder.addCase(getCompanyDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.companyDetails = payload;
    });
    builder.addCase(getCompanyDetails.rejected, state => {
      state.loading = false;
    });
    // updateCompanyDetails
    builder.addCase(updateCompanyDetails.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateCompanyDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.companyDetails = payload;
    });
    builder.addCase(updateCompanyDetails.rejected, state => {
      state.loading = false;
    });
  },
});

export default companySlice.reducer;
