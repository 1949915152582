export const SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TYPE_OF_SECURITY_TITLE = 'Type of Security';
export const SCHEDULE_OF_INVESTMENTS_INVESTMENT_DATE_TITLE = 'Investment Date';
export const SCHEDULE_OF_INVESTMENTS_SPREADSHEET_INVESTED_CAPITAL_TITLE = 'Invested Capital';
export const SCHEDULE_OF_INVESTMENTS_SPREADSHEET_SHARES_TITLE = 'Shares';
export const SCHEDULE_OF_INVESTMENTS_SPREADSHEET_CSE_SHARES_TITLE = 'CSE Shares';
export const SCHEDULE_OF_INVESTMENTS_SPREADSHEET_FULLY_DILUTED_OWNERSHIP_TITLE = 'Fully Diluted Ownership %';
export const SCHEDULE_OF_INVESTMENTS_SPREADSHEET_REALIZED_VALUE_TITLE = 'Realized Value';
export const SCHEDULE_OF_INVESTMENTS_SPREADSHEET_UNREALIZED_EQUITY_TITLE = 'Unrealized Equity';
export const SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TOTAL_VALUE_TITLE = 'Total Value';
export const SCHEDULE_OF_INVESTMENTS_SPREADSHEET_MOIC_TITLE = 'MOIC';

export const SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TOTAL = 'Total';

export const SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMNS = Object.freeze({
  TYPE_OF_SECURITY: 0,
  INVESTMENT_DATE: 1,
  INVESTED_CAPITAL: 2,
  SHARES: 3,
  CSE_SHARES: 4,
  FULLY_DILUTED_OWNERSHIP: 5,
  REALIZED_VALUE: 6,
  UNREALIZED_EQUITY: 7,
  TOTAL_VALUE: 8,
  MOIC: 9,
});

export const SCHEDULE_OF_INVESTMENTS_SPREADSHEET_HEADERS = Object.freeze([
  {
    value: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TYPE_OF_SECURITY_TITLE,
  },
  {
    value: SCHEDULE_OF_INVESTMENTS_INVESTMENT_DATE_TITLE,
  },
  {
    value: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_INVESTED_CAPITAL_TITLE,
  },
  {
    value: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_SHARES_TITLE,
  },
  {
    value: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_CSE_SHARES_TITLE,
  },
  {
    value: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_FULLY_DILUTED_OWNERSHIP_TITLE,
  },
  {
    value: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_REALIZED_VALUE_TITLE,
  },
  {
    value: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_UNREALIZED_EQUITY_TITLE,
  },
  {
    value: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TOTAL_VALUE_TITLE,
  },
  {
    value: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_MOIC_TITLE,
  },
]);
