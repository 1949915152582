import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import { GET_NOTES_KEY, NOTES_GETTING_NOTES_ERROR_MESSAGE } from 'common/constants/services/notes';
import { NoteData } from 'components/Notes/types';
import NotesService from 'services/notes';
import { throwError } from 'utillities';
import { GetNotesHook, NotesResponse, UseGetNotesHook, UseGetNotesParams } from './types';

const getNotes = async (params: UseGetNotesParams) => {
  const { pageType, pageTypeKey, pageTypeId } = params;

  if (!pageType || !pageTypeKey || isNil(pageTypeId)) return null;

  const notesService = new NotesService();

  try {
    const notes = (await notesService.getNotes(pageType, pageTypeKey, pageTypeId)) as NotesResponse;

    if (!notes) return throwError(NOTES_GETTING_NOTES_ERROR_MESSAGE);

    const sortedNotes = notes.sort(
      (a: NoteData, b: NoteData) => new Date(a?.created_at ?? '').getTime() - new Date(b?.created_at ?? '').getTime()
    );
    return sortedNotes;
  } catch (error) {
    return throwError(NOTES_GETTING_NOTES_ERROR_MESSAGE);
  }
};

export const useGetNotes: UseGetNotesHook = params => {
  const { pageType, pageTypeKey, pageTypeId, shouldQueryAutomatically = true } = params;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<GetNotesHook['data'], Error>({
    enabled: Boolean(pageType) && Boolean(pageTypeKey) && !isNil(pageTypeId) && shouldQueryAutomatically,
    queryFn: () => getNotes({ pageType, pageTypeKey, pageTypeId }),
    queryKey: [GET_NOTES_KEY, pageType, pageTypeKey, pageTypeId],
    refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};
