/* eslint-disable default-case */
import { SET_CURRENCY } from 'common/actions/format/types';

const reducer = (currentState, action) => {
  if (action.type === SET_CURRENCY) {
    const newCurrencyState = {
      ...currentState.currency,
      ...action.currency,
    };
    // check if the newCurrencyState is different from the current state before returning the new state
    // doing so will cut out unnecessary renders
    if (Object.keys(newCurrencyState).some(key => newCurrencyState[key] !== currentState.currency[key])) {
      return {
        ...currentState,
        currency: newCurrencyState,
      };
    }
  }
  return currentState;
};

export default reducer;
