import React, { FC } from 'react';
import { TaskProgressModalContentBodyDocument } from 'pages/Funds/components/ProcessManagement/TaskProgressModalContentBodyDocument';
import { TaskProgressModalContentBodyQuestionnaire } from 'pages/Funds/components/ProcessManagement/TaskProgressModalContentBodyQuestionnaire';
import { getObjectValue } from 'utillities';
import { TaskProgressModalContentBodyProps } from './types';

const TaskProgressModalContentBody: FC<TaskProgressModalContentBodyProps> = props => {
  const { taskProgressDetails } = props;
  const { category } = getObjectValue(taskProgressDetails);

  switch (category) {
    case 1:
      return <TaskProgressModalContentBodyDocument taskProgressDetails={taskProgressDetails} />;
    case 2:
      return <TaskProgressModalContentBodyQuestionnaire taskProgressDetails={taskProgressDetails} />;
    default:
      return null;
  }
};

export default TaskProgressModalContentBody;
