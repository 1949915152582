import { dcfPercentFormatWithNegativeValues, largeCurrencyFormat } from 'common/formats/formats';
import { TERMINAL_VALUE_EXPR_CUSTOM_KEY } from 'pages/Valuations/approaches/discountCashFlow/utilities/constants';
import {
  ENTERPRISE_VALUE_ALIAS,
  ENTERPRISE_VALUE_ROW_NUMBER,
  ENTERPRISE_VALUE_TITLE,
  MAX_VALUATION_NUMBER_DIGITS,
  NOL_ALIAS,
  NOL_ROW_NUMBER,
  NOL_TITLE,
  SUMMED_PRESENT_VALUE_ALIAS,
  SUMMED_PRESENT_VALUE_ROW_NUMBER,
  SUMMED_PRESENT_VALUE_TITLE,
  TERMINAL_VALUE_ALIAS,
  TERMINAL_VALUE_ROW_NUMBER,
  TERMINAL_VALUE_TITLE,
  WACC_ALIAS,
  WACC_ROW_NUMBER,
  WACC_TITLE,
} from 'pages/Valuations/util/constants';

const getRowConfig = ({ dcfTerminalValueName, isDisabled }) => [
  {
    className: 'wide',
    value: 'DCF Summary',
    alias: 'title',
    hidden: false,
    readOnly: true,
    gridType: 'string',
    dbType: 'string',
    expr: '',
    defaultValue: '0',
    isTitleOrHeader: true,
  },
  {
    className: 'bigger-row-label',
    value: WACC_TITLE,
    alias: WACC_ALIAS,
    rowNumber: WACC_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: dcfPercentFormatWithNegativeValues,
    gridType: 'percentage',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '',
    checkPrevCol: true,
    defaultValue: '0',
    disableEvents: true,
    dbDecimalPlaces: 3,
    style: {
      with: '150px',
      minWidth: '150px',
      maxWidth: '150px',
    },
  },
  {
    className: 'bigger-row-label',
    value: SUMMED_PRESENT_VALUE_TITLE,
    alias: SUMMED_PRESENT_VALUE_ALIAS,
    rowNumber: SUMMED_PRESENT_VALUE_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '',
    checkPrevCol: true,
    defaultValue: '0',
  },
  {
    className: 'bigger-row-label',
    value: TERMINAL_VALUE_TITLE,
    alias: `${TERMINAL_VALUE_ALIAS}_expr`,
    rowNumber: TERMINAL_VALUE_ROW_NUMBER,
    customKey: TERMINAL_VALUE_EXPR_CUSTOM_KEY,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
    dbType: 'string',
    allowNegativeValue: true,
    dbDecimalPlaces: 2,
    expr: `=${dcfTerminalValueName}.pv_terminal_value`,
    checkPrevCol: true,
    defaultValue: '0',
  },
  {
    className: 'bigger-row-label',
    value: NOL_TITLE,
    alias: NOL_ALIAS,
    rowNumber: NOL_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '',
    checkPrevCol: true,
    defaultValue: '0',
    disableEvents: true,
    dbDecimalPlaces: 2,
  },
  {
    className: 'bigger-row-label',
    value: ENTERPRISE_VALUE_TITLE,
    alias: ENTERPRISE_VALUE_ALIAS,
    rowNumber: ENTERPRISE_VALUE_ROW_NUMBER,
    customKey: ENTERPRISE_VALUE_ALIAS,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: `=SUM(@${SUMMED_PRESENT_VALUE_ROW_NUMBER}, @${TERMINAL_VALUE_ROW_NUMBER}, @${NOL_ROW_NUMBER})`,
    checkPrevCol: true,
    defaultValue: '0',
  },
];

export default getRowConfig;
