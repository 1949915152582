import { createStyles, makeStyles } from '@material-ui/styles';
import { HORIZONTAL_NAVIGATION_Z_INDEX } from 'components/HorizontalNavigation';
import { FIXED_HEADER_STYLES } from 'theme';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      ...FIXED_HEADER_STYLES,
      top: 0,

      zIndex: HORIZONTAL_NAVIGATION_Z_INDEX,

      '& .MuiToolbar-gutters': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    toolbar: {
      minHeight: `${theme.toolbarHeight}px`,
    },
    titleContainer: {
      overflow: 'hidden',
    },
    taglines: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      fontSize: '14px',
      textTransform: 'uppercase',
    },
    tagline: {
      fontWeight: 600,
      marginRight: '20px',
      minWidth: '40px',

      '&.MuiChip-outlined': {
        borderWidth: '2px',
      },
    },
    lastTagline: {
      marginRight: 0,
      paddingRight: 0,
    },
    subTitle: {
      marginLeft: 10,
    },
    subTitleText: {
      color: theme.palette.primary.dark,
      fontSize: '0.5em',
    },
    progressBar: {
      backgroundColor: 'transparent',
      height: `${theme.progressBarHeight}px`,
      position: 'fixed',
      zIndex: HORIZONTAL_NAVIGATION_Z_INDEX - 1,
      ...FIXED_HEADER_STYLES,
    },
  })
);

export default useStyles;
