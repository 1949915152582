import { isNull } from 'lodash';
import {
  LTM_EBITDA_CUSTOM_KEY,
  LTM_REVENUE_CUSTOM_KEY,
  NTM_EBITDA_CUSTOM_KEY,
  NTM_EBITDA_MULTIPLE,
  NTM_REVENUE_CUSTOM_KEY,
  NTM_REVENUE_MULTIPLE,
  PERCENTILE_SELECTION_A_ALIAS,
  PERCENTILE_SELECTION_B_ALIAS,
  SELECTION,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import {
  percentileChange,
  selectionChange,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/afterCellChanged';
import {
  ENTERPRISE_VALUE_ID,
  ENTERPRISE_VALUE_LETTER,
  GPC_APPROACH_CELL_ALIAS,
  LTM_EBITDA_ID,
  LTM_EBITDA_LETTER,
  LTM_EBITDA_MULTIPLE_ID,
  LTM_EBITDA_MULTIPLE_LETTER,
  LTM_REVENUE_ID,
  LTM_REVENUE_LETTER,
  LTM_REVENUE_MULTIPLE_LETTER,
  NTM_EBITDA_ID,
  NTM_EBITDA_MULTIPLE_ID,
} from 'pages/Valuations/approaches/GuidelineTransactions/config/constants';
import { COMPANY_ROW_ALIAS } from 'pages/ValuationsAllocation/common/constants/valuations';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';

const shouldIgnoreExpr = value => isNull(value) || parseFloat(value) === 0;

const getUpdatedCell = ({ cells, value, rowNumber, multipleCellLetter, ltmCellLetter }) => {
  const enterpriseValueKey = `${ENTERPRISE_VALUE_LETTER}${rowNumber}`;
  const multipleCellRef = cells[`${multipleCellLetter}${rowNumber}`];
  const ltmCellKey = `${ltmCellLetter}${rowNumber}`;
  const ignoreCellExpr = shouldIgnoreExpr(value);
  multipleCellRef.ignoreExpr = ignoreCellExpr;
  multipleCellRef.enabled = !ignoreCellExpr;
  const ltmCellExpr = ignoreCellExpr ? '0' : `=${enterpriseValueKey}/${ltmCellKey}`;
  return { cell: multipleCellRef, value: ltmCellExpr };
};

const calculateNTMDiscount = (columId, gpcApproach) => {
  const tableName = getApproachTableName({ approach: gpcApproach });
  let ntmDiscount;
  switch (columId) {
    case NTM_REVENUE_MULTIPLE:
      ntmDiscount = `=1 - (${tableName}.${LTM_REVENUE_CUSTOM_KEY}  / ${tableName}.${NTM_REVENUE_CUSTOM_KEY})`;
      break;
    case NTM_EBITDA_MULTIPLE:
      ntmDiscount = `=1 - (${tableName}.${LTM_EBITDA_CUSTOM_KEY}  / ${tableName}.${NTM_EBITDA_CUSTOM_KEY})`;
      break;
    default:
      break;
  }
  return ntmDiscount;
};

const handlePercentileChange = (cell, value, cells, changeList) => {
  if ([PERCENTILE_SELECTION_A_ALIAS, PERCENTILE_SELECTION_B_ALIAS].includes(cell.alias)) {
    percentileChange(cell, value, cells, changeList);
  }
};

const handleSelectionChange = (cell, value, cells, changeList) => {
  if (SELECTION === cell.alias) {
    selectionChange(cells, changeList, cell, value);
  }
};

const handleEbitdaAndRevenueChanges = (cell, value, cells, changeList) => {
  const { rowNumber } = cell;
  if ([LTM_EBITDA_ID, LTM_REVENUE_ID, ENTERPRISE_VALUE_ID].includes(cell.columnId)) {
    const ltmEbitdaKey = `${LTM_EBITDA_LETTER}${rowNumber}`;
    const ltmEbitdaCellValue = cell.columnId === LTM_EBITDA_ID ? value : cells[ltmEbitdaKey].value;
    const ebitdaMultipleCell = getUpdatedCell({
      cells,
      value: ltmEbitdaCellValue,
      rowNumber,
      multipleCellLetter: LTM_EBITDA_MULTIPLE_LETTER,
      ltmCellLetter: LTM_EBITDA_LETTER,
    });
    changeList.push(ebitdaMultipleCell);

    const ltmRevenueKey = `${LTM_REVENUE_LETTER}${rowNumber}`;
    const ltmRevenueCellValue = cell.columnId === LTM_REVENUE_ID ? value : cells[ltmRevenueKey].value;
    const revenueMultipleCell = getUpdatedCell({
      cells,
      value: ltmRevenueCellValue,
      rowNumber,
      multipleCellLetter: LTM_REVENUE_MULTIPLE_LETTER,
      ltmCellLetter: LTM_REVENUE_LETTER,
    });
    changeList.push(revenueMultipleCell);
  }
};

const handleGpcApproachChange = (cell, value, cells, changeList, gpcApproaches) => {
  const { rowNumber, columnLegend } = cell;
  if (GPC_APPROACH_CELL_ALIAS.includes(cell.alias)) {
    const selectedApproach = gpcApproaches.find(
      approach => approach.valuations_approach_gpc.id === value || approach.panelId === value
    );
    const nextKeyIndex = 1;
    const nextKey = `${columnLegend}${rowNumber + nextKeyIndex}`;
    const ntmDiscountCell = cells[nextKey];
    if (selectedApproach) {
      const ntmDiscount = calculateNTMDiscount(cell.columnId, selectedApproach);
      changeList.push({ cell: ntmDiscountCell, value: ntmDiscount });
    }
  }
};

const handleCompanyRowAliasChange = (cell, value, change, valuations_approach_gpt) => {
  const updatedValuationsApproachGpt = { ...valuations_approach_gpt };

  if (cell.alias === COMPANY_ROW_ALIAS) {
    if (cell.columnId === LTM_EBITDA_MULTIPLE_ID) {
      updatedValuationsApproachGpt.use_adjusted_LTM_ebitda
        = Number(change.value) !== Number(cell.sheet.tableData.financials[LTM_EBITDA_ID] ?? 0);
    }
    if (cell.columnId === NTM_EBITDA_MULTIPLE_ID) {
      updatedValuationsApproachGpt.use_adjusted_NTM_ebitda
        = Number(change.value) !== Number(cell.sheet.tableData.financials[NTM_EBITDA_ID] ?? 0);
    }
  }

  return updatedValuationsApproachGpt;
};

const afterCellChanged = (changes, cells, _, tableData) => {
  const changeList = [];
  const updatedTableData = { ...tableData };
  const {
    gpcApproaches,
    approach: { valuations_approach_gpt },
  } = updatedTableData;

  changes.forEach(change => {
    const { cell, value } = change;
    changeList.push({ cell, value });
    handlePercentileChange(cell, value, cells, changeList);
    handleSelectionChange(cell, value, cells, changeList);
    handleEbitdaAndRevenueChanges(cell, value, cells, changeList);
    handleGpcApproachChange(cell, value, cells, changeList, gpcApproaches);
    const updatedValuationsApproachGpt = handleCompanyRowAliasChange(cell, value, change, valuations_approach_gpt);
    updatedTableData.approach.valuations_approach_gpt = updatedValuationsApproachGpt;
  });

  return changeList;
};

export default afterCellChanged;
