import React, { useContext, useMemo } from 'react';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import { PUBLIC_COMPANIES, PUBLIC_TRANSACTIONS } from 'pages/Valuations/util/constants';
import FutureExitContext from 'pages/ValuationsAllocation/approaches/FutureExit/utils/FutureExitContext';
import { getStringValue } from 'utillities';

const FilteredValuationApproachSelect = (props: any) => {
  const { approaches, isNTMSelected } = useContext(FutureExitContext);
  const availableApproachTypes = useMemo(() => {
    if (isNTMSelected) {
      return [PUBLIC_COMPANIES];
    }
    return [PUBLIC_COMPANIES, PUBLIC_TRANSACTIONS];
  }, [isNTMSelected]);
  const compsList = useMemo(
    () =>
      approaches
        .filter(a => availableApproachTypes.includes(getStringValue(a?.approach_type)))
        .map(({ panelId, id, name }) => ({
          value: id ?? panelId,
          label: name,
        })),
    [approaches, availableApproachTypes]
  );

  return <GridSelect menuPosition="fixed" options={compsList} {...props} />;
};

export default FilteredValuationApproachSelect;
