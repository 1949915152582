import React, { FC, useEffect, useState } from 'react';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import useWaterfallTableData from '../hooks/useWaterfallTableData';
import { IWaterfallCalculatorTableProps } from '../types';

const WaterfallCalculatorTable: FC<IWaterfallCalculatorTableProps> = props => {
  const { isLoading, exitEquityValue, data, format, formatDispatch } = props;

  const { spreadsheet: waterfallCalculatorSheet } = useWaterfallTableData({ exitEquityValue, data, format });

  const [spreadsheets, setSpreadsheets] = useState([waterfallCalculatorSheet]);

  useEffect(() => {
    if (waterfallCalculatorSheet?.yLength > 0) {
      setSpreadsheets([waterfallCalculatorSheet]);
    }
  }, [waterfallCalculatorSheet]);

  const { onChange, workbook } = useWorkbook(spreadsheets);

  const waterfallCalculatorSheetProps = {
    ...waterfallCalculatorSheet,
    sheet: waterfallCalculatorSheet,
    workbook,
    onChange,
    page: 'Waterfall Calculator',
    format,
    formatDispatch,
  };

  if (isLoading) {
    return <GridSkeleton />;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return waterfallCalculatorSheet?.data ? <ScalarSpreadsheet {...waterfallCalculatorSheetProps} /> : <GridSkeleton />;
};

export default WaterfallCalculatorTable;
