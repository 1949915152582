import React, { FC, useCallback } from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import {
  VALUATIONS_BACKSOLVE_APPROACH,
  VALUATIONS_BACKSOLVE_APPROACH_MENU,
  VALUATIONS_PUBLIC_COMPANIES_APPROACH,
  VALUATIONS_PUBLIC_COMPANIES_APPROACH_MENU,
  VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH,
  VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH_MENU,
  VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH,
  VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH_MENU,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { ApproachTypes } from 'pages/ValuationsAllocation/components/AddScenarioApproachDialog';
import useStyles from './styles';
import { ApproachScenarioTypeOptionProps } from './types';

const connectMenuLabelWithApproach = (approach: ApproachTypes) => {
  switch (approach) {
    case VALUATIONS_PUBLIC_COMPANIES_APPROACH:
      return VALUATIONS_PUBLIC_COMPANIES_APPROACH_MENU;

    case VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH:
      return VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH_MENU;

    case VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH:
      return VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH_MENU;

    case VALUATIONS_BACKSOLVE_APPROACH:
      return VALUATIONS_BACKSOLVE_APPROACH_MENU;

    default:
      return approach;
  }
};

const ApproachScenarioTypeOption: FC<ApproachScenarioTypeOptionProps> = props => {
  const { approach, isDisabled = false, onClick } = props;

  const classes = useStyles();

  const handleOnClick = useCallback(() => onClick(approach), [approach, onClick]);

  return (
    <ListItem button className={classes.paperButton} onClick={handleOnClick} disabled={isDisabled}>
      <ListItemText primary={connectMenuLabelWithApproach(approach)} />
    </ListItem>
  );
};

export default ApproachScenarioTypeOption;
