import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/futureExit/';
import { getNumberValue, getObjectValue } from 'utillities';
import { AllocationMethodColumnCellData, CreateColumns } from './types';

const {
  FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD,
  FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE,
  FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS,
  FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS,
  FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_DATE,
  FE_ALLOCATION_METHOD_SPREADSHEET_RISK_FREE_RATE,
  FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE,
  FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY,
} = SHEET_ALIASES_CONSTANTS;

const createColumns: CreateColumns = params => {
  const { valuationsApproachFutureExit, riskFreeRate } = params;
  const {
    allocation_method: allocationMethod,
    cap_table: capTable,
    maturity,
    volatility_source: volatilitySource,
    volatility,
  } = getObjectValue(valuationsApproachFutureExit);

  const currentAllocationMethod = getNumberValue(allocationMethod);

  // Allocation Method
  const allocationMethodTable = {
    [FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD]: {
      value: currentAllocationMethod,
    } as AllocationMethodColumnCellData,
    [FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE]: {
      value: capTable,
    } as AllocationMethodColumnCellData,
    [FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS]: {
      value: null,
    } as AllocationMethodColumnCellData,
    [FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS]: {
      value: maturity,
    } as AllocationMethodColumnCellData,
    [FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_DATE]: {
      value: null,
    } as AllocationMethodColumnCellData,
    [FE_ALLOCATION_METHOD_SPREADSHEET_RISK_FREE_RATE]: {
      value: getNumberValue(riskFreeRate),
    } as AllocationMethodColumnCellData,
    [FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE]: {
      value: volatilitySource,
    } as AllocationMethodColumnCellData,
    [FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY]: {
      value: volatility,
    } as AllocationMethodColumnCellData,
  };

  return [allocationMethodTable];
};

export default createColumns;
