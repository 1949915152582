import React from 'react';
import uuid from 'react-uuid';
import { currencyFormatZeroToTwoDecimals, percentFormat, standardDecimalFormat } from 'common/formats/formats';
import { withTooltip } from 'components';
import { baseRowConfig } from 'pages/Financials/data';
import RowHeader from 'pages/Valuations/components/CompGroupRowHeader';

const RowHeaderWithTooltip = withTooltip(RowHeader);

const formatMap = {
  1: standardDecimalFormat,
  2: percentFormat({ fractionDigits: 2 }),
  3: currencyFormatZeroToTwoDecimals,
};

const rowConfig = ({ existingKpis, isDisabled }) => {
  const kpiConfig = existingKpis.map(kpi => ({
    value: kpi.name,
    alias: kpi.alias || uuid(),
    hidden: false,
    readOnly: isDisabled,
    isKpiRow: true,
    format: formatMap[kpi.data_type], // this actually will change according to KPI type
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    isEditableTitleCell: !isDisabled,
    valueViewer: props => <RowHeaderWithTooltip {...props} />,
  }));
  const titlesConfig = [...baseRowConfig];
  titlesConfig[0] = {
    ...titlesConfig[0],
    className: 'spreadsheet-table-title-kpi',
    rowSpan: 3,
    value: 'KPI Table',
  };

  return [...titlesConfig, ...kpiConfig];
};

export default rowConfig;
