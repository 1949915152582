/* eslint-disable import/no-unresolved */
/* eslint-disable react/display-name */
import React from 'react';
import * as constants from 'common/constants/allocations';
import { currencyFormat, weightingPercentFormat } from 'common/formats/formats';
import { SelectValueViewer } from 'components';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import CapTableListSelection from 'pages/Valuations/components/CapTableListSelection';

const rowConfig = () => [
  // 1
  {
    value: 'Allocation Method',
    alias: constants.SCENARIO_METHOD_ALIAS,
    hidden: false,
    readOnly: false,
    dataEditor: props => <GridSelect options={constants.SCENARIO_METHODS} {...props} />,
    valueViewer: props => <SelectValueViewer {...props} />,
    dbType: 'number',
    className: 'spreadsheet-table-title-main',
  },
  // 2
  {
    value: 'Cap Table Selection',
    alias: constants.CAP_TABLE_ALIAS,
    hidden: false,
    readOnly: false,
    dataEditor: props => <CapTableListSelection {...props} />,
    valueViewer: props => <SelectValueViewer {...props} />,
  },
  // 3
  {
    value: 'Approach',
    alias: constants.SCENARIO_TYPE_ALIAS,
    hidden: false,
    readOnly: false,
    dataEditor: props => <GridSelect options={constants.SCENARIO_TYPES} {...props} />,
    valueViewer: props => <SelectValueViewer {...props} />,
    dbType: 'number',
  },
  // 4
  {
    value: 'Future Equity Value',
    alias: constants.FUTURE_EQUITY_VALUE_ALIAS,
    hidden: false,
    readOnly: false,
    format: currencyFormat({ fractionDigits: 0 }),
    dataSource: 'backsolve_ledger',
    hasTotal: true,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-bold',
    disableWhenLedgerOpen: true,
  },
  // 5
  {
    value: 'Present Equity Value',
    alias: constants.EQUITY_VALUE_ALIAS,
    hidden: false,
    readOnly: false,
    format: currencyFormat({ fractionDigits: 0 }),
    hasTotal: true,
    gridType: 'number',
    dbType: 'string',
    greaterThan: 0,
    className: 'row-label-bold',
    disableWhenLedgerOpen: true,
  },
  // 6
  {
    value: 'Scenario Weighting / Probability',
    alias: constants.SCENARIO_WEIGHTING_ALIAS,
    hidden: false,
    readOnly: false,
    format: weightingPercentFormat,
    gridType: 'percentage',
    dbType: 'string',
    dbDecimalPlaces: 4,
  },
  {
    value: 'Ledger',
    alias: 'ledgerCell',
    hidden: true,
    isVisible: false,
    readOnly: true,
  },
];

export default rowConfig;
