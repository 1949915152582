/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { parser, rowConfig } from '../common';

const useWaterfallBreakpointsTableData = ({ waterfallColumns, format }) => {
  const [spreadsheet, setSpreadsheet] = useState();
  const { currency } = format;
  const { symbol: currencySymbol, code: currencyCode } = currency;

  const config = useMemo(() => rowConfig({ data: waterfallColumns, isCustomBreakpoint: false }), [waterfallColumns]);

  const tableTerms = useMemo(
    () => ({
      tableName: 'Calculated Breakpoints',
      tableSlug: 'calculated-breakpoints',
      columnName: 'Calculated Breakpoints',
    }),
    []
  );

  useEffect(() => {
    if (waterfallColumns && tableTerms) {
      const spreadsheetConfig = new SpreadsheetConfig({
        parser,
        rowConfig: config,
        name: 'WaterfallBreakPoint',
        columns: waterfallColumns,
        tableData: {
          currencySymbol,
          columns: waterfallColumns,
          isCustomBreakpointsTable: false,
        },
        currency: `${currencyCode} ${currencySymbol}`,
        tableTerms,
        showTotalColumn: false,
        showToolbar: false,
        showPreviousColsDivider: false,
        hasColTitle: true,
        allowConfirmAndDeleteColumn: false,
        currencyFormatter: false,
        unitsFormatter: false,
        format,
        allowCopyColumn: false,
        allowReorderColumns: false,
      });

      setSpreadsheet(spreadsheetConfig);
    }
  }, [waterfallColumns, tableTerms]);

  return {
    spreadsheet,
  };
};

export default useWaterfallBreakpointsTableData;
