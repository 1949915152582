import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    title: {
      fontSize: '20px',
      fontWeight: 700,
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(1),

      [theme.breakpoints.up('md')]: {
        fontSize: '26px',
      },
    },
  })
);

export default useStyles;
