import React from 'react';
import { MAX_CHAR_LENGTH } from 'common/constants/general';
import { roundedPercentFormatIsDecimalTrue, smallCurrencyFormat, xStandardSuffixFormat } from 'common/formats/formats';
import { Cell, Cells } from 'common/types/scalarSpreadsheet';
import { SelectValueViewer } from 'components';
import { GridDate, GridDateEditor, GridDateValueViewer } from 'components/FeaturedSpreadsheet/components';
import FilteredValuationApproachSelect from 'pages/ValuationsAllocation/approaches/FutureExit/FutureEquity/components/FilteredValuationApproachSelect';
import {
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/futureExit';
import { getExpression, getObjectValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { CellParserParams, CustomParserParams } from './types';

const {
  FE_FUTURE_EQUITY_SPREADSHEET_HEADER_TITLE,
  FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE,
  FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE,
  FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH,
  FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE,
  FE_FUTURE_EQUITY_SPREADSHEET_ENTERPRISE_VALUE,
  FE_FUTURE_EQUITY_SPREADSHEET_CASH,
  FE_FUTURE_EQUITY_SPREADSHEET_DEBT,
  FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_EQUITY_VALUE,
  FE_FUTURE_EQUITY_SPREADSHEET_DILUTION_FUTURE_INVESTMENTS,
  FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS,
} = SHEET_ALIASES_CONSTANTS;

const { FE_FUTURE_EQUITY_VALUE_SPREADSHEET } = SHEET_TITLES_CONSTANTS;

const cellParser = (params: CellParserParams) => {
  const { alphabet, colIndex, column, compsList, row, rowIndex } = params;

  const ROW_NUMBER = rowIndex + 1;
  const columnLegend = alphabet[colIndex];
  const cellKey = columnLegend + ROW_NUMBER;

  const { value: columnValue } = getObjectValue(column[row.alias]);

  // Creating Cell from Row and Column
  const cell = { ...row, key: cellKey, value: columnValue };

  // Parse Cell based on Row alias
  switch (row.alias) {
    case FE_FUTURE_EQUITY_SPREADSHEET_HEADER_TITLE:
      cell.value = '';
      break;

    case FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE:
      cell.value = columnValue;
      cell.gridType = 'gridDate';
      cell.dbType = 'string';
      cell.gridDateComponent = (props: any) => <GridDate {...props} />;
      cell.valueViewer = (props: any) => <GridDateValueViewer {...props} />;
      cell.dataEditor = (props: any) => <GridDateEditor {...props} />;
      cell.forceComponent = true;
      break;

    case FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE:
      cell.value = columnValue;
      cell.defaultValue = '';
      cell.dbType = 'string';
      cell.gridType = 'number';
      cell.format = smallCurrencyFormat;
      cell.forceComponent = undefined;
      cell.isEditableTitleCell = null;
      cell.useScalarSpreadsheetCell = null;
      cell.dropdown = undefined;
      cell.valueViewer = null;
      cell.dataEditor = null;
      cell.customKey = '';
      break;

    case FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH:
      cell.value = columnValue;
      cell.dbType = 'string';
      cell.gridType = null;
      cell.valueViewer = (props: any) => <SelectValueViewer options={compsList} {...props} />;
      cell.dataEditor = (props: any) => <FilteredValuationApproachSelect {...props} />;
      cell.forceComponent = true;
      break;

    case FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE:
      cell.value = columnValue;
      cell.dbType = 'string';
      cell.gridType = 'number';
      cell.format = xStandardSuffixFormat;
      cell.forceComponent = undefined;
      cell.isEditableTitleCell = null;
      cell.useScalarSpreadsheetCell = null;
      cell.dropdown = undefined;
      cell.valueViewer = null;
      cell.dataEditor = null;
      break;

    case FE_FUTURE_EQUITY_SPREADSHEET_ENTERPRISE_VALUE:
      cell.value = '';
      cell.dbType = 'string';
      cell.gridType = 'number';
      cell.format = smallCurrencyFormat;
      cell.expr = `=A${FE_FUTURE_EQUITY_VALUE_SPREADSHEET.METRIC_VALUE.ROW_NUMBER} * A${FE_FUTURE_EQUITY_VALUE_SPREADSHEET.MULTIPLE_VALUE.ROW_NUMBER}`;
      break;

    case FE_FUTURE_EQUITY_SPREADSHEET_CASH:
    case FE_FUTURE_EQUITY_SPREADSHEET_DEBT:
      cell.value = columnValue;
      cell.dbType = 'string';
      cell.gridType = 'number';
      cell.format = smallCurrencyFormat;
      break;

    case FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_EQUITY_VALUE:
      cell.dbType = 'string';
      cell.gridType = 'number';
      cell.format = smallCurrencyFormat;
      cell.expr = `=A${FE_FUTURE_EQUITY_VALUE_SPREADSHEET.ENTERPRISE_VALUE.ROW_NUMBER} + A${FE_FUTURE_EQUITY_VALUE_SPREADSHEET.PLUS_CASH.ROW_NUMBER} - A${FE_FUTURE_EQUITY_VALUE_SPREADSHEET.LESS_DEBT.ROW_NUMBER}`;
      break;

    case FE_FUTURE_EQUITY_SPREADSHEET_DILUTION_FUTURE_INVESTMENTS:
      cell.gridType = 'percentage';
      cell.dbType = 'string';
      cell.dbDecimalPlaces = 4;
      cell.format = roundedPercentFormatIsDecimalTrue;
      cell.allowNegativeValue = false;
      break;

    case FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS:
      cell.className = 'bold-row';
      cell.dbType = 'string';
      cell.gridType = 'number';
      cell.format = smallCurrencyFormat;
      cell.expr = `=A${FE_FUTURE_EQUITY_VALUE_SPREADSHEET.FUTURE_EQUITY_VALUE.ROW_NUMBER} * (1 - A${FE_FUTURE_EQUITY_VALUE_SPREADSHEET.DILUTION_FUTURE_INVESTMENTS.ROW_NUMBER})`;
      break;

    default:
      break;
  }

  // Set default Cell values
  const {
    allowEmptyValues = true,
    customKey = null,
    dbType = 'string',
    expr = '',
    format = null,
    gridType = 'string',
    isTextArea = false,
    maxNumberChars = MAX_CHAR_LENGTH,
    value = '',
  } = getObjectValue(cell);

  return {
    [cell.key]: {
      ...cell,
      allowEmptyValues,
      columnLegend,
      customKey,
      dbType,
      expr: getExpression({ expr, columnLegend }),
      format,
      gridType,
      isTextArea,
      maxNumberChars,
      value,
    } as Cell,
  };
};

const customParser = (params: CustomParserParams) => {
  const { columns, compsList, financials, futureEquitySheet, measurementDate, rowConfig } = params;

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];

  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      cells = {
        ...cells,
        ...cellParser({
          alphabet,
          colIndex,
          column,
          compsList,
          financials,
          futureEquitySheet,
          measurementDate,
          row,
          rowIndex,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
