import useStyles from './styles';

const useCellCustomClasses = () => {
  const classes = useStyles();

  return {
    cellCustomClasses: classes,
  };
};

export default useCellCustomClasses;
