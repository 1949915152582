import React, { useCallback } from 'react';
import { MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

const MenuOption = ({ mainAction, tabBarId, option, optionIndex, onClick, classes }) => {
  const onClickHandler = useCallback(() => {
    onClick(option);
  }, [onClick, option]);

  return (
    <MenuItem
      id={`${mainAction?.id || tabBarId}-action-item-${optionIndex + 1}`}
      onClick={option.disabled ? null : onClickHandler}
      className={option.disabled ? classes.disabled : ''}>
      {option.label}
    </MenuItem>
  );
};

MenuOption.propTypes = {
  mainAction: PropTypes.object,
  tabBarId: PropTypes.string,
  option: PropTypes.object,
  optionIndex: PropTypes.number,
  onClick: PropTypes.func,
  classes: PropTypes.object,
};

export default MenuOption;
