/**
 * @file common/reducers/index.js
 * @module common/reducers
 * @desc This is the encapsulation for all reducers
 * @since v1.0
 * @author @clenondavis <dev@carloslenon.com>
 * @return {Object} List of reducers combined
 */
import allocationsReducer from './allocations';
import authReducers from './auth';
import captableReducer from './captable';
import companiesReducer from './companies';
import firmsReducer from './firms';
import fundsReducer from './funds';
import globalReducer from './global';
import usersReducer from './users';
import valuationsReducer from './valuations';

const createReducer = handlers => (state, action) => {
  // eslint-disable-next-line no-prototype-builtins
  if (!handlers.hasOwnProperty(action.type)) {
    return state;
  }
  return handlers[action.type](state, action);
};

export default createReducer({
  ...globalReducer,
  ...allocationsReducer,
  ...captableReducer,
  ...firmsReducer,
  ...fundsReducer,
  ...companiesReducer,
  ...authReducers,
  ...valuationsReducer,
  ...usersReducer,
});
