import React, { FC, useState } from 'react';
import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import { ITopMenuItem } from './types';

const TopMenuItem: FC<ITopMenuItem> = props => {
  const { item } = props;
  const { slug, title, subMenu, isParent = false, url = '' } = item;
  const [isOpen, setIsOpen] = useState(false);

  const classes = useStyles();

  const handleCollapse = () => {
    setIsOpen(previousState => !previousState);
  };

  return (
    <>
      {isParent ? (
        // TODO: Set active state
        <>
          <ListItem
            id={`topMenuItem-${slug}-xs`}
            component="li"
            className={clsx(classes.item, classes.itemXs)}
            onClick={handleCollapse}>
            <ListItemText className={classes.itemText} primary={title} />
            {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </ListItem>

          <ListItem id={`topMenuItem-${slug}-md`} component="li" className={clsx(classes.itemWithLink, classes.itemMd)}>
            <Link to={slug} className={classes.itemLink}>
              <ListItemText className={classes.itemText} primary={title} />
            </Link>
          </ListItem>
        </>
      ) : (
        <ListItem id={`topMenuItem-${slug}`} component="li" className={classes.item}>
          <Link to={url}>
            <ListItemText primary={title} className={classes.itemText} />
          </Link>
        </ListItem>
      )}

      {subMenu && subMenu.length > 0 && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit className={classes.subMenu}>
          <List component="ul" disablePadding>
            {subMenu.map(subMenuItem => {
              const { slug: subMenuSlug, title: subMenuTitle } = subMenuItem;

              return (
                <ListItem key={`subMenuItem-${subMenuSlug}`} component="li" className={classes.subItem}>
                  <Link to={subMenuSlug || '#'} className={classes.subItemLink}>
                    <ListItemText primary={subMenuTitle} className={classes.itemText} />
                  </Link>
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default TopMenuItem;
