import React, { useContext } from 'react';
import BacksolveContext from 'context/BacksolveContext';
import CaptableSecurities from 'pages/Allocation/allocations/components/backsolve-ledger/CaptableSecurities';

const SecurityGridSelect = props => {
  const { securityList } = useContext(BacksolveContext);
  return <CaptableSecurities menuPosition="fixed" securityList={securityList} {...props} />;
};

export default SecurityGridSelect;
