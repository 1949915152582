import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { CAP_TABLE_CURRENCY_PAGE } from 'common/constants/currencyPageTypes/currencyPageTypes';
import { useFormat } from 'common/hooks';
import { LedgerTable } from 'components';
import { LedgerDialog } from 'components/Dialogs';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { cellsParser, reverseCellsParser } from 'components/LedgerTable/utilities/ledgerParser';
import CurrencyAdjustedValueViewer from 'components/Spreadsheet/components/CurrencyAdjustedValueViewer';
import FundOwnershipContext from 'context/FundOwnershipContext';
import { AMOUNT_ALIAS } from 'pages/CapTable/fund-ownership/components/shares-ledger/constants';
import useExchangeRateMap from 'pages/CapTable/fund-ownership/components/shares-ledger/hooks/useExchangeRateMap';
import useFundOwnershipFundCell from 'pages/CapTable/fund-ownership/components/shares-ledger/hooks/useFundOwnershipFundCell';
import { useTableValidation } from 'services/hooks';
import slashedToIsoFormat from 'utillities/slashedToIsoFormat';
import colConfig from './colConfig';
import { reverseParser } from '../../utilities';
import updateAcquisitionsFromDialogToCells from '../../utilities/updateAcquisitionsFromDialogToCells';

const emptyRow = { purchase_date: null, distribution_date: null, amount: null };

const MultipleInvestmentDates = ({ cell, closeDialog }) => {
  const { cells, columns, onCellsChanged } = useContext(FeaturedSpreadsheetContext);

  const existingAcquisitions = useMemo(
    () =>
      cell.acquisitions.map(acquisition => {
        const tempRef = uuid();
        const cashDistributions = acquisition.cash_distributions.map(distr => ({
          ...distr,
          acquisition_ref: tempRef,
          distribution_ref: uuid(),
        }));
        return {
          ...acquisition,
          cash_distributions: cashDistributions,
          deleted_cash_distributions: acquisition.deleted_cash_distributions ?? [],
          acquisition_ref: tempRef,
        };
      }),
    [cell.acquisitions]
  );

  const startingDistributions = useMemo(() => {
    if (existingAcquisitions.length > 0) {
      const distributions = existingAcquisitions.map(acquisition => acquisition.cash_distributions).flat();
      return distributions.map(distr => ({
        ...distr,
        purchase_date: existingAcquisitions.find(acq => acq.acquisition_ref === distr.acquisition_ref)?.purchase_date,
        id: distr.id ?? 0,
      }));
    }
    return [];
  }, [existingAcquisitions]);

  const [rows, setRows] = useState(startingDistributions);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [ledgerCells, setLedgerCells] = useState();
  const { validateTable } = useTableValidation();
  const { isDisabled, fundList, selectedMeasurementDate } = useContext(FundOwnershipContext);
  const [format] = useFormat({ page: CAP_TABLE_CURRENCY_PAGE, units: REGULAR_UNIT });

  const disabledCondition = isDisabled || existingAcquisitions.length === 0;

  const fund = useFundOwnershipFundCell({ cells, cell, fundList });

  const isDifferentCurrency = useMemo(() => fund?.currency && fund.currency !== format.currency.code, [fund, format]);

  const dataSourceKey = useMemo(() => cell.linkedCells.values().next().value.key, [cell.linkedCells]);

  // Reverse parse the cells
  const investmentDates = useMemo(() => {
    if (!isEmpty(ledgerCells) && !isEmpty(rows)) {
      return reverseCellsParser(rows, ledgerCells);
    }
    return [];
  }, [rows, ledgerCells]);

  const config = useMemo(() => colConfig({ acquisitions: existingAcquisitions }), [existingAcquisitions]);

  const { exchangeRateMap, linkedCellChanges } = useExchangeRateMap({
    fundCurrency: fund?.currency,
    format,
    shares: investmentDates,
    selectedMeasurementDate,
    isDifferentCurrency,
    cellKeys: [AMOUNT_ALIAS],
    dateProp: 'distribution_date',
  });

  const addRow = useCallback(() => {
    const oldestAcquisition = existingAcquisitions.reduce((acc, curr) =>
      acc.purchase_date < curr.purchase_date ? acc : curr
    );
    const newRow = {
      ...emptyRow,
      purchase_date: oldestAcquisition.purchase_date,
      distribution_ref: uuid(),
      acquisition_ref: oldestAcquisition.acquisition_ref,
    };
    setRows([...investmentDates, newRow]);
  }, [existingAcquisitions, investmentDates]);

  const deleteRow = rowIndex => {
    const tmpRows = [...rows];
    const removed = tmpRows.splice(rowIndex, 1)[0];
    if (removed.id) {
      // see which place the deleted distribution occupied in the acquisitions distributions
      const acquisition = existingAcquisitions.find(acq => acq.acquisition_ref === removed.acquisition_ref);
      const distributionIndex = acquisition.cash_distributions.findIndex(
        distr => distr.distribution_ref === removed.distribution_ref
      );
      acquisition.deleted_cash_distributions.push(acquisition.cash_distributions[distributionIndex].id);
    }
    setRows(tmpRows);
  };

  const save = () => {
    // validateTable
    const cellsToValidate = Object.entries(ledgerCells).map(([, cell]) => cell);
    const { areCellsValid } = validateTable({
      cellsToValidate,
      toggleAlert: false,
    });

    if (areCellsValid) {
      const tmpState = { ...cells };

      // Save array in the data source field
      const updatedDistributions = investmentDates.map(distr => ({
        ...distr,
        distribution_date: slashedToIsoFormat({ label: distr.distribution_date }),
      }));
      tmpState[dataSourceKey].value = updatedDistributions;

      const totalDistributions
        = investmentDates?.map(({ amount }) => Number(amount)).reduce((acc, curr) => acc + curr, 0) ?? 0;
      // Save the total distributions in the current field
      tmpState[cell.key].value = totalDistributions;

      // update the acquisitions.cash_distributions prop
      const updatedAcquisitions = existingAcquisitions.map(acquisition => {
        const acquisitionDistributions = updatedDistributions.filter(
          distr => distr.acquisition_ref === acquisition.acquisition_ref
        );
        if (acquisition.id) {
          acquisitionDistributions.forEach(distr => {
            if (distr.id) {
              // eslint-disable-next-line no-param-reassign
              distr.acquisition = acquisition.id;
            }
          });
        }
        return { ...acquisition, cash_distributions: acquisitionDistributions };
      });
      updateAcquisitionsFromDialogToCells({
        fundOwnershipDetails: columns,
        reverseParsedAcquisitions: updatedAcquisitions,
        cell,
        startingAcquisitions: existingAcquisitions,
        deletedAcquisitions: cell.deleted_acquisitions,
      });
      onCellsChanged([{ cell, value: totalDistributions.toString() }]);

      reverseParser({
        cells: tmpState,
        columns,
      });
      closeDialog();
    } else {
      setIsAlertVisible(true);
    }
  };

  // Parse cells
  useEffect(() => {
    if (Array.isArray(rows)) {
      const parsedCells = cellsParser(rows, config);
      Object.values(parsedCells).forEach(cell => {
        if (cell.alias === AMOUNT_ALIAS && isDifferentCurrency) {
          // eslint-disable-next-line no-param-reassign
          cell.valueViewer = CurrencyAdjustedValueViewer;
          // eslint-disable-next-line no-param-reassign
          cell.relatedDate = parsedCells[`distribution_date${cell.rowNumber + 1}`].value;
        }
      });
      setLedgerCells(parsedCells);
    }
  }, [rows, isDifferentCurrency, config]);

  return (
    <LedgerDialog
      id="cash-distribution-ledger"
      title="Cash Distribution"
      onSave={save}
      onClose={closeDialog}
      disabled={cell.isDisabled}
      showDeleteColumn>
      {ledgerCells && (
        <LedgerTable
          cell={cell}
          colConfig={config}
          rows={rows}
          addRow={addRow}
          deleteRow={deleteRow}
          cells={ledgerCells}
          setCells={setLedgerCells}
          data={cells[dataSourceKey].value}
          disabled={disabledCondition}
          emptyRow={emptyRow}
          tableTerms={{
            tableName: 'Cash Distribution',
            tableSlug: 'cash-distribution-ledger',
            columnName: 'Cash Distribution',
            pluralColumnName: 'Cash Distributions',
          }}
          isAlertVisible={isAlertVisible}
          setIsAlertVisible={setIsAlertVisible}
          currency={format.currency}
          currencyCode={format.currency.code}
          exchangeRateMap={exchangeRateMap}
          linkedCellUpdates={linkedCellChanges}
        />
      )}
    </LedgerDialog>
  );
};

MultipleInvestmentDates.propTypes = {
  cell: PropTypes.object,
  closeDialog: PropTypes.func,
};

export default MultipleInvestmentDates;
