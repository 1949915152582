import { smallCurrencyFormat } from 'common/formats/formats';
import {
  ENTERPISE_VALUE_TITLE,
  IMPLIED_EQUITY_VALUE_ALIAS,
  IMPLIED_EQUITY_VALUE_TITLE,
  SUMMARY_ENTERPRISE_VALUE_ALIAS,
} from 'pages/Valuations/approaches/backsolveApproach/BacksolveSummaryTable/util/constants';
import {
  SHEET_ALIASES_CONSTANTS,
  VALUATION_SUMMARY_KEY,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary';

const { VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY, VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY }
  = SHEET_ALIASES_CONSTANTS;

export default [
  {
    ignoreRowCopy: true,
    largeHeader: true,
  },
  {
    value: IMPLIED_EQUITY_VALUE_TITLE,
    alias: IMPLIED_EQUITY_VALUE_ALIAS,
    rowNumber: 2,
    readOnly: true,
    dbType: 'string',
    ignoreRowCopy: true,
    gridType: 'number',
    format: smallCurrencyFormat,
  },
  {
    value: ENTERPISE_VALUE_TITLE,
    alias: SUMMARY_ENTERPRISE_VALUE_ALIAS,
    rowNumber: 3,
    readOnly: true,
    dbType: 'string',
    ignoreRowCopy: true,
    gridType: 'number',
    expr: `=B2 - ${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY} + ${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY}`,
    format: smallCurrencyFormat,
  },
];
