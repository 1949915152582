import React, { FC, useState } from 'react';
import { Grid } from '@material-ui/core';
import {
  ExitEquityValueTextField,
  ExitEquityValueTextFieldProps,
  WaterfallCalculatorTable,
  WaterfallCalculatorTableProps,
} from './components';
import { useWaterfallCalculatorStyles } from './styles';
import { IWaterfallCalculatorProps } from './types';

const WaterfallCalculator: FC<IWaterfallCalculatorProps> = props => {
  const [exitEquityValue, setExitEquityValue] = useState<string>('');

  const classes = useWaterfallCalculatorStyles();

  const waterfallCalculatorTableProps: WaterfallCalculatorTableProps = {
    ...props,
    exitEquityValue,
  };

  const exitEquityValueTextFieldProps: ExitEquityValueTextFieldProps = {
    exitEquityValue,
    setExitEquityValue,
  };

  return (
    <Grid container direction="column" alignItems="stretch" className={classes.mainContainer}>
      <ExitEquityValueTextField {...exitEquityValueTextFieldProps} />
      <WaterfallCalculatorTable {...waterfallCalculatorTableProps} />
    </Grid>
  );
};

export default WaterfallCalculator;
