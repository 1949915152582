import React from 'react';
import { Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { DeleteOutline as DeleteIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import CustomTextField from 'components/CustomTextField';
import { useFormValidation } from 'services/hooks/useFormValidation';
import theme from 'theme';

const constraints = {
  assigned_name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 3, maximum: 100, message: 'must be at least 3 and less than 100 characters' },
  },
  assigned_last_name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 3, maximum: 100, message: 'must be at least 3 and less than 100 characters' },
  },
  assigned_email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
};

const RequestUserInfo = ({ contact, formValues, setFormValues }) => {
  const { formValidated: formState, validateForm } = useFormValidation(constraints);
  const { assigned_name: assignedName, assigned_last_name: assignedLastName, assigned_email: assignedEmail } = contact;
  const { contacts } = formValues;
  const contactIndex = contacts.findIndex(contactParam => contactParam === contact);

  const removeContact = () => {
    setFormValues({
      ...formValues,
      contacts: contacts.filter(contactParam => contactParam !== contact),
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      contacts: contacts.map((contactParam, index) => {
        if (index === contactIndex) {
          return {
            ...contactParam,
            [name]: value,
          };
        }
        return contactParam;
      }),
    });
  };

  const handleBlur = () => {
    validateForm(formValues.contacts[contactIndex]);
  };

  const customTextFieldProps = {
    onChange: e => handleChange(e),
    onBlur: handleBlur,
    formErrors: formState.errors,
    required: true,
    variant: 'standard',
    fullWidth: true,
  };

  return (
    <Grid container style={{ marginBottom: '1rem' }} spacing={2}>
      {contacts.length > 1 && (
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            style={{
              fontWeight: 'bold',
              color: theme.palette.gray[400],
            }}>
            Assigned collaborator {contactIndex + 1}
          </Typography>
          <IconButton
            style={{
              color: `${theme.palette.gray[500]}`,
              marginLeft: 0,
              opacity: 0.3,
            }}
            onClick={removeContact}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <CustomTextField
          id="user-requested-name"
          type="text"
          name="assigned_name"
          label="Name"
          value={assignedName}
          {...customTextFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomTextField
          id="user-requested-last-name"
          type="text"
          name="assigned_last_name"
          label="Last Name"
          value={assignedLastName}
          {...customTextFieldProps}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          id="user-requested-email"
          type="email"
          name="assigned_email"
          label="Email"
          value={assignedEmail}
          {...customTextFieldProps}
        />
      </Grid>
      {contacts.length > 1 && <Divider style={{ width: '100%', marginTop: '1rem' }} />}
    </Grid>
  );
};

RequestUserInfo.propTypes = {
  contact: PropTypes.shape({
    assigned_name: PropTypes.string,
    assigned_last_name: PropTypes.string,
    assigned_email: PropTypes.string,
  }),
  setFormValues: PropTypes.func,
  formValidated: PropTypes.shape({
    isValid: PropTypes.bool,
    errors: PropTypes.shape({
      contacts: PropTypes.arrayOf(
        PropTypes.shape({
          assigned_name: PropTypes.string,
          assigned_last_name: PropTypes.string,
          assigned_email: PropTypes.string,
        })
      ),
    }),
  }),
  formValues: PropTypes.shape({
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        assigned_name: PropTypes.string,
        assigned_last_name: PropTypes.string,
        assigned_email: PropTypes.string,
      })
    ),
  }),
  validateForm: PropTypes.func,
  formState: PropTypes.shape({
    isValid: PropTypes.bool,
    errors: PropTypes.shape({
      contacts: PropTypes.arrayOf(
        PropTypes.shape({
          assigned_name: PropTypes.string,
          assigned_last_name: PropTypes.string,
          assigned_email: PropTypes.string,
        })
      ),
    }),
  }),
};

export default RequestUserInfo;
