import { ALLOCATIONS_DRAFT, ALLOCATIONS_FINAL, ALLOCATIONS_PUBLISHED } from 'common/constants/allocations';
import { GetAllocationStatusParams } from './types';

const getAllocationStatus = (params: GetAllocationStatusParams) => {
  const { isPublished, isFinal } = params;

  if (isFinal) return ALLOCATIONS_FINAL;

  if (isPublished) return ALLOCATIONS_PUBLISHED;

  return ALLOCATIONS_DRAFT;
};

export default getAllocationStatus;
