import updateYear from 'pages/Valuations/approaches/discountCashFlow/utilities/updateYear';
import { ValuationApproachWithReferences } from 'pages/ValuationsAllocation/types';
import { generateDCFFinancialPeriods } from 'pages/ValuationsAllocation/util/generateDCFFinancialPeriods';
import { getArrayValue, getNumberValue, getObjectValue } from 'utillities';
import { UpdateDCFPeriodsByVersionParams } from './types';

const updateDCFPeriodsByVersion = (params: UpdateDCFPeriodsByVersionParams) => {
  const { currentApproach, otherFinancialStatements, primaryFinancialStatement, updatedApproach } = params;

  let approachToUpdate = { ...updatedApproach } as ValuationApproachWithReferences;
  const { financial_statement: approachFinancialStatement } = getObjectValue(updatedApproach);

  if (approachFinancialStatement) {
    // Updated DCF Approach
    const { valuations_approach_dcf: valuationsApproachDCF } = getObjectValue(approachToUpdate);
    const { deleted_dcf_financial_periods: deletedDCFFinancialPeriods = [] } = getObjectValue(valuationsApproachDCF);

    // Current DCF Approach
    const { financial_statement: currentFinancialStatement, valuations_approach_dcf: currentValuationsApproachDCF }
      = getObjectValue(currentApproach);
    const { dcf_financial_period: currentDCFFinancialPeriod = [] } = getObjectValue(currentValuationsApproachDCF);

    const isCurrentDCFApproach = approachFinancialStatement === currentFinancialStatement;

    // Financial Statements
    const allFinancialStatements = [primaryFinancialStatement, ...getArrayValue(otherFinancialStatements)].filter(
      Boolean
    );
    const matchedFinancialStatement = allFinancialStatements?.find(
      financial => financial?.id === approachFinancialStatement
    );

    // DCF Financial Periods
    const generatedDCFPeriods = isCurrentDCFApproach
      ? currentDCFFinancialPeriod
      : generateDCFFinancialPeriods(matchedFinancialStatement?.fiscal_years);

    generatedDCFPeriods?.forEach(updateYear);

    // Deleted DCF Financial Periods
    const previousDCFFinancialPeriods = currentDCFFinancialPeriod
      ?.map(DCFfinancialPeriod => getNumberValue(DCFfinancialPeriod?.id))
      ?.filter(Boolean);

    const DCFFinancialPeriodsToDelete = isCurrentDCFApproach
      ? deletedDCFFinancialPeriods
      : [...new Set([...previousDCFFinancialPeriods, ...deletedDCFFinancialPeriods])];

    approachToUpdate = {
      ...approachToUpdate,
      valuations_approach_dcf: {
        ...valuationsApproachDCF,
        dcf_financial_period: generatedDCFPeriods,
        deleted_dcf_financial_periods: DCFFinancialPeriodsToDelete,
      },
    } as ValuationApproachWithReferences;
  }

  return approachToUpdate;
};

export default updateDCFPeriodsByVersion;
