import React from 'react';
import PropTypes from 'prop-types';
import { DOCUMENTS_URL_PARAM, QUESTIONS_URL_PARAM } from 'common/constants/process-management';
import ProcessManagementQuestions from 'pages/ProcessManagementQuestions/ProcessManagementQuestions';
import RequestDocuments from 'pages/RequestDocuments';

const ProcessManagementPortal = ({ processType }) => {
  const processTypeOptions = {
    [DOCUMENTS_URL_PARAM]: RequestDocuments,
    [QUESTIONS_URL_PARAM]: ProcessManagementQuestions,
  };

  const ProcessTypeComponent = processTypeOptions[processType] || null;

  return <>{ProcessTypeComponent && <ProcessTypeComponent />}</>;
};

ProcessManagementPortal.propTypes = {
  processType: PropTypes.string,
};

export default ProcessManagementPortal;
