/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  findScalarOpinion as findScalarOpinionService,
  getScalarOpinion as getScalarOpinionService,
} from 'dashboard409a/services/dashboard';
import { IFindValuationScalarOpinionResponse, IGetValuationScalarOpinionsResponse } from 'dashboard409a/services/types';
import { IValuationState } from './types';

const initialState: IValuationState = {
  loading: false,
  allValuationScalarOpinion: null,
  currentValuationScalarOpinion: null,
};

export const getValuationScalarOpinion = createAsyncThunk<IGetValuationScalarOpinionsResponse[], number>(
  'valuation/getValuationScalarOpinion',
  async (companyId, thunkAPI) => {
    try {
      return await getScalarOpinionService(companyId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const findValuationScalarOpinion = createAsyncThunk<IFindValuationScalarOpinionResponse, number>(
  'valuation/findValuationScalarOpinion',
  async (dateId, thunkAPI) => {
    try {
      return await findScalarOpinionService(dateId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const valuationSlice = createSlice({
  name: 'valuation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getValuationScalarOpinion.pending, state => {
      state.loading = true;
    });
    builder.addCase(getValuationScalarOpinion.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.allValuationScalarOpinion = payload;
    });
    builder.addCase(getValuationScalarOpinion.rejected, state => {
      state.loading = false;
    });
    builder.addCase(findValuationScalarOpinion.pending, state => {
      state.loading = true;
    });
    builder.addCase(findValuationScalarOpinion.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.currentValuationScalarOpinion = payload;
    });
    builder.addCase(findValuationScalarOpinion.rejected, state => {
      state.loading = false;
    });
  },
});

export default valuationSlice.reducer;
