/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ChildUpdateCompanyQuestion } from './ChildUpdateCompanyQuestion';

export type UpdateCompanyQuestion = {
  readonly id?: number;
  name: string;
  question_type?: UpdateCompanyQuestion.question_type;
  options?: Array<string>;
  company: number;
  description?: string | null;
  delete_children?: boolean;
  children_questions?: Array<ChildUpdateCompanyQuestion>;
};

export namespace UpdateCompanyQuestion {
  export enum question_type {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }
}
