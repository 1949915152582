import React, { FC, useCallback, useMemo } from 'react';
import { Button } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { fundOwnershipUrl } from 'common/config/urls';
import { ValuationsParams } from 'pages/ValuationsAllocation/types';
import { getStringValue } from 'utillities';
import { RedirectToFundOwnershipButtonProps } from './types';

const ADD_OWNERSHIP_LABEL = 'Add Ownership';

const RedirectToFundOwnershipButton: FC<RedirectToFundOwnershipButtonProps> = params => {
  const { measurementDateSlug } = params;

  const history = useHistory();

  const { firmSlugParam, companySlugParam } = useParams<ValuationsParams>();

  const financialPageUrl = useMemo(
    () => fundOwnershipUrl(getStringValue(firmSlugParam), getStringValue(companySlugParam)),
    [companySlugParam, firmSlugParam]
  );

  const redirectToFundOwnership = useCallback(() => {
    history.push(`${financialPageUrl}?date=${measurementDateSlug}`);
  }, [financialPageUrl, history, measurementDateSlug]);

  return (
    <Button color="primary" endIcon={<ArrowBackIcon />} onClick={redirectToFundOwnership} variant="outlined">
      {ADD_OWNERSHIP_LABEL}
    </Button>
  );
};

export default RedirectToFundOwnershipButton;
