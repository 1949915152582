import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    messageContainer: {
      marginTop: theme.spacing(1.5),
      textAlign: 'right',
    },
  })
);

export default useStyles;
