const reverseParser = ({ cells, initialObj }) => {
  const tmpApproach = { ...initialObj.valuations_approach_ssv };
  Object.values(cells).forEach(({ alias, value, }) => {
    if (alias) {
      tmpApproach[alias] = value;
    }
  });
  return {
    ...initialObj,
    enterprise_value: tmpApproach.enterprise_value,
    valuations_approach_ssv: tmpApproach
  };
};

export default reverseParser;
