import React, { useEffect, useRef } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { IconTextField } from 'components/IconTextField';

const FirmFilter = ({ setFilterSearch }) => {
  const inputRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current]);

  const handleChange = e => {
    e.stopPropagation();
    const text = e.target.value?.toLowerCase() || '';
    setFilterSearch(text);
  };

  return (
    <div>
      <IconTextField
        inputRef={inputRef}
        id="company-filter"
        onChange={handleChange}
        placeholder="Find a Firm"
        type="text"
        fullWidth
        variant="outlined"
        size="small"
        iconEnd={<SearchIcon />}
      />
    </div>
  );
};

FirmFilter.propTypes = {
  setFilterSearch: PropTypes.func.isRequired,
};

export default FirmFilter;
