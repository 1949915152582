import { FC } from 'react';
import { Grid, GridProps, TextField, TextFieldProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const FormTextField = withStyles(() => ({
  root: {
    marginBottom: 20,
  },
}))(TextField) as FC<TextFieldProps>;

export const FormContainer = withStyles(() => ({
  root: {
    padding: 10,
  },
}))(Grid) as FC<GridProps>;
