import { SYSTEM_COLLAPSE } from 'common/actions/row-groups/types';
import { sum } from 'common/formulas/generator';

export const CASH_AND_EQUIVALENTS_TITLE = 'Cash and Equivalents';
export const CASH_AND_EQUIVALENTS_ALIAS = 'total_cash_equivalents';
export const CASH_AND_EQUIVALENTS_ROW_NUMBER = 4;

export const ASSETS_ACCOUNTS_RECEIVABLE_TITLE = 'Accounts Receivable';
export const ASSETS_ACCOUNTS_RECEIVABLE_ALIAS = 'assets_accounts_receivable';
export const ASSETS_ACCOUNTS_RECEIVABLE_ROW_NUMBER = 5;

export const ASSETS_INVENTORY_TITLE = 'Inventory';
export const ASSETS_INVENTORY_ALIAS = 'assets_inventory';
export const ASSETS_INVENTORY_ROW_NUMBER = 6;

export const ASSETS_OTHER_CURRENT_TITLE = 'Other Current Assets';
export const ASSETS_OTHER_CURRENT_ALIAS = 'assets_other_current';
export const ASSETS_OTHER_CURRENT_ROW_NUMBER = 7;

export const ASSETS_TOTAL_CURRENT_TITLE = 'Total Current Assets';
export const ASSETS_TOTAL_CURRENT_ALIAS = 'assets_total_current';
export const ASSETS_TOTAL_CURRENT_ROW_NUMBER = 8;
export const ASSETS_TOTAL_CURRENT_EXPR = sum([
  CASH_AND_EQUIVALENTS_ROW_NUMBER,
  ASSETS_ACCOUNTS_RECEIVABLE_ROW_NUMBER,
  ASSETS_INVENTORY_ROW_NUMBER,
  ASSETS_OTHER_CURRENT_ROW_NUMBER,
]);

export const ASSETS_PPE_TITLE = 'PPE';
export const ASSETS_PPE_ALIAS = 'assets_ppe';
export const ASSETS_PPE_ROW_NUMBER = 9;

export const ASSETS_INTANGIBLES_TITLE = 'Intangibles';
export const ASSETS_INTANGIBLES_ALIAS = 'assets_intangibles';
export const ASSETS_INTANGIBLES_ROW_NUMBER = 10;

export const ASSETS_OTHER_LONG_TERM_TITLE = 'Other Long Term Assets';
export const ASSETS_OTHER_LONG_TERM_ALIAS = 'assets_other_long_term';
export const ASSETS_OTHER_LONG_TERM_ROW_NUMBER = 11;

export const ASSETS_TOTAL_LONG_TERM_TITLE = 'Total Long Term Assets';
export const ASSETS_TOTAL_LONG_TERM_ALIAS = 'assets_total_long_term';
export const ASSETS_TOTAL_LONG_TERM_ROW_NUMBER = 12;
export const ASSETS_TOTAL_LONG_TERM_EXPR = sum([
  ASSETS_PPE_ROW_NUMBER,
  ASSETS_INTANGIBLES_ROW_NUMBER,
  ASSETS_OTHER_LONG_TERM_ROW_NUMBER,
]);

export const ASSETS_TOTAL_TITLE = 'Total Assets';
export const ASSETS_TOTAL_ALIAS = 'assets_total';
export const ASSETS_TOTAL_ROW_NUMBER = 13;
export const ASSETS_EXPR = sum([ASSETS_TOTAL_CURRENT_ROW_NUMBER, ASSETS_TOTAL_LONG_TERM_ROW_NUMBER]);

export const LIABILITIES_SHORT_TERM_DEBT_TITLE = 'Short Term Debt';
export const LIABILITIES_SHORT_TERM_DEBT_ALIAS = 'liabilities_short_term_debt';
export const LIABILITIES_SHORT_TERM_DEBT_ROW_NUMBER = 14;

export const LIABILITIES_ACCOUNT_PAYABLE_TITLE = 'Accounts Payable';
export const LIABILITIES_ACCOUNT_PAYABLE_ALIAS = 'liabilities_account_payable';
export const LIABILITIES_ACCOUNT_PAYABLE_ROW_NUMBER = 15;

export const LIABILITIES_ACCRUED_LIABILITIES_TITLE = 'Accrued Liabilities';
export const LIABILITIES_ACCRUED_LIABILITIES_ALIAS = 'liabilities_accrued_liabilities';
export const LIABILITIES_ACCRUED_LIABILITIES_ROW_NUMBER = 16;

export const LIABILITIES_DEFERRED_REVENUE_TITLE = 'Deferred Revenue';
export const LIABILITIES_DEFERRED_REVENUE_ALIAS = 'liabilities_deferred_revenue';
export const LIABILITIES_DEFERRED_REVENUE_ROW_NUMBER = 17;

export const LIABILITIES_OTHER_CURRENT_TITLE = 'Other Current Liabilities';
export const LIABILITIES_OTHER_CURRENT_ALIAS = 'liabilities_other_current';
export const LIABILITIES_OTHER_CURRENT_ROW_NUMBER = 18;

export const LIABILITIES_TOTAL_CURRENT_TITLE = 'Total Current Liabilities';
export const LIABILITIES_TOTAL_CURRENT_ALIAS = 'liabilities_total_current';
export const LIABILITIES_TOTAL_CURRENT_ROW_NUMBER = 19;
export const LIABILITIES_TOTAL_CURRENT_EXPR = sum([
  LIABILITIES_ACCOUNT_PAYABLE_ROW_NUMBER,
  LIABILITIES_ACCRUED_LIABILITIES_ROW_NUMBER,
  LIABILITIES_SHORT_TERM_DEBT_ROW_NUMBER,
  LIABILITIES_DEFERRED_REVENUE_ROW_NUMBER,
  LIABILITIES_OTHER_CURRENT_ROW_NUMBER,
]);

export const LIABILITIES_LONG_TERM_DEBT_TITLE = 'Long Term Debt';
export const LIABILITIES_LONG_TERM_DEBT_ALIAS = 'liabilities_long_term_debt';
export const LIABILITIES_LONG_TERM_DEBT_ROW_NUMBER = 20;

export const LIABILITIES_OTHER_LONG_TERM_TITLE = 'Other Long Term Liabilities';
export const LIABILITIES_OTHER_LONG_TERM_ALIAS = 'liabilities_other_long_term';
export const LIABILITIES_OTHER_LONG_TERM_ROW_NUMBER = 21;

export const LIABILITIES_TOTAL_LONG_TERM_TITLE = 'Total Long Term Liabilities';
export const LIABILITIES_TOTAL_LONG_TERM_ALIAS = 'liabilities_total_long_term';
export const LIABILITIES_TOTAL_LONG_TERM_ROW_NUMBER = 22;
export const LIABILITIES_TOTAL_LONG_TERM_EXPR = sum([
  LIABILITIES_LONG_TERM_DEBT_ROW_NUMBER,
  LIABILITIES_OTHER_LONG_TERM_ROW_NUMBER,
]);

export const LIABILITIES_TOTAL_TITLE = 'Total Liabilities';
export const LIABILITIES_TOTAL_ALIAS = 'liabilities_total';
export const LIABILITIES_TOTAL_ROW_NUMBER = 23;
export const LIABILITIES_TOTAL_EXPR = sum([
  LIABILITIES_TOTAL_CURRENT_ROW_NUMBER,
  LIABILITIES_TOTAL_LONG_TERM_ROW_NUMBER,
]);

export const EQUITY_TOTAL_TITLE = 'Equity';
export const EQUITY_TOTAL_ALIAS = 'equity_total';
export const EQUITY_TOTAL_ROW_NUMBER = 24;

export const TOTAL_LIABILITIES_AND_EQUITY_TITLE = 'Total Liabilities and Equity';
export const TOTAL_LIABILITIES_AND_EQUITY_ALIAS = 'liabilities_and_equity_total';
export const TOTAL_LIABILITIES_AND_EQUITY_NUMBER = 25;
export const TOTAL_LIABILITIES_AND_EQUITY_EXPR = sum([LIABILITIES_TOTAL_ROW_NUMBER, EQUITY_TOTAL_ROW_NUMBER]);

export const TOTAL_DEBT_TITLE = 'Debt (All Interest Bearing)';
export const TOTAL_DEBT_ALIAS = 'total_debt';
export const TOTAL_DEBT_ROW_NUMBER = 26;

export const ALIASES_TO_UPDATE_IN_BALANCE_SHEET = [
  CASH_AND_EQUIVALENTS_ALIAS,
  ASSETS_ACCOUNTS_RECEIVABLE_ALIAS,
  ASSETS_INVENTORY_ALIAS,
  ASSETS_OTHER_CURRENT_ALIAS,
  ASSETS_PPE_ALIAS,
  ASSETS_TOTAL_LONG_TERM_ALIAS,
  ASSETS_INTANGIBLES_ALIAS,
  ASSETS_OTHER_LONG_TERM_ALIAS,
  ASSETS_TOTAL_ALIAS,
  LIABILITIES_SHORT_TERM_DEBT_ALIAS,
  LIABILITIES_ACCOUNT_PAYABLE_ALIAS,
  LIABILITIES_ACCRUED_LIABILITIES_ALIAS,
  LIABILITIES_DEFERRED_REVENUE_ALIAS,
  LIABILITIES_OTHER_CURRENT_ALIAS,
  LIABILITIES_TOTAL_CURRENT_ALIAS,
  LIABILITIES_LONG_TERM_DEBT_ALIAS,
  LIABILITIES_OTHER_LONG_TERM_ALIAS,
  LIABILITIES_TOTAL_LONG_TERM_ALIAS,
  LIABILITIES_TOTAL_ALIAS,
  EQUITY_TOTAL_ALIAS,
  TOTAL_DEBT_ALIAS,
];

export const INITIAL_BALANCE_SHEET_STATE = {
  [CASH_AND_EQUIVALENTS_ALIAS]: SYSTEM_COLLAPSE,
  [LIABILITIES_LONG_TERM_DEBT_ALIAS]: SYSTEM_COLLAPSE,
  [LIABILITIES_SHORT_TERM_DEBT_ALIAS]: SYSTEM_COLLAPSE,
};
