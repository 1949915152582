/**
 * @function
 * @name setCompanyInfo
 * @description reducer for action.
 * @param {Object} state  - reducer state
 * @param {Object} action - action context
 * @return {object} Action context
 */

export const setCompanyInfo = (state, action) => ({
  ...state,
  companyInfo: {
    ...state.companyInfo,
    ...action.companyInfo
  }
});

export const setCapTableList = (state, action) => ({
  ...state,
  ...action
});

export const setFundList = (state, action) => ({
  ...state,
  ...action
});

export const setCompanyList = (state, action) => ({
  ...state,
  ...action
});
