/* eslint-disable spaced-comment,no-multi-spaces */
import {
  DISCOUNT_PERIODS_CONFIG,
  ESTIMATED_EXIT_VALUE_CONFIG,
  MULTIPLE_CONFIG,
  PV_OF_TERMINAL_VALUE_CONFIG_2,
  SELECT_MULTIPLE_CONFIG,
  TERMINAL_VALUE_CONFIG,
  TERMINAL_YEAR_EBITDA_CONFIG,
  WACC_CONFIG,
} from 'pages/Valuations/approaches/discountCashFlow/dcfTerminalValue/util/rowConfigConstants';
import { EBITDA_GPC_MULTIPLE, EBITDA_GPC_MULTIPLE_SELECTION, VALUES_OPTIONS } from 'pages/Valuations/util/constants';

export default ({
  mainTableName: mainTableReference,
  dcfWaccSheet: dcfWacc,
  companiesRows,
  percentileSelections,
  benchmarkType,
  options = VALUES_OPTIONS,
  isDisabled,
}) => {
  // This is the default value but some how if it is not included in the function call it cause an issue getting the value for the cell.
  const offset = 3;
  return [
    TERMINAL_VALUE_CONFIG(isDisabled),
    TERMINAL_YEAR_EBITDA_CONFIG(mainTableReference),
    SELECT_MULTIPLE_CONFIG(EBITDA_GPC_MULTIPLE_SELECTION, options, percentileSelections, isDisabled),
    MULTIPLE_CONFIG(EBITDA_GPC_MULTIPLE, companiesRows, options, benchmarkType, isDisabled, offset),
    ESTIMATED_EXIT_VALUE_CONFIG(2, 4),
    WACC_CONFIG(dcfWacc),
    DISCOUNT_PERIODS_CONFIG(mainTableReference),
    PV_OF_TERMINAL_VALUE_CONFIG_2(5, 6, 7),
  ];
};
