import { APPROACH_WEIGHTING_ALIAS } from 'common/constants/allocations';
import { getKey } from 'utillities';

export default (rows, cells, colConfig, tableId) => {
  const tmpRows = [];
  let finalValue = 0;

  rows
    .filter(row => !row.isCalibration)
    .forEach((row, rowIndex) => {
      const tmpCell = {};

      colConfig.forEach(col => {
        const key = getKey(col.alias, rowIndex, tableId);
        if (cells[key]) {
          if (col.alias === 'weightedEquityValue') {
            finalValue += cells[key].value;
          }
          tmpCell[col.alias] = col.alias === APPROACH_WEIGHTING_ALIAS ? Number(cells[key].value) : cells[key].value;
        }
      });
      tmpRows.push({
        ...row,
        ...tmpCell,
      });
    });
  return [tmpRows, finalValue];
};
