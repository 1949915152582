import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CircularProgressBar = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
      <br />
      {children || <p>Loading...</p>}
    </div>
  );
};

CircularProgressBar.propTypes = {
  children: PropTypes.any,
};

export default CircularProgressBar;
