import { range } from 'mathjs';
import { oneDecimalPercentFormatValidateFloatTrue } from 'common/formats/formats';
import { Row, Rows } from 'common/types/scalarSpreadsheet';
import { PERCENTILE_RANKING_TITLE } from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import {
  PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
  PERFORMANCE_METRICS_SPREADSHEET_HEADER_CLASSNAME,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics';
import { generateColumnKey, getArrayValue, getStringValue } from 'utillities';
import { ComparisonCompaniesParams, RowConfigParams } from './types';
import { getMedianExpression } from '../utils/utils';

const comparisonCompanies = (params: ComparisonCompaniesParams): Rows => {
  const { gpcComparison } = params;

  return getArrayValue(
    gpcComparison?.map(
      comparison =>
        ({
          alias: generateColumnKey({
            name: getStringValue(comparison?.cap_iq_id),
            prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
          }),
          gridType: 'string',
          ignoreRowCopy: true,
          readOnly: true,
          value: getStringValue(comparison?.company_name),
        } as Row)
    )
  );
};

const rowConfig = (params: RowConfigParams): Rows => {
  const { companyName, customClasses, valuationsApproachGpc } = params;
  const basedIndex = 1;
  const rangeStart = 1;
  const accountForTitleAndSubtitle = 2;
  const gpcComparison = getArrayValue(valuationsApproachGpc?.gpc_comparison);
  const gpcRange = range(rangeStart, gpcComparison.length + basedIndex)
    .map(rowNumber => `@${rowNumber + accountForTitleAndSubtitle}`) // +2 to account for the title and subtitle rows
    .toString()
    .replace(/"/g, '');

  return [
    {
      alias: SHEET_ALIASES_CONSTANTS.PERFORMANCE_METRICS_SPREADSHEET_HEADER_TITLE,
      className: `${PERFORMANCE_METRICS_SPREADSHEET_HEADER_CLASSNAME} ${customClasses?.titleAndSubtitles}`,
      gridType: 'string',
      ignoreRowCopy: true,
      isParentWithDivider: false,
      readOnly: true,
      rowSpan: 2,
      value: SHEET_TITLES_CONSTANTS.PERFORMANCE_METRICS_SPREADSHEET_HEADERS[0].value,
      isTitleOrHeader: true,
    } as Row,
    {
      alias: SHEET_ALIASES_CONSTANTS.PERFORMANCE_METRICS_SPREADSHEET_HEADER_SUBTITLE,
      className: `${PERFORMANCE_METRICS_SPREADSHEET_HEADER_CLASSNAME} ${customClasses?.titleAndSubtitles} table-header formatter-cell`,
      gridType: 'string',
      readOnly: true,
      value: '',
    } as Row,
    ...comparisonCompanies({ gpcComparison }),
    {
      alias: SHEET_ALIASES_CONSTANTS.PERFORMANCE_METRICS_SPREADSHEET_COMPANY,
      displayBold: true,
      className: 'bold-row',
      format: oneDecimalPercentFormatValidateFloatTrue,
      gridType: 'percentage',
      ignoreRowCopy: true,
      readOnly: true,
      value: companyName,
      expr: getMedianExpression(gpcRange),
    } as Row,
    {
      alias: SHEET_ALIASES_CONSTANTS.PERFORMANCE_METRICS_SPREADSHEET_RANK_ALIAS,
      hidden: false,
      gridType: 'percentage',
      format: oneDecimalPercentFormatValidateFloatTrue,
      value: PERCENTILE_RANKING_TITLE,
      forceComponent: true,
      readOnly: true,
      valueViewer: null,
      dataEditor: null,
      isVolatility: null,
      dropdown: false,
      useScalarSpreadsheetCell: false,
      options: null,
    } as Row,
  ];
};

export default rowConfig;
