import React from 'react';
import theme from 'theme';

const VisaIcon = () => (
  <svg width="55" height="40" viewBox="0 0 34 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.4 0.5C0.626955 0.5 0 1.12735 0 1.90127V21.0987C0 21.8726 0.62727 22.5 1.4 22.5H32.6C33.373 22.5 34 21.8727 34 21.0987V1.90127C34 1.12739 33.3727 0.5 32.6 0.5H1.4ZM20.9037 7.05642C21.7556 7.05642 22.4383 7.24475 22.8736 7.41988L22.5761 9.29299L22.3792 9.19336C21.9737 9.01824 21.4528 8.8497 20.7342 8.86165C19.8741 8.86165 19.4764 9.2457 19.4764 9.60502C19.4714 10.0099 19.941 10.2769 20.709 10.6768C21.9766 11.2937 22.5625 12.0417 22.5542 13.025C22.5372 14.8192 21.0379 15.9786 18.7283 15.9786C17.7429 15.9678 16.7936 15.759 16.2805 15.5177L16.5889 13.5822L16.8722 13.7201C17.5938 14.0429 18.0611 14.1734 18.9405 14.1734C19.572 14.1734 20.2498 13.9087 20.2552 13.3293C20.2593 12.951 19.9718 12.6812 19.1165 12.2576C18.2831 11.844 17.1782 11.1514 17.1905 9.90934C17.2035 8.22918 18.7327 7.05642 20.9037 7.05642ZM3.09529 7.33121H6.64234C7.11998 7.34929 7.50561 7.50322 7.63875 8.022L8.40329 11.9718V11.974L8.63296 13.1575L10.7865 7.33121H13.1163L9.65342 15.8593L7.32592 15.8615L5.47312 8.97988C6.57555 9.56446 7.51411 10.2408 8.05766 11.1716C7.91752 10.8771 7.73287 10.5446 7.49658 10.217C7.22141 9.83539 6.62714 9.34274 6.37875 9.13314C5.5132 8.40283 4.33773 7.813 3.06796 7.5009L3.09529 7.33121ZM14.0427 7.34105H16.321L14.8958 15.856H12.6175L14.0427 7.34105ZM26.9106 7.34105H28.6366L30.4435 15.856H28.3719C28.3719 15.856 28.1664 14.8778 28.0995 14.5795C27.7739 14.5795 25.4967 14.5763 25.2405 14.5763C25.1538 14.8068 24.7702 15.856 24.7702 15.856H22.4263L25.7414 8.04828C25.9761 7.49344 26.3762 7.34105 26.9106 7.34105ZM27.0802 9.63126C26.9681 9.94798 26.7729 10.4594 26.7859 10.437C26.7859 10.437 26.0846 12.3091 25.9011 12.7951L27.744 12.794C27.5728 11.982 27.4015 11.1701 27.23 10.3582L27.0802 9.63126Z"
      fill={theme.palette.gray[300]}
    />
  </svg>
);

export default VisaIcon;
