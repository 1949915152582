import { ValuationApproachFutureExit } from 'api';
import { ValuationsApproach } from 'common/types/valuation';

export const getApproachFromColumn = (col: ValuationApproachFutureExit, approaches: Array<ValuationsApproach>) => {
  if (col.valuation_approach_gpc) {
    return approaches.find(a => a.valuations_approach_gpc?.id === col.valuation_approach_gpc);
  }
  if (col.valuation_approach_gpt) {
    return approaches.find(a => a.valuations_approach_gpt?.id === col.valuation_approach_gpt);
  }
  return null;
};
