/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-tabs */
import React, { useCallback, useState } from 'react';
import { Button, IconButton, InputAdornment, InputLabel, Link, Paper, TextField, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward as GoIcon, Visibility, VisibilityOff } from '@material-ui/icons';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import validate from 'validate.js';
import { ERROR_401, ERROR_404, ERROR_429 } from 'common/config/api';
import { FORGOT_RESET_PASSWORD } from 'common/constants/login';
import * as messages from 'common/constants/messages/validations';
import { logIn } from 'common/constants/paths';
import { emailValidatorBase } from 'common/constants/userSchemaBase';
import { Alert } from 'components';
import PMActionButtons from 'pages/ProcessManagement/components/PMActionButtons';
import { useGetAccessTokens } from 'services/hooks/auth';
import { differentThanValidator, excludeWhiteSpacesValidator } from 'utillities';
import SocialLogin from './components/SocialLogin';

validate.validators.excludeWhiteSpaces = excludeWhiteSpacesValidator;
validate.validators.differentThan = differentThanValidator;

const constraints = {
  mail: {
    presence: {
      allowEmpty: false,
      message: messages.IS_REQUIRED,
    },
    length: {
      maximum: 128,
      minimum: 5,
      message: messages.REQUIRE_CHARACTERS(5),
    },
    ...emailValidatorBase,
  },
  password: {
    presence: {
      allowEmpty: false,
      message: messages.IS_REQUIRED,
    },
    length: {
      maximum: 128,
      minimum: 9,
      message: messages.REQUIRE_CHARACTERS(9),
    },
    type: {
      type: 'string',
      message: messages.REQUIRE_ONE_LETTER,
    },
    excludeWhiteSpaces: {
      message: messages.HAS_WHITE_SPACES,
    },
    differentThan: {
      field: 'mail',
      message: messages.SIMILAR_TO('user name'),
    },
  },
};

const defaultErrorMessage = messages.FIX_BEFORE_CONTINUE;

const useStyles = makeStyles(theme => ({
  form: isProcessManagementForm => ({
    width: '100%',
    maxWidth: 600,
    boxSizing: 'border-box',
    padding: isProcessManagementForm ? '0' : theme.spacing(3),
    backgroundColor: isProcessManagementForm ? 'transparent' : '',

    '& h2': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      fontSize: '1.125rem',
    },
    '& .scar-textField': {
      marginBottom: theme.spacing(2),
    },
    // Hide default password reveal on Edge
    '& input::-ms-reveal': {
      display: 'none',
    },
  }),
  btn: {
    marginTop: theme.spacing(1),
    fontSize: '0.875rem',
  },
  link: {
    marginTop: theme.spacing(1),
    display: 'block',
    cursor: 'pointer',
  },
  signupRow: {
    position: 'absolute',
    bottom: '3em',
  },
  signupLink: {
    marginLeft: '2em',
  },
  text: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const LogInForm = ({
  location,
  history,
  setPasswordRecovery,
  isProcessManagementForm = false,
  isLogInFormVisible = true,
  setIsLogInFormVisible = () => {},
}) => {
  const classes = useStyles(isProcessManagementForm);
  const getAccessTokens = useGetAccessTokens();
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const hasError = field => !!formState.touched[field] && !!formState.errors[field] && !!formState.errors[field].length;

  const handleLogIn = async event => {
    try {
      event.preventDefault();
      setIsAlertVisible(false);

      if (!formState.isValid) {
        setIsAlertVisible(true);
        setErrorMessage(defaultErrorMessage);
        return;
      }

      setIsLoading(true);
      const { from } = location.state || { from: { pathname: logIn } };
      const callback = () => {
        if (location.pathname !== from.pathname) {
          history.replace(`${location.state.from.pathname}${location.state.from.search}`);
        }
      };
      const response = await getAccessTokens(formState.values.mail, formState.values.password, callback);

      const statusCode = response.statusCode?.toString() || ERROR_404;

      // 401 Unauthorized (wrong username/password), 429 Too Many Requests (over throttling limit)
      if (!response || [ERROR_401, ERROR_429].includes(statusCode)) {
        let errorMsg = messages.UNABLE_TO_LOGIN_TRY_AGAIN;
        switch (statusCode) {
          case ERROR_401:
            errorMsg = messages.WRONG_USERNAME_OR_PASSWORD;
            break;
          case ERROR_429:
            // Extract number of seconds until the endpoint is available again
            errorMsg = messages.TOO_MANY_REQUESTS(response.body.detail.match(/\d+/)[0]);
            break;
          default:
            break;
        }

        setIsAlertVisible(true);
        setErrorMessage(errorMsg);
      }
    } catch (error) {
      setIsAlertVisible(true);
      setErrorMessage(messages.UNABLE_TO_LOGIN_TRY_AGAIN);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = useCallback(
    event => {
      const tmpFormState = { ...formState };
      const fieldName = event.target.name;
      const fieldValue = event.target.value;
      const errors = validate({ ...formState.values, [fieldName]: fieldValue }, constraints);

      // Update the values
      tmpFormState.values[fieldName] = fieldValue;

      // Update the touched field
      tmpFormState.touched[fieldName] = true;

      // Update the errors
      tmpFormState.isValid = isUndefined(errors);
      tmpFormState.errors = errors || {};

      setFormState(tmpFormState);
    },
    [formState]
  );

  const forgotPasswordCallback = useCallback(() => {
    setPasswordRecovery(true);
  }, [setPasswordRecovery]);

  function handleSetIsLogInFormVisible() {
    setIsLogInFormVisible(true);
  }

  return (
    <>
      <Paper className={classes.form} elevation={isProcessManagementForm ? 0 : 1}>
        {!isProcessManagementForm && <Typography variant="h2">Login</Typography>}
        <Alert isAlertVisible={isAlertVisible}>
          <>{errorMessage}</>
        </Alert>
        <form onSubmit={handleLogIn} data-testid="login-form">
          {isProcessManagementForm && isLogInFormVisible && (
            <InputLabel style={{ marginBottom: '0.5em' }}>Username</InputLabel>
          )}
          {isLogInFormVisible && (
            <TextField
              id="login-username-input"
              name="mail"
              label={isProcessManagementForm ? '' : 'User'}
              fullWidth
              type="text"
              className="scar-textField"
              error={isAlertVisible && hasError('mail')}
              required
              helperText={isAlertVisible && hasError('mail') ? formState.errors.mail[0] : null}
              onChange={handleChange}
              variant={isProcessManagementForm ? 'outlined' : 'standard'}
            />
          )}
          {isProcessManagementForm && isLogInFormVisible && (
            <InputLabel style={{ marginBottom: '0.5em' }}>Password</InputLabel>
          )}
          {isLogInFormVisible && (
            <TextField
              id="login-password-input"
              name="password"
              label={isProcessManagementForm ? '' : 'Password'}
              fullWidth
              type={showPassword ? 'text' : 'password'}
              className="scar-textField"
              error={isAlertVisible && hasError('password')}
              required
              helperText={isAlertVisible && hasError('password') ? formState.errors.password[0] : null}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      id="login-show-password-btn"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      size="small">
                      {showPassword ? <Visibility fontSize="inherit" /> : <VisibilityOff fontSize="inherit" />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant={isProcessManagementForm ? 'outlined' : 'standard'}
            />
          )}
          {isProcessManagementForm ? (
            <PMActionButtons handleOnClick={isLogInFormVisible ? handleLogIn : handleSetIsLogInFormVisible} />
          ) : (
            <>
              <Button
                id="login-action-btn"
                variant="contained"
                color="secondary"
                fullWidth
                endIcon={!isLoading && <GoIcon />}
                className={classes.btn}
                type="submit">
                {isLoading ? <CircularProgress size={28} color="inherit" /> : 'Log In'}
              </Button>
              <Typography component="p" variant="h3" className={classes.text}>
                OR
              </Typography>
              <SocialLogin setAlert={setIsAlertVisible} setError={setErrorMessage} />
            </>
          )}
        </form>
      </Paper>
      {!isProcessManagementForm && (
        <>
          <Link id="forgot-password-link" onClick={forgotPasswordCallback} variant="h5" className={classes.link}>
            {FORGOT_RESET_PASSWORD}
          </Link>
          <div className={classes.signupRow}>
            <Typography color="textSecondary" variant="body1">
              New to Scalar?
              <Link
                id="signup-link"
                className={classes.signupLink}
                component={RouterLink}
                to="/sign-up"
                variant="body1">
                Sign Up!
              </Link>
            </Typography>
          </div>
        </>
      )}
    </>
  );
};

LogInForm.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setPasswordRecovery: PropTypes.func,
  isProcessManagementForm: PropTypes.bool,
  isLogInFormVisible: PropTypes.bool,
  setIsLogInFormVisible: PropTypes.func,
};

export default LogInForm;
