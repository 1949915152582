import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    appBar: {
      zIndex: 900,
    },

    // Menu hamburger
    menuContainer: {
      paddingRight: theme.spacing(1),
      marginLeft: `-${theme.spacing(2)}px`,

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menuIcon: {
      color: theme.palette.primary.main,
      width: '30px',
      height: '30px',
    },
    // End Menu hamburger

    mainContainer: {
      [theme.breakpoints.up('md')]: {
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
      },
    },
    filter: {
      [theme.breakpoints.up('md')]: {
        order: 2,
      },
    },
    breadcrumbContainer: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },

    navigationContainer: {
      width: '100%',
      position: 'relative',
      display: 'none',

      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    progressBar: {
      height: '4px',
      backgroundColor: 'transparent',

      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '-4px',
    },
  })
);

export default useStyles;
