import React, { useEffect } from 'react';
import { Error as ErrorIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { EXTENSION_ERROR_MESSAGE } from 'common/constants/documents';
import Alert from 'components/Alert';
import useDocumentsContext from 'context/DocumentsContext';
import { useDocuments } from 'services/hooks';
import ProgressBar from './ProgressBar';

const UploadedFileAlert = ({
  droppedFiles,
  file,
  validFileTypes,
  fromReferenceWidget,
  selectedMeasurementDateId,
  setIsFileUploaded,
  setFilesToSave,
}) => {
  const { validateAndUploadFile, progress, isValidType, setIsLoading } = useDocuments();
  const { currentDocuments, setCompanyDocuments } = useDocumentsContext();

  useEffect(() => {
    const fileProps = {
      file,
      fromReferenceWidget,
      mdId: selectedMeasurementDateId,
      currentDocumentsId: currentDocuments.id,
      setCompanyDocumentsParam: setCompanyDocuments,
      setIsLoadingParam: setIsLoading,
      setIsFileUploaded,
      setFilesToSaveParam: setFilesToSave,
    };
    validateAndUploadFile(fileProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isValidType) {
    return <ProgressBar droppedFiles={droppedFiles} file={file} progress={progress} />;
  }
  return (
    <Alert isAlertVisible={true} icon={<ErrorIcon />}>
      {EXTENSION_ERROR_MESSAGE(file)} {validFileTypes.join(', ').toUpperCase()}.
    </Alert>
  );
};

UploadedFileAlert.propTypes = {
  droppedFiles: PropTypes.array,
  file: PropTypes.object,
  selectedMeasurementDateId: PropTypes.number,
  validFileTypes: PropTypes.array,
  fromReferenceWidget: PropTypes.bool,
  setIsFileUploaded: PropTypes.func,
  setFilesToSave: PropTypes.func,
};

export default UploadedFileAlert;
