import React, { FC } from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import SplitFab from 'components/SplitFab/SplitFab';
import { getObjectValue } from 'utillities';
import useStyles from './styles';
import { ExtendedFabButtonProps } from './types';

const ExtendedFabButton: FC<ExtendedFabButtonProps> = props => {
  const { btnStyle, customIcon, label, notFixed = false, onClick, secondaryActions, disabled, ...rest } = props;

  const classes = useStyles();
  const Icon = customIcon ?? AddIcon;

  if (secondaryActions) {
    return (
      <SplitFab label={label} mainAction={onClick} options={secondaryActions} disabled={disabled}>
        <Icon style={{ marginRight: '0.5rem' }} />
      </SplitFab>
    );
  }

  return (
    <Fab
      aria-label={label}
      className={clsx({ [classes.button]: !notFixed, [classes.disabled]: disabled })}
      color="primary"
      disabled={disabled}
      onClick={onClick}
      size="medium"
      style={getObjectValue(btnStyle)}
      variant="extended"
      {...rest}>
      <Icon className={classes.icon} fontSize="small" />
      <span className={classes.label}>{label}</span>
    </Fab>
  );
};

export default ExtendedFabButton;
