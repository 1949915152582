import { largeCurrencyFormat, largeDecimalFormat, sharesFormat } from 'common/formats/formats';

export const colConfig = (modeledAfterConvertibleNote = false, hasDividends = false, modeledAfterLoan = false) => {
  const colDateConfig = {
    value: 'Date',
    alias: 'date',
    disableEvents: true,
    width: '175px',
    readOnly: true,
  };

  if (modeledAfterConvertibleNote) {
    if (modeledAfterLoan) {
      return [
        colDateConfig,
        {
          value: 'Outstanding Amount',
          alias: 'amount',
          disableEvents: true,
          width: '175px',
          readOnly: true,
          format: largeCurrencyFormat,
          gridType: 'number',
          minValue: 0,
        },
        {
          value: 'Loan Value',
          alias: 'loan_value',
          disableEvents: true,
          width: '175px',
          readOnly: true,
          format: largeCurrencyFormat,
          gridType: 'number',
          minValue: 0,
        },
      ];
    }
    return [
      colDateConfig,
      {
        value: 'Balance Owned',
        alias: 'amount',
        disableEvents: true,
        width: '175px',
        readOnly: true,
        format: largeCurrencyFormat,
        gridType: 'number',
        minValue: 0,
      },
    ];
  }

  if (hasDividends) {
    return [
      colDateConfig,
      {
        value: 'Net Shares',
        alias: 'shares',
        disableEvents: true,
        width: '175px',
        readOnly: true,
        format: sharesFormat,
        gridType: 'number',
        minValue: 0,
      },
      {
        value: 'Cost Basis',
        alias: 'cost_basis',
        disableEvents: true,
        width: '175px',
        readOnly: true,
        format: largeCurrencyFormat,
        gridType: 'number',
        minValue: 0,
      },
      {
        value: 'Cumulative Dividends',
        alias: 'cumulative_dividends',
        disableEvents: true,
        width: '175px',
        readOnly: true,
        format: largeCurrencyFormat,
        gridType: 'number',
        minValue: 0,
      },
      {
        value: 'Total Shares Outstanding',
        alias: 'total_shares_outstanding',
        disableEvents: true,
        width: '175px',
        readOnly: true,
        format: largeDecimalFormat,
        gridType: 'number',
        minValue: 0,
      },
    ];
  }

  return [
    colDateConfig,
    {
      value: 'Net Shares',
      alias: 'shares',
      disableEvents: true,
      width: '175px',
      readOnly: true,
      format: sharesFormat,
      gridType: 'number',
      minValue: 0,
    },
    {
      value: 'Cost Basis',
      alias: 'cost_basis',
      disableEvents: true,
      width: '175px',
      readOnly: true,
      format: largeCurrencyFormat,
      gridType: 'number',
      minValue: 0,
    },
  ];
};

export default colConfig;
