import React from 'react';
import { currencyFormatZeroToTwoDecimals, largeDecimalFormat } from 'common/formats/formats';
import { BOLD_ROW_HEADER_CONFIG, TABLE_HEADER_CONFIG } from 'pages/Valuations/approaches/backsolveApproach/constants';
import SecuritySelectionViewer from 'pages/Valuations/approaches/backsolveApproach/SecurityTable/ components/SecuritySelectionViewer';
import SecurityGridSelect from 'pages/Valuations/approaches/backsolveApproach/SecurityTable/SecurityGridSelect';
import {
  PER_SHARE_ALIAS,
  PER_SHARE_TITLE,
  SECURITY_ALIAS,
  SECURITY_TITLE,
  SHARES_ALIAS,
  SHARES_TITLE,
  TARGET_VALUE_ALIAS,
  TOTAL_VALUE_ALIAS,
  TOTAL_VALUE_TITLE,
} from 'pages/Valuations/approaches/backsolveApproach/SecurityTable/util/constants';

export default async ({ rowConfig, tableData }) => {
  const { isDisabled } = tableData;
  const rowOffset = 2;
  const customClasses = `${TABLE_HEADER_CONFIG.className} align-right`;
  const cells = {
    A1: {
      ...TABLE_HEADER_CONFIG,
      value: SECURITY_TITLE,
      key: 'A1',
    },
    B1: {
      ...TABLE_HEADER_CONFIG,
      className: customClasses,
      value: SHARES_TITLE,
      key: 'B1',
    },
    C1: {
      ...TABLE_HEADER_CONFIG,
      className: customClasses,
      value: PER_SHARE_TITLE,
      key: 'C1',
    },
    D1: {
      ...TABLE_HEADER_CONFIG,
      className: customClasses,
      value: TOTAL_VALUE_TITLE,
      key: 'D1',
    },
  };
  // eslint-disable-next-line max-len
  const referencedSecurities = tableData.securitiesBasket.basket;
  const getSumExprCells = colLegend => referencedSecurities.map((_, i) => colLegend + (i + rowOffset)).join(',');
  const TOTAL_LABEL = {
    ...BOLD_ROW_HEADER_CONFIG,
    className: `${BOLD_ROW_HEADER_CONFIG.className} border-top`,
    value: 'Target Value',
    key: `A${rowConfig.length}`,
  };
  const SHARES_TOTAL_CELL = {
    readOnly: true,
    value: '',
    key: `B${rowConfig.length}`,
    expr: `=SUM(${getSumExprCells('B')})`,
    format: largeDecimalFormat,
    gridType: 'number',
  };
  const PER_SHARE_TOTAL_CELL = {
    readOnly: true,
    value: '',
    key: `C${rowConfig.length}`,
    expr: `=SUM(${getSumExprCells('C')})`,
    gridType: 'number',
    format: currencyFormatZeroToTwoDecimals,
  };
  const TOTAL_VALUE_CELL = {
    alias: TARGET_VALUE_ALIAS,
    readOnly: isDisabled,
    value: '',
    gridType: 'number',
    key: `D${rowConfig.length}`,
    expr: `=SUM(${getSumExprCells('D')})`,
    format: currencyFormatZeroToTwoDecimals,
  };
  cells[TOTAL_LABEL.key] = TOTAL_LABEL;
  cells[SHARES_TOTAL_CELL.key] = SHARES_TOTAL_CELL;
  cells[PER_SHARE_TOTAL_CELL.key] = PER_SHARE_TOTAL_CELL;
  cells[TOTAL_VALUE_CELL.key] = TOTAL_VALUE_CELL;

  rowConfig.forEach((security, rowIdx, rowConfigArray) => {
    if (!security.skipParse) {
      const rowNumber = rowIdx + 1;
      const isLastSecurityRow = rowNumber === rowConfigArray.length - 1;
      const securityCellClassName = `grid-select-left ${isLastSecurityRow ? 'subtitle-border-bottom' : ''}`;
      const security_name_cell = {
        alias: SECURITY_ALIAS,
        key: `A${rowNumber}`,
        columnLegend: 'A',
        expr: security.security,
        readOnly: isDisabled,
        ignoreRowCopy: true,
        isRequired: true,
        className: securityCellClassName,
        dataEditor: props => <SecurityGridSelect {...props} />,
        valueViewer: props => <SecuritySelectionViewer {...props} />,
      };
      const shares_cell = {
        key: `B${rowNumber}`,
        gridType: 'number',
        readOnly: isDisabled,
        isRequired: true,
        alias: SHARES_ALIAS,
        value: security.shares,
        format: SHARES_TOTAL_CELL.format,
      };

      const per_share_value_cell = {
        key: `C${rowNumber}`,
        value: security.per_share,
        gridType: 'number',
        format: PER_SHARE_TOTAL_CELL.format,
        alias: PER_SHARE_ALIAS,
      };
      const total_value_cell = {
        key: `D${rowNumber}`,
        expr: `=B${rowNumber} * C${rowNumber}`,
        gridType: 'number',
        defaultValue: 0,
        format: TOTAL_VALUE_CELL.format,
        alias: TOTAL_VALUE_ALIAS,
      };
      cells[security_name_cell.key] = security_name_cell;
      cells[shares_cell.key] = shares_cell;
      cells[per_share_value_cell.key] = per_share_value_cell;
      cells[total_value_cell.key] = total_value_cell;
    }
  });
  return cells;
};
