import { DECIMAL_PLACES } from 'common/config/app';
import { dbShortDate } from 'utillities/datesFormats';
import { doesNotExistValue } from 'utillities/doesNotExistValue';
import { toString } from './typesConverter';

const parseDatabaseValue = ({
  allowEmptyValues = false,
  decimalPlaces = DECIMAL_PLACES,
  dontMultiply = false,
  format,
  gridType,
  type,
  value,
}) => {
  const shouldReturnOriginalValue = allowEmptyValues && doesNotExistValue(value);
  switch (type) {
    // this applies to a DecimalField
    case 'string':
      if (value) {
        // If dbType is string but the value could store a float
        // we need to force the default decimal places precission
        const floatedValue = Number(value);
        if (format?.validateFloat && (!!floatedValue || floatedValue === 0)) {
          /* eslint-disable no-param-reassign */
          value = floatedValue.toFixed(decimalPlaces);
        }

        if (format && format.style === 'percent' && format.isDecimal && !dontMultiply && gridType !== 'percentage') {
          /* eslint-disable no-param-reassign */
          value = floatedValue.toFixed(decimalPlaces);
        }

        return value.toString();
      }

      return allowEmptyValues ? value : toString(Number(value));
    case 'number':
      return allowEmptyValues ? value : Number(value) || 0;
    case 'boolean':
      if (allowEmptyValues) {
        return value;
      }

      if (typeof value === 'string') {
        return Boolean(Number(value));
      }
      return Boolean(value);
    case 'dbDate':
      if (shouldReturnOriginalValue) {
        return value;
      }
      return dbShortDate(value);

    default:
      return value;
  }
};

export default parseDatabaseValue;
