import { ADD_PUBLIC_COMP_GROUP, ADD_TRANSACTION_COMP_GROUP } from 'pages/CompGroups/constants/constants';

export const ADD_COMP_GROUP_TYPES = [ADD_PUBLIC_COMP_GROUP, ADD_TRANSACTION_COMP_GROUP];

export const COMPANY_NAME_GPC = 'Company';
export const COMPANY_NAME_GPC_TOOLTIP = 'Name of the GPC Companies';

export const SYMBOL_GPC = 'Symbol';
export const SYMBOL_GPC_TOOLTIP = 'Symbol of the GPC Companies';

export const CAP_IQ_ID = 'Capital IQ ID';
export const CAP_IQ_ID_TOOLTIP = 'Capital IQ ID of the GPC Companies';

export const TARGET_NAME = 'Target Name';
export const TARGET_NAME_TOOLTIP = 'Target Name of the Transaction Companies';

export const ACQUIRER_NAME = 'Acquirer Name';
export const ACQUIRER_NAME_TOOLTIP = 'Acquirer Name of the Transaction Companies';

export const TRANSACTION_DATE = 'Transaction Date';
export const TRANSACTION_DATE_TOOLTIP = 'Transaction Date of the Transaction Companies';

export const ENTERPRISE_VALUE = 'Enterprise Value';
export const ENTERPRISE_VALUE_TOOLTIP = 'Enterprise Value of the Transaction Companies';

export const LTM_REVENUE = 'LTM Revenue';
export const LTM_REVENUE_TOOLTIP = 'LTM Revenue of the Transaction Companies';

export const LTM_REVENUE_GROWTH = 'LTM Revenue Growth';
export const LTM_REVENUE_GROWTH_TOOLTIP = 'LTM Revenue growth of the Transaction Companies';

export const LTM_EBITDA = 'LTM EBITDA';
export const LTM_EBITDA_TOOLTIP = 'LTM EBITDA of the Transaction Companies';

export const LTM_EBITDA_MARGIN = 'LTM EBITDA Margin';
export const LTM_EBITDA_MARGIN_TOOLTIP = 'LTM EBITDA Margin of the Transaction Companies';

export const LTM_GROSS_MARGIN = 'LTM Gross Margin';
export const LTM_GROSS_MARGIN_TOOLTIP = 'LTM Gross Margin of the Transaction Companies';
