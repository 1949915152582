import React from 'react';
import { Box } from '@material-ui/core';
import { DeleteOutline as DeleteIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  circleContainer: {
    backgroundColor: theme.palette.error.light,
    marginBottom: theme.spacing(3),
    borderRadius: '50%',
    width: '80px',
    height: '80px',
  },
  deleteIcon: {
    color: '#fff',
  },
}));

const ConfirmationDelete = ({ itemName, customMessage }) => {
  const classes = useStyles();

  const getMessage = () => {
    if (customMessage) {
      return customMessage;
    }
    return `Are you sure you want to delete this ${itemName}?`;
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box display="flex" className={classes.circleContainer} justifyContent="center" alignItems="center">
        <DeleteIcon className={classes.deleteIcon} fontSize="large" />
      </Box>
      <p>{getMessage()}</p>
    </Box>
  );
};

ConfirmationDelete.propTypes = {
  itemName: PropTypes.string,
  customMessage: PropTypes.string,
};

export default ConfirmationDelete;
