import React from 'react';
import { largeCurrencyFormat } from 'common/formats/formats';
import { GridDateEditor, GridDateValueViewer } from 'components/FeaturedSpreadsheet/components';
import { LedgerGridDate } from 'components/LedgerTable/components';
import { DISTRIBUTION_DATE_ALIAS } from '../shares-ledger/constants';
import generatePurchaseDateBaseConfig from '../shares-ledger/utilities/generatePurchaseDateBaseConfig';
import generatePurchaseDateOptions from '../shares-ledger/utilities/generatePurchaseDateOptions';

const colConfig = ({ acquisitions }) => {
  const acquisitionOptions = generatePurchaseDateOptions(acquisitions);
  const purchaseDateConfig = generatePurchaseDateBaseConfig(acquisitionOptions);
  return [
    purchaseDateConfig,
    {
      value: 'Date',
      alias: DISTRIBUTION_DATE_ALIAS,
      width: '100%',
      gridDateComponent: LedgerGridDate,
      dataEditor: props => <GridDateEditor {...props} />,
      valueViewer: props => <GridDateValueViewer {...props} slashFormatted />,
      isRequired: true,
    },
    {
      value: 'Amount',
      alias: 'amount',
      width: '100%',
      format: largeCurrencyFormat,
      minValue: 1,
      gridType: 'number',
      isRequired: true,
    },
  ];
};

export default colConfig;
