import { useMemo } from 'react';

export const useCombinedGPCComparisonPerfMetricAttrs = gpcAttributes =>
  useMemo(() => {
    if (!gpcAttributes) {
      return {};
    }
    const { comparisonAttrs, customDatePerformanceMetricAttrs } = gpcAttributes;
    return {
      ...comparisonAttrs,
      ...customDatePerformanceMetricAttrs,
    };
  }, [gpcAttributes]);
