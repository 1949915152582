/* eslint-disable import/no-unresolved */
import * as rows from 'common/constants/financials';
import { oneDecimalPercentFormat } from 'common/formats/formats';
import { baseRowConfig } from 'pages/Financials/data';

const rowConfig = () => [
  ...baseRowConfig,
  {
    value: rows.REVENUE_GROWTH_RATE_TITLE,
    alias: rows.REVENUE_GROWTH_RATE_ALIAS,
    expr: rows.REVENUE_GROWTH_RATE_EXPR,
    hidden: false,
    readOnly: true,
    format: oneDecimalPercentFormat,
    gridType: 'number',
    dbType: 'string',
  },
  {
    value: rows.TOTAL_COST_OF_SALES_TITLE,
    alias: rows.TOTAL_COST_OF_SALES_ALIAS,
    expr: rows.TOTAL_COST_OF_SALES_EXPR,
    hidden: false,
    readOnly: true,
    format: oneDecimalPercentFormat,
    gridType: 'number',
    dbType: 'string',
  },
  {
    value: rows.GROSS_MARGIN_TITLE,
    alias: rows.GROSS_MARGIN_ALIAS,
    expr: rows.GROSS_MARGIN_EXPR,
    hidden: false,
    readOnly: true,
    format: oneDecimalPercentFormat,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-indented',
  },
  {
    value: rows.OPERATING_EXPENSES_SALES_TITLE,
    alias: rows.OPERATING_EXPENSES_SALES_ALIAS,
    expr: rows.OPERATING_EXPENSES_SALES_EXPR,
    hidden: false,
    readOnly: true,
    format: oneDecimalPercentFormat,
    gridType: 'number',
    dbType: 'string',
  },
  {
    value: rows.EBITDA_MARGIN_TITLE,
    alias: rows.EBITDA_MARGIN_ALIAS,
    expr: rows.EBITDA_MARGIN_EXPR,
    hidden: false,
    readOnly: true,
    format: oneDecimalPercentFormat,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-indented',
  },
  {
    value: rows.ADJUSTED_EBITDA_MARGIN_TITLE,
    alias: rows.ADJUSTED_EBITDA_MARGIN_ALIAS,
    expr: rows.ADJUSTED_EBITDA_MARGIN_EXPR,
    hidden: false,
    readOnly: true,
    format: oneDecimalPercentFormat,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-indented',
  },
  {
    value: rows.PROFIT_MARGIN_TITLE,
    alias: rows.PROFIT_MARGIN_ALIAS,
    expr: rows.PROFIT_MARGIN_EXPR,
    hidden: false,
    readOnly: true,
    format: oneDecimalPercentFormat,
    gridType: 'number',
    dbType: 'string',
    className: 'row-label-indented',
  },
];

export default rowConfig;
