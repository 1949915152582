import { isNil } from 'lodash';
import { OPM } from 'common/constants/allocations';
import { GREATER_THAN, IS_REQUIRED_ERROR } from 'common/constants/messages/validations';

const customValidations = ({ cell: tmpCell, tableData, addFeedback, removeFeedback }) => {
  const approach = tableData.data;
  const { allocation_method: allocationMethod } = approach;
  // if allocation method is OPM, then the following fields are required:
  const { value, isRequiredIfOPM, greaterThanIfOPM } = tmpCell;
  if (allocationMethod === OPM && isRequiredIfOPM && (isNil(value) || value.toString().trim().length === 0)) {
    addFeedback(IS_REQUIRED_ERROR);
  } else {
    removeFeedback(IS_REQUIRED_ERROR);
  }

  // Greater than zero, could be 0.1 or 0.01...
  if (allocationMethod === OPM && !isNil(greaterThanIfOPM) && Number(value) <= Number(greaterThanIfOPM)) {
    addFeedback(GREATER_THAN(greaterThanIfOPM));
  } else {
    removeFeedback(GREATER_THAN(greaterThanIfOPM));
  }
};

export default customValidations;
