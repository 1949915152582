import React, { FC, useMemo } from 'react';
import { FINANCIALS_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { useFormat } from 'common/hooks';
import { UseFormatValues } from 'common/types/format';
import { CustomScalarSpreadsheet } from 'components';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { getObjectValue, getStringValue } from 'utillities';
import { FutureEquityProps } from './types';

const FutureEquity: FC<FutureEquityProps> = props => {
  const { isLoading, isSaving, onChange, spreadsheets, workbook } = props;
  const { futureEquitySheet: futureEquitySpreadsheet } = getObjectValue(spreadsheets);

  const [format, formatDispatch] = useFormat({
    page: FINANCIALS_CURRENCY_PAGE,
  }) as UseFormatValues;

  // Create Future Equity Value Sheet props
  const futureValueSheetProps = useMemo(() => {
    if (futureEquitySpreadsheet) {
      return {
        ...futureEquitySpreadsheet,
        format,
        formatDispatch,
        key: getStringValue(futureEquitySpreadsheet?.name),
        onChange,
        sheet: futureEquitySpreadsheet,
        workbook,
      } as unknown as typeof ScalarSpreadsheet;
    }

    return null;
  }, [futureEquitySpreadsheet, format, formatDispatch, onChange, workbook]);

  return <CustomScalarSpreadsheet {...futureValueSheetProps} isLoading={isLoading} isSaving={isSaving} />;
};

export default FutureEquity;
