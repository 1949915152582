/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FormControl, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  SUBTITLE,
  SUBTITLE_FOR_DROPPED_FILES,
  SUBTITLES_FOR_DIALOGS,
  TITLE,
  TITLE_ARRAY,
} from 'common/constants/documents';
import { FormDialog } from 'components/Dialogs';
import DropZone from 'components/DropZone';
import useDocumentsContext from 'context/DocumentsContext';
import { useDocuments } from 'services/hooks';
import FileAlerts from './FileAlerts';
import NotReferencedDocumentsList from './NotReferencedDocumentsList';
import validFileTypes from './validFileTypes';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: '1rem 0',
  },
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%',
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 400,
    fontSize: 16,
    color: 'gray',
  },
  selectorContainer: {
    display: 'flex',
  },
  selectorLabel: {
    margin: '0 0 1rem 1rem',
    shrink: false,
  },
  select: {
    width: '100%',
  },
}));

const DocumentDialog = ({
  open,
  onClose,
  addSelectComponent,
  title,
  droppedFiles,
  setDroppedFiles,
  selectedMeasurementDateId,
  dropZoneTitle,
  selectorTitle,
  referencedDocuments,
  customButtonLabel,
  referenceType,
  referencedFeatureId,
  fromReferenceWidget,
}) => {
  const classes = useStyles();
  const [selectedValues, setSelectedValues] = useState();
  const { addReferenceForExistingDocuments } = useDocuments();
  const { updateReferencedDocuments, currentPage, documents } = useDocumentsContext();
  const fromDocumentFeature = true;

  const clearSelector = () => {
    setSelectedValues('');
  };

  const handleActionButton = async () => {
    if (selectedValues) {
      await addReferenceForExistingDocuments(
        selectedMeasurementDateId,
        selectedValues,
        referencedFeatureId,
        referenceType
      );
      updateReferencedDocuments(documents, currentPage);
    }
    onClose();
    setDroppedFiles([]);
  };

  const processFile = async files => {
    const fileList = [...files];
    setDroppedFiles(prevState => [...prevState, ...fileList]);
    if (fromReferenceWidget) {
      onClose();
    }
  };

  useEffect(() => {
    clearSelector();
  }, [droppedFiles]);

  const DividerWithText = ({ children }) => (
    <div className={classes.container}>
      <div className={classes.border} />
      <span className={classes.content}>{children}</span>
      <div className={classes.border} />
    </div>
  );

  DividerWithText.propTypes = {
    children: PropTypes.node,
  };

  return (
    <FormDialog
      open={open}
      title={title}
      customButtonLabel={customButtonLabel}
      isValid={!isEmpty(droppedFiles) || selectedValues?.length > 0}
      onSave={handleActionButton}
      onClose={onClose}
      bigDialog={true}
      PaperProps={{ style: { width: 787 } }}>
      <div>
        {addSelectComponent && (
          <div>
            {selectorTitle && (
              <Typography variant="h5" gutterBottom>
                {selectorTitle}
              </Typography>
            )}
            {documents ? (
              <FormControl fullWidth>
                <div className={classes.selectorContainer}>
                  <NotReferencedDocumentsList
                    documents={documents}
                    setSelectedValues={setSelectedValues}
                    placeholder={'Select a document'}
                    referencedDocuments={referencedDocuments}
                  />
                </div>
                <DividerWithText>OR</DividerWithText>
              </FormControl>
            ) : (
              <Skeleton height={50} />
            )}
          </div>
        )}
        {dropZoneTitle && (
          <Typography variant="h5" gutterBottom>
            {dropZoneTitle}
          </Typography>
        )}
        <FormControl fullWidth>
          <div style={{ marginBottom: '1rem' }}>
            <DropZone
              setFileSrc={processFile}
              title={TITLE}
              titleArray={TITLE_ARRAY}
              subTitle={SUBTITLE}
              maxSizeAllowedInMb={15}
              validFileTypes={validFileTypes}
              fromDocumentFeature={fromDocumentFeature}
            />
            <Typography variant="caption" style={{ margin: '0.5rem 0', color: 'darkgray' }}>
              {SUBTITLES_FOR_DIALOGS('document')}
            </Typography>
          </div>
          {droppedFiles === [] && (
            <Typography variant="caption" style={{ margin: '0.5rem 0', color: 'darkgray' }}>
              {SUBTITLE_FOR_DROPPED_FILES}
            </Typography>
          )}
          {!isEmpty(droppedFiles) && (
            <FileAlerts
              droppedFiles={droppedFiles}
              selectedMeasurementDateId={selectedMeasurementDateId}
              validFileTypes={validFileTypes}
              fromReferenceWidget={fromReferenceWidget}
            />
          )}
        </FormControl>
      </div>
    </FormDialog>
  );
};

DocumentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  addSelectComponent: PropTypes.bool,
  title: PropTypes.string,
  droppedFiles: PropTypes.array,
  setDroppedFiles: PropTypes.func,
  selectedMeasurementDateId: PropTypes.number,
  dropZoneTitle: PropTypes.string,
  selectorTitle: PropTypes.string,
  referencedDocuments: PropTypes.array,
  customButtonLabel: PropTypes.string,
  referenceType: PropTypes.string,
  referencedFeatureId: PropTypes.number,
  fromReferenceWidget: PropTypes.bool,
};

export default DocumentDialog;
