/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-tabs */
import React, { useState } from 'react';
import { Button, Grid, Link, Paper, TextField, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { isNull, isUndefined } from 'lodash';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import validate from 'validate.js';
import { ERROR_400, ERROR_401, ERROR_404 } from 'common/config/api';
import * as messages from 'common/constants/messages/validations';
import { Alert, Logo } from 'components';
import { useSendPasswordChange } from 'services/hooks/auth';
import { differentThanValidator, excludeWhiteSpacesValidator } from 'utillities';
import { logIn } from '../../common/constants/paths';
import useStyles from '../../layouts/AuthLayout/AuthLayout.styles';

validate.validators.excludeWhiteSpaces = excludeWhiteSpacesValidator;
validate.validators.differentThan = differentThanValidator;

const constraints = {
  password: {
    presence: {
      allowEmpty: false,
      message: messages.IS_REQUIRED,
    },
  },
  passwordConfirmation: {
    presence: {
      allowEmpty: false,
      message: messages.IS_REQUIRED,
    },
  },
};

const defaultErrorMessage = messages.FIX_BEFORE_CONTINUE;

const ResetPasswordConfirmForm = () => {
  const { uid, token } = useParams();
  const classes = useStyles();
  const sendPasswordReset = useSendPasswordChange();
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [severity, setSeverity] = useState('error');

  const hasError = field => !!formState.errors[field] && !!formState.errors[field].length;

  const checkValuePresence = field => {
    const value = formState.values[field];
    let existValue = true;
    if (!value) {
      existValue = false;
      switch (field) {
        case 'password':
          formState.errors[field] = [messages.PASSWORD_EMPTY('New Password')];
          break;
        case 'passwordConfirmation':
          formState.errors.passwordConfirmation = [messages.PASSWORD_EMPTY('New Password confirm')];
          break;
        default:
          break;
      }
    }
    return existValue;
  };

  const checkPasswordsEquality = () => {
    setIsLoading(false);
    if (!checkValuePresence('password') || !checkValuePresence('passwordConfirmation')) {
      setFormState({ ...formState });
      setSeverity('error');
      setErrorMessage(defaultErrorMessage);
      setIsAlertVisible(true);
    } else if (formState.values.password !== formState.values.passwordConfirmation) {
      setSeverity('error');
      setErrorMessage(messages.PASSWORD_MISMATCH);
      setIsAlertVisible(true);
      formState.errors.password = [''];
      formState.errors.passwordConfirmation = [''];
    } else {
      setIsAlertVisible(false);
    }
  };

  const setBackendError = response => {
    setIsAlertVisible(true);
    setSeverity('error');

    switch (response.statusCode) {
      case ERROR_401:
        setErrorMessage(response.body.detail);
        break;
      case ERROR_400:
        setErrorMessage(messages.PASSWORD_USER_OR_TOKEN_NOT_FOUND);
        break;
      case ERROR_404:
        setErrorMessage(messages.PASSWORD_URL_USED);
        break;
      default:
        setErrorMessage(defaultErrorMessage);
        break;
    }
  };

  const redirectToLoginAfterNSeconds = seconds => {
    setTimeout(() => {
      window.location.href = logIn;
    }, seconds * 1000);
  };

  const handleSetPassword = async event => {
    event.preventDefault();
    setIsAlertVisible(false);
    checkPasswordsEquality();

    if (formState.isValid) {
      setIsLoading(true);
      const response = await sendPasswordReset(
        formState.values.password,
        formState.values.passwordConfirmation,
        uid,
        token
      );
      setIsLoading(false);
      if (isNull(response)) {
        // status code 204 => null body
        setIsAlertVisible(true);
        setSeverity('success');
        setErrorMessage(messages.PASSWORD_CHANGE_CONFIRM);
        redirectToLoginAfterNSeconds(5);
      } else {
        setBackendError(response);
      }
    } else {
      setIsAlertVisible(true);
    }
    setIsLoading(false);
  };

  const updateErrors = (errors, fieldName) => {
    if (errors?.[fieldName] && errors[fieldName].length > 0) {
      setIsAlertVisible(true);
    } else {
      setIsAlertVisible(false);
    }
  };

  const handleChange = event => {
    const tmpFormState = { ...formState };
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    const errors = validate({ ...formState.values, [fieldName]: fieldValue }, constraints);

    // Update the values
    tmpFormState.values[fieldName] = fieldValue;

    // Update the touched field
    tmpFormState.touched[fieldName] = true;

    // Update the errors
    tmpFormState.isValid = isUndefined(errors);
    tmpFormState.errors = errors || {};

    setFormState(tmpFormState);
    updateErrors(errors, fieldName);
  };

  const handleFocusOut = () => {
    checkPasswordsEquality();
  };

  const regexpList = [
    /^\S{9,}$/, // 9 characters or more (Not whitespace)
    /[a-z]+/i, // At least 1 letter
    /\d+/, // At least 1 digit
    /\W+/, // At least 1 special character
  ];

  const checkConditionByIndex = index => {
    const { password } = formState.values;
    const { passwordConfirmation } = formState.values;

    if (!password || password.length <= 0) {
      return false;
    }

    if (index === 4) {
      // check password equality condition
      return password === passwordConfirmation;
    }
    // check other condition
    return regexpList[index].test(password);
  };

  const checks = ['9 characters', '1 letter', '1 number', '1 special character', 'Passwords must match'];

  const getCheckIcon = index => {
    const style = { fill: 'white' };
    const dataTestId = `img-${index}`;
    const id = `icon-id-${index}`;
    if (checkConditionByIndex(index)) {
      return <CheckCircleIcon id={id} data-testid={dataTestId} style={style} />;
    }
    return <CheckCircleOutlinedIcon id={id} data-testid={dataTestId} style={style} />;
  };

  const checklist = checks.map((elem, index) => (
    <ListItem key={elem}>
      <ListItemIcon role="img">{getCheckIcon(index)}</ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Typography component="span">
            <Box color="white" fontWeight={500}>
              {elem}
            </Box>
          </Typography>
        }
      />
    </ListItem>
  ));

  const formIsValid = () => {
    for (let i = 0; i < checklist.length; i += 1) {
      if (!checkConditionByIndex(i)) {
        return false;
      }
    }
    return true;
  };

  return (
    <Grid container className={classes.root}>
      <Grid item lg={6} className={classes.backImage}>
        <div className={classes.contentImage}>
          <img alt="Background" className={classes.image} src="/images/login-bg.jpg" />
        </div>
        <div className={classes.headerLogo}>
          <Typography variant="h1">
            <Link to="/">
              <Logo variation="white" />
            </Link>
          </Typography>
        </div>
        <div className={classes.quoteContainer}>
          <Typography variant="h2">
            In order to be secure enough,
            <br />
            your new password should have at least:
          </Typography>
          <List>{checklist}</List>
        </div>
      </Grid>
      <Grid item lg={6} xs={12} className={classes.formContainer}>
        <Paper className={classes.form}>
          <Typography variant="h1" color="primary">
            Reset password
          </Typography>
          <Typography style={{ marginTop: '1rem', fontSize: '1.2rem' }}>
            Invest in your security by creating a strong password
          </Typography>
          <Alert id="alert" isAlertVisible={isAlertVisible} severity={severity}>
            <>{errorMessage}</>
          </Alert>
          <form
            id="password-confirm-form"
            onSubmit={e => {
              handleSetPassword(e).catch(noop);
            }}>
            <TextField
              id="password"
              name="password"
              label="New Password"
              fullWidth
              type="password"
              className="scar-textField"
              error={isAlertVisible && hasError('password')}
              helperText={isAlertVisible && hasError('password') ? formState.errors.password[0] : null}
              onChange={handleChange}
              defaultValue=""
            />
            <TextField
              id="password-confirmation"
              name="passwordConfirmation"
              label="Repeat New Password"
              fullWidth
              type="password"
              className="scar-textField"
              error={isAlertVisible && hasError('passwordConfirmation')}
              helperText={
                isAlertVisible && hasError('passwordConfirmation') ? formState.errors.passwordConfirmation[0] : null
              }
              onChange={handleChange}
              onBlur={handleFocusOut}
              defaultValue=""
            />
            <Button
              id="submit-action-btn"
              variant="contained"
              color="secondary"
              fullWidth
              className={classes.btn}
              type="submit"
              disabled={!formIsValid()}
              onClick={e => {
                handleSetPassword(e).catch(noop);
              }}>
              {isLoading ? <CircularProgress size={28} color="inherit" /> : 'Create new password'}
            </Button>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

ResetPasswordConfirmForm.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default ResetPasswordConfirmForm;
