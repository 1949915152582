import React from 'react';
import PropTypes from 'prop-types';

const StaticValueViewer = ({ value }) => (
  <span className="value-viewer">
    <span className="text-cell">{ value }</span>
  </span>
);

StaticValueViewer.propTypes = {
  value: PropTypes.any,
};

export default StaticValueViewer;
