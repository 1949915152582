import React from 'react';
import { GridDateValueViewer } from 'components/FeaturedSpreadsheet/components';
import PurchaseDateSelect from 'pages/CapTable/fund-ownership/components/shares-ledger/components/purchase-date-select/PurchaseDateSelect';

const generatePurchaseDateBaseConfig = options => {
  if (options.length === 1) {
    return {
      value: 'Purchase Date',
      alias: 'purchase_date',
      width: '175px',
      dataEditor: null,
      readOnly: true,
      valueViewer: props => <GridDateValueViewer {...props} slashFormatted />,
    };
  }
  return {
    value: 'Purchase Date',
    alias: 'purchase_date',
    width: '175px',
    component: <PurchaseDateSelect options={options} />,
    disableEvents: true,
    forceComponent: true,
    soldTable: true,
  };
};

export default generatePurchaseDateBaseConfig;
