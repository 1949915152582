import React, { useContext, useState } from 'react';
import { Cell } from 'common/types/scalarSpreadsheet';
import { LedgerDialog } from 'components/Dialogs';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import DCFApproachContext from 'pages/Valuations/approaches/discountCashFlow/context/DCFApproachContext';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { getObjectValue } from 'utillities';
import DialogHeader from './DialogHeader';
import { WACCLedgerProps } from './types';

const WACCLedger = (props: WACCLedgerProps) => {
  const { closeDialog, dcfWacc } = getObjectValue(props);
  const { workbook } = useContext(ValuationContext);
  const { onChange: onWorkbookChange } = useContext(DCFApproachContext);
  const [cells, setCells] = useState(dcfWacc.cells);
  const [waccInfo, setWaccInfo] = useState(Number(dcfWacc.cells.wacc.value || 0) * 100);

  const onChange = (cell: Cell, expr: string) => {
    if (onWorkbookChange) onWorkbookChange(cell, expr);
    setWaccInfo(Number(dcfWacc.cells.wacc.value || 0) * 100);
  };

  const waccLedgerSheetProps = {
    ...dcfWacc,
    sheet: dcfWacc,
    onChange,
    showToolbar: true,
    workbook,
    className: 'valuations-dcf-wacc-ledger-table',
    cells,
    updateCells: setCells,
  };

  return (
    <LedgerDialog
      id="current-waccledger"
      dialogHeader={<DialogHeader waccInfo={waccInfo} />}
      title=""
      actionButtonText={{ cancel: 'Close' }}
      onClose={closeDialog}>
      <ScalarSpreadsheet {...waccLedgerSheetProps} />
    </LedgerDialog>
  );
};

export default WACCLedger;
