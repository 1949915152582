import React, { FC } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { RadioButtonGroupProps } from './types';

const RadioButtonGroup: FC<RadioButtonGroupProps> = props => {
  const { ariaLabel, defaultValue, formLabel, handleChange, name, options } = props;

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{formLabel}</FormLabel>
      <RadioGroup value={defaultValue} aria-label={ariaLabel} name={name} onChange={handleChange}>
        {options.map(option => (
          <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonGroup;
