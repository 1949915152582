import React from 'react';
import { Box, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import theme from 'theme';
import FormInput from './FormInput';

const useStyles = makeStyles({
  title: {
    fontWeight: 700,
  },
  deleteButton: {
    color: theme.palette.gray[400],
  },
});

const ModalItemBox = ({
  index,
  item,
  handleInputChange,
  setIsMaxCharactersError,
  formValues,
  setFormValues,
  itemTitle,
  inputLabel,
  attribute,
  additionalInputs = [],
}) => {
  const classes = useStyles();

  const handleRemoveItem = () => {
    setFormValues({
      ...formValues,
      [attribute]: formValues[attribute].filter((_, i) => i !== index),
    });
  };

  return (
    <Box mt={4} border={1} borderColor={theme.palette.gray[400]} borderRadius={4} p={2}>
      <Grid container alignItems="center">
        <Grid item xs={11}>
          <Typography variant="body2" gutterBottom className={classes.title}>
            {itemTitle ? `${itemTitle} ${index + 1}` : ''}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton className={classes.deleteButton} onClick={handleRemoveItem} aria-label="delete">
            <DeleteOutlineIcon />
          </IconButton>
        </Grid>
      </Grid>

      <FormInput
        name="name"
        label={inputLabel}
        placeholder={inputLabel}
        value={item.name}
        handleInputChange={e => handleInputChange(index, e)}
        maxCharacters={additionalInputs[0]?.maxCharacters || 50}
        setIsMaxCharactersError={setIsMaxCharactersError}
      />
      {additionalInputs.map((input, i) => (
        <FormInput
          key={input.id || i}
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          value={item[input.name]}
          handleInputChange={e => handleInputChange(index, e)}
          maxCharacters={input.maxCharacters}
          setIsMaxCharactersError={setIsMaxCharactersError}
        />
      ))}
    </Box>
  );
};

ModalItemBox.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  setIsMaxCharactersError: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  }).isRequired,
  setFormValues: PropTypes.func.isRequired,
  itemTitle: PropTypes.string,
  inputLabel: PropTypes.string.isRequired,
  attribute: PropTypes.string.isRequired,
  additionalInputs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      maxCharacters: PropTypes.number,
    })
  ),
};

export default ModalItemBox;
