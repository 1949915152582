import { Row, Rows } from 'common/types/scalarSpreadsheet';
import {
  FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/futureExit/';
import { getSlugValue } from 'utillities';
import { RowConfigParams } from './types';

const { FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD, FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE }
  = SHEET_ALIASES_CONSTANTS;

const { FE_ALLOCATION_METHOD_SPREADSHEET } = SHEET_TITLES_CONSTANTS;

const rowConfig = (params: RowConfigParams): Rows => {
  const { isDisabled } = params;

  return [
    {
      alias: FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD,
      className: `${FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE}-${getSlugValue(
        FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      value: FE_ALLOCATION_METHOD_SPREADSHEET.ALLOCATION_METHOD.TITLE,
    } as Row,
    {
      alias: FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE,
      className: `${FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE}-${getSlugValue(
        FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      value: FE_ALLOCATION_METHOD_SPREADSHEET.CAP_TABLE.TITLE,
    } as Row,
  ];
};

export default rowConfig;
