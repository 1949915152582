import React from 'react';
import PropTypes from 'prop-types';
import { MessageBox } from 'components';

const EmptyTableMessage = ({ tableTerms, component, title, tagline }) => {
  const { tableName, pluralColumnName, columnName } = tableTerms;
  const beginsWithVocal = /^[aeiou]/i.test(columnName);
  return (
    <MessageBox
      title={title || `The ${tableName} has no ${pluralColumnName}`}
      tagline={tagline || `Please add a${beginsWithVocal ? 'n' : ''} ${columnName.toLowerCase()} to continue`}
      fullWidth={false}
      action={component}
    />
  );
};

EmptyTableMessage.propTypes = {
  tableTerms: PropTypes.shape({
    tableName: PropTypes.string,
    pluralColumnName: PropTypes.string,
    columnName: PropTypes.string,
  }).isRequired,
  component: PropTypes.element,
  title: PropTypes.string,
  tagline: PropTypes.string,
};

export default EmptyTableMessage;
