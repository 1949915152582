import { EnableCellsParams } from './types';

const enableCells = (params: EnableCellsParams) => {
  const { cells, isReadOnly = false } = params;

  cells.forEach(cell => {
    if (!cell) return;

    const currentCell = cell;

    if (isReadOnly) {
      currentCell.hidden = false;
      return;
    }

    currentCell.greaterThan = 0;
    currentCell.hidden = false;
    currentCell.isRequired = true;
    currentCell.readOnly = false;
  });
};

export default enableCells;
