import useStyles from './styles';

const useCustomClasses = () => {
  const classes = useStyles();

  return {
    customClasses: classes,
  };
};

export default useCustomClasses;
