import { handleAllocationMethod } from 'pages/ValuationsAllocation/approaches/FutureExit/AllocationMethodTable/utils/utils';
import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/futureExit';
import { parseCellValue } from 'pages/ValuationsAllocation/util';
import { getNumberValue, getObjectValue } from 'utillities';
import { CustomReverseParserParams, HandlersParams } from './types';

const handleCapTable = (params: HandlersParams) => {
  const { approach, cell, futureExitAttributes } = params;
  const fieldDecimalPlaces = getNumberValue(futureExitAttributes?.cap_table?.decimal_places);

  const updatedCellValue = parseCellValue({ cell, fieldDecimalPlaces });
  approach.cap_table = updatedCellValue;
};

const handleMaturityYears = (params: HandlersParams) => {
  const { approach, cell, futureExitAttributes } = params;
  const fieldDecimalPlaces = getNumberValue(futureExitAttributes?.maturity?.decimal_places);
  const updatedCellValue = parseCellValue({ cell, fieldDecimalPlaces });
  approach.maturity = updatedCellValue;
};

const handleVolatilitySource = (params: HandlersParams) => {
  const { approach, cell, futureExitAttributes } = params;
  if (Number(cell.value) === 0) {
    approach.volatility_source = null;
  } else {
    const fieldDecimalPlaces = getNumberValue(futureExitAttributes?.volatility_source?.decimal_places);
    const updatedCellValue = parseCellValue({ cell, fieldDecimalPlaces });
    approach.volatility_source = updatedCellValue;
  }
};

const handleVolatility = (params: HandlersParams) => {
  const { approach, cell, futureExitAttributes } = params;
  const fieldDecimalPlaces = getNumberValue(futureExitAttributes?.volatility?.decimal_places ?? 3);
  const updatedCellValue = parseCellValue({ cell, fieldDecimalPlaces });
  approach.volatility = updatedCellValue;
};

const customReverseParser = (params: CustomReverseParserParams) => {
  const { cells, approach, futureExitAttributes } = getObjectValue(params);
  const { valuations_approach_future_exit: valuationFEApproach } = getObjectValue(approach);
  const updatedFEApproach = getObjectValue(valuationFEApproach);

  const {
    FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD,
    FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE,
    FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS,
    FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE,
    FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY,
  } = SHEET_ALIASES_CONSTANTS;

  Object.values(getObjectValue(cells)).forEach(cell => {
    const { alias } = getObjectValue(cell);

    switch (alias) {
      // Handle Allocation Method
      case FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD: {
        handleAllocationMethod({ approach: updatedFEApproach, cell, futureExitAttributes });
        break;
      }
      // Handle Cap Table Selection
      case FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE: {
        handleCapTable({ approach: updatedFEApproach, cell, futureExitAttributes });
        break;
      }
      // Handle Maturity Years
      case FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS: {
        handleMaturityYears({ approach: updatedFEApproach, cell, futureExitAttributes });
        break;
      }
      // Handle Volatility Source
      case FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE: {
        handleVolatilitySource({ approach: updatedFEApproach, cell, futureExitAttributes });
        break;
      }
      // Handle Volatility
      case FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY: {
        handleVolatility({ approach: updatedFEApproach, cell, futureExitAttributes });
        break;
      }

      default:
        break;
    }
  });
};

export default customReverseParser;
