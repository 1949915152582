// Link to formats documentation: https://scalar.atlassian.net/wiki/spaces/SCAL/pages/855932929/Cell+Standardization
// Start currency formats
const commonCurrencyFormat = {
  style: 'currency',
  validateFloat: true,
};

export const smallCurrencyFormat = {
  ...commonCurrencyFormat,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const largeCurrencyFormat = {
  ...commonCurrencyFormat,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

export const currencyFormatZeroToTwoDecimals = {
  ...commonCurrencyFormat,
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

export const currencyFormat = ({ fractionDigits = 3 } = {}) => ({
  ...commonCurrencyFormat,
  minimumFractionDigits: fractionDigits,
  maximumFractionDigits: fractionDigits,
});
// End currency formats

// Decimal formats
const commonDecimalFormat = {
  style: 'decimal',
  validateFloat: true,
};

export const smallDecimalFormat = {
  ...commonDecimalFormat,
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
};

export const standardDecimalFormat = {
  ...commonDecimalFormat,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const decimalFormatZeroToTwoDecimals = {
  ...commonDecimalFormat,
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

export const largeDecimalFormat = {
  ...commonDecimalFormat,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

export const yearsDecimalFormat = {
  ...commonDecimalFormat,
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
};

export const decimalFormat = ({ fractionDigits = 2 } = {}) => ({
  style: 'decimal',
  minimumFractionDigits: fractionDigits,
  maximumFractionDigits: fractionDigits,
  validateFloat: true,
});
// End decimal formats

// Start percent formats
const commonPercentFormat = {
  style: 'percent',
  validateFloat: true,
  isDecimal: false,
};

export const roundedPercentFormat = {
  ...commonPercentFormat,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

export const roundedPercentFormatIsDecimalTrue = {
  ...commonPercentFormat,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  isDecimal: true,
};

export const oneDecimalPercentFormat = {
  ...commonPercentFormat,
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  isDecimal: true,
  validateFloat: false,
};

export const oneDecimalPercentFormatValidateFloatTrue = {
  ...oneDecimalPercentFormat,
  validateFloat: true,
};

export const twoDecimalPercentFormat = ({ minimumFractionDigits = 2, isDecimal = true, validateFloat = false }) => ({
  ...commonPercentFormat,
  minimumFractionDigits,
  maximumFractionDigits: 2,
  isDecimal,
  validateFloat,
});

export const percentFormat = ({ fractionDigits = 1, isDecimal = false, validateFloat = true } = {}) => ({
  style: 'percent',
  minimumFractionDigits: fractionDigits,
  maximumFractionDigits: fractionDigits,
  isDecimal,
  validateFloat,
});
// End percent formats

// Start x suffix formats
const commonXSuffixFormat = {
  style: 'decimal',
  suffix: 'x',
  validateFloat: true,
};

export const xSmallSuffixFormat = {
  ...commonXSuffixFormat,
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
};

export const xStandardSuffixFormat = {
  ...commonXSuffixFormat,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const xLargeSuffixFormat = {
  ...commonXSuffixFormat,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};
// End x suffix formats

// Start Cap table formats
export const capTableCurrencyFormat = {
  ...smallCurrencyFormat,
  maximumFractionDigitsWhenEditing: 15,
};

export const capTableTwoDecimalPercentFormat = {
  ...twoDecimalPercentFormat({}),
  maximumFractionDigitsWhenEditing: 15,
  validateFloat: true,
};

export const capTableXSuffixFormat = {
  ...xStandardSuffixFormat,
  maximumFractionDigitsWhenEditing: 15,
};
// End Cap table formats

// Start DCF formats
export const dcfPercentFormat = {
  ...oneDecimalPercentFormat,
  validateFloat: true,
};

export const dcfPercentFormatWithNegativeValues = {
  ...dcfPercentFormat,
  allowNegativeValue: true,
};
// End DCF formats

// Start 409 Dashboard formats
export const dashboardPercentFormatWithNegativeValues = {
  ...twoDecimalPercentFormat({ minimumFractionDigits: 2, isDecimal: true, validateFloat: true }),
  allowNegativeValue: true,
};

export const chipPercentFormat = {
  ...dashboardPercentFormatWithNegativeValues,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

export const oneDecimalPercentFormatValidateFloatTrueWithNegativeValues = {
  ...oneDecimalPercentFormatValidateFloatTrue,
  allowNegativeValue: true,
};
// End 409 Dashboard formats

// Start Special formats
export const sharesFormat = {
  ...largeDecimalFormat,
};

export const fullyDilutedOwnershipFormat = {
  ...oneDecimalPercentFormatValidateFloatTrue,
};

export const weightingPercentFormat = {
  ...oneDecimalPercentFormat,
  validateFloat: true,
  allowNegativeValue: true,
};
