import React, { useCallback } from 'react';
import { Button, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';
import { SHOW_LESS, VIEW_ALL } from 'common/constants/process-management';
import theme from 'theme';

const ShowItemsButton = ({ callback, icon, label }) => (
  <Button
    onClick={callback}
    endIcon={icon}
    style={{
      textTransform: 'none',
      color: `${theme.palette.primary[500]}`,
    }}>
    <Typography
      style={{
        color: `${theme.palette.primary[500]}`,
        fontWeight: 500,
        fontSize: '1.125rem',
      }}>
      {label}
    </Typography>
  </Button>
);

ShowItemsButton.propTypes = {
  callback: PropTypes.func,
  icon: PropTypes.node,
  label: PropTypes.string,
};

const ViewAllItemsButton = ({ itemsToShow, setItemsToShow, quantityParam, itemList, itemCategory }) => {
  const handleItemsToShow = useCallback(
    quantity => {
      setItemsToShow(itemList.slice(0, quantity));
    },
    [itemList, setItemsToShow]
  );

  return (
    <>
      {itemsToShow.length < itemList.length ? (
        <ShowItemsButton
          callback={() => handleItemsToShow(itemList.length)}
          icon={<KeyboardArrowDownIcon />}
          label={`${VIEW_ALL} ${itemCategory}`}
        />
      ) : (
        <ShowItemsButton
          callback={() => handleItemsToShow(quantityParam)}
          icon={<KeyboardArrowUpIcon />}
          label={`${SHOW_LESS} ${itemCategory}`}
        />
      )}
    </>
  );
};

ViewAllItemsButton.propTypes = {
  itemsToShow: PropTypes.array.isRequired,
  setItemsToShow: PropTypes.func.isRequired,
  quantityParam: PropTypes.number,
  itemList: PropTypes.array.isRequired,
  itemCategory: PropTypes.string.isRequired,
};

export default ViewAllItemsButton;
