import React from 'react';

const DialogContext = React.createContext({
  props: {},
  component: null,
  showDialog: () => {},
  hideDialog: () => {}
});

export default DialogContext;
