import React from 'react';
import { IconButton, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { OutlinedPushPin, PushPin } from 'icons';

const ListItemsSubMenu = ({
  menuItem,
  subMenuItem,
  idIndex,
  classes,
  openDropDown,
  handleSubMenu,
  companySlugParam,
  fundSlugParam,
}) => {
  const { id, title, slug, icon, subMenu, isPinned, isPinnable, updatePinStatus } = subMenuItem;

  const systemIcon = isPinnable && (
    <Tooltip title={isPinned ? 'Unpin' : 'Pin'}>
      <IconButton
        onClick={e => {
          e.stopPropagation();
          updatePinStatus(id, title, isPinned);
        }}
        size="small"
        aria-label="pin">
        {isPinned ? (
          <PushPin fontSize="inherit" htmlColor="rgba(255, 255, 255, 0.5)" />
        ) : (
          <OutlinedPushPin
            fontSize="inherit"
            htmlColor="rgba(255, 255, 255, 0.5)"
            className="pin-company"
            opacity="0"
          />
        )}
      </IconButton>
    </Tooltip>
  );

  const actionIcon = systemIcon || icon;

  return (
    <div data-testid="submenu">
      <ListItem
        id={`sidebar-menu-item-${idIndex + 1}`}
        className={clsx(classes.item, classes.subitem, 'sidebar-menu-second-level', {
          [classes.subItemAction]: !!icon,
        })}
        onMouseEnter={event => openDropDown(event, menuItem, subMenuItem)}
        onClick={event => handleSubMenu(event, subMenuItem)}
        aria-describedby="dropdown-anchor"
        aria-controls={slug}>
        {actionIcon && <ListItemIcon className={classes.subItemIcon}>{actionIcon}</ListItemIcon>}
        <ListItemText
          inset={!actionIcon}
          primary={title}
          className={clsx(classes.subitemText, {
            [classes.activeSubMenuItem]: companySlugParam === slug || fundSlugParam === slug,
          })}
        />

        {subMenu && subMenu.length > 0 && (
          <ListItemIcon className={classes.rightDropIcon}>
            <ArrowRight />
          </ListItemIcon>
        )}
      </ListItem>
    </div>
  );
};

ListItemsSubMenu.propTypes = {
  menuItem: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    icon: PropTypes.node,
    subMenu: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        icon: PropTypes.node,
      })
    ),
    filterComponent: PropTypes.node,
  }).isRequired,
  subMenuItem: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    icon: PropTypes.node,
    subMenu: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        icon: PropTypes.node,
      })
    ),
    filterComponent: PropTypes.node,
    isPinned: PropTypes.bool,
    isPinnable: PropTypes.bool,
    updatePinStatus: PropTypes.func,
    id: PropTypes.number,
  }).isRequired,
  idIndex: PropTypes.number.isRequired,
  classes: PropTypes.shape({
    item: PropTypes.string,
    subitem: PropTypes.string,
    subItemAction: PropTypes.string,
    subItemIcon: PropTypes.string,
    subitemText: PropTypes.string,
    activeSubMenuItem: PropTypes.string,
    rightDropIcon: PropTypes.string,
  }).isRequired,
  openDropDown: PropTypes.func.isRequired,
  handleSubMenu: PropTypes.func.isRequired,
  companySlugParam: PropTypes.string,
  fundSlugParam: PropTypes.string,
};

export default ListItemsSubMenu;
