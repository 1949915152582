import { CompaniesService } from 'services';

const getExchangeRate = async (sourceCurrency, targetCurrency, measurementDateId, currencyDate = undefined) => {
  let closePrice = '1'; // Default value if sourceCurrency is the same as targetCurrency
  if (sourceCurrency !== targetCurrency) {
    const companiesService = new CompaniesService();
    // Implement the financials --> cap table currency conversion
    const response = await companiesService.getExchangeRate(
      sourceCurrency,
      targetCurrency,
      measurementDateId,
      currencyDate
    );
    if (response?.valid_exchange_data) {
      closePrice = response?.exchange_rate?.close_price?.value;
    }
  }

  return closePrice;
};

export default getExchangeRate;
