import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import { type IndividualQuestionBaseRepresentation, RequestedFile } from '../../../../../api';
import { MessageBox } from '../../../../../components';
import { ReportDate } from '../../../../services/types';
import { ResponsibleUser } from '../../InformationRequestModal/SourceResponsibleSelector/types';
import useStyles from '../useStyles';

const ConfirmRequest = (props: {
  open: boolean;
  onClose: () => void;
  saving: boolean;
  hasSaved: boolean;
  reportDate: ReportDate | undefined;
  responsibleUser: ResponsibleUser | undefined;
  files: RequestedFile[] | undefined;
  questions: IndividualQuestionBaseRepresentation[] | undefined;
  onSend: () => Promise<void>;
  onFinish: () => void;
}) => {
  const classes = useStyles();
  return (
    <Dialog open={props.open} onClose={props.onClose} title="Information Request" maxWidth="md" fullWidth>
      {!props.saving && !props.hasSaved && (
        <span>
          <DialogTitle id="customized-dialog-title">
            <Typography variant="h6">Information Request</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography align="center" variant="h6" className={classes.title}>
              Do you confirm these details are correct??
            </Typography>
            <br />
            <Table style={{ borderCollapse: 'collapse', border: 'none' }}>
              <TableBody>
                <TableRow className={classes.tablesWithoutBorders}>
                  <TableCell className={classes.tablesWithoutBorders}>
                    <Typography variant="body1" className={classes.tableHead}>
                      Report Date:
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tablesWithoutBorders}>
                    <Typography variant="body1" color="primary">
                      {props.reportDate?.measurement_date?.date}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow className={classes.tablesWithoutBorders}>
                  <TableCell className={classes.tablesWithoutBorders}>
                    <Typography variant="body1" className={classes.tableHead}>
                      Responsible source:
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tablesWithoutBorders}>
                    <Typography variant="body1" color="primary">
                      {props.responsibleUser?.label}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow className={classes.tablesWithoutBorders}>
                  <TableCell className={classes.tablesWithoutBorders}>
                    <Typography variant="body1" className={classes.tableHead}>
                      Documents to be sent:
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tablesWithoutBorders}>
                    {props.files?.map(file => (
                      <Chip
                        key={file.id}
                        className={classes.chipSpace}
                        size="small"
                        label={file.name}
                        color="primary"
                      />
                    ))}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.tablesWithoutBorders}>
                  <TableCell className={classes.tablesWithoutBorders}>
                    <Typography variant="body1" className={classes.tableHead}>
                      Questions to be answered:
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tablesWithoutBorders}>
                    <Typography variant="body1" color="primary">
                      {props.questions?.length}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={props.onClose}>
              <Typography color="primary">Back</Typography>
            </Button>
            <Button autoFocus variant="contained" color="secondary" onClick={props.onSend}>
              Yes, Send
            </Button>
          </DialogActions>
        </span>
      )}
      {props.hasSaved && (
        <span>
          <DialogContent>
            <MessageBox
              type="success"
              title="Request sent successfully"
              tagline="Track what was requested from the information screen"
              action={
                <Button variant="contained" color="primary" onClick={props.onFinish}>
                  Ok
                </Button>
              }
              secondaryAction={undefined}
            />
          </DialogContent>
        </span>
      )}
    </Dialog>
  );
};

export default ConfirmRequest;
