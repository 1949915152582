// #region dependencies
// #endregion
// #region constant
// #endregion
// #region common
import BaseServiceRetryWrapper from './baseRetry';
import { ALLOCATIONS } from '../common/endpoints';
// #endregion

class AllocationsService extends BaseServiceRetryWrapper {
  /**
   * @function
   * @name dataById
   * @description load captable by id selected.
   * @param {INTEGER} dataId the captable id
   */
  dataById = (dataId, exchangeRate) => this.get(`${ALLOCATIONS.byId}/${dataId}?exchange_rate=${exchangeRate}`);

  /**
   * @function
   * @name updateDataById
   * @description update allocation by id selected.
   * @param {INTEGER} dataId the allocation id
   * @param {OBJECT} data the allocation data structure to send
   */
  updateDataById = (dataId, data) => this.put(`${ALLOCATIONS.updateById}/${dataId}`, data);

  /**
   * @function
   * @name updateScenariosById
   * @description update allocation scenarios by id selected.
   * @param {INTEGER} dataId the allocation id
   * @param {OBJECT} data the allocation scenarios data structure to send
   */
  updateScenariosById = (dataId, data, exchangeRate) =>
    this.put(`${ALLOCATIONS.updateScenariosById}/${dataId}/scenarios?exchange_rate=${exchangeRate}`, data);

  postScenarioValues = data => this.post(ALLOCATIONS.postScenarioValues, data);

  publishById = dataId => this.put(`${ALLOCATIONS.publishAllocation}/${dataId}/published`);

  makeFinal = ids => this.put(ALLOCATIONS.makeFinal(ids));

  createVersion = data => this.post(ALLOCATIONS.createVersion, data);

  getScenarioEquityValue = (id, data) => this.post(ALLOCATIONS.getScenarioEquityValue(id), data);

  calculateBacksolveEquityValue = data => this.post(ALLOCATIONS.calculateBacksolveEquityValue, data);

  /**
   * @function
   * @name getRiskFreeRate
   * @description Get risk free rate based on maturity and measurement date.
   * @param {INTEGER} maturity The maturity value.
   * @param {INTEGER} measurementDateID The measurement date id.
   */
  getRiskFreeRateByDate = (maturity, queryDate) => this.get(ALLOCATIONS.getRiskFreeRateByDate(maturity, queryDate));
}

export default AllocationsService;
