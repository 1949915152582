import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 600,
      maxHeight: '100vh',
      boxSizing: 'border-box',
      padding: theme.spacing(3),
    },

    listHeader: {
      marginBottom: theme.spacing(2),
    },
    searchInput: {
      width: '100%',
    },

    companiesList: {
      overflow: 'hidden',
      overflowY: 'auto',
      marginLeft: `-${theme.spacing(1)}px`,
      marginRight: `-${theme.spacing(1)}px`,
      boxSizing: 'border-box',
      maxHeight: 'calc(100vh - 150px);',
    },
  })
);

export default useStyles;
