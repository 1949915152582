import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isUndefined } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useFormat } from 'common/hooks';
import { useStore } from 'common/store';
import { MessageBox } from 'components';
import { LedgerDialog } from 'components/Dialogs';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import BacksolveContext from 'context/BacksolveContext';
import {
  AS_OF_DATE_ALIAS,
  compKeyMap,
  MARKET_ADJUSTMENT_LEDGER_TITLE,
  PERCENTILE_PARSE,
  PERCENTILE_SELECTION_A,
  PERCENTILE_SELECTION_B,
} from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentLedger/util/constants';
import MarketAdjustmentLedgerContext from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentLedger/util/MarketAdjustmentLedgerContext';
import { useRefreshHelpersWithContext } from 'pages/Valuations/components/RefreshGPCOption/hooks';
import { MEAN_LABEL, MEDIAN_LABEL, SPECIFIED_LABEL } from 'pages/Valuations/util/constants';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { useGetCompsByDate, usePrevious } from 'services/hooks';
import { ISO_DATE_FORMAT } from 'utillities';
import parseDatabaseValue from 'utillities/parseDatabaseValue';
import swapMap from 'utillities/swapMap';
import checkAsOfComps from './util/checkAsOfComps';
import colConfig from './util/colConfig';
import getRowConfig from './util/getRowConfig';
import parser from './util/parser';
import { isPercentileAlias, mapPercentileAliasToDatabaseValue } from './util/percentileHelpers';

const MarketAdjustmentLedger = ({ closeDialog }) => {
  const {
    gpcApproach,
    spreadsheets: gpcSheets,
    onMarketAdjustmentLedgerChange,
    approach,
  } = useContext(BacksolveContext);

  // Always use the financials currency. That's what we do in the export and in the rollover. Using a differnet currency
  // can actually result in slightly different values
  const [format, formatDispatch] = useFormat();

  const { companyMeasurementDate, publicCompsAttrs } = useContext(ValuationContext);
  const { firmId } = useStore();
  const [asOfDate, setAsOfDate] = useState(gpcSheets.marketAdjustmentSheet.cells.B2.value);
  const previousAsOfDate = usePrevious(asOfDate);
  const [cellOptions, setSellOptions] = useState({});
  const { marketAdjustmentSheet } = gpcSheets;
  const { isDisabled } = marketAdjustmentSheet.tableData;
  const { getFlattenedComparisons } = useRefreshHelpersWithContext();
  const [fetchGpcCompData] = useGetCompsByDate();

  const [publicComps, setPublicComps] = useState();

  const zipCompData = useCallback(
    ({ asOfComps, comps, fetchedAsOfComps }) =>
      comps.map(comp => {
        const { ticker_symbol: identifier, id: public_company, cap_iq_id } = comp;
        const asOfComp = asOfComps.find(
          comp => (!fetchedAsOfComps ? comp.ticker_symbol : comp.company_ticker.value) === identifier
        );
        const mdComp = comp;
        const company_name = mdComp?.company_name?.value || comp.name;
        const savedComp = approach.valuations_approach_backsolve.public_companies_status?.find(
          ({ public_company: savedPublicCompId }) => savedPublicCompId === public_company
        );

        const { id, enabled } = savedComp || { id: undefined, enabled: true };

        return Object.entries(compKeyMap).reduce(
          (sofar, [key, value]) => {
            // eslint-disable-next-line no-param-reassign
            sofar.asOfComp[value] = (fetchedAsOfComps ? asOfComp?.[key]?.value : asOfComp?.[key]) || 0;
            // eslint-disable-next-line no-param-reassign
            sofar.mdComp[value] = mdComp?.[key] || 0;
            return sofar;
          },
          {
            asOfComp: {},
            mdComp: {},
            company_name,
            public_company,
            identifier,
            id,
            enabled,
            cap_iq_id,
            isDisabled,
          }
        );
      }),
    [approach, isDisabled]
  );

  const publicCompsStatus = useMemo(
    () => approach?.valuations_approach_backsolve?.public_companies_status || [],
    [approach]
  );

  useEffect(() => {
    const handleDataProcessing = async () => {
      if (publicCompsStatus.length < gpcApproach.valuations_approach_gpc.gpc_comparison.length) {
        const currentCapIQs = publicCompsStatus.map(publicComp => publicComp.cap_iq_id);
        const newCompsCapIQ = gpcApproach.valuations_approach_gpc.gpc_comparison
          .filter(comparison => !currentCapIQs.includes(comparison.cap_iq_id))
          .map(comp => comp.cap_iq_id);

        const asOfDateMoment = moment(asOfDate).format(ISO_DATE_FORMAT);
        const asOfCompsData = await fetchGpcCompData(newCompsCapIQ, asOfDateMoment, format.currency.code, firmId, true);
        const flattenedComps = getFlattenedComparisons(asOfCompsData.results);

        flattenedComps.forEach(comp => {
          const {
            ltm_revenue,
            ltm_ebitda,
            ntm_revenue,
            ntm_ebitda,
            market_cap,
            enterprise_value,
            ticker_symbol,
            cap_iq_id,
          } = comp;

          const newPublicCompanyStatus = {
            ltm_revenue,
            ltm_ebitda,
            ntm_revenue,
            ntm_ebitda,
            market_cap,
            enterprise_value,
            ticker_symbol,
            cap_iq_id,
            valuation_approach_backsolve: approach?.valuations_approach_backsolve.id,
            enabled: true,
          };

          publicCompsStatus.push(newPublicCompanyStatus);
        });
      }

      const zippedCompData = zipCompData({
        asOfComps: publicCompsStatus,
        comps: gpcApproach.valuations_approach_gpc.gpc_comparison,
        fetchedAsOfComps: false,
      });

      setPublicComps(zippedCompData);
    };
    const allAsOfValuesAreAvailable = checkAsOfComps(publicCompsStatus);
    if (gpcApproach && companyMeasurementDate.measurement_date.date && asOfDate) {
      const identifiers = gpcApproach.valuations_approach_gpc.gpc_comparison.map(({ ticker_symbol }) => ticker_symbol);
      if (allAsOfValuesAreAvailable && (!previousAsOfDate || previousAsOfDate === asOfDate)) {
        handleDataProcessing();
      } else {
        const asOfDateMoment = moment(asOfDate).format(ISO_DATE_FORMAT);
        const asOfCompDataPromise = fetchGpcCompData(identifiers, asOfDateMoment, format.currency.code, firmId);
        asOfCompDataPromise.then(asOfComps => {
          if (asOfComps) {
            const zippedCompData = zipCompData({
              asOfComps: asOfComps.results,
              comps: gpcApproach.valuations_approach_gpc.gpc_comparison,
              fetchedAsOfComps: true,
            });
            setPublicComps(zippedCompData);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpcApproach, firmId, companyMeasurementDate.measurement_date.date, asOfDate, publicCompsStatus, format]);

  const spreadsheets = useMemo(() => {
    if (publicComps) {
      const formattedMeasurementDate = moment(companyMeasurementDate.measurement_date.date).format('MMM DD, YYYY');
      return [
        new SpreadsheetConfig({
          name: 'marketAdjustmentLedgerTable',
          parser,
          rowConfig: getRowConfig(publicComps, approach, isDisabled),
          tableData: {
            measurementDate: formattedMeasurementDate,
            asOfDate,
            publicComps,
            approach,
            isDisabled,
          },
          columns: colConfig,
          showTitlesColumn: false,
          showToolbar: true,
        }),
      ];
    }
  }, [publicComps, companyMeasurementDate.measurement_date.date, asOfDate, approach, isDisabled]);

  const { onChange, workbook } = useWorkbook(spreadsheets);

  const onLedgerChange = useCallback(
    (cell, expr) => {
      if (cell.alias === AS_OF_DATE_ALIAS) {
        setAsOfDate(expr);
      } else {
        if ([PERCENTILE_SELECTION_A, PERCENTILE_SELECTION_B].includes(cell.alias)) {
          setSellOptions({
            ...cellOptions,
            [cell.alias]: expr,
          });
        }
        onChange(cell, expr);
      }
    },
    [cellOptions, onChange]
  );

  const onSave = useCallback(() => {
    if (!publicComps) {
      return;
    }
    const spreadsheet = spreadsheets[0];
    // only reversing the once cell that needs it that is passed on to greater scope
    // not neccessary to run the reverse parser on every field on this component, just the one cell
    const specifiedAdjustmentCell = spreadsheet.cells.SpecifiedAdjustment;
    const specifiedAdjustmentValue = parseDatabaseValue({
      value: specifiedAdjustmentCell.value,
      type: specifiedAdjustmentCell.dbType,
      defaultValue: specifiedAdjustmentCell.defaultValue,
      format: specifiedAdjustmentCell.format,
      gridType: specifiedAdjustmentCell.gridType,
      decimalPlaces: gpcSheets?.backsolveSheet?.fieldAttributes?.specified_adjustment?.decimal_places,
    });
    let selectedAdjustment = spreadsheet.cells.SelectedAdjustment.value;
    if (![MEDIAN_LABEL, MEAN_LABEL, SPECIFIED_LABEL].includes(selectedAdjustment)) {
      // get the correct option to save by matching the value
      const percentileCells = Object.values(spreadsheet.cells).filter(
        ({ alias, parseAs }) => isPercentileAlias(alias) && parseAs === PERCENTILE_PARSE
      );
      const selectedPercentileCell = percentileCells.find(({ value }) => selectedAdjustment.includes(value.toString()));
      selectedAdjustment = mapPercentileAliasToDatabaseValue(selectedPercentileCell.alias);
    }
    const data = {
      compData: [],
      AsOfDate: spreadsheet.cells.B2.value,
      Metric: spreadsheet.cells.B3.value,
      SelectedAdjustment: selectedAdjustment,
      AppliedAdjustment: spreadsheet.cells.AppliedAdjustment.value,
      SpecifiedAdjustment: specifiedAdjustmentValue,
    };
    const metricsMaps = new Map(Object.entries(compKeyMap));
    const invertedMetricsMaps = swapMap(metricsMaps);
    spreadsheet.rowConfig.forEach((row, index) => {
      if (index >= 3) {
        if (index < 3 + publicComps.length) {
          const publicCompStatus = {
            company_name: row.company_name,
            id: row.id,
            public_company: row.public_company,
            identifier: row.identifier,
            enabled: spreadsheet.cells[`D${index + 1}`].enabled,
            value: spreadsheet.cells[`D${index + 1}`].value,
            cap_iq_id: row.cap_iq_id,
          };
          if (isUndefined(row.public_company)) {
            publicCompStatus.reference_for_backsolve = row.cap_iq_id;
          }
          Object.keys(row.asOfComp).forEach(key => {
            const alias = invertedMetricsMaps.get(key);
            const decimalPlaces = publicCompsAttrs[alias]?.decimal_places || 2;
            publicCompStatus[alias] = Number(row.asOfComp[key]).toFixed(decimalPlaces);
          });
          data.compData.push(publicCompStatus);
        }
        if (index >= 3 + publicComps.length + 2 && index < 3 + publicComps.length + 4) {
          data[`${row.alias}_selection`] = spreadsheet.cells[`C${index + 1}`].value;
        }
        data[row.alias] = spreadsheet.cells[`D${index + 1}`].value;
      }
    });
    onMarketAdjustmentLedgerChange(data);
    closeDialog();
  }, [closeDialog, onMarketAdjustmentLedgerChange, publicComps, spreadsheets, gpcSheets, publicCompsAttrs]);

  const memoizedCellOptions = useMemo(() => ({ cellOptions }), [cellOptions]);

  if (!asOfDate || !gpcApproach) {
    return (
      <LedgerDialog id="market-adjustment-ledger" onClose={closeDialog} title={MARKET_ADJUSTMENT_LEDGER_TITLE}>
        <MessageBox
          title={'No \'As of Date\' or \'Guideline Public Company\''}
          tagline="Please select an As of Date and a Guideline Public Company in the Market Adjustment Table"
        />
      </LedgerDialog>
    );
  }

  if (spreadsheets?.length && publicComps) {
    return (
      <LedgerDialog id="market-adjustment-ledger" onClose={closeDialog} onSave={onSave} disabled={isDisabled}>
        <MarketAdjustmentLedgerContext.Provider value={memoizedCellOptions}>
          <ScalarSpreadsheet
            {...spreadsheets[0]}
            tableTerms={{ tableSlug: 'market-adjustment-ledger' }}
            workbook={workbook}
            onChange={onLedgerChange}
            sheet={spreadsheets[0]}
            displayLegend={false}
            alwaysDisplayLegend={false}
            isLedgerTable
            format={format}
            formatDispatch={formatDispatch}
          />
        </MarketAdjustmentLedgerContext.Provider>
      </LedgerDialog>
    );
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
      <div className="spinner" />
      <p>Loading {MARKET_ADJUSTMENT_LEDGER_TITLE}...</p>
    </div>
  );
};
MarketAdjustmentLedger.propTypes = {
  closeDialog: PropTypes.func,
};
export default MarketAdjustmentLedger;
