import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { STATUS } from 'services/hooks/useRolloverCompanies/rollover.types';

const useStyles = makeStyles(theme => ({
  selected: {
    backgroundColor: theme.palette.gray[100],
  },
  container: {
    overflow: 'auto',
    maxHeight: '60vh',
  },
}));
const StatusListItem = ({ item, index, idNameMap }) => {
  const classes = useStyles();

  return (
    <ListItem className={index % 2 !== 0 ? classes.selected : ''}>
      <ListItemText primary={idNameMap[item.id]} />
      <ListItemIcon>
        {item.status === STATUS.LOADING && <CircularProgress size={24} />}
        {item.status === STATUS.SUCCESS && <CheckCircleOutline color="primary" />}
        {item.status === STATUS.ERROR && (
          <Tooltip title={item.errorMsg}>
            <ErrorOutline color="error" />
          </Tooltip>
        )}
        {item.status === STATUS.CONFLICT && (
          <Tooltip title={item.errorMsg}>
            <CheckCircleOutline />
          </Tooltip>
        )}
      </ListItemIcon>
    </ListItem>
  );
};

StatusListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    errorMsg: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  idNameMap: PropTypes.object.isRequired,
};
export default StatusListItem;
