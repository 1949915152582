import React from 'react';
import { MenuItem, Select, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { COMPANIES, FUND, FUNDS, NO_ITEMS_AVAILABLE } from 'common/constants/user';

const CompanyFundSelect = ({ value, handleSelect, itemType, itemList }) => (
  <>
    <Select
      disabled={itemList?.length === 0}
      style={{ width: '100%' }}
      labelId={`${itemType.toLowerCase()}-label`}
      id={`${itemType.toLowerCase()}-select`}
      value={value || ''}
      onChange={event =>
        handleSelect({
          itemType,
          itemId: event.target.value,
        })
      }>
      {itemList.map(item => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
    {itemList?.length === 0 && (
      <Typography variant="caption">{NO_ITEMS_AVAILABLE(itemType === FUND ? FUNDS : COMPANIES)}</Typography>
    )}
  </>
);

CompanyFundSelect.propTypes = {
  value: PropTypes.number,
  handleSelect: PropTypes.func.isRequired,
  itemType: PropTypes.string.isRequired,
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default CompanyFundSelect;
