import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { uniq } from 'lodash';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  messagesContainer: {
    marginTop: 14,
    marginLeft: 34,
  },
  message: {
    fontSize: 16,
  },
  paragraph: {
    fontSize: 16,
    marginTop: 14,
    marginLeft: 34,
  },
});

const ConfirmationCannotDelete = ({ messages, itemName }) => {
  const classes = useStyles();

  const uniqueMessages = uniq(messages);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignmessages="center">
      <Box alignSelf="start">
        <>
          <p className={classes.paragraph}>This {itemName} cannot be deleted because it is being used in:</p>
          <ul className={classes.messagesContainer}>
            {uniqueMessages.map(message => (
              <li key={message} className={classes.message}>
                {message}
              </li>
            ))}
          </ul>
          <p className={classes.paragraph}>Please remove this {itemName} there first.</p>
        </>
      </Box>
    </Box>
  );
};

ConfirmationCannotDelete.defaultProps = {
  messages: [],
  itemName: 'element',
};

ConfirmationCannotDelete.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string),
  itemName: PropTypes.string,
};

export default ConfirmationCannotDelete;
