import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { firmSummaryUrl, homePath } from 'common/config/urls';
import { useStore } from 'common/store';
import { Logo } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    height: '100%',
  },
  main: {
    padding: theme.spacing(4),
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  container: {
    padding: theme.spacing(10, 4),
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  content: {
    textAlign: 'center',
  },
  margin: {
    marginTop: 30,
  },
  fontLarge: {
    fontSize: 60,
  },
}));

const ErrorComponent = ({ title, subtitle, body }) => {
  const classes = useStyles();
  const [{ firmList, firmInfo }] = useStore();

  const homeUrl = useMemo(() => {
    let auxPath = homePath;
    if (firmInfo) {
      auxPath = firmSummaryUrl(firmInfo.slug);
    } else if (firmList?.length) {
      const [defaultFirm] = firmList;
      auxPath = firmSummaryUrl(defaultFirm.slug);
    }
    return auxPath;
  }, [firmList, firmInfo]);

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <a href="/">
          <Logo variation="onlyWord" />
        </a>
      </header>
      <main className={classes.main}>
        <div className={classes.container}>
          <Grid container justifyContent="center" alignItems="center" spacing={4}>
            <Grid item lg={6} xs={12}>
              <div className={classes.content}>
                <Typography className={classes.fontLarge} variant="h1">
                  {title}
                </Typography>
                <div className={classes.margin}>
                  <Typography variant="h1">{subtitle}</Typography>
                </div>
                <div className={classes.margin}>
                  <Typography variant="subtitle2">{body}</Typography>
                </div>
                <div className={classes.margin}>
                  <a href={homeUrl}>Back to Home</a>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </main>
    </div>
  );
};

ErrorComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  body: PropTypes.string,
};

export default ErrorComponent;
