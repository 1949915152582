import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Logo } from 'components';
import useStyles from '../AuthLayout.styles';

const FirstViewLogo = ({ loading, setLoading }) => {
  const classes = useStyles();
  const onLoadHandler = () => {
    setLoading(false);
  };

  return (
    <Grid item lg={6} className={classes.backImage} style={loading ? { display: 'none' } : {}}>
      <div className={classes.contentImage}>
        <img alt="Background" className={classes.image} src="/images/login-bg.jpg" onLoad={onLoadHandler} />
      </div>
      <div className={classes.headerLogo}>
        <Typography variant="h1">
          <Link to="/">
            <Logo />
          </Link>
        </Typography>
      </div>
      <div className={classes.quoteContainer}>
        <Typography variant="h1">
          The leading
          <br />
          independent
        </Typography>
        <Typography variant="body1">valuation firm</Typography>
      </div>
    </Grid>
  );
};

FirstViewLogo.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default FirstViewLogo;
