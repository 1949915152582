/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from 'react';
import { Button, Link } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import LockIcon from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { valuationPathByDateAndVersion } from 'common/constants/paths';
import { Alert, MessageBox } from 'components';
import { ConfirmationDialog } from 'components/Dialogs';
import DialogContext from 'context/DialogContext';
import UnlockConfirmation from './UnlockConfirmation';

const AllocationFinalMessage = ({
  tableTerms,
  setIsDisabled,
  finalAllocations,
  savesAsProformaConfirmation,
  actions,
  variant,
  children,
  DocumentsDisabled,
  singleAlert,
  isAllocation,
}) => {
  const history = useHistory();
  const { firmSlugParam, companySlugParam } = useParams();
  const { showDialog } = useContext(DialogContext);

  const unlockCapTable = () => {
    setIsDisabled(false);
    if (DocumentsDisabled) {
      DocumentsDisabled(false);
    }
  };

  const manyFinals = finalAllocations?.length > 1;
  const snAlertMessage = 'a final allocation. Any changes can alter its data.';
  const plAlertMessage = 'several final allocations. Any changes can alter their data.';
  const snBoxMessage = 'an allocation marked as final.';
  const plBoxMessage = 'multiple allocations marked as final.';

  const unlockConfirmation = (showDuplicate = true) => {
    const actions = [
      {
        label: 'Unlock',
        variant: 'text',
        type: 'primary',
        callback: unlockCapTable,
      },
    ];

    if (showDuplicate) {
      actions.push({
        label: 'Duplicate',
        variant: 'contained',
        type: 'success',
        callback: savesAsProformaConfirmation,
      });
    }

    showDialog({
      wrapper: ConfirmationDialog,
      wrapperProps: {
        title: 'Confirmation',
      },
      content: UnlockConfirmation,
      actions,
    });
  };

  const alertActions = useMemo(
    () => [
      {
        label: 'Unlock',
        buttonProps: {
          variant: 'text',
          size: 'small',
        },
        callback: unlockConfirmation,
      },
      ...actions,
    ],
    [actions]
  );

  const singleAlertActions = useMemo(
    () => [
      {
        label: 'Unlock',
        buttonProps: {
          variant: 'text',
          size: 'small',
        },
        callback: () => unlockConfirmation(false),
      },
    ],
    []
  );

  const handleClick = (dateSlug, versionSlug) => {
    history.push(valuationPathByDateAndVersion(firmSlugParam, companySlugParam, dateSlug, versionSlug));
  };

  const GoToCapAllocations = () => (
    <Button
      variant="outlined"
      color="primary"
      onClick={() =>
        handleClick(finalAllocations[0].company_measurement_date.measurement_date.slug, finalAllocations[0].slug)
      }
      startIcon={<ArrowBackIcon />}>
      See Allocation
    </Button>
  );

  const ListAllocations = () => {
    if (finalAllocations.length > 0) {
      return finalAllocations.map(alloc => (
        <li key={alloc.slug}>
          <Link
            component="button"
            variant="body2"
            underline="none"
            onClick={() => handleClick(alloc.company_measurement_date.measurement_date.slug, alloc.slug)}>
            {`See Allocation ${alloc.name}`}
          </Link>
        </li>
      ));
    }
  };

  if (singleAlert) {
    return (
      <Alert isAlertVisible icon={<LockIcon fontSize="small" />} actions={singleAlertActions}>
        This {tableTerms.tableName} is being used on {manyFinals ? plAlertMessage : snAlertMessage}
      </Alert>
    );
  }

  return variant ? (
    <Alert isAlertVisible icon={<LockIcon fontSize="small" />} actions={alertActions}>
      {isAllocation
        ? 'This allocation table is marked as Final, any change can alter this data.'
        : `This ${tableTerms.tableName} is being used on ${manyFinals ? plAlertMessage : snAlertMessage}`}
    </Alert>
  ) : (
    <>
      <Alert isAlertVisible icon={<LockIcon fontSize="small" />} actions={alertActions}>
        This {tableTerms.tableName} is being used on {manyFinals ? plAlertMessage : snAlertMessage}
      </Alert>
      <MessageBox
        title="Final Allocation associated"
        tagline={`This ${tableTerms.tableName} is being used on ${manyFinals ? plBoxMessage : snBoxMessage}`}
        fullWidth={false}
        action={manyFinals ? <ListAllocations /> : <GoToCapAllocations />}
      />
      {children}
    </>
  );
};

AllocationFinalMessage.defaultProps = {
  actions: [],
};

AllocationFinalMessage.propTypes = {
  tableTerms: PropTypes.shape({
    tableName: PropTypes.string,
  }).isRequired,
  savesAsProformaConfirmation: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  setIsDisabled: PropTypes.func.isRequired,
  children: PropTypes.elementType,
  finalAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      company_measurement_date: PropTypes.shape({
        measurement_date: PropTypes.shape({
          slug: PropTypes.string,
        }),
      }),
    })
  ),
  variant: PropTypes.bool,
  DocumentsDisabled: PropTypes.func,
  singleAlert: PropTypes.bool,
  isAllocation: PropTypes.bool,
};

export default AllocationFinalMessage;
