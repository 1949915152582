import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    avatar: {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: theme.spacing(1.5),
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      fontSize: '1rem',
      fontWeight: 700,
      backgroundColor: theme.palette.white,
    },
  })
);

export default useStyles;
