import { TASKS } from 'common/endpoints';
import BaseServiceRetryWrapper from './baseRetry';

class TasksService extends BaseServiceRetryWrapper {
  validateQuestionnairesRequestToken = (requestId, token) =>
    this.get(TASKS.validateQuestionnairesRequestToken(requestId, token));

  updateQuestionnaireRequestToken = (requestId, token, data) =>
    this.post(TASKS.updateQuestionnaireRequestToken(requestId, token), data);

  getQuestionOptions = () => this.get(TASKS.getQuestionOptions());
}

export default TasksService;
