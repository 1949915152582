import React from 'react';
import { Box, Radio } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import { ALLOCATIONS_DRAFT, ALLOCATIONS_FINAL, ALLOCATIONS_PUBLISHED } from 'common/constants/allocations';
import theme from 'theme';

const GreenRadio = withStyles({
  root: {
    padding: theme.spacing(0.5),
    '& svg': {
      fill: `${green[400]} !important`,
      width: '0.9em !important',
      height: '0.9em !important',
    },
  },
})(props => <Radio {...props} />);

const GreyRadio = withStyles({
  root: {
    padding: theme.spacing(0.5),
    '& svg': {
      fill: `${grey[400]} !important`,
      width: '0.9em !important',
      height: '0.9em !important',
    },
  },
})(props => <Radio {...props} />);

const cell = {
  readOnly: true,
  value: '',
};

const radioCell = ({ value, checked, handleChange, disabled }) => {
  const props = {
    checked,
    disabled,
    value,
    size: 'small',
    onChange: handleChange,
  };

  return {
    forceComponent: true,
    component: (
      <Box display="flex" justifyContent="center">
        {disabled && value !== ALLOCATIONS_FINAL ? <GreyRadio {...props} /> : <GreenRadio {...props} />}
      </Box>
    ),
  };
};

const companiesParser = ({ items, selectedCompanies, updateCompanyAllocation }) => {
  const columns = ['Companies', 'Draft', 'Published', 'Final'];

  const header = () =>
    columns.map(column => ({
      ...cell,
      value: column,
      className: 'table-header',
    }));

  const rows = [];

  items.forEach(fund => {
    const fundRows = [];

    fund.subItems.forEach(company => {
      const selectedCompany = selectedCompanies[company.id];

      if (selectedCompany?.allocationId) {
        const { allocationStatus } = selectedCompany;

        fundRows.push([
          {
            ...cell,
            value: company.name,
          },
          {
            // Draft Column
            ...radioCell({
              value: ALLOCATIONS_DRAFT,
              checked: allocationStatus === ALLOCATIONS_DRAFT,
              disabled: company.isPublished || company.isFinal,
              handleChange: e => updateCompanyAllocation(company.id, e.target.value),
            }),
          },
          {
            // Pusblished Column
            ...radioCell({
              value: ALLOCATIONS_PUBLISHED,
              checked: allocationStatus === ALLOCATIONS_PUBLISHED,
              disabled: company.isFinal,
              handleChange: e => updateCompanyAllocation(company.id, e.target.value),
            }),
          },
          {
            // Final Column
            ...radioCell({
              value: ALLOCATIONS_FINAL,
              checked: allocationStatus === ALLOCATIONS_FINAL,
              disabled: company.isFinal,
              handleChange: e => updateCompanyAllocation(company.id, e.target.value),
            }),
          },
        ]);
      }
    });

    if (fundRows.length) {
      rows.push(
        [
          {
            ...cell,
            value: fund.name,
            colSpan: columns.length,
            className: 'row-title',
          },
        ],
        ...fundRows
      );
    }
  });

  return [header(), ...rows];
};

export default companiesParser;
