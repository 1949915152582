import { ENTER_DATA } from 'common/constants/inputs';
import { largeCurrencyFormat, standardDecimalFormat } from 'common/formats/formats';
import * as constants from './constants';

const rowConfig = [
  {
    titleClassName: 'title-first',
    value: constants.NET_OPERATING_LOSS_TITLE,
    alias: constants.NET_OPERATING_LOSS_ALIAS,
    hidden: false,
    readOnly: true,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    titleClassName: 'title-first',
    value: constants.EBIT_TITLE,
    alias: constants.EBIT_ALIAS,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.NOL_BALANCE_APPLIED_UP_TO_100_TITLE,
    alias: constants.NOL_BALANCE_APPLIED_UP_TO_100_ALIAS,
    expr: constants.NOL_BALANCE_APPLIED_UP_TO_100_EXPR,
    format: standardDecimalFormat,
    hidden: false,
    readOnly: true,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.PRE_TAX_CUTS_JOBS_ACT_TITLE,
    alias: constants.PRE_TAX_CUTS_JOBS_ACT_ALIAS,
    expr: constants.PRE_TAX_CUTS_JOBS_ACT_EXPR,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    enterDataMessage: ENTER_DATA,
  },
  {
    titleClassName: 'title-first',
    className: 'subtitle',
    value: constants.EBIT_AFTER_PRE_TCJA_NOL_APPLICATION_TITLE,
    alias: constants.EBIT_AFTER_PRE_TCJA_NOL_APPLICATION_ALIAS,
    expr: constants.EBIT_AFTER_PRE_TCJA_NOL_APPLICATION_EXPR,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.NOL_BALANCE_APPLIED_UP_TO_80_TITLE,
    alias: constants.NOL_BALANCE_APPLIED_UP_TO_80_ALIAS,
    expr: constants.NOL_BALANCE_APPLIED_UP_TO_80_EXPR,
    format: standardDecimalFormat,
    hidden: false,
    readOnly: true,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.FULLY_TAXABLE_INCOME_TITLE,
    alias: constants.FULLY_TAXABLE_INCOME_ALIAS,
    expr: constants.FULLY_TAXABLE_INCOME_EXPR,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.POST_TAX_CUTS_JOBS_ACT_NOL_BALANCE_TITLE,
    alias: constants.POST_TAX_CUTS_JOBS_ACT_NOL_BALANCE_ALIAS,
    expr: constants.POST_TAX_CUTS_JOBS_ACT_NOL_BALANCE_EXPR,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    enterDataMessage: ENTER_DATA,
  },
  {
    titleClassName: 'title-first',
    className: 'subtitle',
    value: constants.TOTAL_NOL_BALANCE_APPLIED_TITLE,
    alias: constants.TOTAL_NOL_BALANCE_APPLIED_ALIAS,
    expr: constants.TOTAL_NOL_BALANCE_APPLIED_EXPR,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    titleClassName: 'title-first',
    className: 'subtitle',
    value: constants.TAX_SAVINGS_AT_RATE_TITLE,
    alias: constants.TAX_SAVINGS_AT_RATE_ALIAS,
    expr: constants.TAX_SAVINGS_AT_RATE_EXPR,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.DISCOUNT_PERIODS_TITLE,
    alias: constants.DISCOUNT_PERIODS_ALIAS,
    format: standardDecimalFormat,
    hidden: false,
    readOnly: true,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    value: constants.DISCOUNT_FACTOR_TITLE,
    alias: constants.DISCOUNT_FACTOR_ALIAS,
    format: standardDecimalFormat,
    hidden: false,
    readOnly: true,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
  },
  {
    titleClassName: 'title-first',
    className: 'subtitle',
    value: constants.PRESENT_VALUE_OF_NOL_TAX_SAVINGS_TITLE,
    alias: constants.PRESENT_VALUE_OF_NOL_TAX_SAVINGS_ALIAS,
    expr: constants.PRESENT_VALUE_OF_NOL_TAX_SAVINGS_EXPR,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    dbDecimalPlaces: 2,
  },
];

export default rowConfig;
