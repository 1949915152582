import React, { useContext } from 'react';
import { Box, Button, List, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  GO_TO_QUESTIONS,
  THANK_YOU_FOR_UPLOADING_DOCUMENTS,
  UPLOAD_MORE_DOCUMENTS,
} from 'common/constants/process-management';
import { TaskContext } from 'context';
import theme from 'theme';
import CircularProgressWithLabel from './CircularProgressWithLabel';

const useStyles = makeStyles({
  taskCompleted: isMobile => ({
    marginTop: !isMobile && '3rem',
    borderWidth: !isMobile && '0 0.125rem 0.125rem 0.125rem',
    borderStyle: 'solid',
    borderColor: theme.palette.primary[900],
    borderRadius: !isMobile && '0.5rem',
    width: isMobile ? '100%' : '70%',
    backgroundColor: theme.palette.primary[900],
    padding: !isMobile && '1.5rem 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: isMobile && 'fixed',
    zIndex: isMobile && '10000',
    height: isMobile && '100%',
  }),
  taskCompletedText: {
    color: theme.palette.white,
    fontSize: '1.125rem',
    fontWeight: '700',
    textAlign: 'center',
    marginTop: '1.5rem',
  },
  completedFilesItem: {
    color: theme.palette.white,
    fontSize: '0.875rem',
  },
});

const TaskCompletedBox = ({ progress, requestedFiles, isMobile, handleNeedToUploadMore, pairedQuestionnaire }) => {
  const classes = useStyles(isMobile);
  const { questionnaireUrl } = useContext(TaskContext);

  return (
    <Box className={classes.taskCompleted}>
      <Box padding="0 3rem" display="flex" justifyContent="center" alignItems="flex-start" flexDirection="column">
        <Box margin="0 auto">
          <CircularProgressWithLabel
            progress={progress}
            numberStyles={{ fontSize: '1.35rem', color: theme.palette.white }}
            circleSize="5.375rem"
          />
        </Box>
        <Typography variant="h3" className={classes.taskCompletedText}>
          {THANK_YOU_FOR_UPLOADING_DOCUMENTS}
        </Typography>
        <List
          style={{
            listStyle: 'disc',
            margin: '0.5rem 0 0 1rem',
          }}>
          {requestedFiles.map(file => (
            <li key={file.id} className={classes.completedFilesItem}>
              {file.name}
            </li>
          ))}
        </List>
        {pairedQuestionnaire && (
          <Button
            variant="outlined"
            style={{
              color: theme.palette.white,
              borderColor: theme.palette.white,
              marginTop: '1.5rem',
              width: '100%',
            }}
            component={Link}
            to={questionnaireUrl || ''}>
            {GO_TO_QUESTIONS}
          </Button>
        )}
        <Button
          style={{
            color: theme.palette.white,
            fontWeight: '700',
            marginTop: '1.5rem',
            width: '100%',
            fontSize: '1.125rem',
            textTransform: 'none',
            textDecoration: `underline ${theme.palette.primary[500]}`,
            textUnderlinePosition: 'under',
          }}
          onClick={handleNeedToUploadMore}>
          {UPLOAD_MORE_DOCUMENTS}
        </Button>
      </Box>
    </Box>
  );
};

TaskCompletedBox.propTypes = {
  progress: PropTypes.number.isRequired,
  requestedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  isMobile: PropTypes.bool,
  handleNeedToUploadMore: PropTypes.func,
  pairedQuestionnaire: PropTypes.shape({}),
};

export default TaskCompletedBox;
