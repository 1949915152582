import React, { useContext, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckBox as CheckBoxIcon } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import CompGroupDialogContext from 'pages/Valuations/components/CompGroups/components/common/CompGroupDialogContext';
import FormLabelCompGroup from 'pages/Valuations/components/CompGroups/components/common/FormLabelCompGroup';
import { FIRM_COMP_GROUPS, SCALAR_COMP_GROUPS } from 'pages/Valuations/util/constants';

const useStyles = makeStyles(theme => ({
  groupLabel: {
    color: theme.palette.gray[300],
    fontSize: '10px',
    fontWeight: 'bold',
  },
  option: {
    '& > span.icon': {
      color: theme.palette.green[500],
      marginLeft: 5,
      display: 'inline-flex',
    },
  },
}));

const PublicCompsList = ({ setCompGroupId }) => {
  const classes = useStyles();
  const { compGroupList, compGroupSelected, setCompaniesToDelete, setCompGroupToDelete }
    = useContext(CompGroupDialogContext);

  const handleOnSelectCompGroup = value => {
    const compGroupId = value ? value.id : -1;
    setCompaniesToDelete([]);
    setCompGroupToDelete(null);
    setCompGroupId(compGroupId);
  };

  const [globalComps, setGlobalComps] = useState();
  const [firmComps, setFirmComps] = useState();
  // Reorder comps to put the Firm comps first in the dropdown
  const reorderComps = firmComps?.concat(globalComps);

  useEffect(() => {
    setGlobalComps(compGroupList?.filter(compGroup => compGroup.is_global));
    setFirmComps(compGroupList?.filter(compGroup => !compGroup.is_global));
  }, [compGroupList]);

  const options = reorderComps || compGroupList;
  const selectedValue = options.find(option => option.id === compGroupSelected?.id) || null;

  return (
    <>
      <FormLabelCompGroup title="Comp Group:"></FormLabelCompGroup>
      <Autocomplete
        id="comp-groups-autocomplete"
        value={selectedValue}
        classes={{
          option: classes.option,
          groupLabel: classes.groupLabel,
        }}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={option => option.name || ''}
        options={options}
        groupBy={option => (option.is_global ? SCALAR_COMP_GROUPS : FIRM_COMP_GROUPS)}
        style={{
          marginRight: '1rem',
        }}
        renderInput={params => (
          <TextField {...params} placeholder="Select Comps" variant="outlined" sx={{ padding: 0 }} />
        )}
        onChange={(__event, value) => handleOnSelectCompGroup(value)}
        renderOption={option => (
          <React.Fragment>
            {option.name}
            {option.checked && (
              <span className={'icon'}>
                <CheckBoxIcon />
              </span>
            )}
          </React.Fragment>
        )}
      />
    </>
  );
};

PublicCompsList.propTypes = {
  setCompGroupId: PropTypes.func,
  compGroupId: PropTypes.number,
};

export default PublicCompsList;
