import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppBar, Button, Container, Dialog, IconButton, Slide, Toolbar, Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import SendIcon from '@material-ui/icons/Send';
import { useSelector } from 'react-redux';
import ConfirmRequest from './components/ConfirmRequest';
import { CreateInformationRequestModalType } from './types';
import useStyles from './useStyles';
import { ApiService } from '../../../../api';
import { manageApiRequest } from '../../../../hooks/apiRequest';
import RequestsContent from '../../../../pages/Firms/Requests/components/RequestContent';
import { RootStateType } from '../../../states/store';
import { SourceResponsibleSelector } from '../InformationRequestModal/SourceResponsibleSelector';
import { ResponsibleUser } from '../InformationRequestModal/SourceResponsibleSelector/types';

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const CreateInformationRequestModal: React.FC<CreateInformationRequestModalType> = ({
  isOpen,
  setIsOpen,
  informationRequestStatuses,
  setUpdateTaskList,
  currentInformationRequests,
  companyUsers,
}) => {
  const classes = useStyles();
  const { allValuationScalarOpinion } = useSelector((state: RootStateType) => state.valuation);

  const [isSavingModelOpen, setIsSavingModelOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const handleClose = useCallback(() => {
    setHasSaved(false);
    setIsSavingModelOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  const responsibleUsers = useMemo<Array<ResponsibleUser>>(
    () =>
      companyUsers.map(user => ({
        value: user.id!,
        label: `${user.first_name} ${user.last_name}`,
        isPrimaryContact: !!user.is_primary_contact,
      })),
    [companyUsers]
  );

  const getPrimaryContact = useCallback(
    () => responsibleUsers.find(userParam => userParam.isPrimaryContact)?.value,
    [responsibleUsers]
  );
  const [responsibleUserId, setResponsibleUserId] = useState<number | undefined>(getPrimaryContact());

  const responsibleUser = useMemo(
    () => responsibleUsers.find(user => user.value === responsibleUserId),
    [responsibleUserId, responsibleUsers]
  );

  useEffect(() => {
    if (responsibleUsers.length && !responsibleUserId) {
      setResponsibleUserId(getPrimaryContact());
    }
  }, [getPrimaryContact, responsibleUserId, responsibleUsers]);

  const closeSaveModel = useCallback(() => {
    setIsSavingModelOpen(false);
  }, []);

  const hasCurrentInformationRequests = useMemo(
    () => currentInformationRequests.length === 2,
    [currentInformationRequests]
  );

  // find the category 1 currentInformationRequests and make a map of the progress property, which is a list of objects with ids
  // then do the same with category 2 requests
  // then filter out the questions and files that match ids with the progress property
  const createdFileIdSet = useMemo(
    () =>
      currentInformationRequests
        .filter(request => request.category === 1)
        .map(request => request.progress)
        .flat()
        .reduce((acc, progress) => {
          acc.add(progress.id);
          return acc;
        }, new Set()),
    [currentInformationRequests]
  );

  const createdQuestionIdSet = useMemo(
    () =>
      currentInformationRequests
        .filter(request => request.category === 2)
        .map(request => request.progress)
        .flat()
        .reduce((acc, progress) => {
          acc.add(progress.id);
          return acc;
        }, new Set()),
    [currentInformationRequests]
  );

  //   when we start doing other types of valuations we will make change here
  const primaryInformationRequest = useMemo(
    () => informationRequestStatuses.find(status => status.valuation_type_name === '409a'),
    [informationRequestStatuses]
  );

  const questions = useMemo(
    () =>
      primaryInformationRequest?.questions?.filter(question => question.id && !createdQuestionIdSet.has(question.id)),
    [primaryInformationRequest, createdQuestionIdSet]
  );

  const files = useMemo(
    () => primaryInformationRequest?.files?.filter(file => file.id && !createdFileIdSet.has(file.id)),
    [primaryInformationRequest, createdFileIdSet]
  );

  const reportDate = useMemo(
    () =>
      (allValuationScalarOpinion?.map(scalarOpinion => scalarOpinion.report_date) || []).find(
        reportDate => reportDate.id === primaryInformationRequest?.report_date
      ),
    [allValuationScalarOpinion, primaryInformationRequest]
  );

  const handleSave = useCallback(async () => {
    if (!primaryInformationRequest?.id) return;
    // set piece of state to indicate we are saving
    setIsSaving(true);
    if (hasCurrentInformationRequests) {
      await manageApiRequest(
        ApiService.apiUpdateInformationRequestStatusUpdate(primaryInformationRequest.id.toString(), {
          id: primaryInformationRequest.id,
        })
      );
    } else if (responsibleUserId) {
      await manageApiRequest(
        ApiService.apiCreateInformationRequestUpdate(primaryInformationRequest.id.toString(), {
          responsible_users: [responsibleUserId],
          send_email: true,
        })
      );
    }
    //   unset saving state
    setIsSaving(false);
    setHasSaved(true);
    setUpdateTaskList(true);
  }, [primaryInformationRequest, setUpdateTaskList, hasCurrentInformationRequests, responsibleUserId]);

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar color="transparent" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Information Request
          </Typography>
          <Button
            autoFocus
            variant="contained"
            color="secondary"
            endIcon={<SendIcon />}
            onClick={() => setIsSavingModelOpen(true)}>
            {hasCurrentInformationRequests ? 'Update' : 'Send'}
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl">
        <RequestsContent
          documents={files}
          questions={questions}
          setUpdateTaskList={setUpdateTaskList}
          primaryInformationRequestId={primaryInformationRequest?.id}
          isInitialSetup
        />
        <SourceResponsibleSelector
          responsibleUser={responsibleUserId}
          setResponsibleUser={setResponsibleUserId}
          responsibleUsers={responsibleUsers}
          isDisabled={hasCurrentInformationRequests}
        />
      </Container>
      {reportDate && (
        <ConfirmRequest
          open={isSavingModelOpen}
          onClose={closeSaveModel}
          saving={isSaving}
          hasSaved={hasSaved}
          reportDate={reportDate}
          responsibleUser={responsibleUser}
          files={files}
          questions={questions}
          onSend={handleSave}
          onFinish={handleClose}
        />
      )}
    </Dialog>
  );
};

export default CreateInformationRequestModal;
