/**
 * @name Funds Config
 * @memberof module:common/config
 * @type {Config}
 * @return {Object} Configurations of API
 */

import API from '../config';

const base = `${API.BASE_DEV_HOST}/api/funds`;

export const byId = `${base}`;
export const updateById = `${base}`;
export const newFund = `${base}`;
export const fundById = fundId => `${base}/${fundId}`;
export const getMeasurementDateByFundId = fundId => `${base}/measurement-dates/funds/${fundId}`;
export const createCompanyForMeasurementDate = mpId => `${base}/measurement-dates/${mpId}/company`;
export const addCompanyToMeasurementDate = `${API.BASE_DEV_HOST}/fund-companies`;
export const getFundSummaryByMD = (fundId, mdId) => `${base}/${fundId}/summary?md=${mdId}`;
export const getFundInvestmentByMD = (fundId, mdId) => `${base}/${fundId}/investments?md=${mdId}`;
export const getFundAllocationsByMD = (fundId, mdId) => `${base}/${fundId}/allocations?md=${mdId}`;
export const getCompaniesByFundId = fundId => `${base}/${fundId}/companies/`;
