import { isEmpty, isNil } from 'lodash';
import { ScenarioValue, ScenarioValues } from 'common/types/allocation';
import { ALLOCATION_SCENARIO_TYPE_BACKSOLVE } from 'pages/ValuationsAllocation/common/constants/allocation';
import { getAllocationBacksolve } from 'pages/ValuationsAllocation/util/getAllocationBacksolve';
import { getMethodologiesScenarioValues } from 'pages/ValuationsAllocation/util/getMethodologiesScenarioValues';
import { generateColumnKey, getArrayValue, getNumberValue, getObjectValue, getStringValue } from 'utillities';
import { GetBacksolveScenarioValuesParams } from './types';

const getBacksolveScenarioValues = (params: GetBacksolveScenarioValuesParams) => {
  const { allocationScenariosValues, approaches, scenario } = params;

  const { id: scenarioId, scenario_ref: scenarioRef, scenario_type: scenarioType } = getObjectValue(scenario);

  const isBacksolve = [ALLOCATION_SCENARIO_TYPE_BACKSOLVE].includes(getNumberValue(scenarioType));

  // Scenario Id or Ref
  const scenarioIdOrRef = getStringValue(scenarioId ? scenarioId?.toString() : scenarioRef);

  // Backsolve
  const { backsolveValuationApproach } = getAllocationBacksolve({
    approaches,
    scenario,
  });

  const { applied_methodologies: backsolveAppliedMethodologies } = getObjectValue(backsolveValuationApproach);

  // Backsolve Scenario Values
  const appliedMethodologiesScenariosValues = backsolveAppliedMethodologies
    ?.map(AppliedMethodology => {
      const { allocation_method: allocationMethod } = getObjectValue(AppliedMethodology);

      const currentScenarioValues = getObjectValue(
        allocationScenariosValues?.[
          generateColumnKey({
            id: scenarioIdOrRef,
            name: allocationMethod?.toString(),
          })
        ]
      );

      const aggregateValuesWithMethod = currentScenarioValues?.aggregate_values?.map(
        aggregateValue =>
          ({
            ...aggregateValue,
            allocationMethod,
          } as ScenarioValue)
      );

      const futureValuesWithMethod = currentScenarioValues?.future_values?.map(
        futureValue =>
          ({
            ...futureValue,
            allocationMethod,
          } as ScenarioValue)
      );

      const presentValuesWithMethod = currentScenarioValues.present_values?.map(
        presentValue =>
          ({
            ...presentValue,
            allocationMethod,
          } as ScenarioValue)
      );

      // Scenario Values are empty
      if (isNil(aggregateValuesWithMethod || futureValuesWithMethod || presentValuesWithMethod)) return null;

      return {
        aggregate_values: aggregateValuesWithMethod,
        future_values: futureValuesWithMethod,
        present_values: presentValuesWithMethod,
      } as ScenarioValues;
    })
    ?.filter(Boolean) as ScenarioValues[];

  // Methodologies Scenario Values are empty
  if (isEmpty(appliedMethodologiesScenariosValues)) return null;

  const backsolveAggregateValues = appliedMethodologiesScenariosValues?.map(scenarioValues =>
    getArrayValue(scenarioValues?.aggregate_values)
  );

  const backsolvePresentValues = appliedMethodologiesScenariosValues?.map(scenarioValues =>
    getArrayValue(scenarioValues?.present_values)
  );

  const backsolveFutureValues = appliedMethodologiesScenariosValues?.map(scenarioValues =>
    getArrayValue(scenarioValues?.future_values)
  );

  const backsolveScenarioValues = isBacksolve
    ? ({
      aggregate_values: getMethodologiesScenarioValues({
        scenarioValues: backsolveAggregateValues,
        appliedMethodologies: backsolveAppliedMethodologies,
      }),
      future_values: getMethodologiesScenarioValues({
        scenarioValues: backsolveFutureValues,
        appliedMethodologies: backsolveAppliedMethodologies,
      }),
      present_values: getMethodologiesScenarioValues({
        scenarioValues: backsolvePresentValues,
        appliedMethodologies: backsolveAppliedMethodologies,
      }),
    } as ScenarioValues)
    : null;

  return backsolveScenarioValues;
};

export default getBacksolveScenarioValues;
