/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, isEqual, keyBy } from 'lodash';
import PropTypes from 'prop-types';
import { RISKFREERATE_ALIAS } from 'common/constants/allocations';
import { LedgerTable } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import AllocationContext from 'context/AllocationContext';
import { useGetRiskFreeRate } from 'services/hooks/allocation';
import usePrevious from 'services/hooks/usePrevious';
import { filterTableCells, getLedgerKey, getRowKey } from 'utillities';
import { colConfig, rowConfig } from './data';
import { reverseCellsParser } from './utils';
import { baseCellsParser } from '../../utilities';

const initialData = [{ alias: 'value' }];

const OpmInputs = ({
  cell,
  setIsAlertVisible,
  setIsValid,
  isDisabled,
  tableId,
  sharedCells,
  setSharedCells,
  setDialogData,
  doFullValidation,
  updateValidationStatus,
}) => {
  const { cells } = useContext(FeaturedSpreadsheetContext);
  const allocationContext = useContext(AllocationContext);

  const [rows, setRows] = useState(initialData);
  const prevRows = usePrevious(rows);

  // Extract as getRiskFreeRate (instead of getRiskFreeRateByDate) to
  // indicate that we will use the maturity and the measurement date id
  const [getRiskFreeRate] = useGetRiskFreeRate();

  // Filter local cells
  const tableCells = useMemo(() => {
    if (!isEmpty(sharedCells)) {
      return filterTableCells(sharedCells, tableId);
    }
    return {};
  }, [sharedCells]);

  const previousTableCells = usePrevious(tableCells);

  const parseCells = async ledgerData => {
    const parsedCells = await baseCellsParser(ledgerData, colConfig, rowConfig, tableId);
    setSharedCells(state => ({ ...state, ...parsedCells }));
  };

  const getRFRValue = async maturityValue => {
    const { measurementDateId } = allocationContext;
    const rfr = await getRiskFreeRate(maturityValue, measurementDateId);

    const newRows = rows.map(row => {
      if (row.alias === RISKFREERATE_ALIAS) {
        row.value = rfr;
      }

      return row;
    });

    const parsedCells = await baseCellsParser(newRows, colConfig, rowConfig, tableId);
    setSharedCells(state => ({ ...state, ...parsedCells }));
  };

  // Set initial rows data
  useEffect(() => {
    if (cells) {
      const ledgerKey = getLedgerKey(cell.columnLegend);
      const ledgersData = cells[ledgerKey];
      const ledgerData = ledgersData?.[tableId] || initialData;

      setRows(ledgerData);
      parseCells(ledgerData);
    }
  }, []);

  // Update rows on table cells changes
  useEffect(() => {
    if (!isEmpty(tableCells) && !isEqual(tableCells, previousTableCells)) {
      const tmpRows = reverseCellsParser(tableCells);
      setRows(tmpRows);
      setDialogData(state => ({ ...state, [tableId]: tmpRows }));
      setSharedCells(state => ({ ...state, ...tableCells }));
    }
  }, [tableCells]);

  useEffect(() => {
    const { maturity } = keyBy(rows, 'alias');
    const { maturity: prevValue } = keyBy(prevRows, 'alias');

    if (maturity?.value && maturity?.value !== prevValue?.value) {
      getRFRValue(maturity?.value);
    }
  }, [rows]);

  return (
    <>
      <LedgerTable
        id="opm-inputs"
        colConfig={colConfig}
        rows={initialData}
        setRows={setRows}
        tableTerms={{
          tableName: 'OPM Inputs',
          tableSlug: 'opm-inputs',
          columnName: 'OPM Input',
          pluralColumnName: 'Approaches',
        }}
        setIsAlertVisible={setIsAlertVisible}
        disabled={isDisabled}
        cells={tableCells}
        sharedCells={sharedCells}
        setCells={setSharedCells}
        data={rows}
        setIsValid={setIsValid}
        doFullValidation={doFullValidation}
        updateValidationStatus={updateValidationStatus}
        showAddRowButton={false}
        showDeleteColumn={false}
        getKey={getRowKey}
        tableId={tableId}
        inLedger
      />
    </>
  );
};

OpmInputs.defaultProps = {
  ledgerScope: {},
};

OpmInputs.propTypes = {
  dialogData: PropTypes.object,
  setDialogData: PropTypes.func,
  cell: PropTypes.object,
  isTableValid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  setIsAlertVisible: PropTypes.func,
  setIsValid: PropTypes.func,
  data: PropTypes.object,
  tableId: PropTypes.string,
  sharedCells: PropTypes.object.isRequired,
  setSharedCells: PropTypes.func.isRequired,
  doFullValidation: PropTypes.bool,
  updateValidationStatus: PropTypes.func,
};

export default OpmInputs;
