/**
 *
 * @param {*} financialStatementData
 * @param {*} getVersions
 * @param {*} create
 * @param {*} update
 * @param {*} financialStatementSetter
 */
const updateOrCreateFinancialStatement = async ({
  financialStatementData,
  getVersions,
  create,
  update,
  financialStatementSetter,
}) => {
  let savedFinancialStatement = {};

  if (!financialStatementData.id) {
    savedFinancialStatement = await create(financialStatementData);
  } else {
    savedFinancialStatement = await update(financialStatementData.id, financialStatementData);
  }
  // changing names requires versions update
  if (savedFinancialStatement) {
    // This function will not be present when updating the statement name
    if (financialStatementSetter) {
      financialStatementSetter(savedFinancialStatement);
    }
    getVersions();
  }
};

export default updateOrCreateFinancialStatement;
