import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from 'theme';

const CustomCircularProgress = ({ value }) => (
  <Box sx={{ position: 'relative' }}>
    <CircularProgress variant="determinate" style={{ color: theme.palette.grey[200] }} value={100} />
    <CircularProgress variant="determinate" value={value} style={{ position: 'absolute', left: 0 }} />
  </Box>
);

CustomCircularProgress.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CustomCircularProgress;
