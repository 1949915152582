import { ValuationsApproach } from 'common/types/valuation';

const findFirstBenchmarkApproach = (approaches: ValuationsApproach[]): ValuationsApproach | null =>
  approaches?.find(
    approach =>
      Boolean(approach?.valuations_approach_gpc?.is_benchmark)
      || Boolean(approach?.valuations_approach_gpt?.is_benchmark)
  ) ?? null;

export default findFirstBenchmarkApproach;
