import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import {
  MEASUREMENT_DATES_BY_FUND_DEFAULT_ERROR_MESSAGE,
  MEASUREMENT_DATES_BY_FUND_QUERY_KEY,
} from 'common/constants/services/funds';
import { FundService } from 'services';
import { throwError } from 'utillities';
import {
  GetMeasurementDatesByFundHook,
  GetMeasurementDatesByFundParams,
  GetMeasurementDatesByFundResponse,
  UseGetMeasurementDatesByFund,
} from './types';

const getMeasurementDatesByFund = async (params: GetMeasurementDatesByFundParams) => {
  const { fundId } = params;

  if (isNil(fundId)) return null;

  const fundService = new FundService();

  try {
    const measurementDates = (await fundService.getMeasurementDateByFundId(
      fundId
    )) as GetMeasurementDatesByFundResponse;

    if (!measurementDates?.results) return throwError(MEASUREMENT_DATES_BY_FUND_DEFAULT_ERROR_MESSAGE);

    return measurementDates?.results;
  } catch (error) {
    return throwError(MEASUREMENT_DATES_BY_FUND_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetMeasurementDatesByFund: UseGetMeasurementDatesByFund = params => {
  const { fund, shouldQueryAutomatically = true } = params;

  const fundId = fund?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<
    GetMeasurementDatesByFundHook['data'],
    Error
  >({
    enabled: !isNil(fundId) && shouldQueryAutomatically,
    queryFn: () => getMeasurementDatesByFund({ fundId }),
    queryKey: [MEASUREMENT_DATES_BY_FUND_QUERY_KEY, fundId],
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetMeasurementDatesByFund;
