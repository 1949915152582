import moment from 'moment';
import {
  EBITDA_MARGIN_ALIAS,
  GROSS_MARGIN_ALIAS,
  LTM_EBITDA_ALIAS,
  LTM_REVENUE_ALIAS,
  LTM_REVENUE_GROWTH_ALIAS,
  NTM_EBITDA_ALIAS,
  NTM_REVENUE_ALIAS,
  NTM_REVENUE_GROWTH_ALIAS,
  PERFORMANCE_METRIC_ALIASES,
} from 'common/constants/financials';
import { DEFAULT_ZERO_VALUE } from 'common/constants/valuations';
import { dbShortDate, getNumberOrNAValue } from 'utillities';
import { parseFloatToFixed } from 'utillities/parseFloatToFixed';
import {
  ADJUSTMENT_METRIC_OPTIONS,
  MULTIPLE_PREMIUM_DISCOUNT_MEASUREMENT_DATE,
} from '../MultiplePremiumDiscount/common/constants';
import { PERFORMANCE_METRICS_SPREADSHEET_COMPANY } from '../PerformanceMetrics/common/constants/performanceMetrics/sheetAliases';
import { PERFORMANCE_METRICS_SPREADSHEET_COLUMNS } from '../PerformanceMetrics/common/constants/performanceMetrics/sheetTitles';

export const removeCustomCompanyFromComparisons = comparisons =>
  comparisons?.filter(comparison => comparison?.cap_iq_id);

export const getTopNObjectsFromList = (objectList, quantity) =>
  objectList.sort((object1, object2) => object2.id - object1.id).slice(0, quantity);

export const isCompanyInComparables = (companyName, comparisons) =>
  comparisons.some(comparison => comparison.name === companyName && !comparison.cap_iq_id);

export const ensureNoMoreThanTwoDecimalPlaces = value => {
  const parsedValue = getNumberOrNAValue(value);

  return typeof parsedValue === 'number' ? parseFloatToFixed(parsedValue, 2) : parsedValue;
};

export const getAllCustomDatePerformanceMetrics = (gpcComparison, asOfDate) => {
  const allCustomDatePerformanceMetrics = [];
  gpcComparison?.forEach(item => {
    if (item.custom_date_performance_metrics) {
      const customDatePerformanceMetricsInAsOfDate = item.custom_date_performance_metrics.find(
        performanceMetrics => performanceMetrics.as_of_date === asOfDate
      );
      if (customDatePerformanceMetricsInAsOfDate) {
        allCustomDatePerformanceMetrics.push(customDatePerformanceMetricsInAsOfDate);
      }
    }
  });
  return allCustomDatePerformanceMetrics;
};

const getSortedPremiumDiscounts = multiplePremiumDiscount => [...multiplePremiumDiscount].sort((a, b) => b.id - a.id);

export const getTotalPremiumDiscount = multiplePremiumDiscount => {
  if (!multiplePremiumDiscount || multiplePremiumDiscount.length === 0) {
    return 0;
  }
  const sortedPremiumDiscounts = getSortedPremiumDiscounts(multiplePremiumDiscount).filter(
    item => item.name === MULTIPLE_PREMIUM_DISCOUNT_MEASUREMENT_DATE
  );
  return sortedPremiumDiscounts[0].total_premium_discount;
};

export const getMultiplePremiumDiscountOptions = (mpdReference, multiplePremiumDiscount) => {
  const totalPremiumDiscount = getTotalPremiumDiscount(multiplePremiumDiscount);
  return [
    { label: DEFAULT_ZERO_VALUE, value: parseFloatToFixed(0, 3) },
    { label: parseFloatToFixed(totalPremiumDiscount ?? 0, 3).toString(), value: `=${mpdReference}.C8` },
  ];
};

export const getMultiplePremiumDiscountOptionsForCell = (mpdReference, totalPremiumDiscount) => [
  { label: DEFAULT_ZERO_VALUE, value: parseFloatToFixed(0, 3) },
  { label: parseFloatToFixed(totalPremiumDiscount ?? 0, 3).toString(), value: `=${mpdReference}.C8` },
];

export const getAdjustmentMetricFormat = cellValue => {
  const formats = ADJUSTMENT_METRIC_OPTIONS.reduce((acc, { label, formatConfig }) => {
    acc[label] = formatConfig.format;
    return acc;
  }, {});
  return formats[cellValue];
};

export const getFiscalYearEnd = (value, companyMeasurementDate) => {
  const asOfDateISO = new Date(value).toISOString();
  const yearFromAsOfDate = asOfDateISO.split('-')[0];
  const { end_day: endDay, end_month: endMonth } = companyMeasurementDate.company.fiscal_year_end || {};
  return `${yearFromAsOfDate}-${endMonth}-${endDay}`;
};

const OFFSET_FOR_TITLE_COLUMN = 1;
const metricIndexes = {
  [LTM_REVENUE_ALIAS]: PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_REVENUE - OFFSET_FOR_TITLE_COLUMN,
  [NTM_REVENUE_ALIAS]: PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_REVENUE - OFFSET_FOR_TITLE_COLUMN,
  [LTM_EBITDA_ALIAS]: PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_EBITDA - OFFSET_FOR_TITLE_COLUMN,
  [NTM_EBITDA_ALIAS]: PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_EBITDA - OFFSET_FOR_TITLE_COLUMN,
  [LTM_REVENUE_GROWTH_ALIAS]: PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_REVENUE_GROWTH - OFFSET_FOR_TITLE_COLUMN,
  [NTM_REVENUE_GROWTH_ALIAS]: PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_REVENUE_GROWTH - OFFSET_FOR_TITLE_COLUMN,
  [GROSS_MARGIN_ALIAS]: PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.GROSS_MARGIN - OFFSET_FOR_TITLE_COLUMN,
  [EBITDA_MARGIN_ALIAS]: PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.EBITDA_MARGIN - OFFSET_FOR_TITLE_COLUMN,
};

const retrieveMetricValue = (metricValues, targetMetric) => metricValues[metricIndexes[targetMetric]];

export const getCompanyPerformanceMetrics = columns => {
  if (!Array.isArray(columns)) {
    throw new TypeError('Expected columns to be an array');
  }
  const performanceMetrics = columns.map(column => {
    if (column[PERFORMANCE_METRICS_SPREADSHEET_COMPANY]) {
      return column[PERFORMANCE_METRICS_SPREADSHEET_COMPANY].value;
    }
    return null;
  });
  const metrics = {};
  PERFORMANCE_METRIC_ALIASES.forEach(alias => {
    metrics[alias] = retrieveMetricValue(performanceMetrics, alias);
  });
  return metrics;
};

export const isDateBeforeReference = (date, referenceDate) => {
  const formattedDate = dbShortDate(date);
  const formattedReferenceDate = dbShortDate(referenceDate);
  return moment(formattedDate).isBefore(moment(formattedReferenceDate));
};
