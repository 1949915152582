/* eslint-disable no-param-reassign */
import { isEmpty } from 'lodash';
import { BREAKPOINT_INFINITY } from 'common/constants/cap-table/capTable';
import { largeCurrencyFormat } from 'common/formats/formats';
import { formatNumbers } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';

const reverseParser = ({ cells, columns, currencyCode, tableData }) => {
  if (isEmpty(columns)) {
    return [];
  }

  const { currencySymbol } = tableData;

  const parsedColumnsValues = Object.values(cells).filter(item => item.key);
  const prices = parsedColumnsValues.filter(item => item.name === 'price');

  const alphabet = alphabetGenerator([], columns.length + 1);

  const getTotalSeries = col => col.series.reduce((acc, serie) => acc + Number(serie.value), 0);

  const getLowerLimit = (column, columnIndex) => {
    if (columnIndex === 0) {
      return `${currencySymbol}0`;
    }
    if (column.series.every(serie => !serie.value)) {
      return `${currencySymbol}${alphabet[columnIndex]}`;
    }
    return formatNumbers({
      value: getTotalSeries(columns[columnIndex - 1]),
      format: { ...largeCurrencyFormat },
      currencyCode,
    });
  };

  const getUpperLimit = (column, columnIndex) => {
    if (columnIndex === columns.length - 1) {
      return BREAKPOINT_INFINITY;
    }
    if (column.series.every(serie => !serie.value)) {
      return `${currencySymbol}${alphabet[columnIndex + 1]}`;
    }
    return formatNumbers({
      value: getTotalSeries(column),
      format: { ...largeCurrencyFormat },
      currencyCode,
    });
  };

  const getSeriesValue = value => {
    if (!Number.isNaN(Number(value))) {
      return Number(value);
    }
    return value;
  };

  const getValueOrInfinity = (upper, value) => {
    if (upper === BREAKPOINT_INFINITY) {
      return upper;
    }

    return value;
  };

  columns.forEach((col, colIndex) => {
    const bpSeries = parsedColumnsValues.filter(item => item.name === col.name);
    col.series.forEach((serie, sIndex) => {
      serie.value = getSeriesValue(bpSeries[sIndex]?.value);
    });

    const lowerLimit = getLowerLimit(col, colIndex);
    const upperLimit = getUpperLimit(col, colIndex);
    col.range = `${lowerLimit} to ${upperLimit}`;
    col.price = getValueOrInfinity(upperLimit, Number(prices[colIndex]?.value));
    col.total = getValueOrInfinity(upperLimit, getTotalSeries(col));
    tableData.columns[colIndex] = col;
  });
};

export default reverseParser;
