import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 600,
      maxHeight: '100vh',
      boxSizing: 'border-box',
      padding: theme.spacing(3),
    },
    title: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    button: {
      textTransform: 'none',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: '0.875rem',
      boxShadow: theme.shadows[1],
      backgroundColor: theme.palette.white,
      color: theme.palette.black,
      transition: 'background-color 0.5s',
      '&:hover, &:active, &:focus': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        transition: 'background-color 0.5s',
        cursor: 'pointer',
      },
    },
    buttonText: {
      textAlign: 'center',
      marginLeft: theme.spacing(),
    },
    orText: {
      textAlign: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

export default useStyles;
