const validFileTypes = [
  'csv',
  'doc',
  'docx',
  'docm',
  'gif',
  'jpeg',
  'jpg',
  'key',
  'numbers',
  'pages',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'pptm',
  'ppsm',
  'rtf',
  'txt',
  'xls',
  'xlsx',
  'xlsm',
  'xlsb',
  'zip'
];

export default validFileTypes;
