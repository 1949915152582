import React, { useRef, useState } from 'react';
import { Button, ButtonGroup, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add as AddIcon, ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FloatingButtonOptions from './FloatingButtonOptions';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    borderRadius: '2.688rem',
    marginTop: '1.5em',
    backgroundColor: theme.palette.white,
  },
  button: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    zIndex: 3,
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
  label: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
}));

const FloatingButtonGroup = ({ mainAction, secondaryActions, alignItems = 'flex-end', notFixed, ...rest }) => {
  const classes = useStyles();
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
  const openOptionsButtonRef = useRef(null);

  const getArrowIcon = () => {
    if (isOpenSubMenu) return <ArrowDropUpIcon id="comp-groups-up" />;
    return <ArrowDropDownIcon id="comp-groups-down" />;
  };

  const handleToggle = () => {
    setIsOpenSubMenu(prevOpen => !prevOpen);
  };

  const getIcon = action => {
    const Icon = action.icon || AddIcon;
    return <Icon className={classes.icon} fontSize="small" />;
  };

  return (
    <Grid container>
      <Grid item xs={12} container direction="column" alignItems={alignItems}>
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="split button"
          className={notFixed ? classes.buttonGroup : clsx(classes.buttonGroup, classes.button)}
          {...rest}>
          <Button
            id="main-action-button"
            onClick={mainAction.onClick}
            style={{
              borderBottomLeftRadius: '2.688rem',
              borderTopLeftRadius: '2.688rem',
            }}>
            {getIcon(mainAction)}
            <span className={classes.label}>{mainAction.label}</span>
          </Button>
          <Button
            ref={openOptionsButtonRef}
            id="open-options-button"
            size="small"
            aria-controls={() => {
              if (isOpenSubMenu) return 'split-button-menu';
              return undefined;
            }}
            aria-expanded={() => {
              if (isOpenSubMenu) return 'true';
              return undefined;
            }}
            aria-label="floating button options"
            aria-haspopup="menu"
            onClick={handleToggle}
            style={{
              borderBottomRightRadius: '2.688rem',
              borderTopRightRadius: '2.688rem',
            }}>
            {getArrowIcon()}
          </Button>
        </ButtonGroup>
        <FloatingButtonOptions
          optionsMenu={secondaryActions}
          openOptionsButtonRef={openOptionsButtonRef}
          isOpenSubMenu={isOpenSubMenu}
          setIsOpenSubMenu={setIsOpenSubMenu}
          getIcon={getIcon}
        />
      </Grid>
    </Grid>
  );
};

FloatingButtonGroup.propTypes = {
  mainAction: PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.instanceOf(Object),
    onClick: PropTypes.func.isRequired,
  }),
  secondaryActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.instanceOf(Object),
      onClick: PropTypes.func.isRequired,
    })
  ),
  alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  notFixed: PropTypes.bool,
};

export default FloatingButtonGroup;
