import { useCallback, useState } from 'react';
import { NOTES_SAVED_ERROR, NOTES_SAVED_SUCCESS, NOTES_SHOULD_NOT_BE_EMPTY } from 'common/constants/services/notes';
import { NoteData } from 'components/Notes/types';
import { getArrayValue } from 'utillities';
import { useSaveNotes } from './useSaveNotes';
import { onAddNote, onDeleteNote, onUpdateNotes } from './utilities';
import { useResponse } from '../useResponse';

export const useNotes = () => {
  const [notes, setNotes] = useState<NoteData[] | null>(null);
  const [notesHasChanged, setNotesHasChanged] = useState<boolean>(false);
  const { mutate: saveNotesFetch } = useSaveNotes();
  const { infoNotification, errorNotification, successNotification } = useResponse();

  const getParsedNotes = useCallback(
    (notesData: NoteData[]) => {
      const notesToSave = notesData ?? notes;
      if (notesToSave && notesToSave?.length > 0) {
        return notesToSave.map(({ temp_id, isNew, author, updated_by, ...rest }) => rest);
      }
      return [];
    },
    [notes]
  );

  const isAnyNoteEmpty = (notesData: NoteData[]) => notesData.findIndex(note => note.content === '') === -1;

  const saveNotes = useCallback(
    (notesData?: NoteData[]) => {
      const notesToSave = notesData ?? notes;
      const isAllNotesValidContent = isAnyNoteEmpty(getArrayValue(notesToSave));
      if (notesToSave) {
        if (!isAllNotesValidContent) {
          return infoNotification(NOTES_SHOULD_NOT_BE_EMPTY);
        }
        try {
          const parsedNotes = getParsedNotes(notesToSave);
          saveNotesFetch(
            { notes: parsedNotes },
            {
              onSuccess: () => {
                successNotification(NOTES_SAVED_SUCCESS);
                setNotesHasChanged(false);
              },
            }
          );
        } catch (error) {
          errorNotification(NOTES_SAVED_ERROR);
        }
      }
    },
    [notes, getParsedNotes, saveNotesFetch, successNotification, errorNotification, infoNotification]
  );

  const handleOnAddNote = useCallback(
    ({ pageType, pageTypeId }) => {
      onAddNote({
        notes: notes ?? [],
        setNotes,
        pageType,
        pageTypeId,
      });
      setNotesHasChanged(true);
    },
    [notes, setNotes]
  );

  const handleOnUpdateNotes = useCallback(
    (updatedNote: NoteData) => {
      onUpdateNotes({
        notes: notes ?? [],
        setNotes,
        updatedNote,
      });
      setNotesHasChanged(true);
    },
    [notes, setNotes]
  );

  const handleOnDeleteNote = useCallback(
    (updatedNote: NoteData) => {
      onDeleteNote({
        notes: notes ?? [],
        setNotes,
        updatedNote,
      });
      setNotesHasChanged(true);
    },
    [notes, setNotes]
  );

  return {
    notes,
    setNotes,
    notesHasChanged,
    setNotesHasChanged,
    saveNotes,
    onAddNote: handleOnAddNote,
    onUpdateNotes: handleOnUpdateNotes,
    onDeleteNote: handleOnDeleteNote,
  };
};

export default useNotes;
