import React, { FC } from 'react';
import { Box, Grid, Radio, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { ANSWERED } from 'common/constants/process-management';
import { ModalAnswerRow } from 'pages/Funds/components/ProcessManagement/ModalAnswerRow';
import theme from 'theme';
import { AnswerProgressItemProps } from './types';

const AnswerProgressItem: FC<AnswerProgressItemProps> = props => {
  const { task } = props;
  const { name: taskName, answers: taskAnswers } = task;
  const isTaskAnswered = !isEmpty(taskAnswers)
    ? taskAnswers?.some(answer => Boolean(answer?.date_answer ?? answer?.selected_choice ?? answer?.text_answer))
    : false;

  return (
    <Box
      border={`1px solid ${theme.palette.grey?.[300]}`}
      borderRadius="0.25rem"
      bgcolor={theme.palette.grey?.[100]}
      padding="1rem"
      marginBottom="1rem">
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={1}>
          <Radio checked={isTaskAnswered} style={{ pointerEvents: 'none', padding: 0 }} />
        </Grid>
        <Grid item xs={11}>
          <Typography style={{ fontWeight: 700, color: theme.palette.black, fontSize: '1rem' }}>
            {taskName}{' '}
            {isTaskAnswered ? (
              <span
                style={{
                  fontWeight: 700,
                  color: theme.palette.secondary[600],
                  fontSize: '0.75rem',
                  textTransform: 'uppercase',
                }}>
                {ANSWERED}
              </span>
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <ModalAnswerRow task={task} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnswerProgressItem;
