import BaseServiceRetryWrapper from './baseRetry';
import { FINANCIAL_STATEMENT } from '../common/endpoints';

class FinancialStatementService extends BaseServiceRetryWrapper {
  retrieve = id => this.get(FINANCIAL_STATEMENT.retrieve(id));

  update = (id, data) => this.put(FINANCIAL_STATEMENT.update(id), data);

  destroy = id => this.delete(FINANCIAL_STATEMENT.destroy(id));

  create = data => this.post(FINANCIAL_STATEMENT.create(), data);

  list = () => this.get(FINANCIAL_STATEMENT.list());

  createVersion = (id, data) => this.put(FINANCIAL_STATEMENT.createVersion(id), data);

  listByMeasurementPeriod = measurementPeriodId =>
    this.get(FINANCIAL_STATEMENT.listByMeasurementPeriod(measurementPeriodId));

  getByMeasurementDateId = measurementPeriodId =>
    this.get(FINANCIAL_STATEMENT.getByMeasurementDateId(measurementPeriodId));

  financialLtmNtm = (measurementPeriodId, versionId) =>
    this.get(FINANCIAL_STATEMENT.financialLtmNtm(measurementPeriodId, versionId));

  getFinancialPeriodsFiscalYear = (companyMeasurementDateId, versionId) =>
    this.get(FINANCIAL_STATEMENT.getFinancialPeriodsFiscalYear(companyMeasurementDateId, versionId));

  getFinancialsBasicDataListByMD = measurementDateId =>
    this.get(FINANCIAL_STATEMENT.getFinancialsBasicDataListByMD(measurementDateId));
}

export default FinancialStatementService;
