import React from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { ErrorComponent } from 'components';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    // Display fallback UI
    this.setState({ hasError: true });

    // According to the docs, this shouldn't be necessary
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorComponent
          title="Please Refresh"
          subtitle="Please refresh your page in order to finish processing this request."
          body="If you are still seeing this after attempting multiple times, please contact Scalar so we can help resolve the issue."
        />
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
