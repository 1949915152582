import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    dialogPaper: {
      backgroundColor: 'transparent',
      bottom: theme.spacing(4),
      boxShadow: 'none',
      position: 'fixed',
      right: theme.spacing(2),
      zIndex: 3,
    },
  })
);

export default useStyles;
