import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { FUNDS_BY_FIRM_QUERY_KEY } from 'common/constants/services/firms';
import {
  COMPANIES_WITH_OWNERSHIP_BY_FUND_QUERY_KEY,
  DELETE_FUND_DEFAULT_ERROR_MESSAGE,
  DELETE_FUND_DEFAULT_SUCCESS_MESSAGE,
  FUND_ALLOCATIONS_BY_MD_QUERY_KEY,
  FUND_INVESTMENTS_BY_MD_QUERY_KEY,
  FUND_SUMMARY_BY_MD_QUERY_KEY,
} from 'common/constants/services/funds';
import { FundService } from 'services';
import { throwError } from 'utillities';
import { DeleteFundParams, DeleteFundResponse } from './types';

const deleteFund = async (params: DeleteFundParams) => {
  const { fundId } = params;

  if (isNil(fundId)) return throwError(DELETE_FUND_DEFAULT_ERROR_MESSAGE);

  const fundService = new FundService();

  try {
    await fundService.deleteFund(fundId);

    return {
      fundId,
      responseMessage: DELETE_FUND_DEFAULT_SUCCESS_MESSAGE,
    } as DeleteFundResponse;
  } catch (error) {
    return throwError(DELETE_FUND_DEFAULT_ERROR_MESSAGE);
  }
};

const useDeleteFund = () => {
  const queryClient = useQueryClient();

  const deleteFundMutation = useMutation<DeleteFundResponse, Error, DeleteFundParams>({
    mutationFn: params => deleteFund(params),
  });

  const { isSuccess, mutateAsync } = deleteFundMutation;

  if (isSuccess) {
    // Invalidate the queries to refetch the updated data of the Fund
    queryClient.invalidateQueries({
      queryKey: [
        FUNDS_BY_FIRM_QUERY_KEY, // Funds List
        COMPANIES_WITH_OWNERSHIP_BY_FUND_QUERY_KEY, // Companies with Ownership by Fund
        FUND_SUMMARY_BY_MD_QUERY_KEY, // Fund Summary by Measurement Date
        FUND_ALLOCATIONS_BY_MD_QUERY_KEY, // Fund Allocations by Measurement Date
        FUND_INVESTMENTS_BY_MD_QUERY_KEY, // Fund Investments by Measurement Date
      ],
    });

    // Clean the mutation internal state
    deleteFundMutation.reset();
  }

  return {
    mutateAsync,
  };
};

export default useDeleteFund;
