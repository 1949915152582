import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

export const EDIT_BUTTON_CLASSNAME = 'editButton';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      '& .MuiBreadcrumbs-separator': {
        color: theme.palette.gray[300],
        fontSize: '0.75rem',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    },
    crumb: {
      color: theme.palette.gray[400],
      fontSize: '0.75rem',
    },
    crumbLink: {
      color: theme.palette.primary.main,
    },
    version: {
      '& .edit-icon': {
        marginLeft: 10,
        opacity: 0,
        transition: 'ease opacity 1s',
      },

      '&:hover .edit-icon': {
        opacity: 1,
      },
    },
    crumbIcon: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  })
);

export default useStyles;
