/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { IGetCaptableResponse, Security } from 'dashboard409a/services/types';
import parser from '../config/parser';
import rowConfig from '../config/rowConfig';
import { UseWaterfallTableDataProps } from '../types';

const getData = (exitEquitityValue: string, data: IGetCaptableResponse | null) => {
  if (!data) return [];

  const { securities } = data;

  const exitEquitityValueNumber = Number.parseFloat(exitEquitityValue);
  const total = securities.reduce((prev, current) => prev + Number.parseFloat(current.total_preference), 0);

  const result = securities.map(security => ({
    ...security,
    total_preference:
      exitEquitityValueNumber > 0
        ? (
          ((exitEquitityValueNumber - total) / total) * Number(security.total_preference)
            + Number(security.total_preference)
        ).toString()
        : security.total_preference,
  })) as Security[];

  return result;
};

const useWaterfallTableData = ({ exitEquityValue, data, format }: UseWaterfallTableDataProps) => {
  const [securities, setSecurities] = useState<Security[]>([]);
  const [columns, setColumns] = useState<any[]>();
  const [spreadsheet, setSpreadsheet] = useState<SpreadsheetConfig>();

  useEffect(() => {
    const loadData = async () => {
      const response = getData(exitEquityValue, data);
      setSecurities(response);
    };
    loadData();
  }, [exitEquityValue, data]);

  const config = useMemo(() => rowConfig({ securities }), [securities]);

  useEffect(() => {
    if (securities?.length) {
      const securitiesObj = securities.reduce(
        (prev, current) => ({ ...prev, [`security-${current.id}`]: current.total_preference }),
        {}
      );
      setColumns([securitiesObj]);
    }
  }, [securities]);

  const tableTerms: any = useMemo(
    () => ({
      tableName: 'Waterfall Calculator',
      tableSlug: 'waterfall-calculator',
      columnName: 'Distribution',
    }),
    []
  );

  useEffect(() => {
    if (columns && tableTerms) {
      const spreadsheetConfig = new SpreadsheetConfig({
        parser,
        rowConfig: config,
        name: 'WaterfallCalculator',
        columns,
        tableData: { columns },
        tableTerms,
        showTotalColumn: false,
        showToolbar: true,
        showPreviousColsDivider: true,
        hasColTitle: true,
        allowConfirmAndDeleteColumn: false,
        currencyFormatter: false,
        unitsFormatter: false,
        totalParser: undefined,
        alwaysDisplayLegend: false,
        format,
      });

      setSpreadsheet(spreadsheetConfig);
    }
  }, [columns, tableTerms]);

  return {
    spreadsheet,
  };
};

export default useWaterfallTableData;
