/* eslint-disable import/no-unresolved */

import React, { useState } from 'react';
import { Dialog, DialogTitle, IconButton } from '@material-ui/core';
import { CropFree as CropFreeIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import withRequired from 'components/WithRequired';
import withTooltip from 'components/WithTooltip';
import SharesOutstandingValues from '../Spreadsheet/components/SharesOutstandingValue';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  left: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.cellPadding,
  },
  right: {
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    minWidth: '100%',
  },
  dialogButton: {
    padding: theme.spacing(0.5),
  },
  dialogIcon: {
    padding: theme.spacing(0),
  },
  dialog: {},
  dialogContent: {},
  dialogTitle: {
    padding: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const GridDialog = ({ cell, state, setState }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        {cell.component ? cell.component : <SharesOutstandingValues cell={cell} state={state} />}
      </div>
      <div className={classes.right}>
        <IconButton
          id={`maximize-cell-${cell.key}`}
          color="primary"
          className={classes.dialogButton}
          onClick={openDialog}>
          <CropFreeIcon color="disabled" className={classes.dialogIcon} size="small" />
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          className={classes.dialog}
          maxWidth="xl"
          PaperProps={{
            square: true,
          }}>
          <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
            {cell.dialog.title}
          </DialogTitle>
          <cell.dialog.component cell={cell} handleClose={handleClose} state={state} setState={setState} />
        </Dialog>
      </div>
    </div>
  );
};

GridDialog.propTypes = {
  cell: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
};

export default withRequired(withTooltip(GridDialog));
