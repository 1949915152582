export const getAccessTokens = '/auth/jwt/create';
export const refreshAccessToken = '/auth/jwt/refresh';
export const verifyAccessToken = '/auth/jwt/verify';
export const sendAccessToken = '/auth/social/';

export default {
  getAccessTokens,
  refreshAccessToken,
  verifyAccessToken,
  sendAccessToken,
};
