/* eslint-disable max-len */
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { KEEP_STATIC_CODES_SAFE, THESE_ARE_YOUR_NEW_BACKUP_CODES, USE_BACKUP_CODES } from 'common/constants/login';
import BackupCodesInfo from './BackupCodesInfo';
import useStyles from '../styles';

const PrintableBackupCodes = props => {
  const {
    backupCodes,
    componentRef,
    disabledGenerate = false,
    forPrint,
    isGeneratingBackupCodes,
    isModal = false,
    username = '',
  } = props;

  const classes = useStyles();

  return (
    <Grid className={forPrint && classes.hideGrid}>
      <Grid
        className={clsx(forPrint && classes.printGrid, !isModal && classes.centerContainer)}
        ref={componentRef}
        container={!isModal}>
        {forPrint && (
          <Grid className={classes.printLogo}>
            <img alt="Background" className={classes.logo} src="/images/logos/scalar-logo.jpg" />
          </Grid>
        )}

        <Grid item xs={12} lg={isModal ? 12 : 6} style={{ paddingRight: isModal ? 0 : '1rem' }}>
          {isModal && (
            <Typography id="modal-modal-title" variant="h6" component="h2" className={classes.modalTitle}>
              {THESE_ARE_YOUR_NEW_BACKUP_CODES}
              {forPrint && ` for ${username}`}
            </Typography>
          )}

          <Typography id="modal-modal-description" className={classes.modalSubtitle}>
            {KEEP_STATIC_CODES_SAFE}
          </Typography>

          <Typography id="modal-modal-description" className={clsx(classes.modalSubtitle, classes.modalSubtitleGrey)}>
            {USE_BACKUP_CODES}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={isModal ? 12 : 6}>
          <BackupCodesInfo
            backupCodes={backupCodes}
            disabledGenerate={disabledGenerate}
            isGeneratingBackupCodes={isGeneratingBackupCodes}
            isModal={isModal}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

PrintableBackupCodes.propTypes = {
  backupCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  componentRef: PropTypes.object,
  disabledGenerate: PropTypes.bool,
  forPrint: PropTypes.bool,
  isGeneratingBackupCodes: PropTypes.bool,
  isModal: PropTypes.bool,
  username: PropTypes.string,
};

export default PrintableBackupCodes;
