export const VALUATIONS_SUMMARY_SPREADSHEET_COMPANY_NAME_TITLE = 'Company';
export const VALUATIONS_SUMMARY_SPREADSHEET_VALUATION_DATE_TITLE = 'Valuation Date';
export const VALUATIONS_SUMMARY_SPREADSHEET_VALUATION_STATUS_TITLE = 'Valuation Status';
export const VALUATIONS_SUMMARY_SPREADSHEET_ENTERPRISE_VALUATION_METHOD_TITLE = 'Enterprise Valuation Method';
export const VALUATION_SUMMARY_SPREADSHEET_ENTERPRISE_VALUE_TITLE = 'Enterprise Value';
export const VALUATION_SUMMARY_SPREADSHEET_EQUITY_VALUE_TITLE = 'Equity Value';
export const VALUATIONS_SUMMARY_SPREADSHEET_BREAKEVEN_EQUITY_EXIT_VALUE_TITLE = 'Breakeven Equity Exit Value';
export const VALUATIONS_SUMMARY_SPREADSHEET_ALLOCATION_METHOD_TITLE = 'Allocation Method';
export const VALUATIONS_SUMMARY_SPREADSHEET_CURRENT_FUND_VALUE_TITLE = 'Current Fund Value';
export const VALUATIONS_SUMMARY_SPREADSHEET_CHANGE_FROM_PREVIOUS_TITLE = '% Change from Previous';

export const VALUATIONS_SUMMARY_SPREADSHEET_TOTAL = 'Total';

export const VALUATIONS_SUMMARY_SPREADSHEET_COLUMNS = Object.freeze({
  COMPANY_NAME: 0,
  VALUATION_DATE: 1,
  VALUATION_STATUS: 2,
  ENTERPRISE_VALUATION_METHOD: 3,
  ENTERPRISE_VALUE: 4,
  EQUITY_VALUE: 5,
  BREAKEVEN_EQUITY_EXIT_VALUE: 6,
  ALLOCATION_METHOD: 7,
  CURRENT_FUND_VALUE: 8,
  CHANGE_FROM_PREVIOUS: 9,
});

export const VALUATIONS_SUMMARY_SPREADSHEET_HEADERS = Object.freeze([
  {
    value: VALUATIONS_SUMMARY_SPREADSHEET_COMPANY_NAME_TITLE,
  },
  {
    value: VALUATIONS_SUMMARY_SPREADSHEET_VALUATION_DATE_TITLE,
  },
  {
    value: VALUATIONS_SUMMARY_SPREADSHEET_VALUATION_STATUS_TITLE,
  },
  {
    value: VALUATIONS_SUMMARY_SPREADSHEET_ENTERPRISE_VALUATION_METHOD_TITLE,
  },
  {
    value: VALUATION_SUMMARY_SPREADSHEET_ENTERPRISE_VALUE_TITLE,
  },
  {
    value: VALUATION_SUMMARY_SPREADSHEET_EQUITY_VALUE_TITLE,
  },
  {
    value: VALUATIONS_SUMMARY_SPREADSHEET_BREAKEVEN_EQUITY_EXIT_VALUE_TITLE,
  },
  {
    value: VALUATIONS_SUMMARY_SPREADSHEET_ALLOCATION_METHOD_TITLE,
  },
  {
    value: VALUATIONS_SUMMARY_SPREADSHEET_CURRENT_FUND_VALUE_TITLE,
  },
  {
    value: VALUATIONS_SUMMARY_SPREADSHEET_CHANGE_FROM_PREVIOUS_TITLE,
  },
]);
