/**
 * @file services/companies.js
 * @since v1.0
 * @author Carlos Lenon <code@carloslenon.com>
 */

// #region constant
// #endregion
// #region common
import BaseServiceRetryWrapper from './baseRetry';
import { EXCEL_EXPORT } from '../common/endpoints';
// #endregion

class ExcelExportService extends BaseServiceRetryWrapper {
  beginReportProcess = (reportUUID, data) => this.post(EXCEL_EXPORT.beginReportProcess(reportUUID), data);

  checkReport = (reportUUID, data) => this.post(EXCEL_EXPORT.checkReportFile(reportUUID), data);
}

export default ExcelExportService;
