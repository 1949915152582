import { getSlugValue } from 'utillities';

export const FUND_SUMMARY_DRAFT_CELL = 'DRAFT';
export const FUND_SUMMARY_ONLY_INCLUDES_PUBLISHED_VALUATIONS = 'Summary values shown for published valuations';
export const FUND_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX = 'company';
export const FUND_SUMMARY_SPREADSHEET_COLUMN_NAME = 'Column';
export const FUND_SUMMARY_SPREADSHEET_COLUMN_NAME_PLURAL = 'Columns';
export const FUND_SUMMARY_SPREADSHEET_TITLE = 'Fund Summary';
export const FUND_SUMMARY_SPREADSHEET_SLUG = getSlugValue(FUND_SUMMARY_SPREADSHEET_TITLE);
export const FUND_SUMMARY_SPREADSHEET_TABLE = `${FUND_SUMMARY_SPREADSHEET_SLUG}-table`;
export const FUND_SUMMARY_VALUE_WITH_NOT_PUBLISHED_VALUATION_TOOLTIP
  = 'This value isn’t shown until the valuation allocation for this company is published';
