/* eslint-disable import/no-unresolved */
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SYSTEM_EXPAND } from 'common/actions/row-groups/types';
import {
  CURRENT_OWNERSHIP_ROW_NUMBER,
  PREFERRED_TERMS_ALIAS,
  SHARES_FULLY_DILUTED_ROW_NUMBER,
  STRIKE_PRICE_ALIAS,
} from 'common/constants/cap-table';
import {
  CONVERTIBLE_NOTES,
  OPTION,
  PREFERRED_STOCK,
  TYPES,
  UNISSUED_OPTIONS,
  WARRANT,
} from 'common/constants/securityTypes';
import { CustomSelect, withRequired, withTooltip } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { reverseParser } from '../utilities';
import checkIfExistConvertibleNoteInCapTable from '../utilities/checkIfExistConvertibleNoteInCapTable';

const SecurityType = ({ cell, options, ...restProps }) => {
  const { onCommit, onRevert } = restProps;
  const { cells, columns = [], onCellsChanged, setRowGroups } = useContext(FeaturedSpreadsheetContext);

  const alphabet = alphabetGenerator([], columns.length);

  const handleChange = value => {
    const selectedSecurity = value.toString();
    const tmpState = {
      ...cells,
    };
    tmpState[cell.key].value = selectedSecurity;
    // Includes current cell changes in the list of changes
    const changes = [
      {
        cell,
        value: selectedSecurity,
      },
    ];

    // Get a list of the updated columns
    const updatedColumns = reverseParser({ cells: tmpState, columns }).map((column, columnIndex) => ({
      security_type: column.security_type,
      columnLegend: alphabet[columnIndex],
      value: Number(column.current_own_percent),
    }));

    // Filter updated columns to get only not "Pool Option" security types
    const notOptionPoolKeyList = rowNumber =>
      updatedColumns
        .filter(column => column.security_type?.toString() !== UNISSUED_OPTIONS.toString())
        .map(column => column.columnLegend + rowNumber);

    // Filter updated columns to get only not "Pool Option" security types
    const filteredSecurities = rowNumber =>
      updatedColumns
        .filter(
          column =>
            ![UNISSUED_OPTIONS.toString(), CONVERTIBLE_NOTES.toString()].includes(column.security_type?.toString())
        )
        .map(column => column.columnLegend + rowNumber);

    const cellFormula = (columnLegend, security_type) => {
      if ([CONVERTIBLE_NOTES, UNISSUED_OPTIONS].map(val => val.toString()).includes(security_type?.toString())) {
        return '0';
      }
      const key = columnLegend + SHARES_FULLY_DILUTED_ROW_NUMBER;
      const captableHasConvertibleNotes = checkIfExistConvertibleNoteInCapTable(updatedColumns);
      const keyList = captableHasConvertibleNotes
        ? filteredSecurities(SHARES_FULLY_DILUTED_ROW_NUMBER)
        : notOptionPoolKeyList(SHARES_FULLY_DILUTED_ROW_NUMBER);
      const sharesFullyDiluted = keyList.length > 0 ? `sum(${keyList})` : '0';

      if (sharesFullyDiluted === '0') {
        return '0.0';
      }

      return `=${key}/sum(${keyList})`;
    };

    // Add the new formula to each cell in the row
    updatedColumns.forEach((column, columnIndex) => {
      const columnLegend = alphabet[columnIndex];
      const key = column.columnLegend + CURRENT_OWNERSHIP_ROW_NUMBER;
      const expr = cellFormula(columnLegend, column.security_type);

      changes.push({
        cell: tmpState[key],
        value: expr,
      });
    });

    // Apply all the changes
    onCellsChanged(changes);

    // Handle row groups
    switch (selectedSecurity) {
      case PREFERRED_STOCK.toString():
        setRowGroups({ type: SYSTEM_EXPAND, row: PREFERRED_TERMS_ALIAS });
        break;
      case WARRANT.toString():
        setRowGroups({ type: SYSTEM_EXPAND, row: PREFERRED_TERMS_ALIAS });
        setRowGroups({ type: SYSTEM_EXPAND, row: STRIKE_PRICE_ALIAS });
        break;
      case OPTION.toString():
        setRowGroups({ type: SYSTEM_EXPAND, row: STRIKE_PRICE_ALIAS });
        break;
      default:
        break;
    }
  };

  const getOptions = useMemo(() => {
    const tmpOptions = [];

    if (Array.isArray(options)) {
      TYPES.forEach(type => {
        tmpOptions.push({
          value: type,
          label: options[type - 1],
        });
      });
    }

    return tmpOptions;
  }, [options]);

  return (
    <CustomSelect
      id={`security-select-${cell.key}`}
      value={cell.value}
      options={getOptions}
      onChange={handleChange}
      onCommit={onCommit}
      onRevert={onRevert}
      onEmptyValue="SELECT SECURITY"
      disabled={cell.readOnly}
    />
  );
};

SecurityType.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
};

SecurityType.propTypes = {
  cell: PropTypes.object,
  options: PropTypes.array,
};

export default withTooltip(withRequired(SecurityType));
