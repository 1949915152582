/* eslint-disable import/no-unresolved */
/**
 * @name Global Reducer
 * @memberof module:common/reducers
 * @type {ReduxReducer}
 * @return {Object} Reducer Specification
 */

import {
  CREATE_CAPTABLE_PROFORMA,
  GET_CAPTABLE_COMPANY,
  GET_CAPTABLE_INFO,
  SET_CAPTABLE_INFO,
} from 'common/actions/captable/types';
import { createCapTableProforma, getCapTableInfo, setCaptableCompany, setCaptableInfo } from './reducer';

export default {
  [GET_CAPTABLE_COMPANY]: setCaptableCompany,
  [SET_CAPTABLE_INFO]: setCaptableInfo,
  [GET_CAPTABLE_INFO]: getCapTableInfo,

  [CREATE_CAPTABLE_PROFORMA]: createCapTableProforma,
};
