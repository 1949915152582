import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { DeleteOutline as DeleteIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  deleteButton: {
    opacity: 0,
    transition: 'ease opacity 0.5s',
    padding: theme.spacing(0.5),
    float: 'left',
  },
}));

const DeleteRowButton = ({ rowIndex, deleteRow }) => {
  const classes = useStyles();

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      title="Delete row"
      placement="top"
      className="delete-button"
      arrow>
      <IconButton
        onClick={() => {
          deleteRow(rowIndex);
        }}
        className={classes.deleteButton}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

DeleteRowButton.propTypes = {
  rowIndex: PropTypes.any,
  deleteRow: PropTypes.func,
};

export default DeleteRowButton;
