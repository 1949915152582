/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  findCaptable as findCaptableService,
  getCaptablebyCompany as getCaptablebyCompanyService,
  getWaterfallBreakpoint as getWaterfallBreakpointService,
} from 'dashboard409a/services/captable';
import {
  IGetCaptableByCompanyResponse,
  IGetCaptableResponse,
  IGetWaterfallBreakpointResponse,
} from 'dashboard409a/services/types';
import { IgGetCaptablebyCompanyRequest, IWaterfallState } from './types';

const initialState: IWaterfallState = {
  loading: false,
  waterfallBreakpoint: null,
  captableInfobyCompany: null,
  currentCaptable: null,
};

export const getWaterfallBreakpoint = createAsyncThunk<IGetWaterfallBreakpointResponse, number>(
  'waterfall/getValuationScalarOpinion',
  async (captableId, thunkAPI) => {
    try {
      return await getWaterfallBreakpointService(captableId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCaptablebyCompany = createAsyncThunk<IGetCaptableByCompanyResponse[], IgGetCaptablebyCompanyRequest>(
  'waterfall/getCaptablebyCompany',
  async ({ companyId, measurementDateId }, thunkAPI) => {
    try {
      return await getCaptablebyCompanyService(companyId, { mp: measurementDateId });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const findCaptable = createAsyncThunk<IGetCaptableResponse, number>(
  'waterfall/findCaptable',
  async (captableId, thunkAPI) => {
    try {
      return await findCaptableService(captableId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const watefallSlice = createSlice({
  name: 'waterfall',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getWaterfallBreakpoint.pending, state => {
      state.loading = true;
    });
    builder.addCase(getWaterfallBreakpoint.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.waterfallBreakpoint = payload;
    });
    builder.addCase(getWaterfallBreakpoint.rejected, state => {
      state.loading = false;
    });
    builder.addCase(getCaptablebyCompany.pending, state => {
      state.loading = true;
    });
    builder.addCase(getCaptablebyCompany.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.captableInfobyCompany = payload;
    });
    builder.addCase(getCaptablebyCompany.rejected, state => {
      state.loading = false;
    });
    builder.addCase(findCaptable.pending, state => {
      state.loading = true;
    });
    builder.addCase(findCaptable.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.currentCaptable = payload;
    });
    builder.addCase(findCaptable.rejected, state => {
      state.loading = false;
    });
  },
});

export default watefallSlice.reducer;
