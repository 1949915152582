import React, { useCallback, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { SHOW_LESS, SHOW_MORE } from 'common/constants/pageFilters';
import { MAX_FIRM_ITEMS } from 'common/constants/user';
import FirmItem from './FirmItem';

const useStyles = makeStyles(theme => ({
  showMoreLess: {
    fontSize: '0.875rem',
    color: theme.palette.gray[400],
  },
}));

const FirmItems = ({
  firmItems,
  itemType,
  disabled,
  userPermissions,
  shouldCheckAll,
  setUserPermissionsToSave,
  userId,
}) => {
  const classes = useStyles();
  const [itemList, setItemList] = useState(
    firmItems.length <= MAX_FIRM_ITEMS ? firmItems : firmItems.slice(0, MAX_FIRM_ITEMS)
  );

  const showLess = useCallback(() => {
    setItemList(firmItems.slice(0, MAX_FIRM_ITEMS));
    const scrollbarContainer = document.getElementById('scrollbar-firm-items');
    const itemsContainer = document.getElementById(`${itemType.toLowerCase()}-subtitle`);
    scrollbarContainer.scrollTop = itemsContainer.offsetTop;
  }, [firmItems, itemType]);

  const showMore = useCallback(() => {
    setItemList(firmItems);
    const scrollbarContainer = document.getElementById('scrollbar-firm-items');
    const itemsContainer = document.getElementById(`${itemType.toLowerCase()}-subtitle`);
    scrollbarContainer.scrollTop = itemsContainer.offsetTop + itemsContainer.offsetHeight;
  }, [firmItems, itemType]);

  const handleClick = useCallback(() => {
    if (itemList.length < firmItems.length) {
      showMore();
    } else {
      showLess();
    }
  }, [firmItems, itemList, showLess, showMore]);

  return (
    <>
      {itemList.map(item => (
        <FirmItem
          key={`${itemType}-${item.id}`}
          item={item}
          itemType={itemType}
          userPermissions={userPermissions}
          disabled={disabled}
          shouldCheckAll={shouldCheckAll}
          setUserPermissionsToSave={setUserPermissionsToSave}
          userId={userId}
        />
      ))}
      {firmItems.length > MAX_FIRM_ITEMS && (
        <MenuItem onClick={handleClick} className={classes.showMoreLess}>
          {itemList.length < firmItems.length ? SHOW_MORE : SHOW_LESS}
        </MenuItem>
      )}
    </>
  );
};

FirmItems.propTypes = {
  firmItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  itemType: PropTypes.string,
  disabled: PropTypes.bool,
  userPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      access_type: PropTypes.string,
      id: PropTypes.number,
      object_id: PropTypes.number,
      feature: PropTypes.string,
      object_type: PropTypes.string,
      remove_permission: PropTypes.bool,
    })
  ),
  shouldCheckAll: PropTypes.bool,
  setUserPermissionsToSave: PropTypes.func,
  userId: PropTypes.number,
};

export default FirmItems;
