/* eslint-disable no-param-reassign */
// eslint-disable-next-line object-curly-newline
import { isEmpty, isObject, transform } from 'lodash';
import { toString } from './typesConverter';

const difference = (initialState, currentState) => {
  const changes = (initialState, currentState) =>
    transform(initialState, (result, tmpInitialState, key) => {
      if (isObject(tmpInitialState) && isObject(currentState[key]) && key !== 'component') {
        changes(tmpInitialState, currentState[key]);
      }

      if (
        tmpInitialState
        && currentState[key]
        && currentState[key].value
        && toString(tmpInitialState.value) !== toString(currentState[key].value)
      ) {
        result[key] = tmpInitialState;
      }
    });

  return changes(initialState, currentState);
};

const compareChanges = (initialState, currentState) => {
  if (initialState && currentState) {
    const hasChanges = difference(initialState, currentState);
    return !isEmpty(hasChanges);
  }

  return false;
};

export default compareChanges;
