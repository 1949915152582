import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SingleColumn } from 'components/FeaturedSpreadsheet/components/';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { Cell } from 'components/Spreadsheet/components';
import { ALLOCATION_SCENARIOS_SLUG, VALUATION_SUMMARY_SLUG } from './constants';
import FastValueViewer from '../utilities/FastValueViewer';

const TitlesColumn = ({ className, data, dataEditor = undefined, tableSlug }) => {
  const { onCellsChanged } = useContext(FeaturedSpreadsheetContext);

  const adjustSummaryValuationTitleCells = useCallback(() => {
    const valuationSummaryEmptyCells = document.querySelectorAll('table tbody tr td.blank-cell');
    const valuationSummaryTitleCell = document.querySelector('table tbody tr td.spreadsheet-table-title-main > div');

    if (valuationSummaryEmptyCells && valuationSummaryTitleCell) {
      let totalHeight = 0;
      valuationSummaryEmptyCells.forEach(emptyCell => {
        totalHeight += emptyCell.offsetHeight - 1.2;
      });
      valuationSummaryTitleCell.style.height = `${totalHeight}px`;
    }
  }, []);

  const adjustAllocationTitleCells = useCallback(() => {
    const baseQuerySelector = 'table tbody tr td.spreadsheet-table-title-main > div';
    const allocationScenarioHeadersCells = document.querySelectorAll(
      `#spreadsheet-table__content-columns ${baseQuerySelector}`
    );
    const allocationScenarioTitle = document.querySelector(`#spreadsheet-table__title-columns ${baseQuerySelector}`);
    if (allocationScenarioHeadersCells && allocationScenarioTitle) {
      let largerHeight = 0;
      allocationScenarioHeadersCells.forEach(headerCell => {
        if (headerCell.offsetHeight > largerHeight) {
          largerHeight = headerCell.offsetHeight;
        }
      });
      allocationScenarioTitle.style.height = `${largerHeight}px`;
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (tableSlug === VALUATION_SUMMARY_SLUG) {
        adjustSummaryValuationTitleCells();
      }
      if (tableSlug === ALLOCATION_SCENARIOS_SLUG) {
        adjustAllocationTitleCells();
      }
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [tableSlug, adjustSummaryValuationTitleCells, adjustAllocationTitleCells]);

  return (
    <SingleColumn
      isLegend
      data={data}
      className={className}
      dataEditor={dataEditor}
      valueViewer={FastValueViewer}
      onCellsChanged={onCellsChanged}
      cellRenderer={Cell}
    />
  );
};

TitlesColumn.defaultProps = {
  className: '',
};

TitlesColumn.propTypes = {
  dataEditor: PropTypes.func,
  data: PropTypes.instanceOf(Array),
  className: PropTypes.string,
  tableSlug: PropTypes.string,
};

export default TitlesColumn;
