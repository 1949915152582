/**
 * @name Measurement Date Config
 * @memberof module:common/config
 * @type {Config}
 * @return {Object} Configurations of API
 */

import API from '../config';

const base = `${API.BASE_DEV_HOST}/api/measurement-dates`;

export const getMeasurementDateByFundId = fundId => `${base}/funds/${fundId}`;
export const measurementDatesByCompanyId = companyId => `${base}/company/${companyId}`;
export const measurementDatesByFirmId = firmId => `${base}/firm/${firmId}`;
export const rolloverCompanies = `${base}/company/rollover`;
export const companiesAndfundsByFirmAndDate = (firmId, date) => `${base}/firm/${firmId}/date/${date}`;
export const allMeasurementDatesByFirmId = firmId => `${base}/by-firm/${firmId}`;
export const deleteCompaniesAndFundsByMd = `${base}/delete-related-info`;
