import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  required: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    alignContent: 'center',
  },
  value: {
    display: 'flex',
    flexGrow: 1,
    alignContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

// eslint-disable-next-line react/display-name
const withRequired = WrappedComponent => props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.value}>
        <WrappedComponent {...props} />
      </div>
    </div>
  );
};

export default withRequired;
