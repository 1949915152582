import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FeaturesContext from 'context/FeaturesContext';

/* eslint-disable max-len */
/*
- Enable typing in console
localStorage.setItem('features', JSON.stringify({documents:true}));dispatchEvent(new Event('storage'));
- Disable typing in console
localStorage.removeItem('features');dispatchEvent(new Event('storage'));
*/

export const FeaturesProvider = ({ children }) => {
  const [featuresEnabled, setFeaturesEnabled] = useState(localStorage.getItem('features'));

  const handleStorageEvent = () => {
    // maybe display a notification or something
    setFeaturesEnabled(localStorage.getItem('features'));
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageEvent);

    return () => {
      window.removeEventListener('storage', handleStorageEvent);
      setFeaturesEnabled(undefined);
    };
  }, []);

  return (
    <FeaturesContext.Provider
      value={{ features: (featuresEnabled ? JSON.parse(featuresEnabled) : {}) }}
    >
      {children}
    </FeaturesContext.Provider>
  );
};

FeaturesProvider.propTypes = {
  features: PropTypes.string,
  children: PropTypes.element
};
