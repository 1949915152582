import React, { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { CustomSelect, withTooltip } from 'components';
import { CompGroupsContext } from 'context';
import { gridShortDate } from 'utillities';

const PreviousVersionsList = ({ cell, ...restProps }) => {
  const { onCommit, onRevert } = restProps;
  const { group } = useContext(CompGroupsContext);

  const getOptions = useMemo(() => {
    const tmpOptions = [];

    if (!isEmpty(group.versions)) {
      group.versions.forEach(version => {
        tmpOptions.push({
          label: `${gridShortDate(version.created_at)} | V-${version.version_number}`,
          value: version.id,
        });
      });
    }
    return tmpOptions.sort((a, b) => b.value - a.value);
  }, [group.versions]);

  return (
    <CustomSelect
      id="captable-list"
      options={getOptions}
      value={cell?.value?.id || ''}
      onCommit={onCommit}
      onRevert={onRevert}
    />
  );
};

PreviousVersionsList.propTypes = {
  cell: PropTypes.object,
};

export default withTooltip(PreviousVersionsList);
