import React, { useState } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStore } from 'common/store';
import LayoutContext from 'context/LayoutContext';
import LoadingPaper from 'pages/Login/components/LoadingPaper';
import useStyles from './AuthLayout.styles';
import FirstViewLogo from './components/FirstViewLogo';

const AuthLayout = props => {
  const { children } = props;
  const classes = useStyles();
  const [showPageForm, setShowPageForm] = useState(false);
  const [loading, setLoading] = useState(true);

  const togglePageForm = () => setShowPageForm(!showPageForm);
  const [{ isShowLoadingProgress }] = useStore();

  return (
    <LayoutContext.Provider
      value={{
        showPageForm,
        setShowPageForm,
        togglePageForm,
      }}>
      {isShowLoadingProgress && <LinearProgress color="primary" />}
      <LoadingPaper isFullScreen loading={loading} />
      <Grid container className={classes.root}>
        <FirstViewLogo loading={loading} setLoading={setLoading} />
        <Grid item lg={6} xs={12} className={classes.formContainer} style={loading ? { display: 'none' } : {}}>
          <div className={classes.root}>
            <main className={classes.content}>{children}</main>
          </div>
        </Grid>
      </Grid>
    </LayoutContext.Provider>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default AuthLayout;
