import React from 'react';
import PropTypes from 'prop-types';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';

const BacksolveSummaryTable = ({ spreadsheets, onChange, format, formatDispatch }) => {
  const { backsolveSummarySheet } = spreadsheets;
  return (
    <ScalarSpreadsheet
      {...backsolveSummarySheet}
      id="backsolve-summary-table"
      tableTerms={{ tableSlug: 'backsolve-summary-table' }}
      onChange={onChange}
      sheet={backsolveSummarySheet}
      displayLegend={false}
      format={format}
      formatDispatch={formatDispatch}
      alwaysDisplayLegend={false}
    />
  );
};

export default BacksolveSummaryTable;

BacksolveSummaryTable.propTypes = {
  spreadsheets: PropTypes.object,
  onChange: PropTypes.func,
  format: PropTypes.object,
  formatDispatch: PropTypes.func,
};
