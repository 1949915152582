import React, { ChangeEvent, FC, useContext, useMemo, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { getNumberValue } from 'utillities';
import useStyles from './styles';
import { SelectChangeEvent, SelectFinancialsVersionsProps } from './types';

const FINANCIAL_STATEMENT_ID = 'financial_statement_id';
const SELECT_FINANCIALS_LABEL_ID = 'select-financials-label';
const SELECT_FINANCIALS_VERSION_LABEL = 'Select Financials Version';

const SelectFinancialsVersions: FC<SelectFinancialsVersionsProps> = props => {
  const { onChange } = props;

  const { financialsVersions, selectedFinancialsVersion } = useContext(ValuationContext);

  const [selectedVersion, setSelectedVersion] = useState<number>(getNumberValue(selectedFinancialsVersion));

  const classes = useStyles();

  const disabledSelection = useMemo(() => getNumberValue(financialsVersions?.length) <= 1, [financialsVersions]);

  const handleChange = (event: SelectChangeEvent) => {
    const versionId = getNumberValue(event?.target?.value as number);

    setSelectedVersion(versionId);

    const updatedEvent = {
      ...event,
      target: {
        name: FINANCIAL_STATEMENT_ID,
        value: versionId,
      },
    } as unknown as ChangeEvent<HTMLInputElement>;

    onChange(updatedEvent);
  };

  return (
    <FormControl className={classes.form} disabled={disabledSelection}>
      <InputLabel id={SELECT_FINANCIALS_LABEL_ID}>{SELECT_FINANCIALS_VERSION_LABEL}</InputLabel>
      <Select
        id={FINANCIAL_STATEMENT_ID}
        labelId={SELECT_FINANCIALS_LABEL_ID}
        onChange={handleChange}
        value={selectedVersion}>
        {financialsVersions?.map(version => (
          <MenuItem value={version.id} key={version.id}>
            {version.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectFinancialsVersions;
