import React, { FC } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from 'dashboard409a/states/store';
import { IProvider } from './types';

export const Provider: FC<IProvider> = props => {
  const { children } = props;
  return <ReduxProvider store={store}>{children}</ReduxProvider>;
};

export default Provider;
