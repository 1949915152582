/**
 * @name CompanyTypes ActionTypes
 * @memberof module:common/actions
 * @type {ReduxAction}
 * @return {Object} Firms Actions Types
 */
export const SET_COMPANY_INFO = 'SET_COMPANY_INFO';
export const SET_CAPTABLE_LIST = 'SET_CAPTABLE_LIST';
export const SET_FUND_LIST = 'SET_FUND_LIST';
export const SET_COMPANY_LIST = 'SET_COMPANY_LIST';
