/**
 * @name API Config
 * @memberof module:common/config
 * @type {Config}
 * @return {Object} Configurations of API
 */

const BASE_HOST = process.env.REACT_APP_API_ENDPOINT;
export const BASE_DEV_HOST = `${BASE_HOST}`;
export const ERROR_400 = '400';
export const ERROR_401 = '401';
export const ERROR_403 = '403';
export const ERROR_404 = '404';
export const ERROR_409 = '409';
export const ERROR_429 = '429';
export const ERROR_500 = '500';
export const ERROR_504 = '504';

export default BASE_DEV_HOST;
