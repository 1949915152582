import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  section: {
    height: 'calc(100vh - 15rem)',
    padding: '1rem',
  },
  borderLeft: {
    borderLeft: `3px solid ${theme.palette.grey[200]}`,
  },
  subtitle: {
    marginTop: theme.spacing(3),
    fontWeight: 'bold',
    fontSize: '1.125rem',
    letterSpacing: '-0.5px',
    lineHeight: '1.5rem',
    fontFamily: theme.typography.mainFont,
  },
  scrollbarStyle: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 1.5rem 2rem 0',
    padding: '0',
    maxHeight: 'calc(35vh - 3.5rem)',
  },
  listItem: {
    marginLeft: '1rem',
  },
  compsAndFunds: {
    color: theme.palette.primary[500],
    fontWeight: 700,
  },
  container: {
    margin: '1rem auto',
    width: '40%',
  },
  boldLabel: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    margin: theme.spacing(1, 0),
  },
}));

export default useStyles;
