import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  action: {
    color: theme.palette.gray[400],
  },
  combination: {
    color: theme.palette.gray[400],
    fontWeight: 'bold',
  },
}));

const KeyboardShortcutItem = ({ action, combination }) => {
  const classes = useStyles();
  return (
    <Grid item container direction="row" justifyContent="space-between">
      <Grid item className={classes.action}>
        {action}
      </Grid>
      <Grid item className={classes.combination}>
        {combination}
      </Grid>
    </Grid>
  );
};

KeyboardShortcutItem.propTypes = {
  action: PropTypes.string,
  combination: PropTypes.string,
};

export default KeyboardShortcutItem;
