/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/display-name
const withCellEventListener = WrappedComponent =>
  function (props) {
    const { cell, className } = props;
    const fullKey = cell.getFullKey ? cell.getFullKey() : undefined;
    const [value, setValue] = useState();
    const [cellClassName, setCellClassName] = useState(className);
    const [cellBackgroundColor, setCellBackgroundColor] = useState();

    useEffect(() => {
      setCellClassName(className);
    }, [className]);

    useEffect(() => {
      const getValueHandler = () => {
        setValue(cell.value);
        const tmpClassName = className || '';
        const tmpCellClassName = cell.className || '';
        const cellClassesSet = new Set([...tmpCellClassName.split(' '), ...tmpClassName.split(' ')]);
        setCellClassName(Array.from(cellClassesSet).join(' '));
        setCellBackgroundColor(cell.backgroundColor);
      };

      if (fullKey) {
        document.addEventListener(fullKey, getValueHandler);
        return () => document.removeEventListener(fullKey, getValueHandler);
      }
    }, [cell, fullKey, cell.value, cell.colSpan, className]);

    return (
      <WrappedComponent {...props} value={value} className={cellClassName} backgroundColor={cellBackgroundColor} />
    );
  };

withCellEventListener.propTypes = {
  cell: PropTypes.shape({
    getFullKey: PropTypes.func,
    value: PropTypes.any,
    backgroundColor: PropTypes.string,
    className: PropTypes.string,
    colSpan: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
};

export default withCellEventListener;
