import { isEmpty } from 'lodash';
import { NOT_APPLICABLE } from 'common/constants/general';
import { FinancialStatementsBasic } from 'common/types/financials';
import { getBooleanValue, getNumberValue, getObjectValue } from 'utillities';
import { FinancialsTransposed } from './types';

const transposeFinancials = (financialStatement?: FinancialStatementsBasic): FinancialsTransposed => {
  if (!isEmpty(financialStatement)) {
    const { ltm, ntm, use_adjusted_ebitda: useAdjustedEBITDA } = getObjectValue(financialStatement);

    // LTM
    const {
      balance_sheet: LTMBalanceSheet,
      ebitda_margin: LTMEBITDAMargin,
      gross_margin: LTMGROSSMargin,
      income_statement: LTMIncomeStatement,
      revenue_growth_rate: LTMRevenueGrowthRate,
    } = getObjectValue(ltm);

    // LTM Income Statement
    const {
      ebitda: LTMEBITDA,
      adjusted_ebitda: LTMAdjustedEBITDA,
      total_revenue: LTMRevenue,
    } = getObjectValue(LTMIncomeStatement);

    // LTM Balance Sheet
    const { total_cash_equivalents: TotalCashEquivalents, total_debt: TotalDebt } = getObjectValue(LTMBalanceSheet);

    // NTM
    const { income_statement: NTMIncomeStatement, revenue_growth_rate: NTMRevenueGrowthRate } = getObjectValue(ntm);

    // NTM Income Statement
    const {
      adjusted_ebitda: NTMAdjustedEBITDA,
      ebitda: NTMEBITDA,
      total_revenue: NTMRevenue,
    } = getObjectValue(NTMIncomeStatement);

    return {
      ltm_adjusted_ebitda: Number(getNumberValue(LTMAdjustedEBITDA)),
      ltm_ebitda_margin: getNumberValue(LTMEBITDAMargin),
      ltm_ebitda: Number(getNumberValue(LTMEBITDA)),
      ltm_gross_margin: getNumberValue(LTMGROSSMargin),
      ltm_revenue_growth_rate:
        LTMRevenueGrowthRate === NOT_APPLICABLE ? LTMRevenueGrowthRate : Number(getNumberValue(LTMRevenueGrowthRate)),
      ltm_revenue: Number(getNumberValue(LTMRevenue)),
      ntm_adjusted_ebitda: Number(getNumberValue(NTMAdjustedEBITDA)),
      ntm_ebitda: Number(getNumberValue(NTMEBITDA)),
      ntm_revenue_growth_rate:
        NTMRevenueGrowthRate === NOT_APPLICABLE ? NTMRevenueGrowthRate : Number(getNumberValue(NTMRevenueGrowthRate)),
      ntm_revenue: Number(getNumberValue(NTMRevenue)),
      total_cash_equivalents: Number(getNumberValue(TotalCashEquivalents)),
      total_debt: Number(getNumberValue(TotalDebt)),
      use_adjusted_ebitda: getBooleanValue(useAdjustedEBITDA),
      id: financialStatement.id,
    };
  }

  return {
    ltm_adjusted_ebitda: 0,
    ltm_ebitda_margin: 0,
    ltm_ebitda: 0,
    ltm_gross_margin: 0,
    ltm_revenue_growth_rate: NOT_APPLICABLE,
    ltm_revenue: 0,
    ntm_adjusted_ebitda: 0,
    ntm_ebitda: 0,
    ntm_revenue_growth_rate: NOT_APPLICABLE,
    ntm_revenue: 0,
    total_cash_equivalents: 0,
    total_debt: 0,
    use_adjusted_ebitda: false,
  };
};

export default transposeFinancials;
