/* eslint-disable no-case-declarations */
/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
import { isEmpty, isNull } from 'lodash';
import {
  CUMMULATIVE_DIVIDENDS_ROW_NUMBER,
  INVESTMENT_DATE_ALIAS,
  SECURITY_NAME_ALIAS,
  SECURITY_TYPE_ALIAS,
  SECURITY_TYPE_ROW_NUMBER,
  SENIORITY_ALIAS,
  SHARES_OUTSTANDING_ALIAS,
  UNDERLYING_SECURITY_ALIAS,
} from 'common/constants/cap-table';
import * as validationMessages from 'common/constants/messages/validations';
import { OPTION, PREFERRED_STOCK as PREFERRED, WARRANT } from 'common/constants/securityTypes';
import { toString } from 'utillities';
import { getPreferredSecurities } from './getOptionsForSelect';

const customValidations = ({
  cell,
  parsedColumns,
  tableData,
  addFeedback,
  addFeedbackFromCell,
  removeFeedbackFromCell,
}) => {
  const { alias, value, defaultValue, columnLegend, tooltipMessages } = cell;

  const { columns } = tableData;
  const preferredSecurities = getPreferredSecurities({ columns });
  const isSecurityType = type => {
    const securityType = parsedColumns[columnLegend + SECURITY_TYPE_ROW_NUMBER];

    if (type && securityType?.value) {
      if (securityType.value.toString() === type.toString()) {
        return true;
      }
    }
    return false;
  };

  const isCumulativeDividendsYes = () => {
    const dividendsCell = parsedColumns[columnLegend + CUMMULATIVE_DIVIDENDS_ROW_NUMBER];
    return dividendsCell.value === '1';
  };

  switch (alias) {
    case SECURITY_NAME_ALIAS: // 0
      // If is empty
      if (isNull(value) || isEmpty(toString(value).trim())) {
        addFeedback('Create a name for this security.');
      } else if (value === defaultValue) {
        addFeedback(validationMessages.REPLACE_DEFAULT_VALUE);
      } else if (value !== defaultValue) {
        removeFeedbackFromCell(cell, validationMessages.REPLACE_DEFAULT_VALUE);
      } else {
        // full row cell validation
        const securityNamaValidationObject = {};
        Object.entries(parsedColumns).forEach(([key, auxCell]) => {
          if (auxCell.alias === alias) {
            if (!securityNamaValidationObject[auxCell.value]) {
              securityNamaValidationObject[auxCell.value] = [];
            }
            // only save the cell reference
            if (cell.key === key) {
              // becuause the current cell is a copy that will by used to
              // replace in the parsedSecurities state variable, on SpreadSheet component
              securityNamaValidationObject[auxCell.value].push(cell);
            } else {
              securityNamaValidationObject[auxCell.value].push(auxCell);
            }
          }
        });

        Object.entries(securityNamaValidationObject).forEach(([, value]) => {
          if (value.length > 1) {
            value.forEach(auxCell => addFeedbackFromCell(auxCell, validationMessages.SECURITY_SAME_NAME));
          } else {
            removeFeedbackFromCell(value[0], validationMessages.SECURITY_SAME_NAME);
          }
        });
        // end full row validation
      }

      break;
    case INVESTMENT_DATE_ALIAS: // 02
      if (!isCumulativeDividendsYes() && isSecurityType(PREFERRED)) {
        // remove required feedback from the investment date cell
        removeFeedbackFromCell(cell, validationMessages.IS_REQUIRED_ERROR);
      } else if (isCumulativeDividendsYes() && isSecurityType(PREFERRED) && !value) {
        addFeedbackFromCell(cell, validationMessages.IS_REQUIRED_ERROR);
      }
      break;
    case SECURITY_TYPE_ALIAS: // 03
      if (Number(value) <= 0) {
        addFeedback(validationMessages.SELECT_VALID_SECURITY);
      } else {
        removeFeedbackFromCell(cell, validationMessages.SELECT_VALID_SECURITY);
      }
      if (tooltipMessages.length > 0) {
        addFeedback(tooltipMessages[0]);
      } else {
        removeFeedbackFromCell(cell, tooltipMessages[0]);
      }
      break;
    case SHARES_OUTSTANDING_ALIAS: // 05
      if (isSecurityType(PREFERRED)) {
        /*
          Validate if the sum of shares in the related columns in fund ownership,
          are less than the shares outstanding
        */
        const fundOwnershipColumns = tableData?.fund_ownership?.fund_ownership_detail
          ? tableData.fund_ownership.fund_ownership_detail
          : [];

        const relatedFundColumns = fundOwnershipColumns.filter(
          column => column.security?.toString() === cell.columnId.toString()
        );

        // Get the total shares of the related fund ownership columns
        if (relatedFundColumns?.length) {
          const totalFundOwnershipShares = relatedFundColumns.reduce((acc, curr) => acc + Number(curr.shares), 0);

          if (cell.value < totalFundOwnershipShares) {
            addFeedback(validationMessages.LESS_SHARES_IN_CAPTABLE_THAN_FUND_OWNERSHIP);
          }
        }
      }
      break;

    case UNDERLYING_SECURITY_ALIAS: // 12
      if ([0, '0', null].includes(value)) {
        if (isSecurityType(OPTION)) {
          addFeedback('Please select the security that this option purchases when exercised.');
        }

        if (isSecurityType(WARRANT)) {
          addFeedback('Please select the security that this warrant purchases when exercised.');
        }
      }
      break;
    case SENIORITY_ALIAS: // 15
      if (value === 'SELECT PRIORITY' || value > preferredSecurities.length) {
        addFeedback('Please select the liquidation priority.');
      }
      break;
    default:
      break;
  }
};

export default customValidations;
