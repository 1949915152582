import removeNonAlpha from './removeNonAlpha';

const getInitials = ({ names = '', maxInitials = 2 }) => {
  const sanitizedStr = removeNonAlpha(names);
  return sanitizedStr
    .split(' ')
    .map((name, nameIndex) => (nameIndex < maxInitials ? name[0] : ''))
    .join('')
    .toUpperCase();
};

export default getInitials;
