import { alphabetGenerator } from 'utillities/alphabet-utilities';

export default async ({ columns, rowConfig }) => {
  let cells = {};
  const alphabet = alphabetGenerator([], columns.length);
  const getExpr = (expr, columnLegend) => expr.replace(/@/g, `${columnLegend}`);

  rowConfig.forEach((row, rowIdx) => {
    columns.forEach((col, colIdx) => {
      const rowNumber = rowIdx + 1;
      const columnLegend = alphabet[colIdx];
      const key = columnLegend + rowNumber;
      const expr = getExpr(row.expr, columnLegend);
      const value = col[row.alias];

      const customKey = colIdx === columns.length - 1 ? row.alias : undefined;

      cells = {
        ...cells,
        [key]: {
          ...row,
          customKey,
          expr,
          key,
          columnLegend,
          value,
          className: '',
        },
      };
    });
  });

  return cells;
};
