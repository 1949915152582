import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { CompanyDescription } from 'pages/Valuations/components/CompanyDescription';
import { getGPCTableRows, GPCData } from 'pages/ValuationsAllocation/util';
import useStyles from './styles';
import { CompanyInfoDialogProps } from './types';

const CompanyInfoDialog: FC<CompanyInfoDialogProps> = props => {
  const classes = useStyles();
  const { openDialog, setOpenDialog, data: comparisonData, financialsCurrency } = props;
  const [truncateDescription, setTruncateDescription] = useState(true);

  const toggleDescriptionTruncate = () => {
    setTruncateDescription(!truncateDescription);
  };

  const onClose = () => {
    setOpenDialog(false);
  };

  const gpcData: GPCData = Object.keys(comparisonData).reduce((acc, key) => {
    const value = comparisonData[key as keyof typeof comparisonData];
    acc[key as keyof GPCData] = { value: value as never };

    return acc;
  }, {} as GPCData);

  const tableData = getGPCTableRows({ gpcData, financialsCurrency });

  if (!tableData) return null;
  return (
    <Dialog
      aria-labelledby="comapny-info"
      fullWidth
      maxWidth="sm"
      data-testid="companyNameDialog"
      open={openDialog}
      onClose={onClose}>
      <DialogTitle id="comapny-info-title">
        <Typography variant="h2">{tableData.companyName}</Typography>
        <Typography>{tableData.ticker}</Typography>
      </DialogTitle>
      <DialogContent data-testid="company-info-content" dividers>
        {tableData.businessDescription && (
          <div className={classes.descriptionContent}>
            <CompanyDescription
              description={tableData.businessDescription}
              truncate={truncateDescription}
              toggleDescription={toggleDescriptionTruncate}
            />
          </div>
        )}
        <Table aria-label="Guideline Public Company Table" size="small">
          <TableBody>
            {tableData?.tableRows?.map((tableRow, key) => (
              <TableRow key={`${tableRow.label}-${tableRow.value}`}>
                <TableCell align="right" className={classes.tableCellValue}>
                  <strong data-testid={`company-data-row-value-${key}`}>{tableRow.value}</strong>
                </TableCell>
                <TableCell align="left">{tableRow.label}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions id="comapny-info-actions">
        <Button
          id={`${comparisonData.company_name}-cancel-btn`}
          className={classes.cancelBtn}
          variant="contained"
          onClick={onClose}>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyInfoDialog;
