import React from 'react';
import { Dialog, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ADD_COMP_GROUP_TYPES } from 'common/constants/comp-groups/compGroups';
import theme from 'theme';

const useStyles = makeStyles(() => ({
  paperButton: {
    backgroundColor: '#0380E8',
    marginBottom: '0.8rem',
    borderRadius: '2rem',
    width: 'fit-content',
    textTransform: 'uppercase',
    '& .MuiTypography-displayBlock': {
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const NewCompDialog = ({ openDialog, handleCompClose, handleApproachTypeClick }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={handleCompClose}
      aria-labelledby="comp-group-dialog"
      open={openDialog}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          position: 'fixed',
          zIndex: 3,
          bottom: '2rem',
          right: '1rem',
        },
      }}>
      <List>
        {ADD_COMP_GROUP_TYPES.map(approach => (
          <ListItem
            className={classes.paperButton}
            button
            onClick={() => handleApproachTypeClick(approach)}
            key={approach}>
            <ListItemText primary={approach} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

NewCompDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  handleCompClose: PropTypes.func.isRequired,
  handleApproachTypeClick: PropTypes.func.isRequired,
};

export default NewCompDialog;
