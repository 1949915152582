export const VOLATILITY_TITLE = 'Volatility';
export const VOLATILITY_ALIAS = 'volatility';
export const MATURITY_TITLE = 'Maturity (Years)';
export const MATURITY_ALIAS = 'maturity';
export const RISK_TITLE = 'Risk Free Rate';
export const RISK_ALIAS = 'risk_free_rate';
export const VOLATILITY_SOURCE_TITLE = 'Volatility Source';
export const VOLATILITY_SOURCE_ALIAS = 'volatility_source';
export const VOLATILITY_SOURCE_REFERENCE = 'reference_for_volatility';
export const OPM_BACKSOLVE_DATE_TITLE = 'Backsolve Date';
export const OPM_BACKSOLVE_DATE_ALIAS = 'opm_backsolve_date';
export const SPECIFY_VOLATILITY = 'Specify Volatility';
export const SPECIFIED_VOLATILITY = 'Specified';
export const MATURITY_DATE_TITLE = 'Maturity Date';
export const MATURITY_DATE_ALIAS = 'maturity_date';
export const PUBLIC_COMPANIES = 'Guideline Public Companies';
export const SPECIFIED_VOLATILITY_OPTIONS = [
  {
    id: SPECIFIED_VOLATILITY,
    name: SPECIFIED_VOLATILITY,
  },
  {
    id: RISK_TITLE,
    name: RISK_TITLE,
  },
];
