import React from 'react';
import { isNull, isUndefined } from 'lodash';
import { all, create } from 'mathjs';
import * as customFormulas from '../../../common/formulas/math.js';
import ColoredSymbol from '../components/ColoredSymbol';

const math = create(all);
math.import(customFormulas);

/**
 * @param {string} contentTableId id of useRef for content table
 * @param {string} cellKey Key of selected cell
 */
export const getCellRef = (tableRef, cellKey) => {
  const fullCellKey = `CELL-${cellKey}`;
  const cellRef = tableRef.current.querySelector(`#${fullCellKey}`);
  if (cellRef) return cellRef;
  return tableRef.current.querySelector(`td[data-cell-id=${fullCellKey}]`);
};

/**
 * @param {Object} cells Cells Object.
 * @param {string} cellKey Key of selected cell
 * @param {string} cellRef Reference of cell on the DOM.
 */
export const hasCellColSpan = (cells, cellKey, cellRef) => {
  const cellInfo = cells[cellKey];
  if (cellInfo) {
    const cellInfoColSpan = cellInfo.colSpan;
    return !isUndefined(cellInfoColSpan) && cellInfoColSpan > 1;
  }
  if (cellRef) {
    const cellColSpan = cellRef.getAttribute('colspan');
    return !isNull(cellColSpan) && cellColSpan > 1;
  }
  return false;
};

/**
 * @param {Object} cells Cells Object.
 * @param {string} cellKey Key of selected cell
 * @param {string} cellRef Reference of cell on the DOM.
 */
export const hasCellRowSpan = (cells, cellKey, cellRef) => {
  const cellInfo = cells[cellKey];
  if (cellInfo) {
    const cellInfoRowSpan = cellInfo.rowSpan;
    return !isUndefined(cellInfoRowSpan) && cellInfoRowSpan > 1;
  }
  if (cellRef) {
    const cellRowSpan = cellRef.getAttribute('colspan');
    return !isNull(cellRowSpan) && cellRowSpan > 1;
  }
  return false;
};

/**
 * @param {Object} cells Cells Object.
 * @param {string | number} key this could be a columnLetter or rowNumber.
 * @param {boolean} isColumn this param is used to determine the way to get the data values.
 */
export const getValuesToCopy = (cells, key, isColumn) => {
  const comparisson = item => (isColumn ? item.startsWith(key) : parseInt(item.slice(1), 10) === key);

  const filteredKeys = Object.keys(cells).filter(item => comparisson(item));
  const selectedValues = filteredKeys.map(keyItem => cells[keyItem]);

  let valuesToCopy = [];
  if (isColumn) {
    const filteredValues = selectedValues.filter(
      ({ isVisible, isTitleOrHeader, isTotal }) => isVisible !== false && !isTitleOrHeader && !isTotal
    );
    const values = filteredValues.map(cell => (cell.hidden ? '' : cell.value));
    valuesToCopy = values.join('\n');
  } else {
    const rowValues = selectedValues.map(cell => (cell.hidden ? '' : cell.value));
    // Use tab as separator for tabular data
    valuesToCopy = rowValues.join('\t');
  }
  return valuesToCopy;
};

/**
 * @param {String} values string separated with tab or enter, depending of row or column
 * @param {Function} infoNotification
 * @param {Function} errorNotification
 */
export const copyToClipboard = (values, infoNotification, errorNotification) => {
  navigator.clipboard
    .writeText(values)
    .then(() => {
      infoNotification('Values copied to clipboard successfully!');
    })
    .catch(() => {
      errorNotification('Error copying values to clipboard');
    });
};
export class SymbolHandler {
  constructor(cell) {
    const symbols = Array.from(cell.sheet.cells[cell.key].getSymbols());
    const keyCellMap = Array.from(cell.sheet.cells[cell.key].providers).reduce((sofar, next) => {
      const keys = next.getRelativeKeys(cell.sheet).flat();
      return keys.reduce((map, key) => ({ ...map, [key]: next }), sofar);
    }, {});
    const strExpr = cell.sheet.cells[cell.key].getExprString();
    const node = math.parse(strExpr.substring(1));
    this.node = node;
    this.list = [];
    this.keyCellMap = keyCellMap;
    this.symbols = symbols;
  }

  insertSeparator(i, childList) {
    if (i < childList.length - 1) {
      this.list.push(',');
    }
  }

  handleAccessorNode(node) {
    const symbol = node.toString();
    if (this.symbols.includes(symbol)) {
      this.list.push(
        <ColoredSymbol
          symbol={symbol}
          value={this.keyCellMap[symbol]?.value}
          colorIndex={this.symbols.indexOf(symbol)}
        />
      );
    }
  }

  handleArrayNode(node) {
    node.items.forEach((item, i) => {
      this.traverseAndReplaceSymbols(item);
      this.insertSeparator(i, node.items);
    });
  }

  handleFunctionNode(node) {
    this.list.push(`${node.fn.name}(`);
    node.args.forEach((child, i) => {
      this.traverseAndReplaceSymbols(child);
      this.insertSeparator(i, node.args);
    });
    this.list.push(')');
  }

  handleOperatorNode(node) {
    if (node.args.length === 2) {
      this.traverseAndReplaceSymbols(node.args[0]);
      this.list.push(node.op);
      this.traverseAndReplaceSymbols(node.args[1]);
    } else if (node.args.length === 1) {
      this.list.push(node.op);
      this.traverseAndReplaceSymbols(node.args[0]);
    } else {
      throw new Error('unhandled number of args for operator node');
    }
  }

  handleObjectNode(node) {
    this.list.push('{');
    const propertiesArray = Object.entries(node.properties);
    propertiesArray.forEach(([key, value], i) => {
      this.list.push(`"${key}": `);
      this.traverseAndReplaceSymbols(value);
      this.insertSeparator(i, propertiesArray);
    });
    this.list.push('}');
  }

  traverseAndReplaceSymbols(node) {
    switch (true) {
      case node.isSymbolNode && this.symbols.includes(node.name):
        this.list.push(
          <ColoredSymbol
            symbol={node.name}
            value={this.keyCellMap[node.name]?.value}
            colorIndex={this.symbols.indexOf(node.name)}
          />
        );
        break;
      case node.isOperatorNode:
        this.handleOperatorNode(node);
        break;
      case node.isConstantNode:
        this.list.push(node.value);
        break;
      case node.isFunctionNode:
        this.handleFunctionNode(node);
        break;
      case node.isArrayNode:
        this.list.push('[');
        this.handleArrayNode(node);
        this.list.push(']');
        break;
      case node.isAccessorNode:
        this.handleAccessorNode(node);
        break;
      case node.isObjectNode: {
        this.handleObjectNode(node);
        break;
      }
      case node.isParenthesisNode:
        this.list.push('(');
        this.traverseAndReplaceSymbols(node.content);
        this.list.push(')');
        break;
      default:
        this.list.push(node.toString());
    }
  }

  getExpressionList() {
    this.traverseAndReplaceSymbols(this.node);
    return this.list;
  }
}
