import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { List, Paper, TextField, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import useStayScrolled from 'react-stay-scrolled';
import { SELECT_A_COMPANY } from 'common/constants/login';
import { HiddenContentChip } from 'components';
import { IStandaloneCompaniesPermissions } from 'dashboard409a/states/features/user';
import { RootStateType } from 'dashboard409a/states/store';
import { CompaniesList } from './components';
import useStyles from './styles';
import getFilteredCompanies from './utilities/getFilteredCompanies';
import getHiddenCompanies from './utilities/getHiddenCompanies';

const FILTER_COMPANY_NUMBER = 1;

const CompanySelector: FC = () => {
  const [hiddenBottom, setHiddenBottom] = useState(0);

  // TODO: Refactor company filtered with Hook
  const [searchCompany, setSearchCompany] = useState('');
  const [filteredCompaniesList, setFilteredCompaniesList] = useState<IStandaloneCompaniesPermissions[]>([]);
  const user = useSelector((state: RootStateType) => state.user);

  const divRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  useStayScrolled(divRef);

  const classes = useStyles();

  // set filteredCompaniesList with Store and apply search filter
  useEffect(() => {
    getFilteredCompanies(searchCompany, user.standalone_companies_permissions, setFilteredCompaniesList);
  }, [searchCompany, user.standalone_companies_permissions]);

  // Focus on textfield
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [inputRef]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setSearchCompany(value || '');
  };

  return (
    <Paper className={classes.root}>
      <List
        id="companiesList"
        component="nav"
        ref={divRef}
        className={classes.companiesList}
        onScroll={() => getHiddenCompanies(hiddenBottom, setHiddenBottom)}>
        {/* Header */}
        <div className={classes.listHeader}>
          {user.standalone_companies_permissions
          && user.standalone_companies_permissions.length > FILTER_COMPANY_NUMBER ? (
              <TextField
                inputRef={inputRef}
                label={SELECT_A_COMPANY.toUpperCase()}
                variant="standard"
                className={classes.searchInput}
                value={searchCompany}
                onChange={handleInputChange}
              />
            ) : (
              <Typography variant="h2">{SELECT_A_COMPANY}</Typography>
            )}
        </div>

        {/* List */}
        <CompaniesList filteredCompaniesList={filteredCompaniesList} />
      </List>
      <HiddenContentChip isVisible={hiddenBottom > 0} label={`${hiddenBottom} companies`} position="bottom" />
    </Paper>
  );
};

export default CompanySelector;
