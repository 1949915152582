import {
  EQUITY_ALLOCATION_SPREADSHEET_COLUMN_NAME,
  EQUITY_ALLOCATION_SPREADSHEET_COLUMN_NAME_PLURAL,
  EQUITY_ALLOCATION_SPREADSHEET_TABLE,
  EQUITY_ALLOCATION_SPREADSHEET_TITLE,
} from 'pages/ValuationsAllocation/common/constants/equityAllocation/equityAllocation';

export const EQUITY_ALLOCATION_SPREADSHEET_TABLE_TERMS = Object.freeze({
  columnName: EQUITY_ALLOCATION_SPREADSHEET_COLUMN_NAME,
  pluralColumnName: EQUITY_ALLOCATION_SPREADSHEET_COLUMN_NAME_PLURAL,
  tableName: EQUITY_ALLOCATION_SPREADSHEET_TITLE,
  tableSlug: EQUITY_ALLOCATION_SPREADSHEET_TABLE,
});
