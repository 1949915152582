/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SELECT_DATE } from 'common/constants/inputs';
import { CustomSelect, withTooltip } from 'components';
import { LedgerContext } from 'context';

const InvestmentDateSelect = ({ cell, investmentDates }) => {
  const { onCellsChange } = useContext(LedgerContext);

  const handleChange = value => {
    const newValue = value.toString();
    onCellsChange([{ cell, value: newValue }]);
  };

  const getOptions = useMemo(() => {
    const tmpOptions = [];

    if (Array.isArray(investmentDates)) {
      investmentDates.forEach(date => {
        tmpOptions.push({
          value: date,
          label: date,
        });
      });
    }

    return tmpOptions;
  }, [investmentDates]);

  return (
    <CustomSelect
      id={`investment-date-${cell.key}`}
      value={cell.value || ''}
      options={getOptions}
      openMenuOnFocus={false}
      onChange={handleChange}
      onEmptyValue={SELECT_DATE}
      disabled={cell.readOnly}
      setLabelAsValue
      disableUnderline
      autoFocus={false}
    />
  );
};

InvestmentDateSelect.defaultProps = {
  investmentDates: [],
};

InvestmentDateSelect.propTypes = {
  cell: PropTypes.object,
  investmentDates: PropTypes.any,
};

export default withTooltip(InvestmentDateSelect);
