export const BACKSOLVE_TITLE = 'Backsolve';
export const ALLOCATION_METHOD_TITLE = 'Allocation Method';
export const ALLOCATION_METHOD_ALIAS = 'allocation_method';
export const ALLOCATION_BACKSOLVE_WEIGHTING_TITLE = 'Allocation Backsolve Weighting';
export const ALLOCATION_BACKSOLVE_WEIGHTING_ALIAS = 'weight';
export const PRESENT_SHARE_VALUES_TITLE = 'Present Share Values';
export const PRESENT_SHARE_VALUES_ALIAS = 'present_share_value';

export const ALLOCATION_METHOD_DUPLICATE_ERROR = 'The allocation method must be unique';
export const TOTAL_WEIGHT_ERROR = 'Total weight should be equal to 100.';
export const WEIGHT_VALUE_ERROR = 'The value must be greater than 0.';
