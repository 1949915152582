/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { COMPANY, USE_PORTAL } from 'common/constants/user';
import useGetFirmUsers from 'services/hooks/useGetFirmUsers';
import { getUserPermissions } from 'services/hooks/useUserSelected';

const useCompanyCategoriesProcessManagement = ({ firmId, companies }) => {
  const HAS_MEASUREMENT_DATE = true;
  const NO_MEASUREMENT_DATE = false;
  const HAS_USERS = true;
  const NO_USERS = false;

  const { getFirmUsers } = useGetFirmUsers(firmId);
  const [users, setUsers] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [companyCategories, setCompanyCategories] = useState({
    companiesWithMeasurementDateAndUsers: [],
    companiesWithMeasurementDateButNoUsers: [],
    companiesWithoutMeasurementDateButUsers: [],
    companiesWithoutMeasurementDateAndUsers: [],
  });

  // functions to get and set firm users and permissions
  const handleGetAndSetFirmUsers = useCallback(async () => {
    try {
      const tmpUsers = await getFirmUsers();
      setUsers(tmpUsers);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [getFirmUsers]);

  const handleGetAndSetFirmUsersPermissions = useCallback(async () => {
    try {
      const promises = users.map(user => getUserPermissions(user.id, firmId));
      const tmpUserPermissions = await Promise.all(promises);
      setUserPermissions(tmpUserPermissions.flat());
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [users]);

  // function to get user permissions for a company
  const getUserPermissionsForCompany = (userPermissionsParam, company) =>
    userPermissionsParam.filter(permission => {
      if (!permission?.feature_object) return false;

      const {
        feature_object: { object_type: objectType, object_id: objectId },
        access_type: accessType,
      } = permission;

      const isCompanyType = objectType === COMPANY;
      const isAccessType = accessType === USE_PORTAL;
      const isCompanyId = objectId === company.id;

      return isCompanyType && isAccessType && isCompanyId;
    });

  const handleGetUserPermissions = company => {
    const userPermissionsForCompany = getUserPermissionsForCompany(userPermissions, company);
    if (userPermissionsForCompany.length) {
      return userPermissionsForCompany;
    }
    return null;
  };

  const mapUserPermissionsToUsers = (permission, usersParam) => {
    const user = usersParam.find(userParam => userParam.id === permission.user_id);
    return user;
  };

  const processCompany = (company, usersParam) => {
    const tmpUsers = handleGetUserPermissions(company);
    const usersWithCompanyPermissions = tmpUsers?.map(permission => mapUserPermissionsToUsers(permission, usersParam));

    if (tmpUsers && tmpUsers.length > 0) {
      return { ...company, users_with_company_permissions: usersWithCompanyPermissions };
    }
    return company;
  };

  // to get and set company categories first I need to get the companies with and without users
  const getCompaniesWithAndWithoutUsers = (companiesParam, userPermissionsParam, usersParam) => {
    if (!userPermissionsParam.length) return { companiesWithUsers: [], companiesWithoutUsers: [] };

    const tmpCompaniesWithUsers = [];
    const tmpCompaniesWithoutUsers = [];

    companiesParam.forEach(company => {
      const processedCompany = processCompany(company, usersParam);

      if (processedCompany.users_with_company_permissions) {
        tmpCompaniesWithUsers.push(processedCompany);
      } else {
        tmpCompaniesWithoutUsers.push(processedCompany);
      }
    });

    return { tmpCompaniesWithUsers, tmpCompaniesWithoutUsers };
  };

  const [companiesWithUsers, companiesWithoutUsers] = useMemo(() => {
    if (userPermissions.length && users.length) {
      const { tmpCompaniesWithUsers, tmpCompaniesWithoutUsers } = getCompaniesWithAndWithoutUsers(
        companies,
        userPermissions,
        users
      );
      return [tmpCompaniesWithUsers, tmpCompaniesWithoutUsers];
    }
    return [[], []];
  }, [companies, userPermissions, users]);

  const companyHasUsersWithPermissions = company => Boolean(company.users_with_company_permissions);
  const companyHasMeasurementDate = company => Boolean(company.latest_cmd);

  const filterCompanies = (companiesParam, hasMeasurementDateCondition, hasUsersCondition) =>
    companiesParam.filter(
      company =>
        companyHasMeasurementDate(company) === hasMeasurementDateCondition
        && companyHasUsersWithPermissions(company) === hasUsersCondition
    );

  // functions set company categories
  const handleSetCompaniesCategories = useCallback(() => {
    const companiesWithMeasurementDateAndUsers = filterCompanies(companiesWithUsers, HAS_MEASUREMENT_DATE, HAS_USERS);
    const companiesWithMeasurementDateButNoUsers = filterCompanies(
      companiesWithoutUsers,
      HAS_MEASUREMENT_DATE,
      NO_USERS
    );
    const companiesWithoutMeasurementDateButUsers = filterCompanies(companiesWithUsers, NO_MEASUREMENT_DATE, HAS_USERS);
    const companiesWithoutMeasurementDateAndUsers = filterCompanies(
      companiesWithoutUsers,
      NO_MEASUREMENT_DATE,
      NO_USERS
    );

    setCompanyCategories({
      companiesWithMeasurementDateAndUsers,
      companiesWithMeasurementDateButNoUsers,
      companiesWithoutMeasurementDateButUsers,
      companiesWithoutMeasurementDateAndUsers,
    });
  }, [companiesWithoutUsers, companiesWithUsers]);

  useEffect(() => {
    handleGetAndSetFirmUsers();
  }, []);

  useEffect(() => {
    if (users.length) handleGetAndSetFirmUsersPermissions();
  }, [users]);

  useEffect(() => {
    if (companiesWithUsers.length || companiesWithoutUsers.length) handleSetCompaniesCategories();
  }, [companiesWithUsers, companiesWithoutUsers]);

  return { companyCategories };
};

export default useCompanyCategoriesProcessManagement;
