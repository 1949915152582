import { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useStore } from 'common/store';
import { ParserParams } from 'common/types/scalarSpreadsheet';
import { UseStoreValues } from 'common/types/store';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { SHEET_CONFIG_CONSTANTS } from 'pages/Funds/common/constants/fundSummary';
import { useCellCustomClasses } from 'pages/Funds/hooks';
import { createColumns, customParser, FundSummaryColumn, rowConfig } from 'pages/Funds/pages/FundSummary/config';
import { getArrayValue, getDateValue, getNumberValue, getStringValue } from 'utillities';
import { UseFundSummarySheet } from './types';

const { FUND_SUMMARY_SPREADSHEET_TABLE_TERMS } = SHEET_CONFIG_CONSTANTS;

const useFundSummarySheet: UseFundSummarySheet = params => {
  const { fundSummary, isProcessManagementEnabled, measurementDate, tasksProgress } = params;

  const [storeValue] = useStore() as unknown as UseStoreValues;
  const { firmInfo, fundInfo } = storeValue;

  const { cellCustomClasses } = useCellCustomClasses();

  const companies = useMemo(() => getArrayValue(fundSummary?.companies), [fundSummary]);
  const fundGrossIRR = useMemo(() => getNumberValue(fundSummary?.fund_gross_irr), [fundSummary]);

  // Create row config for Fund Summary Spreadsheet
  const rows = useMemo(
    () => rowConfig({ companies, firmInfo, measurementDate }),
    [companies, firmInfo, measurementDate]
  );

  // Create columns for Fund Summary Spreadsheet
  const columns = useMemo(() => {
    if (!isEmpty(companies)) return createColumns({ companies, isProcessManagementEnabled });

    return [];
  }, [companies, isProcessManagementEnabled]);

  // Create parser with custom props
  const parser = useCallback(
    (props: ParserParams<FundSummaryColumn>) =>
      customParser({
        ...props,
        cellCustomClasses,
        fundGrossIRR,
        fundName: getStringValue(fundInfo?.name),
        isProcessManagementEnabled,
        measurementDateName: getDateValue(measurementDate?.name),
        tasksProgress,
      }),
    [cellCustomClasses, fundGrossIRR, fundInfo, isProcessManagementEnabled, measurementDate, tasksProgress]
  );

  // Create Spreadsheet config
  const spreadsheet = useMemo(() => {
    if (!isEmpty(columns)) {
      const spreadsheetConfig = new SpreadsheetConfig({
        allowConfirmAndDeleteColumn: false,
        alwaysDisplayLegend: false,
        colTitleRow: 0, // Add header row styles
        columns,
        currencyFormatter: true,
        format: undefined,
        hasColTitle: true, // Add styles to header row (colTitleRow)
        name: FUND_SUMMARY_SPREADSHEET_TABLE_TERMS.tableName,
        page: FUND_SUMMARY_SPREADSHEET_TABLE_TERMS.tableName as unknown as SpreadsheetConfig['page'],
        parser,
        rowConfig: rows,
        showPreviousColsDivider: false,
        showTitlesColumn: true,
        showToolbar: true,
        showTotalColumn: false,
        tableData: { columns },
        tableTerms: FUND_SUMMARY_SPREADSHEET_TABLE_TERMS as unknown as SpreadsheetConfig['tableTerms'],
        totalParser: undefined,
        unitsFormatter: true,
      });

      return spreadsheetConfig;
    }

    return null;
  }, [columns, parser, rows]);

  return {
    spreadsheet,
  };
};

export default useFundSummarySheet;
