/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-unused-vars,max-classes-per-file
import { LAST_TWELVE_MONTHS, NEXT_TWELVE_MONTHS } from 'common/constants/financials';
import { GRID_NUMBER_CHECKBOX } from 'common/constants/gridType';
import { MULTIPLE_PREMIUM_DISCOUNT_ALIAS, SELECTION_A, SELECTION_B } from 'common/constants/valuations';
import {
  mainColumnLetters,
  MEAN_ALIAS,
  MEDIAN_ALIAS,
  MULTIPLE_BASIS_ALIAS,
  PERCENTILE_SELECTION_A_ALIAS,
  PERCENTILE_SELECTION_B_ALIAS,
  SELECTED_DATABASE_VALUE,
  SELECTED_MULTIPLE,
  SELECTION,
  SPECIFIED_LABEL,
  VALUES_OPTIONS,
  WEIGHTED_EV,
  WEIGHTING,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { percentileOptions } from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/getRowConfig';
import {
  LTM_EBITDA_ID,
  LTM_REVENUE_ID,
  NTM_EBITDA_ID,
  NTM_REVENUE_ID,
} from 'pages/Valuations/approaches/GuidelineTransactions/config/constants';
import { getFixedValue, trimUpdateFields } from 'pages/Valuations/util/util';
import { parseDatabaseValue } from 'utillities';

const GPC_COMPARISON_FIELDS = [
  'ltm_revenue_growth',
  'ltm_ebitda_margin',
  'ltm_gross_margin',
  'fy_plus1_revenue',
  'fy_plus2_revenue',
  'ntm_gross_margin',
  'ntm_ebitda_margin',
  'fy_plus1_ebitda',
  'fy_plus2_ebitda',
  'current_portion_of_debt',
  'short_term_debt',
  'current_debt',
  'current_portion_of_leases',
  'long_term_debt',
  'non_current_debt',
  'capital_lease_liabilities',
  'effective_tax_rate',
  'cost_borrowing',
  'beta_5yr',
  'volume',
  'price_volume_historical_1yr',
  'price_volume_historical_2yr',
  'price_volume_historical_5yr',
  'close_price',
  'market_cap',
  'enterprise_value',
  'ltm_revenue',
  'ltm_ebitda',
  'ntm_revenue',
  'ntm_ebitda',
];

const extractMultiple = (cell, updated_gpc_approach, alias) => {
  // these aren't stored in DB -> don't fix decimal places
  const key = `${cell.columnId}_multiple_options`;
  if (!updated_gpc_approach[key]) {
    updated_gpc_approach[key] = {};
  }
  updated_gpc_approach[key][alias] = cell.value;
};

// this function will update the specificApproach's selection field with the value from the cell object
export function reverseSelectionCell(cell, specificApproach, fieldPrefix = '') {
  const fieldName = `${fieldPrefix || cell.columnId}_${SELECTION}`;
  if (cell.value === SPECIFIED_LABEL) {
    specificApproach[fieldName] = SELECTED_DATABASE_VALUE;
  } else if (VALUES_OPTIONS.includes(cell.value)) {
    specificApproach[fieldName] = cell.value;
  } else {
    const percentileOption = percentileOptions.find(option => option.label === cell.value);
    if (percentileOption) {
      if (Number(specificApproach.percentile_selection_a) === percentileOption.value) {
        specificApproach[fieldName] = SELECTION_A;
      } else if (Number(specificApproach.percentile_selection_b) === percentileOption.value) {
        specificApproach[fieldName] = SELECTION_B;
      }
    }
  }
}

function reverseParse(cell, comparisonMap, updated_gpc_approach, gpcApproachAttrs) {
  if (cell.gridType === GRID_NUMBER_CHECKBOX) {
    comparisonMap[cell.alias][`${cell.columnId}_enabled`] = cell.enabled;
    comparisonMap[cell.alias][`${cell.columnId}`] = parseFloat(cell.revenue).toFixed(2);
  }
  const decimalPlaces = gpcApproachAttrs[cell.alias]?.decimal_places || 2;

  if (cell.alias === SELECTION) {
    reverseSelectionCell(cell, updated_gpc_approach);
  } else if (cell.alias === SELECTED_MULTIPLE) {
    updated_gpc_approach[`${cell.columnId}_${SELECTED_MULTIPLE}`] = getFixedValue(cell.value, decimalPlaces);
    extractMultiple(cell, updated_gpc_approach, SELECTED_MULTIPLE);
  } else if (cell.alias === WEIGHTING) {
    updated_gpc_approach[`${cell.columnId}_${WEIGHTING}`] = parseInt(cell.value, 10) / 100;
  } else if (cell.alias === WEIGHTED_EV) {
    const enterpriseValueDecimalPlaces = gpcApproachAttrs.weighted_enterprise_value.decimal_places;
    updated_gpc_approach.weighted_enterprise_value = getFixedValue(cell.value, enterpriseValueDecimalPlaces);
  } else if (cell.alias === MEAN_ALIAS) {
    extractMultiple(cell, updated_gpc_approach, MEAN_ALIAS);
  } else if (cell.alias === MEDIAN_ALIAS) {
    extractMultiple(cell, updated_gpc_approach, MEDIAN_ALIAS);
  } else if (cell.alias === PERCENTILE_SELECTION_A_ALIAS) {
    extractMultiple(cell, updated_gpc_approach, PERCENTILE_SELECTION_A_ALIAS);
  } else if (cell.alias === PERCENTILE_SELECTION_B_ALIAS) {
    extractMultiple(cell, updated_gpc_approach, PERCENTILE_SELECTION_B_ALIAS);
  } else if (cell.alias === MULTIPLE_BASIS_ALIAS) {
    updated_gpc_approach.multiple_basis = cell.value;
  } else if (cell.alias === MULTIPLE_PREMIUM_DISCOUNT_ALIAS) {
    const columnIdToPropertyMap = {
      [LTM_REVENUE_ID]: 'ltm_revenue_multiple_premium_discount',
      [LTM_EBITDA_ID]: 'ltm_ebitda_multiple_premium_discount',
      [NTM_REVENUE_ID]: 'ntm_revenue_multiple_premium_discount',
      [NTM_EBITDA_ID]: 'ntm_ebitda_multiple_premium_discount',
    };

    if (updated_gpc_approach.use_multiple_premium_discount) {
      const property = columnIdToPropertyMap[cell.columnId];
      if (property) {
        const cellValue = parseDatabaseValue({
          type: 'string',
          value: cell.value,
          format: cell.format,
          allowEmptyValues: true,
          gridType: cell.gridType,
          decimalPlaces: gpcApproachAttrs[property].decimal_places,
        });
        updated_gpc_approach[property] = cellValue;
      }
    } else {
      Object.keys(columnIdToPropertyMap).forEach(key => {
        updated_gpc_approach[columnIdToPropertyMap[key]] = null;
      });
    }
  }
}

const reverseParser = ({ cells, tableData, fieldAttributes }) => {
  const { approach } = tableData;
  const { comparisonAttrs, gpcApproachAttrs } = fieldAttributes;

  if (cells) {
    const comparisons = approach?.valuations_approach_gpc?.gpc_comparison || [];
    const comparisonMap = comparisons.reduce((map, comparison) => {
      // eslint-disable-next-line no-param-reassign
      map[comparison.cap_iq_id] = {
        ...comparison,
        ...trimUpdateFields({
          comparison,
          fieldAttributes: comparisonAttrs,
          fieldList: GPC_COMPARISON_FIELDS,
        }),
      };
      // eslint-disable-next-line no-param-reassign
      map[comparison.cap_iq_id].name = comparison.company_name;
      return map;
    }, {});

    const ltmFinancialPeriodId = cells.E1.value === LAST_TWELVE_MONTHS ? null : cells.E1.value;
    const ntmFinancialPeriodId = cells.G1.value === NEXT_TWELVE_MONTHS ? null : cells.G1.value;

    const updated_gpc_approach = {
      ...approach.valuations_approach_gpc,
      ltm_financial_statement_period_id: ltmFinancialPeriodId,
      ntm_financial_statement_period_id: ntmFinancialPeriodId,
      percentile_selection_a: Number(cells.TITLES_percentile_selection_a.value),
      percentile_selection_b: Number(cells.TITLES_percentile_selection_b.value),
    };
    Object.values(cells).forEach(cell => {
      if (mainColumnLetters.indexOf(cell.columnLegend) >= 0) {
        reverseParse(cell, comparisonMap, updated_gpc_approach, gpcApproachAttrs);
      }
    });
    reverseParse(cells.D2, comparisonMap, updated_gpc_approach, gpcApproachAttrs);
    updated_gpc_approach.gpc_comparison = Object.values(comparisonMap);
    tableData.approach.valuations_approach_gpc = updated_gpc_approach;
  }
};

export default reverseParser;
