import React, { useContext } from 'react';
import SelectValueViewer from 'components/SelectValueViewer';
import FutureExitContext from 'pages/ValuationsAllocation/approaches/FutureExit/utils/FutureExitContext';

const FilteredMultipleViewer = (props: any) => {
  const { multipleOptions } = useContext(FutureExitContext);
  return <SelectValueViewer options={multipleOptions} {...props} useLabel />;
};

export default FilteredMultipleViewer;
