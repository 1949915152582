import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import CompGroupContext from 'context/CompGroupContext';
import { getSelectionCellOptions } from 'pages/ValuationsAllocation/util/getSelectionCellOptions';

const MultipleTypeSelect = props => {
  const { cellOptions } = useContext(CompGroupContext);
  const cellOptionsData = cellOptions || props.performanceCellOptions;
  const options = getSelectionCellOptions({
    specificApproach: cellOptionsData,
  });
  return <GridSelect useHandleChange={false} options={options} menuPlacement="top" {...props} enumerated />;
};

MultipleTypeSelect.propTypes = {
  performanceCellOptions: PropTypes.object,
};

export default MultipleTypeSelect;
