export const COMPANY_SUMMARY_SPREADSHEET_TYPE_OF_SECURITY_TITLE = 'Type of Security';
export const COMPANY_SUMMARY_SPREADSHEET_INVESTMENT_DATE_TITLE = 'Investment Date';
export const COMPANY_SUMMARY_SPREADSHEET_INVESTED_CAPITAL_TITLE = 'Invested Capital';
export const COMPANY_SUMMARY_SPREADSHEET_SHARES_TITLE = 'Shares';
export const COMPANY_SUMMARY_SPREADSHEET_CSE_SHARES_TITLE = 'CSE Shares';
export const COMPANY_SUMMARY_SPREADSHEET_CONCLUDED_SHARE_VALUE_TITLE = 'Concluded Share Value';
export const COMPANY_SUMMARY_SPREADSHEET_FULLY_DILUTED_OWNERSHIP_TITLE = 'Fully Diluted Ownership %';
export const COMPANY_SUMMARY_SPREADSHEET_REALIZED_VALUE_TITLE = 'Realized Value';
export const COMPANY_SUMMARY_SPREADSHEET_UNREALIZED_EQUITY_TITLE = 'Unrealized Value';
export const COMPANY_SUMMARY_SPREADSHEET_TOTAL_VALUE_TITLE = 'Total Value';
export const COMPANY_SUMMARY_SPREADSHEET_MOIC_TITLE = 'MOIC'; // Multiple of Invested Capital or X Factor

export const COMPANY_SUMMARY_SPREADSHEET_FUND_TOTAL = 'Fund Total';
export const COMPANY_SUMMARY_SPREADSHEET_FIRM_TOTAL = 'Firm Total';

export const COMPANY_SUMMARY_SPREADSHEET_COLUMNS = Object.freeze({
  TYPE_OF_SECURITY: 0,
  INVESTMENT_DATE: 1,
  INVESTED_CAPITAL: 2,
  SHARES: 3,
  CSE_SHARES: 4,
  CONCLUDED_SHARE_VALUE: 5,
  FULLY_DILUTED_OWNERSHIP: 6,
  REALIZED_VALUE: 7,
  UNREALIZED_EQUITY: 8,
  TOTAL_VALUE: 9,
  MOIC: 10,
});

export const COMPANY_SUMMARY_SPREADSHEET_HEADERS = Object.freeze([
  {
    value: COMPANY_SUMMARY_SPREADSHEET_TYPE_OF_SECURITY_TITLE,
  },
  {
    value: COMPANY_SUMMARY_SPREADSHEET_INVESTMENT_DATE_TITLE,
  },
  {
    value: COMPANY_SUMMARY_SPREADSHEET_INVESTED_CAPITAL_TITLE,
  },
  {
    value: COMPANY_SUMMARY_SPREADSHEET_SHARES_TITLE,
  },
  {
    value: COMPANY_SUMMARY_SPREADSHEET_CSE_SHARES_TITLE,
  },
  {
    value: COMPANY_SUMMARY_SPREADSHEET_CONCLUDED_SHARE_VALUE_TITLE,
  },
  {
    value: COMPANY_SUMMARY_SPREADSHEET_FULLY_DILUTED_OWNERSHIP_TITLE,
  },
  {
    value: COMPANY_SUMMARY_SPREADSHEET_REALIZED_VALUE_TITLE,
  },
  {
    value: COMPANY_SUMMARY_SPREADSHEET_UNREALIZED_EQUITY_TITLE,
  },
  {
    value: COMPANY_SUMMARY_SPREADSHEET_TOTAL_VALUE_TITLE,
  },
  {
    value: COMPANY_SUMMARY_SPREADSHEET_MOIC_TITLE,
  },
]);
