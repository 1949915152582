import { generateTotalExpression } from 'utillities/generateTotalExpression';
import { GenerateAllRowsExpression } from './types';

const generateAllRowsExpression = (params: GenerateAllRowsExpression) => {
  const { excludedRows, excludeLastRow = false, rowConfig } = params;

  const totalExcludedRows = excludedRows + 1; // +1 arrayIndex

  const allCompanies = Array.from(
    { length: rowConfig.length - (excludeLastRow ? totalExcludedRows : excludedRows) },
    () => ({})
  );

  const allCompaniesExpression = generateTotalExpression({
    items: allCompanies,
    skippedIndexes: totalExcludedRows,
  });

  return allCompaniesExpression;
};

export default generateAllRowsExpression;
