const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const getColumnIndex = columnLetter => {
  if (!columnLetter) {
    return 0;
  }
  if (columnLetter.length === 1) {
    // A, B, C, D, E...
    return alphabet.indexOf(columnLetter);
  }
  if (columnLetter.length === 2) {
    // AA, AB, AC, AD, AE...
    const prefixIndex = alphabet.indexOf(columnLetter[0]);
    const suffixIndex = alphabet.indexOf(columnLetter[1]);
    if (prefixIndex !== -1 && suffixIndex !== -1) {
      return (prefixIndex + 1) * 26 + suffixIndex;
    }
  }
  return null;
};

export const getColumnLetter = columnIndex => {
  if (columnIndex > 25) {
    const prefix = Math.trunc(columnIndex / 26) - 1;
    const suffix = columnIndex % 26;
    return `${alphabet[prefix]}${alphabet[suffix]}`;
  }
  return alphabet[columnIndex];
};

export const alphabetGenerator = (columns = [], totalColumns = 0) => {
  const newAlphabet = [];
  const generator = index => {
    const letter = getColumnLetter(index);
    newAlphabet.push(letter);
  };

  if (totalColumns) {
    for (let i = 0; i < totalColumns; i += 1) {
      generator(i);
    }
  } else if (columns && columns.length > 0) {
    columns.forEach((item, i) => {
      generator(i);
    });
  }

  return newAlphabet;
};

export const getLastColumn = totalColumns => {
  const alphabet = alphabetGenerator([], totalColumns);
  return alphabet[alphabet.length - 1];
};
