import React from 'react';
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from 'theme';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
    color: theme.palette.primary[500],
    fontSize: '1.125rem',
    marginRight: '1.5rem',
    cursor: 'default',
  },
  text: {
    color: theme.palette.primary[500],
  },
  tooltip: {
    backgroundColor: theme.palette.black,
  },
});

const IconAndText = ({ icon, text, tooltip }) => {
  const classes = useStyles();
  return (
    <Tooltip title={tooltip || ''} classes={{ tooltip: classes.tooltip }}>
      <Box className={classes.box}>
        {icon}
        <Typography className={classes.text}>{text}</Typography>
      </Box>
    </Tooltip>
  );
};

IconAndText.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

export default IconAndText;
