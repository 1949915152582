import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
    },
    typographyTitle: {
      fontSize: 32,
      textTransform: 'uppercase',
      marginBottom: 30,
      marginTop: 20,
    },
    typographyDescription: {
      fontSize: 20,
    },
    buttonOutlined: {
      marginRight: 20,
    },
    accordionTitle: {
      textTransform: 'uppercase',
      fontWeight: 600,
    },
    accordionDetails: {
      display: 'block',
    },
    squareIcon: {
      padding: '6px 6px',
      minWidth: 0,
    },
  })
);

export default useStyles;
