import { useQueryClient } from '@tanstack/react-query';
import { hoursToMilliseconds } from 'date-fns';
import { isEmpty, isNil } from 'lodash';
import {
  BACKSOLVE_EQUITY_VALUE_DEFAULT_ERROR_MESSAGE,
  BACKSOLVE_EQUITY_VALUE_QUERY_KEY,
} from 'common/constants/services/allocations';
import { AllocationService } from 'services';
import { getNumberValue, throwError } from 'utillities';
import {
  BacksolveEquityValue,
  FetchBacksolveEquityValue,
  GetBacksolveEquityValueHook,
  GetBacksolveEquityValueParams,
  UseGetBacksolveEquityValue,
} from './types';

const getBacksolveEquityValue = async (params: GetBacksolveEquityValueParams) => {
  const { backsolve } = params;

  if (isEmpty(backsolve)) return null;

  const allocationService = new AllocationService();

  try {
    const backsolveEquityValue = (await allocationService.calculateBacksolveEquityValue(
      backsolve
    )) as BacksolveEquityValue;

    if (isNil(backsolveEquityValue)) return throwError(BACKSOLVE_EQUITY_VALUE_DEFAULT_ERROR_MESSAGE);

    return getNumberValue(backsolveEquityValue?.equity_value);
  } catch (error) {
    return throwError(BACKSOLVE_EQUITY_VALUE_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetBacksolveEquityValue: UseGetBacksolveEquityValue = () => {
  const queryClient = useQueryClient();

  const fetchBacksolveEquityValue: FetchBacksolveEquityValue = async params => {
    const { backsolve } = params;

    if (isEmpty(backsolve)) {
      return null;
    }

    try {
      const data = await queryClient.fetchQuery<GetBacksolveEquityValueHook, Error>(
        [BACKSOLVE_EQUITY_VALUE_QUERY_KEY, backsolve],
        () => getBacksolveEquityValue({ backsolve }),
        {
          staleTime: hoursToMilliseconds(24),
        }
      );

      return data;
    } catch (error) {
      return null;
    }
  };

  return fetchBacksolveEquityValue;
};

export default useGetBacksolveEquityValue;
