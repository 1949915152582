import React, { FC } from 'react';
import { AppBar, Grid, IconButton, LinearProgress, Toolbar } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import slugify from 'slugify';
import { COMPANY_DETAILS_SLUGS } from 'dashboard409a/common/constants';
import { REPORT_DATE } from 'dashboard409a/common/constants/layouts';
import { Breadcrumb, DateFilter, Navigation, Title } from 'dashboard409a/components';
import { useFindSlug } from 'dashboard409a/utilities';
import useStyles from './styles';
import { IHeader } from './types';

const Header: FC<IHeader> = props => {
  const { isLoading, navigationItems, openSidebar, pageTitle } = props;

  const currentSlug = useFindSlug();

  const classes = useStyles();

  return (
    <AppBar position="sticky" elevation={1} color="inherit" className={classes.appBar}>
      <Toolbar>
        {/* Title and Breadcrumb */}
        <Grid container direction="row" wrap="nowrap" alignItems="center">
          {/* Menu hamburger */}
          <Grid item className={classes.menuContainer}>
            <IconButton component="button" onClick={openSidebar}>
              <Menu fontSize="small" className={classes.menuIcon} />
            </IconButton>
          </Grid>

          <Grid item>
            <Title title={pageTitle} />

            <Grid className={classes.breadcrumbContainer} item>
              <Breadcrumb navigationItems={navigationItems} currentSlug={currentSlug} />
            </Grid>
          </Grid>
        </Grid>

        {!COMPANY_DETAILS_SLUGS.includes(currentSlug) && (
          <Grid
            alignItems="center"
            className={classes.mainContainer}
            container
            direction="row"
            justifyContent="space-between"
            wrap="nowrap">
            {/* Filters */}
            <Grid container justifyContent="flex-end" className={classes.filter}>
              <DateFilter label={REPORT_DATE} filterId={slugify(REPORT_DATE)} />
            </Grid>
          </Grid>
        )}
      </Toolbar>

      <Grid container direction="row" wrap="nowrap" alignItems="center">
        <Grid item className={classes.navigationContainer}>
          <Grid item>
            <Navigation items={navigationItems} />
          </Grid>

          <Grid item>
            <div className={classes.progressBar}>{isLoading && <LinearProgress color="primary" />}</div>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Header;
