import { clientWithAuth } from './base';
import { captableEndpoints } from './endpoints';
import {
  IGetCaptableByCompanyRequest,
  IGetCaptableByCompanyResponse,
  IGetCaptableResponse,
  IGetWaterfallBreakpointResponse,
} from './types';

export const getWaterfallBreakpoint = (captableId: number): Promise<IGetWaterfallBreakpointResponse> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .get(captableEndpoints.getWaterfallBreakpoint(captableId))
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });

export const getCaptablebyCompany = (
  companyId: number,
  request: IGetCaptableByCompanyRequest
): Promise<IGetCaptableByCompanyResponse[]> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .get(captableEndpoints.getCaptablebyCompany(companyId), { params: request })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });

export const findCaptable = (captableId: number): Promise<IGetCaptableResponse> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .get(captableEndpoints.findCaptable(captableId))
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });
