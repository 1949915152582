import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { dateMinusOrPlusXPeriods, ISO_DATE_FORMAT } from 'utillities';
import { afterCellChanged, createColumns, customParser, reverseParser, rowConfig } from './config';
import { conditions } from './PerformanceMetricsAsOfDate/config/conditions';
import { validations } from './PerformanceMetricsAsOfDate/config/validations';

const createMultiplePremiumDiscount = ({
  companyName,
  approach,
  measurementDate,
  financials,
  performanceMetricsSheet,
  performanceMetricsAsOfDateSheet,
  multiplePremiumDiscountFromDB,
  comparisons,
  multiplePremiumDiscountIdsToDelete,
  gpcSheet,
}) => {
  // Per the business logic, the max allowed date should be
  // the day before the valuation date or measurement date.
  const maxDate = dateMinusOrPlusXPeriods({
    date: approach.valuation_date ?? measurementDate?.date,
    period: 'days',
    amount: -1,
    format: ISO_DATE_FORMAT,
  });
  const columns = createColumns({
    multiplePremiumDiscountFromDB,
    measurementDate,
    financials,
    valuationDate: approach.valuation_date ?? measurementDate?.date,
  });
  const config = rowConfig({
    companyName,
    maxDate,
  });
  const name = getApproachTableName({ approach, tableSuffix: 'multiple_premium_discount' });

  const spreadsheet = new SpreadsheetConfig({
    columns,
    format: undefined,
    name,
    showTitlesColumn: false,
    parser: customParser,
    reverseParser,
    rowConfig: config,
    reverseParseAfterLoad: true,
    showToolbar: true,
    tableData: {
      approach,
      comparisons,
      performanceMetricsSheet,
      performanceMetricsAsOfDateSheet,
      companyName,
      multiplePremiumDiscountIdsToDelete,
      gpcSheet,
    },
    afterCellChanged,
    totalParser: undefined,
    conditions,
    customValidations: validations,
  });

  return { spreadsheet };
};

export default createMultiplePremiumDiscount;
