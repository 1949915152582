import React, { FC, useEffect, useState } from 'react';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import useValuationMethodsDetailTableData from '../hooks/useValuationMethodsDetailTableData';
import { ValuationMethodsDetailTableProps } from '../types';

const ValuationMethodsDetailTable: FC<ValuationMethodsDetailTableProps> = props => {
  const { data, isLoading, format, formatDispatch } = props;

  const { spreadsheet: valuationMethodsDetailSheet } = useValuationMethodsDetailTableData({ data, format });

  const [spreadsheets, setSpreadsheets] = useState([valuationMethodsDetailSheet]);

  useEffect(() => {
    if (valuationMethodsDetailSheet?.yLength > 0) {
      setSpreadsheets([valuationMethodsDetailSheet]);
    }
  }, [valuationMethodsDetailSheet]);

  const { onChange, workbook } = useWorkbook(spreadsheets);

  const valuationMethodsDetailSheetProps = {
    ...valuationMethodsDetailSheet,
    sheet: valuationMethodsDetailSheet,
    workbook,
    onChange,
    page: 'Valuation Methods Detail',
    format,
    formatDispatch,
  };

  return !isLoading && valuationMethodsDetailSheet?.data ? (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ScalarSpreadsheet {...valuationMethodsDetailSheetProps} />
  ) : (
    <GridSkeleton />
  );
};

export default ValuationMethodsDetailTable;
