import React, { FC, useEffect, useState } from 'react';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import useComparisonCompaniesTableData from '../../hooks/useComparisonCompaniesTableData';
import { IComparisonCompaniesTableProps } from '../../types';

const ComparisonCompaniesTable: FC<IComparisonCompaniesTableProps> = props => {
  const { data, isLoading, format, formatDispatch, currentCompanyName } = props;

  const { spreadsheet: comparisonCompaniesSheet } = useComparisonCompaniesTableData({
    data,
    format,
    currentCompanyName,
  });

  const [spreadsheets, setSpreadsheets] = useState([comparisonCompaniesSheet]);

  useEffect(() => {
    if (comparisonCompaniesSheet?.yLength > 0) {
      setSpreadsheets([comparisonCompaniesSheet]);
    }
  }, [comparisonCompaniesSheet]);

  const { onChange, workbook } = useWorkbook(spreadsheets);

  const comparisonCompaniesSheetProps = {
    ...comparisonCompaniesSheet,
    sheet: comparisonCompaniesSheet,
    workbook,
    onChange,
    page: 'Comparison Companies',
    format,
    formatDispatch,
  };

  return !isLoading && comparisonCompaniesSheet?.data ? (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ScalarSpreadsheet {...comparisonCompaniesSheetProps} />
  ) : (
    <GridSkeleton />
  );
};

export default ComparisonCompaniesTable;
