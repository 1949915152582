import React from 'react';
import { ClickAwayListener, Grow, makeStyles, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  menuList: {
    '& .MuiListItem-root': {
      textAlign: 'right',
      justifyContent: 'end',
      width: 'fit-content',
    },
    '& .MuiMenuItem-root': {
      marginLeft: 'auto',
      backgroundColor: '#037DE8',
      color: '#FFFFFF',
      margin: '0.8rem 0',
      borderRadius: '2.688rem',
      textTransform: 'uppercase',
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: '#043A6E',
    },
  },
}));

const FloatingButtonOptions = ({ optionsMenu, openOptionsButtonRef, isOpenSubMenu, setIsOpenSubMenu, getIcon }) => {
  const classes = useStyles();

  const handleClose = () => {
    setIsOpenSubMenu(false);
  };

  return (
    <>
      <Popper
        open={isOpenSubMenu}
        anchorEl={openOptionsButtonRef.current}
        role={undefined}
        transition
        disablePortal
        placement="top-end">
        {({ TransitionProps }) => (
          <>
            <Grow {...TransitionProps}>
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" className={classes.menuList}>
                    {optionsMenu.map((option, index) => (
                      <MenuItem
                        key={option.key}
                        id={`floating-button-options-btn-${index}`}
                        className={classes.menuItem}
                        onClick={() => option.onClick(option)}>
                        {getIcon(option)}
                        <span className={classes.label}>{option.label}</span>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          </>
        )}
      </Popper>
    </>
  );
};

FloatingButtonOptions.propTypes = {
  optionsMenu: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.instanceOf(Object),
      onClick: PropTypes.func.isRequired,
    })
  ),
  openOptionsButtonRef: PropTypes.shape({ current: PropTypes.instanceOf(HTMLButtonElement) }),
  isOpenSubMenu: PropTypes.bool,
  setIsOpenSubMenu: PropTypes.func,
  getIcon: PropTypes.func,
};

export default FloatingButtonOptions;
