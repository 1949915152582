/* eslint-disable import/no-unresolved,react/prop-types */
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  margin: {
    marginTop: theme.spacing(3),
  },
  marginBetweenActions: {
    marginTop: theme.spacing(5),
  },
  iconContent: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    position: 'absolute',
    cursor: 'pointer',
    padding: 0,
  },
  center: {
    top: 16,
    left: 80,
  },
  right: {
    top: 8,
    right: 0,
  },
  icon: {
    width: '16px !important',
    height: '16px !important',
    fill: `${theme.palette.gray[300]} !important`,
  },
}));

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: '#222',
    backgroundColor: '#fff',
    maxWidth: 220,
    padding: theme.spacing(2),
    fontSize: theme.typography.pxToRem(12),
    boxShadow: '0 3px 20px 1px rgb(0, 0, 0, 0.3)',
  },
  arrow: {
    color: '#fff',
  },
}))(Tooltip);

// eslint-disable-next-line react/display-name
const withAction = WrappedComponent =>
  function (props) {
    const classes = useStyles();
    const { action, extraActions, key } = props.cell;
    const [isIconVisible, setIsIconVisible] = useState();
    const [divRect, setDivRect] = useState({});
    const divRef = useRef();

    const Icon = useMemo(() => {
      if (action) {
        return () =>
          React.cloneElement(action.icon, {
            classes: {
              root: classes.icon,
            },
          });
      }
    }, [action, classes.icon]);

    useEffect(() => {
      if (divRef.current) {
        setDivRect(divRef.current.getBoundingClientRect());
      }

      return () => setDivRect({});
    }, [isIconVisible]);

    const tooltipPosition = useMemo(() => {
      if (divRect) {
        if (divRect.right + divRect.width > window.innerWidth) {
          return 'top-end';
        }
        return 'top';
      }
    }, [divRect]);

    const tooltipContent = useMemo(() => {
      if (action) {
        return (
          <>
            <Typography align="center">{action.tooltip.title}</Typography>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className={classes.margin}
              onClick={action.callback}>
              {action.tooltip.buttonText || 'Calculate'}
            </Button>

            {!!extraActions
              && extraActions.map(extraAction => (
                <Fragment key={key}>
                  <Typography className={classes.marginBetweenActions} align="center">
                    {extraAction.title}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.margin}
                    onClick={extraAction.callback}>
                    {extraAction.buttonText}
                  </Button>
                </Fragment>
              ))}
          </>
        );
      }
    }, [action, classes.margin, classes.marginBetweenActions, extraActions, key]);

    if (!action) {
      return <WrappedComponent {...props} />;
    }

    return (
      <div
        className={classes.root}
        onMouseEnter={() => setIsIconVisible(true)}
        onMouseLeave={() => setIsIconVisible(false)}
        ref={divRef}>
        <WrappedComponent {...props} />
        <CustomTooltip
          arrow
          interactive
          enterDelay={action.tooltip.enterDelay}
          enterNextDelay={action.tooltip.enterNextDelay}
          placement={tooltipPosition}
          title={tooltipContent}>
          <div className={`${classes.iconContainer} ${classes[action.position]}`}>
            <div className={classes.iconContent}>{isIconVisible && <Icon />}</div>
          </div>
        </CustomTooltip>
      </div>
    );
  };

withAction.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
};

withAction.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default withAction;
