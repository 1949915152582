import { AllocationScenarioDetailSecurityModel, SecurityModel } from 'api';
import { CONVERTIBLE_NOTES, UNISSUED_OPTIONS } from 'common/constants/securityTypes';
import { getNumberValue } from 'utillities/getNumberValue';

const EXCLUDED_SECURITIES = Object.freeze([UNISSUED_OPTIONS, CONVERTIBLE_NOTES]);

const filterSecurities = <T extends AllocationScenarioDetailSecurityModel | SecurityModel>(securities: T[]): T[] =>
  securities.filter(security => !EXCLUDED_SECURITIES.includes(getNumberValue(security?.security_type)));

export default filterSecurities;
