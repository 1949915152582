import { MeasurementDateRollover, MeasurementDateRolloverReturn } from 'api';

export enum STATUS {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
  CONFLICT = 'conflict',
}

export type RolloverRequestInfo = {
  request: MeasurementDateRollover | null | undefined;
  errorMsg?: string;
  status: STATUS;
  id?: number | null;
  response?: MeasurementDateRolloverReturn;
};
export type RolloverStatusList = {
  funds: RolloverRequestInfo[];
  allocations: RolloverRequestInfo[];
  capTablesOnly: RolloverRequestInfo[];
};
export type SubItem = {
  id: number;
  allocationId?: number;
  name: string;
};
export enum ROLLOVER_TYPE_KEYS {
  FUNDS = 'funds',
  ALLOCATIONS = 'allocations',
  CAP_TABLES_ONLY = 'capTablesOnly',
}
