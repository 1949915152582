import React from 'react';
import { SelectValueViewer } from 'components';
import {
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
  ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
  ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import { CapTableVersionSelector } from 'pages/ValuationsAllocation/components';
import { getNumberValue } from 'utillities';
import { HandleCapTableSelectionParams } from './types';

const handleCapTableSelection = (params: HandleCapTableSelectionParams) => {
  const { cell, scenarioType } = params;

  cell.dataEditor = props => <CapTableVersionSelector {...props} />;
  cell.dbType = 'number';
  cell.forceComponent = true;
  cell.gridType = 'number';
  cell.isRequired = true;
  cell.valueViewer = props => <SelectValueViewer {...props} />;

  if (
    [
      ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
      ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
      ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES,
    ].includes(getNumberValue(scenarioType))
  ) {
    cell.isRequired = false;
    cell.readOnly = true;
  }
};

export default handleCapTableSelection;
