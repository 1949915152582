import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import { SectionHeadingProps } from './types';

const SectionHeading: FC<SectionHeadingProps> = props => {
  const { title } = props;

  const classes = useStyles();

  return (
    <Typography className={classes.heading} component="h2" variant="h2">
      {title}
    </Typography>
  );
};

export default SectionHeading;
