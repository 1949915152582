const checkCompExistsInTable = ({ existingComps, publicCompsData }) => {
  const currentCompsIds = existingComps?.map(comp => comp.cap_iq_id) || [];

  return publicCompsData.results.map(company => {
    const companyAlreadyInTable = currentCompsIds.includes(company.cap_iq_id.value);
    return {
      gpc: companyAlreadyInTable
        ? {
          company_name: {
            value: `"${company.ticker_symbol.value}" already present in the table`,
          },
          ticker_symbol: {
            value: company.ticker_symbol.value,
          },
        }
        : company,
      valid_gpc_results: !companyAlreadyInTable,
    };
  });
};

export default checkCompExistsInTable;
