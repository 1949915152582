/* eslint-disable no-param-reassign */
import moment from 'moment';
import { ISO_DATE_FORMAT, MOMENT_DEFAULT_DATE_FORMAT } from 'utillities';
import { ensureNoMoreThanTwoDecimalPlaces } from '../../../utils/utilities';
import {
  MULTIPLE_PREMIUM_DISCOUNT_CUSTOM_DATE,
  MULTIPLE_PREMIUM_DISCOUNT_MEASUREMENT_DATE,
} from '../../common/constants';

const reverseParser = ({ cells, tableData }) => {
  const { approach } = tableData;

  const formatDate = date => {
    if (!date) return null;

    const dateParsed = moment(date, [MOMENT_DEFAULT_DATE_FORMAT, ISO_DATE_FORMAT]);

    return dateParsed.isValid() ? dateParsed.format(ISO_DATE_FORMAT) : null;
  };

  const { valuations_approach_gpc: approachGPC } = approach;
  const { multiple_premium_discount: mpd, use_multiple_premium_discount: useMultiplePremiumDiscount } = approachGPC;
  const customDateObject = mpd?.find(column => column.name === MULTIPLE_PREMIUM_DISCOUNT_CUSTOM_DATE);
  const measurementDateObject = mpd?.find(column => column.name === MULTIPLE_PREMIUM_DISCOUNT_MEASUREMENT_DATE);

  if (cells) {
    const customDateColumn = {
      id: customDateObject?.id,
      name: MULTIPLE_PREMIUM_DISCOUNT_CUSTOM_DATE,
      is_as_of_date: true,
      as_of_date: formatDate(cells.B2.value),
      adjustment_metric: cells.B3.value,
      gpc_median: ensureNoMoreThanTwoDecimalPlaces(cells.B4.value) || 0,
      computed_premium_discount: ensureNoMoreThanTwoDecimalPlaces(cells.B6.value) || 0,
      other_premium_discount: 0,
      total_premium_discount: cells.B8.value,
    };
    const measurementDateColumn = {
      id: measurementDateObject?.id,
      is_as_of_date: false,
      name: MULTIPLE_PREMIUM_DISCOUNT_MEASUREMENT_DATE,
      as_of_date: formatDate(cells.C2.value),
      adjustment_metric: cells.C3.value,
      gpc_median: ensureNoMoreThanTwoDecimalPlaces(cells.C4.value) || 0,
      computed_premium_discount: ensureNoMoreThanTwoDecimalPlaces(cells.C6.value) || 0,
      other_premium_discount: ensureNoMoreThanTwoDecimalPlaces(cells.C7.value) || 0,
      total_premium_discount: ensureNoMoreThanTwoDecimalPlaces(cells.C8.value) || 0,
    };

    tableData.approach.valuations_approach_gpc = {
      ...approach.valuations_approach_gpc,
      multiple_premium_discount: useMultiplePremiumDiscount ? [customDateColumn, measurementDateColumn] : [],
      deleted_multiple_premium_discount_objects: [
        ...(useMultiplePremiumDiscount ? [] : tableData.multiplePremiumDiscountIdsToDelete || []),
      ],
    };
  }
};

export default reverseParser;
