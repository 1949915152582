/* eslint-disable import/no-unresolved */
import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CustomSelect } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import withTooltip from 'components/WithTooltip';
import { getStringValue } from 'utillities';

const GridSelect = ({
  cell,
  options,
  enumerated,
  objectOptions,
  useHandleChange,
  menuPlacement,
  menuPosition,
  handleOptionsNumberFormat,
  ...restProps
}) => {
  const { format, onCellsChanged } = useContext(FeaturedSpreadsheetContext);
  const currency = format?.currency;

  const objectList = useMemo(() => {
    if (handleOptionsNumberFormat) {
      return handleOptionsNumberFormat({
        options,
        format: cell.format,
        currency,
        value: cell.value,
      });
    }
    if (!objectOptions) {
      return options;
    }
    return options.map(option => option[objectOptions.displayKey]);
  }, [options, objectOptions, cell, handleOptionsNumberFormat, currency]);

  const objectMap = useMemo(() => {
    if (objectOptions) {
      return options.reduce((sofar, next) => {
        // eslint-disable-next-line no-param-reassign
        sofar[next[objectOptions.valueKey]] = next[objectOptions.displayKey];
        return sofar;
      }, {});
    }
    return undefined;
  }, [options, objectOptions]);

  const { onCommit, onRevert, usePortal = false } = restProps;

  const getValueIndex = value => options.findIndex(option => option === value);

  let cellValue = enumerated ? getValueIndex(cell.value) : cell.value;
  if (objectMap && cellValue) {
    cellValue = objectMap[cellValue];
  }

  const handleChange = useCallback(
    value => {
      if (useHandleChange) {
        const newValue = objectOptions ? options[value][objectOptions.valueKey] : getStringValue(value);
        onCellsChanged([{ cell, value: newValue }]);
      }
    },
    [cell, objectOptions, onCellsChanged, options, useHandleChange]
  );

  return (
    <CustomSelect
      id={`${cell.alias}-${cell.key}`}
      className={cell.alias}
      value={cellValue}
      options={objectList}
      onChange={handleChange}
      onCommit={onCommit}
      onRevert={onRevert}
      onEmptyValue="Select Option"
      disabled={cell.readOnly}
      enumerated={enumerated}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      usePortal={usePortal}
    />
  );
};

GridSelect.defaultProps = {
  useHandleChange: true,
};

GridSelect.propTypes = {
  cell: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  enumerated: PropTypes.bool,
  useHandleChange: PropTypes.bool,
  menuPlacement: PropTypes.string,
  menuPosition: PropTypes.string,
  objectOptions: PropTypes.shape({
    displayKey: PropTypes.string,
    valueKey: PropTypes.string,
  }),
  handleOptionsNumberFormat: PropTypes.func,
};

export default withTooltip(GridSelect);
