import React, { FC } from 'react';
import { List } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { TopMenuItem } from 'dashboard409a/components';
import useStyles from './styles';
import { ITopMenu } from './types';

// TODO: Test perfect scroll on page
const TopMenu: FC<ITopMenu> = props => {
  const { items } = props;
  const classes = useStyles();

  return (
    <PerfectScrollbar options={{ suppressScrollX: true, wheelPropagation: false, wheelSpeed: 3 }}>
      <div className={classes.top}>
        {items.length > 0 && (
          <List component="ul" className={classes.listContainer}>
            {items.map(menuItem => (
              <TopMenuItem item={menuItem} key={menuItem.slug} />
            ))}
          </List>
        )}
      </div>
    </PerfectScrollbar>
  );
};

export default TopMenu;
