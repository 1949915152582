import React, { useState } from 'react';
import { Box, Chip, makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { capitalize, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import theme from 'theme';

const useStyles = makeStyles({
  marginTop: {
    marginTop: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
});

const OptionAutocomplete = ({ formValues, setFormValues, isMaxOptions, questionOptions: optionsFromDB }) => {
  const classes = useStyles();
  const { options } = formValues;
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleAddValueToOptions = optionParam => {
    let optionName = '';
    let optionId = '';

    if (typeof optionParam === 'object') {
      optionName = optionParam.name;
      optionId = optionParam.id;
    } else if (inputValue.trim() !== '') {
      optionName = inputValue;
    }

    const optionExists = formValues.options.some(option => option.name === optionName);

    if (!optionExists) {
      setFormValues(prevValues => ({
        ...prevValues,
        options: [...prevValues.options, { name: optionName, id: optionId }],
      }));
      setInputValue('');
    }
  };

  const getOptionData = () => {
    const option = optionsFromDB.find(opt => opt.name === inputValue);
    return option;
  };

  const optionAlreadyExists = optionName => formValues.options.some(option => option.name === optionName);

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      // we capitalize the option value since all options in the database start with an uppercase letter
      // this way we avoid add "yes" and "Yes" as two different options
      const isExistingOption = optionsFromDB.some(option => option.name === capitalize(inputValue));
      if (!isExistingOption) handleAddValueToOptions();

      const option = getOptionData();
      if (option && !optionAlreadyExists(option.name)) handleAddValueToOptions(option);
    }
  };

  const handleDelete = indexToDelete => {
    setFormValues(prevValues => ({
      ...prevValues,
      options: prevValues.options.filter((option, index) => index !== indexToDelete),
    }));
  };

  return (
    <>
      <Typography variant="caption" color="textSecondary" className={classes.marginTop} gutterBottom>
        Write an option and <strong>press Enter</strong> to add it:
      </Typography>
      <Autocomplete
        className={classes.marginTop}
        disablePortal
        id="options-autocomplete"
        options={optionsFromDB}
        getOptionLabel={option => option?.name || ''}
        inputValue={inputValue}
        freeSolo // Allow the user to add new options
        disabled={isMaxOptions}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        renderInput={params => (
          <TextField
            {...params}
            label="Write a new option"
            variant="outlined"
            disabled={isMaxOptions}
            helperText={isMaxOptions ? 'Max options reached' : ''}
          />
        )}
      />
      <Box mt={2} />
      {!isEmpty(options) && (
        <Typography variant="caption" color="textSecondary" className={classes.marginTop} gutterBottom>
          Selected options:
        </Typography>
      )}
      <Box mt={1} />
      {options.map((option, index) => (
        <Chip
          key={option?.id || option.name}
          label={option.name}
          className={classes.marginRight}
          onDelete={() => handleDelete(index)}
        />
      ))}
    </>
  );
};

OptionAutocomplete.propTypes = {
  formValues: PropTypes.shape({
    question_type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })).isRequired,
  }).isRequired,
  setFormValues: PropTypes.func.isRequired,
  isMaxOptions: PropTypes.bool.isRequired,
  questionOptions: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })).isRequired,
};

export default OptionAutocomplete;
