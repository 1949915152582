/**
 * @file services/funds.js
 * @since v1.0
 * @author Carlos Lenon <code@carloslenon.com>
 */

// #region common
import BaseServiceRetryWrapper from './baseRetry';
import { FUNDS, MEASUREMENT_DATES } from '../common/endpoints';

// #endregion

class FundsService extends BaseServiceRetryWrapper {
  /**
   * @function
   * @name getFundById
   * @description Get the fund by its Id
   * @param {integer}
   * @return {object}
   */
  getFundById = fundId => this.get(FUNDS.fundById(fundId));

  /**
   * @function
   * @name getMeasurementDateByFundId
   * @description Get the list of Measurement Dates that belong to a Fund
   * @param {integer} fundId
   * @return {array} List of Measurement Dates
   */
  getMeasurementDateByFundId = fundId => this.get(MEASUREMENT_DATES.getMeasurementDateByFundId(fundId));

  /**
   * @function
   * @name getFundSummaryByMD
   * @description Get summary data by Measurement Date Id that belongs to a fund
   * @param {integer} mdId
   * @return {array}
   */
  getFundSummaryByMD = (fundId, mdId) => this.get(FUNDS.getFundSummaryByMD(fundId, mdId));

  /**
   * @function
   * @name getFundInvestmentByMD
   * @description Get investment data by Measurement Date Id that belongs to a fund
   * @param {integer} mdId
   * @return {array}
   */
  getFundInvestmentByMD = (fundId, mdId) => this.get(FUNDS.getFundInvestmentByMD(fundId, mdId));

  /**
   * @function
   * @name newFund
   * @description create a new Fund.
   * @param {OBJECT} data The fund structure to send
   */
  newFund = data => this.post(FUNDS.newFund, data);

  /**
   * @function
   * @name updateFundById
   * @description Update an existent Fund.
   * @param {OBJECT} data The fund structure to send
   */
  updateFundById = (fundId, data) => this.put(FUNDS.fundById(fundId), data);

  /**
   * @function
   * @name createCompanyForMeasurementDate
   * @description Create new company and add it to a fund's measurement Date.
   * @param {OBJECT} data The company structure to send
   */
  createCompanyForMeasurementDate = (mpId, data) => this.post(FUNDS.createCompanyForMeasurementDate(mpId), data);

  /**
   * @function
   * @name addCompanyToMeasurementDate
   * @description Add existing company to fund measurement Date.
   * @param {OBJECT} data The data structure to send
   */
  addCompanyToMeasurementDate = data => this.post(FUNDS.addCompanyToMeasurementDate, data);

  getFundAllocationsByMd = (fundId, mdId) => this.get(FUNDS.getFundAllocationsByMD(fundId, mdId));

  getCompaniesWithOwnershipByFund = fundId => this.get(FUNDS.getCompaniesByFundId(fundId));

  deleteFund = fundId => this.delete(FUNDS.fundById(fundId));
}

export default FundsService;
