const userHasAccessType = ({ permissions, userId, accessType, objectType, objectId }) => {
  const matchingPermission = permissions?.find(
    perm =>
      perm.user_id === userId
      && perm.feature_object.object_id === objectId
      && perm.feature_object.object_type === objectType
      && perm.access_type === accessType
  );

  return !!matchingPermission;
};

export default userHasAccessType;
