/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useMemo } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { isEmpty } from 'validate.js';
import { FormDialog } from 'components';
import { useUpdateAllocationInfo } from 'services/hooks/allocation';
import { useFormValidation } from 'services/hooks/useFormValidation';

const useStyles = makeStyles({
  margin: {
    marginBottom: 20,
  },
});

const constraints = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 3, message: 'must be at least 3 characters' },
  },
};

const AllocationDialog = ({ open, onClose, versions, getVersions, allocationInfo, setAllocationInfo }) => {
  const classes = useStyles();
  const { formValidated, validateForm } = useFormValidation(constraints);
  const { values } = formValidated;

  const [updatedAllocation, updateAllocation] = useUpdateAllocationInfo();

  useEffect(() => {
    if (!isEmpty(allocationInfo)) {
      const formValues = {
        name: allocationInfo.name,
      };

      validateForm(formValues);
    }
  }, [allocationInfo, validateForm]);

  const editMode = useMemo(() => allocationInfo && !!allocationInfo.id, [allocationInfo]);

  const handleChange = event => {
    event.persist();

    validateForm({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSave = useCallback(() => {
    updateAllocation(allocationInfo.id, {
      ...allocationInfo,
      ...values,
    }).then(updatedAllocationInfo => {
      setAllocationInfo(updatedAllocationInfo);
    });
  }, [setAllocationInfo, allocationInfo, updateAllocation, values]);

  useEffect(() => {
    if (!isEmpty(updatedAllocation)) {
      const currentVersion = versions.find(version => version.id === updatedAllocation.id);
      setAllocationInfo(updatedAllocation);
      getVersions({
        ...currentVersion,
        name: updatedAllocation.name,
        slug: updatedAllocation.slug,
      });
    }
  }, [updatedAllocation, setAllocationInfo, versions, getVersions]);

  const onBlur = () => {
    if (!values.name?.length) {
      validateForm({
        ...values,
        name: allocationInfo.name,
      });
    }
  };

  return (
    <FormDialog
      open={open}
      title={`Version ${allocationInfo?.version}`}
      editMode={editMode}
      isValid={formValidated.isValid}
      onSave={handleSave}
      onClose={onClose}>
      <Grid container>
        <TextField
          fullWidth
          id="name"
          name="name"
          type="text"
          label="Nickname"
          onChange={handleChange}
          className={classes.margin}
          value={values.name || ''}
          onBlur={onBlur}
        />
      </Grid>
    </FormDialog>
  );
};

AllocationDialog.defaultProps = {
  open: false,
};

AllocationDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  versions: PropTypes.array,
  getVersions: PropTypes.func,
  allocationInfo: PropTypes.object,
  setAllocationInfo: PropTypes.func,
};

export default AllocationDialog;
