export const setUserInfo = (state, action) => ({
  ...state,
  user: action.user
});

export const setActivationEmail = (state, action) => ({
  ...state,
  ...action,
});

export const setIsRestrictedUser = (state, action) => ({
  ...state,
  ...action,
});

export default setUserInfo;
