import { isEmpty } from 'lodash';
import { ValuationsApproach } from 'common/types/valuation';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import createGpcData from './gpc/createGpcData';
import GPC from './GuidelinePublicCompanies';
import createMultiplePremiumDiscount from './MultiplePremiumDiscount/createMultiplePremiumDiscount';
import createPerformanceMetricsAsOfDate from './MultiplePremiumDiscount/PerformanceMetricsAsOfDate/createPerformanceMetricsAsOfDate';
import { createPerformanceMetrics } from './PerformanceMetrics';
import { CreateGpcConfigParams } from './types';
import { getAllCustomDatePerformanceMetrics } from './utils/utilities';

const createGpcConfig = (params: CreateGpcConfigParams) => {
  const {
    approach,
    companyName,
    compGroups,
    customClasses,
    financials,
    financialsPeriods,
    fiscalYearEnd,
    gpcAttributes,
    isDisabled,
    measurementDate,
  } = params;
  const name = getApproachTableName({ approach: approach as ValuationsApproach });
  const {
    gpc_comparison: gpcComparison,
    multiple_premium_discount: multiplePremiumDiscountFromDB,
    company_as_of_date_performance_metrics: companyAsOfDatePerformanceMetrics,
  } = approach?.valuations_approach_gpc ?? {};
  const multiplePremiumDiscountIdsToDelete = multiplePremiumDiscountFromDB?.map(item => item.id);
  const asOfDate
    = multiplePremiumDiscountFromDB && !isEmpty(multiplePremiumDiscountFromDB)
      ? multiplePremiumDiscountFromDB[0].as_of_date
      : null;
  const allCustomDatePerformanceMetrics = getAllCustomDatePerformanceMetrics(gpcComparison, asOfDate);
  const comparisons = !isEmpty(allCustomDatePerformanceMetrics) ? allCustomDatePerformanceMetrics : gpcComparison;

  const gpcSheet = createGpcData({
    approach,
    companyName,
    compGroups,
    financials,
    financialsPeriods,
    gpcAttributes,
    name,
    isDisabled,
  });

  const { spreadsheet: performanceMetricsSheet } = createPerformanceMetrics({
    approach,
    companyName,
    customClasses,
    financials,
    isDisabled,
  });

  const { spreadsheet: performanceMetricsAsOfDateSheet } = createPerformanceMetricsAsOfDate({
    companyName,
    approach,
    measurementDate,
    financials,
    financialsPeriods,
    fiscalYearEnd,
    comparisons,
    multiplePremiumDiscountFromDB,
    companyAsOfDatePerformanceMetrics,
    performanceMetricsSheetColumns: performanceMetricsSheet?.columns,
  });

  const { spreadsheet: multiplePremiumDiscountSheet } = createMultiplePremiumDiscount({
    companyName,
    approach,
    measurementDate,
    financials,
    performanceMetricsSheet,
    performanceMetricsAsOfDateSheet,
    multiplePremiumDiscountFromDB,
    comparisons,
    multiplePremiumDiscountIdsToDelete,
    gpcSheet,
  });

  return {
    approach,
    name,
    component: GPC,
    enterpriseValueReference: name,
    spreadsheets: {
      gpc: gpcSheet,
      performanceMetrics: performanceMetricsSheet,
      performanceMetricsAsOfDate: performanceMetricsAsOfDateSheet,
      multiplePremiumDiscount: multiplePremiumDiscountSheet,
    },
    value: approach?.enterprise_value,
  };
};

export default createGpcConfig;
