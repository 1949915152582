import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    formContainer: {
      width: theme.spacing(40),

      '&.no-measurement-date': {
        pointerEvents: 'none',
      },
    },
    skeleton: {
      height: theme.spacing(5.25),
    },
    select: {
      borderRadius: 0,
    },
    listItem: {
      padding: 0,
    },
    listItemText: {
      color: theme.palette.black,
      margin: 0,

      '&.date': {
        marginLeft: theme.spacing(2),
        textAlign: 'right',
      },
    },
  })
);

export default useStyles;
