// eslint-disable-next-line no-unused-vars

// eslint-disable-next-line no-unused-vars

const calcPV = ({
  rate, nper, pmt, fv
}) => {
  let presentValue;
  let x;
  let y;

  if (rate === 0) {
    presentValue = -(fv + (pmt * nper));
  } else {
    x = (1 + rate) ** -nper;
    y = (1 + rate) ** nper;
    presentValue = -(x * (fv * rate - pmt + y * pmt)) / rate;
  }

  return Math.ceil(presentValue);
};

const PV = (discount, nper, pmt, fv) => {
  // divide the discount by 100 to make into a percentage value
  const rate = parseFloat(discount / 100);
  return calcPV({
    rate, nper, pmt, fv
  });
};

export default PV;
