import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { FutureShareValue, PresentShareValue } from 'api';
import { DECIMAL_PLACES } from 'common/config/app';
import { ADJUSTED_EBITDA_TITLE } from 'common/constants/financials';
import { VALUATIONS_PAGE_KEY, VALUATIONS_PAGE_VALUE } from 'common/constants/notes';
import { Cell, ScalarSpreadsheetConfigs } from 'common/types/scalarSpreadsheet';
import { Widgets } from 'components';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import { getApproachFromColumn } from 'pages/ValuationsAllocation/approaches/FutureExit/utils/getApproachFromColumn';
import { ALLOCATION_SCENARIO_METHOD_OPM } from 'pages/ValuationsAllocation/common/constants/allocation';
import {
  ALLOCATION_METHOD_OPTIONS_VALUES,
  AllocationMethodsMapValues,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_CUSTOM_KEY,
  METRIC_OPTION_LABELS,
  SCENARIO_VALUES_DISPATCH_CELLS,
  SPECIFIED_MULTIPLE,
} from 'pages/ValuationsAllocation/common/constants/futureExit';
import {
  FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD,
  FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS,
  FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY,
  FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE,
} from 'pages/ValuationsAllocation/common/constants/futureExit/sheetAliases';
import { Note } from 'pages/ValuationsAllocation/types';
import { getSelectionCellOptions, parseCellValue, SpecificApproach } from 'pages/ValuationsAllocation/util';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { AllocationService } from 'services';
import { useNotes } from 'services/hooks/notes';
import { dbShortDate, getNumberValue, getObjectValue, getStringValue } from 'utillities';
import { AllocationMethodTable } from './AllocationMethodTable';
import { FUTURE_EXIT } from './AllocationMethodTable/utils/constants';
import { FutureEquity } from './FutureEquity';
import { ModifiedPresentEquity } from './ModifiedPresentEquity';
import { FutureExitParams, SecurityWithValue, ValidateParams } from './types';
import FutureExitContext from './utils/FutureExitContext';
import useValuesPerShareTableData from './ValuesPerShareBase/useValuesPerShareTableData';
import { ValuesPerShareTable } from './ValuesPerShareTable';

const FutureExit = (params: FutureExitParams) => {
  const { spreadsheets, onChange: onWorkbookChange, approaches, approach, financials, isDisabled } = params;
  const { valuations_approach_future_exit: valuationsApproachFutureExit } = getObjectValue(approach);
  const {
    allocation_method: allocationMethod,
    cap_table: capTable,
    exit_date: exitDate,
    future_share_values: futureShareValues,
    present_share_values: presentShareValues,
  } = getObjectValue(valuationsApproachFutureExit);

  const approachPanelId = approach.panelId;
  const getApproach = useCallback(
    () => getApproachFromColumn(getObjectValue(valuationsApproachFutureExit), approaches),
    [approaches, valuationsApproachFutureExit]
  );

  const getValuesPerShare = (dataShareValues?: PresentShareValue[] | FutureShareValue[]): SecurityWithValue[] => {
    if (!dataShareValues) return [];
    return dataShareValues.map(item => ({
      security: {
        id: item.security,
        name: getStringValue(item.security_name),
      },
      value: getNumberValue(item.value),
    }));
  };

  const [currentAllocationMethodValue, setCurrentAllocationMethodValue] = useState<any>(null);
  const initialPresentValuesPerShare = getValuesPerShare(presentShareValues);
  const [presentValuesPerShare, setPresentValuesPerShare] = useState<SecurityWithValue[]>(initialPresentValuesPerShare);

  const initialFutureValuesPerShare = getValuesPerShare(futureShareValues);
  const [futureValuesPerShare, setFutureValuesPerShare] = useState<SecurityWithValue[]>(initialFutureValuesPerShare);

  const [selectedApproach, setSelectedApproach] = useState(getApproach());
  const { notes, setNotes, notesHasChanged, onAddNote, onUpdateNotes, onDeleteNote } = useNotes();
  const { presentValueSheet, futureEquitySheet, allocationMethodOPMSheet } = spreadsheets;
  const { setNotesInApproach } = useContext(ValuationContext);

  const { futureValuesPerShareSheet, presentValuesPerShareSheet } = useValuesPerShareTableData({
    approach,
    futureValuesPerShare,
    presentValuesPerShare,
  });

  const valuesPerShareSpreadsheets = useMemo(
    () =>
      futureValuesPerShareSheet && presentValuesPerShareSheet
        ? [futureValuesPerShareSheet, presentValuesPerShareSheet]
        : [],
    [futureValuesPerShareSheet, presentValuesPerShareSheet]
  );

  const { onChange: valuesPerShareOnChange, workbook: valuesPerShareWorkbook }
    = useWorkbook(valuesPerShareSpreadsheets);

  const multipleOptions = useMemo(() => {
    if (selectedApproach) {
      return [
        ...getSelectionCellOptions({ specificApproach: selectedApproach as SpecificApproach }),
        SPECIFIED_MULTIPLE,
      ];
    }
    return [SPECIFIED_MULTIPLE];
  }, [selectedApproach]);

  const metricOptions = useMemo(() => {
    if (!financials?.use_adjusted_ebitda) return METRIC_OPTION_LABELS.filter(m => !m.includes(ADJUSTED_EBITDA_TITLE));
    return METRIC_OPTION_LABELS;
  }, [financials]);

  const updateCurrentAllocationMethodValue = useCallback(
    allocationMethod => {
      allocationMethod.reverseShouldValidate();
      if (currentAllocationMethodValue) {
        currentAllocationMethodValue.reverseShouldValidate();
      }
      setCurrentAllocationMethodValue(allocationMethod);
    },
    [currentAllocationMethodValue]
  );

  const contextValue = useMemo(
    () => ({
      approaches,
      multipleOptions,
      metricOptions,
      currentAllocationMethodValue,
      setCurrentAllocationMethodValue: updateCurrentAllocationMethodValue,
    }),
    [approaches, multipleOptions, metricOptions, currentAllocationMethodValue, updateCurrentAllocationMethodValue]
  );

  const getCellValue = useCallback((tableSpreadSheet: ScalarSpreadsheetConfigs, key: string) => {
    const { cells } = tableSpreadSheet;
    return Object.values(cells).find((cell: Cell) => cell?.alias === key || cell?.customKey === key);
  }, []);

  const getValueFromTable = useCallback(
    (tableSpreadSheet: ScalarSpreadsheetConfigs, key: string, fieldDecimalPlaces: number = DECIMAL_PLACES) => {
      const cell = getCellValue(tableSpreadSheet, key);
      return cell?.value ? parseCellValue({ cell, fieldDecimalPlaces }) : null;
    },
    [getCellValue]
  );

  const hasValidParams = useCallback((params: ValidateParams, isOPM): boolean => {
    const commonProperties = ['scenarioMethod', 'captableId', 'exitDate', 'discountRate', 'equityValue'];
    const propertiesToValidate = isOPM ? [...commonProperties, 'maturity', 'volatility'] : commonProperties;
    const invalidProperties = propertiesToValidate.filter(
      property => params[property] === null || params[property] === undefined || Number(params[property]) === 0
    );
    return invalidProperties.length === 0;
  }, []);

  const getScenarioValuesParams = useCallback(
    (scenarioMethod, isOPM) => {
      if (!futureEquitySheet || !presentValueSheet || !allocationMethodOPMSheet) return {};
      // Future Values Table
      const equityValue = getValueFromTable(presentValueSheet, FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_CUSTOM_KEY);
      const discountRate = getValueFromTable(
        presentValueSheet,
        FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE
      );

      const maturityValue = getValueFromTable(
        allocationMethodOPMSheet,
        FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS
      );
      const volatilityValue = getValueFromTable(allocationMethodOPMSheet, FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY);

      return {
        backsolveDate: null,
        captableId: capTable,
        discountRate,
        equityValue,
        exitDate: dbShortDate(exitDate),
        maturity: isOPM ? maturityValue : null,
        presentValues: null,
        scenarioMethod,
        scenarioType: FUTURE_EXIT,
        volatility: isOPM ? volatilityValue : null,
      };
    },
    [futureEquitySheet, presentValueSheet, allocationMethodOPMSheet, getValueFromTable, capTable, exitDate]
  );

  const getScenarioValues = useCallback(
    async scenarioMethod => {
      const isOPM = scenarioMethod === ALLOCATION_SCENARIO_METHOD_OPM;
      const scenarioValuesParams = getScenarioValuesParams(scenarioMethod, isOPM);
      if (hasValidParams(scenarioValuesParams, isOPM)) {
        const allocationService = new AllocationService();
        const response = await allocationService.postScenarioValues(scenarioValuesParams);
        if (response) {
          const { present_values: presentValues, future_values: futureValues } = response;
          setPresentValuesPerShare(presentValues as SecurityWithValue[]);
          setFutureValuesPerShare(futureValues as SecurityWithValue[]);
        }
      }
    },
    [getScenarioValuesParams, hasValidParams]
  );

  const onChange = useCallback(
    async (cell, expr) => {
      onWorkbookChange(cell, expr);
      if (cell.alias === FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH) {
        const foundApproach = approaches.find(a => (a.id?.toString() ?? a.panelId) === expr?.toString());
        setSelectedApproach(foundApproach);
      }
      if (SCENARIO_VALUES_DISPATCH_CELLS.includes(cell.alias)) {
        setPresentValuesPerShare([] as SecurityWithValue[]);
        setFutureValuesPerShare([] as SecurityWithValue[]);
        const allocationMethodValue
          = cell.alias === FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD ? cell.value : allocationMethod;

        const scenarioMethod
          = typeof allocationMethodValue === 'number'
            ? allocationMethodValue
            : ALLOCATION_METHOD_OPTIONS_VALUES[allocationMethodValue as keyof AllocationMethodsMapValues];
        getScenarioValues(scenarioMethod);
      }
    },
    [approaches, getScenarioValues, onWorkbookChange, allocationMethod]
  );

  useEffect(() => {
    if (!isEmpty(notes)) {
      setNotesInApproach?.(prevState => {
        const tmpNotes = prevState.filter((note: Note) => note.panelId !== approachPanelId);
        return [...tmpNotes, { notes, panelId: approachPanelId, notesHasChanged } as Note];
      });
    }
  }, [notes, notesHasChanged, approachPanelId, setNotesInApproach]);

  return (
    <FutureExitContext.Provider value={contextValue}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FutureEquity spreadsheets={spreadsheets} onChange={onChange} />
        </Grid>
        <Grid item xs={6}>
          <ModifiedPresentEquity spreadsheets={spreadsheets} onChange={onChange} />
        </Grid>
        <Grid item xs={12}>
          <AllocationMethodTable
            approach={valuationsApproachFutureExit}
            spreadsheets={spreadsheets}
            onChange={onChange}
            isDisabled={isDisabled}
            approaches={approaches}
            financials={financials}
          />
        </Grid>
        {Boolean(futureValuesPerShare.length) && futureValuesPerShareSheet && (
          <Grid item xs={6}>
            <ValuesPerShareTable
              onChange={valuesPerShareOnChange}
              valuesPerShareSheet={futureValuesPerShareSheet}
              workbook={valuesPerShareWorkbook}
            />
          </Grid>
        )}

        {Boolean(presentValuesPerShare.length) && presentValuesPerShareSheet && (
          <Grid item xs={6}>
            <ValuesPerShareTable
              onChange={valuesPerShareOnChange}
              valuesPerShareSheet={presentValuesPerShareSheet}
              workbook={valuesPerShareWorkbook}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Widgets
            notesProps={{
              pageType: VALUATIONS_PAGE_VALUE,
              pageTypeKey: VALUATIONS_PAGE_KEY,
              pageTypeId: approach.id,
              notes,
              isApproach: true,
              setNotes,
              onAddNote,
              onUpdateNotes,
              onDeleteNote,
              isDisabled,
            }}
          />
        </Grid>
      </Grid>
    </FutureExitContext.Provider>
  );
};
export default FutureExit;
