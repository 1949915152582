import {
  ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_NAME,
  ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_NAME_PLURAL,
  ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_TABLE,
  ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_TITLE,
} from 'pages/ValuationsAllocation/common/constants/weightedShareValues/weightedShareValues';

export const ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_TABLE_TERMS = (fundId: string) =>
  Object.freeze({
    columnName: ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_NAME,
    pluralColumnName: ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_NAME_PLURAL,
    tableName: `${ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_TITLE}_${fundId}`,
    tableSlug: `${ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_TABLE}_${fundId}`,
  });
