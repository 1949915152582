/* eslint-disable no-tabs */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import {
  OPTIONS_LEDGER_ROW_NUMBER,
  SHARES_OUTSTANDING_ROW_NUMBER,
  STRIKE_PRICE_ROW_NUMBER,
} from 'common/constants/cap-table';
import { LedgerTable } from 'components';
import { LedgerDialog } from 'components/Dialogs';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { cellsParser, reverseCellsParser } from 'components/LedgerTable/utilities/ledgerParser';
import CapTableContext from 'context/CapTableContext';
import { useTableValidation } from 'services/hooks';
import colConfig from './colConfig';

const emptyRow = { price: null, shares: null };

const OptionsLedger = ({ cell, closeDialog }) => {
  const [rows, setRows] = useState();
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [ledgerCells, setLedgerCells] = useState();

  const { validateTable } = useTableValidation();

  const { cells, onCellsChanged, format } = useContext(FeaturedSpreadsheetContext);
  const { getLinkedCellUpdates } = useContext(CapTableContext);
  const { isDisabled } = useContext(CapTableContext);

  const dataSourceKey = useMemo(() => cell.columnLegend + OPTIONS_LEDGER_ROW_NUMBER, []);
  const sharesOutstandingKey = useMemo(() => cell.columnLegend + SHARES_OUTSTANDING_ROW_NUMBER, []);
  const strikePriceKey = useMemo(() => cell.columnLegend + STRIKE_PRICE_ROW_NUMBER, []);
  // Reserve the cells parse
  const options = useMemo(() => {
    if (!isEmpty(ledgerCells) && !isEmpty(rows)) {
      return reverseCellsParser(rows, ledgerCells);
    }
    return [];
  }, [rows, ledgerCells]);

  const addRow = () => {
    setRows([...options, emptyRow]);
  };

  const deleteRow = rowIndex => {
    const tmpRows = [...options];
    tmpRows.splice(rowIndex, 1);
    setRows(tmpRows);
  };

  const save = () => {
    const cellsToValidate = Object.entries(ledgerCells).map(([, cell]) => cell);
    const { areCellsValid, validatedCells } = validateTable({
      cellsToValidate,
      toggleAlert: false,
    });

    if (areCellsValid) {
      const tmpState = { ...cells };
      let totalShares = 0;
      let sharesByStrikePrices = 0;

      // Save dates in local state
      options.forEach(({ shares, price }) => {
        // Get the total of shares
        totalShares += Number(shares);

        // Multiply each strike price by the number of associated shares
        sharesByStrikePrices += Number(shares) * Number(price);
      });

      // Update the "Shares Outstandings" value
      const sharesKey = cell.columnLegend + SHARES_OUTSTANDING_ROW_NUMBER;
      tmpState[sharesKey].expr = totalShares;

      // Save dates in the data source
      tmpState[dataSourceKey].expr = options;

      // Update strike price field
      const strikePriceKey = cell.columnLegend + STRIKE_PRICE_ROW_NUMBER;
      const price = sharesByStrikePrices / totalShares || 0;
      tmpState[strikePriceKey].expr = price;

      const linkedUpdates = getLinkedCellUpdates(tmpState);
      const changes = [
        { cell: tmpState[sharesOutstandingKey], value: totalShares.toString() },
        { cell: tmpState[strikePriceKey], value: price.toString() },
        { cell: tmpState[dataSourceKey], value: options },
      ];
      onCellsChanged([...changes, ...linkedUpdates]);

      closeDialog();
    } else {
      setIsAlertVisible(true);

      setLedgerCells(validatedCells.reduce((acc, curr) => ({ ...acc, [curr.key]: curr }), {}));
    }
  };

  const getInitialData = () => {
    const ledgerData = [...(cells[dataSourceKey].value || [])];
    const strikePrice = cells[strikePriceKey];
    const sharesOutstanding = cells[sharesOutstandingKey];

    // Use the security values to create the first row
    if (isEmpty(ledgerData)) {
      ledgerData.shift();
      ledgerData.unshift({
        shares: sharesOutstanding.value || sharesOutstanding.defaultValue,
        price: strikePrice.value || strikePrice.defaultValue,
      });
    }

    setRows(ledgerData);
  };

  useEffect(() => {
    if (cells && dataSourceKey && cells[dataSourceKey]) {
      getInitialData();
    }
  }, [cells, dataSourceKey]);

  // Parse cells
  useEffect(() => {
    if (!isNil(rows)) {
      const parsedCells = cellsParser(rows, colConfig, cell);
      setLedgerCells(parsedCells);
    }
  }, [rows]);

  return (
    <LedgerDialog
      id="options-ledger"
      title="Ledger"
      onSave={save}
      onClose={closeDialog}
      showDeleteColumn
      disabled={cell.isDisabled}>
      {ledgerCells && (
        <LedgerTable
          cell={cell}
          colConfig={colConfig}
          rows={rows}
          setRows={setRows}
          emptyRow={emptyRow}
          disabled={isDisabled}
          tableTerms={{
            tableName: 'Options Ledger',
            tableSlug: 'options-ledger',
            columnName: 'Option',
            pluralColumnName: 'Options',
          }}
          isAlertVisible={isAlertVisible}
          setIsAlertVisible={setIsAlertVisible}
          addRow={addRow}
          deleteRow={deleteRow}
          cells={ledgerCells}
          setCells={setLedgerCells}
          data={cells[dataSourceKey].value}
          currency={format.currency}
        />
      )}
    </LedgerDialog>
  );
};

OptionsLedger.propTypes = {
  columnLegend: PropTypes.string,
  cell: PropTypes.object,
  closeDialog: PropTypes.func,
};

export default OptionsLedger;
