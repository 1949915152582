import { Row, Rows } from 'common/types/scalarSpreadsheet';
import {
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TABLE,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/futureExit/';
import { getSlugValue } from 'utillities';
import { RowConfigParams } from './types';

const {
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_HEADER_TITLE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_MEASUREMENT_DATE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_EXIT_DATE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_YEARS_UNTIL_EXIT,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_EQUITY_VALUE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PLUS_DEBT,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_LESS_CASH,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_ENTERPRISE_VALUE,
} = SHEET_ALIASES_CONSTANTS;

const { FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET } = SHEET_TITLES_CONSTANTS;

const rowConfig = (params: RowConfigParams): Rows => {
  const { isDisabled } = params;

  return [
    {
      alias: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_HEADER_TITLE,
      className: `${FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_HEADER_TITLE
      )} division-bottom-only`,
      gridType: 'string',
      ignoreRowCopy: true,
      largeHeader: true,
      readOnly: true,
      value: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET.MODIFIED_PRESENT_EQUITY_VALUE.TITLE,
    } as Row,
    {
      alias: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_MEASUREMENT_DATE,
      className: `${FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_MEASUREMENT_DATE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET.MEASUREMENT_DATE.TITLE,
    } as Row,
    {
      alias: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_EXIT_DATE,
      className: `${FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_EXIT_DATE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET.EXIT_DATE.TITLE,
    } as Row,
    {
      alias: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_YEARS_UNTIL_EXIT,
      className: `${FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_YEARS_UNTIL_EXIT
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET.YEARS_UNTIL_EXIT.TITLE,
    } as Row,
    {
      alias: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE,
      className: `${FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      isRequired: !isDisabled,
      value: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET.DISCOUNT_RATE.TITLE,
    } as Row,
    {
      alias: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS,
      className: `${FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET.FUTURE_VALUE_CURRENT_SHAREHOLDERS.TITLE,
    } as Row,
    {
      alias: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_EQUITY_VALUE,
      className: `${FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_EQUITY_VALUE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET.PRESENT_EQUITY_VALUE.TITLE,
    } as Row,
    {
      alias: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PLUS_DEBT,
      className: `${FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PLUS_DEBT
      )} subtitle-indented`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET.PLUS_DEBT.TITLE,
    } as Row,
    {
      alias: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_LESS_CASH,
      className: `${FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_LESS_CASH
      )} subtitle-indented`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET.LESS_CASH.TITLE,
    } as Row,
    {
      alias: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_ENTERPRISE_VALUE,
      className: `bold-row ${FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_ENTERPRISE_VALUE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET.PRESENT_ENTERPRISE_VALUE.TITLE,
    } as Row,
  ];
};

export default rowConfig;
