import { isEmpty } from 'lodash';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getThresholdDate } from 'pages/Valuations/util/util';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { isValidDate } from 'utillities/datesFormats';
import afterCellChanged from './config/afterCellChanged';
import { percentagesAlias } from './config/constants';
import getColumns from './config/getColumns';
import getDCFRowConfig from './config/getRowConfig';
import parser from './config/parser';
import reverseParser from './config/reverseParser';

const processFinancialsPeriods = approach => {
  const {
    valuations_approach_dcf: { dcf_financial_period },
  } = approach;

  if (isEmpty(dcf_financial_period)) return;

  const financialPeriods = dcf_financial_period.map(period =>
    Object.keys(period).reduce((flattenedObj, key) => {
      const value = period[key];
      const isPercentageKey = percentagesAlias.includes(key);
      const toFixedArgument = isPercentageKey ? 4 : 2;
      // eslint-disable-next-line no-param-reassign
      flattenedObj[key]
        = !Number.isNaN(parseFloat(value)) && !Number.isInteger(value) && !isValidDate(value)
          ? parseFloat(value).toFixed(toFixedArgument)
          : value;
      return flattenedObj;
    }, {})
  );

  // eslint-disable-next-line no-param-reassign
  approach.valuations_approach_dcf.dcf_financial_period = financialPeriods;
};

export const getDcfConfig = ({
  approach,
  measurementDate,
  companyMeasurementDate,
  cashTaxRate,
  dcfWaccSheet,
  isDisabled,
}) => {
  processFinancialsPeriods(approach);

  const thresholdDate = getThresholdDate(companyMeasurementDate);

  const columns = getColumns(approach.valuations_approach_dcf.dcf_financial_period, thresholdDate);

  const rowConfig = getDCFRowConfig({
    cashTaxRate,
    dcfWaccSheet,
    isDisabled,
  });

  return {
    columns,
    rowConfig,
    tableData: {
      approach,
      measurementDate,
      isDisabled,
    },
  };
};

const createDCFData = ({ approach, dcfProps, dcfWaccSheet, dcfAttributes, isDisabled }) => {
  const name = getApproachTableName({ approach });

  const dcfConfig = getDcfConfig({
    approach,
    ...dcfProps,
    dcfWaccSheet,
    isDisabled,
  });

  return new SpreadsheetConfig({
    name,
    parser,
    showToolbar: true,
    reverseParser,
    afterCellChanged,
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    tableTerms: {
      tableSlug: 'valuation-dcf',
      columnName: 'year',
    },
    fieldAttributes: dcfAttributes,
    allowDeleteOnlySpecificColumn: true,
    ...dcfConfig,
  });
};

export default createDCFData;
