import React from 'react';
import { Box } from '@material-ui/core';
import {
  CONFIRMATION_MESSAGE_READY_FOR_AUDIT,
  CONFIRMATION_MESSAGE_REMOVE_READY_FOR_AUDIT,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { SetReadyForAuditConfirmationProps } from './types';

const SetReadyForAuditConfirmation = (props: SetReadyForAuditConfirmationProps) => {
  const { currentReadinessStatus } = props;
  const message = currentReadinessStatus
    ? CONFIRMATION_MESSAGE_REMOVE_READY_FOR_AUDIT
    : CONFIRMATION_MESSAGE_READY_FOR_AUDIT;
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box display="flex" justifyContent="center" alignItems="center" />
      <div>
        <p>{message}</p>
      </div>
    </Box>
  );
};

export default SetReadyForAuditConfirmation;
