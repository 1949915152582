import { MULTIPLE_TYPE } from 'common/constants/process-management';

export function extractAnswer(question) {
  const { answers } = question;
  if (question.question_type !== MULTIPLE_TYPE && answers?.length > 0) {
    return answers[0];
  }

  return null;
}
