import React from 'react';
import { SelectValueViewer } from 'components';
import PreviousVersionsList from '../components/PreviousVersionsList';

const rowConfig = [
  // 1
  {
    value: 'Name',
    alias: 'Comp Group Name',
    hidden: false,
    readOnly: false,
    gridType: 'string',
  },
  // 2
  {
    value: 'Previous Versions',
    alias: 'Comp Group Versions',
    hidden: false,
    readOnly: false,
    dropdown: true,
    dataEditor: props => <PreviousVersionsList {...props} />,
    valueViewer: props => <SelectValueViewer {...props} />,
  },
];

export default rowConfig;
