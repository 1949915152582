import React from 'react';
import { fundOwnershipUrl } from 'common/config/urls';
import { Row, Rows } from 'common/types/scalarSpreadsheet';
import { ValueWithLinkViewer } from 'components';
import {
  SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_KEY_PREFIX,
  SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TABLE,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/Funds/common/constants/scheduleOfInvestments';
import { createColumnKeyName } from 'pages/Funds/pages/ScheduleOfInvestments/config/createColumns';
import { generateColumnKey, getArrayValue, getStringValue } from 'utillities';
import { CreateSecuritiesRowsParams, RowConfigParams } from './types';

const createSecuritiesRows = (params: CreateSecuritiesRowsParams): Rows => {
  const { companySlug, firmSlug, investments: securities, queryParams } = params;

  return getArrayValue(
    securities?.map((security, securityIndex) => {
      const securityName = getStringValue(security?.security_name);

      // Get Company Fund Ownership URL
      const companyUrl = `${fundOwnershipUrl(firmSlug, companySlug)}${queryParams}`;

      return {
        alias: generateColumnKey({
          id: securityIndex,
          name: createColumnKeyName({ name: securityName, slug: companySlug }),
          prefix: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_KEY_PREFIX,
        }),
        gridType: 'string',
        readOnly: true,
        value: <ValueWithLinkViewer name={securityName} url={companyUrl} />,
      } as Row;
    })
  );
};

const rowConfig = (params: RowConfigParams): Rows => {
  const { company, firmSlug, queryParams } = params;
  const { investments } = company;

  const companySlug = getStringValue(company?.company_slug);

  return [
    {
      alias: SHEET_ALIASES_CONSTANTS.SCHEDULE_OF_INVESTMENTS_SPREADSHEET_HEADER_TITLE,
      className: `${SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TABLE}-header division-bottom-only`,
      gridType: 'string',
      largeHeader: true,
      readOnly: true,
      value: SHEET_TITLES_CONSTANTS.SCHEDULE_OF_INVESTMENTS_SPREADSHEET_HEADERS[0].value,
    } as Row,
    ...createSecuritiesRows({ companySlug, firmSlug, investments, queryParams }),
    {
      alias: SHEET_ALIASES_CONSTANTS.SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TOTAL,
      className: `${SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TABLE}-total division`,
      format: null,
      gridType: 'string',
      readOnly: true,
      value: SHEET_TITLES_CONSTANTS.SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TOTAL,
    } as Row,
  ];
};

export default rowConfig;
