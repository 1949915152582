// hides cells if it's parent is hidden
// TODO: Refactor this
const hideByVisibility = (rows, rowTitles) =>
  rows.map((row, rowIndex) =>
    row.map((col, colIndex) => {
      const isTableBody = colIndex > 1 && rowIndex > 0;
      let tmpCol = { ...col };

      if (isTableBody && col.conditions) {
        // find the row index of the depended row
        const conditionalRowIndex = rowTitles.findIndex(row => col.conditions.row === row.alias);

        // apply actions if the cell meets the criteria
        if (rows[conditionalRowIndex]?.[colIndex]?.hidden) {
          tmpCol = {
            ...col,
            hidden: true,
            value: '',
            forceComponent: false,
          };
        }
      }

      // Set value to null if cell is hidden
      tmpCol.value = !tmpCol.hidden ? tmpCol.value : null;
      return tmpCol;
    })
  ); // hideCells

export default hideByVisibility;
