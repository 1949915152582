import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import {
  PUBLISH_ALLOCATION_DEFAULT_ERROR_MESSAGE,
  PUBLISH_ALLOCATION_DEFAULT_SUCCESS_MESSAGE,
} from 'common/constants/services/allocations';
import { VALUATION_BY_ALLOCATION_QUERY_KEY } from 'common/constants/services/valuations';
import { AllocationService } from 'services';
import { throwError } from 'utillities';
import { PublishAllocationVersionParams, PublishAllocationVersionResponse } from './types';

const publishAllocationVersion = async (params: PublishAllocationVersionParams) => {
  const { allocationId } = params;

  if (!allocationId) return throwError(PUBLISH_ALLOCATION_DEFAULT_ERROR_MESSAGE);

  const allocationService = new AllocationService();

  try {
    const response = await allocationService.publishById(allocationId);

    return {
      published: !isEmpty(response),
      responseMessage: PUBLISH_ALLOCATION_DEFAULT_SUCCESS_MESSAGE,
    } as PublishAllocationVersionResponse;
  } catch (error) {
    return throwError(PUBLISH_ALLOCATION_DEFAULT_ERROR_MESSAGE);
  }
};

const usePublishAllocationVersion = () => {
  const queryClient = useQueryClient();

  const PublishAllocationVersionMutation = useMutation<
    PublishAllocationVersionResponse,
    Error,
    PublishAllocationVersionParams
  >({
    mutationFn: params => publishAllocationVersion(params),
  });

  const { isSuccess, mutateAsync } = PublishAllocationVersionMutation;

  if (isSuccess) {
    // Invalidate the Valuations Allocation query to refetch the data with the updated allocations status
    queryClient.invalidateQueries({
      queryKey: [VALUATION_BY_ALLOCATION_QUERY_KEY],
    });

    // Clean the mutation internal state
    PublishAllocationVersionMutation.reset();
  }

  return {
    mutateAsync,
  };
};

export default usePublishAllocationVersion;
