import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      width: '100%',
    },
  })
);

export default useStyles;
