import React, { FC, MouseEventHandler, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { SelectedMeasurementDate } from 'common/types/measurementDate';
import { FundMeasurementDateSelector } from 'pages/Funds/components';
import { FundSummary } from 'pages/Funds/pages';
import { getNumberValue, getStringValue, stopEventPropagation } from 'utillities';
import useStyles from './styles';
import { FundSummaryAccordionProps } from './types';

const FundSummaryAccordion: FC<FundSummaryAccordionProps> = props => {
  const { fund } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedMeasurementDate, setSelectedMeasurementDate] = useState<SelectedMeasurementDate>(null);

  const classes = useStyles();

  const handleExpansion: MouseEventHandler<HTMLDivElement> = () => setIsExpanded(previousState => !previousState);

  return (
    <Accordion
      expanded={isExpanded}
      onClick={handleExpansion}
      // TransitionProps={{ mountOnEnter: true }}
    >
      <AccordionSummary
        aria-controls={`${getStringValue(fund?.name)}-accordion-content`}
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        id={`${getNumberValue(fund?.id)}-accordion-header`}>
        <Typography component="h3" noWrap variant="h4">
          {`${getStringValue(fund?.name)} (${getStringValue(fund?.currency?.toString())})`}
        </Typography>

        <Grid className={classes.dateSelectorContainer}>
          <FundMeasurementDateSelector
            fund={fund}
            selectedMeasurementDate={selectedMeasurementDate}
            setSelectedMeasurementDate={setSelectedMeasurementDate}
          />
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        className={classes.accordionDetails}
        onClick={stopEventPropagation} // Prevent expanding/unexpanding the FundSummaryAccordion
      >
        {!isEmpty(fund) && !isEmpty(selectedMeasurementDate) && (
          <FundSummary fund={fund} includeTitle={false} measurementDate={selectedMeasurementDate} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default FundSummaryAccordion;
