import { isNil } from 'lodash';
import { BLANK_VALUE } from 'common/constants/general';
import { COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX } from 'pages/Portfolio/common/constants/companySummary';
import {
  generateColumnKey,
  getNumberOrNAValue,
  getNumberValue,
  getSlugValue,
  getStringValue,
  shortDate,
} from 'utillities';
import { CompanySummaryColumn, CreateColumnKeyNameParams, CreateColumns } from './types';

export const createColumnKeyName = (params: CreateColumnKeyNameParams) => {
  const { name, slug } = params;

  return `${getStringValue(slug)}-${getSlugValue(name)}`;
};

const createColumns: CreateColumns = params => {
  const { fundsInvestments } = params;

  // Investment Date
  const securityInvestmentDate = fundsInvestments.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current.id,
        name: createColumnKeyName({ name: current?.security_name, slug: current?.fundSlug }),
        prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getStringValue(shortDate(current?.investment_date)),
      },
    }),
    {}
  ) as CompanySummaryColumn;

  // Invested Capital
  const securityInvestedCapital = fundsInvestments.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current.id,
        name: createColumnKeyName({ name: current?.security_name, slug: current?.fundSlug }),
        prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.investment),
      },
    }),
    {}
  ) as CompanySummaryColumn;

  // Shares
  const securityShares = fundsInvestments.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current.id,
        name: createColumnKeyName({ name: current?.security_name, slug: current?.fundSlug }),
        prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.shares),
      },
    }),
    {}
  ) as CompanySummaryColumn;

  // CSE Shares
  const securityCSEShares = fundsInvestments.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current.id,
        name: createColumnKeyName({ name: current?.security_name, slug: current?.fundSlug }),
        prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.cse_shares),
      },
    }),
    {}
  ) as CompanySummaryColumn;

  // Concluded Share Value
  const securityConcludedShareValue = fundsInvestments.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current.id,
        name: createColumnKeyName({ name: current?.security_name, slug: current?.fundSlug }),
        prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: !isNil(current?.weighted_share_price) ? getNumberValue(current.weighted_share_price) : BLANK_VALUE,
      },
    }),
    {}
  ) as CompanySummaryColumn;

  // Fully Diluted Ownership %
  const securityFullyDilutedOwnership = fundsInvestments.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current.id,
        name: createColumnKeyName({ name: current?.security_name, slug: current?.fundSlug }),
        prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.diluted_ownership),
      },
    }),
    {}
  ) as CompanySummaryColumn;

  // Realized Value
  const securityRealizedValue = fundsInvestments.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current.id,
        name: createColumnKeyName({ name: current?.security_name, slug: current?.fundSlug }),
        prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: !isNil(current?.realized_value) ? getNumberValue(current.realized_value) : BLANK_VALUE,
      },
    }),
    {}
  ) as CompanySummaryColumn;

  // Unrealized Equity
  const securityUnrealizedEquity = fundsInvestments.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current.id,
        name: createColumnKeyName({ name: current?.security_name, slug: current?.fundSlug }),
        prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: !isNil(current?.unrealized_value) ? getNumberValue(current.unrealized_value) : BLANK_VALUE,
      },
    }),
    {}
  ) as CompanySummaryColumn;

  // Total Value
  const securityTotalValue = fundsInvestments.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current.id,
        name: createColumnKeyName({ name: current?.security_name, slug: current?.fundSlug }),
        prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: !isNil(current?.total_value) ? getNumberValue(current.total_value) : BLANK_VALUE,
      },
    }),
    {}
  ) as CompanySummaryColumn;

  // MOIC
  const securityMOIC = fundsInvestments.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current.id,
        name: createColumnKeyName({ name: current?.security_name, slug: current?.fundSlug }),
        prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: !isNil(current?.x_factor) ? getNumberOrNAValue(current.x_factor) : BLANK_VALUE,
      },
    }),
    {}
  ) as CompanySummaryColumn;

  return [
    securityInvestmentDate,
    securityInvestedCapital,
    securityShares,
    securityCSEShares,
    securityConcludedShareValue,
    securityFullyDilutedOwnership,
    securityRealizedValue,
    securityUnrealizedEquity,
    securityTotalValue,
    securityMOIC,
  ];
};

export default createColumns;
