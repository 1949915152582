import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import theme from 'theme';

const useStyles = makeStyles({
  chip: {
    marginRight: 8,
    cursor: 'pointer',
  },
  checkIcon: {
    fontSize: 16,
  },
});

const AnswerTypeChip = ({ type, value, handleAnswerTypeClick }) => {
  const classes = useStyles();
  return (
    <Chip
      key={type.value}
      label={capitalize(type.label)}
      className={classes.chip}
      style={{
        backgroundColor: value === type.value ? theme.palette.cyan : 'white',
      }}
      onClick={() => handleAnswerTypeClick(type.value)}
      variant={value === type.value ? 'default' : 'outlined'}
      clickable
      icon={value === type.value ? <CheckIcon className={classes.checkIcon} /> : null}
    />
  );
};

AnswerTypeChip.propTypes = {
  type: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleAnswerTypeClick: PropTypes.func.isRequired,
};

export default AnswerTypeChip;
