import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import {
  COMPANY_SETTINGS_ID,
  SUBSCRIPTION_ID,
  USER_MANAGEMENT_ID,
} from 'dashboard409a/common/constants/layouts/companySettings';
import useStyles from '../styles';

interface SettingsMenuButtonsProps {
  componentToRenderId: number;
  setComponentToRenderId: (id: number) => void;
}

const SettingsMenuButtons: FC<SettingsMenuButtonsProps> = ({ componentToRenderId, setComponentToRenderId }) => {
  const classes = useStyles();

  const settingsMenuStructure = [
    {
      id: COMPANY_SETTINGS_ID,
      title: 'Company Settings',
    },
    {
      id: USER_MANAGEMENT_ID,
      title: 'User Management',
    },
    {
      id: SUBSCRIPTION_ID,
      title: 'Subscription',
    },
  ];

  return (
    <div className={classes.settingsMenuButtons}>
      {settingsMenuStructure.map(item => (
        <Button
          key={item.id}
          color="primary"
          variant={componentToRenderId === item.id ? 'contained' : 'outlined'}
          className={classes.button}
          onClick={() => setComponentToRenderId(item.id)}>
          {item.title}
        </Button>
      ))}
    </div>
  );
};

export default SettingsMenuButtons;
