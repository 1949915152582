/* eslint-disable no-param-reassign */
const calculateTotalByAcquisitionRef = (aliasCriteria, data) =>
  data.reduce((acum, { value, acquisition_ref: acquisitionRef, alias }) => {
    if (acum[acquisitionRef] === undefined) {
      acum[acquisitionRef] = 0;
    }
    if (alias === aliasCriteria) {
      acum[acquisitionRef] += Number(value);
    }
    return acum;
  }, {});

export default calculateTotalByAcquisitionRef;
