import { template as balanceTemplate } from 'pages/Financials/balance-sheet/data';
import { reverseParser as balanceReverseParser } from 'pages/Financials/balance-sheet/utilities';
import { reverseParser as incomeReverseParser } from 'pages/Financials/income-statement/utilities';
import kpiReverseParser from 'pages/Financials/kpi/config/reverseParser';
import { generateKeyForMap, getMapKeyFromIncomeStatement } from './auxiliaryFunctions';
/**
 *
 * @param {*} incomeStatement
 * @param {*} balanceSheets
 * @param {*} financialStatement
 * @param {*} periods
 * @returns
 */
const getFinancialsData = (incomeStatement, balanceSheets, KPIs, financialStatement, periods) => {
  /* periods don't come sorted as their appear in render => use map */
  const periodsMap = new Map();
  periods.forEach(period => {
    const { period_type, statement_date } = period;
    const keyToSet = generateKeyForMap(period_type, statement_date);
    periodsMap.set(keyToSet, period);
  });
  const updatedIncomeStatements = incomeReverseParser(incomeStatement);
  const updatedBalanceSheets = balanceReverseParser(balanceSheets);
  const updatedKPIs = kpiReverseParser(KPIs);
  /* this variable is created to check when template or prexisting data
  should be used to fill missing NTM periods since updatedBalanceSheets
  is shorter than updatedIncomeStatements */
  const updBalanceSheetLength = updatedBalanceSheets.length;
  const updKPILength = updatedKPIs.length;

  const tmpFinancialStatement = { ...financialStatement };
  const financialStatementPeriods = [];

  updatedIncomeStatements.forEach((incomeStatementCol, index) => {
    const financialStatementPeriod = tmpFinancialStatement.financial_statement_periods.find(period => {
      const matchByPeriodId
        = incomeStatementCol.financial_statement_period && period.id === incomeStatementCol.financial_statement_period;
      const matchByDateAndPeriodType
        = period.statement_date === periods[index].statement_date
        && period.period_type === periods[index].period_type
        && period.id === incomeStatementCol.financial_statement_period;
      return matchByPeriodId || matchByDateAndPeriodType;
    });

    // remove de uuid from visibleCols id
    delete updatedIncomeStatements[index].id;
    if (index < updBalanceSheetLength) delete updatedBalanceSheets[index].id;

    if (financialStatementPeriod) {
      // the 2nd part is intended for existing data for NTM periods.
      const balanceUpdate
        = index < updBalanceSheetLength
          ? {
            ...financialStatementPeriod.balance_sheet,
            ...updatedBalanceSheets[index],
          }
          : { ...balanceTemplate, ...financialStatementPeriod.balance_sheet, name: 'NTM' };

      const kpiUpdate = index < updKPILength ? updatedKPIs[index]?.kpi_dataset || [] : [];

      // a period already exist
      // update the already existing period
      financialStatementPeriods.push({
        ...financialStatementPeriod,
        income_statement: {
          ...financialStatementPeriod.income_statement,
          ...incomeStatementCol,
        },
        balance_sheet: balanceUpdate,
        kpi_dataset: kpiUpdate,
      });
    } else {
      // add a new period to financial statement
      const generatedKey = getMapKeyFromIncomeStatement(incomeStatementCol);
      const matchedPeriod = periodsMap.get(generatedKey);
      financialStatementPeriods.push({
        income_statement: { ...incomeStatementCol },
        balance_sheet: { ...balanceTemplate, name: 'NTM', ...updatedBalanceSheets[index] },
        is_projection: matchedPeriod.is_projection,
        statement_date: matchedPeriod.statement_date,
        period_type: matchedPeriod.period_type,
        name: matchedPeriod.name,
        kpi_dataset: updatedKPIs[index]?.kpi_dataset || [],
        column_ref: matchedPeriod.id,
      });
    }
  });

  tmpFinancialStatement.financial_statement_periods = financialStatementPeriods;
  return tmpFinancialStatement;
};

export default getFinancialsData;
