import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function EqualSymbol(props) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M0.5 8C0.5 3.86 3.86 0.5 8 0.5C12.14 0.5 15.5 3.86 15.5 8C15.5 12.14 12.14 15.5 8 15.5C3.86 15.5 0.5 12.14 0.5 8ZM2 8C2 11.315 4.685 14 8 14C11.315 14 14 11.315 14 8C14 4.685 11.315 2 8 2C4.685 2 2 4.685 2 8ZM12.5 5.75H3.5V7.31985H12.5V5.75ZM12.5 8.68015H3.5V10.25H12.5V8.68015Z" />
    </SvgIcon>
  );
}
