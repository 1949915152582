import { isUndefined } from 'lodash';
import { largeCurrencyFormat } from 'common/formats/formats';
import formatNumbers from './formatNumbers';

export const returnCurrencyFormattedValueOrNull = (value, format, exchangeRate, isCaptableCurrencyApproach) => {
  const currency = {
    ...(format?.currency || {}),
    exchangeRate: isCaptableCurrencyApproach ? 1 : exchangeRate,
  };
  return !isUndefined(value)
    ? `${formatNumbers({
      value,
      currency,
      format: largeCurrencyFormat,
    })}`
    : null;
};
