import { CURRENT_VALUE, OPM } from 'common/constants/allocations';
import { toString } from 'utillities';

export default {
  id: 0,
  cap_table: {},
  name: 'Empty Scenario',
  is_deleted: false,
  deleted_at: null,
  weighting_probability: '0.00',
  scenario_type: CURRENT_VALUE,
  equity_value: '',
  exit_date: null,
  discount_rate: null,
  dilution_percentage: '0',
  maturity: '',
  volatility: '',
  exit_equity_value: '0.00',
  total_aggregate_value: null,
  order: 0,
  scenario_method: toString(OPM),
  scenario_values: {
    future_values: [],
    present_values: [],
    aggregate_values: [],
  },
  allocation: 1,
};
