import { SERVICES_AN_ERROR_OCCURRED_LOADING } from 'common/constants/services/services';

// Financial Statements
export const FINANCIAL_STATEMENTS_BY_MD_QUERY_KEY = 'FINANCIAL_STATEMENTS_BY_MD';
export const FINANCIAL_STATEMENTS_BY_MD_TITLE = 'Financial Statements';
export const FINANCIAL_STATEMENTS_BY_MD_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_LOADING(
  FINANCIAL_STATEMENTS_BY_MD_TITLE
);

// Financial Fiscal Year Periods
export const FINANCIAL_FISCAL_YEAR_PERIODS_BY_CMD_QUERY_KEY = 'FINANCIAL_FISCAL_YEAR_PERIODS_BY_CMD';
export const FINANCIAL_FISCAL_YEAR_PERIODS_BY_CMD_TITLE = 'Financial Fiscal Year Periods';
export const FINANCIAL_FISCAL_YEAR_PERIODS_BY_CMD_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_LOADING(
  FINANCIAL_FISCAL_YEAR_PERIODS_BY_CMD_TITLE
);

// Financial Versions
export const FINANCIAL_VERSIONS_BY_MD_QUERY_KEY = 'FINANCIAL_VERSIONS_BY_MD';
export const FINANCIAL_VERSIONS_BY_MD_TITLE = 'Financial Versions';
export const FINANCIAL_VERSIONS_BY_MD_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_LOADING(FINANCIAL_VERSIONS_BY_MD_TITLE);
