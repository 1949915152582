/**
 * @function
 * @name setFundInfo
 * @description reducer for action.
 * @param {Object} state  - reducer state
 * @param {Object} action - action context
 * @return {OBJECT} Action context
 */
export const setFundInfo = (state, action) => ({ ...state, ...action });
export const setFundList = (state, action) => ({ ...state, ...action });
export const setCurrentMpByFund = (state, action) => ({ ...state, ...action });
export const getFundSummaryInfo = (state, action) => ({ ...state, ...action });
