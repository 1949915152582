/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { isEqual, times, toLower } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useMDContext from 'context/MDContext';
import useStyles from './List.styles';

const CompanyList = () => {
  const classes = useStyles();

  const { items, companyList, isLoading, selectedCompanies, setSelectedCompanies, isDeletingMD } = useMDContext();

  const [filteredItems, setFilteredItems] = useState();

  const companyItems = useMemo(() => {
    if (items) {
      return items;
    }
    if (companyList) {
      return companyList.map(({ id, company }) => ({ id, name: company.name, subItems: [] }));
    }
    return [];
  }, [items, companyList]);

  const handleFilterChange = e => {
    const filter = e.target.value;
    const tmpFilteredItems = companyItems.filter(
      item =>
        toLower(item.name).includes(toLower(filter))
        || item.subItems.some(subItem => toLower(subItem.name).includes(toLower(filter)))
    );

    setFilteredItems(tmpFilteredItems);
  };

  const handleChange = (e, option) => {
    const { checked } = e.target;

    let tmpSelectedItems = {
      ...selectedCompanies,
    };

    if (checked) {
      tmpSelectedItems = {
        ...tmpSelectedItems,
        [option.id]: selectedCompanies[option.id] || checked,
      };
    } else {
      delete tmpSelectedItems[option.id];
    }

    setSelectedCompanies(tmpSelectedItems);
  };

  const renderCompaniesToDelete = () =>
    (filteredItems || []).map(item => (
      <FormControl key={`checkbox-${item.id}`} component="fieldset">
        <FormGroup>
          <FormControlLabel
            key={item.id}
            control={
              <Checkbox
                id={`checkbox-${item.id}`}
                size="small"
                name={item.name}
                checked={selectedCompanies[item.id] || false}
                onChange={e => handleChange(e, item)}
              />
            }
            label={item.name}
          />
        </FormGroup>
      </FormControl>
    ));

  const renderCompaniesToAdd = () =>
    (filteredItems || []).map(item => {
      if (item.subItems.length) {
        return (
          <FormControl key={item.name} component="fieldset" className={classes.itemContainer}>
            <FormLabel component="legend" classes={{ root: classes.itemTitle }}>
              {item.name}
            </FormLabel>
            <FormGroup>
              {item.subItems.map(subItem => (
                <FormControlLabel
                  key={subItem.id}
                  control={
                    <Checkbox
                      id={`checkbox-${subItem.id}`}
                      size="small"
                      name={subItem.name}
                      checked={selectedCompanies[subItem.id] || false}
                      onChange={e =>
                        handleChange(e, {
                          fund: item.id,
                          ...subItem,
                        })
                      }
                    />
                  }
                  label={subItem.name}
                />
              ))}
            </FormGroup>
          </FormControl>
        );
      }

      return null;
    });

  const renderItems = () => (isDeletingMD ? renderCompaniesToDelete() : renderCompaniesToAdd());

  const renderSkeleton = () => {
    const rows = 4;
    return times(rows, key => <Skeleton key={key} variant="text" height={40} />);
  };

  useEffect(() => {
    if (!isEqual(companyItems, filteredItems)) {
      setFilteredItems(companyItems);
    }
  }, [companyItems]);

  return (
    <Grid classes={{ root: classes.root }}>
      <Typography variant="subtitle2" gutterBottom classes={{ root: classes.listTitle }}>
        Select one or more Companies:
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Find a Company"
        onChange={handleFilterChange}
        InputProps={{
          classes: {
            root: classes.input,
            notchedOutline: classes.notchedOutline,
            adornedEnd: classes.inputAdornEnd,
          },
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon className={classes.icon} />
            </InputAdornment>
          ),
        }}
      />
      <Grid classes={{ root: classes.listContainer }}>
        <PerfectScrollbar options={{ minScrollbarLength: 50 }}>
          <Grid container direction="column" wrap="nowrap">
            {Array.isArray(filteredItems) && !isLoading ? renderItems() : renderSkeleton()}
          </Grid>
        </PerfectScrollbar>
      </Grid>
    </Grid>
  );
};

export default CompanyList;
