import { CONVERSION_RATE_ALIAS, LIQUIDATION_PREFERENCE_ALIAS, SENIORITY_ALIAS } from 'common/constants/cap-table';

const getUnderlyingSecurityCells = ({ cells, underlyingSecurityRef }) => {
  const matchingCells = Object.values(cells).filter(
    ({ alias, securityRef }) =>
      [CONVERSION_RATE_ALIAS, SENIORITY_ALIAS, LIQUIDATION_PREFERENCE_ALIAS].includes(alias)
      && securityRef === underlyingSecurityRef
  );

  return matchingCells.length > 0 ? matchingCells : null;
};

export default getUnderlyingSecurityCells;
