import { isUndefined } from 'lodash';
import moment from 'moment';
import { THIRD_ROW_ALIAS } from 'common/constants/financials';
import { getClassName, getPrevQuarterColumn, getPrevYearColumn, getRowSpan } from 'pages/Financials/utilities';
import { getValueByPropOrFallback, parseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';

const getKpiFromKpiDataset = (column, rowIndex) => {
  const { kpi_dataset: kpiDataset } = column;

  if (!kpiDataset?.length) return null;

  const kpiData = kpiDataset.find(kpi => kpi.company_kpi.sort_order === rowIndex - 3);

  if (!kpiData) return null;

  return kpiData;
};

const getKPIAndColumnValue = ({ column, row, index }) => {
  const kpi = getKpiFromKpiDataset(column, index);
  const columnValue = index > 2 && kpi ? kpi.kpi_value : column[row.alias];
  return {
    columnValue,
    kpi,
  };
};

const parser = async ({ columns, rowConfig, currencyCode }) => {
  const cells = {};
  let quarterLegends = {};
  const alphabet = alphabetGenerator([], columns.length);

  columns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];
    const { quarter } = column;

    rowConfig.forEach((row, index) => {
      const rowNumber = index + 1;
      const key = columnLegend + rowNumber;
      const type = getValueByPropOrFallback(row, 'gridType', null);
      const { kpi, columnValue } = getKPIAndColumnValue({ column, row, index });
      const value = !isUndefined(columnValue) ? columnValue : null;
      let parsedValue = parseValue(value, type, null, null, row.dbType);

      const date = getValueByPropOrFallback(column, 'statement_date', column.date);
      const statementDate = moment(date).format('MM-DD-YYYY');

      if (column.quarter) {
        if (row.alias === THIRD_ROW_ALIAS && column.subtitle === column.date) parsedValue = '';

        if (!quarterLegends[statementDate]) {
          quarterLegends = {
            ...quarterLegends,
            [statementDate]: columnLegend,
          };
        }
      }

      const { needs_actualization } = column;
      const className = getClassName(needs_actualization, row);

      const isAdditionalHistoricalYear = Boolean(column.isAdditionalHistoricalYear);
      const showAdditionalHistoricalYear = Boolean(column.showAdditionalHistoricalYear);
      const isVisibleColumn = isAdditionalHistoricalYear
        ? isAdditionalHistoricalYear && showAdditionalHistoricalYear
        : null;

      cells[key] = {
        ...row,
        isEditableTitleCell: false,
        date,
        key,
        columnLegend,
        columnIndex,
        needs_actualization,
        className,
        columnId: column.id,
        rowNumber,
        quarter,
        value: parsedValue,
        alias: getValueByPropOrFallback(row, 'alias', ''),
        isParent: Boolean(column.isParent),
        parentColumn: column.parentColumn,
        childIndex: column.quarter,
        rowSpan: getRowSpan(rowNumber, column),
        prevQuarter: getPrevQuarterColumn({ columnIndex, rowNumber, alphabet, cells }),
        prevYear: getPrevYearColumn({ columnIndex, column, alphabet }),
        isProjectionPeriod: getValueByPropOrFallback(column, 'isProjectedQuarter', column.isProjectedYear),
        isChildWithDivider: Boolean(column.isChildWithDivider),
        isParentWithDivider: Boolean(column.isParentWithDivider),
        isAdditionalHistoricalYear,
        showAdditionalHistoricalYear,
        isVisibleColumn,
        mdMatchesFiscalYearEnd: column.mdMatchesFiscalYearEnd,
        year: column.year,
        style: row.style,
        currencyCode,
        kpiId: kpi?.id ?? 0,
        valueViewer: undefined, // remove valueViewer meant for row headers
      };
    });
  });

  return cells;
};

export default parser;
