// TODO: Move constants to ValuationsAllocation

// General
export const ALLOCATION_SLUG = 'allocation';
export const ALLOCATION_TITLE = 'Allocation';
export const DATE_ALIAS = 'date';
export const FORBIDDEN_ALLOCATION_INFO = 'You do not have permissions to view the allocation info';
export const GET_ALLOCATION_INFO_ACCESS = 'Please contact your manager to gain access';
export const MEASUREMENT_DATE_TITLE = 'Measurement Date';
export const NO_EQUITY_ALLOCATION_AVAILABLE
  = 'No equity allocation is available unless a valid cap table is provided. A valid cap table requires at least one security of type preferred or common.';
export const SAVE_DRAFT_BEFORE_PUBLISHING = 'Please save the draft before publishing';
export const VERSION = 'Version';
export const WATERFALL_SLUG = 'waterfall';
export const WATERFALL_TITLE = 'Waterfall';

// Row number
export const SCENARIO_METHOD_ALIAS = 'scenario_method';
export const SCENARIO_METHOD_ROW_NUMBER = 1;
export const SCENARIO_METHOD_TITLE = 'Allocation Method';

export const CAP_TABLE_ALIAS = 'cap_table';
export const CAP_TABLE_ROW_NUMBER = 2;

export const SCENARIO_TYPE_ALIAS = 'scenario_type';
export const SCENARIO_TYPE_ROW_NUMBER = 3;

export const FUTURE_EQUITY_VALUE_ALIAS = 'exit_equity_value';
export const FUTURE_EQUITY_VALUE_ROW_NUMBER = 4;

export const EQUITY_VALUE_ALIAS = 'equity_value';
export const EQUITY_VALUE_ROW_NUMBER = 5;

export const SCENARIO_WEIGHTING_ALIAS = 'weighting_probability';
export const SCENARIO_WEIGHTING_ROW_NUMBER = 6;

export const AGGREGATE_VALUE_ALIAS = 'aggregate_values';
export const FUTURE_VALUE_ALIAS = 'future_values';
export const PRESENT_VALUE_ALIAS = 'present_values';

export const DILUTION_PERCENTAGE_ALIAS = 'dilution_percentage';

export const CURRENT_EQUITY_VALUE_ALIAS = 'current_equity_value';

// Scenario methods
export const WATERFALL = 0;
export const CSE = 1;
export const OPM = 2;
export const SPECIFIED_SHARE_VALUES = 3;

// Scenario types
export const FUTURE_EXIT = 0;
export const CURRENT_VALUE = 1;
export const BACKSOLVE = 2;
// Not a scenario type, but adding it here to avoid inserting null in a field that expects a number
export const SSV = 3;

// Allocation Statuses
export const ALLOCATION_STATUS_TITLE = 'Allocation Status:';
export const ALLOCATIONS_DRAFT = -1;
export const ALLOCATIONS_PUBLISHED = 0;
export const ALLOCATIONS_FINAL = 1;

export const STATUSES_LIST = Object.freeze(['Published', 'Final']);

export const CSE_METHOD = 'CSE';
export const OPM_METHOD = 'OPM';
export const SSV_METHOD = 'Specified Share Values';
export const WATERFALL_METHOD = 'Waterfall';

export const SCENARIO_METHODS = Object.freeze([WATERFALL_METHOD, CSE_METHOD, OPM_METHOD, SSV_METHOD]);
export const SCENARIO_METHODS_BACKSOLVE = Object.freeze([WATERFALL_METHOD, CSE_METHOD, OPM_METHOD]);

export const SCENARIO_TYPES = Object.freeze(['Future Exit', 'Current Value', 'Backsolve']);

export const ALLOCATION_METHODS = {
  [WATERFALL]: WATERFALL_METHOD,
  [CSE]: CSE_METHOD,
  [OPM]: OPM_METHOD,
};

/* Backsolve */
export const BASKET_BACKSOLVE = 1;
export const PER_SHARE_BACKSOLVE = 2;

export const BACKSOLVE_PER_SHARE_ALIAS = 'per_share';
export const BACKSOLVE_SECURITY_ALIAS = 'security';
export const BACKSOLVE_SECURITY_VALUE_ALIAS = 'value';
export const BACKSOLVE_SHARES_ALIAS = 'shares';
export const RISKFREERATE_ALIAS = 'risk_free_rate';

export const CURRENT_VALUE_LEDGER_ALIAS = 'current_value_ledger';
export const WEIGHTED_PRESENT_VALUE = 'Weighted Present Value';

export const BACKSOLVE_ALLOCATIONS_METHOD_TOOLTIP
  = 'The allocation method for a backsolve must be selected or modified from within the Backsolve Ledger';

// Current Value Ledger
export const FUTURE_EXIT_INPUTS_ALIAS = 'future_exits_inputs';
export const FUTURE_EXIT_INPUTS_ROW_NUMBER = 7;

export const EXIT_DATE_ALIAS = 'exit_date';
export const EXIT_DATE_ROW_NUMBER = 11;

export const DISCOUNT_RATE_ALIAS = 'discount_rate';
export const DISCOUNT_RATE_ROW_NUMBER = 12;

export const OPTION_PRICING_ALIAS = 'option_pricing';
export const OPTION_PRICING_ROW_NUMBER = 14;

export const MATURITY_ALIAS = 'maturity';
export const MATURITY_ROW_NUMBER = 15;

export const VOLATILITY_ALIAS = 'volatility';
export const VOLATILITY_ROW_NUMBER = 16;
export const APPROACHES = 'approaches';

// Future Exit Ledger
export const ALLOCATION_SCENARIO_WEIGHTS = 'allocations_scenarios_weights';
export const BENCHMARK_MULTIPLE_VALUE_ALIAS = 'benchmark_multiple_value';
export const CAP_TABLE_ENTERPRISE_VALUE_ALIAS = 'cap_table_enterprise_value';
export const ENTERPRISE_VALUE_ALIAS = 'enterprise_value';
export const EXIT_DATE_REFERENCE_ALIAS = 'exit_date_ref';
export const FINANCIALS_METRIC_VALUE_ALIAS = 'financials_metric_value';
export const FUTURE_EXIT_LEDGER_ALIAS = 'future_exit_ledger';
export const FUTURE_EXIT_VALUE = 'Future Exit Value';
export const FUTURE_VALUE = 'Future Value';
export const FUTURE_VALUE_TO_CURRENT_SHAREHOLDERS_ALIAS = 'future_value_to_current_shareholders';
export const GPC_MULTIPLE_VALUE_ALIAS = 'gpc_multiple_value';
export const LESS_DEBT_ALIAS = 'less_debt';
export const MEASUREMENT_DATE_ALIAS = 'measurement_date';
export const METRIC_OPTIONS = Object.freeze(['Revenue', 'EBITDA', 'Specified Metric']);
export const MULTIPLE_OPTIONS = Object.freeze(['Mean', 'Median', '25th', '75th', 'Specified Multiple']);
export const PLUS_CASH_ALIAS = 'plus_cash';
export const YEARS_UNTIL_EXIT_ALIAS = 'years_until_exit';
// values for selection
export const SPECIFIED_METRIC = '2';
export const SPECIFIED_MULTIPLE = '4';

export const APPROACH_TABLE_ID = 'APP';
export const APPROACH_WEIGHTING_ALIAS = 'approachWeighting';
export const BACKSOLVE_VALUATION_TABLE_ID = 'BACKSOLVE_VALUATION';
export const BASKET_TABLE_ID = 'BASKET';
export const FUTURE_EQUITY_VALUE_TABLE_ID = 'FEV';
export const FUTURE_EXIT_TABLE_ID = 'FUTURE_EXIT';
export const OPM_TABLE_ID = 'OPM';
export const PRESENT_EQUITY_VALUE_TABLE_ID = 'PEV';
export const SSV_TABLE_ID = 'SSV';
export const TARGET_VALUE_TABLE_ID = 'TARGET_VALUE';
export const ALLOCATION_FINAL_MESSAGE = 'This allocation table is marked as Final, any change can alter this data.';

export const allocationsMethod = Object.freeze({
  0: WATERFALL_METHOD,
  1: CSE_METHOD,
  2: OPM_METHOD,
  3: SSV_METHOD,
});

export const OPM_BACKSOLVE_DATE_INDEX = 0;
export const MATURITY_INDEX = 1;
export const VOLATILITY_INDEX = 4;
