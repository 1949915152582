import {
  SERVICES_AN_ERROR_OCCURRED_CREATING,
  SERVICES_AN_ERROR_OCCURRED_DELETING,
  SERVICES_AN_ERROR_OCCURRED_LOADING,
  SERVICES_AN_ERROR_OCCURRED_UPDATING,
  SERVICES_SUCCESSFULLY_CREATED,
  SERVICES_SUCCESSFULLY_DELETED,
  SERVICES_SUCCESSFULLY_UPDATED,
} from 'common/constants/services/services';

// Fund Companies With Ownership
export const COMPANIES_WITH_OWNERSHIP_BY_FUND_QUERY_KEY = 'COMPANIES_WITH_OWNERSHIP_BY_FUND';
export const COMPANIES_WITH_OWNERSHIP_BY_FUND_TITLE = 'Fund Companies With Ownership';
export const COMPANIES_WITH_OWNERSHIP_BY_FUND_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_LOADING(
  COMPANIES_WITH_OWNERSHIP_BY_FUND_TITLE
);

// Fund Measurement Dates
export const MEASUREMENT_DATES_BY_FUND_QUERY_KEY = 'MEASUREMENT_DATES_BY_FUND';
export const MEASUREMENT_DATES_BY_FUND_TITLE = 'Fund Measurement Dates';
export const MEASUREMENT_DATES_BY_FUND_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_LOADING(
  MEASUREMENT_DATES_BY_FUND_TITLE
);

// Fund Summary
export const FUND_SUMMARY_BY_MD_QUERY_KEY = 'FUND_SUMMARY_BY_MD';
export const FUND_SUMMARY_SPREADSHEET_TITLE = 'Fund Summary';
export const FUND_SUMMARY_BY_MD_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_LOADING(FUND_SUMMARY_SPREADSHEET_TITLE);
export const FUND_SUMMARY_BY_MD_CAP_IQ_API_ERROR = SERVICES_AN_ERROR_OCCURRED_LOADING('information from Capital IQ');
// Funds Allocations
export const FUND_ALLOCATIONS_BY_MD_QUERY_KEY = 'FUND_ALLOCATIONS_BY_MD';
export const FUND_ALLOCATIONS_BY_MD_TITLE = 'Fund Allocations';
export const FUND_ALLOCATIONS_BY_MD_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_LOADING(FUND_ALLOCATIONS_BY_MD_TITLE);

// Funds Investments
export const FUND_INVESTMENTS_BY_MD_QUERY_KEY = 'FUND_INVESTMENTS_BY_MD';
export const FUND_INVESTMENTS_BY_MD_TITLE = 'Fund Investments';
export const FUND_INVESTMENTS_BY_MD_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_LOADING(FUND_INVESTMENTS_BY_MD_TITLE);

// Create Fund
export const CREATE_FUND_TITLE = 'Fund';
export const CREATE_FUND_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_CREATING(CREATE_FUND_TITLE);
export const CREATE_FUND_NAME_ALREADY_EXISTS_ERROR_MESSAGE = 'A fund with this name already exists';
export const CREATE_FUND_DEFAULT_SUCCESS_MESSAGE = (fundName: string) =>
  SERVICES_SUCCESSFULLY_CREATED(`${CREATE_FUND_TITLE}: ${fundName}`);

// Update Fund
export const UPDATE_FUND_TITLE = 'Fund';
export const UPDATE_FUND_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_UPDATING(UPDATE_FUND_TITLE);
export const UPDATE_FUND_DEFAULT_SUCCESS_MESSAGE = (fundName: string) =>
  SERVICES_SUCCESSFULLY_UPDATED(`${UPDATE_FUND_TITLE}: ${fundName}`);

// Delete Fund
export const DELETE_FUND_TITLE = 'Fund';
export const DELETE_FUND_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_DELETING(DELETE_FUND_TITLE);
export const DELETE_FUND_DEFAULT_SUCCESS_MESSAGE = SERVICES_SUCCESSFULLY_DELETED(DELETE_FUND_TITLE);
