/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ConvertibleNoteModel } from './ConvertibleNoteModel';

export type SecurityModel = {
  readonly id?: number;
  convertible_notes: Array<ConvertibleNoteModel>;
  readonly is_used_in_fod?: string;
  readonly is_used_in_backsolve?: string;
  readonly created_at?: string;
  readonly updated_at?: string;
  name: string;
  is_deleted?: boolean | null;
  readonly deleted_at?: string | null;
  investment_date?: string | null;
  issue_price?: string | null;
  shares_outstanding?: string | null;
  conversion_rate?: string | null;
  pik_shares?: string | null;
  shares_diluted?: string | null;
  current_own_percent?: string | null;
  diluted_own_percent?: string | null;
  strike_price?: string | null;
  liquidation_preference?: string | null;
  seniority?: number | null;
  is_participating?: boolean | null;
  is_participation_cap?: boolean | null;
  participation_cap?: string | null;
  has_dividends?: boolean | null;
  has_multiple_investments?: boolean | null;
  dividend_payment_type?: SecurityModel.dividend_payment_type | null;
  dividend_rate?: string | null;
  has_compounding?: boolean | null;
  compounding_period?: number | null;
  accrued_dividends?: string | null;
  initial_liquidation_pref?: string | null;
  total_preference?: string | null;
  multiple_investments?: any;
  order?: number | null;
  options_ledger?: any;
  security_ref?: string | null;
  underlying_security_ref?: string | null;
  security_group_ref?: string | null;
  is_hidden?: boolean | null;
  total_note_preference?: string | null;
  total_note_value_at_md?: string | null;
  cap_table: number;
  security_type?: number | null;
  underlying_security?: number | null;
};

export namespace SecurityModel {
  export enum dividend_payment_type {
    '_0' = 0,
    '_1' = 1,
  }
}
