// #region states
import allocationsStates from './allocationsStates';
import captableStates from './captableStates';
import companiesStates from './companiesStates';
import globalStates from './globalStates';
// #endregion

export default {
  ...globalStates,
  ...allocationsStates,
  ...captableStates,
  ...companiesStates,
};
