import { isNil } from 'lodash';
import moment from 'moment';
import { ValuationsApproach } from 'common/types/valuation';
import { handleScenariosWeights } from 'pages/ValuationsAllocation/approaches/ValuationSummary/config/customReverseParser/utils';
import {
  ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES,
  ALLOCATION_SCENARIO_METHOD_WATERFALL,
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
  ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
  ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import { getNumberValue, getObjectValue, getStringValue, ISO_DATE_FORMAT, MDY_FORMAT } from 'utillities';
import { HandleScenarioTypeParams } from './types';
import { checkAndResetScenario } from '../checkAndResetScenario';

const handleScenarioType = (params: HandleScenarioTypeParams) => {
  const { approaches, approachesScenarioMethods, cell, fieldAttributes, scenario, value } = params;

  // Allocation Method
  const allocationMethodApproach = approachesScenarioMethods.find(approach => approach?.id === value);
  const { approachFutureEquityValue, approachPanelId, approachValuation, approachValuationId, scenarioType }
    = getObjectValue(allocationMethodApproach);

  const scenarioApproachId = getStringValue(approachPanelId);

  // Future Exit
  const {
    allocation_method: futureExitAllocationMethod,
    dilution_percentage: futureExitDilutionPercentage,
    discount_rate: futureExitDiscountRate,
    exit_date: futureExitExitDate,
  } = getObjectValue(approachValuation as ValuationsApproach['valuations_approach_future_exit']);

  if (!isNil(allocationMethodApproach)) {
    // Approach
    const approach = getObjectValue(
      approaches?.find(valuationApproach => valuationApproach?.panel_id === scenarioApproachId)
    );

    // Handle Scenarios Weights
    handleScenariosWeights({
      approach,
      cell,
      fieldAttributes: getObjectValue(fieldAttributes.valuationApproachWeightAttrs),
      scenario,
      shouldSetFullWeight: true,
    });

    switch (scenarioType) {
      case ALLOCATION_SCENARIO_TYPE_BACKSOLVE:
        checkAndResetScenario({
          scenario,
          scenarioApproachId,
          scenarioMethod: ALLOCATION_SCENARIO_METHOD_WATERFALL,
          scenarioType: ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
        });

        scenario.approach_uuid = scenarioApproachId;
        scenario.backsolve_valuation = approachValuationId;
        scenario.scenario_method = ALLOCATION_SCENARIO_METHOD_WATERFALL;
        scenario.scenario_type = ALLOCATION_SCENARIO_TYPE_BACKSOLVE;
        break;

      case ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT:
        checkAndResetScenario({
          scenario,
          scenarioApproachId,
          scenarioMethod: getNumberValue(futureExitAllocationMethod),
          scenarioType: ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
        });

        scenario.approach_uuid = scenarioApproachId;
        scenario.dilution_percentage = futureExitDilutionPercentage;
        scenario.discount_rate = futureExitDiscountRate;
        scenario.exit_date = moment(futureExitExitDate, ISO_DATE_FORMAT, true).isValid()
          ? getStringValue(futureExitExitDate)
          : getStringValue(moment(futureExitExitDate, MDY_FORMAT).format(ISO_DATE_FORMAT));
        scenario.exit_equity_value = getStringValue(approachFutureEquityValue?.toString() ?? '0');
        scenario.future_exit = approachValuationId;
        scenario.scenario_method = getNumberValue(futureExitAllocationMethod);
        scenario.scenario_type = ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT;
        break;

      case ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES:
        checkAndResetScenario({
          scenario,
          scenarioApproachId,
          scenarioMethod: ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES,
          scenarioType: ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES,
        });

        scenario.approach_uuid = scenarioApproachId;
        scenario.scenario_method = ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES;
        scenario.scenario_type = ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES;
        scenario.specified_share_values = approachValuationId;
        break;

      default:
        break;
    }
  }
};

export default handleScenarioType;
