import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import { RadioButtonGroup } from 'components';
import { SELECT_AN_OPTION } from './constants';

const ConfirmationWithRadio = ({
  cell,
  confirmationBaseFn,
  defaultValue = 0,
  dialogTitle,
  formLabel,
  onClose = () => {},
  options,
  open,
  size = 'sm',
}) => {
  const [selection, setSelection] = useState(defaultValue);
  const handleChange = event => {
    setSelection(Number(event.target.value));
  };
  const handleConfirm = () => {
    confirmationBaseFn(cell, selection);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={size}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <RadioButtonGroup
          options={options}
          formLabel={formLabel ?? SELECT_AN_OPTION}
          defaultValue={selection}
          ariaLabel={SELECT_AN_OPTION}
          name="radio-button-group"
          handleChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationWithRadio.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  confirmationBaseFn: PropTypes.func.isRequired,
  formLabel: PropTypes.string,
  options: PropTypes.array.isRequired,
  cell: PropTypes.object.isRequired,
  dialogTitle: PropTypes.string,
  defaultValue: PropTypes.number,
  size: PropTypes.string,
};

export default ConfirmationWithRadio;
