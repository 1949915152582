import * as messages from 'common/constants/messages/validations';

/**
 * Validates a value to exclude white spaces.
 *
 * @param {string} value - The value to be validated
 * @param {object} options - Additional options for the validation.
 * @returns {string|null} - Returns a string if the value is invalid, otherwise null.
 */

const excludeWhiteSpacesValidator = (value, options) => {
  const tempValue = value ?? '';

  const hasSpaces = tempValue.match(/\s+/);

  return hasSpaces ? options.message || messages.HAS_WHITE_SPACES : null;
};

export default excludeWhiteSpacesValidator;
