import { isEmpty, isUndefined } from 'lodash';

const FILTER_UNDEFINED = ({ _data: data }) => {
  const result = data.filter(element => !isUndefined(element));

  if (isEmpty(result)) return [0];
  return result;
};

export default FILTER_UNDEFINED;
