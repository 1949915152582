import React from 'react';
import { valuationUrl } from 'common/config/urls';
import { Row, Rows } from 'common/types/scalarSpreadsheet';
import { ValueWithLinkViewer } from 'components';
import { TAB_QUERY_PARAM } from 'pages/ValuationsAllocation/common/constants/valuations';
import {
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
  VALUATION_SUMMARY_LABEL,
  VALUATION_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
  VALUATION_SUMMARY_SPREADSHEET_TABLE,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import {
  generateColumnKey,
  getArrayValue,
  getBooleanValue,
  getObjectValue,
  getSlugValue,
  getStringValue,
} from 'utillities';
import { CreateApproachesRowsParams, CustomRowConfigParams } from './types';

const {
  VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_METHOD,
  VALUATION_SUMMARY_SPREADSHEET_HEADER_TITLE,
  VALUATION_SUMMARY_SPREADSHEET_LESS_CASH,
  VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT,
  VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE,
  VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
  VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_ENTERPRISE_VALUE,
  VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE,
} = SHEET_ALIASES_CONSTANTS;

const {
  VALUATION_SUMMARY_SPREADSHEET_HEADERS,
  VALUATION_SUMMARY_SPREADSHEET_CASH_TITLE,
  VALUATION_SUMMARY_SPREADSHEET_DEBT_TITLE,
  VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE_TITLE,
  VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY_TITLE,
  VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_ENTERPRISE_VALUE_TITLE,
  VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE_TITLE,
} = SHEET_TITLES_CONSTANTS;
const { VALUATION_APPROACH_NAME } = VALUATION_SUMMARY_SPREADSHEET_HEADERS;

const createApproachesRows = (params: CreateApproachesRowsParams): Rows => {
  const { companySlug, configurations, firmSlug, isDisabled, queryParams } = params;

  const nonCalibrationConfigurations = configurations?.filter(
    configuration => !getBooleanValue(configuration?.approach?.is_calibration)
  );

  return getArrayValue(
    nonCalibrationConfigurations?.map(configuration => {
      const { approach } = getObjectValue(configuration);
      const { name } = getObjectValue(approach);

      const optionalKey = generateColumnKey({
        id: approach?.id,
        name: approach?.name,
        prefix: VALUATION_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      });

      const approachTableName = getApproachTableName({ approach });

      // Get Company Valuations Allocation URL
      const valuationsAllocationApproachUrl = `${valuationUrl(
        firmSlug,
        companySlug
      )}?${TAB_QUERY_PARAM}=${approachTableName}&${queryParams}`;

      return {
        alias: getStringValue(approach?.panelId ?? optionalKey),
        className: `${VALUATION_SUMMARY_SPREADSHEET_TABLE}-${getSlugValue(approachTableName)}`,
        data: { isApproach: true },
        gridType: 'string',
        ignoreRowCopy: true,
        parent: VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_METHOD,
        readOnly: isDisabled,
        value: <ValueWithLinkViewer name={getStringValue(name)} url={valuationsAllocationApproachUrl} />,
      } as Row;
    })
  );
};

const customRowConfig = (params: CustomRowConfigParams): Rows => {
  const { companySlug, configurations, firmSlug, isDisabled, queryParams } = params;

  return [
    {
      alias: VALUATION_SUMMARY_SPREADSHEET_HEADER_TITLE,
      className: `${VALUATION_SUMMARY_SPREADSHEET_TABLE}-${getSlugValue(
        VALUATION_SUMMARY_SPREADSHEET_HEADER_TITLE
      )} division-bottom-only`,
      gridType: 'string',
      ignoreRowCopy: true,
      largeHeader: true,
      readOnly: true,
      value: VALUATION_SUMMARY_LABEL,
    } as Row,
    {
      alias: VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_METHOD,
      className: `${VALUATION_SUMMARY_SPREADSHEET_TABLE}-${getSlugValue(
        VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_METHOD
      )} subtitle`,
      gridType: 'string',
      hasSubRows: true,
      ignoreRowCopy: true,
      readOnly: true,
      value: VALUATION_APPROACH_NAME.title,
    } as Row,
    ...createApproachesRows({
      companySlug,
      configurations,
      firmSlug,
      isDisabled,
      queryParams,
    }),
    {
      alias: VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE,
      className: `${VALUATION_SUMMARY_SPREADSHEET_TABLE}-${getSlugValue(
        VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE
      )} subtitle-bold division-top-only`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE_TITLE,
    } as Row,
    {
      alias: VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
      className: `${VALUATION_SUMMARY_SPREADSHEET_TABLE}-${getSlugValue(
        VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY
      )} subtitle-indented`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      value: VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY_TITLE,
    } as Row,
    {
      alias: VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE,
      className: `${VALUATION_SUMMARY_SPREADSHEET_TABLE}-${getSlugValue(
        VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE
      )} subtitle`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE_TITLE,
    } as Row,
    {
      alias: VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT,
      className: `${VALUATION_SUMMARY_SPREADSHEET_TABLE}-${getSlugValue(
        VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT
      )} subtitle-indented`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: VALUATION_SUMMARY_SPREADSHEET_DEBT_TITLE,
    } as Row,
    {
      alias: VALUATION_SUMMARY_SPREADSHEET_LESS_CASH,
      className: `${VALUATION_SUMMARY_SPREADSHEET_TABLE}-${getSlugValue(
        VALUATION_SUMMARY_SPREADSHEET_LESS_CASH
      )} subtitle-indented`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: VALUATION_SUMMARY_SPREADSHEET_CASH_TITLE,
    } as Row,
    {
      alias: VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_ENTERPRISE_VALUE,
      className: `${VALUATION_SUMMARY_SPREADSHEET_TABLE}-${getSlugValue(
        VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_ENTERPRISE_VALUE
      )} subtitle`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_ENTERPRISE_VALUE_TITLE,
    } as Row,
  ];
};

export default customRowConfig;
