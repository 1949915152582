import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { notFoundPath } from 'common/config/urls';
import {
  DOCUMENT,
  DOCUMENT_TOOL,
  DOCUMENTS_URL_PARAM,
  DONE_HERE,
  getWelcomeMessage,
  LINK,
  PROCESS_MANAGEMENT,
  QUESTIONNAIRE,
  QUESTIONNAIRE_ACTION,
  QUESTIONS_URL_PARAM,
  UPLOAD_ACTION,
  UPLOAD_TOOL,
} from 'common/constants/process-management';
import { TaskContext } from 'context';
import ProcessManagementLanding from './components/ProcessManagementLanding';
import ProcessManagementPortal from './ProcessManagementPortal';

const ProcessManagement = props => {
  const { processType, portal } = useParams();
  const [processTypeObject, setProcessTypeObject] = useState({});
  const history = useHistory();
  const { setHeaderMenuItems, questionnaireUrl, documentUrl } = useContext(TaskContext);

  useEffect(() => {
    if (processType && !portal) {
      switch (processType) {
        case DOCUMENTS_URL_PARAM:
          setProcessTypeObject({
            page: DOCUMENTS_URL_PARAM,
            titleBold: DOCUMENT,
            title: UPLOAD_TOOL,
            description: getWelcomeMessage(`${UPLOAD_TOOL}`, `${UPLOAD_ACTION}`),
          });
          setHeaderMenuItems({
            right: [
              {
                id: uuid(),
                label: DONE_HERE(QUESTIONNAIRE),
                link: questionnaireUrl,
                component: LINK,
                withArrow: true,
              },
            ],
          });
          break;
        case QUESTIONS_URL_PARAM:
          setProcessTypeObject({
            page: QUESTIONS_URL_PARAM,
            titleBold: PROCESS_MANAGEMENT,
            title: QUESTIONNAIRE,
            description: getWelcomeMessage(`${QUESTIONNAIRE}`, `${QUESTIONNAIRE_ACTION}`),
          });
          setHeaderMenuItems({
            right: [
              {
                id: uuid(),
                label: DONE_HERE(DOCUMENT_TOOL),
                link: documentUrl,
                component: LINK,
                withArrow: true,
              },
            ],
          });
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentUrl, portal, processType, questionnaireUrl]);

  if (processType !== DOCUMENTS_URL_PARAM && processType !== QUESTIONS_URL_PARAM) {
    history.push(notFoundPath);
    return null;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" marginTop="10rem">
      {portal ? (
        <ProcessManagementPortal processType={processType} {...props} />
      ) : (
        <ProcessManagementLanding processTypeObject={processTypeObject} {...props} />
      )}
    </Box>
  );
};

export default ProcessManagement;
