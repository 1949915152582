import { ALLOCATION_PERCENTAGE, BREAKPOINT_TOTAL_ALIAS } from 'common/constants/cap-table';
import { letterRegex, numberRegex } from 'common/constants/general';
import {
  LOWER_VALUE_THAN_PREVIOUS,
  POSITIVE_NUMBER_ERROR,
  TOTAL_WEIGHT_NOT_100,
  VALUE_TYPE_ERROR,
} from 'common/constants/messages/validations';
import { roundToN } from 'utillities';

const customValidations = ({ cell, parsedColumns, addFeedback, addFeedbackFromCell, removeFeedbackFromCell }) => {
  const { value, key: cellKey, alias } = cell;

  const parseTotalValue = totalValue => {
    if (typeof totalValue === 'string') {
      /* The regex replaces anything that is not a digit or a dot with an empty string.
      // The u flag includes support for unicode characters, which may be helpful
      when we implement foreign currencies in the future. */
      return Number(totalValue.replace(/[^0-9.]/gu, ''));
    }
    return totalValue;
  };

  const parsedColumnsKeys = Object.keys(parsedColumns);
  const totalRow = numberRegex.exec(parsedColumnsKeys[parsedColumnsKeys.length - 1])[0];

  // Returns the letter part of all the parsed column keys: ['A', 'A', 'B', 'B', 'AA', 'AB', etc]
  const parsedColumnsKeyLetters = parsedColumnsKeys.map(key => letterRegex.exec(key)[0]);

  // Sort by length and then by lexicographical order. Finally, remove duplicates.
  // For example, AA should be after Z in the same fashion as in an Excel spreadsheet.
  // ['A', 'T', 'S', 'B', 'F', 'U', 'AA', 'C', 'AB'] --> ['A', 'B', 'C', 'F', 'S', 'T', 'U', 'AA', 'AB']
  parsedColumnsKeyLetters.sort((a, b) => a.length - b.length || a.localeCompare(b));
  const uniqueKeys = [...new Set(parsedColumnsKeyLetters)];

  // If cellKey is B3 or AA21, then cellLocation becomes:
  // { column: 'B', row: '3'} or { column: 'AA', row: '21'}
  const cellLocation = {
    column: letterRegex.exec(cellKey) ? letterRegex.exec(cellKey)[0] : '',
    row: numberRegex.exec(cellKey) ? numberRegex.exec(cellKey)[0] : '',
  };

  const cellColIndex = uniqueKeys.findIndex(key => key === cellLocation.column);

  /* Start validating on row #3 and second column (A3) since that is the first data cell in the
  custom BPs table. The first column consists of the row titles and doesn't need validation. */
  if (Number(cellLocation.row) > 2 && Number(cellColIndex) > 1) {
    const totalRowCurrentColumn = parsedColumns[`${cellLocation.column}${totalRow}`];
    const totalRowPreviousColumn = parsedColumns[`${uniqueKeys[cellColIndex - 1]}${totalRow}`];

    const tmpTotalCurrentColumn = parseTotalValue(totalRowCurrentColumn.value);
    const tmpTotalPreviousColumn = parseTotalValue(totalRowPreviousColumn.value);

    // Compare total in the current column with the previous one
    if (alias !== ALLOCATION_PERCENTAGE && tmpTotalCurrentColumn < tmpTotalPreviousColumn) {
      addFeedbackFromCell(totalRowCurrentColumn, LOWER_VALUE_THAN_PREVIOUS('total'));
    } else {
      removeFeedbackFromCell(totalRowCurrentColumn, LOWER_VALUE_THAN_PREVIOUS('total'));
    }
    const totalCells = Object.values(parsedColumns).filter(totalCell => totalCell.alias === BREAKPOINT_TOTAL_ALIAS);
    const totalPercentageCell = totalCells.find(totalCell => totalCell.y === cellColIndex);
    // since we're displaying 2 decimals, we need to round the total percentage to 4 decimals
    const roundedTotalPercentage = roundToN(tmpTotalCurrentColumn, 4);
    if (alias === ALLOCATION_PERCENTAGE && roundedTotalPercentage !== 1) {
      addFeedbackFromCell(totalPercentageCell, TOTAL_WEIGHT_NOT_100);
    } else {
      removeFeedbackFromCell(totalPercentageCell, TOTAL_WEIGHT_NOT_100);
    }
  }

  // Value type validation
  if (Number.isNaN(Number(value))) {
    addFeedback(VALUE_TYPE_ERROR('number'), 'error');
  }

  if (Number(value) < 0) {
    addFeedback(POSITIVE_NUMBER_ERROR, 'error');
  }
};

export default customValidations;
