import React, { FC } from 'react';
import { Box, Card, CardContent, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import { useFormat } from 'common/hooks';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import { SubscriptionExpiredBox } from 'dashboard409a/components/SubscriptionExpiredBox';
import { TrendsDetail } from 'dashboard409a/components/TrendsDetail';
import { ITrendsDetailProps } from 'dashboard409a/components/TrendsDetail/types';
import { IValuationState } from 'dashboard409a/states/features/valuation';
import { RootStateType } from 'dashboard409a/states/store';
import { ValuationTrendCardsProps } from './types';
import { ValuationTrendProps } from '../ValuationTrend/types';
import ValuationTrend from '../ValuationTrend/ValuationTrend';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '2%',
    boxShadow: 'none',
  },
  link: {
    display: 'inline',
    cursor: 'pointer',
  },
  boxTrends: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '2%',
  },
}));

const ValuationTrendCards: FC<ValuationTrendCardsProps> = ({ showTrendsDetails, displayTable }) => {
  const classes = useStyles();
  const { currentCompany } = useSelector((state: RootStateType) => state.global);
  const { loading, allValuationScalarOpinion } = useSelector<RootStateType, IValuationState>(state => state.valuation);
  const [format, formatDispatch] = useFormat({ units: REGULAR_UNIT });

  const subscriptionIsActive = currentCompany?.is_active;
  const displayExpired = !isUndefined(subscriptionIsActive) && subscriptionIsActive === false;

  const valuationTrendProps: ValuationTrendProps = {
    data: allValuationScalarOpinion,
    isLoading: loading,
  };
  const trendsDetailProps: ITrendsDetailProps = {
    data: allValuationScalarOpinion,
    isLoading: loading,
    format,
    formatDispatch,
  };

  return (
    <Grid item xs={12} lg={6}>
      <Card className={classes.root}>
        <CardContent>
          {displayExpired ? <SubscriptionExpiredBox /> : <ValuationTrend {...valuationTrendProps} />}
          <Box className={classes.boxTrends}>
            <Link
              id="show-valuation-methods-link"
              onClick={() => displayTable('ValuationTrends')}
              className={classes.link}
              variant="body2">
              {`${showTrendsDetails ? 'Hide' : 'Show'} Trends Details`}
            </Link>
          </Box>
        </CardContent>
      </Card>
      {showTrendsDetails ? (
        <Card>{displayExpired ? <SubscriptionExpiredBox /> : <TrendsDetail {...trendsDetailProps} />}</Card>
      ) : null}
    </Grid>
  );
};

export default ValuationTrendCards;
