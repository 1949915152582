/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import { conditions, customValidations, parser, reverseParser, totalParser } from './utilities';

const useFundOwnership = props => {
  const {
    updatedFundOwnership,
    updatedColumns,
    visibleColumns,
    customConfig,
    tableData,
    cells,
    tableTerms,
    setColumns,
    columns,
    isDisabled,
    fundOwnershipAttrs,
    captableInfo,
  } = props;
  const [spreadsheet, setSpreadsheet] = useState();
  const { evaluateSpreadsheets } = useWorkbook();

  const deletedColumns = useMemo(() => {
    if (columns?.length) {
      return columns.filter(c => c.is_deleted);
    }

    return [];
  }, [columns]);

  const deleteColumn = columnIndex => {
    const tmpPersistedColumns = [
      ...reverseParser({
        cells: cells.fundOwnership,
        columns: visibleColumns,
      }),
    ];

    const { fund_ownership } = captableInfo;

    if (fund_ownership) {
      const existInDB = fund_ownership.fund_ownership_detail.find(
        item => item.id === tmpPersistedColumns[columnIndex].id
      );

      if (existInDB) {
        tmpPersistedColumns[columnIndex].is_deleted = true;
      } else {
        tmpPersistedColumns.splice(columnIndex, 1);
      }
      setColumns([...tmpPersistedColumns, ...deletedColumns]);
    }
  };

  const cloneColumn = columnIndex => {
    const tmpColumns = updatedColumns;
    const column = tmpColumns[columnIndex];
    const clonedColumn = { ...column, id: 0 };

    clonedColumn.acquisitions = column.acquisitions.map(acquisition => ({
      ...acquisition,
      id: 0,
    }));

    const tmpPersistedColumns = reverseParser({
      cells: cells.fundOwnership,
      columns: visibleColumns,
    });
    const allColumns = [clonedColumn, ...tmpPersistedColumns, ...deletedColumns];
    setColumns(allColumns);
  };

  const addColumn = async (isCustomSecurity = false) => {
    const emptyColumn = {
      id: 0,
      fund_ownership: tableData.fundOwnership.id || 0,
      invested_capital: '0.00',
      fund: null,
      security: null,
      is_deleted: false,
      is_custom_security: isCustomSecurity,
      acquisitions: [],
      ...(isCustomSecurity ? { custom_security_name: '' } : null),
    };

    if (spreadsheet) {
      spreadsheet.addColumns(spreadsheet.columns.length, [
        {
          emptyColumn,
        },
      ]);
    }

    const tmpPersistedColumns = reverseParser({
      cells: cells.fundOwnership,
      columns: visibleColumns,
    });
    const allColumns = [...tmpPersistedColumns, ...deletedColumns];
    setColumns(allColumns);
    await evaluateSpreadsheets([spreadsheet]);
  };

  useEffect(() => {
    if (visibleColumns && tableData.capTable) {
      setSpreadsheet(
        new SpreadsheetConfig({
          columns: visibleColumns,
          parser,
          totalParser,
          rowConfig: customConfig,
          conditions,
          name: 'fundOwnership',
          tableData: {
            ...tableData,
            columns,
            capTable: {
              ...tableData.capTable,
              securities: captableInfo?.securities || [],
            },
            fundOwnership: updatedFundOwnership,
          },
          reverseParser,
          customValidations,
          showTotalColumn: true,
          showToolbar: true,
          tableTerms,
          hasColTitle: true,
          currencyFormatter: true,
          allowReorderColumns: !isDisabled,
          fieldAttributes: fundOwnershipAttrs,
          allowCloneColumn: !isDisabled,
          allowDeleteColumn: !isDisabled,
          page: 'captable',
          cloneColumn,
          disabled: isDisabled,
          currency: tableData.capTable.currency,
        })
      );
    }
  }, [visibleColumns]);

  return {
    spreadsheet,
    updatedFundOwnership,
    updatedColumns,
    deletedColumns,
    addColumn,
    deleteColumn,
    visibleColumns,
  };
};

export default useFundOwnership;
