import React, { useCallback, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { ApiService, type CustomPrimaryUser } from '../../../api';
import { manageApiRequest } from '../../../hooks/apiRequest';
import UserCard from '../../../pages/UserPermissions/components/UserCard';
import UserDirectory from '../../../pages/UserPermissions/components/UserDirectory';
import { prettyLastLogin, prettyUsername } from '../../../pages/UserPermissions/utils/prettyUserData';
import { useGetUserDetails } from '../../../services/hooks/useUserSelected';
import { RootStateType } from '../../states/store';

const useStyles = makeStyles(theme => ({
  section: {
    height: 'calc(100vh - 15rem)',
    padding: '1rem',
  },
  borderLeft: {
    borderLeft: `3px solid ${theme.palette.grey[200]}`,
  },
}));

const UserManagement = () => {
  const classes = useStyles();
  const [currentUser, setCurrentUser] = useState<CustomPrimaryUser>();
  const { data: userSelected } = useGetUserDetails(currentUser?.id);

  const { currentCompany } = useSelector((state: RootStateType) => state.global);
  const handleAddUser = useCallback(
    (userData, _, handleDialogClose) => {
      if (currentCompany) {
        manageApiRequest(
          ApiService.apiDashboardCompanyAddUserCreate({
            company_id: currentCompany.id,
            email: userData.email,
            first_name: userData.firstName,
            last_name: userData.lastName,
            is_primary_contact: !!userData.isPrimaryContact,
            send_activation_email: !!userData.sendActivationEmail,
          })
        ).then(handleDialogClose);
      }
    },
    [currentCompany]
  );

  const userFullNameOrEmail = useMemo(() => prettyUsername(userSelected), [userSelected]);

  const lastLogin = useMemo(() => prettyLastLogin(userSelected), [userSelected]);

  return (
    <Grid container style={{ margin: '-1rem' }}>
      <Grid item sm={6} className={classes.section}>
        {userSelected && (
          <UserCard
            name={userFullNameOrEmail}
            lastLogin={lastLogin}
            email={userSelected?.email}
            isPrimaryUser={currentUser?.is_primary_contact}
            pictureUrl={userSelected?.profile?.picture_url}
            disabled={!userSelected?.id}
          />
        )}
      </Grid>

      <Grid item sm={6} className={`${classes.section} ${classes.borderLeft}`}>
        <UserDirectory
          setCurrentUser={setCurrentUser}
          isFirmAdmin
          isSuperUser
          handleAddUser={handleAddUser}
          companyList={[]}
          fundList={[]}
          selectedCompany={currentCompany}
          hideBulk
        />
      </Grid>
    </Grid>
  );
};

export default UserManagement;
