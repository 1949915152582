const getPercentile = (arr, initialPercentile) => {
  if (arr.length === 0) {
    return 0;
  }
  let percentile = initialPercentile;
  if (percentile < 0) percentile = 0;
  if (percentile > 100) percentile = 100;
  const sorted = arr.map(i => parseFloat(i)).sort((a, b) => a - b);
  const rank = (percentile / 100) * (sorted.length - 1);
  if (Number.isInteger(rank)) {
    return sorted[rank];
  }
  const rankIntegerPart = Math.floor(rank);
  const rankFractionPart = rank - rankIntegerPart;
  const xRi = sorted[rankIntegerPart];
  return xRi + rankFractionPart * (sorted[rankIntegerPart + 1] - xRi);
};
export const Q25 = arr => getPercentile(arr, 25);
export const Q75 = arr => getPercentile(arr, 75);
export const PERCENTILE = (arr, p) => getPercentile(arr, p);
