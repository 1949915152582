export const CONVERTIBLE_NOTE_LEDGER = 'CNL';
export const NOTE_NAME_ROW = 1;
export const ISSUANCE_DATE_ROW = 2;
export const MATURITY_DATE_ROW = 3;
export const NOTE_PRINCIPLE_AMOUNT_ROW = 4;
export const INTEREST_RATE_ROW = 5;
export const COMPOUNDING_ROW = 6;
export const COMPOUNDING_PERIODS_PER_YEAR_ROW = 7;
export const CONVERSION_DISCOUNT_ROW = 8;
export const CONVERSION_CAP_ROW = 9;
export const TERMS_ANYTIME_PRIOR_ROW = 10;
export const TERMS_AT_QUALIFIED_FINANCING_ROW = 11;
export const TERMS_AT_CHANGE_OF_CONTROL_ROW = 12;
export const TERMS_AT_MATURITY_ROW = 13;
export const EQUITY_CONVERSION_MODEL_ROW = 14;
export const LOAN_VALUE_ROW = 15;
export const CONVERSION_DATE_ROW = 16;
export const INTEREST_AT_MEASUREMENT_DATE_ROW = 17;
export const VALUE_AT_MEASUREMENT_DATE_ROW = 18;
export const VALUE_AT_CONVERSION_ROW = 19;
export const NEXT_ROUND_PREMONEY_ROW = 20;
export const CONVERSION_PRE_MONEY_ROW = 21;
export const PRE_MONEY_SHARE_COUNT_ROW = 22;
export const CONVERSION_PRICE_PER_SHARE_ROW = 23;
export const EXPECTED_SHARES_ROW = 24;
export const CURRENT_OWNERSHIP_ROW = 25;
export const NEXT_ROUND_PREMONEY_ENABLED_ROW = 26;
export const CONVERSION_PRE_MONEY_ENABLED_ROW = 27;
export const TOTAL_SHARES_OUTSTANDING_KEY = 'TOTALS_A5';

export const PREFERRED_STOCK_VALUE = 1;
export const COMMON_STOCK_VALUE = 2;
export const LOAN_VALUE = 3;

export const EQUITY_CONVERSION_MODEL_VALUES = [
  { value: PREFERRED_STOCK_VALUE, label: 'Preferred Stock' },
  { value: COMMON_STOCK_VALUE, label: 'Common Stock' },
  { value: LOAN_VALUE, label: 'Loan' },
];
export const REPLACE_DEFAULT_VALUE = 'Replace the default value.';

export const NOTE_NAME_TITLE = 'Note Name';
export const NOTE_NAME_ALIAS = 'note_name';
export const NOTE_NAME_REQUIRED = 'Create a name for this convertible note.';
export const NOTE_NAME_REPEATED = 'There is other convertible note with the same name.';

export const ISSUANCE_DATE_TITLE = 'Issuance Date';
export const ISSUANCE_DATE_ALIAS = 'issuance_date';

export const MATURITY_DATE_TITLE = 'Maturity Date';
export const MATURITY_DATE_ALIAS = 'maturity_date';
export const MATURITY_DATE_SHOULD_BE_SAME_OR_AFTER_ISSUANCE_DATE = `This value must be equal to or after the ${ISSUANCE_DATE_TITLE}`;

export const NOTE_PRINCIPLE_AMOUNT_TITLE = 'Note Principle Amount';
export const NOTE_PRINCIPLE_AMOUNT_ALIAS = 'note_principle_amount';

export const INTEREST_RATE_TITLE = 'Interest Rate';
export const INTEREST_RATE_ALIAS = 'interest_rate';
export const INTEREST_RATE_MUST_BE_EQUAL_OR_GREATER_THAN_0 = 'This value must be equal to or greater than 0';

export const COMPOUNDING_TITLE = 'Compounding';
export const COMPOUNDING_ALIAS = 'compounding';

export const COMPOUNDING_PERIODS_PER_YEAR_TITLE = 'Compounding Periods/Year';
export const COMPOUNDING_PERIODS_PER_YEAR_ALIAS = 'compounding_periods_per_year';

export const CONVERSION_DISCOUNT_TITLE = 'Conversion Discount';
export const CONVERSION_DISCOUNT_ALIAS = 'conversion_discount';

export const CONVERSION_CAP_TITLE = 'Conversion Cap';
export const CONVERSION_CAP_ALIAS = 'conversion_cap';

export const TERMS_ANYTIME_PRIOR_TITLE = 'Terms Anytime Prior';
export const TERMS_ANYTIME_PRIOR_ALIAS = 'terms_anytime_prior';

export const TERMS_AT_QUALIFIED_FINANCING_TITLE = 'Terms at Qualified Financing';
export const TERMS_AT_QUALIFIED_FINANCING_ALIAS = 'terms_at_qualified_financing';

export const TERMS_AT_CHANGE_OF_CONTROL_TITLE = 'Terms at Change of Control';
export const TERMS_AT_CHANGE_OF_CONTROL_ALIAS = 'terms_at_change_of_control';

export const TERMS_AT_MATURITY_TITLE = 'Terms at Maturity';
export const TERMS_AT_MATURITY_ALIAS = 'terms_at_maturity';

export const EQUITY_CONVERSION_MODEL_TITLE = 'Equity Conversion Model';
export const EQUITY_CONVERSION_MODEL_ALIAS = 'model_as_equity';
export const EQUITY_CONVERSION_MODEL_INVALID = 'Select a valid equity conversion model.';

export const CONVERSION_DATE_TITLE = 'Conversion Date';
export const CONVERSION_DATE_ALIAS = 'conversion_date';
export const CONVERSION_DATE_SHOULD_OCCUR_BEFORE_MATURITY_DATE = `This value should usually occur before the ${MATURITY_DATE_TITLE}`;

export const INTEREST_AT_MEASUREMENT_DATE_TITLE = 'Interest at Measurement Date';
export const INTEREST_AT_MEASUREMENT_DATE_ALIAS = 'interest_at_md';
// If Compounding = True
export const INTEREST_AT_MEASUREMENT_DATE_EXPR1 = measurementDate =>
  `@${NOTE_PRINCIPLE_AMOUNT_ROW}*(1+@${INTEREST_RATE_ROW}/@${COMPOUNDING_PERIODS_PER_YEAR_ROW})^(YEARFRAC(@${ISSUANCE_DATE_ROW},"${measurementDate}",2)*@${COMPOUNDING_PERIODS_PER_YEAR_ROW})`;
// If Compounding = False
export const INTEREST_AT_MEASUREMENT_DATE_EXPR2 = measurementDate =>
  `(@${NOTE_PRINCIPLE_AMOUNT_ROW}*@${INTEREST_RATE_ROW})*(YEARFRAC(@${ISSUANCE_DATE_ROW},"${measurementDate}",2))`;
// In case we update/change this formula, we need to update the formula
// on the backend as well (app.cap_table.models.ConvertibleNote.recalculate_interest_at_measurement_date)
export const INTEREST_AT_MEASUREMENT_DATE_EXPR = md =>
  `=IF(@${EQUITY_CONVERSION_MODEL_ROW}!='3',IF(@${COMPOUNDING_ROW}=='1',${INTEREST_AT_MEASUREMENT_DATE_EXPR1(
    md
  )},${INTEREST_AT_MEASUREMENT_DATE_EXPR2(md)}),0)`;

export const VALUE_AT_MEASUREMENT_DATE_TITLE = 'Value at Measurement Date';
export const VALUE_AT_MEASUREMENT_DATE_ALIAS = 'value_at_md';
export const VALUE_AT_MEASUREMENT_DATE_EXPR = `=@${NOTE_PRINCIPLE_AMOUNT_ROW}+@${INTEREST_AT_MEASUREMENT_DATE_ROW}`;

export const VALUE_AT_CONVERSION_TITLE = 'Value at Conversion';
export const VALUE_AT_CONVERSION_ALIAS = 'value_at_conversion';
export const VALUE_AT_CONVERSION_EXPR = `=@${NOTE_PRINCIPLE_AMOUNT_ROW}*@${INTEREST_RATE_ROW}*YEARFRAC(@${ISSUANCE_DATE_ROW},@${CONVERSION_DATE_ROW},2)+@${NOTE_PRINCIPLE_AMOUNT_ROW}`;

export const NEXT_ROUND_PREMONEY_TITLE = 'Next Round PreMoney';
export const NEXT_ROUND_PREMONEY_ALIAS = 'next_round_premoney';
export const NEXT_ROUND_PREMONEY_EXPR = `=@${CONVERSION_CAP_ROW}/(1-@${CONVERSION_DISCOUNT_ROW})`;
export const NEXT_ROUND_PREMONEY_TOOLTIP
  = 'Based on Conversion PreMoney, Next Round PreMoney must be this amount or greater';

export const CONVERSION_PRE_MONEY_TITLE = 'Conversion Premoney';
export const CONVERSION_PRE_MONEY_ALIAS = 'conversion_premoney';
export const CONVERSION_PRE_MONEY_EXPR = `=MIN((@${NEXT_ROUND_PREMONEY_ROW}-(@${NEXT_ROUND_PREMONEY_ROW}*@${CONVERSION_DISCOUNT_ROW})),@${CONVERSION_CAP_ROW})`;

export const PRE_MONEY_SHARE_COUNT_TITLE = 'Pre Money Share Count';
export const PRE_MONEY_SHARE_COUNT_ALIAS = 'pre_money_share_count';

export const CONVERSION_PRICE_PER_SHARE_TITLE = 'Conversion Price/Share';
export const CONVERSION_PRICE_PER_SHARE_ALIAS = 'conversion_price_per_share';
export const CONVERSION_PRICE_PER_SHARE_EXPR = `=@${CONVERSION_PRE_MONEY_ROW}/@${PRE_MONEY_SHARE_COUNT_ROW}`;

export const EXPECTED_SHARES_TITLE = 'Expected Shares';
export const EXPECTED_SHARES_ALIAS = 'expected_shares';
export const EXPECTED_SHARES_EXPR = `=@${VALUE_AT_CONVERSION_ROW}/@${CONVERSION_PRICE_PER_SHARE_ROW}`;
export const EXPECTED_SHARES_CANT_BE_ZERO = 'The expected shares value can\'t be 0.';

export const CURRENT_OWNERSHIP_ALIAS = 'current_ownership';
export const NEXT_ROUND_PREMONEY_ENABLED_ALIAS = 'next_round_premoney_enabled';
export const CONVERSION_PRE_MONEY_ENABLED_ALIAS = 'conversion_premoney_enabled';

export const ROW_WITH_PAIRS = [NEXT_ROUND_PREMONEY_ALIAS, CONVERSION_PRE_MONEY_ALIAS];

export const LOAN_VALUE_TITLE = 'Loan Value';

export const LOAN_VALUE_ALIAS = 'loan_value';

export const GREATER_THAN_ZERO_IF_PRESENT = [CONVERSION_CAP_ALIAS, PRE_MONEY_SHARE_COUNT_ALIAS];
