import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { notFoundPath } from 'common/config/urls';
import { MEASUREMENT_DATE_TITLE } from 'common/constants/allocations';
import { DATE_ALIAS } from 'common/constants/general';
import { MEASUREMENT_DATE_ACTION_ADD, MEASUREMENT_DATE_ACTION_DELETE } from 'common/constants/measurement-date';
import { MEASUREMENT_DATE_FILTER } from 'common/constants/pageFilters';
import { useStore } from 'common/store';
import { DeleteMeasurementDateDialog } from 'components/DeleteMeasurementDate';
import { MeasurementDateDialog } from 'components/MeasurementDates';
import LayoutContext from 'context/LayoutContext';
import { useGetMeasurementDatesByCompanyId } from 'services/hooks/firm';
import { extractDate, formatMeasurementDates, setQueryString } from 'utillities';

const useCompanyMeasurementDateFilter = () => {
  const [{ companyInfo }] = useStore();

  const { setPageFilters, pageFilters, commonMeasurementDate, setCurrentMeasurementDate } = useContext(LayoutContext);

  const { companySlugParam } = useParams();

  const isSameCompany = (companyInfo?.slug && companySlugParam && companySlugParam === companyInfo.slug) || false;

  const [measurementDates, fetchMeasurementDates] = useGetMeasurementDatesByCompanyId();

  const [selectedMeasurementDate, setSelectedMeasurementDate] = useState();
  const [mdHasBeenDeleted, setMdHasBeenDeleted] = useState(false);
  const [openMeasurementDatesDialog, setOpenMeasurementDatesDialog] = useState(false);
  const [measurementDateAction, setMeasurementDateAction] = useState(MEASUREMENT_DATE_ACTION_ADD);
  const [newMD, setNewMD] = useState(null);
  const [changedMd, setChangedMd] = useState(null);
  const [pageInitialized, setPageInitialized] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const [parsedQuery, setParsedQuery] = useState(queryString.parse(location.search));

  const { date: dateQueryString } = useMemo(() => parsedQuery, [parsedQuery]);

  const matchDateResult = (measurementDateList, md) =>
    measurementDateList.find(item => [item.id.toString(), item.slug, item.date].includes(md?.toString()));

  const matchDate = useCallback(
    md => {
      if (!md || !measurementDates) return undefined;

      const mdToMatch = extractDate({
        dateSlug: md,
      });

      return matchDateResult(measurementDates, mdToMatch);
    },
    [measurementDates]
  );

  const handleDateQueryString = useCallback(
    (dateArg, isNewMD) => {
      if (isEmpty(measurementDates) || mdHasBeenDeleted) {
        return;
      }

      const matchedDate
        = matchDate(dateArg)
        || matchDate(dateQueryString)
        || matchDate(commonMeasurementDate)
        || matchDate(companyInfo.measurement_date_slug);

      if (matchedDate && matchedDate.id !== selectedMeasurementDate?.id) {
        setSelectedMeasurementDate(matchedDate);

        if (matchedDate.slug !== parsedQuery.date) {
          setQueryString({
            history,
            pathname: location.pathname,
            searchParams: queryString.stringify(parsedQuery),
            paramName: DATE_ALIAS,
            paramValue: matchedDate.slug,
            isNewMD,
          });
        }
      } else if (!matchedDate) {
        history.push(notFoundPath);
      }
    },
    [
      mdHasBeenDeleted,
      selectedMeasurementDate,
      commonMeasurementDate,
      measurementDates,
      matchDate,
      dateQueryString,
      companyInfo.measurement_date_slug,
      history,
      location.pathname,
      parsedQuery,
    ]
  );
  // Filter handlers
  const onMeasurementDateChange = useCallback(
    event => {
      const measurementId = event.target.value;

      if (measurementId) {
        const selectedDate = measurementDates.find(date => date.id.toString() === event.target.value.toString());

        delete parsedQuery.version;

        if (selectedDate) {
          setChangedMd(selectedDate.slug);
        }
      }
    },
    [measurementDates, parsedQuery.version]
  );

  const openMeasurementDateDialog = useCallback(
    (action = MEASUREMENT_DATE_ACTION_ADD) => {
      setOpenMeasurementDatesDialog(true);
      setMeasurementDateAction(action);
    },
    [setMeasurementDateAction]
  );

  const getMeasurementDates = useCallback(
    async newMeasurementDate => {
      if (!isEmpty(companyInfo)) {
        await fetchMeasurementDates(companyInfo.id);

        if (!newMeasurementDate) setPageInitialized(false);
      }

      const recentMD = newMeasurementDate?.company_mds?.[0]?.measurement_date || false;

      if (recentMD) {
        setNewMD(recentMD.slug);
      }
    },
    [companyInfo, fetchMeasurementDates]
  );

  const clearFiltersFromUrl = useCallback(() => {
    setCurrentMeasurementDate(null);
    history.push(location.pathname);
    setMdHasBeenDeleted(false);
  }, [history, location.pathname, setCurrentMeasurementDate]);

  // If location.search.date changes from its previous value
  useEffect(() => {
    const tmpParsedQuery = queryString.parse(location.search);
    if (tmpParsedQuery.date !== parsedQuery.date) {
      setParsedQuery(tmpParsedQuery);
    }
  }, [location, parsedQuery]);

  // When the selected measurement date changes
  useEffect(() => {
    setCurrentMeasurementDate(selectedMeasurementDate);
  }, [selectedMeasurementDate, setCurrentMeasurementDate]);

  // When a new measurement date is added
  useEffect(() => {
    if (newMD && !isEmpty(measurementDates) && measurementDates.find(md => md.slug === newMD)) {
      handleDateQueryString(newMD, true);
      setNewMD('');
    }
  }, [newMD, measurementDates, handleDateQueryString]);

  // When the measurement date changes
  useEffect(() => {
    if (changedMd && !isEmpty(measurementDates) && measurementDates.find(md => md.slug === changedMd)) {
      handleDateQueryString(changedMd, false);
      setChangedMd('');
    }
  }, [changedMd, measurementDates, handleDateQueryString]);

  // Get measurement Dates
  useEffect(() => {
    if (!isEmpty(companyInfo) && isSameCompany) {
      getMeasurementDates().catch(() => {});
    }
  }, [companyInfo, companyInfo.measurement_date_id, fetchMeasurementDates, getMeasurementDates, isSameCompany]);

  // When the page loads
  useEffect(() => {
    if (!pageInitialized && !isEmpty(measurementDates) && !mdHasBeenDeleted) {
      handleDateQueryString(undefined, false);
      setPageInitialized(true);
    }
  }, [measurementDates, mdHasBeenDeleted, handleDateQueryString, pageInitialized]);

  // When a measurement date has been deleted
  useEffect(() => {
    if (mdHasBeenDeleted) {
      clearFiltersFromUrl();
    }
  }, [measurementDates, mdHasBeenDeleted, clearFiltersFromUrl]);

  // When the company changes
  useEffect(() => {
    if (!isSameCompany) setPageInitialized(false);
  }, [isSameCompany]);

  const isAddingNewMD = measurementDateAction === MEASUREMENT_DATE_ACTION_ADD;

  const MDDialogue = React.memo(() => (
    <>
      {openMeasurementDatesDialog && isAddingNewMD && (
        <MeasurementDateDialog
          open={openMeasurementDatesDialog}
          defaultCompanies={companyInfo ? { [companyInfo.id]: true } : undefined}
          getMeasurementDates={getMeasurementDates}
          onClose={() => setOpenMeasurementDatesDialog(false)}
          activeCompany={companyInfo}
        />
      )}
      {openMeasurementDatesDialog && !isAddingNewMD && (
        <DeleteMeasurementDateDialog
          open={openMeasurementDatesDialog}
          onClose={() => setOpenMeasurementDatesDialog(false)}
          getMeasurementDates={getMeasurementDates}
          setMdHasBeenDeleted={setMdHasBeenDeleted}
          selectedCmdId={selectedMeasurementDate?.cmd_id}
        />
      )}
    </>
  ));

  useEffect(() => {
    // function that looks through the page filters for a filter with the prop 'id' equal to MEASUREMENT_DATE_FILTER
    // and returns true if it doesn't exist or if it is different from the selectedMeasurementDate.id
    const measurementDateFilterNeedsUpdate = () => {
      const measurementDateFilter = pageFilters?.find(filter => filter.id === MEASUREMENT_DATE_FILTER);
      return (
        !measurementDateFilter
        || measurementDateFilter.selectedValue !== selectedMeasurementDate?.id
        || mdHasBeenDeleted
      );
    };
    if (!isEmpty(measurementDates) && selectedMeasurementDate) {
      if (measurementDateFilterNeedsUpdate()) {
        const updatedFilters = [
          {
            id: MEASUREMENT_DATE_FILTER,
            title: MEASUREMENT_DATE_TITLE,
            options: formatMeasurementDates(measurementDates ?? []),
            selectedValue: selectedMeasurementDate?.id,
            handler: onMeasurementDateChange,
            addOption: () => openMeasurementDateDialog(MEASUREMENT_DATE_ACTION_ADD),
            deleteOption: () => openMeasurementDateDialog(MEASUREMENT_DATE_ACTION_DELETE),
          },
        ];
        setPageFilters(updatedFilters);
      }
    } else {
      setPageFilters(null);
    }
  }, [
    selectedMeasurementDate,
    measurementDates,
    setPageFilters,
    onMeasurementDateChange,
    openMeasurementDateDialog,
    pageFilters,
    mdHasBeenDeleted,
  ]);

  return {
    selectedMeasurementDate,
    MDDialogue,
    openMeasurementDateDialog,
    matchDate,
    measurementDates,
  };
};

export default useCompanyMeasurementDateFilter;
