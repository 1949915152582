import React, { useCallback, useMemo, useState } from 'react';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import LayoutContext from 'context/LayoutContext';
import { Logo } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    height: '100%',
  },
  main: {
    padding: theme.spacing(4),
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

const Minimal = props => {
  const { children } = props;
  const classes = useStyles();
  const [showPageForm, setShowPageForm] = useState(false);

  const togglePageForm = useCallback(() => setShowPageForm(!showPageForm), [showPageForm]);

  const layoutContextProviderValue = useMemo(
    () => ({ showPageForm, setShowPageForm, togglePageForm }),
    [showPageForm, setShowPageForm, togglePageForm]
  );

  return (
    <LayoutContext.Provider value={layoutContextProviderValue}>
      <div className={classes.root}>
        <header className={classes.header}>
          <Link to="/">
            <Logo />
          </Link>
        </header>
        <main className={classes.main}>{children}</main>
      </div>
    </LayoutContext.Provider>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
};

export default Minimal;
