import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useFindSlug = () => {
  const location = useLocation();
  const [currentSlug, setCurrentSlug] = useState('');

  useEffect(() => {
    const splitPath = location.pathname.split('/');
    const lastPath = splitPath[splitPath.length - 1];

    setCurrentSlug(lastPath);
  }, [location]);

  return currentSlug;
};

export default useFindSlug;
