export const onUnloadPage = showDialog => {
  window.onbeforeunload = () => {
    if (showDialog) {
      return true;
    }
    window.onbeforeunload = null;
  };
};

export default onUnloadPage;
