import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { MEASUREMENT_DATE_HEADER } from 'common/constants/documents';
import theme from 'theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  date: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black',
    },
    '& .MuiIconButton-root.Mui-disabled': {
      color: `${theme.palette.primary.main}`,
    },
  },
});

const MeasurementDateHeader = ({ date }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        variant="overline"
        gutterBottom
        style={{
          fontWeight: 400,
          fontSize: '0.625rem',
          color: '#8A9097',
        }}>
        {MEASUREMENT_DATE_HEADER}
      </Typography>
      <DatePicker value={date} format="MM/dd/yyyy" disabled className={classes.date} />
    </div>
  );
};

MeasurementDateHeader.propTypes = {
  date: PropTypes.string,
};

export default MeasurementDateHeader;
