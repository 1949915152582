import { ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT } from 'pages/ValuationsAllocation/common/constants/allocation';
import { getFutureExitScenarioMethod } from 'pages/ValuationsAllocation/util/getFutureExitScenarioMethod';
import { getObjectValue } from 'utillities';
import { GetAllocationFutureExitParams } from './types';

const getAllocationFutureExit = (params: GetAllocationFutureExitParams) => {
  const { approaches, scenario } = params;

  const { future_exit: futureExitId, scenario_type: scenarioType } = getObjectValue(scenario);

  const { valuations_approach_future_exit: futureExitValuationApproach } = getObjectValue(
    approaches?.find(approach => approach?.valuations_approach_future_exit?.id === futureExitId)
  );

  const scenearioTypeIsFutureExit = scenarioType === ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT;

  // Get Future Exit Scenario Method
  const { futureExitScenarioMethod, isFutureExitWithOPM } = getFutureExitScenarioMethod(futureExitValuationApproach);

  return {
    futureExitScenarioMethod,
    futureExitValuationApproach,
    isFutureExitWithOPM,
    scenearioTypeIsFutureExit,
  };
};

export default getAllocationFutureExit;
