import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ANSWER, ANSWERED, IS_UPLOADED, QUESTIONNAIRE } from 'common/constants/process-management';
import { useStore } from 'common/store';
import { TaskContext } from 'context';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import ViewAllItemsButton from './ViewAllItemsButton';

const MobileProgressList = ({ progress, itemsToShow, setItemsToShow, quantityParam, itemList, itemCategory }) => {
  const { isMobile } = useContext(TaskContext);
  const [{ user }] = useStore();
  const viewAllItemsProps = {
    itemsToShow,
    setItemsToShow,
    quantityParam,
    itemList,
    itemCategory,
  };
  const [uploadedProp, setUploadedProp] = useState();

  useEffect(() => {
    if (itemCategory === QUESTIONNAIRE) {
      if (user) {
        setUploadedProp(ANSWER);
      } else {
        setUploadedProp(ANSWERED);
      }
    } else {
      setUploadedProp(IS_UPLOADED);
    }
  }, [itemCategory, uploadedProp, user]);

  return (
    <>
      {isMobile && (
        <Box display="flex" alignItems="center" justifyContent="space-around" marginTop="1rem">
          <CircularProgressWithLabel progress={progress} />
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography style={{ fontWeight: 'bold' }}>{`${itemList.filter(item => item[uploadedProp]).length}/${
              itemList.length
            } ${itemCategory} Completed`}</Typography>
            <ViewAllItemsButton {...viewAllItemsProps} />
          </Box>
        </Box>
      )}
    </>
  );
};

MobileProgressList.propTypes = {
  progress: PropTypes.number.isRequired,
  itemList: PropTypes.array.isRequired,
  itemsToShow: PropTypes.array.isRequired,
  setItemsToShow: PropTypes.func.isRequired,
  quantityParam: PropTypes.number,
  itemCategory: PropTypes.string.isRequired,
};

export default MobileProgressList;
