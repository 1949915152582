import React from 'react';
import PropTypes from 'prop-types';
import SingleColumn from 'components/FeaturedSpreadsheet/components/SingleColumn';
import FastValueViewer from 'components/ScalarSpreadsheet/utilities/FastValueViewer';
import { Cell } from 'components/Spreadsheet/components';

const TotalsColumn = ({ className, data }) => (
  <SingleColumn data={data} className={className} cellRenderer={Cell} valueViewer={FastValueViewer} />
);

TotalsColumn.defaultProps = {
  className: '',
};

TotalsColumn.propTypes = {
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default TotalsColumn;
