/* eslint-disable import/prefer-default-export */
/**
 * @name CompanyMeasurement Date Config
 * @memberof module:common/config
 * @type {Config}
 * @return {Object} Configurations of API
 */

import API from '../config';

const base = `${API.BASE_DEV_HOST}/api/company-measurement-dates`;

export const byMeasurementDate = measurementDateId => `${base}/${measurementDateId}/by_measurement_date/`;
