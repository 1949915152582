import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import { EMPTY_INVESTMENTS, FIRMS_FIRM_SUMMARY_PAGE } from 'common/constants/firms';
import { MessageBox } from 'components';
import { FundSummaryAccordion, SectionHeading } from 'pages/Funds/components';
import { getNumberValue } from 'utillities';
import { FundsSummariesProps } from './types';

const FundsSummaries: FC<FundsSummariesProps> = props => {
  const { funds } = props;

  if (isEmpty(funds)) {
    return <MessageBox fullWidth={false} title={EMPTY_INVESTMENTS} />;
  }

  return (
    <>
      <SectionHeading title={FIRMS_FIRM_SUMMARY_PAGE} />

      {funds?.map(fund => (
        <FundSummaryAccordion fund={fund} key={getNumberValue(fund?.id)} />
      ))}
    </>
  );
};

export default FundsSummaries;
