/**
 * @name Company Hook
 * @memberof module:common/hooks
 * @type {ReactHook}
 * @return {React} Captable Hook
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import { useHistory } from 'react-router-dom';
import { ERROR_403 } from 'common/config/api';
import useResponse from './useResponse';
import { useGetLowerUserPermissions } from './users';
import { CompaniesService, FirmService } from '..';
import { authAction, companiesAction, globalAction } from '../../common/actions';
import { useStore } from '../../common/store';

/**
 * @function
 * @name useGetVersionsByDate
 * @memberof Company Hook
 * @description Hook to fetch company versions dates info from company service
 * @param {integer} companyId the company Id
 * @param {integer} dateId the quarter Id selected
 * @return {array} array for Hook context.
 */
export const useGetAllocationsVersionsByCompanyIdByDateId = () => {
  const { processErrorResponse, errorNotification } = useResponse();

  const fetchData = useCallback(
    async (companyId, dateId) => {
      if (companyId && dateId) {
        try {
          const compaSvc = new CompaniesService();
          const versions = await compaSvc.getAllocationsVersionsByCompanyIdByDateId(companyId, dateId);
          if (versions) return versions;
          errorNotification('An error occurred while loading Versions');
        } catch (error) {
          if (error.status === 403) {
            return ERROR_403;
          }
          processErrorResponse({ error });
        }
      }
    },
    [processErrorResponse, errorNotification]
  );

  return fetchData;
};

/**
 * @function
 * @name useGetFundsByCompanyId
 * @memberof CaptableHook
 * @description Hook to fetch the funds/taglines for a specific captable
 * @param {integer} captableId the captable id
 * @returns {array}
 */
export const useGetFundsByCompanyId = () => {
  const [, dispatch] = useStore();
  const [data, setData] = useState([]);

  const { processErrorResponse, errorNotification } = useResponse();

  const fetchData = useCallback(
    async (companyId, updateGlobalStore = true) => {
      if (companyId) {
        try {
          const compaSvc = new CompaniesService();
          const funds = await compaSvc.getFundsByCompanyId(companyId);
          if (!isEmpty(funds)) {
            setData(funds);
            // Save the fund list in the global store
            if (updateGlobalStore) {
              dispatch(companiesAction.setFundList(funds));
            }
          } else {
            errorNotification('The company does not have Funds');
          }
        } catch (error) {
          const defaultErrorMessage = 'An error occurred while loading Funds';
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'get funds of this company',
          });
        }
      }
    },
    [dispatch, processErrorResponse, errorNotification]
  );

  return [data, fetchData];
};

/**
 * @function
 * @name useGetVersionsByCompanyIdByDateId
 * @memberof Company Hook
 * @description Hook to fetch company versions dates info from company service
 * @param {integer} companyId the company Id
 * @param {integer} dateId the quarter Id selected
 * @return {array} structure for Hook context.
 */
export const useGetVersionsByCompanyIdByDateId = () => {
  const { processErrorResponse, errorNotification } = useResponse();

  const fetchData = useCallback(
    async (companyId, dateId) => {
      const defaultErrorMessage = 'An error occurred while loading Cap Table Versions';

      try {
        if (companyId && dateId) {
          const compaSvc = new CompaniesService();
          const versions = await compaSvc.getVersionsByCompanyIdByDateId(companyId, dateId);
          if (versions) return versions;
          errorNotification(defaultErrorMessage);
        }
      } catch (error) {
        processErrorResponse({
          error,
          defaultErrorMessage,
          action: 'get the Cap Table Versions',
        });
      }
    },
    [processErrorResponse, errorNotification]
  );

  return fetchData;
};

/**
 * @function
 * @name useGetMeasurementDates
 * @memberof Company Hook
 * @description Hook to fetch company measurement Dates from company service
 * @param {INTEGER} companyId the company Id
 * @return {OBJECT} structure for Hook context.
 */
export const useGetMeasurementDates = companyId => {
  const [, dispatch] = useStore();
  const [data, setData] = useState({});

  const { processErrorResponse, errorNotification } = useResponse();

  const fetchData = useCallback(
    async companyId => {
      dispatch(globalAction.showLoadingProgress(true));
      const defaultErrorMessage = 'An error occurred while loading Measurement Dates';

      try {
        const compaSvc = new CompaniesService();
        const measurementsDates = await compaSvc.measurementDates(companyId)(companyId);
        if (measurementsDates) {
          setData(measurementsDates);
        } else {
          errorNotification(defaultErrorMessage);
        }
      } catch (error) {
        processErrorResponse({
          error,
          defaultErrorMessage,
          action: 'get Measurement Dates',
        });
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    },
    [dispatch, processErrorResponse, errorNotification]
  );

  useEffect(() => {
    fetchData(companyId);
  }, [companyId, fetchData]);

  return [{ measurementsDates: data }];
};

export const useUpdateCompanyById = () => {
  const [{ companyInfo }, dispatch] = useStore();
  const history = useHistory();

  const { processErrorResponse, errorNotification, successNotification } = useResponse();

  return async (companyId, companyData, firmSlug) => {
    const defaultErrorMessage = 'An error occurred while updating the Company';
    dispatch(globalAction.showLoadingProgress(true));

    try {
      const service = new CompaniesService();
      const updatedCompany = await service.updateCompanyById(companyId, companyData);
      if (updatedCompany?.id) {
        successNotification(`${updatedCompany.name} successfully updated`);
        history.push({ pathname: `/firms/${firmSlug}/companies/${updatedCompany.slug}/summary` });
        dispatch(companiesAction.setCompanyInfo({ ...companyInfo, ...updatedCompany }));
      } else {
        errorNotification(defaultErrorMessage);
      }
    } catch (error) {
      processErrorResponse({
        error,
        defaultErrorMessage,
        action: 'update the Company',
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };
};

export const useGetCompanyInfoById = () => {
  const [, dispatch] = useStore();
  const { processErrorResponse, errorNotification } = useResponse();

  return async companyId => {
    const defaultErrorMessage = 'an error ocurring while trying to get the company information';
    try {
      const companyService = new CompaniesService();
      const response = await companyService.getCompanyById(companyId);
      if (response) {
        dispatch(companiesAction.setCompanyInfo({ ...response }));
      } else {
        errorNotification(defaultErrorMessage);
      }
    } catch (error) {
      processErrorResponse({
        error,
        defaultErrorMessage,
        action: 'get the company info',
      });
    }
  };
};

export const useGetCapTableList = () => {
  const [, dispatch] = useStore();
  const [capTableList, setCapTableList] = useState([]);

  const { processErrorResponse } = useResponse();

  const getCapTableList = useCallback(
    async (companyId, dateId) => {
      dispatch(globalAction.showLoadingProgress(true));
      try {
        const compaSvc = new CompaniesService();
        const captableList = await compaSvc.getCapTableList(companyId, dateId);
        if (captableList) {
          setCapTableList(captableList);
          dispatch(companiesAction.setCapTableList(captableList));
        }
      } catch (error) {
        processErrorResponse({
          error,
          action: 'get the Cap Table list',
        });
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    },
    [dispatch, processErrorResponse]
  );

  return [capTableList, getCapTableList];
};

export const useGetCompanySummary = () => {
  const [, dispatch] = useStore();
  const [data, setData] = useState();
  const { processErrorResponse } = useResponse();

  const fetchData = async companyId => {
    dispatch(globalAction.showLoadingProgress(true));

    try {
      const companySvc = new CompaniesService();
      const response = await companySvc.getCompanySummary(companyId);
      if (response) {
        setData(response);
      }
    } catch (error) {
      processErrorResponse({
        error,
        config: {
          [ERROR_403]: {
            notification: false,
            callback: () => setData([]),
          },
        },
        action: 'get company summary',
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return [data, fetchData];
};

export const useHasCompanySummary = () => {
  const [, dispatch] = useStore();
  const { processErrorResponse } = useResponse();

  const fetchData = async (companyId, measurementDateId) => {
    dispatch(globalAction.showLoadingProgress(true));

    try {
      const companySvc = new CompaniesService();
      const response = await companySvc.hasCompanySummary(companyId, measurementDateId);
      return response;
    } catch (error) {
      processErrorResponse({
        error,
        config: {
          [ERROR_403]: {
            notification: false,
          },
        },
        action: 'has company summary',
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return fetchData;
};

export const useGetCompanyFiscalYearData = () => {
  const [, dispatch] = useStore();
  const { processErrorResponse } = useResponse();

  const fetchData = useCallback(
    async companyMeasurementDateId => {
      dispatch(globalAction.showLoadingProgress(true));

      try {
        const companySvc = new CompaniesService();
        const response = await companySvc.getFiscalYearData(companyMeasurementDateId);
        if (response) {
          return response;
        }
      } catch (error) {
        const defaultErrorMessage = 'An error occurred while getting Company Fiscal Year Data';
        processErrorResponse({
          error,
          defaultErrorMessage,
          action: 'get the company fiscal year data',
        });
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    },
    [dispatch, processErrorResponse]
  );
  return fetchData;
};

/* eslint-disable react-hooks/exhaustive-deps */
export const useHandleSingleCompanyUser = useThisHook => {
  const [{ firmList, isRestrictedUser, user, companyInfo }, dispatch] = useStore();
  const [extendedCompanyInfo, setExtendedCompanyInfo] = useState();
  const [permissions, fetchPermissions] = useGetLowerUserPermissions();
  const getCompanyInfo = useGetCompanyInfoById();

  const hasNotFirmPermissions = useMemo(() => {
    if (firmList) {
      return firmList.every(firm => firm.user_permissions.length === 0);
    }
    return true;
  }, [firmList]);

  useEffect(() => {
    if (hasNotFirmPermissions && user?.id && !user.is_superuser && permissions?.length === 0 && useThisHook) {
      fetchPermissions(user.id);
    }
  }, [hasNotFirmPermissions, user, permissions, useThisHook]);

  useEffect(() => {
    // to avoid running if this is not a single company user
    if (permissions?.length === 1) {
      const featureObject = permissions[0].feature_object;
      dispatch(authAction.setIsRestrictedUser(true));
      // this gets companyInfo from API and sets it in store
      getCompanyInfo(featureObject.object_id);
    }
  }, [permissions]);

  useEffect(() => {
    if (isRestrictedUser && companyInfo?.id && isUndefined(extendedCompanyInfo)) {
      const getActiveCompanyExtendedInfo = async () => {
        const firmService = new FirmService();
        const response = await firmService.getFirmCompanyExtendedInfo(
          companyInfo.firm_id || companyInfo.firm,
          companyInfo.id
        );
        setExtendedCompanyInfo(response);
      };
      getActiveCompanyExtendedInfo();
    }
  }, [isRestrictedUser, companyInfo]);

  // this allows access to MDs and more for single company users
  useEffect(() => {
    if (!isEmpty(extendedCompanyInfo)) {
      // don't need to delete since we're not using firmList in this flow
      const requiredProperties = {
        id: companyInfo.id,
        slug: companyInfo.slug,
        name: companyInfo.name,
        ...extendedCompanyInfo,
      };
      dispatch(companiesAction.setCompanyInfo({ ...requiredProperties }));
    }
  }, [extendedCompanyInfo]);
};
