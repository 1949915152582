/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-tabs */
import React, { useState } from 'react';
import { Button, Link, Paper, TextField, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import EmailValidator from 'email-validator';
import { isNull, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { ERROR_401 } from 'common/config/api';
import * as messages from 'common/constants/messages/validations';
import { emailValidatorBase } from 'common/constants/userSchemaBase';
import { Alert } from 'components';
import { useSendPasswordReset } from 'services/hooks/auth';
import { differentThanValidator, excludeWhiteSpacesValidator } from 'utillities';

validate.validators.excludeWhiteSpaces = excludeWhiteSpacesValidator;
validate.validators.differentThan = differentThanValidator;

const constraints = {
  mail: {
    presence: {
      allowEmpty: false,
      message: messages.IS_REQUIRED,
    },
    length: {
      maximum: 128,
      minimum: 5,
      message: messages.REQUIRE_CHARACTERS(5),
    },
    ...emailValidatorBase,
  },
};

const defaultErrorMessage = messages.FIX_BEFORE_CONTINUE;

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    maxWidth: 600,
    boxSizing: 'border-box',
    padding: theme.spacing(3),

    '& h2': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      fontSize: '1.125rem',
      color: 'black',
    },
    '& .scar-textField': {
      marginBottom: theme.spacing(2),
    },
  },
  subtitle: {
    color: 'rgba(0, 0, 0, 0.75)',
    marginBottom: theme.spacing(1),
  },
  btn: {
    marginTop: theme.spacing(1),
    fontSize: '0.875rem',
  },
  link: {
    marginTop: theme.spacing(1),
    display: 'block',
    cursor: 'pointer',
  },
}));

const ResetPasswordForm = ({ setPasswordRecovery }) => {
  const classes = useStyles();
  const sendPasswordReset = useSendPasswordReset();
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [severity, setSeverity] = useState('error');

  const hasError = field => !!formState.touched[field] && !!formState.errors[field] && !!formState.errors[field].length;

  const handleResetPassword = async event => {
    event.preventDefault();
    setIsAlertVisible(false);

    if (formState.isValid) {
      setIsLoading(true);

      const response = await sendPasswordReset(formState.values.mail);

      setIsLoading(false);
      if (isNull(response)) {
        // status code 204 => null body
        setSeverity('success');
        setErrorMessage(messages.PASSWORD_CHANGE_SEND_MAIL);
        setIsAlertVisible(true);
      } else {
        setIsAlertVisible(true);
        setSeverity('error');
        if (response?.statusCode === ERROR_401) setErrorMessage(response.body.detail);
        setErrorMessage(messages.PASSWORD_RESET_FAILED);
      }
    }
  };

  const handleBackClick = () => {
    setPasswordRecovery(false);
  };

  const emailIsValid = event => {
    const fieldValue = event.target.value;
    const tmpFormState = { ...formState };
    tmpFormState.values.mail = fieldValue;
    setFormState(tmpFormState);
    const { mail } = formState.values;
    return EmailValidator.validate(mail);
  };

  const [sendEmailButtonIsDisabled, setSendEmailButtonIsDisabled] = useState(true);

  const handleOnChange = event => {
    setSendEmailButtonIsDisabled(!emailIsValid(event));
  };

  const handleOnBlur = event => {
    const tmpFormState = { ...formState };
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    const errors = validate({ ...formState.values, [fieldName]: fieldValue }, constraints);
    // Update the values
    tmpFormState.values[fieldName] = fieldValue;

    // Update the touched field
    tmpFormState.touched[fieldName] = true;

    // Update the errors
    tmpFormState.isValid = isUndefined(errors);
    tmpFormState.errors = errors || {};

    setFormState(tmpFormState);

    if (errors && errors.mail.length > 0) {
      setIsAlertVisible(true);
    } else {
      setIsAlertVisible(false);
    }
  };

  return (
    <>
      <Paper className={classes.form}>
        <Typography variant="h2">Reset password</Typography>
        <Typography className={classes.subtitle}>
          Enter the email linked to the account you want to reset the password for
        </Typography>
        <Alert id="alert" isAlertVisible={isAlertVisible} severity={severity}>
          <>{errorMessage}</>
        </Alert>
        <form id="password-reset-form" onSubmit={handleResetPassword}>
          <TextField
            id="login-username-input"
            name="mail"
            label="Email"
            fullWidth
            type="text"
            className="scar-textField"
            error={isAlertVisible && hasError('mail')}
            helperText={isAlertVisible && hasError('mail') ? formState.errors.mail[0] : null}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          <Button
            id="submit-action-btn"
            variant="contained"
            color="secondary"
            fullWidth
            className={classes.btn}
            type="submit"
            onClick={handleResetPassword}
            disabled={sendEmailButtonIsDisabled}
            data-testid="send_mail_button">
            {isLoading ? <CircularProgress size={28} color="inherit" /> : 'Email me a recovery link'}
          </Button>
        </form>
      </Paper>
      <Link id="login-link" onClick={handleBackClick} variant="h5" className={classes.link}>
        LOG IN
      </Link>
    </>
  );
};

ResetPasswordForm.propTypes = {
  setPasswordRecovery: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
