import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import {
  COMP_GROUPS_BY_FIRM_DEFAULT_ERROR_MESSAGE,
  COMP_GROUPS_BY_FIRM_QUERY_KEY,
} from 'common/constants/services/valuations';
import { ValuationService } from 'services';
import { getArrayValue, throwError } from 'utillities';
import {
  GetCompGroupsByFirmHook,
  GetCompGroupsByFirmParams,
  GetCompGroupsByFirmResponse,
  UseGetCompGroupsByFirm,
} from './types';

const getCompGroupsByFirm = async (params: GetCompGroupsByFirmParams) => {
  const { firmId } = params;

  if (isNil(firmId)) return null;

  const valuationService = new ValuationService();

  try {
    const compGroups = (await valuationService.getFirmCompGroups(firmId)) as GetCompGroupsByFirmResponse;

    if (!compGroups) return throwError(COMP_GROUPS_BY_FIRM_DEFAULT_ERROR_MESSAGE);

    return getArrayValue(compGroups?.results);
  } catch (error) {
    return throwError(COMP_GROUPS_BY_FIRM_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetCompGroupsByFirm: UseGetCompGroupsByFirm = params => {
  const { firm, shouldQueryAutomatically = true } = params;

  const firmId = firm?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<GetCompGroupsByFirmHook['data'], Error>({
    enabled: !isNil(firmId) && shouldQueryAutomatically,
    queryFn: () => getCompGroupsByFirm({ firmId }),
    queryKey: [COMP_GROUPS_BY_FIRM_QUERY_KEY, firmId],
    refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetCompGroupsByFirm;
