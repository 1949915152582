/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { LedgerTable } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import AllocationContext from 'context/AllocationContext';
import LayoutContext from 'context/LayoutContext';
import customValidations from 'pages/Allocation/allocations/components/current-value-ledger/components/approach-table/utils/customValidations';
import linkedCellUpdates from 'pages/Allocation/allocations/components/current-value-ledger/components/approach-table/utils/linkedCellUpdates';
import usePrevious from 'services/hooks/usePrevious';
import { filterTableCells, getLedgerKey, getRowKey } from 'utillities';
import { colConfig } from './data';
import { cellsParser, reverseCellsParser } from './utils';
import EmptyValuationMessage from '../EmptyValuationMessage';

const initialData = [
  {
    approachType: null,
    approachWeighting: null,
    equityValue: null,
    weightedEquityValue: null,
  },
];

const ApproachTable = ({
  cell,
  setDialogData,
  setIsAlertVisible,
  doFullValidation,
  setIsValid,
  updateValidationStatus,
  isDisabled,
  sharedCells,
  setSharedCells,
  tableId,
  setFinalValue,
  currency,
}) => {
  const { cells } = useContext(FeaturedSpreadsheetContext);
  const { approachList } = useContext(AllocationContext);
  const { financialExchangeRate } = useContext(LayoutContext);

  const [rows, setRows] = useState();
  const [isApproachesTableEmpty, setIsApproachesTableEmpty] = useState(false);

  const nonCalibrationApproaches = useMemo(
    () => approachList?.filter(approach => !approach.is_calibration),
    [approachList]
  );
  const calibrationApproachesIds = useMemo(
    () => approachList?.filter(approach => approach.is_calibration).map(approach => approach.id),
    [approachList]
  );

  // Filter local cells
  const tableCells = useMemo(() => {
    if (!isEmpty(sharedCells)) {
      return filterTableCells(sharedCells, tableId);
    }
    return {};
  }, [sharedCells]);

  const parseCells = async ledgerData => {
    const parsedCells = await cellsParser(ledgerData, tableId, colConfig);
    setSharedCells(state => ({ ...state, ...parsedCells }));
  };

  const getApproachWeighting = approachRow => {
    const { approachWeighting, approachWeights, approachId, scenarioId } = approachRow;
    if (approachWeighting) return approachWeighting;
    // Check if there is a value that should be considered after a rollover
    const matchingWeight = approachWeights.find(
      weight => weight.valuation_approach_id === approachId && weight.allocation_scenario_id === scenarioId
    )?.weight;
    return matchingWeight ? Number(matchingWeight) : 0;
  };

  // Set initial rows data
  useEffect(() => {
    if (cells) {
      const ledgerKey = getLedgerKey(cell.columnLegend);
      const ledgersData = cells?.[ledgerKey];
      const ledgerData = ledgersData?.[tableId]
        .filter(approach => !calibrationApproachesIds.includes(approach.approachId))
        .map(row => ({
          ...row,
          approachWeighting: getApproachWeighting(row),
        }));
      const tmpData = rows || ledgerData || nonCalibrationApproaches || initialData;

      const data = tmpData.map(row => ({
        ...row,
        equityValue: row.equityValue * financialExchangeRate || 0,
      }));

      setRows(data);
      parseCells(data);

      setIsApproachesTableEmpty(!nonCalibrationApproaches?.length);
    }
  }, [nonCalibrationApproaches]);

  const previousTableCells = usePrevious(tableCells);

  // Update rows on table cells changes
  useEffect(() => {
    const ledgerKey = getLedgerKey(cell.columnLegend);
    const ledgersData = cells?.[ledgerKey];
    const ledgerData = ledgersData?.[tableId] || [];
    if (!isEmpty(tableCells) && !isEqual(tableCells, previousTableCells)) {
      const [tmpRows, finalValue] = reverseCellsParser(ledgerData, tableCells, colConfig, tableId);
      setRows(tmpRows);
      setFinalValue(finalValue);
      setDialogData(state => ({ ...state, [tableId]: tmpRows }));
      setSharedCells(state => ({ ...state, ...tableCells }));
    }
  }, [tableCells]);

  if (isApproachesTableEmpty) {
    return <EmptyValuationMessage />;
  }

  return (
    <>
      <LedgerTable
        colConfig={colConfig}
        rows={rows}
        setRows={setRows}
        tableTerms={{
          tableName: 'Approaches',
          tableSlug: 'approaches',
          columnName: 'Approach',
          pluralColumnName: 'Approaches',
        }}
        setIsAlertVisible={setIsAlertVisible}
        disabled={isDisabled}
        sharedCells={sharedCells}
        cells={tableCells}
        setCells={setSharedCells}
        linkedCellUpdates={linkedCellUpdates}
        data={rows}
        doFullValidation={doFullValidation}
        setIsValid={setIsValid}
        updateValidationStatus={updateValidationStatus}
        showAddRowButton={false}
        showDeleteColumn={false}
        showTotalRow
        getKey={getRowKey}
        tableId={tableId}
        customValidations={customValidations}
        currency={currency}
        inLedger
      />
    </>
  );
};

ApproachTable.propTypes = {
  setDialogData: PropTypes.func,
  setFinalValue: PropTypes.func,
  cell: PropTypes.object,
  isDisabled: PropTypes.bool,
  setIsAlertVisible: PropTypes.func,
  doFullValidation: PropTypes.bool,
  setIsValid: PropTypes.func,
  updateValidationStatus: PropTypes.func,
  sharedCells: PropTypes.object,
  setSharedCells: PropTypes.func,
  tableId: PropTypes.string,
  currency: PropTypes.object,
};

export default ApproachTable;
