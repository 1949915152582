import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import slugify from 'slugify';
import {
  dashboardCompanyInformationUrl,
  dashboardCompanyOverviewUrl,
  dashboardCompanySettingsUrl,
  dashboardCompanySubscriptionUrl,
} from 'dashboard409a/common/config/urls';
import {
  COMPANY_DETAILS_SLUGS,
  LOG_OUT,
  SELECT_A_COMPANY_TO_SWITCH,
  SETTINGS_SLUG,
  SUBSCRIPTION_SLUG,
  VIEW_DETAILS,
} from 'dashboard409a/common/constants';
import { IBottomMenuItems, INavigationItem, ITopMenuItems, Sidebar } from 'dashboard409a/components';
import { RootStateType } from 'dashboard409a/states/store';
import { useFindSlug } from 'dashboard409a/utilities';
import { useLogOutUser } from 'services/hooks/auth';
import { MainContainer } from './components';
import { IDashboardLayout } from './types';
import { ISubMenu } from '../../components/Sidebar/components/TopMenu/types';

const DashboardLayout: FC<IDashboardLayout> = props => {
  const { children } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const history = useHistory();
  const currentSlug = useFindSlug();

  const logOutUser = useLogOutUser();

  const user = useSelector((state: RootStateType) => state.user);

  const userIsStaff = useMemo(() => {
    if (user) {
      return user.is_staff;
    }
    return false;
  }, [user]);

  const { currentCompany, showLoadingProgressBar = false } = useSelector((state: RootStateType) => state.global);

  const bottomItems = useMemo(() => {
    const currentBottomItems = [] as IBottomMenuItems[];

    if (
      user?.standalone_companies_permissions
      && user?.standalone_companies_permissions?.length > 0
      && currentCompany
    ) {
      currentBottomItems.push({
        imageUrl: currentCompany?.icon_url ?? '',
        isLink: true,
        primaryText: currentCompany.name ?? '',
        secondaryText: VIEW_DETAILS,
        slug: dashboardCompanySettingsUrl(currentCompany.slug) ?? '',
        subItems: user?.standalone_companies_permissions
          ?.filter(standaloneCompany => standaloneCompany.id !== (currentCompany.id ?? 0))
          ?.map(standaloneCompany => ({
            handleItemClick: () => {
              history.push({
                pathname: dashboardCompanyOverviewUrl(standaloneCompany.slug),
                search: `?${new URLSearchParams({})}`,
              });
            },
            slug: standaloneCompany.slug,
            title: standaloneCompany.name,
          })),
        subItemsLabel: SELECT_A_COMPANY_TO_SWITCH,
      });

      currentBottomItems.push({
        displayAvatar: true,
        handleButtonClick: () => logOutUser(),
        imageUrl: user.profile?.picture_url ?? '',
        primaryText: `${user.first_name} ${user.last_name}`.trim(),
        secondaryText: LOG_OUT,
        slug: slugify(`${user.first_name} ${user.last_name}`.trim()),
      });
    }

    return currentBottomItems;
  }, [currentCompany, history, logOutUser, user]);

  const topMenuItems = useMemo(() => {
    const subMenuItems: ISubMenu[] = [
      { slug: 'reports', title: 'Reports', url: dashboardCompanyOverviewUrl(currentCompany?.slug ?? '') },
    ];
    if (userIsStaff) {
      subMenuItems.push({
        slug: 'information',
        title: 'Information',
        url: dashboardCompanyInformationUrl(currentCompany?.slug ?? ''),
      });
      subMenuItems.push({
        slug: 'settings',
        title: 'Settings',
        url: dashboardCompanySettingsUrl(currentCompany?.slug ?? ''),
      });
    }
    return [
      {
        slug: 'reports',
        title: 'Reports',
        isParent: true,
        handleClick: () => {
          history.push(dashboardCompanyOverviewUrl(currentCompany?.slug ?? ''));
        },
        subMenu: subMenuItems,
      },
    ] as ITopMenuItems[];
  }, [currentCompany, history, userIsStaff]);

  const settingsMenuItems = useMemo(
    () =>
      [
        {
          handleClick: () => {
            history.push(dashboardCompanyOverviewUrl(currentCompany?.slug ?? ''));
          },
          isParent: true,
          slug: SETTINGS_SLUG,
          title: 'Settings',
          subMenu: [
            { slug: SETTINGS_SLUG, title: 'Settings', url: dashboardCompanySettingsUrl(currentCompany?.slug ?? '') },
            {
              slug: SUBSCRIPTION_SLUG,
              title: 'Subscription',
              url: dashboardCompanySubscriptionUrl(currentCompany?.slug ?? ''),
            },
          ],
        },
      ] as ITopMenuItems[],
    [currentCompany, history]
  );

  const navigationItems = useMemo(() => {
    const currentNavItems = [] as INavigationItem[];

    if (currentSlug) {
      const navMenu = (COMPANY_DETAILS_SLUGS.includes(currentSlug) ? settingsMenuItems : topMenuItems).filter(
        menuItem => menuItem?.subMenu?.some(subItem => subItem.url.includes(`${currentSlug}`))
      )[0]?.subMenu;

      navMenu?.forEach(nav => {
        currentNavItems.push({
          id: nav.slug,
          title: nav.title,
          handleAction: () => history.push(nav.url),
        } as INavigationItem);
      });
    }

    return currentNavItems;
  }, [currentSlug, settingsMenuItems, topMenuItems, history]);

  return (
    <>
      <Sidebar
        isOpen={isSidebarOpen}
        setIsOpen={setIsSidebarOpen}
        topMenuItems={topMenuItems}
        bottomMenuItems={bottomItems}
        logoUrl={currentCompany?.logo_url ?? ''}
      />

      <MainContainer
        currentCompany={currentCompany}
        isLoading={showLoadingProgressBar}
        navigationItems={navigationItems}
        openSidebar={() => setIsSidebarOpen(true)}>
        {children}
      </MainContainer>
    </>
  );
};

export default DashboardLayout;
