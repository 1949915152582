import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

export default makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHead: {
    borderBottom: `2px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#ffff',
  },
  tableHeadLabel: {
    color: theme.palette.primary[800],
    fontWeight: 700,
    marginRight: '0.625rem',
    fontSize: '0.75rem',
  },
  tableUser: {
    color: theme.palette.primary[700],
  },
  sortLabel: {
    display: 'flex',
  },
  sortIcon: {
    width: '10%',
  },
  activeSortIcon: {
    color: `${theme.palette.primary.dark} !important`,
    opacity: 1,
  },
  inactiveSortIcon: {
    opacity: 0.2,
  },
  emptyFilesMsg: {
    textAlign: 'center',
    padding: '0.8em',
    fontSize: '16px',
    color: theme.palette.primary[900],
  },
});
