import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { CustomTextField } from 'components';
import { FormDialog } from 'components/Dialogs';
import useDocumentsContext from 'context/DocumentsContext';
import { useDocuments } from 'services/hooks';
import { useFormValidation } from 'services/hooks/useFormValidation';
import { REFERENCES_DATA, REFERENCES_TO_ADD, REFERENCES_TO_DELETE, UPDATING_DOCUMENT_DATA } from './constants';

const constraints = {
  filename: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 3, message: 'must be at least 3 characters' },
  },
};

const useStyles = makeStyles({
  references: {
    marginTop: '2rem',
  },
});

const EditDocumentDialog = ({ open, onClose, title, formState, onFormChange }) => {
  const classes = useStyles();
  const { company_id, featuresObjects, setCompanyDocuments, isLoading, setIsLoading } = useDocumentsContext();
  const { formValidated, validateForm } = useFormValidation(constraints);
  const [formValues, setFormValues] = useState(formState.values);
  const [originalValues, setOriginalValues] = useState(null);
  const [loadingText, setLoadingText] = useState(null);
  const { getDocuments, updateFile, addDocumentReferencesObject } = useDocuments();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    setOriginalValues(formValues);
    return () => {
      setOriginalValues(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = useMemo(
    () =>
      REFERENCES_DATA.map(reference => {
        const referencedFeatureId = featuresObjects ? featuresObjects[reference.slug] : null;
        const isDisabled = isNull(referencedFeatureId);
        return {
          ...reference,
          objectId: referencedFeatureId,
          isDisabled,
        };
      }),
    [featuresObjects]
  );

  const hasFileNameChanged = useMemo(() => {
    if (formValues && originalValues) {
      return formValues.filename !== originalValues.filename;
    }
    return false;
  }, [formValues, originalValues]);

  const evaluateReference = (itemSource, itemToEvaluate) => {
    const sourceReferenceId = itemSource.reference_object_id || itemSource.objectId;
    const toEvaluateReferenceId = itemToEvaluate.reference_object_id || itemToEvaluate.objectId;
    return toEvaluateReferenceId === sourceReferenceId;
  };

  const hasFileReferencesChanged = useMemo(() => {
    if (formValues && originalValues) {
      const formValuesReferences = formValues.references || [];
      const originalReferences = originalValues.references || [];
      const diffReferences = originalReferences.filter(
        itemSource => !formValuesReferences.some(itemToEvaluate => evaluateReference(itemSource, itemToEvaluate))
      );
      const dataHasChanged = !isEmpty(diffReferences);
      const lengthHasChanged = formValuesReferences.length !== originalReferences.length;
      return dataHasChanged || lengthHasChanged;
    }
    return false;
  }, [formValues, originalValues]);

  const hasAnyChange = useMemo(
    () => hasFileNameChanged || hasFileReferencesChanged,
    [hasFileNameChanged, hasFileReferencesChanged]
  );

  useEffect(() => {
    validateForm(formValues);
  }, [validateForm, formValues]);

  useEffect(() => {
    onFormChange(formValidated);
  }, [onFormChange, formValidated]);

  const handleChange = event => {
    event.persist();
    setFormValues(currentFormValues => ({
      ...currentFormValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleReferenceChange = (__event, data) => {
    setFormValues(currentFormValues => ({
      ...currentFormValues,
      references: data,
    }));
  };

  const getItemsOf = action => {
    const originalReferences = originalValues.references || [];
    const isToAdd = action === REFERENCES_TO_ADD;
    const arraySource = (isToAdd ? formValues.references : originalReferences) || [];
    const arrayToEvaluate = (isToAdd ? originalReferences : formValues.references) || [];
    return arraySource.filter(
      itemSource => !arrayToEvaluate.some(itemToEvaluate => evaluateReference(itemSource, itemToEvaluate))
    );
  };

  const getReferenceCreateList = () => {
    const { document_reference_id } = formValues;
    const referenceToAdd = getItemsOf(REFERENCES_TO_ADD);
    return referenceToAdd.map(reference => ({
      reference_object_id: reference.objectId,
      reference_object_type: reference.type,
      document_reference_id,
    }));
  };

  const getReferenceDeleteList = () => {
    const referenceToDelete = getItemsOf(REFERENCES_TO_DELETE);
    return referenceToDelete.map(item => item.id);
  };

  const getReferencesData = () => ({
    filename: formValues.filename,
    create_list: getReferenceCreateList(),
    delete_list: getReferenceDeleteList(),
  });

  const updateFileName = async showMsg => {
    const { fileId, filename, documentId, folderId: folder_id } = formValues;
    const fileData = { filename, folder_id };
    await updateFile(documentId, fileId, fileData, showMsg);
  };

  const updateReferences = async showMsg => {
    const updatedReferenceData = getReferencesData();
    const { mdId } = formValues;
    // measurement_date_id required for permission in POST endpoint
    const requestData = { ...updatedReferenceData, measurement_date_id: mdId };
    await addDocumentReferencesObject(mdId, requestData, showMsg);
  };

  const refreshDocuments = async () => {
    setCompanyDocuments(await getDocuments(company_id));
  };

  const handleSave = async () => {
    let shouldRefreshDocuments = false;
    if (formState.isValid) {
      setIsLoading(true);
      setLoadingText(UPDATING_DOCUMENT_DATA);
      if (hasFileNameChanged) {
        shouldRefreshDocuments = true;
        await updateFileName(!hasFileReferencesChanged);
      }
      if (hasFileReferencesChanged) {
        shouldRefreshDocuments = true;
        await updateReferences(!hasFileNameChanged);
      }
      if (shouldRefreshDocuments) await refreshDocuments();
      setIsLoading(false);
      setLoadingText(null);
    }
  };

  return (
    <FormDialog
      open={open}
      title={title}
      isValid={formState.isValid && hasAnyChange && !isLoading}
      customButtonLabel="Done"
      onSave={handleSave}
      onClose={onClose}
      isLoading={isLoading}
      loadingText={loadingText}>
      <div>
        <FormControl fullWidth>
          <CustomTextField
            id="filename"
            name="filename"
            type="text"
            label="File name"
            required
            fullWidth
            value={formValues.filename}
            onChange={handleChange}
            formErrors={formState.errors}
            dbErrors={formState.dbErrors}
          />
          <Autocomplete
            id="references"
            name="references"
            multiple
            className={classes.references}
            options={options}
            getOptionDisabled={option => option.isDisabled}
            disableCloseOnSelect
            getOptionLabel={option => option.reference_object_type || option.name}
            getOptionSelected={(option, value) => {
              const valueReferenceId = value.reference_object_id || value.objectId;
              return option.objectId === valueReferenceId;
            }}
            value={formValues.references || []}
            onChange={handleReferenceChange}
            renderOption={(option, state) => (
              <>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={state.selected}
                  disabled={option.isDisabled}
                />
                {option.name}
              </>
            )}
            style={{ width: '100%' }}
            renderInput={params => <TextField {...params} variant="outlined" label="References" />}
          />
        </FormControl>
      </div>
    </FormDialog>
  );
};

EditDocumentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  formState: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
};

export default EditDocumentDialog;
