/* eslint-disable max-len */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isUndefined } from 'lodash';
import parser from 'components/ScalarSpreadsheet/utilities/parser';
import { useTableValidation } from 'services/hooks';
import { convertedColors } from '../constants';

const useWorkbook = spreadsheets => {
  const { validateCells } = useTableValidation();
  const [cells, setCells] = useState();
  const [data, setData] = useState();
  const [workbook, setWorkbook] = useState();
  // this is the active cell in the workbook, not just a single sheet
  const [workbookActiveCell, setWorkbookActiveCell] = useState(null);

  // input is an array of ScalarSpreadsheetData

  const onChange = useCallback(
    (cell, expr, customFn) => {
      const changeList = cell.sheet.afterCellChanged(
        [{ cell, value: expr }],
        cell.sheet.cells,
        cell.sheet.rowConfig,
        cell.sheet.tableData,
        cells
      );

      const changes = changeList.flatMap(({ cell: changeCell, value }) => changeCell.onChange(value, cells));

      if (customFn) {
        customFn(changes);
      }

      const sheets = changes
        .filter(change => change)
        .reduce((sofar, change) => {
          sofar.add(change.sheet);
          return sofar;
        }, new Set());

      const reversedData = [];

      let linkedChanges = [];
      Array.from(sheets).forEach(sheet => {
        const sheetChanges = changeList.map(change => change.cell).filter(c => c.sheet.name === sheet.name);
        const linkedSheetChanges = cell.getLinkedCells();
        const linkedAndSheetChanges = [...sheetChanges, ...linkedSheetChanges];
        linkedChanges = [...linkedChanges, ...linkedSheetChanges];
        sheet.applyChangeConditions(linkedAndSheetChanges, cells, onChange);
        const cellsToValidate = sheetChanges;
        validateCells({
          cellsToValidate: cellsToValidate.filter(cell => isUndefined(cell.hidden) || cell.hidden === false),
          useOriginalCell: true,
          tableData: sheet.tableData,
          customValidations: sheet.customValidations,
          parsedColumns: sheet.cells,
        });

        if (sheet.reverseParser) {
          const reversed = sheet.reverseParse();
          reversedData.push(reversed);
        }
      });

      const allChanges = new Set([...changes, ...linkedChanges]);

      Array.from(allChanges).forEach(change => change?.dispatchEvent());
      return reversedData;
    },
    [cells, validateCells]
  );

  const areCellsValid = useCallback(
    (includeSheetsWithInvalidCells = false, isSaving = false) => {
      let sheetsWithInvalidCells = [];
      const isValid = cell => cell.isValid || isUndefined(cell.isValid);

      spreadsheets
        .filter(({ shouldValidate }) => shouldValidate)
        .forEach(sheet => {
          validateCells({
            cellsToValidate:
              sheet?.cells && Object.values(sheet.cells).filter(cell => !cell.readOnly || cell.validateWhenReadOnly),
            useOriginalCell: true,
            customValidations: sheet.customValidations,
            parsedColumns: sheet.cells,
            tableData: sheet.tableData,
            isSaving,
            // ... other arguments
          });
        });

      const areAllCellsValid = Object.values(cells).every(sheet => Object.values(sheet).every(isValid));

      if (!areAllCellsValid) {
        sheetsWithInvalidCells = Object.values(cells)
          .map(sheet => Object.values(sheet).filter(cell => !isValid(cell)))
          .filter(arr => arr.length);
      }

      if (includeSheetsWithInvalidCells) {
        return { areAllCellsValid, sheetsWithInvalidCells };
      }

      return areAllCellsValid;
    },
    [cells, spreadsheets, validateCells]
  );

  const evaluateSpreadsheets = useCallback(async sheets => {
    const tmpData = {};

    await Promise.all(sheets.map(sheet => sheet.evaluate()));
    sheets.forEach(sheet => {
      tmpData[sheet.name] = sheet;
    });
    const tmpCells = parser(tmpData);
    setData(tmpData);
    setCells(tmpCells);
    // add a setting to sheet to determine if it needs to be reverse parsed after loading
    sheets
      .filter(({ reverseParseAfterLoad }) => reverseParseAfterLoad)
      .forEach(sheet => {
        sheet.reverseParse();
      });
  }, []);

  useEffect(() => {
    const getData = async () => {
      // NEED TO GUARANTEE ALL SPREADSHEETS HAVE UPDATED SCOPE
      await evaluateSpreadsheets(spreadsheets);
      const workbookObj = spreadsheets.reduce((sofar, sheet) => ({ ...sofar, [sheet.name]: sheet }), {
        evaluateSpreadsheets,
      });
      setWorkbook(workbookObj);
    };
    if (spreadsheets?.length && spreadsheets.every(spreadsheet => !!spreadsheet)) {
      getData();
    }
  }, [evaluateSpreadsheets, spreadsheets]);

  const updateWorkbookActiveCell = useCallback(newWorkbookActiveCell => {
    setWorkbookActiveCell(oldWorkBookActiveCell => {
      if (oldWorkBookActiveCell) {
        const providers = Array.from(oldWorkBookActiveCell.providers);
        providers.forEach(p => {
          p.setCellBackgroundColor(undefined);
          p.dispatchEvent();
        });
      }
      return newWorkbookActiveCell;
    });
  }, []);

  const workbookContextValue = useMemo(
    () => ({
      setWorkbookActiveCell,
      updateWorkbookActiveCell,
      workbookActiveCell,
    }),
    [workbookActiveCell, updateWorkbookActiveCell]
  );

  // when the workbookActiveCell changes,
  useEffect(() => {
    if (workbookActiveCell) {
      const providers = Array.from(workbookActiveCell.providers);
      providers.forEach((p, i) => {
        p.setCellBackgroundColor(convertedColors[i]);
        p.dispatchEvent();
      });
    }
  }, [workbookActiveCell]);

  return {
    areCellsValid,
    cells,
    data,
    evaluateSpreadsheets,
    onChange,
    setCells,
    setData,
    workbook,
    workbookContextValue,
  };
};

export default useWorkbook;
