import React, { useEffect, useMemo, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { uniq } from 'lodash';
import PropTypes from 'prop-types';
import { useStore } from 'common/store';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.8),
    textTransform: 'uppercase',
    fontWeight: 'bold',
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

const FundsChipContainer = ({ columns, handleColumnChange, reset, theme }) => {
  const [chipData, setChipData] = useState([]);
  const [selectedFundIds, setSelectedFundIds] = useState([]);
  const [{ captableInfo, fundList, fundInfo }] = useStore();
  const classes = useStyles();

  const funds = useMemo(() => {
    if (columns && captableInfo && captableInfo.fund_ownership) {
      return uniq(columns.filter(column => !column.is_deleted).map(col => col.fund));
    }
  }, [captableInfo, columns]);

  useEffect(() => {
    if (columns && fundList && captableInfo && captableInfo.fund_ownership) {
      setChipData(fundList.filter(column => funds.includes(column.id)));
    } else if (fundInfo) {
      setChipData([fundInfo]);
    }
  }, [captableInfo, columns, fundList, funds, fundInfo]);

  useEffect(() => {
    if (columns) {
      handleColumnChange(columns.filter(column => !column.is_deleted && !selectedFundIds.includes(column.fund)));
    }
  }, [columns, handleColumnChange, selectedFundIds]);

  useEffect(() => {
    if (reset) {
      setSelectedFundIds([]);
    }
  }, [reset]);

  const handleClick = selectedChip => () => {
    if (!selectedFundIds.includes(selectedChip.id) && funds.length > selectedFundIds.length + 1) {
      setSelectedFundIds([...selectedFundIds, selectedChip.id]);
    } else if (selectedFundIds.includes(selectedChip.id)) {
      setSelectedFundIds(selectedFundIds.filter(id => id !== selectedChip.id));
    }
  };

  if (Array.isArray(chipData) && !chipData.length) {
    return null;
  }

  return (
    <Paper component="ul" className={classes.root}>
      {chipData.map(data => (
        <li key={data.id}>
          <Chip
            id={data.id}
            label={data.name}
            onClick={handleClick(data)}
            className={classes.chip}
            clickable={true}
            style={
              selectedFundIds.includes(data.id)
                ? {
                  backgroundColor: theme.palette.primary.contrastText,
                  color: theme.palette.primary.main,
                }
                : {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }
            }
          />
        </li>
      ))}
    </Paper>
  );
};

FundsChipContainer.propTypes = {
  columns: PropTypes.array.isRequired,
  handleColumnChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

export default withTheme(FundsChipContainer);
