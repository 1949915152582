import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    tableHead: {
      marginLeft: theme.spacing(2),
      fontWeight: 'bold',
    },
    tablesWithoutBorders: {
      border: 'none',
    },
    chipSpace: {
      marginRight: '8px',
      marginBottom: '8px',
      display: 'inline-block',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

export default useStyles;
