import API from '../config';

const authBase = `${API.BASE_DEV_HOST}/auth`;
const totpBase = `${API.BASE_DEV_HOST}/api/totp`;
const GMauthBase = `${API.BASE_DEV_HOST}/api/oauth2`;

export const getAccessTokens = `${authBase}/jwt/create`;
export const refreshAccessToken = `${authBase}/jwt/refresh`;
export const verifyAccessToken = `${authBase}/jwt/verify`;
export const sendAccessToken = `${GMauthBase}/social/`;
export const createUser = `${authBase}/users/`;
export const getUserInfo = `${authBase}/users/me/`;
export const updateUserProfileInfo = `${authBase}/users/me/`;
export const activateUser = `${authBase}/users/activation/`;
export const resendActivationEmail = `${authBase}/users/resend_activation/`;
export const resetPassword = `${authBase}/users/reset_password/`;
export const passwordChange = `${authBase}/users/reset_password_confirm/`;
export const getTOTP = `${totpBase}/get/`;
export const createTOTP = `${totpBase}/create/`;
export const verifyOTP = token => `${totpBase}/verify/${token}/`;
export const getBackupCodes = `${totpBase}/static/get/`;
export const createBackupCodes = `${totpBase}/static/create/`;
export const updateTOTP = `${totpBase}/update/`;
export const deleteTOTP = `${totpBase}/delete/`;
export const verifyBackupCode = code => `${totpBase}/static/verify/${code}/`;
