import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import {
  ANALYST,
  COMPANY,
  COMPANY_USER,
  COMPANY_USER_VALUE,
  FULL_ACCESS_VIEWER_VALUE,
  FUND,
  FUND_ADMIN,
  FUND_ADMIN_VALUE,
  LIMITED_VIEWER_VALUE,
  USER_ROLES,
} from 'common/constants/user';
import { useStore } from 'common/store';
import CompanyFundSelect from './CompanyFundSelect';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  radioButtonsGroup: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(0.5),
    border: 'none',
    borderRadius: '0.25rem',
    padding: '0.5rem 1rem',
    backgroundColor: theme.palette.primary[100],
    '&:hover': {
      backgroundColor: theme.palette.primary[200],
    },
  },
  buttonSelected: {
    backgroundColor: theme.palette.primary[400],
    color: theme.palette.white,
  },
  boldLabel: {
    fontWeight: 'bold',
    margin: theme.spacing(1, 0),
  },
}));

const constraints = {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 3, maximum: 50, message: 'must be at least 3 and less than 50 characters' },
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 3, maximum: 50, message: 'must be at least 3 and less than 50 characters' },
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
  },
};

const InviteUserLedger = ({ dialogUserData, handleDialogClose, handleAddUserToFirm, isCompanyInvite }) => {
  const [{ companyList, fundList }] = useStore();
  const classes = useStyles();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      firstName: '',
      lastName: '',
      email: '',
    },
    errors: {},
  });
  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  // Set Analyst as default role type
  const [roleType, setRoleType] = useState(USER_ROLES[ANALYST].value);

  const [firmItem, setFirmItem] = useState({
    itemType: null,
    itemId: null,
  });

  const handleCheckboxChange = event => {
    event.persist();
    const { name, checked } = event.target;
    const tmpValues = { ...formState.values, [name]: checked };
    const errors = validate(tmpValues, constraints);

    setFormState(prevFormState => ({
      ...prevFormState,
      values: tmpValues,
      errors,
      isValid: isEmpty(errors),
    }));

    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const handleChange = event => {
    event.persist();
    const { name, value } = event.target;
    const tmpValues = { ...formState.values, [name]: value };
    const errors = validate(tmpValues, constraints);

    setFormState(prevFormState => ({
      ...prevFormState,
      values: tmpValues,
      errors,
      isValid: isEmpty(errors),
    }));

    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const handleRoleToggle = ({ value }) => {
    setFirmItem({ itemType: null, itemId: null });
    setRoleType(value);
  };

  const handleSelect = ({ itemType, itemId }) => {
    if ([COMPANY, FUND].includes(itemType)) {
      setFirmItem({ itemType, itemId });
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    const newUser = { ...formState.values, role_id: roleType };
    const role = {
      id: roleType,
      firmItem,
    };
    handleAddUserToFirm(newUser, role);
  };

  if (!dialogUserData.open) return null;

  return (
    <form onSubmit={handleSubmit} id="invite-user">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel component="legend" classes={{ root: classes.boldLabel }}>
            First Name
          </FormLabel>
          <TextField
            autoFocus
            fullWidth
            placeholder="John"
            name="firstName"
            variant="outlined"
            values={formState.values.firstName || ''}
            onChange={handleChange}
            error={touched.firstName && !!formState.errors?.firstName}
            helperText={touched.firstName && formState.errors?.firstName && formState.errors.firstName[0]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel component="legend" classes={{ root: classes.boldLabel }}>
            Last Name
          </FormLabel>
          <TextField
            fullWidth
            placeholder="Doe"
            name="lastName"
            variant="outlined"
            values={formState.values.lastName || ''}
            onChange={handleChange}
            error={touched.lastName && !!formState.errors?.lastName}
            helperText={touched.lastName && formState.errors?.lastName && formState.errors.lastName[0]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel component="legend" classes={{ root: classes.boldLabel }}>
            Email
          </FormLabel>
          <TextField
            fullWidth
            placeholder="john@doe.com"
            name="email"
            variant="outlined"
            value={formState.email}
            onChange={handleChange}
            error={touched.email && !!formState.errors?.email}
            helperText={touched.email && formState.errors?.email && formState.errors.email[0]}
          />
        </Grid>
        {isCompanyInvite && (
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.isPrimaryContact}
                    onChange={handleCheckboxChange}
                    name="isPrimaryContact"
                    color="primary"
                  />
                }
                label="Primary Contact"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.sendActivationEmail}
                    onChange={handleCheckboxChange}
                    name="sendActivationEmail"
                    color="primary"
                  />
                }
                label="Send Activation Email"
              />
            </Grid>
          </>
        )}
        {!isCompanyInvite && (
          <Grid item xs={12}>
            <FormLabel component="legend" classes={{ root: classes.boldLabel }}>
              Define Role
            </FormLabel>
            <FormGroup row>
              <div className={classes.radioButtonsGroup}>
                {Object.values(USER_ROLES).map((r, index) => (
                  <button
                    type="button"
                    key={r.value}
                    className={`${classes.button} ${roleType === Number(r.value) ? classes.buttonSelected : ''}`}
                    onClick={() => handleRoleToggle({ value: r.value })}>
                    {![FULL_ACCESS_VIEWER_VALUE, LIMITED_VIEWER_VALUE].includes(index)
                      ? r.label.split(' ')[0]
                      : r.label}
                  </button>
                ))}
              </div>
            </FormGroup>
          </Grid>
        )}
        <Grid item xs={12}>
          {[COMPANY_USER_VALUE, FUND_ADMIN_VALUE].includes(roleType) && !isCompanyInvite && (
            <FormLabel component="legend" classes={{ root: classes.boldLabel }}>
              {`Select ${roleType === COMPANY_USER_VALUE ? COMPANY : FUND}`}
            </FormLabel>
          )}
          {roleType === FUND_ADMIN_VALUE && (
            <CompanyFundSelect
              value={firmItem.itemId}
              handleSelect={handleSelect}
              itemType={FUND}
              itemList={
                fundList?.map(fund => ({
                  id: fund.id,
                  name: fund.name,
                })) || []
              }
            />
          )}
          {roleType === COMPANY_USER_VALUE && (
            <CompanyFundSelect
              value={firmItem.itemId}
              handleSelect={handleSelect}
              itemType={COMPANY}
              itemList={
                companyList?.map(company => ({
                  id: company.company_id,
                  name: company.company_name,
                })) || []
              }
            />
          )}
        </Grid>
      </Grid>
      <div className={classes.buttonGroup}>
        <Button variant="outlined" color="primary" style={{ marginRight: '1rem' }} onClick={() => handleDialogClose()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          // Disable the submit button if the form contains errors or if a fund/company cannot/has not been selected
          disabled={
            !formState.isValid
            || (roleType === USER_ROLES[COMPANY_USER].value && (companyList?.length === 0 || !firmItem.itemId))
            || (roleType === USER_ROLES[FUND_ADMIN].value && (fundList?.length === 0 || !firmItem.itemId))
          }
          type="submit">
          Add User
        </Button>
      </div>
    </form>
  );
};

InviteUserLedger.propTypes = {
  dialogUserData: PropTypes.shape({
    type: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
  }),
  handleDialogClose: PropTypes.func.isRequired,
  handleAddUserToFirm: PropTypes.func.isRequired,
  isCompanyInvite: PropTypes.bool,
};

export default InviteUserLedger;
