import React, { useContext } from 'react';
import {
  COMPANIES_ARE_NOT_AVAILABLE,
  CONTACT_SCALAR_FOR_VALUATIONS,
  CREATE_COMPANY_FIRST,
  EMPTY_COMPANIES,
} from 'common/constants/firms';
import { MessageBox } from 'components';
import { LayoutContext } from 'context';
import AddCompanyButton from './AddCompanyButton';

const NoCompanyMessage = () => {
  const { viewOnlyUser } = useContext(LayoutContext);
  const title = !viewOnlyUser ? EMPTY_COMPANIES : COMPANIES_ARE_NOT_AVAILABLE;
  const tagline = !viewOnlyUser ? CREATE_COMPANY_FIRST : CONTACT_SCALAR_FOR_VALUATIONS;
  const action = !viewOnlyUser ? <AddCompanyButton /> : null;
  return <MessageBox title={title} tagline={tagline} fullWidth={false} action={action} />;
};

export default NoCompanyMessage;
