import { Cell, Cells, ParserParams, Row } from 'common/types/scalarSpreadsheet';
import { getObjectValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { ValuesPerShareColumn } from './createColumns';

const DEFAULT_ALLOW_NEGATIVE_VALUE = false;

export interface CellParserParams {
  alphabet: string[];
  colIndex: number;
  column: ValuesPerShareColumn;
  row: Row;
  rowIndex: number;
}

const cellParser = (params: CellParserParams) => {
  const { alphabet, colIndex, column, row, rowIndex } = params;

  const rowNumber = rowIndex + 1;
  const columnLegend = alphabet[colIndex];
  const key = columnLegend + rowNumber;

  const { alias, tooltipMessages = [] } = row;
  const { expr = '', format = null, gridType = 'string' } = getObjectValue(row);

  const { value = '' } = getObjectValue(column[alias]);

  return {
    [key]: {
      ...row,
      allowNegativeValue: DEFAULT_ALLOW_NEGATIVE_VALUE,
      columnLegend,
      expr,
      format,
      gridType,
      key,
      tooltipMessages,
      value,
    } as Cell,
  };
};

const customParser = (params: ParserParams<ValuesPerShareColumn>) => {
  const { columns, rowConfig } = params;

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];

  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      cells = {
        ...cells,
        ...cellParser({
          alphabet,
          colIndex,
          column,
          row,
          rowIndex,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
