import {
  SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_NAME,
  SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_NAME_PLURAL,
  SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TABLE,
  SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TITLE,
} from 'pages/Funds/common/constants/scheduleOfInvestments/scheduleOfInvestments';

export const SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TABLE_TERMS = (companySlug: string) =>
  Object.freeze({
    columnName: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_NAME,
    pluralColumnName: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_NAME_PLURAL,
    tableName: `${SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TITLE}_${companySlug}`,
    tableSlug: `${SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TABLE}_${companySlug}`,
  });
