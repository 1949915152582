import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';
import { isEmpty, isEqual, isEqualWith, isFunction, mean, orderBy, uniq } from 'lodash';
import PropTypes from 'prop-types';
import { captableAction, firmsAction } from 'common/actions';
import { ERROR_403 } from 'common/config/api';
import { FUND_OWNERSHIP_REFERENCE_TITLE, FUND_OWNERSHIP_REFERENCE_TYPE } from 'common/constants/documents';
import * as FOC from 'common/constants/fund-ownership/fundOwnership';
import { FUND_OWNERSHIP_LOCKED_MESSAGE } from 'common/constants/fund-ownership/fundOwnership';
import { FUND_OWNERSHIP_PAGE_KEY } from 'common/constants/notes';
import { UNISSUED_OPTIONS } from 'common/constants/securityTypes';
import { FIRM_PERMISSION_NAME, FUND_PERMISSION_NAME } from 'common/constants/sidebar';
import { useFormat } from 'common/hooks';
import { useStore } from 'common/store';
import { Alert, AllocationFinalMessage, EmptyTableMessage, MessageBox, Widgets } from 'components';
import { ExtendedFabButton } from 'components/Buttons';
import { ConfirmationDialog } from 'components/Dialogs';
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_SYMBOL } from 'components/FeaturedSpreadsheet/constants';
import { FloatingButtonGroup } from 'components/FloatingButtonGroup';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import DialogContext from 'context/DialogContext';
import FundOwnershipContext from 'context/FundOwnershipContext';
import LayoutContext from 'context/LayoutContext';
import { useDocuments, useKeyEventForFabButton, useReadFieldAttributes } from 'services/hooks';
import { useUpdateCaptableFundOwnership } from 'services/hooks/captable';
import { useNotes } from 'services/hooks/notes';
import { FUND_OWNERSHIP_PAGE } from 'services/hooks/useReadFieldAttributes/constants';
import { gridShortDate, toString } from 'utillities';
import { getLastColumn } from 'utillities/alphabet-utilities';
import { ConfirmationSaveFinal, FundsChipContainer } from './components';
import { rowConfig } from './data';
import useFundOwnership from './useFundOwnership';
import { dividendsPerShare, getReferences, reverseParser } from './utilities';
import WorkbookContext from '../../../components/ScalarSpreadsheet/utilities/WorkbookContext';
import { EmptyCapTableMessage, ProformaCaptableDialog } from '../cap-table/components';

const useStyles = makeStyles(() => ({
  spreadsheet: {
    '& td.row-label': {
      minWidth: '380px',
    },
  },
  emptyTableSecondaryAction: {
    marginTop: 14,
  },
}));

const initialTableData = {
  capTable: null,
  allocation: null,
  fundOwnership: null,
  calculations: {},
};

const tableTerms = {
  tableName: 'Fund Ownership',
  tableSlug: 'fund-ownership',
  columnName: 'Ownership',
  pluralColumnName: 'Ownerships',
};

const customizerSkipFunction = (objValue, othValue) => {
  if (isFunction(objValue) && isFunction(othValue)) {
    return true;
  }
};

const FundOwnership = props => {
  const classes = useStyles();
  const { saveAsNewProforma, selectedMeasurementDate } = props;
  const { updatePageActions, viewOnlyUser } = useContext(LayoutContext);
  const { showDialog } = useContext(DialogContext);
  const [
    {
      companyInfo,
      captableInfo,
      allocationInfo,
      isShowLoadingProgress,
      fundList,
      otherPermissions,
      fundInfo,
      user,
      firmId,
      selectedUserPermissions,
    },
    dispatch,
  ] = useStore();

  const initialFormValues = useMemo(
    () => ({
      isValid: true,
      name: `${captableInfo?.name} Proforma`,
      values: {},
      errors: {},
    }),
    [captableInfo]
  );

  const [openProformaDialog, setOpenProformaDialog] = useState(false);
  const [formState, setFormState] = useState(initialFormValues);
  const [tableData, setTableData] = useState(initialTableData);
  const [columns, setColumns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState();
  const [totalsCells, setTotalsCells] = useState(); // Formatted to spreadsheet (B1, B2, etc)
  const [customConfig, setCustomConfig] = useState([]);
  const [isDisabled, setIsDisabled] = useState();
  const [isAllocationFinal, setIsAllocationFinal] = useState();
  const [resetColumns, setResetColumns] = useState(false);
  const [referencedValues, setReferencedValues] = useState({});
  const [finalAllocations, setFinalAllocations] = useState();
  const [captableId, setCaptableId] = useState();
  const [isLoaded, setIsLoaded] = useState(); // When all the requests are finished
  const { filesToSave, setFilesToSave, addReferenceForExistingDocuments } = useDocuments();
  const { notes, setNotes, notesHasChanged, saveNotes, onAddNote, onUpdateNotes, onDeleteNote } = useNotes();
  const [dividends, setDividends] = useState({});
  const fieldAttributes = useReadFieldAttributes(FUND_OWNERSHIP_PAGE);
  const fundOwnershipDetailAttrs = fieldAttributes.get('fundownershipdetail');
  const fundOwnershipAttrs = fieldAttributes.get('fundownership');
  const [sheets, setSheets] = useState();
  const [updatedColumns, setUpdatedColumns] = useState();
  const FIXED_DECIMAL_PLACES = 2;
  const [isDisplayingRowNumbers, setIsDisplayingRowNumbers] = useState(false);

  // custom hooks
  const [, updateFundOwnershipData] = useUpdateCaptableFundOwnership();

  const [format, formatDispatch] = useFormat({
    page: 'captable',
    units: `${DEFAULT_CURRENCY} ${DEFAULT_CURRENCY_SYMBOL}`,
  });

  const {
    onChange: onCellsChanged,
    cells,
    setCells,
    workbook,
    areCellsValid,
    workbookContextValue,
  } = useWorkbook(sheets);

  const getTotalValue = (rowNumber, rowAlias, factor = 1) => {
    const totalColumnLegend = getLastColumn(1);
    const totalCell = cells?.fundOwnership?.[`TOTALS_${totalColumnLegend + rowNumber}`];
    if (totalCell) {
      const decimalPlaces = fundOwnershipAttrs ? fundOwnershipAttrs[rowAlias]?.decimal_places : FIXED_DECIMAL_PLACES;
      const cellValue = Number(totalCell.value) * factor;
      return Number(cellValue.toFixed(decimalPlaces));
    }
    return 0;
  };

  const updatedFundOwnership = useMemo(
    () => ({
      ...tableData.fundOwnership,
      cap_table: tableData?.capTable?.id,
      invested_capital: getTotalValue(FOC.INVESTMENT_CAPITAL_ROW_NUMBER, FOC.INVESTMENT_CAPITAL_ALIAS),
      shares: getTotalValue(FOC.SHARES_ROW_NUMBER, FOC.SHARES_ALIAS),
      shares_converted: getTotalValue(FOC.SHARES_AS_CONVERTED_ROW_NUMBER, FOC.SHARES_AS_CONVERTED_ALIAS),
      diluted_own_percent: getTotalValue(
        FOC.FULLY_DILUTED_OWNERSHIP_ROW_NUMBER,
        FOC.FULLY_DILUTED_OWNERSHIP_ALIAS,
        100
      ),
      loan_value: getTotalValue(FOC.LOAN_VALUE_ROW_NUMBER, FOC.LOAN_VALUE_ALIAS),
      initial_liquidation_pref: getTotalValue(
        FOC.INITIAL_LIQUIDATION_PREFERENCE_ROW_NUMBER,
        FOC.INITIAL_LIQUIDATION_PREFERENCE_ALIAS
      ),
      liquidation_pref_dividends: getTotalValue(
        FOC.LIQUIDATION_PREFERENCE_PLUS_ROW_NUMBER,
        FOC.LIQUIDATION_PREFERENCE_PLUS_ALIAS
      ),
      cash_distribution: getTotalValue(FOC.CASH_DISTRIBUTION_ROW_NUMBER, FOC.CASH_DISTRIBUTION_ALIAS),
      shares_distribution: getTotalValue(FOC.PROCEEDS_FROM_SOLD_SHARES_ROW_NUMBER, FOC.PROCEEDS_FROM_SOLD_SHARES_ALIAS),
      // columns
      fund_ownership_detail: updatedColumns || tableData.fundOwnership?.fund_ownership_detail,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cells, updatedColumns]
  );

  const hasDividends = useMemo(() => {
    const capTableSecurities = captableInfo?.securities;
    if (updatedColumns && capTableSecurities) {
      // Get only the info of the selected securities
      const selectedSecurities = capTableSecurities.filter(security =>
        updatedColumns.some(column => Number(column.security) === Number(security.id))
      );
      // Check if any of the selected security has dividends
      return selectedSecurities.filter(security => security.has_dividends).length > 0;
    }
    return false;
  }, [updatedColumns, captableInfo]);

  const onChange = useCallback(
    (cell, expr, customFn) => {
      const reversedColumns = onCellsChanged(cell, expr, customFn)[0];
      if (visibleColumns?.length && cells?.fundOwnership) {
        setUpdatedColumns([...reversedColumns]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cells]
  );

  const { spreadsheet, deletedColumns, addColumn, deleteColumn } = useFundOwnership({
    updatedFundOwnership,
    updatedColumns,
    visibleColumns,
    customConfig,
    tableData,
    cells,
    tableTerms,
    setColumns,
    columns,
    isDisabled,
    fundOwnershipAttrs,
    captableInfo,
  });

  const addCustomSecurityOwnership = async () => {
    await addColumn(true);
  };

  useEffect(() => {
    if (spreadsheet) {
      setSheets([spreadsheet]);
    }
  }, [spreadsheet]);

  // Check if the user has explicit permission for the firm
  const userFirmPermissions = useMemo(() => {
    if (selectedUserPermissions && firmId) {
      return selectedUserPermissions?.filter(
        p => p.feature_object.object_id === firmId && p.feature_object.object_type === FIRM_PERMISSION_NAME
      );
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, firmId]);

  const isSingleFundUser = useMemo(() => {
    if (otherPermissions) {
      return (
        otherPermissions?.filter(({ feature_object }) => feature_object.object_type === FUND_PERMISSION_NAME).length
          === 1 && isEmpty(userFirmPermissions)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherPermissions]);

  const getInitialData = () => {
    setTableData({
      fundOwnership: { ...(captableInfo.fund_ownership || {}) },
      capTable: captableInfo,
      allocation: allocationInfo || {},
    });

    // Add fund ownership columns
    if (captableInfo.fund_ownership?.fund_ownership_detail) {
      const details = orderBy(captableInfo.fund_ownership.fund_ownership_detail, 'id', 'desc');
      const detailsToSet
        = isSingleFundUser && !isEmpty(fundInfo) ? details.filter(({ fund }) => fund === fundInfo.id) : details;
      setColumns(detailsToSet);
    } else {
      // If there is no fund_ownership_detail add an empty array to hide the skeleton
      setColumns([]);
    }
  };

  // Visible columns based on the last spreadsheet changes
  useEffect(() => {
    if (visibleColumns?.length && cells?.fundOwnership) {
      const reversedColumns = reverseParser({
        cells: cells.fundOwnership,
        columns: visibleColumns,
        fieldAttributes: fundOwnershipDetailAttrs,
        allowEmptyValues: false,
      });
      setUpdatedColumns([...reversedColumns]);
    } else {
      setUpdatedColumns([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cells, visibleColumns]);

  useEffect(() => {
    if ([null, undefined].includes(columns)) {
      return;
    }

    const securities = tableData.capTable?.securities;
    const dividendsById = {};

    columns.forEach(column => {
      const columnData = tableData?.fundOwnership.fund_ownership_detail.find(element => element.id === column?.id);

      if (!columnData) {
        return;
      }

      const security = securities.find(element => element.id === column.security);

      if (!security) {
        return;
      }

      const hasCumulativeDividends = !!security?.has_dividends;
      const hasCompounding = !!security?.has_compounding;
      const { id } = column;
      const purchased = column?.acquisitions?.map(acq => ({ ...acq, date: acq.purchase_date })) ?? [];
      const dividendsAux = dividendsPerShare(
        hasCumulativeDividends,
        purchased,
        hasCompounding,
        security,
        selectedMeasurementDate?.name
      );

      dividendsById[id.toString()] = dividendsAux;
    });

    setDividends(dividendsById);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, selectedMeasurementDate]);

  useEffect(() => {
    const calculations = {};
    const securities = tableData.capTable?.securities;
    if (isEmpty(dividends) || isEmpty(columns)) {
      return;
    }
    columns.forEach(column => {
      const columnData = tableData?.fundOwnership.fund_ownership_detail.find(element => element.id === column.id);

      if (!columnData) {
        return;
      }
      const { id } = column;
      const security = securities.find(element => element.id === column.security);

      if (!security) {
        return;
      }
      const outstandingShares = columnData?.shares_distribution_ledger?.outstanding?.shares;
      if (!dividends[id] || !outstandingShares) {
        return;
      }
      const cumulativeDividends = Math.round(mean(dividends[id]) * outstandingShares);
      const totalSharesOutstanding = Math.round(
        (security?.dividend_payment_type * cumulativeDividends) / security?.issue_price + outstandingShares
      );

      calculations[id] = {
        cumulativeDividends,
        totalSharesOutstanding,
      };
    });

    setTableData(tableData => ({ ...tableData, calculations }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dividends, columns]);

  useEffect(() => {
    if (captableInfo?.id && allocationInfo?.id) {
      setCaptableId(captableInfo.id);

      const verifyAllocationStatus = async () => {
        try {
          const allocations = await captableAction.verifyIfHasFinalAllocation(captableInfo.id);

          setIsAllocationFinal(!isEmpty(allocations));
          setIsDisabled(!isEmpty(allocations) || viewOnlyUser);
          setFinalAllocations(allocations);
          setIsLoaded(true);
        } catch (error) {
          throw new Error(error);
        }
      };

      verifyAllocationStatus();
    }

    return () => {
      setCaptableId(undefined);
      setIsLoaded(undefined);
    };
  }, [captableInfo, allocationInfo, viewOnlyUser]);

  useEffect(() => {
    if (columns) {
      const newVisibleColumns = columns.filter(({ is_deleted }) => !is_deleted);
      if (!isEqual(visibleColumns, newVisibleColumns)) {
        setVisibleColumns(newVisibleColumns);
      }
      const auxConfig = rowConfig(hasDividends, isDisabled);

      if (!isEqualWith(customConfig, auxConfig, customizerSkipFunction)) {
        // only if the rowConfig change
        setCustomConfig(auxConfig);
      }
      if (!isEmpty(captableInfo)) {
        const tmpReferencedValues = getReferences({
          columns: newVisibleColumns,
          funds: fundList,
          securities: captableInfo.securities,
        });

        if (!isEqual(referencedValues, tmpReferencedValues)) {
          setReferencedValues(tmpReferencedValues);
        }
      }
      if (!newVisibleColumns.length) {
        setCells({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, isSingleFundUser, hasDividends, isDisabled]);

  const validSecurities = useMemo(() => {
    if (isEmpty(captableInfo) || isEmpty(captableInfo.securities)) {
      return [];
    }
    return captableInfo.securities.filter(
      security =>
        toString(security.security_type) !== toString(UNISSUED_OPTIONS) && security.convertible_notes.length === 0
    );
  }, [captableInfo]);

  const saveConfirmation = useCallback(
    callback => {
      if (isAllocationFinal) {
        showDialog({
          wrapper: ConfirmationDialog,
          wrapperProps: {
            title: 'Confirmation',
          },
          content: ConfirmationSaveFinal,
          actions: [
            {
              label: 'Cancel',
              variant: 'text',
              type: 'primary',
            },
            {
              label: 'Save',
              variant: 'contained',
              type: 'success',
              callback,
            },
          ],
        });
      }
    },
    [isAllocationFinal, showDialog]
  );

  const getUpdatedFOColumn = foColumn => {
    if (foColumn.custom_security_name) {
      return {
        ...foColumn,
        security: null,
      };
    }
    return foColumn;
  };

  const saveProforma = useCallback(() => {
    if (isDisabled) {
      setIsDisabled(false);
    }
    saveAsNewProforma({
      ...captableInfo,
      fund_ownership: {
        ...updatedFundOwnership,
        fund_ownership_detail: updatedFundOwnership?.fund_ownership_detail?.map(foColumn =>
          getUpdatedFOColumn(foColumn)
        ),
      },
      name: formState.values.name || `${captableInfo.name} Proforma`,
    });
  }, [captableInfo, formState.values.name, isDisabled, saveAsNewProforma, updatedFundOwnership]);

  const saveFundOwnership = useCallback(() => {
    // Save files in widget
    if (!isEmpty(filesToSave)) {
      addReferenceForExistingDocuments(
        selectedMeasurementDate?.id,
        filesToSave,
        captableInfo.fund_ownership.id,
        FUND_OWNERSHIP_REFERENCE_TYPE
      );
    }
    // Save notes in widget
    if (!isEmpty(notes) && notesHasChanged) {
      saveNotes();
    }

    setResetColumns(true);
    if (areCellsValid()) {
      const fundOwnershipInfo = updatedFundOwnership;
      const tmpUpdatedColumns = updatedColumns?.map(foColumn => {
        if (foColumn.emptyColumn) {
          return {
            ...foColumn,
            fund_ownership: foColumn.emptyColumn.fund_ownership,
            id: foColumn.emptyColumn.id,
            is_deleted: foColumn.emptyColumn.is_deleted,
          };
        }
        return getUpdatedFOColumn(foColumn);
      });

      const updatedDeletedcolumns = deletedColumns?.map(deletedColumn => getUpdatedFOColumn(deletedColumn));

      fundOwnershipInfo.fund_ownership_detail = [...tmpUpdatedColumns, ...updatedDeletedcolumns];

      if (isAllocationFinal) {
        saveConfirmation(() => {
          updateFundOwnershipData(captableInfo?.id, fundOwnershipInfo);
        });
      } else {
        /* request to /api/cap-tables/<someId>/fund-ownership */
        updateFundOwnershipData(captableInfo?.id, fundOwnershipInfo);
      }
    }
    setResetColumns(false);
  }, [
    filesToSave,
    notes,
    notesHasChanged,
    areCellsValid,
    addReferenceForExistingDocuments,
    selectedMeasurementDate,
    captableInfo,
    saveNotes,
    updatedFundOwnership,
    updatedColumns,
    deletedColumns,
    isAllocationFinal,
    saveConfirmation,
    updateFundOwnershipData,
  ]);

  const savesAsProformaConfirmation = () => {
    setOpenProformaDialog(true);
  };

  // Set page actions
  useEffect(() => {
    if (!isEmpty(captableInfo)) {
      updatePageActions({
        mainAction: {
          id: 'fund-ownership',
          label: 'Save',
          isActive: isDisabled,
          callback: saveFundOwnership,
          isLock: isAllocationFinal,
        },
        secondaryActions: [
          {
            label: 'Save as New Proforma',
            isActive: captableInfo.is_primary,
            callback: savesAsProformaConfirmation,
          },
        ],
      });
    }
    return () => updatePageActions(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captableInfo, updatedFundOwnership, isDisabled, filesToSave, notes]);

  useEffect(() => {
    const fetchSelectedUserPermissions = async userId => {
      dispatch(await firmsAction.getSelectedUserPermissions(userId));
    };
    if (user) {
      fetchSelectedUserPermissions(user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Get fund ownership info from the global store
  useEffect(() => {
    if (!isEmpty(captableInfo)) {
      getInitialData();

      // Save in a global scope
      window.appGlobal = { ...window.appGlobal, capTable: captableInfo };
    }

    return () => {
      setColumns(undefined);
      if (window.appGlobal?.capTable) delete window.appGlobal.capTable;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captableInfo, companyInfo, allocationInfo]);

  useEffect(() => {
    if (isEmpty(captableInfo)) {
      setCells({});
      setColumns([]);
    }

    if (captableInfo?.securities) {
      setCustomConfig(rowConfig(hasDividends, isDisabled));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captableInfo, companyInfo, hasDividends, isDisabled]);

  useEffect(() => {
    if (columns) {
      const tmpVisibleColumns = columns.filter(({ is_deleted }) => !is_deleted);
      setVisibleColumns(tmpVisibleColumns);
      const auxConfig = rowConfig(hasDividends, isDisabled);
      if (!isEqual(customConfig, auxConfig)) {
        // only if the rowConfig change
        setCustomConfig(auxConfig);
      }
      if (!isEmpty(captableInfo)) {
        const tmpReferencedValues = getReferences({
          columns: tmpVisibleColumns,
          funds: fundList,
          securities: captableInfo.securities,
        });
        setReferencedValues(tmpReferencedValues);
      }
      if (!tmpVisibleColumns.length) {
        setCells({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, isSingleFundUser, isDisabled]);

  useEffect(() => {
    if (columns && visibleColumns) {
      setResetColumns(
        isEqual(
          columns.filter(column => !column.is_deleted),
          visibleColumns
        )
      );
    }
  }, [columns, visibleColumns]);

  useEffect(() => {
    if (cells?.fundOwnership && visibleColumns) {
      const tmpPersistedColumns = reverseParser({
        cells: cells.fundOwnership,
        columns: visibleColumns,
      });
      const allColumns = [...tmpPersistedColumns, ...deletedColumns];
      setColumns(allColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDividends]);

  const dbColumns = useMemo(() => captableInfo?.fund_ownership?.fund_ownership_detail, [captableInfo]);

  const measurementDate = useMemo(
    () => (selectedMeasurementDate ? gridShortDate(selectedMeasurementDate.date) : undefined),
    [selectedMeasurementDate]
  );

  useKeyEventForFabButton(isShowLoadingProgress || isDisabled);

  const fundOwnershipContext = useMemo(
    () => ({
      isDisabled,
      measurementDate,
      validSecurities,
      tableData,
      selectedMeasurementDate,
      fundList,
    }),
    [fundList, isDisabled, measurementDate, selectedMeasurementDate, tableData, validSecurities]
  );

  const renderProformaCaptable = useMemo(
    () => (
      <ProformaCaptableDialog
        editMode={false}
        initialValues={initialFormValues}
        isValid={formState?.isValid || false}
        onClose={() => setOpenProformaDialog(false)}
        onFormChange={updatedFormState => setFormState(updatedFormState)}
        onSave={saveProforma}
        open={openProformaDialog || false}
        tableTerms={{ tableName: 'Cap Table' }}
        title="New Proforma Cap Table"
      />
    ),
    [formState, initialFormValues, openProformaDialog, saveProforma]
  );

  if (!captableId || !isLoaded) {
    return <GridSkeleton />;
  }

  if (isEqual(allocationInfo, ERROR_403)) {
    return (
      <MessageBox
        title={FOC.FORBIDDEN_FUNDOWNERSHIP_INFO}
        tagline={FOC.GET_FUNDOWNERSHIP_INFO_ACCESS}
        fullWidth={false}
      />
    );
  }

  if (!isEmpty(captableInfo) && isEmpty(validSecurities) && isEmpty(visibleColumns)) {
    return (
      <EmptyCapTableMessage
        tableTerms={tableTerms}
        secondaryAction={
          <div>
            <Button
              className={classes.emptyTableSecondaryAction}
              variant="text"
              color="primary"
              onClick={addCustomSecurityOwnership}>
              {FOC.ADD_CUSTOM_SECURITY_OWNERSHIP}
            </Button>
          </div>
        }
      />
    );
  }

  return (
    <FundOwnershipContext.Provider value={fundOwnershipContext}>
      {columns && uniq(columns.map(column => column.fund)).length > 1 && !isDisabled && dbColumns?.length > 1 && (
        <FundsChipContainer
          columns={columns}
          handleColumnChange={setVisibleColumns}
          reset={resetColumns}
          isSingleFundUser={isSingleFundUser}
        />
      )}

      {/* Proforma Cap Table */}
      {renderProformaCaptable}

      {isDisabled && isAllocationFinal && (
        <AllocationFinalMessage
          tableTerms={tableTerms}
          openProformaDialog={openProformaDialog}
          finalAllocations={finalAllocations}
          setIsDisabled={setIsDisabled}
          savesAsProformaConfirmation={savesAsProformaConfirmation}
          variant
          actions={[
            {
              label: 'Duplicate',
              buttonProps: {
                variant: 'contained',
                size: 'small',
              },
              callback: savesAsProformaConfirmation,
            },
          ]}
        />
      )}

      {isAllocationFinal && !isDisabled && <Alert isAlertVisible>{FUND_OWNERSHIP_LOCKED_MESSAGE}</Alert>}

      <WorkbookContext.Provider value={workbookContextValue}>
        <ScalarSpreadsheet
          {...spreadsheet}
          sheet={spreadsheet}
          onChange={onChange}
          data={spreadsheet ? spreadsheet.data : []}
          className={classes.spreadsheet}
          dbColumns={dbColumns}
          totalsCells={totalsCells}
          updateTotalsCells={setTotalsCells}
          updateCells={setCells}
          customDeleteConfirmation={false}
          referencedValues={referencedValues}
          workbook={workbook}
          deleteColumn={deleteColumn}
          format={format}
          formatDispatch={formatDispatch}
          setColumns={setVisibleColumns}
          setIsDisplayingRowNumbers={setIsDisplayingRowNumbers}>
          {isEmpty(visibleColumns) && <EmptyTableMessage tableTerms={tableTerms} />}
        </ScalarSpreadsheet>
      </WorkbookContext.Provider>
      {captableInfo?.is_primary && (
        <Widgets
          documentReferencesProps={{
            referenceType: FUND_OWNERSHIP_REFERENCE_TYPE,
            selectedMeasurementDate,
            currentPage: FUND_OWNERSHIP_REFERENCE_TITLE,
            referencedFeatureId: captableInfo?.fund_ownership?.id,
            filesToSave,
            setFilesToSave,
            isDisplayingRowNumbers,
            isDisabled,
          }}
          notesProps={{
            pageType: FUND_OWNERSHIP_REFERENCE_TYPE,
            pageTypeKey: FUND_OWNERSHIP_PAGE_KEY,
            pageTypeId: captableInfo?.fund_ownership?.id,
            notes,
            setNotes,
            onAddNote,
            onUpdateNotes,
            onDeleteNote,
            isDisabled,
          }}
        />
      )}
      {!isEmpty(captableInfo) && isEmpty(validSecurities) && !isEmpty(visibleColumns) ? (
        <ExtendedFabButton
          id="fund-ownership-add-btn"
          disabled={isShowLoadingProgress || isDisabled}
          label={FOC.ADD_CUSTOM_SECURITY_OWNERSHIP}
          onClick={addCustomSecurityOwnership}
        />
      ) : (
        <FloatingButtonGroup
          id="fund-ownership-fab-group"
          disabled={isShowLoadingProgress || isDisabled}
          mainAction={{
            label: FOC.ADD_FUND_OWNERSHIP,
            onClick: () => addColumn(),
            icon: AddIcon,
          }}
          secondaryActions={[
            {
              key: 1,
              label: FOC.ADD_CUSTOM_SECURITY_OWNERSHIP,
              onClick: addCustomSecurityOwnership,
              icon: AddIcon,
            },
          ]}
        />
      )}
    </FundOwnershipContext.Provider>
  );
};

FundOwnership.propTypes = {
  selectedVersions: PropTypes.number,
  setSelectedTab: PropTypes.func,
  saveAsNewProforma: PropTypes.func,
  selectedMeasurementDate: PropTypes.shape({
    cmd_id: PropTypes.number,
    date: PropTypes.string,
    id: PropTypes.number,
    is_open: PropTypes.bool,
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
};

export default FundOwnership;
