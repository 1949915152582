import React, { FC } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import { useFormat } from 'common/hooks';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import { ValuationMethodsDetail } from 'dashboard409a/components/ValuationMethodsDetail';
import { ValuationMethodsDetailProps } from 'dashboard409a/components/ValuationMethodsDetail/types';
import { IValuationState } from 'dashboard409a/states/features/valuation';
import { RootStateType } from 'dashboard409a/states/store';
import { OpinionSummaryCardHeader } from './components/OpinionSummaryCardHeader';
import { OpinionSummaryCardSubHeader } from './components/OpinionSummaryCardSubHeader';
import { VOSDProps } from './types';
import { EnterpriseValue } from '../EnterpriseValue';
import { EnterpriseValueProps } from '../EnterpriseValue/types';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '2%',
    boxShadow: 'none',
  },
  link: {
    display: 'inline',
    cursor: 'pointer',
  },
  boxValOpinion: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2%',
  },
}));

const ValuationOpinionSummaryData: FC<VOSDProps> = ({ showValMethods, displayTable, shareSpace }) => {
  const classes = useStyles();
  const { loading, currentValuationScalarOpinion } = useSelector<RootStateType, IValuationState>(
    state => state.valuation
  );
  const { currentCompany } = useSelector((state: RootStateType) => state.global);
  const subscriptionIsActive = currentCompany?.is_active;
  const displayExpired = !isUndefined(subscriptionIsActive) && subscriptionIsActive === false;
  const [format, formatDispatch] = useFormat({ units: REGULAR_UNIT });
  const lgColumns = shareSpace ? 6 : 12;

  const valuationMethodsDetailProps: ValuationMethodsDetailProps = {
    data: currentValuationScalarOpinion,
    isLoading: loading,
    format,
    formatDispatch,
  };

  const enterpriseValueProps: EnterpriseValueProps = {
    enterpriseData: currentValuationScalarOpinion,
    isLoading: loading,
    format,
  };

  return (
    <Grid item xs={12} lg={lgColumns}>
      <Card className={classes.root}>
        <CardHeader
          title={<OpinionSummaryCardHeader displayExpired={displayExpired} />}
          subheader={<OpinionSummaryCardSubHeader displayExpired={displayExpired} />}
          disableTypography
        />
        <CardContent>
          <EnterpriseValue {...enterpriseValueProps} />
          <Box className={classes.boxValOpinion}>
            <small />
            <Link
              id="show-valuation-methods-link"
              onClick={() => displayTable('valuationOpinion')}
              className={classes.link}
              variant="body2">
              {`${showValMethods ? 'Hide' : 'Show'} Valuation Methods`}
            </Link>
          </Box>
        </CardContent>
      </Card>
      {showValMethods ? (
        <Card>
          <CardContent>
            <ValuationMethodsDetail {...valuationMethodsDetailProps} />
          </CardContent>
        </Card>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default ValuationOpinionSummaryData;
