import { getPercentileLabel } from 'pages/ValuationsAllocation/util/getPercentileLabel';

const getPercentileOptions = (arrayOfNumbers?: number[]) =>
  arrayOfNumbers?.map((_, index) => {
    const percentileLabel = getPercentileLabel(index);

    return {
      label: percentileLabel,
      value: index + 1,
    };
  });

export default getPercentileOptions;
