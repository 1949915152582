import React, { useCallback, useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import { Dialog } from 'components/Dialogs';
import { getObjectValue } from 'utillities';
import { WACCChipProps } from './types';
import WACCLedger from '../WACCLedger';

const WACCChip = (props: WACCChipProps) => {
  const { dcfWaccSheet } = getObjectValue(props);
  const [showLedger, setShowLedger] = useState(false);
  const [dcfWacc] = useState(dcfWaccSheet);
  const waccCell = dcfWaccSheet.cells.wacc;

  const [chipValue, setChipValue] = useState(waccCell.value);
  const handleClick = () => {
    setShowLedger(true);
  };

  const onClose = () => {
    setShowLedger(false);
  };

  const updateChipValue = useCallback(() => {
    setChipValue(waccCell.value);
  }, [waccCell]);

  useEffect(() => {
    const waccCellKey = waccCell.getFullKey ? waccCell.getFullKey() : '';
    document.addEventListener(waccCellKey, updateChipValue);

    return () => document.removeEventListener(waccCellKey, updateChipValue);
  }, [updateChipValue, waccCell]);

  return (
    <>
      <Chip
        label={`${(Number(chipValue || 0) * 100).toFixed(1)} %`}
        clickable
        color={'primary'}
        size={'small'}
        onClick={handleClick}
        variant="outlined"
      />
      {showLedger && (
        <Dialog onClose={onClose} open={showLedger} hideTitle>
          <WACCLedger closeDialog={onClose} dcfWacc={dcfWacc} />
        </Dialog>
      )}
    </>
  );
};

export default WACCChip;
