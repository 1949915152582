import React from 'react';
import { Box, Checkbox, Tooltip, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PropTypes from 'prop-types';
import theme from 'theme';

const ModalItemCompanyBox = ({
  company,
  includeCheckbox,
  backgroundColor,
  padding,
  handleSelectCompanies,
  selectedCompanies,
}) => {
  const { name: companyName, users_with_company_permissions: usersWithCompanyPermissions } = company;
  const listOfUserNames = usersWithCompanyPermissions?.map(user => user.email).join(', ');

  const handleChange = () => {
    handleSelectCompanies(company);
  };

  return (
    <Box
      border={`1px solid ${theme.palette.gray[300]}`}
      borderRadius={4}
      marginBottom={0.5}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding={padding || 0}
      style={{
        backgroundColor: backgroundColor || 'transparent',
      }}>
      <Box display="flex" alignItems="center">
        {includeCheckbox && <Checkbox onChange={handleChange} checked={selectedCompanies.includes(company)} />}
        <Typography
          style={{
            color: theme.palette.gray[500],
            fontSize: '0.875rem',
            fontWeight: 700,
          }}>
          {companyName}
        </Typography>
      </Box>
      {includeCheckbox && (
        <Tooltip title={listOfUserNames} placement="top">
          <PersonIcon style={{ color: theme.palette.gray[500], fontSize: '1.25rem', marginRight: '0.5rem' }} />
        </Tooltip>
      )}
    </Box>
  );
};

ModalItemCompanyBox.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    users_with_company_permissions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  includeCheckbox: PropTypes.bool,
  backgroundColor: PropTypes.string,
  padding: PropTypes.string,
  handleSelectCompanies: PropTypes.func,
  selectedCompanies: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ModalItemCompanyBox;
