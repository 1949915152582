export const NOTES_TITLE = 'NOTES:';
export const ADD_NOTES_TITLE = 'ADD NOTES:';
export const NOTES_EMPTY = 'Notes are empty';
export const NOTES_ADD_NEW = 'Add note here';
export const NOTES_STATUS = 'Notes';
export const NOTES_ADDED = 'Notes added';
export const SAVE_PAGE_TO_ADD_NOTES = 'Please save the page in order to add notes.';
export const CAP_TABLE_PAGE_VALUE = 'ct';
export const BREAKPOINTS_PAGE_VALUE = 'brkpts';
export const WATERFALL_PAGE_VALUE = 'wtrfl';
export const FUND_OWNERSHIP_PAGE_VALUE = 'fo';
export const FINANCIALS_PAGE_VALUE = 'fi';
export const VALUATIONS_PAGE_VALUE = 'va';
export const ALLOCATIONS_PAGE_VALUE = 'alloc';
export const COMPANY_SUMMARY_PAGE_VALUE = 'cs';
export const FUND_SUMMARY_PAGE_VALUE = 'fs';

export const CAP_TABLE_PAGE_KEY = 'cap_table';
export const FUND_OWNERSHIP_PAGE_KEY = 'fund_ownership';
export const FINANCIALS_PAGE_KEY = 'financial_statement';
export const VALUATIONS_PAGE_KEY = 'valuation_approach';
export const ALLOCATIONS_PAGE_KEY = 'allocation';
export const COMPANY_SUMMARY_PAGE_KEY = 'company_measurement_date';
export const FUND_SUMMARY_PAGE_KEY = 'fund_measurement_date';

export const PAGE_VALUE_TYPES = [
  CAP_TABLE_PAGE_VALUE,
  FUND_OWNERSHIP_PAGE_VALUE,
  FINANCIALS_PAGE_VALUE,
  VALUATIONS_PAGE_VALUE,
  ALLOCATIONS_PAGE_VALUE,
  BREAKPOINTS_PAGE_VALUE,
  COMPANY_SUMMARY_PAGE_VALUE,
  FUND_SUMMARY_PAGE_VALUE,
  WATERFALL_PAGE_VALUE,
];

export const PAGE_VALUE_TYPE_KEYS = [
  CAP_TABLE_PAGE_KEY,
  FUND_OWNERSHIP_PAGE_KEY,
  FINANCIALS_PAGE_KEY,
  VALUATIONS_PAGE_KEY,
  ALLOCATIONS_PAGE_KEY,
  COMPANY_SUMMARY_PAGE_KEY,
  FUND_SUMMARY_PAGE_KEY,
];
