import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    icon: {
      fill: `${theme.palette.white} !important`,
      height: `${theme.spacing(2.25)}px !important`,
      width: `${theme.spacing(2.25)}px !important`,
    },
  })
);

export default useStyles;
