/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { Skeleton } from '@material-ui/lab';
import { ConfirmationDelete, ConfirmationDialog } from 'components/Dialogs';
import { DialogContext } from 'context';
import { NotesContext } from 'context/NotesContext';
import { useGetNotes } from 'services/hooks/notes/';
import NoteList from './NoteList';
import NotesNotAvailable from './NotesNotAvailable';
import { NoteData, NotesProps } from './types';

// @ts-ignore
const WrapperComponent = props => <ConfirmationDialog {...props} title="Delete" />;
const ContentComponent = () => <ConfirmationDelete itemName="note" />;

const Notes: FC<NotesProps> = props => {
  const { pageType, pageTypeKey, pageTypeId, isApproach, notes, setNotes, onAddNote, onUpdateNotes, onDeleteNote }
    = props;
  const { showDialog } = useContext(DialogContext);
  const { data } = useGetNotes({ pageType, pageTypeKey, pageTypeId });

  useEffect(() => {
    setNotes(data ?? []);
  }, [setNotes, data]);

  const onDeleteNoteConfirmationDialog = useCallback(
    (updatedNote: NoteData) => {
      // @ts-ignore
      showDialog({
        wrapper: WrapperComponent,
        content: ContentComponent,
        actions: [
          {
            label: 'Cancel',
            type: 'cancel',
          },
          {
            label: 'Delete',
            variant: 'contained',
            type: 'danger',
            callback: () => onDeleteNote(updatedNote),
          },
        ],
      });
    },
    [onDeleteNote, showDialog]
  );

  const onDeleteNewNote = useCallback(
    (note: NoteData) => {
      if (note.isNew && note.content.length > 0) {
        onDeleteNoteConfirmationDialog(note);
      } else {
        onDeleteNote(note);
      }
    },
    [onDeleteNote, onDeleteNoteConfirmationDialog]
  );

  const notesContextProviderValue = useMemo(
    () => ({
      pageType,
      pageTypeKey,
      pageTypeId,
      onAddNote,
      onUpdateNotes,
      onDeleteNote: onDeleteNoteConfirmationDialog,
      onDeleteNewNote,
    }),
    [pageType, pageTypeKey, pageTypeId, onAddNote, onUpdateNotes, onDeleteNoteConfirmationDialog, onDeleteNewNote]
  );

  if (!isApproach && !pageTypeId) {
    return <Skeleton height="5rem" />;
  }

  return (
    <>
      {pageTypeId ? (
        <NotesContext.Provider value={notesContextProviderValue}>
          <NoteList pageTypeKey={pageTypeKey} pageType={pageType} pageTypeId={pageTypeId} notes={notes} />
        </NotesContext.Provider>
      ) : (
        <NotesNotAvailable />
      )}
    </>
  );
};

export default Notes;
