import React, { useState } from 'react';
import { ClickAwayListener, Grow, makeStyles, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import PropTypes from 'prop-types';
import CompGroupsDialog from 'pages/Valuations/components/CompGroups/components/CompGroupsDialog';
import { PRIVATE_TRANSACTIONS } from 'pages/Valuations/util/constants';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  menuList: {
    '& .MuiListItem-root': {
      textAlign: 'right',
      justifyContent: 'end',
      width: 'fit-content',
    },
    '& .MuiMenuItem-root': {
      marginLeft: 'auto',
      backgroundColor: '#037DE8',
      color: '#FFFFFF',
      margin: '0.8rem 0',
      borderRadius: '2.688rem',
      textTransform: 'uppercase',
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: '#043A6E',
    },
  },
}));

const CompGroupsOptions = ({
  setOpenCompGroupsOptions,
  compGroupsRef,
  openCompGroupsOptions,
  saveCompGroups,
  deleteCompGroup,
  tableData,
  approachType,
}) => {
  const [compGroupsOptionSelected, setCompGroupsOptionSelected] = useState();
  const [openCompGroupsDialog, setOpenCompGroupsDialog] = useState(false);
  const classes = useStyles();
  const handleMenuItemClick = option => {
    setCompGroupsOptionSelected(option);
    setOpenCompGroupsOptions(false);
    setOpenCompGroupsDialog(true);
  };

  const handleClose = () => {
    setOpenCompGroupsOptions(false);
  };

  const compGroupType = approachType === PRIVATE_TRANSACTIONS ? 'Transaction' : 'Public';
  const optionsCompGroups = [
    {
      value: 0,
      title: `Select ${compGroupType} Comps Group`,
    },
  ];

  return (
    <>
      <Popper
        open={openCompGroupsOptions}
        anchorEl={compGroupsRef.current}
        role={undefined}
        transition
        disablePortal
        placement="top-end">
        {({ TransitionProps }) => (
          <>
            <Grow {...TransitionProps}>
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" className={classes.menuList}>
                    {optionsCompGroups.map((option, index) => (
                      <MenuItem
                        id={`comp-groups-options-btn-${index}`}
                        key={`${option.title}-${option.value}}`}
                        className={classes.menuItem}
                        onClick={() => handleMenuItemClick(option)}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          </>
        )}
      </Popper>

      <CompGroupsDialog
        openCompGroupsDialog={openCompGroupsDialog}
        setOpenCompGroupsDialog={setOpenCompGroupsDialog}
        compGroupsOptionSelected={compGroupsOptionSelected}
        saveCompGroups={saveCompGroups}
        deleteCompGroup={deleteCompGroup}
        tableData={tableData}
        approachType={approachType}
      />
    </>
  );
};

CompGroupsOptions.propTypes = {
  setOpenCompGroupsOptions: PropTypes.func,
  compGroupsRef: PropTypes.any,
  openCompGroupsOptions: PropTypes.bool,
  saveCompGroups: PropTypes.func,
  deleteCompGroup: PropTypes.func,
  tableData: PropTypes.object,
  approachType: PropTypes.string,
};

export default CompGroupsOptions;
