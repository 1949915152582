/**
 * @name CaptableTypes ActionTypes
 * @memberof module:common/actions
 * @type {ReduxAction}
 * @return {Object} Global Actions Types
 */
export const GET_CAPTABLE_COMPANY = 'GET_CAPTABLE_COMPANY';
export const SET_CAPTABLE_INFO = 'SET_CAPTABLE_INFO';
export const GET_CAPTABLE_INFO = 'GET_CAPTABLE_INFO';

export const CREATE_CAPTABLE_PROFORMA = 'CREATE_CAPTABLE_PROFORMA';
