import React from 'react';
import { valuationUrl } from 'common/config/urls';
import { Row, Rows } from 'common/types/scalarSpreadsheet';
import { ValueWithLinkViewer } from 'components';
import {
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
  VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
  VALUATIONS_SUMMARY_SPREADSHEET_TABLE,
} from 'pages/Funds/common/constants/valuationsSummary';
import { getExchangeRateTooltip } from 'pages/Funds/utils';
import { generateColumnKey, getArrayValue, getNumberValue, getStringValue } from 'utillities';
import { CreateCompaniesRowsParams, RowConfigParams } from './types';

const createCompaniesRows = (params: CreateCompaniesRowsParams): Rows => {
  const { companies, firmSlug, fundCurrency, queryParams } = params;

  return getArrayValue(
    companies?.map(company => {
      const companyCaptableCurrency = getStringValue(company?.captable_currency?.toString());
      const fundCurrencyValue = getStringValue(fundCurrency?.toString());
      const companyFundAllocationId = getNumberValue(company?.fundAllocationId);
      const companySlug = getStringValue(company?.slug);
      let companyName = getStringValue(company?.name);

      // Display Company Captable Currency if it's different from Fund currency
      if (fundCurrency !== company.captable_currency) companyName = `${companyName} (${companyCaptableCurrency})`;

      // Get Company Summary URL
      const companyUrl = `${valuationUrl(firmSlug, companySlug)}${queryParams}`;
      const exchangeRateTooltip = getExchangeRateTooltip(
        fundCurrencyValue,
        companyCaptableCurrency,
        company.exchangeRateToFundCurrency
      );

      return {
        alias: generateColumnKey({
          id: companyFundAllocationId,
          name: companySlug,
          prefix: VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
        }),
        gridType: 'string',
        readOnly: true,
        value: <ValueWithLinkViewer exchangeRateMessage={exchangeRateTooltip} name={companyName} url={companyUrl} />,
      } as Row;
    })
  );
};

const rowConfig = (params: RowConfigParams): Rows => {
  const { companies, firmInfo, fundCurrency, measurementDate } = params;

  const firmSlug = getStringValue(firmInfo?.slug);
  const queryParams = measurementDate?.slug ? `?date=${measurementDate?.slug}` : '';

  return [
    {
      alias: SHEET_ALIASES_CONSTANTS.VALUATION_SUMMARY_SPREADSHEET_HEADER_TITLE,
      className: `${VALUATIONS_SUMMARY_SPREADSHEET_TABLE}-header division-bottom-only`,
      gridType: 'string',
      largeHeader: true,
      readOnly: true,
      value: SHEET_TITLES_CONSTANTS.VALUATIONS_SUMMARY_SPREADSHEET_HEADERS[0].value,
    } as Row,
    ...createCompaniesRows({ companies, firmSlug, queryParams, fundCurrency }),
    {
      alias: SHEET_ALIASES_CONSTANTS.VALUATIONS_SUMMARY_SPREADSHEET_TOTAL,
      className: `${VALUATIONS_SUMMARY_SPREADSHEET_TABLE}-total division`,
      format: null,
      gridType: 'string',
      readOnly: true,
      value: SHEET_TITLES_CONSTANTS.VALUATIONS_SUMMARY_SPREADSHEET_TOTAL,
    } as Row,
  ];
};

export default rowConfig;
