import { isNull } from 'lodash';
import { extractCells } from './extractCells';

const getValue = (key, changeValue, cellInExpr, changeCell) => {
  if (key === changeCell.key) {
    return changeValue !== null && changeValue !== '' ? changeValue : 0;
  }
  return cellInExpr.value !== null && cellInExpr.value !== '' ? cellInExpr.value : 0;
};

/**
 * @function
 * @name updateTotalCellExpr
 * @description This function updates spreadsheet cells based on changes in a 'controller' cell.
 *               It takes in the current spreadsheet's cell object, detects changes, and stores updates in an array.
 * @param {object} cells - Object containing all cells in the current spreadsheet.
 * @param {object} change - Object representing the specific cell that changed, with properties 'cell' for the cell object and 'value' for the new value.
 * @param {array} newChanges - Array to store changes to be pushed for updates.
 */

const getExpr = (currentExpr, columnLegend) => currentExpr.replace(/@/g, columnLegend);

const updateTotalCellExpr = ({ cells, change, newChanges }) => {
  // Destructure 'change' object to obtain 'cell' and 'value' properties
  const { cell: changeCell, value: changeValue } = change;

  // Iterate over each cell in the 'cells' object
  Object.entries(cells).forEach(([, cell]) => {
    // Exclude the changed cell, check if the cell has a valid expression and is not a title cell
    if (cell.key !== changeCell.key && cell.validExpr?.length && !cell.isTitleCell) {
      // Replace '@' with the column legend in the cell's valid expression
      const validExpr = getExpr(cell.validExpr, cell.columnLegend);
      // Extract cell keys from the modified valid expression
      const cellKeys = extractCells(validExpr);

      // Check if the changed cell is one of the dependent cells
      if (cellKeys.includes(changeCell.key)) {
        // Map through the cell keys to update their values
        const updatedProviderListValue = cellKeys.map(key => {
          const cellInExpr = cells[key];
          // If the current cell key matches the changed cell key, use the new value, otherwise, use the existing value
          // if the value is null or '' the value is place to 0
          const value = getValue(key, changeValue, cellInExpr, changeCell);
          return { key, value };
        });

        // Check if all dependent cells have non-null and non-empty values
        if (updatedProviderListValue.every(({ value }) => !isNull(value) && value !== '')) {
          // Create an updated change object with the default expression
          const updatedChange = { cell, value: validExpr };
          // Push the updated change to 'newChanges'
          newChanges.push(updatedChange);
          // Recursively call updateTotalCellExpr with the updated change
          // This is to update any cells that depend on the current cell
          updateTotalCellExpr({ cells, change: updatedChange, newChanges });
        } else {
          // If any dependent cell has null or empty value, push null value to 'newChanges'
          newChanges.push({ cell, value: null });
        }
      }
    }
  });

  // Push the changed cell with its new value to 'newChanges'
  newChanges.push({ cell: changeCell, value: changeValue });
};

export default updateTotalCellExpr;
