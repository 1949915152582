import React, { FC } from 'react';
import { InsertInvitation as InsertInvitationIcon } from '@material-ui/icons';
import clsx from 'clsx';
import useStyles from './styles';
import { CustomCalendarIconProps } from './types';

const CustomCalendarIcon: FC<CustomCalendarIconProps> = props => {
  const { className } = props;

  const classes = useStyles();

  return <InsertInvitationIcon className={clsx(className, classes.icon)} />;
};

export default CustomCalendarIcon;
