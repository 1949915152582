import React, { useMemo } from 'react';
import { isEmpty, isUndefined, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { largeDecimalFormat, smallCurrencyFormat } from 'common/formats/formats';
import ScalarDatasheet from 'components/ScalarDatasheet';
import { formatNumbers } from 'utillities';

const FundFirmDistribution = ({ weightedShareValues, currency, validSecurities }) => {
  const fundShares = useMemo(() => {
    if (weightedShareValues) {
      const tmpShareValues = weightedShareValues.map(sv => sv.share_values);
      const tmpWeightedShareValues = [...weightedShareValues];

      // Some funds may not have investments in all securities
      const allShareValuesSameSize
        = tmpShareValues.every(item => item.length === tmpShareValues[0].length)
        && validSecurities.length === tmpShareValues[0].length;

      if (!allShareValuesSameSize) {
        // Identify missing securities by fund and fill in with zeroes
        tmpWeightedShareValues.forEach(sv => {
          const securitiesByFund = sv.share_values.map(s => s.securityId);
          validSecurities.forEach(sec => {
            if (!securitiesByFund.includes(sec.id)) {
              sv.share_values.push({
                securityId: sec.id,
                security: sec.name,
                per_share: 0,
                shares: 0,
              });
            }
          });
        });
      }
      return weightedShareValues.map(({ fund, share_values }) => ({
        fund,
        /* Order share values by security name alphabetically because
        we do not have the id in each weighted shares value object */
        share_values: orderBy(
          share_values?.filter(sv => !isUndefined(sv.per_share)),
          'securityId',
          'desc'
        ),
      }));
    }
    return [];
  }, [weightedShareValues, validSecurities]);

  const headerClass = 'header-no-border title-col';
  const subtitleClass = 'table-header title-col align-right';
  const contentClass = 'align-right';
  const totalClass = 'title-col division-bottom-only division-top-only subtitle cell align-right';

  const fundDistributions = useMemo(() => {
    const data = [];
    if (fundShares) {
      const firmData = [];
      const tmpSecurityFirmData = [];
      fundShares.forEach(fundShare => {
        const fundData = [];
        let fundTotal = 0;
        let fundTotalShares = 0;
        const { fund, share_values } = fundShare;
        // Fund name as title for each distribution table
        fundData.push([
          {
            value: fund.name,
            readOnly: true,
            colSpan: 2,
            className: headerClass,
          },
        ]);
        // Subtitles
        fundData.push([
          { value: 'Share Count', readOnly: true, className: subtitleClass },
          { value: 'Value', readOnly: true, className: subtitleClass },
        ]);
        // Table content
        share_values.forEach(shareValue => {
          fundData.push([
            {
              value: shareValue.shares || '0',
              readOnly: true,
              gridType: 'number',
              format: largeDecimalFormat,
              className: contentClass,
            },
            {
              value: formatNumbers({
                currency,
                format: smallCurrencyFormat,
                value: shareValue.per_share * shareValue.shares,
              }),
              readOnly: true,
              gridType: 'number',
              className: contentClass,
            },
          ]);
          fundTotal += shareValue.per_share * shareValue.shares;
          fundTotalShares += shareValue.shares;

          const securityFirmData = tmpSecurityFirmData.find(firm => firm.security === shareValue.security);

          // Increase the firm share count and value per security if it exists...
          if (securityFirmData) {
            securityFirmData.share_count += shareValue.shares;
            securityFirmData.value += shareValue.per_share * shareValue.shares;
          } else {
            // ...or initialize it otherwise
            tmpSecurityFirmData.push({
              security: shareValue.security,
              share_count: shareValue.shares,
              value: shareValue.per_share * shareValue.shares,
            });
          }
        });
        fundData.push([
          {
            value: formatNumbers({
              gridType: 'number',
              format: largeDecimalFormat,
              value: fundTotalShares,
            }),
            readOnly: true,
            className: totalClass,
            gridType: 'number',
          },
          {
            value: formatNumbers({
              currency,
              format: smallCurrencyFormat,
              value: fundTotal,
            }),
            readOnly: true,
            className: totalClass,
            gridType: 'number',
          },
        ]);
        data.push(fundData);
      });

      // Add the firm data to the data array only if there is more than one fund
      if (fundShares.length > 1) {
        let firmTotal = 0;
        let firmTotalShares = 0;

        // Add total for all funds (firm)
        firmData.push([
          {
            value: 'Total Firm',
            readOnly: true,
            colSpan: 2,
            className: headerClass,
          },
        ]);

        // Subtitles
        firmData.push([
          { value: 'Share Count', readOnly: true, className: subtitleClass },
          { value: 'Value', readOnly: true, className: subtitleClass },
        ]);

        // Table content
        tmpSecurityFirmData.forEach(securityData => {
          firmData.push([
            {
              value: securityData.share_count,
              readOnly: true,
              gridType: 'number',
              format: largeDecimalFormat,
              className: contentClass,
            },
            {
              value: formatNumbers({
                currency,
                format: smallCurrencyFormat,
                value: securityData.value,
              }),
              readOnly: true,
              gridType: 'number',
              className: contentClass,
            },
          ]);
          firmTotal += securityData.value;
          firmTotalShares += securityData.share_count;
        });

        firmData.push([
          {
            value: firmTotalShares,
            format: largeDecimalFormat,
            readOnly: true,
            className: totalClass,
            gridType: 'number',
          },
          {
            value: formatNumbers({
              currency,
              format: smallCurrencyFormat,
              value: firmTotal,
            }),
            readOnly: true,
            className: totalClass,
            gridType: 'number',
          },
        ]);

        data.push(firmData);
      }
    }

    return data;
  }, [fundShares, currency]);

  return (
    <>
      {fundShares.every(fundShare => !isEmpty(fundShare.share_values))
        && fundDistributions.map(fundDistribution => (
          <ScalarDatasheet key={fundDistribution.value} data={fundDistribution} className="data-table" />
        ))}
    </>
  );
};

FundFirmDistribution.propTypes = {
  weightedShareValues: PropTypes.array,
  currency: PropTypes.object,
  validSecurities: PropTypes.array,
};

export default FundFirmDistribution;
