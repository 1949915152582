import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function Microsoft(props) {
  return (
    <SvgIcon
      className="Microsoft-icon"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      width="412px"
      height="412px"
      viewBox="0 0 412 412">
      <path d="M75 75v171h171v-171z" fill="#f25022" />
      <path d="M266 75v171h171v-171z" fill="#7fba00" />
      <path d="M75 266v171h171v-171z" fill="#00a4ef" />
      <path d="M266 266v171h171v-171z" fill="#ffb900" />
    </SvgIcon>
  );
}
