import moment from 'moment';

/**
 * This Function calculates and return the end date of the next fiscal quarter
 *
 * @param {Object} fye - An object with two properties: end_month and end_day,
 * which represent the company's fiscal year end month and day
 *
 * @param {String} date -  A string representing the current date in 'YYYY-MM-DD' format. If not provided, the function uses the current date.
 *
 * @return {String} The function returns a string representing the end date of the next fiscal quarter in 'MM/DD/YYYY' format.
 * If all quarters of the current year are in the past, it returns the end date of the first quarter of the next year.
 */

const getSuggestedQuarterFiscalEndDate = (fye, date) => {
  // Check that fye is an object with end_month and end_day properties
  if (!fye || typeof fye !== 'object' || typeof fye.end_month !== 'number' || typeof fye.end_day !== 'number') {
    throw new Error('Invalid fye parameter. It should be an object with end_moth and end_day properties (numbers).');
  }

  // Check that date is a string in YYYY-MM-DD format
  if (date && (typeof date !== 'string' || !moment(date, 'YYYY-MM-DD').isValid())) {
    throw new Error('Invalid date parameter. It should be a string in YYYY-MM-DD format.');
  }

  const today = date ? moment(date) : moment();
  const currentYear = today.year();
  const currentFye = moment(`${currentYear}-${fye.end_month}-${fye.end_day}`, 'YYYY-MM-DD');

  const currentQuarters = [3, 6, 9, 12]
    .map(months => {
      const quarterEnd = currentFye.isSameOrAfter(today)
        ? currentFye.clone().add(months, 'months').year(today.year())
        : currentFye.clone().add(months, 'months').endOf('month');
      return quarterEnd.format('MM/DD/YYYY');
    })
    .sort((a, b) => new Date(a) - new Date(b));

  return currentQuarters.find(quarter => today.isSameOrBefore(quarter));
};

export default getSuggestedQuarterFiscalEndDate;
