import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import {
  FINANCIAL_STATEMENTS_BY_MD_DEFAULT_ERROR_MESSAGE,
  FINANCIAL_STATEMENTS_BY_MD_QUERY_KEY,
} from 'common/constants/services/financials';
import { FinancialStatementService } from 'services';
import { throwError } from 'utillities';
import {
  GetFinancialStatementsByMDHook,
  GetFinancialStatementsByMDParams,
  GetFinancialStatementsByMDResponse,
  UseGetFinancialStatementsByMD,
} from './types';

const getFinancialStatementsByMD = async (params: GetFinancialStatementsByMDParams) => {
  const { measurementDateId } = params;

  if (isNil(measurementDateId)) return null;

  const financialStatementService = new FinancialStatementService();

  try {
    const financialStatements = (await financialStatementService.getFinancialsBasicDataListByMD(
      measurementDateId
    )) as GetFinancialStatementsByMDResponse;

    if (!financialStatements) return throwError(FINANCIAL_STATEMENTS_BY_MD_DEFAULT_ERROR_MESSAGE);

    return financialStatements;
  } catch (error) {
    return throwError(FINANCIAL_STATEMENTS_BY_MD_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetFinancialStatementsByMD: UseGetFinancialStatementsByMD = params => {
  const { measurementDate, shouldQueryAutomatically = true } = params;

  const measurementDateId = measurementDate?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<
    GetFinancialStatementsByMDHook['data'],
    Error
  >({
    enabled: !isNil(measurementDateId) && shouldQueryAutomatically,
    queryFn: () => getFinancialStatementsByMD({ measurementDateId }),
    queryKey: [FINANCIAL_STATEMENTS_BY_MD_QUERY_KEY, measurementDateId],
    refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetFinancialStatementsByMD;
