import React from 'react';
import PropTypes from 'prop-types';
import { questionModalMetadata, requestedDocumentModalMetadata } from 'common/constants/firms';
import FormInput from './FormInput';

const RequestItemModalContent = ({
  isDocumentRequest,
  formValues,
  setFormValues,
  setIsMaxCharactersError,
  hideDescription,
  children,
}) => {
  const formStructure = isDocumentRequest ? requestedDocumentModalMetadata : questionModalMetadata;

  const handleInputChange = e => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <FormInput
        name="name"
        label={formStructure.name}
        placeholder={formStructure.namePlaceholder}
        value={formValues.name}
        handleInputChange={handleInputChange}
        type="textarea"
        maxCharacters={100}
        setIsMaxCharactersError={setIsMaxCharactersError}
      />
      {!hideDescription && (
        <FormInput
          name="description"
          label={formStructure.description}
          placeholder={formStructure.descriptionPlaceholder}
          value={formValues.description}
          handleInputChange={handleInputChange}
          type="textarea"
          maxCharacters={200}
          setIsMaxCharactersError={setIsMaxCharactersError}
        />
      )}
      {children}
    </div>
  );
};

RequestItemModalContent.propTypes = {
  isDocumentRequest: PropTypes.bool.isRequired,
  formValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  setFormValues: PropTypes.func.isRequired,
  setIsMaxCharactersError: PropTypes.func.isRequired,
  hideDescription: PropTypes.bool,
  children: PropTypes.node,
};

export default RequestItemModalContent;
