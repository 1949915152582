import { WATERFALL_SLUG } from 'common/constants/allocations';
import { BREAKPOINT_ANALYSIS_SLUG, CAP_TABLE_SLUG, FUND_OWNERSHIP_SLUG } from 'common/constants/cap-table';
import { COMPANIES_SUMMARY_SLUG } from 'common/constants/companies';
import { DOCUMENTS_SLUG } from 'common/constants/documents';
import { FINANCIAL_SLUG } from 'common/constants/financials';
import { FIRMS_SUMMARY_SLUG } from 'common/constants/firms';
import { FUNDS_INVESTMENTS_SLUG, FUNDS_SUMMARY_SLUG } from 'common/constants/funds';
import { VALUATIONS_SLUG } from 'common/constants/valuations';

// BASE

export const forbiddenPath = '/not-permission';
export const homePath = '/';
export const notFoundPath = '/not-found';

// FIRMS

export const baseFirmUrl = (firmSlug: string) => `/firms/${firmSlug}`;

export const firmCompGroupsUrl = (firmSlug: string) => `${baseFirmUrl(firmSlug)}/comp-groups`;
export const firmRequestsUrl = (firmSlug: string) => `${baseFirmUrl(firmSlug)}/requests`;
export const firmSettingsUrl = (firmSlug: string) => `${baseFirmUrl(firmSlug)}/settings`;
export const firmSummaryUrl = (firmSlug: string) => `${baseFirmUrl(firmSlug)}/${FIRMS_SUMMARY_SLUG}`;
export const firmUserManagementUrl = (firmSlug: string) => `${baseFirmUrl(firmSlug)}/user-management`;

// COMPANIES

export const baseCompanyUrl = (firmSlug: string, companySlug: string) =>
  `${baseFirmUrl(firmSlug)}/companies/${companySlug}`;

export const breakpointAnalysisUrl = (firmSlug: string, companySlug: string) =>
  `${baseCompanyUrl(firmSlug, companySlug)}/${BREAKPOINT_ANALYSIS_SLUG}`;
export const captableUrl = (firmSlug: string, companySlug: string) =>
  `${baseCompanyUrl(firmSlug, companySlug)}/${CAP_TABLE_SLUG}`;
export const companySummaryUrl = (firmSlug: string, companySlug: string) =>
  `${baseCompanyUrl(firmSlug, companySlug)}/${COMPANIES_SUMMARY_SLUG}`;
export const documentsUrl = (firmSlug: string, companySlug: string) =>
  `${baseCompanyUrl(firmSlug, companySlug)}/${DOCUMENTS_SLUG}`;
export const financialUrl = (firmSlug: string, companySlug: string) =>
  `${baseCompanyUrl(firmSlug, companySlug)}/${FINANCIAL_SLUG}`;
export const fundOwnershipUrl = (firmSlug: string, companySlug: string) =>
  `${baseCompanyUrl(firmSlug, companySlug)}/${FUND_OWNERSHIP_SLUG}`;
export const valuationUrl = (firmSlug: string, companySlug: string) =>
  `${baseCompanyUrl(firmSlug, companySlug)}/${VALUATIONS_SLUG}`;
export const waterfallUrl = (firmSlug: string, companySlug: string) =>
  `${baseCompanyUrl(firmSlug, companySlug)}/${WATERFALL_SLUG}`;

// FUNDS
export const baseFundUrl = (firmSlug: string, fundSlug: string) => `${baseFirmUrl(firmSlug)}/funds/${fundSlug}`;

export const fundScheduleOfInvestmentsUrl = (firmSlug: string, fundSlug: string) =>
  `${baseFundUrl(firmSlug, fundSlug)}/${FUNDS_INVESTMENTS_SLUG}`;
export const fundSummaryUrl = (firmSlug: string, fundSlug: string) =>
  `${baseFundUrl(firmSlug, fundSlug)}/${FUNDS_SUMMARY_SLUG}`;

// USERS
export const baseUserUrl = (firmSlug: string, userId?: string) => {
  if (userId) return `${baseFirmUrl(firmSlug)}/users/${userId}`;

  return `${baseFirmUrl(firmSlug)}/users`;
};

export const user2faUrl = (firmSlug: string) => `${baseUserUrl(firmSlug)}/two-factor-authentication`;
export const userProfileUrl = (firmSlug: string) => `${baseUserUrl(firmSlug)}/account-settings`;
export const userSelectedPermissionsUrl = (firmSlug: string, userId: string) =>
  `${baseUserUrl(firmSlug, userId)}/permissions`;
export const userSelectedProfileUrl = (firmSlug: string, userId: string) =>
  `${baseUserUrl(firmSlug, userId)}/account-settings`;
