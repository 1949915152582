import { isNil } from 'lodash';
import * as validationMessages from 'common/constants/messages/validations';
import {
  MULTIPLE_DISCOUNT_COLUMN_LEGEND,
  SPECIFIED_LABEL,
  WEIGHTING,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { handleWeightingProbability } from 'pages/ValuationsAllocation/util';
import { GPC_APPROACH_CELL_ALIAS, LTM_MULTIPLE_COLUMNS_ALIAS } from './constants';

const validations = ({ cell, addFeedback, removeFeedback, removeFeedbackFromCell, tableData, parsedColumns }) => {
  const { isDisabled, gpcOptions } = tableData;
  const { columnLegend, rowNumber, alias, columnId, value: cellValue } = cell;

  if (LTM_MULTIPLE_COLUMNS_ALIAS.includes(columnId)) {
    if (isNil(cellValue)) {
      addFeedback(validationMessages.IS_REQUIRED_ERROR);
    } else {
      removeFeedback(validationMessages.IS_REQUIRED_ERROR);
    }
  }
  if (GPC_APPROACH_CELL_ALIAS.includes(alias) && MULTIPLE_DISCOUNT_COLUMN_LEGEND.includes(columnId)) {
    const nextKeyIndex = 1;
    const mdCellKey = `${columnLegend}${rowNumber + nextKeyIndex}`;
    const multipleDiscountCell = parsedColumns[mdCellKey];
    multipleDiscountCell.readOnly
      = gpcOptions.find(option => option.id === cellValue)?.name !== SPECIFIED_LABEL || isDisabled;
  }
  if (alias === WEIGHTING) {
    handleWeightingProbability({
      parsedColumns,
      cellValue,
      addFeedback,
      removeFeedback,
      weightingAliasToSearch: WEIGHTING,
      removeFeedbackFromCell,
      shouldMultiplyBy100: false,
    });
  }
};

export default validations;
