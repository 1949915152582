import { YEARFRAC } from 'common/formulas/math.js';

const dividendsAtDate = ({
  hasCumulativeDividends,
  investmentDate,
  hasCompounding,
  security,
  selectedMeasurementDate,
}) => {
  if (!hasCumulativeDividends || !investmentDate) return 0;
  if (hasCompounding) {
    return (
      security?.issue_price
        * (1 + security?.dividend_rate / security?.compounding_period)
          ** (YEARFRAC(investmentDate, selectedMeasurementDate, 3) * security?.compounding_period)
      - security?.issue_price
    );
  }
  return security?.issue_price * security?.dividend_rate * YEARFRAC(investmentDate, selectedMeasurementDate, 3);
};

export default dividendsAtDate;
