import React from 'react';
import { GRID_NUMBER_CHECKBOX } from 'common/constants/gridType';
import { largeCurrencyFormat, twoDecimalPercentFormat } from 'common/formats/formats';
import NumberCheckbox from 'components/FeaturedSpreadsheet/components/NumberMultipleCheckbox';
import { GridRowLabel } from 'components/Grid';
import {
  AS_OF_VALUE_ALIAS,
  COMPANY_NAME_ALIAS,
  MD_VALUE_ALIAS,
  PERCENT_CHANGE_ALIAS,
} from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentLedger/util/constants';

const colConfig = [
  () => ({
    columnLegend: 'A',
    gridType: 'string',
    alias: COMPANY_NAME_ALIAS,
    className: 'read-only--white',
    valueViewer: props => <GridRowLabel {...props} />,
  }),
  comp => ({
    columnLegend: 'B',
    gridType: 'number',
    alias: AS_OF_VALUE_ALIAS,
    className: 'read-only--white',
    dbDecimalPlaces: 3,
    value: '',
    expr: `=FROM_KEY(${JSON.stringify(comp.asOfComp)}, B3)`,
    format: largeCurrencyFormat,
  }),
  comp => ({
    columnLegend: 'C',
    gridType: 'number',
    alias: MD_VALUE_ALIAS,
    className: 'read-only--white',
    dbDecimalPlaces: 3,
    value: '',
    expr: `=FROM_KEY(${JSON.stringify(comp.mdComp)}, B3)`,
    format: largeCurrencyFormat,
  }),
  (comp, rowNum) => ({
    columnLegend: 'D',
    gridType: GRID_NUMBER_CHECKBOX,
    alias: PERCENT_CHANGE_ALIAS,
    forceComponent: true,
    component: <NumberCheckbox />,
    readOnly: comp.isDisabled,
    dbDecimalPlaces: 4,
    value: '',
    expr: `=(C${rowNum} - B${rowNum})/B${rowNum}`,
    format: { ...twoDecimalPercentFormat({}), validateFloat: true },
  }),
];

export default colConfig;
