/**
 * @name Task Config
 * @memberof module:common/config
 * @type {Config}
 * @return {Object} Configurations of API
 */

// #region common
import API from '../config';

const baseQuestionnaires = `${API.BASE_DEV_HOST}/api/questionnaires`;

export const validateQuestionnairesRequestToken = (requestId, token) =>
  `${baseQuestionnaires}/requests/validate-token/${requestId}/${token}`;
export const updateQuestionnaireRequestToken = (requestId, token) =>
  `${baseQuestionnaires}/requests/validate-token/${requestId}/${token}/answer-question`;
export const getQuestionOptions = () => `${baseQuestionnaires}/choices-for-dropdowns`;
