import React from 'react';
import { ValuationsApproach } from 'common/types/valuation';

interface FutureExitContextType {
  approaches: Array<ValuationsApproach>;
  multipleOptions?: any;
  metricOptions?: any;
  isNTMSelected?: any;
  currentAllocationMethodValue?: any;
  setCurrentAllocationMethodValue: (param: any) => void;
}

// Create the context with an initial value of an empty object of type FutureExitContextType
const FutureExitContext = React.createContext<FutureExitContextType>({
  approaches: [],
  multipleOptions: null,
  metricOptions: null,
  isNTMSelected: null,
  currentAllocationMethodValue: null,
  setCurrentAllocationMethodValue: () => {},
});

export default FutureExitContext;
