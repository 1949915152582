import { TITLE_TO_ALIAS } from 'common/constants/cap-table';

export const CAPTABLE = (fieldName, securityId) => {
  if (window.appGlobal?.capTable?.securities) {
    // Find the security passed as an argument
    const selectedSecurity = window.appGlobal.capTable.securities.find(
      security => security.id.toString() === securityId.toString()
    );

    // Locate and return the field-name value
    if (selectedSecurity) {
      return selectedSecurity[TITLE_TO_ALIAS[fieldName]];
    }
  }

  return null;
};

export const CAPTABLETOTAL = fieldName => {
  if (window.appGlobal?.capTable?.[TITLE_TO_ALIAS[fieldName]]) {
    return window.appGlobal.capTable[TITLE_TO_ALIAS[fieldName]];
  }
  return null;
};
