import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { VALUATIONS_DEFAULT_CURRENCY } from 'common/constants/valuations';
import { useStore } from 'common/store';
import { FormDialog } from 'components';
import { ConfirmationDelete, ConfirmationDialog } from 'components/Dialogs';
import DialogContext from 'context/DialogContext';
import {
  ERROR_GETTING_COMPANIES,
  WARNING_GETTING_COMPANIES,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { GPT_TRANSACTION } from 'pages/Valuations/approaches/GuidelineTransactions/config/constants';
import CompGroupDialogContext from 'pages/Valuations/components/CompGroups/components/common/CompGroupDialogContext';
import EditCompGroupForm from 'pages/Valuations/components/CompGroups/components/EditCompGroupForm';
import { PRIVATE_TRANSACTIONS, PUBLIC_COMPANIES } from 'pages/Valuations/util/constants';
import { extractSpecificApproachFromApproach } from 'pages/ValuationsAllocation/util';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { useGetPublicCompList, useResponse } from 'services/hooks';

const ContentComponent = compGroupSelectedName => <ConfirmationDelete itemName={compGroupSelectedName} />;

const CompGroupsDialog = ({
  openCompGroupsDialog,
  setOpenCompGroupsDialog,
  compGroupsOptionSelected,
  saveCompGroups,
  deleteCompGroup,
  tableData,
  approachType,
  defaultCompGroupId,
}) => {
  const [compGroupList, setCompGroupList] = useState([]);
  const [compGroupSelected, setCompGroupSelected] = useState({});
  const [allowDeleteCompGroup, setAllowDeleteCompGroup] = useState(false);
  const [companiesToDelete, setCompaniesToDelete] = useState([]);
  const [compGroupToDelete, setCompGroupToDelete] = useState([]);
  const [valid, setValid] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(null);
  const [shouldRefreshCompGroupList, setShouldRefreshCompGroupList] = useState(true);
  const { compGroups, measurementDate, setAreThereChanges, financialsPeriods } = useContext(ValuationContext);
  const [publicCompsData, fetchPCFromCompGroup] = useGetPublicCompList();
  const [
    {
      companyInfo: { financials_currency: financialsCurrency },
    },
  ] = useStore();
  const specificApproach = extractSpecificApproachFromApproach(tableData.approach);
  const { errorNotification } = useResponse();
  const { enqueueSnackbar } = useSnackbar();
  const isGPC = approachType === PUBLIC_COMPANIES;
  const currentComparisons = useMemo(
    () => (isGPC ? specificApproach?.gpc_comparison || [] : specificApproach?.gpt_transactions || []),
    [isGPC, specificApproach]
  );

  const currentCompGroups = useMemo(
    () =>
      isGPC
        ? specificApproach?.valuationapproachgpccompgroup_set || []
        : specificApproach?.valuationapproachptcompgroup_set || [],
    [isGPC, specificApproach]
  );

  const { showDialog } = useContext(DialogContext);
  const matchedCompGroup = useMemo(() => {
    if (compGroupSelected?.id && !isEmpty(currentCompGroups)) {
      return currentCompGroups.find(item => item.comp_group === compGroupSelected.id);
    }
    return null;
  }, [compGroupSelected, currentCompGroups]);

  const handleClose = () => {
    setOpenCompGroupsDialog(false);
  };

  const getCompaniesData = (results, useLatestVersion) =>
    results.map(item => {
      const gpcItem = {
        ...item,
      };
      if (useLatestVersion) {
        gpcItem.comp_group_id = compGroupSelected.id;
        gpcItem.comp_group_name = compGroupSelected.name;
      }
      return {
        gpc: gpcItem,
        valid_gpc_results: true,
      };
    });

  const isCompanyMarkedToDelete = comp => {
    const originalComparisons = compGroupSelected?.originalData?.gpc_comps || [];
    const comparisonsTickerSymbols = originalComparisons
      .filter(company => companiesToDelete.includes(company.id))
      .map(item => item.cap_iq_id);

    return comparisonsTickerSymbols.includes(comp.cap_iq_id);
  };

  const handleGPCCompGroupsCompanies = async () => {
    const selectedCompanies = compGroupSelected.companies.map(gpcCompItem => ({
      cap_iq_id: gpcCompItem.cap_iq_id,
    }));
    const filteredSelectedCompanies = selectedCompanies.filter(comp => !isCompanyMarkedToDelete(comp));
    const uniqueSelectedCompaniesTemp = [
      ...new Map(filteredSelectedCompanies.map(item => [item.cap_iq_id, item])).values(),
    ];

    const tickerSymbols = uniqueSelectedCompaniesTemp.map(item => item.cap_iq_id);

    if (!isEmpty(tickerSymbols)) {
      await fetchPCFromCompGroup(
        tickerSymbols,
        measurementDate.id,
        financialsCurrency || VALUATIONS_DEFAULT_CURRENCY,
        financialsPeriods
      );
    } else {
      const useLatestVersion = compGroupSelected?.use_latest_comp_group_version;
      saveCompGroups([], useLatestVersion, companiesToDelete, compGroupToDelete);
    }
  };

  const onSaveGpcSelectedCompGroup = async () => {
    // Save selected values
    setIsLoading(true);
    setLoadingText('Loading selected companies data...');
    await handleGPCCompGroupsCompanies();
    setIsLoading(false);
    setLoadingText(null);
  };

  const getUpdatedTransactions = async () => {
    const useLatestVersion = compGroupSelected.use_latest_comp_group_version;
    if (compGroupSelected.companies) {
      return compGroupSelected.companies.map(gptCompItem => {
        const { id, ...newGptCompItem } = gptCompItem;
        const newTransaction = {
          ...newGptCompItem,
          row_ref: uuid(),
          temp_id: gptCompItem.id,
          name: GPT_TRANSACTION,
          isNew: true,
          ltm_revenue_enabled: true,
          ltm_ebitda_enabled: true,
        };
        if (useLatestVersion) {
          newTransaction.comp_group_id = compGroupSelected.id;
          newTransaction.comp_group_name = compGroupSelected.name;
          newTransaction.temp_ref = compGroupSelected.id;
        }
        return newTransaction;
      });
    }
    return [];
  };

  const onSaveGptSelectedCompGroup = async () => {
    const useLatestVersion = compGroupSelected.use_latest_comp_group_version;
    const updatedTransactions = await getUpdatedTransactions();
    saveCompGroups(updatedTransactions, useLatestVersion, companiesToDelete, compGroupToDelete);
  };

  const onSaveDialog = async () => {
    if (isGPC) {
      await onSaveGpcSelectedCompGroup();
    } else {
      await onSaveGptSelectedCompGroup();
    }
    setCompGroupSelected({});
    setShouldRefreshCompGroupList(true);
    setAreThereChanges(true);
  };

  const isCompGroupMarkedToDelete = useCallback(
    id => {
      const deletedCompGroups = specificApproach.deleted_comp_groups || [];
      return deletedCompGroups.includes(id);
    },
    [specificApproach]
  );

  const getCompGroupData = useCallback(
    compGroupResults => {
      const approachCompGroupData = compGroupResults.filter(item => item.group_type === approachType);
      const approachCompsId = currentComparisons?.map(
        item => item.gpc_approach_comp_group || item.pt_approach_comp_group
      );
      const relatedCompGroups = currentCompGroups.filter(currentCgItem => approachCompsId.includes(currentCgItem.id));
      setShouldRefreshCompGroupList(false);
      return approachCompGroupData.map(compGroup => {
        const isChecked = relatedCompGroups.findIndex(item => item.comp_group === compGroup.id) !== -1;
        // eslint-disable-next-line no-param-reassign
        compGroup.checked = isChecked && !isCompGroupMarkedToDelete(compGroup.id);
        return compGroup;
      });
    },
    [approachType, currentCompGroups, currentComparisons, isCompGroupMarkedToDelete]
  );

  const onDeleteCompGroup = async () => {
    const compGroupId = matchedCompGroup.id || matchedCompGroup.comp_group;
    await deleteCompGroup(compGroupId);
    setCompGroupSelected({});
    setShouldRefreshCompGroupList(true);
  };

  const handleDeleteCompGroup = () => {
    showDialog({
      wrapper: ConfirmationDialog,
      content: () => ContentComponent(compGroupSelected.name),
      actions: [
        {
          label: 'Cancel',
          type: 'cancel',
        },
        {
          label: 'Delete',
          variant: 'contained',
          type: 'danger',
          callback: onDeleteCompGroup,
        },
      ],
    });
  };
  useEffect(() => {
    if (publicCompsData) {
      const useLatestVersion = compGroupSelected?.use_latest_comp_group_version;
      if (!publicCompsData.all_valid && !isEmpty(publicCompsData.results)) {
        enqueueSnackbar(WARNING_GETTING_COMPANIES, { variant: 'warning' });
      } else if (!publicCompsData.all_valid && isEmpty(publicCompsData.results)) {
        errorNotification(ERROR_GETTING_COMPANIES);
      }
      const companiesData = getCompaniesData(publicCompsData.results, useLatestVersion);
      saveCompGroups(companiesData, useLatestVersion, companiesToDelete, compGroupToDelete);
    }
    // We don't have exhaustive deps here because we want to run the effect only when
    // fetchPCFromCompGroup has fetched the data. That means that publicCompsData is not undefined.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicCompsData]);

  useEffect(() => {
    if (compGroups && openCompGroupsDialog && shouldRefreshCompGroupList) {
      const tempCompGroupData = getCompGroupData(compGroups);
      setCompGroupList(tempCompGroupData);
    }
  }, [tableData, compGroups, openCompGroupsDialog, shouldRefreshCompGroupList, getCompGroupData]);

  useEffect(() => {
    if (compGroupSelected?.id) {
      if (matchedCompGroup) {
        const allowDelete = matchedCompGroup.use_latest_comp_group_version;
        setAllowDeleteCompGroup(allowDelete);
      } else {
        setAllowDeleteCompGroup(false);
      }
    } else {
      setAllowDeleteCompGroup(false);
    }
  }, [compGroupSelected, matchedCompGroup]);

  const compGroupType = approachType === PRIVATE_TRANSACTIONS ? 'Transaction' : 'Public';
  const compGroupDialogContextValue = useMemo(
    () => ({
      currentComparisons,
      compGroupList,
      compGroupSelected,
      setCompGroupSelected,
      setCompaniesToDelete,
      setCompGroupToDelete,
      setValid,
      currentCompGroups,
      isGPC,
      defaultCompGroupId,
    }),
    [
      currentComparisons,
      compGroupList,
      compGroupSelected,
      setCompGroupSelected,
      setCompaniesToDelete,
      setCompGroupToDelete,
      setValid,
      currentCompGroups,
      isGPC,
      defaultCompGroupId,
    ]
  );

  const dialogActions = useMemo(() => {
    if (matchedCompGroup) {
      return {
        title: `Edit ${compGroupType} Comps Group`,
        btnTxt: 'Update',
      };
    }
    return {
      title: `Select ${compGroupType} Comps Group`,
      btnTxt: 'Add',
    };
  }, [compGroupType, matchedCompGroup]);

  return (
    <FormDialog
      open={openCompGroupsDialog}
      title={dialogActions.title}
      onClose={handleClose}
      isValid={valid || !isUndefined(compGroupSelected?.id)}
      onSave={onSaveDialog}
      onDelete={allowDeleteCompGroup ? handleDeleteCompGroup : undefined}
      isLoading={isLoading}
      loadingText={loadingText}
      customButtonLabel={dialogActions.btnTxt}
      deleteText="Remove Comp Group"
      shouldCloseOnDelete={false}
      allowDelete={allowDeleteCompGroup}>
      <CompGroupDialogContext.Provider value={compGroupDialogContextValue}>
        <EditCompGroupForm />
      </CompGroupDialogContext.Provider>
    </FormDialog>
  );
};

export default CompGroupsDialog;

CompGroupsDialog.propTypes = {
  openCompGroupsDialog: PropTypes.bool,
  setOpenCompGroupsDialog: PropTypes.func,
  compGroupsOptionSelected: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.number,
    value: PropTypes.number,
  }),
  saveCompGroups: PropTypes.func,
  deleteCompGroup: PropTypes.func,
  tableData: PropTypes.shape({
    currentComparisons: PropTypes.arrayOf(PropTypes.shape({})),
    currentCompGroups: PropTypes.arrayOf(PropTypes.shape({})),
    approach: PropTypes.shape({}),
  }),
  approachType: PropTypes.string,
  defaultCompGroupId: PropTypes.number,
};
