import { useMemo } from 'react';

const useFundOwnershipFundCell = ({ cells, cell, fundList }) => {
  const fund = useMemo(() => {
    const fundCell = cells[`${cell.columnLegend}3`];
    return fundList?.find(fundListItem => fundListItem.id.toString() === fundCell.value.toString());
  }, [fundList, cells, cell]);
  return fund;
};
export default useFundOwnershipFundCell;
