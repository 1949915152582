import { alpha, createStyles, makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const wrapText: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    // Items
    item: {
      color: theme.palette.white,
      cursor: 'pointer',
      padding: theme.spacing(2),
      // '&.Mui-selected': {
      //   backgroundColor: theme.palette.primary['100'],
      // },

      '&:hover': {
        backgroundColor: theme.palette.primary['500'],
      },

      // Arrow
      '&>svg': {
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
    },
    itemXs: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    itemMd: {
      display: 'none',

      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    itemWithLink: {
      cursor: 'auto',
      padding: 0,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    itemLink: {
      display: 'block',
      cursor: 'pointer',
      padding: theme.spacing(2),

      '&:hover': {
        backgroundColor: theme.palette.primary['500'],
      },
    },
    itemText: {
      ...wrapText,
      textTransform: 'capitalize',
      '& .MuiTypography-body1': {
        fontWeight: 700,
        color: theme.palette.white,
      },
    },
    itemTitle: {
      // display: 'inline-block',
    },
    itemIcon: {
      // display: 'inline-block',
      color: theme.palette.white,
      marginLeft: theme.spacing(2),
    },

    // subItems
    subMenu: {
      backgroundColor: alpha(theme.palette.primary['500'] ?? theme.palette.primary.main, 0.2),

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    subItem: {
      borderRadius: 0,
      padding: 0,
      margin: 0,
      '& .MuiListItemIcon-root': {
        minWidth: theme.spacing(5),
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary[200],
      },
      '&.Mui-selected .MuiTypography-root': {
        fontWeight: '700',
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    subItemLink: {
      borderRadius: 0,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      paddingLeft: theme.spacing(4),
      textTransform: 'capitalize',
      position: 'relative',
      cursor: 'pointer !important',
      width: '100%',
    },
    subItemText: {
      '& .MuiTypography-body1': {
        ...wrapText,
        color: theme.palette.white,
        fontSize: '0.85rem',
      },
    },
    subItemAction: {
      '& .MuiTypography-body1': {
        color: theme.palette.primary.light,
      },

      '&:hover .MuiTypography-body1': {
        color: theme.palette.white,
      },
    },

    // services
    service: {
      paddingLeft: theme.spacing(7),
      backgroundColor: 'rgba(0,0,0, 0.1)',

      '&.Mui-selected': {
        backgroundColor: 'rgba(0,0,0, 0.10)',
      },
      '&.Mui-selected .MuiTypography-root': {
        fontWeight: '700',
      },
    },
    serviceIcon: {
      opacity: 0.5,
      minWidth: '18px',

      '& .MuiSvgIcon-root': {
        width: '18px',
      },
    },
    rightDropIcon: {
      color: theme.palette.white,
      opacity: 0.5,
      minWidth: 0,
    },
    overflowtext: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    // skeleton
    skeleton: {
      backgroundColor: 'rgba(255,255,255, 25%)',
    },
  })
);

export default useStyles;
