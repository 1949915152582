import { oneDecimalPercentFormatValidateFloatTrue } from 'common/formats/formats';
import { PERCENTILE_RANKING_TITLE } from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import {
  PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
  PERFORMANCE_METRICS_SPREADSHEET_HEADER_CLASSNAME,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics';
import { generateColumnKey, getArrayValue, getStringValue, gridShortDate } from 'utillities';
import { COMPANY_PERFORMANCE_METRIC_CUSTOM_KEY } from '../../../common/constants';

const comparisonCompanies = ({ gpcComparison }) =>
  getArrayValue(
    gpcComparison?.map(comparison => ({
      alias: generateColumnKey({
        name: comparison?.name ? getStringValue(comparison?.name.value || comparison?.name) : '',
        prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
      }),
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: getStringValue(comparison?.name || comparison?.company_name.value || comparison?.company_name),
    }))
  );

const rowConfig = ({ asOfDate, companyName, comparisons: gpcComparison, isReadOnlyCompanyPerfMetricsAsOfDateRow }) => [
  {
    alias: SHEET_ALIASES_CONSTANTS.PERFORMANCE_METRICS_SPREADSHEET_HEADER_TITLE,
    className: `${PERFORMANCE_METRICS_SPREADSHEET_HEADER_CLASSNAME} large-header`,
    gridType: 'string',
    ignoreRowCopy: true,
    isParentWithDivider: false,
    readOnly: true,
    value: `${SHEET_TITLES_CONSTANTS.PERFORMANCE_METRICS_SPREADSHEET_HEADERS[0].value} as of ${gridShortDate(
      asOfDate
    )}`,
    isTitleOrHeader: true,
  },
  ...comparisonCompanies({ gpcComparison }),
  {
    alias: SHEET_ALIASES_CONSTANTS.PERFORMANCE_METRICS_SPREADSHEET_COMPANY,
    className: 'bold-row',
    displayBold: true,
    gridType: 'string',
    ignoreRowCopy: true,
    readOnly: isReadOnlyCompanyPerfMetricsAsOfDateRow,
    value: `${companyName} as of ${gridShortDate(asOfDate)}`,
    dbDecimalPlaces: 2,
    customKey: COMPANY_PERFORMANCE_METRIC_CUSTOM_KEY,
  },
  {
    alias: SHEET_ALIASES_CONSTANTS.PERFORMANCE_METRICS_SPREADSHEET_RANK_ALIAS,
    gridType: 'percentage',
    format: oneDecimalPercentFormatValidateFloatTrue,
    readOnly: true,
    value: PERCENTILE_RANKING_TITLE,
  },
];

export default rowConfig;
