import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    tableCellValue: {
      width: '5rem',
    },
    descriptionContent: {
      marginBottom: theme.spacing(3),
    },
    cancelBtn: {
      background: 'white',
      color: '#004A8B',

      '&:hover': {
        backgroundColor: '#f7f7f7',
        boxShadow: 'none',
      },
    },
  })
);

export default useStyles;
