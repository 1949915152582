import React, { useCallback, useMemo, useState } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ADD_USERS_NO_EMAIL } from 'common/constants/user';
import { useResponse } from 'services/hooks';
import useCreateBulkCompanyUsers from 'services/hooks/useCreateBulkCompanyUsers/useCreateBulkCompanyUsers';
import { useCreateBulkUsers } from 'services/hooks/useManageUser';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const ActionButtonGroup = ({
  handleDialogClose,
  createButtonText,
  disableCreate,
  firm,
  selectedCompany,
  newUsers,
  companyList,
  fundList,
}) => {
  const classes = useStyles();
  const { successNotification, errorNotification } = useResponse();

  const { mutate: createBulkUsers, isLoading: isFirmUsersLoading } = useCreateBulkUsers();
  const { mutate: createBulkCompanyUsers, isLoading: isCompanyUsersLoading } = useCreateBulkCompanyUsers();
  const [skipEmail, setSkipEmail] = useState(false);

  const isLoading = useMemo(
    () => isFirmUsersLoading || isCompanyUsersLoading,
    [isFirmUsersLoading, isCompanyUsersLoading]
  );

  const handleSave = useCallback(async () => {
    if (selectedCompany) {
      createBulkCompanyUsers(
        {
          companyId: selectedCompany.id,
          users: newUsers,
          sendActivationEmail: !skipEmail,
        },
        {
          onSuccess: response => {
            if (response) {
              handleDialogClose();
              successNotification('Users created successfully');
            }
          },
          onError: () => errorNotification('Error creating users'),
        }
      );
    } else {
      createBulkUsers(
        {
          firm_name: firm.name,
          firm_id: firm.id,
          users: newUsers,
          companyList,
          fundList,
          skipEmail,
        },
        {
          onSuccess: response => {
            if (response) {
              handleDialogClose();
              successNotification('Users created successfully');
            }
          },
          onError: () => errorNotification('Error creating users'),
        }
      );
    }
  }, [
    companyList,
    createBulkCompanyUsers,
    createBulkUsers,
    errorNotification,
    firm,
    fundList,
    handleDialogClose,
    newUsers,
    selectedCompany,
    skipEmail,
    successNotification,
  ]);

  return (
    <>
      {isLoading && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
          <div className="spinner" />
          <p>Creating users...</p>
        </div>
      )}
      <FormGroup row style={{ marginTop: '1rem', fontSize: '0.5rem' }}>
        <FormControlLabel
          control={
            <Checkbox
              name="add-users-no-email"
              color="primary"
              onClick={event => setSkipEmail(event.target.checked)}
              disabled={isLoading}
            />
          }
          label={<Typography variant="body2">{ADD_USERS_NO_EMAIL}</Typography>}
        />
      </FormGroup>
      <div className={classes.buttonGroup}>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: '1rem' }}
          onClick={() => handleDialogClose()}
          disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" disabled={disableCreate} onClick={handleSave}>
          {createButtonText}
        </Button>
      </div>
    </>
  );
};

ActionButtonGroup.propTypes = {
  handleDialogClose: PropTypes.func.isRequired,
  createButtonText: PropTypes.string.isRequired,
  disableCreate: PropTypes.bool,
  firm: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  newUsers: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    })
  ),
  selectedCompany: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  companyList: PropTypes.arrayOf(
    PropTypes.shape({
      company_id: PropTypes.number.isRequired,
      company_name: PropTypes.string.isRequired,
    })
  ),
  fundList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default ActionButtonGroup;
