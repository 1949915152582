import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';
import { ButtonGroupIconButtonProps } from './types';

const ButtonGroupIconButton: FC<ButtonGroupIconButtonProps> = props => {
  const { disableElevation, fullWidth, disableFocusRipple, children, ...iconButtonProps } = props;
  return <IconButton {...iconButtonProps}>{children}</IconButton>;
};

export default ButtonGroupIconButton;
