/* eslint-disable @typescript-eslint/no-explicit-any */
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { TABLE_TITLE } from './rowConfig';

export default async ({ columns, rowConfig }: any) => {
  let cells = {};
  const alphabet = alphabetGenerator([], columns.length);

  rowConfig.forEach((row: any, rowIdx: any) => {
    columns.forEach((col: any, colIdx: any) => {
      const rowNumber = rowIdx + 1;
      const columnLegend = alphabet[colIdx];
      const key = columnLegend + rowNumber;
      const value = rowNumber === 1 || row.alias === TABLE_TITLE ? 'Distribution' : col[row.alias];

      cells = {
        ...cells,
        [key]: {
          ...row,
          key,
          rowSpan: 1,
          columnLegend,
          value,
        },
      };
    });
  });

  return cells;
};
