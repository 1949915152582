import { isNil } from 'lodash';
import { ValuationsApproach } from 'common/types/valuation';
import {
  ALLOCATION_SCENARIO_METHOD_CSE_KEY,
  ALLOCATION_SCENARIO_METHOD_OPM_KEY,
  ALLOCATION_SCENARIO_METHOD_WATERFALL_KEY,
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE_KEY,
  ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
  ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT_KEY,
  ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES,
  ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES_KEY,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import { getObjectValue } from 'utillities';
import { CustomAfterCellChangedParams } from './types';
import {
  enableCells,
  handleBacksolveScenario,
  handleFutureExitScenario,
  handlePresentEquityValue,
  handleSpecifiedShareValuesScenario,
  resetCells,
} from './utils';

const {
  EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
  EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION,
  EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE,
  EQUITY_ALLOCATION_SPREADSHEET_MATURITY,
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE,
  EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE,
  EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY,
} = SHEET_ALIASES_CONSTANTS;

const customAfterCellChanged = (params: CustomAfterCellChangedParams) => {
  const { approachesScenarioMethods, cells, initialChanges, primaryCapTable } = params;

  const changes = [...initialChanges];

  initialChanges.forEach(change => {
    const { cell, value } = getObjectValue(change);
    const { alias, columnLegend } = getObjectValue(cell);

    // Column Cells
    const columnCells = Object.values(cells).filter(currentCell => currentCell?.columnLegend === columnLegend);

    const capTableSelectionCell = columnCells.find(
      currentCell => currentCell?.alias === EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION
    );

    const maturityCell = columnCells.find(currentCell => currentCell?.alias === EQUITY_ALLOCATION_SPREADSHEET_MATURITY);

    const riskFreeRateCell = columnCells.find(
      currentCell => currentCell?.alias === EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE
    );

    const volatilityCell = columnCells.find(
      currentCell => currentCell?.alias === EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY
    );

    const futureEquityValueCell = columnCells.find(
      currentCell => currentCell?.alias === EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE
    );

    const presentEquityValueCell = columnCells.find(
      currentCell => currentCell?.alias === EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE
    );

    // Allocation Method Approach
    const allocationMethodApproach = approachesScenarioMethods.find(approach => approach?.id === value);
    const { approachEquityValue, approachFutureEquityValue, approachValuation, isApproachWithOPM, scenarioType }
      = getObjectValue(allocationMethodApproach);

    // Backsolve
    const {
      maturity: backsolveMaturity,
      volatility: backsolveVolatility,
      applied_methodologies: backsolveAppliedMethodologies,
    } = getObjectValue(approachValuation as ValuationsApproach['valuations_approach_backsolve']);

    const backsolveCapTable = backsolveAppliedMethodologies?.[0]?.cap_table;

    // Future Exit
    const {
      maturity: futureExitMaturity,
      volatility: futureExitVolatility,
      cap_table: futureExitCapTable,
    } = getObjectValue(approachValuation as ValuationsApproach['valuations_approach_future_exit']);

    // Specified Share Values
    const { cap_table: specifiedShareValuesCapTable } = getObjectValue(
      approachValuation as ValuationsApproach['valuations_approach_ssv']
    );

    // Update Cell Data
    const updatedCell = cell;
    updatedCell.data = {
      ...getObjectValue(cell.data),
      isApproachWithOPM,
    };

    // Update Original Cell
    changes[0] = { cell: updatedCell, value };

    // Handle Allocation Method
    if (alias === EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD) {
      // Reset CapTable Selection
      if (capTableSelectionCell) {
        capTableSelectionCell.readOnly = false;
      }

      // Reset Cells
      resetCells({ cells: [futureEquityValueCell, maturityCell, riskFreeRateCell, volatilityCell], changes });

      switch (value) {
        case ALLOCATION_SCENARIO_METHOD_OPM_KEY:
          // Enable OPM Inputs
          enableCells({ cells: [maturityCell, riskFreeRateCell, volatilityCell] });
          break;

        case ALLOCATION_SCENARIO_METHOD_WATERFALL_KEY:
        case ALLOCATION_SCENARIO_METHOD_CSE_KEY:
          // Clear and Hide OPM Inputs
          break;

        // Add Approch
        case ALLOCATION_SCENARIO_TYPE_BACKSOLVE_KEY:
        case ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT_KEY:
        case ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES_KEY:
          break;

        // Handle Scenario Type
        default:
          if (!isNil(allocationMethodApproach)) {
            handlePresentEquityValue({ approachEquityValue, changes, presentEquityValueCell });

            switch (scenarioType) {
              case ALLOCATION_SCENARIO_TYPE_BACKSOLVE:
                handleBacksolveScenario({
                  backsolveCapTable,
                  backsolveMaturity,
                  backsolveVolatility,
                  capTableSelectionCell,
                  changes,
                  enableCells,
                  isApproachWithOPM,
                  maturityCell,
                  primaryCapTable,
                  riskFreeRateCell,
                  volatilityCell,
                });
                break;

              case ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT:
                handleFutureExitScenario({
                  approachFutureEquityValue,
                  capTableSelectionCell,
                  changes,
                  enableCells,
                  futureEquityValueCell,
                  futureExitCapTable,
                  futureExitMaturity,
                  futureExitVolatility,
                  isApproachWithOPM,
                  maturityCell,
                  primaryCapTable,
                  riskFreeRateCell,
                  volatilityCell,
                });
                break;

              case ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES:
                handleSpecifiedShareValuesScenario({
                  capTableSelectionCell,
                  changes,
                  primaryCapTable,
                  specifiedShareValuesCapTable,
                });
                break;

              default:
                break;
            }
          }
          break;
      }
    }
  });

  return changes;
};

export default customAfterCellChanged;
