import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const GoToFundOwnership = ({ fundOwnershipURL, measurementDateSlug }) => {
  const history = useHistory();

  const redirectToFundOwnershipPage = useCallback(() => {
    history.push(`${fundOwnershipURL}?date=${measurementDateSlug}`);
  }, [fundOwnershipURL, history, measurementDateSlug]);

  return (
    <Button variant="outlined" color="primary" onClick={redirectToFundOwnershipPage} endIcon={<ArrowBackIcon />}>
      Add Ownership
    </Button>
  );
};

GoToFundOwnership.propTypes = {
  fundOwnershipURL: PropTypes.string,
  measurementDateSlug: PropTypes.string,
};

export default GoToFundOwnership;
