import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ValuationsStore } from './types';

const useValuationsStore = create<ValuationsStore>()(
  devtools(
    set => ({
      currentCapTable: null,
      currentCapTableVersions: null,
      currentValuation: null,
      setCurrentCapTable: currentCapTable => set(() => ({ currentCapTable })),
      setCurrentCapTableVersions: currentCapTableVersions => set(() => ({ currentCapTableVersions })),
      setCurrentValuation: currentValuation => set(() => ({ currentValuation })),
    }),
    {
      name: 'ValuationsStore',
    }
  )
);

export default useValuationsStore;
