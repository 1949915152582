/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import {
  APPROACH_TABLE_ID,
  CURRENT_VALUE_LEDGER_ALIAS,
  OPM,
  OPM_TABLE_ID,
  SCENARIO_METHOD_ROW_NUMBER,
  SCENARIO_METHOD_TITLE,
  SCENARIO_METHODS,
  WEIGHTED_PRESENT_VALUE,
} from 'common/constants/allocations';
import { largeCurrencyFormat } from 'common/formats/formats';
import { InfoCard } from 'components';
import { LedgerDialog } from 'components/Dialogs';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { formatNumbers, getLedgerKey } from 'utillities';
import { ApproachTable } from './components';
import { OpmInputs } from '../opm-inputs';

const emptyDialogData = {
  opmInputs: {
    volatility: null,
    maturity: null,
    riskFreeRate: null,
  },
  purchased: [],
  weightedPresentValue: [
    {
      weightedPresentValue: 0,
    },
  ],
};

const useStyles = makeStyles(() => ({
  infoCards: {
    display: 'flex',
    padding: '1rem 2.5rem',
    backgroundColor: '#f1f4f6',

    '& .MuiPaper-root': {
      flexGrow: 1,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

const InfoCards = ({ totalPresentValue, scenarioMethod }) => (
  <div className={useStyles().infoCards}>
    <InfoCard title={WEIGHTED_PRESENT_VALUE} body={totalPresentValue} />
    <InfoCard title={SCENARIO_METHOD_TITLE} body={SCENARIO_METHODS[scenarioMethod] || ''} />
  </div>
);

const CurrentValueLedger = ({ cell, closeDialog }) => {
  const { cells, onCellsChanged, format } = useContext(FeaturedSpreadsheetContext);
  const [dialogData, setDialogData] = useState();
  const [doFullValidation, setDoFullValidation] = useState(false);
  const [isValidatingTables, setIsValidatingTables] = useState(false);
  const [isValidApproachesTable, setIsValidApproachesTable] = useState(true);
  const [isValidOpmTable, setIsValidOpmTable] = useState(true);
  const [sharedCells, setSharedCells] = useState({});
  const [finalValue, setFinalValue] = useState(0);

  const approachTableData = dialogData?.[APPROACH_TABLE_ID]?.filter(approach => !approach.isCalibration);

  const totalPresentValue = useMemo(() => {
    let total = 0;

    if (approachTableData && Array.isArray(approachTableData)) {
      // eslint-disable-next-line no-return-assign
      total = approachTableData.reduce((acc, curr) => acc + curr?.weightedEquityValue || 0, 0);
    }

    return formatNumbers({
      format: largeCurrencyFormat,
      currency: format.currency,
      value: total,
    });
  }, [approachTableData]);

  const scenarioMethod = useMemo(() => {
    const scenarioMethodKey = `${cell.columnLegend}${SCENARIO_METHOD_ROW_NUMBER}`;
    return cells[scenarioMethodKey].value;
  }, [cell]);

  const updateValidationStatus = validationStatus => {
    setIsValidatingTables(isValidatingTables || validationStatus);
  };

  const updateFields = () => {
    const tmpCells = { ...cells };
    const ledgerKey = getLedgerKey(cell.columnLegend);

    const changes = [];
    changes.push({
      cell: tmpCells[cell.key],
      value: finalValue,
    });

    // merge tmpCells[ledgerKey] with diologData
    Object.assign(tmpCells[ledgerKey], dialogData);
    changes.push({
      cell: tmpCells[ledgerKey],
      value: tmpCells[ledgerKey]?.value,
    });

    onCellsChanged(changes);
  };

  const save = async () => {
    if (isValidApproachesTable && isValidOpmTable) {
      updateFields();
      closeDialog();
    }
  };

  useEffect(() => {
    if (!isValidatingTables && doFullValidation) {
      (async () => {
        try {
          await save();
          setDoFullValidation(false);
        } catch (error) {
          throw new Error(error);
        }
      })();
    }
  }, [isValidatingTables]);

  useEffect(() => {
    const ledgerKey = `${CURRENT_VALUE_LEDGER_ALIAS}_${cell.columnLegend}`;
    const dialogContent = cells[ledgerKey]?.value || emptyDialogData;

    if (cells[ledgerKey]) {
      setDialogData(dialogContent?.filter(approach => !approach.isCalibration));
    }

    return () => {
      setDialogData(null);
    };
  }, [cells]);

  const sharedProps = {
    dialogData,
    setDialogData,
    cell,
    doFullValidation,
    updateValidationStatus,
    sharedCells,
    setSharedCells,
    setFinalValue,
  };

  return (
    <LedgerDialog
      id="current-value-ledger"
      title="Current Value Ledger"
      onSave={() => setDoFullValidation(true)}
      onClose={closeDialog}
      showDeleteColumn
      dialogHeader={<InfoCards totalPresentValue={totalPresentValue} scenarioMethod={scenarioMethod} />}>
      <ApproachTable
        {...sharedProps}
        setIsValid={setIsValidApproachesTable}
        tableId={APPROACH_TABLE_ID}
        currency={format.currency}
      />
      <br />
      {scenarioMethod.toString() === OPM.toString() && (
        <OpmInputs {...sharedProps} setIsValid={setIsValidOpmTable} tableId={OPM_TABLE_ID} />
      )}
    </LedgerDialog>
  );
};

CurrentValueLedger.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
};

CurrentValueLedger.propTypes = {
  cell: PropTypes.object,
  closeDialog: PropTypes.func,
  investmentDates: PropTypes.any,
};

export default React.memo(CurrentValueLedger);
