import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { isEmpty } from 'validate.js';
import { FormDialog } from 'components';
import CustomTextField from 'components/CustomTextField';
import { useFormValidation } from 'services/hooks/useFormValidation';

const defaultConstraints = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 3, message: 'must be at least 3 characters' },
  },
};

const NewVersionForm = ({ open, defaultValues, onFormChange, onSave, onClose }) => {
  const { formValidated, validateForm } = useFormValidation(defaultConstraints);
  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (!isEmpty(formValues)) {
      validateForm(formValues);
    }
  }, [formValues, validateForm]);

  const onInputChange = debounce(() => {
    onFormChange(formValidated);
  }, 0);

  useEffect(() => {
    onInputChange();
  }, [onInputChange, onFormChange, formValidated]);

  const handleChange = event => {
    event.persist();
    const {
      target: { name: fieldName, value },
    } = event;
    setFormValues({
      ...formValues,
      [fieldName]: value,
    });
  };

  const onBlur = () => {
    if (!formValues?.name?.length) {
      setFormValues({
        ...formValues,
        name: defaultValues.name,
      });
    }
  };

  return (
    <FormDialog
      open={open}
      title="Save as New Version"
      editMode={false}
      onClose={onClose}
      onSave={onSave}
      isValid={formValidated.isValid}>
      <Grid container>
        <CustomTextField
          fullWidth
          id="name"
          name="name"
          type="text"
          label="Nickname"
          onChange={handleChange}
          value={formValues.name || ''}
          onBlur={onBlur}
        />
      </Grid>
    </FormDialog>
  );
};

NewVersionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  defaultValues: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
};

export default NewVersionForm;
