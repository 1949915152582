import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import {
  GET_NOTES_KEY,
  NOTES_UPDATING_NOTES_ERROR_MESSAGE,
  SAVE_NOTES_EMPTY_ERROR,
} from 'common/constants/services/notes';
import NotesService from 'services/notes';
import { throwError } from 'utillities';
import { NotesFinalResponse, SaveNotesParams } from './types';

const saveNotes = async (params: SaveNotesParams) => {
  const { notes } = params;

  if (isEmpty(notes)) return throwError(SAVE_NOTES_EMPTY_ERROR);

  const notesService = new NotesService();

  try {
    const noteResponse = await notesService.saveNotes(notes);

    return noteResponse;
  } catch (error) {
    return throwError(NOTES_UPDATING_NOTES_ERROR_MESSAGE);
  }
};

export const useSaveNotes = () => {
  const queryClient = useQueryClient();

  const saveNotesMutation = useMutation<NotesFinalResponse, Error, SaveNotesParams>({
    mutationFn: ({ notes }) => saveNotes({ notes }),
  });

  const { data, isError, isLoading, isSuccess, mutate } = saveNotesMutation;

  if (isSuccess) {
    // Invalidate the Save Notes to refetch the data
    queryClient.invalidateQueries({ queryKey: [GET_NOTES_KEY] });
    // Clean the mutation internal state
    saveNotesMutation.reset();
  }

  return {
    data,
    isError,
    isLoading,
    isSuccess,
    mutate,
  };
};
