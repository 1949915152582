import React, { FC, useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { ALLOCATIONS_DRAFT } from 'common/constants/allocations';
import { useAllocationValuesStore } from 'store';
import { ALLOCATION_STATUS } from './common/constants';
import useStyles from './styles';
import { AllocationStatusBadgeProps } from './types';

const AllocationStatusBadge: FC<AllocationStatusBadgeProps> = props => {
  const { status } = props;

  const classes = useStyles();

  // Allocation Values Store
  const currentAllocationStatus = useAllocationValuesStore(state => state.currentAllocationStatus);

  const shouldDisplayBadge = useMemo(
    () => !isNil(status) || !isNil(currentAllocationStatus),
    [currentAllocationStatus, status]
  );

  const allocationStatus = useMemo(
    () => ALLOCATION_STATUS[status ?? currentAllocationStatus ?? ALLOCATIONS_DRAFT],
    [currentAllocationStatus, status]
  );

  if (!shouldDisplayBadge) return null;

  return (
    <Grid
      className={clsx(classes.badge, allocationStatus)}
      component="span"
      data-testid={`allocation-status-badge-${allocationStatus}`}>
      <Typography className={clsx(classes.title, allocationStatus)} component="span" paragraph>
        {allocationStatus}
      </Typography>
    </Grid>
  );
};

export default AllocationStatusBadge;
