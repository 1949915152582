/**
 * @name Company Config
 * @memberof module:common/config
 * @type {Config}
 * @return {Object} Configurations of API
 */

// #region common
import API from '../config';

const base = `${API.BASE_DEV_HOST}/api/companies`;

export const updateById = companyId => `${base}/${companyId}`;
export const getCapTableList = (companyId, dateId) => `${base}/${companyId}/cap-tables?mp=${dateId}`;
export const getCompanySummary = (companyId, measurementDateId) => `${base}/${companyId}/summary/${measurementDateId}`;
export const hasCompanySummary = (companyId, measurementDateId) =>
  `${base}/${companyId}/has-summary/${measurementDateId}`;
export const getFundsByCompanyId = companyId => `${base}/${companyId}/funds`;
export const getVersionsByCompanyIdByDateId = (companyId, dateId) => `${base}/${companyId}/cap-tables?mp=${dateId}`;
export const getAllocationsVersionsByCompanyIdByDateId = (companyId, dateId) =>
  `${base}/${companyId}/allocations?mp=${dateId}`;
export const getQuarterEndsByCompanyId = companyMeasurementDateId =>
  `${base}/fiscal-end-dates/${companyMeasurementDateId}`;
export const deleteCompany = companyId => `${base}/${companyId}`;
export const getAllocationValuesByCompanyId = (companyId, measurementDateId, version) =>
  version
    ? `${base}/${companyId}/allocation-values?md=${measurementDateId}&version=${version}`
    : `${base}/${companyId}/allocation-values?md=${measurementDateId}`;
