import React from 'react';
import { dcfPercentFormatWithNegativeValues, largeCurrencyFormat, standardDecimalFormat } from 'common/formats/formats';
import * as constants from './constants';
import { MAX_VALUATION_NUMBER_DIGITS } from './constants';
import EffectiveTaxRateChip from '../../components/EffectiveTaxRateChip';
import WACCChip from '../../components/WACCChip';

const getRowConfig = ({ cashTaxRate, dcfWaccSheet, isDisabled }) => [
  {
    className: 'title-center',
    value: '',
    alias: 'year',
    hidden: false,
    readOnly: true,
    gridType: 'string',
    dbType: 'string',
    expr: '',
    defaultValue: '0',
    isTitleOrHeader: true,
  },
  {
    value: constants.REVENUE_TOTAL_TITLE,
    alias: constants.REVENUE_TOTAL_ALIAS,
    rowNumber: constants.REVENUE_TOTAL_ROW_NUMBER,
    titleClassName: 'title-first bigger-row-label',
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    exprPair: '=@@2*(1+@3)',
    defaultValue: '0',
    isRequired: true,
    dbDecimalPlaces: 2,
    maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
    rowNumberPair: constants.REVENUE_GROWTH_ROW_NUMBER,
  },
  {
    titleClassName: 'title-second',
    value: constants.REVENUE_GROWTH_TITLE,
    alias: constants.REVENUE_GROWTH_ALIAS,
    rowNumber: constants.REVENUE_GROWTH_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: dcfPercentFormatWithNegativeValues,
    gridType: 'percentage',
    dbType: 'string',
    allowNegativeValue: true,
    exprPair: '=(@2/@@2)-1',
    checkPrevCol: true,
    defaultValue: '0',
    dbDecimalPlaces: 4,
    rowNumberPair: constants.REVENUE_TOTAL_ROW_NUMBER,
  },
  {
    titleClassName: 'title-first',
    value: constants.EBITDA_TOTAL_TITLE,
    alias: constants.EBITDA_TOTAL_ALIAS,
    rowNumber: constants.EBITDA_TOTAL_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '',
    defaultValue: '0',
    isRequired: true,
    maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
  },
  {
    titleClassName: 'title-second',
    value: constants.EBITDA_MARGIN_TITLE,
    alias: constants.EBITDA_MARGIN_ALIAS,
    rowNumber: constants.EBITDA_MARGIN_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: dcfPercentFormatWithNegativeValues,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '=@4/@2',
    defaultValue: '0',
    dbDecimalPlaces: 3,
  },
  {
    titleClassName: 'title-first',
    value: constants.EBIT_TOTAL_TITLE,
    alias: constants.EBIT_TOTAL_ALIAS,
    rowNumber: constants.EBIT_TOTAL_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '=@4-(@8+@10)',
    defaultValue: '0',
    isRequired: true,
    maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
    dbDecimalPlaces: 2,
  },
  {
    titleClassName: 'title-second',
    className: 'division-bottom-only',
    value: constants.EBIT_CASH_TAXES_TITLE,
    alias: constants.EBIT_CASH_TAXES_ALIAS,
    rowNumber: constants.EBIT_CASH_TAXES_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: `=-MAX(@6*${cashTaxRate?.effective_tax_rate},0)`,
    defaultValue: '0',
    sideComponent: <EffectiveTaxRateChip cashTaxRate={cashTaxRate} />,
  },
  {
    titleClassName: 'title-third',
    value: constants.EBIT_DEPRECIATION_EXPENSES_TOTAL_TITLE,
    alias: constants.EBIT_DEPRECIATION_EXPENSES_TOTAL_ALIAS,
    rowNumber: constants.EBIT_DEPRECIATION_EXPENSES_TOTAL_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    exprPair: '=@9*@2',
    defaultValue: '0',
    rowNumberPair: constants.EBIT_DEPRECIATION_EXPENSES_PERCENT_ROW_NUMBER,
  },
  {
    titleClassName: 'title-third',
    value: constants.EBIT_DEPRECIATION_EXPENSES_PERCENT_TITLE,
    alias: constants.EBIT_DEPRECIATION_EXPENSES_PERCENT_ALIAS,
    rowNumber: constants.EBIT_DEPRECIATION_EXPENSES_PERCENT_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: dcfPercentFormatWithNegativeValues,
    gridType: 'percentage',
    dbType: 'string',
    allowNegativeValue: true,
    exprPair: '=@8/@2',
    defaultValue: '0',
    dbDecimalPlaces: 4,
    maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
    rowNumberPair: constants.EBIT_DEPRECIATION_EXPENSES_TOTAL_ROW_NUMBER,
  },
  {
    titleClassName: 'title-third',
    value: constants.EBIT_AMORTIZATION_EXPENSES_TOTAL_TITLE,
    alias: constants.EBIT_AMORTIZATION_EXPENSES_TOTAL_ALIAS,
    rowNumber: constants.EBIT_AMORTIZATION_EXPENSES_TOTAL_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    exprPair: '=@11*@2',
    defaultValue: '0',
    dbDecimalPlaces: 2,
    rowNumberPair: constants.EBIT_AMORTIZATION_EXPENSES_PERCENT_ROW_NUMBER,
  },
  {
    titleClassName: 'title-third',
    value: constants.EBIT_AMORTIZATION_EXPENSES_PERCENT_TITLE,
    alias: constants.EBIT_AMORTIZATION_EXPENSES_PERCENT_ALIAS,
    rowNumber: constants.EBIT_AMORTIZATION_EXPENSES_PERCENT_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: dcfPercentFormatWithNegativeValues,
    gridType: 'percentage',
    dbType: 'string',
    allowNegativeValue: true,
    exprPair: '=@10/@2',
    defaultValue: '0',
    dbDecimalPlaces: 4,
    maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
    rowNumberPair: constants.EBIT_AMORTIZATION_EXPENSES_TOTAL_ROW_NUMBER,
  },
  {
    titleClassName: 'title-second',
    className: 'division-bottom-only',
    value: constants.PLUS_DEPRECATION_AMORTIZATION_EXPENSE_TITLE,
    alias: constants.PLUS_DEPRECATION_AMORTIZATION_EXPENSE_ALIAS,
    rowNumber: constants.PLUS_DEPRECATION_AMORTIZATION_EXPENSE_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '=@8+@10',
    defaultValue: '0',
  },
  {
    titleClassName: 'title-third',
    value: constants.EBIT_CAPITAL_EXPENSES_TOTAL_TITLE,
    alias: constants.EBIT_CAPITAL_EXPENSES_TOTAL_ALIAS,
    rowNumber: constants.EBIT_CAPITAL_EXPENSES_TOTAL_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    exprPair: '=@14*@2',
    defaultValue: '0',
    rowNumberPair: constants.EBIT_CAPITAL_EXPENSES_PERCENT_ROW_NUMBER,
  },
  {
    titleClassName: 'title-third',
    value: constants.EBIT_CAPITAL_EXPENSES_PERCENT_TITLE,
    alias: constants.EBIT_CAPITAL_EXPENSES_PERCENT_ALIAS,
    rowNumber: constants.EBIT_CAPITAL_EXPENSES_PERCENT_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: dcfPercentFormatWithNegativeValues,
    gridType: 'percentage',
    dbType: 'string',
    allowNegativeValue: true,
    exprPair: '=@13/@2',
    defaultValue: '0',
    dbDecimalPlaces: 4,
    maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
    rowNumberPair: constants.EBIT_CAPITAL_EXPENSES_TOTAL_ROW_NUMBER,
  },
  {
    titleClassName: 'title-third',
    value: constants.EBIT_ADDITIONS_TO_INTANGIBLES_TOTAL_TITLE,
    alias: constants.EBIT_ADDITIONS_TO_INTANGIBLES_TOTAL_ALIAS,
    rowNumber: constants.EBIT_ADDITIONS_TO_INTANGIBLES_TOTAL_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    exprPair: '=@16*@2',
    defaultValue: '0',
    rowNumberPair: constants.EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_ROW_NUMBER,
  },
  {
    titleClassName: 'title-third',
    value: constants.EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_TITLE,
    alias: constants.EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_ALIAS,
    rowNumber: constants.EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: dcfPercentFormatWithNegativeValues,
    gridType: 'percentage',
    dbType: 'string',
    allowNegativeValue: true,
    exprPair: '=@15/@2',
    defaultValue: '0',
    dbDecimalPlaces: 4,
    maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
    rowNumberPair: constants.EBIT_ADDITIONS_TO_INTANGIBLES_TOTAL_ROW_NUMBER,
  },
  {
    titleClassName: 'title-second',
    className: 'division-bottom-only',
    value: constants.LESS_CAPITAL_EXPENDITURES_ADDITIONS_TITLE,
    alias: constants.LESS_CAPITAL_EXPENDITURES_ADDITIONS_ALIAS,
    rowNumber: constants.LESS_CAPITAL_EXPENDITURES_ADDITIONS_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '=-(@13+@15)',
    defaultValue: '0',
  },
  {
    titleClassName: 'title-third',
    value: constants.EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_TITLE,
    alias: constants.EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_ALIAS,
    rowNumber: constants.EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    exprPair: '=@19*@2',
    defaultValue: '0',
    rowNumberPair: constants.EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ROW_NUMBER,
  },
  {
    titleClassName: 'title-third',
    value: constants.EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_TITLE,
    alias: constants.EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ALIAS,
    rowNumber: constants.EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: dcfPercentFormatWithNegativeValues,
    gridType: 'percentage',
    dbType: 'string',
    allowNegativeValue: true,
    exprPair: '=@18/@2',
    defaultValue: '0',
    dbDecimalPlaces: 4,
    maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
    rowNumberPair: constants.EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_ROW_NUMBER,
  },
  {
    titleClassName: 'title-second',
    className: 'division-bottom-only',
    value: constants.INCREASE_DECREASE_CASH_FREE_NET_WORKING_CAPITAL_TITLE,
    alias: constants.INCREASE_DECREASE_CASH_FREE_NET_WORKING_CAPITAL_ALIAS,
    rowNumber: constants.INCREASE_DECREASE_CASH_FREE_NET_WORKING_CAPITAL_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '=@@18-@18',
    defaultValue: '0',
  },
  {
    titleClassName: 'title-first',
    value: constants.NET_CASH_FLOWS_TITLE,
    alias: constants.NET_CASH_FLOWS_ALIAS,
    rowNumber: constants.NET_CASH_FLOWS_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '=@6+@7+@12+@17+@20',
    defaultValue: '0',
  },
  {
    titleClassName: 'title-second',
    value: constants.CASH_FLOW_GROWTH_TITLE,
    alias: constants.CASH_FLOW_GROWTH_ALIAS,
    rowNumber: constants.CASH_FLOW_GROWTH_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: dcfPercentFormatWithNegativeValues,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '=IF(@@21, (@21/@@21)-1, 0)',
    defaultValue: '0',
    dbDecimalPlaces: 3,
  },
  {
    titleClassName: 'title-first',
    value: constants.CASH_FLOW_REMAINING_TITLE,
    alias: constants.CASH_FLOW_REMAINING_ALIAS,
    rowNumber: constants.CASH_FLOW_REMAINING_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '=@21',
    defaultValue: '0',
  },
  {
    titleClassName: 'title-first-alternative',
    value: constants.DISCOUNT_PERIODS_TITLE,
    alias: constants.DISCOUNT_PERIODS_ALIAS,
    rowNumber: constants.DISCOUNT_PERIODS_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: standardDecimalFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '',
    defaultValue: '0',
    dbDecimalPlaces: 2,
  },
  {
    titleClassName: 'title-first-alternative',
    value: constants.DISCOUNT_FACTOR_TITLE,
    alias: constants.DISCOUNT_FACTOR_ALIAS,
    rowNumber: constants.DISCOUNT_FACTOR_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: standardDecimalFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    // eslint-disable-next-line no-restricted-globals
    expr: `=(1+${dcfWaccSheet.name}.wacc) ^ (-1 * @24)`,
    defaultValue: '0',
    dbDecimalPlaces: 2,
    sideComponent: <WACCChip dcfWaccSheet={dcfWaccSheet} />,
  },
  {
    titleClassName: 'title-first',
    className: 'division-bottom-only division-top-only',
    value: constants.TOTAL_CASH_FLOWS_TITLE,
    alias: constants.TOTAL_CASH_FLOWS_ALIAS,
    rowNumber: constants.TOTAL_CASH_FLOWS_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '=@23*@25',
    defaultValue: '0',
    dbDecimalPlaces: 2,
  },
];

export default getRowConfig;
