/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MethodologyPSV } from './MethodologyPSV';

export type AppliedMethodology = {
  readonly id?: number;
  valuation_approach_backsolve?: number;
  present_share_values: Array<MethodologyPSV>;
  deleted_present_share_values?: Array<number>;
  is_deleted?: boolean | null;
  readonly deleted_at?: string | null;
  weight: string;
  allocation_method: AppliedMethodology.allocation_method;
  maturity?: string | null;
  volatility?: string | null;
  cap_table: number;
};

export namespace AppliedMethodology {
  export enum allocation_method {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
  }
}
