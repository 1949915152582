import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { GridDate as GridDateBase } from 'components/Grid';

const useStyles = makeStyles({
  dateIcon: {
    '& > span > svg': {
      fill: '#fff !important',
    },
  },
});

const FinancialsDate = ({ cell }) => {
  const classes = useStyles();
  const { onCellsChanged } = useContext(FeaturedSpreadsheetContext);

  const handleOnChange = date => {
    onCellsChanged([{ cell, value: date }]);
  };

  const { value, readOnly, disabled } = cell;

  return (
    <GridDateBase
      className={classes.dateIcon}
      value={value}
      readOnly={readOnly}
      disabled={disabled}
      onCellsChanged={handleOnChange}
    />
  );
};

FinancialsDate.propTypes = {
  cell: PropTypes.object,
};

export default FinancialsDate;
