/* eslint-disable no-param-reassign */

const updateAcquisitionInColumnCells = ({
  fundOwnershipDetails,
  reverseParsedAcquisitions,
  cell,
  startingAcquisitions,
  deletedAcquisitions,
}) => {
  // recover ids for objects
  const currentFod = fundOwnershipDetails.find(({ columnRef }) => columnRef === cell.columnRef);
  const updatedAcquisitions = reverseParsedAcquisitions.map(acquisition => ({
    ...acquisition,
    id: startingAcquisitions.find(acq => acq.acquisition_ref === acquisition.acquisition_ref)?.id ?? 0,
    fund_ownership_detail: currentFod?.id ?? undefined,
  }));
  // this should update the acquisitions information for the whole column
  const { columnLegend: currentColumnLegend, sheet } = cell;

  Object.values(sheet.cells)
    .filter(({ columnLegend }) => currentColumnLegend === columnLegend)
    .forEach(cell => {
      cell.acquisitions = updatedAcquisitions;
      cell.deleted_acquisitions = deletedAcquisitions;
    });
};

export default updateAcquisitionInColumnCells;
