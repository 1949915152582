import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  spreadsheet: {
    '& td.row-label': {
      minWidth: '275px',
    },
  },
  marginTopMedium: {
    marginTop: '36px',
  },
  marginTopSmall: {
    marginTop: '28px',
  },
  marginBottom: {
    marginBottom: '36px',
  },
  padLeft: {
    paddingLeft: '25px',
  },
  blueText: {
    color: theme.palette.primary[600],
  },
}));
