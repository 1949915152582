/**
 * @function
 * @name setAllocationCompany
 * @description reducer for action.
 * @param {Object} state  - reducer state
 * @param {Object} action - action context
 * @return {OBJECT} Action context
 */
export const setFirmInfo = (state, action) => ({
  ...state,
  ...action
});

export const setFirmInfoById = (state, action) => ({
  ...state,
  ...action
});

export const getFirmList = (state, action) => ({
  ...state,
  ...action
});

export const getUserManagementFirmList = (state, action) => ({
  ...state,
  ...action
});

export const getSelectedUserPermissions = (state, action) => ({
  ...state,
  ...action
});

export const getFundListByFirmId = (state, action) => ({
  ...state,
  ...action
});

export const setFirmId = (state, action) => ({
  ...state,
  ...action
});

export const setFirmList = (state, action) => ({
  ...state,
  ...action
});

export const setUserManagementFirmList = (state, action) => ({
  ...state,
  ...action
});

export const setSelectedUserPermissions = (state, action) => ({
  ...state,
  ...action
});
