import { ValuationsApproach } from 'common/types/valuation';
import {
  VALUATIONS_PUBLIC_COMPANIES_APPROACH,
  VALUATIONS_SPECIFIED_ID,
  VALUATIONS_SPECIFIED_LABEL,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { getArrayValue, getStringValue } from 'utillities';

const getGPCOptions = (approaches?: ValuationsApproach[]) => {
  const gpcApproaches = approaches?.filter(
    valuationsApproach => valuationsApproach?.approach_type === VALUATIONS_PUBLIC_COMPANIES_APPROACH
  );

  const gpcApproachesOptions = gpcApproaches?.map(gpcApproach => ({
    id: gpcApproach?.valuations_approach_gpc?.id || gpcApproach?.panelId,
    name: getStringValue(gpcApproach?.name),
  }));

  const gpcOptions = [
    { id: VALUATIONS_SPECIFIED_ID, name: VALUATIONS_SPECIFIED_LABEL },
    ...getArrayValue(gpcApproachesOptions),
  ];

  return {
    gpcApproaches,
    gpcOptions,
  };
};

export default getGPCOptions;
