import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useFinancials } from 'context/FinancialsContext';

const useStyles = makeStyles(theme => ({
  chip: {
    fontSize: '0.775rem',
    zIndex: 2,
    padding: `${theme.spacing(0.25)}px 0`,
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  chipSelected: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white,
  },
}));

const AdjustEBITDAChip = ({ theme }) => {
  const { useAdjustedEBITDA, setUseAdjustedEBITDA } = useFinancials();
  const classes = useStyles();
  const handleClick = () => {
    setUseAdjustedEBITDA(!useAdjustedEBITDA);
  };

  return (
    <Chip
      style={
        useAdjustedEBITDA
          ? { backgroundColor: theme.palette.primary.main, color: theme.palette.white }
          : { backgroundColor: theme.palette.white, color: theme.palette.primary.main }
      }
      label="Adjust"
      clickable
      color="primary"
      size="small"
      onClick={handleClick}
      variant="outlined"
      className={classes.chip}
    />
  );
};

AdjustEBITDAChip.propTypes = {
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string.isRequired,
      }).isRequired,
      white: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withTheme(AdjustEBITDAChip);
