import React from 'react';
import { useLocation } from 'react-router-dom';
import { notFoundPath } from 'common/config/urls';
import { ErrorComponent } from 'components';

const NotFound = () => {
  const location = useLocation();

  const isNotFound = location.pathname === notFoundPath;
  const errorCode = ':(';
  const errorTitle = isNotFound ? 'Page not found' : 'Access denied';

  const body = isNotFound
    ? 'Sorry, we cannot find the page you were looking for'
    : 'You do not have permission to view this page or access this resource';

  return <ErrorComponent title={errorCode} subtitle={errorTitle} body={body} />;
};

export default NotFound;
