import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';

const MarketAdjustmentTable = ({ spreadsheets, onChange, format, formatDispatch }) => {
  const { marketAdjustmentSheet } = spreadsheets;

  const onSheetChange = useCallback(
    (cell, value) => {
      onChange(cell, value);
    },
    [onChange]
  );

  return (
    <ScalarSpreadsheet
      {...marketAdjustmentSheet}
      id="market-adjustment-table"
      tableTerms={{ tableSlug: 'market-adjustment-table' }}
      onChange={onSheetChange}
      sheet={marketAdjustmentSheet}
      displayLegend={false}
      alwaysDisplayLegend={false}
      format={format}
      formatDispatch={formatDispatch}
    />
  );
};

export default MarketAdjustmentTable;

MarketAdjustmentTable.propTypes = {
  spreadsheets: PropTypes.object,
  onChange: PropTypes.func,
  format: PropTypes.object,
  formatDispatch: PropTypes.func,
};
