import { isUndefined } from 'lodash';
import {
  FISCAL_QUARTER,
  FISCAL_YEAR,
  FISCAL_YEAR_PREFIX,
  FORMAT_YEAR_TITLE,
  LTM,
  LTM_QUARTER,
  NTM,
  NTM_QUARTER,
  SECOND_ROW_ALIAS,
} from 'common/constants/financials';

export const getPeriodTypeFromIncomeStatement = incomeStatementColumn => {
  const { period_type, isLTMYear, isNTMYear, isLTMQuarter, isNTMQuarter, quarter } = incomeStatementColumn;
  if (period_type) return period_type;
  if (isLTMYear) return LTM;
  if (isNTMYear) return NTM;
  if (isLTMQuarter) return LTM_QUARTER;
  if (isNTMQuarter) return NTM_QUARTER;
  if (!isUndefined(quarter)) return FISCAL_QUARTER;
  return FISCAL_YEAR;
};

export const getSuffixFromIncomeStatement = (incomeStatementColumn, periodType) => {
  const { date, subtitle, isProjectedYear, name } = incomeStatementColumn;

  // Is a historical year
  if (periodType === FISCAL_YEAR && !isProjectedYear) {
    // If name already has FISCAL_YEAR_PREFIX, return name
    if (name.includes(FISCAL_YEAR_PREFIX)) return `-${name}`;
    return `-${FORMAT_YEAR_TITLE(name)}`;
  }

  // Is a quarter
  if (periodType === FISCAL_QUARTER) {
    let [M, D, Y] = subtitle.split('-');
    if ([M, D, Y].every(value => !isUndefined(value))) return `-${Y}-${M}-${D}`;
    [M, D, Y] = date.split('-');
    return `-${Y}-${M}-${D}`;
  }

  // Is a LTM or NTM quarter
  if ([LTM_QUARTER, NTM_QUARTER].includes(periodType)) {
    const [M, D, Y] = date.split('-');
    return `-${Y}-${M}-${D}`;
  }

  // Is a projection year
  // If name already has FISCAL_YEAR_PREFIX, return name
  if (subtitle.includes(FISCAL_YEAR_PREFIX)) return `-${subtitle}`;
  return `-${FORMAT_YEAR_TITLE(subtitle)}`;
};

export const getMapKeyFromIncomeStatement = incomeStatementColumn => {
  const periodType = getPeriodTypeFromIncomeStatement(incomeStatementColumn);

  if ([LTM, NTM].includes(periodType)) return periodType;

  const suffix = getSuffixFromIncomeStatement(incomeStatementColumn, periodType);

  return `${periodType}${suffix}`;
};

export const generateKeyForMap = (periodType, statementDate) => {
  if (periodType === FISCAL_YEAR) {
    const [year] = statementDate.split('-');
    return `${periodType}-${FORMAT_YEAR_TITLE(year)}`;
  }
  if ([LTM, NTM].includes(periodType)) return periodType;
  return `${periodType}-${statementDate}`;
};

/**
 *
 * @param {Array} validPeriods
 * @param {boolean} addHistoricalYear
 * @returns {number} index of the reference period
 */
export const getReferenceIndex = (validPeriods, addHistoricalYear) => {
  // returning 0 would return the first historical quarter
  if (addHistoricalYear) return 4;

  for (let index = validPeriods.length - 1; index >= 0; index -= 1) {
    if (
      validPeriods[index]?.is_projection
      && validPeriods[index]?.isParent
      && validPeriods[index]?.isLTM !== true
      && validPeriods[index]?.isNTM !== true
      && validPeriods[index]?.is_deleted !== true
    ) {
      return index;
    }
  }
};

export const getColumnValue = ({ rowAlias, isColumnWithReportedDate, column }) =>
  rowAlias === SECOND_ROW_ALIAS && isColumnWithReportedDate && column.reported_statement_date
    ? column.reported_statement_date
    : column[rowAlias];
