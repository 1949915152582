/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AggregateValue } from './AggregateValue';
import type { FutureShareValue } from './FutureShareValue';
import type { PresentShareValue } from './PresentShareValue';

export type ValuationApproachFutureExit = {
  readonly id?: number;
  valuation_approach: number;
  present_share_values?: Array<PresentShareValue>;
  future_share_values?: Array<FutureShareValue>;
  aggregate_values?: Array<AggregateValue>;
  deleted_present_share_value_ids?: Array<number>;
  deleted_future_share_value_ids?: Array<number>;
  deleted_aggregate_value_ids?: Array<number>;
  financials_metric: string;
  referenced_approach_multiple: string;
  is_deleted?: boolean | null;
  readonly deleted_at?: string | null;
  financials_metric_value?: string | null;
  referenced_approach_multiple_value?: string | null;
  less_debt?: string | null;
  plus_cash?: string | null;
  present_enterprise_value?: string | null;
  discount_rate?: string | null;
  dilution_percentage?: string | null;
  exit_date?: string | null;
  allocation_method?: ValuationApproachFutureExit.allocation_method | null;
  maturity?: string | null;
  volatility?: string | null;
  valuation_approach_gpc?: number | null;
  valuation_approach_gpt?: number | null;
  volatility_source?: number | null;
  cap_table?: number | null;
};

export namespace ValuationApproachFutureExit {
  export enum allocation_method {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
  }
}
