export const PREFERRED_STOCK = 1;
export const COMMON_STOCK = 2;
export const WARRANT = 3;
export const OPTION = 4;
export const UNISSUED_OPTIONS = 5;
export const CONVERTIBLE_NOTES = 6;

export const TYPES = [PREFERRED_STOCK, COMMON_STOCK, WARRANT, OPTION, UNISSUED_OPTIONS, CONVERTIBLE_NOTES];

export const LIST = ['Preferred Stock', 'Common Stock', 'Warrant', 'Option', 'Unissued Options', 'Convertible Note'];
