import { isNull, isUndefined } from 'lodash';
import { VALUATIONS_OTHER_LABEL } from 'common/constants/valuations';
import {
  PV_OF_TERMINAL_VALUE_CUSTOM_KEY,
  TERMINAL_VALUE,
  TERMINAL_VALUE_CUSTOM_KEY,
} from 'pages/Valuations/approaches/discountCashFlow/utilities/constants';
import {
  EBITDA_GPC_MULTIPLE,
  GPC_LTM_EBITDA_COLUMN_LEGEND,
  GPC_LTM_REVENUE_COLUMN_LEGEND,
  GPC_MULTIPLE,
  GPT_EBITDA_COLUMN_LEGEND,
  GPT_REVENUE_COLUMN_LEGEND,
  multipleAliases,
  PUBLIC_COMPANIES,
  PV_OF_TERMINAL_VALUE,
  REM_TVT_SECOND_COLUMN,
  selectMultipleAliases,
} from 'pages/Valuations/util/constants';
import { mapVariableLabelsToSelectionIndex } from 'pages/Valuations/util/percentileOptionsTools';
import { getValueByPropOrFallback, parseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import swapMap from 'utillities/swapMap';
import obtainMapForSelectMultipleAlias from './obtainMapForSelectMultipleAlias';

function getValue(column, row) {
  return !isUndefined(column[row.alias]) ? column[row.alias] : null;
}

export function getBenchmarkColumnLegend(benchmarkType, rowAlias, columnLegend) {
  let benchmarkColumnLegend
    = benchmarkType === PUBLIC_COMPANIES ? GPC_LTM_REVENUE_COLUMN_LEGEND : GPT_REVENUE_COLUMN_LEGEND;
  // this happens when we have the Revenue && EBITDA TVT
  if (rowAlias === GPC_MULTIPLE && benchmarkType === PUBLIC_COMPANIES && columnLegend === REM_TVT_SECOND_COLUMN)
    return GPC_LTM_EBITDA_COLUMN_LEGEND;
  if (rowAlias === EBITDA_GPC_MULTIPLE) {
    benchmarkColumnLegend
      = benchmarkType === PUBLIC_COMPANIES ? GPC_LTM_EBITDA_COLUMN_LEGEND : GPT_EBITDA_COLUMN_LEGEND;
  }
  return benchmarkColumnLegend;
}

function getBenchmarkExpression(row, columnLegend, multipleSelection) {
  const { alias, expr, benchmarkType } = row;
  if (multipleAliases.includes(alias) && multipleSelection !== VALUATIONS_OTHER_LABEL) {
    const blueprint = expr;
    // column legends depend on benchmark type
    const benchmarkColumnLegend = getBenchmarkColumnLegend(benchmarkType, alias, columnLegend);
    if (blueprint) {
      const benchmarkExpression = blueprint.replace(/#/g, `benchmark.${benchmarkColumnLegend}`);
      return benchmarkExpression.replace(/@/g, columnLegend);
    }
  }
  return '';
}

function getExpr(row, columnLegend) {
  return row.expr ? row.expr.replace(/@/g, columnLegend) : '';
}

function getCellValue(pickedSelectionValue, parsedValue) {
  if (!isNull(pickedSelectionValue)) {
    return pickedSelectionValue;
  }
  return parsedValue;
}

function getMultipleSelectionAliasForMultiple(multipleAlias) {
  if (!multipleAliases.includes(multipleAlias)) return null;
  const invertedMap = swapMap(obtainMapForSelectMultipleAlias());
  return invertedMap.get(multipleAlias);
}

function updateReadOnlyState(cellObject, makeEditable) {
  if (!makeEditable) return cellObject;
  return { ...cellObject, readOnly: !makeEditable };
}

const getExpressionToUse = ({ rowConfigExpression, benchmarkBasedExpression, multipleSelectedIsOther }) => {
  if (multipleSelectedIsOther) return '';
  return benchmarkBasedExpression || rowConfigExpression;
};

const getParser
  = (cellUpdateMixin, cfTVTRMSheetName, dcfTVTEMSheetName) =>
    ({ columns, rowConfig }) => {
      const alphabet = alphabetGenerator([], columns.length);
      let cells = {};

      const handleRow = (column, columnLegend) => (row, rowIndex) => {
        const rowNumber = rowIndex + 1;
        const key = columnLegend + rowNumber;
        const value = getValue(column, row);
        const type = getValueByPropOrFallback(row, 'gridType', null);

        let customKey;

        if (row.alias === PV_OF_TERMINAL_VALUE) {
          customKey = PV_OF_TERMINAL_VALUE_CUSTOM_KEY;
        }
        if (row.alias === TERMINAL_VALUE) {
          customKey = TERMINAL_VALUE_CUSTOM_KEY;
        }

        /* check if selection for the multiple was "Other".
    We don't want a complex expression if that's the case */
        const multipleSelectionAlias = getMultipleSelectionAliasForMultiple(row.alias);
        const multipleSelection = column[multipleSelectionAlias];
        const multipleSelectedIsOther = multipleSelection === VALUATIONS_OTHER_LABEL;
        const benchmarkBasedExpression = getBenchmarkExpression(row, columnLegend, multipleSelection);

        const expression = getExpressionToUse({
          benchmarkBasedExpression,
          rowConfigExpression: getExpr(row, columnLegend),
          multipleSelectedIsOther,
        });

        const parsedValue = parseValue(value, type, null, null, row.dbType);

        const options = rowConfig.find(({ alias }) => multipleAliases.includes(alias))?.options;
        const percentileSelections = rowConfig.find(({ alias }) =>
          selectMultipleAliases.includes(alias)
        )?.percentileSelections;
        const pickedSelectionValue = selectMultipleAliases.includes(row.alias)
          ? mapVariableLabelsToSelectionIndex({
            selection: value,
            options,
            specificApproach: percentileSelections,
          })
          : null;

        let cell = {
          ...row,
          key,
          type,
          alias: getValueByPropOrFallback(row, 'alias', ''),
          className: '',
          customKey,
          columnId: column.id,
          parentColumn: column.parentColumn,
          columnLegend,
          columnOrder: column.order,
          rowNumber,
          expr: expression,
          value: getCellValue(pickedSelectionValue, parsedValue),
        };

        cell = updateReadOnlyState(cell, multipleSelectedIsOther);

        if (cellUpdateMixin) {
          cell = cellUpdateMixin(cell, cfTVTRMSheetName, dcfTVTEMSheetName);
        }
        if (!cell.ignoreCell) {
          cells = {
            ...cells,
            [key]: cell,
          };
        }
      };

      // Parse body cells
      columns.forEach((column, columnIndex) => {
        const columnLegend = alphabet[columnIndex];
        rowConfig.forEach(handleRow(column, columnLegend));
      });

      return cells;
    };

export default getParser;
