export const columns = [
  {
    id: 'file_type',
    label: '',
    isSortable: false,
    align: 'center',
    disablePadding: true,
  },
  {
    id: 'filename',
    label: 'Name',
    isSortable: true,
    align: 'left',
    disablePadding: false,
  },
  {
    id: 'uploaded_by',
    label: 'Uploaded By',
    isSortable: true,
    align: 'left',
    disablePadding: false,
  },
  {
    id: 'uploaded_date',
    label: 'Upload Date',
    isSortable: true,
    align: 'left',
    disablePadding: false,
  },
  {
    id: 'references',
    label: 'References',
    isSortable: false,
    align: 'left',
    disablePadding: false,
  },
  {
    id: 'actions',
    label: 'Actions',
    isSortable: false,
    align: 'right',
    disablePadding: false,
  },
];
