import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    navigation: {
      backgroundColor: theme.palette.primary[600],
      overflowX: 'hidden',
    },
    container: {
      margin: 0,
      padding: `0px ${theme.spacing(2)}px`,
      // width: '100%',
    },
    tabsContainer: {
      padding: '0px !important',
    },
    tabsItems: {
      // '& div[role="tablist"]': {
      //   overflowX: 'scroll'
      // },
    },
    tabItem: {
      color: '#80A4C5',
      fontSize: '12px',
      fontWeight: 600,
      minWidth: 0,
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,

      '&:hover': {
        color: theme.palette.white,
      },
    },
    selected: {
      color: theme.palette.white,
    },
    navChildren: {},
  })
);

export default useStyles;
