import { AllocationScenarioMethods } from 'pages/ValuationsAllocation/allocation/EquityAllocation/config';
import { generateColumnKey } from 'utillities';

export const ALLOCATION_ALLOCATION_METHOD_PREFIX = 'Allocation Method';

// Allocation Scenario Methods
export const ALLOCATION_SCENARIO_METHOD_WATERFALL = 0;
export const ALLOCATION_SCENARIO_METHOD_CSE = 1;
export const ALLOCATION_SCENARIO_METHOD_OPM = 2;
export const ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES = 3;

export const ALLOCATION_SCENARIO_METHOD_WATERFALL_NAME = 'Waterfall';
export const ALLOCATION_SCENARIO_METHOD_CSE_NAME = 'CSE';
export const ALLOCATION_SCENARIO_METHOD_OPM_NAME = 'OPM';
export const ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES_NAME = 'Specified Share Values';

export const ALLOCATION_SCENARIO_METHOD_OPM_KEY = generateColumnKey({
  id: ALLOCATION_SCENARIO_METHOD_OPM,
  name: ALLOCATION_SCENARIO_METHOD_OPM_NAME,
  prefix: ALLOCATION_ALLOCATION_METHOD_PREFIX,
});

export const ALLOCATION_SCENARIO_METHOD_WATERFALL_KEY = generateColumnKey({
  id: ALLOCATION_SCENARIO_METHOD_WATERFALL,
  name: ALLOCATION_SCENARIO_METHOD_WATERFALL_NAME,
  prefix: ALLOCATION_ALLOCATION_METHOD_PREFIX,
});

export const ALLOCATION_SCENARIO_METHOD_CSE_KEY = generateColumnKey({
  id: ALLOCATION_SCENARIO_METHOD_CSE,
  name: ALLOCATION_SCENARIO_METHOD_CSE_NAME,
  prefix: ALLOCATION_ALLOCATION_METHOD_PREFIX,
});

// Allocation Scenario Types
export const ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT = 0;
export const ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE = 1;
export const ALLOCATION_SCENARIO_TYPE_BACKSOLVE = 2;
export const ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES = 3;

export const ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT_NAME = 'Future Exit';
export const ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE_NAME = 'Current Value';
export const ALLOCATION_SCENARIO_TYPE_BACKSOLVE_NAME = 'Backsolve';
export const ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES_NAME = 'Specified Share Values';

export const ALLOCATION_SCENARIO_TYPE_BACKSOLVE_KEY = generateColumnKey({
  id: ALLOCATION_SCENARIO_METHOD_WATERFALL,
  name: ALLOCATION_SCENARIO_TYPE_BACKSOLVE_NAME,
  prefix: ALLOCATION_ALLOCATION_METHOD_PREFIX,
});

export const ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT_KEY = generateColumnKey({
  id: ALLOCATION_SCENARIO_METHOD_WATERFALL,
  name: ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT_NAME,
  prefix: ALLOCATION_ALLOCATION_METHOD_PREFIX,
});

export const ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES_KEY = generateColumnKey({
  id: ALLOCATION_SCENARIO_METHOD_WATERFALL,
  name: ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES_NAME,
  prefix: ALLOCATION_ALLOCATION_METHOD_PREFIX,
});

export const ALLOCATION_SCENARIO_TYPES_NAMES = {
  [ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT]: ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT_NAME,
  [ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE]: ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE_NAME,
  [ALLOCATION_SCENARIO_TYPE_BACKSOLVE]: ALLOCATION_SCENARIO_TYPE_BACKSOLVE_NAME,
  [ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES]: ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES_NAME,
};

export const ALLOCATION_SCENARIO_METHODS_NAMES = {
  [ALLOCATION_SCENARIO_METHOD_WATERFALL]: ALLOCATION_SCENARIO_METHOD_WATERFALL_NAME,
  [ALLOCATION_SCENARIO_METHOD_CSE]: ALLOCATION_SCENARIO_METHOD_CSE_NAME,
  [ALLOCATION_SCENARIO_METHOD_OPM]: ALLOCATION_SCENARIO_METHOD_OPM_NAME,
  [ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES]: ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES_NAME,
};

export const ALLOCATION_SCENARIO_METHODS = Object.freeze([
  {
    id: ALLOCATION_SCENARIO_METHOD_OPM_KEY,
    name: ALLOCATION_SCENARIO_METHOD_OPM_NAME,
    scenarioMethod: ALLOCATION_SCENARIO_METHOD_OPM,
    scenarioType: ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE,
  } as AllocationScenarioMethods,
  {
    id: ALLOCATION_SCENARIO_METHOD_WATERFALL_KEY,
    name: ALLOCATION_SCENARIO_METHOD_WATERFALL_NAME,
    scenarioMethod: ALLOCATION_SCENARIO_METHOD_WATERFALL,
    scenarioType: ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE,
  } as AllocationScenarioMethods,
  {
    id: ALLOCATION_SCENARIO_METHOD_CSE_KEY,
    name: ALLOCATION_SCENARIO_METHOD_CSE_NAME,
    scenarioMethod: ALLOCATION_SCENARIO_METHOD_CSE,
    scenarioType: ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE,
  } as AllocationScenarioMethods,
]);

export const ADD_ALLOCATION_SCENARIO_TYPES = Object.freeze([
  {
    id: ALLOCATION_SCENARIO_TYPE_BACKSOLVE_KEY,
    name: `+ ${ALLOCATION_SCENARIO_TYPE_BACKSOLVE_NAME}`,
    scenarioMethod: ALLOCATION_SCENARIO_METHOD_WATERFALL,
    scenarioType: ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
  } as AllocationScenarioMethods,
  {
    id: ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT_KEY,
    name: `+ ${ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT_NAME}`,
    scenarioMethod: ALLOCATION_SCENARIO_METHOD_WATERFALL,
    scenarioType: ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
  } as AllocationScenarioMethods,
  {
    id: ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES_KEY,
    name: `+ ${ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES_NAME}`,
    scenarioMethod: ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES,
    scenarioType: ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES,
  } as AllocationScenarioMethods,
]);

// Errors

export const ALLOCATION_FORBIDDEN_TITLE = 'You do not have permissions to view the Allocation info';
export const ALLOCATION_FORBIDDEN_TAGLINE = 'Please contact your Manager to gain access';

export const ALLOCATION_EMPTY_FUND_OWNERSHIP_TITLE = 'No Fund Ownership defined for the Primary Cap Table';
export const ALLOCATION_EMPTY_FUND_OWNERSHIP_TAGLINE = 'Add Fund Ownership to see its Share Values';

// Formats

export const ALLOCATION_STATIC_CUSTOM_FORMAT = Object.freeze({
  currency: {
    units: '',
  },
});
