import { useQuery } from '@tanstack/react-query';
import { UsersService } from 'services';

const useGetFirmUsers = (firmId, userSearchValue) => {
  const getFirmUsers = async () => {
    const usersService = new UsersService();
    const firmUsers = await usersService.getUsers(firmId);
    if (!firmUsers) throw new Error('An error occurred while retrieving firm users');
    return firmUsers;
  };

  const { data, isLoading, error } = useQuery({
    enabled: !!firmId,
    queryKey: ['firmUsers', firmId],
    queryFn: () => getFirmUsers(),
    select: firmUsers => firmUsers.filter(user => user.email.includes(userSearchValue)),
  });

  return { data, isLoading, error, getFirmUsers };
};

export default useGetFirmUsers;
