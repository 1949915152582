import React, { ChangeEvent } from 'react';
import { Box, Chip, MenuItem, TextField, Typography } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { SourceResponsibleSelectorProps } from './types';
import useStyles from '../styles';

const SourceResponsibleSelector: React.FC<SourceResponsibleSelectorProps> = ({
  responsibleUser,
  setResponsibleUser,
  responsibleUsers,
  isDisabled,
}) => {
  const classes = useStyles();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setResponsibleUser(Number(event.target.value));
  };

  return (
    <div>
      <Typography variant="body1" className={classes.typographyLabel}>
        Select source responsible for sending information
      </Typography>
      <TextField
        id="outlined-select-responsible-user"
        select
        label="Select"
        variant="outlined"
        value={responsibleUser}
        onChange={handleChange}
        disabled={isDisabled}
        fullWidth>
        {responsibleUsers.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Box display="flex" justifyContent="center" alignItems="center">
                <AccountCircle className={classes.accountCircle} />
                {option.label}
              </Box>
              {option.isPrimaryContact && <Chip label="Primary Contact" color="primary" />}
            </Box>
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default SourceResponsibleSelector;
