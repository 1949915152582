import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api';
import { CreateBulkUserData } from './types';

const useCreateBulkCompanyUsers = () => {
  const queryClient = useQueryClient();
  const createBulkUsersMutation = useMutation({
    mutationFn: ({ companyId, users, sendActivationEmail }: CreateBulkUserData) =>
      ApiService.apiDashboardCompanyAddBulkUserCreate({
        company_id: companyId,
        users,
        send_activation_email: sendActivationEmail,
      }),
    onSuccess: () => {
      // Invalidate the firmUsers query to refetch the data including the new users
      queryClient.invalidateQueries(['companyUsers']);
    },
  });

  const { mutate, isLoading, error, isSuccess } = createBulkUsersMutation;

  if (isSuccess) {
    // Clean the mutation internal state
    createBulkUsersMutation.reset();
  }

  return { mutate, isLoading, error, isSuccess };
};

export default useCreateBulkCompanyUsers;
