/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import { isNull } from 'lodash';
import {
  FIRST_ROW_TITLE,
  FOURTH_ROW_ROW_NUMBER,
  REVENUE_GROWTH_RATE_ALIAS,
  SECOND_ROW_ALIAS,
  THIRD_ROW_ALIAS,
} from 'common/constants/financials';
import { ntmAndLtmHeadersStyle } from 'pages/Financials/data/baseRowConfig';
import { getRowSpan } from 'pages/Financials/utilities';
import { getValueByPropOrFallback, gridShortDate, parseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import getLTMRevenueGrowthRate, { NA_VALUE, QUARTER_AND_YEAR_COLUMN } from './getLTMRevenueGrowthRate';

const FIRST_QUARTER_LABEL = 'Q1';
const LAST_QUARTER_LABEL = 'Q4';

const getExpr = (expr, columnLegend) => (expr ? expr.replace(/@/g, `${columnLegend}`) : '');

// return previous column legend based in if column is first Q, year, ntm, ltm, etc
const getPreviousColumnLegend = (colIndex, columnInformation, alphabet, lastReportedQuarterIndex) => {
  const { name, isParent, isLTMYear } = columnInformation;

  const FIRST_LTM_QUARTER_LABEL = 'LTM Q1';
  const FIRST_NTM_QUARTER_LABEL = 'NTM Q1';
  const LAST_REPORTED_QUARTER_LEGEND = alphabet[lastReportedQuarterIndex];
  const NEIGHBOUR_PARENT_COLUMN_STEP = 5;
  const NEIGHBOUR_QUARTER_COLUMN_STEP = 1;

  // if there's only one historical period, the last quarter has D for column legend
  const moreThanOneHistoricalYear = LAST_REPORTED_QUARTER_LEGEND !== 'D';

  if (isLTMYear && moreThanOneHistoricalYear) return alphabet[lastReportedQuarterIndex - 4];
  if (name === FIRST_LTM_QUARTER_LABEL && moreThanOneHistoricalYear) return alphabet[lastReportedQuarterIndex - 5];
  if (name === FIRST_NTM_QUARTER_LABEL) return LAST_REPORTED_QUARTER_LEGEND;
  if (name === FIRST_QUARTER_LABEL) return alphabet[colIndex - 2];
  // in this case, there isn't a relative expression
  if ((name === FIRST_LTM_QUARTER_LABEL || isLTMYear) && !moreThanOneHistoricalYear) return null;
  return isParent
    ? alphabet[colIndex - NEIGHBOUR_PARENT_COLUMN_STEP]
    : alphabet[colIndex - NEIGHBOUR_QUARTER_COLUMN_STEP];
};

const getRelativeExpr = (expr, columnLegend, columnIndex, column, alphabet, lastReportedQuarterIndex) => {
  const FIRST_QUARTER_COLUMN_LEGEND = 'A';
  const FIRST_YEAR_COLUMN_LEGEND = 'E';
  if ([FIRST_QUARTER_COLUMN_LEGEND, FIRST_YEAR_COLUMN_LEGEND].includes(columnLegend)) return '';

  const previousColumnLegend = getPreviousColumnLegend(columnIndex, column, alphabet, lastReportedQuarterIndex);
  if (isNull(previousColumnLegend)) return '';

  return expr.replace(/#/g, `${columnLegend}`).replace(/@/g, `${previousColumnLegend}`);
};

const getExpressionToUse = ({
  rowAlias,
  rowExpr,
  column,
  columnLegend,
  columnIndex,
  alphabet,
  lastReportedQuarterIndex,
}) =>
  rowAlias === REVENUE_GROWTH_RATE_ALIAS
    ? getRelativeExpr(rowExpr, columnLegend, columnIndex, column, alphabet, lastReportedQuarterIndex)
    : getExpr(rowExpr, columnLegend);

const generateSecondRowConditionalProps = params => {
  const {
    alias,
    alphabet,
    columnIndex,
    columns,
    dateInLTMOrNTM,
    expr,
    fiscalYearData,
    format,
    ignoreExpr,
    isLTM,
    isLTMYear,
    mirrorExpression,
    value,
    incomeStatementColumns,
  } = params;

  let cellExpression = expr;
  let cellFormat = format;
  let cellIgnoreExpr = ignoreExpr;
  let cellTooltipMessages = [];
  let cellValue = value;

  if (alias === REVENUE_GROWTH_RATE_ALIAS && isLTM && isLTMYear) {
    const LTMRevenueGrowthRate = getLTMRevenueGrowthRate({
      alphabet,
      columns,
      fiscalYearData,
      format: cellFormat,
      ignoreExpr: cellIgnoreExpr,
      value: cellValue,
      incomeStatementColumns,
    });

    cellExpression = LTMRevenueGrowthRate.expr;
    cellFormat = LTMRevenueGrowthRate.format;
    cellIgnoreExpr = LTMRevenueGrowthRate.ignoreExpr;
    cellTooltipMessages = LTMRevenueGrowthRate.tooltipMessages;
    cellValue = LTMRevenueGrowthRate.value;
  }

  const secondRowConditionalProps = dateInLTMOrNTM
    ? {
      expr: mirrorExpression,
      ignoreExpr: false,
    }
    : {
      expr: cellExpression,
      ignoreExpr: cellIgnoreExpr,
    };

  let result = {
    ...secondRowConditionalProps,
    format: cellFormat,
    tooltipMessages: cellTooltipMessages,
    value: cellValue,
  };

  // If is the first FY/Quarter column, then we need to set the value to N/A
  if (alias === REVENUE_GROWTH_RATE_ALIAS && [0, QUARTER_AND_YEAR_COLUMN - 1].includes(columnIndex)) {
    result = {
      ...secondRowConditionalProps,
      ...NA_VALUE(cellTooltipMessages),
    };
  }

  return result;
};

const parser = async ({ columns = [], rowConfig, fiscalYearData, tableData }) => {
  let cells = {};
  const incomeStatementColumns = tableData.incomeStatementSheet?.columns || [];
  const historicalPeriodsNames = columns
    .filter(column => !column.isNTM && !column.isLTM && !column.is_projection)
    .map(column => column.name);
  const lastReportedQuarterIndex = historicalPeriodsNames.lastIndexOf(LAST_QUARTER_LABEL);

  const alphabet = alphabetGenerator([], columns.length);

  const getParsedValue = ({ row, value, type, extraConditions }) => {
    const { isReportedQuarter, isLTMOrNTM, quarter } = extraConditions;
    // resolve particular cases
    if (row.alias === THIRD_ROW_ALIAS && isReportedQuarter) {
      return '';
    }
    if (row.alias === THIRD_ROW_ALIAS && isLTMOrNTM && !quarter) {
      return '';
    }
    if (row.alias === SECOND_ROW_ALIAS && isLTMOrNTM && !quarter) {
      return gridShortDate(value);
    }
    if (row.alias === THIRD_ROW_ALIAS && isLTMOrNTM && quarter) {
      return gridShortDate(value);
    }
    // generic resolve
    return parseValue(value, type, null, null, row.dbType);
  };

  // Parse body cells
  columns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];
    const { quarter, isNTM, isLTM, title, isProjectedYear, isLTMYear, isNTMYear } = column;

    const isLTMOrNTM = isNTM || isLTM;
    const isReportedQuarter = quarter && !isLTMOrNTM && title !== FIRST_ROW_TITLE;

    rowConfig.forEach((row, index) => {
      const rowNumber = index + 1;
      const key = columnLegend + rowNumber;
      const type = getValueByPropOrFallback(row, 'gridType', null);
      const expr = getExpressionToUse({
        rowAlias: row.alias,
        rowExpr: row.expr,
        columnLegend,
        column,
        columnIndex,
        alphabet,
        lastReportedQuarterIndex,
      });

      const mirrorExpression = `=incomeStatement.${key}`;
      const dateInLTMOrNTM = row.alias === SECOND_ROW_ALIAS && (isLTMYear || isNTMYear);
      const columnValue
        = dateInLTMOrNTM && column.income_statement ? column.income_statement.reported_statement_date : column[row.alias];

      const parsedValue = getParsedValue({
        row,
        value: columnValue,
        type,
        extraConditions: { isReportedQuarter, isLTMOrNTM, quarter },
      });

      let tmpStyle = row?.style;

      const isHeaderCell = rowNumber < FOURTH_ROW_ROW_NUMBER;

      if (isLTMOrNTM && isHeaderCell) {
        tmpStyle = {
          ...ntmAndLtmHeadersStyle,
        };
      } else if (isProjectedYear && isHeaderCell) {
        tmpStyle = {
          ...tmpStyle,
          fontWeight: 700,
        };
      }

      // Calculate LTM Revenue Growth Rate / Create second Row Conditional props
      const secondRowConditionalProps = generateSecondRowConditionalProps({
        alias: row.alias,
        alphabet,
        columnIndex,
        columns,
        dateInLTMOrNTM,
        expr,
        fiscalYearData,
        format: row.format,
        ignoreExpr: row.ignoreExpr,
        isLTM,
        isLTMYear,
        mirrorExpression,
        value: parsedValue,
        incomeStatementColumns,
      });

      const isAdditionalHistoricalYear = getValueByPropOrFallback(column, 'isAdditionalHistoricalYear', false);
      const showAdditionalHistoricalYear = getValueByPropOrFallback(column, 'showAdditionalHistoricalYear', false);
      const isVisibleColumn = isAdditionalHistoricalYear
        ? isAdditionalHistoricalYear && showAdditionalHistoricalYear
        : null;

      cells = {
        ...cells,
        [key]: {
          ...row,
          alias: getValueByPropOrFallback(row, 'alias', ''),
          childIndex: column.quarter,
          columnId: column.id,
          columnLegend,
          isAdditionalHistoricalYear,
          isChildWithDivider: Boolean(column.isChildWithDivider),
          isParent: getValueByPropOrFallback(column, 'isParent', false),
          isParentWithDivider: Boolean(column.isParentWithDivider),
          isVisibleColumn,
          key,
          parentColumn: column.parentColumn,
          rowSpan: getRowSpan(rowNumber, column),
          showAdditionalHistoricalYear,
          style: tmpStyle,
          value: parsedValue,
          ...secondRowConditionalProps,
        },
      };
    });
  });

  return cells;
};

export default parser;
