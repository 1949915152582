/**
 * @name Captable Config
 * @memberof module:common/config
 * @type {Config}
 * @return {Object} Configurations of API
 */

// #region common
import API from '../config';
// #endregion

const captables = `${API.BASE_DEV_HOST}/api/cap-tables`;

export const byId = `${captables}`;
export const captableById = id => `${captables}/${id}`;
export const updateSecurititesById = id => `${captables}/${id}/securities`;
export const updateFundOwnershipById = id => `${captables}/${id}/fund-ownership`;
export const createProforma = () => `${captables}/pro-forma`;
export const primaryCaptableByCompanyDate = companyDate => `${captables}/${companyDate}/primary`;
export const gotFinalAllocation = id => `${captables}/${id}/got-final-allocation`;
export const hasMeasurementDates = id => `${captables}/${id}/check-associated-measurement-date`;
export const allBreakpointsData = id => `${captables}/${id}/all-breakpoints`;
