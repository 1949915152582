import React, { useCallback, useEffect, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { getOpmData } from 'pages/Valuations/approaches/backsolveApproach/BacksolveApproach';
import {
  MATURITY_ALIAS,
  OPM_BACKSOLVE_DATE_ALIAS,
} from 'pages/Valuations/approaches/backsolveApproach/OPMInputTable/constants';
import { useGetRiskFreeRate } from 'services/hooks/allocation';

const OPMInputTable = ({ spreadsheets, onChange, setPrevOpmData, isLedgerTable, componentId }) => {
  const { opmInputSheet } = spreadsheets;
  const [getRiskFreeRateByDate] = useGetRiskFreeRate();
  const calledForRiskFreeRate = useRef(false);
  const backsolveDate = opmInputSheet?.cells?.OPM_BACKSOLVE_DATE?.value;
  const maturityValue = opmInputSheet?.cells?.MATURITY?.value;
  const riskFreeRateCell = opmInputSheet?.cells?.RISK_FREE_RATE;
  const riskFreeRateValue = opmInputSheet?.cells?.RISK_FREE_RATE?.value;

  const changeRiskFreeRate = useCallback(
    async (maturity, queryDate) => {
      const rfr = await getRiskFreeRateByDate(maturity, moment(queryDate, 'MM/DD/YYYY').format('YYYY-MM-DD'));
      onChange(riskFreeRateCell, rfr);
    },
    [getRiskFreeRateByDate, onChange, riskFreeRateCell]
  );

  const onWorkbookChange = useCallback(
    (cell, value) => {
      const opmData = getOpmData(spreadsheets);
      if (setPrevOpmData) {
        setPrevOpmData(opmData.map(cell => ({ ...cell })));
      }
      onChange(cell, value);
      if (cell.alias === MATURITY_ALIAS) {
        changeRiskFreeRate(value, backsolveDate);
      }
      if (cell.alias === OPM_BACKSOLVE_DATE_ALIAS) {
        changeRiskFreeRate(maturityValue, value);
      }
    },
    [spreadsheets, setPrevOpmData, onChange, changeRiskFreeRate, backsolveDate, maturityValue]
  );

  useEffect(() => {
    if (backsolveDate && maturityValue && !riskFreeRateValue && !calledForRiskFreeRate.current) {
      calledForRiskFreeRate.current = true;
      changeRiskFreeRate(maturityValue, backsolveDate);
    }
  }, [maturityValue, changeRiskFreeRate, riskFreeRateValue, backsolveDate]);

  return (
    <ScalarSpreadsheet
      {...opmInputSheet}
      id={componentId ?? 'opm-input-table'}
      tableTerms={{ tableSlug: 'opm-input-table' }}
      onChange={onWorkbookChange}
      sheet={opmInputSheet}
      displayLegend={false}
      showToolbar
      alwaysDisplayLegend={false}
      isLedgerTable={isLedgerTable}
    />
  );
};

export default OPMInputTable;

OPMInputTable.propTypes = {
  componentId: PropTypes.string,
  spreadsheets: PropTypes.object,
  onChange: PropTypes.func,
  setPrevOpmData: PropTypes.func,
  isLedgerTable: PropTypes.bool,
  measurementDate: PropTypes.shape({
    id: PropTypes.number,
    cmd_id: PropTypes.number,
    date: PropTypes.string,
    is_open: PropTypes.bool,
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
};
