import React from 'react';
import { AccordionActions } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ADD_NEW_DOCUMENT_BUTTON, REQUEST_NEW_DOCUMENT } from 'common/constants/documents';
import AddFile from 'icons/AddFile';
import RequestFile from 'icons/RequestFile';
import FooterActionButton from './FooterActionButton';

const DocumentReferencesFooter = ({ isDisabled, onClickHandler }) => (
  <AccordionActions style={{ display: 'flex', justifyContent: 'start' }}>
    <FooterActionButton
      title={ADD_NEW_DOCUMENT_BUTTON}
      icon={<AddFile />}
      onClick={() => onClickHandler(ADD_NEW_DOCUMENT_BUTTON)}
      isDisabled={isDisabled}
    />
    <FooterActionButton
      title={REQUEST_NEW_DOCUMENT}
      icon={<RequestFile />}
      onClick={() => onClickHandler(REQUEST_NEW_DOCUMENT)}
      isDisabled={isDisabled}
    />
  </AccordionActions>
);

DocumentReferencesFooter.propTypes = {
  onClickHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default DocumentReferencesFooter;
