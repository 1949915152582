import { alpha, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'hidden'
  },
  // items
  item: props => ({
    color: theme.palette.white,
    padding: props.dense
      ? `${theme.spacing(1)}px ${theme.spacing(2)}px`
      : theme.spacing(2),
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main
    },
    borderRadius: 0
  }),
  itemIcon: {
    color: theme.palette.white,
    marginRight: theme.spacing(2),
    minWidth: '24px',
    width: '24px'
  },
  itemText: {
    '& .MuiTypography-body1': {
      fontWeight: 700,
      color: 'white'
    },
    textTransform: 'capitalize'
  },
  // subitems
  subMenu: {},
  subitem: {
    borderRadius: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px !important`,
    backgroundColor: alpha(theme.palette.primary[600], 0.3),
    cursor: 'pointer !important',

    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(5)
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary[200]
    },
    '&.Mui-selected .MuiTypography-root': {
      fontWeight: '700'
    },
    textTransform: 'capitalize',
    position: 'relative',

    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  subitemText: {
    '& .MuiTypography-body1': {
      color: 'white',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: '0.85rem'
    }
  },
  subItemAction: {
    '& .MuiTypography-body1': {
      color: theme.palette.primary.light
    },

    '&:hover .MuiTypography-body1': {
      color: 'white'
    }
  },
  subItemIcon: {
    color: theme.palette.primary.light,
    '&:hover .pin-company': {
      opacity: 1
    }
  },
  // services
  service: {
    paddingLeft: theme.spacing(7),
    backgroundColor: 'rgba(0,0,0, 0.1)',

    '&.Mui-selected': {
      backgroundColor: 'rgba(0,0,0, 0.10)'
    },
    '&.Mui-selected .MuiTypography-root': {
      fontWeight: '700'
    }
  },
  serviceIcon: {
    opacity: 0.5,
    minWidth: '18px',

    '& .MuiSvgIcon-root': {
      width: '18px'
    }
  },
  rightDropIcon: {
    color: 'white',
    opacity: 0.5,
    minWidth: 0
  },
  overflowtext: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  // skeleton
  skeleton: {
    backgroundColor: 'rgba(255,255,255, 25%)'
  },
  activeSubMenuItem: {
    '& .MuiListItemText-primary': {
      fontWeight: 900
    }
  }
}));

export default useStyles;
