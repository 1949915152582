/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-case-declarations */
import { sortBy } from 'lodash';
import securityTypes from 'common/constants';
import { CURRENT_OWNERSHIP_ROW_NUMBER, SHARES_OUTSTANDING_ROW_NUMBER } from 'common/constants/cap-table';
import { alphabetGenerator } from 'utillities/alphabet-utilities';

const totalParser = ({ cells, columns, rows }) => {
  let totalCells = [];

  const alphabet = alphabetGenerator([], columns.length);

  // Sort all columns by creation date
  const sortedColumns = sortBy(columns, ['order']);

  const notOptionPoolKeyList = rowNumber =>
    sortedColumns.reduce((list, column, columnIndex) => {
      const key = alphabet[columnIndex] + rowNumber;

      if (cells[key] && column.security_type !== securityTypes.UNISSUED_OPTIONS) {
        list.push(key);
      }

      return list;
    }, []);
  const notConvertibleNoteKeyList = rowNumber =>
    sortedColumns.reduce((list, column, columnIndex) => {
      const key = alphabet[columnIndex] + rowNumber;

      if (cells[key] && column.security_type !== securityTypes.CONVERTIBLE_NOTES) {
        list.push(key);
      }

      return list;
    }, []);

  rows.forEach((row, rowIndex) => {
    const rowNumber = rowIndex + 1;
    const columnLegend = alphabet[0];
    const keyActual = `${columnLegend + rowNumber}`;
    const key = `TOTAL_${columnLegend + rowNumber}`;

    const expr = () =>
      Object.keys(columns)
        .map(columnIndex => alphabet[columnIndex] + rowNumber)
        .filter(key => cells[key]);

    const cell = {
      ...row,
      readOnly: true,
      key: keyActual,
      isTotal: true,
      alias: row.alias,
      defaultTooltip: null,
      expr: rowIndex === 0 ? 'Total' : undefined,
      className: [row.className, 'total-cell'].join(' '),
      linkedCellSymbols: undefined,
    };

    if (row.hasTotal) {
      switch (rowNumber) {
        case CURRENT_OWNERSHIP_ROW_NUMBER:
          const notOptionPoolList = notOptionPoolKeyList(CURRENT_OWNERSHIP_ROW_NUMBER);

          if (notOptionPoolList.length) {
            cell.expr = `=sum(${notOptionPoolList})`;
          }
          break;
        case SHARES_OUTSTANDING_ROW_NUMBER:
          const notNoteList = notConvertibleNoteKeyList(SHARES_OUTSTANDING_ROW_NUMBER);

          if (notNoteList.length) {
            cell.expr = `=sum(${notNoteList})`;
          }
          break;
        default:
          cell.expr = `=sum(${expr()})`;
          break;
      }
    }

    totalCells = {
      ...totalCells,
      [key]: cell,
    };
  });

  return totalCells;
};

export default totalParser;
