import { useQuery } from '@tanstack/react-query';
import { isEqual, isNil } from 'lodash';
import { ERROR_404 } from 'common/config/api';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import {
  FINANCIAL_FISCAL_YEAR_PERIODS_BY_CMD_DEFAULT_ERROR_MESSAGE,
  FINANCIAL_FISCAL_YEAR_PERIODS_BY_CMD_QUERY_KEY,
} from 'common/constants/services/financials';
import { SuperAgentError } from 'common/types/services';
import { FinancialStatementService } from 'services';
import { throwError } from 'utillities';
import {
  GetFinancialFiscalYearPeriodsByCMDHook,
  GetFinancialFiscalYearPeriodsByCMDParams,
  GetFinancialFiscalYearPeriodsByCMDResponse,
  UseGetFinancialFiscalYearPeriodsByCMD,
} from './types';

const getFinancialFiscalYearPeriodsByCMD = async (params: GetFinancialFiscalYearPeriodsByCMDParams) => {
  const { companyMeasurementDateId, version } = params;

  if (isNil(companyMeasurementDateId)) return null;

  const financialStatementService = new FinancialStatementService();

  try {
    const financialFiscalYearPeriods = (await financialStatementService.getFinancialPeriodsFiscalYear(
      companyMeasurementDateId,
      version
    )) as GetFinancialFiscalYearPeriodsByCMDResponse;

    if (!financialFiscalYearPeriods) return throwError(FINANCIAL_FISCAL_YEAR_PERIODS_BY_CMD_DEFAULT_ERROR_MESSAGE);

    return financialFiscalYearPeriods;
  } catch (error) {
    // Handle Financial Statements not found for this Measurement Date
    if (error instanceof Error) {
      const { response } = error as SuperAgentError;

      if (isEqual(response?.error?.status?.toString(), ERROR_404))
        return [] as GetFinancialFiscalYearPeriodsByCMDResponse;
    }

    return throwError(FINANCIAL_FISCAL_YEAR_PERIODS_BY_CMD_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetFinancialFiscalYearPeriodsByCMD: UseGetFinancialFiscalYearPeriodsByCMD = params => {
  const { companyMeasurementDate, version, shouldQueryAutomatically = true } = params;

  const companyMeasurementDateId = companyMeasurementDate?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<
    GetFinancialFiscalYearPeriodsByCMDHook['data'],
    Error
  >({
    enabled: !isNil(companyMeasurementDateId) && shouldQueryAutomatically,
    queryFn: () => getFinancialFiscalYearPeriodsByCMD({ companyMeasurementDateId, version }),
    queryKey: [FINANCIAL_FISCAL_YEAR_PERIODS_BY_CMD_QUERY_KEY, companyMeasurementDateId, version],
    refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetFinancialFiscalYearPeriodsByCMD;
