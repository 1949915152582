/* eslint-disable no-param-reassign */
import {
  MEAN_LABEL,
  MEDIAN_LABEL,
  PERCENTILE_25,
  PERCENTILE_75,
  SPECIFIED_LABEL,
  VOLATILITY_YEARS_VALUES,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics';
import type { IConditionsParams, ICellChange as TCell } from './types';
import { getPreviousCell } from './utils';

const conditions = (params: IConditionsParams) => {
  const { cell, cells, sheet, tableData } = params;
  const performanceMetricsCells: any = cells[sheet.name];
  const { alias, value, isVolatility, key } = cell;
  const { isDisabled } = tableData;

  const resetCellConfig = (param: TCell) => {
    param.hidden = true;
    param.readOnly = true;
    param.forceComponent = false;
    param.component = null;
  };

  const setAsEditable = (param: TCell) => {
    resetCellConfig(param);
    param.readOnly = isDisabled || false;
    param.hidden = false;
    param.className = '';
    param.isRequired = true;
  };

  const setAsReadOnly = (param: TCell) => {
    resetCellConfig(param);
    param.readOnly = true;
    param.hidden = false;
    param.className = 'bold-row';
  };

  if (VOLATILITY_YEARS_VALUES.includes(alias)) {
    if (isVolatility) {
      const PREVIOUS_CELL_KEY = getPreviousCell(key);
      const previousCell = performanceMetricsCells[PREVIOUS_CELL_KEY];
      const isExpr = previousCell?.isExpr;
      switch (value) {
        case SPECIFIED_LABEL:
          setAsEditable(previousCell);
          previousCell.value = isExpr ? 0 : previousCell.value;
          break;

        case PERCENTILE_25:
        case PERCENTILE_75:
        case MEDIAN_LABEL:
        case MEAN_LABEL:
          setAsReadOnly(previousCell);
          break;
        default:
          break;
      }
    }
  }
};

export default conditions;
