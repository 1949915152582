import { isEmpty } from 'lodash';
import { companySummaryUrl } from 'common/config/urls';

export const getBreadcrumbs = (firmInfo, companyInfo, breadcrumbsTitle, extraBreadcrumbs) => {
  const getCompanyURL = (firmInfo, companyInfo) => {
    if (isEmpty(firmInfo) || isEmpty(companyInfo)) {
      return '';
    }
    return companySummaryUrl(firmInfo.slug, companyInfo.slug);
  };

  const breadcrumbs = () => [
    {
      title: companyInfo.name,
      url: getCompanyURL(firmInfo, companyInfo),
    },
    {
      title: breadcrumbsTitle,
    },
    ...(extraBreadcrumbs || []),
  ];

  return breadcrumbs;
};

export default getBreadcrumbs;
