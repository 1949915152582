import { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getArrayValue } from 'utillities';
import {
  FUTURE_VALUES_PER_SHARE_TABLE_NAME,
  FUTURE_VALUES_PER_SHARE_TABLE_SLUG,
  PRESENT_VALUES_PER_SHARE_TABLE_NAME,
  PRESENT_VALUES_PER_SHARE_TABLE_SLUG,
} from './config/constants';
import createColumns from './config/createColumns';
import customParser from './config/customParser';
import getRowConfig from './config/rowConfig';
import { CreateValuesPerShareSheetParams } from './types';

const presentTableTerms = Object.freeze({
  tableName: PRESENT_VALUES_PER_SHARE_TABLE_NAME,
  tableSlug: PRESENT_VALUES_PER_SHARE_TABLE_SLUG,
});

const futureTableTerms = Object.freeze({
  tableName: FUTURE_VALUES_PER_SHARE_TABLE_NAME,
  tableSlug: FUTURE_VALUES_PER_SHARE_TABLE_SLUG,
});

const useValuesPerShareTableData = (params: CreateValuesPerShareSheetParams) => {
  const { approach, futureValuesPerShare = [], presentValuesPerShare = [] } = params;

  const presentValuesPerShareName = useMemo(
    () =>
      getApproachTableName({
        approach,
        tableSuffix: PRESENT_VALUES_PER_SHARE_TABLE_NAME,
      }),
    [approach]
  );

  const futureValuesPerShareName = useMemo(
    () =>
      getApproachTableName({
        approach,
        tableSuffix: FUTURE_VALUES_PER_SHARE_TABLE_NAME,
      }),
    [approach]
  );

  const presentValuesPerShareColumns = useMemo(
    () => getArrayValue(createColumns(presentValuesPerShare)),
    [presentValuesPerShare]
  );

  const futureValuesPerShareColumns = useMemo(
    () => getArrayValue(createColumns(futureValuesPerShare)),
    [futureValuesPerShare]
  );

  const rowConfig = useCallback(
    ({ isFutureValuesPerShare }: any) =>
      getRowConfig({
        securities: isFutureValuesPerShare ? futureValuesPerShare : presentValuesPerShare,
        isFutureValuesPerShare,
      }),
    [futureValuesPerShare, presentValuesPerShare]
  );

  const parser = useCallback(parserParams => customParser(parserParams), []);

  const commonSheetConfig = useMemo(
    () =>
      ({
        allowConfirmAndDeleteColumn: false,
        alwaysDisplayLegend: false,
        currencyFormatter: true,
        format: undefined,
        parser,
        showToolbar: false,
        showTotalColumn: false,
        totalParser: undefined,
        unitsFormatter: true,
        colTitleRow: 0,
        showPreviousColsDivider: false,
        showTitlesColumn: true,
        hasColTitle: true,
      } as SpreadsheetConfig),
    [parser]
  );

  const presentValuesPerShareSheet = useMemo(() => {
    if (!isEmpty(presentValuesPerShareColumns)) {
      const spreadsheetConfig = new SpreadsheetConfig({
        ...commonSheetConfig,
        columns: presentValuesPerShareColumns,
        name: presentValuesPerShareName,
        rowConfig: rowConfig({ isFutureValuesPerShare: false }),
        tableData: { columns: presentValuesPerShareColumns },
        tableTerms: presentTableTerms as unknown as SpreadsheetConfig['tableTerms'],
      });

      return spreadsheetConfig;
    }

    return null;
  }, [commonSheetConfig, presentValuesPerShareColumns, presentValuesPerShareName, rowConfig]);

  const futureValuesPerShareSheet = useMemo(() => {
    if (!isEmpty(futureValuesPerShareColumns)) {
      const spreadsheetConfig = new SpreadsheetConfig({
        ...commonSheetConfig,
        columns: futureValuesPerShareColumns,
        name: futureValuesPerShareName,
        rowConfig: rowConfig({ isFutureValuesPerShare: true }),
        tableData: { columns: futureValuesPerShareColumns },
        tableTerms: futureTableTerms as unknown as SpreadsheetConfig['tableTerms'],
      });

      return spreadsheetConfig;
    }

    return null;
  }, [commonSheetConfig, futureValuesPerShareColumns, futureValuesPerShareName, rowConfig]);

  return {
    futureValuesPerShareSheet,
    presentValuesPerShareSheet,
  };
};

export default useValuesPerShareTableData;
