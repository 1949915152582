import { Currency } from 'common/types/format/format';
import { formatNumbers } from 'utillities';

interface Option {
  value: number;
  label: string;
}

interface Format {
  maximumFractionDigits: number;
  minimumFractionDigits: number;
  style: string;
  validateFloat: boolean;
}

interface FormatNumbersOptions {
  format: Format;
  value: number;
  prefix: string;
  suffix: string;
  allowNegativeValue: boolean;
  currency: Currency;
  currencyCode: string;
  blankIfNull: boolean;
  staticCurrency: boolean;
}

interface HandleOptionsNumberFormatProps {
  options: Option[];
  currency: Currency | null;
  format: Format;
}

export const handleDiscountOptionsNumberFormat = ({
  options,
  currency = null,
  format,
}: HandleOptionsNumberFormatProps) => {
  const formattedOptions = options.map(option => {
    const { label } = option;
    // convert label to a number
    const cellValue = parseFloat(label);
    const formattedValue = formatNumbers({
      value: cellValue,
      currency,
      format,
    } as FormatNumbersOptions);

    return {
      ...option,
      label: `${formattedValue}`,
    };
  });

  return formattedOptions;
};

const handleOptionsNumberFormat = ({ options, currency = null, format }: HandleOptionsNumberFormatProps) => {
  const formattedOptions = options.map(option => {
    const { label, value } = option;
    const ebitdaType = label.split(': ')[0];
    const formattedValue = formatNumbers({
      value,
      currency,
      format,
    } as FormatNumbersOptions);

    return {
      ...option,
      label: `${ebitdaType} ${formattedValue}`,
    };
  });

  return formattedOptions;
};

export default handleOptionsNumberFormat;
