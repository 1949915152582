import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { companySummaryUrl } from 'common/config/urls';
import { PINNED_COMPANIES_KEY } from 'common/constants/userPreferences';
import { useLocalStorageByUser } from 'common/hooks';
import { useStore } from 'common/store';
import { OutlinedPushPin, PushPin } from 'icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  input: {
    color: '#fff',
    padding: '4px 10px 4px !important',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.5)',
  },
  focused: {
    backgroundColor: '#fff',
    color: '#293033',
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  notchedOutline: {
    borderColor: 'rgba(255, 255, 255, 0.5) !important',
  },
  autocompleteOption: {
    padding: 0,
  },
}));

const CompanyFilter = ({ updatePins }) => {
  const classes = useStyles();
  const [{ companyList = [], firmInfo }] = useStore();
  const [options, setOptions] = useState([]);
  const [pinnedCompanies] = useLocalStorageByUser(PINNED_COMPANIES_KEY, []);

  const history = useHistory();

  useEffect(() => {
    if (Array.isArray(companyList) && companyList.length) {
      const tmpOptions = [];

      companyList.forEach(company => {
        tmpOptions.push({
          value: company.company_id,
          label: company.company_name,
        });
      });

      setOptions(tmpOptions);
    }
  }, [companyList]);

  const isPinned = useCallback(
    option => pinnedCompanies.find(o => o.value === option.value) || false,
    [pinnedCompanies]
  );

  const updateOption = (e, option) => {
    e.stopPropagation();

    let tmpOptions = [];

    if (isPinned(option)) {
      tmpOptions = pinnedCompanies.filter(o => o.value !== option.value) || [];
    } else {
      tmpOptions = [...pinnedCompanies, option];
    }

    updatePins(tmpOptions);
  };

  const handleChange = (e, option) => {
    e.stopPropagation();

    const selectedCompany = companyList.find(c => c.company_id === option.value);
    if (firmInfo && selectedCompany) {
      const summaryUrl = companySummaryUrl(firmInfo.slug, selectedCompany.company_slug);
      history.push(summaryUrl);
    }
  };

  const renderInput = params => (
    <TextField
      {...params}
      fullWidth
      placeholder="Find a Company"
      InputProps={{
        ...params.InputProps,
        classes: {
          root: classes.input,
          notchedOutline: classes.notchedOutline,
          focused: classes.focused,
        },
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon className={classes.icon} />
          </InputAdornment>
        ),
      }}
      onMouseDownCapture={e => e.stopPropagation()}
      variant="outlined"
    />
  );

  const renderOption = option => (
    <>
      <IconButton onClick={e => updateOption(e, option)} aria-label="pin">
        {isPinned(option) ? <PushPin fontSize="small" /> : <OutlinedPushPin fontSize="small" />}
      </IconButton>
      {option.label}
    </>
  );

  return (
    <div className={classes.root}>
      <Autocomplete
        id="company-filter"
        options={options}
        getOptionLabel={option => option.label}
        renderInput={renderInput}
        renderOption={renderOption}
        onChange={handleChange}
        classes={{
          option: classes.autocompleteOption,
        }}
        disableCloseOnSelect
        clearOnEscape
      />
    </div>
  );
};

CompanyFilter.propTypes = {
  updatePins: PropTypes.func.isRequired,
};

export default CompanyFilter;
