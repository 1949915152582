import { clientWithAuth } from './base';
import { subscriptionEndpoints } from './endpoints';
import { IGetPaymentMethodList, IGetSubscription } from './types';

export const getClient = (companyId: number): Promise<{ [key: string]: string }> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .post(subscriptionEndpoints.getClientSecret(), { company: companyId })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });

export const setPaymentMethod = (companyId: number): Promise<{ [key: string]: string }> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .post(subscriptionEndpoints.setPaymentMethod(), { company: companyId })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }

        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });

export const listPaymentMethods = (companyId: number): Promise<IGetPaymentMethodList> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .get(subscriptionEndpoints.paymentMethods(companyId))
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }

        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });

export const deletePaymentMethod = (companyId: number, paymentMethod: string[]): Promise<unknown> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .post(subscriptionEndpoints.paymentMethods(companyId), { company: companyId, delete: paymentMethod })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }

        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });

export const getSubscription = (companyId: number): Promise<IGetSubscription> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .get(subscriptionEndpoints.getSubscription(companyId))
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }

        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });

export const setSubscriptionRenewal = (companyId: number, isRenew: boolean): Promise<IGetSubscription> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .patch(subscriptionEndpoints.getSubscription(companyId), { auto_renewal: isRenew })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }

        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });

export const setSubscriptionActivePaymentMethod = (
  companyId: number,
  paymentMethodId: string | null
): Promise<IGetSubscription> =>
  new Promise((resolve, reject) => {
    clientWithAuth
      .patch(subscriptionEndpoints.getSubscription(companyId), { active_payment_method: paymentMethodId })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }

        reject(response.data || response.statusText);
      })
      .catch(error => {
        reject(error);
      });
  });
