import { FundAllocations } from 'common/types/allocation';
import { getStringValue } from 'utillities';

const sortFundAllocationsByCompany = (fundAllocations: FundAllocations[]) => {
  const sortedFundAllocations = [...fundAllocations];

  sortedFundAllocations.sort((a, b) =>
    getStringValue(a.allocation?.company_measurement_date?.company?.name).localeCompare(
      getStringValue(b.allocation?.company_measurement_date?.company?.name)
    )
  );

  return sortedFundAllocations;
};

export default sortFundAllocationsByCompany;
