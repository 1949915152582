import { isNil } from 'lodash';
import { DECIMAL_PLACES } from 'common/config/app';
import { gridShortDate } from 'utillities/datesFormats';
import { doesNotExistValue } from 'utillities/doesNotExistValue';

const parseValueString = (value, format, allowEmptyValues, shouldReturnOriginalValue) => {
  if (value && !allowEmptyValues) {
    // If dbType is string but the value could store a float
    // we need to force the default decimal places precission
    const floatedValue = Number(value);
    if (format?.validateFloat && !!floatedValue) {
      /* eslint-disable no-param-reassign */
      value = floatedValue.toFixed(DECIMAL_PLACES);
    }

    return value.toString();
  }
  return value;
};

const parseValueNumber = (value, defaultValue, dbType, shouldReturnOriginalValue) => {
  if (shouldReturnOriginalValue) return value;
  if (!isNil(defaultValue) && Number.isNaN(value)) {
    return defaultValue;
  }

  if (value === '' || dbType === 'string') {
    return value;
  }
  return Number(value);
};

const parseValue = (value, type, defaultValue, format, dbType, allowEmptyValues = false) => {
  const shouldReturnOriginalValue = allowEmptyValues && doesNotExistValue(value);
  switch (type) {
    case 'string':
      return parseValueString(value, format, allowEmptyValues, shouldReturnOriginalValue);
    case 'number':
      return parseValueNumber(value, defaultValue, dbType, shouldReturnOriginalValue);
    case 'boolean':
      if (shouldReturnOriginalValue) return value;
      if (typeof value === 'string') {
        return Boolean(Number(value));
      }
      return Boolean(value);
    case 'gridDate':
      return shouldReturnOriginalValue ? value : gridShortDate(value);
    case 'percentage': {
      return value;
    }
    default:
      return value;
  }
};

export default parseValue;
