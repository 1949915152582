import React, { FC, useMemo, useState } from 'react';
import { LinearProgress } from '@material-ui/core';
import clsx from 'clsx';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useStyles from './styles';
import { CustomScalarSpreadsheetProps } from './types';

const CustomScalarSpreadsheet: FC<CustomScalarSpreadsheetProps> = props => {
  const { isLoading = false, isSaving = false, ...spreadsheetProps } = props;

  const [isDisplayingToolbar, setIsDisplayingToolbar] = useState(false);
  const [spreadsheetWidth, setSpreadsheetWidth] = useState(0);

  const classes = useStyles({ isDisplayingToolbar, spreadsheetWidth });

  const scalarSpreadsheetProps = useMemo(() => {
    if (spreadsheetProps)
      return {
        ...spreadsheetProps,
        setIsDisplayingToolbar,
        setSpreadsheetWidth,
      } as unknown as typeof ScalarSpreadsheet;

    return null;
  }, [spreadsheetProps]);

  if (isSaving) return <GridSkeleton />;

  return (
    <>
      <LinearProgress color="primary" className={clsx(classes.loading, { active: isLoading })} />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore: ScalarSpreadsheet is not typed */}
      <ScalarSpreadsheet {...scalarSpreadsheetProps} />
    </>
  );
};

export default CustomScalarSpreadsheet;
