import { isEmpty, isNil } from 'lodash';
import {
  EBITDA_MARGIN_ALIAS,
  GROSS_MARGIN_ALIAS,
  LTM_EBITDA_ALIAS,
  LTM_REVENUE_ALIAS,
  LTM_REVENUE_GROWTH_ALIAS,
  NTM_EBITDA_ALIAS,
  NTM_REVENUE_ALIAS,
  NTM_REVENUE_GROWTH_ALIAS,
} from 'common/constants/financials';
import { NOT_APPLICABLE } from 'common/constants/general';
import {
  PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
  SHEET_ALIASES_CONSTANTS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics';
import { getCompanyPerfMetricsAsOfDate } from 'pages/Valuations/approaches/guidelinePublicCompanies/utils/getCompanyPerfMetricsCalculations';
import { getCompanyPerformanceMetrics } from 'pages/Valuations/approaches/guidelinePublicCompanies/utils/utilities';
import { generateColumnKey, getArrayValue, getNumberValue, getStringValue } from 'utillities';
import { LTM_EBITDA_MARGIN_ALIAS, LTM_GROSS_MARGIN_ALIAS } from '../../../common/constants';

const { PERFORMANCE_METRICS_SPREADSHEET_COMPANY } = SHEET_ALIASES_CONSTANTS;

const createColumns = ({
  comparisons,
  financials,
  companyName,
  financialsPeriods,
  fiscalYearEnd,
  asOfDate,
  isReadOnlyCompanyPerfMetricsAsOfDateRow,
  companyAsOfDatePerformanceMetrics,
  measurementDate,
  performanceMetricsSheetColumns,
}) => {
  let customCompanyPerfMetrics = companyAsOfDatePerformanceMetrics?.[0] ?? {};
  // This block of code is executed when the 'asOfDate' changes. In this scenario, 'customCompanyPerfMetrics' may have only the 'id' attribute, so the check with 'isEmpty' is not enough. We also need to check if 'customCompanyPerfMetrics' has exactly one property and if that property is 'id'.
  const customDateCompanyOnlyHasIdElement
    = Object.keys(customCompanyPerfMetrics).length === 1 && customCompanyPerfMetrics.id;
  if (asOfDate !== measurementDate && (isEmpty(customCompanyPerfMetrics) || customDateCompanyOnlyHasIdElement)) {
    const { tmpCustomCompanyPerfMetrics } = getCompanyPerfMetricsAsOfDate({
      asOfDate,
      financialsPeriods,
      companyName,
      fiscalYearEnd,
    });
    customCompanyPerfMetrics = tmpCustomCompanyPerfMetrics;
  } else {
    /* When the custom and measurement dates are identical, there is no need to recalculate the
    custom perf metrics. When that happens, we display the same perf metrics on both tables. */
    const perfMetricsTableValues = getCompanyPerformanceMetrics(performanceMetricsSheetColumns);
    customCompanyPerfMetrics = {
      ...customCompanyPerfMetrics,
      ...perfMetricsTableValues,
    };
  }

  const tmpComparisons = getArrayValue(comparisons);
  const getMetricValue = (metricName, alternativeMetricName) => {
    if (
      !isReadOnlyCompanyPerfMetricsAsOfDateRow
      && companyAsOfDatePerformanceMetrics
      && !companyAsOfDatePerformanceMetrics[0]
    ) {
      return 0;
    }
    return (
      customCompanyPerfMetrics?.[metricName]
      ?? customCompanyPerfMetrics?.[alternativeMetricName]
      ?? financials?.[metricName]
    );
  };

  const customCompanyPerfMetricsObjectValues = {
    ltm_revenue: getMetricValue(LTM_REVENUE_ALIAS, 'total_revenue'),
    ltm_revenue_growth: getMetricValue(LTM_REVENUE_GROWTH_ALIAS),
    ltm_ebitda: getMetricValue(LTM_EBITDA_ALIAS),
    ltm_gross_margin: getMetricValue(LTM_GROSS_MARGIN_ALIAS, GROSS_MARGIN_ALIAS),
    ltm_ebitda_margin: getMetricValue(LTM_EBITDA_MARGIN_ALIAS, EBITDA_MARGIN_ALIAS),
    ntm_revenue: getMetricValue(NTM_REVENUE_ALIAS),
    ntm_revenue_growth: getMetricValue(NTM_REVENUE_GROWTH_ALIAS),
    ntm_ebitda: getMetricValue(NTM_EBITDA_ALIAS),
  };

  const getGrossAndEbitdaMargin = (current, grossMarginKey, ebitdaMarginKey) => {
    if (!isNil(current?.[grossMarginKey])) {
      return current?.[grossMarginKey];
    }
    if (!isNil(current?.[ebitdaMarginKey])) {
      return current?.[ebitdaMarginKey];
    }
    return current?.[ebitdaMarginKey]?.value;
  };

  const generateComparisons = (metricName, marginAlias, ltmMarginAlias) =>
    tmpComparisons?.reduce(
      (accumulator, current) => {
        const key = generateColumnKey({
          name: current?.name ? getStringValue(current?.name.value || current?.name) : '',
          prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
        });

        let value;
        if (marginAlias) {
          value = getGrossAndEbitdaMargin(current, marginAlias, ltmMarginAlias);
        } else {
          const metricValue = current?.[metricName]?.value || current?.[metricName];
          value = Number(metricValue || 0) !== 0 ? getNumberValue(metricValue) : NOT_APPLICABLE;
        }

        return {
          ...accumulator,
          [key]: { value },
        };
      },
      // Set Company value
      {
        [PERFORMANCE_METRICS_SPREADSHEET_COMPANY]: {
          value: customCompanyPerfMetricsObjectValues[metricName],
        },
      }
    );

  const comparisonsLTMRevenue = generateComparisons(LTM_REVENUE_ALIAS);
  const comparisonsLTMRevenueGrowth = generateComparisons(LTM_REVENUE_GROWTH_ALIAS);
  const comparisonsNTMRevenue = generateComparisons(NTM_REVENUE_ALIAS);
  const comparisonsNTMRevenueGrowth = generateComparisons(NTM_REVENUE_GROWTH_ALIAS);
  const comparisonsLTMEBITDA = generateComparisons(LTM_EBITDA_ALIAS);
  const comparisonsNTMEBITDA = generateComparisons(NTM_EBITDA_ALIAS);
  const comparisonsLTMGrossMargin = generateComparisons(
    LTM_GROSS_MARGIN_ALIAS,
    GROSS_MARGIN_ALIAS,
    LTM_GROSS_MARGIN_ALIAS
  );
  const comparisonsLTMEBITDAMargin = generateComparisons(
    LTM_EBITDA_MARGIN_ALIAS,
    EBITDA_MARGIN_ALIAS,
    LTM_EBITDA_MARGIN_ALIAS
  );

  return [
    comparisonsLTMRevenue,
    comparisonsLTMRevenueGrowth,
    comparisonsNTMRevenue,
    comparisonsNTMRevenueGrowth,
    comparisonsLTMEBITDA,
    comparisonsNTMEBITDA,
    comparisonsLTMGrossMargin,
    comparisonsLTMEBITDAMargin,
  ];
};

export default createColumns;
