import { getSlugValue } from 'utillities';

export const EQUITY_ALLOCATION_KEY = 'equityAllocation';
export const EQUITY_ALLOCATION_LABEL = 'Equity Allocation';
export const EQUITY_ALLOCATION_MATURITY_MAX_VALUE = 999.99;
export const EQUITY_ALLOCATION_SPREADSHEET_COLUMN_KEY_PREFIX = 'security';
export const EQUITY_ALLOCATION_SPREADSHEET_COLUMN_NAME = 'scenario';
export const EQUITY_ALLOCATION_SPREADSHEET_COLUMN_NAME_PLURAL = 'scenarios';
export const EQUITY_ALLOCATION_SPREADSHEET_TITLE = EQUITY_ALLOCATION_LABEL;
export const EQUITY_ALLOCATION_SPREADSHEET_SLUG = getSlugValue(EQUITY_ALLOCATION_SPREADSHEET_TITLE);
export const EQUITY_ALLOCATION_SPREADSHEET_TABLE = `${EQUITY_ALLOCATION_SPREADSHEET_SLUG}-table`;
export const EQUITY_ALLOCATION_SCENARIO = 'Allocation Scenario';
