/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { IGetValuationScalarOpinionsResponse } from 'dashboard409a/services/types';
import { gridShortDate } from 'utillities';
import columnTemplate from '../config/columnTemplate';
import parser from '../config/parser';
import rowConfig from '../config/rowConfig';
import { IUseTrendsDetailDataProps } from '../types';

const useTrendsDetailData = ({ data, format }: IUseTrendsDetailDataProps) => {
  const [columns, setColumns] = useState<IGetValuationScalarOpinionsResponse[]>();
  const [spreadsheet, setSpreadsheet] = useState<SpreadsheetConfig>();

  const config = useMemo(() => rowConfig(), []);

  const tableTerms: any = useMemo(
    () => ({
      tableName: 'Valuation Trends',
      tableSlug: 'valuation-trends',
      columnName: 'Valuation Trends',
      pluralColumnName: 'Valuation Trends',
    }),
    []
  );

  useEffect(() => {
    if (data?.length) {
      const generatedColumns: any = [];

      data?.forEach(column => {
        generatedColumns.push({
          ...columnTemplate,
          ...column,
          trends_date: gridShortDate(column.report_date.measurement_date.date),
        });
      });

      setColumns(generatedColumns);
    }
  }, [data]);

  useEffect(() => {
    if (columns && tableTerms) {
      const spreadsheetConfig = new SpreadsheetConfig({
        parser,
        rowConfig: config,
        name: 'valuationTrends',
        columns,
        tableData: { columns },
        tableTerms,
        showTotalColumn: false,
        showToolbar: false,
        showPreviousColsDivider: true,
        hasColTitle: false,
        allowConfirmAndDeleteColumn: false,
        currencyFormatter: true,
        unitsFormatter: true,
        totalParser: undefined,
        alwaysDisplayLegend: false,
        format,
      });

      setSpreadsheet(spreadsheetConfig);
    }
  }, [columns, tableTerms]);

  return {
    spreadsheet,
  };
};

export default useTrendsDetailData;
