import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import getRowConfig from 'pages/Valuations/approaches/backsolveApproach/SecurityTable/util/getRowConfig';
import parser from 'pages/Valuations/approaches/backsolveApproach/SecurityTable/util/parser';
import reverseParser from 'pages/Valuations/approaches/backsolveApproach/SecurityTable/util/reverseParser';

const createSecurityTableData = ({ captable, tableName, securitiesBasket, isDisabled }) => {
  const name = tableName;
  const columns = [{}, {}, {}, {}];
  const rowConfig = getRowConfig(securitiesBasket.basket);
  return new SpreadsheetConfig({
    name,
    parser,
    reverseParser,
    rowConfig,
    tableData: { securitiesBasket, securities: captable.securities, isDisabled },
    columns,
    showTitlesColumn: false,
    showToolbar: true,
  });
};

export default createSecurityTableData;
