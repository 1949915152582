import { API } from '../config';

const BASE_VALUATION = `${API.BASE_DEV_HOST}/api/valuation`;
const BASE_VALUATION_ALLOCATION = `${API.BASE_DEV_HOST}/api/valuation-allocation`;
const BASE_VALUATIONS = `${API.BASE_DEV_HOST}/api/valuations`;

export const createEffectiveTaxRate = `${BASE_VALUATIONS}/effective-tax-rate/create`;
export const getBenchmarkMultiples = allocationId => `${BASE_VALUATION}/primary-approach-multiples/${allocationId}/`;
export const getEffectiveTaxRate = companyMeasurementDate =>
  `${BASE_VALUATIONS}/effective-tax-rate/${companyMeasurementDate}`;
export const getExchangeRate = (sourceCurrency, targetCurrency, mdId, currencyDate) => {
  let query = `${BASE_VALUATION}/get-exchange-rate/${mdId}?currencies=$${targetCurrency}${sourceCurrency}:`;

  if (currencyDate) {
    query += `&currency_date=${currencyDate}`;
  }

  return query;
};
export const getFirmCompGroups = firmId => `${BASE_VALUATION}/comp-group/firm/${firmId}`;
export const getGPC = (ticker, md, currencySymbol, calendarYears) =>
  `${BASE_VALUATION}/gpc/${md}?identifier=${ticker}&currency=${currencySymbol}&calendar_years=${calendarYears}`;
export const getPublicCompList = (md, currencySymbol, tickers, calendarYears, customDate) =>
  `${BASE_VALUATION}/pc-list/${md}?identifiers=${tickers}&currency=${currencySymbol}&calendar_years=${calendarYears}&custom_date=${customDate}`;
export const getTickerByKeyword = keyword => `${BASE_VALUATION}/ticker-search?keywords=${keyword}`;
export const getTransaction = transactionId => `${BASE_VALUATION}/get-transaction-information/${transactionId}/`;
export const getValuationApproaches = (measurementDateId, allocationVersionId) =>
  `${BASE_VALUATION}/approaches/${measurementDateId}/${allocationVersionId}`;
export const updateEffectiveTaxRate = id => `${BASE_VALUATIONS}/effective-tax-rate/${id}/update`;
export const updateValuation = valuationId => `${BASE_VALUATION}/${valuationId}`;
export const valuationByAllocationId = allocationId => `${BASE_VALUATION_ALLOCATION}/${allocationId}`;
export const createNewVersion = allocationId => `${BASE_VALUATION_ALLOCATION}/${allocationId}/new-version`;
export const setReadyForAudit = valuationId => `${BASE_VALUATION}/${valuationId}/ready-for-audit`;
