/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SpecifiedShareValueSerializerWithNulls } from './SpecifiedShareValueSerializerWithNulls';
import type { ValuationApproachBacksolveSerializerWithNulls } from './ValuationApproachBacksolveSerializerWithNulls';
import type { ValuationApproachFutureExitSerializerWithNulls } from './ValuationApproachFutureExitSerializerWithNulls';

export type NewAllocationVersionScenarioModel = {
  readonly id?: number;
  future_exit?: ValuationApproachFutureExitSerializerWithNulls;
  backsolve_valuation?: ValuationApproachBacksolveSerializerWithNulls;
  specified_share_values?: SpecifiedShareValueSerializerWithNulls;
  name: string;
  is_deleted?: boolean | null;
  readonly deleted_at?: string | null;
  weighting_probability: string;
  scenario_type: NewAllocationVersionScenarioModel.scenario_type;
  equity_value: string;
  exit_date?: string | null;
  discount_rate?: string | null;
  dilution_percentage?: string | null;
  maturity?: string | null;
  volatility?: string | null;
  exit_equity_value?: string | null;
  total_aggregate_value?: string | null;
  order?: number | null;
  scenario_values?: any;
  scenario_method?: NewAllocationVersionScenarioModel.scenario_method | null;
  backsolve_ledger?: any;
  financials_metric?: number | null;
  financials_metric_value?: string | null;
  gpc_multiple?: number | null;
  gpc_multiple_value?: string | null;
  less_debt?: string | null;
  plus_cash?: string | null;
  allocation: number;
  cap_table: number;
};

export namespace NewAllocationVersionScenarioModel {
  export enum scenario_type {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }

  export enum scenario_method {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }
}
