import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles<ITheme>(theme =>
  createStyles({
    boxContainer: {
      border: `0.063rem solid ${theme.palette.gray[300]}`,
      padding: '0 0.5rem',
      width: 'auto',
      '& .MuiIconButton-edgeEnd': {
        marginRight: '0px !important',
      },
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    notesContainerTitle: {
      fontSize: '0.875rem',
      fontWeight: 700,
      marginRight: '0.4rem',
    },
    notesContainerStatus: {
      fontSize: '0.875rem',
    },
    addNote: {
      color: theme.palette.primary.main,
      marginTop: '-6px',
    },
    notesContainerDetails: {
      display: 'flex',
      justifyContent: 'left',
    },
    notesNotAvialableContainerDetails: {
      display: 'flex',
      justifyContent: 'center',
    },
    expandIcon: {
      '& .MuiIconButton-edgeEnd': {
        marginRight: '0px !important',
      },
    },
    notesEmpty: {
      margin: '3rem 0',
      fontWeight: 700,
      color: theme.palette.grey?.[500] ?? '#9e9e9e',
    },
    notesEmptyBtn: {
      margin: '3rem 0',
      '&:hover': {
        background: '#ffffff',
      },
    },
    notesEmptyBtnText: {
      fontWeight: 700,
      color: theme.palette.grey?.[500] ?? '#9e9e9e',
    },
    note: {
      width: '100%',
    },
    noteHeaderText: {
      fontSize: '0.75rem',
      fontWeight: 700,
    },
    noteDetails: {
      width: 'auto',
      whiteSpace: 'break-spaces',
      marginRight: '1.5rem',
    },
    noteDetailContent: {
      fontSize: '0.85rem',
    },
    noteFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignContent: 'flex-end',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      flexDirection: 'column',
      marginRight: '1.5rem',
    },
    noteEditContainer: {
      margin: '0rem 1.5rem',
    },
    noteEditInput: {
      width: '100%',
    },
    noteEditButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '0.5rem 0rem !important',
    },
    noteCancelButton: {
      marginLeft: '0.5rem',
      padding: '0.2rem',
      fontWeight: 'normal',
      border: `1px solid ${theme.palette.gray[100]}`,
      color: theme.palette.gray[400],
    },
    noteEditButton: {
      marginLeft: '0.5rem',
      padding: '0.2rem',
      fontWeight: 'normal',
    },
    actionIcons: {
      width: '0.8em',
      height: '0.8em',
    },
  })
);

export default useStyles;
