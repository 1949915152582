/* eslint-disable no-param-reassign */
import { isNull } from 'lodash';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { DCF_SUMMARY_TABLE_NAME } from 'pages/Valuations/approaches/discountCashFlow/utilities/constants';
import reverseParser from 'pages/Valuations/approaches/discountCashFlow/utilities/reverseParser';
import {
  NOL_ALIAS,
  PLUSH_CASH_ALIAS,
  SUMMED_PRESENT_VALUE_ALIAS,
  TERMINAL_VALUE_ALIAS,
  WACC_ALIAS,
} from 'pages/Valuations/util/constants';
import { getNolDataApproach, getWaccDataApproach } from 'pages/Valuations/util/util';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { gridShortDate } from 'utillities';
import afterCellChanged from './config/afterCellChanged';
import rowConfig from './config/getRowConfig';
import parserSummary from './config/parser';

export const getRowValue = (alias, approach, waccData, nolData, measurementDate) => {
  let value = 0;
  switch (alias) {
    case WACC_ALIAS: {
      value = waccData.wacc || 0;
      break;
    }
    case SUMMED_PRESENT_VALUE_ALIAS: {
      const periods = approach?.valuations_approach_dcf?.dcf_financial_period || [];
      value = periods.reduce((previous, current) => {
        const auxValue = current.total_cash_flows;
        const increment = Number.isNaN(auxValue) || isNull(auxValue) || auxValue === '' ? 0 : Number(auxValue);
        return previous + increment;
      }, 0);
      break;
    }
    case NOL_ALIAS:
      value = nolData.nol_present_value_savings || 0;
      break;
    case PLUSH_CASH_ALIAS:
      value = approach.valuations_approach_dcf.balance_sheet_ltm_cash;
      break;
    case TERMINAL_VALUE_ALIAS: {
      value = approach.valuations_approach_dcf.present_value_of_terminal_value;
      break;
    }
    case 'title':
      value = `Measurement Date ${gridShortDate(new Date(measurementDate.date))}`;
      break;
    default:
      break;
  }
  return value;
};

export const parseDataToCols = (approach, waccData, nolData, measurementDate, dcfTerminalValueName) => [
  rowConfig({ dcfTerminalValueName }).reduce((col, row) => {
    col[row.alias] = getRowValue(row.alias, approach, waccData, nolData, measurementDate);
    return col;
  }, {}),
];

const createDCFSummaryData = ({
  approach,
  dcfProps,
  dcfWaccSheet,
  dcfNolSheet,
  dcfTerminalValueSheet,
  dcfSheet,
  dcfAttributes,
  isDisabled,
}) => {
  const { measurementDate } = dcfProps;
  const name = getApproachTableName({ approach, tableSuffix: DCF_SUMMARY_TABLE_NAME });
  const waccData = getWaccDataApproach(approach, 'valuations_approach_dcf');
  const nolData = getNolDataApproach(approach, 'valuations_approach_dcf');

  const dcfTerminalValueName = dcfTerminalValueSheet.name;

  const columns = parseDataToCols(approach, waccData, nolData, measurementDate, dcfTerminalValueName);

  const parser = ({ columns = [], rowConfig: rowConfigParam }) =>
    parserSummary({
      columns,
      rowConfig: rowConfigParam,
      dcfWacc: dcfWaccSheet,
      dcfNol: dcfNolSheet,
      dcf: dcfSheet,
    });

  return new SpreadsheetConfig({
    name,
    tableData: { approach },
    rowConfig: rowConfig({ dcfTerminalValueName, isDisabled }),
    columns,
    parser,
    reverseParser,
    afterCellChanged,
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    tableTerms: {
      tableSlug: 'dcf_summary_table',
    },
    fieldAttributes: dcfAttributes,
    allowCopyColumn: false,
    allowReorderColumns: false,
  });
};

export default createDCFSummaryData;
