import { colors } from '@material-ui/core';
import { IPalette } from './types';
import styles from '../assets/scss/_variables.scss';

const white = '#FFFFFF';
const black = '#000000';
const pink = '#FF4070';
const cyan = '#D0FDF9';

const palette: IPalette = {
  primary: {
    contrastText: white,
    dark: styles.primary900,
    main: styles.primaryColor,
    light: styles.primary200,
    extraLight: styles.primary50,
    100: styles.primary100,
    200: styles.primary200,
    300: styles.primary300,
    400: styles.primary400,
    500: styles.primary500,
    600: styles.primary600,
    700: styles.primary700,
    800: styles.primary800,
    900: styles.primary900,
  },
  secondary: {
    contrastText: white,
    dark: styles.secondary800,
    main: styles.secondary500,
    light: styles.secondary200,
    100: styles.secondary100,
    200: styles.secondary200,
    300: styles.secondary300,
    400: styles.secondary400,
    500: styles.secondary500,
    600: styles.secondary600,
    700: styles.secondary700,
    800: styles.secondary800,
    900: styles.secondary900,
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
    veryLight: colors.orange[200],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    light: colors.red[400],
    main: colors.red[600],
    pink: '#FF4070',
    veryLight: colors.red[50],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: '#F4F6F8',
    paper: white,
  },
  gray: {
    100: styles.gray100,
    200: styles.gray200,
    300: styles.gray300,
    400: styles.gray400,
    500: styles.gray500,
    600: styles.gray600,
    700: styles.gray700,
    800: styles.gray800,
    900: styles.gray900,
  },
  green: {
    primary: styles.green,
    500: styles.green500,
  },
  black,
  cellIconColor: styles.cellIconColor,
  cyan,
  disabled: styles.disabled,
  divider: colors.grey[200],
  icon: colors.blueGrey[600],
  pink,
  white,
};

export default palette;
