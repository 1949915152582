/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserIdentity } from './UserIdentity';

export type FileView = {
  readonly id?: number;
  uploaded_by: UserIdentity;
  is_deleted?: boolean | null;
  readonly deleted_at?: string | null;
  readonly uploaded_date?: string;
  filename: string;
  file_type: FileView.file_type;
  file_path: string;
  guid: string;
};

export namespace FileView {
  export enum file_type {
    CSV = 'csv',
    DOC = 'doc',
    DOCX = 'docx',
    DOCM = 'docm',
    GIF = 'gif',
    JPEG = 'jpeg',
    JPG = 'jpg',
    KEY = 'key',
    NUMBERS = 'numbers',
    PAGES = 'pages',
    PDF = 'pdf',
    PNG = 'png',
    PPT = 'ppt',
    PPTX = 'pptx',
    PPSM = 'ppsm',
    PPTM = 'pptm',
    RTF = 'rtf',
    TXT = 'txt',
    XLS = 'xls',
    XLSX = 'xlsx',
    XLSM = 'xlsm',
    XLSB = 'xlsb',
    ZIP = 'zip',
  }
}
