import React from 'react';
import { CircularProgress, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  paper: {
    display: props => (props.loading ? 'block' : 'none'),
    padding: props => (props.isFullScreen ? '0px' : `${theme.spacing(8)}px ${theme.spacing(4)}px`),
    minWidth: '500px',
    textAlign: 'center',
    paddingRight: '0px',
    paddingLeft: '0px',
    position: 'relative',
    width: props => (props.isFullScreen ? '100%' : 'auto'),
    height: props => (props.isFullScreen ? '100%' : 'auto'),
  },
  circularProgress: {
    position: props => (props.isFullScreen ? 'absolute' : 'relative'),
    top: props => (props.isFullScreen ? '50%' : 'auto'),
    left: props => (props.isFullScreen ? '50%' : 'auto'),
    marginTop: props => (props.isFullScreen ? '-25px' : '0'),
    marginLeft: props => (props.isFullScreen ? '-25px' : '0'),
  },
  loadingMessage: {
    marginTop: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
  },
}));

const LoadingPaper = props => {
  const classes = useStyles(props);
  const { message } = props;

  return (
    <Paper className={classes.paper}>
      <CircularProgress className={classes.circularProgress} />
      {message && (
        <Typography className={classes.loadingMessage} color="textPrimary" variant="body1">
          {message}
        </Typography>
      )}
    </Paper>
  );
};

LoadingPaper.defaultProps = {
  loading: true,
  isFullScreen: false,
};

LoadingPaper.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.string,
  isFullScreen: PropTypes.bool,
};

export default LoadingPaper;
