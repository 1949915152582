import { isNil } from 'lodash';
import { NOT_APPLICABLE } from 'common/constants/general';
import { getNumberValue } from 'utillities/getNumberValue';

const getNumberOrNAValue = (value?: number | string | null): number | string => {
  if (isNil(value) || value === NOT_APPLICABLE) return NOT_APPLICABLE;
  return getNumberValue(value);
};

export default getNumberOrNAValue;
