/* eslint-disable no-tabs */
import BaseService from './base';
import { AUTH } from '../common/endpoints';

class AuthService extends BaseService {
  static instance;

  constructor() {
    super();
    if (!AuthService.instance) {
      AuthService.instance = this;
    }
  }

  static getInstance() {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  getAccessTokens = payload => this.post(AUTH.getAccessTokens, payload);

  sendAccessToken = payload => this.postNoAuth(AUTH.sendAccessToken, payload);

  refreshAccessToken = payload => this.post(AUTH.refreshAccessToken, payload);

  verifyAccessToken = accessToken => this.post(AUTH.verifyAccessToken, accessToken);

  createUser = data => this.postNoAuth(AUTH.createUser, data);

  getUserInfo = () => this.get(AUTH.getUserInfo);

  updateUserProfileInfo = payload => this.put(AUTH.updateUserProfileInfo, payload);

  activateUser = (uid, token) => this.postNoAuth(AUTH.activateUser, { uid, token });

  resendActivationEmail = email => this.postNoAuth(AUTH.resendActivationEmail, { email });

  sendPasswordReset = payload => this.postNoAuth(AUTH.resetPassword, payload);

  sendPasswordChange = payload => this.postNoAuth(AUTH.passwordChange, payload);

  getTOTP = () => this.get(AUTH.getTOTP);

  createTOTP = () => this.get(AUTH.createTOTP);

  verifyOTP = token => this.post(AUTH.verifyOTP(token));

  getBackupCodes = () => this.get(AUTH.getBackupCodes);

  createBackupCodes = data => this.post(AUTH.createBackupCodes, data);

  updateTOTP = data => this.post(AUTH.updateTOTP, data);

  deleteTOTP = () => this.post(AUTH.deleteTOTP);

  verifyBackupCode = code => this.post(AUTH.verifyBackupCode(code));
}

export default AuthService;
