import { useCallback, useMemo } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { TAB_QUERY_PARAM } from 'pages/ValuationsAllocation/common/constants/valuations';
import { setQueryString } from 'utillities';

const useTabQueryParam = () => {
  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;

  const parsedQuery = useMemo(() => queryString.parse(search), [search]);
  const { [TAB_QUERY_PARAM]: tabId } = parsedQuery;

  const setApproachQueryParam = useCallback(
    (id: string) => {
      if (tabId !== id) {
        setQueryString({
          history,
          paramName: TAB_QUERY_PARAM,
          paramValue: id,
          pathname,
          searchParams: queryString.stringify(parsedQuery),
          shouldPushPath: true,
        });
      }
    },
    [tabId, history, parsedQuery, pathname]
  );

  return {
    tabId,
    setApproachQueryParam,
  };
};

export default useTabQueryParam;
