/* eslint-disable no-console */
import { useRef } from 'react';
import { getArrayValue } from 'utillities/getArrayValue';
import { InvalidSpreadsheetsLogs, LogInvalidCellParams } from './types';

const LOG_TITLE = 'Invalid Spreadsheets Cells';

const useLogInvalidCells = () => {
  const invalidCellsBySpreadsheet = useRef<InvalidSpreadsheetsLogs>({});

  const logInvalidCell = (params: LogInvalidCellParams) => {
    const { approachTableName, invalidCell } = params;

    const updatedInvalidCells = {
      ...invalidCellsBySpreadsheet.current,
      [approachTableName]: [...getArrayValue(invalidCellsBySpreadsheet.current?.[approachTableName]), invalidCell],
    };

    invalidCellsBySpreadsheet.current = updatedInvalidCells;
  };

  const resetInvalidCellsBySpreadsheet = () => {
    invalidCellsBySpreadsheet.current = {};
  };

  const displayInvalidCells = () => {
    // Log on Development
    if (process.env.NODE_ENV !== 'production') {
      // Global Group
      console.groupCollapsed(LOG_TITLE);

      Object.entries(invalidCellsBySpreadsheet.current).forEach(([approachTableName, invalidCells]) => {
        // Group by Spreadsheet
        console.group(`❌ ${approachTableName}`);

        invalidCells.forEach(invalidCell => {
          console.log(invalidCell);
        });

        // End Group by Spreadsheet
        console.groupEnd();
      });

      // End Global Group
      console.groupEnd();
    }

    resetInvalidCellsBySpreadsheet();
  };

  return { displayInvalidCells, logInvalidCell, resetInvalidCellsBySpreadsheet };
};

export default useLogInvalidCells;
