/**
 * @name Global Reducer
 * @memberof module:common/reducers
 * @type {ReduxReducer}
 * @return {Object} Reducer Specification
 */

import {
  setDialogFlag,
  setFieldAttributes,
  showAllocationDrawer,
  showApproachDialog,
  showCaptableDrawer,
  showCompanyDialog,
  showCompanyDialogForEdit,
  showFirmDrawer,
  showFirmStepper,
  showLoadingProgress,
  showMeasurementDatesLedger,
} from './reducer';
import {
  SET_DIALOG_FLAG,
  SET_FIELD_ATTRS,
  SHOW_ALLOCATION_DRAWER,
  SHOW_APPROACH_DIALOG,
  SHOW_CAPTABLE_DRAWER,
  SHOW_COMPANY_DIALOG,
  SHOW_COMPANY_DIALOG_FOR_EDIT,
  SHOW_FIRM_DRAWER,
  SHOW_FIRM_STEPPER,
  SHOW_LOADING_PROGRESS,
  SHOW_MEASUREMENT_DATES_LEDGER,
} from '../../actions/global/types';

export default {
  [SHOW_COMPANY_DIALOG]: showCompanyDialog,
  [SHOW_COMPANY_DIALOG_FOR_EDIT]: showCompanyDialogForEdit,
  [SHOW_FIRM_DRAWER]: showFirmDrawer,
  [SHOW_ALLOCATION_DRAWER]: showAllocationDrawer,
  [SHOW_CAPTABLE_DRAWER]: showCaptableDrawer,
  [SHOW_LOADING_PROGRESS]: showLoadingProgress,
  [SHOW_FIRM_STEPPER]: showFirmStepper,
  [SHOW_MEASUREMENT_DATES_LEDGER]: showMeasurementDatesLedger,
  [SHOW_APPROACH_DIALOG]: showApproachDialog,
  [SET_DIALOG_FLAG]: setDialogFlag,
  [SET_FIELD_ATTRS]: setFieldAttributes,
};
