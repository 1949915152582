import React, { FC } from 'react';
import { CheckCircle as CheckCircleIcon, PublishOutlined as PublishOutlinedIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { ALLOCATIONS_FINAL } from 'common/constants/allocations';
import useStyles from './styles';
import { CustomOptionIconProps } from './types';

const CustomOptionIcon: FC<CustomOptionIconProps> = props => {
  const { status } = props;

  const classes = useStyles();

  // Final Icon
  if (status === ALLOCATIONS_FINAL) return <CheckCircleIcon className={clsx(classes.icon, classes.finalIcon)} />;

  // Published Icon
  return <PublishOutlinedIcon className={clsx(classes.icon, classes.publishedIcon)} />;
};

export default CustomOptionIcon;
