import React from 'react';
import { makeStyles } from '@material-ui/core';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { SELECT_DATE } from 'common/constants/inputs';
import withRequired from 'components/WithRequired/withRequired';
import withTooltip from 'components/WithTooltip';
import slashFormat from 'utillities/slashFormat';

const useStyles = makeStyles(theme => ({
  valueViewer: {
    position: 'absolute',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    right: 0,
    top: 0,
  },
  valueContainer: {
    position: 'relative',
    height: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  margin: {
    marginRight: theme.spacing(3),
  },
}));

const GridDateValueViewer = props => {
  const classes = useStyles();

  const { cell, slashFormatted } = props;

  const { value } = cell;

  if (cell.hidden) {
    return null;
  }
  const displayedValue = slashFormatted && value ? slashFormat(value) : value;

  return (
    <div className={classes.valueViewer}>
      <div className={`${classes.valueContainer} ${classes.margin}`} id={`${cell.key}-date_viewer`}>
        <span>{displayedValue || SELECT_DATE}</span>
      </div>
    </div>
  );
};

GridDateValueViewer.propTypes = {
  value: PropTypes.string,
  cell: PropTypes.object,
  slashFormatted: PropTypes.bool,
};

const enhance = flowRight(withRequired);

export default withTooltip(enhance(GridDateValueViewer));
