import React from 'react';
import { Divider, MenuItem } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { NEW_FIRM_SLUG } from 'common/constants/sidebar';

const AddFirmItem = ({ handleAddFirm, classes }) => (
  <div key={NEW_FIRM_SLUG}>
    <Divider className={classes?.divider} />
    <Link to="#">
      <MenuItem
        key={NEW_FIRM_SLUG}
        onClick={handleAddFirm}
        className={`
              sidebar-bottom__dropdown-item
              sidebar-bottom__dropdown-item-${NEW_FIRM_SLUG}
              ${classes?.menuItem}
          `}>
        <Add className={classes?.menuItemIcon} />
        Add Firm
      </MenuItem>
    </Link>
  </div>
);

AddFirmItem.propTypes = {
  handleAddFirm: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    divider: PropTypes.string,
    menuItem: PropTypes.string.isRequired,
    menuItemIcon: PropTypes.string.isRequired,
  }),
};

export default AddFirmItem;
