import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import useWaterfallBreakpointsTableData from './useWaterfallBreakpointsTableData';

const WaterfallBreakpoints = props => {
  const { setTableData, waterfallColumns, format, hideGrid } = props;

  const { spreadsheet: WaterfallBreakpointsDetailSheet } = useWaterfallBreakpointsTableData({
    waterfallColumns,
    format,
  });

  const [spreadsheets, setSpreadsheets] = useState([WaterfallBreakpointsDetailSheet]);

  useEffect(() => {
    if (WaterfallBreakpointsDetailSheet?.yLength > 0) {
      setSpreadsheets([WaterfallBreakpointsDetailSheet]);
    }
  }, [WaterfallBreakpointsDetailSheet]);

  const { onChange, workbook } = useWorkbook(spreadsheets, setTableData);

  const WaterfallBreakpointsDetailSheetProps = {
    ...WaterfallBreakpointsDetailSheet,
    sheet: WaterfallBreakpointsDetailSheet,
    workbook,
    onChange,
    page: 'captable',
    className: 'waterfall-custom-width',
  };

  if (hideGrid) {
    return null;
  }

  return WaterfallBreakpointsDetailSheet?.data ? (
    <PerfectScrollbar className="always-visible" id="waterfall-breakpoints">
      <ScalarSpreadsheet {...WaterfallBreakpointsDetailSheetProps} page={'captable'} />
    </PerfectScrollbar>
  ) : (
    <GridSkeleton />
  );
};

WaterfallBreakpoints.propTypes = {
  setTableData: PropTypes.func,
  waterfallColumns: PropTypes.array,
  format: PropTypes.object,
  hideGrid: PropTypes.bool,
};

export default WaterfallBreakpoints;
