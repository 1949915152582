import moment from 'moment';
import { FormatDateParams } from './types';

const formatDate = (params: FormatDateParams) => {
  const { date, separator = '/' } = params;

  const dateFormat = `MM${separator}DD${separator}YYYY`;

  return moment(date).format(dateFormat);
};

export default formatDate;
