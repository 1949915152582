import { isString } from 'validate.js';

const slashedToIsoFormat = option => {
  if (!isString(option?.label)) return null;
  const [month, day, year] = option.label.split('/');

  if (month && day && year) {
    return `${year}-${month}-${day}`;
  }
  return option.label;
};

export default slashedToIsoFormat;
