import { isUndefined } from 'lodash';

function percentRank(data, x, significance = 3) {
  const { _data } = data;
  // sort the array
  const sorted = _data.map(i => parseFloat(i)).sort((a, b) => a - b);
  // check if 'x' is in the array
  const isValueInArray = sorted.includes(x);
  let finalValue;
  if (isValueInArray) {
    // find the number of values in the array that are less than 'x'
    const lessThanValue = sorted.filter(i => i < x).length;
    // find the number of values greater than x
    const greaterThanValue = sorted.filter(i => i > x).length;
    finalValue = lessThanValue / (lessThanValue + greaterThanValue);
  } else {
    let lesserValue;
    let greaterValue;
    // find the two values in sorted adjacent to the 'x' parameter
    sorted.forEach(i => {
      if (i < x) {
        lesserValue = i;
      } else if (isUndefined(greaterValue)) {
        greaterValue = i;
      }
    });
    if (isUndefined(lesserValue) || isUndefined(greaterValue)) {
      return null;
    }
    lesserValue = percentRank(sorted, lesserValue);
    greaterValue = percentRank(sorted, greaterValue);
    finalValue = lesserValue + 0.25 * (greaterValue - lesserValue);
  }
  return parseFloat(finalValue.toFixed(significance));
}

export default percentRank;
