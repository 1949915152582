import React, { useState } from 'react';
import { Button, Paper } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import {
  ACCOUNT_CREATED,
  CONFIRMATION_EMAIL_ERROR,
  CONFIRMATION_EMAIL_SENT,
  EMAIL_NOT_SENT,
  EMAIL_SENT,
  SUCCESS_ACCOUNT_REGISTRATION,
} from 'common/constants/messages/validations';
import { useStore } from 'common/store';
import { CircularProgressBar, MessageBox } from 'components';
import { useResendActivationEmail } from 'services/hooks/auth';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 500,
    margin: 'auto',
    flexDirection: 'column',
  },
  actions: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  progressBar: {
    padding: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
    minWidth: '500px',
  },
}));

const SignUpConfirmation = () => {
  const classes = useStyles();
  const resendActivationEmail = useResendActivationEmail();
  const [title, setTitle] = useState(ACCOUNT_CREATED);
  const [tagline, setTagline] = useState(SUCCESS_ACCOUNT_REGISTRATION);
  const [severity, setSeverity] = useState('success');
  const [{ activationEmail }] = useStore();
  const [isLoading, setIsLoading] = useState(false);

  const handleResendActivationEmail = async e => {
    e.preventDefault();
    setIsLoading(true);
    const [, error] = await resendActivationEmail(activationEmail);

    if (error) {
      setSeverity('error');
      setTitle(EMAIL_NOT_SENT);
      setTagline(CONFIRMATION_EMAIL_ERROR);
    } else {
      setSeverity('success');
      setTitle(EMAIL_SENT);
      setTagline(CONFIRMATION_EMAIL_SENT);
    }
    setIsLoading(false);
  };

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Paper className={classes.progressBar} elevation={2} data-testid="circular-progress-bar">
          <CircularProgressBar>
            <p>Sending activation message...</p>
          </CircularProgressBar>
        </Paper>
      ) : (
        <MessageBox title={title} tagline={tagline} type={severity} fullWidth={false} />
      )}
      <div className={classes.actions}>
        <Button
          variant="text"
          color="primary"
          onClick={handleResendActivationEmail}
          endIcon={<ArrowForwardIcon />}
          disabled={isLoading}>
          Resend activation message
        </Button>
      </div>
    </div>
  );
};

export default SignUpConfirmation;
