import createPublicCompsData from 'pages/Valuations/approaches/GuidelineTransactions/config/createPublicCompsData';
import GuidelineTransactions from 'pages/Valuations/approaches/GuidelineTransactions/GuidelineTransactions';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';

const createPublicTransactionsConfig = ({
  approach,
  companyInfo,
  financials,
  format,
  compGroups,
  gptAttributes,
  isDisabled,
  gpcApproaches,
  gpcOptions,
}) => {
  const name = getApproachTableName({ approach });

  const compsSheet = createPublicCompsData({
    approach,
    name,
    companyInfo,
    financials,
    format,
    compGroups,
    gptAttributes,
    isDisabled,
    gpcApproaches,
    gpcOptions,
  });

  return {
    name,
    approach,
    spreadsheets: {
      compsSheet,
    },
    enterpriseValueReference: name,
    value: approach.enterprise_value,
    component: GuidelineTransactions,
    financials,
  };
};

export default createPublicTransactionsConfig;
