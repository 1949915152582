import React, { FC } from 'react';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import { AllocationMethodSelectorProps } from './types';

const AllocationMethodSelector: FC<AllocationMethodSelectorProps> = props => {
  const { allocationMethodsOptions } = props;

  const selectProps = {
    menuPosition: 'fixed',
    objectOptions: { displayKey: 'name', valueKey: 'id' },
    options: allocationMethodsOptions,
  };

  return <GridSelect {...props} {...selectProps} />;
};

export default AllocationMethodSelector;
