import { FoundSelectedOptionParams } from './types';

const foundSelectedOption = (params: FoundSelectedOptionParams) => {
  const { id, options } = params;

  const singleOption = options?.find(option => option.id === id);
  return singleOption ? singleOption.name : '';
};

export default foundSelectedOption;
