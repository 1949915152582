import { oneDecimalPercentFormatValidateFloatTrue } from 'common/formats/formats';
import { ColConfigProps } from '../types';

const columns: ColConfigProps[] = [
  {
    className: 'bigger-row-label-wacc-ledger',
    value: '',
    alias: 'title',
    readOnly: true,
    gridType: 'string',
    isTitleOrHeader: true,
  },
  {
    value: '',
    alias: 'value',
    gridType: 'percentage',
    dbType: 'string',
    readOnly: false,
    hasTotal: false,
    format: { ...oneDecimalPercentFormatValidateFloatTrue, allowNegativeValue: false },
    dbDecimalPlaces: 3,
  },
];

export default columns;
