import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from 'components/Dialogs/FormDialog';
import { ProformaCapTableForm } from 'components/Forms';

const ProformaCaptableDialog = props => {
  const { open, title, editMode, onClose, onSave, isValid, tableTerms, initialValues, onFormChange } = props;

  return (
    <FormDialog
      open={open}
      title={title}
      subtitle="Current Cap Table, Fund Ownership, and Breakpoint Analysis will be copied to the new version"
      editMode={editMode}
      onClose={onClose}
      onSave={onSave}
      isValid={isValid}>
      <ProformaCapTableForm tableTerms={tableTerms} initialValues={initialValues} onFormChange={onFormChange} />
    </FormDialog>
  );
};

ProformaCaptableDialog.propTypes = {
  editMode: PropTypes.bool,
  initialValues: PropTypes.shape({
    errors: PropTypes.shape({}),
    isValid: PropTypes.bool,
    name: PropTypes.string,
    values: PropTypes.shape({}),
  }),
  isValid: PropTypes.bool,
  onClose: PropTypes.func,
  onFormChange: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.bool,
  tableTerms: PropTypes.shape({
    columnName: PropTypes.string,
    pluralColumnName: PropTypes.string,
    tableName: PropTypes.string,
    tableSlug: PropTypes.string,
  }),
  title: PropTypes.string,
};

export default ProformaCaptableDialog;
