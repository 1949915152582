import React, { useState } from 'react';
import { Link, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Redirect, Link as RouterLink } from 'react-router-dom';
import { SIGN_IN_URL } from 'common/constants/login';
import { SignUpConfirmation, SignUpForm } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    minWidth: '500px',
    width: '32.563rem',
  },
  progressBar: {
    padding: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
  },
  top: {
    width: '32.563rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  bottom: {
    width: '100%',
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  signInLink: {
    marginLeft: '1em',
  },
}));

const SignUp = () => {
  const classes = useStyles();
  const [isAccountCreated, setIsAccountCreated] = useState(false);

  if (localStorage.getItem('access')) {
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        {isAccountCreated ? (
          <SignUpConfirmation />
        ) : (
          <Paper className={classes.paper}>
            <SignUpForm setIsAccountCreated={setIsAccountCreated} />
          </Paper>
        )}
      </div>
      <div className={classes.bottom}>
        <Typography color="textSecondary" variant="body1">
          Have an account?{' '}
          <Link className={classes.signInLink} component={RouterLink} to={SIGN_IN_URL} variant="body1">
            Sign In!
          </Link>
        </Typography>
      </div>
    </div>
  );
};

export default SignUp;
