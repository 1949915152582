// Widget
export const WIDGET_TITLE = 'Document References:';
export const DROP_ZONE_TITLE = [['Drop document to upload as a new document'], ['in the current measurement date']];
export const ADD_NEW_DOCUMENT_BUTTON = 'Add new document';
export const NO_REFERENCED_DOCUMENTS = 'There are no documents referenced on this page.';
export const THE_DOCUMENT = 'The document ';
export const SAVE_TO_ADD_REFERENCE
  = ' was uploaded successfully. It will be saved as a reference by pressing the SAVE button at the top of the page.';
export const REQUEST_NEW_DOCUMENT = 'Request new document';
export const SAVE_TO_ADD_REFERENCES = 'Please save the page in order to add references.';
export const CLICK_TO_DOWNLOAD = 'Click to download';

// Document Widget Dialog
export const DIALOG_TITLE = 'Add Document';
export const DIALOG_SELECTOR_TITLE = 'Select an existing document';
export const DIALOG_DROP_ZONE_TITLE = 'Upload a new document';
export const DIALOG_CUSTOM_BUTTON_LABEL = 'Add';
export const FILE_NAME = document => `${document.file.filename}.${document.file.file_type}`;
export const EXTENSION_ERROR_MESSAGE = file =>
  `The specific file ${file?.name} could not be uploaded. Only files with the following extensions are allowed:`;

// Request New Document Dialog
export const REQUEST_NEW_DOCUMENT_DIALOG_TITLE = companyName => `Request Documents from ${companyName}`;
export const WHO_CAN_PROVIDE_TITLE = 'Who can provide the documents:';
export const DOCUMENTS_NEEDED_TITLE = 'What documents are needed:';
export const SELECT_FOLDER_TITLE = 'Select a folder';
export const ADD_DOCUMENT_REQUEST_BUTTON = 'Add document request';
export const ADD_MORE_COLLABORATORS_BUTTON = 'Add more collaborators';
export const HELPER_TEXT = companyName =>
  `Documents uploaded by ${companyName} will be added automatically to the selected folder`;
export const SECURE_UPLOAD_LINK_NOTICE
  = 'A secure document upload link will be sent via email. All uploads will be added to the current documents. For security, the document request links will automatically expire after 7 days.';
export const REQUEST_NEW_DOCUMENT_SUMMARY = 'Request documents';
export const REQUEST_NEW_DOCUMENT_DESCRIPTION = numberOfFiles => `Request ${numberOfFiles} documents from a user`;

// Delete reference
export const DELETE_REFERENCE_CUSTOM_MESSAGE = document =>
  `Are you sure you want to delete the reference of ${document.file.filename}.${document.file.file_type} on this page?`;
export const DELETE_REFERENCE_TOOLTIP
  = 'Delete the reference to this document. Document will still be available in company documents.';

// Reference titles and types
export const CAP_TABLE_REFERENCE_TITLE = 'Cap Table';
export const CAP_TABLE_REFERENCE_TYPE = 'ct';
export const ALLOCATIONS_REFERENCE_TYPE = 'alloc';
export const FUND_OWNERSHIP_REFERENCE_TITLE = 'Fund Ownership';
export const FUND_OWNERSHIP_REFERENCE_TYPE = 'fo';
export const FINANCIALS_REFERENCE_TITLE = 'Financials';
export const FINANCIALS_REFERENCE_TYPE = 'fi';
export const CUSTOM_BREAKPOINTS_REFERENCE_TITLE = 'Custom Breakpoints';
export const CUSTOM_BREAKPOINTS_REFERENCE_TYPE = 'cb';
export const EXTERNAL_VALUATION_REFERENCE_TITLE = 'External Value Approach';
export const EXTERNAL_VALUATION_REFERENCE_TYPE = 'ev';
export const SSV_REFERENCE_TITLE = 'Specified Values Approach';
export const SSV_REFERENCE_TYPE = 'ssv';
