import { GetApproachTableNameParams } from './types';

const getApproachTableName = (params: GetApproachTableNameParams) => {
  const { approach, tableSuffix = '' } = params;

  if (
    (Boolean(approach?.valuations_approach_gpt?.is_benchmark)
      || Boolean(approach?.valuations_approach_gpc?.is_benchmark))
    && tableSuffix === ''
  ) {
    return 'benchmark';
  }

  const updatedName = approach?.name?.replaceAll(/[^\w]/g, '')?.replace(/^\d/, '_$&');

  return [
    updatedName,
    approach?.id ?? approach?.panelId?.replaceAll(/-/g, '_'),
    tableSuffix.length ? tableSuffix.toUpperCase() : false,
  ]
    .filter(Boolean)
    .join('_');
};

export default getApproachTableName;
