import { ValuationsApproach } from 'common/types/valuation';
import {
  VALUATIONS_PUBLIC_COMPANIES_APPROACH,
  VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { getStringValue } from 'utillities';

const findFirstPublicApproach = (approaches: ValuationsApproach[]): ValuationsApproach | null =>
  approaches?.filter(approach =>
    [VALUATIONS_PUBLIC_COMPANIES_APPROACH, VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH].includes(
      getStringValue(approach?.approach_type)
    )
  )?.[0] ?? null;

export default findFirstPublicApproach;
