import React, { useEffect, useRef } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FilterList, Search as SearchIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import UserRow from './UserRow';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    margin: '1rem 0',
    maxHeight: 'calc(50vh)',
  },
  filterIcon: {
    marginRight: theme.spacing(-3.5),
  },
  tableHead: {
    backgroundColor: '#ffff',
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  tableHeadLabel: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginRight: '0.625rem',
    textTransform: 'uppercase',
  },
  noPadding: {
    padding: '0',
  },
}));

const UsersTable = ({
  firm,
  setCurrentUser,
  isSuperUser,
  isFirmAdmin,
  users,
  isLoading,
  userSearchValue,
  setUserSearchValue,
  togglePrimaryUser,
}) => {
  const classes = useStyles();

  const tableRef = useRef(null);

  const handleSearchChange = (_, value) => {
    setUserSearchValue(value?.email || '');
  };

  useEffect(() => {
    const tableContainer = tableRef.current;
    if (tableContainer) {
      tableContainer.scrollTop = 0; // Reset scroll position when users change
    }
  }, [users]);

  if (isLoading) return <LoadingIndicator isLoading />;

  return (
    <>
      <Autocomplete
        id="user-search"
        options={users}
        getOptionLabel={user => user.email || ''}
        style={{ width: 300 }}
        value={userSearchValue.email || ''}
        onChange={handleSearchChange}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            margin="dense"
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon color="action" />,
              endAdornment: (
                <>
                  {userSearchValue === '' ? (
                    <FilterList color="action" className={classes.filterIcon} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
            fullWidth
          />
        )}
        className={classes.tableContainer}
        freeSolo
      />
      <TableContainer component={Paper} className={classes.tableContainer} ref={tableRef}>
        <PerfectScrollbar className={classes.noPadding}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableHeadLabel}>Email</TableCell>
                <TableCell className={classes.tableHeadLabel}>Last Login</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => (
                <UserRow
                  key={user.id}
                  user={user}
                  firm={firm}
                  setCurrentUser={setCurrentUser}
                  isSuperUser={isSuperUser}
                  isFirmAdmin={isFirmAdmin}
                  togglePrimaryUser={togglePrimaryUser}
                />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </TableContainer>
    </>
  );
};

UsersTable.propTypes = {
  firm: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  isSuperUser: PropTypes.bool.isRequired,
  isFirmAdmin: PropTypes.bool.isRequired,
  isCompanyTable: PropTypes.bool,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      last_login: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  userSearchValue: PropTypes.string,
  setUserSearchValue: PropTypes.func,
  togglePrimaryUser: PropTypes.func,
};

export default UsersTable;
