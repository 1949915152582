import React from 'react';
import PropTypes from 'prop-types';
import { DialogConsumer, DialogProvider } from 'providers/DialogProvider';

const DialogWrapper = ({ children }) => (
  <DialogProvider>
    <>
      <DialogConsumer>
        {({ dialog: Dialog, content: Content, wrapperProps, contentProps }) => {
          if (Dialog) {
            return <Dialog {...wrapperProps}>{Content && <Content {...contentProps} />}</Dialog>;
          }
        }}
      </DialogConsumer>
      {children}
    </>
  </DialogProvider>
);

DialogWrapper.propTypes = {
  children: PropTypes.element,
};

export default DialogWrapper;
