import { getSlugValue } from 'utillities/getSlugValue';
import { GenerateColumnKeyParams } from './types';

const generateColumnKey = (params: GenerateColumnKeyParams) => {
  const { id = 0, name = '', prefix = '' } = params;

  return `${getSlugValue(prefix)}_${getSlugValue(name)}_${id}`;
};

export default generateColumnKey;
