import React from 'react';
import { range } from 'mathjs';
import { GridRowLabel } from 'components/Grid';
import { TABLE_HEADER_CONFIG } from 'pages/Valuations/approaches/backsolveApproach/constants';
import { getExpression, getObjectValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { oneDecimalPercentFormatValidateFloatTrue } from '../../../../../../../common/formats/formats';
import { PERFORMANCE_METRICS_SPREADSHEET_COMPANY } from '../../../PerformanceMetrics/common/constants/performanceMetrics/sheetAliases';
import {
  ADJUSTMENT_METRIC_OPTIONS,
  ADJUSTMENT_METRIC_TITLE,
  AS_OF_DATE_TITLE,
  CELLS_TO_FORMAT,
  COMPANY_PERFORMANCE_METRIC_ALIAS,
  COMPUTED_PREMIUM_DISCOUNT_ALIAS,
  COMPUTED_PREMIUM_DISCOUNT_TITLE,
  GPC_MEDIAN_ALIAS,
  GPC_MEDIAN_TITLE,
  METRIC_TITLES_AND_ALIASES,
  MULTIPLE_PREMIUM_DISCOUNT_TITLE,
  OTHER_PREMIUM_DISCOUNT_ALIAS,
  OTHER_PREMIUM_DISCOUNT_TITLE,
  READ_ONLY_CELLS,
  TOTAL_PREMIUM_DISCOUNT_TITLE,
  TOTALS_ROW_CELLS,
} from '../../common/constants';

const findRowNumber = (rowConfig, alias) => rowConfig.find(row => row.alias === alias)?.rowNumber;

const cellParser = params => {
  const { alphabet, colIndex, column, row, rowIndex } = params;

  const rowNumber = rowIndex + 1;
  const columnLegend = alphabet[colIndex];
  const key = columnLegend + rowNumber;

  const { alias } = getObjectValue(row);
  const {
    className = '',
    colSpan = 1,
    expr = '',
    format = null,
    gridType = 'string',
    rowSpan,
    hidden,
  } = getObjectValue(row);
  const value = column[alias]?.value ?? '';

  return {
    [key]: {
      ...row,
      className,
      colSpan,
      columnLegend,
      expr: getExpression({ expr, columnLegend }),
      format,
      gridType,
      key,
      hidden,
      rowSpan,
      value,
    },
  };
};

const createTitleCell = (key, rowNumber, expr) => ({
  key,
  rowNumber,
  alias: expr,
  className: 'row-label bold-row',
  readOnly: true,
  expr,
  value: expr,
  valueViewer: props => <GridRowLabel {...props} formatterCell />,
});

const setReadOnly = cell => ({
  ...cell,
  readOnly: true,
});

const addSubtitleClass = cell => {
  const className = cell.className ? cell.className : '';
  return {
    ...cell,
    className: `${className} subtitle`,
  };
};

export const getComputedPremiumDiscountExpr = (
  { rowNumbersForExpr: { GPC_ROW, COMPANY_METRIC_ROW, COMPUTED_PREM_DISC_ROW } },
  { columnsForExpr: { CUSTOM_MD_COL, MD_COL } }
) => {
  const CUSTOM_MD_COMPUTED_KEY = `${CUSTOM_MD_COL}${COMPUTED_PREM_DISC_ROW}`;
  const MD_COMPANY_KEY = `${MD_COL}${COMPANY_METRIC_ROW}`;
  const MD_GPC_KEY = `${MD_COL}${GPC_ROW}`;
  const CUSTOM_MD_COMPANY_KEY = `${CUSTOM_MD_COL}${COMPANY_METRIC_ROW}`;
  const CUSTOM_MD_GPC_KEY = `${CUSTOM_MD_COL}${GPC_ROW}`;

  const formulaPart1 = `${CUSTOM_MD_COMPUTED_KEY}*(1-(( ${MD_COMPANY_KEY} - ${MD_GPC_KEY} )-( ${CUSTOM_MD_COMPANY_KEY} - ${CUSTOM_MD_GPC_KEY} ))/${CUSTOM_MD_COMPANY_KEY})`;
  const formulaPart2 = `${CUSTOM_MD_COMPUTED_KEY}*(1+(( ${MD_COMPANY_KEY} - ${MD_GPC_KEY} )-( ${CUSTOM_MD_COMPANY_KEY} - ${CUSTOM_MD_GPC_KEY} ))/${CUSTOM_MD_COMPANY_KEY})`;

  return `=IF(${CUSTOM_MD_COMPUTED_KEY}<0, ${formulaPart1}, ${formulaPart2})`;
};

const assignFormatToCells = (adjustmentMetricIndex, cells) => {
  const adjustmentMetric = adjustmentMetricIndex > -1 ? adjustmentMetricIndex : 0;

  const gridType = ADJUSTMENT_METRIC_OPTIONS[adjustmentMetric].formatConfig?.gridType;
  const format = ADJUSTMENT_METRIC_OPTIONS[adjustmentMetric].formatConfig?.format;
  const gridTypeAndFormat = { gridType, format };
  CELLS_TO_FORMAT.forEach(cellId => {
    const cell = cells[cellId];
    cell.gridType = gridTypeAndFormat.gridType;
    cell.format = gridTypeAndFormat.format;
  });
};

const customParser = params => {
  const { columns, rowConfig, tableData } = params;
  const { comparisons, performanceMetricsAsOfDateSheet, performanceMetricsSheet } = tableData;
  const companyPerformanceMetricRowIndex = performanceMetricsAsOfDateSheet?.rowConfig.findIndex(
    obj => obj.alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY
  );
  const asOfCompanyPerformanceMetricRowNumber = companyPerformanceMetricRowIndex + 1;
  const companyPerformanceMetricRowNumber = companyPerformanceMetricRowIndex + 2;
  let cells = {};
  const alphabet = alphabetGenerator([], columns.length);
  const performanceMetricsAlphabet = alphabetGenerator([], performanceMetricsAsOfDateSheet.columns.length);
  const COMPANY_NAME = rowConfig.find(row => row.alias === COMPANY_PERFORMANCE_METRIC_ALIAS)?.value;
  const rowNumbersForExpr = {
    GPC_ROW: findRowNumber(rowConfig, GPC_MEDIAN_ALIAS),
    COMPANY_METRIC_ROW: findRowNumber(rowConfig, COMPANY_PERFORMANCE_METRIC_ALIAS),
    COMPUTED_PREM_DISC_ROW: findRowNumber(rowConfig, COMPUTED_PREMIUM_DISCOUNT_ALIAS),
    OTHER_PREMIUM_DISCOUNT_ROW: findRowNumber(rowConfig, OTHER_PREMIUM_DISCOUNT_ALIAS),
  };
  const columnsForExpr = {
    CUSTOM_MD_COL: alphabet[1],
    MD_COL: alphabet[2],
  };
  const { MD_COL } = columnsForExpr;
  const { COMPUTED_PREM_DISC_ROW, OTHER_PREMIUM_DISCOUNT_ROW } = rowNumbersForExpr;

  rowConfig.forEach((row, rowIndex) => {
    columns.forEach((column, colIndex) => {
      cells = {
        ...cells,
        ...cellParser({
          alphabet,
          colIndex,
          column,
          row,
          rowIndex,
        }),
      };
    });
  });

  const adjustmentMetricIndex = ADJUSTMENT_METRIC_OPTIONS.map(option => option.value).includes(cells.C3.value)
    ? ADJUSTMENT_METRIC_OPTIONS.findIndex(option => option.value === cells.C3.value)
    : 0;

  const performanceMetricsIndex = Object.keys(METRIC_TITLES_AND_ALIASES).findIndex(
    option => option === cells.C3.value.toUpperCase()
  );

  const performanceMetricsSheetColumnLegend = performanceMetricsAlphabet[performanceMetricsIndex];

  cells.A1 = {
    ...TABLE_HEADER_CONFIG,
    ignoreRowCopy: true,
    largeHeader: true,
    value: MULTIPLE_PREMIUM_DISCOUNT_TITLE,
    alias: 'header',
    expr: MULTIPLE_PREMIUM_DISCOUNT_TITLE,
    rowNumber: 1,
    colSpan: 3,
    checkPrevCol: true,
    valueViewer: props => <GridRowLabel {...props} unitsFormatter currencyFormatter formatterCell />,
  };

  // TITLE CELLS
  const titles = [
    AS_OF_DATE_TITLE,
    ADJUSTMENT_METRIC_TITLE,
    GPC_MEDIAN_TITLE,
    COMPANY_NAME,
    COMPUTED_PREMIUM_DISCOUNT_TITLE,
    OTHER_PREMIUM_DISCOUNT_TITLE,
    TOTAL_PREMIUM_DISCOUNT_TITLE,
  ];

  titles.forEach((title, index) => {
    const cellId = `A${index + 2}`;
    cells[cellId] = createTitleCell(cellId, index + 2, title);
  });

  // CONTENT CELLS
  READ_ONLY_CELLS.forEach(cellId => {
    cells[cellId] = setReadOnly(cells[cellId]);
  });

  assignFormatToCells(adjustmentMetricIndex, cells);

  // GPC Median cells

  const customDateGpcRange = range(1, comparisons.length + 1)
    .map(rowNumber => `${performanceMetricsAsOfDateSheet.name}.${performanceMetricsSheetColumnLegend}${rowNumber + 1}`)
    .toString()
    .replace(/"/g, '');

  // in this case rowNumber is + 2 because the Performance Metric table header has a rowSpan attribute of 2
  const measurementDateGpcRange = range(1, comparisons.length + 1)
    .map(rowNumber => `${performanceMetricsSheet.name}.${performanceMetricsSheetColumnLegend}${rowNumber + 2}`)
    .toString()
    .replace(/"/g, '');

  cells.B4 = {
    ...cells.B4,
    expr: `=MEDIAN(FILTER_NULL(${customDateGpcRange}))`,
  };
  cells.C4 = {
    ...cells.C4,
    expr: `=MEDIAN(FILTER_NULL(${measurementDateGpcRange}))`,
  };

  // Company performance metric cells
  cells.B5 = {
    ...cells.B5,
    expr: `=${performanceMetricsAsOfDateSheet.name}.${performanceMetricsSheetColumnLegend}${asOfCompanyPerformanceMetricRowNumber}`,
  };
  cells.C5 = {
    ...cells.C5,
    expr: `=${performanceMetricsSheet.name}.${performanceMetricsSheetColumnLegend}${companyPerformanceMetricRowNumber}`,
  };

  cells.B6 = {
    ...cells.B6,
    format: oneDecimalPercentFormatValidateFloatTrue,
    gridType: 'percentage',
  };

  cells.C6 = {
    ...cells.C6,
    expr: getComputedPremiumDiscountExpr({ rowNumbersForExpr }, { columnsForExpr }),
    format: oneDecimalPercentFormatValidateFloatTrue,
    gridType: 'percentage',
  };

  cells.C7 = {
    ...cells.C7,
    format: oneDecimalPercentFormatValidateFloatTrue,
    gridType: 'percentage',
  };

  // TOTAL CELLS
  TOTALS_ROW_CELLS.forEach(cellId => {
    cells[cellId] = addSubtitleClass(cells[cellId]);
  });

  cells.B8 = {
    ...cells.B8,
    // Remove data from cell B8 to make it a blank cell
    gridType: undefined,
    value: '',
    expr: '',
  };

  cells.C8 = {
    ...cells.C8,
    expr: `=SUM(${MD_COL}${COMPUTED_PREM_DISC_ROW}+${MD_COL}${OTHER_PREMIUM_DISCOUNT_ROW})`,
    format: oneDecimalPercentFormatValidateFloatTrue,
    gridType: 'percentage',
  };

  return cells;
};

export default customParser;
