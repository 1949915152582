import { getSlugValue } from 'utillities';

export const ALLOCATION_WEIGHTED_SHARE_VALUES_KEY = 'weightedShareValues';
export const ALLOCATION_WEIGHTED_SHARE_VALUES_LABEL = 'Weighted Share Values';
export const ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_KEY_PREFIX = 'security';
export const ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_NAME = 'Column';
export const ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_NAME_PLURAL = 'Columns';
export const ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_TITLE = ALLOCATION_WEIGHTED_SHARE_VALUES_KEY;
export const ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_SLUG = getSlugValue(ALLOCATION_WEIGHTED_SHARE_VALUES_LABEL);
export const ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_TABLE = `${ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_SLUG}-table`;
