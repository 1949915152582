import React, { useContext } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { TaskContext } from 'context';
import PMHeaderMenuItem from './PMHeaderMenuItem';

const useStyles = makeStyles(theme => ({
  appBar: isMobile => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
    height: '10rem',
    position: 'absolute',
    top: '0',
    padding: isMobile ? '0 1rem' : '0 10rem',
  }),
  menuItems: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    marginRight: '3rem',
  },
  title: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  arrow: {
    color: theme.palette.black,
    fontSize: 20,
    marginLeft: '0.5rem',
  },
  measurementDateHeader: {
    maxWidth: '7rem',
    height: '4.5rem',
    display: 'flex',
    alignItems: 'center',
  },
}));

const ProcessManagementHeader = () => {
  const { headerMenuItems, task, isMobile } = useContext(TaskContext);
  const classes = useStyles();
  const { task: taskData } = task;

  const pMHeaderMenuItemProps = {
    taskData,
    isMobile,
    classes,
  };

  return (
    <Box display="flex" justifyContent="center">
      <Grid container className={classes.appBar}>
        <Grid
          item
          sm={6}
          xs={12}
          className={classes.menuItems}
          style={{
            order: isMobile ? 2 : 1,
          }}>
          {headerMenuItems?.left?.map(item => (
            <PMHeaderMenuItem key={item.id} item={item} {...pMHeaderMenuItemProps} />
          ))}
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          className={classes.menuItems}
          style={{
            order: isMobile ? 1 : 2,
            justifyContent: 'flex-end',
          }}>
          {headerMenuItems?.right?.map(
            item => item && <PMHeaderMenuItem key={item.id} item={item} {...pMHeaderMenuItemProps} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProcessManagementHeader;
