import React, { useContext } from 'react';
import { toString } from 'lodash';
import PropTypes from 'prop-types';
import { CustomSelect } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';

const FinancialStatementPeriods = ({ cell, options, ...restProps }) => {
  const { onCommit, onRevert } = restProps;
  const { onCellsChanged } = useContext(FeaturedSpreadsheetContext);

  const handleChange = value => {
    const newValue = toString(value);
    onCellsChanged([{ cell, value: newValue }]);
  };

  return (
    <CustomSelect
      id={`financial-statement-periods-${cell.key}`}
      value={cell.value || ''}
      options={options}
      onChange={handleChange}
      onCommit={onCommit}
      onRevert={onRevert}
      disabled={cell.readOnly}
      disableUnderline
    />
  );
};

FinancialStatementPeriods.defaultProps = {
  cell: {
    key: null,
    readOnly: false,
  },
};

FinancialStatementPeriods.propTypes = {
  cell: PropTypes.object,
  options: PropTypes.array,
};

export default FinancialStatementPeriods;
