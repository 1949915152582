/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { List } from '@material-ui/core';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useStyles from './ListItems.styles';
import ListItemsMenu from './ListItemsMenu';
import ListMenuSkeleton from './ListMenuSkeleton';

const ListItems = ({ menuItems, open, setOpenSidebar, setAnchorEl, closeDropDown, setSubMenuItem }) => {
  const testRef = useRef();
  const classes = useStyles(testRef);
  const [activeFirstLevel, setActiveFirstLevel] = useState();
  const { companySlugParam, fundSlugParam } = useParams();
  const history = useHistory();
  const location = useLocation();

  const { date: dateQueryString } = queryString.parse(location.search);

  const handleMenu = (event, menuItem) => {
    closeDropDown();
    if (menuItem.callback) {
      menuItem.callback();
      return;
    }
    if (event && menuItem.slug) {
      setActiveFirstLevel(activeFirstLevel === menuItem.slug ? null : menuItem.slug);
    }
  };

  const handleSubMenu = (event, subMenuItem) => {
    setOpenSidebar(false);
    if (subMenuItem.callback) {
      subMenuItem.callback();
      return;
    }
    const dateParams = subMenuItem.measurementDateSlug ? subMenuItem.measurementDateSlug : dateQueryString;

    history.push({ pathname: subMenuItem.url, search: `date=${dateParams}` });
  };

  const setExpandedMenuSection = () => {
    menuItems.forEach(menuItem => {
      if (menuItem.subMenu && companySlugParam) {
        menuItem.subMenu.forEach(subMenuItem => {
          if (subMenuItem.slug === companySlugParam) {
            setActiveFirstLevel(menuItem.slug);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (!activeFirstLevel && menuItems) {
      setExpandedMenuSection();
    }
  }, [menuItems]);

  const openDropDown = (event, menuItem, subMenuItem) => {
    // Open dropdown only if it's an actual company
    if (subMenuItem.subMenu) {
      setSubMenuItem({ ...subMenuItem, parent: menuItem });
      const target = event.target.tagName !== 'LI' ? event.target.closest('li') : event.target;
      setAnchorEl(target.getBoundingClientRect());
    } else {
      // Close any dropdown when you hover over the Add Company button
      closeDropDown();
    }
  };

  return (
    <List className={classes.root}>
      {menuItems.map(menuItem =>
        !menuItem.subMenu ? (
          <ListMenuSkeleton key={menuItem.slug} open={open} classes={classes} />
        ) : (
          <ListItemsMenu
            key={menuItem.slug}
            menuItem={menuItem}
            open={open}
            activeFirstLevel={activeFirstLevel}
            handleMenu={handleMenu}
            classes={classes}
            openDropDown={openDropDown}
            handleSubMenu={handleSubMenu}
            companySlugParam={companySlugParam}
            fundSlugParam={fundSlugParam}
          />
        )
      )}
    </List>
  );
};

ListItems.defaultProps = {
  open: false,
};

ListItems.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      actionMenu: PropTypes.shape({
        callback: PropTypes.func,
        icon: PropTypes.element,
        slug: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
      icon: PropTypes.element,
      slug: PropTypes.string,
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          slug: PropTypes.string,
          title: PropTypes.string,
          url: PropTypes.string,
        })
      ),
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
  open: PropTypes.bool,
  setOpenSidebar: PropTypes.func,
  setAnchorEl: PropTypes.func,
  closeDropDown: PropTypes.func,
  setSubMenuItem: PropTypes.func,
};

export default ListItems;
