import { Row, Rows } from 'common/types/scalarSpreadsheet';
import {
  ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_KEY_PREFIX,
  ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_TABLE,
  SHEET_ALIASES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/weightedShareValues';
import { generateColumnKey, getArrayValue, getObjectValue, getSlugValue, getStringValue } from 'utillities';
import { CreateSecuritiesRowsParams, CustomRowConfigParams } from './types';

const { ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADER_TITLE } = SHEET_ALIASES_CONSTANTS;

const createSecuritiesRows = (params: CreateSecuritiesRowsParams): Rows => {
  const { fund, securities } = params;
  const { id: fundId = 0 } = getObjectValue(fund);

  return getArrayValue(
    securities?.map(security => {
      const { name } = getObjectValue(security);

      return {
        alias: generateColumnKey({
          id: fundId,
          name: getStringValue(name),
          prefix: ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_KEY_PREFIX,
        }),
        gridType: 'string',
        ignoreRowCopy: true,
        readOnly: true,
        value: getStringValue(name),
      } as Row;
    })
  );
};

const customRowConfig = (params: CustomRowConfigParams): Rows => {
  const { fund, name, securities } = params;

  return [
    {
      alias: ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADER_TITLE,
      className: `${ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_TABLE}-${getSlugValue(
        ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADER_TITLE
      )} division-bottom-only`,
      gridType: 'string',
      ignoreRowCopy: true,
      largeHeader: true,
      readOnly: true,
      value: name,
    } as Row,
    ...createSecuritiesRows({
      fund,
      securities,
    }),
  ];
};

export default customRowConfig;
