import calculateTotalFromCellByAlias from './calculateTotalFromCellByAlias';
import { COST_BASIS_ALIAS, SHARES_ALIAS } from '../constants';

const calculateRegularOutstandingFromCells = ({ purchasedCells, soldCells }) => {
  const purchasedValues = purchasedCells ? Object.values(purchasedCells) : [];
  const soldValues = soldCells ? Object.values(soldCells) : [];
  const totalSharesPurchased = calculateTotalFromCellByAlias(SHARES_ALIAS, purchasedValues);
  const totalCostBasisPurchased = calculateTotalFromCellByAlias(COST_BASIS_ALIAS, purchasedValues);
  const totalSharesSold = calculateTotalFromCellByAlias(SHARES_ALIAS, soldValues);
  const totalCostBasisSold = calculateTotalFromCellByAlias(COST_BASIS_ALIAS, soldValues);
  const sharesOutstanding = totalSharesPurchased - totalSharesSold;
  const costBasisOutstanding = totalCostBasisPurchased - totalCostBasisSold;
  return {
    sharesOutstanding,
    costBasisOutstanding,
    amountOutstanding: 0,
    outstandingLoanValue: 0,
  };
};

export default calculateRegularOutstandingFromCells;
