/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { allocationsAction, captableAction, companiesAction } from 'common/actions';
import { companySummaryUrl, firmSummaryUrl } from 'common/config/urls';
import { COMPANIES_TOOLTIP_TEXT_COMPANY_MEASUREMENT_DATE } from 'common/constants/companies';
import { useStore } from 'common/store';
import { FormDialog } from 'components';
import { ConfirmationDelete, ConfirmationDialog } from 'components/Dialogs';
import { CompanyForm } from 'components/Forms';
import DialogContext from 'context/DialogContext';
import { CompaniesService, FirmService } from 'services';
import { CompanyHk } from 'services/hooks';
import { useCreateFirmCompany } from 'services/hooks/firm';

const { useUpdateCompanyById } = CompanyHk;
const ContentComponent = itemName => <ConfirmationDelete itemName={itemName} />;

const NewCompanyDialog = ({ open, onClose, editMode }) => {
  const firmService = new FirmService();
  const [{ companyInfo, firmId, firmInfo }, dispatch] = useStore();
  const defaultValues = editMode ? companyInfo : {};
  const history = useHistory();
  const { firmSlugParam } = useParams();
  const { showDialog } = useContext(DialogContext);
  const [formState, setFormState] = useState({
    isValid: editMode,
    values: {
      ...defaultValues,
      captable_currency: 'USD',
      financials_currency: 'USD',
    },
    errors: {},
  });

  const isEmptyCompany = isEmpty(pickBy(formState.values));

  const updateCompany = useUpdateCompanyById();
  const [createdCompany, createCompany] = useCreateFirmCompany();
  const [allowDeleteCompany, setAllowDeleteCompany] = useState(true);

  const deleteCompany = async () => {
    try {
      const companySvc = new CompaniesService();
      await companySvc.deleteCompany(companyInfo.id);
      history.push({ pathname: firmSummaryUrl(firmSlugParam) });
      const companies = await firmService.getCompanyListByFirmId(companyInfo.firm_id);
      dispatch(companiesAction.setCompanyList(companies));
    } catch (error) {
      return error;
    }
  };
  const handleDeleteCompany = () => {
    showDialog({
      wrapper: ConfirmationDialog,
      content: () => ContentComponent(`Company: ${companyInfo.name}`),
      actions: [
        {
          label: 'Delete',
          variant: 'contained',
          type: 'danger',
          callback: deleteCompany,
        },
      ],
    });
  };

  const saveData = async () => {
    if (editMode) {
      const { firm_id, name, legal_company_name, website, fiscal_year_end, captable_currency, financials_currency }
        = formState.values;
      const companyFormValues = {
        firm: firm_id,
        name,
        legal_company_name,
        website,
        fiscal_year_end,
        captable_currency,
        financials_currency,
      };
      updateCompany(companyInfo.id, companyFormValues, firmSlugParam);
    } else {
      const { values: companyData } = formState;

      await createCompany({ ...companyData, firm: firmId });
    }
  };

  useEffect(() => {
    if (isEmpty(companyInfo) || companyInfo?.measurement_date_id) {
      setAllowDeleteCompany(false);
    }
  }, [companyInfo]);

  useEffect(() => {
    if (!isEmpty(createdCompany) && !isEmpty(firmInfo)) {
      dispatch(captableAction.setCaptableInfo({}));
      dispatch(allocationsAction.setAllocationInfo({}));
      history.push(companySummaryUrl(firmInfo.slug, createdCompany.slug));
    }
  }, [createdCompany, firmInfo]);

  const shouldCheckFeatureEnabler = useMemo(() => !allowDeleteCompany, [allowDeleteCompany]);

  return (
    <FormDialog
      open={open}
      title={editMode ? companyInfo.name : 'Add Company'}
      editMode={editMode}
      onSave={saveData}
      onClose={onClose}
      onDelete={handleDeleteCompany}
      allowDelete={allowDeleteCompany}
      isValid={formState.isValid}
      confirmCancel={!isEmptyCompany}
      deleteText="Delete Company"
      toolTipText={COMPANIES_TOOLTIP_TEXT_COMPANY_MEASUREMENT_DATE}
      shouldCheckFeatureEnabler={shouldCheckFeatureEnabler}>
      <CompanyForm
        editMode={editMode}
        initialValues={defaultValues}
        defaultFormState={formState}
        onFormChange={formCurrentState => setFormState(formCurrentState)}
      />
    </FormDialog>
  );
};

NewCompanyDialog.defaultProps = {
  editMode: false,
};

NewCompanyDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  editMode: PropTypes.bool,
  toolTipText: PropTypes.string,
};

export default NewCompanyDialog;
