import { getNumberValue } from 'utillities';
import { HandlePresentEquityScenarioParams } from './types';

const handlePresentEquityValue = (params: HandlePresentEquityScenarioParams) => {
  const { approachEquityValue, changes, presentEquityValueCell } = params;

  if (presentEquityValueCell) {
    changes?.push({ cell: presentEquityValueCell, value: getNumberValue(approachEquityValue) });
  }
};

export default handlePresentEquityValue;
