import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import {
  FUND_SUMMARY_BY_MD_CAP_IQ_API_ERROR,
  FUND_SUMMARY_BY_MD_DEFAULT_ERROR_MESSAGE,
  FUND_SUMMARY_BY_MD_QUERY_KEY,
} from 'common/constants/services/funds';
import { FundService } from 'services';
import { throwError } from 'utillities';
import {
  GetFundSummaryByMDHook,
  GetFundSummaryByMDParams,
  GetFundSummaryByMDResponse,
  UseGetFundSummaryByMD,
} from './types';

const getFundSummaryByMD = async (params: GetFundSummaryByMDParams) => {
  const { fundId, measurementDateId } = params;

  if (isNil(fundId) && isNil(measurementDateId)) return null;

  const fundService = new FundService();

  try {
    const fundSummary = (await fundService.getFundSummaryByMD(fundId, measurementDateId)) as GetFundSummaryByMDResponse;

    if (!fundSummary) return throwError(FUND_SUMMARY_BY_MD_DEFAULT_ERROR_MESSAGE);

    return fundSummary;
  } catch (error: any) {
    if (error?.response?.body?.cap_iq_api) {
      return throwError(FUND_SUMMARY_BY_MD_CAP_IQ_API_ERROR);
    }
    return throwError(FUND_SUMMARY_BY_MD_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetFundSummaryByMD: UseGetFundSummaryByMD = params => {
  const { fund, measurementDate, shouldQueryAutomatically = true } = params;

  const fundId = fund?.id;
  const measurementDateId = measurementDate?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<GetFundSummaryByMDHook['data'], Error>({
    enabled: !isNil(fundId) && !isNil(measurementDateId) && shouldQueryAutomatically,
    queryFn: () => getFundSummaryByMD({ fundId, measurementDateId }),
    queryKey: [FUND_SUMMARY_BY_MD_QUERY_KEY, fundId, measurementDateId],
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export { useGetFundSummaryByMD, getFundSummaryByMD };
