import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import CompanyMenuItem from './CompanyMenuItem';

const useStyles = makeStyles(theme => ({
  dropdown: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    position: 'absolute',
    visibility: 'hidden',
    opacity: 0,
    transform: 'translateX(-10px)',
    transition: 'opacity 0.5s, transform 0.25s',
    zIndex: 3,
  },
}));

const DropDown = ({ handleDropDown, dropDownRef, subMenuItem, open }) => {
  const classes = useStyles();

  return (
    <>
      {open && (
        <Paper square id="company-dropdown" className={classes.dropdown} elevation={7} ref={dropDownRef}>
          {subMenuItem?.subMenu?.map(option => (
            <CompanyMenuItem
              key={option.id}
              handleDropDown={handleDropDown}
              option={option}
              subMenuItem={subMenuItem}
            />
          ))}
        </Paper>
      )}
    </>
  );
};

DropDown.propTypes = {
  handleDropDown: PropTypes.func.isRequired,
  dropDownRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  subMenuItem: PropTypes.shape({
    id: PropTypes.number,
    idPinnable: PropTypes.bool,
    isPinned: PropTypes.bool,
    measurementDateSlug: PropTypes.string,
    subMenu: PropTypes.array,
    title: PropTypes.string,
    updatePinStatus: PropTypes.func,
    url: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
};

export default DropDown;
