import { SYSTEM_COLLAPSE } from 'common/actions/row-groups/types';
import { COMMON_STOCK, CONVERTIBLE_NOTES, PREFERRED_STOCK } from 'common/constants/securityTypes';

export const CAP_TABLE_TITLE = 'Cap Table';
export const CAP_TABLE_SLUG = 'cap-table';
export const FUND_OWERNERSHIP_TITLE = 'Fund Ownership';
export const FUND_OWNERSHIP_SLUG = 'fund-ownership';
export const EMPTY_BREAKPOINT = 'Breakpoint column cannot be empty';
export const BREAKPOINT_ANALYSIS_TITLE = 'Breakpoint Analysis';
export const BREAKPOINT_ANALYSIS_SLUG = 'breakpoint-analysis';
export const BREAKPOINT_INFINITY = 'Infinity';
export const BREAKPOINT_PRO_RATA = 'Pro Rata';
export const BREAKPOINT_RANGE = 'breakpoint-range';
export const BREAKPOINT_TITLE = 'breakpoint-title';
export const BREAKPOINT_PRICE_ALIAS = 'breakpoint_price';
export const ALLOCATION_PERCENTAGE = 'allocation_percentage';
export const BREAKPOINT_SERIE_ALIAS = 'breakpoint_serie';
export const BREAKPOINT_TOTAL_ALIAS = 'breakpoint_total';
export const TWO_DECIMAL_PLACES = 2;
export const MAX_DECIMAL_PLACES_WHEN_EDITING_BPS = 4;

export const SECURITY = 'Security';
export const SECURITIES = 'Securities';
export const SECURITY_NAME_ALIAS = 'name';
export const SECURITY_NAME_TITLE = 'Security Name';
export const SECURITY_NAME_ROW_NUMBER = 1;

export const INVESTMENT_DATE_ALIAS = 'investment_date';
export const INVESTMENT_DATE_TITLE = 'Investment Date';
export const INVESTMENT_DATE_ROW_NUMBER = 2;

export const SECURITY_TYPE_ROW_NUMBER = 3;
export const SECURITY_TYPE_ALIAS = 'security_type';
export const SECURITY_TYPE_TITLE = 'Security Type';

export const ISSUE_PRICE_ALIAS = 'issue_price';
export const ISSUE_PRICE_TITLE = 'Original Issue Price';
export const ISSUE_PRICE_ROW_NUMBER = 4;

export const SHARES_OUTSTANDING_ALIAS = 'shares_outstanding';
export const SHARES_OUTSTANDING_TITLE = 'Shares Outstanding';
export const SHARES_OUTSTANDING_ROW_NUMBER = 5;

export const CONVERSION_RATE_ALIAS = 'conversion_rate';
export const CONVERSION_RATE_TITLE = 'Conversion Rate';
export const CONVERSION_RATE_ROW_NUMBER = 6;

export const PIK_SHARES_ALIAS = 'pik_shares';
export const PIK_SHARES_TITLE = 'Pik Shares (As of Today)';
export const PIK_SHARES_ROW_NUMBER = 7;

export const SHARES_FULLY_DILUTED_ALIAS = 'shares_diluted';
export const SHARES_FULLY_DILUTED_TITLE = 'Shares Fully Diluted';
export const SHARES_FULLY_DILUTED_ROW_NUMBER = 8;

export const CURRENT_OWNERSHIP_ALIAS = 'current_own_percent';
export const CURRENT_OWNERSHIP_TITLE = 'Current Ownership';
export const CURRENT_OWNERSHIP_ROW_NUMBER = 9;

export const FULLY_DILUTED_OWNERSHIP_ALIAS = 'diluted_own_percent';
export const FULLY_DILUTED_OWNERSHIP_TITLE = 'Fully Diluted Ownership';
export const FULLY_DILUTED_OWNERSHIP_ROW_NUMBER = 10;

export const STRIKE_PRICE_ALIAS = 'strike_price';
export const STRIKE_PRICE_TITLE = 'Strike Price';
export const STRIKE_PRICE_ROW_NUMBER = 11;

export const UNDERLYING_SECURITY_ALIAS = 'underlying_security_ref';
export const UNDERLYING_SECURITY_TITLE = 'Underlying Security';
export const UNDERLYING_SECURITY_ROW_NUMBER = 12;

export const PREFERRED_TERMS_ALIAS = 'prefered_terms';
export const PREFERRED_TERMS_TITLE = 'Preferred Terms';
export const PREFERRED_TERMS_ROW_NUMBER = 13;

export const LIQUIDATION_PREFERENCE_ALIAS = 'liquidation_preference';
export const LIQUIDATION_PREFERENCE_TITLE = 'Liquidation Preference';
export const LIQUIDATION_PREFERENCE_ROW_NUMBER = 14;

export const SENIORITY_ALIAS = 'seniority';
export const SENIORITY_TITLE = 'Liquidation Priority';
export const SELECT_PRIORITY = 'SELECT PRIORITY';
export const SENIORITY_ROW_NUMBER = 15;

export const PARTICIPATION_ALIAS = 'is_participating';
export const PARTICIPATION_TITLE = 'Participating';
export const PARTICIPATON_ROW_NUMBER = 16;

export const IS_PARTICIPATION_CAP_ALIAS = 'is_participation_cap';
export const IS_PARTICIPATION_CAP_TITLE = 'Participation Cap';
export const IS_PARTICIPATION_CAP_ROW_NUMBER = 17;

export const PARTICIPATION_CAP_ALIAS = 'participation_cap';
export const PARTICIPATION_CAP_TITLE = 'Participation Cap (X)';
export const PARTICIPATION_CAP_ROW_NUMBER = 18;

export const CUMMULATIVE_DIVIDENDS_ALIAS = 'has_dividends';
export const CUMMULATIVE_DIVIDENDS_TITLE = 'Cumulative Dividends';
export const CUMMULATIVE_DIVIDENDS_ROW_NUMBER = 19;

export const HAS_MULTIPLE_INVESTMENT_DATES_ALIAS = 'has_multiple_investments';
export const HAS_MULTIPLE_INVESTMENT_DATES_TITLE = 'Multiple Investments Dates';
export const HAS_MULTIPLE_INVESTMENT_DATES_ROW_NUMBER = 20;

export const DIVIDEND_PAYMENT_TYPE_ALIAS = 'dividend_payment_type';
export const DIVIDEND_PAYMENT_TYPE_TITLE = 'Cash or Pik';
export const DIVIDEND_PAYMENT_TYPE_ROW_NUMBER = 21;

export const DIVIDEND_RATE_ALIAS = 'dividend_rate';
export const DIVIDEND_RATE_TITLE = 'Dividend Rate';
export const DIVIDEND_RATE_ROW_NUMBER = 22;

export const COMPOUNDING_ALIAS = 'has_compounding';
export const COMPOUNDING_TITLE = 'Compounding';
export const COMPOUNDING_ROW_NUMBER = 23;

export const COMPOUNDING_PERIOD_ALIAS = 'compounding_period';
export const COMPOUNDING_PERIOD_TITLE = 'Compounding Periods/Year';
export const COMPOUNDING_PERIOD_ROW_NUMBER = 24;

export const ACCRUED_DIVIDENDS_ALIAS = 'accrued_dividends';
export const ACCRUED_DIVIDENDS_TITLE = 'Accrued Dividends';
export const ACCRUED_DIVIDENDS_ROW_NUMBER = 25;

export const INITIAL_LIQUIDATION_PREFERENCE_ALIAS = 'initial_liquidation_pref';
export const INITIAL_LIQUIDATION_PREFERENCE_TITLE = 'Initial Liquidation Preference';
export const INITIAL_LIQUIDATION_PREFERENCE_ROW_NUMBER = 26;

export const TOTAL_PREFERENCE_ALIAS = 'total_preference';
export const TOTAL_PREFERENCE_TITLE = 'Total Preference';
export const TOTAL_PREFERENCE_ROW_NUMBER = 27;

export const MULTIPLE_INVESTMENT_LEDGER_ALIAS = 'multiple_investments';
export const MULTIPLE_INVESTMENT_LEDGER_TITLE = 'Multiple Investments Ledger';
export const MULTIPLE_INVESTMENT_LEDGER_ROW_NUMBER = 28;

export const OPTIONS_LEDGER_ALIAS = 'options_ledger';
export const OPTIONS_LEDGER_TITLE = 'Options Ledger';
export const OPTIONS_LEDGER_ROW_NUMBER = 29;

export const SECURITY_REFERENCE_ALIAS = 'security_ref';
export const SECURITY_REFERENCE_ALIAS_ROW_NUMBER = 30;

export const CONVERTIBLE_NOTES_ALIAS = 'convertible_notes';
export const CONVERTIBLE_NOTES_TITLE = 'Convertible Notes';
export const CONVERTIBLE_NOTES_ROW_NUMBER = 31;

export const TOTAL_NOTE_PRINCIPLE_ALIAS = 'total_note_preference';
export const TOTAL_NOTE_PRINCIPLE_TITLE = 'Total Note Principle';
export const TOTAL_NOTE_PRINCIPLE_ROW_NUMBER = 32;

export const TOTAL_NOTE_VALUE_AT_MD_ALIAS = 'total_note_value_at_md';
export const TOTAL_NOTE_VALUE_AT_MD_TITLE = 'Total Note Value at Measurement DAte';
export const TOTAL_NOTE_VALUE_AT_MD_ROW_NUMBER = 33;

export const TOTAL_SHARES_FULLY_DILUTED_ALIAS = 'shares_diluted';
export const TOTAL_SHARES_FULLY_DILUTED_TITLE = 'Total Shares Fully Diluted';

export const EMPTY_COLUMNS = ({ tableName, columnName }) => `The ${tableName} has no ${columnName} yet`;
export const CAP_TABLE_NEEDS_MORE_SECURITIES_TITLE = 'Cap Table needs more Securities';
export const CAP_TABLE_NEEDS_MORE_SECURITIES_TAGLINE
  = 'Please add at least one Security with type Preferred or Common to generate Breakpoints';
export const TABLE_EMPTY = 'Table Empty';

export const FORBIDDEN_BP_ANALYSIS = 'You do not have permissions to view this breakpoint analysis';
export const GET_BP_ACCESS = 'Please contact your manager to gain access';

export const DEFAULT_CURRENCY = 'USD';

export const CAP_TABLE_LOCKED_MESSAGE
  = 'This Cap Table version is used in a Final Valuation, any changes saved could affect that valuation.';

export const BREAKPOINT_ANALYSIS_LOCKED_MESSAGE
  = 'This Breakpoint Analysis is being used on a Final Valuation, any changes saved could affect that valuation.';

export const SECURITY_SELECTED_IN_FO_OR_BACKSOLVE
  = 'Changing this security\'s type is not allowed while it is selected in a fund ownership and/or backsolve';

export const TITLE_TO_ALIAS = {
  [SECURITY_NAME_TITLE]: SECURITY_NAME_ALIAS,
  [INVESTMENT_DATE_TITLE]: INVESTMENT_DATE_ALIAS,
  [SECURITY_TYPE_TITLE]: SECURITY_TYPE_ALIAS,
  [ISSUE_PRICE_TITLE]: ISSUE_PRICE_ALIAS,
  [SHARES_OUTSTANDING_TITLE]: SHARES_OUTSTANDING_ALIAS,
  [CONVERSION_RATE_TITLE]: CONVERSION_RATE_ALIAS,
  [PIK_SHARES_TITLE]: PIK_SHARES_ALIAS,
  [SHARES_FULLY_DILUTED_TITLE]: SHARES_FULLY_DILUTED_ALIAS,
  [CURRENT_OWNERSHIP_TITLE]: CURRENT_OWNERSHIP_ALIAS,
  [FULLY_DILUTED_OWNERSHIP_TITLE]: FULLY_DILUTED_OWNERSHIP_ALIAS,
  [STRIKE_PRICE_TITLE]: STRIKE_PRICE_ALIAS,
  [UNDERLYING_SECURITY_TITLE]: UNDERLYING_SECURITY_ALIAS,
  [PREFERRED_TERMS_TITLE]: PREFERRED_TERMS_ALIAS,
  [LIQUIDATION_PREFERENCE_TITLE]: LIQUIDATION_PREFERENCE_ALIAS,
  [SENIORITY_TITLE]: SENIORITY_ALIAS,
  [PARTICIPATION_TITLE]: PARTICIPATION_ALIAS,
  [IS_PARTICIPATION_CAP_TITLE]: IS_PARTICIPATION_CAP_ALIAS,
  [PARTICIPATION_CAP_TITLE]: PARTICIPATION_CAP_ALIAS,
  [CUMMULATIVE_DIVIDENDS_TITLE]: CUMMULATIVE_DIVIDENDS_ALIAS,
  [HAS_MULTIPLE_INVESTMENT_DATES_TITLE]: HAS_MULTIPLE_INVESTMENT_DATES_ALIAS,
  [DIVIDEND_PAYMENT_TYPE_TITLE]: DIVIDEND_PAYMENT_TYPE_ALIAS,
  [DIVIDEND_RATE_TITLE]: DIVIDEND_RATE_ALIAS,
  [COMPOUNDING_TITLE]: COMPOUNDING_ALIAS,
  [COMPOUNDING_PERIOD_TITLE]: COMPOUNDING_PERIOD_ALIAS,
  [ACCRUED_DIVIDENDS_TITLE]: ACCRUED_DIVIDENDS_ALIAS,
  [TOTAL_PREFERENCE_TITLE]: TOTAL_PREFERENCE_ALIAS,
  [MULTIPLE_INVESTMENT_LEDGER_TITLE]: MULTIPLE_INVESTMENT_LEDGER_ALIAS,
  [OPTIONS_LEDGER_TITLE]: OPTIONS_LEDGER_ALIAS,
  [TOTAL_SHARES_FULLY_DILUTED_TITLE]: TOTAL_SHARES_FULLY_DILUTED_ALIAS,
};

export const ASSIGNED_TYPE_FOR_NOTES = {
  1: PREFERRED_STOCK,
  2: COMMON_STOCK,
  3: CONVERTIBLE_NOTES,
};

export const INITIAL_CAP_TABLE_STATE = {
  [STRIKE_PRICE_ALIAS]: SYSTEM_COLLAPSE,
  [PREFERRED_TERMS_ALIAS]: SYSTEM_COLLAPSE,
};

export const CAP_TABLE_TERMS = {
  tableName: CAP_TABLE_TITLE,
  tableSlug: CAP_TABLE_SLUG,
  columnName: SECURITY,
  pluralColumnName: SECURITIES,
};
