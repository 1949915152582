import { Row, Rows } from 'common/types/scalarSpreadsheet';
import {
  EXTERNAL_VALUATION_LABEL,
  EXTERNAL_VALUATION_SPREADSHEET_TABLE,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/externalValuation';
import { getSlugValue } from 'utillities';
import { CustomRowConfigParams } from './types';

const {
  EXTERNAL_VALUATION_SPREADSHEET_ENTERPRISE_VALUE,
  EXTERNAL_VALUATION_SPREADSHEET_EQUITY_VALUE,
  EXTERNAL_VALUATION_SPREADSHEET_HEADER_TITLE,
} = SHEET_ALIASES_CONSTANTS;

const { EXTERNAL_VALUATION_SPREADSHEET_ENTERPRISE_VALUE_TITLE, EXTERNAL_VALUATION_SPREADSHEET_EQUITY_VALUE_TITLE }
  = SHEET_TITLES_CONSTANTS;

const customRowConfig = (params: CustomRowConfigParams): Rows => {
  const { isDisabled } = params;

  return [
    {
      alias: EXTERNAL_VALUATION_SPREADSHEET_HEADER_TITLE,
      className: `${EXTERNAL_VALUATION_SPREADSHEET_TABLE}-${getSlugValue(
        EXTERNAL_VALUATION_SPREADSHEET_HEADER_TITLE
      )} division-bottom-only`,
      gridType: 'string',
      ignoreRowCopy: true,
      largeHeader: true,
      readOnly: true,
      value: EXTERNAL_VALUATION_LABEL,
    } as Row,
    {
      alias: EXTERNAL_VALUATION_SPREADSHEET_EQUITY_VALUE,
      className: `${EXTERNAL_VALUATION_SPREADSHEET_TABLE}-${getSlugValue(EXTERNAL_VALUATION_SPREADSHEET_EQUITY_VALUE)}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      value: EXTERNAL_VALUATION_SPREADSHEET_EQUITY_VALUE_TITLE,
    } as Row,
    {
      alias: EXTERNAL_VALUATION_SPREADSHEET_ENTERPRISE_VALUE,
      className: `${EXTERNAL_VALUATION_SPREADSHEET_TABLE}-${getSlugValue(
        EXTERNAL_VALUATION_SPREADSHEET_ENTERPRISE_VALUE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      value: EXTERNAL_VALUATION_SPREADSHEET_ENTERPRISE_VALUE_TITLE,
    } as Row,
  ];
};

export default customRowConfig;
