import { createStyles, makeStyles } from '@material-ui/styles';
import { COLUMN_INDICATOR_ADJUSTMENT } from 'components/ScalarSpreadsheet/components/ContentColumns';
import { ITheme } from 'theme/types';
import { StyleProps } from './types';

const useStyles = makeStyles<ITheme, StyleProps>(() =>
  createStyles({
    loading: {
      margin: 'auto',
      marginLeft: COLUMN_INDICATOR_ADJUSTMENT,
      transform: props => `translateY(${props.isDisplayingToolbar ? 0 : COLUMN_INDICATOR_ADJUSTMENT})`,
      visibility: 'hidden',
      width: props => `calc(${props.spreadsheetWidth}px - ${COLUMN_INDICATOR_ADJUSTMENT})`,

      '&.active': {
        visibility: 'visible',
      },
    },
  })
);

export default useStyles;
