import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { styled, ThemeProvider } from '@material-ui/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { hoursToMilliseconds } from 'date-fns';
import { createBrowserHistory } from 'history';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { Router } from 'react-router-dom';
import validate from 'validate.js';
import reducers from 'common/reducers';
import appInitialStates from 'common/states';
import { StoreProvider } from 'common/store';
import { DefaultMutationResponse } from 'common/types/services';
import validators from 'common/validators';
import { ErrorBoundary, SnackCloseIcon } from 'components';
import DialogWrapper from 'components/DialogWrapper';
import ShortcutHelper from 'components/ShortcutHelper/ShortcutHelper';
import { UnsavedChangesWrapper } from 'components/UnsavedChanges';
import { Provider as DashboardProvider } from 'dashboard409a/states/provider';
import { FeaturesProvider } from 'providers/FeaturesProvider';
import UserbackProvider from 'providers/UserbackProvider';
import Routes from 'Routes';
import AnalyticsProvider from 'scalarAnalytics/AnalyticsProvider';
import theme from 'theme';
import { displayErrorMessage, displaySuccessMessage, getObjectValue, getStringValue } from 'utillities';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/scss/index.scss';

// Browser history
const history = createBrowserHistory();

// Add custom validators
validate.validators = {
  ...validate.validators,
  ...validators,
};

// Sentry
Sentry.init({
  environment: process.env.REACT_APP_ENVIRONMENT,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({
      history,
    }),
  ],
  tracePropagationTargets: [window.location.hostname, getStringValue(process.env.REACT_APP_API_ENDPOINT)],
  beforeSend(event) {
    // Only track errors from staging and production
    if (!['staging', 'production'].includes(process.env.REACT_APP_ENVIRONMENT || '')) {
      return null;
    }
    return event;
  },
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0, // Record all the sampling sessions where errors occur
});

// Notistack Origin position override
const useStyles = makeStyles({
  anchorOriginTopRight: {
    top: 155,
  },
});

// Notistack Components override
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

// Set up react-query client with cache time
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Cache for 24 hours
      cacheTime: hoursToMilliseconds(24),
      // Display error message
      onError: error => displayErrorMessage(error instanceof Error ? error.message : (error as string)),
      // Do not retry on error
      retry: false,
      // The query will not be retried on mount if it contains an error
      retryOnMount: false,
    },
    mutations: {
      // Display error message
      onError: error => displayErrorMessage(error instanceof Error ? error.message : (error as string)),
      // Display success message
      onSuccess: data => {
        const { responseMessage } = getObjectValue(data) as DefaultMutationResponse;

        if (responseMessage) displaySuccessMessage(responseMessage);
      },
    },
  },
});

// Set up react-query local storage persister
const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const App = () => {
  const classes = useStyles();

  return (
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
      <StoreProvider initialState={appInitialStates} reducer={reducers}>
        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
          <DashboardProvider>
            <ThemeProvider theme={theme}>
              <Router history={history}>
                <AnalyticsProvider>
                  <UserbackProvider>
                    <ErrorBoundary>
                      <DialogWrapper>
                        <UnsavedChangesWrapper>
                          <SnackbarProvider
                            action={SnackCloseIcon}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            autoHideDuration={3000}
                            classes={{ anchorOriginTopRight: classes.anchorOriginTopRight }}
                            Components={{ success: StyledMaterialDesignContent }}
                            maxSnack={3}
                            preventDuplicate>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <FeaturesProvider>
                                <ShortcutHelper>
                                  <Routes />
                                </ShortcutHelper>
                              </FeaturesProvider>
                            </MuiPickersUtilsProvider>
                          </SnackbarProvider>
                        </UnsavedChangesWrapper>
                      </DialogWrapper>
                    </ErrorBoundary>
                  </UserbackProvider>
                </AnalyticsProvider>
              </Router>
            </ThemeProvider>
          </DashboardProvider>
        </PersistQueryClientProvider>
      </StoreProvider>
    </GoogleOAuthProvider>
  );
};

export default Sentry.withProfiler(App);
