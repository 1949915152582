import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import ScalarDatasheet from 'components/ScalarDatasheet';

const useStyles = makeStyles(() => ({
  paper: {
    spacing: 0,
  },
}));

const FundTable = ({ data }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <ScalarDatasheet className="full-width" data={data} dataRenderer={cell => cell.expr} />
    </Paper>
  );
};

FundTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FundTable;
