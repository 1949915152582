import React from 'react';
import { CAP_TABLE_SELECTION_ALIAS, CAP_TABLE_SELECTION_TITLE } from 'common/constants/valuations';
import { SelectValueViewer } from 'components';
import CapTableListSelection from 'pages/Valuations/components/CapTableListSelection';

const getRowConfig = ({ capTableList, isDisabled }) => [
  {
    value: CAP_TABLE_SELECTION_TITLE,
    alias: CAP_TABLE_SELECTION_ALIAS,
    rowNumber: 1,
    customKey: 'capTableSelection',
    readOnly: isDisabled,
    dbType: 'string',
    defaultValue: capTableList?.find(ct => ct.is_primary)?.id,
    expr: '',
    dataEditor: props => <CapTableListSelection {...props} />,
    valueViewer: props => (
      <SelectValueViewer options={capTableList} objectOptions={{ displayKey: 'name', valueKey: 'id' }} {...props} />
    ),
    ignoreRowCopy: true,
  },
];

export default getRowConfig;
