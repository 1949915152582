import {
  SYSTEM_COLLAPSE,
  SYSTEM_EXPAND,
  TOGGLE_COLLAPSE,
  USER_COLLAPSE,
  USER_EXPAND,
} from 'common/actions/row-groups/types';
import { RowsGroups, RowsGroupsAction } from './types';

const rowsGroupsReducer = (currentState: RowsGroups, action: RowsGroupsAction) => {
  const row = currentState?.[action?.row];
  const isCollapsed = row === SYSTEM_COLLAPSE || row === USER_COLLAPSE;

  switch (action.type) {
    case USER_EXPAND:
      return { ...currentState, [action.row]: USER_EXPAND };
    case USER_COLLAPSE:
      return { ...currentState, [action.row]: USER_COLLAPSE };
    case SYSTEM_EXPAND:
      return { ...currentState, [action.row]: SYSTEM_EXPAND };
    case SYSTEM_COLLAPSE:
      return { ...currentState, [action.row]: SYSTEM_COLLAPSE };
    case TOGGLE_COLLAPSE:
      if (Array.isArray(action?.collapseGroups)) {
        const newState = { ...currentState };
        action.collapseGroups.forEach(group => {
          newState[group] = isCollapsed ? SYSTEM_EXPAND : SYSTEM_COLLAPSE;
        });
        return { ...currentState, ...newState };
      }

      return {
        ...currentState,
        [action.row]: isCollapsed ? USER_EXPAND : USER_COLLAPSE,
      };
    default:
      return currentState;
  }
};

export default rowsGroupsReducer;
