/**
 * @name Global Reducer
 * @memberof module:common/reducers
 * @type {ReduxReducer}
 * @return {Object} Reducer Specification
 */

import { getFundSummaryInfo, setCurrentMpByFund, setFundInfo, setFundList } from './reducer';
import { SET_CURRENT_MP_BY_FUND, SET_FUND_INFO, SET_FUND_LIST, SET_FUND_SUMMARY_INFO } from '../../actions/funds/types';

export default {
  [SET_FUND_INFO]: setFundInfo,
  [SET_FUND_LIST]: setFundList,
  [SET_CURRENT_MP_BY_FUND]: setCurrentMpByFund,
  [SET_FUND_SUMMARY_INFO]: getFundSummaryInfo,
};
