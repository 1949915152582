import {
  ALLOCATION_SCENARIO_METHOD_WATERFALL,
  ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import { getArrayValue, getStringValue } from 'utillities';
import { ResetScenarioParams } from './types';

const resetScenario = (params: ResetScenarioParams) => {
  const { scenario, shouldResetScenariosWeights = false } = params;
  const currentScenario = scenario;

  currentScenario.approach_uuid = null;
  currentScenario.backsolve_ledger = null;
  currentScenario.backsolve_valuation = null;
  currentScenario.dilution_percentage = null;
  currentScenario.discount_rate = null;
  currentScenario.equity_value = '0';
  currentScenario.exit_date = null;
  currentScenario.exit_equity_value = null;
  currentScenario.financials_metric = null;
  currentScenario.financials_metric_value = null;
  currentScenario.future_exit = null;
  currentScenario.gpc_multiple = null;
  currentScenario.gpc_multiple_value = null;
  currentScenario.less_debt = null;
  currentScenario.maturity = null;
  currentScenario.plus_cash = null;
  currentScenario.scenario_method = ALLOCATION_SCENARIO_METHOD_WATERFALL;
  currentScenario.scenario_type = ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE;
  currentScenario.specified_share_values = null;
  currentScenario.total_aggregate_value = null;
  currentScenario.volatility = null;

  if (shouldResetScenariosWeights) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: allocations_scenarios_weights is not readonly
    scenario.allocations_scenarios_weights = getArrayValue(scenario?.allocations_scenarios_weights)?.map(weight => ({
      ...weight,
      weight: '0',
      equity_value: getStringValue(weight.equity_value ?? '0'),
    }));
  }
};

export default resetScenario;
