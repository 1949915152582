/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { CustomTextField, WebsiteTextField } from 'components';
import CurrencyList from 'components/CurrencyList';
import { useFormValidation } from 'services/hooks/useFormValidation';
import { gridShortDate, reconstructDate } from 'utillities';

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

const constraints = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 3, message: 'must be at least 3 characters' },
  },
  website: {
    url: true,
  },
};

const CompanyForm = ({ defaultFormState, onFormChange, editMode, initialValues }) => {
  const classes = useStyles();
  const { values, errors, dbErrors } = defaultFormState;
  const { formValidated, validateForm } = useFormValidation(constraints);
  const [formValues, setFormValues] = useState(editMode ? initialValues : values);

  const initial_fiscal_year_end = editMode
    ? reconstructDate(initialValues.fiscal_year_end.end_month, initialValues.fiscal_year_end.end_day)
    : reconstructDate();
  const [selectedDate, setSelectedDate] = useState(initial_fiscal_year_end);
  /* default date when creating company should be December 31. If there's no change here,
  it will not send any fiscal year data in the form values and backend will use default. */

  // in EditMode should pre populate date with stored value
  useEffect(() => {
    validateForm(formValues);
  }, [validateForm, formValues]);

  useEffect(() => {
    onFormChange(formValidated);
  }, [formValidated]);

  const handleChange = event => {
    const {
      target: { name, value },
    } = event;
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const handleDateChange = value => {
    const formattedDate = gridShortDate(value);
    const [month, day] = formattedDate.split('/');
    const fiscalYearEnd = {
      ...formValues.fiscal_year_end,
      end_month: month,
      end_day: day,
    };
    setSelectedDate(formattedDate);
    handleChange({
      target: {
        name: 'fiscal_year_end',
        value: fiscalYearEnd,
      },
    });
  };

  const handleCurrencyChange = (event, option) => {
    handleChange({
      target: {
        name: event.target.id.includes('captable-currency') ? 'captable_currency' : 'financials_currency',
        value: option.value,
      },
    });
  };

  return (
    <Grid container>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={8}>
          <CustomTextField
            fullWidth
            required
            id="company-name"
            name="name"
            type="text"
            label="Name"
            className={classes.marginBottom}
            value={formValues.name}
            onChange={handleChange}
            formErrors={errors}
            dbErrors={dbErrors}
          />
        </Grid>
        <Grid item xs={4}>
          <DatePicker
            required
            openTo={editMode ? 'date' : 'month'}
            format="MM/dd"
            label="FY End"
            views={['month', 'date']}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <CustomTextField
            fullWidth
            id="legal-company-name"
            name="legal_company_name"
            type="text"
            label="Legal Company Name"
            className={classes.marginBottom}
            value={formValues.legal_company_name || ''}
            onChange={handleChange}
            formErrors={errors}
            dbErrors={dbErrors}
          />
        </Grid>
        <Grid item xs={4} className={classes.margin}>
          <CurrencyList
            label="Captable Currency"
            formCurrency={formValues.captable_currency}
            handleCurrencyChange={handleCurrencyChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <WebsiteTextField
            fullWidth
            id="company-website"
            name="website"
            type="text"
            label="Website"
            value={formValues.website || ''}
            onChange={handleChange}
            formErrors={errors}
            dbErrors={dbErrors}
          />
        </Grid>
        <Grid item xs={4} className={classes.margin}>
          <CurrencyList
            label="Financials Currency"
            formCurrency={formValues.financials_currency}
            handleCurrencyChange={handleCurrencyChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

CompanyForm.propTypes = {
  defaultFormState: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  editMode: PropTypes.bool.isRequired,
};

export default CompanyForm;
