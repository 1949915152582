import { createStyles, makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { SMALL_FONT_SIZE } from 'dashboard409a/common/constants';
import { ITheme } from 'theme/types';

const wrapText: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    avatar: {
      backgroundColor: 'transparent',
      border: '2px solid #fff',
      fontWeight: 700,
      fontSize: '1rem',
    },
    bottomMenuItemPrimaryText: {
      ...wrapText,
      color: theme.palette.white,
      fontWeight: 700,
    },
    bottomMenuItemSecondaryText: {
      ...wrapText,
    },
    secondaryLink: {
      color: theme.palette.white,
      textTransform: 'none',
      fontWeight: 'normal',
      fontSize: '0.8rem',
      justifyContent: 'flex-start',
      padding: `${theme.spacing(0.2)}px 0`,
      marginTop: theme.spacing(0.5),

      '&:hover': {
        textDecoration: 'underline',
      },
    },

    bottomMenuItemSubMenuIcon: {
      color: theme.palette.white,
    },
    menuItemsLabel: {
      fontFamily: theme.typography.secondaryFont,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: SMALL_FONT_SIZE,
      lineHeight: '16px',
      textTransform: 'uppercase',
    },
    menuItem: {
      fontFamily: theme.typography.secondaryFont,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '1rem',
      lineHeight: '30px',
    },

    skeleton: {
      backgroundColor: 'rgba(255,255,255,0.5)',
    },
  })
);

export default useStyles;
