import React, { FC } from 'react';
import { Box, Radio } from '@material-ui/core';
import NameLetters from 'pages/RequestDocuments/components/NameLetters';
import folderIcon from 'pages/RequestDocuments/icons/FolderIcon2x.png';
import { getBooleanValue } from 'utillities';
import { FolderIconProps } from './types';

const sizeStyle = {
  small: {
    maxWidth: '3.375rem',
    height: '3.375rem',
    marginRight: '0.5rem',
  },
  medium: {
    maxWidth: '5.375rem',
    height: '5.375rem',
    marginRight: '1.5rem',
  },
  large: {
    maxWidth: '7.375rem',
    height: '7.375rem',
    marginRight: '1.5rem',
  },
};

const nameLettersContainer = {
  small: {
    bottom: '0.5rem',
    left: '0.8rem',
  },
  medium: {
    bottom: '1rem',
    left: '1.5rem',
  },
  large: {
    bottom: '1.5rem',
    left: '2rem',
  },
};

const FolderIcon: FC<FolderIconProps> = props => {
  const { requestedFile, hideRadio = false, hideBorder = false, size = 'medium' } = props;

  return (
    <Box
      border={hideBorder ? null : '1px solid #848484'}
      borderRadius="0.125rem"
      width="100%"
      {...sizeStyle[size]}
      position="relative">
      {!hideRadio && (
        <Radio
          checked={getBooleanValue(requestedFile?.is_uploaded)}
          name="radio-buttons"
          style={{
            pointerEvents: 'none',
            position: 'absolute',
            top: '-0.5rem',
            right: '-0.5rem',
          }}
          size="small"
        />
      )}
      <Box
        style={{
          backgroundImage: `url(${folderIcon})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
        }}
        width="100%"
        height="80%"
        position="absolute"
        top="0.5rem">
        <Box position="absolute" {...nameLettersContainer[size]}>
          <NameLetters name={requestedFile?.name ?? requestedFile?.filename} size={size} />
        </Box>
      </Box>
    </Box>
  );
};

export default FolderIcon;
