import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function EqualSymbol(props) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M0.5 8C0.5 3.86 3.86 0.5 8 0.5C12.14 0.5 15.5 3.86 15.5 8C15.5 12.14 12.14 15.5 8 15.5C3.86 15.5 0.5 12.14 0.5 8ZM2 8C2 11.315 4.685 14 8 14C11.315 14 14 11.315 14 8C14 4.685 11.315 2 8 2C4.685 2 2 4.685 2 8ZM5.07732 11.8011L10.3378 4.25L11.0166 4.72292L10.3011 5.75H12.5V7.31985H9.20747L8.25982 8.68015H12.5V10.25H7.16618L5.75616 12.274L5.07732 11.8011ZM8.4655 5.75L7.37186 7.31985H3.5V5.75H8.4655ZM6.4242 8.68015L5.33056 10.25H3.5V8.68015H6.4242Z" />
    </SvgIcon>
  );
}
