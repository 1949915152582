/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { jsPDF } from 'jspdf';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import {
  DONE_CODES,
  DOWNLOAD_CODES,
  ERROR_DOWNLOAD_CODES,
  GENERATE_NEW_CODES,
  PRINT_CODES,
  SUCCESS_DOWNLOAD_CODES,
} from 'common/constants/login';
import { useResponse } from 'services/hooks';
import BackupCodesContainer from './components/BackupCodesContainer';
import PrintableBackupCodes from './components/PrintableBackupCodes';
import useStyles from './styles';

const fileName = `scalar-backup-codes-${new Date().toLocaleDateString('en-US').replaceAll('/', '-')}.pdf`;

const BackupCodes = props => {
  const {
    backupCodes = [],
    disabledGenerate = false,
    handleClose = () => {},
    handleGenerateNewCodes = () => {},
    isGeneratingBackupCodes = false,
    isModal = false,
    openModal = false,
    username = '',
  } = props;

  const [doneIsEnabled, setDoneIsEnabled] = useState(false);

  const componentRef = useRef(null);

  const { processErrorResponse, successNotification } = useResponse();

  const classes = useStyles();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setDoneIsEnabled(true),
  });

  const handleDownload = useReactToPrint({
    onPrintError: error =>
      processErrorResponse({
        error,
        defaultErrorMessage: ERROR_DOWNLOAD_CODES,
      }),
    content: () => componentRef.current,
    removeAfterPrint: true,
    print: async printIframe => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName('html')[0];

        // eslint-disable-next-line new-cap
        const pdfExporter = new jsPDF();
        try {
          await pdfExporter.html(html, {
            callback(doc) {
              doc.save(fileName);
            },
            margin: [10, 10, 10, 10],
            autoPaging: 'text',
            x: 0,
            y: 0,
            width: 190, // target width in the PDF document
            windowWidth: 675, // window width in CSS pixels
          });
          successNotification(SUCCESS_DOWNLOAD_CODES);
          setDoneIsEnabled(true);
        } catch (error) {
          processErrorResponse({
            error,
            defaultErrorMessage: ERROR_DOWNLOAD_CODES,
          });
        }
      }
    },
  });

  return (
    <BackupCodesContainer handleClose={handleClose} isModal={isModal} openModal={openModal}>
      <Box className={clsx(classes.boxContainer, isModal && classes.modalContainer)}>
        {/* Only for Print */}
        <PrintableBackupCodes
          backupCodes={backupCodes}
          componentRef={componentRef}
          disabledGenerate={disabledGenerate}
          forPrint
          isGeneratingBackupCodes={isGeneratingBackupCodes}
          isModal
          username={username}
        />

        <PrintableBackupCodes
          backupCodes={backupCodes}
          disabledGenerate={disabledGenerate}
          isGeneratingBackupCodes={isGeneratingBackupCodes}
          isModal={isModal}
        />

        {/* Buttons */}
        {isModal ? (
          <Grid container>
            <Grid className={classes.generateButtonContainer} justifyContent="center" alignItems="center" container>
              <Grid item>
                <Button
                  className={clsx(classes.actionButton, classes.whiteButton, classes.generateButton)}
                  color="primary"
                  disabled={disabledGenerate || isGeneratingBackupCodes}
                  onClick={handleGenerateNewCodes}
                  startIcon={<RefreshIcon />}
                  variant="contained">
                  {GENERATE_NEW_CODES}
                </Button>
              </Grid>
            </Grid>

            <Grid className={classes.buttonsContainer} justifyContent="space-between" alignItems="center" container>
              <Grid item>
                {isModal && (
                  <Button
                    className={classes.actionButton}
                    color="primary"
                    onClick={handleClose}
                    variant="contained"
                    disabled={!doneIsEnabled}>
                    {DONE_CODES}
                  </Button>
                )}

                <Button
                  className={clsx(classes.actionButton, classes.whiteButton, classes.downloadButton)}
                  color="primary"
                  disabled={backupCodes.length === 0 || !username}
                  onClick={handleDownload}
                  variant="contained">
                  {DOWNLOAD_CODES}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  className={clsx(classes.actionButton, classes.whiteButton)}
                  color="primary"
                  disabled={backupCodes.length === 0 || !username}
                  onClick={handlePrint}
                  variant="contained">
                  {PRINT_CODES}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.generateButtonContainer} justifyContent="flex-end" alignItems="center" container>
            <Grid item>
              <Button
                className={clsx(
                  classes.actionButton,
                  classes.whiteButton,
                  classes.generateButton,
                  classes.generateButtonMd
                )}
                color="primary"
                disabled={disabledGenerate || isGeneratingBackupCodes}
                onClick={handleGenerateNewCodes}
                startIcon={<RefreshIcon />}
                variant="contained">
                {GENERATE_NEW_CODES}
              </Button>
            </Grid>

            <Grid item>
              <Button
                className={clsx(classes.actionButton, classes.whiteButton, classes.downloadButton)}
                color="primary"
                disabled={backupCodes.length === 0 || !username}
                onClick={handleDownload}
                variant="contained">
                {DOWNLOAD_CODES}
              </Button>
            </Grid>

            <Grid item>
              <Button
                className={clsx(classes.actionButton, classes.whiteButton, classes.downloadButton)}
                color="primary"
                disabled={backupCodes.length === 0 || !username}
                onClick={handlePrint}
                variant="contained">
                {PRINT_CODES}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </BackupCodesContainer>
  );
};

BackupCodes.propTypes = {
  backupCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabledGenerate: PropTypes.bool,
  handleClose: PropTypes.func,
  handleGenerateNewCodes: PropTypes.func,
  isGeneratingBackupCodes: PropTypes.bool,
  isModal: PropTypes.bool,
  openModal: PropTypes.bool,
  username: PropTypes.string,
};

export default BackupCodes;
