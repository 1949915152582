import { SPECIFIED_SHARE_VALUES } from 'common/constants/allocations';

export function getPresentValues(scenario_method, getPresentValuesFromSSVLedgerCell, column, cells) {
  return scenario_method === SPECIFIED_SHARE_VALUES
    ? getPresentValuesFromSSVLedgerCell(
      {
        columnLegend: column.columnLegend,
      },
      cells?.allocation
    )
    : undefined;
}

export function underScoreSpaces(string) {
  // replace symbols that can't be used in an object key with alternate values
  // if the string starts with a number, prepend an underscore
  const prepended = string?.match(/^\d/) ? `_${string}` : string;
  return prepended?.replace(/[^a-zA-Z0-9]/g, '_');
}
