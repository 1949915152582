import React, { useMemo } from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isUndefined } from 'lodash';
import useMDContext from 'context/MDContext';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  noPadding: {
    padding: 0,
  },
}));

const ROLLOVER_ALL = 'ALL';
const ROLLOVER_CAPTABLE = 'CAPTABLE';

const RolloverConfirmation = () => {
  const classes = useStyles();
  const { rollover, setRollover } = useMDContext();

  const handleChange = e => {
    setRollover(e.target.value === ROLLOVER_ALL);
  };

  const value = useMemo(() => (rollover ? ROLLOVER_ALL : ROLLOVER_CAPTABLE), [rollover]);

  return (
    <>
      <Typography className={classes.title}>
        Would you like to rollover the Valuations from the previous Measurement Date?
      </Typography>
      <div className={classes.marginTop}>
        <RadioGroup
          aria-label="rollover"
          name="rollover"
          value={!isUndefined(rollover) ? value : null}
          onChange={handleChange}>
          <FormControlLabel
            value={ROLLOVER_CAPTABLE}
            control={<Radio />}
            label="No, just rollover the primary Cap Table"
          />
          <FormControlLabel
            value={ROLLOVER_ALL}
            control={<Radio />}
            label="Yes, rollover Valuations and all related Cap Tables"
          />
        </RadioGroup>
      </div>
    </>
  );
};
export default RolloverConfirmation;
