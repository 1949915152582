/**
 * @function
 * @name showCompanyDrawer
 * @description reducer for action.
 * @param {Object} state  - reducer state
 * @param {Object} action - action context
 * @return {OBJECT} Action context
 */
export const showCompanyDialog = (state, action) => ({
  ...state,
  ...action,
});

/**
 * @function
 * @name showCompanyDrawerForEdit
 * @description reducer for action.
 * @param {Object} state  - reducer state
 * @param {Object} action - action context
 * @return {OBJECT} Action context
 */
export const showCompanyDialogForEdit = (state, action) => ({ ...state, ...action });

/**
 * @function
 * @name showFirmDrawer
 * @description reducer for action.
 * @param {Object} state  - reducer state
 * @param {Object} action - action context
 * @return {OBJECT} Action context
 */
export const showFirmDrawer = (state, action) => ({
  ...state,
  ...action,
});

export const showAllocationDrawer = (state, action) => ({
  ...state,
  ...action,
});
/**
 * @function
 * @name showCaptableDrawer
 * @description reducer for action.
 * @param {Object} state  - reducer state
 * @param {Object} action - action context
 * @return {OBJECT} Action context
 */
export const showCaptableDrawer = (state, action) => ({
  ...state,
  ...action,
});
/**
 * @function
 * @name showLoadingProgress
 * @description reducer for action.
 * @param {Object} state  - reducer state
 * @param {Object} action - action context
 * @return {OBJECT} Action context
 */
export const showLoadingProgress = (state, action) => ({
  ...state,
  ...action,
});

export const showFirmStepper = (state, action) => ({ ...state, ...action });

export const showMeasurementDatesLedger = (state, action) => ({ ...state, ...action });

export const showApproachDialog = (state, action) => ({
  ...state,
  ...action,
});

export const setDialogFlag = (state, action) => ({
  ...state,
  ...action,
});

export const setFieldAttributes = (state, action) => ({ ...state, ...action });
