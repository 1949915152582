import getOutdatedCellsChanges from './getOutdatedCellsChanges';

const columnUpdateActionCallback = ({ cell, scope, onCellsChanged, reverseParser }) => {
  const { sheet } = cell;
  const changes = getOutdatedCellsChanges({
    cell,
    rowConfig: sheet.rowConfig,
    cells: sheet.cells,
  });
  onCellsChanged(changes, scope);

  const columnsToSet = reverseParser ? reverseParser(sheet) : [...sheet.columns];
  const updatedColumn = columnsToSet[cell.columnIndex];
  updatedColumn.needs_actualization = false;
  sheet.reset({ columns: columnsToSet });
  document.dispatchEvent(new Event('columnUpdated'));
};

export default columnUpdateActionCallback;
