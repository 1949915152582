import React, { FC } from 'react';
import clsx from 'clsx';
import { Logo } from 'dashboard409a/components';
import useStyles from './styles';
import { ICompanyLogo } from './types';

const CompanyLogo: FC<ICompanyLogo> = props => {
  const { logoUrl = '' } = props;

  const classes = useStyles();

  if (!logoUrl) {
    return <Logo variation="onlyWordWhite" />;
  }

  return <img className={clsx(classes.logo)} src={logoUrl} alt="Firm logo" />;
};

export default CompanyLogo;
