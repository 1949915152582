import {
  CAP_TABLE_REFERENCE_TITLE,
  CAP_TABLE_REFERENCE_TYPE,
  FINANCIALS_REFERENCE_TITLE,
  FINANCIALS_REFERENCE_TYPE,
  FUND_OWNERSHIP_REFERENCE_TITLE,
  FUND_OWNERSHIP_REFERENCE_TYPE,
} from 'common/constants/documents';

export const CAP_TABLE_SLUG = 'cap_table';
export const FUND_OWNERSHIP_SLUG = 'fund_ownership';
export const FINANCIALS_SLUG = 'financial_statement';

export const REFERENCES_DATA = [
  { type: CAP_TABLE_REFERENCE_TYPE, name: CAP_TABLE_REFERENCE_TITLE, slug: CAP_TABLE_SLUG },
  {
    type: FUND_OWNERSHIP_REFERENCE_TYPE,
    name: FUND_OWNERSHIP_REFERENCE_TITLE,
    slug: FUND_OWNERSHIP_SLUG
  },
  { type: FINANCIALS_REFERENCE_TYPE, name: FINANCIALS_REFERENCE_TITLE, slug: FINANCIALS_SLUG },
];

export const REFERENCES_TO_ADD = 1;
export const REFERENCES_TO_DELETE = 2;
export const UPDATING_DOCUMENT_DATA = 'Updating document data...';
