import { resetScenario } from 'pages/ValuationsAllocation/allocation/EquityAllocation/config/customReverseParser/utils/resetScenario';
import { ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE } from 'pages/ValuationsAllocation/common/constants/allocation';
import { getObjectValue } from 'utillities';
import { CheckAndResetScenarioParams } from './types';

const checkAndResetScenario = (params: CheckAndResetScenarioParams) => {
  const { scenario, scenarioMethod, scenarioType, scenarioApproachId } = params;

  const {
    scenario_type: currentScenarioType,
    scenario_method: currentScenarioMethod,
    approach_uuid: currentScenarioApproachId,
  } = getObjectValue(scenario);

  let shouldResetScenariosWeights = false;

  if ([ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE].includes(currentScenarioType)) {
    shouldResetScenariosWeights = scenarioMethod !== currentScenarioMethod;
  } else {
    shouldResetScenariosWeights
      = scenarioType !== currentScenarioType
      && scenarioMethod !== currentScenarioMethod
      && scenarioApproachId !== currentScenarioApproachId;
  }

  resetScenario({ scenario, shouldResetScenariosWeights });
};

export default checkAndResetScenario;
