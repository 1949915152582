import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import theme from 'theme';
import ModalItemCompanyBox from './ModalItemCompanyBox';

const InvalidCompaniesBox = ({ companies, title }) => (
  <Box marginTop="1rem">
    {!isEmpty(companies) && (
      <Typography
        gutterBottom
        style={{
          color: theme.palette.black,
          fontSize: '0.875rem',
          fontWeight: 700,
          textTransform: 'capitalize',
        }}>
        {title}
      </Typography>
    )}
    <PerfectScrollbar
      style={{
        maxHeight: '20rem',
        overflow: 'auto',
        padding: '0.5rem 0',
      }}
      options={{
        suppressScrollX: true,
      }}>
      {companies.map(company => (
        <ModalItemCompanyBox
          key={company.id}
          company={company}
          includeCheckbox={false}
          backgroundColor={theme.palette.gray[100]}
          padding="0.8rem"
        />
      ))}
    </PerfectScrollbar>
  </Box>
);

InvalidCompaniesBox.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  title: PropTypes.string.isRequired,
};

export default InvalidCompaniesBox;
