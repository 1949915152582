import { useContext, useState } from 'react';
import { globalAction, usersAction } from 'common/actions';
import { ERROR_403 } from 'common/config/api';
import { useStore } from 'common/store';
import UnsavedChanges from 'context/UnsavedChanges';
import { UsersService } from 'services';
import useResponse from './useResponse';

const usersService = new UsersService();

/**
 * @function
 * @name useGetUsers
 * @description Get user list with optional firmId filter
 * @return {array}
 */
export const useGetUsers = () => {
  const [, dispatch] = useStore();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { processErrorResponse } = useResponse();

  const getUsers = async firmId => {
    setIsLoading(true);
    dispatch(globalAction.showLoadingProgress(true));
    try {
      const response = await usersService.getUsers(firmId);
      setData(response);
    } catch (error) {
      const defaultErrorMessage = 'An error occurred while trying to get user list';
      processErrorResponse({
        error,
        defaultErrorMessage,
        action: 'get user list',
      });
      setData(null);
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
      setIsLoading(false);
    }
  };

  return [data, getUsers, isLoading];
};

/**
 * @function
 * @name useGetUserDetails
 * @description Get user by id
 * @return {object}
 */
export const useGetUserDetails = () => {
  const [, dispatch] = useStore();
  const [userData, setUserData] = useState();
  const { processErrorResponse } = useResponse();

  const getUserDetails = async userId => {
    dispatch(globalAction.showLoadingProgress(true));
    try {
      const response = await usersService.getUserProfileById(userId);
      setUserData(response || undefined);
    } catch (error) {
      const defaultErrorMessage = 'An error occurred while trying to get user detail';
      processErrorResponse({
        error,
        defaultErrorMessage,
        action: 'get user details',
      });
      if (error.status === 403) {
        setUserData(ERROR_403);
      } else {
        setUserData(undefined);
      }
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return [userData, getUserDetails];
};

export const useCreateUser = () => {
  const [, dispatch] = useStore();

  const { setAction } = useContext(UnsavedChanges);
  const { processErrorResponse, successNotification } = useResponse();

  const create = async data => {
    if (data) {
      dispatch(globalAction.showLoadingProgress(true));
      let response = null;
      try {
        response = await usersService.create(data);
        setAction(false);
        successNotification('The new user was created successfully');
      } catch (error) {
        const defaultErrorMessage = 'An error ocurred while creating the new user';
        processErrorResponse({ error, defaultErrorMessage });
        response = error.response;
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
      return response;
    }
  };

  return [create];
};

export const useUpdateUserProfile = () => {
  const [, dispatch] = useStore();
  const { processErrorResponse } = useResponse();

  return async (userId, payload) => {
    try {
      dispatch(globalAction.showLoadingProgress(true));
      const data = await usersService.updateUserProfile(userId, payload);
      return [data, null];
    } catch (error) {
      const defaultErrorMessage = 'An error occurred while trying to update user profile';
      processErrorResponse({
        error,
        defaultErrorMessage,
        action: 'update user profile',
      });
      return [null, error];
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };
};

export const useGetLowerUserPermissions = () => {
  const [, dispatch] = useStore();
  const [data, setData] = useState([]);
  const { processErrorResponse } = useResponse();

  const fetchData = async userId => {
    dispatch(globalAction.showLoadingProgress(true));
    try {
      const response = await usersService.getLowerUserPermissions(userId);
      if (response) {
        // querying for superuser or firm admin should return a 200 and empty array
        setData(response);
        dispatch(usersAction.setOtherPermissions(response));
      }
    } catch (error) {
      setData([]);
      const defaultErrorMessage = 'An error occurred while getting permissions for user';
      processErrorResponse({
        error,
        defaultErrorMessage,
        action: 'get the user permissions',
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return [data, fetchData];
};

export const useDeleteUser = () => {
  const [, dispatch] = useStore();
  const { processErrorResponse, successNotification } = useResponse();

  return async userId => {
    try {
      dispatch(globalAction.showLoadingProgress(true));
      const data = await usersService.deleteUser(userId);
      const successMsg = data?.detail || 'User deleted successfully';
      successNotification(successMsg);
      return [data, null];
    } catch (error) {
      const {
        response: { body: errorMsg },
      } = error;
      const defaultErrorMessage = errorMsg?.detail || 'An error occurred while trying to delete this user';
      processErrorResponse({
        error,
        defaultErrorMessage,
        action: 'delete user',
      });
      return [null, error];
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };
};

export default useGetUsers;
