import { isEmpty, isString } from 'lodash';

const generateCompGroupsRequest = (compGroups, compsToDelete, inSuperuserPage) => {
  const compsToDeleteWithoutFirmString = compsToDelete.filter(group => !isString(group.id));
  const comps = !isEmpty(compGroups) ? compGroups : compsToDelete;
  const requestBody = {
    firm_id: null,
    create_list: null,
    update_list: null,
    delete_list: null
  };

  requestBody.firm_id = inSuperuserPage ? 0
    : comps.filter(({ is_global }) => !is_global)[0].firm_id;
  // get list of id of comp groups to delete
  requestBody.delete_list = compsToDeleteWithoutFirmString.filter(group => group.is_deleted)
    .map(group => group.id);
  // get groups to create (they have a random id)
  requestBody.create_list = compGroups.filter(({ id }) => /-/.test(id));
  // get groups to update (their id is an integer)
  requestBody.update_list = compGroups.filter(({ id, is_deleted, changed }) => {
    if (!isString(id) && changed) {
      return !is_deleted ?? /^\d+$/.test(id);
    }
    return null;
  });
  /* existing global comp groups return with firm_id null
  but in other to update them, send them with firm_id == 0 */
  if (inSuperuserPage) {
    /* eslint-disable no-param-reassign */
    requestBody.update_list.forEach(group => {
      group.firm_id = 0;
    });
  }
  return requestBody;
};

export default generateCompGroupsRequest;
