import * as rows from 'common/constants/financials';

const style = {
  textAlign: 'center',
  fontWeight: 700,
  color: 'var(--blue-300)',
};

export const ntmAndLtmHeadersStyle = {
  textAlign: 'center',
  fontWeight: 500,
  color: 'white',
  backgroundColor: 'var(--blue-500)',
  border: '1px solid var(--blue-500)',
};

const baseRowConfig = [
  {
    value: rows.FIRST_ROW_TITLE,
    alias: rows.FIRST_ROW_ALIAS,
    hidden: false,
    readOnly: true,
    gridType: 'string',
    dbType: 'string',
    style,
    ignoreExpr: true,
    isTitleOrHeader: true,
  },
  {
    value: rows.SECOND_ROW_TITLE,
    alias: rows.SECOND_ROW_ALIAS,
    hidden: false,
    readOnly: true,
    gridType: 'string',
    dbType: 'string',
    style,
    ignoreExpr: true,
    isTitleOrHeader: true,
  },
  {
    value: rows.THIRD_ROW_TITLE,
    alias: rows.THIRD_ROW_ALIAS,
    hidden: false,
    readOnly: true,
    gridType: 'string',
    dbType: 'string',
    style,
    ignoreExpr: true,
    isTitleOrHeader: true,
  },
];

export default baseRowConfig;
