import { useEffect, useState } from 'react';
import { DEFAULT_ROWS_PER_PAGE } from 'common/constants/general';

const useSortTable = (rows, orderByParam) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(orderByParam);
  const [displayValue, setDisplayValue] = useState(null);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (orderParam, orderBy) =>
    orderParam === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orderComparator = comparator(a[0], b[0]);
      if (orderComparator !== 0) return orderComparator;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  const handleRequestSort = (__event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (__event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (rowsPerPage && rows) {
      const value = rowsPerPage >= rows.length ? 'none' : 'inline-flex';
      setDisplayValue({ style: { display: value } });
    }
  }, [rows, rowsPerPage]);

  return {
    order,
    orderBy,
    selected,
    page,
    rowsPerPage,
    getComparator,
    stableSort,
    handleRequestSort,
    handleSelectAllClick,
    handleChangePage,
    handleChangeRowsPerPage,
    displayValue,
  };
};

export default useSortTable;
