import React, { useContext } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import MarketAdjustmentLedgerContext from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentLedger/util/MarketAdjustmentLedgerContext';
import { getSelectionCellOptions } from 'pages/ValuationsAllocation/util/getSelectionCellOptions';

const MarketAdjustmentGridSelect = props => {
  const { cellOptions } = useContext(MarketAdjustmentLedgerContext);
  const optionsData = isEmpty(cellOptions)
    ? props.cell.sheet.tableData.approach?.valuations_approach_backsolve
    : cellOptions;
  const options = getSelectionCellOptions({
    specificApproach: optionsData,
  });
  return <GridSelect menuPosition="fixed" options={options} enumerated {...props} />;
};

export default MarketAdjustmentGridSelect;

MarketAdjustmentGridSelect.propTypes = {
  cell: PropTypes.object,
  children: PropTypes.node,
  col: PropTypes.number,
  doubleClicked: PropTypes.bool,
  onDoubleClick: PropTypes.func,
  row: PropTypes.number,
  selected: PropTypes.bool,
  setDoubleClicked: PropTypes.func,
  value: PropTypes.any,
};
