import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    marginTop: {
      marginTop: theme.spacing(2),
    },
    actionButtons: {
      marginTop: theme.spacing(2),
    },
    paperSm: {
      maxWidth: '700px !important',
    },
    dangerButton: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.light,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
  })
);

export default useStyles;
