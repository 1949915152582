import {
  HIGH_GROWTH_RATE,
  LONG_TERM_GROWTH_RATE,
  WACC,
} from 'pages/Valuations/approaches/discountCashFlow/utilities/constants';

const getColumns = ({ valuationApproachDCF, dCFFinancialPeriod, waccData }) => {
  const wacc = waccData?.wacc || 0;
  const long_term_growth_rate = valuationApproachDCF[LONG_TERM_GROWTH_RATE] || 0;
  const high_growth_rate = valuationApproachDCF[HIGH_GROWTH_RATE] || 0;
  const column = {
    ...valuationApproachDCF,
    ...dCFFinancialPeriod,
    [WACC]: wacc * 100,
    [LONG_TERM_GROWTH_RATE]: long_term_growth_rate,
    [HIGH_GROWTH_RATE]: high_growth_rate,
  };
  return [column];
};

export default getColumns;
