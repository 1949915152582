import React from 'react';
import PropTypes from 'prop-types';
import { GridDate, GridDialog, GridSwitch } from '../../Grid';
import { InvestmentDateTemplate } from '../components';

const renderTemplate = props => {
  switch (props.template) {
    case 'InvestmentDateTemplate':
      return <InvestmentDateTemplate {...props} />;
    case 'GridDate':
      return <GridDate {...props} />;
    case 'Switch':
      return <GridSwitch {...props} />;
    case 'GridDialog':
      return <GridDialog {...props} />;
    default:
      return null;
  }
};

renderTemplate.propTypes = {
  template: PropTypes.string,
};

export default renderTemplate;
