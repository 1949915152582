import { useSnackbar, VariantType } from 'notistack';

const useDisplaySnack = () => {
  const { enqueueSnackbar } = useSnackbar();

  const displaySnack = (message: string, variant: VariantType = 'success') => enqueueSnackbar(message, { variant });

  return displaySnack;
};

export default useDisplaySnack;
