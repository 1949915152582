/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'common/store';
import { FormDialog } from 'components';
import { CapTableForm } from 'components/Forms';
import { useUpdateCaptableInfo } from 'services/hooks/captable';

const CapTableDialog = ({ open, onClose, onSave }) => {
  const [, updateCapTableData] = useUpdateCaptableInfo(null, null);
  const [{ captableInfo }] = useStore();
  const [formState, setFormState] = useState({
    values: { name: captableInfo.name },
    errors: {},
  });

  const saveData = async () => {
    const temporalCaptableInfo = { ...captableInfo, name: formState.values.name };
    await updateCapTableData(captableInfo.id, temporalCaptableInfo);
    onSave();
  };

  return (
    <FormDialog
      open={open}
      title={captableInfo.name}
      editMode={true}
      onSave={saveData}
      onClose={onClose}
      isValid={formState.isValid}>
      <CapTableForm defaultFormState={formState} onFormChange={formState => setFormState(formState)} />
    </FormDialog>
  );
};

CapTableDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onSave: PropTypes.func,
};

export default CapTableDialog;
