import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { MATURITY_ALIAS, VOLATILITY_SOURCE_ALIAS } from './constants';
import {
  FIVE_YEAR_VOLATILITY,
  ONE_YEAR_VOLATILITY,
  PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
  TWO_YEAR_VOLATILITY,
} from '../../guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics';

const getMExpression = (v1, v2, y1, y2) => `(${v2} - ${v1}) / (${y2} - ${y1})`;
const getVolatilityExpr = (v1, v2, y1, y2, maturityYearCellKey = 'B2') => {
  const m = getMExpression(v1, v2, y1, y2);
  return `=${m} * ${maturityYearCellKey} + ${v2} - ${m} * ${y2}`;
};

export const calculateVolatilityValue = (comparison, gpcApproach, maturityYearCellKey) => {
  const tableName = getApproachTableName({
    approach: gpcApproach,
    tableSuffix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
  });

  let volatilityValue;
  switch (true) {
    case comparison <= 1:
      volatilityValue = `=${tableName}.${ONE_YEAR_VOLATILITY}`;
      break;
    case comparison > 1 && comparison < 2:
      volatilityValue = getVolatilityExpr(
        `${tableName}.${ONE_YEAR_VOLATILITY}`,
        `${tableName}.${TWO_YEAR_VOLATILITY}`,
        1,
        2
      );
      break;
    case comparison > 2 && comparison < 5:
      volatilityValue = getVolatilityExpr(
        `${tableName}.${TWO_YEAR_VOLATILITY}`,
        `${tableName}.${FIVE_YEAR_VOLATILITY}`,
        2,
        5
      );
      break;
    case comparison === 2:
      volatilityValue = `=${tableName}.${TWO_YEAR_VOLATILITY}`;
      break;
    default:
      volatilityValue = `=${tableName}.${FIVE_YEAR_VOLATILITY}`;
      break;
  }
  return volatilityValue;
};

const afterCellChanged = (changes, cells, rowConfig, tableData) => {
  const { gpcApproaches } = tableData;
  changes.forEach(change => {
    const { cell, value } = change;
    const volatilityCell = cells.B5;
    if ([VOLATILITY_SOURCE_ALIAS, MATURITY_ALIAS].includes(cell.alias)) {
      const maturityCell = cell.alias === MATURITY_ALIAS ? value : cells.B2?.value;
      const volatilitySourceCell = cell.alias === VOLATILITY_SOURCE_ALIAS ? value : cells.B4?.value;

      const selectedApproach = gpcApproaches.find(approach => {
        const approachId = approach.valuations_approach_gpc.id || approach.panelId;
        return approachId === volatilitySourceCell;
      });

      changes.push({ cell, value });
      if (selectedApproach) {
        const comparison = Number(maturityCell);
        const volatilityValue = calculateVolatilityValue(comparison, selectedApproach);
        changes.push({ cell: volatilityCell, value: volatilityValue });
      }
    }
  });
  return changes;
};

export default afterCellChanged;
