import { ONE_HUNDRED_VALUE } from 'common/config/app';
import {
  WEIGHTING_PROBABILITIES_GREATER_THAN_100,
  WEIGHTING_PROBABILITIES_LOWER_THAN_100,
} from 'common/constants/messages/validations';
import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import { clearAndAddFeedback } from 'pages/ValuationsAllocation/util';
import { getNumberValue, getObjectValue } from 'utillities';
import { HandleWeightingProbabilityParams } from './types';

const WEIGHTING_PROBABILITY_VALIDATIONS = Object.freeze({
  [WEIGHTING_PROBABILITIES_GREATER_THAN_100]: WEIGHTING_PROBABILITIES_LOWER_THAN_100,
  [WEIGHTING_PROBABILITIES_LOWER_THAN_100]: WEIGHTING_PROBABILITIES_GREATER_THAN_100,
});

const { EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY } = SHEET_ALIASES_CONSTANTS;

const handleWeightingProbability = (params: HandleWeightingProbabilityParams) => {
  const {
    addFeedback,
    parsedColumns: parsedCells,
    removeFeedback,
    removeFeedbackFromCell,
    weightingAliasToSearch = EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY, // originally this function was used for EquityAllocation, so this default value was used
    shouldMultiplyBy100 = true, // this parameter was added to handle the case where the weighting probability is a percentage and should be multiplied by 100
  } = params;

  const weightingProbabilityCells = Object.values(getObjectValue(parsedCells)).filter(
    parsedCell => parsedCell?.alias === weightingAliasToSearch
  );

  const weightingProbability = weightingProbabilityCells.reduce(
    (accumulator, current) => accumulator + getNumberValue(current?.value as number),
    0
  );

  const weightingProbabilityTotal = shouldMultiplyBy100
    ? weightingProbability * ONE_HUNDRED_VALUE
    : weightingProbability;

  // Add Weighting Probability feedback if the total is less than 100
  if (weightingProbabilityTotal < ONE_HUNDRED_VALUE) {
    clearAndAddFeedback({
      addFeedback,
      feedback: WEIGHTING_PROBABILITIES_LOWER_THAN_100,
      removeFeedback,
      validations: WEIGHTING_PROBABILITY_VALIDATIONS,
    });
  }
  // Add Weighting Probability feedback if the total is greater than 100
  else if (weightingProbabilityTotal > ONE_HUNDRED_VALUE) {
    clearAndAddFeedback({
      addFeedback,
      feedback: WEIGHTING_PROBABILITIES_GREATER_THAN_100,
      removeFeedback,
      validations: WEIGHTING_PROBABILITY_VALIDATIONS,
    });
  }
  // Remove Weighting Probability feedbacks
  else {
    weightingProbabilityCells.forEach(weightingProbabilityCell => {
      removeFeedbackFromCell?.(weightingProbabilityCell, WEIGHTING_PROBABILITIES_LOWER_THAN_100);
      removeFeedbackFromCell?.(weightingProbabilityCell, WEIGHTING_PROBABILITIES_GREATER_THAN_100);
    });
  }
};

export default handleWeightingProbability;
