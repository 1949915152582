import React, { useMemo, useState } from 'react';
import { isEmpty, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { firmSummaryUrl } from 'common/config/urls';
import { FIRM_FUND_STEPPER_BASE_STATE, FIRM_FUND_STEPPER_TITLES } from 'common/constants/firms';
import { FirmForm } from 'components/Forms';
import Stepper from 'components/Stepper';
import { useFundForm } from 'pages/Funds/forms/FundDialogForm/hooks';
import { FirmHk } from 'services/hooks';

const FirmStepper = ({ isVisible, onClose }) => {
  const [stepperState, setStepperState] = useState({
    firm: FIRM_FUND_STEPPER_BASE_STATE,
    fund: FIRM_FUND_STEPPER_BASE_STATE,
  });

  const { firm } = stepperState;

  const history = useHistory();

  const { useCreateNewFirm } = FirmHk;
  const [, createFirm] = useCreateNewFirm();

  const { formState: fundFormState, FundForm } = useFundForm({ fund: undefined });

  const isStepperEmpty = useMemo(
    () => isEmpty(pickBy(firm.values)) && isEmpty(pickBy(fundFormState.values)),
    [firm, fundFormState]
  );

  const handleFormStateChange = (step, formState) => {
    setStepperState(previousState => ({
      ...previousState,
      [step]: {
        ...previousState[step],
        ...formState,
      },
    }));
  };

  const onSubmit = async () => {
    const { values: firmData } = firm;
    const { values: fundData } = fundFormState;

    const allData = {
      ...firmData,
      fund: fundData,
    };

    // Create Firm
    const data = await createFirm(allData);
    const { response } = data;

    if (response?.status) {
      const errors = response.body;

      if (errors) {
        const { fund: fundErrors, ...firmErrors } = errors;

        setStepperState({
          ...stepperState,
          firm: {
            ...stepperState.firm,
            dbErrors: firmErrors,
          },
          fund: {
            ...stepperState.firm,
            dbErrors: fundErrors,
          },
        });

        return false;
      }
    }

    history.push(firmSummaryUrl(data.slug));
    return true;
  };

  const steps = [
    {
      id: 'firm',
      title: 'Firm',
      isValid: firm.isValid,
      component: <FirmForm defaultFormState={firm} onFormChange={state => handleFormStateChange('firm', state)} />,
    },
    {
      id: 'fund',
      title: 'Fund',
      isValid: fundFormState.isValid,
      component: FundForm,
    },
  ];

  return (
    <Stepper
      id="firm"
      isVisible={isVisible}
      title="Create New Firm"
      stepperTitles={FIRM_FUND_STEPPER_TITLES}
      steps={steps}
      onClose={onClose}
      onSubmit={onSubmit}
      confirmCancel={!isStepperEmpty}
    />
  );
};

FirmStepper.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default FirmStepper;
