import { getNumberValue, getObjectValue } from 'utillities';
import { HandleFutureExitScenarioParams } from './types';

const handleFutureExitScenario = (params: HandleFutureExitScenarioParams) => {
  const {
    approachFutureEquityValue,
    capTableSelectionCell,
    changes,
    enableCells,
    futureEquityValueCell,
    futureExitCapTable,
    futureExitMaturity,
    futureExitVolatility,
    isApproachWithOPM,
    maturityCell,
    primaryCapTable,
    riskFreeRateCell,
    volatilityCell,
  } = params;

  const { id: primaryCapTableId } = getObjectValue(primaryCapTable);

  if (capTableSelectionCell) {
    capTableSelectionCell.readOnly = true;
    changes?.push({ cell: capTableSelectionCell, value: getNumberValue(futureExitCapTable ?? primaryCapTableId) });
  }

  if (isApproachWithOPM) {
    // Read-only OPM Inputs
    enableCells?.({ cells: [maturityCell, riskFreeRateCell, volatilityCell], isReadOnly: true });

    if (maturityCell) {
      changes?.push({ cell: maturityCell, value: getNumberValue(futureExitMaturity) });
    }

    if (riskFreeRateCell) {
      changes?.push({ cell: riskFreeRateCell, value: 0 });
    }

    if (volatilityCell) {
      changes?.push({ cell: volatilityCell, value: getNumberValue(futureExitVolatility) });
    }
  }

  if (futureEquityValueCell) {
    enableCells?.({ cells: [futureEquityValueCell], isReadOnly: true });

    changes?.push({ cell: futureEquityValueCell, value: getNumberValue(approachFutureEquityValue) });
  }
};

export default handleFutureExitScenario;
