import { isEmpty } from 'lodash';
import { getStringValue } from 'utillities';
import { HandleEnterpriseValueParams } from './types';

const handleEnterpriseValue = (params: HandleEnterpriseValueParams) => {
  const { approach, cell, isEnterpriseValue } = params;

  if (isEnterpriseValue && !isEmpty(approach)) {
    approach.enterprise_value = getStringValue((cell?.value as string) ?? 0);
  }
};

export default handleEnterpriseValue;
