import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

export const SIDEBAR_DESKTOP = {
  width: '240px',
};

export const BOTTOM_MENU_HEIGHT = '190px';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    drawer: {
      zIndex: 1000,
      backgroundColor: theme.palette.primary['600'],
      overflow: 'hidden',

      [theme.breakpoints.up('md')]: {
        borderRight: `1px solid ${theme.palette.white} !important`,
      },
    },

    openingDrawer: {
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
      }),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        ...SIDEBAR_DESKTOP,
      },
    },
    closingDrawer: {
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.leavingScreen / 2,
        easing: theme.transitions.easing.sharp,
      }),
      width: 0,
      [theme.breakpoints.up('md')]: {
        ...SIDEBAR_DESKTOP,
      },
    },

    drawerContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
      position: 'relative',
      minHeight: '100vh',
    },

    closeButtonContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(1),

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    closeButton: {
      color: theme.palette.white,
      height: '26px',
      width: '26px',
    },

    drawerTop: {},

    companyLogoContainer: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: 'center',
    },

    drawerBottom: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexGrow: 1,
      height: `calc(100% - ${BOTTOM_MENU_HEIGHT})`,
    },
    bottom: {
      // zIndex: 1000,
      paddingBottom: theme.spacing(1),
      minHeight: BOTTOM_MENU_HEIGHT,
    },
  })
);

export default useStyles;
