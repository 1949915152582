import {
  COMPANY_SUMMARY_SPREADSHEET_COLUMN_NAME,
  COMPANY_SUMMARY_SPREADSHEET_COLUMN_NAME_PLURAL,
  COMPANY_SUMMARY_SPREADSHEET_TABLE,
  COMPANY_SUMMARY_SPREADSHEET_TITLE,
} from 'pages/Portfolio/common/constants/companySummary/companySummary';

export const COMPANY_SUMMARY_SPREADSHEET_TABLE_TERMS = Object.freeze({
  columnName: COMPANY_SUMMARY_SPREADSHEET_COLUMN_NAME,
  pluralColumnName: COMPANY_SUMMARY_SPREADSHEET_COLUMN_NAME_PLURAL,
  tableName: COMPANY_SUMMARY_SPREADSHEET_TITLE,
  tableSlug: COMPANY_SUMMARY_SPREADSHEET_TABLE,
});
