import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  separator: {
    position: 'absolute',
    backgroundColor: theme.palette.secondary[400],
    zIndex: 1,
    width: '2px',
  },
}));

const CollapsedIndicator = ({ baseTopPosition, collapseLeft, tableContainerRef }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.separator}
      style={{
        top: `${baseTopPosition}px`,
        left: `${collapseLeft}px`,
        height: `${tableContainerRef.current?.offsetHeight}px`,
      }}
    />
  );
};

CollapsedIndicator.propTypes = {
  baseTopPosition: PropTypes.number.isRequired,
  collapseLeft: PropTypes.number.isRequired,
  tableContainerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
};

export default CollapsedIndicator;
