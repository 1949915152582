import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@material-ui/core';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { EMPTY_TABLE } from 'common/constants/documents';
import { DEFAULT_ROWS_PER_PAGE } from 'common/constants/general';
import { useStore } from 'common/store';
import { EnhancedTableHead } from 'components';
import { GridSkeleton } from 'components/Grid';
import { useDocumentsDragAndDrop, useSortTable } from 'services/hooks';
import { gridLongDate } from 'utillities';
import useStyles from './useStyles';
import DocumentsTableActionButtons from '../DocumentsTableActionButtons';
import EditDocumentDialog from '../EditDocumentDialog/EditDocumentDialog';
import useFileValidations from '../Folders/useFolderValidations';

const DocumentsTable = ({ columns, rows, isCurrentData, measurementDate }) => {
  const classes = useStyles();
  const [{ firmInfo }] = useStore();
  const {
    selected,
    order,
    orderBy,
    handleSelectAllClick,
    handleRequestSort,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    displayValue,
    getComparator,
    stableSort,
  } = useSortTable(rows, 'uploaded_date');
  const [openEditFileDialog, setOpenEditFileDialog] = useState(false);
  const [formState, setFormState] = useState({
    values: {},
    errors: {},
  });
  const validations = useFileValidations();
  const selectedMeasurementDateId = measurementDate ? measurementDate.id : null;
  const { handleDragStart, handleDragEnd, handleDragLeave } = useDocumentsDragAndDrop({
    validations,
    selectedMeasurementDateId,
  });

  const onCloseFileDialog = () => {
    setFormState(currentState => ({
      ...currentState,
      values: {},
    }));
    setOpenEditFileDialog(false);
  };

  const documentsTableActionButtonsProps = {
    isCurrentData,
    measurementDate,
    setFormState,
    setOpenEditFileDialog,
  };

  useEffect(() => {
    const totalFiles = rows?.length;
    if (totalFiles) {
      // Page + 1 because the first page is the 0;
      const currentPage = page + 1;
      const totalPages = Math.ceil(totalFiles / rowsPerPage);
      if (currentPage > totalPages) {
        const newPage = page - 1;
        handleChangePage(null, newPage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  if (!firmInfo || isUndefined(rows)) {
    return <GridSkeleton />;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              columns={columns}
            />
            <TableBody>
              {rows.length ? (
                <>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.document_reference_id}
                        style={{ background: '#ffff' }}
                        draggable
                        onDragStart={event => handleDragStart(event, row)}
                        onDragLeave={handleDragLeave}
                        onDragEnd={handleDragEnd}>
                        <TableCell
                          align="center"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: 'auto',
                          }}>
                          <div
                            style={{
                              width: '2rem',
                              height: '3rem',
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            <FileIcon extension={row.file.file_type} {...defaultStyles[row.file.file_type]} />
                          </div>
                        </TableCell>
                        <TableCell align="left" style={{ textDecoration: 'underline' }}>
                          {row.file.filename}.{row.file.file_type}
                        </TableCell>
                        <TableCell align="left">{row.file.uploaded_by?.full_name || '-'}</TableCell>
                        <TableCell align="left">{gridLongDate(row.file.uploaded_date) || '-'}</TableCell>
                        <TableCell align="left">
                          {!isEmpty(row.referenced_in)
                            ? row.referenced_in.map(item => item.reference_object_type).join(' / ')
                            : '-'}
                        </TableCell>
                        <TableCell align="right">
                          <DocumentsTableActionButtons fileData={row} {...documentsTableActionButtonsProps} />
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              ) : (
                <TableRow style={{ height: 33 }}>
                  <TableCell colSpan={6} className={classes.emptyFilesMsg}>
                    {EMPTY_TABLE}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {rows && rows.length > DEFAULT_ROWS_PER_PAGE && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            nextIconButtonProps={displayValue}
            backIconButtonProps={displayValue}
            labelRowsPerPage="Total Rows:"
          />
        )}
      </Paper>
      {openEditFileDialog && (
        <EditDocumentDialog
          open={openEditFileDialog}
          onClose={onCloseFileDialog}
          title="Edit File"
          formState={formState}
          onFormChange={formStateChanges => setFormState(formStateChanges)}
        />
      )}
    </div>
  );
};

DocumentsTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      align: PropTypes.string,
      sort: PropTypes.bool,
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      document_reference_id: PropTypes.number,
      file: PropTypes.shape({
        filename: PropTypes.string,
        file_type: PropTypes.string,
        uploaded_by: PropTypes.shape({
          full_name: PropTypes.string,
        }),
        uploaded_date: PropTypes.string,
      }),
      referenced_in: PropTypes.arrayOf(
        PropTypes.shape({
          reference_object_type: PropTypes.string,
        })
      ),
    })
  ),
  measurementDate: PropTypes.shape({
    id: PropTypes.number,
  }),
  isCurrentData: PropTypes.bool,
};

export default DocumentsTable;
