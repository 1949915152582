const updateFiscalQuarters = ({ conditions, allColumns, aliasesToUpdate }) => {
  // 1. Spread the array to avoid mutating it
  const tmpAllColumns = [...allColumns];

  const filterField = tmpAllColumns.some(tmpColumn => tmpColumn.mdMatchesFiscalYearEnd) ? 'year' : 'date';

  // 2. Filter out the columns based on the conditions
  // Update approach if we need to handle > than 3 conditions (isLTMQuarter, isNTMQuarter, isParent)
  const filteredColumns = tmpAllColumns.filter(col => col[conditions[0]] || col[conditions[1]] || col[conditions[2]]);

  // 3. Get the dates from the columns that matched the condition
  // Parent columns (historical/projection years, LTM, and NTM) have a 'year' property,
  // whereas fiscal periods and LTM/NTM quarters have a 'date'.
  const conditionStatementDates = filteredColumns
    .map(filteredCol => filteredCol[filterField])
    .filter(filteredCol => filteredCol);

  // 4. Update the fields given by the aliases based on the filtered columns
  return tmpAllColumns.map(quarter => {
    // Include only fiscal quarters and not LTM/NTM quarters or parent years
    if (
      conditionStatementDates.includes(quarter[filterField])
      && !quarter[conditions[0]]
      && !quarter[conditions[1]]
      && !quarter[conditions[2]]
    ) {
      aliasesToUpdate.forEach(alias => {
        // eslint-disable-next-line no-param-reassign
        quarter[alias] = filteredColumns.find(
          filteredColumn => filteredColumn[filterField] === quarter[filterField] && quarter.id !== filteredColumn.id
        )?.[alias];
      });
    }

    return quarter;
  });
};

export default updateFiscalQuarters;
