/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserFirmRole = {
  readonly id?: number;
  user?: number | null;
  firm?: number | null;
  role?: UserFirmRole.role | null;
};

export namespace UserFirmRole {
  export enum role {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
    '_5' = 5,
    '_6' = 6,
    '_7' = 7,
    '_8' = 8,
  }
}
