import { BLANK_VALUE } from 'common/constants/general';
import { GridType, RowFormat } from 'common/types/scalarSpreadsheet';

export const removeFormatIfBlank = (value: any, format: RowFormat | null, gridType: GridType | null) => {
  if (value === BLANK_VALUE) {
    return { value, format: null, gridType: null };
  }
  return { value, format, gridType };
};

export default removeFormatIfBlank;
