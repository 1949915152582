/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useStayScrolled from 'react-stay-scrolled';
import useDataSheetDialogActions from 'common/hooks/useDataSheetDialogActions';
import ScalarDatasheet from 'components/ScalarDatasheet';
import { Cell, ValueEditor } from 'components/Spreadsheet/components';
import LayoutContext from 'context/LayoutContext';
import { CollapseCol, ExpandCol } from 'icons';
import { isCellNavigable } from 'utillities';
import CollapsedIndicator from './CollapsedIndicator';
import ColumnIndicator from './ColumnIndicator';
import DraggableColumns from './DraggableColumns';
import ExpandedIndicator from './ExpandedIndicator';
import HiddenColumnsChip from './HiddenColumnsChip';
import Row from './Row';
import FeaturedSpreadsheetContext from '../context/FeaturedSpreadsheetContext';

const COLUMN_INDICATOR_ADJUSTMENT = '12px';

const commonStylesBorders = backgroundColor => ({
  backgroundColor,
  bottom: `calc(-${COLUMN_INDICATOR_ADJUSTMENT} + 2.5px)`,
  position: 'absolute',
  top: COLUMN_INDICATOR_ADJUSTMENT,
  width: '2px',
  zIndex: 1,
});

const useStyles = makeStyles(theme => ({
  absolute: {
    position: 'absolute',
  },
  borderedLeft: {
    ...commonStylesBorders(theme.palette.primary.main),
    left: 0,
  },
  borderedRight: {
    ...commonStylesBorders(theme.palette.primary.main),
    right: 0,
  },
  separator: {
    position: 'absolute',
    backgroundColor: '#8A9097',
    zIndex: 1,
    width: '2px',
  },
  divider: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    zIndex: 1,
    width: '4px',
  },
  brace: {
    position: 'absolute',
    backgroundColor: '#8A9097',
    zIndex: 1,
    top: 16,
    height: 2,
    '&:before, &:after': {
      content: '""',
      width: 20,
      height: 12,
      position: 'absolute',
      borderTop: '2px solid #8A9097',
    },
  },
  braceLeft: {
    '&:before': {
      borderTopLeftRadius: 20,
      left: -20,
    },
  },
  braceRight: {
    '&:after': {
      borderTopRightRadius: 20,
      right: -20,
    },
  },
}));

const ContentColumns = ({ data, className, tableSlug }) => {
  const classes = useStyles();
  const [hiddenColumns, setHiddenColumns] = useState({ right: 0, left: 0 });
  const [containerRect, setContainerRect] = useState({});
  const [tableWidth, setTableWidth] = useState();
  const [renderDivider, setRenderDivider] = useState(false);
  const scrollbarId = `scrollbar-${tableSlug}`;
  const { showAlert } = useContext(LayoutContext);
  const [isLedgerOpen, setIsLedgerOpen] = useState(false);
  const { dialogs } = useDataSheetDialogActions();

  const refContainer = useRef();
  const containerCurrentRef = refContainer.current;

  const {
    cells,
    onCellsChanged,
    setCollapsibleColumns,
    collapsibleColumns = {},
    showPreviousColsDivider,
  } = useContext(FeaturedSpreadsheetContext);

  const contentColumnsRef = useRef({});
  const tableContainerRef = useRef({});

  useStayScrolled(contentColumnsRef);

  const getHiddenColumns = scrollbarRef => {
    let { scrollLeft } = document.getElementById(scrollbarId);
    let tmpHiddenColumnsRight = 0;
    let tmpHiddenColumnsLeft = 0;

    if (scrollbarRef) {
      scrollLeft = scrollbarRef.scrollLeft;
    }

    if (tableContainerRef.current) {
      const cols = tableContainerRef.current.querySelectorAll('table tbody tr:nth-child(2) td');
      const containerWidth = tableContainerRef.current.offsetWidth;

      cols.forEach(col => {
        if (col.offsetLeft >= containerWidth + scrollLeft) {
          tmpHiddenColumnsRight += 1;
        } else if (col.offsetLeft > containerWidth) {
          tmpHiddenColumnsLeft += 1;
        }
      });

      setHiddenColumns({
        right: tmpHiddenColumnsRight,
        left: tmpHiddenColumnsLeft,
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      getHiddenColumns();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useLayoutEffect(() => {
    getHiddenColumns();

    if (contentColumnsRef.current) {
      const el = contentColumnsRef.current.getBoundingClientRect();
      setContainerRect(el);
    }
  }, [contentColumnsRef.current.offsetWidth]);

  useEffect(() => {
    if (!isEmpty(collapsibleColumns)) {
      getHiddenColumns();
    }
  }, [collapsibleColumns]);

  const getInvalidCellElement = invalidCell => {
    if (invalidCell) {
      return document.getElementById(`CELL-${invalidCell?.key}`);
    }
    return undefined;
  };

  const moveScrollbarToInvalidCell = cellElement => {
    if (cellElement && containerCurrentRef) {
      containerCurrentRef.scrollLeft = cellElement.offsetLeft;
    }
  };

  useEffect(() => {
    if (showAlert && cells && containerCurrentRef) {
      const invalidCell = Object.values(cells).find(cell => !isEmpty(cell.tooltipMessages));
      const invalidCellElement = getInvalidCellElement(invalidCell);
      moveScrollbarToInvalidCell(invalidCellElement);
    }
  }, [cells]);

  useEffect(() => {
    const [table] = tableContainerRef.current.getElementsByTagName('table');

    if (table.offsetWidth !== tableWidth) {
      getHiddenColumns();
      setTableWidth(table.offsetWidth);
    }
  }, [cells]);

  const handleChipClickRight = () => {
    if (containerCurrentRef) {
      containerCurrentRef.scrollLeft = tableContainerRef.current.scrollWidth;
    }
  };

  const handleChipClickLeft = () => {
    if (containerCurrentRef) {
      containerCurrentRef.scrollLeft = 0;
    }
  };

  const toggleColumn = (id, isCollapsed) => {
    setRenderDivider(false);
    const colLegendCells = document.querySelectorAll(`td[data-col-parent="${id}"]`);
    colLegendCells.forEach(cell => {
      if (isCollapsed) {
        cell.classList.remove('hidden');
        cell.classList.add('visible');
      } else {
        cell.classList.remove('visible');
        cell.classList.add('hidden');
      }
    });

    if (setCollapsibleColumns) {
      setCollapsibleColumns(prevCols => ({
        ...prevCols,
        [id]: !isCollapsed,
      }));
    }

    getHiddenColumns();
  };

  const collapseActions = () => {
    const getIcon = isCollapsed => (isCollapsed ? <ExpandCol /> : <CollapseCol />);

    return Object.entries(collapsibleColumns).map(([id, isCollapsed]) => {
      const colLegend = containerCurrentRef?.querySelector(`td[data-column-id="${id}"]`);

      const baseTopPosition = 33; // px
      const baseLeftPosition = 17; // px

      let expandedArea = 0;

      const expandedColumns = containerCurrentRef?.querySelectorAll(`td.legend[data-col-parent="${id}"]`);
      (expandedColumns || []).forEach(col => {
        expandedArea += col.offsetWidth;
      });

      if (colLegend) {
        const legend = colLegend.getBoundingClientRect();
        let collapsedLeftPosition = legend.left - containerRect.left - baseLeftPosition;
        // Compensate position when hidden columns border has showed up
        if (hiddenColumns.left > 0) {
          collapsedLeftPosition -= 1;
        } else if (hiddenColumns.right > 0) {
          collapsedLeftPosition -= 2;
        }

        const halfExpandedArea = expandedArea / 2;
        const expandedLeftPosition = collapsedLeftPosition - (halfExpandedArea - 2);
        const firstCol = expandedColumns[0].getBoundingClientRect();
        const middleCol = expandedColumns[2].getBoundingClientRect();
        const collapseLeft
          = (!!hiddenColumns.left && !!hiddenColumns.right) || (!!hiddenColumns.left && hiddenColumns.right === 0)
            ? baseLeftPosition - 2
            : baseLeftPosition;

        if (
          (isCollapsed && legend.left >= containerRect.left)
          || (!isCollapsed && middleCol.left >= containerRect.left)
        ) {
          return (
            <div
              id={id}
              key={id}
              style={{
                position: 'absolute',
                top: -baseTopPosition,
                left: isCollapsed ? `${collapsedLeftPosition}px` : `${expandedLeftPosition}px`,
              }}>
              {isCollapsed ? (
                <CollapsedIndicator
                  baseTopPosition={baseLeftPosition}
                  collapseLeft={collapseLeft}
                  tableContainerRef={tableContainerRef}
                />
              ) : (
                <ExpandedIndicator
                  firstCol={firstCol}
                  containerRect={containerRect}
                  halfExpandedArea={halfExpandedArea}
                  baseLeftPosition={baseLeftPosition}
                />
              )}
              <IconButton onClick={() => toggleColumn(id, isCollapsed)} size="small">
                {getIcon(isCollapsed)}
              </IconButton>
            </div>
          );
        }
      }

      return null;
    });
  };

  const getCellWithSelector = selectorName => containerCurrentRef?.querySelectorAll(selectorName);

  const previousColsDivider = () => {
    let leftPosition = 0;
    const baseLeftPosition = 12;
    let baseRightBoundingCell = 0;

    if (containerCurrentRef) {
      const prevQuarterCols = getCellWithSelector('td.child-with-divider');
      const prevYearcol = getCellWithSelector('td.parent-with-divider');

      if (prevQuarterCols?.length > 0 || prevYearcol?.length > 0) {
        const boundingCell = (prevQuarterCols[0] || prevYearcol[0]).getBoundingClientRect();
        baseRightBoundingCell = boundingCell.right;
      }
      leftPosition = baseRightBoundingCell - containerRect.left - baseLeftPosition;
    }

    const dividerLeftPost
      = (!!hiddenColumns.left && !!hiddenColumns.right) || (!!hiddenColumns.left && hiddenColumns.right === 0)
        ? leftPosition - 2
        : leftPosition;
    const displayDivider = dividerLeftPost + baseLeftPosition > 0 ? 'initial' : 'none';
    return (
      <div
        className={classes.divider}
        style={{
          position: 'absolute',
          left: dividerLeftPost,
          display: displayDivider,
        }}>
        <div
          className={classes.divider}
          style={{
            left: `${10}px`,
            height: `${tableContainerRef.current?.offsetHeight}px`,
          }}
        />
      </div>
    );
  };

  const shouldRenderCollapseActions = () => collapsibleColumns && containerCurrentRef;

  useEffect(() => {
    const requestId = window.requestAnimationFrame(() => {
      setRenderDivider(true);
    });
    return () => window.cancelAnimationFrame(requestId);
  }, [collapsibleColumns]);

  useEffect(() => {
    if (dialogs.find(dialog => !isUndefined(dialog.cellId) && dialog.disableWhenLedgerOpen)) {
      setIsLedgerOpen(true);
    }

    return () => {
      setIsLedgerOpen(false);
    };
  }, [dialogs]);
  return (
    <div
      ref={contentColumnsRef}
      className={classes.columnsContent}
      style={{
        ...(tableContainerRef.current.offsetHeight ? { height: `${tableContainerRef.current.offsetHeight}px` } : {}),
      }}>
      <div className={clsx(hiddenColumns.left && classes.borderedLeft)} />
      <div className={clsx(hiddenColumns.right && classes.borderedRight)} />
      <HiddenColumnsChip
        isVisible={!!hiddenColumns.right}
        label={`${hiddenColumns.right} column${hiddenColumns.right > 1 ? 's' : ''}`}
        handleClick={handleChipClickRight}
        position={{
          top: contentColumnsRef.current.offsetHeight / 2,
          left: containerRect.right,
        }}
        atRight
      />
      <HiddenColumnsChip
        isVisible={!!hiddenColumns.left}
        label={`${hiddenColumns.left} column${hiddenColumns.left > 1 ? 's' : ''}`}
        handleClick={handleChipClickLeft}
        position={{
          top: contentColumnsRef.current.offsetHeight / 2,
          left: containerRect.left - 66,
        }}
        atRight={false}
      />

      <div className={classes.absolute}>{shouldRenderCollapseActions() && collapseActions()}</div>
      <div className={classes.absolute}>{showPreviousColsDivider && renderDivider && previousColsDivider()}</div>
      <PerfectScrollbar
        onScrollX={getHiddenColumns}
        containerRef={el => {
          refContainer.current = el;
        }}
        id={scrollbarId}>
        <ColumnIndicator tableRef={tableContainerRef} hiddenColumns={hiddenColumns} />
        <DraggableColumns tableRef={tableContainerRef}>
          <div ref={tableContainerRef}>
            <ScalarDatasheet
              className={className}
              dataRenderer={cell => (cell.expr ? cell.expr : cell.value)}
              onCellsChanged={onCellsChanged}
              data={data}
              rowRenderer={Row}
              dataEditor={ValueEditor}
              cellRenderer={Cell}
              attributesRenderer={cell => ({
                ...(cell.key ? { id: `CELL-${cell.key}` } : {}),
                'data-parent': cell.parent || '',
                'data-col-parent': cell.parentColumn || '',
                'data-column-legend': cell.columnLegend || '',
                'data-column-id': cell.columnId || 0,
                'data-column-ref': cell.columnRef || 0,
              })}
              isCellNavigable={cell => isCellNavigable({ cell, containerRef: tableContainerRef })}
              {...(isLedgerOpen && { selected: null })}
            />
          </div>
        </DraggableColumns>
      </PerfectScrollbar>
    </div>
  );
};

ContentColumns.defaultProps = {
  className: '',
  tableSlug: '',
};

ContentColumns.propTypes = {
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  tableSlug: PropTypes.string,
};

export default ContentColumns;
