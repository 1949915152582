export const getKey = (alias, idx, tableId) => (
  `${tableId}_${alias}_${idx}`.toUpperCase().replace('-', '_')
);

export const getRowKey = (alias, colIndex, tableId, rowIndex) => getKey(alias, rowIndex, tableId);

export const getLedgerKey = columnLegend => (
  `ledgers_${columnLegend}`.toUpperCase().replace('-', '_')
);

export default getKey;
