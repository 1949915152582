import * as EmailValidator from 'email-validator';
import validate from 'validate.js';
import * as messages from './messages/validations';

const emailValidator = value => {
  const isValidEmail = EmailValidator.validate(value);
  if (!isValidEmail) return messages.ONLY_MAIL;
  return null;
};

validate.validators.customEmailValidator = emailValidator;

export const emailValidatorBase = {
  customEmailValidator: {},
};

export const userSchemaBase = {
  first_name: {
    presence: { allowEmpty: true },
    length: {
      maximum: 32,
    },
  },
  last_name: {
    presence: { allowEmpty: true },
    length: {
      maximum: 32,
    },
  },
  email: {
    presence: { allowEmpty: false, message: messages.IS_REQUIRED },
    email: true,
    length: {
      maximum: 64,
    },
    ...emailValidatorBase,
  },
};
