import React from 'react';

const TitleText = ({ titleArray, title }) => {
  if (titleArray) {
    return titleArray.map(line => (
      <p key={line.join('-')} style={line[1]}>
        {line[0]}
      </p>
    ));
  }
  return title;
};

export default TitleText;
