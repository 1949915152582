import { useQuery } from '@tanstack/react-query';
import { isEmpty, isNil } from 'lodash';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import { BACKSOLVES_EQUITY_VALUES_QUERY_KEY } from 'common/constants/services/allocations';
import { useGetBacksolveEquityValue } from 'services/hooks/allocations/useGetBacksolveEquityValue';
import {
  GetBacksolvesEquityValuesHook,
  GetBacksolvesEquityValuesParams,
  GetBacksolvesEquityValuesResponse,
  UseGetBacksolvesEquityValuesParams,
} from './types';

const getBacksolvesEquityValues = (params: GetBacksolvesEquityValuesParams) => {
  const { backsolveValues, fetchBacksolveEquityValue } = params;

  const result = backsolveValues?.reduce(async (accumulatorPromise, current) => {
    const accumulator = await accumulatorPromise;

    const { valuationApproachId, ...backsolve } = current;

    try {
      const backsolveEquityValue = await fetchBacksolveEquityValue({ backsolve });

      if (isNil(backsolveEquityValue)) return accumulator;

      return {
        ...accumulator,
        [valuationApproachId]: backsolveEquityValue,
      } as GetBacksolvesEquityValuesResponse;
    } catch (error) {
      return accumulator;
    }
  }, Promise.resolve({} as GetBacksolvesEquityValuesResponse));

  return result;
};

const useGetBacksolvesEquityValues = (params: UseGetBacksolvesEquityValuesParams) => {
  const { backsolveValues, shouldQueryAutomatically = true } = params;

  const fetchBacksolveEquityValue = useGetBacksolveEquityValue();

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<
    GetBacksolvesEquityValuesHook['data'],
    Error
  >({
    enabled: !isEmpty(backsolveValues) && shouldQueryAutomatically,
    queryFn: () => getBacksolvesEquityValues({ backsolveValues, fetchBacksolveEquityValue }),
    queryKey: [BACKSOLVES_EQUITY_VALUES_QUERY_KEY, backsolveValues, fetchBacksolveEquityValue],
    refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetBacksolvesEquityValues;
