/**
 * @name Global Reducer
 * @memberof module:common/reducers
 * @type {ReduxReducer}
 * @return {Object} Reducer Specification
 */

import { setAllocationCompany, setAllocationInfo } from './reducer';
import { GET_ALLOCATION_COMPANY, SET_ALLOCATION_INFO } from '../../actions/allocations/types';

export default {
  [GET_ALLOCATION_COMPANY]: setAllocationCompany,
  [SET_ALLOCATION_INFO]: setAllocationInfo,
};
