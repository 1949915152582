import { SERVICES_AN_ERROR_OCCURRED_LOADING } from 'common/constants/services/services';

// Allocation Values
export const ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY = 'ALLOCATION_VALUES_BY_COMPANY';
export const ALLOCATION_VALUES_BY_COMPANY_TITLE = 'Allocation Values';
export const ALLOCATION_VALUES_BY_COMPANY_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_LOADING(
  ALLOCATION_VALUES_BY_COMPANY_TITLE
);

// Company Summary
export const COMPANY_SUMMARY_BY_MD_QUERY_KEY = 'COMPANY_SUMMARY_BY_MD';
export const COMPANY_SUMMARY_BY_MD_TITLE = 'Company Summary';
export const COMPANY_SUMMARY_BY_MD_DEFAULT_ERROR_MESSAGE
  = SERVICES_AN_ERROR_OCCURRED_LOADING(COMPANY_SUMMARY_BY_MD_TITLE);

// Company Measurement Date
export const COMPANY_MEASUREMENT_DATE_BY_MD_QUERY_KEY = 'COMPANY_MEASUREMENT_DATE_BY_MD';
export const COMPANY_MEASUREMENT_DATE_BY_MD_TITLE = 'Company Measurement Date';
export const COMPANY_MEASUREMENT_DATE_BY_MD_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_LOADING(
  COMPANY_MEASUREMENT_DATE_BY_MD_TITLE
);

// Cap Table Versions
export const CAP_TABLE_VERSIONS_BY_COMPANY_AND_MD_QUERY_KEY = 'CAP_TABLE_VERSIONS_BY_COMPANY_AND_MD';
export const CAP_TABLE_VERSIONS_BY_COMPANY_AND_MD_TITLE = 'Cap Table Versions';
export const CAP_TABLE_VERSIONS_BY_COMPANY_AND_MD_DEFAULT_ERROR_MESSAGE = SERVICES_AN_ERROR_OCCURRED_LOADING(
  CAP_TABLE_VERSIONS_BY_COMPANY_AND_MD_TITLE
);
