import { Row, Rows } from 'common/types/scalarSpreadsheet';
import { ALLOCATION_STATIC_CUSTOM_FORMAT } from 'pages/ValuationsAllocation/common/constants/allocation';
import {
  EQUITY_ALLOCATION_LABEL,
  EQUITY_ALLOCATION_SPREADSHEET_TABLE,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import { generateColumnKey, getArrayValue, getObjectValue, getSlugValue, getStringValue } from 'utillities';
import { CreateSecuritiesRowsParams, CustomRowConfigParams } from './types';

const {
  EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION,
  EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE,
  EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
  EQUITY_ALLOCATION_SPREADSHEET_HEADER_TITLE,
  EQUITY_ALLOCATION_SPREADSHEET_MATURITY,
  EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS,
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE,
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE,
  EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE,
  EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
  EQUITY_ALLOCATION_SPREADSHEET_TOTAL,
  EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS,
  EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY,
} = SHEET_ALIASES_CONSTANTS;

const {
  EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION_TITLE,
  EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE_TITLE,
  EQUITY_ALLOCATION_SPREADSHEET_HEADERS,
  EQUITY_ALLOCATION_SPREADSHEET_MATURITY_TITLE,
  EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS_TITLE,
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE_TITLE,
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE_TITLE,
  EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE_TITLE,
  EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY_TITLE,
  EQUITY_ALLOCATION_SPREADSHEET_TOTAL_TITLE,
  EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS_TITLE,
  EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY_TITLE,
} = SHEET_TITLES_CONSTANTS;
const { ALLOCATION_METHOD } = EQUITY_ALLOCATION_SPREADSHEET_HEADERS;

const createSecuritiesRows = (params: CreateSecuritiesRowsParams): Rows => {
  const { parent, securities, data, shouldUseCustomFormat = false } = params;

  return getArrayValue(
    securities?.map(security => {
      const { name: securityName = '' } = getObjectValue(security);

      return {
        alias: generateColumnKey({
          name: securityName,
          prefix: getStringValue(parent),
        }),
        className: `${EQUITY_ALLOCATION_SPREADSHEET_TABLE}-${getSlugValue(securityName)}`,
        customFormat: shouldUseCustomFormat ? ALLOCATION_STATIC_CUSTOM_FORMAT : null,
        data,
        gridType: 'string',
        ignoreRowCopy: true,
        parent,
        readOnly: true,
        value: securityName,
      } as Row;
    })
  );
};

const customRowConfig = (params: CustomRowConfigParams): Rows => {
  const { isDisabled, securities } = params;

  return [
    {
      alias: EQUITY_ALLOCATION_SPREADSHEET_HEADER_TITLE,
      className: `${EQUITY_ALLOCATION_SPREADSHEET_TABLE}-${getSlugValue(
        EQUITY_ALLOCATION_SPREADSHEET_HEADER_TITLE
      )} division-bottom-only`,
      gridType: 'string',
      ignoreRowCopy: true,
      largeHeader: true,
      readOnly: true,
      value: EQUITY_ALLOCATION_LABEL,
    } as Row,
    {
      alias: EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
      className: `${EQUITY_ALLOCATION_SPREADSHEET_TABLE}-${getSlugValue(
        EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD
      )} subtitle`,
      gridType: 'string',
      hasSubRows: true,
      ignoreRowCopy: true,
      readOnly: isDisabled,
      value: ALLOCATION_METHOD.title,
    } as Row,
    {
      alias: EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION,
      className: `${EQUITY_ALLOCATION_SPREADSHEET_TABLE}-${getSlugValue(
        EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      parent: EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
      readOnly: isDisabled,
      value: EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION_TITLE,
    } as Row,
    {
      alias: EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS,
      className: `${EQUITY_ALLOCATION_SPREADSHEET_TABLE}-${getSlugValue(EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS)}`,
      gridType: 'string',
      hasSubRows: true,
      ignoreRowCopy: true,
      parent: EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
      readOnly: true,
      value: EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS_TITLE,
    } as Row,
    {
      alias: EQUITY_ALLOCATION_SPREADSHEET_MATURITY,
      className: `${EQUITY_ALLOCATION_SPREADSHEET_TABLE}-${getSlugValue(
        EQUITY_ALLOCATION_SPREADSHEET_MATURITY
      )} subtitle-indented`,
      gridType: 'string',
      ignoreRowCopy: true,
      parent: EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS,
      readOnly: isDisabled,
      value: EQUITY_ALLOCATION_SPREADSHEET_MATURITY_TITLE,
    } as Row,
    {
      alias: EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE,
      className: `${EQUITY_ALLOCATION_SPREADSHEET_TABLE}-${getSlugValue(
        EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE
      )} subtitle-indented`,
      gridType: 'string',
      ignoreRowCopy: true,
      parent: EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS,
      readOnly: true,
      value: EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE_TITLE,
    } as Row,
    {
      alias: EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY,
      className: `${EQUITY_ALLOCATION_SPREADSHEET_TABLE}-${getSlugValue(
        EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY
      )} subtitle-indented`,
      gridType: 'string',
      ignoreRowCopy: true,
      parent: EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS,
      readOnly: isDisabled,
      value: EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY_TITLE,
    } as Row,
    {
      alias: EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE,
      className: `${EQUITY_ALLOCATION_SPREADSHEET_TABLE}-${getSlugValue(
        EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      parent: EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
      readOnly: true,
      value: EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE_TITLE,
    } as Row,
    {
      alias: EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE,
      className: `${EQUITY_ALLOCATION_SPREADSHEET_TABLE}-${getSlugValue(
        EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE
      )} subtitle-bold`,
      gridType: 'string',
      ignoreRowCopy: true,
      parent: EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
      readOnly: true,
      value: EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE_TITLE,
    } as Row,
    {
      alias: EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
      className: `${EQUITY_ALLOCATION_SPREADSHEET_TABLE}-${getSlugValue(
        EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      parent: EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
      readOnly: isDisabled,
      value: EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY_TITLE,
    } as Row,
    {
      alias: EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS,
      className: `${EQUITY_ALLOCATION_SPREADSHEET_TABLE}-${getSlugValue(
        EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS
      )} subtitle-bold division-top-only`,
      gridType: 'string',
      hasSubRows: true,
      ignoreRowCopy: true,
      readOnly: true,
      value: EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS_TITLE,
    } as Row,
    // Securities rows for Value Allocated to Security Class
    ...createSecuritiesRows({
      data: { isValueAllocatedToSecurityClass: true },
      parent: EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS,
      securities,
    }),
    {
      alias: EQUITY_ALLOCATION_SPREADSHEET_TOTAL,
      className: `${EQUITY_ALLOCATION_SPREADSHEET_TABLE}-${getSlugValue(EQUITY_ALLOCATION_SPREADSHEET_TOTAL)} subtitle`,
      gridType: 'string',
      ignoreRowCopy: true,
      parent: EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
      readOnly: true,
      value: EQUITY_ALLOCATION_SPREADSHEET_TOTAL_TITLE,
    } as Row,
    {
      alias: EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE,
      className: `${EQUITY_ALLOCATION_SPREADSHEET_TABLE}-${getSlugValue(
        EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE
      )} subtitle-bold`,
      gridType: 'string',
      hasSubRows: true,
      ignoreRowCopy: true,
      readOnly: true,
      value: EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE_TITLE,
    } as Row,
    // Securities rows for Present Value per Share
    ...createSecuritiesRows({
      data: { isPresentValuePerShare: true },
      parent: EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE,
      securities,
      shouldUseCustomFormat: true,
    }),
  ];
};

export default customRowConfig;
