import React from 'react';
import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  media: {
    height: 140,
  },
  input: {
    height: 40,
    margin: theme.spacing(1),
  },
}));

const UserFormSkeleton = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item md={6} sm={12}>
        <Skeleton animation="wave" variant="rect" className={classes.input} />
        <br/>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <Skeleton animation="wave" variant="rect" className={classes.input} />
          </Grid>
          <Grid item md={6}>
            <Skeleton animation="wave" variant="rect" className={classes.input} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Skeleton animation="wave" variant="rect" className={classes.input} />
          </Grid>
        </Grid>
        <br/>
        <br/>
        <br/>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Skeleton animation="wave" variant="rect" className={classes.media} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} sm={12}>
        <Skeleton animation="wave" variant="rect" className={classes.input} />
        <br/>
        <Skeleton animation="wave" variant="rect" className={classes.media} />
      </Grid>
    </Grid>
  );
};

export default UserFormSkeleton;
