import React from 'react';
import {
  fullyDilutedOwnershipFormat,
  largeCurrencyFormat,
  twoDecimalPercentFormat,
  xStandardSuffixFormat,
} from 'common/formats/formats';
import { Cell, Cells } from 'common/types/scalarSpreadsheet';
import {
  FUND_SUMMARY_DRAFT_CELL,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/Funds/common/constants/fundSummary';
import { TaskCell } from 'pages/Funds/components';
import {
  generateAllRowsExpression,
  generateTotalExpression,
  getExpression,
  getObjectValue,
  handleDisabledCells,
} from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import {
  CellParserParams,
  CustomParserParams,
  GenerateTotalPublishedValuationsExpressionParams,
  GetValuationsExpressionsParams,
} from './types';

const { FUND_SUMMARY_SPREADSHEET_HEADER_TITLE, FUND_SUMMARY_SPREADSHEET_TOTAL } = SHEET_ALIASES_CONSTANTS;
const {
  FUND_SUMMARY_SPREADSHEET_COLUMNS: FUND_SUMMARY_COLUMNS,
  FUND_SUMMARY_SPREADSHEET_HEADERS: FUND_SUMMARY_HEADERS,
} = SHEET_TITLES_CONSTANTS;

const DEFAULT_ALLOW_NEGATIVE_VALUE = true;
const DEFAULT_ROW_SPAN = 1;
const EXCLUDE_HEADER = 1;
const EXCLUDE_HEADER_AND_TOTAL = 2;

const HEADER_AND_TOTAL = Object.freeze([FUND_SUMMARY_SPREADSHEET_HEADER_TITLE, FUND_SUMMARY_SPREADSHEET_TOTAL]);

const GET_ADJUSTED_FUND_SUMMARY_SPREADSHEET_COLUMNS = (isProcessManagementEnabled: boolean) => {
  if (isProcessManagementEnabled) return FUND_SUMMARY_COLUMNS;

  let adjustedColumns = { ...FUND_SUMMARY_COLUMNS };
  type FundSummaryColumnsKey = keyof typeof FUND_SUMMARY_COLUMNS;

  Object.entries(FUND_SUMMARY_COLUMNS).forEach(([key, value]) => {
    const columnKey = key as FundSummaryColumnsKey;

    if (columnKey !== Object.keys(FUND_SUMMARY_COLUMNS)[0]) {
      adjustedColumns = { ...adjustedColumns, [columnKey]: value - 1 };
    }
  });

  return adjustedColumns;
};

const GET_ADJUSTED_FUND_SUMMARY_SPREADSHEET_HEADERS = (isProcessManagementEnabled: boolean) => {
  if (isProcessManagementEnabled) return FUND_SUMMARY_HEADERS;

  const adjustedHeaders = [...FUND_SUMMARY_HEADERS.slice(0, 1), ...FUND_SUMMARY_HEADERS.slice(2)];
  return adjustedHeaders;
};

const generateTotalPublishedValuationsExpression = (
  params: GenerateTotalPublishedValuationsExpressionParams
): string => {
  const { allPublishedValuationsExpression, alphabet, currentColumn } = params;

  // Column legend (-1 excluding Company Column)
  const columnLegend = alphabet[currentColumn - 1];

  // Total Published Valuations Expression
  const totalPublishedInvestedCapitalExpression = getExpression({
    columnLegend,
    expr: `SUM(${allPublishedValuationsExpression})`,
  });

  return totalPublishedInvestedCapitalExpression;
};

const cellParser = (params: CellParserParams) => {
  const {
    allPublishedValuationsExpression,
    allValuationsExpression,
    alphabet,
    cellCustomClasses,
    colIndex,
    column,
    FUND_SUMMARY_SPREADSHEET_COLUMNS,
    FUND_SUMMARY_SPREADSHEET_HEADERS,
    fundGrossIRR,
    fundName,
    isProcessManagementEnabled,
    measurementDateName,
    row,
    rowIndex,
    tasksProgress,
    totalTotalValueKey,
  } = params;

  const rowNumber = rowIndex + 1;
  const colNumber = colIndex + 1;
  const columnLegend = alphabet[colIndex];
  const key = columnLegend + rowNumber;

  const { alias, tooltipMessages = [] } = getObjectValue(row);
  let { component = null, expr = '', forceComponent = false, format = null, gridType = 'string' } = getObjectValue(row);

  const { name: companyName = '', slug: companySlug = '' } = getObjectValue(column[alias]);
  let { value = '' } = getObjectValue(column[alias]);

  const totalPublishedInvestedCapitalExpression = generateTotalPublishedValuationsExpression({
    allPublishedValuationsExpression,
    alphabet,
    currentColumn: FUND_SUMMARY_SPREADSHEET_COLUMNS.INVESTED_CAPITAL,
  });

  // Parse cell based on Row alias
  switch (alias) {
    // Header titles
    case FUND_SUMMARY_SPREADSHEET_HEADER_TITLE:
      if (FUND_SUMMARY_SPREADSHEET_HEADERS[colNumber].defaultTooltip)
        tooltipMessages?.push(FUND_SUMMARY_SPREADSHEET_HEADERS[colNumber].defaultTooltip);

      value = FUND_SUMMARY_SPREADSHEET_HEADERS[colNumber].value;
      break;

    // Totals
    case FUND_SUMMARY_SPREADSHEET_TOTAL:
      switch (colNumber) {
        case FUND_SUMMARY_SPREADSHEET_COLUMNS.INVESTED_CAPITAL:
          expr = `=SUM(${allValuationsExpression})`;
          format = largeCurrencyFormat;
          gridType = 'number';
          break;

        case FUND_SUMMARY_SPREADSHEET_COLUMNS.REALIZED_VALUE:
          expr = `=${generateTotalPublishedValuationsExpression({
            allPublishedValuationsExpression,
            alphabet,
            currentColumn: FUND_SUMMARY_SPREADSHEET_COLUMNS.REALIZED_VALUE,
          })}`;
          format = largeCurrencyFormat;
          gridType = 'number';
          break;

        case FUND_SUMMARY_SPREADSHEET_COLUMNS.UNREALIZED_EQUITY:
          expr = `=${generateTotalPublishedValuationsExpression({
            allPublishedValuationsExpression,
            alphabet,
            currentColumn: FUND_SUMMARY_SPREADSHEET_COLUMNS.UNREALIZED_EQUITY,
          })}`;
          format = largeCurrencyFormat;
          gridType = 'number';
          break;

        case FUND_SUMMARY_SPREADSHEET_COLUMNS.TOTAL_VALUE:
          expr = `=${generateTotalPublishedValuationsExpression({
            allPublishedValuationsExpression,
            alphabet,
            currentColumn: FUND_SUMMARY_SPREADSHEET_COLUMNS.TOTAL_VALUE,
          })}`;
          format = largeCurrencyFormat;
          gridType = 'number';
          break;

        case FUND_SUMMARY_SPREADSHEET_COLUMNS.GROSS_IRR:
          format = twoDecimalPercentFormat({});
          gridType = 'percentage';
          value = fundGrossIRR;
          break;

        case FUND_SUMMARY_SPREADSHEET_COLUMNS.MOIC:
          // Total X Factor or MOIC = Total of Total Value (only Published) / Total Invested Capital (only Published)
          expr = `=IF(${totalPublishedInvestedCapitalExpression}>0,${totalTotalValueKey}/${totalPublishedInvestedCapitalExpression},0)`;
          format = xStandardSuffixFormat;
          gridType = 'number';
          break;

        default:
          break;
      }
      break;

    // Rest of the rows (excluding Header titles and Totals)
    default:
      // Parse cell based on Column number
      switch (colNumber) {
        case FUND_SUMMARY_SPREADSHEET_COLUMNS.FULLY_DILUTED_OWNERSHIP:
          format = fullyDilutedOwnershipFormat;
          gridType = 'percentage';
          break;

        case FUND_SUMMARY_SPREADSHEET_COLUMNS.GROSS_IRR:
          format = twoDecimalPercentFormat({});
          gridType = 'percentage';
          break;

        case FUND_SUMMARY_SPREADSHEET_COLUMNS.INVESTED_CAPITAL:
        case FUND_SUMMARY_SPREADSHEET_COLUMNS.REALIZED_VALUE:
        case FUND_SUMMARY_SPREADSHEET_COLUMNS.UNREALIZED_EQUITY:
        case FUND_SUMMARY_SPREADSHEET_COLUMNS.TOTAL_VALUE:
          format = largeCurrencyFormat;
          gridType = 'number';
          break;

        case FUND_SUMMARY_SPREADSHEET_COLUMNS.MOIC:
          format = xStandardSuffixFormat;
          gridType = 'number';
          break;

        // Must be at the end of the switch after all conditions are evaluated
        case FUND_SUMMARY_SPREADSHEET_COLUMNS.PROCESS_MANAGEMENT:
          if (isProcessManagementEnabled) {
            component = (
              <TaskCell
                companyName={companyName}
                companySlug={companySlug}
                fundName={fundName}
                measurementDateName={measurementDateName}
                tasksProgress={tasksProgress}
              />
            );
            forceComponent = true;
          }

          break;

        default:
          break;
      }
      break;
  }

  // Handle disabled cells (not published Valuations) and  (has only Custom Ownerships)

  const shouldCheckDisabledCell
    = !HEADER_AND_TOTAL.includes(alias) // Ignore Header titles and Total
    && (
      [
        FUND_SUMMARY_SPREADSHEET_COLUMNS.FULLY_DILUTED_OWNERSHIP, // check if has only Custom Ownerships
        FUND_SUMMARY_SPREADSHEET_COLUMNS.REALIZED_VALUE,
        FUND_SUMMARY_SPREADSHEET_COLUMNS.UNREALIZED_EQUITY,
        FUND_SUMMARY_SPREADSHEET_COLUMNS.TOTAL_VALUE,
        FUND_SUMMARY_SPREADSHEET_COLUMNS.GROSS_IRR,
        FUND_SUMMARY_SPREADSHEET_COLUMNS.MOIC,
      ] as number[]
    ).includes(colNumber);

  const includeDraftValue
    = !HEADER_AND_TOTAL.includes(alias) // Ignore Header titles and Total
    && ([FUND_SUMMARY_SPREADSHEET_COLUMNS.TOTAL_VALUE] as number[]).includes(colNumber);

  const { cellClassName, cellGridType, cellTooltipMessages, cellValue } = handleDisabledCells({
    cellCustomClasses,
    colNumber,
    column,
    gridType,
    row,
    shouldCheckDisabledCell,
    tooltipMessages,
    value,
    customValue: includeDraftValue ? FUND_SUMMARY_DRAFT_CELL : '',
  });

  return {
    [key]: {
      ...row,
      allowNegativeValue: DEFAULT_ALLOW_NEGATIVE_VALUE,
      className: cellClassName,
      columnLegend,
      component,
      expr: getExpression({ expr, columnLegend }),
      forceComponent,
      format,
      gridType: cellGridType,
      key,
      rowSpan: DEFAULT_ROW_SPAN,
      tooltipMessages: cellTooltipMessages,
      value: cellValue,
    } as Cell,
  };
};

const getValuationsExpressions = (params: GetValuationsExpressionsParams) => {
  const { alphabet, columns, FUND_SUMMARY_SPREADSHEET_COLUMNS, rowConfig } = params;

  const allValuationsExpression = generateAllRowsExpression({
    rowConfig,
    excludedRows: EXCLUDE_HEADER,
    excludeLastRow: true,
  });

  const totalRowNumber = allValuationsExpression.length + EXCLUDE_HEADER_AND_TOTAL; // Same length as rowConfig

  // Published valuations
  const allPublishedValuations = [] as number[];

  // Set all Published valuations
  Object.values(columns[FUND_SUMMARY_SPREADSHEET_COLUMNS.INVESTED_CAPITAL - 1]) // -1 excluding Company Column
    .forEach((row, rowIndex) => {
      if (!row?.disabled) allPublishedValuations.push(rowIndex + EXCLUDE_HEADER_AND_TOTAL); // + 2 excluding Index and Header
    });

  // All Published valuations expression
  const allPublishedValuationsExpression = generateTotalExpression({
    items: allPublishedValuations,
    generateIndex: false,
  });

  // Columns legends (-1 excluding Company Column)
  const totalValueColLegend = alphabet[FUND_SUMMARY_SPREADSHEET_COLUMNS.TOTAL_VALUE - 1];

  // Cell key of total of Total Values
  const totalTotalValueKey = `${totalValueColLegend}${totalRowNumber}`;

  return {
    allPublishedValuationsExpression,
    allValuationsExpression,
    totalTotalValueKey,
  };
};

const customParser = (params: CustomParserParams) => {
  const {
    cellCustomClasses,
    columns,
    fundGrossIRR,
    fundName,
    isProcessManagementEnabled,
    measurementDateName,
    rowConfig,
    tasksProgress,
  } = params;

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];

  const FUND_SUMMARY_SPREADSHEET_COLUMNS = GET_ADJUSTED_FUND_SUMMARY_SPREADSHEET_COLUMNS(isProcessManagementEnabled);
  const FUND_SUMMARY_SPREADSHEET_HEADERS = GET_ADJUSTED_FUND_SUMMARY_SPREADSHEET_HEADERS(isProcessManagementEnabled);

  const { allPublishedValuationsExpression, allValuationsExpression, totalTotalValueKey } = getValuationsExpressions({
    alphabet,
    columns,
    FUND_SUMMARY_SPREADSHEET_COLUMNS,
    rowConfig,
  });

  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      cells = {
        ...cells,
        ...cellParser({
          allPublishedValuationsExpression,
          allValuationsExpression,
          alphabet,
          cellCustomClasses,
          colIndex,
          column,
          FUND_SUMMARY_SPREADSHEET_COLUMNS,
          FUND_SUMMARY_SPREADSHEET_HEADERS,
          fundGrossIRR,
          fundName,
          isProcessManagementEnabled,
          measurementDateName,
          row,
          rowIndex,
          tasksProgress,
          totalTotalValueKey,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
