import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  actionButton: {
    fontSize: '1rem',
    textTransform: 'none',
    fontWeight: 500,
    marginRight: theme.spacing(3),
    padding: '0.375rem 0',
  },
}));

const FooterActionButton = ({ title, icon, onClick, isDisabled }) => {
  const classes = useStyles();
  return (
    <Button disabled={isDisabled} className={classes.actionButton} endIcon={icon} color="primary" onClick={onClick}>
      <span>{title}</span>
    </Button>
  );
};

FooterActionButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default FooterActionButton;
