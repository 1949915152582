import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Prompt, withRouter } from 'react-router-dom';

const UnsavedChangesGuard = ({ when, next, shouldBlockNavigation, location, showDialog, isVisible }) => {
  const [visibleDialog, setVisibleDialog] = useState(false);
  const targetLocation = useRef();

  const onConfirm = useCallback(() => {
    if (targetLocation.current) {
      next(targetLocation.current);
    }
  }, [next]);

  useEffect(() => {
    if (visibleDialog && !isVisible) {
      showDialog(onConfirm);
    }
  }, [visibleDialog, isVisible, showDialog, onConfirm]);

  useEffect(() => {
    setVisibleDialog(isVisible);
  }, [isVisible]);

  const handleBlockedNavigation = nextLocation => {
    if (shouldBlockNavigation(nextLocation) && location.pathname !== nextLocation.pathname) {
      setVisibleDialog(true);
      targetLocation.current = nextLocation;
      return false;
    }
    return true;
  };

  return <Prompt when={when} message={handleBlockedNavigation} />;
};

UnsavedChangesGuard.propTypes = {
  isVisible: PropTypes.bool,
  showDialog: PropTypes.func,
  location: PropTypes.object,
  shouldBlockNavigation: PropTypes.func,
  next: PropTypes.func,
  when: PropTypes.bool,
};

export default withRouter(UnsavedChangesGuard);
