import { getSlugValue } from 'utillities';
import {
  CONVERSION_RATE_ALIAS,
  CUMMULATIVE_DIVIDENDS_ALIAS,
  INVESTMENT_DATE_ALIAS,
  ISSUE_PRICE_ALIAS,
  LIQUIDATION_PREFERENCE_ALIAS,
  MULTIPLE_INVESTMENT_LEDGER_ALIAS,
  PIK_SHARES_ALIAS,
  SECURITY_TYPE_ALIAS,
  SHARES_FULLY_DILUTED_ALIAS,
  SHARES_OUTSTANDING_ALIAS,
  TOTAL_PREFERENCE_ALIAS,
} from '../cap-table';

// General
export const FORBIDDEN_FUNDOWNERSHIP_INFO = 'You do not have permissions to view the fund ownership info';
export const GET_FUNDOWNERSHIP_INFO_ACCESS = 'Please contact your manager to gain access';
export const ADD_CUSTOM_SECURITY_OWNERSHIP = 'Add Custom Security Ownership';
export const ADD_FUND_OWNERSHIP = 'Add Fund Ownership';

export const SECURITY_ROW_NUMBER = 2;
export const SECURITY_ALIAS = 'security';

export const FUND_ROW_NUMBER = 3;
export const FUND_ALIAS = 'fund';

export const INVESTMENT_DATE_ROW_NUMBER = 4;
// The alias is taken from the cap table constants to avoid unnecessary repetition here

export const INVESTMENT_CAPITAL_ALIAS = 'invested_capital';
export const INVESTMENT_CAPITAL_ROW_NUMBER = 5;

export const LOAN_VALUE_ROW_NUMBER = 6;
export const LOAN_VALUE_ALIAS = 'loan_value';

export const SHARES_ROW_NUMBER = 7;
export const SHARES_ALIAS = 'shares';

export const SHARES_AS_CONVERTED_ROW_NUMBER = 8;
export const SHARES_AS_CONVERTED_ALIAS = 'shares_converted';

export const FULLY_DILUTED_OWNERSHIP_ROW_NUMBER = 9;
export const FULLY_DILUTED_OWNERSHIP_ALIAS = 'diluted_own_percent';

export const INITIAL_LIQUIDATION_PREFERENCE_ROW_NUMBER = 10;
export const INITIAL_LIQUIDATION_PREFERENCE_ALIAS = 'initial_liquidation_pref';

export const LIQUIDATION_PREFERENCE_PLUS_ROW_NUMBER = 11;
export const LIQUIDATION_PREFERENCE_PLUS_ALIAS = 'liquidation_pref_dividends';

export const CASH_DISTRIBUTION_ROW_NUMBER = 12;
export const CASH_DISTRIBUTION_ALIAS = 'cash_distribution';

export const PROCEEDS_FROM_SOLD_SHARES_ROW_NUMBER = 13;
export const PROCEEDS_FROM_SOLD_SHARES_ALIAS = 'shares_distribution';

export const SHARES_LEDGER_ALIAS = 'shares_distribution_ledger';
export const SHARES_LEDGER_ROW_NUMBER = 14;

export const CASH_DISTRIBUTION_LEDGER_ALIAS = 'cash_distribution_ledger';
export const CASH_DISTRIBUTION_LEDGER_ROW_NUMBER = 15;

export const CUSTOM_SECURITY_NAME_ROW_NUMBER = 16;
export const CUSTOM_SECURITY_NAME_ALIAS = 'custom_security_name';

export const FUND_OWNERSHIP_LOCKED_MESSAGE
  = 'This Fund Ownership table version is used in a Final Valuation, any changes saved could affect that valuation.';

export const ROWS_TO_RESET_WHEN_CHANGING_SECURITY = [
  INVESTMENT_DATE_ROW_NUMBER,
  INVESTMENT_CAPITAL_ROW_NUMBER,
  SHARES_ROW_NUMBER,
  SHARES_AS_CONVERTED_ROW_NUMBER,
  FULLY_DILUTED_OWNERSHIP_ROW_NUMBER,
  INITIAL_LIQUIDATION_PREFERENCE_ROW_NUMBER,
  LIQUIDATION_PREFERENCE_PLUS_ROW_NUMBER,
  CASH_DISTRIBUTION_ROW_NUMBER,
  PROCEEDS_FROM_SOLD_SHARES_ROW_NUMBER,
];

export const CAP_TABLE_FIELDS = [
  ISSUE_PRICE_ALIAS,
  LIQUIDATION_PREFERENCE_ALIAS,
  CONVERSION_RATE_ALIAS,
  TOTAL_PREFERENCE_ALIAS,
  PIK_SHARES_ALIAS,
  SHARES_OUTSTANDING_ALIAS,
];

export const CAP_TABLE_CELLS = [
  SECURITY_TYPE_ALIAS,
  INVESTMENT_DATE_ALIAS,
  CUMMULATIVE_DIVIDENDS_ALIAS,
  MULTIPLE_INVESTMENT_LEDGER_ALIAS,
];

export const CAP_TABLE_TOTAL_FIELDS = [SHARES_FULLY_DILUTED_ALIAS];

export const INVESTMENT_LOAN_ROWS = [INVESTMENT_DATE_ROW_NUMBER, INVESTMENT_CAPITAL_ROW_NUMBER, LOAN_VALUE_ROW_NUMBER];

export const FUND_OWNERSHIP_LABEL = 'Fund Ownership';
export const FUND_OWNERSHIP_SPREADSHEET_COLUMN_NAME = 'Ownership';
export const FUND_OWNERSHIP_SPREADSHEET_COLUMN_NAME_PLURAL = 'Ownership';
export const FUND_OWNERSHIP_SPREADSHEET_TITLE = FUND_OWNERSHIP_LABEL;
export const FUND_OWNERSHIP_SPREADSHEET_SLUG = getSlugValue(FUND_OWNERSHIP_SPREADSHEET_TITLE);
export const FUND_OWNERSHIP_SPREADSHEET_TABLE = `${FUND_OWNERSHIP_SPREADSHEET_SLUG}-table`;

export const FUND_OWNERSHIP_SPREADSHEET_TABLE_TERMS = Object.freeze({
  columnName: FUND_OWNERSHIP_SPREADSHEET_COLUMN_NAME,
  pluralColumnName: FUND_OWNERSHIP_SPREADSHEET_COLUMN_NAME_PLURAL,
  tableName: FUND_OWNERSHIP_SPREADSHEET_TITLE,
  tableSlug: FUND_OWNERSHIP_SPREADSHEET_TABLE,
});
