/* eslint-disable spaced-comment,no-multi-spaces */
import { largeCurrencyFormat } from 'common/formats/formats';
import {
  DISCOUNT_PERIODS_CONFIG,
  ESTIMATED_EXIT_VALUE_CONFIG,
  MULTIPLE_CONFIG,
  PV_OF_TERMINAL_VALUE_CONFIG_2,
  SELECT_MULTIPLE_CONFIG,
  TERMINAL_VALUE_CONFIG,
  WACC_CONFIG,
} from 'pages/Valuations/approaches/discountCashFlow/dcfTerminalValue/util/rowConfigConstants';
import {
  DCF_FINANCIAL_PERIOD_TABLE_NAME,
  EBITDA_GPC_TITLE,
  GPC_MULTIPLE,
  GPC_MULTIPLE_SELECTION,
  MAX_VALUATION_NUMBER_DIGITS,
  METRIC_TOTAL,
  VALUES_OPTIONS,
} from 'pages/Valuations/util/constants';

const TITLE_CONFIG = {
  readOnly: true,
  value: '',
  alias: EBITDA_GPC_TITLE,
  gridType: 'string',
};

const TERMINAL_YEAR_METRIC_CONFIG = {
  readOnly: true,
  value: 'Terminal Year Metric',
  gridType: 'number',
  format: largeCurrencyFormat,
  maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
  alias: METRIC_TOTAL,
  origin: DCF_FINANCIAL_PERIOD_TABLE_NAME,
};

const AVERAGE_EXIT_VALUE_CONFIG = {
  readOnly: true,
  value: 'Average Exit Value',
  gridType: 'number',
  format: largeCurrencyFormat,
  maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
  alias: 'estimated_exit_value',
  expr: '=MEAN([A6, B6])',
};

export default ({
  mainTableName: mainTableReference,
  dcfWaccSheet: dcfWacc,
  companiesRows,
  percentileSelections,
  benchmarkType,
  options = VALUES_OPTIONS,
  isDisabled,
}) => [
  { ...TERMINAL_VALUE_CONFIG(isDisabled), ignoreRowCopy: true }, //1
  TITLE_CONFIG, //2
  TERMINAL_YEAR_METRIC_CONFIG, //3
  SELECT_MULTIPLE_CONFIG(GPC_MULTIPLE_SELECTION, options, percentileSelections, isDisabled), //4
  MULTIPLE_CONFIG(GPC_MULTIPLE, companiesRows, options, benchmarkType, isDisabled, 4), //5
  ESTIMATED_EXIT_VALUE_CONFIG(3, 5), //6
  { ...AVERAGE_EXIT_VALUE_CONFIG, ignoreRowCopy: true, className: 'division-top-only' }, //7
  { ...WACC_CONFIG(dcfWacc), ignoreRowCopy: true }, //8
  {
    ...DISCOUNT_PERIODS_CONFIG(mainTableReference),
    ignoreRowCopy: true,
  }, //9
  { ...PV_OF_TERMINAL_VALUE_CONFIG_2(7, 8, 9), ignoreRowCopy: true }, //10
];
