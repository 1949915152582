/* eslint-disable import/no-unresolved */
import React from 'react';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { APP_VERSION } from 'common/config/app';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    padding: theme.spacing(3),
    backgroundColor: ({ shouldDisplayGrayBg }) => (shouldDisplayGrayBg ? 'transparent' : theme.palette.white),
  },
  separator: {
    margin: `0px ${theme.spacing(3)}px`,
    color: 'silver',
  },
}));

const Footer = ({ shouldDisplayGrayBg }) => {
  const classes = useStyles({ shouldDisplayGrayBg });

  const hasFirstLetter = !!(APP_VERSION && APP_VERSION.trim()[0].toUpperCase() === 'V');

  return (
    <div className={classes.root}>
      <p>
        ©{' '}
        <Link component="a" href="https://scalar.io/" target="_blank">
          Scalar Technologies
        </Link>{' '}
        {new Date().getFullYear()} <span className={classes.separator}>|</span>
        {hasFirstLetter ? '' : 'v'}
        {APP_VERSION}
      </p>
    </div>
  );
};

Footer.propTypes = {
  shouldDisplayGrayBg: PropTypes.bool,
};

export default Footer;
