import React, { MouseEvent } from 'react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

type DateFilterProps = {
  value: string;
  handleChange: (event: MouseEvent<{ value: string }>) => void;
};

const useStyles = makeStyles(theme =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(2),
    },
    buttonStyle: {
      border: 'none !important',
    },
  })
);

const DateFilter = ({ value, handleChange }: DateFilterProps) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonContainer}>
      <Button
        className={classes.buttonStyle}
        variant={value === 'month_3' ? 'contained' : 'outlined'}
        color="primary"
        value="month_3"
        onClick={handleChange}>
        3M
      </Button>
      <Button
        className={classes.buttonStyle}
        variant={value === 'month_6' ? 'contained' : 'outlined'}
        color="primary"
        value="month_6"
        onClick={handleChange}>
        6M
      </Button>
      <Button
        className={classes.buttonStyle}
        variant={value === 'year_1' ? 'contained' : 'outlined'}
        color="primary"
        value="year_1"
        onClick={handleChange}>
        1Y
      </Button>
      <Button
        className={classes.buttonStyle}
        variant={value === 'year_3' ? 'contained' : 'outlined'}
        color="primary"
        value="year_3"
        onClick={handleChange}>
        3Y
      </Button>
      <Button
        className={classes.buttonStyle}
        variant={value === 'year_5' ? 'contained' : 'outlined'}
        color="primary"
        value="year_5"
        onClick={handleChange}>
        5Y
      </Button>
      <Button
        className={classes.buttonStyle}
        variant={value === 'year_10' ? 'contained' : 'outlined'}
        color="primary"
        value="year_10"
        onClick={handleChange}>
        10Y
      </Button>
    </div>
  );
};

export default DateFilter;
