/**
 * @name Captable Action
 * @memberof module:common/actions
 * @type {ReduxAction}
 * @return {Object} Global Actions
 */
import { ERROR_403 } from 'common/config/api';
import { FirmService, UsersService } from 'services';
import {
  GET_FIRM_INFO,
  GET_FIRM_LIST,
  GET_FUND_LIST_BY_FIRM_ID,
  GET_SELECTED_USER_PERMISSIONS,
  SET_FIRM_ID,
  SET_FIRM_INFO,
  SET_FIRM_INFO_ID,
  SET_FIRM_LIST,
  SET_SELECTED_USER_PERMISSIONS,
  SET_USER_MANAGEMENT_FIRM_LIST,
} from './types';

const firmService = new FirmService();

/**
 * @function
 * @name getFirmList
 * @description Get list of existent firm
 * @return {array}
 */
export const getFirmList = async () => {
  try {
    const { results: firms, requires_2fa, user_has_2fa, field_attributes } = await firmService.allData();
    return {
      type: GET_FIRM_LIST,
      firms,
      requires_2fa,
      user_has_2fa,
      field_attributes,
    };
  } catch (error) {
    if (error.status === 403) {
      return { type: GET_FIRM_LIST, firmList: ERROR_403 };
    }
    return error;
  }
};

/**
 * @function
 * @name getSelectedUserPermissions
 * @description Get firms filtered for user management view
 * @return {array}
 */
export const getSelectedUserPermissions = async userId => {
  const usersService = new UsersService();
  try {
    const selectedUserPermissions = await usersService.getUserPermission(userId);
    return { type: GET_SELECTED_USER_PERMISSIONS, selectedUserPermissions };
  } catch (error) {
    return error;
  }
};

export const getFirmById = async firmId => {
  try {
    const firmInfo = await firmService.getFirmById(firmId);
    return { type: GET_FIRM_INFO, firmInfo };
  } catch (error) {
    return error;
  }
};

/**
 * @function
 * @name getFundListByFirmId
 * @param {integer} firmId
 * @description Get all funds that belongs to a firm
 * @return {array} Array of funds
 */
export const getFundListByFirmId = async firmId => {
  try {
    const fundList = await firmService.getFundListByFirmId(firmId);
    return { type: GET_FUND_LIST_BY_FIRM_ID, fundList };
  } catch (error) {
    return error;
  }
};

/**
 * @function
 * @name setFirmInfo
 * @description set firm data selected.
 * @param {OBJECT} firmInfo  - reducer state
 * @return {OBJECT} Action context
 */
export const setFirmInfo = firmInfo => ({ type: SET_FIRM_INFO, firmInfo });

/**
 * @function
 * @name setFirmInfoByID
 * @description action that set Firm info by its Id
 * @param {object} firmState
 * @return {object} Action context
 */
export const setFirmInfoByID = firmState => ({
  type: SET_FIRM_INFO_ID,
  firmState,
});

/**
 * @function
 * @name setFirmList
 * @description set firm list (currently used to clear the list)
 * @return {array} Action context
 */
export const setFirmList = firmListInfo => ({
  type: SET_FIRM_LIST,
  firmList: firmListInfo,
});

export const setUserManagementFirmList = userManagementFirmListInfo => ({
  type: SET_USER_MANAGEMENT_FIRM_LIST,
  userManagementFirmList: userManagementFirmListInfo,
});

export const setSelectedUserPermissions = selectedUserPermissionsInfo => ({
  type: SET_SELECTED_USER_PERMISSIONS,
  selectedUserPermissions: selectedUserPermissionsInfo,
});

export const setFirmId = firmId => ({ type: SET_FIRM_ID, firmId });
