import { Cell } from 'common/types/scalarSpreadsheet';
import { getKey, onCellsChanged, toString } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { gridShortDate } from 'utillities/datesFormats';
import {
  ColConfigProps,
  EffectiveTaxRateLedgerParserParams,
  RowConfigProps,
  TCells,
  TChanges,
  ValueType,
} from '../types';

const customParser = async (params: EffectiveTaxRateLedgerParserParams) => {
  const { initialObject, rowConfig, columns, tableId } = params;
  let cells: TCells = {};
  const changes: TChanges[] = [];
  const alphabet = alphabetGenerator([], columns.length);

  rowConfig.forEach((row: RowConfigProps, rowIdx: number) => {
    columns.forEach((col: ColConfigProps, colIdx: number) => {
      const columnLegend = alphabet[colIdx];
      const key = getKey(row.alias, colIdx, tableId);
      let value: ValueType = null;

      if (col.alias === 'value') {
        const auxValue = initialObject[rowIdx].value ?? '';
        value = row.dbType === 'dbDate' ? toString(gridShortDate(auxValue)) : auxValue;
      } else {
        value = row.value ?? '';
      }

      const cell: Cell = {
        ...col,
        ...row,
        columnLegend,
        key,
        alias: row.alias ?? '',
        colAlias: col.alias ?? '',
        rowAlias: row.alias ?? '',
        colNumber: colIdx,
        rowNumber: rowIdx,
        value,
        className: row.readOnly && colIdx === 1 ? '' : 'title-col align-left',
        insideLedger: true,
        tableId,
      };
      cells[key] = cell;
      changes.push({ cell, value });
    });
  });

  cells = await onCellsChanged(changes, cells);
  return cells;
};

export default customParser;
