import React, { useEffect, useState } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { isEmpty, isUndefined } from 'lodash';
import { useStore } from 'common/store';
import { useGetBaseSetOfQuestionsAndDocuments } from 'services/hooks/firm';
import FirmRequestsFooter from './components/FirmRequestsFooter';
import FirmRequestsHeader from './components/FirmRequestsHeader';
import RequestsContent from './components/RequestContent';

const FirmRequests = () => {
  const [updateTaskList, setUpdateTaskList] = useState(false);
  const [{ firmInfo }] = useStore();
  const { name, id: firmId } = firmInfo || {};
  const [firmSetOfQuestionsAndDocuments, getFirmSetOfQuestionsAndDocuments] = useGetBaseSetOfQuestionsAndDocuments();
  const { requested_documents: documents, questions, companies } = firmSetOfQuestionsAndDocuments || {};

  const firmRequestsProps = {
    documents,
    questions,
    firmId,
    companies,
    setUpdateTaskList,
  };

  useEffect(() => {
    if (firmId) {
      getFirmSetOfQuestionsAndDocuments(firmId);
      setUpdateTaskList(false);
    }
  }, [firmId, getFirmSetOfQuestionsAndDocuments, updateTaskList]);

  return (
    <Paper
      elevation={2}
      style={{
        padding: '2rem 1rem',
        margin: '1rem',
      }}>
      {name && <FirmRequestsHeader name={name} />}
      {!isUndefined(companies) && isEmpty(companies) && (
        <Typography variant="h6" style={{ textAlign: 'center' }}>
          No companies created yet
        </Typography>
      )}
      {!isUndefined(companies) ? (
        <>
          <RequestsContent {...firmRequestsProps} />
          <FirmRequestsFooter {...firmRequestsProps} />
        </>
      ) : (
        <Skeleton variant="rect" width="100%" height={400} />
      )}
    </Paper>
  );
};

export default FirmRequests;
