import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  brace: {
    position: 'absolute',
    backgroundColor: theme.palette.secondary[400],
    zIndex: 1,
    top: 16,
    height: 2,
    '&:before, &:after': {
      content: '""',
      width: 20,
      height: 12,
      position: 'absolute',
      borderTop: `2px solid ${theme.palette.secondary[400]}`,
    },
  },
  braceLeft: {
    '&:before': {
      borderTopLeftRadius: 20,
      left: -20,
    },
  },
  braceRight: {
    '&:after': {
      borderTopRightRadius: 20,
      right: -20,
    },
  },
}));

const ExpandedIndicator = ({ firstCol, containerRect, halfExpandedArea, baseLeftPosition }) => {
  const classes = useStyles();

  if (firstCol && containerRect && firstCol.left >= containerRect.left) {
    return (
      <>
        <div
          className={`${classes.brace} ${classes.braceLeft}`}
          style={{
            width: halfExpandedArea - 40,
            left: `${baseLeftPosition - halfExpandedArea + 27}px`,
          }}
        />
        <div
          className={`${classes.brace} ${classes.braceRight}`}
          style={{
            width: `${halfExpandedArea - 40}px`,
            left: `${baseLeftPosition + 12}px`,
          }}
        />
      </>
    );
  }
  return null;
};

ExpandedIndicator.propTypes = {
  firstCol: PropTypes.bool.isRequired,
  containerRect: PropTypes.shape({
    left: PropTypes.number.isRequired,
  }).isRequired,
  halfExpandedArea: PropTypes.number.isRequired,
  baseLeftPosition: PropTypes.number.isRequired,
};

export default ExpandedIndicator;
