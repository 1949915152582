import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const RequestDocumentDialogSubtitle = ({ text }) => (
  <Typography variant="h4" gutterBottom>
    {text}
  </Typography>
);

RequestDocumentDialogSubtitle.propTypes = {
  text: PropTypes.string,
};

export default React.memo(RequestDocumentDialogSubtitle);
