/* eslint-disable quote-props */
/* eslint-disable no-underscore-dangle */

/**
 * @file services/base/index.js
 * @class BaseService
 * @classdesc
 * Interface to comunicate with the API.
 * This version is a revision based on Mauricio Sanchez <mausadd@outlook.com> implementation
 * @since v1.0
 * @author clenondavis <dev@carloslenon.com>
 */

import request from 'superagent';

class BaseService {
  defaultHeaders = { 'Content-Type': 'application/json' };

  _getAccessToken = () => localStorage.getItem('access');

  /**
   * @function
   * @name _getHeaders
   * @memberof BaseService
   * @description Compose the request headers, including the authorization token.
   * @param {OBJECT} defaultHeaders Default headers overridden by particular headers provided.
   * @param {OBJECT} userHeaders Particular headers for a request.
   * @param {String} token Authorization token if the user is logged-in.
   * @return {OBJECT} The definitive headers to be used in a request.
   */
  _getHeaders = (defaultHeaders, userHeaders) => {
    const headers = Object.assign(defaultHeaders, userHeaders);
    return headers;
  };

  /**
   * @function
   * @name get
   * @memberof BaseService
   * @description Function to perform a get request to the API.
   * @param {String} url The URL path pointing to an specific resource in the API.
   * @param {OBJECT} query An string or an object that represent the query string for a request.
   * @param {STRING} moduleView module for the origin request.
   * @param {BOOLEAN} isList amount of element in the response
   * @param {OBJECT} headers Specific headers to be sent in a request.
   * @return {OBJECT} Response sent by the API.
   */
  async get(url, query, moduleView, isList, headers) {
    try {
      const response = await request
        .get(url)
        .set(this._getHeaders(this.defaultHeaders, headers))
        .auth(this._getAccessToken(), { type: 'bearer' });
      return await Promise.resolve(response.body);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
   * @function
   * @name getFile
   * @memberof BaseService
   * @description Function to perform a post request to the API.
   * @param {String} url The URL path pointing to an specific resource in the API.
   * @param {OBJECT} data An string or an object that represent the data or body for a request.
   * @param {STRING} moduleView module for the origin request.
   * @param {BOOLEAN} isList amount of element in the response
   *
   * @param {OBJECT} headers Specific headers to be sent in a request.
   * @return {OBJECT} Response sent by the API.
   */
  async getFile(url, data, moduleView, isList, headers) {
    try {
      const response = await request
        .post(url)
        .responseType('blob')
        .set(this._getHeaders(this.defaultHeaders, headers))
        .auth(this._getAccessToken(), { type: 'bearer' })
        .send(data);
      return await Promise.resolve(response.body);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
   * @function
   * @name PUT
   * @memberof BaseService
   * @description Function to perform a get request to the API.
   * @param {String} url The URL path pointing to an specific resource in the API.
   * @param {OBJECT} data An string or an object that represent the data or body for a request.
   * @param {STRING} moduleView module for the origin request.
   * @param {BOOLEAN} isList amount of element in the response
   * @param {OBJECT} headers Specific headers to be sent in a request.
   * @return {OBJECT} Response sent by the API.
   */
  async put(url, data, moduleView, isList, headers) {
    try {
      const response = await request
        .put(url)
        .set(this._getHeaders(this.defaultHeaders, headers))
        .auth(this._getAccessToken(), { type: 'bearer' })
        .send(data);
      return await Promise.resolve(response.body);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
   * @function
   * @name POST
   * @memberof BaseService
   * @description Function to perform a get request to the API.
   * @param {String} url The URL path pointing to an specific resource in the API.
   * @param {OBJECT} data An string or an object that represent the data or body for a request.
   * @param {STRING} moduleView module for the origin request.
   * @param {BOOLEAN} isList amount of element in the response
   *
   * @param {OBJECT} headers Specific headers to be sent in a request.
   * @return {OBJECT} Response sent by the API.
   */
  async post(url, data, moduleView, isList, headers) {
    try {
      const response = await request
        .post(url)
        .set(this._getHeaders(this.defaultHeaders, headers))
        .auth(this._getAccessToken(), { type: 'bearer' })
        .send(data);
      return await Promise.resolve(response.body);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
   * @function
   * @name delete
   * @memberof BaseService
   * @description Function to perform a delete request to the API.
   * @param {String} url The URL path pointing to an specific resource in the API.
   * @param {STRING} moduleView module for the origin request.
   * @param {BOOLEAN} isList amount of element in the response
   * @param {OBJECT} headers Specific headers to be sent in a request.
   * @return {OBJECT} Response sent by the API.
   */
  async delete(url, moduleView, isList, headers) {
    try {
      const response = await request
        .del(url)
        .set(this._getHeaders(this.defaultHeaders, headers))
        .auth(this._getAccessToken(), { type: 'bearer' });
      return await Promise.resolve(response.body);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async postNoAuth(url, data, moduleView, isList, headers) {
    try {
      const response = await request.post(url).set(this._getHeaders(this.defaultHeaders, headers)).send(data);
      return await Promise.resolve(response.body);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getNoAuth(url, query, moduleView, isList, headers) {
    try {
      const response = await request.get(url).set(this._getHeaders(this.defaultHeaders, headers));
      return await Promise.resolve(response.body);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async patch(url, data, moduleView, isList, headers) {
    try {
      const response = await request
        .patch(url)
        .set(this._getHeaders(this.defaultHeaders, headers))
        .auth(this._getAccessToken(), { type: 'bearer' })
        .send(data);
      return await Promise.resolve(response.body);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default BaseService;
