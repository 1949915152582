import React from 'react';
import { CAP_TABLE_ALIAS, FUTURE_EQUITY_VALUE_ALIAS } from 'common/constants/allocations';
import { largeCurrencyFormat } from 'common/formats/formats';
import { SelectValueViewer } from 'components';
import CaptableList from '../components/CaptableList';

const rowConfig = [
  // 1
  {
    value: 'Exit Equity Value',
    alias: FUTURE_EQUITY_VALUE_ALIAS,
    hidden: false,
    readOnly: false,
    format: largeCurrencyFormat,
    gridType: 'number',
  },
  // 2
  {
    value: 'Cap Table',
    className: 'align-right',
    alias: CAP_TABLE_ALIAS,
    hidden: false,
    readOnly: false,
    dropdown: true,
    dataEditor: props => <CaptableList {...props} />,
    valueViewer: props => <SelectValueViewer {...props} />,
  },
];

export default rowConfig;
