import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { uniq } from 'lodash';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  messagesContainer: {
    marginTop: 14,
    marginLeft: 34,
  },
  message: {
    fontSize: 16,
  },
});

const ConfirmationDeleteWithList = ({ messages, itemName }) => {
  const classes = useStyles();

  const uniqueMessages = uniq(messages);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignmessages="center">
      <Box alignSelf="start">
        {messages.length ? (
          <>
            {messages.length >= 2 && <p>By deleting this {itemName}, you would also be deleting:</p>}
            <ul className={classes.messagesContainer}>
              {uniqueMessages.map(message => (
                <li key={message} className={classes.message}>
                  {message}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p>By deleting this {itemName} all information related will be lost. This process cannot be undone.</p>
        )}
      </Box>
    </Box>
  );
};

ConfirmationDeleteWithList.defaultProps = {
  messages: [],
  itemName: 'element',
};

ConfirmationDeleteWithList.propTypes = {
  messages: PropTypes.array,
  itemName: PropTypes.string,
};

export default ConfirmationDeleteWithList;
