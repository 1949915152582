import {
  VALUATION_SUMMARY_SPREADSHEET_COLUMN_NAME,
  VALUATION_SUMMARY_SPREADSHEET_COLUMN_NAME_PLURAL,
  VALUATION_SUMMARY_SPREADSHEET_TABLE,
  VALUATION_SUMMARY_SPREADSHEET_TITLE,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary/valuationSummary';

export const VALUATION_SUMMARY_SPREADSHEET_TABLE_TERMS = Object.freeze({
  columnName: VALUATION_SUMMARY_SPREADSHEET_COLUMN_NAME,
  pluralColumnName: VALUATION_SUMMARY_SPREADSHEET_COLUMN_NAME_PLURAL,
  tableName: VALUATION_SUMMARY_SPREADSHEET_TITLE,
  tableSlug: VALUATION_SUMMARY_SPREADSHEET_TABLE,
});
