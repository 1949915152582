import { AreObjectIdsEqualParams } from './types';

const areObjectIdsEqual = (params: AreObjectIdsEqualParams) => {
  const { firstObjectList = [], secondObjectList = [] } = params;

  return firstObjectList.every(objectFromFirst =>
    Boolean(secondObjectList.find(objectFromSecond => objectFromSecond?.id === objectFromFirst?.id))
  );
};

export default areObjectIdsEqual;
