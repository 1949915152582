import { APPROACH_WEIGHTING_ALIAS } from 'common/constants/allocations';
import { largeCurrencyFormat, weightingPercentFormat } from 'common/formats/formats';

export default [
  {
    value: 'Approach',
    alias: 'approachName',
    width: '175px',
    gridType: 'string',
    readOnly: true,
    className: 'align-left',
  },
  {
    value: 'Equity Value',
    alias: 'equityValue',
    width: '175px',
    format: largeCurrencyFormat,
    gridType: 'number',
    isRequired: false,
    readOnly: true,
    hasTotal: true,
  },
  {
    value: 'Approach Weighting',
    alias: APPROACH_WEIGHTING_ALIAS,
    width: '175px',
    dbDecimalPlaces: 1,
    format: weightingPercentFormat,
    maxValue: 100,
    gridType: 'percentage',
    hasTotal: true,
  },
  {
    value: 'Weighted Equity Value',
    alias: 'weightedEquityValue',
    width: '175px',
    format: largeCurrencyFormat,
    minValue: 0,
    gridType: 'number',
    isRequired: false,
    readOnly: true,
    hasTotal: true,
    expr: '=APP_EQUITYVALUE_{{rowNumber}} * APP_APPROACHWEIGHTING_{{rowNumber}}',
  },
];
