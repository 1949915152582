import React, { FC } from 'react';
import { MEASUREMENT_DATE_ACTION_ADD } from 'common/constants/measurement-date';
import { DeleteMeasurementDateDialog } from 'components/DeleteMeasurementDate';
import { MeasurementDateDialog } from 'components/MeasurementDates/';
import { MeasurementDateModalProps } from './types';

const MeasurementDateModal: FC<MeasurementDateModalProps> = props => {
  const {
    activeFund,
    closeMeasurementDateModal,
    getMeasurementDates,
    openMeasurementDatesDialog,
    measurementDateAction,
    setMdHasBeenDeleted,
    selectedFmdId,
  } = props;

  const isAddingNewMD = measurementDateAction === MEASUREMENT_DATE_ACTION_ADD;

  return (
    <>
      {openMeasurementDatesDialog && isAddingNewMD && (
        <MeasurementDateDialog
          activeFund={activeFund}
          getMeasurementDates={getMeasurementDates}
          onClose={closeMeasurementDateModal}
          open={openMeasurementDatesDialog}
        />
      )}
      {openMeasurementDatesDialog && !isAddingNewMD && (
        <DeleteMeasurementDateDialog
          onClose={closeMeasurementDateModal}
          open={openMeasurementDatesDialog}
          getMeasurementDates={getMeasurementDates}
          setMdHasBeenDeleted={setMdHasBeenDeleted}
          selectedFmdId={selectedFmdId}
        />
      )}
    </>
  );
};

export default MeasurementDateModal;
