import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Alert } from 'components';
import theme from 'theme';

const useStyles = makeStyles({
  progressBar: {
    height: 64,
    border: `2px solid ${theme.palette.secondary[500]}`,
    borderRadius: '0.25rem',
    backgroundColor: 'white',
    '&&& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: `${theme.palette.secondary[200]}`,
    },
  },
});

const LinearProgressWithLabel = props => {
  const classes = useStyles();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" {...props} className={classes.progressBar} />
      </Box>
      <Box sx={{ minWidth: 35, position: 'absolute', marginLeft: '2rem' }}>
        <Typography variant="body2">{props.file.name}</Typography>
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  file: PropTypes.object.isRequired,
};

const ProgressBar = ({ droppedFiles, file, progress }) => {
  const [uploadedFile, setUploadedFile] = useState(false);

  useEffect(() => {
    if (!droppedFiles.includes(file)) {
      setUploadedFile(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setUploadedFile(true);
    }
  }, [progress]);

  return (
    <Box>
      {!uploadedFile ? (
        <LinearProgressWithLabel value={progress} file={file} />
      ) : (
        <Alert isAlertVisible={true} icon={<CheckCircleOutlineIcon />} severity="success">
          <Typography style={{ fontSize: '0.875rem' }}>{file?.name}</Typography>
        </Alert>
      )}
    </Box>
  );
};

ProgressBar.propTypes = {
  droppedFiles: PropTypes.array.isRequired,
  file: PropTypes.object.isRequired,
  progress: PropTypes.number,
};

export default ProgressBar;
