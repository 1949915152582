/**
 * @name Allocations Action
 * @memberof module:common/actions
 * @type {ReduxAction}
 * @return {Object} Global Actions
 */
import { GET_ALLOCATION_COMPANY, SET_ALLOCATION_INFO } from './types';

/**
 * @function
 * @name getAllocationCompany
 * @description get allocations data by id selected.
 * @param {OBJECT} allocationCompany  - reducer state
 * @return {OBJECT} Action context
 */
export const getAllocationCompany = allocationCompany => ({
  type: GET_ALLOCATION_COMPANY,
  allocationCompany
});

export const setAllocationInfo = allocationInfo => ({
  type: SET_ALLOCATION_INFO,
  allocationInfo
});
