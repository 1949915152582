/**
 * @name Fund Hook
 * @memberof module:common/hooks
 * @type {ReactHook}
 * @return {React} Fund Hook
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'validate.js';
import { FUND_PERMISSION_NAME } from 'common/constants/sidebar';
import { useResponse } from './useResponse';
import { useGetLowerUserPermissions } from './users';
import { FundService } from '..';
import { authAction, companiesAction, fundsAction, globalAction } from '../../common/actions';
import { useStore } from '../../common/store';

/**
 * @function
 * @name useGetFundById
 * @description Get the fund by its Id
 * @param {integer}
 * @return {object}
 */
export const useGetFundById = () => {
  const [, dispatch] = useStore();
  const [data, setData] = useState({});

  const { processErrorResponse, errorNotification } = useResponse();

  const fetchData = useCallback(
    async (newFundId, updateGlobalStore = true) => {
      if (newFundId) {
        dispatch(globalAction.showLoadingProgress(true));

        const defaultErrorMessage = 'An error occurred while loading the Fund information';
        try {
          const fundService = new FundService();
          const fund = await fundService.getFundById(newFundId);
          if (fund) {
            setData(fund);

            if (updateGlobalStore) {
              dispatch(fundsAction.setFundInfo(fund));
            }
          } else {
            errorNotification(defaultErrorMessage);
          }
        } catch (error) {
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'get the fund',
          });
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
      }
    },
    [dispatch, processErrorResponse, errorNotification]
  );

  return [data, fetchData];
};

export const useGetCompaniesWithOwnershipByFund = () => {
  const [, dispatch] = useStore();
  const { processErrorResponse } = useResponse();

  const fetchData = async fundId => {
    if (fundId) {
      dispatch(globalAction.showLoadingProgress(true));
      try {
        const fundService = new FundService();
        const response = await fundService.getCompaniesWithOwnershipByFund(fundId);
        if (response.length > 0) {
          dispatch(companiesAction.setCompanyList(response));
        }
      } catch (error) {
        processErrorResponse({
          error,
          config: {
            404: {
              notification: false,
              callback: () => dispatch(companiesAction.setCompanyList([])),
            },
          },
          action: 'get companies list by fund',
        });
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };

  return [fetchData];
};
/* eslint-disable react-hooks/exhaustive-deps */
export const useHandleSingleFundUser = usingCompanyHook => {
  const [{ firmList, isRestrictedUser, user, fundInfo }, dispatch] = useStore();

  const [permissions, fetchPermissions] = useGetLowerUserPermissions();
  const [, getFundInfo] = useGetFundById();
  const [getCompaniesByFundId] = useGetCompaniesWithOwnershipByFund();

  const hasNotFirmPermissions = useMemo(() => {
    if (firmList) {
      return firmList.every(firm => firm.user_permissions.length === 0);
    }
  }, [firmList]);

  useEffect(() => {
    if (
      hasNotFirmPermissions
      && user?.id
      && !user.is_superuser
      && permissions?.length === 0
      && usingCompanyHook === false
    ) {
      fetchPermissions(user.id);
    }
  }, [hasNotFirmPermissions, user, permissions, usingCompanyHook]);

  useEffect(() => {
    if (permissions?.length > 0 && isEmpty(fundInfo)) {
      const fundPermission = permissions.find(
        ({ feature_object }) => feature_object.object_type === FUND_PERMISSION_NAME
      );
      if (fundPermission) {
        dispatch(authAction.setIsRestrictedUser(true));
        getFundInfo(fundPermission.feature_object.object_id);
      }
    }
  }, [permissions]);

  // need to see if there are companies for this fund
  useEffect(() => {
    if (isRestrictedUser && fundInfo?.id) {
      getCompaniesByFundId(fundInfo.id);
    }
  }, [isRestrictedUser, fundInfo]);
};
