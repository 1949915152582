import React from 'react';
import PropTypes from 'prop-types';
import { CAP_TABLE_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { useFormat } from 'common/hooks';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';

const BacksolveTable = ({ spreadsheets, onChange, deleteColFn, isLedgerTable, workbook }) => {
  const { backsolveSheet } = spreadsheets;

  const [format] = useFormat({ page: CAP_TABLE_CURRENCY_PAGE, units: REGULAR_UNIT });
  return (
    <ScalarSpreadsheet
      {...backsolveSheet}
      id="backsolve-table"
      tableTerms={{ tableSlug: 'backsolve-table' }}
      workbook={workbook}
      onChange={onChange}
      sheet={backsolveSheet}
      displayLegend={false}
      alwaysDisplayLegend={false}
      format={format}
      deleteColumn={deleteColFn}
      isLedgerTable={isLedgerTable}
    />
  );
};

export default BacksolveTable;

BacksolveTable.propTypes = {
  spreadsheets: PropTypes.object,
  workbook: PropTypes.object,
  onChange: PropTypes.func,
  deleteColFn: PropTypes.func,
  isLedgerTable: PropTypes.bool,
};
