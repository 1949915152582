import React, { useContext } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import { TaskContext } from 'context';
import ChildrenQuestionInput from './ChildrenTextQuestionInput';

const QuestionInputDropdown = ({
  inputLabel,
  setAnswer,
  answer,
  setIsDisabled,
  question,
  availableOptions,
  setChildrenAnswers,
  childrenAnswers,
}) => {
  const { children_questions: childrenQuestions } = question;
  const { isMobile } = useContext(TaskContext);

  const handleChange = event => {
    const { value } = event.target;
    setAnswer(value);
    setIsDisabled(false);
  };

  const childQuestionProp = {
    setIsDisabled,
    childrenAnswers,
    setChildrenAnswers,
  };

  return (
    <FormControl variant="outlined" style={{ width: isMobile ? '100%' : '30%' }}>
      <InputLabel id="question-input-select-label">{inputLabel}</InputLabel>
      <Select
        labelId="question-input-select-label"
        id="question-input-select"
        value={answer}
        label={inputLabel}
        onChange={handleChange}>
        {availableOptions.map(option => (
          <MenuItem key={option.id} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {childrenQuestions.map(questionParam => (
        <ChildrenQuestionInput key={questionParam.id} question={questionParam} {...childQuestionProp} />
      ))}
    </FormControl>
  );
};

QuestionInputDropdown.propTypes = {
  availableOptions: PropTypes.array.isRequired,
  inputLabel: PropTypes.string.isRequired,
  setAnswer: PropTypes.func.isRequired,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setIsDisabled: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  setOptionalAnswer: PropTypes.func,
  setChildrenAnswers: PropTypes.func,
  childrenAnswers: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

export default QuestionInputDropdown;
