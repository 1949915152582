import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import {
  DOCUMENT_REQUEST_DESCRIPTION,
  DOCUMENT_REQUEST_TITLE,
  QUESTION_REQUEST_DESCRIPTION,
  QUESTION_REQUEST_TITLE,
} from 'common/constants/firms';
import theme from 'theme';
import RequestTask from './RequestTask';

const RequestsContent = ({
  documents,
  questions,
  setUpdateTaskList,
  primaryInformationRequestId,
  firmId = null,
  companyId = null,
  isInitialSetup = false,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const requestTaskProps = {
    firmId,
    companyId,
    setUpdateTaskList,
    primaryInformationRequestId,
  };

  return (
    <Grid
      container
      style={{
        margin: '2rem 0',
      }}>
      <Grid
        item
        xs={12}
        sm={4}
        style={{
          padding: '0',
          marginRight: !isMobile && '5rem',
        }}>
        {!documents ? (
          <Skeleton variant="rect" width="100%" height={600} />
        ) : (
          <RequestTask
            {...requestTaskProps}
            isDocumentRequest
            title={DOCUMENT_REQUEST_TITLE}
            description={DOCUMENT_REQUEST_DESCRIPTION}
            tasks={documents}
            isInitialSetup={isInitialSetup}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={{
          padding: '0',
        }}>
        {!questions ? (
          <Skeleton variant="rect" width="100%" height={600} />
        ) : (
          <RequestTask
            {...requestTaskProps}
            title={QUESTION_REQUEST_TITLE}
            description={QUESTION_REQUEST_DESCRIPTION}
            tasks={questions}
            isInitialSetup={isInitialSetup}
          />
        )}
      </Grid>
    </Grid>
  );
};

RequestsContent.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  questions: PropTypes.arrayOf(PropTypes.shape({})),
  firmId: PropTypes.number,
  companyId: PropTypes.number,
  setUpdateTaskList: PropTypes.func.isRequired,
  primaryInformationRequestId: PropTypes.number,
  isInitialSetup: PropTypes.bool,
};

export default RequestsContent;
