import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import theme from 'theme';
import { shortDate } from 'utillities';
import DocumentsWithoutFolder from './DocumentsWithoutFolder';
import Folders from './Folders/Folders';
import NumberOfDocsAndSubfolders from './NumberOfDocsAndSubfolders';

const useStyles = makeStyles({
  accordion: {
    '&& .MuiAccordion-root.Mui-expanded:last-child': {
      width: '100%',
    },
    '&& .MuiAccordion-rounded:first-child': {
      width: '100%',
    },
    '&& .MuiPaper-elevation1': {
      boxShadow: 'none',
      borderRadius: '0rem',
    },
  },
  accordionSummary: {
    borderBottom: `0.18rem solid ${theme.palette.primary[700]}`,
    marginBottom: '0.01rem',
  },
  accordionDetails: {
    padding: '0rem',
  },
  folder: {
    '&& .MuiPaper-elevation1': {
      borderBottom: 'none',
    },
  },
  editIcon: {
    marginLeft: 0,
    opacity: 0.3,
    transition: 'ease opacity 1s',
    '&:hover': {
      opacity: 1,
    },
  },
});

const RecentAndPrevAccordions = ({ documents, isCurrentData, displayOnly, expandedStates, accordionClickHandler }) => {
  const classes = useStyles();

  return (
    <div>
      {documents?.length > 0 && (
        <div>
          {documents?.map(documentInfo => (
            <Accordion
              key={documentInfo?.documentId}
              expanded={expandedStates?.[documentInfo?.measurementDate?.date] || false}
              onChange={(event, expanded) => accordionClickHandler(event, expanded, documentInfo.measurementDate.date)}
              className={classes.accordion}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
                <Typography style={{ color: `${theme.palette.primary[700]}`, fontWeight: 'bold' }}>
                  Measurement Date {shortDate(documentInfo?.measurementDate?.date)}
                </Typography>
                <NumberOfDocsAndSubfolders
                  numberOfDocuments={documentInfo?.filesWithoutFolders?.length}
                  numberOfFolders={documentInfo?.folders?.length}
                />
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <DocumentsWithoutFolder
                  documents={documentInfo?.filesWithoutFolders}
                  isCurrentData={isCurrentData}
                  displayOnly={displayOnly}
                  measurementDate={documentInfo?.measurementDate}
                />
              </AccordionDetails>
              <AccordionDetails style={{ padding: '0 0 0 2rem' }}>
                <div id="folders-content">
                  {documentInfo?.folders?.length > 0 && (
                    <Folders
                      documentId={documentInfo?.documentId}
                      documentsFolders={documentInfo?.folders}
                      isCurrentData={isCurrentData}
                    />
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
    </div>
  );
};

RecentAndPrevAccordions.propTypes = {
  isCurrentData: PropTypes.bool,
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  displayOnly: PropTypes.string,
  expandedStates: PropTypes.shape({}),
  accordionClickHandler: PropTypes.func,
};

export default RecentAndPrevAccordions;
