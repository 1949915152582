import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import CustomTextField from 'components/CustomTextField';
import { useFormValidation } from 'services/hooks/useFormValidation';

const constraints = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 3, message: 'must be at least 3 characters' },
  },
};

const ProformaCapTableForm = ({ initialValues, onFormChange, tableTerms }) => {
  const { formValidated, validateForm } = useFormValidation(constraints);
  const [formValues, setFormValues] = useState(initialValues);
  const { errors } = formValidated;

  useEffect(() => {
    validateForm(formValues);
  }, [validateForm, formValues]);

  const onInputChange = debounce(() => {
    onFormChange(formValidated);
  }, 1000);

  useEffect(() => {
    onInputChange();
  }, [onInputChange, onFormChange, formValidated]);

  const handleChange = event => {
    event.persist();
    const {
      target: { name: fieldName, value },
    } = event;

    setFormValues({
      ...formValues,
      [fieldName]: value,
    });
  };

  return (
    <Grid container>
      <CustomTextField
        required
        fullWidth
        id="name"
        name="name"
        type="text"
        label={`${tableTerms.tableName} name`}
        value={formValues.name || ''}
        onChange={handleChange}
        formErrors={errors}
      />
    </Grid>
  );
};

ProformaCapTableForm.propTypes = {
  initialValues: PropTypes.shape({
    errors: PropTypes.shape({}),
    isValid: PropTypes.bool,
    name: PropTypes.string,
    values: PropTypes.shape({}),
  }).isRequired,
  onFormChange: PropTypes.func.isRequired,
  tableTerms: PropTypes.shape({
    columnName: PropTypes.string,
    pluralColumnName: PropTypes.string,
    tableName: PropTypes.string,
    tableSlug: PropTypes.string,
  }).isRequired,
};

export default ProformaCapTableForm;
