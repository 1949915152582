import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useStore } from 'common/store';

const useStyles = makeStyles(theme => ({
  alertStyle: {
    color: theme.palette.warning.main,
    fontSize: '1rem',
    padding: '1rem',
    border: `2px solid ${theme.palette.warning.main}`,
    marginBottom: '1rem',
  },
  yesButton: {
    '&.MuiButton-containedSizeLarge': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.white,
      marginRight: '1rem',
    },
  },
  noButton: {
    '&.MuiButton-sizeLarge': {
      color: theme.palette.warning.main,
      marginRight: '1rem',
      '&:hover': {
        backgroundColor: theme.palette.warning.veryLight,
      },
    },
  },
}));

const ActionButtons = ({ classes, handleNo, handleYes }) => (
  <>
    <Button size="large" className={classes.noButton} onClick={handleNo}>
      No
    </Button>
    <Button size="large" variant="contained" className={classes.yesButton} onClick={handleYes}>
      Yes
    </Button>
  </>
);

const DecisionAlert = ({ cause, updateDecisionService, setShowDisconnectDialog, setDecisionData }) => {
  const { causing_object_name } = cause;
  const classes = useStyles();
  const [{ firmId, user }] = useStore();

  const decisionUpdate = trueOrFalse => ({
    ...cause,
    resolved_by: user.id,
    keep_updated: trueOrFalse,
  });

  const handleNo = () => {
    setShowDisconnectDialog(true);
    const baseData = decisionUpdate(false);
    setDecisionData({ decisions: [baseData], firm: firmId });
  };

  const handleYes = () => {
    const baseData = decisionUpdate(true);
    const decisionData = { decisions: [baseData], firm: firmId };
    updateDecisionService(firmId, decisionData);
  };

  return (
    <Alert
      icon={<ErrorIcon fontSize="medium" style={{ alignSelf: 'center' }} />}
      severity="warning"
      variant="outlined"
      className={classes.alertStyle}
      action={<ActionButtons classes={classes} handleYes={handleYes} handleNo={handleNo} />}>
      <Typography>
        Scalar has updated the <b>{causing_object_name}</b> comp group. There is at least one non-final valuation that
        uses that comp group.
        <b> Would you like to automatically update valuations based on the comp group?</b>
      </Typography>
    </Alert>
  );
};

DecisionAlert.propTypes = {
  cause: PropTypes.object,
  updateDecisionService: PropTypes.func,
  setShowDisconnectDialog: PropTypes.func,
  setDecisionData: PropTypes.func,
};
ActionButtons.propTypes = {
  classes: PropTypes.object,
  handleNo: PropTypes.func,
  handleYes: PropTypes.func,
};

export default DecisionAlert;
