import { isEmpty, isNil, isUndefined } from 'lodash';
import { NOT_APPLICABLE } from '../../constants/general';

export const FILTER_ENABLED = ({ _data: data }) => {
  const result = data
    .filter(cell => cell.enabled && cell.value !== Infinity && !isUndefined(cell.value))
    .map(cell => cell.value);

  if (isEmpty(result)) return [0];
  return result;
};

export const FILTER_NULL = ({ _data: data }) => {
  const result = data.filter(value => !isNil(value) && value !== NOT_APPLICABLE && value !== Infinity && value !== 0);

  if (isEmpty(result)) return [0];
  return result;
};
