import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ScalarSpreadsheetStore } from './types';

const useScalarSpreadsheetStore = create<ScalarSpreadsheetStore>()(
  devtools(
    set => ({
      synchronizedBy: null,
      synchronizedScrollLeft: 0,
      synchronizedSpreadsheets: [],
      setSynchronizedBy: synchronizedBy => set(() => ({ synchronizedBy })),
      setSynchronizedScroll: ({ synchronizedBy, synchronizedScrollLeft }) =>
        set(() => ({ synchronizedBy, synchronizedScrollLeft })),
      setSynchronizedScrollLeft: synchronizedScrollLeft => set(() => ({ synchronizedScrollLeft })),
      setSynchronizedSpreadsheets: synchronizedSpreadsheets => set(() => ({ synchronizedSpreadsheets })),
    }),
    {
      name: 'ScalarSpreadsheetStore',
    }
  )
);

export default useScalarSpreadsheetStore;
