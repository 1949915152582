import CurrencyAdjustedValueViewer from 'components/Spreadsheet/components/CurrencyAdjustedValueViewer';

const updateCostCellsIfDifferent = ({
  currencyAdjustedValueViewerCells,
  isDifferentCurrency,
  parsedCells,
  cell,
  relatedDateAlias = 'date',
}) => {
  if (currencyAdjustedValueViewerCells.includes(cell.alias) && isDifferentCurrency) {
    const relatedDate = parsedCells?.[`${relatedDateAlias}${cell.rowNumber + 1}`]?.value;
    return {
      ...cell,
      valueViewer: CurrencyAdjustedValueViewer,
      relatedDate,
    };
  }
  return cell;
};

export default updateCostCellsIfDifferent;
