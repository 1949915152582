import React, { FC } from 'react';
import { Chart, ChartData, ChartOptions, ChartType, registerables } from 'chart.js';
import { Chart as ReactChartJs } from 'react-chartjs-2';

Chart.register(...registerables);

interface LineBarChartProps {
  data: ChartData;
  options: ChartOptions;
  type?: ChartType;
}

// eslint-disable-next-line arrow-body-style
const LineBarChart: FC<LineBarChartProps> = ({ data, options, type = 'bar' }) => {
  return <ReactChartJs type={type} data={data} options={options} />;
};

export default LineBarChart;
