/* eslint-disable no-console */
import { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authAction, companiesAction, firmsAction, fundsAction, globalAction, usersAction } from 'common/actions';
import { logIn as logInUrl } from 'common/constants/paths';
import { useStore } from 'common/store';
import UnsavedChanges from 'context/UnsavedChanges';
import { resetUserState, updateUserState } from 'dashboard409a/states/features/user';
import { AuthService } from 'services';
import { AuthService as ReadAuthService } from '../../api';

const authService = AuthService.getInstance();

export const useIsUserLoggedIn = () => {
  const [{ user }] = useStore();
  return useMemo(() => !isEmpty(user), [user]);
};

export const useLogOutUser = () => {
  const [, dispatch] = useStore();
  const history = useHistory();

  // Redux dispatch
  const dispatchState = useDispatch();

  const logOutUser = () => {
    // Delete access tokens
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');

    // Clear the OTP verification status
    localStorage.removeItem('isOtpVerified');

    // Clear the user data in the global store
    dispatch(authAction.setUserInfo(null));
    dispatch(authAction.setIsRestrictedUser(null));
    dispatch(firmsAction.setFirmList(null));
    dispatch(firmsAction.setUserManagementFirmList(null));
    dispatch(firmsAction.setFirmId(null));
    dispatch(firmsAction.setFirmInfo(null));
    dispatch(companiesAction.setCompanyList(null));
    dispatch(fundsAction.setFundList(null));
    dispatch(usersAction.setOtherPermissions(null));

    // Redux resetUserState
    dispatchState(resetUserState({}));

    // Redirect the user to the log in page
    history.push(logInUrl);
  };

  return logOutUser;
};

export const useGetUserInfo = () => {
  const [, dispatch] = useStore();

  // Redux dispatch
  const dispatchState = useDispatch();

  const getUserInfo = async callback => {
    try {
      const userInfo = await ReadAuthService.authUsersMeRead();

      // Save user data in the global store
      if (userInfo) {
        dispatch(authAction.setUserInfo(userInfo));
        // Redux updateUserState
        dispatchState(updateUserState(userInfo));

        if (callback) callback();
      }
    } catch (error) {
      dispatch(authAction.setUserInfo({}));
      // Redux resetUserState
      dispatchState(resetUserState({}));
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return getUserInfo;
};

export const useUpdateUserProfileInfo = () => {
  const [, dispatch] = useStore();

  return async userProfileInfo => {
    try {
      dispatch(globalAction.showLoadingProgress(true));
      const data = await authService.updateUserProfileInfo(userProfileInfo);
      return [data, null];
    } catch (error) {
      return [null, error];
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };
};

export const useSendPasswordReset = () => {
  const [, dispatch] = useStore();
  const sendPasswordReset = async email => {
    if (email) {
      try {
        dispatch(globalAction.showLoadingProgress(true));
        const response = await authService.sendPasswordReset({ email });
        return response;
      } catch (error) {
        return error.response;
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };
  return sendPasswordReset;
};

export const useSendPasswordChange = () => {
  const sendPasswordChange = async (newPassword, reNewPassword, uid, token) => {
    if (newPassword && reNewPassword) {
      try {
        const response = await authService.sendPasswordChange({
          new_password: newPassword,
          re_new_password: reNewPassword,
          uid,
          token,
        });
        return response;
      } catch (error) {
        return error.response;
      }
    }
  };
  return sendPasswordChange;
};

export const useGetAccessTokens = () => {
  const [, dispatch] = useStore();
  const getUserInfo = useGetUserInfo();

  const getAccessTokens = async (username, password, callback) => {
    if (username && password) {
      try {
        dispatch(globalAction.showLoadingProgress(true));
        const accessTokens = await authService.getAccessTokens({
          username,
          password,
        });

        if (accessTokens) {
          // Save access tokens in the local store
          localStorage.setItem('access', accessTokens.access);
          localStorage.setItem('refresh', accessTokens.refresh);

          await getUserInfo(callback);

          return accessTokens;
        }
      } catch (error) {
        return error.response;
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };

  return getAccessTokens;
};

export const useRefreshAccessToken = () => {
  const [, dispatch] = useStore();
  const getUserInfo = useGetUserInfo();
  const history = useHistory();
  const { setAction } = useContext(UnsavedChanges);

  const cleanUserInfo = () => {
    setAction(false);
    dispatch(authAction.setUserInfo({}));
    history.push(logInUrl);
  };

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refresh');

    const payload = {
      refresh: refreshToken,
    };

    if (refreshToken) {
      try {
        const newAccessToken = await authService.refreshAccessToken(JSON.stringify(payload));

        if (newAccessToken) {
          localStorage.setItem('access', newAccessToken.access);
          await getUserInfo();

          document.location.reload();
        }
      } catch (error) {
        cleanUserInfo();
        localStorage.removeItem('refresh');
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    } else {
      cleanUserInfo();
    }
  };

  return refreshAccessToken;
};

export const useVerifyAccessToken = () => {
  const [, dispatch] = useStore();
  const getUserInfo = useGetUserInfo();
  const refreshToken = useRefreshAccessToken();

  const verifyAccessToken = async () => {
    const accessToken = localStorage.getItem('access');
    // Check if the the access token exist
    if (accessToken?.length) {
      try {
        // Check if the access token is valid
        const response = await authService.verifyAccessToken({
          token: accessToken,
        });

        if (response) await getUserInfo();
      } catch (error) {
        localStorage.removeItem('access');
        try {
          await refreshToken();
        } catch (error) {
          throw new Error(error);
        }
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };

  return verifyAccessToken;
};

export const useCreateUser = () => {
  const [, dispatch] = useStore();

  const createUser = async newUserData => {
    try {
      dispatch(globalAction.showLoadingProgress(true));
      const data = await authService.createUser(newUserData);
      return [data, null];
    } catch (error) {
      return [null, error];
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return createUser;
};

export const useActivateUser = () => {
  const [, dispatch] = useStore();

  const activateUser = async (uid, token) => {
    try {
      dispatch(globalAction.showLoadingProgress(true));
      const data = await authService.activateUser(uid, token);
      return [data, null];
    } catch (error) {
      return [null, error];
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return activateUser;
};

export const useResendActivationEmail = () => {
  const [, dispatch] = useStore();

  const resendActivationEmail = async email => {
    try {
      dispatch(globalAction.showLoadingProgress(true));
      const data = await authService.resendActivationEmail(email);
      return [data, null];
    } catch (error) {
      return [null, error];
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return resendActivationEmail;
};

export const useGetSocialAccessToken = () => {
  const [, dispatch] = useStore();
  const getUserInfo = useGetUserInfo();

  return async payload => {
    if (payload) {
      try {
        dispatch(globalAction.showLoadingProgress(true));
        const response = await authService.sendAccessToken(payload);

        if (response) {
          // Save access tokens in the local store
          localStorage.setItem('access', response.access);
          localStorage.setItem('refresh', response.refresh);
          dispatch(authAction.setUserInfo(response.user));

          await getUserInfo();
        }
      } catch (error) {
        return error.response;
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };
};
