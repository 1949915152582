import React, { FC, useState } from 'react';
import { List } from '@material-ui/core';
import { BottomMenuItem } from 'dashboard409a/components';
import { IBottomMenu } from './types';
import { BottomMenuItemSkeleton } from '../BottomMenuItem';

const BottomMenu: FC<IBottomMenu> = props => {
  const { items = [] } = props;
  const [anchorElement, setAnchorElement] = useState<HTMLSpanElement | null>(null);

  return (
    <List>
      <>
        {items.map(bottomMenuItem => (
          <BottomMenuItem
            anchorElement={anchorElement}
            item={bottomMenuItem}
            key={`sidebar-bottom-menu-${bottomMenuItem.slug}`}
            setAnchorElement={setAnchorElement}
          />
        ))}

        {items.length === 0
          && Array.from(Array(2).keys()).map(item => (
            <BottomMenuItemSkeleton key={`sidebar-bottom-menu-skeleton-${item}`} />
          ))}
      </>
    </List>
  );
};

export default BottomMenu;
