import React, { FC } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { PublishOutlined as PublishOutlinedIcon } from '@material-ui/icons';
import useStyles from './styles';
import { ValuationDialogContentProps } from './types';

const ValuationDialogContent: FC<ValuationDialogContentProps> = props => {
  const { displayIcon = true, message } = props;

  const classes = useStyles();

  return (
    <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
      {displayIcon && (
        <Grid className={classes.iconContainer}>
          <PublishOutlinedIcon className={classes.icon} />
        </Grid>
      )}

      <Grid>
        <Typography component="p" variant="body1">
          {message}
        </Typography>
      </Grid>
    </Box>
  );
};

export default ValuationDialogContent;
