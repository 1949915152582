import { largeCurrencyFormat } from 'common/formats/formats';
import {
  DISCOUNT_FACTOR_CONFIG,
  LONG_TERM_GROWTH_RATE_CONFIG,
  PV_OF_TERMINAL_VALUE_CONFIG,
  TERMINAL_VALUE_CONFIG,
  TOTAL_CASH_FLOWS_CONFIG,
  WACC_CONFIG,
} from 'pages/Valuations/approaches/discountCashFlow/dcfTerminalValue/util/rowConfigConstants';
import { MAX_VALUATION_NUMBER_DIGITS, PERPETUITY_VALUE } from 'pages/Valuations/util/constants';

export default columnsProps => [
  TERMINAL_VALUE_CONFIG(columnsProps.isDisabled),
  TOTAL_CASH_FLOWS_CONFIG(columnsProps.mainTableName),
  WACC_CONFIG(columnsProps.dcfWaccSheet),
  LONG_TERM_GROWTH_RATE_CONFIG(columnsProps.isDisabled),
  {
    readOnly: true,
    value: 'Terminal Year Perpetuity Value',
    gridType: 'number',
    format: largeCurrencyFormat,
    maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
    expr: '=@2 * (1 + @4) / (@3 - @4)',
    alias: PERPETUITY_VALUE,
  },
  DISCOUNT_FACTOR_CONFIG(columnsProps.mainTableName),
  PV_OF_TERMINAL_VALUE_CONFIG(5, 6),
];
