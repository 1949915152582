import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import theme from 'theme';

const useStyles = makeStyles({
  root: {
    marginLeft: '1rem',
    display: 'flex',
  },
  numberOfItems: {
    color: `${theme.palette.gray[500]}`,
    opacity: 0.3,
    margin: '0 5px',
  },
});

const NumberOfDocsAndSubfolders = ({ numberOfDocuments = 0, numberOfFolders = 0 }) => {
  const classes = useStyles();
  const documentsStringLength = numberOfDocuments === 1 ? 'Document' : 'Documents';
  const foldersStringLength = numberOfFolders === 1 ? 'Subfolder' : 'Subfolders';
  const numberOfDocumentsString = `${numberOfDocuments} ${documentsStringLength}`;
  const numberOfFoldersString = `${numberOfFolders} ${foldersStringLength}`;

  return (
    <div className={classes.root}>
      <Typography className={classes.numberOfItems}>
        {numberOfDocumentsString} | {numberOfFoldersString}
      </Typography>
    </div>
  );
};

NumberOfDocsAndSubfolders.propTypes = {
  numberOfDocuments: PropTypes.number,
  numberOfFolders: PropTypes.number,
};

export default NumberOfDocsAndSubfolders;
