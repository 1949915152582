const INCOME_STATEMENT = cell => {
  const { appGlobal } = window;
  const key = cell.replace(/!/g, '');

  if (appGlobal?.incomeStatement?.cells) {
    if (appGlobal.incomeStatement.cells[key]) {
      return appGlobal.incomeStatement.cells[key].value;
    }
  }

  return null;
};

export default INCOME_STATEMENT;
