import { isEmpty, isNil } from 'lodash';
import { ScenarioValue } from 'common/types/allocation';
import { getArrayValue, getNumberValue, getObjectValue, getStringValue } from 'utillities';
import { GetMethodologiesScenarioValuesParams } from './types';

const getMethodologiesScenarioValues = (params: GetMethodologiesScenarioValuesParams) => {
  const { appliedMethodologies, scenarioValues } = params;

  return getArrayValue(
    scenarioValues?.reduce((accumulator, current) => {
      const shareValues = current.map(shareValue => {
        const { allocationMethod, security } = getObjectValue(shareValue);
        const { id, name } = getObjectValue(security);

        const securityName = getStringValue(name);
        const securityId = getNumberValue(id);
        const shareAllocationMethod = getNumberValue(allocationMethod);

        const currentAllocationScenario = appliedMethodologies?.find(
          appliedMethodology => getNumberValue(appliedMethodology?.allocation_method) === shareAllocationMethod
        );

        const value = getNumberValue(shareValue?.value) * getNumberValue(currentAllocationScenario?.weight);

        return {
          security: {
            name: securityName,
            id: securityId,
          },
          value,
        } as ScenarioValue;
      });

      if (isEmpty(accumulator)) {
        return shareValues;
      }

      return accumulator.map(shareValue => {
        const previousShareValue = shareValues.find(
          currentShareValue =>
            getNumberValue(currentShareValue?.security?.id) === getNumberValue(shareValue?.security?.id)
        );

        return {
          ...shareValue,
          value: !isNil(previousShareValue?.value)
            ? getNumberValue(previousShareValue?.value) + getNumberValue(shareValue?.value)
            : 0,
        };
      });
    }, [])
  );
};

export default getMethodologiesScenarioValues;
