import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { FUNDS_NO_MEASUREMENT_DATE_ASSOCIATED } from 'common/constants/funds';
import { MessageBox } from 'components';
import { AddMeasurementDateButton } from 'pages/Funds/components/AddMeasurementDateButton';
import { NoMeasurementDatesBoxProps } from './types';

const NoMeasurementDatesBox: FC<NoMeasurementDatesBoxProps> = props => {
  const { MeasurementDateModal, openMeasurementDateModal } = props;

  return (
    <Grid>
      {/* Message Box with no measurement dates on this fund and button to add measurement date */}
      <MessageBox
        action={<AddMeasurementDateButton openMeasurementDateModal={openMeasurementDateModal} />}
        fullWidth={false}
        title={FUNDS_NO_MEASUREMENT_DATE_ASSOCIATED}
      />

      {/* Measurement Date Modal */}
      <MeasurementDateModal />
    </Grid>
  );
};

export default NoMeasurementDatesBox;
