import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { ValueWithLinkViewer } from 'components';
import useStyles from './styles';
import { HeadingWithLinkProps } from './types';

const HeadingWithLink: FC<HeadingWithLinkProps> = props => {
  const { title, url } = props;

  const classes = useStyles();

  return (
    <Grid className={classes.heading}>
      <ValueWithLinkViewer name={title} url={url} isTitle />
    </Grid>
  );
};

export default HeadingWithLink;
