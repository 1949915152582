import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { mapToAnswerProp, NEXT, PREVIOUS, QUESTION } from 'common/constants/process-management';
import { useStore } from 'common/store';
import { TaskContext } from 'context';
import { useTasks } from 'services/hooks';
import theme from 'theme';
import { gridShortDate } from 'utillities';
import CustomCircularProgress from './CustomCircularProgress';
import { extractAnswer } from './extractAnswer';
import QuestionInput from './QuestionInput';
import QuestionnaireCompleted from './QuestionnaireCompleted';

const useStyles = makeStyles({
  root: isMobile => ({
    padding: isMobile ? '1rem' : '2rem',
    margin: !isMobile && '2rem',
    minHeight: '40rem',
    borderRadius: '0.5rem',
    border: `2px solid ${theme.palette.primary[500]}`,
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }),
  circularProgress: isMobile => ({
    position: 'absolute',
    top: isMobile ? '1rem' : '2.5rem',
    right: !isMobile && '2.5rem',
    left: isMobile && '50%',
    transform: isMobile && 'translateX(-50%)',
  }),
  typography: isMobile => ({
    fontSize: isMobile ? '1rem' : '1.5rem',
    fontWeight: isMobile ? '500' : '700',
    maxWidth: !isMobile && '50%',
    lineHeight: isMobile ? '1.5rem' : '2rem',
  }),
  boxNavigation: isMobile => ({
    display: 'flex',
    width: '100%',
    position: 'absolute',
    bottom: isMobile ? '0.5rem' : '2rem',
  }),
  buttonNavigation: {
    textTransform: 'none',
    fontSize: '0.875rem',
  },
});

const QuestionSlide = ({ question, allQuestions, setActiveStep, maxSteps }) => {
  const { answers: dbAnswers = [], children_questions: childrenQuestions = [] } = question || {};

  const { requestId, hmacToken } = useParams();
  const firstQuestion = allQuestions[0];
  const lastQuestion = allQuestions[allQuestions.length - 1];
  const { task, isMobile, validateToken } = useContext(TaskContext);
  const classes = useStyles(isMobile);
  const [progress, setProgress] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const { task: taskData } = task;
  const [questionnaireCompleted, setQuestionnaireCompleted] = useState(taskData.is_done);
  const [answer, setAnswer] = useState('');

  const [childrenAnswers, setChildrenAnswers] = useState({});
  const answerIsEmpty = answer === '';
  const [{ user }] = useStore();
  const { updateQuestionnaireRequestToken } = useTasks();
  const answerProp = user ? 'answer' : 'answered';

  function isAnswerModified(answerParam, questionAttrs) {
    const extractedAnswer = extractAnswer(questionAttrs);
    if (extractedAnswer) {
      const propToAccess = mapToAnswerProp[questionAttrs.question_type];
      return extractedAnswer[propToAccess] !== answerParam;
    }
    // there isn't an answer stored
    return true;
  }

  function mapAnswerToAnswerProps(answer, questionAttrs) {
    const extractedAnswer = extractAnswer(questionAttrs);
    const storedId = extractedAnswer ? extractedAnswer.id : null;
    return {
      [QUESTION]: questionAttrs.id,
      [mapToAnswerProp[questionAttrs.question_type]]: answer,
      ...(storedId && { id: storedId }),
    };
  }

  // needs to handle nested questions, send one by one
  async function handleAnswerQuestion(answerParam) {
    const tmpIsQuestionModified = isAnswerModified(answerParam, question);
    if (tmpIsQuestionModified) {
      const answer = mapAnswerToAnswerProps(answerParam, question);
      updateQuestionnaireRequestToken(requestId, hmacToken, answer);
    }
    if (childrenQuestions?.length > 0 && !isEmpty(childrenAnswers)) {
      childrenQuestions.forEach(question => {
        const childAnswer = childrenAnswers[question.id];
        if (isAnswerModified(childAnswer, question)) {
          const answer = mapAnswerToAnswerProps(childAnswer, question);
          updateQuestionnaireRequestToken(requestId, hmacToken, answer);
        }
      });
    }
    setAnswer('');
  }

  async function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    await validateToken();
  }

  function handleNext() {
    if (answerIsEmpty) {
      setIsDisabled(true);
    }

    if (!answerIsEmpty) {
      handleAnswerQuestion(answer);
    }

    if (question.id !== lastQuestion.id) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else {
      setQuestionnaireCompleted(true);
    }
  }

  const questionInputProps = {
    questionType: question.question_type,
    options: question.options,
    isDisabled,
    setIsDisabled,
    handleAnswerQuestion,
    setActiveStep,
    answer,
    setAnswer,
    question,
    dbAnswers,
    setChildrenAnswers,
    childrenAnswers,
  };

  useEffect(() => {
    if (answerIsEmpty && !question[answerProp]) {
      setIsDisabled(true);
    }
  }, [answerIsEmpty, answerProp, question]);

  useEffect(() => {
    const currentQuestionIndex = allQuestions.findIndex(q => q.id === question.id);
    const progressValue = Math.round(((currentQuestionIndex + 1) / maxSteps) * 100);
    setProgress(progressValue);
  }, [allQuestions, maxSteps, question.id]);

  useEffect(() => {
    if (!user && question.answered) {
      setIsDisabled(false);
    } else if (user && question.answers.length) {
      const extractedAnswer = extractAnswer(question);
      const propToAccess = mapToAnswerProp[question.question_type];
      const tempAnswer = extractedAnswer[propToAccess];
      setAnswer(tempAnswer);
      setIsDisabled(false);
    } else if (question.question_type === 'date') {
      setAnswer(gridShortDate());
    } else {
      setAnswer('');
    }
  }, [question, user]);

  useEffect(() => {
    if (childrenQuestions.length) {
      const storedChildrenAnswers = childrenQuestions.reduce((acum, question) => {
        const extractedAnswer = extractAnswer(question);
        if (extractedAnswer) {
          const answerType = mapToAnswerProp[question.question_type];
          // for not stored answers, we use the question id as key, so this is for consistency
          return { ...acum, [question.id]: extractedAnswer[answerType] };
        }
        return undefined;
      }, {});
      setChildrenAnswers(storedChildrenAnswers);
    }
  }, [childrenQuestions]);

  return (
    <Container className={classes.root}>
      {!questionnaireCompleted && (
        <div className={classes.circularProgress}>
          <CustomCircularProgress value={progress} />
        </div>
      )}
      {questionnaireCompleted ? (
        <QuestionnaireCompleted taskData={taskData} typographyClassName={classes.typography} />
      ) : (
        <>
          {!user && question.answered && <CheckCircleIcon color="primary" style={{ margin: '1rem 0' }} />}
          {question.answer && <CheckCircleIcon color="primary" style={{ margin: '1rem 0' }} />}
          <Typography align="center" className={classes.typography}>
            {question.name}
          </Typography>
          <Box marginTop="2rem" width="100%" display="flex" justifyContent="center">
            <QuestionInput {...questionInputProps} />
          </Box>
        </>
      )}
      {!questionnaireCompleted && (
        <Box
          className={classes.boxNavigation}
          justifyContent={question.id === firstQuestion.id ? 'flex-end' : 'space-between'}>
          {question.id !== firstQuestion.id && (
            <Button
              className={classes.buttonNavigation}
              style={{ marginLeft: !isMobile ? '2rem' : '0' }}
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={handleBack}>
              {PREVIOUS}
            </Button>
          )}
          <Button
            className={classes.buttonNavigation}
            style={{ marginRight: !isMobile ? '2rem' : '0' }}
            color="primary"
            endIcon={<ArrowForwardIcon />}
            onClick={handleNext}
            disabled={isDisabled}>
            {NEXT}
          </Button>
        </Box>
      )}
    </Container>
  );
};

QuestionSlide.propTypes = {
  question: PropTypes.object,
  allQuestions: PropTypes.array,
  setActiveStep: PropTypes.func,
  handleBack: PropTypes.func,
  maxSteps: PropTypes.number,
  activeStep: PropTypes.number,
};

export default QuestionSlide;
