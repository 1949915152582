import {
  EXTERNAL_VALUATION_SPREADSHEET_ENTERPRISE_VALUE,
  EXTERNAL_VALUATION_SPREADSHEET_EQUITY_VALUE,
} from 'pages/ValuationsAllocation/common/constants/externalValuation/sheetAliases';
import { parseCellValue } from 'pages/ValuationsAllocation/util';
import { getBooleanValue, getNumberValue, getObjectValue } from 'utillities';
import { CustomReverseParserParams } from './types';

const customReverseParser = (params: CustomReverseParserParams) => {
  const { cells, externalValueAttrs, approach } = getObjectValue(params);
  const { valuations_approach_ev: valuationEVApproach } = getObjectValue(approach);
  const updatedEVApproach = getObjectValue(valuationEVApproach);

  Object.values(getObjectValue(cells)).forEach(cell => {
    const { alias } = getObjectValue(cell);

    const equityDecimalPlaces = getNumberValue(externalValueAttrs?.equity_value?.decimal_places);
    const updatedEquityCellValue = parseCellValue({ cell, fieldDecimalPlaces: equityDecimalPlaces });

    const enterpriseDecimalPlaces = getNumberValue(externalValueAttrs?.equity_value?.decimal_places);
    const updatedEnterpriseCellValue = parseCellValue({ cell, fieldDecimalPlaces: enterpriseDecimalPlaces });

    switch (alias) {
      // Handle Equity Value
      case EXTERNAL_VALUATION_SPREADSHEET_EQUITY_VALUE:
        updatedEVApproach.equity_value = updatedEquityCellValue;
        updatedEVApproach.is_enterprise_value_calculated = !cell.isCellCalculated;
        break;

      // Handle Enterprise Value
      case EXTERNAL_VALUATION_SPREADSHEET_ENTERPRISE_VALUE:
        updatedEVApproach.enterprise_value = updatedEnterpriseCellValue;
        updatedEVApproach.is_enterprise_value_calculated = getBooleanValue(cell.isCellCalculated);
        break;

      default:
        break;
    }
  });
};

export default customReverseParser;
