/**
 * @file services/companies.js
 * @since v1.0
 * @author Carlos Lenon <code@carloslenon.com>
 */

// #region common
import BaseServiceRetryWrapper from './baseRetry';
import { COMPANY, MEASUREMENT_DATES, VALUATIONS } from '../common/endpoints';
// #endregion

class CompaniesService extends BaseServiceRetryWrapper {
  getCapTableList = (companyId, dateId) => this.get(COMPANY.getCapTableList(companyId, dateId));

  /**
   * @function
   * @name getCompanySummary
   * @description Get investments grouped by fund for the given company
   */
  getCompanySummary = (companyId, measurementDateId) =>
    this.get(COMPANY.getCompanySummary(companyId, measurementDateId));

  /**
   * @function
   * @name hasCompanySummary
   * @description Get investments grouped by fund for the given company
   */
  hasCompanySummary = (companyId, measurementDateId) =>
    this.get(COMPANY.hasCompanySummary(companyId, measurementDateId));

  /**
   * @function
   * @name getFundsByCompanyId
   * @description Get the funds/tagalines by id that belongs to a company
   */
  getFundsByCompanyId = companyId => this.get(COMPANY.getFundsByCompanyId(companyId));

  /**
   * @function
   * @name getVersionsByCompanyIdByDateId
   * @description update captable by id selected.
   * @param {integer} companyId the company id
   * @param {integer} dateId the date id
   * @returns {array}
   */
  getVersionsByCompanyIdByDateId = (companyId, dateId) =>
    this.get(COMPANY.getVersionsByCompanyIdByDateId(companyId, dateId));

  /**
   * @function
   * @name getAllocationsVersionsByCompanyIdByDateId
   * @description get allocations versions by company and by date.
   * @param {integer} companyId the company id
   * @param {integer} dateId the date id
   */
  getAllocationsVersionsByCompanyIdByDateId = (companyId, dateId) =>
    this.get(COMPANY.getAllocationsVersionsByCompanyIdByDateId(companyId, dateId));

  /**
   * @function
   * @name measurementDates
   * @description get companies measurement dates.
   * @param {INTEGER} companyId the company id
   */
  measurementDates = companyId => this.get(MEASUREMENT_DATES.measurementDatesByCompanyId(companyId));

  /**
   * @function
   * @name getAllocationValuesByCompanyId
   * @description Get Allocations Values by Company and Measurement Date
   * @param {integer} companyId The Company ID
   * @param {integer} dateId The Measurement Date ID
   */
  getAllocationValuesByCompanyId = (companyId, measurementDateId, version) =>
    this.get(COMPANY.getAllocationValuesByCompanyId(companyId, measurementDateId, version));

  /**
   * @function
   * @name updateCompanyById
   * @description Update a company by Id.
   * @param {OBJECT} data the company structure to send
   */
  updateCompanyById = (companyId, data) => this.put(COMPANY.updateById(companyId), data);

  /* get the data that will be set to companyInfo in store.
  Use same URL as update endpoint ( /api/companies/<companyId> ) */
  getCompanyById = companyId => this.get(COMPANY.updateById(companyId));

  getFiscalYearData = companyMeasurementDateId => this.get(COMPANY.getQuarterEndsByCompanyId(companyMeasurementDateId));

  getExchangeRate = (sourceCurrency, targetCurrency, mdId, currencyDate = undefined) =>
    this.get(VALUATIONS.getExchangeRate(sourceCurrency, targetCurrency, mdId, currencyDate));

  deleteCompany = companyId => this.delete(COMPANY.deleteCompany(companyId));
}

export default CompaniesService;
