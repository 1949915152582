import React, { useEffect, useState } from 'react';
import { Checkbox, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';

const NotReferencedDocumentsList = ({ documents, placeholder, referencedDocuments, setSelectedValues }) => {
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleChange = (__event, data) => {
    setSelectedValues(data);
  };

  useEffect(() => {
    const filteredReferencedDocuments = documents.filter(
      document =>
        !referencedDocuments?.map(refDoc => refDoc.document_reference_id).includes(document.document_reference_id)
    );
    // Add selected attribute for each document in filteredReferencedDocuments
    const selectedDocuments = filteredReferencedDocuments.map(document => ({
      ...document,
    }));
    setFilteredDocuments(selectedDocuments);
  }, [documents, referencedDocuments]);

  return (
    <div style={{ width: '100%' }}>
      {documents && (
        <Autocomplete
          multiple
          id="checkboxes-documents"
          options={filteredDocuments}
          disableCloseOnSelect
          onChange={handleChange}
          getOptionLabel={option => option.file.filename || ''}
          renderOption={(option, state) => (
            <>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={state.selected} />
              {`${option.file.filename}.${option.file.file_type}`}
            </>
          )}
          style={{ width: '100%' }}
          renderInput={params => <TextField variant="outlined" {...params} placeholder={placeholder} />}
          getOptionSelected={(option, value) => option.document_reference_id === value.document_reference_id}
        />
      )}
    </div>
  );
};

NotReferencedDocumentsList.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  setSelectedValues: PropTypes.func,
  placeholder: PropTypes.string,
  endIconSelector: PropTypes.shape({}),
  referencedDocuments: PropTypes.arrayOf(PropTypes.shape({})),
};

export default NotReferencedDocumentsList;
