import React, { FC, useContext } from 'react';
import { Grid, Hidden, Breadcrumbs as MaterialBreadcrums, Typography } from '@material-ui/core';
import { Skeleton as MaterialSkeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import { LayoutContextValues } from 'common/types/store';
import LayoutContext from 'context/LayoutContext';
import ScalarIcon from 'icons/Scalar';
import useStyles from './styles';
import { BreadcrumbProps } from './types';

const BreadcrumbSkeleton: FC = () => <MaterialSkeleton height={25} variant="text" width={150} />;

const Breadcrumb: FC<BreadcrumbProps> = props => {
  const { icon, title, url, isScalarOwned } = props;

  const classes = useStyles();

  if (url) {
    return (
      <Link
        className={`${classes.crumb} ${classes.crumbLink} breadcrumb__item breadcrumb--link`}
        color="inherit"
        to={url}>
        {title ?? <BreadcrumbSkeleton />}
      </Link>
    );
  }

  if (isScalarOwned) {
    return (
      <Typography
        className={`${classes.crumb} ${classes.version} breadcrumb__item ${classes.crumbIcon}`}
        color="textPrimary">
        <ScalarIcon />
        {title ?? <BreadcrumbSkeleton />}
      </Typography>
    );
  }

  return (
    <Typography
      className={`${classes.crumb} ${classes.version} breadcrumb__item`}
      color="textPrimary"
      style={{ opacity: 1 }}>
      {title ?? <BreadcrumbSkeleton />}
      {title && icon ? icon : ''}
    </Typography>
  );
};

const Breadcrumbs: FC = () => {
  const classes = useStyles();
  const { pageBreadcrumbs } = useContext(LayoutContext) as unknown as LayoutContextValues;

  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Hidden mdDown>
        <MaterialBreadcrums className={classes.root} id="breadcrumb" separator="/">
          {pageBreadcrumbs?.map(crumb => (
            <Breadcrumb key={`${crumb.title}-${uuid()}`} {...crumb} />
          ))}
        </MaterialBreadcrums>
      </Hidden>
    </Grid>
  );
};

export default Breadcrumbs;
