export const FUNDS_ADD_MEASUREMENT_DATE = 'Add Measurement Date';
export const FUNDS_ADD_INVESTMENTS = 'Please add investments to a company to continue';
export const FUNDS_DEFAULT_CURRENCY = 'USD';
export const FUNDS_NO_INVESTMENTS = 'There are no investments made in this fund yet';
export const FUNDS_FORBIDDEN_FUND_SUMMARY = 'You do not have permissions to view the fund summary';
export const FUNDS_GET_FUND_SUMMARY_ACCESS = 'Please contact your manager to gain access';
export const FUNDS_INVESTMENTS_SLUG = 'investments';
export const FUNDS_INVESTMENTS_TITLE = 'Schedule of Investments';
export const FUNDS_MEASUREMENT_DATE = 'Measurement Date';
export const FUNDS_NO_MEASUREMENT_DATE = 'No Measurement Date';
export const FUNDS_NO_MEASUREMENT_DATE_ASSOCIATED = 'There are no measurement date associated with this fund yet';
export const FUNDS_SLUG = 'funds';
export const FUNDS_SUMMARY_SLUG = 'summary';
export const FUNDS_SUMMARY_TITLE = 'Summary';
export const FUNDS_TITLE = 'Funds';
export const FUNDS_TOOLTIP_TEXT_FUND_FUND_OWNERSHIP = 'A fund cannot be deleted if it has a fund ownership';
export const FUNDS_TOOLTIP_TEXT_FUND_MEASUREMENT_DATE = 'A fund cannot be deleted if it has a measurement date';
export const FUNDS_VALUATIONS_SUMMARY_TITLE = 'Valuations';
export const SELECT_FUND = 'SELECT FUND';
