import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    typographyLabel: {
      fontWeight: 'bold',
      marginBottom: 20,
    },
    accountCircle: {
      marginRight: 10,
    },
  })
);

export default useStyles;
