import { useCallback, useEffect, useMemo, useState } from 'react';
import uuid from 'react-uuid';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import parser from '../kpi/config/parser';
import reverseParser from '../kpi/config/reverseParser';
import rowConfig from '../kpi/config/rowConfig';

const useKpi = ({ periods, fieldAttributes, isDisabled }) => {
  const [cells, setCells] = useState();
  const [spreadsheet, setSpreadsheet] = useState();
  const [columns, setColumns] = useState();
  const [tableData, setTableData] = useState();
  const [noKpiData, setNoKpiData] = useState(false);

  const updateCells = useCallback(async newCells => {
    setCells(newCells);
  }, []);

  useEffect(() => {
    if (periods?.length) {
      const kpiColumns = periods.map(period => {
        const info = { ...period };
        delete info.income_statement;
        delete info.balance_sheet;
        delete info.statement_date;
        delete info.period_type;
        delete info.is_projection;
        if (!info.kpi_dataset) {
          info.kpi_dataset = [];
        }
        return info;
      });
      const visibleKpiColumns = kpiColumns.filter(column => !column.is_deleted && !column.isNTM && !column.isLTM);
      setColumns(visibleKpiColumns);
    }
  }, [periods]);

  const setOnlyAliveColumns = useCallback(
    columns => setColumns(columns.filter(({ is_deleted }) => !is_deleted)),
    [setColumns]
  );

  useEffect(() => {
    if (columns && columns.length > 0) {
      const tempCompanyKPIs = columns[0].kpi_dataset.map(periodKpi => ({ ...periodKpi.company_kpi, alias: uuid() }));
      setNoKpiData(tempCompanyKPIs.length === 0);
      const tempTableData = {
        columns,
        companyKPIs: tempCompanyKPIs ?? [],
      };
      setTableData(tempTableData);
    }
  }, [columns]);

  const config = useMemo(
    () => rowConfig({ existingKpis: tableData?.companyKPIs ?? [], isDisabled }),
    [tableData, isDisabled]
  );

  const tableTerms = useMemo(
    () => ({
      columnName: 'KPI',
      pluralColumnName: 'KPIs',
      tableName: 'KPI',
      tableSlug: 'kpi',
    }),
    []
  );

  useEffect(() => {
    if (tableData && config) {
      const kpiSpreadsheet = new SpreadsheetConfig({
        allowDeleteRow: !isDisabled,
        allowConfirmAndDeleteColumn: !isDisabled,
        allowCopyColumn: !isDisabled,
        allowDeleteColumn: !isDisabled,
        columns: tableData.columns,
        currencyFormatter: true,
        disabled: isDisabled,
        fieldAttributes,
        hasColTitle: false,
        name: 'KPI',
        parser,
        rowConfig: config,
        reverseParser,
        setColumns: setOnlyAliveColumns,
        showPreviousColsDivider: true,
        showToolbar: true,
        showTotalColumn: false,
        tableData,
        tableTerms,
        unitsFormatter: true,
      });
      setSpreadsheet(kpiSpreadsheet);
    }
  }, [columns, tableData, config, isDisabled, fieldAttributes, tableTerms, setOnlyAliveColumns]);

  return {
    spreadsheet,
    setSpreadsheet,
    setTableData,
    noKpiData,
    cells,
    updateCells,
  };
};

export default useKpi;
