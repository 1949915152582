import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { AUTH_CODE_LABEL } from 'common/constants/login';

const useStyles = makeStyles(theme => ({
  helperText: {
    color: `${theme.palette.pink}!important`,
  },
  label: {
    '&$erroredLabel': {
      color: theme.palette.pink,
    },
  },
  erroredLabel: {},
  underline: {
    '&$error:after': {
      borderBottomColor: theme.palette.pink,
    },
  },
  error: {},
}));

const AuthenticationInput = ({ formState, handleChange }) => {
  const { values, errors } = formState;
  const classes = useStyles();

  return (
    <TextField
      id="enter-user-otp"
      name="otp"
      value={values.otp || ''}
      label={AUTH_CODE_LABEL}
      fullWidth
      type="text"
      className="scar-textField"
      error={errors?.otp?.length > 0}
      required={true}
      helperText={isEmpty(errors?.otp) ? null : errors.otp[0]}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          error: classes.erroredLabel,
        },
      }}
      InputProps={{
        classes: {
          root: classes.underline,
          error: classes.error,
        },
      }}
      onChange={handleChange}
    />
  );
};

AuthenticationInput.propTypes = {
  formState: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default AuthenticationInput;
