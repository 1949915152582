import { useMemo } from 'react';
import { isNull } from 'lodash';
import { transposeFinancials } from 'pages/ValuationsAllocation/util';
import { getStringValue } from 'utillities';
import { ApproachFinancialStatement, UseAssociateFinancialStatementToValuationParams } from './types';

const useAssociateFinancialStatementToValuation = (params: UseAssociateFinancialStatementToValuationParams) => {
  const { approaches, financials, otherFinancialStatements } = params;

  const associateFinancialStatement = useMemo(
    () =>
      approaches?.reduce((accumulator, current) => {
        let financialStatement = financials;

        if (otherFinancialStatements?.length && !isNull(current?.financial_statement)) {
          const matchedFinancials = otherFinancialStatements.find(
            financial => financial.id === current.financial_statement
          );

          financialStatement = matchedFinancials ? transposeFinancials(matchedFinancials) : financials;
        }

        const approachPanelId = getStringValue(current?.panelId ?? current?.panel_id ?? current?.id?.toString());

        const approachFinancialStatement = {
          ...accumulator,
          [approachPanelId]: financialStatement,
        } as ApproachFinancialStatement;

        return approachFinancialStatement;
      }, {} as ApproachFinancialStatement),
    [otherFinancialStatements, approaches, financials]
  );

  return associateFinancialStatement;
};

export default useAssociateFinancialStatementToValuation;
