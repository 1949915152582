import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontSize: '0.75rem',
    color: theme.palette.primary.main,
    fontWeight: 'normal',
  },
}));

const SubTitleText = ({ subTitle }) => {
  const classes = useStyles();

  return <h2 className={classes.subtitle}>{subTitle}</h2>;
};

SubTitleText.propTypes = {
  subTitle: PropTypes.string,
};

export default SubTitleText;
